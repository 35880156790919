import classNames from 'classnames';
import React, { Fragment, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ButtonSave from 'app/components/button/ButtonSave';
import GDStatusBar from 'app/components/status/statusbar';
import { LIST_STATUS } from 'app/const/App';
import { TEMPLATE_KEYWORD } from 'app/const/Notes';
import { PERMISSIONS } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { GET_TEMPLATES_SMS, updateTemplateSMS } from 'app/const/api/V2';
import ErrorPage from 'app/modules/error';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkPermission } from 'common/utils/PermissionUtils';
import { SMS_TEMPLATES } from '../notes/const';
import { TitleTemplate } from '../notes/detail';
import Header from '../notes/detail/Header';
import Loading from '../notes/detail/Loading';
import List from '../notes/list';
import DetailSMSTemplate from './DetailSMSTemplate';

const SMSTemplates = () => {
    const { t } = useTranslation('setting');
    const refWrap = useRef(null);
    const refList = useRef(null);
    const refDetail = useRef(null);
    const refHeader = useRef(null);
    const refItemDetail = useRef({});
    const refSave = useRef(null);
    const refChanged = useRef(-1);
    const refAlert = useRef(false);

    const { settings, permissions } = useSelector(({ auth }) => auth.user);
    const isActiveSMS =
        !!settings?.addons?.smsplivo && checkPermission(permissions.enabled || [], PERMISSIONS.accessSettingsTab);

    const [state, dispatchState] = useReducer(reducer, {
        isEdit: true,
        isLoading: true
    });
    const { isEdit, isLoading } = state;

    const _handleShowDetail = (value = false) => {
        refWrap.current.classList.toggle('is-show', value);
    };

    const _handleClickAddNew = () => {
        _handleShowDetail(true);
        _handleSetDetail({ item: { content: '', name: '', attachments: [] }, isEditItem: false });
    };

    const _handleSave = () => {
        if (!refChanged.current) return;
        const { content, count, attachments } = refDetail.current.getValue();
        const templateName = refHeader.current._getValueInput();

        if (!count || !templateName.trim()) {
            _handleShowAlert(
                null,
                t('customers:cannot_be_blank', { name: !count ? t('content') : t('template_name') }),
                LIST_STATUS.ERROR
            );
            return;
        }

        const newData = { name: templateName, content, attachments };

        const _handleSuccess = () => {
            if (isEdit) {
                const itemEdit = { ...newData, id: refItemDetail.current.id };
                refList.current._updateItemToList(itemEdit);
                _handleSetDetail({ item: itemEdit });
            } else {
                refList.current._addItemToList();
            }
            _handleShowAlert(null, t(isEdit ? 'sms_template_updated_successfully' : 'sms_template_added_successfully'));
        };

        const _handleFail = ({ message }) => {
            _handleShowAlert(null, message, LIST_STATUS.ERROR);
        };

        const _handleFinally = () => {
            refSave.current?.setLoading(false);
            refSave.current?.setDisable(true);
        };

        clientQuery(
            isEdit ? updateTemplateSMS(refItemDetail.current.id) : GET_TEMPLATES_SMS,
            {
                method: isEdit ? 'PUT' : 'POST',
                toFormData: false,
                data: newData
            },
            _handleSuccess,
            _handleFail,
            _handleFinally
        );
    };

    const _handleGetDetail = (item) => {
        if (!item) {
            _handleShowDetail(false);
            return;
        }
        _handleSetDetail({ item, isEditItem: true });
    };

    const _handleSetDetail = ({ item, isEditItem = true }) => {
        if (isEditItem !== isEdit) dispatchState({ isEdit: isEditItem });
        refItemDetail.current = item;
        const { name: smsName, content, attachments } = item;
        refDetail.current.setValue({ message: content, attachments: [...attachments] });
        refHeader.current._setInput(smsName);
        _handleShowDetail(true);
        refSave.current.setDisable(true);
    };

    const _handleChange = () => {
        refChanged.current += 1;
        if (refChanged.current > 0) refSave.current.setDisable(false);
    };

    const _handleFinally = () => {
        if (isLoading) dispatchState({ isLoading: false });
    };

    const _handleShowAlert = (id, message, type = LIST_STATUS.SUCCESS) => {
        if (!refAlert.current || !refSave.current) return;
        refAlert.current.clearAllStatusBar();
        refAlert.current.showStatusBar(id || 'sms_template', message, type);
        refSave.current.setLoading(false);
    };

    if (!isActiveSMS) return <ErrorPage errorMessage={t('auth:no_permission_access')} />;

    return (
        <Fragment>
            <List
                ref={refList}
                typeTemplate={TEMPLATE_KEYWORD.SMS}
                onAddNew={_handleClickAddNew}
                tagTemplate="sms"
                onGetDetail={_handleGetDetail}
                onFinally={_handleFinally}
                onShowStatusBar={_handleShowAlert}
            />
            {isLoading ? <Loading /> : null}
            <div
                id="wrapper_sms"
                className={classNames('wrap-note-template flex-column flex-1', { 'dp-hide': isLoading })}
            >
                <GDStatusBar ref={refAlert} />
                <div ref={refWrap} className="wrap-note-template__content flex-column">
                    <Header
                        ref={refHeader}
                        titleTemplate={<TitleTemplate isEdit={isEdit} title={SMS_TEMPLATES} />}
                        isEdit={isEdit}
                        onChangeName={_handleChange}
                    />

                    <div className="template-content flex-column flex-1">
                        <DetailSMSTemplate ref={refDetail} isEdit={isEdit} onChange={_handleChange} />
                        <div className="template-content__footer shadow-top-brown flex-betweenitems">
                            <div className="v2-btn-default" onClick={() => _handleShowDetail(false)}>
                                {t('close')}
                            </div>
                            <ButtonSave
                                ref={refSave}
                                title={t(isEdit ? 'save_changes' : 'save')}
                                onSave={_handleSave}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SMSTemplates;

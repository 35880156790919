import React from 'react';
const IconsEvent = () => (
    <svg width={0} height={0} style={{ display: 'none' }}>
        <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="link">
            <path
                d="M9.73234 13.7678L13.2678 10.2322"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.61096 11.6464L4.8886 14.3688C3.65845 15.599 3.61096 17.5459 4.78253 18.7175C5.95411 19.8891 7.90109 19.8416 9.13124 18.6114L11.8536 15.8891"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.1465 8.11091L13.8688 5.38855C15.099 4.1584 17.046 4.11091 18.2176 5.28249C19.3891 6.45406 19.3416 8.40104 18.1115 9.63119L15.3891 12.3536"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </symbol>
        <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="lock">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5 8.5C15.8137 8.5 18.5 11.1863 18.5 14.5C18.5 17.8137 15.8137 20.5 12.5 20.5C9.18629 20.5 6.5 17.8137 6.5 14.5C6.5 11.1863 9.18629 8.5 12.5 8.5Z"
                fill="#FFF5EC"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5 8.5C14.8137 8.5 17.5 11.1863 17.5 14.5C17.5 17.8137 14.8137 20.5 11.5 20.5C8.18629 20.5 5.5 17.8137 5.5 14.5C5.5 11.1863 8.18629 8.5 11.5 8.5Z"
                stroke="#FF871E"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5 12.5C12.0523 12.5 12.5 12.9477 12.5 13.5C12.5 14.0523 12.0523 14.5 11.5 14.5C10.9477 14.5 10.5 14.0523 10.5 13.5C10.5 12.9477 10.9477 12.5 11.5 12.5Z"
                stroke="#FF871E"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.5 9.5V7.5C7.5 5.29086 9.29086 3.5 11.5 3.5C13.7091 3.5 15.5 5.29086 15.5 7.5V9.5"
                stroke="#FF871E"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M11.5 14.5V16.5" stroke="#FF871E" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
        </symbol>
        <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 16" id="attention">
            <path
                d="M11.1651 1.75C10.2028 0.0833318 7.79718 0.083336 6.83494 1.75L1.63878 10.75C0.676533 12.4167 1.87935 14.5 3.80385 14.5H14.1962C16.1207 14.5 17.3235 12.4167 16.3612 10.75L11.1651 1.75Z"
                fill="#EE4646"
                stroke="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 11C9.55228 11 10 11.4477 10 12C10 12.5523 9.55228 13 9 13C8.44772 13 8 12.5523 8 12C8 11.4477 8.44772 11 9 11Z"
                fill="white"
            />
            <rect x={8} y={3} width={2} height={6} rx={1} fill="white" />
        </symbol>
        <symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="clock">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.7151 6.02057L22.2849 6.92694C22.6019 7.10991 22.7935 7.45138 22.7846 7.81721L22.7316 10.0261C23.5317 10.9271 24.1537 11.9898 24.5424 13.159L26.4789 14.2158C26.8001 14.391 27 14.7277 27 15.0937V16.9064C27 17.2723 26.8001 17.6091 26.4789 17.7843L24.5424 18.8411C24.1537 20.0104 23.5316 21.0731 22.7314 21.9741L22.7846 24.1829C22.7935 24.5487 22.6019 24.8902 22.2849 25.0731L20.7151 25.9795C20.3981 26.1625 20.0066 26.1577 19.6942 25.9671L17.8098 24.818C17.2252 24.9374 16.6199 25 16 25C15.3801 25 14.7748 24.9374 14.1902 24.818L12.3058 25.9671C11.9934 26.1577 11.6019 26.1625 11.2849 25.9795L9.71506 25.0731C9.39815 24.8902 9.20648 24.5487 9.21536 24.1829L9.26856 21.9741C8.46837 21.0731 7.84631 20.0104 7.45762 18.8411L5.52115 17.7843C5.19989 17.6091 5 17.2723 5 16.9064V15.0937C5 14.7277 5.19989 14.391 5.52115 14.2158L7.45762 13.159C7.84629 11.9898 8.46829 10.9271 9.26841 10.0261L9.21536 7.81721C9.20648 7.45138 9.39815 7.10991 9.71506 6.92694L11.2849 6.02057C11.6019 5.8376 11.9934 5.84235 12.3058 6.03295L14.1902 7.18207C14.7748 7.06271 15.3801 7.00003 16 7.00003C16.6199 7.00003 17.2252 7.06271 17.8098 7.18207L19.6942 6.03295C20.0066 5.84235 20.3981 5.8376 20.7151 6.02057ZM16 11C13.2386 11 11 13.2386 11 16C11 18.7615 13.2386 21 16 21C18.7614 21 21 18.7615 21 16C21 13.2386 18.7614 11 16 11Z"
                fill="#FF4A00"
            />
            <circle cx={16} cy={16} r={7} fill="#ffe0e0" />
            <path d="M16 11V16L19 18" stroke="#FF4A00" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        </symbol>
    </svg>
);
export default IconsEvent;

import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ADDONS_PORTAL_WIDGET } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import IconCogWheel from 'assets/icon/IconCogWheel';

const AddonsFooterPortalWidget = () => {
    const activeCustomerPortal = useSelector(({ auth }) => auth.user.settings.addons.customer_portal);
    const { t } = useTranslation();
    return (
        <div className="boxs__footer">
            <Link
                to={addBranchPath(ADDONS_PORTAL_WIDGET)}
                className={classNames('v2-btn-default svg-purple btn-bg-purple --icon-r --sm', {
                    'is-disable': !activeCustomerPortal
                })}
            >
                {t('addons:manage')} <IconCogWheel />
            </Link>
        </div>
    );
};

export default AddonsFooterPortalWidget;

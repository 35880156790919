import React, { forwardRef, useCallback, useContext, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import StatusBar from 'app/components/status/statusbar';
import { actionUpdateBuilding, actionUpdateUnitDetail, getUrlCustomerContact } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import { ManageUnitContext } from 'app/modules/jobdetail/contexts/ManageUnitContext';
import UnitDetailContextProvider from 'app/modules/jobdetail/contexts/UnitDetailContext';
import Building from 'app/modules/jobdetail/tabs/unit/manage/detail/Building';
import Email from 'app/modules/jobdetail/tabs/unit/manage/detail/Email';
import FirstName from 'app/modules/jobdetail/tabs/unit/manage/detail/FirstName';
import LastName from 'app/modules/jobdetail/tabs/unit/manage/detail/LastName';
import Phone from 'app/modules/jobdetail/tabs/unit/manage/detail/Phone';
import TopNote from 'app/modules/jobdetail/tabs/unit/manage/detail/topnote/Detail';
import Unit from 'app/modules/jobdetail/tabs/unit/manage/detail/Unit';
import { clientQuery } from 'common/utils/ApiUtils';

const DetailUnit = forwardRef(({ onUpdate, parentContact = [], parentTotalContact = 0 }, ref) => {
    const { t } = useTranslation();
    const { jobData, updateUnit } = useSelector(({ createJobReducer }) => createJobReducer);
    const { locationData } = useSelector(({ locationReducer }) => locationReducer);
    const { manageUnitContextData, updateManageUnitContextData } = useContext(ManageUnitContext);

    const [state, dispatchState] = useReducer(reducer, {
        isLoadingContact: true,
        isFetchedContact: false,
        dataContact: parentContact || [],
        totalContact: parentTotalContact || 0
    });

    const finalUnitSelected = manageUnitContextData.unitSelected;

    const refUnitPhone = useRef(null);
    const refStatusBar = useRef(null);
    const refOffset = useRef(0);

    useImperativeHandle(ref, () => ({ onSave: _handleSaveUnit }));

    function _handleFetchContact(offset) {
        if (manageUnitContextData.customerId) {
            const optionsQuery = {
                data: { limit: 10, offset },
                toFormData: false,
                method: 'GET'
            };

            if (offset === 0) {
                optionsQuery.data.total = 1;
                optionsQuery.data.inc = 'customer';
                refOffset.current = 0;
            }

            if (offset === null) {
                optionsQuery.data.offset = refOffset.current + 10;
                refOffset.current += 10;
            }

            clientQuery(
                getUrlCustomerContact(manageUnitContextData.customerId),
                optionsQuery,
                _handleFetchContactSuccess,
                _handleFetchContactFailed
            );
        } else {
            dispatchState({
                isLoadingContact: false
            });
        }
    }

    function _handleFetchContactSuccess(response) {
        dispatchState({
            isLoadingContact: false,
            isFetchedContact: true,
            dataContact: [...state.dataContact, ...response.data],
            totalContact: response.total || state.totalContact
        });
    }

    function _handleFetchContactFailed() {
        dispatchState({
            isLoadingContact: false
        });
    }

    const _prepareData = useCallback(() => {
        const getDetailBuildingSelected =
            manageUnitContextData.buildingData.find((item) => item.id === manageUnitContextData.buildingSelected) || {};
        const getDetailUnitSelected =
            manageUnitContextData.unitData.find((item) => item.unit_id === finalUnitSelected) || {};
        const unitDetail = getDetailBuildingSelected.units?.find((item) => item.id === finalUnitSelected) || {};

        return {
            buildingName: getDetailBuildingSelected.name,
            unitName: unitDetail?.name || '',
            unit_id: finalUnitSelected,
            top_note: getDetailUnitSelected?.top_note || t('common:this_note_will_appear_on_all_unit'),
            phone: getDetailUnitSelected?.phone || [],
            first_name: getDetailUnitSelected?.first_name || '',
            last_name: getDetailUnitSelected?.last_name || '',
            email: getDetailUnitSelected?.email || '',
            jobId: manageUnitContextData.jobId,
            buildingData: manageUnitContextData.buildingData,
            buildingSelected: manageUnitContextData.buildingSelected,
            isCheckunit: true,
            isCheckBilding: true
        };
    }, [finalUnitSelected]);

    function _handleSaveUnit(callback) {
        const params = refUnitPhone.current.getValue();
        const optionsQuery = { data: params, toFormData: false, method: 'PUT' };

        if (manageUnitContextData.withoutFetch) {
            _handleSaveSuccess(
                { message: t('jobDetail:save_unit_successfully', { name: params.name }) },
                callback,
                params
            );
            return;
        }

        clientQuery(
            actionUpdateUnitDetail(manageUnitContextData.locationId, finalUnitSelected),
            optionsQuery,
            (response) => _handleSaveSuccess(response, callback, params),
            (response) => _handleSaveFailed(response, callback)
        );
    }

    function _handleSaveSuccess(response, callback, params) {
        refStatusBar.current &&
            refStatusBar.current.showStatusBar('show_error', response.message.toString(), LIST_STATUS.SUCCESS);
        typeof callback === 'function' && callback();
        let tempBuilding = [...manageUnitContextData.buildingData];
        let temUnit = [...manageUnitContextData.unitData];

        const newNameBuilding = params.buildingName;

        tempBuilding = tempBuilding.map((item) => {
            if (item.id === manageUnitContextData.buildingSelected) {
                if (newNameBuilding !== item.name && !manageUnitContextData.withoutFetch) {
                    _handleUpdateBuilding(newNameBuilding);
                }

                let listUnit = item.units || [];
                listUnit = listUnit.map((itemUnit) => {
                    if (itemUnit.id === finalUnitSelected) {
                        return {
                            ...itemUnit,
                            name: params.name
                        };
                    }
                    return itemUnit;
                });
                return {
                    ...item,
                    name: newNameBuilding,
                    units: listUnit
                };
            }
            return item;
        });

        temUnit = temUnit.map((item) => {
            if (item.unit_id === finalUnitSelected) {
                return {
                    ...item,
                    name: params.name,
                    email: params.email,
                    first_name: params.first_name,
                    last_name: params.last_name,
                    phone: params.phones,
                    top_note: params.note
                };
            }
            return item;
        });

        onUpdate(tempBuilding, temUnit);
        const dataUpdate = { ...(response?.data || {}), location_id: manageUnitContextData.locationId };
        if (locationData?.onUpdateUnitSuccess) locationData.onUpdateUnitSuccess(dataUpdate, 'update');
        if (updateUnit && jobData) updateUnit(dataUpdate, 'update', jobData.customer_id);
        updateManageUnitContextData({
            buildingData: tempBuilding,
            unitData: temUnit
        });
    }

    function _handleUpdateBuilding(newName) {
        const optionsQuery = { data: { name: newName, job_id: manageUnitContextData.jobId }, method: 'PUT' };
        clientQuery(
            actionUpdateBuilding(manageUnitContextData.locationId, manageUnitContextData.buildingSelected),
            optionsQuery
        );
    }

    function _handleSaveFailed(response, callback) {
        refStatusBar.current &&
            refStatusBar.current.showStatusBar('show_error', response.message.toString(), LIST_STATUS.ERROR);
        typeof callback === 'function' && callback();
    }

    if (!finalUnitSelected) {
        return <div className="wrap-mdu__detail has-form scrolls" />;
    }

    return (
        <div className="wrap-mdu__detail has-form scrolls">
            <div className="header mdu-label">{t('customers:unit_details')}</div>
            <StatusBar ref={refStatusBar} />
            <UnitDetailContextProvider finalUnitSelectedId={finalUnitSelected} manageUnitData={_prepareData()}>
                <Building />
                <Unit />
                <FirstName {...state} onFetch={_handleFetchContact} />
                <LastName />
                <Email />
                <Phone ref={refUnitPhone} />
                <TopNote />
            </UnitDetailContextProvider>
        </div>
    );
});

export default DetailUnit;

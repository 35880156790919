export default function IconEdit() {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.7071 5.70711L18.2929 7.29289C18.6834 7.68344 18.6834 8.31658 18.2929 8.70715L10.001 17L5 19L7 14L15.2929 5.70711C15.6834 5.31658 16.3166 5.31658 16.7071 5.70711Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.5 7.5L16.5 9.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.5 14.5L9.5 16.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

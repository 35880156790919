import React, { useMemo } from 'react';
import GdButton from 'app/components/button';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { useReducer, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import { LoadingDetailItems } from 'app/modules/settings/templatesManager/components/todoList/Loading';
import ButtonSave from 'app/components/button/ButtonSave';
import { useTranslation } from 'react-i18next';
import StatusBar from 'app/components/status/statusbar';
import { LIST_STATUS } from 'app/const/Status';

import GdConfirm from 'app/components/confirm';
import { useEnterKeydownClick } from 'common/hooks';
import { ESTIMATE_TYPE } from 'app/modules/jobdetail/const/Estimate';
import AddInvoiceContextProvider from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import InvoiceService from 'app/modules/jobdetail/tabs/addinvoice/form/items';
import InvoiceSummary from 'app/modules/jobdetail/tabs/addinvoice/form/summary';
import InvoiceTerms from 'app/modules/jobdetail/tabs/addinvoice/form/terms';
import InvoiceNotes from 'app/modules/jobdetail/tabs/addinvoice/form/notes';
import CustomerInfo from 'app/modules/quickadd/invoice/CustomerInfo';
import { GET_TEMPLATES_ESTIMATES, actionTemplateEstimates } from 'app/const/api/V2';
import { prepareDataTemolate } from 'app/modules/jobdetail/tabs/addestimate/EstimateUtils';
import { TAG_ESTIMATE_TEMPLATE } from 'app/const/Notes';
import { DEFAULT_VALUE } from 'app/modules/jobdetail/const/Invoice';

const EstimateDetail = ({ onAddTemplate = () => {}, onHasBeenChange = () => {} }, ref) => {
    const [state, dispatchState] = useReducer(reducer, {
        estimateDetail: {},
        isLoading: false,
        name: '',
        templateId: '',
        isEdit: false,
        isOpen: false,
        isFetched: 0,
        type: ESTIMATE_TYPE.BASIC
    });

    const refInput = useRef(null);
    const refButtonSave = useRef(null);
    const refStatusBar = useRef(null);
    const refConfirm = useRef(null);
    const refCustomer = useRef(null);

    const { t } = useTranslation(['setting']);

    const { estimateDetail, isLoading, templateId, name, isEdit, isOpen, isFetched, type: estimateType } = state;

    useImperativeHandle(ref, () => ({
        _getDetail: _getDetailItems,
        _openAddTemplate,
        _closeForm,
        _showStatusBar
    }));

    useEffect(() => {
        if (isFetched) {
            _setDisableSave(false);
            refInput.current.value = name;
        }
    }, [isFetched]);

    useEnterKeydownClick(isOpen);

    const defaultData = useMemo(() => {
        return prepareDataTemolate({ isEdit, estimateType, estimateDetail });
    }, [estimateType, isOpen, templateId, isFetched]);

    const _getDetailItems = (data) => {
        const { id: itemId, name } = data;

        if (!itemId) {
            dispatchState({ isLoading: false, isFetched: 0, isOpen: false });
            return;
        }

        !isLoading && dispatchState({ isLoading: true, isFetched: 0, isOpen: false });

        const _success = ({ data }) => {
            dispatchState({
                estimateDetail: data,
                isLoading: false,
                name,
                templateId: itemId,
                isEdit: true,
                isOpen: true,
                isFetched: Date.now()
            });
        };

        const _fail = () => {
            dispatchState({ isLoading: false });
        };

        clientQuery(actionTemplateEstimates(itemId), { method: 'GET' }, _success, _fail);
    };

    const _openAddTemplate = (type) => {
        _openAddTemplateInternal(type, {});
    };

    const _openAddTemplateInternal = (type, newEsdata = {}, is_edit = false) => {
        dispatchState({
            estimateDetail: newEsdata,
            isLoading: false,
            name: newEsdata.name || '',
            templateId: newEsdata.id || '',
            isEdit: is_edit,
            isOpen: true,
            isFetched: Date.now(),
            type
        });
    };

    const _submitFail = (response) => {
        _removeSaveLoading();
        _showStatusBar('show_error', response.message?.toString(), LIST_STATUS.ERROR);
    };

    const _success = ({ data }) => {
        onAddTemplate({ id: data.id, name: refInput.current.value }, isEdit);
        _removeSaveLoading();
        _showStatusBar(
            'show_success',
            t(isEdit ? 'changes_saved_successfully' : 'create_template_successfully'),
            LIST_STATUS.SUCCESS
        );
        _openAddTemplateInternal(estimateType, data, true);
    };

    const _addNewTemplate = (items) => {
        const name = refInput.current.value;
        const refValue = refCustomer.current.getValue();
        const { type: estimateType, packages: estimatePackages } = refValue;

        const finalDiscount = { ...refValue.discount };
        delete finalDiscount.total;
        const finalDeposit = { ...refValue.deposit };
        delete finalDeposit.total;

        clientQuery(
            GET_TEMPLATES_ESTIMATES,
            {
                method: 'POST',
                toFormData: false,
                data: {
                    name,
                    discount: finalDiscount,
                    deposit: finalDeposit,
                    items,
                    terms: refValue.terms,
                    note: refValue.note,
                    packages: estimatePackages,
                    type: estimateType,
                    payment_terms_id: refValue.payment_terms.id || ''
                }
            },
            _success,
            _submitFail
        );
    };

    const _updateTemplate = (items) => {
        const name = refInput.current.value;
        const refValue = refCustomer.current.getValue();
        const { type: estimateType, packages: estimatePackages } = refValue;

        const finalDiscount = { ...refValue.discount };
        delete finalDiscount.total;
        const finalDeposit = { ...refValue.deposit };
        delete finalDeposit.total;

        clientQuery(
            actionTemplateEstimates(templateId),
            {
                method: 'PUT',
                toFormData: false,
                data: {
                    name,
                    discount: finalDiscount,
                    deposit: finalDeposit,
                    items,
                    terms: refValue.terms,
                    note: refValue.note,
                    packages: estimatePackages,
                    type: estimateType,
                    payment_terms_id: refValue.payment_terms.id || ''
                }
            },
            _success,
            _submitFail
        );
    };

    const _removeSaveLoading = () => refButtonSave.current.removeLoading();

    const _handleError = (msg) => {
        refStatusBar.current.showStatusBar(
            'show_error',
            msg || t('common:one_or_more_estimate_field_not_complete'),
            LIST_STATUS.ERROR
        );
        _removeSaveLoading();
        return false;
    };

    const _checkDeposit = (depositValue, total, packageId) => {
        const finalValueDeposit = parseFloat(depositValue.total.value);

        if (parseFloat(total) >= 0 && finalValueDeposit > parseFloat(total)) {
            document.getElementById(`deposit_cost_${packageId}`).classList.add('field-error');
            _handleError(t('setting:message_error_value_deposit'));
            return false;
        }
        return true;
    };

    const _saveTemplate = () => {
        const refValue = refCustomer.current.getValue();
        const name = refInput.current.value;

        if (!name.trim().length) {
            return _handleError(t('setting:please_enter_a_template_name'));
        }

        const itemsSubmit = [];
        const { type: estimateType, packages: estimatePackages, items: listItems } = refValue;
        const finalDeposit = { ...(refValue.deposit || DEFAULT_VALUE.deposit) };

        let checkPass = true;

        if (estimateType === ESTIMATE_TYPE.PACKAGES) {
            let checkPassDeposit = true;

            estimatePackages.every((itemPackage) => {
                const { id: packageId, name: packageName, deposit, total } = itemPackage;

                checkPassDeposit = _checkDeposit(deposit, total, packageId);

                if (!checkPassDeposit) {
                    return false;
                }

                if (!!!packageName) {
                    checkPass = false;
                    document.getElementById(`package_item_name_${packageId}`).classList.add('field-error');
                }

                if (!listItems.some((lineItem) => lineItem.package_id === packageId)) {
                    checkPass = false;
                    document.getElementById(`package_item_detail_${packageId}`).classList.remove('dp-hide');
                }

                return true;
            });

            if (!checkPassDeposit) {
                return false;
            }
        } else {
            if (!_checkDeposit(finalDeposit, refValue.total, '')) {
                return false;
            }
        }

        if (!checkPass) {
            return _handleError();
        }

        listItems.forEach((element) => {
            const checkItemCost = element.cost.value.length !== 0;
            const { item_id: serviceItemId, id: itemId, tax1: tax1Detail, tax2: tax2Detail } = element;

            if (checkItemCost && serviceItemId) {
                itemsSubmit.push({
                    item_id: serviceItemId,
                    name: element.name,
                    quantity: element.quantity,
                    cost: element.cost.value,
                    tax1_id: tax1Detail?.id || '',
                    tax2_id: tax2Detail?.id || '',
                    tax1_name: tax1Detail?.name || '',
                    tax2_name: tax2Detail?.name || '',
                    tax1_rate: tax1Detail?.rate || '',
                    tax2_rate: tax2Detail?.rate || '',
                    description: element.description,
                    one_time: element.one_time || 0,
                    type: element.type || '',
                    package_id: element.package_id || ''
                });
            } else {
                checkPass = false;
                if (!checkItemCost) {
                    document.getElementById(`service_item_cost_${itemId}`).classList.add('field-error');
                }
                if (!serviceItemId) {
                    document.getElementById(`service_item_detail_${itemId}`).classList.add('field-error');
                }
            }
        });

        if (!checkPass) {
            return _handleError();
        }

        if (isEdit) {
            _updateTemplate(itemsSubmit);
        } else {
            _addNewTemplate(itemsSubmit);
        }
    };

    const _showStatusBar = (id, message, status) => {
        refStatusBar.current.showStatusBar(id, message, status);
    };

    const _changeTemplateName = (e) => {
        _setDisableSave(!!!e.target.value);
    };

    const _close = () => {
        dispatchState({ isOpen: false });
    };

    const _closeForm = () => {
        if (!refButtonSave.current.getStatusBtn()) {
            refConfirm.current.open(_close);
        } else {
            _close();
        }
    };

    const _setDisableSave = (disabled) => {
        refButtonSave.current.setDisable(disabled);
        onHasBeenChange(!disabled);
    };

    const _nextAction = (callback = () => {}) => {
        callback();
    };

    function _handleTriggerButtonSave(value) {
        refButtonSave.current.setDisable(value);
    }

    if (isLoading) return <LoadingDetailItems />;

    if (!isFetched) {
        return false;
    }

    return (
        <div className="wrap-note-template flex-column flex-1">
            <StatusBar ref={refStatusBar} />
            <div className="wrap-note-template__content flex-column is-show">
                <div className="template-header">
                    <h5 className="title --sm">
                        {t('action_estimate_template', {
                            action: t(isEdit ? 'edit' : 'create'),
                            type: t(TAG_ESTIMATE_TEMPLATE[estimateDetail?.type || estimateType])
                        })}
                    </h5>
                    <div className="template-header__name mt-2">
                        <p className="title">{t('template_name')}</p>
                        <input
                            ref={refInput}
                            type="text"
                            className="field-input"
                            placeholder={t('template_name')}
                            defaultValue={name}
                            onChange={_changeTemplateName}
                            spellCheck
                        />
                    </div>
                </div>

                <div className="template-content flex-column flex-1">
                    <div className="wrap-estimate">
                        <div className="tab-conts form-tabpane-details show-form-edit form-add-estimate">
                            <div className="container-setting-center">
                                <div className="container-column form-edit-template has-form">
                                    <AddInvoiceContextProvider
                                        onUpdateButtonSave={_handleTriggerButtonSave}
                                        invoiceData={defaultData}
                                        reloadService={isFetched}
                                    >
                                        <CustomerInfo isEmpty isInvoice={false} ref={refCustomer} />
                                        <InvoiceService isInvoice={false} />
                                        <InvoiceSummary isInvoice={false} isAddPayment={false} />

                                        <div className="content-elm-edit rows note-details">
                                            <div className="dashboard-wrapper --main">
                                                <div className="content-top-notes">
                                                    <InvoiceTerms isInvoice={false} />
                                                    <InvoiceNotes isInvoice={false} />
                                                </div>
                                            </div>
                                        </div>
                                    </AddInvoiceContextProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="template-content__footer shadow-top-brown flex-betweenitems">
                        <GdButton className="v2-btn-default" title={t('close')} onClick={_closeForm} />
                        <ButtonSave
                            ref={refButtonSave}
                            wrapClass="v2-btn-main"
                            title={isEdit ? t('save_changes') : t('jobDetail:save_template')}
                            onSave={_saveTemplate}
                        />
                    </div>
                </div>
            </div>
            <GdConfirm
                ref={refConfirm}
                title={t('common:notification')}
                message={t('confirm_unsaved_changes')}
                titleConfirm={t('common:confirm')}
                listButton={{ confirm: true, cancel: true }}
                onConfirm={_nextAction}
            />
        </div>
    );
};

export default forwardRef(EstimateDetail);

import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import IconUnOrderList from 'assets/icon/IconUnOrderList';
import { handleGetActiveInlineStyle } from '../utils';

const CustomUlOption = ({ editorState, onInsert = () => {} }) => {
    const { t } = useTranslation();
    return (
        <span
            className={classNames('editor-controls__btn tooltip', {
                active: handleGetActiveInlineStyle(editorState, 'unordered-list-item')
            })}
            onMouseDown={(e) => onInsert(e, 'unordered-list-item')}
        >
            <IconUnOrderList />
            <span className="tooltiptext top">{t('common:unordered_list')}</span>
        </span>
    );
};

export default CustomUlOption;

import classNames from 'classnames';
import moment from 'moment';
import React, { forwardRef, Fragment, useImperativeHandle, useReducer } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { DATE_FORMAT_ENGLISH, DATE_FORMAT_SPANISH, TODAY } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { EN } from 'app/const/Settings';
import i18n from 'assets/i18n';
import IconSingleStep from 'assets/icon/IconSingleStep';
import { renderDatePickerDayName } from 'common/utils/FunctionUtils';

const InputDate = ({ start, onChangeDate = () => {} }, ref) => {
    const CustomInputRef = forwardRef(CustomInput);
    const [state, dispatchState] = useReducer(reducer, {
        isFocus: false,
        selected: moment(start)._d || TODAY
    });

    useImperativeHandle(ref, () => ({ getValue: () => state.selected }));

    const _handleOnClick = (date) => {
        dispatchState({ selected: date });
        onChangeDate(date);
    };

    const renderContainer = ({ children }) => {
        return (
            <div className="format-container-date-picker">
                <div className="wrapper-date-picker-container">
                    <CalendarContainer className="react-datepicker-custom">
                        <div style={{ position: 'relative' }}>{children}</div>
                    </CalendarContainer>
                </div>
            </div>
        );
    };

    return (
        <DatePicker
            selected={state.selected}
            onCalendarOpen={() => dispatchState({ isFocus: true })}
            onCalendarClose={() => dispatchState({ isFocus: false })}
            shouldCloseOnSelect={false}
            calendarContainer={renderContainer}
            onChange={_handleOnClick}
            customInput={<CustomInputRef date={state.selected} isFocus={state.isFocus} />}
            renderCustomHeader={CustomHeader}
        />
    );
};

const CustomInput = ({ isFocus = false, onChange = () => {}, onFocus = () => {}, ...props }, ref) => {
    const { t, i18n } = useTranslation();
    const i18nLanguageCode = i18n.language;
    const value = moment.utc(props.date).locale(i18nLanguageCode);
    return (
        <input
            ref={ref}
            onFocus={onFocus}
            onChange={onChange}
            className={classNames('field-control', { 'has-focus': isFocus })}
            type="text"
            placeholder={t('calendar:select_date')}
            value={`${value.format(i18nLanguageCode === EN ? DATE_FORMAT_ENGLISH : DATE_FORMAT_SPANISH)}, ${value.format('YYYY')}`}
        />
    );
};

const CustomHeader = ({ monthDate, decreaseMonth, increaseMonth }) => {
    return (
        <Fragment>
            <div className="datepicker-header-custom --just-label">
                <div
                    onClick={decreaseMonth}
                    className="btn-navigation --previous v2-btn-default --icon-sm --transparent"
                >
                    <IconSingleStep isPrev />
                </div>

                <p className="label-month w-100">{moment(monthDate).locale(i18n.language).format('MMMM YYYY')}</p>

                <div onClick={increaseMonth} className="btn-navigation --next v2-btn-default --icon-sm --transparent">
                    <IconSingleStep />
                </div>
            </div>
            {renderDatePickerDayName()}
        </Fragment>
    );
};

export default forwardRef(InputDate);

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { InvoiceRepeatContext } from 'app/modules/jobdetail/contexts/InvoiceRepeatContext';
import IconPen from 'assets/icon/IconPen';

const Summary = () => {
    const { t } = useTranslation();
    const { invoiceRepeatData } = useContext(InvoiceRepeatContext);

    return (
        <div className="rows --summary row-has-line border-top-border-color-grey">
            <div className="txt">
                <IconPen isHasColor />
                {t('setting:summary')}
            </div>
            <div className="detail">
                {invoiceRepeatData.summary
                    ? t(`jobDetail:${invoiceRepeatData.summary}`)
                    : t('jobDetail:this_invoice_repeats_with_the_job')}
                <br />
            </div>
        </div>
    );
};

export default Summary;

import React, { createContext, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { getMerchant } from 'app/components/payment/utils';
import {
    API_CUSTOMER,
    customerDeleteCardMerchant,
    customerSetCardMerchant,
    getCustomerDetail,
    getCustomerPaymentList,
    getUrlCustomerContact
} from 'app/const/Api';
import { COMMON, DEFAULT_ALL } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { DEFAULT_STATUS_PAYMENT, STATUS_ACH } from 'app/const/Customers';
import { reducer } from 'app/const/Reducer';
import { CUSTOMERS } from 'app/const/Route';
import { LIST_STATUS } from 'app/const/Status';
import { customerCustomFields } from 'app/const/api/V2';
import { PAYMENT_MERCHANT_KEYWORD } from 'app/const/customer/CustomerPayment';
import { CUSTOM_FIELDS_ACTIONS } from 'app/modules/customfields/constant';
import { convertToCustomerField } from 'app/modules/customfields/utils';
import { getListLocation } from 'common/redux/actions/customers/locationAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { combineString } from 'common/utils/ReportUtils';
import { KEY_LOCATION_FILTER_SELECTED } from '../../const';
import { sortPaymentMethod, transformToCustomerType } from '../../utils';
import { CUSTOM_FIELDS_ACTIONS_UPDATE, getDefaultSelectLocation, getDefaultSelectUnit } from '../constant';
import {
    _handelSortContacts,
    _handleCombineUnits,
    _handleConvertTags,
    _handleSortLocation,
    _handleUpdateMultipleLocations,
    utilsDeleteUnit,
    utilsUpdateUnit
} from './utils';

export const CustomerDetailContext = createContext();

const CustomerDetailProvider = ({ children }) => {
    const { t } = useTranslation();
    const customFieldsAddon = useSelector(({ auth }) => auth.user.settings.addons.custom_fields);
    const { STRIPE, SQUARE, BANK_ACCOUNT } = PAYMENT_MERCHANT_KEYWORD;
    const DEFAULT_IDS_DEFAULT_PAYMENT = {
        [STRIPE]: null,
        [SQUARE]: null
    };

    const dispatch = useDispatch();
    const { id: customerId } = useParams();
    const history = useHistory();
    const refUnits = useRef([]);
    const [state, dispatchState] = useReducer(reducer, {
        customer: {
            id: '',
            avatar: '',
            first_name: '',
            last_name: '',
            email: '',
            status: 1,
            account_number: '',
            company: '',
            qb_id: null,
            phones: [],
            source: null,
            balance: '',
            credits: '',
            hide_total_balance: 0,
            tags: [],
            merchant_customer_id: {},
            sending: { email: 1, sms: 0, email_sms: 0 }
        },
        isLoading: true,
        locationSelected: getDefaultSelectLocation(),
        tagsUpdate: null,
        location_ids: null,
        locations: [],
        units: [],
        customFields: [],
        unitSelected: null,
        contacts: null,
        reloadCustomerPage: 0,
        payments: [],
        idsDefault: { ...DEFAULT_IDS_DEFAULT_PAYMENT },
        isPaymentLoaded: false
    });
    // eslint-disable-next-line no-undef
    global.customerDetail = state.customer;

    useEffect(() => {
        _handleGetPayments();
        _handleGetContacts();
        _getCustomerDetail();
        _handleGetLocations();
        _handleGetCustomFields();
        // eslint-disable-next-line no-undef
        return () => (global.customerDetail = null);
    }, [customerId]);

    const _handleGetCustomFields = () => {
        if (!customFieldsAddon) return;
        const _handleSuccess = ({ data = [] }) => {
            dispatchState((prevState) => ({ ...prevState, customFields: data }));
        };
        const _handleFail = () => {
            dispatchState((prevState) => ({ ...prevState, customFields: [] }));
        };
        clientQuery(customerCustomFields(customerId), { method: 'GET' }, _handleSuccess, _handleFail);
    };

    const _actionsCustomField = (type, data) => {
        switch (type) {
            case CUSTOM_FIELDS_ACTIONS.MULTIPLE_ADD:
                dispatchState((prevState) => {
                    const newCustomFields = data.map((item) =>
                        convertToCustomerField({
                            field: item.field,
                            value: { label: item.field.label, value: item.value }
                        })
                    );
                    return { ...prevState, customFields: [...prevState.customFields, ...newCustomFields] };
                });
                break;
            // Not handle case add for now because it's not used
            case CUSTOM_FIELDS_ACTIONS.MULTIPLE_HANDLE:
                dispatchState((prevState) => {
                    const customFields = [...prevState.customFields];

                    data[CUSTOM_FIELDS_ACTIONS_UPDATE.DELETE].forEach((id) => {
                        const index = customFields.findIndex((field) => field.id === id);
                        if (index >= 0) customFields.splice(index, 1);
                    });
                    data[CUSTOM_FIELDS_ACTIONS_UPDATE.UPDATE].forEach((item) => {
                        const index = customFields.findIndex((field) => field.id === (item.id || item.field_id));
                        if (index >= 0) customFields[index]['value'] = item.value;
                    });

                    return { ...prevState, customFields };
                });
                break;
            case CUSTOM_FIELDS_ACTIONS.EDIT:
                dispatchState((prevState) => {
                    const newData = prevState.customFields.map((item) => {
                        if (item.id === data.id) return { ...item, value: data.value };
                        return item;
                    });
                    return { ...prevState, customFields: newData };
                });
                break;
            case CUSTOM_FIELDS_ACTIONS.DELETE:
                dispatchState((prevState) => ({
                    ...prevState,
                    customFields: prevState.customFields.filter((item) => item.id !== data.id)
                }));
                break;
            default:
                throw new Error(t('customers:invalid_action_for_custom_fields'));
        }
    };

    const _handleGetContacts = () => {
        const _handleGetSuccess = ({ data = [] }) => {
            dispatchState((prevState) => ({
                ...prevState,
                contacts: _handelSortContacts(
                    data.map((contact) => ({
                        id: contact.id,
                        customer: transformToCustomerType(contact),
                        email: contact.email,
                        phones: contact.phones,
                        title: contact.title || ''
                    }))
                )
            }));
        };
        const _handleGetFail = () => {
            dispatchState((prevState) => ({ ...prevState, contacts: [] }));
        };
        clientQuery(
            getUrlCustomerContact(customerId),
            { data: { inc: 'customer' }, method: 'GET' },
            _handleGetSuccess,
            _handleGetFail
        );
    };

    const _handleUpdateContacts = (contacts) => {
        dispatchState((prevState) => ({ ...prevState, contacts }));
    };

    const _handleUpdateContact = (contact, type) => {
        switch (type) {
            case 'create':
                dispatchState((prevState) => ({
                    ...prevState,
                    contacts: _handelSortContacts([...(prevState.contacts || []), contact])
                }));
                break;
            case 'update':
                dispatchState((prevState) => {
                    const newListContact = prevState.contacts.map((item) => {
                        if (item.id === contact.id) return contact;
                        return item;
                    });
                    return { ...prevState, contacts: _handelSortContacts(newListContact) || [] };
                });
                break;
            case 'delete':
                // In this case contact is ids of contact delete
                dispatchState((prevState) => {
                    return {
                        ...prevState,
                        contacts: _handelSortContacts([
                            ...(prevState?.contacts?.filter((item) => !contact?.includes(item?.id)) || [])
                        ])
                    };
                });
                break;
            default:
                break;
        }
    };

    const _handleGetPayments = () => {
        if (customerId) {
            const _handleGetSuccess = ({ data }) => {
                const idsDefault = { ...DEFAULT_IDS_DEFAULT_PAYMENT };
                data?.forEach((item) => {
                    if (item.name === PAYMENT_MERCHANT_KEYWORD.BANK_ACCOUNT) {
                        item.name = PAYMENT_MERCHANT_KEYWORD.STRIPE;
                        item.isACH = true;
                    }
                    if (item.is_default) idsDefault[item.name] = item.account_id;
                });

                dispatchState({ payments: sortPaymentMethod(data || []), idsDefault, isPaymentLoaded: true });
            };

            clientQuery(getCustomerPaymentList(customerId), { data: {}, method: 'GET' }, _handleGetSuccess);
        }
    };

    const _handleGetLocations = () => {
        // Data from local storage
        const params = getLocalStorage(KEY_LOCATION_FILTER_SELECTED) || {};
        const isSameCustomer = params.customer_id === customerId;
        if (isSameCustomer) {
            dispatchState({
                location_ids: params.location_ids || DEFAULT_ALL,
                locationSelected: params.location || getDefaultSelectLocation(),
                unitSelected: params.unit || getDefaultSelectUnit()
            });
        } else {
            dispatchState({
                location_ids: DEFAULT_ALL,
                locationSelected: getDefaultSelectLocation(),
                unitSelected: getDefaultSelectUnit()
            });
            removeLocalStorage(KEY_LOCATION_FILTER_SELECTED);
        }

        const _handleSuccess = ({ data = [], units = [] }) => {
            const finalUnits = _handleCombineUnits(units) || [];
            const newState = {
                locations: data.map((location) => ({
                    ...location,
                    address: combineString([
                        location.street1,
                        location.street2,
                        location.city,
                        location.state,
                        location.zip
                    ])
                })),
                units: finalUnits
            };
            // Store units list to ref
            refUnits.current = finalUnits;

            if (!isSameCustomer) {
                newState['locationSelected'] = getDefaultSelectLocation();
            } else {
                // Check if unit is deleted remove it from local storage
                const localUnitIds = params?.unit?.id || DEFAULT_ALL;
                const unitInList =
                    localUnitIds === DEFAULT_ALL
                        ? false
                        : units.some((item) => localUnitIds?.includes(item.id)) || false;
                if (!unitInList) {
                    const params = getLocalStorage(KEY_LOCATION_FILTER_SELECTED) || {};
                    setLocalStorage(KEY_LOCATION_FILTER_SELECTED, { ...params, unit: null });
                    newState['unitSelected'] = getDefaultSelectUnit();
                }

                if (params.location_ids !== DEFAULT_ALL) {
                    const newUnits = units.filter((item) => item.location_id === params.location_ids) || [];
                    newState['units'] = _handleCombineUnits(newUnits) || [];
                }
            }

            dispatchState(newState);
        };

        const _handleFail = () => {
            dispatchState({
                locations: [],
                units: [],
                locationSelected: getDefaultSelectLocation(),
                location_ids: DEFAULT_ALL,
                unitSelected: getDefaultSelectUnit(),
                isLoading: false
            });
        };

        dispatch(
            getListLocation(
                { customer_id: customerId, order: COMMON.SERVICE_ADDRESS, inc: 'tags,units' },
                _handleSuccess,
                _handleFail
            )
        );
    };

    const _getCustomerDetail = () => {
        const _success = ({ data }) => {
            dispatchState({
                customer: { ...data, full_name: `${data.first_name} ${data.last_name}` },
                isLoading: false
            });
        };
        const _failed = ({ message }) => {
            history.replace(addBranchPath(CUSTOMERS), { error: message });
        };

        clientQuery(getCustomerDetail(customerId), { data: { fields: 'tag' }, method: 'GET' }, _success, _failed);
    };

    const _handleUpdateCustomer = (customer) =>
        dispatchState((prevState) => ({ ...prevState, customer: { ...prevState.customer, ...customer } }));

    const _handleSetValueLocal = (key, value) => {
        const params = getLocalStorage(KEY_LOCATION_FILTER_SELECTED) || {};
        const { locationSelected, locations } = state;
        const locationDefault = locationSelected?.id === DEFAULT_ALL ? locations[0] : locationSelected;
        setLocalStorage(KEY_LOCATION_FILTER_SELECTED, {
            ...params,
            location_ids: params.location_ids || DEFAULT_ALL,
            locationSelected: params.location || locationDefault,
            customer_id: customerId,
            [key]: value
        });
    };

    const _handleUpdateUnit = (unit) => {
        _handleSetValueLocal('unit', unit);
        dispatchState({ unitSelected: unit });
    };

    const _handleUpdateUnitList = (unit, type) => {
        switch (type) {
            case 'create':
                dispatchState((prevState) => {
                    const newState = { ...prevState };
                    if (unit.location_id === prevState.location_ids || prevState.location_ids === DEFAULT_ALL) {
                        const { units, selected } = _handleCombineUnits(
                            [...(newState['units'] || []), unit],
                            newState.unitSelected
                        );
                        newState['units'] = units;
                        newState['unitSelected'] = selected;
                        _handleSetValueLocal('unit', selected);
                    }

                    refUnits.current = _handleCombineUnits([...refUnits.current, unit]);
                    return newState;
                });
                break;
            case 'create_from_location':
                dispatchState((prevState) => {
                    const newState = { ...prevState };
                    if (unit[0]?.location_id === prevState.location_ids || prevState.location_ids === DEFAULT_ALL) {
                        const { units, selected } = _handleCombineUnits(
                            [...newState['units'], ...unit],
                            newState.unitSelected
                        );
                        newState['units'] = units;
                        newState['unitSelected'] = selected;
                        _handleSetValueLocal('unit', selected);
                    }
                    refUnits.current = _handleCombineUnits([...refUnits.current, ...unit]);
                    return newState;
                });
                break;
            case 'update':
                dispatchState((prevState) => {
                    // Update storage value
                    refUnits.current = utilsUpdateUnit({ units: refUnits.current, unit });

                    const newState = { ...prevState };
                    const { units, selected } = utilsUpdateUnit({
                        unit,
                        units: prevState.units,
                        prevSelected: { ...(newState['unitSelected'] || {}) }
                    });

                    if (selected) {
                        newState['unitSelected'] = selected;
                        _handleSetValueLocal('unit', selected);
                    }
                    newState['units'] = units;
                    return newState;
                });
                break;
            case 'delete_multiple':
                // For this case unit is array contain unit_id
                dispatchState((prevState) => {
                    const newState = { ...prevState };
                    if (unit.some((item) => item === prevState['unitSelected']?.id)) {
                        newState['unitSelected'] = getDefaultSelectUnit();
                        _handleSetValueLocal('unit', getDefaultSelectUnit());
                    }
                    newState['units'] = newState['units'].filter(({ id }) => !unit.includes(id));
                    refUnits.current = refUnits.current.filter(({ id }) => !unit.includes(id));
                    return newState;
                });
                break;
            case 'delete':
                dispatchState((prevState) => {
                    const newState = { ...prevState };
                    const { shouldRemoveSelected, newSelectedIds, units } = utilsDeleteUnit({
                        unit,
                        units: newState['units'],
                        justGetList: false,
                        prevIdSelected: prevState?.unitSelected?.id?.toString()?.split(',') || []
                    });
                    if (shouldRemoveSelected) {
                        newState['unitSelected'] = getDefaultSelectUnit();
                        _handleSetValueLocal('unit', getDefaultSelectUnit());
                    } else if (newSelectedIds) {
                        const newSelected = { ...(prevState?.unitSelected || {}), id: newSelectedIds };
                        newState['unitSelected'] = newSelected;
                        _handleSetValueLocal('unit', newSelected);
                    }
                    newState['units'] = units;
                    refUnits.current = utilsDeleteUnit({ units: refUnits.current, unit });
                    return newState;
                });
                break;
            default:
                break;
        }
    };

    const _handleUpdateLocationTags = ({ tags, id }) => {
        dispatchState((prevState) => {
            return {
                ...prevState,
                locations: [...prevState.locations].map((item) => (item.id === id ? { ...item, tags } : item)),
                tagsUpdate: { tags: tags.map((item) => ({ id: item, name: item })), locationId: id }
            };
        });
    };

    const _handleUpdateLocation = (location) => {
        let tagsUpdate = null;
        const finalLocation = { ...(state.locationSelected || {}), ...location };

        if (location['tags']) {
            tagsUpdate = _handleConvertTags(location['tags']);
            finalLocation['tags'] = tagsUpdate;
        }

        dispatchState((prevState) => {
            const result = { ...prevState, locationSelected: finalLocation };
            const prevUnits = refUnits.current || [];
            const newLocationIds = finalLocation.id;
            const isSelectAllLocation = newLocationIds === DEFAULT_ALL;
            result['location_ids'] = newLocationIds;
            result['units'] = !isSelectAllLocation
                ? prevUnits.filter((item) => `${item.location_id}` === `${finalLocation.id}`)
                : prevUnits;
            if (result['unitSelected'] !== newLocationIds) {
                result['unitSelected'] = getDefaultSelectUnit();
                _handleSetValueLocal('unit', getDefaultSelectUnit());
            }
            return result;
        });
    };

    const _handleUpdateLocationList = (dataLocation, type, shouldReload = false) => {
        const newLocations = [...(state.locations || [])];

        switch (type) {
            case 'delete':
            case 'un_delete':
                _handleGetLocations();
                break;
            case 'update_single':
                const newState = {
                    locations: _handleSortLocation(_handleUpdateMultipleLocations([dataLocation], newLocations))
                };
                if (dataLocation.id === state.locationSelected?.id) {
                    const finalLocation = { ...(state.locationSelected || {}), ...dataLocation };
                    if (dataLocation['tags']) {
                        const tagsUpdate = _handleConvertTags(dataLocation['tags']);
                        finalLocation['tags'] = tagsUpdate;
                        const params = getLocalStorage(KEY_LOCATION_FILTER_SELECTED) || {};
                        setLocalStorage(KEY_LOCATION_FILTER_SELECTED, {
                            ...params,
                            location: finalLocation
                        });
                    }
                    newState['locationSelected'] = finalLocation;
                }
                if (shouldReload) newState['reloadCustomerPage'] = Date.now();
                dispatchState(newState);
                break;
            case 'update':
                dispatchState({
                    locations: _handleSortLocation(_handleUpdateMultipleLocations(dataLocation, newLocations))
                });
                break;
            case 'create':
                dispatchState({ locations: _handleSortLocation([...newLocations, dataLocation]) });
                break;
            default:
                break;
        }
    };

    const _handleReloadCustomer = (value = Date.now()) => dispatchState({ reloadCustomerPage: value });

    const _handleDeleteCustomer = (callbackFailed = () => {}) => {
        const _handleDeleteSuccess = ({ error, message }) => {
            if (error) return callbackFailed(error.message);
            history.replace(addBranchPath(CUSTOMERS), { error: message, type: LIST_STATUS.SUCCESS });
        };
        const _handleDeleteFailed = ({ message }) => callbackFailed(message);
        clientQuery(
            API_CUSTOMER,
            { data: { ids: [customerId] }, method: 'DELETE' },
            _handleDeleteSuccess,
            _handleDeleteFailed
        );
    };

    const _handleUpdatePayment = (payments) => {
        dispatchState({ payments: [...state.payments, ...payments] });
    };

    const _handleAddPayment = (payment) => {
        const isArray = Array.isArray(payment);
        const { is_default, name: paymentName } = isArray ? payment[0] : payment;

        dispatchState((prev) => {
            const newState = { idsDefault: { ...prev.idsDefault } };
            newState['payments'] = isArray ? [...prev.payments, ...payment] : [...prev.payments, payment];

            if (!prev.idsDefault[paymentName] && !isArray && !!is_default) {
                newState['idsDefault'][paymentName] = payment.account_id;
                if (paymentName === STRIPE)
                    newState['customer'] = _newPaymentCard({ customer: prev['customer'], ...payment });
            }
            if (!prev.idsDefault[paymentName] && isArray) {
                const paymentDefault = payment.find((item) => item.is_default);
                if (paymentDefault) {
                    const { name: defaultName, account_id: defaultAccountId } = paymentDefault;
                    newState['idsDefault'][defaultName] = defaultAccountId;
                    if (defaultName === STRIPE)
                        newState['customer'] = _newPaymentCard({ customer: prev['customer'], ...paymentDefault });
                }
            }

            return {
                ...prev,
                payments: sortPaymentMethod(newState.payments),
                idsDefault: newState.idsDefault,
                customer: newState.customer || prev.customer
            };
        });
    };

    const _handleDeletePayment = ({ id, keyword, name }, callback, callbackFail, callBackClearStatus) => {
        if (callBackClearStatus) callBackClearStatus();
        const _handleDeleteSuccess = ({ data: { account_id } }) => {
            if (typeof callback === 'function') callback();

            dispatchState((prev) => {
                const result = { ...prev };
                const nameCard = name.toLowerCase();
                const currentMerchantIds = prev.customer.merchant_customer_id || {};

                result['payments'] = result.payments.filter((item) => item.id !== id);
                result['idsDefault'][name] = account_id;
                result['payments'] = sortPaymentMethod(
                    result['payments'].map((card) => {
                        if (card.account_id === account_id) {
                            if (name === STRIPE)
                                result['customer'] = _newPaymentCard({ customer: result['customer'], ...card });
                            return { ...card, is_default: DEFAULT_STATUS_PAYMENT.DEFAULT };
                        }
                        return card;
                    })
                );
                if (name === STRIPE && !account_id)
                    result['customer'] = _newPaymentCard({ customer: result['customer'], isRemove: true });
                // if card deleted, remove account_customer_id
                if (name === SQUARE) {
                    if (!result['payments'].find((item) => item.name.toLowerCase() === nameCard)) {
                        // TODO: REMOVE WHEN RELEASE NEW UI CUSTOMER DETAIL-ACCOUNT
                        const newCustomer = {
                            ...result.customer,
                            merchant_customer_id: { ...currentMerchantIds, [nameCard]: false }
                        };
                        result['customer'] = newCustomer;
                    }
                }

                return { ...result };
            });
        };

        const _handleDeleteFail = (response) => {
            if (typeof callbackFail === 'function') callbackFail(response);
        };

        clientQuery(
            customerDeleteCardMerchant(id),
            { data: { keyword, merchant: getMerchant(name) }, method: 'DELETE' },
            _handleDeleteSuccess,
            _handleDeleteFail
        );
    };

    const _handleDeleteAllStripe = (type) => {
        const listType = type === STRIPE ? [type, BANK_ACCOUNT] : [type];
        dispatchState((prev) => ({
            ...prev,
            payments: prev.payments.filter((item) => !listType.includes(item.name)),
            idsDefault: { ...prev.idsDefault, [type]: null },
            customer: _newPaymentCard({ customer: prev.customer, isRemove: true, type })
        }));
    };

    const _handleUpdateDefault = (item, isDefault = false) => {
        if (isDefault) return;
        const { name, id, account_id } = item;
        const newState = { ...state };
        const oldDefault = newState['idsDefault'][name];
        newState['idsDefault'][name] = account_id;
        newState['payments'] = sortPaymentMethod(
            newState['payments'].map((card) => {
                if (`${card.id}` === `${id}`) return { ...card, is_default: DEFAULT_STATUS_PAYMENT.DEFAULT };
                if (`${card.account_id}` === `${oldDefault}`)
                    return { ...card, is_default: DEFAULT_STATUS_PAYMENT.NOT_DEFAULT };
                return card;
            })
        );
        if (name === STRIPE) newState['customer'] = _newPaymentCard({ customer: newState['customer'], ...item });

        dispatchState(newState);
        clientQuery(customerSetCardMerchant(customerId, id), {
            data: { merchant: getMerchant(name) },
            method: 'PUT'
        });
    };

    const _handleSortPayment = () => {
        const listPayments = [...state.payments];
        const newListPayments = [];

        listPayments.forEach((payment) => {
            const isDefault = state.idsDefault[payment.name] === payment.account_id;

            if (!isDefault) {
                newListPayments.push({ ...payment, is_default: DEFAULT_STATUS_PAYMENT.NOT_DEFAULT });
            } else {
                newListPayments.unshift({ ...payment, is_default: DEFAULT_STATUS_PAYMENT.DEFAULT });
            }
        });

        dispatchState({ payments: newListPayments });
    };

    const _handleVerifyAchSuccess = ({ id }) => {
        dispatchState((prev) => ({
            ...prev,
            payments: prev.payments.map((item) => {
                if (item.id === id) {
                    return { ...item, status: STATUS_ACH.VERIFY };
                }
                return item;
            })
        }));
    };

    const _newPaymentCard = ({
        customer = {},
        account_number = '',
        account_type = '',
        exp_month = '',
        exp_year = '',
        name = '',
        isACH = false,
        isRemove = false,
        type = ''
    }) => {
        return {
            ...customer,
            payment_card: isRemove
                ? type === SQUARE
                    ? customer.payment_card
                    : null
                : {
                      account_number,
                      account_type: isACH ? null : account_type,
                      exp_month,
                      exp_year,
                      name
                  }
        };
    };

    return (
        <CustomerDetailContext.Provider
            value={{
                ...state,
                _handleUpdateCustomer,
                _handleDeleteCustomer,
                _handleUpdateLocation,
                _handleUpdateLocationList,
                _handleUpdateLocationTags,
                _handleReloadCustomer,
                _handleAddPayment,
                _handleUpdatePayment,
                _handleDeletePayment,
                _handleUpdateDefault,
                _handleDeleteAllStripe,
                _handleSortPayment,
                _handleUpdateUnit,
                _handleUpdateUnitList,
                _handleUpdateContacts,
                _handleUpdateContact,
                _handleVerifyAchSuccess,
                _actionsCustomField
            }}
        >
            {children}
        </CustomerDetailContext.Provider>
    );
};

export default CustomerDetailProvider;

import loadable from '@loadable/component';
import classNames from 'classnames';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useVirtual } from 'react-virtual';

import { GdGridRowContent, GdGridRowHeader } from 'app/components/grid/GdGridRow';
import { ColumnCheckBox, ColumnCheckBoxSingle, ColumnDefault } from 'app/components/grid/GridColumn';
import GridAssignBooking from 'app/components/grid/components/GridAssignBooking';
import { GD_HEADER_HEIGHT, GD_ROW_TITLE_HEIGHT } from 'app/const/App';
import { HEIGHT_TO_SCROLL } from 'app/const/Reports';
import { CUSTOMER_INACTIVE } from 'app/modules/customer/const';
import { getListStaffs, getNestedValue, isScrollToEndBottom } from 'common/utils/FunctionUtils';
import GdTableWithSubRows from './GdTableWithSubRows';
import ColumnQuickBookSync from './components/ColumnQuickBookSync';
import GridEmpty from './components/GridEmpty';
import GridLoading from './components/GridLoading';
import GridLoadmore from './components/GridLoadmore';

const Tooltip = loadable(() => import('app/modules/inbox/opportunity/statusChanges/Tooltip'));

const GdGridView = ({
    isEmptyFlat = false,
    msgEmpty = null,
    isHasDropdown = false,
    isLoading = false,
    isShowSubRows = false,
    columns = [],
    header = {},
    content = [],
    locations = [],
    contentConfig = {},
    fileTranslation = '',
    classTable = '',
    classTableContent = '',
    title = '',
    handleClick = () => {},
    handleClickHeader = () => {},
    showCheckBox = false,
    shouldCheckDefaultRow = false,
    checkedItems = [],
    typeQuickBook,
    onChangeStateCheckedItems = () => {},
    onRowClick = () => {},
    isSelectRow = false,
    quickbookActive = false,
    assignBookingPopup = false,
    onSearchQB = () => {},
    onOpenLocation = () => {},
    onCancelSubscription = () => {},
    onEditSubscription = () => {},
    onOpenJob = () => {},
    onOpenSubscription = () => {},
    onOpenInvoice = () => {},
    onClickItem = () => {},
    onNameClick = () => {},
    onEdit = null,
    rowTotal = () => {},
    showNumberQB = true,
    onChangeStateCheckedOnlyItems,
    onChangeInput = () => {},
    onSelectAssign = () => {},
    onSelectAddress = () => {},
    showCheckboxSingle = false,
    isScroll = false,
    keyGetId = false,
    offsetHeightChild = 0,
    parentRefProps,
    styleQBSync = '',
    isDuplicateInvoiceQB = false,
    onHandleActionGroups = () => {},
    onHandleSaveTags = () => {},
    onScrollToEnd = () => {},
    onChangeSwitchOppPermission = () => {},
    isLoadmore = false,
    isHaveChart = false,
    showRowTotalOnTop = false,
    overscanItems = 3,
    isShowHeader = true,
    tableId = '',
    isNotShowMsgColEmpty = false,
    isHasCrews = false,
    isShowToolTip = false
}) => {
    const { t } = useTranslation(['report']);
    const { users, crew } = useSelector(({ companyUsers }) => companyUsers);
    const companyUsers = getListStaffs({ users, crew, isHasCrews });
    const currency = useSelector(({ auth }) => auth?.user?.settings?.currency);
    const parentRef = useRef();
    const contentRef = useRef();
    const refTooltip = useRef(null);

    // useEffect(() => {
    //     const parentHeight = parentRef.current.clientHeight || 0;
    //     const contentHeight = contentRef.current?.clientHeight || 0;
    //     if (autoLoad && !isHaveChart && contentHeight <= parentHeight && !isLoading && !isLoadmore) {
    //         onScrollToEnd();
    //     }
    // }, [content]);

    const totalItems = content?.length || 0;
    const isEmptyData = !totalItems || (!columns.length && !isNotShowMsgColEmpty) || !header || !contentConfig;
    const _resize = useCallback(
        (ref) => {
            return { height: ref.current?.offsetHeight || 0, width: 0 };
        },
        [offsetHeightChild]
    );

    const rowVirtualizer = !isScroll
        ? useVirtual({
              size: content.length,
              parentRef: parentRefProps || parentRef,
              paddingStart: offsetHeightChild,
              paddingEnd: -offsetHeightChild,
              overscan: parentRefProps ? overscanItems : 15,
              useObserver: _resize
          })
        : null;

    function _renderListRowContent() {
        if (isEmptyData) return <GridEmpty msgEmpty={msgEmpty} isFlat={isEmptyFlat} />;

        return rowVirtualizer.virtualItems.map(({ index, start, measureRef }) => {
            const row = content[index];
            const finalRowId = row.id;

            const itemChecked = checkedItems?.ids?.includes(finalRowId) || false;
            const isRowSelected = checkedItems === finalRowId && isSelectRow;
            //Check row is show check box or not
            // const isShowCheckBox = showCheckBox && (typeof row.disabled === 'undefined' || !row.disabled);
            const heightRowTotal = document.querySelector('#row_total')?.offsetHeight || 0;
            const isDisableCheckBox = typeof row.disabled !== 'undefined' && row.disabled;
            const offsetY =
                start +
                (isShowHeader ? GD_HEADER_HEIGHT : 0) +
                (title ? GD_ROW_TITLE_HEIGHT : 0) +
                (showRowTotalOnTop ? heightRowTotal : 0) -
                offsetHeightChild;
            const idRow = `row_${row.id || index.toString()}`;
            const zIndex = isHasDropdown ? totalItems - index + 1 : 0;
            const isBlur = row?.status === CUSTOMER_INACTIVE;
            let isShowCheckBox = true;
            if (shouldCheckDefaultRow) isShowCheckBox = !!row.is_default;
            const isOddItem = index % 2 === 0;

            return (
                <div
                    key={idRow}
                    id={idRow}
                    ref={measureRef}
                    onClick={() => _handleRowClick(finalRowId)}
                    className={classNames('rows', {
                        selected: isRowSelected,
                        'is-inactive': isBlur,
                        '--odd': isOddItem,
                        '--even': !isOddItem
                    })}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        transform: `translateY(${offsetY}px)`,
                        zIndex: zIndex,
                        width: '100%'
                    }}
                >
                    {showCheckBox &&
                        ColumnCheckBox(
                            'col --checkbox',
                            finalRowId,
                            itemChecked,
                            handleOnClickCheckItem,
                            isDisableCheckBox,
                            isShowCheckBox
                        )}

                    {showCheckboxSingle &&
                        ColumnCheckBoxSingle('col --checkbox', finalRowId, itemChecked, handleOnClickCheckSingle)}

                    <GdGridRowContent
                        row={row}
                        idRow={idRow}
                        zIndexRow={zIndex}
                        totalItems={totalItems}
                        columns={columns}
                        locations={locations}
                        contentConfig={contentConfig}
                        companyUsers={companyUsers}
                        typeQuickBook={typeQuickBook}
                        handleClick={handleClick}
                        onEdit={onEdit}
                        onOpenJob={onOpenJob}
                        onOpenInvoice={onOpenInvoice}
                        onOpenLocation={onOpenLocation}
                        onOpenSubscription={onOpenSubscription}
                        onCancelSubscription={onCancelSubscription}
                        onEditSubscription={onEditSubscription}
                        onSearchQB={onSearchQB}
                        onClickItem={onClickItem}
                        onNameClick={onNameClick}
                        onChangeInput={onChangeInput}
                        onSelectAssign={onSelectAssign}
                        onSelectAddress={onSelectAddress}
                        currency={currency}
                        isChecked={isRowSelected}
                        fileTranslation={fileTranslation}
                        isDuplicateInvoiceQB={isDuplicateInvoiceQB}
                        onHandleSaveTags={onHandleSaveTags}
                        handleMouseEnter={_handleMouseEnter}
                        handleMouseLeave={_handleMouseLeave}
                    />

                    {quickbookActive && (
                        <>
                            <ColumnQuickBookSync
                                item={row}
                                typeQuickBook={typeQuickBook}
                                style={styleQBSync}
                                onClick={onSearchQB}
                                isDuplicateInvoiceQB={isDuplicateInvoiceQB}
                                handleMouseEnter={_handleMouseEnter}
                                handleMouseLeave={_handleMouseLeave}
                            />
                            {showNumberQB && renderColumnCustomerQB(row)}
                        </>
                    )}

                    {assignBookingPopup.isActive && !!row.assignable && (
                        <GridAssignBooking
                            urlAPI={assignBookingPopup.urlAPI}
                            callBackAfterAssign={assignBookingPopup.callBackAfterAssign}
                            rowId={finalRowId || index.toString()}
                            rowIndex={index}
                            listSuggestion={row?.customer_list}
                        />
                    )}
                </div>
            );
        });
    }

    function _renderListTable() {
        if (isEmptyData) return <GridEmpty msgEmpty={msgEmpty} isFlat={isEmptyFlat} />;
        if (isShowSubRows)
            return (
                <GdTableWithSubRows
                    data={content || []}
                    checkedItems={checkedItems}
                    keyGetId={keyGetId}
                    showCheckBox={showCheckBox}
                    shouldCheckDefaultRow={shouldCheckDefaultRow}
                    currency={currency}
                    columns={columns}
                    locations={locations}
                    contentConfig={contentConfig}
                    companyUsers={companyUsers}
                    typeQuickBook={typeQuickBook}
                    fileTranslation={fileTranslation}
                    onClickCheckItem={handleOnClickCheckItem}
                    handleClick={handleClick}
                    onOpenJob={onOpenJob}
                    onOpenInvoice={onOpenInvoice}
                    onOpenLocation={onOpenLocation}
                    onOpenSubscription={onOpenSubscription}
                    onCancelSubscription={onCancelSubscription}
                    onEditSubscription={onEditSubscription}
                    onSearchQB={onSearchQB}
                    onClickItem={onClickItem}
                    onNameClick={onNameClick}
                    onChangeInput={onChangeInput}
                    onSelectAssign={onSelectAssign}
                    onSelectAddress={onSelectAddress}
                    assignBookingPopup={assignBookingPopup}
                    onHandleActionGroups={onHandleActionGroups}
                    onHandleSaveTags={onHandleSaveTags}
                    onChangeSwitchOppPermission={onChangeSwitchOppPermission}
                />
            );

        return content.map((rows) => {
            const rowId = keyGetId ? getNestedValue(rows, keyGetId) : rows.id;
            const itemChecked = checkedItems?.ids?.includes(rowId);
            const itemCheckedSingle = checkedItems?.ids === rowId;
            const isDisableCheckBox = !!rows.disabled;
            const isBlur = rows?.status === CUSTOMER_INACTIVE;
            const hasBackgroundStyle = !!rows.is_script;
            let isShowCheckBox = true;
            if (shouldCheckDefaultRow) isShowCheckBox = !!rows.is_default;

            return (
                <div
                    key={rowId}
                    id={rowId}
                    onClick={() => _handleRowClick(rowId)}
                    className={classNames('rows', { 'is-inactive': isBlur, 'bg-white-anti-flash': hasBackgroundStyle })}
                >
                    {showCheckBox &&
                        ColumnCheckBox(
                            'col --checkbox',
                            rowId,
                            itemChecked,
                            handleOnClickCheckItem,
                            isDisableCheckBox,
                            isShowCheckBox
                        )}

                    {showCheckboxSingle &&
                        ColumnCheckBoxSingle('col --checkbox', rowId, itemCheckedSingle, handleOnClickCheckSingle)}

                    <GdGridRowContent
                        row={rows}
                        idRow={rowId}
                        totalItems={totalItems}
                        columns={columns}
                        locations={locations}
                        contentConfig={contentConfig}
                        companyUsers={companyUsers}
                        typeQuickBook={typeQuickBook}
                        handleClick={handleClick}
                        onEdit={onEdit}
                        onOpenJob={onOpenJob}
                        onOpenInvoice={onOpenInvoice}
                        onOpenLocation={onOpenLocation}
                        onOpenSubscription={onOpenSubscription}
                        onCancelSubscription={onCancelSubscription}
                        onEditSubscription={onEditSubscription}
                        onSearchQB={onSearchQB}
                        onClickItem={onClickItem}
                        onNameClick={onNameClick}
                        onChangeInput={onChangeInput}
                        onSelectAssign={onSelectAssign}
                        onSelectAddress={onSelectAddress}
                        currency={currency}
                        isDuplicateInvoiceQB={isDuplicateInvoiceQB}
                        assignBookingPopup={assignBookingPopup}
                        onHandleActionGroups={onHandleActionGroups}
                        fileTranslation={fileTranslation}
                        onHandleSaveTags={onHandleSaveTags}
                        onChangeSwitchOppPermission={onChangeSwitchOppPermission}
                        handleMouseEnter={_handleMouseEnter}
                        handleMouseLeave={_handleMouseLeave}
                    />

                    {quickbookActive && (
                        <>
                            <ColumnQuickBookSync
                                item={rows}
                                typeQuickBook={typeQuickBook}
                                style={styleQBSync}
                                onClick={onSearchQB}
                                isDuplicateInvoiceQB={isDuplicateInvoiceQB}
                                handleMouseEnter={_handleMouseEnter}
                                handleMouseLeave={_handleMouseLeave}
                            />

                            {showNumberQB && renderColumnCustomerQB(rows)}
                        </>
                    )}
                </div>
            );
        });
    }

    function handleOnClickCheckAll() {
        const ids = [];
        if (!totalItems) return false;
        if (!checkedItems.is_check_all) {
            const data = Array.isArray(content) ? content : [];

            data.map((item) => {
                let isShowCheckBoxDefault = true;
                if (shouldCheckDefaultRow) isShowCheckBoxDefault = !!item.is_default;
                //Check row is show check box or not
                const isShowCheckBox = typeof item.disabled === 'undefined' || !item.disabled;
                isShowCheckBox && isShowCheckBoxDefault && ids.push(item.id);
            });
        }

        onChangeStateCheckedItems({
            is_check_all: !checkedItems.is_check_all,
            ids: ids
        });
    }

    function _handleScroll(e) {
        !isHaveChart && isScrollToEndBottom(e.currentTarget, HEIGHT_TO_SCROLL) && !isLoadmore && onScrollToEnd();
    }

    function handleOnClickCheckItem(e, id) {
        const dataLength = Array.isArray(content) ? content.filter((item) => !item.disabled).length : 0;
        let all = [...checkedItems.ids];
        let unChecked = true;

        all = all.filter((item) => {
            if (item == id) {
                unChecked = false;
            } else {
                return item;
            }
        });

        unChecked && all.push(id);

        onChangeStateCheckedItems({
            is_check_all: all.length === dataLength,
            ids: all
        });
    }

    function handleOnClickCheckSingle(e, id) {
        onChangeStateCheckedOnlyItems(id);
    }

    const _handleRowClick = (checkedItems) => {
        if (!isSelectRow) return;
        onRowClick(checkedItems);
    };

    const renderColumnCustomerQB = (row) => {
        const qbId = row.customer_quickbooks?.id;
        if (qbId) return ColumnDefault('col col-sm', qbId);
        return (
            <div className="col col-sm">
                <div className="v2-btn-default --purple ml-n1 px-1" onClick={() => onSearchQB(row)}>
                    {t('common:find')}
                </div>
            </div>
        );
    };

    const renderTitleTable = () => {
        if (!title) return null;
        return <div className="title-material">{title}</div>;
    };

    function _renderCheckboxHeader(loadingState) {
        return ColumnCheckBox(
            `col --checkbox ${!totalItems || loadingState ? 'is-disable' : ''}`,
            'checkbox-header'.concat(checkedItems.ids.length),
            checkedItems.is_check_all,
            handleOnClickCheckAll
        );
    }
    function _renderSyncQBHeader() {
        return (
            <>
                <div className={styleQBSync}>
                    <p className="col-label">{t('report:qb_sync')}</p>
                </div>
                {showNumberQB && (
                    <div className="col col-sm">
                        <p className="col-label">{t('report:customer_qb')}</p>
                    </div>
                )}
            </>
        );
    }

    const renderRowTotal = () => {
        return typeof rowTotal === 'function' && rowTotal({ isHasDropdown, zIndex: totalItems + 2, currency });
    };

    const _displayTooltip = (data) => {
        refTooltip.current?.visible(data);
    };

    const _handleMouseEnter = ({ currentTarget = null }) => {
        const tooltip = currentTarget?.dataset?.tooltip;
        !!tooltip && _displayTooltip({ el: currentTarget, value: tooltip });
    };

    const _handleMouseLeave = () => {
        _displayTooltip(null);
    };

    return (
        <div
            className={classNames('tables', classTable, { 'table-virtual': !isScroll })}
            ref={parentRef}
            tabIndex={0}
            onScroll={_handleScroll}
            onTouchEnd={_handleScroll}
            id={tableId}
        >
            {renderTitleTable()}

            {isShowHeader &&
                columns.length != 0 &&
                (isScroll ? (
                    <div className="rows --fixed --header">
                        {showCheckBox && _renderCheckboxHeader(isLoading)}
                        <GdGridRowHeader
                            row={header}
                            columns={columns}
                            fileTranslation={fileTranslation}
                            handleClickHeader={handleClickHeader}
                            isLoading={isLoading}
                        />

                        {!isLoading && quickbookActive && _renderSyncQBHeader()}
                    </div>
                ) : (
                    <div className="rows --fixed --header" style={{ zIndex: isHasDropdown ? totalItems + 2 : 1 }}>
                        {showCheckBox && _renderCheckboxHeader(isLoading)}

                        <GdGridRowHeader
                            row={header}
                            columns={columns}
                            fileTranslation={fileTranslation}
                            handleClickHeader={handleClickHeader}
                            isLoading={isLoading}
                        />

                        {!isLoading && quickbookActive && _renderSyncQBHeader()}
                    </div>
                ))}

            {showRowTotalOnTop && !isEmptyData && renderRowTotal()}

            {isScroll ? (
                <div ref={contentRef} className={classNames('tables-list', classTableContent)}>
                    {isLoading ? <GridLoading /> : _renderListTable()}
                    {isLoadmore && <GridLoadmore />}
                </div>
            ) : (
                <div
                    className={classNames('tables-list', classTableContent)}
                    ref={contentRef}
                    style={{
                        height: rowVirtualizer.totalSize && !isLoading ? `${rowVirtualizer.totalSize}px` : 'auto'
                    }}
                >
                    {isLoading ? <GridLoading /> : _renderListRowContent()}
                </div>
            )}

            {renderRowTotal()}
            {isShowToolTip ? <Tooltip ref={refTooltip} /> : null}
        </div>
    );
};

export default GdGridView;

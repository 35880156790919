import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export default function ServicesPath() {
    const isFirstTimeSchedules = useSelector(({ schedules }) => schedules.isFirstTime);
    const { schedules: finalSchedules } = useSelector(({ calendar }) => calendar);
    const history = useHistory();

    useEffect(() => {
        !isFirstTimeSchedules && addParamsToUrl({ schedules: finalSchedules.map((item) => item.id).join(',') });
    }, [finalSchedules]);

    const addParamsToUrl = (params) => {
        const urlSearchParams = new URLSearchParams(location.search);

        Object.entries(params).forEach(([key, value]) => {
            urlSearchParams.set(key, value);
        });

        history.replace(`${location.pathname}?${urlSearchParams.toString()}`);
    };

    return null;
}

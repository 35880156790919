import React, { forwardRef, useContext, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { timeConvert } from 'app/modules/jobdetail/const/Utils';
import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';
import JobLength from 'app/modules/jobdetail/tabs/settingschedule/time/JobLength';
import IconTime from 'assets/icon/IconTime';

const ServiceTime = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const { repeatSettings } = useContext(DataRepeatContext);
    const jobLength = timeConvert(repeatSettings.length);

    const refJobLength = useRef(null);

    useImperativeHandle(ref, () => ({
        getValue: () => {
            return {
                jobLength: refJobLength.current._getTime()
            };
        }
    }));

    return (
        <div className="rows">
            <div className="txt">
                <IconTime isTimeJob />
                <span className="txt-ellipsis">{t('setting:length')}</span>
            </div>
            <div className="details detail-time">
                <JobLength isShowTitle={false} ref={refJobLength} minute={jobLength.minute} hour={jobLength.hour} />
            </div>
        </div>
    );
});

export default ServiceTime;

import React from 'react';

export default function TabConsoleSync({ data }) {
    function _renderListData() {
        return data.map((item) => {
            return (
                <p className="screen-sync__history" key={item.entity_id}>
                    {item.log}
                </p>
            );
        });
    }
    return (
        <div className="tab-content-active">
            <div className="screen-sync scrolls">{_renderListData()}</div>
        </div>
    );
}

import React from 'react';
import { useTranslation } from 'react-i18next';

import TabSwitcher from 'app/components/tabs/TabSwitcher';
import { BUTTON_SHAPE, TAB_BUTTON_SHAPE } from '../constants';

const ItemButtonShape = ({ buttonShape = '', onChangeValue = () => {} }) => {
    const { t } = useTranslation();
    return (
        <div className="line">
            <span className="txt">{t('addons:button_shape')}</span>
            <div className="fields">
                <TabSwitcher
                    wrapperClassName="tabs"
                    listTabs={TAB_BUTTON_SHAPE}
                    activeTab={buttonShape}
                    fileTranslation="addons"
                    onChange={(value) => onChangeValue({ value, key: BUTTON_SHAPE })}
                />
            </div>
        </div>
    );
};

export default ItemButtonShape;

import React, { Fragment, useEffect, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectDate } from 'common/redux/actions/calendar';
import { clientQuery } from 'common/utils/ApiUtils';
import { CALENDAR_STORE } from 'app/const/Api';
import IconSingleStep from 'assets/icon/IconSingleStep';
import { CALENDAR_MODES } from 'app/modules/calendar/const';
import { convertISOTodate, convertTimeToISO } from 'common/utils/DateUtils';
import { useTranslation } from 'react-i18next';
import TooltipPopper from 'app/components/tooltip/TooltipPopper';
import { renderDatePickerDayName } from 'common/utils/FunctionUtils';
import i18n from 'assets/i18n';

const InLineDatePicker = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation(['calendar']);

    const {
        date: dateStore,
        view: finalCalendarView,
        calendar_open: isVisibleCalendar = false
    } = useSelector(({ calendar }) => calendar);
    const { start, end } = useSelector(({ inlineCalendarReducer }) => inlineCalendarReducer);

    const finaleDate = useMemo(() => convertISOTodate(moment(dateStore).utc()), [dateStore]);
    const finaleStart = useMemo(() => convertISOTodate(moment(start).utc()), [start]);
    const finaleEnd = useMemo(() => convertISOTodate(moment(end).utc().subtract(1, 'd')), [end]);

    useEffect(() => {
        const elm = document.getElementById('calendar_sidebar');

        if (isVisibleCalendar) {
            elm && elm.classList.add('is-show-minicalendar');
        } else {
            elm && elm.classList.remove('is-show-minicalendar');
        }
    }, [isVisibleCalendar]);

    function _toggleCalendar() {
        const finalValue = isVisibleCalendar ? 0 : 1;

        dispatch(updateSelectDate({ calendar_open: finalValue }));

        clientQuery(CALENDAR_STORE, {
            data: { type: 14, value: finalValue },
            method: 'PUT'
        });
    }

    function _onChangeDate(value) {
        const viewsCheckRange = [
            CALENDAR_MODES.AGENDA_WEEK,
            CALENDAR_MODES.AGENDA_2_WEEK,
            CALENDAR_MODES.AGENDA_3_WEEK,
            CALENDAR_MODES.AGENDA_4_WEEK,
            CALENDAR_MODES.DAY_GRID_MONTH
        ];
        const newDate = moment(value).utc().unix();

        if (moment.unix(newDate).format('MM-DD-YYYY') === moment(dateStore).utc().format('MM-DD-YYYY')) {
            return false;
        }

        if (
            viewsCheckRange.includes(finalCalendarView) &&
            moment(start).utc().unix() <= newDate &&
            newDate <= moment(end).utc().subtract(1, 'd').unix()
        ) {
            // case select date in range
            // skip this action becasuse it inside range
        } else {
            dispatch(updateSelectDate({ date: convertTimeToISO(moment(value)) }));
        }

        // clientQuery(CALENDAR_STORE, {
        //     data: { type: 3, value: moment(value).toString() },
        //     method: 'PUT'
        // });
    }

    return (
        <div className="monthly-calendar">
            {!!isVisibleCalendar && (
                <DatePicker
                    inline
                    dateFormat="MM-DD-YYYY"
                    startDate={finaleStart}
                    endDate={finaleEnd}
                    renderCustomHeader={({
                        date,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled
                    }) => {
                        return (
                            <Fragment>
                                <div className="react-datepicker">
                                    <div className="react-datepicker__month-container p-0">
                                        <div className="react-datepicker__header">
                                            <div className="datepicker-header-custom border-none mx-0">
                                                <div
                                                    onClick={decreaseMonth}
                                                    disabled={prevMonthButtonDisabled}
                                                    className="btn-navigation --previous v2-btn-default has-bg-grey --icon-lg"
                                                >
                                                    <IconSingleStep isPrev />
                                                </div>

                                                <p className="text-capitalize">
                                                    {moment(date).locale(i18n.language).format('MMMM YYYY')}
                                                </p>

                                                <div
                                                    onClick={increaseMonth}
                                                    disabled={nextMonthButtonDisabled}
                                                    className="btn-navigation --next v2-btn-default has-bg-grey --icon-lg"
                                                >
                                                    <IconSingleStep />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {renderDatePickerDayName()}
                                </div>
                            </Fragment>
                        );
                    }}
                    selected={finaleDate}
                    onSelect={(date) => _onChangeDate(date)}
                />
            )}
            <TooltipPopper
                onClick={_toggleCalendar}
                className={`monthly-calendar__minimize tooltip`}
                tooltipText={!!isVisibleCalendar ? t('calendar:hide_calendar') : t('calendar:show_calendar')}
            ></TooltipPopper>
        </div>
    );
};

export default InLineDatePicker;

import React, { useEffect, useRef, useState } from 'react';
import { TABS } from 'app/modules/calendar/const/SideBar';
import TabJobList from 'app/modules/calendar/components/sidebar/components/joblist';
import TabTaskList from 'app/modules/calendar/components/sidebar/components/tasks';
import WorkPoolList from 'app/modules/calendar/components/sidebar/components/workpool';
import { useSelector } from 'react-redux';
import IconWorkOrder from 'assets/icon/IconWorkOrder';
import IconTask from 'assets/icon/IconTask';
import IconFolder from 'assets/icon/IconFolder';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { checkPermission } from 'common/utils/PermissionUtils';
import { PERMISSIONS } from 'app/const/Permissions';
import TooltipPopper from 'app/components/tooltip/TooltipPopper';

const SlideBarContent = () => {
    const { t } = useTranslation(['calendar']);
    const selectCustomer = useSelector((state) => state.jobListReducer.customer);
    const permissionsList = useSelector(({ auth }) => auth.user.permissions.enabled || []);
    const isPermissionWorkPool = checkPermission(permissionsList, PERMISSIONS.showWorkpool);

    const [currentTab, setCurrentTab] = useState(isPermissionWorkPool ? TABS.WORK_POOL : TABS.JOB_LIST);
    const isFirstTime = useRef(true);

    const activeWorkPool = currentTab === TABS.WORK_POOL;
    const activeTaskList = currentTab === TABS.TASKS;
    const activeJobList = currentTab === TABS.JOB_LIST;

    useEffect(() => {
        if (selectCustomer && !activeJobList) {
            isFirstTime.current = false;
            setCurrentTab(TABS.JOB_LIST);
        }
    }, [selectCustomer]);

    function _setCurrentTab(tab) {
        if (currentTab !== tab) {
            isFirstTime.current = false;
            setCurrentTab(tab);
        }
    }

    return (
        <div className={'switch-maintab tabs accessible-tabs-container'}>
            <div className="tab-selectors header-action">
                <div className="header-action__content w-100">
                    {isPermissionWorkPool && (
                        <TooltipPopper
                            onClick={() => _setCurrentTab(TABS.WORK_POOL)}
                            className={classNames('tab-items svg-noeffect tooltip', {
                                'active-tab-selector': activeWorkPool
                            })}
                            tooltipText={t('calendar:work_pool')}
                        >
                            <IconWorkOrder />
                            <p className="tab-items__label txt-ellipsis">{t('calendar:work_pool')}</p>
                            <span id={'sidebar_content_workpool_number_pool'} className="count" />
                        </TooltipPopper>
                    )}
                    <TooltipPopper
                        onClick={() => _setCurrentTab(TABS.TASKS)}
                        className={classNames('tab-items svg-noeffect tooltip', {
                            'active-tab-selector': activeTaskList
                        })}
                        tooltipText={t('calendar:tasks')}
                    >
                        <IconTask />
                        <p className="tab-items__label txt-ellipsis">{t('calendar:tasks')}</p>
                    </TooltipPopper>
                    <TooltipPopper
                        onClick={() => _setCurrentTab(TABS.JOB_LIST)}
                        className={classNames('tab-items svg-noeffect tooltip', {
                            'active-tab-selector': activeJobList
                        })}
                        tooltipText={t('calendar:job_list')}
                    >
                        <IconFolder />
                        <p className="tab-items__label txt-ellipsis">{t('calendar:job_list')}</p>
                    </TooltipPopper>
                </div>
            </div>

            <div className="tab-contents">
                <TabJobList activeTab={activeJobList} selectCustomer={selectCustomer} />

                {!isFirstTime.current && <TabTaskList activeTab={activeTaskList} />}

                {isPermissionWorkPool && <WorkPoolList activeTab={activeWorkPool} />}
            </div>
        </div>
    );
};

export default SlideBarContent;

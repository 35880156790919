import ButtonSave from 'app/components/button/ButtonSave';
import GdConfirm from 'app/components/confirm';
import {
    ACTIONN_UPDATE_OPTION_QUICKBOOKS_ADDON,
    ACTIONN_UPDATE_QUICKBOOKS_ADDON,
    ADDON_DISCONNECT_QUICKBOOK
} from 'app/const/api/V2';
import { LIST_STATUS } from 'app/const/Status';
import StatusBar from 'app/components/status/statusbar';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { useRef } from 'react';
import AddonsBoxHeader from '../../components/AddonsBoxHeader';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setBranchLocalStorage } from 'app/const/Branch';
import { updateAddonStatus } from 'common/redux/actions/authAction';

export default function Header({
    name,
    status,
    level,
    keyword,
    upgrade,
    linkConnect,
    onUpdate = () => {},
    qbConnected
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const branchId = useSelector(({ auth }) => auth.user.company.branch.id);
    const refConfirm = useRef(null);
    const refButtonSave = useRef(null);
    const refStatusBar = useRef(null);
    const refHeader = useRef(null);
    const refStatus = useRef(false);

    function _connectQuickbook() {
        if (!refStatus.current) {
            setBranchLocalStorage(branchId);
            window.location.href = linkConnect;
        } else {
            refButtonSave.current?.setLoading(true);
            _handleDisconnect();
        }
    }

    function _handleDisconnect() {
        clientQuery(ADDON_DISCONNECT_QUICKBOOK, { method: 'PUT' }, _handleDisconnectSuccess, _handleDisconnectFailed);
    }

    function _handleDisconnectSuccess() {
        clientQuery(ACTIONN_UPDATE_QUICKBOOKS_ADDON, { method: 'PUT', data: { is_remove: 1 } });
        dispatch(updateAddonStatus({ keyword, data: 0 }));
        onUpdate({ qb_connected: false, status: 0 });
        refHeader.current?._handleChangeImmediately(false);
        refStatusBar.current.showStatusBar('show_error', t('addons:disconnect_success'), LIST_STATUS.SUCCESS);
    }

    function _handleDisconnectFailed(response) {
        refStatusBar.current.showStatusBar('show_error', response?.message?.toString(), LIST_STATUS.ERROR);
        _handleRemoveLoading();
    }

    function _rendeButton() {
        if (qbConnected) {
            return (
                <ButtonSave
                    ref={refButtonSave}
                    wrapClass="mt-3 v2-btn-main --grey btn-modal"
                    title={t('addons:disconnect_to_quickbooks')}
                    onSave={() => _handleConfirmChange(true)}
                />
            );
        }

        return (
            <p onClick={() => _handleConfirmChange(false)} className="mt-3 v2-btn-main">
                {t('addons:connect_to_quickbooks')}
            </p>
        );
    }

    const _handleConfirmChange = (status) => {
        refStatus.current = status;

        if (!status) {
            refConfirm.current.open(
                true,
                t('addons:be_careful_if_you_have_connected'),
                t('addons:connect_to_quickbooks')
            );
        } else {
            refConfirm.current.open(false, t('addons:disable_quickbooks_desc'), t('addons:disable_quickbooks'));
        }
    };

    const _handleRemoveLoading = () => {
        refButtonSave.current?.removeLoading();
    };

    return (
        <div className="boxs boxs--no-border --hasline">
            <AddonsBoxHeader
                ref={refHeader}
                keyword={keyword}
                name={name}
                status={status}
                upgrade={upgrade}
                level={level}
                onConfirmChange={_handleConfirmChange}
                urlUpdateStatus={ACTIONN_UPDATE_OPTION_QUICKBOOKS_ADDON}
                isQuickBook
                shouldConfirm
            />
            <div className="boxs__contents mt-3">
                <StatusBar ref={refStatusBar} />
                <div className="description">{t('addons:sync_your_customers_invoices_and_payment')}</div>
                <p className="description mt-5">{t('addons:this_process_is_a_one_way_sync')}</p>
                {_rendeButton()}
            </div>
            <GdConfirm
                ref={refConfirm}
                listButton={{ confirm: true, cancel: true }}
                titleConfirm={t('addons:confirm')}
                onConfirm={_connectQuickbook}
                onClose={_handleRemoveLoading}
                onCancel={_handleRemoveLoading}
            />
        </div>
    );
}

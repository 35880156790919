import React from 'react';

const IconOnBoarding = ({ isMenuActive = false }) => {
    if (isMenuActive) {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="12" fill="#8D4AFC"></rect>
                <path
                    opacity="0.5"
                    d="M12.256 12.3478C12.7464 10.1413 13.7015 8.06468 15.0577 6.25642V6.25642C15.5288 5.62821 16.4712 5.62821 16.9423 6.25642V6.25642C18.2985 8.06468 19.2536 10.1413 19.744 12.3478L20.0713 13.8208C20.6763 16.5435 20.4459 19.3854 19.41 21.975V21.975C19.1624 22.5941 18.5628 23 17.896 23H14.104C13.4372 23 12.8376 22.5941 12.59 21.975V21.975C11.5541 19.3854 11.3237 16.5435 11.9287 13.8208L12.256 12.3478Z"
                    fill="white"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 16C17.1046 16 18 15.1046 18 14C18 12.8954 17.1046 12 16 12C14.8955 12 14 12.8954 14 14C14 15.1046 14.8955 16 16 16ZM21.1811 26H22.9476C23.2434 26 23.4745 25.7446 23.4451 25.4502L23.1626 22.6257C23.0548 21.5473 22.7958 20.4893 22.3932 19.483L21.9702 18.4254C21.7645 17.9111 21 18.0583 21 18.6122C21 19.5314 20.8519 20.4446 20.5612 21.3166L20.2334 22.2999C20.0881 22.736 19.73 23.0675 19.2842 23.179C18.7506 23.3124 18.5943 23.9953 19.0169 24.3474L20.861 25.8841C20.9508 25.959 21.0641 26 21.1811 26ZM9.05254 26H10.819C10.936 26 11.0493 25.959 11.1391 25.8841L12.9832 24.3474C13.4057 23.9953 13.2495 23.3124 12.7159 23.179C12.2701 23.0675 11.912 22.736 11.7667 22.2999L11.4389 21.3166C11.1482 20.4446 11 19.5314 11 18.6122C11 18.0583 10.2356 17.9111 10.0299 18.4254L9.60684 19.483C9.20431 20.4893 8.94532 21.5473 8.83747 22.6257L8.55502 25.4502C8.52559 25.7446 8.75673 26 9.05254 26ZM15.3861 25.6581L15.2924 25.3771C15.1 24.8 15.0418 24.1866 15.1222 23.5835L15.7027 19.2303C15.749 18.8831 16.2511 18.8831 16.2974 19.2303L16.8779 23.5835C16.9583 24.1866 16.9001 24.8 16.7077 25.3771L16.614 25.6581C16.546 25.8623 16.3549 26 16.1397 26H15.8604C15.6452 26 15.4541 25.8623 15.3861 25.6581Z"
                    fill="white"
                ></path>
            </svg>
        );
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <rect width="32" height="32" rx="12" fill="#E7E4EE" />
            <path
                opacity="0.5"
                d="M12.256 12.3478C12.7464 10.1413 13.7015 8.06468 15.0577 6.25642C15.5288 5.62821 16.4712 5.62821 16.9423 6.25642C18.2985 8.06468 19.2536 10.1413 19.744 12.3478L20.0713 13.8208C20.6763 16.5435 20.4459 19.3854 19.41 21.975C19.1624 22.5941 18.5628 23 17.896 23H14.104C13.4372 23 12.8376 22.5941 12.59 21.975C11.5541 19.3854 11.3237 16.5435 11.9287 13.8208L12.256 12.3478Z"
                fill="#8D4AFC"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 16C17.1046 16 18 15.1046 18 14C18 12.8954 17.1046 12 16 12C14.8955 12 14 12.8954 14 14C14 15.1046 14.8955 16 16 16ZM21.1811 26H22.9476C23.2434 26 23.4745 25.7446 23.4451 25.4502L23.1626 22.6257C23.0548 21.5473 22.7958 20.4893 22.3932 19.483L21.9702 18.4254C21.7645 17.9111 21 18.0583 21 18.6122C21 19.5314 20.8519 20.4446 20.5612 21.3166L20.2334 22.2999C20.0881 22.736 19.73 23.0675 19.2842 23.179C18.7506 23.3124 18.5943 23.9953 19.0169 24.3474L20.861 25.8841C20.9508 25.959 21.0641 26 21.1811 26ZM9.05254 26H10.819C10.936 26 11.0493 25.959 11.1391 25.8841L12.9832 24.3474C13.4057 23.9953 13.2495 23.3124 12.7159 23.179C12.2701 23.0675 11.912 22.736 11.7667 22.2999L11.4389 21.3166C11.1482 20.4446 11 19.5314 11 18.6122C11 18.0583 10.2356 17.9111 10.0299 18.4254L9.60684 19.483C9.20431 20.4893 8.94532 21.5473 8.83747 22.6257L8.55502 25.4502C8.52559 25.7446 8.75673 26 9.05254 26ZM15.3861 25.6581L15.2924 25.3771C15.1 24.8 15.0418 24.1866 15.1222 23.5835L15.7027 19.2303C15.749 18.8831 16.2511 18.8831 16.2974 19.2303L16.8779 23.5835C16.9583 24.1866 16.9001 24.8 16.7077 25.3771L16.614 25.6581C16.546 25.8623 16.3549 26 16.1397 26H15.8604C15.6452 26 15.4541 25.8623 15.3861 25.6581Z"
                fill="#8D4AFC"
            />
        </svg>
    );
};

export default IconOnBoarding;

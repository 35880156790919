import React, { Fragment, useRef } from 'react';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconTrash from 'assets/icon/IconTrash';
import { useTranslation } from 'react-i18next';

export const EditAutoAttendant = ({ item, onDelete = () => {} }) => {
    const { t } = useTranslation('addons');
    const refDropdown = useRef(null);

    const { id } = item;

    const _handleDelete = () => {
        onDelete(id);
        _handleCloseDropdown();
    };

    const _handleCloseDropdown = () => {
        refDropdown.current?._closeDropdown();
    };

    return (
        <CalendarDropdown
            id={`btn_delete_${id}`}
            ref={refDropdown}
            wrapperClassName="v2-dropdown dropdown-delete"
            buttonClassName="dropbtn v2-btn-default --icon-sm svg-delete-grey btn-plus"
            customDropButton={() => <IconTrash />}
            wrapperListClass="v2-dropdown__menu wrap-action flex-betweenitems content-full gap-8 elm-action-delete"
        >
            <Fragment>
                <div className="v2-btn-default --sm --transparent js-action-delete" onClick={_handleCloseDropdown}>
                    {t('cancel')}
                </div>
                <div className="v2-btn-default --sm bg-red-default border-transparent white-default" onClick={_handleDelete}>
                    {t('delete_extension')}
                </div>
            </Fragment>
        </CalendarDropdown>
    );
};

import { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CALENDAR_JOBS_DRIVE_TIME } from 'app/const/api/V2';
import { clientQuery } from 'common/utils/ApiUtils';
import { dispatchEvent, subscribeEvent, unsubscribeEvent } from 'common/utils/eventUtils';
import { handleAbortController } from '../customer/utils';
import { CALENDAR_CUSTOM_EVENTS, JOB_MAGNET_ABILITY } from './const';
import { convertEventsDriveTime } from './ultil/Calendar';

const driveTimeReducer = (state, { type }) => {
    switch (type) {
        case CALENDAR_CUSTOM_EVENTS.ACTIVATE_DRIVE_TIME:
            return { ...state, isActivated: true };
        case CALENDAR_CUSTOM_EVENTS.DEACTIVATE_DRIVE_TIME:
            return { ...state, isActivated: false };
        default:
            return state;
    }
};

const ServicesDriveTime = forwardRef((props, ref) => {
    const { calendarView = '', onWarning = () => {}, onGetParamsQuery = () => {}, onSetDriveEvents = () => {} } = props;

    const { t } = useTranslation(['calendar']);
    const driveTimeAddon = useSelector(({ auth }) => auth.user.settings.addons.routing.drive_time || null);
    const { start, end } = useSelector(({ inlineCalendarReducer }) => inlineCalendarReducer);
    const schedules = useSelector(({ calendar }) => calendar.schedules);
    const refAbortController = useRef(null);
    const refStoreDriveTime = useRef({ eventsConsecutive: new Map(), driveEvents: [] });

    const [state, dispatchState] = useReducer(driveTimeReducer, { isActivated: false });
    const { isActivated } = state;

    useImperativeHandle(ref, () => ({
        handleGetDriveTime: () => {
            isActivated && handleFetchDriveTime();
        },
        clearStoreDriveTime: (isClearStatus) => {
            refStoreDriveTime.current = { eventsConsecutive: new Map(), driveEvents: [] };
            if (refAbortController.current) handleAbortController(refAbortController);
            if (isClearStatus) dispatchState({ type: CALENDAR_CUSTOM_EVENTS.DEACTIVATE_DRIVE_TIME });
        }
    }));

    useEffect(() => {
        subscribeEvent(CALENDAR_CUSTOM_EVENTS.ACTIVATE_DRIVE_TIME, handleActivateDriveTime);
        subscribeEvent(CALENDAR_CUSTOM_EVENTS.DEACTIVATE_DRIVE_TIME, handleDeactivateDriveTime);
        return () => {
            unsubscribeEvent(CALENDAR_CUSTOM_EVENTS.ACTIVATE_DRIVE_TIME, handleActivateDriveTime);
            unsubscribeEvent(CALENDAR_CUSTOM_EVENTS.DEACTIVATE_DRIVE_TIME, handleDeactivateDriveTime);
        };
    }, [schedules, start, end]);

    useEffect(() => {
        return () => {
            if (refAbortController.current) handleAbortController(refAbortController);
        };
    }, []);

    const handleDeactivateDriveTime = () => {
        if (refAbortController.current) handleAbortController(refAbortController);
        dispatchState({ type: CALENDAR_CUSTOM_EVENTS.DEACTIVATE_DRIVE_TIME });
        onSetDriveEvents({ eventsConsecutive: new Map(), driveEvents: [], isDeactivate: true });
    };

    const handleActivateDriveTime = () => {
        dispatchState({ type: CALENDAR_CUSTOM_EVENTS.ACTIVATE_DRIVE_TIME });
        if (refStoreDriveTime.current['eventsConsecutive'].size || refStoreDriveTime.current['driveEvents'].length) {
            dispatchEvent(CALENDAR_CUSTOM_EVENTS.TOGGLE_LOADING_DRIVE_TIME, { isLoading: false });
            onSetDriveEvents(refStoreDriveTime.current);
        } else {
            handleFetchDriveTime();
        }
    };

    const handleFetchDriveTime = () => {
        handleAbortController(refAbortController);
        if (!JOB_MAGNET_ABILITY.includes(calendarView) || !driveTimeAddon) return;
        refAbortController.current = new AbortController();
        const paramsQuery = onGetParamsQuery() || {};

        const handleGetDriveTimeSuccess = ({ data = [] }) => {
            const dataConverted = convertEventsDriveTime(data || []);
            refStoreDriveTime.current = dataConverted;
            onSetDriveEvents(dataConverted);
        };

        const handleGetDriveTimeFail = ({ isAborted, message, statusCode }) => {
            if (isAborted) return;
            refStoreDriveTime.current = { eventsConsecutive: new Map(), driveEvents: [] };
            onWarning({ message: message || t('you_do_not_have_permission'), statusCode });
        };

        const handleGetDriveTimeFinally = () => {
            dispatchEvent(CALENDAR_CUSTOM_EVENTS.TOGGLE_LOADING_DRIVE_TIME, { isLoading: false });
        };

        clientQuery(
            CALENDAR_JOBS_DRIVE_TIME,
            { data: paramsQuery, method: 'GET', toFormData: false, abortController: refAbortController.current },
            handleGetDriveTimeSuccess,
            handleGetDriveTimeFail,
            handleGetDriveTimeFinally
        );
    };

    return null;
});

export default ServicesDriveTime;

import React from 'react';
import { useTranslation } from 'react-i18next';

export default function UpgradeSuccess() {
    const { t } = useTranslation(['setting']);

    return (
        <div className="contents-pages scrolls setting-plans">
            <div className="page-plans">
                <div className="box-thankyou">
                    <h3 className="box-thankyou__title fs-30">{t('setting:plan_and_pricing')}</h3>
                    <p className="box-thankyou__desc mt-2">{t('setting:account_plan_header')}</p>
                    <h5 className="box-thankyou__title fs-20 mt-8">{t('setting:account_plan_thanks')}</h5>
                </div>
            </div>
        </div>
    );
}

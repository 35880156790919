import { GET_EXIST_CUSTOMER_BY_LOCATION } from 'app/const/api/V2';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import PlaceInput from './PlaceInput';
import { useTranslation } from 'react-i18next';
import IconCircleClose from 'assets/icon/IconCircleClose';
import IconArrowDown from 'assets/icon/IconArrowDown';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';

const AddressInfo = (
    {
        type = 'services',
        defaultValue = {},
        shouldCheckExisted = true,
        onAutoComplete = () => {},
        onExistCustomer = () => {},
        onBlur = () => {},
        onChange = () => {},
        billingAddressOptions,
        onHandleGetBillingAddress,
        onHandleSelectBilling,
        isLoading,
        isBillingCustomer = false,
        onHandleCheckSame = () => {}
    },
    ref
) => {
    const { t } = useTranslation(['customers']);
    const refPlaces = useRef(null);
    const refPlaces2 = useRef(null);
    const refState = useRef(null);
    const refCity = useRef(null);
    const refZipCode = useRef(null);
    const refBilling = useRef(null);
    const refCountry = useRef('');
    const refCounty = useRef('');
    const street1 = defaultValue?.street1 || defaultValue?.line1 || '';

    useImperativeHandle(ref, () => ({ getValue: _handleGetValue, setValue: _handleSetValue }));

    const _handleGetValue = () => {
        return {
            street1: refPlaces.current.getValue(),
            street2: refPlaces2.current.getValue(),
            city: refCity.current.value,
            state: refState.current.value,
            zip: refZipCode.current.value,
            country: refCountry.current,
            county: refCounty.current,
            formattedAddress: refPlaces.current.getValue(),
            ...refPlaces.current.getGeo()
        };
    };

    const _handleSetValue = (data) => {
        refPlaces.current._setValue(data['name'] || '');
        refPlaces2.current._setValue(data['street2'] || '');
        refCity.current.value = data['city'] || '';
        refState.current.value = data['state'] || '';
        refZipCode.current.value = data['zip'] || '';
        refCountry.current = data['country'] || '';
        refCounty.current = data['county'] || '';
    };

    const refBlurCheck = useRef({});

    const _handleBlur = (street1, isStreet2 = false) => {
        let shouldCheckExistCustomer = false;
        // Value when onChange called
        const dataSubmit = _handleGetValue();
        if (street1 && !isStreet2) dataSubmit.street1 = street1;
        if (street1 && isStreet2) dataSubmit.street2 = street1;

        for (const key in dataSubmit) {
            if (Object.hasOwnProperty.call(dataSubmit, key)) {
                const element = dataSubmit[key],
                    valueCheck = refBlurCheck.current[key];
                if (
                    !['lat', 'lng', 'formattedAddress'].includes(key) &&
                    (element !== valueCheck || valueCheck === undefined)
                ) {
                    shouldCheckExistCustomer = true;
                    break;
                }
            }
        }

        if (shouldCheckExistCustomer) {
            if (shouldCheckExisted) {
                const _checkExistSuccess = ({ data }) => {
                    if (data) onExistCustomer({ ...data, ...dataSubmit });
                };
                clientQuery(GET_EXIST_CUSTOMER_BY_LOCATION, { data: dataSubmit, method: 'GET' }, _checkExistSuccess);
            }
        }

        onBlur(dataSubmit);
        refBlurCheck.current = dataSubmit;
    };

    const _handleChange = (e) => {
        onHandleCheckSame(false);
        onChange(e, type);
    };

    const _handleChangeAddress = (name, value) => {
        refCity.current.value = value.city;
        refState.current.value = value.state;
        refZipCode.current.value = value.zip;
        refCountry.current = value.country;
        refCounty.current = value.county;
        onAutoComplete(type, value);
    };

    const _handleStreet2 = (name, value) => {
        refPlaces2.current._setValue(value || '');
        onAutoComplete(type, value, true);
    };

    const customDropButton = useCallback(() => {
        return (
            <>
                <div className="search-input">
                    <PlaceInput
                        ref={refPlaces}
                        withClassName="field-input field-street --first"
                        placeholder={t('customers:street_1')}
                        name={`${type}_street1`}
                        is-address="true"
                        defaultValue={street1}
                        onChange={_handleChange}
                        onBlur={_handleBlur}
                        onSelect={_handleChangeAddress}
                        spellCheck
                    />
                    <div className="close-icon dp-hide">
                        <IconCircleClose />
                    </div>
                </div>
                <span className="arrow">
                    <IconArrowDown />
                </span>
            </>
        );
    }, [street1]);

    return (
        <>
            {type === 'billing' && !isBillingCustomer ? (
                <CalendarDropdown
                    ref={refBilling}
                    id="billing_address_1"
                    options={billingAddressOptions}
                    keyGetValue="id"
                    keyGetName="billing_address_1"
                    keyGetKey="id"
                    selected={street1}
                    selectedOption={defaultValue}
                    isLoading={isLoading}
                    onVisible={onHandleGetBillingAddress}
                    onSelect={onHandleSelectBilling}
                    buttonClassName="dropbtn items field-input field-street --first"
                    customDropButton={customDropButton}
                />
            ) : (
                <PlaceInput
                    ref={refPlaces}
                    withClassName="field-input field-street --first"
                    placeholder={t('customers:street_1')}
                    name={`${type}_street1`}
                    is-address="true"
                    defaultValue={street1}
                    onChange={_handleChange}
                    onBlur={_handleBlur}
                    onSelect={_handleChangeAddress}
                    spellCheck
                />
            )}

            <PlaceInput
                ref={refPlaces2}
                withClassName="field-input field-street --second"
                placeholder={t('customers:street_2')}
                name={`${type}_street2`}
                is-address="true"
                defaultValue={defaultValue?.street2 || defaultValue?.line2 || ''}
                onChange={_handleChange}
                onBlur={(data) => _handleBlur(data, true)}
                onSelect={_handleStreet2}
                spellCheck
            />

            <input
                ref={refCity}
                name={`${type}_city`}
                type="text"
                className="field-input field-city"
                placeholder={t('customers:city')}
                defaultValue={defaultValue?.city || ''}
                onBlur={() => _handleBlur()}
                onChange={_handleChange}
                spellCheck
            />
            <input
                ref={refState}
                name={`${type}_state`}
                type="text"
                className="field-input field-state"
                placeholder={t('customers:st')}
                defaultValue={defaultValue?.state || ''}
                onBlur={() => _handleBlur()}
                onChange={_handleChange}
                spellCheck
            />
            <input
                ref={refZipCode}
                name={`${type}_zip`}
                type="text"
                className="field-input field-zip"
                placeholder={t('customers:customer_zip')}
                defaultValue={defaultValue?.zip || ''}
                onBlur={() => _handleBlur()}
                onChange={_handleChange}
                spellCheck
            />
        </>
    );
};

export default forwardRef(AddressInfo);

import moment from 'moment';
import { DATE_RANGE } from 'app/const/App';
import { SPECIAL_DATE_RANGE_FORMAT } from 'app/modules/calendar/const';
import { caculateTimeAgo } from './TimeUtils';
import store from 'common/redux/store/configureStore';
import i18n from 'assets/i18n';

export const getRangeDateDisplay = (type, format = null) => {
    const FORMAT_DISPLAY = format || 'MMM D';
    const currentDisplay = moment().locale(i18n.language).format(FORMAT_DISPLAY);

    const startOfMonth = moment().startOf('month').locale(i18n.language).format(FORMAT_DISPLAY);
    const endOfMonth = moment().endOf('month').locale(i18n.language).format(FORMAT_DISPLAY);
    const startOfYear = moment().startOf('year').locale(i18n.language).format(FORMAT_DISPLAY);
    const last30Days = moment().subtract(29, 'days').locale(i18n.language).format(FORMAT_DISPLAY);
    const startOfLastMonth = moment()
        .subtract(1, 'month')
        .startOf('month')
        .locale(i18n.language)
        .format(FORMAT_DISPLAY);
    const startOfLast3Month = moment()
        .subtract(3, 'month')
        .startOf('month')
        .locale(i18n.language)
        .format(FORMAT_DISPLAY);
    const startOfLast6Month = moment()
        .subtract(6, 'month')
        .startOf('month')
        .locale(i18n.language)
        .format(FORMAT_DISPLAY);
    const endOfLastMonth = moment().subtract(1, 'month').endOf('month').locale(i18n.language).format(FORMAT_DISPLAY);
    const endOfYear = moment().endOf('year').locale(i18n.language).locale(i18n.language).format(FORMAT_DISPLAY);
    const startOfThisWeek = moment().startOf('week').locale(i18n.language).format(FORMAT_DISPLAY);
    const endOfThisWeek = moment().endOf('week').locale(i18n.language).format(FORMAT_DISPLAY);
    const startOfLastWeek = moment().subtract(1, 'weeks').startOf('week').locale(i18n.language).format(FORMAT_DISPLAY);
    const endOfLastWeek = moment().subtract(1, 'weeks').endOf('week').locale(i18n.language).format(FORMAT_DISPLAY);
    const startOfLast7Days = moment().subtract(6, 'days').locale(i18n.language).format(FORMAT_DISPLAY);
    const startDayAllTime = moment().subtract(5, 'years').locale(i18n.language).format(FORMAT_DISPLAY);

    switch (type) {
        case DATE_RANGE.LAST_30_DAYS:
            return `${last30Days} - ${currentDisplay}`;
        case DATE_RANGE.THIS_MONTH:
            return `${startOfMonth} - ${endOfMonth}`;
        case DATE_RANGE.LAST_MONTH:
            return `${startOfLastMonth} - ${endOfLastMonth}`;
        case DATE_RANGE.LAST_3_MONTH:
            return `${startOfLast3Month} - ${endOfLastMonth}`;
        case DATE_RANGE.LAST_6_MONTH:
            return `${startOfLast6Month} - ${endOfLastMonth}`;
        case DATE_RANGE.THIS_YEAR:
            return `${startOfYear} - ${currentDisplay}`;
        case DATE_RANGE.LAST_YEAR:
            return `${startOfYear} - ${endOfYear}`;
        case DATE_RANGE.THIS_WEEK:
            return `${startOfThisWeek} - ${endOfThisWeek}`;
        case DATE_RANGE.LAST_WEEK:
            return `${startOfLastWeek} - ${endOfLastWeek}`;
        case DATE_RANGE.LAST_7_DAYS:
            return `${startOfLast7Days} - ${currentDisplay}`;
        case DATE_RANGE.ALL_TIME:
            return `${startDayAllTime} - ${currentDisplay}`;
        case DATE_RANGE.TO_DAY:
            return currentDisplay;
        default:
            return '';
    }
};

export function formatDate(value, format, formatTime = null) {
    const newFormat = convertPhpFormatToMoment(format);
    if (formatTime)
        return `${moment(value).locale(i18n.language).format(newFormat)} ${moment(value).utc().locale(i18n.language).format(formatTime)}`;
    return moment(value).utc().locale(i18n.language).format(newFormat);
}

export function formatDateLocal(value, format, formatTime = null) {
    const newFormat = convertPhpFormatToMoment(format);
    if (formatTime)
        return `${moment(value).locale(i18n.language).format(newFormat)} ${moment(value).locale(i18n.language).format(formatTime)}`;
    return moment(value).locale(i18n.language).format(newFormat);
}

export function formatTime(value, format = 'HH:mm') {
    return moment(value).utc().format(format);
}

export function convertPhpFormatToMoment(value) {
    switch (value) {
        case 'm/d/y':
            return 'MM/DD/YY';
        case 'd/m/y':
            return 'DD/MM/YY';
        case 'y/m/d':
            return 'YY/MM/DD';
        case 'm/d/Y':
            return 'MM/DD/YYYY';
        case 'd/m/Y':
            return 'DD/MM/YYYY';
        case 'Y/m/d':
            return 'YYYY/MM/DD';
        case 'd M Y':
            return 'DD MMM YYYY';
        case 'd F Y':
            return 'DD MMMM YYYY';
        case 'F d, Y':
            return 'MMMM DD, YYYY';
        case SPECIAL_DATE_RANGE_FORMAT:
            return SPECIAL_DATE_RANGE_FORMAT;
        default:
            break;
    }
}

export function formatWorkTime(time, format = 'hh:mm A') {
    return moment(time, 'H:mm').format(format);
}

export function convertCompanyToWorkTime(time, format = 'H:mm') {
    return moment(time, 'hh:mm A').format(format);
}

export function getDurationOperatingTime(value, defaultTime = '12:00 AM') {
    return moment.duration(moment(value, 'HH:mm A').diff(moment(defaultTime, 'HH:mm A'))).asMinutes();
}

export function convertTimeToISO(value) {
    const finalString = value.format('YYYY-MM-DD HH:mm:ss');
    return moment.tz(finalString, 'YYYY-MM-DD HH:mm:ss', 'UTC').toISOString();
}

export const combineDateTimeToISO = (date, time) => {
    return convertTimeToISO(
        moment(moment(date).utc().startOf('day').format(`YYYY-MM-DD`).concat(' ', time), 'YYYY-MM-DD hh:mm a')
    );
};

export function formartDateWithString(string) {
    return new Date(string.replace(/\s+/g, 'T'));
}

export function enumerateDays(start, end) {
    const dates = [];

    for (const m = moment(start); m.isSameOrBefore(end); m.add(1, 'days')) {
        dates.push(`${m.format('ddd')}, ${m.format('MMM')} ${convertDateOrdinal(m.date())} ${m.format('YYYY')}`);
    }
    return dates;
}

function convertDateOrdinal(d) {
    const ordinalDate = moment.localeData().ordinal(d);
    return d < 10 ? `0${ordinalDate}` : ordinalDate;
}

/**
 * It takes a moment object and return a string with the format:
 * @param date - The date to be converted.
 * @returns A function that takes a date and returns a new Date object.
 */
export const convertISOTodate = (date) => {
    return date ? new formartDateWithString(date.format('YYYY-MM-DD HH:mm:ss')) : new Date();
};

/**
 * @param {date|moment} start The start date
 * @param {date|moment} end The end date
 * @param {string} type The range type. eg: 'days', 'hours' etc
 */
export const getRange = (startDate, endDate, type = 'day') => {
    const range = [];
    const fromDate = moment(startDate).utc();
    const toDate = moment(endDate).utc();
    const diff = toDate.diff(fromDate, type);

    for (let i = 0; i < diff; i++) {
        range.push(moment.utc(startDate).add(i, type).startOf('days').unix());
    }
    return range;
};

export const _isFeature = (date) => {
    return moment(date).startOf('day').format('X') > moment().startOf('day').format('X');
};

export const convertSecondsToTime = (seconds, isHour = false) => {
    return new Date(seconds * 1000).toISOString().slice(isHour ? 11 : 14, 19);
};

export const convertMinuteToTime = (minute = 0) => {
    return moment(`${minute / 60}:${minute % 60}`, 'hh:mm');
};

export const formatDateDashboard = (start, end) => {
    const dateFormat = convertPhpFormatToMoment(store.getState().auth.user.company.date_format);
    const startDate = moment(start).locale(i18n.language).toDate();
    const endDate = moment(end).locale(i18n.language).toDate();

    if (startDate.getTime() !== endDate.getTime()) {
        return `${moment(startDate).locale(i18n.language).format(dateFormat)} - ${moment(endDate).locale(i18n.language).format(dateFormat)}`;
    }

    if (startDate.getTime() === endDate.getTime()) {
        return `${moment(startDate).locale(i18n.language).format(dateFormat)}`;
    }

    return caculateTimeAgo(startDate, dateFormat);
};

export const formatDateCompany = (date, format) => {
    const formatDisplay = convertPhpFormatToMoment(format);
    const dateMoment = moment(date);
    return date && dateMoment.isValid() ? dateMoment.locale(i18n.language).format(formatDisplay) : '';
};

export const convertDateSelect = ({ start, end }) => {
    const startDate = moment(start)._d;
    const endDate = moment(end)._d;

    return {
        startDate,
        start: startDate,
        endDate: endDate,
        end: endDate
    };
};
export const isExpired = (expMonth, expYear) => {
    const currentDate = moment();
    const cardDate = moment(`${expYear}-${expMonth}`, 'YYYY-MM');
    return cardDate.isBefore(currentDate, 'month');
};

import React from 'react';

export default function IconBachelorsHat({ isNormal = false }) {
    if (isNormal) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.95299 8.5655L11.288 5.77122C11.7466 5.59652 12.2534 5.59652 12.712 5.77122L20.0469 8.5655C20.563 8.76211 20.822 9.33988 20.6254 9.85598C20.5239 10.1225 20.3134 10.333 20.0469 10.4345L12.712 13.2288C12.2534 13.4035 11.7466 13.4035 11.288 13.2288L3.95299 10.4345C3.43689 10.2379 3.17789 9.6601 3.3745 9.14399C3.47602 8.8775 3.68649 8.66702 3.95299 8.5655Z"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.5 11.5V14.5C7.16667 16.5 9 17.5 12 17.5C15 17.5 16.8333 16.5 17.5 14.5V11.5"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12 9.5L19.5 10.5V14.5"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.95299 8.5655L11.288 5.77122C11.7466 5.59652 12.2534 5.59652 12.712 5.77122L20.0469 8.5655C20.563 8.76211 20.822 9.33988 20.6254 9.85598C20.5239 10.1225 20.3134 10.333 20.0469 10.4345L12.712 13.2288C12.2534 13.4035 11.7466 13.4035 11.288 13.2288L3.95299 10.4345C3.43689 10.2379 3.17789 9.6601 3.3745 9.14399C3.47602 8.8775 3.68649 8.66702 3.95299 8.5655Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.5 11.5V14.5C7.16667 16.5 9 17.5 12 17.5C15 17.5 16.8333 16.5 17.5 14.5V11.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 9.5L19.5 10.5V14.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

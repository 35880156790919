import React, { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import { reducer } from 'app/const/Reducer';
import { OPERATOR_IDS } from 'app/modules/smartview/constants/types';
import { actionGetListSchedules } from 'common/redux/actions/schedulesList';
import { getListPipelines } from 'common/redux/actions/settings/pipelinesAction';
import { getListSettingService } from 'common/redux/actions/settings/serviceAction';
import { getListSimpleTags } from 'common/redux/actions/settings/tagsAction';
import { SMART_VIEWS_TYPES } from 'common/redux/actions/smartviews';
import LoadingIcon from '../LoadingIcon';
import CustomButton from './CustomButton';
import ListContent from './ListContent';
import { getListJobStatus } from 'common/utils/JobStatusUtils';

const ReferenceCondition = ({
    objectType = '',
    operatorId,
    options: defaultOptions,
    keyJoin = '',
    keyChoose = 'Option',
    keyGetValue = '',
    keySelect = '',
    referencedType = '',
    isJobStatus = false,
    shouldFetch = false,
    selected = [],
    onChange = () => {}
}) => {
    const dispatch = useDispatch();
    const users = useSelector(({ companyUsers }) => companyUsers.users || []);
    const services = useSelector(({ serviceReducer }) => serviceReducer?.list_service || []);
    const isFirstTime = useSelector(({ serviceReducer }) => serviceReducer?.first_time);
    const { data: schedules, isFirstTime: firstTimeSchedule } = useSelector(({ schedulesList }) => schedulesList);
    const { isFirstTime: firstTimePipeline, data: pipelines } = useSelector(({ pipelines }) => pipelines);
    const { data: customerTags, isFirstTime: isLoadingCustomerTag } = useSelector(({ tags }) => tags.customer);
    const { data: locationTags, isFirstTime: isLoadingLocationTag } = useSelector(({ tags }) => tags.location);
    const { data: dataCity, isFirstTime: isLoadingCity } = useSelector(({ smartViews }) => smartViews.city);
    const { data: dataZip, isFirstTime: isLoadingZip } = useSelector(({ smartViews }) => smartViews.zip);
    const { data: dataItems, isFirstTime: isLoadingItems } = useSelector(({ smartViews }) => smartViews.items);
    const objectStore = { users, services, pipelines, customerTags, locationTags, items: dataItems };
    const objectAction = {
        pipelines: () => dispatch(getListPipelines({})),
        services: () => dispatch(getListSettingService({ localSaveStore: true })),
        schedulesList: () => dispatch(actionGetListSchedules()),
        customerTags: () => dispatch(getListSimpleTags({ type: 1 })),
        locationTags: () => dispatch(getListSimpleTags({ type: 2 })),
        city: () => dispatch({ type: SMART_VIEWS_TYPES.SV_GET_CITY }),
        zip: () => dispatch({ type: SMART_VIEWS_TYPES.SV_GET_ZIP }),
        items: () => dispatch({ type: SMART_VIEWS_TYPES.SV_GET_ITEMS })
    };

    const initialState = ({ defaultOptions, shouldFetch, isJobStatus }) => {
        const newState = { options: defaultOptions, isLoading: shouldFetch };
        if (isJobStatus) {
            newState['options'] = getListJobStatus({ isIncludeDeleted: true });
            newState['isLoading'] = false;
            return newState;
        }

        if (shouldFetch) {
            if (!!Array.isArray(objectStore[keyGetValue]) && objectStore[keyGetValue].length) {
                newState['options'] = objectStore[keyGetValue] || null;
                newState['isLoading'] = false;
            } else {
                objectAction[keyGetValue]();
            }
        } else {
            if (!!Array.isArray(objectStore[keyGetValue]) && objectStore[keyGetValue].length) {
                newState['options'] = objectStore[keyGetValue] || null;
                newState['isLoading'] = false;
            }
        }

        return newState;
    };

    const [state, dispatchState] = useReducer(reducer, { defaultOptions, shouldFetch, isJobStatus }, initialState);
    const { options, isLoading } = state;

    useEffect(() => {
        if (keyGetValue === 'services') dispatchState({ options: services, isLoading: isFirstTime });
    }, [services]);

    useEffect(() => {
        if (keyGetValue === 'pipelines')
            dispatchState({ options: pipelines?.[0]?.pipelines || [], isLoading: firstTimePipeline });
    }, [pipelines]);

    useEffect(() => {
        if (keyGetValue === 'schedulesList') dispatchState({ options: schedules || [], isLoading: firstTimeSchedule });
    }, [schedules]);

    useEffect(() => {
        if (keyGetValue === 'customerTags')
            dispatchState({ options: customerTags || [], isLoading: isLoadingCustomerTag });
    }, [customerTags]);

    useEffect(() => {
        if (keyGetValue === 'locationTags')
            dispatchState({ options: locationTags || [], isLoading: isLoadingLocationTag });
    }, [locationTags]);

    useEffect(() => {
        if (keyGetValue === 'city') dispatchState({ options: dataCity || [], isLoading: isLoadingCity });
    }, [dataCity]);

    useEffect(() => {
        if (keyGetValue === 'zip') dispatchState({ options: dataZip || [], isLoading: isLoadingZip });
    }, [dataZip]);

    useEffect(() => {
        if (keyGetValue === 'items') dispatchState({ options: dataItems || [], isLoading: isLoadingItems });
    }, [dataItems]);

    if ([OPERATOR_IDS.PRESENT, OPERATOR_IDS.NOT_PRESENT].includes(operatorId)) return null;
    return (
        <DropdownPopper
            isUseToggle
            isCalculateWidth
            isCheckHidden
            wrapperClassName="v2-dropdown field"
            buttonClassName="dropbtn items"
            modifiers={[{ name: 'offset', options: { offset: [0, 5] } }]}
            customButton={
                <CustomButton
                    keyJoin={keyJoin}
                    keyChoose={keyChoose}
                    keySelect={keySelect}
                    options={options || []}
                    objectType={objectType}
                    isJobStatus={isJobStatus}
                    shouldFetch={shouldFetch}
                    selected={selected}
                />
            }
        >
            {isLoading ? (
                <LoadingIcon />
            ) : (
                <ListContent
                    options={options || []}
                    shouldFetch={shouldFetch}
                    isJobStatus={isJobStatus}
                    selected={selected}
                    keySelect={keySelect}
                    referencedType={referencedType}
                    onChange={onChange}
                />
            )}
        </DropdownPopper>
    );
};

export default ReferenceCondition;

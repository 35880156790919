import React, { Fragment, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { SETTINGS_SERVICE_PLAN } from 'app/config/routes';
import { ADDONS_SERVICE_PLAN } from 'app/const/addons';
import { ADDONS_SERVICE_PLAN_DETAIL, UPDATE_ADDONS_SERVICE_PLAN_OPTIONS } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import ErrorPage from 'app/modules/error';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkAccessFail, isActiveFeature } from 'common/utils/PermissionUtils';
import AddonBack from '../components/AddonBack';
import AddonsOptions from '../components/AddonsOptions';
import ServicePlanContent from './components/ServicePlanContent';
import ServicePlanLoading from './components/ServicePlanLoading';

const AddonsServicePlan = () => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { servicePlan: {}, isLoading: true });
    const { isLoading, servicePlan } = state;
    const havePermission = isActiveFeature(ADDONS_SERVICE_PLAN.toUpperCase());

    useEffect(() => {
        if (!havePermission) return;
        _getDetailServicePlanAddons();
    }, []);

    const _getDetailServicePlanAddons = () => {
        clientQuery(ADDONS_SERVICE_PLAN_DETAIL, { method: 'GET' }, _handleGetSuccess, checkAccessFail);
    };

    const _handleGetSuccess = ({ data = {} }) => {
        dispatchState({ servicePlan: data, isLoading: false });
    };

    if (!havePermission) return <ErrorPage errorMessage={t('auth:no_permission_access')} />;

    return (
        <div className="addons-wrap__container">
            <div className="header">
                <AddonBack />
            </div>
            <div className="scrolls">
                <div className="boxs-wrapper">
                    {isLoading ? (
                        <ServicePlanLoading />
                    ) : (
                        <Fragment>
                            <ServicePlanContent {...servicePlan} />
                            <AddonsOptions
                                data={servicePlan.options}
                                status={servicePlan.status}
                                shouldCheckManage
                                pathManage={SETTINGS_SERVICE_PLAN}
                                urlUpdate={UPDATE_ADDONS_SERVICE_PLAN_OPTIONS}
                            />
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddonsServicePlan;

import React, { useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ButtonSave from 'app/components/button/ButtonSave';
import { SAVE_PORTAL_LINK } from 'app/const/api/V2';
import { reducer } from 'app/const/Reducer';
import IconOpen from 'assets/icon/IconOpen';
import { updateUserSettings } from 'common/redux/actions/authAction';
import { clientQuery } from 'common/utils/ApiUtils';

const SaveSubdomain = ({ subdomain = '', domain = '', description = '', defaultValue = '' }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [state, dispatchState] = useReducer(reducer, {
        isPortalDomain: !!subdomain,
        urlValue: defaultValue,
        portalUrl: ''
    });
    const refButtonSave = useRef(null);
    const { urlValue = '', portalUrl = '' } = state;
    const subDomain = subdomain || portalUrl;

    const _handleSavePortalLink = () => {
        const _success = () => {
            dispatchState({
                isPortalDomain: true,
                portalUrl: `${subdomain || domain}/${urlValue}`
            });
            dispatch(updateUserSettings({ portal_url: `${subdomain || domain}/${urlValue}` }));
        };

        const _finally = () => {
            refButtonSave.current.removeLoading();
        };
        clientQuery(SAVE_PORTAL_LINK, { data: { subdomain: urlValue }, method: 'PUT' }, _success, null, _finally);
    };

    const _handleChange = (e) => {
        dispatchState({ urlValue: e.target.value });
    };

    return (
        <div className="link-edit">
            {state.isPortalDomain ? (
                <a
                    className="svg-purple purple-default link-portal ml-n1"
                    href={subDomain}
                    target="_blank"
                    rel="noreferrer"
                >
                    <IconOpen />
                    <span className="txt-ellipsis ml-1 pr-1">{subDomain}</span>
                </a>
            ) : (
                <>
                    <div className="svg-purple purple-default link-portal ml-n1">
                        <IconOpen />
                        <span className="txt-ellipsis ml-1 pr-1">{domain}</span>
                    </div>
                    <input
                        className="field-input"
                        type="text"
                        value={urlValue}
                        onChange={(e) => _handleChange(e)}
                        spellCheck
                    />
                    <ButtonSave ref={refButtonSave} onSave={_handleSavePortalLink} />
                </>
            )}

            <p className="mb-0 mt-3">{description || t('addons:save_sub_domain_desc')}</p>
        </div>
    );
};

export default SaveSubdomain;

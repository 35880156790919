import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { addBranchPath } from 'app/const/Branch';
import { PERMISSIONS } from 'app/const/Permissions';
import { sidebarsBottom, sidebarsTop } from 'app/const/StaticLinks';
import { checkAddon } from 'common/utils/AddonUtils';
import { checkIsExpiredAccount, checkPermission } from 'common/utils/PermissionUtils';
import { KEY_ONBOARDING } from '../onboarding/constant';

function RenderItem({
    id,
    permissionsList,
    addonsList,
    havePermissionTracking = false,
    to,
    tooltip,
    icon,
    onboardingPermission = false
}) {
    const { t } = useTranslation(['sidebar']);

    let isPermission = true;
    switch (id) {
        //Calendar: permissions viewSchedulesAssigned  or admin
        case 'calendar':
            isPermission =
                checkPermission(permissionsList, PERMISSIONS.viewCustomersAssigned) ||
                checkPermission(permissionsList, PERMISSIONS.admin);
            break;
        //Customer: have permission accessCustomersTab
        case 'customers':
            isPermission = checkPermission(permissionsList, PERMISSIONS.accessCustomersTab);
            break;
        //Leads: Have permissions accessReportsTab and one of 3 addons active: customer_portal , online_booking , zapier
        case 'leads':
            isPermission =
                checkPermission(permissionsList, PERMISSIONS.accessReportsTab) &&
                (checkAddon(addonsList?.customer_portal) ||
                    checkAddon(addonsList?.online_booking) ||
                    checkAddon(addonsList?.zapier));
            break;
        //Review: Have permission  accessReportsTab and Addon Review active review_engine
        case 'reviews':
            isPermission =
                checkPermission(permissionsList, PERMISSIONS.accessReportsTab) && checkAddon(addonsList?.review_engine);
            break;
        //Review: Have permission  accessReportsTab and Addon Review active review_engine
        case 'work_log':
            isPermission = !!checkAddon(addonsList?.work_log) && havePermissionTracking;
            break;
        //Report: Have permission accessReportsTab
        case 'reports':
            isPermission = checkPermission(permissionsList, PERMISSIONS.accessReportsTab);
            break;
        //Commissions: Has permission admin  and Addon Commission tracking active commission_tracking
        case 'commissions':
            isPermission =
                checkPermission(permissionsList, PERMISSIONS.admin) && checkAddon(addonsList?.commission_tracking);
            break;
        //Addons, Settings: have permissions "accessSettingsTab"
        case 'settings':
            isPermission = checkPermission(permissionsList, PERMISSIONS.accessSettingsTab);
            break;
        case 'addons':
            isPermission = checkPermission(permissionsList, PERMISSIONS.accessAddonsTab);
            break;
        case 'dashboard':
            isPermission = checkPermission(permissionsList, PERMISSIONS.accessDashboard);
            break;
        case KEY_ONBOARDING:
            isPermission = onboardingPermission;
            break;
        default:
            break;
    }
    return (
        isPermission && (
            <NavLink to={addBranchPath(to)} className={classNames('tooltip menu-item')} activeClassName="is-active">
                {icon}
                {tooltip && <span className="tooltiptext right"> {t(`header:${id}`)} </span>}
            </NavLink>
        )
    );
}

function Sidebar() {
    const { toggled } = useSelector((state) => ({
        toggled: state.app.toggled
    }));

    const permissionsList = useSelector((state) => state.auth.user?.permissions?.enabled || []);
    const addonsList = useSelector((state) => state.auth.user?.settings?.addons || {});
    const profile = useSelector((state) => state.auth?.user?.profile);
    const trackingPermission = useSelector(({ auth }) => !!auth.user.settings?.time_tracking?.tracking);
    const isActiveQuestLog = useSelector(({ auth }) => !!auth.user.settings?.questlog?.question_active);

    const _renderSidebar = (arrMenu) => {
        return arrMenu.map((item) => {
            return (
                <RenderItem
                    key={item.id}
                    havePermissionTracking={trackingPermission}
                    permissionsList={permissionsList}
                    addonsList={addonsList}
                    onboardingPermission={isActiveQuestLog}
                    {...item}
                />
            );
        });
    };

    return (
        <div
            id="sidebar"
            className={classNames('sidebar-container', { active: toggled && !checkIsExpiredAccount(profile) })}
        >
            <div className="sidebar-container__content flex-column">{_renderSidebar(sidebarsTop)}</div>
            <div className="sidebar-container__content flex-column">{_renderSidebar(sidebarsBottom)}</div>
        </div>
    );
}

export default Sidebar;

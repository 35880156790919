import React, { useContext, useEffect, useReducer, useRef } from 'react';
import Options from 'app/modules/jobdetail/tabs/timeline/todo/Options';
import IconPlus from 'assets/icon/IconPlus';
import ShowMore from 'app/modules/jobdetail/tabs/timeline/todo/ShowMore';
import CheckListDetail from 'app/modules/jobdetail/tabs/timeline/todo/checklist';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { getCheckListImTemplate } from 'app/const/api/Todo';
import Add from 'app/modules/jobdetail/tabs/timeline/todo/checklist/Add';
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SaveAsTemplate from 'app/modules/jobdetail/tabs/timeline/todo/SaveAsTemPlate';
import GdConfirm from 'app/components/confirm';
import { useTranslation } from 'react-i18next';
import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';
import { LoadingMore } from 'app/modules/jobdetail/tabs/timeline/todo/Loading';
import UpdateTodo from './UpdateTodo';

export default function TodoService({ tabActive, onDelete = () => {} }) {
    const { t } = useTranslation(['jobDetail', 'setting']);

    const { repeatSettings, onChangeState } = useContext(DataRepeatContext);

    const { total, autoOpen, data: finalData = [], renderShowMore } = repeatSettings.todos || {};

    const { id: serviceId } = repeatSettings;

    const [state, dispatchState] = useReducer(reducer, {
        isVisible: !!total,
        isLoadingAddMore: false,
        data: [],
        showMore: true,
        initAddTodo: 0
    });

    const { showMore, isLoadingAddMore, isVisible: fialIsVisible, initAddTodo } = state;

    const refFormAdd = useRef(null);
    const refSaveAsTemplate = useRef(null);
    const refButtonAddNew = useRef(null);
    const refConfirm = useRef(null);
    const refConfirmDeleteTodo = useRef(null);
    const numberCheckList = finalData.length;

    useEffect(() => {
        if (!numberCheckList && fialIsVisible) {
            dispatchState({
                isVisible: false
            });
        }
    }, [numberCheckList]);

    useEffect(() => {
        if (autoOpen) {
            dispatchState({
                isVisible: true,
                initAddTodo: Date.now(),
                isLoading: false
            });
        }
    }, [autoOpen]);

    useEffect(() => {
        const elm = document.getElementById('js-add-todolist');

        if (!numberCheckList) {
            if (elm && !fialIsVisible) {
                elm.classList.remove('is-disable');
            }
        } else {
            if (elm) {
                elm.classList.add('is-disable');
            }
        }
    }, [finalData, fialIsVisible]);

    const getItemStyle = (isDragging, draggableStyle) => ({
        ...draggableStyle,
        userSelect: 'none',
        opacity: isDragging ? 0.7 : 1,
        backgroundColor: isDragging ? 'rgba(0, 0, 0, 0.07)' : 'transparent',
        border: 1
    });

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const _onDragEnd = (result) => {
        if (!result.destination) {
            return false;
        }

        onChangeState((prev) => {
            const prevTodos = prev.todos || {};
            return {
                ...prev,
                todos: {
                    ...prevTodos,
                    data: reorder(prevTodos.data, result.source.index, result.destination.index)
                },
                isUpdateTodo: true
            };
        });
    };

    /**
     * If item new without api create response please not call api update
     * @param {*} newItem
     */
    const _handleUpdateItem = (newItem) => {
        onChangeState((prev) => {
            const prevTodos = prev.todos || {};
            return {
                ...prev,
                todos: {
                    ...prevTodos,
                    data: prevTodos.data.map((item) => {
                        if (item.id === newItem.id) {
                            return {
                                ...item,
                                ...newItem
                            };
                        }
                        return item;
                    })
                },
                isUpdateTodo: true
            };
        });
    };

    const _handleUpdateStatus = (newItem) => {
        onChangeState((prev) => {
            const prevTodos = prev.todos || {};
            return {
                ...prev,
                todos: {
                    ...prevTodos,
                    data: prevTodos.data.map((item) => {
                        if (item.id === newItem.id) {
                            return {
                                ...item,
                                ...newItem
                            };
                        }
                        return item;
                    })
                },
                isUpdateTodo: true
            };
        });
    };

    const _openFormAdd = () => {
        refFormAdd.current._open();
        refButtonAddNew.current.className = 'v2-btn-default --icon-lg --transparent tooltip is-disable';
    };

    const _handleConfirmDelete = (itemId) => {
        refConfirm.current.open(itemId);
    };

    const _handleConfirmDeleteTodo = (itemId) => {
        refConfirmDeleteTodo.current.open(itemId);
    };

    const _handleToggleShowMore = () => {
        dispatchState((prev) => {
            return {
                ...prev,
                showMore: !prev.showMore
            };
        });
    };

    const _handleSelectTemplate = (templateId) => {
        dispatchState((prev) => {
            return {
                ...prev,
                isLoadingAddMore: true,
                showMore: false
            };
        });

        clientQuery(getCheckListImTemplate(templateId), { method: 'GET' }, _handleSelectTemplaceSuccess);
    };

    const _handleSelectTemplaceSuccess = ({ data: itemsAdded = [] }) => {
        onChangeState((prev) => {
            const prevTodos = prev.todos || {};
            const newDataCheckList = [...(prevTodos.data || []), ...itemsAdded];

            return {
                ...prev,
                todos: {
                    ...prevTodos,
                    data: newDataCheckList,
                    renderShowMore: newDataCheckList.length > 10
                },
                isUpdateTodo: true
            };
        });

        dispatchState((prev) => {
            return {
                ...prev,
                isLoadingAddMore: false
            };
        });
    };

    const _handleOpenFormSaveAs = () => {
        refSaveAsTemplate.current._open(
            finalData
                .filter((item) => !!!item.status)
                .map((item) => {
                    return {
                        id: uuidv4(),
                        description: item.description
                    };
                })
        );
    };

    const _handleAddNewCheckList = (description) => {
        const newData = {
            id: uuidv4(),
            description,
            status: 0
        };

        onChangeState((prev) => {
            const prevTodos = prev.todos || {};
            const newDataAdd = [...(prevTodos.data || []), { ...newData, addNew: true }];
            return {
                ...prev,
                todos: {
                    ...prevTodos,
                    data: newDataAdd,
                    renderShowMore: newDataAdd.length > 10
                },
                isUpdateTodo: true
            };
        });
    };

    const _handleCloseFormAdd = (isSave = false) => {
        refButtonAddNew.current.className = 'v2-btn-default --icon-lg --transparent tooltip';
        if (!numberCheckList && !isSave) {
            setTimeout(() => {
                dispatchState((prev) => {
                    return {
                        ...prev,
                        isVisible: false
                    };
                });
            }, 0);
        }
    };

    const _handleDeleteTodo = () => {
        onChangeState((prev) => {
            return {
                ...prev,
                todos: {
                    data: [],
                    renderShowMore: false
                },
                isUpdateTodo: true
            };
        });
        onDelete();
    };

    const _handleDeleteItem = (itemId) => {
        onChangeState((prev) => {
            const prevTodos = prev.todos || {};

            const newData = prevTodos.data.filter((item) => {
                if (item.id !== itemId) {
                    return item;
                }
            });

            return {
                ...prev,
                todos: {
                    ...prevTodos,
                    data: newData,
                    renderShowMore: newData.length > 10
                },
                isUpdateTodo: true
            };
        });
    };

    function _openForm() {
        dispatchState({
            isVisible: true,
            initAddTodo: Date.now(),
            isLoading: false
        });
    }

    const _renderCheckList = () => {
        return (
            <DragDropContext onDragEnd={_onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {finalData.map((item, index) => {
                                if (showMore && index > 9) {
                                    return false;
                                }
                                const serviceItemId = item.id || index.toString();
                                return (
                                    <Draggable key={serviceItemId} draggableId={serviceItemId} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                            >
                                                <CheckListDetail
                                                    onUpdate={_handleUpdateItem}
                                                    onDelete={_handleConfirmDelete}
                                                    onUpdateStatus={_handleUpdateStatus}
                                                    key={item.id}
                                                    itemDetail={item}
                                                    isShowComplete={false}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    };

    return (
        <div className={`tab-conts form-tabpane-details ${tabActive ? 'tab-content-active' : ''}`}>
            <div className="container-setting-center dashboard-wrapper border-none is-todolist">
                <div className="wrap-header flexcenter mb-4 flex-wrap gap-10">
                    <h3 className="fs-17 flex-1 nowrap">{t('setting:todo_list')}</h3>
                    {!!serviceId && <UpdateTodo serviceId={serviceId} />}
                    <div
                        className={`v2-btn-main has-icon svg-white  ${
                            fialIsVisible || finalData.length > 0 ? 'is-disable' : ''
                        }`}
                        id={'js-add-todolist'}
                        onClick={_openForm}
                    >
                        <IconPlus />
                        {t('common:add_todo_list')}
                    </div>
                </div>
                {fialIsVisible && (
                    <div className="wrap-todolist boxs --topnote is-show p-0">
                        <div className="wrapper-template content">
                            <div className="content__header flexcenter">
                                <h5 className="title-font15 fw-500 flex-1">{t('setting:todo_list')}</h5>
                                <Options
                                    onSelectTemplate={_handleSelectTemplate}
                                    onSaveAsTemplate={_handleOpenFormSaveAs}
                                    onDeleteTodo={_handleConfirmDeleteTodo}
                                    numberCheckList={numberCheckList}
                                />
                                <div
                                    ref={refButtonAddNew}
                                    onClick={_openFormAdd}
                                    className={'v2-btn-default --icon-lg --transparent tooltip'}
                                >
                                    <IconPlus />
                                    <p className="tooltiptext top ml-n1">{t('jobDetail:add_a_new_todo')}</p>
                                </div>
                            </div>
                            <Add
                                autoOpen={initAddTodo}
                                ref={refFormAdd}
                                onSave={_handleAddNewCheckList}
                                onClose={_handleCloseFormAdd}
                            />
                            <GdConfirm
                                ref={refConfirm}
                                title={t('setting:delete_todo')}
                                message={t('setting:message_delete_todo')}
                                titleConfirm={t('common:yes')}
                                listButton={{ confirm: true, cancel: true }}
                                onConfirm={_handleDeleteItem}
                            />
                            <GdConfirm
                                ref={refConfirmDeleteTodo}
                                title={t('setting:delete_todo_list')}
                                message={t('setting:message_delete_todo_list')}
                                titleConfirm={t('common:yes')}
                                listButton={{ confirm: true, cancel: true }}
                                onConfirm={_handleDeleteTodo}
                            />
                            <SaveAsTemplate ref={refSaveAsTemplate} />
                            <div className="wrap-todo-items">
                                {_renderCheckList()}
                                {isLoadingAddMore && <LoadingMore />}
                                {renderShowMore && <ShowMore isShowMore={showMore} onToggle={_handleToggleShowMore} />}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

import React from 'react';
import { useTranslation } from 'react-i18next';

const ResourceAreaHeader = () => {
    const { t } = useTranslation();
    return (
        <div className="rows --header">
            <div className="col col-date">{t('common:date')}</div>
            <div className="col col-clock">{t('common:clock_in_out')}</div>
            <div className="col col-duration">{t('calendar:duration')}</div>
        </div>
    );
};

export default ResourceAreaHeader;

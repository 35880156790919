import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import GdGridView from 'app/components/grid/GdGridView';
import { getDataWithFreeSeat } from 'common/utils/FunctionUtils';
import { ActiveUserHeader } from './ActiveUserHeader';
import { getGridColumnsActiveVoipUser } from './ActiveVoipUsersTable';

const ActiveUserGridView = ({
    isVoipTechnicianSeat = false,
    isLoading = false,
    data = [],
    account = {},
    groupNumber = [],
    handleAddUser = () => {},
    handleFailed = () => {}
}) => {
    const { t } = useTranslation();

    if (isVoipTechnicianSeat && !account?.free_seats) return null;
    return (
        <Fragment>
            <ActiveUserHeader
                users={data}
                isVoipTechnicianSeat={isVoipTechnicianSeat}
                account={account}
                isLoading={isLoading}
                onAddUser={handleAddUser}
                handleFailed={handleFailed}
            />
            <div className="wrap-tables">
                <GdGridView
                    isLoading={isLoading}
                    isHasDropdown
                    content={getDataWithFreeSeat({ data, checkFreeSeat: isVoipTechnicianSeat })}
                    classTable="table-multi-column table-user multi-tables"
                    fileTranslation="addons"
                    {...getGridColumnsActiveVoipUser({ groupNumber, isVoipTechnicianSeat })}
                    isScroll
                    msgEmpty={t(
                        `addons:${isVoipTechnicianSeat ? 'there_are_currently_no_technician_users_added' : 'there_are_currently_no_users_added'}`
                    )}
                />
            </div>
        </Fragment>
    );
};

export default ActiveUserGridView;

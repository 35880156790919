import React from 'react';

import IconAmount from 'assets/icon/IconAmount';
import IconCalendar from 'assets/icon/IconCalendar';
import IconEstimate from 'assets/icon/IconEstimate';
import IconInvoice from 'assets/icon/IconInvoice';
import IconDone from 'assets/icon/IconDone';
import IconOnlineBooking from 'assets/icon/IconOnlineBooking';
import IconStar from 'assets/icon/IconStar';
import IconUser from 'assets/icon/IconUser';
import IconDocument from 'assets/icon/IconDocument';
import IconCall from 'assets/icon/IconCall';
import { DEFAULT_VALUE_SLIDER, ONE_HUNDRED_PERCENT } from './addons';

export const LIST_TITLE_ADDON = {
    report: { titleMenu: 'reports', classIcon: 'menu-reports' },
    calendar: { titleMenu: 'calendar', classIcon: 'menu-calendar' },
    customers: { titleMenu: 'customers', classIcon: 'menu-customers' },
    inbox: { titleMenu: 'inbox', classIcon: 'menu-inbox' },
    reports: { titleMenu: 'reports', classIcon: 'menu-reports' },
    addons: { titleMenu: 'addons', classIcon: 'menu-addons' },
    settings: { titleMenu: 'settings', classIcon: 'menu-settings' },
    reviews: { titleMenu: 'reviews', classIcon: 'menu-reviews' },
    commissions: {
        titleMenu: 'commission_tracking',
        classIcon: 'menu-commission'
    },
    drip: { titleMenu: 'drip', classIcon: 'menu-drip' }
};

const ONLINE_PAYMENT_NOTIFICATION = 'onlinePaymentNotification';
const JOB_CONFIRMATION_NOTIFICATION = 'jobConfirmationNotification';
const JOB_RESCHEDULE_NOTIFICATION = 'jobRescheduleNotification';
const E_SIGNED_DOCUMENT_NOTIFICATION = 'eSignedDocumentNotification';
const E_SIGNED_ESTIMATE_NOTIFICATION = 'eSignedEstimateNotification';
const E_SIGNED_INVOICE_NOTIFICATION = 'eSignedInvoiceNotification';
const INBOUND_LEADS_NOTIFICATION = 'inboundLeadsNotification';
const SUBSCRIPTION_NOTIFICATION = 'subscriptionNotification';
const REVIEWS_NOTIFICATION = 'reviewsNotification';
const ONLINE_BOOKING_NOTIFICATION = 'onlineBookingNotification';
const MISSED_CALL_NOTIFICATION = 'voipMissedCallNotification';

export const ICONS_NOTIFICATION_SETTINGS = {
    [ONLINE_PAYMENT_NOTIFICATION]: <IconAmount />,
    [JOB_CONFIRMATION_NOTIFICATION]: <IconDone isHasColor />,
    [JOB_RESCHEDULE_NOTIFICATION]: <IconCalendar isHasDollar />,
    [E_SIGNED_DOCUMENT_NOTIFICATION]: <IconDocument isActive />,
    [E_SIGNED_ESTIMATE_NOTIFICATION]: <IconEstimate isActive />,
    [E_SIGNED_INVOICE_NOTIFICATION]: <IconInvoice isActive />,
    [INBOUND_LEADS_NOTIFICATION]: <IconUser isCustomer />,
    [SUBSCRIPTION_NOTIFICATION]: <IconCalendar isHasDollar />,
    [REVIEWS_NOTIFICATION]: <IconStar isChecked />,
    [ONLINE_BOOKING_NOTIFICATION]: <IconOnlineBooking isSmallGrey />,
    [MISSED_CALL_NOTIFICATION]: <IconCall isHeaderNotification isInActive />
};

export const ICONS_NOTIFICATION_SETTINGS_ACTIVE = {
    [ONLINE_PAYMENT_NOTIFICATION]: <IconAmount isActiveColor />,
    [JOB_CONFIRMATION_NOTIFICATION]: <IconDone isActiveColor />,
    [JOB_RESCHEDULE_NOTIFICATION]: <IconCalendar isDollarActive />,
    [E_SIGNED_DOCUMENT_NOTIFICATION]: <IconDocument isActiveColor />,
    [E_SIGNED_ESTIMATE_NOTIFICATION]: <IconEstimate isActiveColor />,
    [E_SIGNED_INVOICE_NOTIFICATION]: <IconInvoice isActiveColor />,
    [INBOUND_LEADS_NOTIFICATION]: <IconUser isCustomerActive />,
    [SUBSCRIPTION_NOTIFICATION]: <IconCalendar isDollarActive />,
    [REVIEWS_NOTIFICATION]: <IconStar isCheckActive />,
    [ONLINE_BOOKING_NOTIFICATION]: <IconOnlineBooking isSmallPurple />,
    [MISSED_CALL_NOTIFICATION]: <IconCall isHeaderNotification />
};

export const GET_NOTIFICATION_TIMEOUT = 60;
export const GET_NOTIFICATION_PERMISSIONS_TIMEOUT = 60;

// **
// order sensitive.
// */
export const TAB_NOTIFICATIONS = {
    UNREAD: 'UNREAD',
    READ: 'READ'
};

export const NOTIFICATION_SELECTALL_STATE = {
    CHECK: 'CHECK',
    UNCHECK: 'UNCHECK',
    SOME: 'SOME'
};

export const MAP_TAB_NOTIFICATION = Object.keys(TAB_NOTIFICATIONS).map((k) => {
    const v = TAB_NOTIFICATIONS[k];
    return {
        id: v,
        name: v,
        value: v,
        showNumber: v !== TAB_NOTIFICATIONS.READ
    };
});

export const QUEST_LOG_LEVELS = {
    LEVEL1: 'LEVEL 1',
    LEVEL2: 'LEVEL 2',
    LEVEL3: 'LEVEL 3'
};

export const MAP_QUEST_LOG_LEVELS = Object.values(QUEST_LOG_LEVELS);

export const ID_QUEST_LOG = 'quest_log';
export const KEY_LOCAL_STORAGE_BANNER = 'hidden_banner';
export const DAYS_SHOW_AGAIN_BANNER = 45 * 1000 * 3600 * 24; //timeStamp
const BRIGHTNESS = 'brightness';
const CONTRAST = 'contrast';
export const DARK_MODE_INFO = {
    CLASSNAME_DARK_MODE: '--dark-mode',
    ID_DARK_MODE: 'dark_mode',
    VALUE: 'value',
    SEPIA: 'sepia',
    BRIGHTNESS: BRIGHTNESS,
    CONTRAST: CONTRAST,
    GRAYSCALE: 'grayscale',
    LIST_TAB_DARK_MODE: [
        { id: 1, value: 1, label: 'on' },
        { id: 0, value: 0, label: 'off' }
    ],
    DEFAULT_SETTING: {
        sepia: 0,
        brightness: ONE_HUNDRED_PERCENT,
        contrast: DEFAULT_VALUE_SLIDER,
        grayscale: 0
    },
    PREFIX: '--',
    SPECIAL_SETTING: [CONTRAST],
    DARK: 'dark',
    LIGHT: 'light'
};

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AddPaymentContext } from 'app/modules/jobdetail/contexts/AddPaymentContext';
import { roundingMoney, transformToCurrency } from 'common/utils/NumberUtils';

const PaymentSummary = () => {
    const { t } = useTranslation();
    const { addPayment } = useContext(AddPaymentContext);
    const currency = useSelector(({ auth }) => auth.user.settings.currency);

    const {
        surchargeValue: finalSurchargeValue,
        paymentApplied: finalPaymentApplied,
        balanceDueValue: finalBalanceDueValue,
        newCredit: finalNewCredit
    } = addPayment;

    function _renderSurcharge() {
        if (!addPayment.activeSurcharge) {
            return false;
        }
        return (
            <>
                <div className="sm-row">
                    <div className="txt"> {t('jobDetail:surcharge')} </div>
                    <div className="detail --large txt-ellipsis pl-1">{roundingMoney(finalSurchargeValue)}</div>
                </div>
                <div className="line" />
            </>
        );
    }

    return (
        <div className="rows items-payment">
            <div className="items-payment-number">
                {_renderSurcharge()}
                <div className="sm-row">
                    <div className="txt"> {t('jobDetail:payment_applied')} </div>
                    <div className="detail txt-ellipsis pl-1">{`${transformToCurrency(
                        parseFloat(finalPaymentApplied || 0),
                        currency
                    )}`}</div>
                </div>
                <div className="line" />
                <div className="sm-row">
                    <div className="txt"> {t('jobDetail:updated_balance')} </div>
                    <div className="detail --large txt-ellipsis pl-1">{`${transformToCurrency(
                        parseFloat(finalBalanceDueValue || 0),
                        currency
                    )}`}</div>
                </div>
                {finalNewCredit > 0 ? (
                    <>
                        <div className="line" />
                        <div className="sm-row">
                            <div className="txt"> {t('jobDetail:new_credit')} </div>
                            <div className="detail --large txt-ellipsis pl-1">{`${transformToCurrency(
                                parseFloat(finalNewCredit || 0),
                                currency
                            )}`}</div>
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
};

PaymentSummary.propTypes = {};

export default PaymentSummary;

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TABS } from 'app/modules/jobdetail/const';
import { ESTIMATE_TYPE } from 'app/modules/jobdetail/const/Estimate';
import { DEFAULT_VALUE, INVOICE_ITEM_SIMPLE } from 'app/modules/jobdetail/const/Invoice';
import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';
import IconColumns from 'assets/icon/IconColumns';
import IconDocument from 'assets/icon/IconDocument';
import IconEstimate from 'assets/icon/IconEstimate';
import IconInvoice from 'assets/icon/IconInvoice';
import IconMaterial from 'assets/icon/IconMaterial';
import IconPlus from 'assets/icon/IconPlus';
import IconServiceTemplate from 'assets/icon/IconServiceTemplate';

export default function SideBar({ tabActive, onChange, classStyle = '' }) {
    const { t } = useTranslation();
    const { repeatSettings, onChangeDataContext } = useContext(DataRepeatContext);
    const { documents: isActiveDocument, material: isActiveMaterial } =
        useSelector(({ auth }) => auth.user?.settings?.addons) || {};

    function _handleChangeTab(e, tab) {
        e && e.stopPropagation();
        onChange(tab);
    }

    const {
        id: serviceId,
        invoice: serviceInvoice,
        start: serviceStartDate,
        estimate: serviceEstimate,
        todos: serviceTodos,
        materials: serviceMaterials,
        documents: serviceDocuments
    } = repeatSettings;

    function _handleAddInvoice(e) {
        e && e.stopPropagation();

        if (!serviceInvoice) {
            const dateRef = serviceStartDate;

            const newDataInvoice = {
                ...DEFAULT_VALUE,
                date: dateRef,
                recurrence: {
                    ...DEFAULT_VALUE.recurrence,
                    offset: {
                        ...DEFAULT_VALUE.recurrence.offset,
                        nextDateInvoice: dateRef, // Date next invoice
                        dateIssue: dateRef // Date create invoice
                    },
                    summary: t('jobDetail:this_invoice_repeats_with_the_job')
                },
                items: [
                    {
                        ...INVOICE_ITEM_SIMPLE,
                        id: new Date().getTime().toString()
                    }
                ]
            };
            onChangeDataContext({ ...repeatSettings, invoice: newDataInvoice });
        }

        onChange(TABS.INVOICE);
    }

    function _handleAddEstimate(e) {
        e && e.stopPropagation();

        if (!serviceEstimate) {
            const dateRef = serviceStartDate;
            const newDataInvoice = {
                ...DEFAULT_VALUE,
                date: dateRef,
                recurrence: {
                    ...DEFAULT_VALUE.recurrence,
                    offset: {
                        ...DEFAULT_VALUE.recurrence.offset,
                        nextDateInvoice: dateRef, // Date next invoice
                        dateIssue: dateRef // Date create invoice
                    }
                },
                items: [
                    {
                        ...INVOICE_ITEM_SIMPLE,
                        id: new Date().getTime().toString()
                    }
                ],
                type: ESTIMATE_TYPE.BASIC,
                packages: []
            };

            onChangeDataContext({ ...repeatSettings, estimate: newDataInvoice });
        }

        onChange(TABS.ESTIMATE);
    }

    function _handleAddTodoList(e) {
        e && e.stopPropagation();

        onChangeDataContext({
            ...repeatSettings,
            todos: {
                data: [],
                renderShowMore: false,
                autoOpen: true
            }
        });

        onChange(TABS.CHECK_LIST);
    }

    function _renderTabInvoice() {
        if (!serviceInvoice) {
            return false;
        }
        return (
            <li
                className={`tab-items ${tabActive === TABS.INVOICE ? 'active-tab-selector' : ''}`}
                onClick={(e) => _handleChangeTab(e, TABS.INVOICE)}
                tabIndex="0"
            >
                <IconInvoice />
                {t('setting:invoice')}
            </li>
        );
    }

    function _renderTabDocument() {
        if (!serviceDocuments?.length || !isActiveDocument) {
            return false;
        }

        return (
            <li
                className={`tab-items ${tabActive === TABS.DOCUMENTS ? 'active-tab-selector' : ''}`}
                onClick={(e) => _handleChangeTab(e, TABS.DOCUMENTS)}
                tabIndex="0"
            >
                <IconDocument />
                {t('setting:documents')}
            </li>
        );
    }

    function _renderTabMaterial() {
        if (!serviceMaterials?.length || !isActiveMaterial) {
            return false;
        }
        return (
            <li
                className={`tab-items ${tabActive === TABS.MATERIALS ? 'active-tab-selector' : ''}`}
                onClick={(e) => _handleChangeTab(e, TABS.MATERIALS)}
                tabIndex="0"
            >
                <IconMaterial />
                {t('setting:materials')}
            </li>
        );
    }

    function _renderTabAddInvoice() {
        if (serviceInvoice) {
            return false;
        }

        return (
            <li onClick={(e) => _handleAddInvoice(e)} className="tab-items svg-plus svg-noeffect" tabIndex="0">
                <IconPlus />
                <span className="flex-1 txt-ellipsis">
                    {t('setting:add_invoices')}
                </span>
            </li>
        );
    }

    function _renderTabEstimate() {
        if (!serviceEstimate) {
            return false;
        }
        return (
            <li
                className={`tab-items ${tabActive === TABS.ESTIMATE ? 'active-tab-selector' : ''}`}
                onClick={(e) => _handleChangeTab(e, TABS.ESTIMATE)}
                tabIndex="0"
            >
                <IconEstimate />
                {t('setting:estimate')}
            </li>
        );
    }

    function _renderTabAddEstimate() {
        if (serviceEstimate) {
            return false;
        }
        return (
            <li onClick={(e) => _handleAddEstimate(e)} className="tab-items svg-plus svg-noeffect" tabIndex="0">
                <IconPlus />
                <span className="flex-1 txt-ellipsis">
                    {t('setting:add_estimates')}
                </span>
            </li>
        );
    }

    function _renderTabAddTodos() {
        if (!!serviceTodos?.data?.length) {
            return false;
        }
        return (
            <li
                onClick={(e) => _handleAddTodoList(e)}
                className={`tab-items svg-plus svg-noeffect ${
                    tabActive === TABS.CHECK_LIST ? 'active-tab-selector' : ''
                }`}
                tabIndex="0"
            >
                <IconPlus />
                <span className="flex-1 txt-ellipsis">
                    {t('common:add_todo_list')}
                </span>
            </li>
        );
    }

    function _renderTabAddMaterial() {
        if (!!serviceMaterials?.length || !isActiveMaterial) {
            return false;
        }
        return (
            <li
                onClick={(e) => _handleChangeTab(e, TABS.MATERIALS)}
                className={`tab-items svg-plus svg-noeffect ${
                    tabActive === TABS.MATERIALS ? 'active-tab-selector' : ''
                }`}
                tabIndex="0"
            >
                <IconPlus />
                <span className="flex-1 txt-ellipsis">
                    {t('setting:add_material')}
                </span>
            </li>
        );
    }

    function _renderTabAddDocuments() {
        if (!!serviceDocuments?.length || !isActiveDocument) {
            return false;
        }
        return (
            <li
                onClick={(e) => _handleChangeTab(e, TABS.DOCUMENTS)}
                className={`tab-items svg-plus svg-noeffect ${
                    tabActive === TABS.DOCUMENTS ? 'active-tab-selector' : ''
                }`}
                tabIndex="0"
            >
                <IconPlus />
                <span className="flex-1 txt-ellipsis">
                    {t('setting:add_documents')}
                </span>
            </li>
        );
    }

    function _renderTabTodoList() {
        if (!!!serviceTodos?.data?.length) {
            return false;
        }

        return (
            <li
                className={`tab-items svg-noeffect svg-todo ${
                    tabActive === TABS.CHECK_LIST ? 'active-tab-selector' : ''
                }`}
                onClick={(e) => _handleChangeTab(e, TABS.CHECK_LIST)}
                tabIndex="0"
            >
                <IconColumns isChecklist />
                <div className="flex-1 txt-ellipsis">{t('setting:todo_list')}</div>
            </li>
        );
    }

    return (
        <div className={`${classStyle} sidebar-pages scrolls pt-0 px-2`}>
            <div className="header-title px-1">
                <h2 className="header-title__label">
                    {t(`pageTitle:${!!serviceId ? 'edit_service' : 'add_service'}`)}
                </h2>
            </div>
            <ul className="tab-selectors">
                <li
                    className={`tab-items ${tabActive === TABS.DETAIL ? 'active-tab-selector' : ''}`}
                    onClick={(e) => _handleChangeTab(e, TABS.DETAIL)}
                    tabIndex="0"
                >
                    <IconServiceTemplate />
                    {t('report:service')}
                </li>
                {_renderTabInvoice()}
                {_renderTabEstimate()}
                {_renderTabMaterial()}
                {_renderTabDocument()}
                {_renderTabTodoList()}

                <div className="line" />
                {_renderTabAddInvoice()}
                {_renderTabAddEstimate()}
                {_renderTabAddMaterial()}
                {_renderTabAddDocuments()}
                {_renderTabAddTodos()}
            </ul>
        </div>
    );
}

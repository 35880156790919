import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ROUTING_UNITS } from 'app/modules/addons/advanceRouting/constants';
import { convertSecondsToETA, ETA_TIME_FORMAT_TYPES } from 'common/utils/TimeUtils';
const EventDriveTime = ({ customStyle = {}, time, distance = {}, isOverlap = false }) => {
    const { t } = useTranslation(['calendar']);
    const timeArrived = convertSecondsToETA(time || 0, { haveSpace: true, typeFormat: ETA_TIME_FORMAT_TYPES.SHORT });
    const distanceString = `${distance.value} ${t(`${ROUTING_UNITS[distance.unit]?.label}`).toLowerCase()}`;
    const title = timeArrived + ' | ' + distanceString;
    return (
        <div
            title={title}
            className={classNames('drive-time', { '--purple': !isOverlap, '--red': isOverlap })}
            style={{ height: customStyle.height }}
        >
            <p className="fs-10 fw-600">
                <span>{timeArrived}</span> | {distanceString}
            </p>
        </div>
    );
};
export default EventDriveTime;

import moment from 'moment-timezone';
import { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import { reducer } from 'app/const/Reducer';
import { EN } from 'app/const/Settings';
import IconClose from 'assets/icon/IconClose';
import { DATE_FORMAT_ENGLISH, DATE_FORMAT_SPANISH } from 'app/const/App';

const ModalRecurringJob = forwardRef((props, ref) => {
    const { t, i18n } = useTranslation(['calendar, common']);
    const refEvents = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        time: ''
    });
    const i18nLanguageCode = i18n.language;

    function _showOptions(infoEvent, functionCallback) {
        refEvents.current = {
            infoEvent,
            functionCallback
        };
        const finalJobTimeEnd = infoEvent?.end || infoEvent?.event?.end;
        dispatchState({
            isVisible: true,
            time: moment(finalJobTimeEnd)
                .utc()
                .locale(i18nLanguageCode)
                .format(i18nLanguageCode === EN ? DATE_FORMAT_ENGLISH : DATE_FORMAT_SPANISH)
        });
    }

    useImperativeHandle(ref, () => ({
        _showOptions
    }));

    function _closeOptions(e) {
        e.stopPropagation();
        dispatchState({ isVisible: false });
    }

    function _handleClickOption(e) {
        e.stopPropagation();
        dispatchState({ isVisible: false });

        refEvents.current && refEvents.current.functionCallback();
    }

    function _renderReSchedule() {
        if (!state.isVisible) {
            return false;
        }

        return (
            <ReactModal
                isOpen
                onRequestClose={_closeOptions}
                className="modal container-modal batch-job --reschedule open"
            >
                <div className="modal__overlay bg-fixed" onClick={(e) => _closeOptions(e)} />
                <div className="modal__container">
                    <div className="header-modal">
                        <h3 className="header-modal__label">{t('calendar:recurring_job')}</h3>
                        <div onClick={(e) => _closeOptions(e)} className="v2-btn-default --icon-lg --transparent">
                            <IconClose />
                        </div>
                    </div>
                    <div className="body-modal scrolls">
                        <p className="desc fw-600">
                            {t('calendar:recurring_job_popup_description_1')} {state.time}
                        </p>
                        <p className="desc mt-2">{t('calendar:recurring_job_popup_description_2')}</p>
                    </div>
                    <div className="footer-modal btn-close">
                        <span onClick={(e) => _closeOptions(e)} className="v2-btn-default --transparent">
                            {t('common:cancel')}
                        </span>
                        <div onClick={(e) => _handleClickOption(e)} className="v2-btn-main">
                            {t('calendar:open_incomplete_job')}
                        </div>
                    </div>
                </div>
            </ReactModal>
        );
    }

    return _renderReSchedule();
});

export default ModalRecurringJob;

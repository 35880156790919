export default function IconTypes({ isGroup = false }) {
    if (isGroup)
        return (
            <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.99899 4.02559C7.70296 4.02559 6.61684 4.93099 6.3415 6.14442C6.26206 6.49451 5.91387 6.71391 5.56378 6.63447C5.2137 6.55503 4.99429 6.20684 5.07373 5.85675C5.48048 4.06419 7.08263 2.72559 8.99899 2.72559C10.9153 2.72559 12.5175 4.06419 12.9242 5.85675C13.0037 6.20684 12.7843 6.55503 12.4342 6.63447C12.0841 6.71391 11.7359 6.49451 11.6565 6.14442C11.3811 4.93099 10.295 4.02559 8.99899 4.02559ZM5.99766 8.52559C4.49268 8.52559 3.27266 9.74561 3.27266 11.2506C3.27266 12.7556 4.49268 13.9756 5.99766 13.9756C6.43387 13.9756 6.8444 13.8736 7.20838 13.6927C7.52986 13.5329 7.91998 13.664 8.07974 13.9855C8.2395 14.307 8.1084 14.6971 7.78693 14.8568C7.2472 15.1251 6.63907 15.2756 5.99766 15.2756C3.77471 15.2756 1.97266 13.4735 1.97266 11.2506C1.97266 9.02764 3.77471 7.22559 5.99766 7.22559C6.63907 7.22559 7.2472 7.3761 7.78693 7.64432C8.1084 7.80408 8.2395 8.1942 8.07974 8.51568C7.91998 8.83715 7.52986 8.96825 7.20838 8.80849C6.8444 8.6276 6.43387 8.52559 5.99766 8.52559ZM9.27266 11.2506C9.27266 9.74561 10.4927 8.52559 11.9977 8.52559C13.5026 8.52559 14.7227 9.74561 14.7227 11.2506C14.7227 12.7556 13.5026 13.9756 11.9977 13.9756C10.4927 13.9756 9.27266 12.7556 9.27266 11.2506ZM11.9977 7.22559C9.77471 7.22559 7.97266 9.02764 7.97266 11.2506C7.97266 13.4735 9.77471 15.2756 11.9977 15.2756C14.2206 15.2756 16.0227 13.4735 16.0227 11.2506C16.0227 9.02764 14.2206 7.22559 11.9977 7.22559Z"
                    fill="var(--color-icon)"
                />
            </svg>
        );

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.3884 8C15.9337 5.99601 14.1415 4.5 11.9999 4.5C9.85835 4.5 8.06618 5.99601 7.61145 8"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M10 19.0322C9.39752 19.3316 8.71842 19.5 8 19.5C5.51472 19.5 3.5 17.4853 3.5 15C3.5 12.5147 5.51472 10.5 8 10.5C8.71842 10.5 9.39752 10.6684 10 10.9678"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M16 19.5C18.4853 19.5 20.5 17.4853 20.5 15C20.5 12.5147 18.4853 10.5 16 10.5C13.5147 10.5 11.5 12.5147 11.5 15C11.5 17.4853 13.5147 19.5 16 19.5Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
}

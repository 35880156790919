import { COMMON } from 'app/const/App';
import PropTypes from 'prop-types';
import IconTooltip from 'assets/icon/IconTooltip';
import IconError from 'assets/icon/IconError';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
const { NUMBER } = COMMON;

const Input = forwardRef(
    (
        {
            label,
            type,
            name,
            error,
            placeholder,
            handleKeypress,
            classWrapper,
            classWrapperInput,
            classInput,
            classLabel = 'txt',
            initValue = '',
            classWrapperInputIcon,
            tooltip,
            isRequired,
            onChange = () => {},
            autoFocus = false,
            spellCheck = true,
            maxLengthInput = null,
            regexCheckNumber = null,
            isShowIconError = true,
            currencyUnit = '',
            ...restInputProps
        },
        ref
    ) => {
        useImperativeHandle(ref, () => ({
            _getValue,
            _setValue,
            _setError
        }));

        const [stateError, setStateError] = useState();
        const [value, setValue] = useState(initValue);

        useEffect(() => {
            setStateError(error);
            return;
        }, [error]);

        const _getValue = () => {
            return value;
        };
        const _setValue = (value = '') => {
            setValue(value);
        };
        const _setError = (value = null) => {
            setStateError(value);
        };

        /***
         *  We validate some characters , which we don`t want user type to input.
         *  In case NUMBER : any character A-Z
         *  Regex explanation : https://regex101.com
         *  ***/
        const _onKeyDown = (event) => {
            switch (type) {
                case NUMBER:
                    /^[eE]{1}$/.test(event.key) && event.preventDefault();
                    break;
                default:
                    break;
            }
        };

        const _onChange = (event) => {
            const { value } = event.target;
            if (maxLengthInput && value.length > maxLengthInput) {
                event.preventDefault();
                return;
            }
            switch (type) {
                case NUMBER:
                    if (regexCheckNumber) {
                        (regexCheckNumber.test(value) || value === '') && setValue(value);
                    } else {
                        !/^[a-zA-Z]$/.test(value) && setValue(value);
                    }
                    break;
                default:
                    setValue(value);
                    break;
            }
            onChange(event);
        };

        const renderInput = () => {
            const result = (
                <input
                    className={`${classInput ? classInput : ''} ${stateError ? 'field-error' : ''} field-input`}
                    placeholder={placeholder}
                    onKeyPress={handleKeypress}
                    type={type}
                    name={name}
                    value={value}
                    onChange={_onChange}
                    onKeyDown={_onKeyDown}
                    autoFocus={autoFocus}
                    spellCheck={spellCheck}
                    {...restInputProps}
                />
            );

            if (tooltip)
                return (
                    <div className={`relative has-tooltip ${classWrapperInputIcon ? classWrapperInputIcon : ''}`}>
                        {result}
                        <div className="w-tooltip absolute">
                            <div className="tooltip">
                                <IconTooltip />
                                <span className="tooltiptext top">{tooltip}</span>
                            </div>
                        </div>
                    </div>
                );

            if (classWrapperInputIcon)
                return (
                    <div className={classWrapperInputIcon} data-currency-unit={currencyUnit}>
                        {result}
                    </div>
                );

            return result;
        };

        const _renderLabel = () => {
            if (isRequired)
                return (
                    <div className={classLabel}>
                        {label} <span className="red-default">*</span>
                    </div>
                );
            return <span className={classLabel}>{label}</span>;
        };

        return (
            <div className={classWrapper || 'user-box rows mt-5'}>
                {label && _renderLabel()}
                <div className={classWrapperInput}>{renderInput()}</div>
                {stateError && (
                    <span className="dp-inline-block txt-incorrect">
                        {isShowIconError ? <IconError /> : null}
                        {stateError}
                    </span>
                )}
            </div>
        );
    }
);

Input.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    classWrapper: PropTypes.string,
    classInput: PropTypes.string,
    error: PropTypes.string,
    classWrapperInputIcon: PropTypes.string,
    handleKeypress: PropTypes.func,
    isRequired: PropTypes.bool
};
Input.defaultProps = {
    label: null,
    type: 'text',
    name: null,
    placeholder: null,
    classWrapper: null,
    classInput: null,
    error: null,
    classWrapperInputIcon: null,
    handleKeypress: () => {},
    isRequired: false
};

export default Input;

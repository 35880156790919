import queryString from 'query-string';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import { SIGN_UP_VALIDATE_EMAIL } from 'app/const/api/V2';
import { ACCESS_TOKEN, ENTER_KEY_CODE, SINGULAR_NOUN } from 'app/const/App';
import { SIGNUP_STEP } from 'app/const/Auth';
import {
    MIXPANEL_PROPERTIES,
    SIGN_UP_TRACKING_INFO,
    START_SIGN_UP_FORM,
    SUBMIT_SIGN_UP_FORM
} from 'app/const/Mixpanel';
import { reducer } from 'app/const/Reducer';
import { AUTH_SIGN_UP_SOCIAL, HOME, SIGN_UP_SUCCESS } from 'app/const/Route';
import { LINK_CDN_IMG } from 'app/const/URL';
import AuthError from 'app/modules/auth/components/AuthError';
import LoginSocial from 'app/modules/auth/login/components/FormLoginSocial';
import { restoreOptionJobCalendar } from 'app/modules/calendar/ultil/Calendar';
import { mixpanelSignup } from 'app/modules/mixpanel/MixpanelSignup';
import { getPathWithOnboarding } from 'app/modules/onboarding/utils';
import { REFERRAL_QUERY } from 'app/modules/referral/constant';
import { useSiteTitle } from 'common/hooks/useSiteTitle';
import { clearInfoSignup, userSignUpRequest, userSignupSocialRequest } from 'common/redux/actions/authAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { validateEmail } from 'common/utils/EmailUtils';
import { deepCloneObject, detectKeyPress } from 'common/utils/FunctionUtils';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { validPassword } from 'common/utils/ValidationUtils';
import { getCountryName } from '../utils';
import FormIdentify from './components/FormIdentify';
import FreeTrial from './components/freeTrial';
import { COUNTRY, REGEX_VALIDATE_EMAIL, WORD_VALIDATE_EMAIL } from './components/freeTrial/const';

const Signup = () => {
    useSiteTitle('auth:sign_up');
    const [state, dispatchState] = useReducer(reducer, {
        error: null,
        errorsForm: null,
        formDisable: false,
        step: SIGNUP_STEP.one,
        infoSignup: null
    });
    const { formDisable, errorsForm } = state;

    const { t } = useTranslation(['auth', 'common']);
    const dispatch = useDispatch();

    const refForm = useRef(null);
    const history = useHistory();
    const { search } = useLocation();

    const { [REFERRAL_QUERY]: referralCode } = queryString.parse(search);

    const access_token = localStorage.getItem(ACCESS_TOKEN);
    const isLogin = useSelector(({ auth }) => auth.isLogin);

    useEffect(() => {
        clearInfoSignup();
    }, []);

    const handleNextStep = ({ data, userInfo = {} }) => {
        handleTrackingEvent(
            mixpanelSignup({
                profile: { id: data.user.id, email: userInfo.username, registration_method: MIXPANEL_PROPERTIES.EMAIL },
                event: START_SIGN_UP_FORM
            })
        );
        dispatchState({ step: SIGNUP_STEP.two, infoSignup: { ...data.user, ...(userInfo || {}) }, formDisable: false });
    };

    const handleTracking = (email = '', userInfo = {}) => {
        clientQuery(
            SIGN_UP_TRACKING_INFO,
            { data: { email }, toFormData: false, useGdBranchID: false, method: 'POST' },
            ({ data }) => handleNextStep({ data, userInfo }),
            ({ message }) => dispatchState({ formDisable: false, error: message })
        );
    };

    const handleValidateEmail = ({ userInfo = {} }) => {
        dispatchState({ formDisable: true });
        const handleValidateEmailSuccess = ({ data, message }) => {
            data.is_valid === SINGULAR_NOUN
                ? !data.user
                    ? handleTracking(userInfo.username || '', userInfo)
                    : handleNextStep({ data, userInfo })
                : dispatchState({ errorsForm: { username: message }, formDisable: false });
        };
        clientQuery(
            SIGN_UP_VALIDATE_EMAIL,
            { data: { email: userInfo.username || '' }, toFormData: false, method: 'GET' },
            handleValidateEmailSuccess
        );
    };

    const submitUsernamePassword = () => {
        const msgError = {};
        const { username, password } = refForm.current;
        const lengthForm = refForm.current.length;

        for (let i = 0; i < lengthForm; i++) {
            const { name, value, dataset } = refForm.current[i];
            if (name && !value.trim()) {
                msgError[name] = t(`auth:${dataset?.labelField}`).concat(' ', t('auth:can_not_be_blank'));
            }
        }

        const usernameValue = !!username?.value ? username.value.toLowerCase() : '';
        const passwordValue = password?.value;

        if (usernameValue) {
            if (!validateEmail(usernameValue) || usernameValue.includes(' ')) {
                msgError['username'] = t('common:email_is_not_a_valid_email_address');
            } else if (!REGEX_VALIDATE_EMAIL.test(usernameValue)) {
                dispatchState((prev) => ({
                    ...prev,
                    error: t('auth:username').concat(' ', t('common:can_contain_only', { word: WORD_VALIDATE_EMAIL }))
                }));
                return;
            }
        }

        if (!validPassword(passwordValue)) {
            msgError['password'] = t('common:password_limit').concat(' ', t('common:require_least_7_char_with_1_num'));
        }

        const errorKeys = Object.keys(msgError);
        if (errorKeys.length) {
            refForm.current[errorKeys[0]].focus();
            dispatchState({ errorsForm: msgError });
            return false;
        }
        const userInfo = { username: usernameValue, password: passwordValue };
        handleValidateEmail({ userInfo });
    };

    const submitFormSignup = (data) => {
        const { username, password } = state.infoSignup;
        const postData = deepCloneObject(data);

        postData.company.email = username;
        postData.profile.email = username;
        postData.profile.username = username;
        postData.profile.password = password;
        postData.referral_code = referralCode;

        dispatchState({ error: null });
        dispatch(userSignUpRequest(postData, () => signUpSuccess(postData), signUpFailed));
    };

    const signUpSuccess = ({ profile, company }) => {
        handleTrackingEvent(
            mixpanelSignup({
                profile,
                company: { ...company, [COUNTRY]: getCountryName(company?.[COUNTRY]) },
                event: SUBMIT_SIGN_UP_FORM
            })
        );
        dispatchState({ step: SIGNUP_STEP.three, industry: company.industry });
    };

    const signUpFailed = (response) => {
        dispatchState({ error: response?.message });
    };

    const handleLoginFirebase = (data) => {
        dispatchState({ formDisable: true, error: null, errorsForm: null });
        dispatch(userSignupSocialRequest(data, loginSocialStepCompany, loginSocialFailed));
    };

    const loginSocialStepCompany = (response, params) => {
        if (response.token) {
            restoreOptionJobCalendar();
            history.push(getPathWithOnboarding({ profile: response.profile, settings: response.settings }));
            return;
        } else {
            const { id, email } = response?.user || {};
            const data = {
                id,
                type: params.type,
                email: params.email || email,
                referral_code: referralCode
            };

            handleTrackingEvent(
                mixpanelSignup({
                    profile: {
                        id: data.id,
                        email: data.email,
                        registration_method: MIXPANEL_PROPERTIES.SOCIAL[data.type]
                    },
                    company: { [MIXPANEL_PROPERTIES.INDUSTRY]: '' },
                    event: START_SIGN_UP_FORM
                })
            );
            history.push({ pathname: AUTH_SIGN_UP_SOCIAL, state: { dataUser: data, params } });
        }
    };

    const loginSocialFailed = (response) => {
        dispatchState({ formDisable: false, error: response?.message });
    };

    const _handleKeypress = (e) => {
        if (detectKeyPress(e, ENTER_KEY_CODE)) {
            submitUsernamePassword();
        }
    };

    const _handleFocusField = () => {
        dispatchState({ error: null, errorsForm: null });
    };

    if (state.step === SIGNUP_STEP.two) {
        return <FreeTrial submitSignup={submitFormSignup} errorSignupForm={state.error} userId={state.infoSignup.id} />;
    }

    if (state.step === SIGNUP_STEP.three) {
        return (
            <Redirect
                to={{ pathname: SIGN_UP_SUCCESS, state: { info: state.infoSignup, industry: state.industry || '' } }}
            />
        );
    }

    if (isLogin && access_token) {
        return <Redirect to={HOME} />;
    }

    return (
        <React.Fragment>
            <div id="gd_login" className="gd-form-v2 px-6">
                <AuthError text={state.error} />
                <h1 className="logo-text-gorilla">
                    <img src={`${LINK_CDN_IMG}gorilladesk.png`} width={377} height={90} />
                </h1>
                <LoginSocial onLoginSocial={handleLoginFirebase} onLoginFail={loginSocialFailed} t={t} />
                <div className="gd-line flex-betweenitems">
                    <div className="line" />
                    <span> {t('common:or')} </span>
                    <div className="line" />
                </div>
                <form id="login-form" action="#" ref={refForm}>
                    <section className="gd-login-btn-group boxs has-form p-0">
                        <FormIdentify
                            onSubmitForm={submitUsernamePassword}
                            errorsForm={errorsForm}
                            formDisable={formDisable}
                            t={t}
                            onKeyPress={_handleKeypress}
                            onFocusField={_handleFocusField}
                        />
                    </section>
                </form>
            </div>
        </React.Fragment>
    );
};

export default Signup;

import React from 'react';

const IconHash = () => {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.6408 6.10706C11.6998 5.75296 11.4606 5.41806 11.1065 5.35904C10.7524 5.30003 10.4175 5.53924 10.3585 5.89334L9.78231 9.3502H5.99961C5.64062 9.3502 5.34961 9.64121 5.34961 10.0002C5.34961 10.3592 5.64062 10.6502 5.99961 10.6502H9.56564L9.11564 13.3502H5.99961C5.64062 13.3502 5.34961 13.6412 5.34961 14.0002C5.34961 14.3592 5.64062 14.6502 5.99961 14.6502H8.89898L8.35845 17.8933C8.29944 18.2474 8.53865 18.5823 8.89275 18.6414C9.24685 18.7004 9.58175 18.4612 9.64077 18.1071L10.2169 14.6502H12.899L12.3585 17.8933C12.2994 18.2474 12.5386 18.5823 12.8928 18.6414C13.2469 18.7004 13.5817 18.4612 13.6408 18.1071L14.2169 14.6502H17.9996C18.3586 14.6502 18.6496 14.3592 18.6496 14.0002C18.6496 13.6412 18.3586 13.3502 17.9996 13.3502H14.4336L14.8836 10.6502H17.9996C18.3586 10.6502 18.6496 10.3592 18.6496 10.0002C18.6496 9.64121 18.3586 9.3502 17.9996 9.3502H15.1002L15.6408 6.10706C15.6998 5.75296 15.4606 5.41806 15.1065 5.35904C14.7524 5.30003 14.4175 5.53924 14.3585 5.89334L13.7823 9.3502H11.1002L11.6408 6.10706ZM13.1156 13.3502L13.5656 10.6502H10.8836L10.4336 13.3502H13.1156Z"
                fill="var(--color-icon)"
            />
        </svg>
    );
};

export default IconHash;

export default function IconPlay({
    isStop = false,
    isBlack = false,
    isPaused = false,
    isPauseFlat = false,
    isTracking = false,
    isStopTracking = false,
    isWhite = false
}) {
    if (isStopTracking)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x={6} y={6} width={12} height={12} rx={2} fill="white" />
            </svg>
        );

    if (isTracking) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.1384 18.8272L17.6248 13.6444C18.7736 12.8491 18.7736 11.1509 17.6248 10.3556L10.1384 5.17275C8.81196 4.25443 7 5.20381 7 6.81714V17.1829C7 18.7962 8.81196 19.7456 10.1384 18.8272Z"
                    fill="white"
                />
            </svg>
        );
    }
    if (isStop)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x={3} y={3} width={18} height={18} rx={3} fill="#1E7EF7" />
            </svg>
        );

    if (isBlack)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9 15.7063L9 8.29367C9 7.5792 9.65865 7 10.4711 7C10.8153 7 11.1485 7.1061 11.4129 7.29985L16.4706 11.0062C17.0948 11.4636 17.1791 12.2793 16.659 12.8282C16.602 12.8883 16.539 12.9437 16.4706 12.9938L11.4129 16.7001C10.7888 17.1575 9.86112 17.0834 9.34098 16.5345C9.12066 16.302 9 16.0089 9 15.7063Z"
                    fill="#5F6164"
                />
            </svg>
        );

    if (isPaused)
        return (
            <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
                <path
                    fill="var(--color-icon)"
                    d="M10.593 3h-.775a1.16 1.16 0 0 0-1.164 1.142v6.98c0 .643.521 1.164 1.164 1.164l.775.044c.643 0 1.164-.52 1.164-1.164v-6.98c0-.643-.521-1.186-1.164-1.186ZM5.94 3h-.775C4.52 3 4 3.52 4 4.164v6.98c0 .665.52 1.186 1.164 1.186h.775c.643 0 1.164-.52 1.164-1.164v-6.98C7.103 3.542 6.582 3 5.939 3Z"
                />
            </svg>
        );

    if (isPauseFlat)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 8C11 7.44772 10.5523 7 10 7C9.44772 7 9 7.44772 9 8V16C9 16.5523 9.44772 17 10 17C10.5523 17 11 16.5523 11 16V8ZM15 8C15 7.44772 14.5523 7 14 7C13.4477 7 13 7.44772 13 8V16C13 16.5523 13.4477 17 14 17C14.5523 17 15 16.5523 15 16V8Z"
                    fill="var(--color-icon)"
                />
            </svg>
        );

    if (isWhite)
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.1384 18.8272L17.6248 13.6444C18.7736 12.8491 18.7736 11.1509 17.6248 10.3556L10.1384 5.17275C8.81196 4.25443 7 5.20381 7 6.81714V17.1829C7 18.7962 8.81196 19.7456 10.1384 18.8272Z"
                    fill="white"
                ></path>
            </svg>
        );

    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.5 17.5V7.5L16.5 12.5L9.5 17.5Z" stroke="var(--color-icon)" strokeWidth="1.3" strokeLinejoin="round" />
        </svg>
    );
}

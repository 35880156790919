import React, { useState, forwardRef, useMemo } from 'react';
import PropsTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import DatePicker, { CalendarContainer } from 'react-datepicker';

import useClickOutside from 'common/hooks/useClickOutside';
import { formatDateLocal } from 'common/utils/DateUtils';
import { TODAY } from 'app/const/App';
import { HeaderCustom } from 'app/components/daterange/components/HeaderCustom';
import IconFuture from 'assets/icon/IconFuture';
import classNames from 'classnames';

const CustomInput = ({ onClick, date, handleOnClick, isOpen, wrapClassInput = '' }, ref) => {
    const dateFormat = useSelector(({ auth }) => auth?.user?.company?.date_format);

    return (
        <div
            ref={ref}
            className={classNames('field-input field-date', wrapClassInput, { 'auto-focus': isOpen })}
            onClick={() => handleOnClick('set_open', { onClick })}
        >
            <IconFuture /> &nbsp;
            {formatDateLocal(date, dateFormat)}
        </div>
    );
};

function DateSelect({ selectDefault, onChange, wrapClassInput = '' }) {
    const currentDay = useMemo(() => {
        if (selectDefault) {
            return moment(selectDefault)?._d;
        }
        return TODAY;
    }, [selectDefault]);

    const [refDate, isOpen, setIsOpen] = useClickOutside(false);
    const [selected, setSelected] = useState(currentDay);
    const CustomInputRef = forwardRef(CustomInput);

    const handleOnClick = (type, value) => {
        switch (type) {
            case 'set_open':
                value.onClick();
                setIsOpen(!isOpen);
                break;
            case 'select':
                setSelected(value);
                setIsOpen(false);
                onChange(value);
                break;
            default:
                break;
        }
    };

    const renderContainer = ({ children }) => {
        return (
            <div ref={refDate} className="format-container-date-picker">
                <div className="wrapper-date-picker-container">
                    <CalendarContainer className="react-datepicker-custom">
                        <div style={{ position: 'relative' }}>{children}</div>
                    </CalendarContainer>
                </div>
            </div>
        );
    };

    return (
        <DatePicker
            open={isOpen}
            selected={selected}
            shouldCloseOnSelect={false}
            calendarContainer={renderContainer}
            onChange={(date) => handleOnClick('select', date)}
            customInput={
                <CustomInputRef
                    date={selected}
                    handleOnClick={handleOnClick}
                    isOpen={isOpen}
                    wrapClassInput={wrapClassInput}
                />
            }
            renderCustomHeader={HeaderCustom}
        />
    );
}

DateSelect.defaultProps = {
    name: 'date',
    selectDefault: null,
    onChange: () => {}
};

DateSelect.propsTypes = {
    name: PropsTypes.string,
    selectDefault: PropsTypes.object,
    onChange: PropsTypes.func
};

export default DateSelect;

import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import IconTrash from 'assets/icon/IconTrash';

export default function ItemBuilding({ itemBuilding, onSelect, isActive, numberUnit, onDelete, withoutFetch = false }) {
    const { t } = useTranslation();
    const refItemBuilding = useRef(null);
    const buildingId = itemBuilding.id;

    useEffect(() => {
        if (itemBuilding.created) {
            refItemBuilding.current && refItemBuilding.current.scrollIntoView();
        }
    }, [itemBuilding.created]);

    function _handleDeleteBuilding(e) {
        e && e.stopPropagation();
        onDelete(e, buildingId, withoutFetch);
    }

    return (
        <div
            ref={refItemBuilding}
            onClick={(e) => onSelect(e, buildingId, isActive)}
            className={`content__items ${isActive ? 'selected' : ''}`}
        >
            <p className="content__items-name">{itemBuilding.name}</p>
            <span className="content__items-number">{`${numberUnit} ${t(`jobDetail:${numberUnit > 1 ? 'units' : 'unit'}`)}`}</span>
            <div onClick={_handleDeleteBuilding} className="content__items-icon">
                <IconTrash />
            </div>
        </div>
    );
}

import React from 'react';
import { useTranslation } from 'react-i18next';

import IconArrowDown from 'assets/icon/IconArrowDown';

export default function ShowMore({ onToggle = () => {}, isShowMore = false }) {
    const { t } = useTranslation();
    return (
        <div className={`todo-items-action ${isShowMore ? 'is-change-btn' : ''}`}>
            <div className="v2-btn-default btn-bg-grey --icon-r fs-13 --transparent" onClick={onToggle}>
                {t(`setting:${!isShowMore ? 'hide' : 'show_all'}`)} <IconArrowDown isUp={!isShowMore} />
            </div>
        </div>
    );
}

export default function IconStar({
    isAddons = false,
    isChecked = false,
    isMenuActive = false,
    isGrey = false,
    isGreen = false,
    isCheckActive = false,
    isFlat = false,
    color = '#c1c9d4',
    isReferral = false,
    ...props
}) {
    if (isGreen)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FFF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.5347 18.7447L12.5034 20.3383C12.0146 20.5953 11.41 20.4073 11.153 19.9185C11.0506 19.7238 11.0153 19.5009 11.0525 19.2841L11.6314 15.9088C11.687 15.5844 11.5795 15.2534 11.3438 15.0236L8.89147 12.6332C8.49598 12.2477 8.48789 11.6146 8.87339 11.2191C9.0269 11.0616 9.22804 10.9591 9.44568 10.9275L12.8347 10.435C13.1604 10.3877 13.442 10.1831 13.5877 9.88799L15.1033 6.817C15.3477 6.32174 15.9473 6.1184 16.4426 6.36283C16.6398 6.46016 16.7994 6.61978 16.8968 6.817L18.4124 9.88799C18.5581 10.1831 18.8396 10.3877 19.1653 10.435L22.5544 10.9275C23.1009 11.0069 23.4796 11.5143 23.4002 12.0609C23.3686 12.2785 23.2661 12.4797 23.1086 12.6332L20.6562 15.0236C20.4206 15.2534 20.313 15.5844 20.3687 15.9088L20.9476 19.2841C21.0409 19.8284 20.6753 20.3454 20.131 20.4388C19.9142 20.4759 19.6913 20.4406 19.4966 20.3383L16.4654 18.7447C16.174 18.5915 15.826 18.5915 15.5347 18.7447Z"
                    fill="#1EAA5C"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17 22C17.5523 22 18 22.4477 18 23V26H14V23C14 22.4477 14.4477 22 15 22H17ZM23 23C23.5523 23 24 23.4477 24 24V26H20V24C20 23.4477 20.4477 23 21 23H23ZM11 24C11.5523 24 12 24.4477 12 25V26H8V25C8 24.4477 8.44772 24 9 24H11Z"
                    fill="#1EAA5C"
                />
            </svg>
        );

    if (isGrey)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FFF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.5347 18.7447L12.5034 20.3383C12.0146 20.5953 11.41 20.4073 11.153 19.9185C11.0506 19.7238 11.0153 19.5009 11.0525 19.2841L11.6314 15.9088C11.687 15.5844 11.5795 15.2534 11.3438 15.0236L8.89147 12.6332C8.49598 12.2477 8.48789 11.6146 8.87339 11.2191C9.0269 11.0616 9.22804 10.9591 9.44568 10.9275L12.8347 10.435C13.1604 10.3877 13.442 10.1831 13.5877 9.88799L15.1033 6.817C15.3477 6.32174 15.9473 6.1184 16.4426 6.36283C16.6398 6.46016 16.7994 6.61978 16.8968 6.817L18.4124 9.88799C18.5581 10.1831 18.8396 10.3877 19.1653 10.435L22.5544 10.9275C23.1009 11.0069 23.4796 11.5143 23.4002 12.0609C23.3686 12.2785 23.2661 12.4797 23.1086 12.6332L20.6562 15.0236C20.4206 15.2534 20.313 15.5844 20.3687 15.9088L20.9476 19.2841C21.0409 19.8284 20.6753 20.3454 20.131 20.4388C19.9142 20.4759 19.6913 20.4406 19.4966 20.3383L16.4654 18.7447C16.174 18.5915 15.826 18.5915 15.5347 18.7447Z"
                    fill="#7A83A6"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17 22C17.5523 22 18 22.4477 18 23V26H14V23C14 22.4477 14.4477 22 15 22H17ZM23 23C23.5523 23 24 23.4477 24 24V26H20V24C20 23.4477 20.4477 23 21 23H23ZM11 24C11.5523 24 12 24.4477 12 25V26H8V25C8 24.4477 8.44772 24 9 24H11Z"
                    fill="#7A83A6"
                />
            </svg>
        );
    if (isMenuActive)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#F3A52E"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.5347 18.7447L12.5034 20.3383C12.0146 20.5953 11.41 20.4073 11.153 19.9185C11.0506 19.7238 11.0153 19.5009 11.0525 19.2841L11.6314 15.9088C11.687 15.5844 11.5795 15.2534 11.3438 15.0236L8.89147 12.6332C8.49598 12.2477 8.48789 11.6146 8.87339 11.2191C9.0269 11.0616 9.22804 10.9591 9.44568 10.9275L12.8347 10.435C13.1604 10.3877 13.442 10.1831 13.5877 9.88799L15.1033 6.817C15.3477 6.32174 15.9473 6.1184 16.4426 6.36283C16.6398 6.46016 16.7994 6.61978 16.8968 6.817L18.4124 9.88799C18.5581 10.1831 18.8396 10.3877 19.1653 10.435L22.5544 10.9275C23.1009 11.0069 23.4796 11.5143 23.4002 12.0609C23.3686 12.2785 23.2661 12.4797 23.1086 12.6332L20.6562 15.0236C20.4206 15.2534 20.313 15.5844 20.3687 15.9088L20.9476 19.2841C21.0409 19.8284 20.6753 20.3454 20.131 20.4388C19.9142 20.4759 19.6913 20.4406 19.4966 20.3383L16.4654 18.7447C16.174 18.5915 15.826 18.5915 15.5347 18.7447Z"
                    fill="white"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17 22C17.5523 22 18 22.4477 18 23V26H14V23C14 22.4477 14.4477 22 15 22H17ZM23 23C23.5523 23 24 23.4477 24 24V26H20V24C20 23.4477 20.4477 23 21 23H23ZM11 24C11.5523 24 12 24.4477 12 25V26H8V25C8 24.4477 8.44772 24 9 24H11Z"
                    fill="white"
                />
            </svg>
        );
    if (isAddons)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FEF8EF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.5347 18.7447L12.5034 20.3383C12.0146 20.5953 11.41 20.4073 11.153 19.9185C11.0506 19.7238 11.0153 19.5009 11.0525 19.2841L11.6314 15.9088C11.687 15.5844 11.5795 15.2534 11.3438 15.0236L8.89147 12.6332C8.49598 12.2477 8.48789 11.6146 8.87339 11.2191C9.0269 11.0616 9.22804 10.9591 9.44568 10.9275L12.8347 10.435C13.1604 10.3877 13.442 10.1831 13.5877 9.88799L15.1033 6.817C15.3477 6.32174 15.9473 6.1184 16.4426 6.36283C16.6398 6.46016 16.7994 6.61978 16.8968 6.817L18.4124 9.88799C18.5581 10.1831 18.8396 10.3877 19.1653 10.435L22.5544 10.9275C23.1009 11.0069 23.4796 11.5143 23.4002 12.0609C23.3686 12.2785 23.2661 12.4797 23.1086 12.6332L20.6562 15.0236C20.4206 15.2534 20.313 15.5844 20.3687 15.9088L20.9476 19.2841C21.0409 19.8284 20.6753 20.3454 20.131 20.4388C19.9142 20.4759 19.6913 20.4406 19.4966 20.3383L16.4654 18.7447C16.174 18.5915 15.826 18.5915 15.5347 18.7447Z"
                    fill="#F3A52E"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17 22C17.5523 22 18 22.4477 18 23V26H14V23C14 22.4477 14.4477 22 15 22H17ZM23 23C23.5523 23 24 23.4477 24 24V26H20V24C20 23.4477 20.4477 23 21 23H23ZM11 24C11.5523 24 12 24.4477 12 25V26H8V25C8 24.4477 8.44772 24 9 24H11Z"
                    fill="#F3A52E"
                />
            </svg>
        );

    if (isChecked)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.30528 19.4112C8.39871 20.0423 7.15223 19.8189 6.5212 18.9123C6.17171 18.4103 6.07123 17.7758 6.24846 17.1903L7.08027 14.4422L4.79204 12.7079C3.91174 12.0407 3.739 10.7862 4.4062 9.90594C4.77571 9.41841 5.34802 9.1268 5.95964 9.11442L8.83027 9.05632L9.7726 6.34415C10.1351 5.30076 11.2748 4.74881 12.3182 5.11133C12.8961 5.3121 13.3503 5.76629 13.551 6.34415L14.4934 9.05632L17.364 9.11442C18.4683 9.13678 19.3455 10.0501 19.3231 11.1545C19.3107 11.7661 19.0191 12.3384 18.5316 12.7079L16.2434 14.4422L17.0752 17.1903C17.3952 18.2475 16.7976 19.364 15.7404 19.684C15.1549 19.8612 14.5204 19.7607 14.0184 19.4112L11.6618 17.7709L9.30528 19.4112Z"
                    fill="#C1C9D4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.36891 12.2465C8.97839 11.856 8.34522 11.856 7.9547 12.2465C7.56417 12.637 7.56417 13.2702 7.9547 13.6607L9.9547 15.6607C10.3452 16.0512 10.9784 16.0512 11.3689 15.6607L15.3689 11.6607C15.7594 11.2702 15.7594 10.637 15.3689 10.2465C14.9784 9.85598 14.3452 9.85598 13.9547 10.2465L10.6618 13.5394L9.36891 12.2465Z"
                    fill="#7A83A6"
                />
            </svg>
        );
    if (isCheckActive)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.30528 19.4112C8.39871 20.0423 7.15223 19.8189 6.5212 18.9123C6.17171 18.4103 6.07123 17.7758 6.24846 17.1903L7.08027 14.4422L4.79204 12.7079C3.91174 12.0407 3.739 10.7862 4.4062 9.90594C4.77571 9.41841 5.34802 9.1268 5.95964 9.11442L8.83027 9.05632L9.7726 6.34415C10.1351 5.30076 11.2748 4.74881 12.3182 5.11133C12.8961 5.3121 13.3503 5.76629 13.551 6.34415L14.4934 9.05632L17.364 9.11442C18.4683 9.13678 19.3455 10.0501 19.3231 11.1545C19.3107 11.7661 19.0191 12.3384 18.5316 12.7079L16.2434 14.4422L17.0752 17.1903C17.3952 18.2475 16.7976 19.364 15.7404 19.684C15.1549 19.8612 14.5204 19.7607 14.0184 19.4112L11.6618 17.7709L9.30528 19.4112Z"
                    fill="#DAC2F2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.36891 12.2465C8.97839 11.856 8.34522 11.856 7.9547 12.2465C7.56417 12.637 7.56417 13.2702 7.9547 13.6607L9.9547 15.6607C10.3452 16.0512 10.9784 16.0512 11.3689 15.6607L15.3689 11.6607C15.7594 11.2702 15.7594 10.637 15.3689 10.2465C14.9784 9.85598 14.3452 9.85598 13.9547 10.2465L10.6618 13.5394L9.36891 12.2465Z"
                    fill="#8D4AFC"
                />
            </svg>
        );
    if (isFlat)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 17.5L8.50343 19.3383C8.01459 19.5953 7.40996 19.4073 7.15296 18.9185C7.05062 18.7238 7.0153 18.5009 7.05248 18.2841L7.72027 14.3906L4.89147 11.6332C4.49598 11.2477 4.48789 10.6146 4.87339 10.2191C5.0269 10.0616 5.22804 9.95911 5.44568 9.92749L9.35499 9.35943L11.1033 5.817C11.3477 5.32174 11.9473 5.1184 12.4426 5.36283C12.6398 5.46016 12.7994 5.61978 12.8968 5.817L14.6451 9.35943L18.5544 9.92749C19.1009 10.0069 19.4796 10.5144 19.4002 11.0609C19.3686 11.2785 19.2661 11.4797 19.1086 11.6332L16.2798 14.3906L16.9476 18.2841C17.0409 18.8284 16.6753 19.3454 16.131 19.4388C15.9142 19.4759 15.6913 19.4406 15.4966 19.3383L12 17.5Z"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );

    if (isReferral)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_di_765_1644)">
                    <path
                        d="M11.1027 1.8243C11.469 1.07946 12.531 1.07946 12.8973 1.8243L15.2869 6.68233C15.4325 6.97851 15.715 7.18373 16.0417 7.23075L21.4004 8.00213C22.222 8.1204 22.5501 9.13034 21.955 9.70894L18.0731 13.4827C17.8364 13.7128 17.7285 14.0449 17.7848 14.3701L18.7071 19.7049C18.8485 20.5228 17.9894 21.147 17.2552 20.7598L12.4665 18.2341C12.1746 18.0801 11.8254 18.0801 11.5335 18.2341L6.74482 20.7598C6.01062 21.147 5.15151 20.5228 5.29292 19.7049L6.21522 14.3701C6.27145 14.0449 6.16356 13.7128 5.9269 13.4827L2.04504 9.70894C1.44987 9.13034 1.77802 8.1204 2.59962 8.00213L7.95829 7.23075C8.285 7.18373 8.56745 6.97851 8.71314 6.68233L11.1027 1.8243Z"
                        fill="url(#paint0_linear_765_1644)"
                    />
                    <path
                        d="M13.346 1.60361C12.7964 0.486354 11.2036 0.486353 10.654 1.60361L8.26447 6.46164C8.19163 6.60973 8.0504 6.71234 7.88705 6.73586L2.52838 7.50723C1.29599 7.68463 0.803758 9.19954 1.69651 10.0674L5.57837 13.8412C5.6967 13.9563 5.75065 14.1223 5.72253 14.2849L4.80023 19.6197C4.58812 20.8466 5.87678 21.7829 6.97808 21.202L11.7667 18.6763C11.9127 18.5993 12.0873 18.5993 12.2333 18.6763L17.0219 21.202C18.1232 21.7829 19.4119 20.8466 19.1998 19.6197L18.2775 14.2849C18.2494 14.1223 18.3033 13.9563 18.4216 13.8413L22.3035 10.0674C23.1962 9.19954 22.704 7.68463 21.4716 7.50723L16.1129 6.73586C15.9496 6.71234 15.8084 6.60973 15.7355 6.46164L13.346 1.60361Z"
                        stroke="white"
                    />
                </g>
                <defs>
                    <filter
                        id="filter0_di_765_1644"
                        x="-1.2601"
                        y="-1.73438"
                        width="26.5202"
                        height="25.614"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity={0} result="BackgroundImageFix" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation={1} />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_765_1644" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_765_1644" result="shape" />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dx={1} dy={1} />
                        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
                        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.48 0" />
                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_765_1644" />
                    </filter>
                    <linearGradient
                        id="paint0_linear_765_1644"
                        x1={12}
                        y1="1.33333"
                        x2={18}
                        y2="20.6667"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FED85E" />
                        <stop offset={1} stopColor="#FFB000" />
                    </linearGradient>
                </defs>
            </svg>
        );

    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 17L9.09121 18.5293C8.60236 18.7863 7.99774 18.5983 7.74074 18.1095C7.6384 17.9148 7.60308 17.6918 7.64026 17.4751L8.19579 14.2361L5.84252 11.9422C5.44703 11.5567 5.43894 10.9236 5.82444 10.5281C5.97795 10.3706 6.17909 10.2681 6.39673 10.2365L9.64888 9.76394L11.1033 6.817C11.3477 6.32174 11.9473 6.1184 12.4426 6.36283C12.6398 6.46016 12.7994 6.61978 12.8968 6.817L14.3512 9.76394L17.6033 10.2365C18.1499 10.3159 18.5285 10.8234 18.4491 11.3699C18.4175 11.5875 18.315 11.7887 18.1575 11.9422L15.8042 14.2361L16.3598 17.4751C16.4531 18.0194 16.0876 18.5364 15.5432 18.6297C15.3265 18.6669 15.1035 18.6316 14.9088 18.5293L12 17Z"
                fill={color}
            ></path>
        </svg>
    );
}

import React from 'react';

export default function IconUndo() {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.0001 4C16.4183 4 20.0001 7.58172 20.0001 12C20.0001 16.4183 16.4183 20 12.0001 20C11.4478 20 11.0001 19.5523 11.0001 19C11.0001 18.4477 11.4478 18 12.0001 18C15.3138 18 18.0001 15.3137 18.0001 12C18.0001 8.68629 15.3138 6 12.0001 6C9.03954 6 6.57983 8.14416 6.08911 10.9643L7.81248 10.0104C8.25837 9.76324 8.81127 9.89294 9.10429 10.2943L9.17191 10.4002C9.41907 10.8461 9.28938 11.399 8.88804 11.692L8.7821 11.7596L5.07141 13.8165C4.62552 14.0637 4.07262 13.934 3.77961 13.5326L3.71198 13.4267L1.6551 9.71601C1.38735 9.23297 1.56188 8.62433 2.04491 8.35658C2.4908 8.10942 3.0437 8.23911 3.33672 8.64045L3.40434 8.74639L4.20536 10.1913C5.02513 6.64422 8.2038 4 12.0001 4Z"
                fill="var(--color-icon)"
            />
        </svg>
    );
}

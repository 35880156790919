import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import GDButtonInputSearch from 'app/components/button/ButtonInputSearch';
import { ADD_SMART_VIEWS } from 'app/const/Permissions';
import IconPlus from 'assets/icon/IconPlus';
import IconSmartView from 'assets/icon/IconSmartView';
import { checkPermission } from 'common/utils/PermissionUtils';

const Header = ({ isSupperAdmin = false, onSearch = () => {}, onAddNew = () => {} }) => {
    const { t } = useTranslation('smartView');
    const permissionsList = useSelector((state) => state.auth?.user?.permissions?.enabled || []);
    const permissionAdd = checkPermission(permissionsList, ADD_SMART_VIEWS);

    return (
        <div className="sidebar-header">
            <div className="flexcenter gap-4 flex-1">
                <IconSmartView />
                <p className="black fw-500 txt-ellipsis txt-smart-view" title={t('smart_view_title')}>
                    {t('smart_view_title')}
                </p>
            </div>
            <GDButtonInputSearch onSearch={onSearch} />
            {isSupperAdmin || permissionAdd ? (
                <div className="v2-btn-default --icon-lg btn-modal" onClick={onAddNew}>
                    <IconPlus />
                </div>
            ) : null}
        </div>
    );
};
export default Header;

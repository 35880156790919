import React, { Fragment, useContext, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { saveJobMeta } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { TYPE_OPEN_SMS } from 'app/const/Sms';
import Assign from 'app/modules/calendar/job/components/Assign';
import Customer from 'app/modules/calendar/job/components/Customer';
import Header from 'app/modules/calendar/job/components/Header';
import JobNote from 'app/modules/calendar/job/components/jobnote';
import Location from 'app/modules/calendar/job/components/Location';
import Payment from 'app/modules/calendar/job/components/Payment';
import Status from 'app/modules/job/status';
import SmsJobDetail from 'app/modules/jobdetail/components/sms/Sms';
import { SocketContext } from 'app/services/socket/SocketProvider';
import useEmitter from 'common/hooks/useEmitter';
import { actionGetJobSummary, actionUpdateEmoji } from 'common/redux/actions/job';
import { clientQuery } from 'common/utils/ApiUtils';
import { dispatchEvent } from 'common/utils/eventUtils';
import { CALENDAR_CUSTOM_EVENTS } from '../const';
import { TYPE_JOB_META } from '../const/Job';
import EmojiPopper from '../EmojiPopper';
import { handleRemoveTimeWindowEl } from '../ultil/Calendar';
import PreviewNoteCollapse from './components/collapse';

const JobPreView = () => {
    const { t } = useTranslation();
    const jobPreView = useSelector(({ jobReducer }) => jobReducer.jobPreView);

    const [state, dispatchState] = useReducer(reducer, {
        isLoading: false,
        isVisible: false,
        id: '',
        jobPastActive: null
    });

    const dispatch = useDispatch();

    const {
        isLoading: finalIsLoading,
        isVisible: finalIsVisible,
        id: currentJobId,
        customer: finalCustomer = {},
        location: finalLocation = {},
        invoice: finalInvoice,
        status: finalStatus,
        jobPastActive
    } = state;

    function _handleSetPastActive(job) {
        dispatchState({ jobPastActive: job });
    }

    function _handleOpenJob(jobData) {
        if (!jobData) return finalIsVisible && _closeJob(true);
        const jobId = jobData.jobId || jobData?.id;
        if (jobId === currentJobId) {
            dispatchState({
                status: jobData.status,
                recurrence: jobData.recurrence,
                previously_completed: jobData.previously_completed,
                schedule: { ...jobData.schedule },
                emoji: jobData.emoji
            });
            return;
        } else {
            dispatchState({ isLoading: true, isVisible: true, ...jobData });
            dispatch(actionGetJobSummary({ jobId: jobId }, _getJobSummarySuccess, _getJobSummaryFailed));
            dispatchEvent(CALENDAR_CUSTOM_EVENTS.OPEN_PREVIEW, null);
        }
    }

    useEmitter(CALENDAR_CUSTOM_EVENTS.OPEN_PREVIEW_JOB, _handleOpenJob);
    useEffect(() => {
        _handleOpenJob(jobPreView);
    }, []);

    function _getJobSummarySuccess(response) {
        dispatchState({ isLoading: false, ...response.data });
    }

    function _getJobSummaryFailed() {}

    function _closeJob(isCloseWithNotData = false) {
        // eslint-disable-next-line no-undef
        global.jobPreviewId = null;

        // Remove time window on calendar
        if (!isCloseWithNotData) handleRemoveTimeWindowEl();
        finalIsVisible && dispatchState({ isLoading: true, isVisible: false, id: '' });
    }

    const _handleUpdateSuccess = () => {
        dispatch(
            actionGetJobSummary({ jobId: currentJobId, fields: 'invoice' }, _getJobSummarySuccess, _getJobSummaryFailed)
        );
    };

    const _handleUpdateEmoji = (emoji) => {
        dispatchState((prev) => ({ ...prev, emoji }));
    };

    const _handleSaveEmoji = () => {
        const { parent_job_id: jobIdParent, emoji: newEmoji } = state;
        if (!newEmoji) return;

        clientQuery(
            saveJobMeta(jobIdParent),
            {
                method: 'PUT',
                toFormData: false,
                data: { content: newEmoji, type: TYPE_JOB_META.EMOJI }
            },
            () => dispatch(actionUpdateEmoji({ id: jobIdParent, emoji: newEmoji }))
        );
    };

    const _handleUpdateCustomer = (balance) => {
        if (!!finalInvoice) dispatchState((prev) => ({ ...prev, invoice: { ...prev.invoice, balance } }));
    };

    return (
        <Fragment>
            {finalIsVisible ? (
                <div className="sidebar-left-content sidebar-preview job-preview">
                    <div className="sidebar-preview__wrap">
                        <Header
                            onClose={_closeJob}
                            jobData={state}
                            setPastActive={_handleSetPastActive}
                            finalJobPastActive={jobPastActive}
                        />
                        <div className="flex-betweenitems">
                            <Status status={parseInt(finalStatus)} jobData={state} finalJobPastActive={jobPastActive} />
                            <EmojiPopper
                                placementDropdown="bottom-end"
                                notCloseOnClick
                                listEmoji={state?.emoji}
                                onChange={_handleUpdateEmoji}
                                onClose={_handleSaveEmoji}
                                isDisable={!state?.active_job}
                            />
                        </div>
                        {!!state.schedule && <Assign jobData={state} />}
                    </div>
                    <div className="sidebar-content pb-8">
                        <Customer customerDetail={finalCustomer} />

                        <Location
                            jobId={currentJobId}
                            customerId={finalCustomer.id}
                            finalCustomer={finalCustomer}
                            customerLocation={finalLocation}
                        />

                        {!finalIsLoading && !!finalInvoice && (
                            <Payment invoice={finalInvoice} onUpdateSuccess={_handleUpdateSuccess} />
                        )}

                        {!finalIsLoading && (
                            <PreviewNoteCollapse
                                title={`${finalCustomer.full_name}’s ${t('calendar:top_note')}`}
                                note={state.top_note}
                                extraClass="is-topnote"
                                isInnerHTML
                            />
                        )}

                        {!finalIsLoading && <JobNote notes={state.job_notes} />}
                    </div>
                    <CustomerSmsWithJob
                        finalIsLoading={finalIsLoading}
                        finalJobid={currentJobId}
                        finalCustomer={finalCustomer}
                    />
                </div>
            ) : null}
            {finalIsVisible && <RealtimeCreditBalance customerId={finalCustomer.id} onUpdate={_handleUpdateCustomer} />}
        </Fragment>
    );
};

const CustomerSmsWithJob = ({ finalJobid, finalCustomer, finalIsLoading }) => {
    if (finalIsLoading) return null;
    return (
        <SmsJobDetail jobId={finalJobid} isJobPreview customer={finalCustomer} keyTriggerOpen={TYPE_OPEN_SMS.PREVIEW} />
    );
};

const RealtimeCreditBalance = ({ customerId, onUpdate = () => {} }) => {
    const { customerBalance } = useContext(SocketContext);

    useEffect(() => {
        if (customerBalance) {
            if (customerBalance.customer_id === customerId) onUpdate(customerBalance.balance_currency);
        }
    }, [customerBalance]);
};

export default JobPreView;

import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ButtonSave from 'app/components/button/ButtonSave';
import GdInput from 'app/components/input';
import StatusBar from 'app/components/status/statusbar';
import GdTextArea from 'app/components/textarea';
import { settingItems } from 'app/const/Api';
import { KEY_CODE_ENTER } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import Select from 'app/modules/jobdetail/tabs/materials/components/Select';
import { mixpanelAddProduct } from 'app/modules/mixpanel/MixpanelAddProduct';
import IconClose from 'assets/icon/IconClose';
import { setListItemsReducer } from 'common/redux/actions/itemsAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { onKeyDownOnlyNumber } from 'common/utils/NumberUtils';

const ServiceAddForm = forwardRef(({ handleSubmit }, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const EMPTY_TAX = { id: '', name: '' };

    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        isLoading: false,
        tax1: null,
        tax2: null,
        errors: {}
    });

    const { list_items } = useSelector(({ itemsReducer }) => itemsReducer);
    const { list_taxes: listTaxes } = useSelector(({ taxesReducer }) => taxesReducer);
    const profileId = useSelector(({ auth }) => auth.user.profile.id);

    const refForm = useRef(null);
    const isFirstTime = useRef(true);
    const refStatusBar = useRef(null);
    const refButtonSave = useRef(null);

    const { isVisible, tax1: tax1Selected, tax2: tax2Selected } = state;

    useImperativeHandle(ref, () => ({
        _handleOpenForm,
        _handleCloseForm
    }));

    const _handleSelect = (name, value) => {
        name === 'tax1' ? dispatchState({ tax1: value }) : dispatchState({ tax2: value });
    };

    const _preventDefault = (e) => {
        e && e.preventDefault();
    };

    const _stopPropagation = (e) => {
        e && e.stopPropagation();
    };

    const _handleOpenForm = () => {
        dispatchState({
            isVisible: true
        });
    };

    const _handleCloseForm = () => {
        dispatchState({
            isVisible: false,
            errors: {}
        });
    };

    const _handleOnKeypress = (e) => {
        if (e.charCode === KEY_CODE_ENTER) {
            _handleSubmit(e);
        }
    };

    const _handleOnKeyDownNumber = (event) => {
        const finalKey = event.key;
        if (finalKey === '-' && event.target.value !== '') {
            const finalValue = Number(`-${event.target.value}`);
            event.target.value = isNaN(finalValue) ? 0 : finalValue;
            event.preventDefault();
        }

        const finalValue =
            onKeyDownOnlyNumber(event) || (finalKey === '-' && event.target.value === '') || finalKey === '.';
        !finalValue && event.preventDefault();
    };

    const _handleRemoveLoading = () => {
        refButtonSave.current.removeLoading();
    };

    const _handleResetErrors = (value) => {
        if (value.keyword.trim() !== '' && isFirstTime.current) {
            isFirstTime.current = false;
            dispatchState({ errors: {} });
        }
    };

    const _handleSubmit = (e) => {
        _preventDefault(e);
        _stopPropagation(e);

        const errors = {};

        const params = {
            name: refForm.current['name'].value.trim(),
            description: refForm.current['description'].value.trim(),
            cost: refForm.current['cost'].value.trim(),
            tax1_id: state?.tax1,
            tax2_id: state?.tax2
        };

        if (!params.name?.length) {
            errors['name'] = t('customers:cannot_be_blank', { name: t('common:name') });
        }

        if (Object.keys(errors).length) {
            isFirstTime.current = true;
            dispatchState({ errors: errors });
            _handleRemoveLoading();
        } else {
            const optionsQuery = { data: params, method: 'POST' };
            clientQuery(
                settingItems,
                optionsQuery,
                (response) => {
                    _handleSubmitSuccess(response, params);
                },
                _handleSubmitFailed
            );
        }
    };

    const _handleSubmitSuccess = (response, params) => {
        handleTrackingEvent(mixpanelAddProduct({ id: profileId }));
        const newParams = {
            ...params,
            tax1: params.tax1_id,
            tax2: params.tax2_id
        };

        delete newParams.tax1_id;
        delete newParams.tax2_id;

        newParams.id = response?.data?.id;
        dispatch(setListItemsReducer([...list_items, newParams]));

        handleSubmit(newParams);

        dispatchState({
            isVisible: false,
            tax1: null,
            tax2: null
        });
    };

    const _handleSubmitFailed = (response) => {
        _handleRemoveLoading();
        refStatusBar.current.showStatusBar(
            'show_error',
            response.message || t('common:please_try_again'),
            LIST_STATUS.ERROR
        );
    };

    if (!isVisible) return null;

    return (
        <div className="modal container-modal add-items-job open">
            <div className="modal__overlay bg-fixed"></div>
            <div className="modal__container large">
                <form ref={refForm} onSubmit={_preventDefault}>
                    <div className="header-modal">
                        <h3 className="header-modal__label">{t('common:create_new_invoice_item')}</h3>
                        <div
                            className="v2-btn-default --icon-lg --transparent"
                            onClick={(e) => {
                                _handleCloseForm(e);
                            }}
                            tabIndex="0"
                        >
                            <IconClose />
                        </div>
                    </div>
                    <div className="body-modal scrolls has-form">
                        <StatusBar ref={refStatusBar} />
                        <GdInput
                            label={t('common:name')}
                            type="text"
                            withClassName="rows --inmodal"
                            placeholder={t('common:name')}
                            name="name"
                            error={state?.errors?.name}
                            handleOnChange={_handleResetErrors}
                            handleKeypress={_handleOnKeypress}
                        />
                        <div className="rows --inmodal">
                            <p className="txt">{t('common:description')}</p>
                            <GdTextArea
                                withClassName="field-input scrolls description"
                                placeholder={t('common:description')}
                                name="description"
                                type="text"
                                value={state?.description}
                            />
                        </div>
                        <GdInput
                            label={t('setting:cost')}
                            type="text"
                            withClassName="rows --inmodal"
                            placeholder={t('setting:cost')}
                            name="cost"
                            handleOnChange={_handleResetErrors}
                            handleKeyDown={_handleOnKeyDownNumber}
                        />
                        <div className="rows --inmodal row-haft">
                            <div className="col">
                                <span className="txt">{t('common:tax1')}</span>
                                <Select
                                    name="tax1"
                                    classWrapper={'v2-dropup'}
                                    options={tax1Selected ? [EMPTY_TAX, ...listTaxes] : listTaxes}
                                    selected={tax1Selected}
                                    onSelect={_handleSelect}
                                />
                            </div>
                            <div className="col">
                                <span className="txt">{t('common:tax2')}</span>
                                <Select
                                    name="tax2"
                                    classWrapper={'v2-dropup'}
                                    options={tax2Selected ? [EMPTY_TAX, ...listTaxes] : listTaxes}
                                    selected={tax2Selected}
                                    onSelect={_handleSelect}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="footer-modal">
                        <div
                            onClick={(e) => {
                                _handleCloseForm(e);
                            }}
                            className="v2-btn-default --transparent"
                            tabIndex="0"
                        >
                            {t('common:cancel')}
                        </div>
                        <ButtonSave ref={refButtonSave} onSave={_handleSubmit} />
                    </div>
                </form>
            </div>
        </div>
    );
});

ServiceAddForm.propTypes = {
    handleSubmit: PropTypes.func
};

ServiceAddForm.defaultProps = {
    handleSubmit: () => {}
};

export default ServiceAddForm;

import React, { useReducer, forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import ReactModal from 'react-modal';
import { LIST_STATUS } from 'app/const/Status';
import StatusBar from 'app/components/status/statusbar';
import { clientQuery } from 'common/utils/ApiUtils';
import {
    setDefaultInvoiceNotes,
    setDefaultInvoiceTerms,
    setDefaultEstimateNotes,
    setDefaultEstimateTerms
} from 'app/const/Api';
import ButtonSave from 'app/components/button/ButtonSave';
import IconClose from 'assets/icon/IconClose';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import GdRichtext from 'app/components/richtext';
import { TYPE_FUNCTION_RICHTEXT } from 'app/components/richtext/const';

const SetDefault = forwardRef(({ defaultContent, isNote = true, onUpdate = () => {}, isInvoice }, ref) => {
    const [state, dispatchState] = useReducer(reducer, { isVisible: false });
    const { t } = useTranslation(['jobDetail']);
    const refStatusBar = useRef(null);
    const refButtonSave = useRef(null);
    const refRichtext = useRef(null);

    useEffect(() => {
        if (defaultContent && refRichtext.current) refRichtext.current.setValue(defaultContent);
    }, [defaultContent]);

    useImperativeHandle(ref, () => ({ open: _handleOpen }));

    const _handleOpen = () => {
        dispatchState({ isVisible: true });
    };

    const _handleClose = () => {
        dispatchState({ isVisible: false });
    };

    const _handleRemoveLoading = () => {
        refButtonSave.current && refButtonSave.current.removeLoading();
    };

    const _handleSetStatusBar = (message = '', type) => {
        refStatusBar.current && refStatusBar.current.showStatusBar(Date.now(), message, type);
    };

    const _handleSetDefault = () => {
        let finalContent = refRichtext.current.getValue();
        finalContent = finalContent !== '<br/>' ? finalContent : '';
        const _handleSetDefaultSuccess = () => {
            _handleSetStatusBar(t('jobDetail:set_default_successfully'), LIST_STATUS.SUCCESS);
            _handleRemoveLoading();
            onUpdate(finalContent);
            setTimeout(() => {
                _handleClose();
            }, 2000);
        };

        const _handleSetDefaultFailed = (response) => {
            _handleSetStatusBar(response?.message[0], LIST_STATUS.ERROR);
            _handleRemoveLoading();
        };

        if (!finalContent) {
            _handleSetStatusBar(t('customers:cannot_be_blank', { name: t('setting:content') }), LIST_STATUS.ERROR);
            _handleRemoveLoading();
            return false;
        }

        clientQuery(
            isNote
                ? isInvoice
                    ? setDefaultInvoiceNotes
                    : setDefaultEstimateNotes
                : isInvoice
                  ? setDefaultInvoiceTerms
                  : setDefaultEstimateTerms,
            {
                method: 'PUT',
                data: {
                    content: finalContent
                }
            },
            _handleSetDefaultSuccess,
            _handleSetDefaultFailed
        );
    };

    if (!state.isVisible) {
        return false;
    }

    return (
        <ReactModal
            isOpen
            onRequestClose={_handleClose}
            style={{ overlay: { zIndex: 99999, background: 'transparent' } }}
            className="modal container-modal open"
        >
            <div className="modal__overlay bg-fixed" onClick={_handleClose} />
            <div className="modal__container large">
                <div className="header-modal">
                    <h3 className="header-modal__label">
                        {t(`jobDetail:${isNote ? 'set_default_notes' : 'set_default_terms'}`)}
                    </h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_handleClose} tabIndex="0">
                        <IconClose />
                    </div>
                </div>
                <div className={classNames('body-modal scrolls', { 'pt-4': isInvoice })}>
                    <StatusBar ref={refStatusBar} />
                    {isInvoice && (
                        <p className="pb-3 black">
                            {t('jobDetail:desc_term_note', { name: isNote ? 'notes' : 'terms' })}
                        </p>
                    )}
                    <div className="content has-form">
                        <div className="rows --inmodal p-0">
                            <div className="txt fs-13">
                                {t(`jobDetail:${isNote ? 'default_notes' : 'default_terms'}`)}
                            </div>
                            <GdRichtext
                                ref={refRichtext}
                                defaultContent={defaultContent}
                                isBreakLine
                                toolbarConfig={{
                                    left: [
                                        { group: 'emoji', children: [{ type: TYPE_FUNCTION_RICHTEXT.EMOJI }] },
                                        {
                                            group: 'inline-style',
                                            children: [
                                                { type: TYPE_FUNCTION_RICHTEXT.BOLD },
                                                { type: TYPE_FUNCTION_RICHTEXT.ITALIC },
                                                { type: TYPE_FUNCTION_RICHTEXT.UNDERLINE },
                                                { type: TYPE_FUNCTION_RICHTEXT.LINK }
                                            ]
                                        },
                                        {
                                            group: 'list',
                                            children: [
                                                { type: TYPE_FUNCTION_RICHTEXT.UL },
                                                { type: TYPE_FUNCTION_RICHTEXT.OL }
                                            ]
                                        }
                                    ]
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="footer-modal">
                    <div className="v2-btn-default --transparent" onClick={_handleClose} tabIndex="0">
                        {t('common:cancel')}
                    </div>
                    <ButtonSave title={t('jobDetail:save_change')} ref={refButtonSave} onSave={_handleSetDefault} />
                </div>
            </div>
        </ReactModal>
    );
});

export default SetDefault;

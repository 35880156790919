import classNames from 'classnames';
import React, { useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import {
    ADDONS_LEVEL_ALL,
    ADDONS_LEVEL_BASIC,
    ADDONS_LEVEL_GROWTH,
    ADDONS_LEVEL_PRO,
    ADDONS_STATUS_ACTIVE,
    ADDONS_STATUS_ALL,
    ADDONS_STATUS_INACTIVE,
    LIST_OPTIONS_FILTER_BY_PLANS
} from 'app/const/addons';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconGrowth from 'assets/icon/IconGrowth';
import IconItem from 'assets/icon/IconItem';
import IconUpgrade from 'assets/icon/IconUpgrade';
import IconViewRow from 'assets/icon/IconViewRow';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { ADDONS_DASHBOARD_DEFAULT_LOCAL_VALUE, ADDONS_DASHBOARD_LOCAL_KEY } from '../../constants';

const HeaderAddonsTabs = ({ currentStatus, currentLevel, onChangeLevel = () => {}, onChangeStatus = () => {} }) => {
    const { t } = useTranslation();

    return (
        <div className="d-flex">
            <div className="tabs">
                <div className="tab-selectors js-tab-selectors btn-item bg-white">
                    <button
                        className={classNames('tab-items', {
                            'active-tab-selector': currentStatus === ADDONS_STATUS_ALL
                        })}
                        type="button"
                        onClick={() => onChangeStatus(ADDONS_STATUS_ALL)}
                    >
                        {t('all')}
                    </button>
                    <button
                        className={classNames('tab-items', {
                            'active-tab-selector': currentStatus === ADDONS_STATUS_ACTIVE
                        })}
                        type="button"
                        onClick={() => onChangeStatus(ADDONS_STATUS_ACTIVE)}
                    >
                        {t('on')}
                    </button>
                    <button
                        className={classNames('tab-items', {
                            'active-tab-selector': currentStatus === ADDONS_STATUS_INACTIVE
                        })}
                        type="button"
                        onClick={() => onChangeStatus(ADDONS_STATUS_INACTIVE)}
                    >
                        {t('off')}
                    </button>
                </div>

                <SelectPlan currentLevel={currentLevel} onChangeLevel={onChangeLevel} />
                <SelectView />
            </div>
        </div>
    );
};

const SelectView = () => {
    const { t } = useTranslation();
    const localValue = getLocalStorage(ADDONS_DASHBOARD_LOCAL_KEY);
    const [isGridView, setIsGridView] = useState(localValue?.isGridView || false);

    const _handleChangeView = () => {
        const divContainer = document.getElementById('dashboard-container');
        divContainer.classList.toggle('is-grid-view', !isGridView);
        setIsGridView(!isGridView);
        setLocalStorage(ADDONS_DASHBOARD_LOCAL_KEY, {
            ...(localValue || ADDONS_DASHBOARD_DEFAULT_LOCAL_VALUE),
            isGridView: !isGridView
        });
    };

    return (
        <div>
            <div className="v2-btn-default --icon-lg tooltip" onClick={_handleChangeView}>
                {isGridView ? <IconViewRow isGrid /> : <IconItem />}
                <p className="tooltiptext top">{t(`addons:${isGridView ? 'grid_view' : 'list_view'}`)}</p>
            </div>
        </div>
    );
};

const SelectPlan = ({ currentLevel, onChangeLevel = () => {} }) => {
    const { t } = useTranslation();
    const _handleFindLevel = (value) => LIST_OPTIONS_FILTER_BY_PLANS.find((item) => item.value === value);

    const [state, dispatchState] = useReducer(reducer, { selected: _handleFindLevel(currentLevel) });
    const { selected } = state;
    const refDropdown = useRef(null);

    const _handleSelect = (value) => {
        dispatchState({ selected: _handleFindLevel(value) });
        onChangeLevel(value);
    };

    const renderCustomButton = ({ selected }) => (
        <>
            {selected.value === ADDONS_LEVEL_PRO && <IconUpgrade isAddonUpgrade />}
            {selected.value === ADDONS_LEVEL_GROWTH && <IconGrowth />}
            <div className="txt-ellipsis">{t(`addons:${selected.name}`)}</div>
            <div className="arrow">
                <IconArrowDown />
            </div>
        </>
    );

    return (
        <CalendarDropdown
            id="dropdown-filter-addons"
            ref={refDropdown}
            buttonClassName={classNames('dropbtn items', {
                'bg-white has-icon pro-plan': selected.value === ADDONS_LEVEL_PRO,
                'has-icon growth-plan': selected.value === ADDONS_LEVEL_GROWTH,
                'bg-white --other': selected.value === ADDONS_LEVEL_BASIC,
                'bg-white': selected.value === ADDONS_LEVEL_ALL
            })}
            customDropButton={renderCustomButton}
            onSelect={_handleSelect}
            selected={selected.name}
            selectedOption={selected}
            options={LIST_OPTIONS_FILTER_BY_PLANS}
            fileTranslation="addons"
        />
    );
};

HeaderAddonsTabs.displayName = 'HeaderAddonsTabs';
export default HeaderAddonsTabs;

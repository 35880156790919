import React, { useReducer, useEffect, useRef } from 'react';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { getListSettingService } from 'common/redux/actions/settings/serviceAction';
import { useSelector, useDispatch } from 'react-redux';
import SearchOption from 'app/modules/report/filter/SearchOption';
import { clientQuery } from 'common/utils/ApiUtils';
import { changeJobService, saveJobMeta } from 'app/const/Api';
import { actionOpenJobDetail } from 'common/redux/actions/job/detail';
import { actionReloadCalendar } from 'common/redux/actions/calendar';
import IconLoading from 'assets/icon/IconLoading';
import { reducer } from 'app/const/Reducer';
import GdConfirm from 'app/components/confirm';
import { isScrollToEndBottom } from 'common/utils/FunctionUtils';
import { LoadingService } from '../../Loading';
import EmojiPopper from 'app/modules/calendar/EmojiPopper';
import { TYPE_JOB_META } from 'app/modules/calendar/const/Job';
import { actionUpdateEmoji } from 'common/redux/actions/job';
import { useTranslation } from 'react-i18next';

const CustomerSerive = ({ isLoading, serviceDetail, jobId, emoji, parentJobId, servicePlanDetail }) => {
    const { t } = useTranslation(['header', 'common']);
    const dispatch = useDispatch();

    const { list_service, first_time, total_service } = useSelector((store) => store.serviceReducer);

    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        selected: serviceDetail,
        isLoadingService: true,
        isFirstTime: true,
        options: [],
        keyword: '',
        totalSearch: 0,
        emoji
    });

    const refSearch = useRef(null);
    const refConfirm = useRef(null);
    const isDisable = !!servicePlanDetail;

    const {
        isVisible: finalIsVisible,
        isLoadingService: finalIsLoadingService,
        selected: finalServiceSelected,
        keyword: finalKeyword,
        emoji: finalEmoji
    } = state;

    useEffect(() => {
        if (serviceDetail?.id !== finalServiceSelected?.id) {
            dispatchState({ selected: serviceDetail, emoji });
        }
    }, [serviceDetail]);

    useEffect(() => {
        if (finalIsVisible && state.isFirstTime) {
            if (list_service.length === 0 && first_time) {
                _getListServices({
                    limit: 20,
                    offset: 0,
                    localSaveStore: true,
                    localResetData: true,
                    total: 1
                });
            } else {
                dispatchState({
                    isFirstTime: false,
                    isLoadingService: false,
                    options: list_service
                });
            }
        }
    }, [finalIsVisible]);

    useEffect(() => {
        if (finalKeyword.length !== 0) {
            const params = {
                limit: 20,
                offset: 0,
                total: 1,
                localSaveStore: false,
                localResetData: true,
                keyword: finalKeyword
            };

            _getListServices(params);
        }
    }, [finalKeyword]);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function _getListServices(params) {
        dispatch(getListSettingService(params, _getListServicesSuccess, _getListServicesFailed));
    }

    function _getListServicesSuccess(response) {
        dispatchState({
            options: [...state.options, ...response.data],
            isFirstTime: false,
            isLoadingService: false,
            totalSearch: finalKeyword !== '' ? response?.total : state.totalSearch
        });
    }

    function _getListServicesFailed() {
        dispatchState({
            isFirstTime: false,
            isLoadingService: false
        });
    }

    function handleOnScrollContent(e) {
        const isSearching = finalKeyword !== '';
        const lengthServiceCurrent = state.options.length;
        const finalTotalCompare = isSearching ? state.totalSearch : total_service;

        if (!finalIsLoadingService && isScrollToEndBottom(e.target) && lengthServiceCurrent < finalTotalCompare) {
            dispatchState({ isLoadingService: true });

            const params = {
                limit: 20,
                offset: lengthServiceCurrent,
                localSaveStore: !isSearching,
                localResetData: false,
                keyword: finalKeyword
            };

            _getListServices(params);
        }
    }

    function _handleChangeSearch(keyword) {
        if (keyword === '') {
            dispatchState({
                isLoadingService: false,
                options: list_service,
                isFirstTime: false,
                keyword: ''
            });
            refSearch.current.scrollTo(0, 0);
        } else {
            dispatchState({
                isLoadingService: true,
                options: [],
                keyword: keyword
            });
        }
    }

    function _handleFocus(e) {
        e && e.stopPropagation();
        dispatchState({ isVisible: true });
    }

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_result_search_services');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_result_search_services');

        if (
            refSearch.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refSearch.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && dispatchState({ isVisible: false });
    }

    function _handleChangeService(serviceId) {
        const elm = document.getElementById('modal_job_detail_content');
        elm && elm.classList.add('is-disable');

        dispatchState({ isVisible: false });
        clientQuery(
            changeJobService(jobId),
            { method: 'PUT', data: { service_id: serviceId } },
            _changeServiceSuccess,
            _changeServiceFailed
        );
    }

    function _changeServiceSuccess() {
        dispatch(actionOpenJobDetail({ id: jobId }));

        const elm = document.getElementById('modal_job_detail_content');
        elm && elm.classList.remove('is-disable');

        setTimeout(() => {
            dispatch(actionReloadCalendar());
        }, 500);
    }

    function _changeServiceFailed(response) {
        const elm = document.getElementById('modal_job_detail_content');
        elm && elm.classList.remove('is-disable');
        alert(response?.message?.toString() || t('common:please_try_again'));
    }

    function _openConfirmChangeService(serviceId) {
        refConfirm.current.open(serviceId);
    }

    function _renderLoading() {
        return <LoadingService />;
    }

    const _handleChangeEmoji = (emoji) => {
        dispatchState((prev) => ({ ...prev, emoji }));
    };

    const _handleSaveEmoji = () => {
        const { emoji: newEmoji } = state;
        if (!newEmoji) return;

        clientQuery(
            saveJobMeta(parentJobId),
            {
                method: 'PUT',
                toFormData: false,
                data: { content: newEmoji, type: TYPE_JOB_META.EMOJI }
            },
            () => dispatch(actionUpdateEmoji({ id: parentJobId, emoji: newEmoji }))
        );
    };

    if (isLoading) return _renderLoading();
    const renderList = (list) => {
        if (list.length === 0 && !finalIsLoadingService) {
            return (
                <div className="items justify-center pointer-events-none">
                    <div className="loading -ajaxbar">
                        {finalKeyword
                            ? t('header:search_not_match')
                            : t('common:no_data_to_display', { title: t('common:services') })}
                    </div>
                </div>
            );
        }

        return list.map((item) => {
            const serviceId = item?.id;
            const isCheck = serviceId === serviceDetail.id;

            return (
                <li
                    key={serviceId}
                    className={`items ${isCheck ? 'active' : ''}`}
                    onClick={() => _openConfirmChangeService(serviceId)}
                >
                    <div className="word-break">{item.name}</div>
                </li>
            );
        });
    };

    return (
        <div className="details-job__elm rows has-line-bottom">
            <div className="txt">{t('customers:service')}</div>
            <div className="details detail-service">
                {Array.isArray(finalEmoji) && <div className="emoji-content">{finalEmoji.join('')}</div>}
                <EmojiPopper
                    placementDropdown="bottom"
                    placementTooltip="bottom"
                    notCloseOnClick
                    listEmoji={finalEmoji}
                    onChange={_handleChangeEmoji}
                    onClose={_handleSaveEmoji}
                />
                <div
                    ref={refSearch}
                    className={`v2-dropdown select-service ${finalIsVisible ? 'active' : ''} ${
                        isDisable ? 'is-disable' : ''
                    }`}
                >
                    <SearchOption
                        placeholder={t('addons:search')}
                        onSearch={_handleChangeSearch}
                        onFocus={_handleFocus}
                        defaultValue={serviceDetail.name}
                        style="search-input"
                        parentStyle="dropbtn items"
                    />
                    {finalIsVisible && (
                        <div className="v2-dropdown__menu content-full scrolls" onScroll={handleOnScrollContent}>
                            <ul id="show_result_search_services">
                                {renderList(state.options)}
                                {finalIsLoadingService && (
                                    <div className="items justify-center">
                                        <div className="loading -ajaxbar">
                                            <IconLoading />
                                        </div>
                                    </div>
                                )}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
            <GdConfirm
                ref={refConfirm}
                title={t('jobDetail:change_service_settings')}
                message={t('jobDetail:message_change_job_assigned')}
                listButton={{ cancel: true, confirm: true }}
                onConfirm={_handleChangeService}
            />
        </div>
    );
};

export default CustomerSerive;

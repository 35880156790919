import classNames from 'classnames';
import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Checkbox from 'app/components/checkbox';
import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import TooltipPopper from 'app/components/tooltip/TooltipPopper';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { SHOW_WORK_POOL } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { CALENDAR_MODES, NUMBER_SHOW_SCHEDULE, SCHEDULE_GROUP_SIZE } from 'app/modules/calendar/const';
import LoadingSchedule from 'app/modules/calendar/header/components/Loading';
import IconDropUpDown from 'assets/icon/IconDropUpDown';
import IconTooltip from 'assets/icon/IconTooltip';
import { updateSchedule } from 'common/redux/actions/calendar';
import { actionGetListSchedule } from 'common/redux/actions/calendar/scheduleAction';
import { actionOpenJobPreview } from 'common/redux/actions/job';
import { checkPermission } from 'common/utils/PermissionUtils';

export default function Schedules() {
    const { t } = useTranslation(['calendar', 'common']);
    const dispatch = useDispatch();
    const companyUsers = useSelector((state) => state.companyUsers.users);
    const permissionsList = useSelector((state) => state.auth?.user?.permissions?.enabled || []);
    const permissionWorkPool = checkPermission(permissionsList, SHOW_WORK_POOL);
    const { isFirstTime, data: dataSchedule } = useSelector(({ schedules }) => schedules);
    const { schedules, view } = useSelector((state) => state.calendar);
    const [state, setState] = useReducer(reducer, {
        listGroup: [],
        listSchedule: [],
        isVisible: false,
        isLoading: isFirstTime,
        selectedGR: []
    });

    const isCalendarMonth = CALENDAR_MODES.DAY_GRID_MONTH === view;
    const refDowpdown = useRef(null);
    const refWorkPool = useRef(null);

    const {
        isVisible: finalIsVisible,
        isLoading: finalLoading,
        listGroup: finalListGroup,
        listSchedule: finalListSchedule,
        selectedGR: finalGroupSelected
    } = state;

    useEffect(() => {
        if (finalIsVisible) {
            isFirstTime
                ? dispatch(actionGetListSchedule({}, _getListSuccess, _getListFailed))
                : _getListSuccess({ data: dataSchedule });
        }
    }, [finalIsVisible]);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function _getListSuccess(response) {
        const { schedules: responseSchedules = [], groups } = response.data || {};
        const schedulesSelected = schedules.map((item) => item.id);
        const scheduleRespnse = responseSchedules.map((item) => {
            item.active = schedulesSelected.includes(item.id);
            return item;
        });

        setState({
            listGroup: groups,
            listSchedule: scheduleRespnse,
            isLoading: false
        });
    }

    function _getListFailed() {}

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_list_schedule_group');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeDropdown();
        }
    }

    function _closeDropdown() {
        finalIsVisible && setState({ isVisible: false });
    }

    function _handleOpen(e) {
        e.stopPropagation();

        if (!finalIsVisible) {
            const scheduleResponse = [...finalListSchedule];
            const groupResponse = [...finalListGroup];

            if (!isFirstTime.current) {
                const schedulesSelected = schedules.map((item) => item.id);
                const groupSelected = finalGroupSelected.map((item) => item.id);

                scheduleResponse.map((item) => {
                    item.active = schedulesSelected.includes(item.id);
                    return item;
                });

                groupResponse.map((item) => {
                    item.active = groupSelected.includes(item.id);
                    return item;
                });
            }

            setState({
                listGroup: groupResponse,
                listSchedule: scheduleResponse,
                isVisible: true
            });
        } else {
            setState({ isVisible: false });
        }
    }

    function _handleClickGroup(e, groupSelected) {
        e.stopPropagation();

        const scheduleOfGroup = groupSelected.schedule_ids;
        const isActive = !groupSelected.active;

        finalListGroup.map((item) => {
            item.active = item.id === groupSelected.id ? isActive : false;
            return item;
        });

        finalListSchedule.map((item) => {
            item.active = scheduleOfGroup.includes(item.id) ? isActive : false;
            return item;
        });

        setState({
            listGroup: finalListGroup,
            listSchedule: finalListSchedule
        });
    }

    function _handleClickSchedule(e, scheduleId) {
        e.stopPropagation();

        finalListSchedule.map((item) => {
            if (item.id === scheduleId) {
                item.active = !item.active;
            } else {
                if (isCalendarMonth) {
                    item.active = false;
                }
            }
            return item;
        });

        setState({ listSchedule: finalListSchedule });
    }

    function _selectAllSchedule(e, isActive) {
        e && e.stopPropagation();

        finalListSchedule.map((item) => {
            item.active = !isActive;
            return item;
        });

        finalListGroup.map((item) => {
            item.active = !isActive;
            return item;
        });

        setState({ listSchedule: finalListSchedule, listGroup: finalListGroup });
    }

    function _handleClickUpdate(e) {
        e.preventDefault();
        e.stopPropagation();

        let tempScheduleSelected = [...finalListSchedule];
        let temGroundSelected = [...finalListGroup];
        const incWorkPool = permissionWorkPool ? refWorkPool.current.getValue() : false;

        temGroundSelected = temGroundSelected.filter((item) => item.active); //Get group item select to save for check when open again
        tempScheduleSelected = tempScheduleSelected.filter((item) => item.active);

        if (tempScheduleSelected.length === 0) {
            finalListSchedule.map((item, index) => {
                if (!index) {
                    tempScheduleSelected = [item];
                    item.active = true;
                }
                return item;
            });
            dispatch(updateSchedule({ schedules: tempScheduleSelected, listSchedule: finalListSchedule, incWorkPool }));
        } else {
            dispatch(updateSchedule({ schedules: tempScheduleSelected, incWorkPool }));
        }

        dispatch(actionOpenJobPreview(null));

        setState({ isVisible: false, selectedGR: temGroundSelected });
    }

    const _renderSheduleSelected = () => {
        return schedules.map((itemSche) => {
            const scheduleId = itemSche.id;
            const userAssign = companyUsers.find((item) => item.id === itemSche.user_id);
            const finalAvatar = userAssign?.avatar;

            return (
                <TooltipPopper key={scheduleId} className={`avt-img tooltip`} tooltipText={itemSche.name}>
                    {finalAvatar ? (
                        <img src={finalAvatar} width={24} height={24} alt="" />
                    ) : (
                        <div className="avt fs-11">{itemSche.name.substring(0, 2)}</div>
                    )}
                </TooltipPopper>
            );
        });
    };

    function _renderNumberSchedule() {
        const number = schedules.length - NUMBER_SHOW_SCHEDULE;
        if (number < 1) {
            return null;
        }
        return <div className="avt-img more-schedule">+{number}</div>;
    }

    const _renderColumnGroups = () => {
        const _renderGroups = () => {
            return finalListGroup.map((groupItem) => {
                const isSelected = groupItem.active || false;
                const groupId = groupItem.id;

                return (
                    <div
                        title={groupItem.name}
                        key={groupId}
                        className={classNames(`items`, { 'active-item': isSelected })}
                    >
                        <Checkbox
                            label={groupItem.name}
                            checked={isSelected}
                            id={`check-box-group-${groupId}`}
                            onChangeValue={_handleClickGroup}
                            value={groupItem}
                        />
                    </div>
                );
            });
        };

        const isActiveAll = !finalListSchedule.some((item) => !item.active);

        return (
            <div className={`list-schedules__boxs --box-group flex-1 ${isCalendarMonth ? 'is-disable' : ''}`}>
                <div className="mt-1">
                    <div className={classNames(`items`, { 'active-item': isActiveAll })}>
                        <Checkbox
                            label={t('calendar:all_schedules')}
                            checked={isActiveAll}
                            id={'check-box-select-all-schedules'}
                            onChangeValue={_selectAllSchedule}
                            value={isActiveAll}
                        />
                    </div>
                </div>
                {finalListGroup.length > 0 ? (
                    <Fragment>
                        <div className="items --group">{t('calendar:groups')}</div>
                        <div className="sidebar-group-modal">{_renderGroups()}</div>
                    </Fragment>
                ) : null}
            </div>
        );
    };

    const _renderColumnSchedule = () => {
        const _renderSchedules = (group) => {
            const { active: isSelectedGroup, id: groupId, name: groupName, schedule_ids } = group;
            const renderItem = schedule_ids.map((schedule_id) => {
                const dataItem = finalListSchedule.find((item) => item.id === schedule_id);
                if (!dataItem) return null;
                const { id: scheduleId, active: isSelected = false, name, nickname, user_id } = dataItem;

                const finalAvatar = companyUsers.find((item) => item.id === user_id)?.avatar;

                return (
                    <div
                        onClick={(e) => _handleClickSchedule(e, scheduleId)}
                        key={scheduleId}
                        title={nickname || name}
                        className={classNames('items', { 'active-item': isSelected })}
                    >
                        <Checkbox
                            label={nickname || name}
                            checked={isSelected}
                            id={`check-box-schedule-${groupId}-${scheduleId}`}
                            onChangeValue={_handleClickSchedule}
                            value={scheduleId}
                        >
                            <div className="avt-img">
                                {finalAvatar ? (
                                    <img src={finalAvatar} width={24} height={24} alt="" />
                                ) : (
                                    <div className="avt fs-11">{name.substring(0, 2)}</div>
                                )}
                            </div>
                        </Checkbox>
                    </div>
                );
            });

            return (
                <div
                    key={groupId}
                    className={classNames('item-group', { '--without-group': !groupName }, { active: isSelectedGroup })}
                >
                    {!!groupName ? (
                        <div className="items">
                            <Checkbox
                                checked={isSelectedGroup}
                                id={`check-box-schedule-${groupId}`}
                                onChangeValue={_handleClickGroup}
                                value={group}
                            >
                                <span className="txt-ellipsis name-group">{groupName}</span>
                            </Checkbox>
                        </div>
                    ) : null}
                    {renderItem}
                </div>
            );
        };

        let ids = [...finalListSchedule];
        let countItem = ids.length;

        if (!isCalendarMonth) {
            const arrSchedules = [];
            countItem = 0;
            finalListGroup.forEach((group) => {
                arrSchedules.push(...group.schedule_ids);
                countItem += group.schedule_ids.length + 1;
            });

            const unique = [...new Set(arrSchedules)];
            ids = ids.filter((item) => !unique.includes(item.id));
            countItem += ids.length;
        }

        const classGroup =
            countItem <= SCHEDULE_GROUP_SIZE.SMALL
                ? 'group-sm'
                : countItem <= SCHEDULE_GROUP_SIZE.MEDIUM
                  ? 'group-md'
                  : '';
        const schedulesWithoutGroup = {
            active: false,
            name: '',
            id: 'withoutGroup',
            schedule_ids: ids.map((item) => item.id)
        };

        return (
            <div className="list-schedules__boxs --box-schedule">
                <div className={`list-group ${classGroup}`}>
                    {_renderSchedules(schedulesWithoutGroup)}
                    {!isCalendarMonth && finalListGroup.map((item) => _renderSchedules(item))}
                </div>
            </div>
        );
    };

    return (
        <div className="wrap-schedule-picker">
            <div
                className={`wrap-schedule-picker__btn js-show-schedule-picker ${finalIsVisible ? 'active' : ''} `}
                onClick={_handleOpen}
            >
                {_renderSheduleSelected()}
                {_renderNumberSchedule()}
                <span className="svg-dropup">
                    <IconDropUpDown />
                </span>
            </div>
            <div
                ref={refDowpdown}
                className={`modal container-modal wrap-schedule-picker__list ${finalIsVisible ? 'open' : ''}`}
            >
                <div className="modal__overlay bg-fixed" onClick={_closeDropdown} />
                {finalLoading ? (
                    <LoadingSchedule />
                ) : (
                    finalIsVisible && (
                        <div className="modal__container">
                            <div id={'show_list_schedule_group'} className="list-schedules">
                                <div className="sidebar-modal flex-column">
                                    <div className="btn-modal">
                                        <div
                                            onClick={_handleClickUpdate}
                                            className="v2-btn-main fw-normal w-100 justify-center"
                                        >
                                            {t('calendar:update')}
                                        </div>
                                    </div>
                                    {_renderColumnGroups()}
                                    {permissionWorkPool ? <WorkPoolToggle ref={refWorkPool} /> : null}
                                </div>
                                {_renderColumnSchedule()}
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>
    );
}

const WorkPoolToggle = forwardRef((props, ref) => {
    const { t } = useTranslation(['calendar', 'common']);
    const defaultActive = useSelector(({ calendar }) => calendar.active_workpool);
    const [active, setActive] = useState(defaultActive);

    useImperativeHandle(ref, () => ({ getValue: () => active }));
    const handleChange = (checked) => {
        setActive(checked);
    };

    return (
        <div className="work-pool flexcenter">
            <div className="flexcenter gap-4 flex-1">
                <p>{t('work_pool')}</p>
                <p className="tooltip d-flex">
                    <IconTooltip />
                    <span className="tooltiptext top">{t('calendar:work_pool_tooltip')}</span>
                </p>
            </div>
            <GdSwitchCheckbox
                onChangeImmediately={handleChange}
                wrapClassName="switch large"
                shouldLabelActive={true}
                textChecked={t('common:on')}
                textUnChecked={t('common:off')}
                defaultChecked={defaultActive}
            />
        </div>
    );
});

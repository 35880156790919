import React, { useMemo, useEffect, useRef, useReducer } from 'react';
import moment from 'moment';
import DatePicker, { CalendarContainer } from 'react-datepicker';

import { DATE_RANGE, LIST_OPTIONS_SELECT_DATE_DEFAULT } from 'app/const/drip/Common';

import {
    START_DATE_OF_MONTH,
    END_DATE_OF_MONTH,
    CURRENT_DAY,
    LAST_30_DAYS,
    START_OF_MONTH,
    END_OF_MONTH,
    START_OF_LAST_MONTH,
    END_OF_LAST_MONTH,
    START_OF_LAST_3_MONTH,
    START_OF_LAST_6_MONTH,
    START_OF_YEAR,
    START_OF_LAST_YEAR,
    END_OF_LAST_YEAR,
    KEY_CODE_ESCAPE,
    KEY_REPORT_LOCAL_STORAGE,
    START_DATE_LAST_WEEK,
    END_DATE_LAST_WEEK,
    START_DATE_THIS_WEEK,
    END_DATE_THIS_WEEK,
    START_DATE_LAST_7_DAYS,
    START_DATE_ALL_TIME
} from 'app/const/App';

import SelectRangeOptions from './components/SelectRangeOptions';

import { HeaderCustom } from './components/HeaderCustom';
import InputCustom from './components/InputCustom';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { getDefaultParams } from 'app/const/report/ReportParams';
import { reducer } from 'app/const/Reducer';

const TODAY = new Date();

function DateRangePicker({
    i18Text = '',
    selectDefault = {},
    handleSelect = () => {},
    wrapperClassName = '',
    customInputClassName = '',
    reportType = '',
    isNoRefresh = false,
    monthsShown = 2,
    isShowLabel = false,
    listOptionsDateDefault = LIST_OPTIONS_SELECT_DATE_DEFAULT,
    isAddMonthWhenOpen = true
}) {
    const refInputCustom = useRef(null);
    const refDatePicker = useRef(null);
    const refDatepickerId = useRef(`datepicker_${moment().format('x')}`);
    const inputId = `input_${refDatepickerId.current}`;
    const paramsLocal =
        getLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', reportType)) || getDefaultParams(reportType);

    const currentDay = useMemo(() => {
        if (selectDefault) {
            return {
                startDate: moment(selectDefault.startDate)?._d,
                endDate: moment(selectDefault.endDate)?._d
            };
        }
        return { startDate: START_DATE_OF_MONTH, endDate: END_DATE_OF_MONTH };
    }, [selectDefault]);

    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        selected: { ...currentDay },
        apply: { ...currentDay },
        selectedRange: DATE_RANGE.THIS_MONTH,
        isOpenToDate: false,
        keyPicker: Date.now()
    });

    const selected = { ...state.selected };
    const startDate = selected?.startDate;
    const endDate = selected?.endDate;
    const apply = { ...state.apply };
    const { isOpen, isOpenToDate, selectedRange: finalSelectedRange, keyPicker } = state;

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [isOpen]);

    const handleHideDropdown = (event) => {
        const elPrevent = document.getElementById(refDatepickerId.current);
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeDropdown();
        }
    };

    const handleClickOutside = (event) => {
        const elInputCustom = document.getElementById(inputId);
        if (elInputCustom?.contains(event.target)) {
            return false;
        }
        const elPrevent = document.getElementById(refDatepickerId.current);
        if (
            refDatePicker.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDatePicker.current.contains(event.target)
        ) {
            _closeDropdown();
        }
    };

    const _closeDropdown = () => {
        isOpen && dispatchState({ isOpen: false });
    };

    useEffect(() => {
        selectDefault &&
            !isNoRefresh &&
            dispatchState({
                selected: currentDay,
                apply: {
                    startDate: selectDefault.start || paramsLocal.start,
                    endDate: selectDefault.end || paramsLocal.end
                }
            });
    }, [currentDay, selectDefault]);

    const _toggle = () => {
        dispatchState({ isOpen: !isOpen });
    };

    const handleSelectSpecificDate = (value) => {
        if (Array.isArray(value)) {
            const [start, end] = value;
            dispatchState({ selected: { startDate: start, endDate: end }, isOpenToDate: true });
            if (start && end) {
                handleChangeDate({ startDate: moment(start)._d, endDate: moment(end)._d }, true, null);
                _toggle();
            }
        }
    };

    const handleChangeDate = (dateRange, isSelected = false, valueRange = null) => {
        const newData = { ...dateRange };
        if (!dateRange?.endDate) newData['endDate'] = dateRange.startDate;

        dispatchState({
            selected: isSelected ? newData : selected,
            apply: newData,
            selectedRange: valueRange,
            keyPicker: Date.now()
        });

        if (_isDateValid(newData?.startDate) && _isDateValid(newData?.endDate)) {
            handleSelect({
                start: moment(newData?.startDate).startOf('day').format(),
                end: moment(newData?.endDate).endOf('day').format()
            });
        }
    };

    const handleOnClick = (type, value, preventHide = false) => {
        switch (type) {
            case DATE_RANGE.SET_OPEN:
                value.onClick();
                break;
            case DATE_RANGE.CANCEL:
                const isValid = _isDateValid(apply.startDate);
                const newDate = {
                    startDate: isValid ? moment(apply.startDate)?._d : TODAY,
                    endDate: isValid ? moment(apply.endDate)?._d : TODAY
                };

                dispatchState({ apply: newDate, selected: newDate });
                break;
            case DATE_RANGE.APPLY:
                handleChangeDate(selected);
                break;

            // New function range for v2
            case DATE_RANGE.LAST_30_DAYS:
                handleChangeDate({ startDate: LAST_30_DAYS._d, endDate: CURRENT_DAY._d }, true, type);
                break;
            case DATE_RANGE.THIS_MONTH:
                handleChangeDate({ startDate: START_OF_MONTH._d, endDate: END_OF_MONTH._d }, true, type);
                break;
            case DATE_RANGE.LAST_MONTH:
                handleChangeDate({ startDate: START_OF_LAST_MONTH._d, endDate: END_OF_LAST_MONTH._d }, true, type);
                break;
            case DATE_RANGE.LAST_3_MONTH:
                handleChangeDate({ startDate: START_OF_LAST_3_MONTH._d, endDate: END_OF_LAST_MONTH._d }, true, type);
                break;
            case DATE_RANGE.LAST_6_MONTH:
                handleChangeDate({ startDate: START_OF_LAST_6_MONTH._d, endDate: END_OF_LAST_MONTH._d }, true, type);
                break;
            case DATE_RANGE.THIS_YEAR:
                handleChangeDate({ startDate: START_OF_YEAR._d, endDate: CURRENT_DAY._d }, true, type);
                break;
            case DATE_RANGE.LAST_YEAR:
                handleChangeDate({ startDate: START_OF_LAST_YEAR._d, endDate: END_OF_LAST_YEAR._d }, true, type);
                break;
            case DATE_RANGE.CLEAR:
                handleChangeDate({ startDate: null, endDate: null }, true, type);
                break;
            case DATE_RANGE.THIS_WEEK:
                handleChangeDate({ startDate: START_DATE_THIS_WEEK._d, endDate: END_DATE_THIS_WEEK._d }, true, type);
                break;
            case DATE_RANGE.LAST_WEEK:
                handleChangeDate({ startDate: START_DATE_LAST_WEEK._d, endDate: END_DATE_LAST_WEEK._d }, true, type);
                break;
            case DATE_RANGE.TO_DAY:
                handleChangeDate({ startDate: CURRENT_DAY._d, endDate: CURRENT_DAY._d }, true, type);
                break;
            case DATE_RANGE.LAST_7_DAYS:
                handleChangeDate({ startDate: START_DATE_LAST_7_DAYS._d, endDate: CURRENT_DAY._d }, true, type);
                break;
            case DATE_RANGE.ALL_TIME:
                handleChangeDate({ startDate: START_DATE_ALL_TIME._d, endDate: CURRENT_DAY._d }, true, type);
                break;
            default:
                break;
        }
        !preventHide && _toggle();
    };

    const renderContainer = ({ children }) => {
        return (
            <div ref={refDatePicker} id={refDatepickerId.current} className="format-container-date-picker">
                <div className="wrapper-select-range">
                    <SelectRangeOptions
                        data={listOptionsDateDefault}
                        i18Value={i18Text}
                        activeRange={selected || null}
                        onClick={handleOnClick}
                    />

                    <div className="wrapper-date-picker-container">
                        <CalendarContainer className="react-datepicker-custom">
                            <div style={{ position: 'relative' }}>{children}</div>
                        </CalendarContainer>
                    </div>
                </div>
            </div>
        );
    };

    const _isDateValid = (date) => moment(date).isValid();

    const _getDateOpen = () => {
        if (endDate) {
            if (!isOpenToDate) {
                return undefined;
            } else {
                const startDateMoment = moment(startDate);
                const range = moment(endDate).month() - startDateMoment.month();
                if (range === 0 || range === 1) {
                    return startDate;
                } else if (isAddMonthWhenOpen) {
                    return new Date(startDateMoment.add(1, 'months'));
                }
            }
        }
        return undefined;
    };

    return (
        <DatePicker
            key={keyPicker}
            open={isOpen}
            selectsRange
            openToDate={_getDateOpen()}
            monthsShown={monthsShown}
            wrapperClassName={wrapperClassName}
            selected={_isDateValid(startDate) ? startDate : TODAY}
            startDate={startDate || TODAY}
            endDate={endDate}
            shouldCloseOnSelect={false}
            disabledKeyboardNavigation
            calendarContainer={renderContainer}
            onChange={(date) => handleSelectSpecificDate(date)}
            onMonthChange={(date) => handleSelectSpecificDate(date)}
            renderCustomHeader={HeaderCustom}
            customInput={
                <InputCustom
                    ref={refInputCustom}
                    inputId={inputId}
                    apply={apply}
                    handleOnClick={_toggle}
                    className={customInputClassName}
                    isOpen={isOpen}
                    isShowLabel={isShowLabel}
                    label={finalSelectedRange}
                    listOptionsDate={listOptionsDateDefault}
                />
            }
        />
    );
}

export default DateRangePicker;

import React, { useContext, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { updateSoldByJob } from 'app/const/api/V2';
import { PERMISSIONS } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import CommissionDropdown from 'app/modules/addjob/commission/components/CommissionDropdown';
import { TabDetailContext } from 'app/modules/jobdetail/contexts/TabDetailContext';
import { getCommissionItems } from 'common/redux/actions/commission';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkPermission, getPermissionCommission } from 'common/utils/PermissionUtils';
import LoadingCommission from './Loading';

const Commission = ({ isLoading: isJobLoading, jobData = {} }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { updateCommissionJobContext } = useContext(TabDetailContext);
    const commissionReducer = useSelector(({ commissionReducer }) => commissionReducer);
    const { permissions, settings } = useSelector(({ auth }) => auth.user);
    const { canModify, canDisplayCommission } = getPermissionCommission(
        settings.addons.commission_tracking,
        checkPermission(permissions?.enabled || [], PERMISSIONS.salesCommission)
    );
    const [state, dispatchState] = useReducer(reducer, { selected: jobData.sold_by || [] });
    const { selected } = state;

    useEffect(() => {
        if (commissionReducer.isFirstTime && canModify && canDisplayCommission) dispatch(getCommissionItems());
    }, []);

    useEffect(() => {
        dispatchState({ selected: jobData.sold_by || [] });
    }, [isJobLoading]);

    const _handleSelect = (userInfo) => {
        const userIsSelected = selected.some((item) => item.id === userInfo.id);
        const newSelected = userIsSelected
            ? selected.filter((item) => item.id !== userInfo.id)
            : selected.concat(userInfo);

        _handleUpdateSoldBy(newSelected);
    };

    const _handleUnselect = (userId) => {
        _handleUpdateSoldBy(selected.filter((item) => item.id !== userId));
    };

    const _handleUpdateSoldBy = (newSelected) => {
        dispatchState((prevState) => ({ ...prevState, selected: newSelected }));
        updateCommissionJobContext(newSelected);
        clientQuery(updateSoldByJob(jobData.job.id), {
            data: { ids: newSelected.map((item) => item.id) },
            toFormData: false,
            method: 'PUT'
        });
    };

    if (isJobLoading) return <LoadingCommission />;
    if (!canModify) return null;
    return (
        <div className="details-job__elm rows has-line-bottom">
            <div className="txt">{t('common:sold_by')}</div>
            <div className="details detail-schedule is-sold-by">
                <CommissionDropdown selected={jobData.sold_by} onSelect={_handleSelect} onUnselect={_handleUnselect} />
            </div>
        </div>
    );
};

export default Commission;

import React, { useReducer, useRef } from 'react';
import IconUpgrade from 'assets/icon/IconUpgrade';
import ListAccount from './Account';
import ButtonSave from 'app/components/button/ButtonSave';
import StatusBar from 'app/components/status/statusbar';
import { clientQuery } from 'common/utils/ApiUtils';
import { ACTIONN_UPDATE_OPTION_QUICKBOOKS_ADDON } from 'app/const/api/V2';
import { LIST_STATUS } from 'app/const/Status';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';
import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';

let timmer;

export default function TabDiscountSync({
    parentData,
    keyword,
    onUpdateOption,
    accountSelected,
    account,
    isFetched,
    onChange
}) {
    const { t } = useTranslation(['report']);
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        valueAccount: accountSelected,
        ...parentData
    });

    const refButtonSave = useRef(null);
    const refStatusBar = useRef(null);

    const finalIsActive = !!state.value;
    const finalValueAccount = state.valueAccount;

    function _handleChangeValue() {
        if (!parseInt(finalValueAccount) && !finalIsActive) {
            refStatusBar.current.showStatusBar('show_error', t('addons:please_select_account'), LIST_STATUS.ERROR);
            return false;
        }

        dispatchState({
            value: !finalIsActive
        });

        timmer && clearTimeout(timmer);
        timmer = setTimeout(() => {
            onUpdateOption(keyword, !finalIsActive ? 1 : 0);
        }, 500);
    }

    function _handleSelectAccount(accountId) {
        dispatchState({
            valueAccount: accountId
        });
    }

    function _checkDisable() {
        return !finalValueAccount || accountSelected === finalValueAccount;
    }

    function _handleSave() {
        clientQuery(
            ACTIONN_UPDATE_OPTION_QUICKBOOKS_ADDON,
            {
                method: 'PUT',
                data: {
                    keyword: 'discount_qb',
                    value: finalValueAccount
                }
            },
            _handleSaveSuccess,
            _handleSaveFailed
        );
    }

    function _handleSaveSuccess() {
        onChange(finalValueAccount);
        _removeButtonLoading();
        refStatusBar.current.showStatusBar('show_error', t('common:update_successfully'), LIST_STATUS.SUCCESS);
    }

    function _handleSaveFailed(response) {
        _removeButtonLoading();
        refStatusBar.current.showStatusBar(
            'show_error',
            response?.message?.toString() || t('common:please_try_again'),
            LIST_STATUS.ERROR
        );
    }

    function _removeButtonLoading() {
        refButtonSave.current.removeLoading();
    }

    return (
        <div className={`boxs boxs-has-footer ${finalIsActive ? 'active' : ''}`}>
            <div className="boxs__header">
                <div className="header-title">
                    <div className="title">{parentData.name}</div>
                    <div className="dots powerups">
                        <IconUpgrade isFillOrange />
                        {t('report:power_up')}
                    </div>
                </div>
                <GdSwitchCheckbox
                    id="disscount_sync"
                    inputClassName="toggle toggle-round"
                    textUnChecked={t('common:disabled')}
                    textChecked={t('common:enabled')}
                    defaultChecked={finalIsActive}
                    onChangeImmediately={_handleChangeValue}
                />
            </div>
            <div className="boxs__contents">
                <StatusBar ref={refStatusBar} />
                <p className={'description --hasline'}>{parentData.description}</p>
                <p className="required txt-ellipsis">
                    <span className="fw-500">{t('report:discounts_refunds_given')}</span> (
                    {t('report:qb_account_discount')})<span className="red-delete">*</span>
                </p>
                <ListAccount
                    onSelect={_handleSelectAccount}
                    accountSelected={finalValueAccount}
                    account={account}
                    isFetched={isFetched}
                />
            </div>
            <div className="boxs__footer">
                <ButtonSave disabled={_checkDisable()} ref={refButtonSave} onSave={_handleSave} />
            </div>
        </div>
    );
}

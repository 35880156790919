import moment from 'moment';
import { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import { ONE_HUNDRED_PERCENT } from 'app/const/addons';
import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import { onHandlePercent } from 'common/utils/NumberUtils';

const ModalRoutingAddon = (props, ref) => {
    const { t, i18n } = useTranslation(['addons']);
    const [state, dispatchState] = useReducer(reducer, { isVisible: false, data: {} });
    const { isVisible, data } = state;
    const { maximum, amount, last_month_amount: lastMonthAmount } = data;

    useImperativeHandle(ref, () => ({ _open: _handleOpen, _close: _handleClose }));

    const _handleOpen = (data) => {
        dispatchState((prev) => ({ ...prev, isVisible: true, data }));
    };

    const _handleClose = () => {
        dispatchState({ isVisible: false });
    };

    if (!isVisible) return null;
    return (
        <ReactModal
            id="modal_drive_matrix"
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal wrapper-has-preview is-preview-template wrapper-billing modal-drive-matrix open"
            onRequestClose={_handleClose}
        >
            <div className="modal__overlay bg-fixed" onClick={_handleClose} />
            <div className="modal__container --preview-r">
                <div className="wrap-container">
                    <div className="wrap-container__form flex-column">
                        <div className="header-modal">
                            <h3 className="header-modal__label">{t('addons:manage_drive_matrix')}</h3>
                            <div className="v2-btn-default --transparent --icon-lg" onClick={_handleClose}>
                                <IconClose />
                            </div>
                        </div>
                        <div className="body-modal scrolls">
                            <div className="wrap-process customer-process fs-14">
                                <div className="wrap-process customer-process">
                                    <p className="description violet-name">{t('addons:drive_matrix_desc')}</p>
                                    <p className="description mt-5 violet-name">
                                        {t('addons:drive_matrix_desc_contact')}
                                    </p>
                                    <div className="rows mt-4">
                                        <div className="rows__label">{t('addons:connection_usage_this_month')}</div>
                                        <div
                                            className={`progress-bar --green per-${`${ONE_HUNDRED_PERCENT - onHandlePercent(amount, maximum)}`}`}
                                        >
                                            <span />
                                        </div>
                                        <div className="rows__number">
                                            {maximum - amount}/{maximum}
                                        </div>
                                    </div>
                                    <div className="rows">
                                        <div className="rows__label">
                                            {t('addons:usage_last_month', {
                                                month: moment()
                                                    .subtract(1, 'month')
                                                    .startOf('month')
                                                    .locale(i18n.language)
                                                    .format('MMM')
                                            })}
                                        </div>
                                        <div
                                            className={`progress-bar --black per-${onHandlePercent(lastMonthAmount, maximum)}`}
                                        >
                                            <span />
                                        </div>
                                        <div className="rows__number" title={lastMonthAmount}>
                                            {lastMonthAmount}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ReactModal>
    );
};

export default forwardRef(ModalRoutingAddon);

import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from 'app/components/checkbox';
import { CUSTOM_FIELDS_TYPES } from 'app/modules/customfields/constant';
import IconThreeDots from 'assets/icon/IconThreeDots';

const Row = ({
    provided,
    label = '',
    id,
    type,
    value,
    active = false,
    accepts_multiple_values: acceptMultipleValues = false,
    isSupperAdmin = false,
    permissionEdit = false,
    onCheck = () => {},
    onOpenEdit = () => {}
}) => {
    const { t } = useTranslation('common');
    return (
        <div
            ref={provided.innerRef}
            className="rows"
            style={{ ...provided.draggableProps.style }}
            {...provided.draggableProps}
        >
            {isSupperAdmin ? (
                <div className="col --checkbox">
                    <Checkbox id={id} checked={active} onChangeValue={onCheck} />
                </div>
            ) : null}
            {permissionEdit ? (
                <div className="col --menu" {...provided.dragHandleProps}>
                    <IconThreeDots isDouble />
                </div>
            ) : null}
            <div className="col">
                <p
                    title={label}
                    onClick={onOpenEdit}
                    className="txt-ellipsis btn-modal purple-default fw-500 cursor-pointer"
                >
                    {label}
                </p>
            </div>
            <div className="col">
                <div className="tag-label bg-black-verydark">
                    {acceptMultipleValues && CUSTOM_FIELDS_TYPES.CHOICES ? t('common:choices_multiple') : t(type)}
                </div>
            </div>
            <ColValue value={value} type={type} />
        </div>
    );
};

const ColValue = ({ value, type }) => {
    let extraClass = '';
    let valueDisplay = value;

    if (CUSTOM_FIELDS_TYPES.CHOICES === type) valueDisplay = value.join(', ');
    else {
        valueDisplay = `[custom_${type}_value]`;
        extraClass = 'grey-sonic-silver';
    }
    return (
        <div className="col col-value">
            <p className={classNames('txt-ellipsis', extraClass)}>{valueDisplay}</p>
        </div>
    );
};

export default Row;

import loadable from '@loadable/component';
import classNames from 'classnames';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import ButtonSave from 'app/components/button/ButtonSave';
import GDModalWarning from 'app/components/modal/ModalWarning';
import { ADDONS_QUICKBOOKS_SYNC_LOG } from 'app/config/routes';
import {
    API_CUSTOMER,
    CUSTOMERS_QB_SYNC,
    CUSTOMER_MERGE,
    GET_LIST_CUSTOMER,
    PERMANENTLY_DELETE_CUSTOMER,
    UNDELETE_CUSTOMER,
    UPDATE_STATUS_MULTIPLE_CUSTOMERS
} from 'app/const/Api';
import { COMMON, KEY_REPORT_LOCAL_STORAGE, LIST_STATUS, PREFIX_TITLE, TYPE_BUTTON_ACTIONS } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { LIST_ITEM_SIDE_MENU } from 'app/const/Customers';
import { PERMISSIONS } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { REPORT_LIMIT, REPORT_LIMIT_LOADMORE, REPORT_TYPE } from 'app/const/Reports';
import { getGridColumns } from 'app/const/customer/CustomerTable';
import { SORT_BY, TABS_FILTER } from 'app/const/report/ReportFilter';
import { getDefaultParams } from 'app/const/report/ReportParams';
import { clientQuery } from 'common/utils/ApiUtils';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { checkPermission } from 'common/utils/PermissionUtils';
import ErrorPage from '../error';
import CustomerAlphabet from './components/CustomerAlphabet';
import { KEY_LOCAL_SHOW_SUB_LOCATION, VIEW_CUSTOMER_LIST, VIEW_CUSTOMER_MAP } from './const';
import { customersToTable, customersWithSubLocations, getParamsCustomerList } from './utils';
import { convertParamFields } from 'common/utils/ReportUtils';
import ServiceHideInvoiceBalance from './components/RealtimeHideInvoiceBalance';

const CustomersHeader = loadable(() => import('./components/header'));
const GdConfirm = loadable(() => import('app/components/confirm'));
const GDStatusBar = loadable(() => import('app/components/status/statusbar'));
const CustomerHeaderTable = loadable(() => import('./components/CustomerHeaderTable'));
const CustomerMap = loadable(() => import('./components/CustomerMap'));
const CustomerMergeModal = loadable(() => import('./components/CustomerMergeModal'));
const CustomerImportProgress = loadable(() => import('./components/importCustomer/CustomerImportProgress'));
const SideMenu = loadable(() => import('./layouts/SideMenu'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const ModalLoading = loadable(() => import('app/components/loading/ModalLoading'));
const SearchQBModal = loadable(() => import('app/components/quickbooks/SearchQBModal'));

const Customers = () => {
    const { t } = useTranslation(['auth', 'pageTitle']);
    const { state: stateLocation } = useLocation();
    const history = useHistory();
    const abortController = useRef();

    const defaultShowSubLocation = getLocalStorage(KEY_LOCAL_SHOW_SUB_LOCATION);
    const keyLocalStore = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.CUSTOMERS);
    const paramsCustomers = getParamsCustomerList();

    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        checkedItems: { is_check_all: false, ids: [] },
        total: 0,
        isShowSubLocations: defaultShowSubLocation === 'show',
        isLoading: true,
        isLoadingMore: false,
        deleted: paramsCustomers.deleted,
        currentView: VIEW_CUSTOMER_LIST,
        forceRerender: false,
        refresh: false,
        refreshScreen: 0,
        idActive: paramsCustomers.sideMenuActive,
        tabFilterPagination: paramsCustomers.filterTab,
        reloadFilter: 0,
        actionSuccess: 0
    });

    const userReducer = useSelector(({ auth }) => auth.user);
    const quickBooksAddons = userReducer.settings.addons.quickbooks;
    const permissionsList = userReducer.permissions.enabled || [];
    const isHaveACH = !!userReducer.settings.addons?.stripe?.ach;

    const refLoading = useRef(null);
    const refAlert = useRef(null);
    const refMergeCustomer = useRef(null);
    const refProgress = useRef(null);
    const refQBModal = useRef(null);
    const refFirstTime = useRef(true);
    const refCustomerMap = useRef(null);
    const refConfirm = useRef(null);
    const refWarning = useRef(null);
    const refStepLoad = useRef(0);
    const refCustomerAlphabet = useRef(null);
    const refIsReceivedOverride = useRef(false);

    const {
        isLoading,
        deleted,
        data: customers,
        currentView,
        idActive,
        refreshScreen,
        checkedItems: finalCheckedItems,
        isLoadingMore: finalIsLoadMore,
        total: finalTotal,
        actionSuccess
    } = state;

    const { ids: checkedIds } = finalCheckedItems;
    const isViewMap = currentView === VIEW_CUSTOMER_MAP;
    const numberCurrentData = customers.length;

    useEffect(() => {
        document.title = `${PREFIX_TITLE} - ${t('pageTitle:customers')}`;
    }, []);

    useEffect(() => {
        if (stateLocation?.error && history.action === 'REPLACE' && refAlert.current) {
            refAlert.current.handeAddStatus({
                id: 'customer_not_found_alert',
                message: stateLocation.error,
                type: stateLocation.type || LIST_STATUS.ERROR
            });
            delete stateLocation.error;
        }
    }, [stateLocation, isLoading]);

    useEffect(() => {
        if (
            actionSuccess &&
            numberCurrentData < finalTotal &&
            numberCurrentData < REPORT_LIMIT_LOADMORE &&
            !isLoading &&
            !finalIsLoadMore
        ) {
            dispatchState((prev) => {
                return { ...prev, isLoadingMore: true };
            });
        }
    }, [actionSuccess]);

    useEffect(() => {
        let timeoutId = null;
        if (finalIsLoadMore) {
            timeoutId = setTimeout(() => {
                const dataQuery = {
                    ...paramsCustomers,
                    offset: refStepLoad.current + REPORT_LIMIT,
                    reloadFilter: state.reloadFilter,
                    sub_locations: state.isShowSubLocations ? 1 : 0
                };
                _getListCustomer(false, dataQuery, true);
            }, 100);
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [finalIsLoadMore]);

    useEffect(() => {
        _getListCustomer(
            true,
            { ...paramsCustomers, keyword: '', sub_locations: state.isShowSubLocations ? 1 : 0 },
            false,
            true
        );
        return () => {
            if (abortController.current) abortController.current.abort();
            const paramsReset = getParamsCustomerList();
            setLocalStorage(keyLocalStore, { ...paramsReset, keyword: '' });
        };
    }, []);

    const _getListCustomer = (
        isReset,
        data,
        notShowLoading = false,
        isFirstLoad = false,
        isShowSubLocations = state.isShowSubLocations
    ) => {
        const params = data ? _getNewOptionsRequest(data) : {};
        if ((isLoading || finalIsLoadMore) && abortController.current) abortController.current.abort();

        abortController.current = new AbortController();

        const checkShouldLoading = !notShowLoading && !isLoading;
        const optionsQuery = {
            data: convertParamFields({ params, isEmptyFields: finalIsLoadMore }),
            method: 'GET',
            abortController: abortController.current
        };
        const checkRefresh = data?.reloadFilter;

        if (isFirstLoad) optionsQuery['data'][paramsCustomers['sideMenuActive']] = 1;
        if (checkShouldLoading) {
            dispatchState((prev) => {
                return {
                    ...prev,
                    checkedItems: checkShouldLoading ? { is_check_all: false, ids: [] } : prev.checkedItems,
                    isLoading: isReset || !!checkShouldLoading || prev.isLoading,
                    data: isReset ? [] : prev.data,
                    deleted: params.deleted,
                    archived: params.archived
                };
            });
        }

        const _success = ({ data, total }, tabQuery) => {
            if (!data) {
                // eslint-disable-next-line no-undef
                fetch(process.env.REACT_APP_URL_WEB_HOOK_SLACK, {
                    method: 'POST',
                    body: JSON.stringify({
                        type: 'crash',
                        text: { path: 'customer', storage: getParamsCustomerList() }
                    }),
                    headers: { Accept: 'application/json' }
                });
            }
            const finalData = data || [];
            dispatchState((prev) => {
                isReset ? (refStepLoad.current = 0) : (refStepLoad.current += REPORT_LIMIT);
                const { checkedItems } = prev;
                // This variable checkDifferentTabs check current tab have matching with data response with case user click quickly
                const checkDifferentTabs = !!tabQuery && prev.reloadFilter !== tabQuery;
                const newData = isReset
                    ? customersToTable(finalData)
                    : checkDifferentTabs
                      ? prev.data
                      : prev.isShowSubLocations
                        ? customersWithSubLocations(prev.data, finalData)
                        : [...prev.data, ...customersToTable(finalData)];

                return {
                    ...prev,
                    data: newData,
                    isLoading: false,
                    isLoadingMore: false,
                    isShowSubLocations:
                        isShowSubLocations !== prev.isShowSubLocations ? isShowSubLocations : prev.isShowSubLocations,
                    total: total ?? prev.total ?? 0,
                    refreshScreen: refreshScreen + 1,
                    checkedItems: {
                        ...checkedItems,
                        ids: checkedItems.is_check_all ? (newData || []).map((e) => e.id) : checkedItems.ids
                    }
                };
            });
        };

        const _failed = ({ isAborted = false }) => {
            if (!isAborted) dispatchState({ isLoading: false });
        };
        clientQuery(GET_LIST_CUSTOMER, optionsQuery, (response) => _success(response, checkRefresh), _failed);
    };

    const _handleUpdate = () => {
        const params = getLocalStorage(keyLocalStore) || getDefaultParams(REPORT_TYPE.CUSTOMERS);
        params['sub_locations'] = state.isShowSubLocations ? 1 : 0;
        _getListCustomer(true, params);
    };

    const _getNewOptionsRequest = (params) => {
        const newParams = { ...params };

        newParams.limit = REPORT_LIMIT;

        if (newParams.status) newParams.status = newParams?.status?.toString();
        if (newParams.tags) {
            newParams.tag = newParams.tags.toString();
            delete newParams.tags;
        }
        delete newParams?.sideMenuActive;
        delete newParams?.columns;
        delete newParams?.filterTab;
        delete newParams?.currentPage;
        delete newParams?.reloadFilter;
        return newParams;
    };

    const onChangeStateCheckedItems = (checkedItems) => {
        dispatchState({ checkedItems });
    };

    const _handleClearSelected = () => {
        dispatchState((prevState) => ({ ...prevState, checkedItems: { is_check_all: false, ids: [] } }));
    };

    const _fetchMap = (dataFetch, isShowSub = state.isShowSubLocations) =>
        refCustomerMap.current._getListCustomerMap(dataFetch, isShowSub);

    const _handleChangeSideMenu = (id) => {
        abortController.current.abort();

        const resultDispatch = { forceRerender: !state.forceRerender, idActive: id };
        refFirstTime.current = true;
        refCustomerMap.current._resetIsFetched();
        const currentParams = getLocalStorage(keyLocalStore);
        const isActiveTotalCustomer = id === 'total_customer';
        const refreshId = Date.now();
        LIST_ITEM_SIDE_MENU.forEach(({ idFilter }) => {
            currentParams[idFilter] && delete currentParams[idFilter];
        });

        currentParams.sideMenuActive = id;
        currentParams.status = isActiveTotalCustomer ? [0, 1] : [0, 1, 2];
        currentParams.offset = 0;
        currentParams.currentPage = 1;
        currentParams.character = '';
        currentParams.tags = -1;
        currentParams.keyword = '';

        if (!isActiveTotalCustomer) {
            currentParams[id] = 1;
            const filterTab = TABS_FILTER.ACTIVE.value;
            if (!!currentParams.deleted) {
                currentParams.deleted = 0;
                currentParams.filterTab = filterTab;
                resultDispatch.tabFilterPagination = filterTab;
            }
        }

        setLocalStorage(keyLocalStore, currentParams);
        dispatchState((prevState) => {
            return {
                ...prevState,
                ...resultDispatch,
                deleted: currentParams.deleted,
                archived: currentParams.archived,
                data: [],
                isLoading: true,
                isLoadingMore: false,
                checkedItems: { is_check_all: false, ids: [] },
                reloadFilter: refreshId
            };
        });

        const paramsFetch = {
            ...currentParams,
            reloadFilter: refreshId,
            sub_locations: state.isShowSubLocations ? 1 : 0
        };
        isViewMap ? _fetchMap({ shouldClear: true }) : _getListCustomer(true, paramsFetch, true);
    };

    const _handleChangeFilter = (params, mode) => {
        if (!mode) return;
        if (mode === 'columns') {
            dispatchState((prev) => ({ ...prev, refreshScreen: prev.refreshScreen + 1 }));
            return;
        }
        const newParams = { ...params };
        if (mode === SORT_BY) {
            if (paramsCustomers.character) {
                newParams['character'] = '';
                setLocalStorage(keyLocalStore, newParams);
            }
            refCustomerAlphabet.current?.fetchList(newParams.sort_by);
        }
        if (isViewMap) {
            refCustomerMap.current._resetIsFetched();
            _fetchMap();
        } else _getListCustomer(true, newParams);
    };

    const _handleChangeSearch = (keyword) => {
        const newParams = { ...paramsCustomers, offset: 0, currentPage: 1, keyword };
        setLocalStorage(keyLocalStore, newParams);
        if (isViewMap) {
            refCustomerMap.current._resetIsFetched();
            _fetchMap();
        } else _getListCustomer(true, newParams);
    };

    const _handleQBSyncIds = () => {
        clientQuery(CUSTOMERS_QB_SYNC, { data: { ids: checkedIds }, method: 'POST' }, null, null, _redirectQBLogs);
    };

    const _redirectQBLogs = () => {
        history.push(addBranchPath(ADDONS_QUICKBOOKS_SYNC_LOG));
    };
    const _handleClickAction = (type, data) => {
        if (type !== TYPE_BUTTON_ACTIONS.MERGE_ACCOUNT) refLoading.current._open();

        switch (type) {
            case TYPE_BUTTON_ACTIONS.DELETE:
                _handleConfirmWarning();
                break;
            case TYPE_BUTTON_ACTIONS.MARK_AS:
                _handleUpdateStatus(
                    { ids: checkedIds },
                    [...state.data].filter((item) => checkedIds.includes(item.id)),
                    data
                );
                break;
            case TYPE_BUTTON_ACTIONS.PERMANENTLY_DELETE:
                _handlePermanentlyDelete({ ids: checkedIds });
                break;
            case TYPE_BUTTON_ACTIONS.UNDELETE:
                _handleUnDelete({ ids: checkedIds });
                break;
            case TYPE_BUTTON_ACTIONS.MERGE_ACCOUNT:
                _handleMergeAccount(checkedIds);
                break;
            case TYPE_BUTTON_ACTIONS.SYNC_QB:
                _handleQBSyncIds();
                break;
            default:
                break;
        }
    };

    const _handleMergeAccount = (ids = []) => {
        if (ids.length >= 2) {
            const accounts = [];

            customers.forEach(({ id, customer, account_number }) => {
                if (ids.includes(id)) {
                    accounts.push({
                        ...(customer || {}),
                        account_number: !!account_number ? `#${account_number}` : ''
                    });
                }
            });

            refMergeCustomer.current._open(accounts);
        } else {
            refConfirm.current.open(null, t('customers:please_select_at_least_two_customers'));
        }
    };

    const _handleUnDelete = (data) => {
        clientQuery(
            UNDELETE_CUSTOMER,
            { data, method: 'PUT' },
            (response) => _handleSuccessDelete(response, data.ids),
            null,
            () => refLoading.current._close()
        );
    };

    const _handlePermanentlyDelete = (data) => {
        clientQuery(
            PERMANENTLY_DELETE_CUSTOMER,
            { data, method: 'DELETE' },
            (response) => _handleSuccessDelete(response, data.ids),
            null,
            () => refLoading.current._close()
        );
    };

    const _handleDeleteCustomer = (data) => {
        clientQuery(
            API_CUSTOMER,
            { data, method: 'DELETE' },
            (response) => _handleSuccessDelete(response, data.ids),
            null,
            _handleCloseWarning
        );
    };

    const _handleUpdateStatus = (data, customers, statusUpdate) => {
        const _callSuccess = ({ message, error }) => {
            let idsSuccess = [...data.ids];
            const currentFilter = paramsCustomers.status;
            const statuses = ['Inactive', 'Active', 'Lead'];

            if (error.message?.length) {
                idsSuccess = idsSuccess.filter((item) => !error.items.includes(parseInt(item)));
                refAlert.current.handeAddStatus({ id: 'error', message: error.message, type: LIST_STATUS.ERROR });
                return;
            }

            if (message.length) {
                refAlert.current.handeAddStatus({
                    id: 'success',
                    message,
                    type: LIST_STATUS.SUCCESS
                });
            }

            if (idsSuccess.length) {
                const shouldDelete = !currentFilter.includes(statusUpdate);
                let countDelete = 0;
                const newData = [];

                [...state.data].forEach((item) => {
                    if (shouldDelete) {
                        !idsSuccess.includes(item.id) ? newData.push(item) : (countDelete += 1);
                    } else {
                        idsSuccess.includes(item.id)
                            ? newData.push({ ...item, status: statuses[statusUpdate] })
                            : newData.push({ ...item });
                    }
                });

                const finalTotal = state?.total - countDelete;
                const dataReducer = {};
                dataReducer.data = newData;
                dataReducer.total = finalTotal;
                dataReducer.data = newData;
                dataReducer.checkedItems = { is_check_all: false, ids: [] };

                dispatchState(dataReducer);
            }
        };

        clientQuery(
            UPDATE_STATUS_MULTIPLE_CUSTOMERS,
            { data: { ids: data.ids, status: statusUpdate }, method: 'PUT' },
            _callSuccess,
            null,
            () => refLoading.current._close()
        );
    };

    const _handleSuccessDelete = ({ message, error }, ids) => {
        let idsSuccess = ids;
        const { items: errorItem = [], message: errorMessage } = error || {};

        if (errorMessage?.length) {
            idsSuccess = ids.filter((item) => !errorItem.includes(parseInt(item)));
            refAlert.current.handeAddStatus({ id: 'error', message: error.message, type: LIST_STATUS.ERROR });
        }

        if (message.length) {
            refAlert.current.handeAddStatus({ id: 'success', message, type: LIST_STATUS.SUCCESS });
        }

        dispatchState((prev) => {
            const { data, total, checkedItems } = prev;
            const { is_check_all } = checkedItems;

            return {
                ...prev,
                checkedItems: {
                    is_check_all: errorItem.length === ids.length && !!is_check_all,
                    ids: ids.filter((item) => errorItem.includes(parseInt(item)))
                },
                data: data.filter((item) => !idsSuccess.includes(item.id)),
                total: total - idsSuccess.length,
                actionSuccess: Date.now()
            };
        });
    };

    const _handleAddCustomer = (data) => {
        refAlert.current.handeAddStatus({
            id: 'success',
            message: `${t('customers:new_customer')} ${data.customer.full_name} ${t('customers:created')}`,
            type: LIST_STATUS.SUCCESS
        });
        _getListCustomer(true, getLocalStorage(keyLocalStore), true);
    };

    const _onChangeViewCustomer = (view) => {
        const paramsLocal = getLocalStorage(keyLocalStore);

        if (view !== currentView) {
            if (view === VIEW_CUSTOMER_MAP) _fetchMap();
            else {
                paramsLocal['sub_locations'] = state.isShowSubLocations ? 1 : 0;
                refFirstTime.current && _getListCustomer(true, paramsLocal);
                dispatchState({ forceRerender: !state.forceRerender });
            }
            dispatchState({ currentView: view });
        }
    };

    const _handleToggleSubLocations = (isShowSubLocations) => {
        const paramsLocal = getLocalStorage(keyLocalStore);

        if (isShowSubLocations !== state.isShowSubLocations) {
            dispatchState({ isShowSubLocations, forceRerender: !state.forceRerender });
            if (isViewMap) {
                refCustomerMap.current?._resetIsFetched();
                _fetchMap(null, isShowSubLocations);
            } else {
                refFirstTime.current &&
                    _getListCustomer(
                        true,
                        { ...paramsLocal, sub_locations: isShowSubLocations ? 1 : 0 },
                        false,
                        false,
                        isShowSubLocations
                    );
            }
        }
    };

    const _handleFindQuickBook = (data) => {
        refQBModal.current._open(data?.qb_id, data?.id);
    };

    const getColumnWithPermission = () => {
        if (quickBooksAddons && !paramsCustomers.deleted) return paramsCustomers.columns || [];
        return paramsCustomers.columns.filter((item) => item !== 'qb_sync' && item !== 'qb_id');
    };

    const _handleSubmitMerge = (data) => {
        refMergeCustomer.current._close();
        dispatchState((prevState) => {
            return {
                ...prevState,
                data: prevState.data.filter((item) => !data.ids.includes(item.id)),
                checkedItems: { finalCheckedItems, ids: [data.primary] }
            };
        });
        const _mergedSuccess = ({ data }) => {
            refProgress.current.pushProgressList({
                type: 'merge_customer',
                import_number_key: data?.key,
                total: data.total || 0,
                count: 0,
                success: 0
            });
        };
        clientQuery(CUSTOMER_MERGE, { data, method: 'PUT' }, _mergedSuccess);
    };

    const _handleReloadCustomer = () => {
        _getListCustomer(true, getLocalStorage(keyLocalStore));
    };

    const _handleClickHeader = ({ actionType, columnsTarget, currentValue }) => {
        const params = getLocalStorage(keyLocalStore) || getDefaultParams(REPORT_TYPE.CUSTOMERS);
        switch (actionType) {
            case COMMON.ORDER:
                params[COMMON.ORDER] = columnsTarget.concat(' ', currentValue);
                break;
            default:
                break;
        }

        setLocalStorage(keyLocalStore, params);
        _handleUpdate();
    };

    const _handleTab = (tab) => {
        abortController.current.abort();

        const params = getLocalStorage(keyLocalStore);
        const refeshId = Date.now();
        const newParams = {
            ...params,
            ...JSON.parse(tab),
            filterTab: tab,
            currentPage: 1,
            offset: 0
        };

        setLocalStorage(keyLocalStore, newParams);

        dispatchState((prev) => {
            return {
                ...prev,
                tabFilterPagination: tab,
                data: [],
                isLoading: true,
                isLoadingMore: false,
                reloadFilter: refeshId,
                deleted: newParams.deleted,
                archived: newParams.archived,
                checkedItems: { is_check_all: false, ids: [] }
            };
        });

        if (tab === TABS_FILTER.DELETED.value && newParams.sideMenuActive !== 'total_customer') {
            _handleChangeSideMenu('total_customer');
        } else {
            _getListCustomer(true, { ...newParams, reloadFilter: refeshId }, true);
        }

        // Change tab to map view
        if (currentView === 'customer_map')
            _fetchMap({ shouldClear: true, isDeleted: tab === TABS_FILTER.DELETED.value });
    };

    const _handleScrollToEnd = () => {
        const shouldLoadMore = state.isShowSubLocations
            ? refStepLoad.current < finalTotal
            : !!customers.length && customers.length < finalTotal;
        if (shouldLoadMore && !finalIsLoadMore && !isLoading) {
            const refreshId = Date.now();

            dispatchState((prev) => {
                return { ...prev, isLoadingMore: true, reloadFilter: refreshId };
            });
        }
    };

    const handleClickAlphabet = (id) => {
        if (isLoading || finalIsLoadMore) abortController.current.abort();
        dispatchState((prev) => {
            return {
                ...prev,
                refreshScreen: prev.refreshScreen + 1,
                data: [],
                isLoading: true,
                isLoadingMore: false,
                checkedItems: { is_check_all: false, ids: [] }
            };
        });
        const newParams = { ...paramsCustomers, character: id };
        if (id === paramsCustomers.character && id !== '') newParams['character'] = '';
        setLocalStorage(keyLocalStore, newParams);
        if (isViewMap) {
            refCustomerMap.current._resetIsFetched();
            _fetchMap();
        } else _getListCustomer(true, newParams);
    };

    const _handleConfirmWarning = () => {
        const quantity = checkedIds.length;
        refWarning.current._open({
            title: t('customers:confirm_delete_customer'),
            description: t(`customers:confirm_delete_customer${quantity > 1 ? 's' : ''}_desc`, {
                quantity
            }),
            isLargeContent: true
        });
    };

    const _handleCloseWarning = () => {
        refWarning.current._close();
        refLoading.current._close();
    };

    const _handleConfirmDelete = () => {
        _handleDeleteCustomer({ ids: checkedIds });
    };

    const _handleShowStatusHideInvoiceBalance = ({ data, message, success }) => {
        if (refIsReceivedOverride.current) return;
        const alertMessage = data ? data.message : message;
        const statusData = !success ? LIST_STATUS.ERROR : data.status ? LIST_STATUS.SUCCESS : LIST_STATUS.WARNING;
        _handleShowStatus({ message: alertMessage, type: statusData, success });
        if (data) dispatchState((prev) => ({ ...prev, checkedItems: { is_check_all: false, ids: [] } }));
    };

    const _handleRealtimeHideInvoiceBalance = ({ message }) => {
        _handleChangeReceivedOverride(true);
        _handleShowStatus({ message, type: LIST_STATUS.SUCCESS, success: true });
    };

    const _handleShowStatus = ({ message = '', success = false, type }) => {
        if (!refAlert.current) return;
        refAlert.current.clearAllStatusBar();
        refAlert.current.showStatusBar(
            'override',
            message,
            type || (success ? LIST_STATUS.SUCCESS : LIST_STATUS.ERROR)
        );
    };

    const _handleChangeReceivedOverride = (isReceived) => {
        if (isReceived) dispatchState((prev) => ({ ...prev, checkedItems: { is_check_all: false, ids: [] } }));
        refIsReceivedOverride.current = isReceived;
    };

    if (!checkPermission(permissionsList, PERMISSIONS.accessCustomersTab)) {
        return <ErrorPage errorMessage={t('auth:no_permission_access')} />;
    }

    return (
        <Fragment>
            {!!quickBooksAddons && <SearchQBModal ref={refQBModal} />}
            <SideMenu onChange={_handleChangeSideMenu} idActive={idActive} />
            <div className="wrapper-columns">
                <div className="container-print contents-pages has-tab accessible-tabs-container customer-list gap-8">
                    <GDStatusBar ref={refAlert} />
                    <CustomerMergeModal ref={refMergeCustomer} onMergeCustomer={_handleSubmitMerge} />
                    <CustomerImportProgress ref={refProgress} onReloadCustomer={_handleReloadCustomer} />

                    {/* Header of page */}
                    <CustomersHeader
                        refreshScreen={refreshScreen}
                        displayExport={!deleted && checkPermission(permissionsList, PERMISSIONS.exportCustomerList)}
                        onAddCustomer={_handleAddCustomer}
                        onSearch={_handleChangeSearch}
                    />

                    {/* Character list */}
                    <CustomerAlphabet
                        ref={refCustomerAlphabet}
                        activeCharacter={paramsCustomers?.character || ''}
                        onClick={handleClickAlphabet}
                    />

                    <div className="wrap-tables flex-column relative">
                        {/* Table header */}
                        <CustomerHeaderTable
                            forceRerender={state.forceRerender}
                            isDeleted={deleted}
                            isViewMap={isViewMap}
                            quickBooksAddons={quickBooksAddons}
                            totalIds={checkedIds}
                            totalItems={finalTotal}
                            onChangeTab={_handleTab}
                            onSelect={_handleChangeFilter}
                            onClearSelected={_handleClearSelected}
                            onChangeView={_onChangeViewCustomer}
                            onToggleSubLocations={_handleToggleSubLocations}
                            onClickAction={_handleClickAction}
                            onHandleStatus={_handleShowStatusHideInvoiceBalance}
                            onChangeReceived={_handleChangeReceivedOverride}
                        />

                        <div className="tab-contents has-map">
                            <div className={classNames('tab-conts', { 'tab-content-active': isViewMap })}>
                                <CustomerMap
                                    isActive={isViewMap}
                                    isShowSubLocations={state.isShowSubLocations}
                                    ref={refCustomerMap}
                                />
                            </div>
                            <div className={classNames('tab-conts', { 'tab-content-active': !isViewMap })}>
                                <GdGridView
                                    showCheckBox
                                    isScroll
                                    content={customers}
                                    isLoading={isLoading}
                                    isShowSubRows={state.isShowSubLocations}
                                    isLoadmore={finalIsLoadMore}
                                    classTable="table-multi-column has-checkbox scrolls-x"
                                    fileTranslation="report"
                                    checkedItems={state.checkedItems}
                                    handleClickHeader={_handleClickHeader}
                                    onFindQuickBook={_handleFindQuickBook}
                                    onChangeStateCheckedItems={onChangeStateCheckedItems}
                                    onSearchQB={_handleFindQuickBook}
                                    {...getGridColumns(getColumnWithPermission(), { ...paramsCustomers, isHaveACH })}
                                    isShowToolTip
                                    onScrollToEnd={_handleScrollToEnd}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <GdConfirm
                ref={refConfirm}
                title={t('customers:confirm')}
                listButton={{ confirm: true, cancel: false }}
                titleConfirm={t('customers:confirm')}
            />
            <ModalLoading ref={refLoading} />
            <GDModalWarning
                ref={refWarning}
                onClose={() => refLoading.current._close()}
                footer={
                    <div className="footer-modal footer-hasbtn btn-close">
                        <span className="v2-btn-default --transparent" onClick={_handleCloseWarning}>
                            {t('common:cancel')}
                        </span>
                        <ButtonSave title={t('common:yes')} className="v2-btn-main" onSave={_handleConfirmDelete} />
                    </div>
                }
            />
            <ServiceHideInvoiceBalance onHideInvoiceBalance={_handleRealtimeHideInvoiceBalance} />
        </Fragment>
    );
};

export default Customers;

import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconThreeDots from 'assets/icon/IconThreeDots';
import IconClose from 'assets/icon/IconClose';
import IconSaveStick from 'assets/icon/IconSaveStick';
import { reducer } from 'app/const/Reducer';
import { updateJobStatus, JOB_STATUS_END_POINT } from 'app/const/api/V2';
import { clientQuery } from 'common/utils/ApiUtils';
import ButtonSave from 'app/components/button/ButtonSave';
import { JOB_STATUS_ITEM_ACTION } from 'app/const/Job';
import GdConfirm from 'app/components/confirm';
import InputColor from 'app/components/input/InputColor';
import { DEFAULT_COLOR, JOB_STATUS_DELETE_ACTION } from '../utils';
import { checkPermission } from 'common/utils/PermissionUtils';
import { PERMISSIONS } from 'app/const/Permissions';
import { ACCOUNT_ROLE } from 'app/const/App';
import { getContrastColor } from 'common/utils/JobStatusUtils';
import ItemJobStatusAddons from './ItemJobStatus';

const ManageActiveJobStatusItem = ({
    color,
    name,
    type,
    isCustom = false,
    customList = [],
    group = [],
    onHandleAction = () => {},
    onShowAlert = () => {},
    handleOnDragEnd = () => {}
}) => {
    const { t } = useTranslation(['addons']);

    const [state, dispatchState] = useReducer(reducer, {
        editItem: null,
        deleteItem: null
    });
    const permissionList = useSelector(({ auth }) => auth?.user?.permissions?.enabled);
    const userRole = useSelector(({ auth }) => auth?.user?.profile?.role);
    const isSuperAdmin = userRole === ACCOUNT_ROLE.SUPERADMIN;
    const permissionEditAndDelete = checkPermission(permissionList, PERMISSIONS.editCustomJobStatus) || isSuperAdmin;

    const refLabel = useRef(null);
    const refButtonSave = useRef(null);
    const refButtonSaveDelete = useRef(null);
    const refConfirm = useRef(null);
    const refJobStatus = useRef(null);
    const refOnChange = useRef(null);
    const refColor = useRef(null);
    const itemList = isCustom ? customList : group;

    const { editItem, deleteItem } = state;

    useEffect(() => {
        if (!editItem?.isEdit) return;
        refOnChange.current = null;
        refButtonSave.current?.setDisable(true);
    }, [editItem]);

    const _handleAction = ({ action, item }) => {
        if (!item) return;

        const isEditJobStatus = action === JOB_STATUS_ITEM_ACTION.EDIT;

        const _handleFinally = () => {
            refButtonSave.current?.removeLoading();
            refButtonSaveDelete.current?.removeLoading();
            if (!isEditJobStatus) {
                _handleCloseConfirm();
            }
        };

        const _handleGetSuccess = ({ data }) => {
            const updatedActionItem = isEditJobStatus ? data : item;
            onHandleAction(action, updatedActionItem);
            const newState = {
                ...(isEditJobStatus ? { editItem: null } : { deleteItem: null }),
                editItem: editItem?.id === item?.id ? null : state.editItem
            };
            dispatchState((prevState) => ({ ...prevState, ...newState }));
        };

        const _handleGetFail = (err) => onShowAlert(err);

        if (isEditJobStatus) {
            const isExistJobStatus = checkExistJobStatus(item);
            if (isExistJobStatus) {
                onShowAlert({ message: t('addons:exist_job_status'), success: false });
                _handleFinally();
                return;
            }
        }

        const requestData = isEditJobStatus
            ? {
                  label: refLabel?.current?.value,
                  type,
                  color: isCustom ? refColor.current?.getValue() : color,
                  name: isCustom ? refJobStatus?.current?.value : name
              }
            : { ids: [item.id], type: JOB_STATUS_DELETE_ACTION.DELETE };

        clientQuery(
            isEditJobStatus ? updateJobStatus(item.id) : JOB_STATUS_END_POINT,
            { method: 'PUT', data: requestData, toFormData: false },
            _handleGetSuccess,
            _handleGetFail,
            _handleFinally
        );
    };

    const _handleClickEdit = (item) => {
        if (refLabel.current) refLabel.current.value = item.label || '';
        if (refJobStatus.current) refJobStatus.current.value = item.name || '';

        dispatchState((prevState) => ({
            ...prevState,
            color: item?.color,
            editItem: {
                ...item,
                isEdit: true
            }
        }));

        setTimeout(() => {
            if (isCustom) {
                refJobStatus.current.focus();
                refColor.current.setValue(item.color);
            } else refLabel.current.focus();
        }, 50);
    };

    const _handleDelete = (item) => {
        dispatchState((prevState) => ({
            ...prevState,
            deleteItem: item
        }));
        refConfirm.current.open();
    };

    const _handleCloseConfirm = () => refConfirm.current.close();

    const _handleOnChange = () => {
        const labelInputValid = !!isCustom || refLabel.current.value;
        const nameInputValid = !isCustom || refJobStatus.current.value;
        const buttonDisabled = !(labelInputValid && nameInputValid);
        refButtonSave.current?.setDisable(buttonDisabled);
    };

    const _onDragEnd = (result) => {
        if (!result.destination) return;
        handleOnDragEnd({ result, type });
    };

    const checkExistJobStatus = (editItem) => {
        const jobStatusName = isCustom ? refJobStatus.current?.value : name;
        const item = itemList.find(
            ({ name, label, id }) =>
                id !== editItem.id &&
                name.trim().toLowerCase() === jobStatusName.trim().toLowerCase() &&
                label.trim().toLowerCase() === refLabel.current?.value?.trim()?.toLowerCase()
        );
        return !!item;
    };

    const _renderJobStatusItemList = () => {
        return (
            <DragDropContext onDragEnd={_onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className="box-draggable">
                            {itemList.map((item, index) => {
                                const isEditing = editItem?.id === item.id;
                                const isHideDrag = !permissionEditAndDelete || itemList.length < 2 || isEditing;
                                return (
                                    <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={index}
                                        isDragDisabled={isHideDrag}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                className={classNames('rows', {
                                                    'is-drag': snapshot?.isDragging,
                                                    '--custom-status': isEditing
                                                })}
                                                key={item?.id}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                            >
                                                {!isHideDrag ? (
                                                    <div className="col-drag" {...provided.dragHandleProps}>
                                                        <IconThreeDots isDouble />
                                                    </div>
                                                ) : null}
                                                {isEditing ? (
                                                    _renderEditJobStatusItem()
                                                ) : (
                                                    <ItemJobStatusAddons
                                                        item={item}
                                                        permission={permissionEditAndDelete}
                                                        editItem={editItem}
                                                        onClickEdit={_handleClickEdit}
                                                        onDelete={_handleDelete}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    };

    const _renderEditJobStatusItem = () => {
        if (!editItem?.isEdit) return null;
        return (
            <Fragment>
                {isCustom && (
                    <div className="col-select-color" onClick={_handleOnChange}>
                        <InputColor
                            ref={refColor}
                            className="field-input"
                            defaultColor={editItem?.color || DEFAULT_COLOR}
                        />
                    </div>
                )}
                <div className="col-status flex-1">
                    {isCustom ? (
                        <input
                            ref={refJobStatus}
                            type="text"
                            className="field-input"
                            placeholder={t('addons:job_status')}
                            defaultValue={editItem?.name || ''}
                            onChange={_handleOnChange}
                        />
                    ) : (
                        <CalendarDropdown
                            id="dropdown-select-form-user"
                            options={{ type, name }}
                            selected={name}
                            selectedOption={{ type, name }}
                            keyGetKey="type"
                            keyGetName="name"
                            keyGetValue="type"
                            disable
                        />
                    )}
                    <input
                        ref={refLabel}
                        type="text"
                        className="field-input"
                        placeholder={t('addons:job_status_label')}
                        defaultValue={editItem?.label || ''}
                        onChange={_handleOnChange}
                    />
                </div>
                <div className="col-icon flexcenter gap-4">
                    <ButtonSave
                        ref={refButtonSave}
                        wrapClass="v2-btn-main --icon-lg svg-white-stroke tooltip"
                        onSave={() => _handleAction({ action: JOB_STATUS_ITEM_ACTION.EDIT, item: editItem })}
                    >
                        <IconSaveStick />
                        <span className="tooltiptext top">{t('addons:save')}</span>
                    </ButtonSave>
                    <div
                        className="v2-btn-default --icon-lg tooltip"
                        onClick={() => dispatchState((prev) => ({ ...prev, editItem: null }))}
                    >
                        <IconClose />
                        <span className="tooltiptext top">{t('addons:cancel')}</span>
                    </div>
                </div>
            </Fragment>
        );
    };

    return (
        <div className="boxs">
            {!isCustom && (
                <div className="rows">
                    <div
                        className="status-btn"
                        style={{ '--bg-job-status': color, '--color-job-status': getContrastColor(color) }}
                    >
                        {name}
                    </div>
                </div>
            )}
            {_renderJobStatusItemList()}
            <GdConfirm
                ref={refConfirm}
                title={t('addons:delete_job_custom_title')}
                message={t('addons:delete_job_custom_confirm')}
                footer={
                    <div className="footer-modal btn-close justify-end">
                        <div className="v2-btn-default --transparent" onClick={_handleCloseConfirm}>
                            {t('cancel')}
                        </div>
                        <ButtonSave
                            ref={refButtonSaveDelete}
                            title={t('yes')}
                            wrapClass="v2-btn-main ml-2"
                            onSave={() => _handleAction({ action: JOB_STATUS_ITEM_ACTION.DELETE, item: deleteItem })}
                        />
                    </div>
                }
            />
        </div>
    );
};

export default ManageActiveJobStatusItem;

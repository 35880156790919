import { MIXPANEL_EVENT_NAME, MIXPANEL_METHOD, MIXPANEL_PROPERTIES } from 'app/const/Mixpanel';
import { getTimeEventMixpanel } from 'common/utils/MixpanelUtils';
import { getAgendaMode } from '../calendar/const/Header';

const convertViewTypeCalendar = (view) => {
    return (getAgendaMode().find(({ id }) => id === view)?.text || '').replace('Rolling', '').trim();
};

export const mixpanelCalendarViews = ({ profile = {}, viewType = '' }) => {
    const peopleSet = {
        [MIXPANEL_PROPERTIES.VIEW_TYPE]: convertViewTypeCalendar(viewType),
        [MIXPANEL_PROPERTIES.PLAN_TYPE]: profile.plan?.type,
        [MIXPANEL_PROPERTIES.TERM_LENGTH]: profile.plan?.term_length,
        [MIXPANEL_PROPERTIES.PLAN_PRICE]: profile.plan?.price,
        [MIXPANEL_PROPERTIES.$EMAIL]: profile.email,
        [MIXPANEL_PROPERTIES.LAST_CALENDAR_VIEW]: getTimeEventMixpanel()
    };

    return {
        type: MIXPANEL_EVENT_NAME.CALENDAR_VIEW,
        data: {
            [MIXPANEL_METHOD.IDENTIFY]: profile.id,
            [MIXPANEL_METHOD.REGISTER]: peopleSet,
            [MIXPANEL_METHOD.SET]: peopleSet,
            [MIXPANEL_METHOD.SET_ONCE]: {
                [MIXPANEL_PROPERTIES.FIRST_CALENDAR_VIEW]: getTimeEventMixpanel()
            },
            [MIXPANEL_METHOD.INCREMENT]: MIXPANEL_PROPERTIES.INCREMENT_CALENDAR_VIEWS,
            [MIXPANEL_METHOD.REGISTER_ONCE]: {
                [MIXPANEL_PROPERTIES.FIRST_CALENDAR_VIEW]: getTimeEventMixpanel()
            }
        }
    };
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import ButtonSave from 'app/components/button/ButtonSave';
import IconRequest from 'assets/icon/IconRequest';
import { useFilters } from '../../context/addFilterContext';

const FilterFooter = ({ onClose = () => {}, onCancel = () => {} }) => {
    const { t } = useTranslation('smartView');
    const { filters, isEditing } = useFilters();

    const _requestNewField = () => {
        window.Intercom('boot');
        window.Intercom('show');
    };

    return (
        <div className="footer-modal justify-end">
            {isEditing ? null : (
                <div className="flex-1">
                    <div className="v2-btn-default has-icon --grey" onClick={_requestNewField}>
                        <IconRequest />
                        {t('request_a_filter')}
                    </div>
                </div>
            )}
            <span className="fw-normal v2-btn-default --transparent" onClick={onCancel}>
                {t('cancel')}
            </span>
            <ButtonSave
                wrapClass="v2-btn-main ml-2"
                disabled={Object.keys(filters).length === 0}
                title={t('common:apply')}
                // For now, just close filter modal when click on Apply button
                onSave={() => {
                    onClose();
                }}
            />
        </div>
    );
};

export default FilterFooter;

import loadable from '@loadable/component';
import { getGidColumnsDetailTab } from 'app/const/report/RevenueBySource';
import PropTypes from 'prop-types';
import React from 'react';

const GdGridRowTotal = loadable(() => import('app/components/grid/GdGridRowTotal'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));

function DetailTabGridView({ dataReport, paramsReport, handleActionHeader = () => {}, handleOpenInvoice }) {
    const getColumnsDetailTab = getGidColumnsDetailTab(paramsReport.orderDetail);

    return (
        <div className="tab-conts tab-content-active">
            <GdGridView
                isEmptyFlat
                isLoading={dataReport.isLoading}
                classTable="table-multi-column scrolls-x has-text-ellipsis"
                classTableContent="--hastotal"
                content={dataReport?.data}
                fileTranslation={'report'}
                handleClick={handleOpenInvoice}
                {...getColumnsDetailTab}
                handleClickHeader={handleActionHeader}
                rowTotal={(props) => (
                    <GdGridRowTotal
                        columns={dataReport?.rowTotal}
                        contentConfig={getColumnsDetailTab?.contentConfig}
                        showCheckBox={false}
                        isLoading={dataReport.isLoading}
                        {...props}
                    />
                )}
            />
        </div>
    );
}
DetailTabGridView.propTypes = {
    paramsReport: PropTypes.object,
    dataReport: PropTypes.object
};

function handleExportDetailTab() {
    return false;
}

export { DetailTabGridView, handleExportDetailTab };

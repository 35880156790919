import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { addBranchPath } from 'app/const/Branch';

const MenuWithLink = ({ name, itemClassName, path, onSetRef = () => {}, icon = null }) => {
    const { t } = useTranslation();
    return (
        <div ref={(el) => onSetRef(el)} className="wrap-nav nav-menu">
            <NavLink to={addBranchPath(path)} className={classNames('v2-btn-default nav mr-1', itemClassName)}>
                {icon || null}
                <p className="nav__label is-nav-items">{t(`header:${name}`)}</p>
            </NavLink>
        </div>
    );
};

export default MenuWithLink;

import React, { forwardRef, useContext, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';

import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';
import IconPen from 'assets/icon/IconPen';

const Summary = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const { repeatSettings } = useContext(DataRepeatContext);

    useImperativeHandle(ref, () => ({
        getValue: () => {
            return repeatSettings;
        }
    }));

    return (
        <div className="rows row-summary row-has-line border-top-border-color-grey">
            <div className="txt">
                <IconPen isHasColor />
                {t('setting:summary')}
            </div>
            <div className="details">
                {repeatSettings.recurrence.summary}
                <br />
                <p className="red-coral">{repeatSettings.repeat === 1 && (repeatSettings?.exception?.summary || '')}</p>
            </div>
        </div>
    );
});

export default Summary;

import React from 'react';
import { useTranslation } from 'react-i18next';

import IconSync from 'assets/icon/IconSync';

const CustomRestoreOption = ({ onInsert = () => {} }) => {
    const { t } = useTranslation();
    return (
        <span className="editor-controls__btn tooltip restore-default" onClick={() => onInsert()}>
            <IconSync />
            <span className="tooltiptext top">{t('common:restore_default')}</span>
        </span>
    );
};

export default CustomRestoreOption;

import classNames from 'classnames';
import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ADDONS_AI, ADDONS_CHAT_BOT_CONTENT_PORTAL_AI } from 'app/config/routes';
import { ADDONS_AI_AGENTS } from 'app/const/addons';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconCogWheel from 'assets/icon/IconCogWheel';
import { getPlanUser } from 'common/utils/PermissionUtils';
import { BookingCode } from '../../booking/components/BookingShowCode';

const AddonsChatbotPortalFooter = ({
    isDisable = false,
    path = '',
    scriptInfo = {},
    isCustomerPortalAIAgent = false
}) => {
    const { profile, settings } = useSelector(({ auth }) => auth.user);
    const activeChatbot = settings.addons[ADDONS_AI_AGENTS];
    const { isGrowthPlan, isBasicPlan, isTrial } = getPlanUser(profile);
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { isShowCode: false });
    const { isShowCode } = state;

    const _handleToggleShowCode = () => {
        dispatchState((prev) => ({ isShowCode: !prev.isShowCode }));
    };

    const _getPath = () => {
        if (!isCustomerPortalAIAgent) return addBranchPath(path);
        if (!activeChatbot && !isBasicPlan) return addBranchPath(ADDONS_AI);
        return addBranchPath(
            (!activeChatbot && !isBasicPlan) || !isGrowthPlan ? ADDONS_AI : ADDONS_CHAT_BOT_CONTENT_PORTAL_AI
        );
    };

    return (
        <div className={classNames('boxs__footer --has-code', { active: isShowCode })}>
            <div className="flex-betweenitems w-100">
                <div
                    className={classNames('v2-btn-default svg-purple btn-bg-purple --icon-r --sm', {
                        'is-disable': isDisable
                    })}
                    onClick={_handleToggleShowCode}
                >
                    {t(`addons:${isShowCode ? 'hide_code' : 'show_code'}`)}
                    {isShowCode ? <IconArrowDown isUp /> : <IconArrowDown />}
                </div>
                <Link
                    to={_getPath}
                    className={classNames('v2-btn-default svg-purple btn-bg-purple --icon-r --sm', {
                        'is-disable': isCustomerPortalAIAgent ? !isGrowthPlan && !isTrial : isDisable
                    })}
                >
                    {t('addons:manage')} <IconCogWheel />
                </Link>
            </div>
            {isShowCode ? (
                <BookingCode
                    scriptInfo={scriptInfo}
                    isChatBot
                    hideBookingCode
                    hideDescriptionBookingCode
                    wrapperClassName="show-code mt-3 w-100"
                />
            ) : null}
        </div>
    );
};

export default AddonsChatbotPortalFooter;

import React from 'react';

export default function IconMerge({ isActive = false }) {
    if (isActive) {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.5 14.5C7.60457 14.5 8.5 15.3954 8.5 16.5C8.5 17.6046 7.60457 18.5 6.5 18.5C5.39543 18.5 4.5 17.6046 4.5 16.5C4.5 15.3954 5.39543 14.5 6.5 14.5Z"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M17.5 9.5C18.6046 9.5 19.5 10.3954 19.5 11.5C19.5 12.6046 18.6046 13.5 17.5 13.5C16.3954 13.5 15.5 12.6046 15.5 11.5C15.5 10.3954 16.3954 9.5 17.5 9.5Z"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M6.5 4.5C7.60457 4.5 8.5 5.39543 8.5 6.5C8.5 7.60457 7.60457 8.5 6.5 8.5C5.39543 8.5 4.5 7.60457 4.5 6.5C4.5 5.39543 5.39543 4.5 6.5 4.5Z"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M8.5 6.5C12.9978 6.5 10 11.5 15.5 11.5"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M8.5 16.5C12.9978 16.5 10 11.5 15.5 11.5"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </svg>
        );
    }
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 14C20.1046 14 21 13.1046 21 12C21 10.8954 20.1046 10 19 10C17.8954 10 17 10.8954 17 12C17 13.1046 17.8954 14 19 14Z"
                fill="#999999"
            />
            <path
                d="M5 8C6.10457 8 7 7.10457 7 6C7 4.89543 6.10457 4 5 4C3.89543 4 3 4.89543 3 6C3 7.10457 3.89543 8 5 8Z"
                fill="#999999"
            />
            <path
                d="M5 20C6.10457 20 7 19.1046 7 18C7 16.8954 6.10457 16 5 16C3.89543 16 3 16.8954 3 18C3 19.1046 3.89543 20 5 20Z"
                fill="#999999"
            />
            <path
                d="M9 18L9.53884 17.8922C10.7264 17.6547 11.6547 16.7264 11.8922 15.5388L12.1078 14.4612C12.3453 13.2736 13.2736 12.3453 14.4612 12.1078L15 12"
                stroke="#CCCCCC"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9 6L9.53884 6.10777C10.7264 6.34528 11.6547 7.27358 11.8922 8.46116L12.1078 9.53884C12.3453 10.7264 13.2736 11.6547 14.4612 11.8922L15 12"
                stroke="#CCCCCC"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import GdRichText from 'app/components/richtext';
import {
    CONTENT_SMS_TEMPLATE_VARIABLE,
    ID_CONTENT_PREVIEW,
    LIMIT_SMS_COUNT,
    getVariableCompany
} from 'app/const/Settings';
import { getSMSRichTextSetup } from 'app/modules/settings/templates/constant';
import { handlePreviewTemplate } from 'app/modules/settings/utils';
import IconSentSms from 'assets/icon/IconSentSms';

const SMSContent = ({ defaultContent = '', valueRestore = '', onPreview = () => {} }, ref) => {
    const { t } = useTranslation();
    const { settings, company } = useSelector(({ auth }) => auth.user);
    const isActiveSMS = settings?.addons?.smsplivo;
    const refRichtext = useRef(null);
    const refCounterEl = useRef(null);
    const refTimeoutId = useRef(null);
    const refCounterOver = useRef(false);

    useImperativeHandle(ref, () => ({
        getValue: () => refRichtext.current.getValue(),
        isOver: () => refCounterOver.current
    }));

    useEffect(() => {
        refRichtext.current && refRichtext.current.setValue(defaultContent);
        return () => {
            if (refTimeoutId.current) clearTimeout(refTimeoutId.current);
        };
    }, []);

    const _handleChangeContent = (value) => {
        if (refCounterEl.current && refRichtext.current) {
            refTimeoutId.current = setTimeout(() => {
                if (!refRichtext.current) return;
                const counter = refRichtext.current.getWordCount();
                _handleWordCount(counter);
            }, 0);
        }
        _handleSetContentPreview(value);
    };

    const _handleSetContentPreview = (value) => {
        const content = handlePreviewTemplate(
            value,
            getVariableCompany(company),
            CONTENT_SMS_TEMPLATE_VARIABLE(settings?.shorten_url)
        );

        const divInner = document.getElementById(ID_CONTENT_PREVIEW);
        if (divInner) divInner.innerHTML = content;
    };

    const _handleWordCount = (counter = 0) => {
        if (!refCounterEl.current) return;
        refCounterEl.current.innerHTML = counter;
        refCounterEl.current.classList = counter > LIMIT_SMS_COUNT ? 'orange-red' : '';
        refCounterOver.current = counter > LIMIT_SMS_COUNT;
    };

    return (
        <>
            <div className="box-header flex-betweenitems mb-1">
                <div className="flexcenter flex-1">
                    <div className="title flexcenter svg-sms-yellow">
                        <IconSentSms isFillOrange />
                        {t('common:sms')}
                    </div>
                </div>
            </div>

            <div className="wrap-content content-sendsms">
                <GdRichText
                    ref={refRichtext}
                    valueRestore={valueRestore || ''}
                    defaultContent={defaultContent || ' '}
                    textDrop={t('common:drop_text_here')}
                    textDrag={t('common:drag_text_here')}
                    isDisableSMS={!isActiveSMS}
                    onChange={_handleChangeContent}
                    toolbarConfig={getSMSRichTextSetup({}, 5, 2)}
                />

                <div className="mt-2 mb-3 grey-generic">
                    {t('common:character_s')} <span ref={refCounterEl}>0</span>/{LIMIT_SMS_COUNT}
                </div>
                <div className="v2-btn-default" onClick={onPreview}>
                    {t('setting:preview')}
                </div>
            </div>
        </>
    );
};

export default forwardRef(SMSContent);

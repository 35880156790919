import React from 'react';
import { useTranslation } from 'react-i18next';

import IconGoogle from 'assets/icon/IconGoogle';

const ConnectWithGG = ({ onClick = () => {} }) => {
    const { t } = useTranslation();
    return (
        <div className="btn-signin is-google" onClick={onClick}>
            <div className="btn-icon flex-centeritem">
                <IconGoogle />
            </div>
            <div className="btn-label flex-1">{t('addons:sign_in_with_g_suite')}</div>
        </div>
    );
};

export default ConnectWithGG;

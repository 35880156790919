import React, { useContext, useLayoutEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { reducer } from 'app/const/Reducer';
import {
    LIMIT_SMS_COUNT,
    SCHEDULE_TEXT_REMINDER_VALUE,
    TAB_TITLE_FOLLOWUP_TYPE,
    TEMPLATE_DEFAULT_TITLE,
    TYPE_TEMPLATE
} from 'app/const/Settings';
import { getListSettingService } from 'common/redux/actions/settings/serviceAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { _handleGetURLTemplate, getDefaultTemplate } from './utils';

const SettingTemplateContext = React.createContext({});

export const SettingTemplateProvider = ({
    initialData = {},
    onCloseTemplate = () => {},
    onUpdateSuccess = () => {},
    children
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('setting');
    const serviceFirstTime = useSelector(({ serviceReducer }) => serviceReducer.first_time);
    const [state, dispatchState] = useReducer(reducer, {
        ...initialData,
        isLoading: true,
        typePreviewing: '',
        activeIndex: 1,
        template: {},
        currentReminder: {},
        isFetchedVariables: false
    });
    const { idTemplate, typeTemplate, typeAppointment, isCreateTemplate } = state;

    // This is reminders[]
    const refDataContent = useRef([]);
    const refDataName = useRef('');

    useLayoutEffect(() => {
        isCreateTemplate && typeTemplate !== TYPE_TEMPLATE.AUTO_MESSAGE
            ? _handleInitCreateData(typeTemplate)
            : _handleGetTemplate(idTemplate, typeAppointment);
    }, []);

    const _handleInitCreateData = () => {
        const defaultData = getDefaultTemplate(typeTemplate);
        dispatchState({ activeIndex: 1, isLoading: false, ...defaultData });
        refDataContent.current = defaultData.template['reminders'];
        refDataName.current = '';
    };

    const _handleGetTemplate = (idTemplate, typeAppointment) => {
        const dataFetch = {};
        const _handleGetSuccess = ({ data }) => {
            const { reminders = [], name = '', type, tab_title, title } = data || {};
            const firstReminder = reminders[0] || {};
            const { with_service, with_sms } = firstReminder;
            const remindersLength = reminders.length;

            refDataContent.current = reminders;
            refDataName.current = name;
            /* This is a way to store the previous idTemplate. */
            dispatchState((prev) => {
                const newState = {
                    ...prev,
                    isLoading: false,
                    activeIndex: 1,
                    isDisplaySMS: remindersLength && with_sms,
                    isDisplayService: remindersLength && with_service,
                    isDisplaySchedule: remindersLength > 1,
                    typeAppointment: typeAppointment || type,
                    template: data,
                    currentReminder: firstReminder
                };
                if (!tab_title)
                    newState['template']['tab_title'] = t(type === TAB_TITLE_FOLLOWUP_TYPE ? 'follow_up' : 'reminder');
                if (!title)
                    newState['template']['title'] = t(`setting:${TEMPLATE_DEFAULT_TITLE[initialData.idTypeTemplate]}`);

                return newState;
            });
            /* This is a way to load the services when the template is loaded. */
            if (with_service && serviceFirstTime) _handleGetServices();
        };

        if (typeAppointment) dataFetch['type'] = typeAppointment;
        clientQuery(
            _handleGetURLTemplate(typeTemplate, idTemplate),
            { data: dataFetch, method: 'GET' },
            _handleGetSuccess
        );
    };

    const _handleGetServices = () => {
        dispatch(
            getListSettingService(
                { localSaveStore: true },
                () => {},
                () => {}
            )
        );
    };

    const _handleChangeReminder = (activeIndex) => {
        if (activeIndex === state.activeIndex) return;
        dispatchState({ activeIndex, currentReminder: refDataContent.current[activeIndex - 1] });
    };

    const _handleChangeType = (dataType) => {
        const { value, tab_title } = dataType || {};
        if (isCreateTemplate) {
            dispatchState({ isLoading: true });
            _handleGetTemplate('default', value);
        } else {
            let schedule_text = t('schedule_text_follow_up');
            if (value === SCHEDULE_TEXT_REMINDER_VALUE) schedule_text = t('schedule_text_reminder');

            dispatchState((prev) => {
                return {
                    ...prev,
                    template: { ...prev.template, type: value, tab_title, schedule_text },
                    typeAppointment: value
                };
            });
        }
    };

    const _handleUpdateDataReminder = (type, data) => {
        if (!refDataContent.current.length) return;
        const indexReminder = state.activeIndex - 1;
        const dataCurrent = refDataContent.current[indexReminder];
        if (!dataCurrent) return;

        if (Array.isArray(type)) {
            type.forEach((item, index) => {
                if (_handleCheckHasProperty(dataCurrent, item) || item === 'counter_sms' || item === 'all_service') {
                    refDataContent.current[indexReminder][item] = data[index];
                }
            });
        } else if (
            _handleCheckHasProperty(dataCurrent, type) ||
            type === 'is_plain_text' ||
            type === 'plain_email_content'
        ) {
            refDataContent.current[indexReminder][type] = data;
        }
    };

    const _handleCheckHasProperty = (obj, property) => {
        return Object.prototype.hasOwnProperty.call(obj, property);
    };

    const _handleCloseTemplate = () => {
        onCloseTemplate();
        refDataContent.current = [];
        refDataName.current = '';
    };

    const _handlePreview = (typePreviewing) => {
        dispatchState({ typePreviewing });
    };

    const _handleChangeName = (name) => {
        refDataName.current = name;
    };

    const _handleGetDataSave = () => {
        let result = {};
        let isOverSMS = false;
        const reminders = refDataContent.current || [];

        if (reminders[0].with_sms) {
            // Check if any reminder has sms_content with more than 600 characters
            for (let index = 0; index < reminders.length; index++) {
                const element = reminders[index];
                if (element.with_sms && element.counter_sms > LIMIT_SMS_COUNT) {
                    isOverSMS = true;
                    break;
                }
            }
        }

        switch (typeTemplate) {
            case TYPE_TEMPLATE.AUTO_MESSAGE:
                result = { name: refDataName.current, type: state.template.type, reminders };
                break;
            case TYPE_TEMPLATE.EMAIL_INBOX:
                result = {
                    name: refDataName.current,
                    content: reminders[0].email_content,
                    subject: reminders[0].subject,
                    attachments: reminders[0]?.attachments || []
                };
                break;
            case TYPE_TEMPLATE.BROADCAST:
                result = { name: refDataName.current, ...reminders[0] };
                break;
            default:
                result = { reminders: refDataContent.current };
                break;
        }

        if (isOverSMS) result['isOverSMS'] = true;
        return result;
    };

    const _handleUpdateSuccess = (dataTemplate, message) => {
        onUpdateSuccess(dataTemplate, isCreateTemplate, message);
        isCreateTemplate && _handleCloseTemplate();
    };

    const _handleUpdateFetchedVariables = (isFetched = false) => {
        dispatchState((prev) => ({ ...prev, isFetchedVariables: isFetched }));
    };

    const dataProvider = {
        ...state,
        onCloseTemplate: _handleCloseTemplate,
        onChangeReminder: _handleChangeReminder,
        onUpdateDataReminder: _handleUpdateDataReminder,
        onUpdateTypeReminder: _handleChangeType,
        onPreviewTemplate: _handlePreview,
        onChangeName: _handleChangeName,
        onGetDataSave: _handleGetDataSave,
        onGetCurrentData: () => refDataContent.current[state.activeIndex - 1],
        onUpdateSuccess: _handleUpdateSuccess,
        onUpdateFetchedVariables: _handleUpdateFetchedVariables
    };
    return <SettingTemplateContext.Provider value={dataProvider}>{children}</SettingTemplateContext.Provider>;
};

export const useSettingTemplate = () => useContext(SettingTemplateContext);

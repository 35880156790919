import GdGridView from 'app/components/grid/GdGridView';
import { URL_REPORT_RECURRING_INVOICE } from 'app/const/api/Export';
import { KEY_REPORT_LOCAL_STORAGE, LIST_STATUS } from 'app/const/App';
import { getGridColumns } from 'app/const/report/RecurringInvoices';
import { ALL_COLUMNS_RECURRING_INVOICE, RECURRING_INVOICE_LIST_FILTER } from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { actionOpenJobDetail } from 'common/redux/actions/job/detail';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LIST_EXPORT } from 'app/const/report/Common';
import { reducer } from 'app/const/Reducer';
import loadable from '@loadable/component';
import { clientQuery } from 'common/utils/ApiUtils';
import { REPORT_RECURRING_INVOICE_GET_LIST } from 'app/const/Api';
import { REPORT_LIMIT } from 'app/const/Reports';
import { getLocalParamsReport } from 'common/utils/ReportUtils';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';

const GdGridRowTotal = loadable(() => import('app/components/grid/GdGridRowTotal'));
const Export = loadable(() => import('app/modules/report/components/Export'));
const FilterReport = loadable(() => import('app/modules/report/components/FilterReport'));
const MainHeaderReport = loadable(() => import('app/modules/report/components/MainHeader'));
const ReportSearch = loadable(() => import('app/modules/report/components/ReportSearch'));
const StatusBar = loadable(() => import('app/components/status/statusbar'));

function ReportRecurringInvoices() {
    const { t } = useTranslation(['report']);
    const dispatch = useDispatch();
    const refStatusBar = useRef(null);
    const abortController = useRef(null);
    const [dataReport, dispatchActionReport] = useReducer(reducer, {
        refresh: false,
        data: [],
        totalPage: 1,
        isLoading: true,
        isLoadingMore: false,
        total: 0,
        rowTotal: [],
        refreshScreen: 0,
        reloadFilter: 0
    });
    const { refreshScreen, isLoadingMore, total: finalTotal, isLoading: finalIsLoading, data: finalData } = dataReport;

    const KEY_REPORT_RECURRING_INVOICE = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.RECURRING_INVOICE);

    const paramsReport = getLocalParamsReport(KEY_REPORT_RECURRING_INVOICE, REPORT_TYPE.RECURRING_INVOICE);

    const totalItemsExport = dataReport.total;

    useEffect(() => {
        getListReport(true, paramsReport);
    }, []);

    useEffect(() => {
        if (isLoadingMore) {
            setTimeout(() => {
                getListReport(
                    false,
                    { ...paramsReport, offset: finalData.length, reloadFilter: dataReport.reloadFilter },
                    true
                );
            }, 200);
        }
    }, [isLoadingMore]);

    const getListReport = (isReset = false, params, notShowLoading = false) => {
        abortController.current = new AbortController();
        const checkShouldLoading = !notShowLoading && !finalIsLoading;
        const checkRefresh = dataReport.reloadFilter;

        checkShouldLoading &&
            dispatchActionReport((prev) => {
                return {
                    ...prev,
                    data: isReset ? [] : prev.data,
                    isLoading: !!checkShouldLoading || prev.isLoading
                };
            });

        delete params?.columns;
        delete params?.filterTab;
        delete params?.currentPage;

        clientQuery(
            REPORT_RECURRING_INVOICE_GET_LIST,
            { data: { ...params, limit: REPORT_LIMIT }, method: 'GET', abortController: abortController.current },
            (response) => getListSuccess(response, checkRefresh, isReset),
            getListFailed
        );
    };

    const getListSuccess = ({ data, total, total_recurrence }, tabQuery, isReset) => {
        dispatchActionReport((prev) => {
            const checkDiffrentTabs = !!tabQuery && prev.reloadFilter !== tabQuery;
            const newData = isReset ? data : checkDiffrentTabs ? prev.data : [...prev.data, ...data];

            return {
                ...prev,
                isLoading: false,
                isLoadingMore: false,
                data: newData,
                total: total,
                rowTotal: createRowTotal(total_recurrence, total),
                totalRecurrence: total_recurrence,
                refreshScreen: prev.refreshScreen + 1
            };
        });
    };

    const createRowTotal = (data = {}, total) => {
        const paramsLocal = getLocalStorage(KEY_REPORT_RECURRING_INVOICE);
        const columns = paramsLocal.columns || [];
        let result = [];
        if (columns.length) {
            result = ALL_COLUMNS_RECURRING_INVOICE.filter((col) => columns.includes(col));
            result = result.map((column) => {
                const totalAmount = data[column];
                if (totalAmount)
                    return {
                        id: column,
                        isShow: true,
                        totalAmount,
                        isCurrency: true
                    };

                return { id: column, isShow: true };
            });
            if (!result[0].isCurrency)
                result[0].title = `${t('report:total')} ${total ?? dataReport.total} ${t('report:recurring_invoice')}`;
        }

        return result;
    };

    function getListFailed(response) {
        if (!response?.isAborted) {
            refStatusBar.current.showStatusBar(LIST_STATUS.ERROR, t('common:action_failure'), LIST_STATUS.ERROR);
            dispatchActionReport({ isLoading: false, total: 0 });
        }
    }

    const handleChangeFilter = (params, mode) => {
        if (mode && mode === 'columns') {
            dispatchActionReport({ rowTotal: createRowTotal(dataReport.totalRecurrence) });
        }
    };

    const _handleUpdate = () => {
        abortController.current.abort();
        const params = getLocalStorage(KEY_REPORT_RECURRING_INVOICE);
        dispatchActionReport({ rowTotal: [], total: 0 });
        getListReport(true, params);
    };

    const _handleScroll = () => {
        if (!isLoadingMore && finalTotal > finalData.length) {
            const refreshId = Date.now();

            dispatchActionReport((prev) => ({ ...prev, isLoadingMore: true, reloadFilter: refreshId }));
        }
    };
    function _renderHeaderLeft() {
        return (
            <>
                <FilterReport
                    reportType={REPORT_TYPE.RECURRING_INVOICE}
                    filters={RECURRING_INVOICE_LIST_FILTER}
                    onSelect={handleChangeFilter}
                />
            </>
        );
    }

    function _renderHeaderRight() {
        return (
            <>
                <Export
                    title={t('report:records', { count: totalItemsExport })}
                    params={paramsReport}
                    url={URL_REPORT_RECURRING_INVOICE}
                    pageExport={LIST_EXPORT.EXPORT_REPORT_RECURRING_INVOICE}
                    activePrint
                    isDisable={finalIsLoading}
                    refresh={refreshScreen}
                />
                <ReportSearch
                    reportType={REPORT_TYPE.RECURRING_INVOICE}
                    placeholder={t('report:search')}
                    onKeyEnter={_handleUpdate}
                />
            </>
        );
    }

    const _handleOpenJobDetail = ({ key, row }) => {
        if (key === 'job_name') {
            dispatch(actionOpenJobDetail({ id: row.job_id }));
        }
    };

    const _handleOpenInvoice = (invoiceId) => {
        dispatch(
            actionOpenInvoice({
                id: invoiceId,
                status: '',
                total: '',
                isRecurring: true
            })
        );
    };

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: REPORT_TYPE.RECURRING_INVOICE,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleUpdate
        });
    };

    return (
        <>
            <MainHeaderReport
                contentLeft={_renderHeaderLeft}
                contentRight={_renderHeaderRight}
                reportType={REPORT_TYPE.RECURRING_INVOICE}
                onSelectTab={_handleUpdate}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages">
                    <div className="wrap-tables flex-column">
                        <StatusBar ref={refStatusBar} />
                        <GdGridView
                            isEmptyFlat
                            isLoading={finalIsLoading}
                            classTable="table-multi-column scrolls-x has-text-ellipsis"
                            classTableContent="--hastotal"
                            content={dataReport.data}
                            fileTranslation={'report'}
                            handleClick={_handleOpenJobDetail}
                            onOpenInvoice={_handleOpenInvoice}
                            {...getGridColumns(paramsReport?.columns, paramsReport?.order)}
                            rowTotal={(props) => (
                                <GdGridRowTotal
                                    columns={dataReport.rowTotal}
                                    contentConfig={getGridColumns(paramsReport?.columns)?.contentConfig}
                                    {...props}
                                />
                            )}
                            isScroll
                            showRowTotalOnTop
                            isLoadmore={isLoadingMore}
                            onScrollToEnd={_handleScroll}
                            handleClickHeader={_handleActionHeader}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportRecurringInvoices;

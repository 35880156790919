import ButtonSave from 'app/components/button/ButtonSave';
import GdConfirm from 'app/components/confirm';
import { SETTINGS_PLANS } from 'app/config/routes';
import { ADDONS_SMS_ACTIVE, ADDONS_SMS_DEACTIVE } from 'app/const/Api';
import classNames from 'classnames';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SMSAddonsRentPhones from './SMSAddonsRentPhones';
import { SMS_ADDON_STATUS } from '../../constants';
import { addBranchPath } from 'app/const/Branch';
import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';

const SMSAddonsPacks = ({
    data = [],
    globalOverride = 0,
    statusAddon = SMS_ADDON_STATUS.INACTIVE,
    packageActive = null,
    isMustRentPhone = true,
    onOpenOverrideModal = () => {},
    onRentSuccess = () => {},
    onActiveSuccess = () => {},
    onActiveFail = () => {},
    onDeactiveSuccess = () => {}
}) => {
    const { t } = useTranslation('addons');
    const history = useHistory();
    const [activePackage, setActivePackage] = useState(packageActive);
    const accountNumber = useSelector(({ auth }) => auth.user.profile.plan.account_number);
    const refPrePack = useRef(packageActive);
    const refRentPhones = useRef(null);
    const refConfirm = useRef(null);
    const refConfirmValue = useRef({});
    const refButtonSave = useRef(null);

    const _handleChangePackage = (isChecked, packId) => {
        const dataValue = { isChecked, packId, isDeactive: !isChecked };
        refConfirmValue.current = dataValue;

        if (isChecked) {
            refConfirm.current.open(null, t('active_sms_package_desc_confirm'), t('active_sms_package'));
        } else {
            refConfirm.current.open(null, t('de_active_sms_package_desc_confirm'), t('de_active_sms_package'));
        }
    };

    const _handleCloseConfirm = () => {
        refConfirm.current.close();
    };

    const _handleConfirm = () => {
        const { isDeactive, packId } = refConfirmValue.current;
        if (!accountNumber) return history.push(addBranchPath(SETTINGS_PLANS));
        if (isMustRentPhone) {
            refConfirm.current.close();
            refRentPhones.current._open(packId);
            return;
        }

        const url = isDeactive ? ADDONS_SMS_DEACTIVE : ADDONS_SMS_ACTIVE;
        const optionsQuery = {
            data: isDeactive ? {} : { package_id: packId },
            method: isDeactive ? 'DELETE' : 'PUT'
        };

        const _handleSuccess = ({ message, data }) => {
            setActivePackage(isDeactive ? refPrePack.current || null : packId);
            refPrePack.current = packId;
            isDeactive ? onDeactiveSuccess(message) : onActiveSuccess({ sms_package_id: packId, message, data });

            if (!isDeactive && (!activePackage || !globalOverride)) onOpenOverrideModal();
            refConfirm.current.close();
        };

        const _handleFail = ({ message }) => {
            setActivePackage(refPrePack.current);
            onActiveFail(message);
        };

        const _handleFinally = () => {
            refConfirm.current.close();
        };

        clientQuery(url, optionsQuery, _handleSuccess, _handleFail, _handleFinally);
    };

    const _handleRentSuccess = (data) => {
        setActivePackage(data.sms_package_id);
        onRentSuccess(data);
    };

    const _renderListPackage = () => {
        return data.map((item) => {
            const isActive = activePackage === item.id;
            const isChecked = isActive && statusAddon === SMS_ADDON_STATUS.ACTIVE;
            return (
                <SMSPackItem
                    key={item.id}
                    onChange={_handleChangePackage}
                    isActive={isActive}
                    isChecked={isChecked}
                    {...item}
                />
            );
        });
    };

    return (
        <div className="wrap-content --hasline pt-4">
            {_renderListPackage()}
            <SMSAddonsRentPhones ref={refRentPhones} onRentSuccess={_handleRentSuccess} />
            <GdConfirm
                isDisplayClose
                ref={refConfirm}
                listButton={{ confirm: true, cancel: true }}
                className="modal container-modal modal-confirm-active open"
                onConfirm={_handleConfirm}
                alertContent={
                    !accountNumber && (
                        <div className="alert --error">
                            <p className="alert__description pr-0">{t('addons:subscribe_gd_to_active_feature')}</p>
                        </div>
                    )
                }
                footer={
                    <div className="footer-modal footer-hasbtn btn-close">
                        <div className="v2-btn-default --transparent" onClick={_handleCloseConfirm}>
                            {t('common:cancel')}
                        </div>
                        <ButtonSave
                            ref={refButtonSave}
                            title={t('common:yes')}
                            wrapClass="v2-btn-main ml-2"
                            onSave={_handleConfirm}
                        />
                    </div>
                }
            />
        </div>
    );
};

const SMSPackItem = ({
    id,
    save,
    sms_numbers,
    fee_package,
    fee_per_sms,
    isActive = false,
    isChecked = false,
    onChange = () => {}
}) => {
    const { t } = useTranslation();
    const _handleChange = (checked) => onChange(checked, id);

    return (
        <div className={classNames('boxs mb-0', { active: isActive })}>
            <span className="title">
                {sms_numbers} {t('common:sms')}
            </span>
            <div className="info">
                <span className="info__price">${fee_package}</span>
                <span className="info__sm black-2">{fee_per_sms}</span>
                <span className="info__sm green-default">{save}</span>
            </div>
            <GdSwitchCheckbox
                id={`package_${id}`}
                inputClassName="toggle toggle-round"
                textUnChecked={t('common:inactive')}
                textChecked={t('common:active')}
                defaultChecked={isChecked}
                onChangeImmediately={_handleChange}
            />
        </div>
    );
};

export default SMSAddonsPacks;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';

import { CUSTOMERS_ACCOUNT, CUSTOMERS_PORTAL, CUSTOMERS_STATEMENTS } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import IconPen from 'assets/icon/IconPen';
import IconProfile from 'assets/icon/IconProfile';
import IconWallet from 'assets/icon/IconWallet';
import { checkPermission, getPermissionCustomerDetail, isNotPermissionValue } from 'common/utils/PermissionUtils';
import PaymentsDisplay from '../../../accounts/components/PaymentsDisplay';
import CustomerTags from '../../CustomerTags';
import { APP_INVOICE_CARD } from 'app/const/Permissions';

const BlockInfo = ({ customer, balance, credits }) => {
    const { id } = useParams();
    const { t } = useTranslation('customers');

    const permissionsList = useSelector((state) => state.auth.user?.permissions?.enabled || []);
    const isPermissionInvoiceCard = checkPermission(permissionsList, APP_INVOICE_CARD);
    const addons = useSelector(({ auth }) => auth.user.settings.addons);
    const permissions = getPermissionCustomerDetail(addons);
    const { first_name, last_name, account_number, deposit } = customer || {};
    const accountNumber = `#${account_number}`;

    return (
        <div className="details-info flexcenter flex-wrap gap-8">
            <div className="flex-1 word-break">
                <b className="fs-16">{first_name.concat(' ', last_name)}</b>
                <p className="flexcenter gap-4">
                    <span className="grey-generic">{t('account')}</span>
                    <span className="black-2">{accountNumber}</span>
                </p>
            </div>

            <NavLink
                to={addBranchPath(CUSTOMERS_ACCOUNT.replace(':id', id))}
                className="v2-btn-default --icon-lg"
                activeClassName="is-disable"
            >
                <IconPen />
            </NavLink>

            <div className="details-info__statement d-flex gap-4 w-100">
                {!isNotPermissionValue(permissions, 'customer_portal') ? (
                    <NavLink
                        to={addBranchPath(CUSTOMERS_PORTAL.replace(':id', id))}
                        className="v2-btn-default has-icon btn-bg-purple svg-purple --sm fs-13 btn-sidebar"
                        activeClassName="is-disable"
                    >
                        <IconProfile />
                        <span className="flex-1 txt-ellipsis">{t('portal_access')}</span>
                    </NavLink>
                ) : null}
                {!isNotPermissionValue(permissions, 'statement') ? (
                    <NavLink
                        to={addBranchPath(CUSTOMERS_STATEMENTS.replace(':id', id))}
                        className="v2-btn-default has-icon btn-bg-purple svg-purple --sm fs-13 btn-sidebar"
                        activeClassName="is-disable"
                    >
                        <IconWallet />
                        <span className="flex-1 txt-ellipsis">{t('view_statement')}</span>
                    </NavLink>
                ) : null}
            </div>

            <CustomerTags classLabel="tags__label flex-betweenitems" classTitle="title-sx flexcenter gap-4" />
            <PaymentsDisplay isPaymentSideBar />
            {isPermissionInvoiceCard && (
                <div className="price">
                    <div className="flex-betweenitems">
                        <span className="price-label">{t('balance')}</span>
                        <b className="cost fs-16 black-eerie-dark" title={balance}>
                            {balance}
                        </b>
                    </div>
                    <div className="flex-betweenitems mt-1">
                        <span className="price-label">{t('credits')}</span>
                        <b className="cost fs-16 green-default" title={credits}>
                            {credits}
                        </b>
                    </div>
                    <div className="flex-betweenitems mt-1">
                        <span className="price-label green-default flex-1">{t('deposits')}</span>
                        <b className="cost fs-16 green-default" title={deposit}>
                            {deposit}
                        </b>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BlockInfo;

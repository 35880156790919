import classNames from 'classnames';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import GdGridView from 'app/components/grid/GdGridView';
import { DEFAULT_ALL, ERROR_CODE, KEY_REPORT_LOCAL_STORAGE, LIST_STATUS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { REPORT_TYPE } from 'app/const/Reports';
import { REPORT_OPPORTUNITY_SALE_FUNNEL } from 'app/const/api/V2';
import { CONVERSION_RATE, COUNT, SALE_FUNNEL_TAB, SALE_FUNNEL_TAB_LIST } from 'app/const/opportunity';
import { SALES_FUNNEL_LIST_FILTER } from 'app/const/report/ReportFilter';
import { getLocalParamsReport } from 'common/utils/ReportUtils';
import ErrorPage from 'app/modules/error';
import FilterReport from 'app/modules/report/components/FilterReport';
import { clientQuery } from 'common/utils/ApiUtils';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { formatNumberWithCommas, reduceNumberLengthByDigit } from 'common/utils/NumberUtils';
import { checkPermission } from 'common/utils/PermissionUtils';
import { formatSecondToDay } from 'common/utils/TimeUtils';
import { getGridColumnsSalesFunnel } from './SalesFunnelTable';
import SalesFunnelChart from './SalesFunnelChart';
import GridLoading from 'app/components/grid/components/GridLoading';
import IconMinus from 'assets/icon/IconMinus';
import { companySettingCurrencySymbol } from 'common/utils/ObjectUtils';
import ReportDateRangePicker from 'app/modules/report/components/ReportDateRangePicker';
import { LIST_OPTIONS_SELECT_DATE_DEFAULT, OPTION_SELECT_DATE_RANGE_PICKER } from 'app/const/drip/Common';
import { ACCESS_SALES_FUNNEL } from 'app/const/Permissions';
import GDStatusBar from 'app/components/status/statusbar';

const SaleFunnel = () => {
    const { t } = useTranslation(['report', 'common']);
    const opportunityAddon = useSelector(({ auth }) => auth.user.settings.addons.opportunity);
    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users || []);
    const permissionsList = useSelector(({ auth }) => auth.user?.permissions?.enabled || []);
    const isAccessOpportunity = !!opportunityAddon && checkPermission(permissionsList, ACCESS_SALES_FUNNEL);

    const reportType = REPORT_TYPE.SALES_FUNNEL;
    const keyLocalStorage = KEY_REPORT_LOCAL_STORAGE.concat('_', reportType);
    const params = getLocalParamsReport(keyLocalStorage, reportType);
    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: true,
        selectedTab: params?.selectedTab || SALE_FUNNEL_TAB.COUNT,
        isDisable: false
    });

    const { isLoading: finalLoading, data: finalData, selectedTab, isDisable } = state;
    const refButtonUpdate = useRef(null);
    const refContainerSaleFunnel = useRef(null);
    const refScrollChartSaleFunnel = useRef(null);
    const refCount = useRef(0);
    const refAlert = useRef(null);
    const WIDTH_FUNNEL_CHART = 200 * (!!finalData.length ? finalData.length : 1);
    const HEIGHT_FUNNEL_CHART = 471;

    useEffect(() => {
        if (!isAccessOpportunity) return;

        _fetchListSaleFunnel();
    }, []);

    let tooltipDivElement = document.getElementById('tooltipDiv');

    if (!tooltipDivElement) {
        tooltipDivElement = document.createElement('div');
        tooltipDivElement.id = 'tooltipDivElement';
        tooltipDivElement.style.position = 'absolute';
        tooltipDivElement.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
        tooltipDivElement.style.color = '#fff';
        tooltipDivElement.style.padding = '5px';
        tooltipDivElement.style.display = 'none';
        tooltipDivElement.style.transform = 'translateX(-50%)';
        document.body.appendChild(tooltipDivElement);
    }

    useEffect(() => {
        const handleScroll = () => {
            if (!!refContainerSaleFunnel.current && !!refScrollChartSaleFunnel.current && !!tooltipDivElement)
                tooltipDivElement.style.display = 'none';
        };
        refContainerSaleFunnel.current?.addEventListener('scroll', handleScroll);
        refScrollChartSaleFunnel.current?.addEventListener('scroll', handleScroll);

        return () => {
            refContainerSaleFunnel.current?.removeEventListener('scroll', handleScroll);
            refScrollChartSaleFunnel.current?.removeEventListener('scroll', handleScroll);
        };
    }, [tooltipDivElement]);

    const _fetchListSaleFunnel = () => {
        const params = getLocalStorage(keyLocalStorage);
        delete params.selectedTab;
        delete params.currentPage;

        const _handleSuccess = ({ data }) => {
            dispatchState((prev) => ({
                ...prev,
                data,
                isLoading: false
            }));
        };

        const _handleFail = ({ message, statusCode }) => {
            refAlert.current.handeAddStatus({ id: 'sale_funnel', message, type: LIST_STATUS.ERROR });
            dispatchState({ isLoading: false, isDisable: statusCode === ERROR_CODE.UNPROCESSABLE });
        };

        clientQuery(
            REPORT_OPPORTUNITY_SALE_FUNNEL,
            {
                data: {
                    ...params,
                    staffs: Array.isArray(params.staffs) ? params.staffs.join(',') : DEFAULT_ALL
                },
                method: 'GET'
            },
            _handleSuccess,
            _handleFail
        );
    };

    const _convertValueToCurrency = (value = 0) =>
        `${companySettingCurrencySymbol.settingCurrencySymbol}${value < 0 ? '-' : ''}${reduceNumberLengthByDigit(
            Math.abs(value),
            2
        )}`.toUpperCase();

    const _handleMapData = (data) => {
        const convertValuetoPercent = (value) => `${value}%`;

        return (
            data?.map((item) => ({
                ...item,
                count: formatNumberWithCommas(item.count),
                value: _convertValueToCurrency(item.value),
                weighted_value: _convertValueToCurrency(item.weighted_value),
                conversion_rate: convertValuetoPercent(
                    selectedTab === COUNT ? item.conversion_rate : item[`${selectedTab}_${CONVERSION_RATE}`]
                ),
                lost: convertValuetoPercent(
                    selectedTab === COUNT ? item.lost?.percent : item[`${selectedTab}_lost`]?.['percent'] || 0
                ),
                avg_time: formatSecondToDay(item.avg_time) || <IconMinus />
            })) || []
        );
    };

    const _handleUpdate = () => {
        _toggleBtnUpdate(true);
        dispatchState((prev) => ({
            ...prev,
            isLoading: true
        }));
        _fetchListSaleFunnel();
    };

    const _toggleBtnUpdate = (value = true) => {
        refButtonUpdate.current && refButtonUpdate.current.classList[value ? 'add' : 'remove']('is-disable');
    };

    const _handleSelect = () => {
        if (refCount.current < 2) {
            refCount.current += 1;
            return;
        }
        _toggleBtnUpdate(false);
    };

    const _handleChangeTab = (name) => {
        dispatchState((prev) => ({
            ...prev,
            selectedTab: name
        }));
        setLocalStorage(keyLocalStorage, {
            ...params,
            selectedTab: name
        });
    };

    const _renderTab = () => {
        return (
            <div className="btn-item bg-white ml-0">
                {SALE_FUNNEL_TAB_LIST.map(({ id, name, isAnnualized }) => (
                    <div
                        key={id}
                        className={classNames('tab-items flexcenter', {
                            'active-tab-selector': selectedTab === id
                        })}
                        onClick={() => _handleChangeTab(id)}
                    >
                        {t(`${name}`)}
                        {isAnnualized ? <span className="budget">{t('annualized')}</span> : null}
                    </div>
                ))}
            </div>
        );
    };

    const _toggleTooltip = ({ display = '', left = '', top = '', content = '' }) => {
        tooltipDivElement.style.display = display;
        tooltipDivElement.style.left = left;
        tooltipDivElement.style.top = top;
        tooltipDivElement.style.borderRadius = '5px';
        tooltipDivElement.innerHTML = content;
    };

    const _renderChart = () => {
        if (finalLoading) return <GridLoading />;
        if (!finalData.length) return null;

        return (
            <SalesFunnelChart
                data={finalData}
                selectedTab={selectedTab}
                style={{
                    width: WIDTH_FUNNEL_CHART,
                    height: HEIGHT_FUNNEL_CHART
                }}
                convertValueToCurrency={_convertValueToCurrency}
                toggleTooltip={_toggleTooltip}
            />
        );
    };

    if (!isAccessOpportunity) return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;

    return (
        <Fragment>
            <div className={classNames('nav-top header flexcenter', { 'is-disable': isDisable })}>
                <ReportDateRangePicker
                    reportType={reportType}
                    onSelect={_handleUpdate}
                    listOptionsDateDefault={[
                        ...LIST_OPTIONS_SELECT_DATE_DEFAULT,
                        OPTION_SELECT_DATE_RANGE_PICKER.ALL_TIME
                    ]}
                />
                <div className="tabs">{_renderTab()}</div>
                <FilterReport
                    reportType={REPORT_TYPE.SALES_FUNNEL}
                    filters={SALES_FUNNEL_LIST_FILTER}
                    companyUsers={companyUsers}
                    isShowAvatar
                    onSelect={_handleSelect}
                />
                <div ref={refButtonUpdate} className="header-items v2-btn-main is-disable" onClick={_handleUpdate}>
                    {t('common:update')}
                </div>
            </div>
            <div className="wrapper-columns has-chart sales-funnel">
                <div ref={refContainerSaleFunnel} className="container-print contents-pages gap-8">
                    <GDStatusBar ref={refAlert} />
                    <div className="wrap-border">
                        <div ref={refScrollChartSaleFunnel} className="scrolls">
                            {_renderChart()}
                        </div>
                    </div>
                    <div className="wrap-tables flex-column relative tab-contents scroll-x mr-0">
                        <GdGridView
                            isLoading={finalLoading}
                            content={_handleMapData(finalData)}
                            classTable={classNames(
                                'table-multi-column scrolls-x has-text-ellipsis',
                                `--${selectedTab.replace('_value', '')}`
                            )}
                            fileTranslation={'inbox'}
                            {...getGridColumnsSalesFunnel()}
                            isScroll
                            msgEmpty={t('common:there_is_no_data')}
                            isEmptyFlat
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SaleFunnel;

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';
import IconDone from 'assets/icon/IconDone';

const Confirmed = () => {
    const { t } = useTranslation();
    const { repeatSettings, onChangeDataRepeatContext } = useContext(DataRepeatContext);

    function _onChangeConfirm() {
        onChangeDataRepeatContext({ always_confirmed: repeatSettings.always_confirmed ? 0 : 1 });
    }

    return (
        <div className="rows detail-toggle">
            <div className="txt">
                <IconDone isHasColor />
                <span className="flex-1 set-confirm pr-1" title={t('setting:set_to_confirm')}>
                    {t('setting:set_to_confirm')}
                </span>
            </div>
            <div className="details">
                <div className="switch large">
                    <input
                        id="toggle-confirmed"
                        className="toggle toggle-round"
                        type="checkbox"
                        checked={!!repeatSettings.always_confirmed}
                        onChange={_onChangeConfirm}
                    />
                    <label htmlFor="toggle-confirmed" />
                </div>
                <span className="txt-ellipsis">{t('set_to_confirmed_desc')}</span>
            </div>
        </div>
    );
};

export default Confirmed;

import { reducer } from 'app/const/Reducer';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { VOIPAddonsLoadingAfterHour } from '../loadings/VOIPAddonsLoadingAfterHour';
import IconClose from 'assets/icon/IconClose';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import ButtonSave from 'app/components/button/ButtonSave';
import TimePicker from 'app/modules/jobdetail/components/time/picker';
import WeeklyRepeat from 'app/modules/jobdetail/tabs/settingschedule/repeat/WeeklyRepeat';
import { DEFAULT_WEEKS } from 'app/const/addons';
import IconVoicemail from 'assets/icon/IconVoicemail';
import VOIPVoicemailDrop from '../VOIPVoicemailDrop';
import IconTime from 'assets/icon/IconTime';
import { convertTimeToNumber } from 'common/utils/TimeUtils';
import { clientQuery } from 'common/utils/ApiUtils';
import { LIST_STATUS } from 'app/const/App';
import { AFTER_HOURS_SETTING } from 'app/const/api/Voip';
import { convertMinuteToTime } from 'common/utils/DateUtils';
import ForwardingSettings from '../settings/ForwardingSettings';
import { validatePhoneNumberVoip } from 'common/utils/PhoneUtils';

const VOIPAddonsAfterHours = (props, ref) => {
    const { t } = useTranslation('addons');
    const refTimePickerFrom = useRef(null);
    const refTimePickerTo = useRef(null);
    const refButtonSave = useRef(null);
    const refVoicemail = useRef(null);
    const refDataRepeat = useRef(null);
    const refAlert = useRef(null);
    const refForwarding = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        isLoading: true
    });

    const {
        isVisible,
        isLoading,
        from_time: finalTimeStart,
        to_time: finalTimeEnd,
        voicemail: finalGreeting,
        all_day = DEFAULT_WEEKS,
        forwarding_number: finalForwardingNumber,
        greeting_text
    } = state;

    useEffect(() => {
        isVisible && getDetailAfterHours();
    }, [isVisible]);

    useImperativeHandle(ref, () => ({
        setVisible: _setVisible
    }));

    const _setVisible = (value) => {
        dispatchState({ isVisible: value });
    };

    const _handleClose = () => {
        dispatchState({ isVisible: false, isLoading: true });
    };

    const getDetailAfterHours = () => {
        const _handleSuccess = ({ data }) => {
            dispatchState((prev) => ({ ...prev, ...data, isLoading: false }));
        };

        clientQuery(AFTER_HOURS_SETTING, { method: 'GET' }, _handleSuccess);
    };

    const _renderBoxHour = () => {
        const _handleChangeWeekly = (value) => {
            refDataRepeat.current = value;
        };

        return (
            <div className="content-insert-variable">
                <div className="box-use-variable">
                    <h3 className="flexcenter gap-8 black fs-14 svg-green">
                        <IconTime />
                        {t('time_frame')}
                    </h3>
                    <p className="my-3">{t('desc_time_frame_voip_after_hours')}</p>
                    <div className="flex-betweenitems gap-24">
                        <div className="flex-1">
                            <div className="mb-1">{t('from')}</div>
                            <TimePicker
                                ref={refTimePickerFrom}
                                timeSelected={convertMinuteToTime(finalTimeStart)}
                                isCloseOnSelected
                            />
                        </div>
                        <div className="flex-1">
                            <div className="mb-1">{t('to')}</div>
                            <TimePicker
                                ref={refTimePickerTo}
                                timeSelected={convertMinuteToTime(finalTimeEnd)}
                                isCloseOnSelected
                            />
                        </div>
                    </div>

                    <WeeklyRepeat
                        shortDate={false}
                        shouldCheckHaveSelect={false}
                        title={{ className: 'fw-500', title: t('addons:all_day_on') }}
                        className="flexcenter gap-16 mt-3"
                        classWrapper="btn-item m-0 border-none"
                        {...all_day}
                        onChange={_handleChangeWeekly}
                    />
                </div>
            </div>
        );
    };

    const _renderVoicemail = () => {
        return (
            <div className="content-insert-variable">
                <div className="box-use-variable">
                    <h3 className="flexcenter gap-8 black fs-14">
                        <IconVoicemail isGreen />
                        {t('voicemail')}
                    </h3>
                    <div className="flex-column gap-8 mt-3">
                        <VOIPVoicemailDrop
                            ref={refVoicemail}
                            greeting={finalGreeting}
                            greetingText={greeting_text}
                            msgError={t('voicemail_is_required')}
                            isUseTyping
                            isUploadS3OnSuccess
                            onUploadFile={() => _handleDisableButtonSave(true)}
                            onUploadSuccess={() => _handleDisableButtonSave(false)}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const _renderFooter = () => {
        return (
            <div className="wrap-footer">
                <div className="v2-dropdown nav-call-module"></div>
                <div className="flex-1 flexcenter justify-end">
                    <div className="v2-btn-default --transparent" onClick={_handleClose}>
                        {t('cancel')}
                    </div>
                    <ButtonSave ref={refButtonSave} wrapClass="v2-btn-main ml-2" onSave={_handleSave}>
                        {t('save')}
                    </ButtonSave>
                </div>
            </div>
        );
    };

    const _handleSave = async () => {
        const _convertToMinute = (value) => {
            const { hour, midDay, minute } = value;
            return convertTimeToNumber(hour, minute, midDay);
        };

        const { greeting, greetingText, objectKey } = refVoicemail.current.getValue();
        const { forwarding_number } = refForwarding.current._getValue();

        const _handleSuccess = (res) => {
            const { message } = res;
            _handleShowStatus({
                message: !!message.length ? message : t('save_auto_attendant_successfully'),
                isSuccess: true
            });
            refButtonSave.current.setLoading(false);
        };

        const _handleFail = (err) => {
            _handleShowStatus(err);
            refButtonSave.current.setLoading(false);
        };

        if (!!forwarding_number && !validatePhoneNumberVoip(forwarding_number)) {
            _handleFail({ message: t('addons:error_phone_number', { number: forwarding_number }) });
            return false;
        }

        clientQuery(
            AFTER_HOURS_SETTING,
            {
                method: 'PUT',
                toFormData: false,
                data: {
                    all_day: refDataRepeat.current || all_day,
                    from_time: _convertToMinute(refTimePickerFrom.current._getTime()),
                    to_time: _convertToMinute(refTimePickerTo.current._getTime()),
                    voicemail: objectKey || greeting,
                    greeting_text: greetingText,
                    forwarding_number
                }
            },
            _handleSuccess,
            _handleFail
        );
    };

    const _handleShowStatus = ({ message, isSuccess = false }) => {
        refAlert.current.showStatusBar({
            id: 'after_hours',
            message,
            type: isSuccess ? LIST_STATUS.SUCCESS : LIST_STATUS.ERROR
        });
    };

    const _handleDisableButtonSave = (value = false) => {
        refButtonSave.current?.setDisable(value);
    };

    if (!isVisible) return null;

    return (
        <ReactModal
            isOpen
            id="after_hours_form"
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal wrapper-has-preview is-preview-template open"
        >
            <div className="modal__overlay bg-fixed" />
            <div className="modal__container --preview-r">
                {isLoading ? (
                    <VOIPAddonsLoadingAfterHour />
                ) : (
                    <div className="wrap-container">
                        <div className="wrap-container__form flex-column">
                            <div className="wrap-header py-3">
                                <div className="flex-betweenitems">
                                    <h3 className="flex-1 fs-17 mr-2">{t('after_hours_settings')}</h3>
                                    <div className="v2-btn-default --icon-lg --transparent" onClick={_handleClose}>
                                        <IconClose />
                                    </div>
                                </div>
                            </div>

                            <AlertCustomer ref={refAlert} />
                            <div className="wrap-body">
                                {_renderBoxHour()}
                                <div className="content-insert-variable">
                                    <div className="box-use-variable">
                                        <ForwardingSettings
                                            ref={refForwarding}
                                            forwarding={{ number: finalForwardingNumber }}
                                            isNotUseSwitch
                                        />
                                    </div>
                                </div>
                                {_renderVoicemail()}
                            </div>

                            {_renderFooter()}
                        </div>
                    </div>
                )}
            </div>
        </ReactModal>
    );
};

export default forwardRef(VOIPAddonsAfterHours);

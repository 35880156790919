import { ADDONS_CHAT_BOT_CONTENT } from 'app/config/routes';
import { ADDONS_FOOTER_OPTIONS } from 'app/const/addons';
import i18n from 'assets/i18n';
import store from 'common/redux/store/configureStore';
import { convertPhpFormatToMoment } from 'common/utils/DateUtils';
import moment from 'moment';

export const getAddonsChatbotFooterOptionsConfig = ({
    keyword = '',
    isBasicPlan = false,
    isGrowthPlan = false,
    isAccountActive = false,
    isTrial = false
}) => {
    const isChatbotPortal = keyword === ADDONS_FOOTER_OPTIONS.CHATBOT_AI_PORTAL;
    const isDisable = !isAccountActive || isBasicPlan || (isChatbotPortal && !isGrowthPlan && !isTrial);

    return { isDisable, path: ADDONS_CHAT_BOT_CONTENT.replace(':type', keyword) };
};

export const getEndpointAIAgent = ({ endPointAgentSms = '', endPointAgentPortal = '', typeAgent = '' }) => {
    let endPoint = endPointAgentSms;
    if (typeAgent === ADDONS_FOOTER_OPTIONS.CHATBOT_AI_PORTAL) endPoint = endPointAgentPortal;
    return endPoint;
};
export const checkTypeAgentChatbot = ({ typeAgent = '', conditionCheck = ADDONS_FOOTER_OPTIONS.CHATBOT_AI_SMS }) => {
    return typeAgent === conditionCheck;
};
export const getDateString = (dateUnix) => {
    const { settings, company } = store.getState().auth.user;
    return moment
        .unix(dateUnix)
        .tz(settings.timezone)
        .locale(i18n.language)
        .format(`${convertPhpFormatToMoment(company.date_format)}, hh:mmA`);
};

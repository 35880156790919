import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AddPaymentContext } from 'app/modules/jobdetail/contexts/AddPaymentContext';
import IconHashtag from 'assets/icon/IconHashtag';

const Balance = ({ currentBalance = '' }) => {
    const { t } = useTranslation();
    const { addPayment } = useContext(AddPaymentContext);

    return (
        <div className="sm-row">
            <div className="txt">
                <IconHashtag />
                <span className="flex-1 txt-ellipsis">{t('jobDetail:current_balance')}</span>
            </div>
            <div className="detail">{addPayment?.balanceDue?.format || currentBalance}</div>
        </div>
    );
};

export default Balance;

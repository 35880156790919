import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import commonUs from './us/common';
import commonEs from './es/common';

import authUs from './us/auth';
import authEs from './es/auth';

import headerUs from './us/header';
import headerEs from './es/header';

import addonUs from './us/addons';
import addonEs from './es/addons';

import reportUs from './us/report';
import reportEs from './es/report';

import reviewUs from './us/reviews';
import reviewEs from './es/reviews';

import notificationsUs from './us/notifications';
import notificationsEs from './es/notifications';

import commissionsUs from './us/commissions';
import commissionsEs from './es/commissions';

import settingUs from './us/settings';
import settingEs from './es/settings';

import dripUs from './us/drip';
import dripEs from './es/drip';

import customersUs from './us/customers';
import customersEs from './es/customers';

import messageUs from './us/message';
import messageEs from './es/message';

import calendarUs from './us/calendar';
import calendarEs from './es/calendar';

import jobdetailUs from './us/jobdetail';
import jobdetailEs from './es/jobdetail';

import pageTitleUs from './us/pageTitle';
import pageTitleEs from './es/pageTitle';

import smartViewUS from './us/smartview';
import smartViewES from './es/smartview';

import referralUs from './us/referral';
import referralEs from './es/referral';

import onboardingUs from './us/onboarding';
import onboardingEs from './es/onboarding';

import inboxUs from './us/inbox';
import inboxEs from './es/inbox';

const resources = {
    en: {
        common: commonUs,
        auth: authUs,
        header: headerUs,
        addons: addonUs,
        report: reportUs,
        notifications: notificationsUs,
        review: reviewUs,
        commission: commissionsUs,
        setting: settingUs,
        drip: dripUs,
        customers: customersUs,
        messages: messageUs,
        calendar: calendarUs,
        pageTitle: pageTitleUs,
        jobDetail: jobdetailUs,
        smartView: smartViewUS,
        referral: referralUs,
        onboarding: onboardingUs,
        inbox: inboxUs
    },
    es: {
        common: commonEs,
        auth: authEs,
        header: headerEs,
        addons: addonEs,
        report: reportEs,
        notifications: notificationsEs,
        review: reviewEs,
        commission: commissionsEs,
        setting: settingEs,
        drip: dripEs,
        customers: customersEs,
        messages: messageEs,
        calendar: calendarEs,
        pageTitle: pageTitleEs,
        jobDetail: jobdetailEs,
        smartView: smartViewES,
        referral: referralEs,
        onboarding: onboardingEs,
        inbox: inboxEs
    }
};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        debug: false,
        ns: [
            'common',
            'auth',
            'header',
            'addons',
            'notifications',
            'report',
            'review',
            'commission',
            'setting',
            'drip',
            'customers',
            'messages',
            'calendar',
            'pageTitle',
            'jobDetail',
            'smartView',
            'referral',
            'onboarding',
            'inbox'
        ],
        defaultNS: 'common',
        keySeparator: false,
        interpolation: { escapeValue: false, formatSeparator: ',' },
        react: { useSuspense: false }
    });

export default i18n;

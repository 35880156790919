import React from 'react';

const IconLuna = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.5821 14.5586L20.198 14.7663C20.2904 14.4924 20.1908 14.1904 19.9536 14.0252C19.7163 13.86 19.3985 13.8713 19.1736 14.053L19.5821 14.5586ZM12.0342 4.00007L12.3813 4.54966C12.6255 4.39541 12.7387 4.09843 12.659 3.82073C12.5793 3.54304 12.3258 3.35129 12.0369 3.35008L12.0342 4.00007ZM19.1736 14.053C18.1692 14.8645 16.8922 15.35 15.5 15.35V16.65C17.2002 16.65 18.7634 16.0557 19.9906 15.0642L19.1736 14.053ZM15.5 15.35C12.2691 15.35 9.65 12.7309 9.65 9.5H8.35C8.35 13.4488 11.5512 16.65 15.5 16.65V15.35ZM9.65 9.5C9.65 7.41656 10.7387 5.58692 12.3813 4.54966L11.6871 3.45048C9.68328 4.71588 8.35 6.952 8.35 9.5H9.65ZM12 4.65C12.0105 4.65 12.021 4.65002 12.0315 4.65007L12.0369 3.35008C12.0246 3.35003 12.0123 3.35 12 3.35V4.65ZM4.65 12C4.65 7.94071 7.94071 4.65 12 4.65V3.35C7.22274 3.35 3.35 7.22274 3.35 12H4.65ZM12 19.35C7.94071 19.35 4.65 16.0593 4.65 12H3.35C3.35 16.7773 7.22274 20.65 12 20.65V19.35ZM18.9662 14.3508C17.9855 17.2581 15.2362 19.35 12 19.35V20.65C15.8108 20.65 19.0444 18.1861 20.198 14.7663L18.9662 14.3508Z"
                fill="var(--color-icon)"
            ></path>
        </svg>
    );
};

export default IconLuna;

import classNames from 'classnames';
import React, { Fragment, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { isEventOverDiv } from 'app/modules/calendar/ultil/Calendar';
import { openSmsDetail } from 'common/redux/actions/sms/smsAction';
import { dispatchEvent } from 'common/utils/eventUtils';
import { CALENDAR_CUSTOM_EVENTS, EVENT_TOOLTIPS } from '../../const';
import BoxDistance from '../magnet/components/BoxDistance';
import EventDriveTime from './EventDriveTime';

export const EventContent = ({
    magnet: magnetProp = null,
    isMagnetModal: isMagnetModalProp = false,
    magnetSelected = false,
    isAllDayEvent = false,
    event,
    customStyle = {},
    driveData = null,
    headerTitle,
    onToggleTooltip = () => {},
    isVerticalMode
}) => {
    const extendedProps = event?.extendedProps || {};
    const magnet = magnetProp || extendedProps.magnet || null;
    const isMagnetModal = isMagnetModalProp || extendedProps.isMagnetModal || false;
    const colorDistance = extendedProps.colorDistance;

    const dispatch = useDispatch();
    const { t } = useTranslation('calendar');
    const refContent = useRef(null);
    const currentEvent = event?._def?.extendedProps || {};

    const {
        linked_job: isRenderLinkedJobTooltip,
        locked: isRenderLockedTooltip,
        draft_invoice: isRenderDraftTooltip,
        circle_notify: isRenderSMSTooltip,
        time_window = {},
        colorEvent,
        refetchEvents,
        emoji,
        type: eventType
    } = currentEvent;

    const isTimeoffEvent = eventType === 'timeoff' || eventType === 'event';

    const { background: backgroundColor, border: borderColor, text: textColor } = colorEvent || {};

    useEffect(() => {
        if (refetchEvents) {
            event.setProp('backgroundColor', backgroundColor);
            event.setProp('borderColor', borderColor);
        }
    }, [refetchEvents]);

    const idEvent = event.id || '';

    /**
     * Handles the mouse over event for the tooltip.
     * @param {Event} e - The mouse over event.
     * @param {string} type - The type of the tooltip.
     */
    const _handleMouseOverTooltip = (e, type) => {
        onToggleTooltip('show', { element: e.target, content: t(`calendar:${EVENT_TOOLTIPS[type]}`) });
    };

    const _handleMouseBlurTooltip = () => {
        onToggleTooltip('hide');
    };

    const handleOpenMagnetModal = () => {
        dispatchEvent(CALENDAR_CUSTOM_EVENTS.OPEN_MAGNET_JOB, event.extendedProps);
    };

    function initialDrag() {
        window.addEventListener('mousemove', _startDragging, false);
        window.addEventListener('mouseup', _stopDragging, false);
    }

    function initialDragTouch() {
        window.addEventListener('touchmove', _startDragging, false);
        window.addEventListener('touchend', _stopDraggingTouch, false);
    }

    function _startDragging(e) {
        if (isTimeoffEvent) return;
        let emptyDiv;
        const x = e.clientX ?? e.changedTouches[0].clientX;
        const y = e.clientY ?? e.changedTouches[0].clientY;
        const jobtemp = document.getElementById('work_pool_job_move');
        const elmShowEmpty = document.getElementById('show_ui_list_wookpool_empty');
        const idCheckDiv = isMagnetModal ? 'external-events-magnet' : 'external-events-work-pool';

        if (isMagnetModal) {
            if (!event._def.extendedProps.magnet) return;
            const external_events = document.getElementById(idCheckDiv);
            emptyDiv = external_events.querySelector('.content-empty');
        }

        if (isEventOverDiv(x, y, idCheckDiv) && (event.startEditable || isMagnetModal)) {
            const newHtml = `
                <div class="name">
                    <span style="color:${colorEvent?.text}" class="word-break">${headerTitle}</span>
                </div>
                ${_renderContent()}
            `;

            jobtemp.innerHTML = newHtml;
            jobtemp.style.backgroundColor = colorEvent?.background;
            jobtemp.style.borderColor = colorEvent?.border;
            jobtemp.style.color = colorEvent?.text;
            jobtemp.style.display = 'block';

            if (isMagnetModal && emptyDiv) emptyDiv.style.display = 'none';
            elmShowEmpty && elmShowEmpty.classList.add('dp-hide');
        } else {
            if (jobtemp) jobtemp.style.display = 'none';
            elmShowEmpty && elmShowEmpty.classList.remove('dp-hide');
            if (isMagnetModal && emptyDiv) emptyDiv.style.display = 'block';
        }
    }

    function _renderContent() {
        let string = '';
        currentEvent?.tile?.content?.forEach((item) => {
            string = string.concat(`<div class="info">${item}</div>`);
        });
        return string;
    }

    function _stopDragging() {
        window.removeEventListener('mousemove', _startDragging, false);
        window.removeEventListener('mouseup', _stopDragging, false);
    }

    function _stopDraggingTouch() {
        window.removeEventListener('touchmove', _startDragging, false);
        window.removeEventListener('touchend', _stopDraggingTouch, false);
    }

    const _handleSMSClick = () => {
        dispatch(openSmsDetail({ ...event._def.extendedProps.customer }));
    };

    if (isAllDayEvent && isVerticalMode) {
        return (
            <div style={{ textDecoration: !!event._def.extendedProps.active ? 'unset' : 'line-through' }}>
                {event.title}
            </div>
        );
    }

    if (isAllDayEvent) {
        return (
            <div
                title={t('calendar:open_task')}
                style={{ textDecoration: !!event._def.extendedProps.active ? 'unset' : 'line-through' }}
            >
                {event.title}
            </div>
        );
    }

    return (
        <Fragment>
            <div
                id={idEvent}
                ref={refContent}
                className={classNames('fc-event-jobs', {
                    'fc-event-magnet has-mappin': magnet,
                    selected: magnetSelected,
                    'prevent-resize': isMagnetModal,
                    'no-choice': !extendedProps.isEditable || isTimeoffEvent
                })}
                onMouseDown={initialDrag}
                onTouchStart={initialDragTouch}
                style={{
                    backgroundColor: backgroundColor,
                    borderColor: borderColor,
                    color: textColor,
                    '--color-magnet': colorDistance,
                    ...customStyle
                }}
            >
                <div className="header-job">
                    {/* Icon not match time window */}
                    {time_window?.match === 0 && (
                        <div
                            className="cursor-pointer icon-svg"
                            onMouseOver={(e) => _handleMouseOverTooltip(e, 'not_match_time_window')}
                            onMouseLeave={_handleMouseBlurTooltip}
                        >
                            <svg width={18} height={18}>
                                <use xlinkHref="#clock" />
                            </svg>
                        </div>
                    )}

                    {/* Icon Attention */}
                    {!!isRenderDraftTooltip && (
                        <div
                            className="cursor-pointer"
                            onMouseOver={(e) => _handleMouseOverTooltip(e, 'draft')}
                            onMouseLeave={_handleMouseBlurTooltip}
                        >
                            <svg width={14} height={12}>
                                <use xlinkHref="#attention" />
                            </svg>
                        </div>
                    )}

                    {/* Icon locked */}
                    {!!isRenderLockedTooltip && (
                        <div
                            className="cursor-pointer icon"
                            onMouseOver={(e) => _handleMouseOverTooltip(e, 'locked')}
                            onMouseLeave={_handleMouseBlurTooltip}
                        >
                            <svg width={15} height={15} className="icon-svg --lock">
                                <use xlinkHref="#lock" />
                            </svg>
                        </div>
                    )}

                    {/* Icon linked */}
                    {!!isRenderLinkedJobTooltip && (
                        <div
                            className="cursor-pointer icon"
                            onMouseOver={(e) => _handleMouseOverTooltip(e, 'linked')}
                            onMouseLeave={_handleMouseBlurTooltip}
                        >
                            <svg width={15} height={15} className="icon-svg --link">
                                <use xlinkHref="#link" />
                            </svg>
                        </div>
                    )}

                    {/* Loading Icon */}
                    <div className={`icon-loading job_fullcalendar_iconloading_${idEvent}`} />

                    {/* Title of event */}
                    {Array.isArray(emoji) && !!emoji.length && <>{emoji.join('')}</>}
                    <div className="content-job">{headerTitle}</div>
                    {/* Open unread SMS message */}
                    {!!isRenderSMSTooltip && (
                        <div
                            className="icon-sms cursor-pointer"
                            onMouseOver={(e) => _handleMouseOverTooltip(e, 'sms')}
                            onMouseLeave={_handleMouseBlurTooltip}
                            onClick={_handleSMSClick}
                        />
                    )}
                </div>

                <div className="break-word white-space-pre content-job --desc">
                    {currentEvent?.tile?.content?.join('\n')}
                </div>

                {/* Magnet icon */}
                {extendedProps.is_magnet && !isMagnetModal ? (
                    <div className="v2-btn-default --icon-sm btn-magnet btn-modal" onClick={handleOpenMagnetModal}>
                        <svg width={24} height={24}>
                            <use xlinkHref="#icon-magnet" />
                        </svg>
                    </div>
                ) : null}

                {/* Magnet distance and number */}
                {magnet ? <BoxDistance distance={magnet} time={extendedProps.time} /> : null}
                {driveData ? <EventDriveTime {...driveData} /> : null}
            </div>
            {magnet ? (
                <div className="map-pin" data-number={`${extendedProps.indexJob}`}>
                    <svg style={{ width: 18, height: 25 }}>
                        <use xlinkHref={`#icon-map-${colorDistance}`} />
                    </svg>
                </div>
            ) : null}
        </Fragment>
    );
};

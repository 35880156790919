import moment from 'moment';
import { forwardRef, React, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import TimePicker from 'app/modules/jobdetail/components/time/picker';
import IconTrash from 'assets/icon/IconTrash';
import { convertTimeToISO } from 'common/utils/DateUtils';
import { convertTimeNumber } from 'common/utils/TimeUtils';

const TimeWindowDateItem = forwardRef(
    ({ onChangeInput = () => {}, onDelete = () => {}, start, end, name, id: idItem }, ref) => {
        const { t } = useTranslation();
        const refTimePickerStart = useRef(null);
        const refTimePickerEnd = useRef(null);

        useImperativeHandle(ref, () => ({ getTime: _getTimeDate }));

        const _getTimeDate = () => {
            return {
                idTime: idItem,
                start: refTimePickerStart.current._getTime(),
                end: refTimePickerEnd.current._getTime()
            };
        };

        const returnTime = (timeNumber) => {
            if (!timeNumber) {
                return '';
            }
            const { hour, minute, midDay } = convertTimeNumber(timeNumber);
            return `${hour}:${minute} ${midDay}`;
        };

        const startTime = convertTimeToISO(
            moment(`${moment().utc().format('MM-DD-YYYY')} ${returnTime(start)}`, 'MM-DD-YYYY hh:mm A')
        );
        const endTime = convertTimeToISO(
            moment(`${moment().utc().format('MM-DD-YYYY')} ${returnTime(end)}`, 'MM-DD-YYYY hh:mm A')
        );

        return (
            <div className="wrap-select-time template-rows">
                <input
                    value={name}
                    className="field-input field-name"
                    type="text"
                    placeholder={t('setting:template_name')}
                    onChange={(e) => onChangeInput(e, idItem)}
                />
                <TimePicker ref={refTimePickerStart} timeSelected={moment(startTime).utc()} />
                <span className="label-to">{t('common:to')}</span>
                <TimePicker ref={refTimePickerEnd} timeSelected={moment(endTime).utc()} />
                <div onClick={() => onDelete(idItem)} className="v2-btn-default --delete --icon-sm js-delete-row">
                    <IconTrash />
                </div>
            </div>
        );
    }
);
export default TimeWindowDateItem;

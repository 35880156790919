import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';

const RichTextDropzone = (
    { text = '', textDrop = '', showIcon = false, description = '', pointerEventNone = true, onDrop = () => {} },
    ref
) => {
    const { t } = useTranslation();
    let dragTimer;
    const [state, dispatchState] = useReducer(reducer, { isVisible: false, isDragEnter: false });
    const { isVisible, isDragEnter } = state;
    const refDropzone = useRef(null);

    useImperativeHandle(ref, () => ({
        handleDisplay: _handleDisplay,
        handleHidden: _handleHidden,
        hide: () => dispatchState({ isVisible: false }),
        handleDrop: _handleDrop,
        handleSetDragEnter: (isDragEnter) => {
            dispatchState({ isDragEnter });
        }
    }));

    useEffect(() => {
        window.addEventListener('drop', _handleDropGlobal);
        return () => {
            window.removeEventListener('drop', _handleDropGlobal);
        };
    }, []);

    const _handleDropGlobal = () => {
        const divs = document.querySelectorAll('.box-drop-file');
        if (divs) {
            for (let i = 0; i < divs.length; i++) {
                const element = divs[i];
                element.classList.remove('dp-block');
                element.classList.add('dp-hide');
            }
        }
    };

    useEffect(() => {
        if (!pointerEventNone) return;
        const divs = document.querySelectorAll('.box-drop-file:not(#show_ui_list_wookpool_empty)');
        if (isDragEnter) {
            for (let i = 0; i < divs.length; i++) {
                const element = divs[i];
                if (!refDropzone.current?.isEqualNode(element)) {
                    element.classList.remove('dp-block');
                    element.classList.add('dp-hide');
                }
            }
        } else {
            for (let i = 0; i < divs.length; i++) {
                divs[i].classList.add('dp-block');
                divs[i].classList.remove('dp-hide');
            }
        }
    }, [isDragEnter]);

    const _handleDisplay = () => {
        !isVisible && dispatchState({ isVisible: true });
        setTimeout(() => {
            const divs = document.querySelectorAll('.box-drop-file');
            if (divs) {
                for (let i = 0; i < divs.length; i++) {
                    const element = divs[i];
                    element.classList.remove('dp-hide');
                    divs[i].classList.add('dp-block');
                }
            }
        }, 0);
    };

    const _handleHidden = () => {
        isVisible && !isDragEnter && dispatchState({ isVisible: false });
    };

    const _handleDragEnter = (e) => {
        if (!isDragEnter) {
            dispatchState({ isDragEnter: true });
            window.clearTimeout(dragTimer);
        }
        if (pointerEventNone) {
            const divs = document.querySelectorAll('.box-drop-file');
            for (let i = 0; i < divs.length; i++) {
                divs[i].classList.remove('dp-block');
                divs[i].classList.add('dp-hide');
            }
        }
        e.preventDefault();
        e.stopPropagation();
    };

    const _handleDragLeave = (e) => {
        dragTimer = window.setTimeout(() => {
            dispatchState({ isDragEnter: false });
        }, 25);
        e.preventDefault();
        e.stopPropagation();
    };

    const _handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.files.length) {
            onDrop(e.dataTransfer.files);
        } else {
            const str = e.dataTransfer.getData('text/html'),
                rex = /<img.*?src="([^">]*\/([^">]*?))".*?>/g,
                url = rex.exec(str);
            if (url) onDrop(url[1]);
        }
        dispatchState({ isVisible: false, isDragEnter: false });
        setTimeout(() => {
            _handleDropGlobal();
        }, 0);
    };

    if (!isVisible) return null;
    return (
        <div
            ref={refDropzone}
            className="box-drop-file dp-block"
            onDragOver={_handleDragEnter}
            onDragLeave={_handleDragLeave}
            onDrop={_handleDrop}
            style={pointerEventNone ? { userSelect: 'none', pointerEvents: 'none' } : {}}
        >
            <div className="box-drop-file__conts" style={pointerEventNone ? { pointerEvents: 'none' } : {}}>
                {showIcon && <div className="arrow-lg" />}
                <p className="title blue-default">
                    {isDragEnter
                        ? textDrop || t('jobDetail:drop_files_here')
                        : text || t('jobDetail:drag_image_or_text_here')}
                </p>
                {description && <p className="description">{description}</p>}
            </div>
        </div>
    );
};

export default forwardRef(RichTextDropzone);

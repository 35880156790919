import React, { createContext, useEffect, useMemo, useReducer } from 'react';
import { clientQuery } from 'common/utils/ApiUtils';
import { initDataCustomer } from 'app/const/Api';
import { transformToCurrency } from 'common/utils/NumberUtils';
import { reducer } from 'app/const/Reducer';
import { useSelector } from 'react-redux';
import { COMMISSION_TYPE_VALUE } from 'app/const/Commissions';
import { useRef } from 'react';
import { DEFAULT_VALUE_PACKAGE, ESTIMATE_TYPE } from '../const/Estimate';
import { _calculationTotal } from '../tabs/addestimate/EstimateUtils';

export const AddInvoiceContext = createContext();

const AddInvoiceContextProvider = ({
    invoiceData,
    children,
    reloadService = 0,
    reloadEditInvoice = 0,
    reloadSoldBy = 0,
    reloadSchedule = 0,
    reloadDateInvoice = 0,
    changeLocation,
    onChangeLocation = () => {},
    onUpdateButtonSave = () => {}
}) => {
    const refUploadImages = useRef({});

    const _repareData = (newData) => {
        if (newData.type === ESTIMATE_TYPE.PACKAGES) {
            return { packages: _handleCalEstimatePackage({ ...newData }) };
        }
        return _handleCalcuSubTotal({ ...newData });
    };

    const _preData = useMemo(() => {
        return { ...invoiceData, ..._repareData(invoiceData) };
    }, []);

    const [state, dispatchState] = useReducer(reducer, { ..._preData });
    const { list_taxes } = useSelector((store) => store.taxesReducer);
    const { currency } = useSelector(({ auth }) => auth?.user.settings);
    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);
    const { customer_id: customerIdState, location_id: locationIdState, number: numberState } = state;

    useEffect(() => {
        (reloadEditInvoice || reloadService) && _handleChangeData({ ...invoiceData });
    }, [reloadService, reloadEditInvoice]);

    useEffect(() => {
        if (reloadDateInvoice) {
            const dateRef = invoiceData.date;
            dispatchState((prev) => {
                return {
                    ...prev,
                    date: dateRef,
                    recurrence: {
                        ...prev.recurrence,
                        offset: {
                            ...prev.recurrence.offset,
                            dateIssue: dateRef
                        }
                    }
                };
            });
        }
    }, [reloadDateInvoice]);

    useEffect(() => {
        if (reloadSoldBy || reloadSchedule) {
            dispatchState((prevState) => ({
                ...prevState,
                soldBy: invoiceData.soldBy,
                schedule: invoiceData.schedule,
                items: _handleUpdateCommission(prevState.items, {
                    soldBy: invoiceData.soldBy,
                    schedule: invoiceData.schedule
                })
            }));
        }
    }, [reloadSoldBy, reloadSchedule]);

    useEffect(() => {
        onChangeLocation(!!!locationIdState);
    }, [locationIdState]);

    function _handleUploadImages(itemId, value) {
        refUploadImages.current = { ...refUploadImages.current, [itemId]: value };
        onUpdateButtonSave(Object.values(refUploadImages.current).some((item) => item));
    }

    function _handleTaxItem(isActiveLocationTax, locationTax1, locationTax2, item) {
        if (isActiveLocationTax) {
            if (!item.changedTax) {
                item.old_tax1 = item.tax1;
                item.old_tax2 = item.tax2;
                item.changedTax = true;
            }
            item.tax1 = list_taxes.find((item) => item.id === locationTax1);
            item.tax2 = list_taxes.find((item) => item.id === locationTax2);
        } else {
            if (item.changedTax) {
                item.tax1 = item.old_tax1;
                item.tax2 = item.old_tax2;
            }
        }
        return { ...item };
    }

    useEffect(() => {
        if (changeLocation) {
            _handleChangeLocationTax(invoiceData.locationInfo || {});
        }
    }, [changeLocation]);

    useEffect(() => {
        if (!invoiceData.available_balance && customerIdState) {
            clientQuery(initDataCustomer(customerIdState), { method: 'GET' }, _getCustomerDataSuccess);
        }
    }, [customerIdState]);

    function _handleChangeLocationTax(newLocationInfo = {}, customerId = '') {
        const { tax1: locationTax1, tax2: locationTax2, payment_terms, id } = newLocationInfo || {};
        const isActiveLocationTax = locationTax1 || locationTax2;

        dispatchState((prev) => {
            const { customer_id, isFromAddJob, payment_terms: prevPaymentTerms, terms, location_id } = prev;
            const paymentTermsContent = payment_terms?.content;
            const triggerUpdatePaymentTerm = location_id !== id;
            const shouldUsePreviousTerms = isFromAddJob && !triggerUpdatePaymentTerm;
            const finalCustomerId = customerId || customer_id;
            const newData = {
                ...prev,
                items: prev.items.map((item, index) => {
                    item.id = item.id || index.toString();
                    if (item.item_default) {
                        return { ...item };
                    }
                    return _handleTaxItem(isActiveLocationTax, locationTax1, locationTax2, item);
                }),
                locationInfo: newLocationInfo,
                location_id: newLocationInfo?.id || '',
                customer_id: finalCustomerId,
                payment_terms: (shouldUsePreviousTerms ? payment_terms || prevPaymentTerms : payment_terms) || {},
                terms: (shouldUsePreviousTerms ? paymentTermsContent || terms : paymentTermsContent) || ''
            };
            return {
                ...newData,
                ..._handleCalcuSubTotal(newData)
            };
        });
    }

    function _getCustomerDataSuccess(response) {
        dispatchState((prev) => {
            return {
                ...prev,
                available_balance: response.data.balance,
                available_credit: response.data.credit
            };
        });
    }

    function _handleChangeData(newData) {
        dispatchState((prev) => {
            return {
                ...prev,
                ...newData,
                ..._repareData(newData),
                number: numberState || ''
            };
        });
    }

    function _handleChangeWithNewData(callback) {
        dispatchState((prev) => {
            const newData = callback(prev);
            return {
                ...newData,
                ..._handleCalcuSubTotal(newData),
                number: numberState || ''
            };
        });
    }

    function _handleChangeEstimatePackage(callback) {
        dispatchState((prev) => {
            const newData = callback(prev);
            return {
                ...newData,
                packages: _handleCalEstimatePackage(newData),
                number: numberState || ''
            };
        });
    }

    function _handleCalEstimatePackage(estimateData) {
        const { packages, items: estimateItems } = estimateData;
        return packages.map((itemPackage) => {
            const {
                id: packageId,
                discount: discountDetail,
                deposit: depositDetail = DEFAULT_VALUE_PACKAGE.deposit
            } = itemPackage;

            return {
                ...itemPackage,
                ..._calculationTotal(
                    estimateItems.filter((lineItem) => lineItem.package_id === packageId),
                    discountDetail,
                    depositDetail
                )
            };
        });
    }

    function _handleCalcuSubTotal(invoiceData) {
        const { items, discount: discountDetail, deposit: depositDetail = {} } = invoiceData;
        return _calculationTotal(items, discountDetail, depositDetail);
    }

    function _handleChangeInvoiceData(newData) {
        dispatchState((prev) => {
            return {
                ...prev,
                ...newData
            };
        });
    }

    const _handleUpdateCommission = (items, { soldBy = [], schedule = [] }) => {
        return [...items].map((item) => {
            return {
                ...item,
                commission: {
                    productions: _handleGetCommissionData('productions', schedule, item),
                    sales: _handleGetCommissionData('sales', soldBy, item)
                }
            };
        });
    };

    const _handleGetCommissionData = (type, data, itemData) => {
        const result = [];
        const isSale = type === 'sales';
        data.forEach((item) => {
            if (+item.item_id === +itemData.item_id) {
                const value = item[isSale ? 'sale' : 'production'].value;
                const format =
                    item[isSale ? 'sale' : 'production'].type === COMMISSION_TYPE_VALUE.NUMBER
                        ? transformToCurrency(value, currency)
                        : transformToCurrency(itemData.total.value / +value, currency);
                const user = companyUsers.find((user) => +user.id === +item.user_id);
                result.push({ ...item, user, format, value });
            }
        });

        return result;
    };

    const AddInvoiceContextData = {
        invoiceData: state,
        updateInvoiceDataContext: _handleChangeData,
        onUpdateInvoiceDataContext: _handleChangeInvoiceData,
        onChangeLocation: _handleChangeLocationTax,
        onUploadImagesItem: _handleUploadImages,
        onChangeWithPreData: _handleChangeWithNewData,
        onChangeData: dispatchState,
        onChangeEstimatePackage: _handleChangeEstimatePackage
    };

    return <AddInvoiceContext.Provider value={AddInvoiceContextData}>{children}</AddInvoiceContext.Provider>;
};

export default AddInvoiceContextProvider;

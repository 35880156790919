export default function IconArrow({ isPrev, isBelowBlue = false, isLeft = false, isUpGreen = false }) {
    if (isLeft)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5.5 8.5L12 15L18.5 8.5"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    if (isPrev)
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.2929 5.29289C10.6834 4.90237 11.3166 4.90237 11.7071 5.29289C12.0976 5.68342 12.0976 6.31658 11.7071 6.70711L7.413 11H20C20.5523 11 21 11.4477 21 12C21 12.5128 20.614 12.9355 20.1166 12.9933L20 13H7.415L11.7071 17.2929C12.0676 17.6534 12.0953 18.2206 11.7903 18.6129L11.7071 18.7071C11.3166 19.0976 10.6834 19.0976 10.2929 18.7071L4.29289 12.7071C3.90237 12.3166 3.90237 11.6834 4.29289 11.2929L10.2929 5.29289Z"
                    fill="var(--color-icon)"
                ></path>
            </svg>
        );
    if (isBelowBlue)
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} viewBox="0 0 25 25" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.79289 14.2071C5.40237 13.8166 5.40237 13.1834 5.79289 12.7929C6.18342 12.4024 6.81658 12.4024 7.20711 12.7929L11.5 17.087L11.5 4.5C11.5 3.94772 11.9477 3.5 12.5 3.5C13.0128 3.5 13.4355 3.88604 13.4933 4.38338L13.5 4.5L13.5 17.085L17.7929 12.7929C18.1534 12.4324 18.7206 12.4047 19.1129 12.7097L19.2071 12.7929C19.5976 13.1834 19.5976 13.8166 19.2071 14.2071L13.2071 20.2071C12.8166 20.5976 12.1834 20.5976 11.7929 20.2071L5.79289 14.2071Z"
                    fill="#1E7EF7"
                />
            </svg>
        );

    if (isUpGreen)
        return (
            <svg width={6} height={16} viewBox="0 0 6 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.9998 11.9999C1.9998 12.3589 2.29082 12.6499 2.6498 12.6499C3.00879 12.6499 3.2998 12.3589 3.2998 11.9999L3.29981 7.56914L4.19019 8.45952C4.44403 8.71336 4.85558 8.71336 5.10942 8.45952C5.36327 8.20568 5.36327 7.79412 5.10942 7.54028L3.10942 5.54028C3.04711 5.47796 2.97528 5.43094 2.89861 5.39922C2.82198 5.36744 2.73794 5.3499 2.64981 5.3499C2.47031 5.3499 2.30781 5.42265 2.19019 5.54028C2.19017 5.54029 2.19016 5.54031 2.19015 5.54032L0.190186 7.54028C-0.063655 7.79412 -0.063655 8.20568 0.190185 8.45952C0.444026 8.71336 0.855584 8.71336 1.10942 8.45952L1.99981 7.56914L1.9998 11.9999Z"
                    fill="#1EAA5C"
                />
            </svg>
        );

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.7071 5.29289L19.7071 11.2929C20.0976 11.6834 20.0976 12.3166 19.7071 12.7071L13.7071 18.7071C13.3166 19.0976 12.6834 19.0976 12.2929 18.7071C11.9024 18.3166 11.9024 17.6834 12.2929 17.2929L16.585 13H4.02658C3.45961 13 3 12.5523 3 12C3 11.4872 3.3963 11.0645 3.90686 11.0067L4.02658 11H16.585L12.2929 6.70711C11.9024 6.31658 11.9024 5.68342 12.2929 5.29289C12.6834 4.90237 13.3166 4.90237 13.7071 5.29289Z"
                fill="var(--color-icon)"
            ></path>
        </svg>
    );
}

export default function IconOptimize({ isGrey = false, isGreen }) {
    if (isGreen)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                {' '}
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FFF"
                />{' '}
                <path
                    d="M22 22C23.1046 22 24 21.1046 24 20C24 18.8954 23.1046 18 22 18C20.8954 18 20 18.8954 20 20C20 21.1046 20.8954 22 22 22Z"
                    fill="#1EAA5C"
                />{' '}
                <path
                    d="M7 27C8.10457 27 9 26.1046 9 25C9 23.8954 8.10457 23 7 23C5.89543 23 5 23.8954 5 25C5 26.1046 5.89543 27 7 27Z"
                    fill="#1EAA5C"
                />{' '}
                <path
                    d="M7 25L9.18189 21.5181C10.3406 19.6691 12.7789 19.1094 14.6279 20.2681C14.9531 20.4719 15.2469 20.722 15.5 21.0105C16.7971 22.489 19.0472 22.6361 20.5257 21.339C20.8737 21.0336 21.1589 20.6634 21.3652 20.249L22 18.9738"
                    stroke="#1EAA5C"
                    strokeWidth={2}
                    strokeLinejoin="round"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22 4C25.3137 4 28 6.68629 28 10C28 12.6102 26.4739 15.5496 23.4864 18.868C23.4396 18.9199 23.3901 18.9694 23.3382 19.0162C22.5173 19.7552 21.2527 19.6889 20.5136 18.868C17.5261 15.5496 16 12.6102 16 10C16 6.68629 18.6863 4 22 4Z"
                    fill="#C0E5CF"
                />{' '}
                <path
                    d="M22 12C23.1046 12 24 11.1046 24 10C24 8.89543 23.1046 8 22 8C20.8954 8 20 8.89543 20 10C20 11.1046 20.8954 12 22 12Z"
                    fill="#1EAA5C"
                />{' '}
            </svg>
        );

    if (isGrey)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FAFBFC"
                />{' '}
                <path
                    d="M22 22C23.1046 22 24 21.1046 24 20C24 18.8954 23.1046 18 22 18C20.8954 18 20 18.8954 20 20C20 21.1046 20.8954 22 22 22Z"
                    fill="var(--color-icon)"
                />{' '}
                <path
                    d="M7 27C8.10457 27 9 26.1046 9 25C9 23.8954 8.10457 23 7 23C5.89543 23 5 23.8954 5 25C5 26.1046 5.89543 27 7 27Z"
                    fill="var(--color-icon)"
                />{' '}
                <path
                    d="M7 25L9.18189 21.5181C10.3406 19.6691 12.7789 19.1094 14.6279 20.2681C14.9531 20.4719 15.2469 20.722 15.5 21.0105C16.7971 22.489 19.0472 22.6361 20.5257 21.339C20.8737 21.0336 21.1589 20.6634 21.3652 20.249L22 18.9738"
                    stroke="var(--color-icon)"
                    strokeWidth={2}
                    strokeLinejoin="round"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22 4C25.3137 4 28 6.68629 28 10C28 12.6102 26.4739 15.5496 23.4864 18.868C23.4396 18.9199 23.3901 18.9694 23.3382 19.0162C22.5173 19.7552 21.2527 19.6889 20.5136 18.868C17.5261 15.5496 16 12.6102 16 10C16 6.68629 18.6863 4 22 4Z"
                    fill="#C1C9D4"
                />{' '}
                <path
                    d="M22 12C23.1046 12 24 11.1046 24 10C24 8.89543 23.1046 8 22 8C20.8954 8 20 8.89543 20 10C20 11.1046 20.8954 12 22 12Z"
                    fill="var(--color-icon)"
                />{' '}
            </svg>
        );
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                fill="#FAFBFC"
            />
            <path
                d="M22 22C23.1046 22 24 21.1046 24 20C24 18.8954 23.1046 18 22 18C20.8954 18 20 18.8954 20 20C20 21.1046 20.8954 22 22 22Z"
                fill="#8d4afc"
            />
            <path
                d="M7 27C8.10457 27 9 26.1046 9 25C9 23.8954 8.10457 23 7 23C5.89543 23 5 23.8954 5 25C5 26.1046 5.89543 27 7 27Z"
                fill="#8d4afc"
            />
            <path
                d="M7 25L9.18189 21.5181C10.3406 19.6691 12.7789 19.1094 14.6279 20.2681C14.9531 20.4719 15.2469 20.722 15.5 21.0105C16.7971 22.489 19.0472 22.6361 20.5257 21.339C20.8737 21.0336 21.1589 20.6634 21.3652 20.249L22 18.9738"
                stroke="#8d4afc"
                strokeWidth={2}
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 4C25.3137 4 28 6.68629 28 10C28 12.6102 26.4739 15.5496 23.4864 18.868C23.4396 18.9199 23.3901 18.9694 23.3382 19.0162C22.5173 19.7552 21.2527 19.6889 20.5136 18.868C17.5261 15.5496 16 12.6102 16 10C16 6.68629 18.6863 4 22 4Z"
                fill="#dbcbff"
            />
            <path
                d="M22 12C23.1046 12 24 11.1046 24 10C24 8.89543 23.1046 8 22 8C20.8954 8 20 8.89543 20 10C20 11.1046 20.8954 12 22 12Z"
                fill="#8d4afc"
            />
        </svg>
    );
}

import React from 'react';
import { useTranslation } from 'react-i18next';

import GDInputNumber from 'app/components/input/InputNumber';

const DurationCondition = ({ value = {}, onChange = () => {} }) => {
    const { t } = useTranslation();
    const _handleChange = (number, name) => {
        const offset = { ...value, [name]: Number(number) ?? undefined };
        onChange({ durationValue: Object.values(offset).every((number) => number === undefined) ? {} : offset });
    };

    return (
        <div className="field wrap-time">
            <GDInputNumber
                autoFocus
                className="field-input"
                type="text"
                name="hours"
                placeholder={t('common:hours')}
                defaultValue={value?.hours || ''}
                onChange={_handleChange}
            />
            :
            <GDInputNumber
                className="field-input"
                type="text"
                name="minutes"
                placeholder={t('common:minutes')}
                defaultValue={value?.minutes || ''}
                onChange={_handleChange}
            />
            :
            <GDInputNumber
                className="field-input"
                type="text"
                name="seconds"
                placeholder={t('common:seconds')}
                defaultValue={value?.seconds || ''}
                onChange={_handleChange}
            />
        </div>
    );
};

export default DurationCondition;

import React, { useEffect, useReducer, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import IconCircleClose from 'assets/icon/IconCircleClose';
import IconFolder from 'assets/icon/IconFolder';
import IconLoading from 'assets/icon/IconLoading';
import IconSearch from 'assets/icon/IconSearch';
import { actionCustomerJobList } from 'common/redux/actions/calendar/jobListAction';
import { useTranslation } from 'react-i18next';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { CUSTOMERS, CALENDAR } from 'app/const/Route';
import AvatarByName from 'app/components/avatarbyname';
import { reducer } from 'app/const/Reducer';
import { actionOpenJobPreview } from 'common/redux/actions/job';
import { actionOpenJobDetail } from 'common/redux/actions/job/detail';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { actionOpenEstimate } from 'common/redux/actions/estimateAction';
import { isIOS } from 'app/const/Flatform';
import { TYPE_SEARCH_DEFAULT } from 'app/const/Customers';
import { CUSTOMERS_ESTIMATES, CUSTOMERS_INVOICES, CUSTOMERS_JOBS } from 'app/config/routes';
import classNames from 'classnames';
import { clientQuery } from 'common/utils/ApiUtils';
import { GLOBAL_SEARCH } from 'app/const/Api';
import { addBranchPath } from 'app/const/Branch';

let timer = null;

function SearchHeader({ isExpired = false }) {
    const { t } = useTranslation(['header']);
    const dispatch = useDispatch();
    const refInputSearch = useRef(null);

    const [state, setState] = useReducer(reducer, {
        customers: [],
        term: '',
        loading: false,
        showMore: false,
        offset: 0,
        isVisible: false
    });

    const history = useHistory();
    const location = useLocation();

    const {
        term: finalKeyword,
        isVisible: finalIsVisible,
        customers: finalCustomers,
        loading: finalLoading,
        showMore: finalShowMore
    } = state;

    const classActive = finalIsVisible ? 'active' : '';
    const refSearch = useRef(null);
    const abortController = useRef();

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const isShowLoading = document.getElementById('show_icon_loading');
        const elPrevent = document.getElementById('globalSearch');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent && !isShowLoading) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('globalSearch');

        if (
            refSearch.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refSearch.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false, term: '' });
        _outFocusInput();
    }

    useEffect(() => {
        if (finalKeyword.length !== 0) {
            handleGetListCustomers();
        } else {
            refInputSearch.current.value = '';
        }
        return () => clearTimeout(timer);
    }, [finalKeyword]);

    function _openSearchResult() {
        !finalIsVisible && finalKeyword.length > 0 && setState({ isVisible: true });
    }

    const handleGetListCustomers = () => {
        abortController.current && abortController.current.abort();

        const handleOnSuccess = (resp) => {
            const respCustomers = Array.isArray(resp?.customers) ? resp.customers : [];

            const nextState = {
                loading: false,
                showMore: resp.show_more,
                customers: [...finalCustomers, ...respCustomers],
                isVisible: true
            };

            if (!refInputSearch.current.value) return;

            setState((prev) => {
                return {
                    ...prev,
                    ...nextState
                };
            });
        };

        const handleOnFailed = (error) => {
            if (error?.isAborted) return;
            setState({ loading: false });
        };

        abortController.current = new AbortController();

        clientQuery(
            GLOBAL_SEARCH,
            {
                data: {
                    term: finalKeyword,
                    offset: finalCustomers.length,
                    limit: 20,
                    type: TYPE_SEARCH_DEFAULT
                },
                method: 'GET',
                abortController: abortController.current
            },
            handleOnSuccess,
            handleOnFailed
        );
    };

    useHotkeys('alt+s', () => {
        setTimeout(() => {
            refInputSearch.current.click();
        }, 0);
    });

    function handleLoadMore(e) {
        e && e.preventDefault();

        setState({ loading: true });
        handleGetListCustomers();
    }

    const handleOnClickIconSearch = (e) => {
        e.stopPropagation();
        _onFocusInput();
        _openSearchResult();
    };

    const handleOnClickButtonClose = () => {
        setState({
            customers: [],
            term: '',
            loading: false,
            showMore: false,
            offset: 0,
            isVisible: false
        });
        refInputSearch.current.blur();
        document.getElementById('shortcut_alts').style.display = 'block';
    };

    function _onFocusInput() {
        refInputSearch.current.focus();
        document.getElementById('shortcut_alts').style.display = 'none';
    }

    function _outFocusInput() {
        refInputSearch.current.blur();
        _showShorcut();
    }

    function _showShorcut() {
        finalKeyword.length === 0 && (document.getElementById('shortcut_alts').style.display = 'block');
    }

    const handleOnChangeInput = (e) => {
        const { value } = e.target;

        const nextValue = {
            ...state,
            term: value,
            showMore: false,
            offset: 0,
            loading: false
        };
        if (value !== finalKeyword) nextValue.customers = [];

        if (value && value.length !== 0) {
            nextValue.loading = true;
        }

        timer && clearTimeout(timer);

        timer = setTimeout(() => {
            setState(nextValue);
        }, 300);
    };

    const handleOnClickDropdownRow = ({ id, value, type }) => {
        switch (type) {
            case 'invoice':
                dispatch(actionOpenInvoice({ id: value }));
                _redirectToCustomerDetail({ link: CUSTOMERS_INVOICES, id });
                break;
            case 'estimate':
                dispatch(actionOpenEstimate({ id: value }));
                _redirectToCustomerDetail({ link: CUSTOMERS_ESTIMATES, id });
                break;
            case 'job':
                dispatch(actionOpenJobDetail({ id: value }));
                _redirectToCustomerDetail({ link: CUSTOMERS_JOBS, id });
                break;
            default:
                history.push(addBranchPath(`${CUSTOMERS}/${id}`));
                break;
        }

        _closeSearchResult();
    };

    const _redirectToCustomerDetail = ({ link, id }) => {
        history.push(addBranchPath(`${link.replace(':id', id)}`), { isRedirectSearchBar: true });
    };

    const handleOnClickRowFolder = (customer) => {
        if (location.pathname !== CALENDAR) {
            history.push(addBranchPath(CALENDAR));
        }
        dispatch(actionCustomerJobList(customer));
        dispatch(actionOpenJobPreview(null));
        _closeSearchResult();
    };

    const renderSearchDropdown = () => {
        const renderListCustomers = () => {
            return finalCustomers.map((customer) => {
                return (
                    <div key={customer.desc.concat(customer.index)} className="items">
                        <div className="i-left" onClick={() => handleOnClickDropdownRow(customer)}>
                            <AvatarByName avatar={customer.avatar} />
                            <div className="content">
                                <h3 className="name">{customer.label}</h3>
                                <p className="address">{customer.desc}</p>
                            </div>
                        </div>
                        <div className="i-right" onClick={() => handleOnClickRowFolder(customer)}>
                            <div className="v2-btn-default --icon-lg --transparent icon-job-list tooltip svg-folder-yellow">
                                <div className="tooltiptext top">{t(`header:job_list`)}</div>
                                <IconFolder />
                            </div>
                        </div>
                    </div>
                );
            });
        };

        const renderNotFoundContent = () => {
            if (finalCustomers.length === 0 && finalKeyword.length !== 0 && !finalLoading) {
                return (
                    <div className="items w-100 pointer-events-none">
                        <p className="black px-2 fs-13">{t('header:search_not_match')}</p>
                    </div>
                );
            }

            return null;
        };

        const renderLoadMoreContent = () => {
            if (!finalShowMore) return null;

            return (
                <div onClick={handleLoadMore} className="items --loadmore" id="searchLoadmore">
                    <div className="v2-btn-default --noborder --label">
                        {finalLoading ? <IconLoading /> : t('header:load_more')}
                    </div>
                </div>
            );
        };

        return (
            <div id="globalSearch" className={`search-dropdown ${classActive}`}>
                <div className="scrolls">
                    {renderListCustomers()}
                    {renderNotFoundContent()}
                    {renderLoadMoreContent()}
                </div>
            </div>
        );
    };

    const renderRightIcon = () => {
        if (finalLoading) {
            return (
                <span id="show_icon_loading" className="closetxt">
                    <IconLoading />
                </span>
            );
        }

        return (
            <span
                className={`closetxt ${finalKeyword.length !== 0 ? 'is-typing' : ''}`}
                style={{ display: 'block' }}
                onClick={handleOnClickButtonClose}
            >
                <IconCircleClose />
            </span>
        );
    };

    return (
        <form
            ref={refSearch}
            autoComplete="off"
            className={classNames(classActive, 'search-form relative', {
                'is-disable': isExpired
            })}
            onSubmit={(e) => e.preventDefault()}
        >
            <span className="svg-search-absolute" onClick={handleOnClickIconSearch}>
                <IconSearch />
            </span>
            <input
                ref={refInputSearch}
                id="search-ip"
                className="search-ip"
                onClick={handleOnClickIconSearch}
                type="text"
                name="term"
                placeholder={t('header:search')}
                onChange={handleOnChangeInput}
                onBlur={_showShorcut}
            />

            {renderRightIcon()}

            <span id="shortcut_alts" className="key absolute">
                {isIOS ? 'OPTION+S' : 'ALT+S'}
            </span>

            {renderSearchDropdown()}
        </form>
    );
}

export default SearchHeader;

import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { GET_SURCHARGE_METHOD, getListCard } from 'app/const/Api';
import { METHOD_API } from 'app/const/App';
import { PAYMENT_CARD_CUSTOMER, TYPE_CARD_ID } from 'app/const/Customers';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import { METHOD_GET_SURCHARGE } from 'app/modules/jobdetail/const/Invoice';
import { AddPaymentContext } from 'app/modules/jobdetail/contexts/AddPaymentContext';
import IconDropDown from 'assets/icon/IconDropDown';
import IconLoading from 'assets/icon/IconLoading';
import { clientQuery } from 'common/utils/ApiUtils';

export default function Cards({ customerId }) {
    const { t } = useTranslation();
    const { addPayment, updateNumberPaymentContext, updatePaymentDataContext } = useContext(AddPaymentContext);
    const paymentSelectedId = addPayment.paymentSelected.id;
    const typeCard = TYPE_CARD_ID[paymentSelectedId];
    const isPaymentACH = paymentSelectedId === PAYMENT_CARD_CUSTOMER.ACH;

    const [state, setState] = useReducer(reducer, {
        isVisible: false,
        isLoading: true
    });

    const refDropdown = useRef(null);
    const { isVisible: finalIsVisible, isLoading } = state;
    const cardSelected = addPayment.cardSelected;
    const listCards = addPayment.listCard || [];

    useEffect(() => {
        if (!isLoading) {
            setState({
                isLoading: true
            });
        }
        typeCard && customerId && _getSurchargeMethod(METHOD_GET_SURCHARGE[paymentSelectedId]);
    }, [typeCard, customerId]);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_list_customer_cards');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_list_customer_cards');

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    function _getListCardSuccess(response) {
        const responseData = response.data;
        const cardDefault = responseData.find((item) => item.is_default) || responseData?.[0];

        setState({
            isLoading: false
        });

        updatePaymentDataContext({
            cardSelected: cardDefault || null,
            listCard: responseData
        });
    }

    function _getSurchargeMethod(method) {
        clientQuery(GET_SURCHARGE_METHOD, { method: 'GET', data: { method: method } }, _getSurchargeMethodSuccess);
    }

    function _getSurchargeMethodSuccess({ data }) {
        customerId &&
            clientQuery(
                getListCard(customerId),
                { method: 'GET', data: { keyword: typeCard } },
                _getListCardSuccess,
                _getListCardFailed
            );

        updateNumberPaymentContext({
            surchargeData: data.rate_taxes,
            activeSurcharge: data.active_surcharge
        });
    }

    function _getListCardFailed() {
        setState({
            isLoading: false
        });
    }

    const _handleOpen = (e) => {
        e.stopPropagation();
        setState({ isVisible: !finalIsVisible });
    };

    function _handleSelectCard(card, isActive) {
        if (isActive) {
            return false;
        }

        updatePaymentDataContext({
            cardSelected: card
        });

        setState({
            isVisible: false
        });
    }

    const _renderExp = ({ month, year }) => {
        return !!month && !!year ? `${month}/${year}` : '';
    };

    function _renderListCards() {
        if (!isLoading && listCards.length === 0) {
            return (
                <li className="items">
                    <p className="txt-ellipsis">{t('jobDetail:list_card_empty')}</p>
                </li>
            );
        }

        return listCards.map((item) => {
            const isActive = cardSelected && item.id === cardSelected?.id;
            return (
                <li
                    onClick={() => _handleSelectCard(item, isActive)}
                    key={item.id}
                    className={`items ${isActive ? 'active' : ''}`}
                >
                    <p className="txt-ellipsis">{`${
                        !isPaymentACH ? item.account_type : item.account_name
                    } ${METHOD_API} ${item.account_number} ${_renderExp({
                        month: item.exp_month,
                        year: item.exp_year
                    })}`}</p>
                </li>
            );
        });
    }

    if (isLoading && customerId) {
        return (
            <div className={`v2-dropdown select-credit-card`}>
                <IconLoading />
            </div>
        );
    }

    if (listCards.length === 0 || !!!customerId) {
        return false;
    }

    return (
        <div ref={refDropdown} className={`v2-dropdown select-credit-card ${finalIsVisible ? 'active' : ''}`}>
            <div className="dropbtn items selection" onClick={(e) => _handleOpen(e)}>
                <span className="txt-ellipsis">
                    {cardSelected
                        ? `${isPaymentACH ? cardSelected.account_name : cardSelected.account_type} ${METHOD_API} ${
                              cardSelected.account_number
                          } ${_renderExp({
                              month: cardSelected.exp_month,
                              year: cardSelected.exp_year
                          })}`
                        : t('jobDetail:list_card_empty')}
                </span>
                <span className="svg-selectbox">
                    <IconDropDown />
                </span>
            </div>
            <div id={'show_list_customer_cards'} className="v2-dropdown__menu scrolls">
                <ul>{_renderListCards()}</ul>
            </div>
        </div>
    );
}

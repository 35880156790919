import loadable from '@loadable/component';
import { URL_EXPORT_PAYMENT_REVENUES_STAFF, URL_EXPORT_REVENUES_STAFF } from 'app/const/api/Export';
import { COMMON, CURRENT_MONTH_SHORT_NAME, KEY_REPORT_LOCAL_STORAGE, TOTAL_BILLED } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { LIST_EXPORT } from 'app/const/report/Common';
import {
    getColumnsParams,
    PAYMENT_METHODS,
    REVENUE_BY_STAFF_LIST_FILER,
    REVENUE_BY_STAFF_TABS,
    TOTAL_COLLECTED,
    YEAR
} from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { DetailTabGridView, handleExportDetailTab } from 'app/modules/report/revenuebystaff/components/DetailsTab';
import { handleExportMonthTab, MonthTabGridView } from 'app/modules/report/revenuebystaff/components/MouthTab';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import {
    getListReportRevenueByStaffDetail,
    getListReportRevenueByStaffMonth
} from 'common/redux/actions/reports/revenueByStaffAction';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import moment from 'moment';
import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import MainHeaderReport from 'app/modules/report/components/MainHeader';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { getLocalParamsReport, removeObjParams } from 'common/utils/ReportUtils';
import HeaderBottom from '../components/HeaderBottom';

const Export = loadable(() => import('app/modules/report/components/Export'));
const ReportTabs = loadable(() => import('app/modules/report/components/ReportTabs'));

const MONTH_TAB = REPORT_TYPE.REVENUE_BY_STAFF_MONTH_TAB;
const DETAIL_TAB = REPORT_TYPE.REVENUE_BY_STAFF_DETAIL_TAB;

const keyTotalRow = {
    [MONTH_TAB]: 'total_customer',
    [DETAIL_TAB]: 'total_payments_received'
};

function ReportRevenueByStaff() {
    const { t } = useTranslation(['report']);
    const dispatch = useDispatch();

    const keyCurrentTab = COMMON.CURRENT_TAB.concat('_', REPORT_TYPE.REVENUE_BY_STAFF);
    const keyLocalStore = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.REVENUE_BY_STAFF);

    const [dataReport, dispatchActionReport] = useReducer(reducer, {
        rerender: false,
        reloadFilter: false,
        [MONTH_TAB]: {
            data: [],
            total: 0,
            rowTotal: [],
            isLoading: true
        },
        [DETAIL_TAB]: {
            data: [],
            total: 0,
            rowTotal: [],
            isLoading: true
        },
        refreshScreen: 0,
        currentTab: getLocalStorage(keyCurrentTab) || MONTH_TAB
    });

    const paramsReport = getLocalParamsReport(keyLocalStore, REPORT_TYPE.REVENUE_BY_STAFF);

    const { currentTab, rerender, refreshScreen } = dataReport;

    useEffect(() => {
        getListReport(paramsReport);
        setLocalStorage(keyCurrentTab, currentTab);
        if (!rerender) dispatchActionReport({ rerender: currentTab === MONTH_TAB });
    }, [currentTab]);

    useEffect(() => {
        return () => {
            removeLocalStorage(keyCurrentTab);
        };
    }, []);

    const getListReport = (params) => {
        !dataReport[currentTab]?.isLoading && dispatchActionReport({ [currentTab]: { isLoading: true } });

        delete params?.currentPage;
        if (params.sale === TOTAL_BILLED) delete params?.payment_methods;

        let objectParams = { ...params };

        switch (currentTab) {
            case MONTH_TAB:
                objectParams.order = objectParams['orderMonth'];
                objectParams = removeObjParams(objectParams, [
                    'orderMonth',
                    'orderDetail',
                    'start',
                    'end',
                    'payment_for'
                ]);

                return dispatch(
                    getListReportRevenueByStaffMonth(
                        objectParams,
                        (response) => getListSuccess(response, params),
                        getListFailed
                    )
                );
            case DETAIL_TAB:
                objectParams.order = objectParams['orderDetail'];
                objectParams = removeObjParams(objectParams, ['orderMonth', 'orderDetail', 'year', 'customer_id']);

                return dispatch(
                    getListReportRevenueByStaffDetail(
                        objectParams,
                        (response) => getListSuccess(response, params),
                        getListFailed
                    )
                );
            default:
                break;
        }
    };

    const getListSuccess = (response) => {
        const dataReducer = {};

        dataReducer[currentTab] = {};
        dataReducer[currentTab].isLoading = false;
        dataReducer[currentTab].data = response.data;
        dataReducer[currentTab].total = response.data.length;
        dataReducer[currentTab].rowTotal = createRowTotal(response[keyTotalRow[currentTab]], response.data.length);
        dataReducer.refreshScreen = refreshScreen + 1;

        dispatchActionReport(dataReducer);
    };

    const createRowTotal = (data, total) => {
        const totalItem = total || dataReport[currentTab].total;
        const columns = getColumnsParams(currentTab);
        const totalColumns = columns.map((column) => {
            if (typeof data?.[column] !== 'undefined') {
                return {
                    id: column,
                    isShow: true,
                    totalAmount: data[column],
                    isCurrency: true
                };
            }
            return { id: column, isShow: true };
        });
        totalColumns[0].title =
            currentTab === MONTH_TAB
                ? t('report:total_rows', {
                      number: totalItem,
                      title: t('report:staffs')
                  })
                : t('report:total_payments', { number: totalItem });
        return totalColumns;
    };

    function getListFailed() {
        dispatchActionReport({ [currentTab]: { isLoading: false } });
    }

    const handleChangeFilter = (params, type) => {
        if (type === 'year') {
            const newParamsReport = { ...params };
            const selectDate = `${CURRENT_MONTH_SHORT_NAME} ${newParamsReport.year}`;
            newParamsReport.start = moment(selectDate).clone().startOf('month').format();
            newParamsReport.end = moment(selectDate).clone().endOf('month').format();
            setLocalStorage(keyLocalStore, newParamsReport);
        }

        dispatchActionReport({ reloadFilter: params.sale === TOTAL_BILLED, rerender: params.sale === TOTAL_COLLECTED });
    };

    const _handleUpdate = () => {
        const params = getLocalStorage(keyLocalStore) || paramsReport;
        params.payment_methods = params?.payment_methods?.toString() || '';
        getListReport(params);
    };

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: REPORT_TYPE.REVENUE_BY_STAFF,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleUpdate,
            keyOrder: currentTab === DETAIL_TAB ? 'orderDetail' : 'orderMonth'
        });
    };

    const _getNewListFilter = () => {
        let result = [...REVENUE_BY_STAFF_LIST_FILER];
        const params = paramsReport;
        const currentTab = getLocalStorage(keyCurrentTab);
        if (params.sale === TOTAL_BILLED) result = result.filter((filterUnit) => filterUnit !== PAYMENT_METHODS);
        if (currentTab === DETAIL_TAB) result = result.filter((filterUnit) => filterUnit !== YEAR);
        return result;
    };

    function _renderHeaderLeft() {
        return <ReportTabs list={REVENUE_BY_STAFF_TABS} onChange={handleChangeTab} tabActive={currentTab} />;
    }

    function _renderHeaderBottom() {
        const LIST_FILTERS = _getNewListFilter();
        return (
            <HeaderBottom
                classNameHeader="header --filter"
                forceRerenderFilter={rerender}
                typeReport={REPORT_TYPE.REVENUE_BY_STAFF}
                filters={LIST_FILTERS}
                handleChangeFilter={handleChangeFilter}
                handleUpdate={_handleUpdate}
                isLoading={dataReport[currentTab].isLoading}
                isNotShowDateRange={currentTab === MONTH_TAB}
            />
        );
    }

    function handleOnClickExport(e, isCsv, actionPrint) {
        e && e.preventDefault();

        switch (currentTab) {
            case MONTH_TAB:
                return handleExportMonthTab(isCsv, actionPrint, paramsReport);
            case DETAIL_TAB:
                return handleExportDetailTab(isCsv, actionPrint, paramsReport);
            default:
                break;
        }
    }

    const handleChangeTab = (tab) => {
        dispatchActionReport({ currentTab: tab });
    };

    const _handleOpenInvoice = (data) => {
        dispatch(
            actionOpenInvoice({
                id: data?.row?.invoice_id,
                status: '',
                total: '',
                isRecurring: false
            })
        );
    };

    function _getDataExport() {
        let paramsExport = { ...paramsReport };
        switch (currentTab) {
            case MONTH_TAB:
                paramsExport.order = paramsExport['orderMonth'];
                paramsExport = removeObjParams(paramsExport, [
                    'orderMonth',
                    'orderDetail',
                    'start',
                    'end',
                    'payment_for'
                ]);
                return {
                    urlExport: URL_EXPORT_REVENUES_STAFF,
                    pageExport: LIST_EXPORT.EXPORT_REPORT_REVENUE_STAFF,
                    paramsExport
                };
            case DETAIL_TAB:
                paramsExport.order = paramsExport['orderDetail'];
                paramsExport = removeObjParams(paramsExport, ['orderMonth', 'orderDetail', 'year']);
                return {
                    urlExport: URL_EXPORT_PAYMENT_REVENUES_STAFF,
                    pageExport: LIST_EXPORT.EXPORT_REPORT_REVENUE_STAFF_DETAIL,
                    paramsExport
                };
            default:
                return {};
        }
    }

    const _renderGirdView = () => {
        switch (currentTab) {
            case MONTH_TAB:
                return (
                    <MonthTabGridView
                        dataReport={dataReport[MONTH_TAB]}
                        paramsReport={paramsReport}
                        handleActionHeader={_handleActionHeader}
                        handleOpenInvoice={_handleOpenInvoice}
                        monthTab={currentTab}
                    />
                );
            case DETAIL_TAB:
                return (
                    <DetailTabGridView
                        dataReport={dataReport[DETAIL_TAB]}
                        paramsReport={paramsReport}
                        handleActionHeader={_handleActionHeader}
                        handleOpenInvoice={_handleOpenInvoice}
                    />
                );
            default:
                break;
        }
    };

    function _renderHeaderRight() {
        const dataExport = _getDataExport();
        const totalReport = dataReport[currentTab]?.total || 0;
        return (
            <Export
                title={t('report:records', { count: totalReport })}
                activePrint
                onClick={handleOnClickExport}
                url={dataExport.urlExport}
                params={dataExport.paramsExport}
                pageExport={dataExport.pageExport}
                isDisable={dataReport[currentTab].isLoading}
                refresh={refreshScreen}
            />
        );
    }

    return (
        <>
            <MainHeaderReport
                contentLeft={_renderHeaderLeft}
                contentRight={_renderHeaderRight}
                reportType={REPORT_TYPE.REVENUE_BY_STAFF}
                onSelectTab={_handleUpdate}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages has-tab gap-8">
                    <div className="wrap-tables flex-column relative">
                        {_renderHeaderBottom()}
                        <div className="tab-contents">{_renderGirdView()}</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportRevenueByStaff;

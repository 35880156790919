import { DEVICE_SESSION_TYPES } from 'app/modules/settings/users/const';

const IconDeviceSession = ({ type }) => {
    switch (type) {
        case DEVICE_SESSION_TYPES.DESKTOP:
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.5 5.5H16.5C17.6046 5.5 18.5 6.39543 18.5 7.5V12.5C18.5 13.6046 17.6046 14.5 16.5 14.5H6.5C5.39543 14.5 4.5 13.6046 4.5 12.5V7.5C4.5 6.39543 5.39543 5.5 6.5 5.5Z"
                        stroke="var(--color-icon)"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    ></path>
                    <path d="M11.5 18.5V14.5" stroke="var(--color-icon)" strokeWidth="1.3" strokeLinecap="round"></path>
                    <path d="M9.5 18.5H13.5" stroke="var(--color-icon)" strokeWidth="1.3" strokeLinecap="round"></path>
                </svg>
            );
        case DEVICE_SESSION_TYPES.TABLET:
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.5 16.5H13.5" stroke="var(--color-icon)" strokeWidth="1.3" strokeLinecap="round"></path>
                    <rect
                        x="4.5"
                        y="5.5"
                        width="15"
                        height="13"
                        rx="2"
                        stroke="var(--color-icon)"
                        strokeWidth="1.3"
                    ></rect>
                </svg>
            );
        case DEVICE_SESSION_TYPES.MOBILE:
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.5 16.5H12.5" stroke="var(--color-icon)" strokeWidth="1.3" strokeLinecap="round"></path>
                    <rect
                        x="7.5"
                        y="4.5"
                        width="9"
                        height="14"
                        rx="2"
                        stroke="var(--color-icon)"
                        strokeWidth="1.3"
                    ></rect>
                </svg>
            );
        default:
            return null;
    }
};

export default IconDeviceSession;

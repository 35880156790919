import classNames from 'classnames';
import { useId } from 'react';

import { numberItemChecked } from 'app/modules/customer/utils';

const CheckBoxHeader = ({
    checkedItems,
    isDisableEmptyIds = true,
    isShowTotal = false,
    total = 0,
    onUncheckAll = null
}) => {
    const id = useId();
    const { is_check_all, ids } = checkedItems || {};
    const idsSelected = ids?.length || 0;

    return isShowTotal ? (
        <div
            className={classNames('header-items check-items flexcenter', {
                '--uncheck': !is_check_all,
                'is-disable': isDisableEmptyIds && !idsSelected
            })}
        >
            <input id={id} type="checkbox" checked={idsSelected} readOnly={!onUncheckAll} onChange={onUncheckAll} />
            <div className="item-checkbox">
                <label htmlFor={id}>
                    <span />
                </label>
            </div>
            <span className="flexcenter black fw-600">
                <span className="black-darker2">{idsSelected}</span>
                <span>/{total}</span>
            </span>
        </div>
    ) : (
        <div
            className={classNames('header-items check-items', {
                '--uncheck': !is_check_all,
                'is-disable': !idsSelected
            })}
        >
            <input id={id} type="checkbox" checked={idsSelected} readOnly />
            <div className="item-checkbox">
                <label htmlFor={id}>
                    {idsSelected} {numberItemChecked(idsSelected)}
                </label>
            </div>
        </div>
    );
};
export default CheckBoxHeader;

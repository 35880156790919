import { METHOD_CONVERT } from 'app/const/App';
import { PAYMENT_CARD_CUSTOMER } from 'app/const/Customers';
import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { CustomerDetailContext } from 'app/modules/customer/detail/context/CustomerDetailContext';
import IconArrowDown from 'assets/icon/IconArrowDown';
import classNames from 'classnames';
import React, { useContext, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionContext } from '../../context/SubscriptionContext';
import CardDate from 'app/components/payment/components/CardDate';

const BillingContent = ({ payments, paymentDefault, getDefaultPayment }) => {
    const { t } = useTranslation('customers');
    const { isSquare, selectedPlans, price, billing, _handleChangeBilling, billingNumber, _handleChangeBillingNumber } =
        useContext(SubscriptionContext);

    const _handleChange = (e) => {
        _handleChangeBilling({ isAutomatically: e.target.name === 'automatically' });
    };

    const _onChangeBillingNumber = (e) => _handleChangeBillingNumber(e.target.value);

    if (!selectedPlans.length) return null;
    return (
        <div className="billing-content js-changed-active">
            <div className="title-x-sm">{t('common:billing')}</div>
            <div className={classNames('rows', { active: !billing })}>
                <div className="check-radio">
                    <input
                        id="billing_automatically"
                        type="radio"
                        name="automatically"
                        checked={!billing}
                        onChange={_handleChange}
                    />
                    <label
                        htmlFor="billing_automatically"
                        className="check-radio__label js-selected-box active"
                        data-after="Set as Default"
                        data-after-selected="Default"
                    >
                        {t('common:add_billing_desc')}
                    </label>
                </div>
                <div className="desc info-card">
                    <CardSelect
                        reportPayments={payments}
                        paymentDefault={paymentDefault}
                        getDefaultPayment={getDefaultPayment}
                    />
                </div>
            </div>
            <div className={classNames('rows pay-manually', { active: billing })}>
                <div className="check-radio">
                    <input
                        id="billing_manually"
                        type="radio"
                        name="manually"
                        checked={billing}
                        onChange={_handleChange}
                    />
                    <label
                        htmlFor="billing_manually"
                        className="check-radio__label js-selected-box active"
                        data-after="Set as Default"
                        data-after-selected="Default"
                    >
                        {t('common:email_invoices_for_customer')}
                    </label>
                </div>
                {!isSquare ? (
                    <div className="desc pay-txt">
                        {t('common:elibible_invoices')}{' '}
                        <a
                            href="https://stripe.com/docs/subscriptions/ach-credit"
                            target="_blank"
                            className="purple-default"
                            rel="noreferrer"
                        >
                            {t('common:auto_recociled')}
                        </a>
                        <div className="pay-day">
                            {t('common:payment_due')}
                            <input
                                className="field-input"
                                type="number"
                                placeholder={0}
                                value={billingNumber}
                                onChange={_onChangeBillingNumber}
                            />
                            {t('common:day_after_invoices')}
                        </div>
                    </div>
                ) : null}
            </div>
            <div className="subscription-total">{t('bill_subscription_total', { bill: price })}</div>
        </div>
    );
};

const CardSelect = ({ reportPayments, paymentDefault, getDefaultPayment }) => {
    const { isSquare, _handleChangeCard } = useContext(SubscriptionContext);
    const { payments = [], customer = {} } = useContext(CustomerDetailContext) || {};
    const refDropdown = useRef(null);

    const _handleCardName = (card) => `${card.account_type} ${METHOD_CONVERT} ${card.account_number}`;
    const paymentMethod = payments.length ? payments : reportPayments;

    const _preData = () => {
        if (!isSquare) {
            const paymentCard =
                paymentDefault ||
                customer?.payment_card ||
                paymentMethod.find((card) => {
                    return !!card.is_default && card.name.toLowerCase() === PAYMENT_CARD_CUSTOMER.STRIPE;
                });
            return { paymentCard: paymentCard };
        }

        const options = [];
        paymentMethod.forEach((element) => {
            if (element.name.toLowerCase() === PAYMENT_CARD_CUSTOMER.SQUARE)
                options.push({ ...element, nameDisplay: _handleCardName(element) });
        });

        return {
            options,
            paymentCard:
                paymentDefault ||
                paymentMethod.find((card) => {
                    return !!card.is_default && card.name.toLowerCase() === PAYMENT_CARD_CUSTOMER.SQUARE;
                })
        };
    };
    const [state, dispatchState] = useReducer(reducer, _preData());
    const { paymentCard, options } = state;

    const _handleSelectCard = (card) => {
        const cardId = card.account_id;
        dispatchState({ paymentCard: card });
        getDefaultPayment && getDefaultPayment(cardId, paymentMethod);
        _handleChangeCard(cardId);
        refDropdown.current._closeDropdown();
    };

    if (!paymentCard) return null;
    if (!isSquare)
        return (
            <div className="card-number">
                <div className="number">
                    <p className="branch txt-ellipsis">{paymentCard.account_type}</p>
                    <p className="number__dots">{METHOD_CONVERT}</p>
                    <p className="number__digit txt-ellipsis">{paymentCard.account_number}</p>
                </div>
                <CardDate {...paymentCard} />
            </div>
        );

    return (
        <CalendarDropdown
            id="billing-card"
            ref={refDropdown}
            customDropButton={() => (
                <>
                    <div className="txt-ellipsis">
                        {paymentCard.account_type} {METHOD_CONVERT} {paymentCard.account_number}
                    </div>
                    <div className="arrow">
                        <IconArrowDown />
                    </div>
                </>
            )}
            dropdownIcon={<IconArrowDown />}
            wrapperClassName="v2-dropdown"
            wrapperListClass="v2-dropdown__menu scrolls"
            buttonClassName="dropbtn items"
            width={200}
            isUseToggle
        >
            <ListOptions options={options} paymentCard={paymentCard} onSelect={_handleSelectCard} />
        </CalendarDropdown>
    );
};

const ListOptions = ({ options = [], paymentCard, onSelect = () => {} }) => {
    return options.map((payment) => (
        <li
            key={payment.id}
            className={classNames('items', { active: paymentCard.account_id === payment.account_id })}
            onClick={() => onSelect(payment)}
        >
            <div className="txt-ellipsis">{payment.nameDisplay}</div>
        </li>
    ));
};

export default BillingContent;

import ButtonSave from 'app/components/button/ButtonSave';
import { customerPortal } from 'app/const/Api';
import { LIST_STATUS } from 'app/const/Status';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const CredentialsBox = ({ userName = '', customerId = '', onShowStatus = () => {} }, ref) => {
    const { t } = useTranslation();
    const ERRORS_DISPLAY = {
        user_name: t('customers:cannot_be_blank', { name: t('common:user_name') }),
        password: t('customers:cannot_be_blank', { name: t('common:password') })
    };

    const refButtonSave = useRef(null);
    const refForm = useRef(null);

    useImperativeHandle(ref, () => ({ removeLoading: () => refButtonSave.current.removeLoading() }));

    const _handleSubmit = (e) => {
        e && e.preventDefault();
        const elements = refForm.current.elements;
        const username = elements['username'].value;
        const password = elements['password'].value;
        const new_password = elements['new_password'].value;
        const data = {};
        const errors = [];

        for (let index = 0; index < elements.length; index++) {
            const element = elements[index];
            data[element.name] = element.value;

            if (!element.value.trim().length) {
                errors.push(ERRORS_DISPLAY[element.name]);
            }
        }

        if (password !== new_password) errors.push(t('customers:your_password_must_be_repeated_exactly'));

        if (errors.length) {
            onShowStatus({ id: 'update_login_failed', message: errors, type: LIST_STATUS.ERROR });
            refButtonSave.current.removeLoading();
        } else {
            const _handleSuccess = ({ message }) => {
                onShowStatus({ id: 'update_login_success', message, type: LIST_STATUS.SUCCESS });
            };

            const _handleError = ({ message }) => {
                onShowStatus({ id: 'update_login_error', message, type: LIST_STATUS.ERROR });
            };

            const _handleFinally = () => refButtonSave.current.removeLoading();

            clientQuery(
                customerPortal(customerId),
                { data: { username, password }, method: 'PUT' },
                _handleSuccess,
                _handleError,
                _handleFinally
            );
        }
    };

    return (
        <form ref={refForm} className="wrap-portal__box">
            <p className="mb-4">{t('customers:update_the_account_login')}</p>
            <div className="rows">
                <div className="rows__label">{t('customers:username')}</div>
                <div className="rows__info">
                    <input
                        name="username"
                        className="field-input"
                        type="text"
                        placeholder={t('customers:username')}
                        autoFocus
                        defaultValue={userName}
                        spellCheck
                    />
                </div>
            </div>
            <div className="row-haft flex-betweenitems">
                <div className="col-lg rows">
                    <div className="rows__label">{t('common:password')}</div>
                    <div className="rows__info">
                        <input
                            name="password"
                            type="password"
                            className="field-input"
                            placeholder="•••••••"
                            autoComplete="new-password"
                        />
                    </div>
                </div>
                <div className="col-lg rows mt-0">
                    <div className="rows__label">{t('customers:confirm_password')}</div>
                    <div className="rows__info">
                        <input
                            name="new_password"
                            type="password"
                            className="field-input"
                            placeholder="•••••••"
                            autoComplete="new-password"
                        />
                    </div>
                </div>
            </div>
            <div className="rows d-flex justify-end">
                <ButtonSave
                    ref={refButtonSave}
                    className="v2-btn-main"
                    title={t('setting:save_changes')}
                    onSave={_handleSubmit}
                />
            </div>
        </form>
    );
};

export default forwardRef(CredentialsBox);

import { capitalizeFirstLetter } from 'common/utils/StringUtils';
import React, { forwardRef, useContext, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SetupEmailContext } from '../../context/SetupEmailContext';
import EmailChoose from './EmailChoose';

const StepSetupSMTP = (props, ref) => {
    const { t } = useTranslation(['common', 'addons']);
    const refFormSMTP = useRef(null);
    const refFormImap = useRef(null);
    const { isAutomatically } = useContext(SetupEmailContext);
    useImperativeHandle(ref, () => ({ getValue: _handleGetValue }));

    const _handleGetValue = () => {
        const errors = [];
        const result = { smtp: {}, imap: {} };
        const elementsFormSMTP = refFormSMTP.current.elements;

        for (let index = 0; index < elementsFormSMTP.length; index++) {
            const { name, type, value, checked } = elementsFormSMTP[index];
            if (type === 'checkbox') result['smtp']['secure'] = checked ? 1 : 0;
            if (type === 'text' || type === 'password') {
                result['smtp'][name] = value;
                if (!value.trim().length)
                    errors.push(
                        t('addons:value_can_not_blank', { name: `${t('addons:smtp')} ${capitalizeFirstLetter(name)}` })
                    );
            }
        }

        if (isAutomatically) {
            const elementsFormImap = refFormImap.current.elements;
            for (let index = 0; index < elementsFormImap.length; index++) {
                const { name, type, value, checked } = elementsFormImap[index];
                if (type === 'text' || type === 'password') result['imap'][name] = value;
                if (type === 'checkbox') result['imap']['secure'] = checked ? 1 : 0;
                if (!value.trim().length)
                    errors.push(
                        t('addons:value_can_not_blank', { name: `${t('addons:imap')} ${capitalizeFirstLetter(name)}` })
                    );
            }
        }

        return { ...result, error: errors };
    };

    return (
        <div className="step scrolls show">
            <div className="row">
                <p>{t('addons:enter_your_email')}</p>
                <p className="fs-12 btn-hover-black">
                    {t('addons:we_take_the')}{' '}
                    <a href="#" className="purple-default">
                        {t('addons:security_and_privacy')}
                    </a>{' '}
                    {t('addons:of_your_data_very_seriously')}
                </p>
            </div>
            <EmailChoose />
            <div className="row --multi outgoing-email">
                <form ref={refFormSMTP} className="col --lg">
                    <h3 className="title">{t('addons:smtp_email')}</h3>
                    <span className="txt">{t('addons:host')}</span>
                    <input name="host" className="field-input" placeholder={t('addons:host')} type="text" />
                    <span className="txt">{t('common:username')}</span>
                    <input name="user" className="field-input" placeholder={t('common:username')} type="text" />
                    <span className="txt">{t('common:password')}</span>
                    <input name="password" className="field-input" placeholder={t('common:password')} type="password" />
                    <div className="check-items">
                        <input id="setup_outgoing_email" type="checkbox" defaultChecked />
                        <div className="item-checkbox">
                            <label htmlFor="setup_outgoing_email">{t('addons:secure_ssl_tls')}</label>
                        </div>
                    </div>
                </form>
                {isAutomatically && (
                    <form ref={refFormImap} className="col --lg">
                        <h3 className="title">{t('addons:imap_2_way_email_sync')}</h3>
                        <span className="txt">{t('addons:host')}</span>
                        <input name="host" className="field-input" placeholder={t('addons:host')} type="text" />
                        <span className="txt">{t('common:username')}</span>
                        <input name="user" className="field-input" placeholder={t('common:username')} type="text" />
                        <span className="txt">{t('common:password')}</span>
                        <input
                            name="password"
                            className="field-input"
                            placeholder={t('common:password')}
                            type="password"
                        />
                        <div className="check-items is-disable">
                            <input id="setup_email_sync" type="checkbox" defaultChecked />
                            <div className="item-checkbox">
                                <label htmlFor="setup_email_sync">{t('addons:secure_ssl_tls')}</label>
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default forwardRef(StepSetupSMTP);

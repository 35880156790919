import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';

const SMSAddonsInformation = ({ data }) => {
    const { t, i18n } = useTranslation();
    const lastMonthName = moment().subtract(1, 'month').locale(i18n.language).startOf('month').format('MMM');
    return (
        <div className="wrap-process --hasline">
            <div className="rows">
                <div className="rows__label flex-1">{t('common:active_sms_phone_number')}</div>
                <div className="rows__number phone">
                    {data?.phone_number && formatPhoneNumberVoip(data.phone_number)}
                </div>
            </div>
            <div className="rows">
                <div className="rows__label">{t('common:sms_remaining')}</div>
                <div className="rows__number">{data?.sms_amount || 0}</div>
            </div>
            <div className="rows">
                <div className="rows__label">{t('common:usage_this_month')}</div>
                <div className="rows__number">{data?.sms_usage_this_month || 0}</div>
            </div>
            <div className="rows">
                <div className="rows__label">
                    {t('common:usage_last_month')} ({lastMonthName})
                </div>
                <div className="rows__number">{data?.sms_usage_last_month || 0}</div>
            </div>
        </div>
    );
};

export default SMSAddonsInformation;

import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';

import { UPDATE_ADDONS_EMAIL_ACCOUNT } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import ButtonSave from 'app/modules/jobdetail/tabs/unit/content/editunit/ButtonSave';
import IconPen from 'assets/icon/IconPen';
import { clientQuery } from 'common/utils/ApiUtils';
import { validateEmail } from 'common/utils/EmailUtils';

const EditFormModal = forwardRef(({ onUpdateSuccess = () => {} }, ref) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, email: '', selectedName: '', options: [] });
    const users = useSelector(({ companyUsers }) => companyUsers.users);
    const refDropdown = useRef(null);
    const refEmail = useRef(null);
    const refButtonSave = useRef(null);
    const refAlert = useRef(null);
    const { email, selectedName, options, isOpen } = state;

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = (dataUpdate) => {
        let selectedName = '';
        const options = users.map((item) => {
            const full_name = `${item.first_name} ${item.last_name}`;
            if (full_name === dataUpdate.name) selectedName = full_name;
            return { ...item, full_name };
        });

        dispatchState({ isOpen: true, type: dataUpdate.type, email: dataUpdate.email, selectedName, options });
    };

    const _close = () => {
        dispatchState({ isOpen: false, email: '', selectedName: '', options: [] });
    };

    const _handleSelect = (value) => dispatchState({ selectedName: value });

    const _handleSaveForm = () => {
        const email = refEmail.current.value;

        if (!validateEmail(email)) {
            refButtonSave.current.removeLoading();
            refAlert.current.showStatusBar({
                id: 'fail',
                message: t('common:email_is_not_a_valid_email_address'),
                type: LIST_STATUS.ERROR
            });
            return;
        }
        const _handleUpdateSuccess = ({ data, message }) => {
            onUpdateSuccess(data, message);
            _close();
        };
        const _handleUpdateFinally = () => refButtonSave.current.removeLoading();
        const _handleUpdateFail = ({ message }) => {
            refAlert.current && refAlert.current.showStatusBar({ id: 'fail', message, type: LIST_STATUS.ERROR });
        };
        clientQuery(
            UPDATE_ADDONS_EMAIL_ACCOUNT,
            {
                data: {
                    name: selectedName,
                    email,
                    account_type: state.type
                },
                toFormData: false,
                method: 'PUT'
            },
            _handleUpdateSuccess,
            _handleUpdateFail,
            _handleUpdateFinally
        );
    };

    if (!isOpen) return null;
    return (
        <ReactModal
            id="edit_form_address"
            isOpen={isOpen}
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal --edit-address open"
            onRequestClose={_close}
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className="modal__container small">
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('addons:edit_from_address')}</h3>
                </div>
                <div className="body-modal">
                    <AlertCustomer ref={refAlert} />
                    <div className="row">
                        <span className="txt">{t('common:name')}</span>
                        <CalendarDropdown
                            ref={refDropdown}
                            id="dropdown-select-form-user"
                            options={options}
                            selected={selectedName}
                            selectedOption={selectedName}
                            keyGetKey="id"
                            keyGetName="full_name"
                            keyGetValue="full_name"
                            onSelect={_handleSelect}
                        />
                    </div>
                    <div className="row">
                        <span className="txt">{t('common:email_address')}</span>
                        <input
                            ref={refEmail}
                            className="field-input"
                            type="text"
                            name="email"
                            placeholder={t('common:email_address')}
                            defaultValue={email || ''}
                            autoFocus
                        />
                    </div>
                </div>
                <div className="footer-modal btn-close">
                    <span className="v2-btn-default --transparent" onClick={_close}>
                        {t('common:cancel')}
                    </span>
                    <ButtonSave ref={refButtonSave} onClick={_handleSaveForm} />
                </div>
            </div>
        </ReactModal>
    );
});

const EditFromAddress = ({ name, email, type = 'google', onUpdateSuccess = () => {} }) => {
    const { t } = useTranslation();
    const refModal = useRef(null);

    const _handleEdit = () => {
        refModal.current._open({ name, email, type });
    };
    return (
        <>
            <div className="v2-btn-default --purple has-icon svg-purple ml-2 mb-2 btn-modal" onClick={_handleEdit}>
                <IconPen />
                {t('addons:edit_from_address_with_special_character')}
            </div>
            <EditFormModal ref={refModal} onUpdateSuccess={onUpdateSuccess} />
        </>
    );
};

export default EditFromAddress;

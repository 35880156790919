import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import MessageListTags from './MessageListTags';
import MessageEmoji from './MessageEmoji';
import TemplateOptions from 'app/components/templatenotes';
import { TEMPLATE_KEYWORD } from 'app/const/Notes';
import { convertHtmlToPlaintext } from 'app/modules/settings/utils';
import CustomAttachment from 'app/components/richtext/components/CustomAttachment';

function MessageActions(
    {
        customerName,
        onAddTags = () => {},
        onAddEmoji = () => {},
        onAddAttachment = () => {},
        onClick = () => {},
        onSubmit = () => {},
        isUnknow
    },
    ref
) {
    const { t } = useTranslation(['header']);
    const refInput = useRef(null);

    useImperativeHandle(ref, () => ({
        setInputValue: (value) => (refInput.current.value = value),
        getValue: () => refInput.current.value
    }));

    const _handleSelectTemplate = ({ content, attachments }) => {
        onAddTags({ value: convertHtmlToPlaintext(content), attachments }, true);
    };

    return (
        <div className="footer-action">
            <input
                ref={refInput}
                type="text"
                className="input-ft"
                value={refInput.current?.value || ''}
                placeholder={`${t('report:message')} ${customerName || ''}…`}
                readOnly
                onClick={(e) => onClick(e, true)}
            />

            <div className="btn-action">
                <TemplateOptions
                    isShow
                    isShowOnlyIcon
                    wrapperClassName="v2-dropup dropdown-temp"
                    btnClassName="dropbtn"
                    contentClassName="v2-dropdown__menu content-search --right"
                    isShowFilter={false}
                    typeTemplate={TEMPLATE_KEYWORD.SMS}
                    itemClassName="dashboard-wrapper items justify-space-between items-template"
                    strategy="absolute"
                    isShowSetDefault={false}
                    onSelectTemplate={_handleSelectTemplate}
                />
                <MessageListTags onAddTags={onAddTags} isUnknow={isUnknow} />
                <MessageEmoji onAddEmoji={onAddEmoji} />
                <CustomAttachment onInsert={onAddAttachment} />
                <div className="btn-send">
                    <div onClick={onSubmit} className="v2-btn-main">
                        {t('header:send')}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default forwardRef(MessageActions);

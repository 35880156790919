import { ADDONS_QUICKBOOKS_SYNC, ADDONS_SMS, ADDONS_INBOX_EMAIL, ADDONS_AI } from 'app/config/routes';
import IconAddonDocument from 'assets/icon/IconAddonDocument';
import IconAddonGps from 'assets/icon/IconAddonGps';
import IconAddonMaterial from 'assets/icon/IconAddonMaterial';
import IconBuilding from 'assets/icon/IconBuilding';
import IconCommission from 'assets/icon/IconCommission';
import IconDeviceTracking from 'assets/icon/IconDeviceTracking';
import IconEmailInbox from 'assets/icon/IconEmailInbox';
import IconOnlineBooking from 'assets/icon/IconOnlineBooking';
import IconOptimize from 'assets/icon/IconOptimize';
import IconPortal from 'assets/icon/IconPortal';
import IconQuickBook from 'assets/icon/IconQuickBook';
import IconSMS from 'assets/icon/IconSMS';
import IconSquare from 'assets/icon/IconSquare';
import IconStar from 'assets/icon/IconStar';
import IconStripe from 'assets/icon/IconStripe';
import IconTimeWindow from 'assets/icon/IconTimeWindow';
import IconTrigger from 'assets/icon/IconTrigger';
import IconZapier from 'assets/icon/IconZapier';
import IconAddonApi from 'assets/icon/IconAddonApi';
import IconPhone from 'assets/icon/IconPhone';
import moment from 'moment';
import {
    SMS_ADDONS_OPTIONS,
    UPDATE_ADDONS_COMMISSION_OPTIONS,
    UPDATE_ADDONS_DEVICE_TRACKING_OPTIONS,
    UPDATE_ADDONS_DOCUMENT_OPTIONS,
    UPDATE_ADDONS_GPS_TRACKING_OPTIONS,
    UPDATE_ADDONS_INBOX_EMAIL_OPTIONS,
    UPDATE_ADDONS_MATERIAL_OPTIONS,
    UPDATE_ADDONS_MULTI_UNIT_OPTIONS,
    UPDATE_ADDONS_ONLINE_BOOKING_OPTIONS,
    UPDATE_ADDONS_PORTAL_OPTIONS,
    UPDATE_ADDONS_QUICKBOOKS_SYNC,
    UPDATE_ADDONS_REVIEW_OPTIONS,
    UPDATE_ADDONS_ROUTING_OPTIONS,
    UPDATE_ADDONS_SQUARE_OPTIONS,
    UPDATE_ADDONS_STRIPE_OPTIONS,
    UPDATE_ADDONS_TRIGGERS_OPTIONS,
    UPDATE_ADDONS_API_KEY_OPTIONS,
    UPDATE_ADDONS_VOIP_OPTIONS,
    UPDATE_ADDONS_OPPORTUNITY_OPTIONS,
    UPDATE_ADDONS_SMARTVIEW_OPTIONS,
    UPDATE_ADDONS_WORK_LOG_OPTIONS,
    UPDATE_ADDONS_CUSTOM_FIELDS_OPTIONS,
    UPDATE_CUSTOM_JOB_STATUS_FIELDS_OPTIONS,
    UPDATE_ADDONS_ESTIMATE_OPTIONS,
    ADDONS_STRIPE_SURCHARGE as ENDPOINT_STRIPE_SURCHARGE,
    ADDONS_SQUARE_SURCHARGE as ENDPOINT_SQUARE_SURCHARGE,
    ADDONS_STRIPE_ACH_SURCHARGE as ENDPOINT_STRIPE_ACH_SURCHARGE,
    UPDATE_ADDONS_ADVANCE_ROUTING_OPTIONS,
    UPDATE_ADDONS_CHATBOT_AI_OPTIONS,
    UPDATE_ADDONS_SERVICE_PLAN_OPTIONS
} from '../Api';
import { UPDATE_ADDONS_TIME_WINDOW_OPTIONS } from 'app/const/api/V2';
import IconMultiBranch from 'assets/icon/IconMultiBranch';
import IconOpps from 'assets/icon/IconOpps';
import IconSmartView from 'assets/icon/IconSmartView';
import IconClockInOut from 'assets/icon/IconClockInOut';
import IconCustomFields from 'assets/icon/IconCustomFields';
import IconJobStatus from 'assets/icon/IconJobStatus';
import IconEstimate from 'assets/icon/IconEstimate';
import IconRoute from 'assets/icon/IconRoute';
import IconChatbot from 'assets/icon/IconChatbot';
import IconServicePlan from 'assets/icon/IconServicePlan';
import IconSentricon from 'assets/icon/IconSentricon';

export const LIST_MERCHANT_ADDONS = ['STRIPE', 'SQUARE'];

export const LIST_ICONS_ADDONS = {
    STRIPE: <IconStripe hasColor />,
    SQUARE: <IconSquare hasColor />,
    COMMISSION_TRACKING: <IconCommission />,
    CUSTOMER_PORTAL: <IconPortal isActive />,
    DEVICE_TRACKING: <IconDeviceTracking isGreen />,
    DOCUMENTS: <IconAddonDocument isGreen />,
    MATERIAL: <IconAddonMaterial isGreen />,
    GPS_TRACKING: <IconAddonGps isGreen />,
    QUICKBOOKS: <IconQuickBook hasColor />,
    REVIEW_ENGINE: <IconStar isGreen />,
    ROUTING: <IconOptimize isGreen />,
    SMSPLIVO: <IconSMS isGreen />,
    ZAPIER: <IconZapier />,
    MDU_TRACKING: <IconBuilding isAddonsGreen />,
    INBOX_EMAIL: <IconEmailInbox isGreen />,
    ONLINE_BOOKING: <IconOnlineBooking isGreen />,
    TRIGGERS: <IconTrigger isGreen />,
    TIME_WINDOW: <IconTimeWindow isGreen />,
    API_KEY: <IconAddonApi isGreen />,
    VOIP: <IconPhone isGreen />,
    MULTIPLE_BRANCH: <IconMultiBranch />,
    OPPORTUNITY: <IconOpps />,
    SMART_VIEWS: <IconSmartView isGreen />,
    WORK_LOG: <IconClockInOut type="addons" />,
    CUSTOM_FIELDS: <IconCustomFields type="addons" />,
    CUSTOM_JOB_STATUS: <IconJobStatus />,
    ESTIMATES: <IconEstimate isAddons />,
    ADVANCE_ROUTING: <IconRoute type="active" />,
    AI_AGENTS: <IconChatbot />,
    SERVICE_PLAN: <IconServicePlan />,
    sentricon: <IconSentricon />
};

export const LIST_ICONS_ADDONS_INACTIVE = {
    STRIPE: <IconStripe inactive />,
    SQUARE: <IconSquare inactive />,
    COMMISSION_TRACKING: <IconCommission inactive />,
    CUSTOMER_PORTAL: <IconPortal isGrey />,
    DEVICE_TRACKING: <IconDeviceTracking isGrey />,
    DOCUMENTS: <IconAddonDocument isGrey />,
    MATERIAL: <IconAddonMaterial isGrey />,
    GPS_TRACKING: <IconAddonGps isGrey />,
    QUICKBOOKS: <IconQuickBook isGrey />,
    REVIEW_ENGINE: <IconStar isGrey />,
    ROUTING: <IconOptimize isGrey />,
    SMSPLIVO: <IconSMS isAddonsGrey />,
    ZAPIER: <IconZapier isGrey />,
    MDU_TRACKING: <IconBuilding isAddonsGrey />,
    INBOX_EMAIL: <IconEmailInbox isGrey />,
    ONLINE_BOOKING: <IconOnlineBooking isPortal />,
    TRIGGERS: <IconTrigger isGrey />,
    TIME_WINDOW: <IconTimeWindow />,
    API_KEY: <IconAddonApi />,
    VOIP: <IconPhone isBlack />,
    MULTIPLE_BRANCH: <IconMultiBranch isGray />,
    OPPORTUNITY: <IconOpps isGrey />,
    SMART_VIEWS: <IconSmartView isGrey />,
    WORK_LOG: <IconClockInOut type="inactive" />,
    CUSTOM_FIELDS: <IconCustomFields type="default" />,
    CUSTOM_JOB_STATUS: <IconJobStatus isGrey />,
    ESTIMATES: <IconEstimate isAddons type="inactive" />,
    ADVANCE_ROUTING: <IconRoute type="inactive" />,
    AI_AGENTS: <IconChatbot type="inactive" />,
    SERVICE_PLAN: <IconServicePlan inactive />,
    sentricon: <IconSentricon />
};

export const ADDONS_LEVEL_ALL = -1;
export const ADDONS_LEVEL_PRO = 20;
export const ADDONS_LEVEL_GROWTH = 60;
export const ADDONS_LEVEL_BASIC = 1;

export const ADDONS_ACCOUNT_TYPE_PLANS_TEXT = {
    FREE_TRIAL: 'free_trial',
    PRO_PLAN: 'pro_plan',
    BASIC_PLAN: 'basic_plan',
    GROWTH_PLAN: 'growth_plan'
};

export const LIST_OPTIONS_FILTER_BY_PLANS = [
    { id: 1, name: 'all', value: ADDONS_LEVEL_ALL },
    { id: 3, name: 'basic_plan', value: ADDONS_LEVEL_BASIC },
    { id: 4, name: 'pro_plan', value: ADDONS_LEVEL_PRO },
    { id: 5, name: 'growth_plan', value: ADDONS_LEVEL_GROWTH }
];

export const ADDONS_STATUS_ALL = -1;
export const ADDONS_STATUS_ACTIVE = 1;
export const ADDONS_STATUS_INACTIVE = 0;
export const ADDONS_ESTIMATE_PACKAGE = 'estimate_package';
export const ADDONS_ESTIMATE_DYNAMIC = 'estimate_dynamic';
export const ADVANCE_ROUTING_OPTIONS_KEYWORDS = {
    BEST_AVAILABLE_TIME: 'best_available_time',
    JOB_MAGNET: 'job_magnet',
    DRIVE_TIME: 'drive_time'
};
export const ADDONS_AI_AGENTS = 'ai_agents';
export const AI_AGENT_FEATURE_KEYS = {
    AI_AGENT_SMS: 'ai_agent_sms',
    AI_AGENT_VOIP: 'ai_agent_voip',
    AI_AGENT_PORTAL: 'ai_agent_portal'
};
export const ADDONS_DEVICE_TRACKING_OPTIONS_KEYWORDS = {
    PAPERWORK: 'paperwork',
    TREND_REPORT: 'trend_report',
    SENTRICON: 'sentricon'
};
export const LIST_API_UPDATE_ADDONS_OPTIONS = {
    STRIPE: UPDATE_ADDONS_STRIPE_OPTIONS,
    SQUARE: UPDATE_ADDONS_SQUARE_OPTIONS,
    COMMISSION_TRACKING: UPDATE_ADDONS_COMMISSION_OPTIONS,
    CUSTOMER_PORTAL: UPDATE_ADDONS_PORTAL_OPTIONS,
    DEVICE_TRACKING: UPDATE_ADDONS_DEVICE_TRACKING_OPTIONS,
    DOCUMENTS: UPDATE_ADDONS_DOCUMENT_OPTIONS,
    MATERIAL: UPDATE_ADDONS_MATERIAL_OPTIONS,
    GPS_TRACKING: UPDATE_ADDONS_GPS_TRACKING_OPTIONS,
    QUICKBOOKS: UPDATE_ADDONS_QUICKBOOKS_SYNC,
    REVIEW_ENGINE: UPDATE_ADDONS_REVIEW_OPTIONS,
    ROUTING: UPDATE_ADDONS_ROUTING_OPTIONS,
    SMSPLIVO: SMS_ADDONS_OPTIONS,
    ZAPIER: '',
    MDU_TRACKING: UPDATE_ADDONS_MULTI_UNIT_OPTIONS,
    INBOX_EMAIL: UPDATE_ADDONS_INBOX_EMAIL_OPTIONS,
    ONLINE_BOOKING: UPDATE_ADDONS_ONLINE_BOOKING_OPTIONS,
    TRIGGERS: UPDATE_ADDONS_TRIGGERS_OPTIONS,
    TIME_WINDOW: UPDATE_ADDONS_TIME_WINDOW_OPTIONS,
    API_KEY: UPDATE_ADDONS_API_KEY_OPTIONS,
    VOIP: UPDATE_ADDONS_VOIP_OPTIONS,
    OPPORTUNITY: UPDATE_ADDONS_OPPORTUNITY_OPTIONS,
    SMART_VIEWS: UPDATE_ADDONS_SMARTVIEW_OPTIONS,
    WORK_LOG: UPDATE_ADDONS_WORK_LOG_OPTIONS,
    CUSTOM_FIELDS: UPDATE_ADDONS_CUSTOM_FIELDS_OPTIONS,
    CUSTOM_JOB_STATUS: UPDATE_CUSTOM_JOB_STATUS_FIELDS_OPTIONS,
    ESTIMATES: UPDATE_ADDONS_ESTIMATE_OPTIONS,
    ADVANCE_ROUTING: UPDATE_ADDONS_ADVANCE_ROUTING_OPTIONS,
    AI_AGENTS: UPDATE_ADDONS_CHATBOT_AI_OPTIONS,
    SERVICE_PLAN: UPDATE_ADDONS_SERVICE_PLAN_OPTIONS
};

export const ADDONS_FOOTER_OPTIONS = {
    STRIPE_SURCHARGE: 'stripe_surcharge',
    SQUARE_SURCHARGE: 'square_surcharge',
    SERVICE_REVIEW: 'service_reviews',
    LIKELY_SERVICE: 'likely_reviews',
    MULTI_UNIT: 'mdu_paperwork',
    SUBSCRIPTION: 'subscription',
    SQUARE_SUBSCRIPTION: 'square_subscription',
    SMS_AFTER_HOURS: 'sms_after_hour',
    VOIP_ACTIVE_USER: 'user',
    VOIP_PERSONAL: 'personal',
    VOIP_GROUP: 'group',
    VOIP_AFTER_HOURS: 'after_hours',
    VOIP_AUTO_ATTENDANT: 'auto_attendant',
    VOIP_BLOCKED_NUMBERS: 'blocked_numbers',
    SMS_BLOCKED_NUMBERS: 'sms_blocked_numbers',
    OPPORTUNITY_MANAGE: 'opportunity_manage',
    VOIP_HOLD_MUSIC: 'hold_music',
    OPPORTUNITY_PERMISSION: 'opportunity_permission',
    CUSTOM_FIELDS: 'custom_fields',
    PERMISSIONS: 'permissions',
    STRIPE_ACH: 'ach',
    JOB_STATUS_MANAGE: 'custom_job_status',
    JOB_STATUS_PERMISSION: 'custom_job_status_permissions',
    DOCUMENT_LIBRARY: 'document_library',
    VOIP_VOICEMAIL_DROP: 'voicemail_drop',
    MANAGE_DYNAMIC_ESTIMATE: ADDONS_ESTIMATE_DYNAMIC,
    MANAGE_ESTIMATE_PACKAGE: ADDONS_ESTIMATE_PACKAGE,
    BEST_AVAILABLE_TIME: 'best_available_time',
    JOB_MAGNET: 'job_magnet',
    CHATBOT_AI_SMS: 'ai_agent_sms',
    CHATBOT_AI_PORTAL: 'ai_agent_portal',
    SMS_GLOBAL: 'sms_global',
    SMART_VIEWS_PERMISSIONS: 'smartviews_permission',
    ONLINE_BOOKING: 'online_booking',
    SNEAKY_WIDGET: 'sneaky_widget',
    ADDONS_SERVICE_PLAN_MANAGE_TEMPLATES: 'manage_templates',
    SENTRICON: 'sentricon'
};

export const DEFAULT_ITEM_CODE_API_SQUARE = { id: '', address: 'Choose your api code' };

export const ADDONS_ZAPIER = 'ZAPIER';
export const ADDONS_SMS_GLOBAL = 'sms_global';
export const ADDONS_BYPASS_SERVICE_REVIEW = 'bypass_service_review';
export const ADDONS_BYPASS_LIKELY_REVIEW = 'bypass_likely_review';
export const ADDONS_SERVICE_REVIEW = 'service_reviews';
export const ADDONS_LIKELY_REVIEW = 'likely_reviews';
export const ADDONS_COPY_KEY = 'copy';
export const ADDONS_SMS_AFTER_HOURS_KEY = 'sms_after_hour';
export const ADDONS_VOIP_VOICEMAIL = 'voicemail';
export const ADDONS_VOIP_ACTIVE_USER = 'user';
export const ADDONS_VOIP_GROUP = 'group';
export const ADDONS_VOIP_PERSONAL = 'personal';
export const ADDONS_VOIP_AFTER_HOURS = 'after_hours';
export const ADDONS_VOIP_AUTO_ATTENDANT = 'auto_attendant';
export const ADDONS_VOIP_FORWARDING = 'forwarding';
export const ADDONS_VOIP_MISSED_CALL_SMS = 'missed_call_sms';
export const ADDONS_MULTIPLE_BRANCH = 'MULTIPLE_BRANCH';
export const ADDONS_VOIP_BLOCKED_NUMBERS = 'blocked_numbers';
export const ADDONS_SMS_BLOCKED_NUMBERS = 'sms_blocked_numbers';
export const ADDONS_OPPORTUNITY_MANAGE = 'opportunity_manage';
export const ADDONS_OPPORTUNITY_GENERATOR = 'opportunity_generator';
export const ADDONS_VOIP_HOLD_MUSIC = 'hold_music';
export const ADDONS_OPPORTUNITY_PERMISSIONS = 'opportunity_permission';
export const ADDONS_COMPLETIONLIST = 'completionist';
export const ADDONS_JOB_STATUS_MANAGE = 'custom_job_status';
export const ADDONS_JOB_STATUS_PERMISSION = 'custom_job_status_permissions';
export const ADDONS_MANAGE_DOCUMENTS_LIBRARY = 'document_library';

export const TIMEOFF_STATUS_ID = '-2';
export const OPTION_TIMEOFF_STATUS = {
    id: TIMEOFF_STATUS_ID,
    name: 'Time off',
    value: TIMEOFF_STATUS_ID,
    color: '#d0d0d0'
};
const AUTO_CREDIT_COMPLETIONIST = 'auto_credit_completionist';
export const ADDONS_STRIPE_SURCHARGE = 'stripe_surcharge';
export const ADDONS_STRIPE_ACH_SURCHARGE = 'stripe_ach_surcharge';
export const ADDONS_SQUARE_SURCHARGE = 'square_surcharge';
export const ADDONS_STRIPE_ACH = 'ach';

export const ADDONS_CALENDAR_SYNC_STATUS = [
    {
        id: 0,
        name: 'unconfirmed',
        value: 0
    },
    {
        id: 1,
        name: 'confirmed',
        value: 1
    },
    {
        id: 2,
        name: 'completed',
        value: 2
    },
    {
        id: 3,
        name: 'reschedule',
        value: 3
    },
    {
        id: 4,
        name: 'pending_confirmation',
        value: 4
    },
    {
        id: 5,
        name: 'canceled',
        value: 5
    },
    {
        id: 6,
        name: 'recurrence',
        value: 6
    },
    {
        id: -2,
        name: 'timeoff',
        value: -2
    }
];

export const LAST_MONTH = moment().subtract(1, 'month').startOf('month').format('MMM');
export const USAGE_MAXIMUM = 2500;
export const CONNECTION_USAGE = 15000;
export const ONE_HUNDRED_PERCENT = 100;
export const DEFAULT_VALUE_SLIDER = 50;
export const TIMEOUT_DEFAULT = 500;
export const KEY_PORTAL_TOKEN = '__portal_token__';
export const KEY_SUB_DOMAIN = '__domain__';
export const KEY_IS_EMBED_CHATBOT = '__is_embed_chat__';

export const CODE_HIGHT_LIGHT = `<script type="text/javascript">
  var _gorilla = _gorilla || {};
  _gorilla.account_id = '__portal_token__';
  _gorilla.loaded = false;
  _gorilla.chatbot = __is_embed_chat__;
  var _gorillaInitPortal = function () {
      _gorilla.loaded = true;
      var a = document.createElement('script');
      a.type = 'text/javascript';
      a.async = !0;
      a.defer = !0;
      a.src = '__domain__';
      var b = document.getElementsByTagName('script')[0];
      b.parentNode.insertBefore(a, b);
  };
  window.addEventListener
      ? window.addEventListener('load', _gorillaInitPortal, !1)
      : window.attachEvent
      ? window.attachEvent('onload', _gorillaInitPortal)
      : (window.onload = _gorillaInitPortal);
</script>`;
export const CODE_HIGHT_LIGHT_1 = {
    display: `&lt;a href="javascript:void(0)" onclick="<span class="installation-content-code__items">GorillaDesk('showPortal');</span> return false;"&gt;Open Customer Portal Home&lt;/a&gt;`,
    value: `<a href="javascript:void(0)" onclick="GorillaDesk('showPortal'); return false;">Open Customer Portal Home</a>`
};
export const CODE_HIGHT_LIGHT_2 = {
    display: `&lt;a href="javascript:void(0)" onclick="<span class="installation-content-code__items">GorillaDesk('showBooking');</span> return false;"&gt; Open Booking&lt;/a&gt;`,
    value: `<a href="javascript:void(0)" onclick="GorillaDesk('showBooking'); return false;">Open Booking</a>`
};
export const CODE_HIGHT_LIGHT_3 = {
    display: `&lt;a href="javascript:void(0)" onclick="<span class="installation-content-code__items">GorillaDesk('showContact');</span> return false;"&gt;Open Contact&lt;/a&gt;`,
    value: `<a href="javascript:void(0)" onclick="GorillaDesk('showContact'); return false;">Open Contact</a>`
};
export const STRIPE = 'STRIPE';
export const SQUARE = 'SQUARE';
export const MDU_TRACKING = 'MDU_TRACKING';
export const SMS_TEXT_MESSAGING = 'SMSPLIVO';
export const QUICK_BOOKS_SYNC = 'QUICKBOOKS';
export const INBOX_EMAIL = 'INBOX_EMAIL';
export const INBOX_SUB = 'INBOX_SUB';
export const TIME_WINDOW = 'TIME_WINDOW';
export const VOIP = 'VOIP';
export const WORK_LOG = 'WORK_LOG';
export const ESTIMATES = 'ESTIMATES';
export const AI_AGENTS = 'AI_AGENTS';
export const PERMISSIONS_CUSTOM_FIELDS = 'PERMISSIONS_CUSTOM_FIELDS';
export const PERMISSIONS_JOB_STATUS = 'PERMISSIONS_JOB_STATUS';
export const PERMISSIONS_BEST_AVAILABLE_TIME = 'PERMISSIONS_BEST_AVAILABLE_TIME';
export const KEY_ADDONS_CUSTOM_FIELDS = 'custom_fields';
export const KEY_ADDONS_CUSTOM_JOB_STATUS = 'custom_job_status';

export const ADDONS_ACTIVE_WITH_CONDITION_KEY = [SMS_TEXT_MESSAGING, QUICK_BOOKS_SYNC, AI_AGENTS];
export const ADDONS_ACTIVE_WITH_CONDITION_DATA = {
    [SMS_TEXT_MESSAGING]: { url: ADDONS_SMS },
    [QUICK_BOOKS_SYNC]: { url: ADDONS_QUICKBOOKS_SYNC },
    [INBOX_EMAIL]: { url: ADDONS_INBOX_EMAIL },
    [AI_AGENTS]: { url: ADDONS_AI }
};

export const KEY_ADVANCED_BALANCE_DUE = 'auto_sent_invoice';
export const KEY_VOIP = 'voip';

export const ADDONS_DOCUMENT_ESIGN_OPTION = 'esign';
export const ADDONS_SQUARE_READER_OPTION = 'square_reader';
export const ADDONS_SERVICE_PLAN = 'service_plan';
export const SERVICE_PLANS = 'service_plans';
export const ADDONS_SERVICE_PLAN_MANAGE_TEMPLATES = 'manage_templates';

export const ADDONS_OPTION_NOT_SWITCH = [
    ADDONS_DOCUMENT_ESIGN_OPTION,
    ADDONS_VOIP_ACTIVE_USER,
    ADDONS_VOIP_GROUP,
    ADDONS_VOIP_PERSONAL,
    ADDONS_VOIP_BLOCKED_NUMBERS,
    ADDONS_SMS_BLOCKED_NUMBERS,
    ADDONS_OPPORTUNITY_MANAGE,
    ADDONS_VOIP_HOLD_MUSIC,
    ADDONS_OPPORTUNITY_PERMISSIONS,
    ADDONS_COMPLETIONLIST,
    ADDONS_FOOTER_OPTIONS.CUSTOM_FIELDS,
    ADDONS_FOOTER_OPTIONS.PERMISSIONS,
    ADDONS_JOB_STATUS_MANAGE,
    ADDONS_JOB_STATUS_PERMISSION,
    ADDONS_FOOTER_OPTIONS.SMART_VIEWS_PERMISSIONS,
    ADDONS_SERVICE_PLAN_MANAGE_TEMPLATES
];

export const ADDONS_OPTION_NOT_POWERUP = [
    ADDONS_VOIP_ACTIVE_USER,
    ADDONS_VOIP_GROUP,
    ADDONS_VOIP_PERSONAL,
    ADDONS_VOIP_AUTO_ATTENDANT,
    ADDONS_VOIP_BLOCKED_NUMBERS,
    ADDONS_SMS_BLOCKED_NUMBERS,
    ADDONS_OPPORTUNITY_MANAGE,
    ADDONS_VOIP_HOLD_MUSIC,
    ADDONS_OPPORTUNITY_PERMISSIONS,
    ADDONS_JOB_STATUS_MANAGE,
    ADDONS_JOB_STATUS_PERMISSION,
    AI_AGENT_FEATURE_KEYS.AI_AGENT_SMS,
    AI_AGENT_FEATURE_KEYS.AI_AGENT_VOIP,
    AI_AGENT_FEATURE_KEYS.AI_AGENT_PORTAL,
    ADDONS_FOOTER_OPTIONS.ONLINE_BOOKING,
    ADDONS_SERVICE_PLAN_MANAGE_TEMPLATES
];

export const ADDONS_OPTION_SHOW_PRO = [ADDONS_VOIP_AUTO_ATTENDANT, ADDONS_VOIP_GROUP];
export const ADDON_FEATURES_COMING_SOON = [AI_AGENT_FEATURE_KEYS.AI_AGENT_VOIP];
export const ADDONS_GROUP_ITEM = [ADDONS_STRIPE_ACH_SURCHARGE];

export const ADDONS_CUSTOMER_PORTAL = 'CUSTOMER_PORTAL';
export const ADDONS_API_KEYS = 'API_KEY';
export const ADDONS_CUSTOM_FIELDS_KEY = 'CUSTOM_FIELDS';
export const ADDONS_CUSTOM_JOB_STATUS = 'CUSTOM_JOB_STATUS';

export const LINK_POST_CUSTOMER_PORTAL = 'https://intercom.help/gorilladesk/en/articles/8396228-customer-portal';

export const ID_TIME_WINDOW = 'time_window';
export const ID_API_KEY = 'api_key';

export const TAB_ADDONS_VOIP_ADD_GROUP_NUMBER = {
    NEW_NUMBER: 1,
    EXTERNAL_NUMBER: 2,
    PORT_BYOC: 3,
    EXIST_SMS_NUMBER: 4
};

export const TYPE_MANAGE_ADDONS_VOIP = {
    [ADDONS_VOIP_PERSONAL]: 1,
    [ADDONS_VOIP_GROUP]: 2
};

export const TEXT_SAVE_VOIP_ADDONS = {
    [TAB_ADDONS_VOIP_ADD_GROUP_NUMBER.NEW_NUMBER]: 'request_new_number',
    [TAB_ADDONS_VOIP_ADD_GROUP_NUMBER.EXTERNAL_NUMBER]: 'verify_number',
    [TAB_ADDONS_VOIP_ADD_GROUP_NUMBER.PORT_BYOC]: 'done',
    [TAB_ADDONS_VOIP_ADD_GROUP_NUMBER.EXIST_SMS_NUMBER]: 'save'
};

export const SOURCE_PHONE_NUMBER_VOIP = {
    BUY: 1,
    EXTERNAL: 2,
    SMS: 3
};

export const TYPE_PHONE_VOIP = {
    PERSONAL: 1,
    GROUP: 2
};

export const LIST_ADDONS_BETA = [ADDONS_SERVICE_PLAN.toUpperCase()];
export const EARLY_ACCESS_ADDONS_LIST = [];
export const DEFAULT_WEEKS = { MO: '0', TU: '0', WE: '0', TH: '0', FR: '0', SA: '0', SU: '0' };

export const LIST_COUNTRY_SHOW_PROMOTION = ['us'];

export const LIST_ITEMS_PARSE_HTML = [
    AUTO_CREDIT_COMPLETIONIST,
    ADDONS_FOOTER_OPTIONS.STRIPE_SURCHARGE,
    ADDONS_FOOTER_OPTIONS.SQUARE_SURCHARGE
];

export const ADDONS_OPTION_DISABLE_ON_INACTIVE = [
    ADDONS_SMS_AFTER_HOURS_KEY,
    ADDONS_SMS_GLOBAL,
    ADDONS_OPPORTUNITY_GENERATOR,
    ADDONS_ESTIMATE_DYNAMIC,
    ADDONS_ESTIMATE_PACKAGE,
    ADVANCE_ROUTING_OPTIONS_KEYWORDS.BEST_AVAILABLE_TIME,
    ADVANCE_ROUTING_OPTIONS_KEYWORDS.JOB_MAGNET,
    ADDONS_DEVICE_TRACKING_OPTIONS_KEYWORDS.PAPERWORK,
    ADDONS_DEVICE_TRACKING_OPTIONS_KEYWORDS.TREND_REPORT,
    ADDONS_DEVICE_TRACKING_OPTIONS_KEYWORDS.SENTRICON
];

export const SURCHARGE_PAYMENT = {
    [ADDONS_STRIPE_SURCHARGE]: {
        endPoint: ENDPOINT_STRIPE_SURCHARGE,
        title: 'stripe'
    },
    [ADDONS_SQUARE_SURCHARGE]: {
        endPoint: ENDPOINT_SQUARE_SURCHARGE,
        title: 'square'
    },
    [ADDONS_STRIPE_ACH_SURCHARGE]: {
        endPoint: ENDPOINT_STRIPE_ACH_SURCHARGE,
        title: 'bank_account'
    }
};
export const CREDIT_STATUS_COLORS = { GREEN: 'green', ORANGE: 'orange' };

export default function IconSearch({ isHasMinus = false, isHasPlus = false }) {
    if (isHasMinus)
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <g fill="none" fillRule="evenodd">
                    <path d="M0 0h24v24H0z"></path>
                    <circle
                        cx="11.5"
                        cy="11.5"
                        r="6"
                        stroke="var(--color-icon)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.3"
                    ></circle>
                    <path
                        stroke="var(--color-icon)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.3"
                        d="M16.25 16.25l1.5 1.5M9.5 11.5h4"
                    ></path>
                </g>
            </svg>
        );
    if (isHasPlus)
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <g fill="none" fillRule="evenodd">
                    <path d="M0 0h24v24H0z"></path>
                    <circle
                        cx="11.5"
                        cy="11.5"
                        r="6"
                        stroke="var(--color-icon)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.3"
                    ></circle>
                    <path
                        stroke="var(--color-icon)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.3"
                        d="M16.25 16.25l1.5 1.5M11.5 9.5v4m-2-2h4"
                    ></path>
                </g>
            </svg>
        );

    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 17.5C15.0376 17.5 17.5 15.0376 17.5 12C17.5 8.96243 15.0376 6.5 12 6.5C8.96243 6.5 6.5 8.96243 6.5 12C6.5 15.0376 8.96243 17.5 12 17.5Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 9.5C10.6193 9.5 9.5 10.6193 9.5 12"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.25 16.25L17.75 17.75"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

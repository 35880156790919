import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { DEFAULT_CURRENCY_SYMBOL, LIST_STATUS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import AddonGetStarted from '../../components/AddonGetStarted';
import { AI_AGENTS_LEARN_MORE_URL } from '../constants';
import ModalManagePlan from './plan/ModalManagePlan';

const ChatbotAddonsGetStarted = ({
    currencySymbol = DEFAULT_CURRENCY_SYMBOL,
    chatBot = {},
    isShowManageButton = true,
    onUpdateSuccess = () => {}
}) => {
    const { t } = useTranslation('addons');
    const location = useLocation();
    const { account, upgrade } = chatBot;

    const [state, dispatchState] = useReducer(reducer, { isVisibleModal: false, account });
    const { isVisibleModal: finalIsVisibleModal, account: finalAccount } = state;
    const { balance: finalBalance } = finalAccount || {};
    const isActivePlan = !!finalAccount;
    const refAlert = useRef(null);

    useEffect(() => {
        if (location.state?.isActiveSuccess) {
            refAlert.current.showStatusBar({
                id: 'index_addon_chatbot',
                message: t('your_chatbot_plan_has_been_activated'),
                type: LIST_STATUS.SUCCESS
            });
        }
    }, []);

    const _handleClickManage = () => {
        dispatchState((prev) => ({ ...prev, isVisibleModal: true }));
    };

    const _handleCloseModal = () => {
        dispatchState((prev) => ({ ...prev, isVisibleModal: false }));
    };

    return (
        <Fragment>
            <AlertCustomer ref={refAlert} />
            <AddonGetStarted
                upgrade={upgrade}
                currencySymbol={currencySymbol}
                isActivePlan={isActivePlan}
                textGettingStarted="getting_started_with_ai_agents"
                textActive="desc_getting_started_ai_agents_active"
                textInActive=""
                textLearnMore="learn_more_about_ai_agents"
                linkLearnMore={AI_AGENTS_LEARN_MORE_URL}
                balance={Number(finalBalance).toFixed(2)}
                textButton="manage_plan"
                isShowManageButton={isShowManageButton}
                onClickButton={_handleClickManage}
            />
            {finalIsVisibleModal ? (
                <ModalManagePlan
                    currencySymbol={currencySymbol}
                    onClose={_handleCloseModal}
                    onUpdateSuccess={onUpdateSuccess}
                />
            ) : null}
        </Fragment>
    );
};

export default ChatbotAddonsGetStarted;

import React, { Fragment, useEffect, useId, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import IconEdit from 'assets/icon/IconEdit';
import IconFileType from 'assets/icon/IconFileType';
import IconThreeDots from 'assets/icon/IconThreeDots';
import IconTrash from 'assets/icon/IconTrash';
import IconUpload from 'assets/icon/IconUpload';
import { actionToggleInsertJobImages } from 'common/redux/actions/photosAction';

const Options = ({
    openFormEdit,
    onUpLoad = () => {},
    onDelete = () => {},
    onSelectInsert,
    disableEdit = false,
    isInsertImage = false,
    isHideDeleteAll = false
}) => {
    const { t } = useTranslation();
    const [state, setState] = useReducer(reducer, {
        isVisible: false
    });

    const finalIsVisible = state.isVisible;
    const optionsId = useId();
    const refDropdown = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById(optionsId);
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById(optionsId);

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    function _handleOpen(e) {
        e.stopPropagation();

        setState({ isVisible: !finalIsVisible });
    }

    function _handleEditTask(e) {
        e.stopPropagation();
        openFormEdit();
        setState({ isVisible: false });
    }

    function _handleClickUpload(e) {
        e.stopPropagation();
        onUpLoad();
        setState({ isVisible: false });
    }

    function _handleInsert(e) {
        e.stopPropagation();
        dispatch(actionToggleInsertJobImages({ openTime: Date.now(), callback: onSelectInsert }));
        setState({ isVisible: false });
    }

    function _handleDelete(e) {
        e.stopPropagation();
        setState({ isVisible: false });
        onDelete(e, -1);
    }

    function _renderOptions() {
        if (!finalIsVisible) {
            return false;
        }

        return (
            <div className="v2-dropdown__menu scrolls">
                <ul>
                    {!disableEdit && (
                        <li onClick={_handleEditTask} className="items has-icon js-edit-img">
                            <IconEdit />
                            {t('common:edit')}
                        </li>
                    )}

                    <li onClick={_handleClickUpload} className="items has-icon">
                        <IconUpload />
                        {t('jobDetail:upload_images')}
                    </li>

                    {!disableEdit && !isHideDeleteAll && (
                        <li onClick={_handleDelete} className="items has-icon red-default">
                            <IconTrash />
                            {t('jobDetail:delete_all_images')}
                        </li>
                    )}

                    {isInsertImage && (
                        <Fragment>
                            <li className="lines" />
                            <li onClick={_handleInsert} className="items has-icon js-show-existing-images">
                                <IconFileType type={'image/jpeg'} />
                                {t('jobDetail:insert_from_existing_job')}
                            </li>
                        </Fragment>
                    )}
                </ul>
            </div>
        );
    }

    return (
        <div
            id={optionsId}
            className={`elm-option v2-dropdown --more ml-0 ${finalIsVisible ? 'active' : ''}`}
            ref={refDropdown}
        >
            <div onClick={_handleOpen} className="dropbtn">
                <IconThreeDots />
            </div>
            {finalIsVisible && _renderOptions()}
        </div>
    );
};

export default Options;

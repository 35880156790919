import React from 'react';
import { useTranslation } from 'react-i18next';

import IconClose from 'assets/icon/IconClose';
import { OPERATOR_TYPES } from '../../constants/types';
import { getStringDuration } from './utils';

const ItemFilteredDuration = ({ field, filter, onUpdate = () => {}, onRemove = () => {} }) => {
    const { t } = useTranslation(['smartView']);
    const { prefixToken } = OPERATOR_TYPES[filter.operatorId];
    const valueString = getStringDuration(filter.value);

    return (
        <>
            <div onClick={onUpdate} className="flex-1">
                <span className="black-darklight fw-500">{t(field.label)}:</span>
                {prefixToken ? <span className="budget --grey fw-600 text-uppercase">{t(prefixToken)}</span> : null}
                <span className="fw-600 ml-1">{valueString}</span>
            </div>
            <span className="icon-close" onClick={onRemove}>
                <IconClose isSmall />
            </span>
        </>
    );
};

export default ItemFilteredDuration;

import loadable from '@loadable/component';
import { getGidColumnsDetailTab, getGridColumnsMonthTab } from 'app/const/report/RevenueByItem';
import { getListReportRevenueByItemDetail } from 'common/redux/actions/reports/revenueByItemAction';
import { getParamsRevenuePage } from 'common/utils/ReportUtils';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

const GdGridRowTotal = loadable(() => import('app/components/grid/GdGridRowTotal'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const ReportRevenuePopupDetail = loadable(() => import('app/modules/report/components/ReportRevenuePopupDetail'));

function MonthTabGridView({ dataReport, paramsReport, handleActionHeader = () => {}, handleOpenInvoice, monthTab }) {
    const refPopup = useRef(null);
    const getColumnsMonthTab = getGridColumnsMonthTab(paramsReport?.orderMonth || null);

    const handleClickTable = ({ key, row }) => {
        refPopup.current.openPopup({ ...getParamsRevenuePage({ key, paramsReport }), item: row.item_id });
    };

    return (
        <div className="tab-conts tab-content-active">
            <GdGridView
                isEmptyFlat
                isLoading={dataReport.isLoading}
                classTable="--revenue table-multi-column scrolls-x has-text-ellipsis"
                classTableContent="--hastotal"
                content={dataReport?.data}
                fileTranslation={'report'}
                handleClick={(event) => {
                    handleClickTable(event);
                }}
                handleClickHeader={handleActionHeader}
                {...getColumnsMonthTab}
                rowTotal={(props) => (
                    <GdGridRowTotal
                        columns={dataReport?.rowTotal}
                        contentConfig={getColumnsMonthTab?.contentConfig}
                        showCheckBox={false}
                        isLoading={dataReport.isLoading}
                        {...props}
                    />
                )}
            />

            <ReportRevenuePopupDetail
                ref={refPopup}
                functionCallAPI={getListReportRevenueByItemDetail}
                girdColumn={getGidColumnsDetailTab}
                keyTotalRow={'total_payments_received'}
                handleOpenInvoice={handleOpenInvoice}
                monthTab={monthTab}
            />
        </div>
    );
}

MonthTabGridView.propTypes = {
    dataReport: PropTypes.object
};

function handleExportMonthTab() {
    // const token = getLocalStorageValue(ACCESS_TOKEN);
    // const typeExport = isCsv ? 'csv' : 'xls';
    // const form = document.createElement('form');

    return false;
}

export { MonthTabGridView, handleExportMonthTab };

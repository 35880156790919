import React, { Fragment, useContext, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_ALL } from 'app/const/App';
import { ESTIMATE_TYPE } from 'app/modules/jobdetail/const/Estimate';
import { AddInvoiceContext } from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import ServiceDetail from 'app/modules/jobdetail/tabs/addinvoice/form/items/detail';
import { getListTaxes } from 'common/redux/actions/taxesAction';
import EstimatePackageForm from '../package';
import ButtonAdd from '../package/ButtonAdd';

const InvoiceService = ({ showOnTime, isInvoice = true, isEditInvoice = false }) => {
    const { t } = useTranslation();
    const {
        invoiceData,
        onUpdateInvoiceDataContext,
        onUploadImagesItem,
        onChangeWithPreData,
        onChangeEstimatePackage
    } = useContext(AddInvoiceContext);
    const { square: addonSquare, stripe: addonStripe } = useSelector(
        (state) => state.auth.user?.settings?.addons || {}
    );

    const surchargeNote = useSelector((state) => state.auth.user?.settings?.surcharge?.note || '');

    const {
        items: listItems,
        locationInfo = {},
        schedule,
        soldBy,
        type: estimateType,
        packages: estimatePackages
    } = invoiceData;

    const isEstimatePackage = estimateType === ESTIMATE_TYPE.PACKAGES;
    const locationTax = { tax1: locationInfo.tax1, tax2: locationInfo.tax2 };
    const surChargeItems = invoiceData.items_surcharge || [];

    const dispatch = useDispatch();

    useEffect(() => {
        // eslint-disable-next-line no-undef
        !global.fetchedListTax && _getListTaxes();
    }, []);

    function _getListTaxes() {
        dispatch(getListTaxes({ fields: 'rate' }));
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    function updateAndAddBack(array, idPairs) {
        if (!Array.isArray(idPairs) || idPairs.length !== 2) {
            return array;
        }

        const indexes = idPairs.map((id) => array.findIndex((item) => item.id === id));

        if (indexes.some((index) => index === -1)) {
            return array;
        }

        return reorder(array, indexes[0], indexes[1]);
    }

    const _handleRemoveOneTime = (itemSort) => {
        return itemSort.map((item, index) => {
            if (index === 0) {
                return {
                    ...item,
                    one_time: 0
                };
            }
            return item;
        });
    };

    function _onDragEnd(result, provided, packageId) {
        if (!result.destination) {
            return false;
        }

        const fromIndex = result.source.index;
        const toIndex = result.destination.index;

        if (!!packageId && isEstimatePackage) {
            const _findIdItemPackage = (index) =>
                listItems.filter((item) => item.package_id === packageId).find((item, ind) => index === ind)?.id ||
                DEFAULT_ALL;

            const listIdItemsOfPackage = [_findIdItemPackage(fromIndex), _findIdItemPackage(toIndex)];
            onUpdateInvoiceDataContext({
                items: _handleRemoveOneTime(updateAndAddBack(listItems, listIdItemsOfPackage))
            });
            return false;
        }

        onUpdateInvoiceDataContext({
            items: _handleRemoveOneTime(reorder(listItems, fromIndex, toIndex))
        });
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        ...draggableStyle,
        userSelect: 'none',
        opacity: isDragging ? 0.7 : 1,
        border: 1
    });

    function _handleChangeItem(itemChange) {
        const finalFunction = isEstimatePackage && !isInvoice ? onChangeEstimatePackage : onChangeWithPreData;
        finalFunction((prev) => {
            return {
                ...prev,
                items: prev.items.map((oldItem) => {
                    if (oldItem.id === itemChange.id) {
                        oldItem.id = itemChange.id;
                        const isJobInvoice = invoiceData.isJobInvoice;
                        const result = {
                            ...oldItem,
                            item_id: itemChange.item_id,
                            name: itemChange.name,
                            quantity: itemChange.quantity,
                            cost: itemChange.cost,
                            tax1: itemChange.tax1,
                            tax2: itemChange.tax2,
                            description: itemChange.description,
                            one_time: itemChange.one_time,
                            item_default: false,
                            old_tax1: itemChange.old_tax1,
                            old_tax2: itemChange.old_tax2,
                            changedTax: itemChange.changedTax,
                            sold_by_ids: itemChange.sold_by_ids || [],
                            type: itemChange.type
                        };

                        if (isInvoice && !isJobInvoice) result['sold_by_ids'] = itemChange.sold_by_ids || [];
                        if (isInvoice && isJobInvoice)
                            result['commission'] = {
                                productions:
                                    schedule?.filter((schedule) => +schedule.item_id === +itemChange.item_id) || [],
                                soldBys: soldBy?.filter((soldBy) => +soldBy.item_id === +itemChange.item_id) || []
                            };

                        return result;
                    }
                    return oldItem;
                })
            };
        });
    }

    function _handleDeleteItem(itemId) {
        const finalFunction = isEstimatePackage && !isInvoice ? onChangeEstimatePackage : onChangeWithPreData;
        finalFunction((prev) => {
            return {
                ...prev,
                items: prev.items.filter((oldItem) => oldItem.id !== itemId)
            };
        });
    }

    function _triggerUploadImages(itemId, value) {
        onUploadImagesItem(itemId, value);
    }

    function _renderItemDetail(item, index) {
        const finalDescription = item.description || '';
        return (
            <div key={item.id || index.toString()} className={'content-elm-edit rows tabs-items-body is-subcharge'}>
                <div className="items-service tb-content">
                    <div className="col col-item fw-600">{item.name}</div>
                    <div className="col col-cost">
                        <span>{item.cost.format}</span>
                    </div>
                    <div className="col col-tax col-10">
                        <span>{item.tax1?.name || ''}</span>
                    </div>
                    <div className="col col-tax col-10">
                        <span>{item.tax2?.name || ''}</span>
                    </div>
                    <div className="col col-qty">
                        <span>{item.quantity}</span>
                    </div>
                    <div className="col col-price fw-500">
                        <span>{item.subtotal?.format}</span>
                    </div>
                </div>
                {finalDescription.length !== 0 && (
                    <div className="boxs">
                        <div className="conts-box" dangerouslySetInnerHTML={{ __html: finalDescription }} />
                    </div>
                )}
            </div>
        );
    }

    function _renderSurchargeItems() {
        if (!Array.isArray(surChargeItems) && surChargeItems.length === 0) return null;

        return surChargeItems.map((item, index) => {
            return _renderItemDetail(item, index);
        });
    }

    const _renderPackages = () => {
        return estimatePackages.map((item, index) => {
            const { id: packageId } = item;
            return (
                <EstimatePackageForm
                    key={packageId}
                    packageData={item}
                    renderItems={_renderLineItems}
                    listItems={listItems}
                    index={index}
                />
            );
        });
    };

    const _renderLineItems = (listLineItems, packageId) => {
        return (
            <Fragment>
                <div className="content-elm-edit rows">
                    <div className="items-service tb-title">
                        <div className="col col-item">{t('common:item')}</div>
                        <div className="col col-cost">{t('setting:cost')}</div>
                        <div className="col col-tax col-10">{t('setting:tax')}</div>
                        <div className="col col-tax col-10" />
                        <div className="col col-qty">{t('common:qty')}</div>
                        <div className="col col-price">{t('common:price')}</div>
                        {isInvoice && <div className="col col-onetime">{t('common:one_time')}</div>}
                    </div>
                </div>

                <DragDropContext onDragEnd={(result, provided) => _onDragEnd(result, provided, packageId)}>
                    <Droppable droppableId={`droppable_${packageId}`}>
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {listLineItems.map((item, index) => {
                                    const serviceItemId = item.id || index.toString();
                                    return (
                                        <Draggable
                                            key={serviceItemId}
                                            draggableId={serviceItemId.toString()}
                                            index={index}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                    tabIndex="-1"
                                                >
                                                    <ServiceDetail
                                                        itemDetail={item}
                                                        onChange={_handleChangeItem}
                                                        onTriggerImages={_triggerUploadImages}
                                                        showOnTime={index > 0 && showOnTime}
                                                        onDelete={_handleDeleteItem}
                                                        index={index}
                                                        isEditInvoice={isEditInvoice}
                                                        isInvoice={isInvoice}
                                                        isJobInvoice={invoiceData?.isJobInvoice}
                                                        isInvoiceRecurring={invoiceData?.isInvoiceRecurring}
                                                        locationTax={locationTax}
                                                        isEstimateDynamic={estimateType === ESTIMATE_TYPE.DYNAMIC}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Fragment>
        );
    };

    return (
        <Fragment>
            {!isInvoice && isEstimatePackage && (
                <Fragment>
                    {_renderPackages()}
                    <ButtonAdd />
                </Fragment>
            )}
            {!isEstimatePackage && _renderLineItems(listItems)}
            {_renderSurchargeItems()}
            {isInvoice &&
                surChargeItems.length === 0 &&
                (!!addonStripe.stripe_surcharge || !!addonSquare.square_surcharge) && (
                    <p className="content-elm-edit paying-text">{surchargeNote}</p>
                )}
        </Fragment>
    );
};

export default InvoiceService;

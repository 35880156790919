import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import './styles/index.scss';

import { DARK_MODE_INFO } from 'app/const/Header';
import i18n from 'assets/i18n';
import { getDarkModeValue } from 'common/utils/FunctionUtils';

const EmojiMart = ({ handleOnClick = () => {} }) => {
    const isDarkMode = getDarkModeValue();
    const theme = isDarkMode ? DARK_MODE_INFO.DARK : DARK_MODE_INFO.LIGHT;

    const addEmoji = (value) => handleOnClick(value);
    return (
        <Picker
            data={data}
            autoFocus
            theme={theme}
            onEmojiSelect={addEmoji}
            previewPosition="none"
            perLine={8}
            locale={i18n.language}
        />
    );
};

export default EmojiMart;

import { CUSTOMERS_DOCUMENTS } from 'app/config/routes';
import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';
import { COMMON, LIST_TABLE_ITEM_TYPE } from '../App';

export const getGidColumns = (columns = [], actionsStatus = '') => {
    const HEADER_DOCUMENT = {
        document_number: {
            title: 'document_number_#',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        document_type: {
            title: 'document_type',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        account_number: {
            title: 'account_number_#',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        customer: {
            title: 'customer',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        created_by: {
            title: 'created_by',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        date_created: {
            title: 'date_created',
            style: 'col col-date',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        status: {
            title: 'status',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        }
    };

    const REPORT_COLUMNS_DOCUMENT = [
        {
            id: 'document_number',
            title: 'document',
            status: true
        },
        {
            id: 'document_type',
            title: 'document_type',
            status: true
        },
        {
            id: 'account_number',
            title: 'account_number',
            status: true
        },
        {
            id: 'customer',
            title: 'customer',
            status: true
        },
        {
            id: 'created_by',
            title: 'created_by',
            status: true
        },
        {
            id: 'date_created',
            title: 'date_created',
            status: true
        },
        {
            id: 'status',
            title: 'status',
            status: true
        }
    ];

    const REPORT_CONTENT_DOCUMENT = {
        document_number: {
            id: 'document_number',
            style: 'col col-md',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.LINK
        },
        document_type: {
            id: 'document_type',
            style: 'col col-xl flexcenter',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.LINK,
            showTypeDoc: true
        },
        account_number: {
            id: 'account_number',
            style: 'col col-md',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.LINK
        },
        customer: {
            id: 'customer',
            style: 'col col-xl',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO,
            urlConfig: { baseUrl: CUSTOMERS_DOCUMENTS, isOpenNewTab: false }
        },
        created_by: {
            id: 'created_by',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.CREATED_BY
        },
        date_created: { id: 'date_created', style: 'col col-date' },
        status: {
            id: 'status',
            style: 'col col-md d-flex',
            type: LIST_TABLE_ITEM_TYPE.STATUS
        }
    };

    const newHeader = mapCurrentValueHeader(actionsStatus, { ...HEADER_DOCUMENT });
    const newContent = {};
    const newColumns = [];

    REPORT_COLUMNS_DOCUMENT.map((col) => {
        const item = col.id;

        if (columns.includes(item)) {
            newHeader[item] = HEADER_DOCUMENT[item];
            newContent[item] = REPORT_CONTENT_DOCUMENT[item];
            newColumns.push(col);
        }
    });
    return {
        header: newHeader,
        columns: newColumns,
        contentConfig: newContent
    };
};

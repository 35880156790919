import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ContentNoteVisible from 'app/components/notevisible/Content';
import TemplateOptions from 'app/components/templatenotes';
import { NOTES_TEMPLATE_TYPE } from 'app/const/Notes';
import { INVOICE_STATUS } from 'app/modules/jobdetail/const/Invoice';
import { AddInvoiceContext } from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import IconNote from 'assets/icon/IconNote';
import SetDefault from '../notes/SetDefault';

const InvoiceTerms = ({ isInvoice = true, onUpdateDefaultSuccess }) => {
    const { t } = useTranslation(['jobDetail']);
    const { invoiceData, onUpdateInvoiceDataContext } = useContext(AddInvoiceContext);
    const { terms, termsDefault, payment_terms, invoice_status_id, number, isAddInternal } = invoiceData;
    const { content } = payment_terms || {};

    const refSetDefaultTerm = useRef(null);
    const refContentNoteVisible = useRef(null);
    const refFirstTime = useRef(true);

    useEffect(() => {
        if (isAddInternal && number && content && refFirstTime.current) {
            onUpdateInvoiceDataContext({ terms: content });
            refFirstTime.current = false;
        }
    }, [number]);

    const _handleSelectTemplate = (item) => {
        const content = item.content || '';
        refContentNoteVisible.current.setValue(content, false);
        onUpdateInvoiceDataContext({ payment_terms: item, terms: content });
    };

    const _handleUpdateTerm = (content) => onUpdateInvoiceDataContext({ terms: content });

    const _handleSetDefaultTerm = () => {
        refSetDefaultTerm.current && refSetDefaultTerm.current.open();
    };

    const _handleUpdateDefaultTerm = (content) => {
        const dataUpdate = { terms: content, termsDefault: content };
        onUpdateInvoiceDataContext(dataUpdate);
        if (typeof onUpdateDefaultSuccess === 'function')
            onUpdateDefaultSuccess(
                isInvoice && invoice_status_id === INVOICE_STATUS.DRAFT ? dataUpdate : { termsDefault: content }
            );
    };

    return (
        <div className="boxs --terms-note">
            <div className="left-icons">
                <IconNote isActiveColor />
            </div>
            <div className="content elm-parent has-edited">
                <div className="header-box">
                    <div className="name">
                        <div className="name__label">
                            <h4 className="fs-13">{t('jobDetail:terms')}</h4>
                            <span className="fs-10 fw-500 grey-generic">{t('jobDetail:visible_to_client')}</span>
                        </div>
                    </div>
                    <TemplateOptions
                        isShow
                        typeFilter={NOTES_TEMPLATE_TYPE.PAYMENT_TERMS}
                        isShowFilter={false}
                        templateValue={payment_terms || {}}
                        invoiceStatus={invoice_status_id}
                        isInvoice={isInvoice}
                        onSelectTemplate={_handleSelectTemplate}
                        onSetDefaultNote={_handleSetDefaultTerm}
                    />
                </div>
                <div className="wrap-content is-complete">
                    <div className="wrap-content-box">
                        <div className="description" dangerouslySetInnerHTML={{ __html: terms }} />
                    </div>
                </div>
                <ContentNoteVisible
                    ref={refContentNoteVisible}
                    content={terms}
                    showRightToolbar={false}
                    onBlur={_handleUpdateTerm}
                />
            </div>
            <SetDefault
                ref={refSetDefaultTerm}
                isInvoice={isInvoice}
                isNote={false}
                defaultContent={termsDefault}
                onUpdate={_handleUpdateDefaultTerm}
            />
        </div>
    );
};

export default InvoiceTerms;

import React, { Fragment, useContext, useMemo, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import RenderAvatar from 'app/components/avatar/RenderAvatar';
import ButtonSave from 'app/components/button/ButtonSave';
import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import GdPhoneItem from 'app/components/phone/ItemPhone';
import { handleClosePhoneDropdown } from 'app/components/phone/PhoneDropdown';
import StatusBar from 'app/components/status/statusbar';
import { updateCallLog } from 'app/const/api/Voip';
import { LIST_STATUS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { TYPE_OPEN_SMS } from 'app/const/Sms';
import Options from 'app/modules/customer/task/detail/Options';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconCall from 'assets/icon/IconCall';
import IconForward from 'assets/icon/IconForward';
import IconPhone from 'assets/icon/IconPhone';
import { clientQuery } from 'common/utils/ApiUtils';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';
import { caculateTimeAgo } from 'common/utils/TimeUtils';
import Duration from '../calls/Duration';
import { CustomerDetailContext } from '../detail/context/CustomerDetailContext';
import { ListPhone } from '../detail/timeline/header/Call';

export default function CallNote({ callNoteData, customerDetail, onUpdateCallback, onDelete, onMakeCall }) {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        selected: '',
        isVisibleEdit: false
    });

    const { duration, created_date: createdDate, note, assignee: userAssignCall, id: callId, receiver } = callNoteData;

    const settings = useSelector(({ auth }) => auth?.user?.settings);
    const isEnableVoip = !!settings?.voip && !!settings?.addons?.voip;

    const { selected: finalSelected, isVisibleEdit } = state;

    const refStatusBar = useRef(null);
    const refButtonSave = useRef(null);
    const refDuration = useRef(null);
    const refNoteContent = useRef(null);

    const _handleSelect = (phoneSelected) => {
        dispatchState({
            selected: phoneSelected
        });
    };

    const _handleToggleEditNote = () => {
        dispatchState((prev) => {
            return {
                ...prev,
                isVisibleEdit: !prev.isVisibleEdit,
                selected: receiver
            };
        });
    };

    const _handleSave = () => {
        if (!finalSelected) {
            _handleShowStatus(t('customers:please_select_customer_phone'), LIST_STATUS.ERROR);
            _removeLoading();
            return false;
        }

        const duration = refDuration.current.value || 0;

        clientQuery(
            updateCallLog(callId),
            {
                method: 'PUT',
                data: {
                    customer_id: customerDetail.id,
                    receiver: finalSelected,
                    duration: parseFloat(duration) * 60,
                    note: refNoteContent.current.value
                }
            },
            _handleAddNoteSuccess,
            _handleAddNoteFailed
        );
    };

    const _handleShowStatus = (message, type) => {
        refStatusBar.current.showStatusBar(Date.now(), message, type);
    };

    const _handleAddNoteSuccess = (response) => {
        onUpdateCallback((preData) => {
            return {
                ...preData,
                dataTimeLine: [...preData.dataTimeLine].map((item) => {
                    if (item.id === callId) {
                        return {
                            ...item,
                            ...response.data
                        };
                    }
                    return item;
                })
            };
        });
        _removeLoading();
        dispatchState({
            isVisibleEdit: false
        });
    };

    const _handleAddNoteFailed = (response) => {
        _removeLoading();
        refStatusBar.current.showStatusBar(Date.now(), response.message?.toString(), LIST_STATUS.ERROR);
    };

    const _handleDeleteTask = () => {
        clientQuery(updateCallLog(callId), { method: 'DELETE' });
        onDelete(callId);
    };

    const _removeLoading = () => {
        refButtonSave.current.removeLoading();
    };

    return (
        <div className="boxs boxs-calls --logs">
            <div className="left-icons">
                <IconCall isCallOut />
            </div>
            <div className="boxs-calls__inner">
                <StatusBar ref={refStatusBar} />

                <div className="header-box">
                    <RenderAvatar userData={userAssignCall} />
                    <div className="name">
                        <h5 className="name__label fw-normal fs-14">{userAssignCall?.full_name || ''}</h5>
                    </div>
                    <Options
                        textTarget={t('customers:call_log')}
                        openFormEdit={_handleToggleEditNote}
                        onDelete={_handleDeleteTask}
                        taskId={callId}
                    />
                </div>
                <div className="wrap-call-summary flexcenter flex-wrap">
                    <div className="call-summary flexcenter flex-1">
                        <span className="svg-gray mr-1">
                            <IconForward />
                        </span>
                        <span className="grey-generic mr-1">{t('customers:called')}</span>
                        <span className="call-summary__name">{formatPhoneNumberVoip(receiver)}</span>
                        <Duration duration={duration} isCallLog={false} />
                        <span className="call-summary__duration dots">{caculateTimeAgo(createdDate.value)}</span>
                        {!!receiver && isEnableVoip && (
                            <div
                                className="v2-btn-default --circle --icon-lg ml-1"
                                onClick={() => onMakeCall(receiver)}
                            >
                                <IconPhone />
                            </div>
                        )}
                    </div>
                    <div className={`wrap-notes-edit w-100 ${isVisibleEdit ? 'has-editing' : ''}`}>
                        <p className="call-notes-label word-break">{note}</p>
                        {isVisibleEdit && (
                            <div className="call-notes-form">
                                <div className="header betweenitems">
                                    <CallNoteForm onSelect={_handleSelect} finalSelected={finalSelected} />
                                    <input
                                        ref={refDuration}
                                        className="field-input"
                                        type="number"
                                        placeholder={t('customers:minutes')}
                                        defaultValue={!!duration ? parseFloat(duration / 60).toFixed(1) : ''}
                                    />
                                </div>
                                <textarea
                                    className="field-textarea --h-120 --no-border d-flex"
                                    placeholder={t('customers:add_a_note_about_this_call')}
                                    defaultValue={note}
                                    autoFocus
                                    ref={refNoteContent}
                                />
                                <div className="footer --align-right">
                                    <div onClick={_handleToggleEditNote} className="v2-btn-default --transparent">
                                        {t('common:cancel')}
                                    </div>
                                    <ButtonSave ref={refButtonSave} title={t('common:save')} onSave={_handleSave} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export const CallNoteForm = ({ onSelect = () => {}, finalSelected = '' }) => {
    const refDropdown = useRef(null);

    const _handleSelect = (phoneSelected) => {
        onSelect(phoneSelected);
        refDropdown.current._close();
    };

    return (
        <DropdownPopper
            ref={refDropdown}
            isCheckHidden
            wrapperClassName="v2-dropdown wrap-log-call"
            wrapperListClass="v2-dropdown__menu --center scrolls"
            dropdownIcon={
                <Fragment>
                    <SelectedPhone selected={finalSelected} />
                    <div className="arrow">
                        <IconArrowDown />
                    </div>
                </Fragment>
            }
            buttonClassName="dropbtn items --has-icon"
            isUseToggle
            isCalculateWidth
            onClose={handleClosePhoneDropdown}
        >
            <ListPhone onChange={_handleSelect} onlySelect />
        </DropdownPopper>
    );
};

const SelectedPhone = ({ selected }) => {
    const {
        customer: { phones = [] },
        contacts = []
    } = useContext(CustomerDetailContext);

    const findPhone = useMemo(() => {
        const findCustomerPhone = phones.find(
            (item) => formatPhoneNumberVoip(item.phone) === formatPhoneNumberVoip(selected)
        );

        const finalContacts = contacts || [];

        if (!findCustomerPhone) {
            const find = () => {
                for (const item of finalContacts) {
                    const result = item.phones.find(
                        (item) => formatPhoneNumberVoip(item.phone) === formatPhoneNumberVoip(selected)
                    );
                    if (result)
                        return {
                            ...result,
                            name: item.customer.first_name.concat(' ', item.customer.last_name)
                        };
                }
            };
            return find();
        }
        return findCustomerPhone;
    }, [selected]);

    return (
        <div className="dropbtn__label">
            <p className="txt-ellipsis flex-none ml-0">{findPhone?.name || ''}</p>
            {!!findPhone && (
                <GdPhoneItem
                    isValid={findPhone.is_valid}
                    phoneType={findPhone?.type?.name || findPhone.phone_type}
                    phone={findPhone.phone}
                    isPreview
                    typeOpen={TYPE_OPEN_SMS.CUSTOMER}
                />
            )}
        </div>
    );
};

import React from 'react';
import { useHistory } from 'react-router-dom';

import { ADDONS_AI } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import { AddonsOptionsItem } from '../../components/AddonsOptions';

const ItemAdditionalOption = ({
    data = {},
    urlUpdate = '',
    urlUpdateStatus = '',
    isUpgradeGrowthIcon = false,
    isCustomerPortalAIAgent = false
}) => {
    const history = useHistory();
    const _handleRedirectAIAgent = () => {
        history.push(addBranchPath(ADDONS_AI));
    };

    return (
        <AddonsOptionsItem
            key={data.keyword}
            name={data.name}
            keyword={data.keyword?.toLowerCase()}
            description={data.sub_title || data.description}
            upgrade={data.upgrade}
            value={data.status || data.value}
            urlUpdate={urlUpdate}
            urlUpdateStatus={urlUpdateStatus}
            isUpgradeGrowthIcon={isUpgradeGrowthIcon}
            scriptInfo={data.scriptInfo}
            isAccountActive={!!data.account}
            isCustomerPortalAIAgent={isCustomerPortalAIAgent}
            onConfirmChange={isCustomerPortalAIAgent && !data.account ? _handleRedirectAIAgent : null}
        />
    );
};

export default ItemAdditionalOption;

import React from 'react';
import { useTranslation } from 'react-i18next';

import ItemJobStatus from 'app/components/job/ItemJobStatus';
import { OBJECT_TYPES } from 'app/modules/smartview/constants/types';
import IconArrowDown from 'assets/icon/IconArrowDown';

const CustomButton = ({
    selected,
    isJobStatus = false,
    shouldFetch = false,
    objectType = '',
    keyJoin = 'full_name',
    keyChoose = 'option',
    keySelect = 'id',
    options = []
}) => {
    const { t } = useTranslation(['smartView']);
    const keyString = keyChoose ? t(keyChoose) : 'option';
    const defaultTitle = t('choose_a_option_', { key: keyString });

    const handleGetTitle = () => {
        let arrValue = [];
        if (objectType === OBJECT_TYPES.CUSTOM_FIELD) {
            arrValue = selected;
        } else {
            selected.forEach((element) => {
                const itemFound = options.find((option) => {
                    const valueCompare = option?.[keySelect]?.toString() || option?.id?.toString();
                    return valueCompare === element?.toString();
                })?.[keyJoin];
                if (itemFound) arrValue.push(shouldFetch ? itemFound : t(itemFound));
            });
        }
        if (arrValue.length <= 0) return defaultTitle;
        return arrValue.join(', ');
    };
    const finalTitle = selected?.length && Array.isArray(selected) ? handleGetTitle() : defaultTitle;
    return (
        <>
            {isJobStatus ? (
                <StatusesSelected data={selected} titleEmpty={defaultTitle} />
            ) : (
                <span className="txt-ellipsis mr-2">{finalTitle || ''}</span>
            )}
            <div className="arrow">
                <IconArrowDown />
            </div>
        </>
    );
};
const StatusesSelected = ({ data = [], titleEmpty = '' }) => {
    if (!data.length) return titleEmpty;
    return data.map((item) => (
        <ItemJobStatus key={item} idStatus={item} classTextItem="status-btn --job-status" excludeWrapper />
    ));
};
export default CustomButton;

import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';

const EditDocumentAvailable = forwardRef(({ onUpdateDocument = () => {} }, ref) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, document: {} });
    const refInput = useRef(null);
    const document = state.document;

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = (document) => {
        dispatchState({ isOpen: true, document });
    };

    const _close = () => {
        dispatchState({ isOpen: false });
    };

    const _handleSave = () => {
        onUpdateDocument({ ...document, name: refInput.current.value });
        dispatchState({ isOpen: false });
    };

    if (!state.isOpen) return null;
    return (
        <ReactModal
            isOpen={state.isOpen}
            style={{ overlay: { zIndex: 99, background: 'transparent' } }}
            className="modal container-modal open"
            onRequestClose={_close}
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className="modal__container --sm">
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('addons:document_name')}</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                        <IconClose />
                    </div>
                </div>

                <div className="body-modal">
                    <div className="row">
                        <span className="txt">{t('common:name')}</span>
                        <input
                            ref={refInput}
                            className="field-input"
                            type="text"
                            defaultValue={state.document.name}
                            placeholder={t('addons:document_name')}
                            autoFocus
                        />
                    </div>
                </div>

                <div className="footer-modal btn-close">
                    <span className="v2-btn-default --noborder --label" onClick={_close}>
                        {t('common:cancel')}
                    </span>
                    <div className="v2-btn-main" onClick={_handleSave}>
                        {t('common:save')}
                    </div>
                </div>
            </div>
        </ReactModal>
    );
});

export default EditDocumentAvailable;

export default function IconStick({ isBlue }) {
    if (isBlue)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.4471 5.39068L19.1094 10.053C19.4999 10.4435 19.4999 11.0767 19.1094 11.4672C18.9029 11.6736 18.6166 11.7795 18.3256 11.7571L16.4247 11.6109L13.5962 14.4393L14.1773 18.1193C14.2634 18.6648 13.891 19.1769 13.3455 19.263C13.0295 19.3129 12.7086 19.2086 12.4824 18.9824L5.51768 12.0176C5.12715 11.6271 5.12715 10.9939 5.51768 10.6034C5.74391 10.3772 6.06473 10.2729 6.38075 10.3228L10.0607 10.9038L12.8891 8.07538L12.7429 6.17448C12.7006 5.62382 13.1126 5.14309 13.6633 5.10073C13.9543 5.07834 14.2407 5.18426 14.4471 5.39068Z"
                    stroke="#1E7EF7"
                    strokeWidth="1.3"
                />
                <path
                    d="M8.64648 15.1464L5.03198 19.0022L5.51158 19.3463L9.35359 15.8536"
                    stroke="#1E7EF7"
                    strokeWidth="1.3"
                    strokeLinejoin="round"
                />
            </svg>
        );
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.1627 9.18174L21.1627 15.7752C21.1627 16.3275 20.715 16.7752 20.1627 16.7752C19.8708 16.7752 19.5934 16.6477 19.4035 16.426L18.1627 14.9785L14.1627 14.9785L11.9715 17.9915C11.6466 18.4381 11.0212 18.5369 10.5745 18.212C10.3158 18.0239 10.1627 17.7232 10.1627 17.4033L10.1627 7.55366C10.1627 7.00138 10.6104 6.55366 11.1627 6.55366C11.4827 6.55366 11.7833 6.70674 11.9715 6.96549L14.1627 9.97848L18.1627 9.97848L19.4035 8.53094C19.7629 8.11162 20.3942 8.06306 20.8135 8.42248C21.0352 8.61246 21.1627 8.88981 21.1627 9.18174Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
            />
            <path
                d="M10.1627 11.9785L2.5 12.5L10.1627 12.9785"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinejoin="round"
            />
        </svg>
    );
}

import { getListPaymentMethod } from 'app/const/Api';
import { COMMON } from 'app/const/App';
import { PAYMENT_CARD_CUSTOMER } from 'app/const/Customers';
import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { PAYMENT_METHODS, PAYMENT_METHODS_NOT_EDIT } from 'app/modules/jobdetail/const/Invoice';
import IconPayment from 'assets/icon/IconPayment';
import classNames from 'classnames';
import { clientQuery } from 'common/utils/ApiUtils';
import { onKeyDownOnlyNumber } from 'common/utils/NumberUtils';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const CustomerPaymentMethod = ({
    defaultMethod = null,
    defaultMemo = null,
    defaultCheckNumber = null,
    defaultCheckValue = '',
    onChange = () => {},
    onSelect = () => {}
}) => {
    const { t } = useTranslation(['common']);
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        methods: [],
        paymentSelected: defaultMethod
    });
    const refDropdown = useRef(null);
    const paymentSelected = state.paymentSelected || {};
    const isStripe = paymentSelected?.id?.includes(PAYMENT_CARD_CUSTOMER.STRIPE);
    const isSquare = paymentSelected?.id?.includes(PAYMENT_CARD_CUSTOMER.SQUARE);

    useEffect(() => {
        clientQuery(getListPaymentMethod, { method: 'GET' }, _getListPaymentMethodSuccess);
    }, []);

    const _getListPaymentMethodSuccess = ({ data }) => {
        dispatchState({
            isLoading: false,
            methods: data.some((item) => item.id === defaultMethod.id) ? data : [...data, defaultMethod]
        });
    };

    const _handleSelect = (e, item, isActive) => {
        onSelect(item);
        e.stopPropagation();
        if (isActive) return false;

        dispatchState({
            paymentSelected: item
        });
        refDropdown.current._closeDropdown();
    };

    function _renderOptions() {
        return state.methods.map((item) => {
            const paymentId = item.id;
            const isActive = paymentId === state?.paymentSelected?.id;
            if (PAYMENT_METHODS_NOT_EDIT.includes(paymentId)) return null;
            return (
                <li
                    key={paymentId}
                    onClick={(e) => _handleSelect(e, item, isActive)}
                    className={classNames('items', { active: isActive })}
                >
                    <p className="txt-ellipsis">{item.name}</p>
                </li>
            );
        });
    }

    const _handleOnKeyDownNumber = (event) => {
        const finalKey = event.key;
        const currentValue = event.target.value;

        if (finalKey === '-' && currentValue !== '') {
            const convertToNegative = Number(`-${currentValue}`);
            event.target.value = isNaN(convertToNegative) ? 0 : convertToNegative;
            event.preventDefault();
            onChange(event, COMMON.CHECK_VALUE);
        }

        const isKeyAccept =
            onKeyDownOnlyNumber(event) || (finalKey === '-' && event.target.value === '') || finalKey === '.';
        !isKeyAccept && event.preventDefault();
    };

    function _renderValuePayment() {
        switch (paymentSelected?.id) {
            case PAYMENT_METHODS.CHECK:
                return (
                    <Fragment>
                        <input
                            type="text"
                            className="field-input field-method"
                            value={defaultCheckNumber}
                            placeholder={t('customers:check_number')}
                            onChange={(e) => onChange(e, COMMON.CHECK_NUMBER)}
                        />
                        <input
                            type="number"
                            className="field-input field-method"
                            value={defaultCheckValue}
                            onKeyDown={_handleOnKeyDownNumber}
                            placeholder={t('jobDetail:check_value')}
                            onChange={(e) => onChange(e, COMMON.CHECK_VALUE)}
                        />
                    </Fragment>
                );
            default:
                return (
                    <input
                        type="text"
                        className="field-input"
                        value={defaultMemo}
                        placeholder={t('customers:memo')}
                        onChange={(e) => onChange(e, COMMON.MEMO)}
                    />
                );
        }
    }

    function _renderValuePaymentCard() {
        return null;
    }

    return (
        <div className="sm-row">
            <div className="txt">
                <IconPayment isHasColor />
                {t('method')}
            </div>
            <div className="detail d-flex">
                <CalendarDropdown
                    ref={refDropdown}
                    id="method_select_edit"
                    wrapperClassName="v2-dropup select-method"
                    buttonClassName="dropbtn items selection text-capitalize"
                    selected={paymentSelected.name}
                >
                    <ul>{_renderOptions()}</ul>
                </CalendarDropdown>
                {isSquare || isStripe ? _renderValuePaymentCard() : _renderValuePayment()}
            </div>
        </div>
    );
};

export default CustomerPaymentMethod;

import { call, put } from 'redux-saga/effects';
import mixpanel from 'mixpanel-browser';
import { fetchWithToken, postFetchWithoutToken } from 'common/utils/ApiUtils';
import { AUTH_TYPE } from 'common/redux/actions/authAction';
import { LOGIN, SIGNUP_SOCIAL, USER_RETRIEVE, LOGIN_SOCIAL, LOGOUT, LOGIN_CLASSIC } from 'app/const/Api';
import store from 'common/redux/store/configureStore';
import { SET_APP_LOADING } from 'common/redux/actions/appAction';
import { CALENDAR_ACTIONS } from 'common/redux/actions/calendar';
import { MAP_ACTIONS } from 'common/redux/actions/map';
import { KEY_LOCAL_STORAGE_BOOK_MARK_HEADER } from 'app/modules/calendar/const/BookMark';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { TYPE_ACTION_COMPANY_USERS } from 'common/redux/actions/companyUsersAction';
import { AUTH_LOGIN } from 'app/const/Route';
import { TYPE_SCHEDULE_ACTION } from 'common/redux/actions/calendar/scheduleAction';
import { checkIsExpiredAccount } from 'common/utils/PermissionUtils';
import { ACCESS_TOKEN, KEY_CALENDAR_SETTINGS, KEY_CURRENT_BRANCH, KEY_DEVICE_ID } from 'app/const/App';
import { setBranchId } from 'app/const/Branch';
import i18n from 'assets/i18n';
import { EN } from 'app/const/Settings';

export function* userLogin({ payload, actionSuccess, actionFailed }) {
    const response = yield call(postFetchWithoutToken, LOGIN, payload, store.dispatch);
    if (response && response.success) {
        const reponseBranchId = response.company.branch.id;
        const deviceiD = response.device_id;
        i18n.changeLanguage(response.company.language || EN);
        setLocalStorage(KEY_DEVICE_ID, deviceiD || '');
        setBranchId(reponseBranchId);
        setLocalStorage(KEY_CURRENT_BRANCH, reponseBranchId);
        setLocalStorage(KEY_LOCAL_STORAGE_BOOK_MARK_HEADER, []);

        yield put({ type: AUTH_TYPE.LOGIN_USER_SUCCESS, payload: response });
        if (!checkIsExpiredAccount(response.profile)) {
            const calendar = response.calendar || {};
            yield put({
                type: CALENDAR_ACTIONS.UPDATE_CALENDAR,
                payload: calendar
            });
            yield put({
                type: MAP_ACTIONS.UPDATE_MAP_SIZE,
                payload: {
                    heightofmap: calendar.heightofmap,
                    widthofmap: calendar.widthofmap,
                    statusofmap: calendar.statusofmap,
                    typeofmap: calendar.typeofmap,
                    dayofmap: calendar.dayofmap,
                    openmap: calendar.openmap
                }
            });
            yield put({ type: TYPE_SCHEDULE_ACTION.ACTION_LIST_SCHEDULE_REQUEST });
            yield put({ type: TYPE_ACTION_COMPANY_USERS.GET_LIST_USERS_REQUESTING });
        }

        yield put({
            type: SET_APP_LOADING,
            payload: false
        });
        actionSuccess(response);
    } else {
        yield put({ type: AUTH_TYPE.LOGIN_USER_ERROR, payload: response });
        yield put({
            type: SET_APP_LOADING,
            payload: false
        });
        actionFailed(response);
    }
}

export function* userSignupSocial({ payload, actionSuccess, actionFailed }) {
    const response = yield call(postFetchWithoutToken, SIGNUP_SOCIAL, payload, store.dispatch);

    if (response && response.success) {
        if (response.token) {
            const reponseBranchId = response.company.branch.id;
            const deviceiD = response.device_id;
            i18n.changeLanguage(response.company.language || EN);
            setLocalStorage(KEY_DEVICE_ID, deviceiD || '');
            setBranchId(reponseBranchId);
            setLocalStorage(KEY_CURRENT_BRANCH, reponseBranchId);
            setLocalStorage(KEY_LOCAL_STORAGE_BOOK_MARK_HEADER, []);
            setLocalStorage(ACCESS_TOKEN, response.token);
            const calendar = response?.calendar || {};
            yield put({
                type: CALENDAR_ACTIONS.UPDATE_CALENDAR,
                payload: calendar
            });
            yield put({
                type: MAP_ACTIONS.UPDATE_MAP_SIZE,
                payload: {
                    heightofmap: calendar?.heightofmap,
                    widthofmap: calendar?.widthofmap,
                    statusofmap: calendar?.statusofmap,
                    typeofmap: calendar.typeofmap,
                    dayofmap: calendar?.dayofmap,
                    openmap: calendar?.openmap
                }
            });
            yield put({ type: TYPE_SCHEDULE_ACTION.ACTION_LIST_SCHEDULE_REQUEST });
            yield put({
                type: AUTH_TYPE.LOGIN_USER_SUCCESS,
                payload: response
            });
            yield put({ type: TYPE_ACTION_COMPANY_USERS.GET_LIST_USERS_REQUESTING });
            yield put({
                type: SET_APP_LOADING,
                payload: false
            });
            actionSuccess(response, payload);
        } else {
            actionSuccess(response, payload);
        }
    } else {
        actionFailed(response);
    }
}

export function* userLoginSocial({ payload, actionSuccess, actionFailed }) {
    const response = yield call(postFetchWithoutToken, LOGIN_SOCIAL, payload);
    if (response && response.success) {
        const reponseBranchId = response.company.branch.id;
        const deviceiD = response.device_id;
        i18n.changeLanguage(response.company.language || EN);
        setLocalStorage(KEY_DEVICE_ID, deviceiD || '');
        setBranchId(reponseBranchId);
        setLocalStorage(KEY_CURRENT_BRANCH, reponseBranchId);
        setLocalStorage(KEY_LOCAL_STORAGE_BOOK_MARK_HEADER, []);

        yield put({ type: AUTH_TYPE.LOGIN_USER_SUCCESS, payload: response });
        if (!checkIsExpiredAccount(response.profile)) {
            const calendar = response.calendar || {};
            yield put({ type: TYPE_ACTION_COMPANY_USERS.GET_LIST_USERS_REQUESTING });
            yield put({
                type: CALENDAR_ACTIONS.UPDATE_CALENDAR,
                payload: calendar
            });
            yield put({
                type: MAP_ACTIONS.UPDATE_MAP_SIZE,
                payload: {
                    heightofmap: calendar.heightofmap,
                    widthofmap: calendar.widthofmap,
                    statusofmap: calendar.statusofmap,
                    typeofmap: calendar.typeofmap,
                    dayofmap: calendar.dayofmap,
                    openmap: calendar.openmap
                }
            });
            yield put({ type: TYPE_SCHEDULE_ACTION.ACTION_LIST_SCHEDULE_REQUEST });
        }
        yield put({
            type: SET_APP_LOADING,
            payload: false
        });
        actionSuccess(response);
    } else {
        actionFailed(response);
    }
}

export function* getRetrieveUser({ actionSuccess, actionFailed }) {
    const response = yield call(fetchWithToken, USER_RETRIEVE);
    if (response?.success) {
        const reponseBranchId = response.company.branch.id;
        setBranchId(reponseBranchId);
        setLocalStorage(KEY_CURRENT_BRANCH, reponseBranchId);
        actionSuccess(response);
        yield put({ type: AUTH_TYPE.LOGIN_USER_SUCCESS, payload: response });
        if (!checkIsExpiredAccount(response.profile)) {
            let calendar = response.calendar || {};
            const localCalendarSettings = getLocalStorage(KEY_CALENDAR_SETTINGS);
            yield put({ type: TYPE_SCHEDULE_ACTION.ACTION_LIST_SCHEDULE_REQUEST });
            if (typeof localCalendarSettings?.view !== 'undefined') {
                calendar = {
                    ...calendar,
                    ...localCalendarSettings
                };
            } else {
                setLocalStorage(KEY_CALENDAR_SETTINGS, { view: calendar.view });
            }
            yield put({
                type: CALENDAR_ACTIONS.UPDATE_CALENDAR,
                payload: calendar
            });
            yield put({
                type: MAP_ACTIONS.UPDATE_MAP_SIZE,
                payload: {
                    heightofmap: calendar.heightofmap,
                    widthofmap: calendar.widthofmap,
                    statusofmap: calendar.statusofmap,
                    typeofmap: calendar.typeofmap,
                    dayofmap: calendar.dayofmap,
                    openmap: calendar.openmap
                }
            });
            yield put({ type: TYPE_ACTION_COMPANY_USERS.GET_LIST_USERS_REQUESTING });
        }

        yield put({
            type: SET_APP_LOADING,
            payload: false
        });
    } else {
        actionFailed();
        yield put({ type: AUTH_TYPE.CLEAR_USER_DATA });
        yield put({
            type: SET_APP_LOADING,
            payload: false
        });
    }
}

export function* userLogout() {
    yield call(fetchWithToken, LOGOUT);
    mixpanel.reset();
    const valueToKeep = localStorage.getItem(KEY_DEVICE_ID);
    localStorage.clear();
    localStorage.setItem(KEY_DEVICE_ID, valueToKeep);
    window.location.replace(AUTH_LOGIN);
    // window.location.reload();
    // yield put({ type: AUTH_TYPE.CLEAR_USER_DATA });
}

export function* userLoginByToken({ payload, actionSuccess, actionFailed }) {
    const response = yield call(postFetchWithoutToken, payload.url, { token: payload.token }, store.dispatch);

    if (response && response.success) {
        const reponseBranchId = response.company.branch.id;
        i18n.changeLanguage(response.company.language || EN);
        if (payload.url === LOGIN_CLASSIC) {
            const deviceiD = response.device_id;
            setLocalStorage(KEY_DEVICE_ID, deviceiD || '');
        }

        setBranchId(reponseBranchId);
        setLocalStorage(KEY_CURRENT_BRANCH, reponseBranchId);
        setLocalStorage(KEY_LOCAL_STORAGE_BOOK_MARK_HEADER, []);

        yield put({ type: AUTH_TYPE.LOGIN_USER_SUCCESS, payload: response });
        if (!checkIsExpiredAccount(response.profile)) {
            const calendar = response.calendar || {};
            yield put({
                type: CALENDAR_ACTIONS.UPDATE_CALENDAR,
                payload: calendar
            });
            yield put({
                type: MAP_ACTIONS.UPDATE_MAP_SIZE,
                payload: {
                    heightofmap: calendar.heightofmap,
                    widthofmap: calendar.widthofmap,
                    statusofmap: calendar.statusofmap,
                    typeofmap: calendar.typeofmap,
                    dayofmap: calendar.dayofmap,
                    openmap: calendar.openmap
                }
            });
            yield put({ type: TYPE_ACTION_COMPANY_USERS.GET_LIST_USERS_REQUESTING });
            yield put({ type: TYPE_SCHEDULE_ACTION.ACTION_LIST_SCHEDULE_REQUEST });
        }
        yield put({
            type: SET_APP_LOADING,
            payload: false
        });
        actionSuccess(response);
    } else {
        actionFailed(response);
    }
}

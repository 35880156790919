import classNames from 'classnames';
import React, { forwardRef, useContext, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { INTERVAL_CUSTOM, INTERVAL_CUSTOM_OPTIONS_PLANS, INTERVAL_OPTIONS } from 'app/const/Customers';
import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconCalendar from 'assets/icon/IconCalendar';
import { SubscriptionContext } from '../../context/SubscriptionContext';

const init = ({ selectedPlans, isCustomInterval, typePlanSelect, typeSubscription }) => {
    let selected = INTERVAL_OPTIONS[typeSubscription][0];
    let selectedCustom = INTERVAL_CUSTOM_OPTIONS_PLANS[0];

    if (selectedPlans.length) {
        if (isCustomInterval) {
            selectedCustom = INTERVAL_CUSTOM_OPTIONS_PLANS.find((item) => {
                return item.value === typePlanSelect.interval;
            });
            selected = INTERVAL_CUSTOM;
        } else {
            selected = INTERVAL_OPTIONS[typeSubscription].find(({ value, interval_count }) => {
                return value === typePlanSelect['interval'] && interval_count === typePlanSelect['interval_count'];
            });
        }
    }

    return { selected, selectedCustom, typePlanSelect, selectedPlans, isCustomInterval };
};

const PlanInputInterval = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const { typePlanSelect, selectedPlans, isCustomInterval, typeSubscription } = useContext(SubscriptionContext);
    const [state, dispatchState] = useReducer(
        reducer,
        { isCustomInterval, typePlanSelect, selectedPlans, typeSubscription },
        init
    );
    const refDropdown = useRef(null);
    const refInput = useRef(null);

    const selected = state?.selected || {};
    const selectedCustom = state?.selectedCustom || {};

    useImperativeHandle(ref, () => ({
        _getValue: () =>
            selected.value === 'custom'
                ? { interval: selectedCustom.value, interval_count: parseInt(refInput.current.value) }
                : { interval: selected.value }
    }));

    const _handleSelect = (valueSelect) => {
        dispatchState({
            selected: INTERVAL_OPTIONS[typeSubscription].find((item) => item.value === valueSelect),
            isCustomOption: valueSelect === 'custom'
        });
    };

    const _handleSelectCustom = (valueSelect) => {
        dispatchState({
            selectedCustom: INTERVAL_CUSTOM_OPTIONS_PLANS.find((item) => item.value === valueSelect),
            isCustomOption: true
        });
    };

    return (
        <>
            <div className="rows --modify --has-field">
                <div className="rows__label">
                    <IconCalendar />
                    <span className="txt-ellipsis">{t('common:interval')}</span>
                </div>
                <CalendarDropdown
                    id="interval-dropdown-add-plan"
                    ref={refDropdown}
                    options={INTERVAL_OPTIONS[typeSubscription]}
                    selected={selected.name}
                    selectedOption={selected || {}}
                    wrapperClassName={selectedPlans.length ? 'is-disable' : ''}
                    buttonClassName="dropbtn items --large"
                    fileTranslation="customers"
                    onSelect={_handleSelect}
                />
            </div>
            {(isCustomInterval || state.isCustomOption) && (
                <div className="rows --modify --has-field --custom-interval">
                    <div className="rows__label">
                        <IconCalendar />
                        {t('common:custom_interval')}
                    </div>
                    <input
                        ref={refInput}
                        className={classNames('field-input', { 'is-disable': selectedPlans.length })}
                        type="text"
                        defaultValue={typePlanSelect.interval_count}
                    />
                    <CalendarDropdown
                        id="interval-dropdown-add-plan"
                        ref={refDropdown}
                        options={INTERVAL_CUSTOM_OPTIONS_PLANS}
                        selected={selectedCustom.name}
                        selectedOption={selectedCustom}
                        wrapperClassName={`ml-2 ${selectedPlans.length ? 'is-disable' : ''}`}
                        buttonClassName="dropbtn items --large"
                        onSelect={_handleSelectCustom}
                    />
                </div>
            )}
        </>
    );
});

export default PlanInputInterval;

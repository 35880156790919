import { DEFAULT_CUSTOMER_ADD_LOCATION_TABS } from 'app/const/Customers';
import MessageTabs from 'app/modules/calendar/job/components/messaging/MessageTabs';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LocationContact from './LocationContact';

const LocationPreferences = (
    { defaultValueTabs = DEFAULT_CUSTOMER_ADD_LOCATION_TABS, isUpdate = false, locationId = null, customerId = '' },
    ref
) => {
    const { t } = useTranslation('customers');
    const activateSms = useSelector(({ auth }) => auth.user?.settings?.addons?.activate_sms || false);
    const defaultCheckPreference = activateSms ? 3 : 2;
    const defaultMessageTab = {
        appointment_confirmations: defaultValueTabs?.appointment_confirmations ?? defaultCheckPreference,
        appointment_reminders: defaultValueTabs?.appointment_reminders ?? defaultCheckPreference,
        appointment_followups: defaultValueTabs?.appointment_followups ?? defaultCheckPreference,
        broadcasts: defaultValueTabs?.broadcasts ?? defaultCheckPreference,
        late_payment_reminders: defaultValueTabs?.late_payment_reminders ?? defaultCheckPreference
    };
    const refInformation = useRef(null);

    useImperativeHandle(ref, () => ({ _getValue }));

    const _getValue = () => {
        const confirmations = document.getElementById('appointment_confirmations').getAttribute('data-value-selected');
        const reminders = document.getElementById('appointment_reminders').getAttribute('data-value-selected');
        const followups = document.getElementById('appointment_followups').getAttribute('data-value-selected');
        const broadcasts = document.getElementById('broadcasts').getAttribute('data-value-selected');
        const payment = document.getElementById('late_payment_reminders').getAttribute('data-value-selected');

        return {
            messaging_preferences: {
                appointment_confirmations: parseInt(confirmations),
                appointment_reminders: parseInt(reminders),
                appointment_followups: parseInt(followups),
                broadcasts: parseInt(broadcasts),
                late_payment_reminders: parseInt(payment),
                ...refInformation.current._getValue()
            }
        };
    };

    return (
        <div className="column-content column-preferences messaging-preferences --wrap">
            <span className="title mb-4">{t('appointment_messaging_preferences')}</span>
            <LocationContact
                ref={refInformation}
                isUpdate={isUpdate}
                locationId={locationId}
                customerId={customerId}
                defaultValue={defaultValueTabs}
            />
            <div className="box-appointment box-preferences tabs">
                <div className="lines">
                    <span className="txt">{t('confirmations')}</span>
                    <MessageTabs
                        value={defaultMessageTab.appointment_confirmations}
                        type="appointment_confirmations"
                        activateSms={activateSms}
                    />
                </div>
                <div className="lines">
                    <span className="txt">{t('reminders')}</span>
                    <MessageTabs
                        value={defaultMessageTab.appointment_reminders}
                        type="appointment_reminders"
                        activateSms={activateSms}
                    />
                </div>
                <div className="lines">
                    <span className="txt">{t('followups')}</span>
                    <MessageTabs
                        value={defaultMessageTab.appointment_followups}
                        type="appointment_followups"
                        activateSms={activateSms}
                    />
                </div>
                <div className="lines">
                    <span className="txt">{t('broadcasts')}</span>
                    <MessageTabs value={defaultMessageTab.broadcasts} type="broadcasts" activateSms={activateSms} />
                </div>
                <div className="lines">
                    <span className="txt text-left" title={t('jobDetail:late_payment_reminders')}>{t('late_payments_reminders')}</span>
                    <MessageTabs
                        value={defaultMessageTab.late_payment_reminders}
                        type="late_payment_reminders"
                        activateSms={activateSms}
                    />
                </div>
            </div>
        </div>
    );
};

export default forwardRef(LocationPreferences);

import React from 'react';

const Loading = () => {
    return (
        <div className="l-loading --questlog py-3">
            <div className="loading --animation --line --half"></div>
            <div className="loading --animation --line --onefourth"></div>
            <div className="loading --animation --line --full"></div>
            <div className="loading --animation --line --full"></div>
            <div className="divider"></div>
            <div className="loading --animation --line --full"></div>
            <div className="loading --animation --line --half"></div>
            <div className="loading --animation --line --half"></div>
            <div className="loading --animation --line --half"></div>
            <div className="loading --animation --line --half"></div>
            <div className="loading --animation --line --half"></div>
            <div className="loading --animation --line --half"></div>
        </div>
    );
};

export default Loading;

import { ADDONS_DOCUMENTS_LIBRARY } from 'app/config/routes';
import { ADDONS_DOCUMENT_LIST } from 'app/const/Api';
import StatusBar from 'app/components/status/statusbar';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { LIST_STATUS } from 'app/const/App';

import AvailableDocumentList from './components/AvailableDocumentList';
import AvailableDocumentPreview from './components/AvailableDocumentPreview';
import GdButton from 'app/components/button';
import { addBranchPath } from 'app/const/Branch';

const AvailableDocuments = () => {
    const { t } = useTranslation();
    const refListAvailable = useRef(null);
    const refDocumentPreView = useRef(null);
    const refStatus = useRef(null);

    const _handleGetDocuments = () => refListAvailable.current._getSelected();

    const _handleSelectDocument = (documentId, onlyUser) => {
        refDocumentPreView.current._preview(documentId, onlyUser);
    };

    const _handleSaveDocsFail = (message) => {
        refStatus.current.showStatusBar('save_document_fail', message, LIST_STATUS.ERROR);
    };

    const _handleGetFail = ({ message }) => {
        _handleSaveDocsFail(message);
        refDocumentPreView.current?._setLoading(false);
    };

    return (
        <div className="addons-wrap__documents">
            <StatusBar ref={refStatus} />
            <div className="title-header">
                <h3 className="title-header__label">{t('addons:available_documents')}</h3>
                <ButtonsAction onGetDocument={_handleGetDocuments} onSaveDocsFail={_handleSaveDocsFail} />
            </div>
            <div className="list --adddocs">
                <AvailableDocumentList
                    ref={refListAvailable}
                    onSelect={_handleSelectDocument}
                    onGetFail={_handleGetFail}
                />
                <AvailableDocumentPreview ref={refDocumentPreView} />
            </div>
        </div>
    );
};

const ButtonsAction = ({ onGetDocument = () => {}, onSaveDocsFail = () => {} }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const _handleSaveDocument = () => {
        setIsLoading(true);
        clientQuery(
            ADDONS_DOCUMENT_LIST,
            {
                data: { documents: onGetDocument() },
                method: 'PUT',
                toFormData: false
            },
            () => history.replace(addBranchPath(ADDONS_DOCUMENTS_LIBRARY)),
            _handleSaveFail
        );
    };

    const _handleSaveFail = ({ message }) => {
        onSaveDocsFail(message);
        setIsLoading(false);
    };

    return (
        <div className="btn-action">
            <Link to={addBranchPath(ADDONS_DOCUMENTS_LIBRARY)} className="v2-btn-default --noborder --label">
                {t('cancel')}
            </Link>
            <GdButton isLoading={isLoading} className="v2-btn-main" onClick={_handleSaveDocument}>
                {t('save')}
            </GdButton>
        </div>
    );
};

export default AvailableDocuments;

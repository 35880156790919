import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';

const SelectCalendarList = forwardRef(({ accessToken = '', defaultSelect = null }, ref) => {
    const { t } = useTranslation();
    const gapi = window.gapi;
    const [state, dispatchState] = useReducer(reducer, { calendarList: [], selected: null });
    const { calendarList, selected } = state;

    const selectedCalendar = selected || calendarList[0] || {};
    const refCalendarList = useRef();

    useImperativeHandle(ref, () => ({ _getValue: () => selectedCalendar }));

    useEffect(() => {
        if (accessToken) {
            gapi.client.setToken({ access_token: accessToken });
            const request = gapi.client.calendar.calendarList.list();
            request.execute(function (res) {
                const items = res.items || [];
                dispatchState({ calendarList: items, selected: items.find((item) => item.id === defaultSelect) });
            });
        }
    }, [accessToken]);

    const _handleSelect = (idSelect) => {
        dispatchState({ selected: calendarList.find((item) => item.id === idSelect) || {} });
    };

    return (
        <div className="boxs-selection">
            <p className="boxs-selection__label">{t('calendar:select_calendar')}:</p>
            <CalendarDropdown
                id="calendar_list_dropdown_setup"
                ref={refCalendarList}
                options={calendarList}
                selected={selectedCalendar?.summary}
                selectedOption={selectedCalendar}
                keyGetKey="id"
                keyGetName="summary"
                keyGetValue="id"
                onSelect={_handleSelect}
            />
        </div>
    );
});

export default SelectCalendarList;

import moment from 'moment';
import React, { forwardRef, useContext, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import TimePicker from 'app/modules/jobdetail/components/time/picker';
import { convertTimeWindow, timeConvert } from 'app/modules/jobdetail/const/Utils';
import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';
import JobLength from 'app/modules/jobdetail/tabs/settingschedule/time/JobLength';
import IconTime from 'assets/icon/IconTime';
import { convertTimeToISO } from 'common/utils/DateUtils';

const TimeScheduling = forwardRef(({ onUpdate = () => {} }, ref) => {
    const { t } = useTranslation();
    const { repeatSettings, onChangeException } = useContext(DataRepeatContext);
    const jobLength = timeConvert(repeatSettings.length);
    const finalTimeStart = repeatSettings.timeStart;

    const userSetting = useSelector(({ auth }) => auth.user.settings);
    const isActiveTimeGlobal = !!userSetting?.addons?.time_window?.time_window_global;

    const refTimePicker = useRef(null);
    const refJobLength = useRef(null);

    useImperativeHandle(ref, () => ({
        getValue: () => {
            return {
                timePicker: refTimePicker.current._getTime(),
                jobLength: refJobLength.current._getTime()
            };
        },
        getJobData: () => {
            return repeatSettings;
        }
    }));

    function _handleChangeTime() {
        const time = refTimePicker.current._getTime();

        const { hour: hourLength, minute: minuteLength } = refJobLength.current._getTime();
        const jobLength = parseInt(hourLength) * 60 + parseInt(minuteLength);
        const startMoment = moment(`${time.hour}:${time.minute} ${time.midDay}`, 'hh:mm A');
        const timeStart = convertTimeToISO(moment(`${time.hour}:${time.minute} ${time.midDay}`, 'hh:mm A'));

        onUpdate(timeStart);

        onChangeException({
            time_window: isActiveTimeGlobal
                ? convertTimeWindow({ repeatSettings, startMoment, jobLength })
                : repeatSettings.time_window,
            timeStart: timeStart,
            length: jobLength
        });
    }

    return (
        <div className="rows">
            <div className="txt">
                <IconTime isTimeJob />
                {t('common:time')}
            </div>
            <div className="details detail-time d-flex">
                <TimePicker
                    onChange={_handleChangeTime}
                    ref={refTimePicker}
                    timeSelected={moment(finalTimeStart).utc()}
                    reset={finalTimeStart}
                    isCloseOnSelected
                />
                <JobLength
                    onChange={_handleChangeTime}
                    ref={refJobLength}
                    minute={jobLength.minute}
                    hour={jobLength.hour}
                />
            </div>
        </div>
    );
});

export default TimeScheduling;

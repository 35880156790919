import React, { forwardRef, Fragment, useContext, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { ESTIMATE_DYNAMIC_TYPE, ESTIMATE_TYPE } from 'app/modules/jobdetail/const/Estimate';
import { INVOICE_ITEM_SIMPLE } from 'app/modules/jobdetail/const/Invoice';
import { AddInvoiceContext } from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import Discount from 'app/modules/jobdetail/tabs/addinvoice/form/summary/Discount';
import Payment from 'app/modules/jobdetail/tabs/payment';
import IconPlus from 'assets/icon/IconPlus';
import { roundingNumber, transformToCurrency } from 'common/utils/NumberUtils';
import Deposit from './Deposit';

const InvoiceSummary = forwardRef(
    ({ onUpdateBalance = () => {}, isInvoice = true, isAddPayment = true, showDiscountRepeat = true }, ref) => {
        const { t } = useTranslation();
        const { invoiceData, updateInvoiceDataContext } = useContext(AddInvoiceContext);
        const refAddPayment = useRef(null);
        const currency = useSelector(({ auth }) => auth.user.settings.currency);

        const { type: estimateType, deposit: finalDeposit = {} } = invoiceData;

        const isEstimatePackage = estimateType === ESTIMATE_TYPE.PACKAGES;
        const isEstimateDynamic = estimateType === ESTIMATE_TYPE.DYNAMIC;

        const valueDeposit = roundingNumber(parseFloat(finalDeposit?.total?.value || 0));

        useImperativeHandle(ref, () => ({
            getValue: () => {
                return invoiceData;
            }
        }));

        const _handleAddPayment = (e) => {
            e && e.stopPropagation();
            refAddPayment.current.showModal();
        };

        function _handleAddNewItem() {
            updateInvoiceDataContext({
                ...invoiceData,
                items: invoiceData.items.concat({
                    ...INVOICE_ITEM_SIMPLE,
                    id: uuidv4(),
                    type: isEstimateDynamic ? ESTIMATE_DYNAMIC_TYPE.REQUIRED : undefined
                })
            });
        }

        function _renderTaxs() {
            let resArr = [];
            const listTaxs = [...invoiceData.taxes];

            listTaxs.forEach(function (item) {
                let itemFind = {};

                itemFind = resArr.find((entry) => entry.id === item.id);

                if (!itemFind) {
                    resArr.push({ ...item });
                } else {
                    resArr = resArr.map((newItem) => {
                        if (newItem.id === item.id) {
                            const oldValue = parseFloat(itemFind?.total?.value);
                            return {
                                ...newItem,
                                total: {
                                    value: roundingNumber(oldValue + parseFloat(item.total.value))
                                }
                            };
                        }
                        return newItem;
                    });
                }
            });

            return resArr.map((itemTax) => {
                return (
                    <div key={itemTax.id} className="sm-row is-tax-value">
                        <div className="txt">{`${itemTax.name} (${itemTax.rate}%)`}</div>
                        <div className="detail txt-ellipsis">{itemTax.total.value}</div>
                    </div>
                );
            });
        }

        function _addPaymentSuccess(balance, credit) {
            updateInvoiceDataContext({
                ...invoiceData,
                available_balance: balance,
                available_credit: credit
            });
            onUpdateBalance(balance, credit);
        }

        if (isEstimatePackage) return null;

        return (
            <div className="content-elm-edit rows items-payment">
                <div className="content-elm-edit action-additems">
                    <div className="v2-btn-default has-icon" onClick={_handleAddNewItem} tabIndex="0">
                        <IconPlus />
                        {t('common:add_item')}
                    </div>
                </div>
                <div className="content-elm-edit items-payment-number">
                    <div className="sm-row">
                        <div className="txt"> {t('common:subtotal')} </div>
                        <div className="detail txt-ellipsis">{invoiceData?.subtotal || '0.00'}</div>
                    </div>
                    <div className="sm-row edit-discount">
                        <Discount isInvoice={isInvoice} showDiscountRepeat={showDiscountRepeat} />
                        {parseFloat(invoiceData.discount.total.value) !== 0 && (
                            <div className="detail txt-ellipsis">{invoiceData.discount.total.value}</div>
                        )}
                    </div>

                    {isInvoice && parseFloat(valueDeposit) > 0 && (
                        <div className="sm-row">
                            <div className="txt"> {t('report:deposit')} </div>
                            <div className="detail txt-ellipsis">{transformToCurrency(valueDeposit, currency)}</div>
                        </div>
                    )}

                    {_renderTaxs()}

                    <div className="sm-row">
                        <div className="txt"> Total </div>
                        <div className="detail txt-ellipsis">{invoiceData?.total || '0.00'}</div>
                    </div>

                    {!isInvoice && (
                        <div className="sm-row edit-discount">
                            <Deposit />
                            {parseFloat(valueDeposit) > 0 && <div className="detail txt-ellipsis">{valueDeposit}</div>}
                        </div>
                    )}

                    {isAddPayment && (
                        <Fragment>
                            <div className="line" />
                            <div className="sm-row">
                                <div className="txt"> {t('jobDetail:balance_due')} </div>
                                <div className="detail --large txt-ellipsis">
                                    {invoiceData?.available_balance?.format || '0.00'}
                                </div>
                            </div>
                        </Fragment>
                    )}

                    {invoiceData.id && isInvoice && isAddPayment ? (
                        <div className="mt-2">
                            <div
                                onClick={_handleAddPayment}
                                className="v2-btn-main has-icon svg-white add-payment fs-14"
                            >
                                <IconPlus />
                                {t('customers:add_payment')}
                            </div>
                        </div>
                    ) : null}
                </div>
                {invoiceData.id && isInvoice ? (
                    <Payment
                        invoiceNumber={invoiceData.invoice_no}
                        balanceDue={invoiceData?.available_balance}
                        customerCredit={invoiceData?.available_credit}
                        customer={invoiceData.customer}
                        invoiceStatus={invoiceData.invoice_status_id}
                        addPaymentSuccess={_addPaymentSuccess}
                        ref={refAddPayment}
                    />
                ) : null}
            </div>
        );
    }
);

export default InvoiceSummary;

import React, { createContext, useReducer, useEffect } from 'react';
import { clientQuery } from 'common/utils/ApiUtils';
import { initDataCustomer } from 'app/const/Api';
import {
    PAYMENT_METHODS,
    SURCHARGE_METHOD_CONFIG,
    INVOICE_STATUS,
    DEFAULT_METHOD_PAYMENT,
    LIST_METHOD_SURCHARGE
} from 'app/modules/jobdetail/const/Invoice';
import moment from 'moment';
import { reducer } from 'app/const/Reducer';
export const AddPaymentContext = createContext();

const AddPaymentProvider = ({ paymentData, children, isDeposit = false }) => {
    const [state, dispatchState] = useReducer(reducer, _preData());
    const customerId = state.customerId;

    useEffect(() => {
        if (customerId) {
            clientQuery(initDataCustomer(customerId), { method: 'GET' }, _getCustomerDataSuccess);
        }
    }, [customerId]);

    function _getCustomerDataSuccess(response) {
        const dataReponse = response.data;
        dispatchState({
            balanceDue: dataReponse.balance,
            customerCredit: dataReponse.credit,
            depositData: dataReponse.deposit,
            balanceDueValue: dataReponse.balance.value
        });
    }

    function _preData() {
        return {
            ...paymentData,
            paymentSelected: DEFAULT_METHOD_PAYMENT,
            amountValue: paymentData.deposit || 0,
            newCredit: 0,
            invoices: [], //List invoice of customer
            invoiceSelected: [], //List invoice selected
            surchargeData: [], //List tax of surcharge
            date: moment().format('YYYY-MM-DD'),
            valuePayment: '', //Content of memo of check,
            activeCredit: false, //Check have use method credit ot not. If it === true. Can't edit amount value and show selected credit.
            isReloadInvoice: 0,
            checkAll: false, //Flag is check All invoices,
            check_value: '',
            isDeposit,
            depositData: 0,
            activeDeposit: false
        };
    }

    function _handleChangeData(newData) {
        dispatchState({ ...newData });
    }

    function _handleChangeDataCallBack(callBack) {
        dispatchState((prevState) => {
            return callBack(prevState);
        });
    }

    /**
     * totalInvoice count total all invoice selected.
     * totalSurcharge count subcharge for each invoice. Will count with payment > 0
     * totalInvoiceBalance count total all invoice for minus balance value selected have status != draf
     * @param {*} newData
     * @returns
     */
    function _handleCalculateNumberPayment(newData) {
        const newInvoiceChecked = [...newData.invoiceSelected];
        const { isEditAmount, isDeposit, deposit: depositValue } = newData;
        const amountValue = Number(newData.amountValue);
        const paymentMethodId = newData.paymentSelected.id;
        const checkActiveSurcharge = LIST_METHOD_SURCHARGE.includes(paymentMethodId) && !!newData?.activeSurcharge;
        const taxes = newData.surchargeData || [];
        const isMethodCredit = paymentMethodId === PAYMENT_METHODS.CREDIT;

        let amountValueEdit = Number(amountValue);
        let newInvoices = [...newData.invoices];
        let totalInvoice = isDeposit ? depositValue : 0;
        let totalSurcharge = 0;
        let totalInvoiceBalance = 0;

        newInvoices = newInvoices.map((item) => {
            item.payment = 0;
            return item;
        });

        if (!isEditAmount) {
            newInvoices = newInvoices.map((item) => {
                if (newInvoiceChecked.includes(item.number)) {
                    const newPayment = item.amount_due.value;
                    totalInvoice += newPayment;
                    item.payment = newPayment;
                    if (checkActiveSurcharge) {
                        totalSurcharge += _calculateSurCharge({
                            amountValue: newPayment,
                            taxes,
                            surchargeMethod: paymentMethodId
                        });
                    }
                    if (item.status !== INVOICE_STATUS.DRAFT) {
                        totalInvoiceBalance += newPayment;
                    }
                }
                return item;
            });
        } else {
            newInvoiceChecked.forEach((item) => {
                newInvoices = newInvoices.map((itemFind) => {
                    const finalMountDue = itemFind.amount_due.value;
                    if (item === itemFind.number) {
                        const newPayment = amountValueEdit < finalMountDue ? amountValueEdit : finalMountDue;

                        totalInvoice += newPayment;
                        itemFind.payment = newPayment;
                        amountValueEdit = amountValueEdit < finalMountDue ? 0 : amountValueEdit - finalMountDue;

                        if (checkActiveSurcharge) {
                            totalSurcharge += _calculateSurCharge({
                                amountValue: newPayment,
                                taxes,
                                surchargeMethod: paymentMethodId
                            });
                        }

                        if (itemFind.status !== INVOICE_STATUS.DRAFT) {
                            totalInvoiceBalance += newPayment;
                        }
                    }
                    return itemFind;
                });
            });
        }

        const finalBalanceDueValue = (newData?.balanceDue?.value || 0) - totalInvoiceBalance;
        /**
         * With the credit method we deduct surcharge, other methods do not
         */
        const surChargeWithCredit = isMethodCredit ? totalSurcharge : 0;

        const getNewCredit = () => {
            if (!isDeposit) {
                return amountValue - totalInvoice - surChargeWithCredit;
            }
            const newAmount = parseFloat(amountValue) - parseFloat(totalInvoice);

            return newAmount > 0 ? newAmount : 0;
        };

        const finalAmountValue = isEditAmount ? parseFloat(amountValue) : parseFloat(totalInvoice);
        const finalCreditValue = isEditAmount ? getNewCredit() : 0;

        const getSurchargeDeposit = () => {
            if (!checkActiveSurcharge || !isDeposit) {
                return totalSurcharge;
            }

            return _calculateSurCharge({
                amountValue: finalAmountValue - finalCreditValue,
                taxes,
                surchargeMethod: paymentMethodId
            });
        };

        const finalSurcharge = getSurchargeDeposit();

        return {
            ...newData,
            amountValue: finalAmountValue,
            paymentApplied: finalAmountValue - finalCreditValue + finalSurcharge,
            invoices: newInvoices,
            newCredit: finalCreditValue,
            activeSurcharge: checkActiveSurcharge,
            surchargeValue: finalSurcharge,
            balanceDueValue: finalBalanceDueValue < 0 ? 0 : finalBalanceDueValue
        };
    }

    function _calculateSurCharge({ amountValue, taxes = [], surchargeMethod = '' }) {
        const surchargeConfig = SURCHARGE_METHOD_CONFIG[surchargeMethod];
        if (!amountValue || !surchargeConfig) return 0;

        const { FEE_PER, PERCENT_FEE, MIN, MAX } = surchargeConfig;
        let newFee = (amountValue * FEE_PER) / 100 + PERCENT_FEE;
        if (!!MIN && newFee < MIN) newFee = MIN;
        else if (!!MAX && newFee > MAX) newFee = MAX;

        let totalTaxes = 0;
        taxes.forEach((rate) => {
            totalTaxes += (newFee * rate) / 100;
        });

        return newFee + totalTaxes;
    }

    function _handleNumberPayment(newData) {
        dispatchState((prevState) => {
            return _handleCalculateNumberPayment({ ...prevState, ...newData });
        });
    }

    return (
        <AddPaymentContext.Provider
            value={{
                addPayment: state,
                updatePaymentDataContext: _handleChangeData,
                updatePaymentDataCallback: _handleChangeDataCallBack,
                updateNumberPaymentContext: _handleNumberPayment
            }}
        >
            {children}
        </AddPaymentContext.Provider>
    );
};

export default AddPaymentProvider;

import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconPlus from 'assets/icon/IconPlus';

const ItemAddTag = ({ onAddTag = () => {} }, ref) => {
    const { t } = useTranslation();
    const [isDisplay, setIsDisplay] = useState(false);
    const refTag = useRef(null);

    useImperativeHandle(ref, () => ({
        display: _handleDisplay,
        hide: _handleHide,
        setTag: _handleSetTag
    }));

    const _handleDisplay = () => {
        !isDisplay && setIsDisplay(true);
        refTag.current && refTag.current.classList.remove('dp-hide');
    };

    const _handleHide = () => {
        isDisplay && setIsDisplay(false);
        refTag.current && refTag.current.classList.add('dp-hide');
    };

    const _handleSetTag = (tag) => {
        setTimeout(() => {
            if (refTag.current) refTag.current.innerText = tag;
        }, 0);
    };

    const _handleAdd = () => {
        const content = refTag.current.textContent;
        content.trim().length && onAddTag(content);
    };

    if (!isDisplay) return null;
    return (
        <div className="content-checked__all">
            <div ref={refTag} className="txt-ellipsis tag-label" />
            <div className="v2-btn-default has-icon svg-purple-fill fs-13" onClick={_handleAdd}>
                <IconPlus />
                <span className="flex-1 txt-ellipsis" title={t('common:add_new_tag')}>
                    {t('common:add_new_tag')}
                </span>
            </div>
        </div>
    );
};

export default forwardRef(ItemAddTag);

import { Fragment, React, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { SETTINGS_PLANS } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import { ADDONS_GROUP_ITEM } from 'app/const/addons';
import IconStar from 'assets/icon/IconStar';
import IconUpgrade from 'assets/icon/IconUpgrade';
import { clientQuery } from 'common/utils/ApiUtils';
import AddonsDescription from './AddonsDescription';
import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';

const OptionItems = ({ urlUpdate, name, keyword, description, value, upgrade, onUpdateSuccess = () => {} }) => {
    const { t } = useTranslation(['addons']);
    const isGroup = ADDONS_GROUP_ITEM.includes(keyword);

    const _handleChange = ({ checked }) => {
        const _handleSuccess = (res) => {
            if (!isGroup) return;
            onUpdateSuccess(res);
        };

        clientQuery(urlUpdate, { data: { value: checked ? 1 : 0, keyword }, method: 'PUT' }, _handleSuccess);
    };

    const propsItem = { value, upgrade, keyword, onChangeSwitch: _handleChange };

    if (!keyword) return null;

    return (
        <Fragment>
            <div className="boxs__header pt-1 pb-1">
                <div className="header-title">
                    {!!name && (
                        <Fragment>
                            <div className="title">{name}</div>
                            <div className="dots powerups">
                                <IconUpgrade isFillOrange />
                                {t('power_up')}
                            </div>
                        </Fragment>
                    )}
                </div>
                {isGroup ? <SwitchAddonItem {...propsItem} /> : null}
            </div>

            <div className="boxs__contents">
                <AddonsDescription keyword={keyword} description={description} />
                {!isGroup ? <SwitchAddonItem {...propsItem} /> : null}
            </div>
        </Fragment>
    );
};

export const SwitchAddonItem = ({ value = 0, upgrade = 1, keyword = '', onChangeSwitch = () => {} }) => {
    const { t } = useTranslation(['addons']);
    const [isEnabled, setIsEnabled] = useState(!!value);

    const _handleChange = (checked) => {
        setIsEnabled(checked);
        onChangeSwitch({ checked });
    };

    return !!!upgrade ? (
        <GdSwitchCheckbox
            id={keyword}
            wrapClassName="switch large"
            inputClassName="toggle toggle-round"
            textUnChecked={t('common:disabled')}
            textChecked={t('common:enabled')}
            defaultChecked={isEnabled}
            onChangeImmediately={_handleChange}
        />
    ) : (
        <Link to={addBranchPath(SETTINGS_PLANS)} className="btn-upgrage svg-star-white">
            <IconStar />
            <span className="line">{t('addons:upgrade')}</span>
        </Link>
    );
};

export default OptionItems;

import React from 'react';
import { useTranslation } from 'react-i18next';

const PlansTable = ({ plans = [], total }) => {
    const { t } = useTranslation();
    const _renderListPlan = () => {
        return plans.map((item) => {
            return (
                <div key={item.name} className="rows --modify">
                    <div className="col fw-600">{item.name}</div>
                    <div className="col">{item.price}</div>
                    <div className="col --sm">{item.quantity}</div>
                    <div className="col fw-600 --remove">
                        <p className="txt-ellipsis">{item.total}</p>
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="plans-content mt-9">
            <div className="header">
                <div className="title-x-sm">{t('common:plans')}</div>
            </div>
            <div className="content">
                <div className="rows --modify --header">
                    <div className="col fw-600">{t('common:price_plan')}</div>
                    <div className="col fw-600">{t('common:price_details')}</div>
                    <div className="col fw-600 --sm">{t('common:qty')}</div>
                    <div className="col fw-600 --remove">{t('common:total')}</div>
                </div>
                {_renderListPlan()}
                <div className="rows --modify">
                    <div className="col" />
                    <div className="col" />
                    <div className="col --sm">{t('common:total')}</div>
                    <div className="col fw-600 --remove">
                        <p className="txt-ellipsis">{total}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlansTable;

import GdButton from 'app/components/button';
import { LIST_STATUS } from 'app/const/Status';
import { getListTaxes } from 'common/redux/actions/taxesAction';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useDispatch } from 'react-redux';
import LoadingSurchargeManage from './LoadingSurchargeManage';
import SurchargeTaxDropdown from './SurchargeTaxDropdown';
import IconClose from 'assets/icon/IconClose';
import { reducer } from 'app/const/Reducer';
import classNames from 'classnames';
import GDStatusBar from 'app/components/status/statusbar';
import { SURCHARGE_PAYMENT } from 'app/const/addons';

const SurchargeAddonsManager = ({ keyword, onClose = () => {}, onDisplayAlert = () => {} }, ref) => {
    const { t } = useTranslation(['common', 'addons']);
    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        isLoadingSurcharge: true,
        isLoading: false,
        surcharge: {},
        isDisable: false
    });
    const { endPoint, title } = SURCHARGE_PAYMENT[keyword];

    const refForm = useRef(null);
    const refFirstTax = useRef(null);
    const refSecondTax = useRef(null);
    const refAlert = useRef(null);

    const dispatch = useDispatch();
    const { surcharge, isLoadingSurcharge, isDisable } = state;

    useImperativeHandle(ref, () => ({ _open, _close }));

    // Open the modal, and the server is queried to get the data surcharge
    const _open = () => {
        dispatchState({ isOpen: true });
        // eslint-disable-next-line no-undef
        !global.fetchedListTax && _handleGetListTaxes();
        _handleGetSurcharge();
    };

    const _handleGetListTaxes = () => {
        dispatch(getListTaxes({ fields: 'rate' }, () => {}));
    };

    // It makes a request to the server to get the surcharge data.
    const _handleGetSurcharge = () => {
        const _handleGetSuccess = ({ data }) => dispatchState({ surcharge: data, isLoadingSurcharge: false });

        const _handleGetFail = ({ message }) => {
            dispatchState((prev) => ({ ...prev, isDisable: true, isLoadingSurcharge: false }));
            refAlert.current?.showStatusBar('error', message, LIST_STATUS.ERROR);
        };

        clientQuery(endPoint, { data: {}, method: 'GET' }, _handleGetSuccess, _handleGetFail);
    };

    /**
     * The function _close() is called when the user clicks on the close button.
     * It calls onClose() and then sets the state of isOpen to false.
     */
    const _close = () => {
        onClose();
        dispatchState({
            isOpen: false,
            isLoadingSurcharge: true,
            isLoading: false,
            surcharge: {}
        });
    };

    const _handleUpdateSurcharge = () => {
        dispatchState({ isLoading: true });

        const dataUpdate = {
            item_tax1: refFirstTax.current._getValue(),
            item_tax2: refSecondTax.current._getValue()
        };
        const elements = refForm.current.elements;

        for (let index = 0; index < elements.length; index++) {
            const { name, value, type } = elements[index];
            if (type === 'text') dataUpdate[name] = value;
        }

        clientQuery(
            endPoint,
            {
                data: { surcharge: { ...dataUpdate } },
                method: 'PUT',
                toFormData: false
            },
            _handleUpdateSuccess
        );
    };

    const _handleUpdateSuccess = () => {
        onDisplayAlert({ id: 'success', message: t('addons:update_successfully'), type: LIST_STATUS.SUCCESS });
        _close();
    };

    if (!state.isOpen) return null;
    return (
        <ReactModal
            id="manage_stripe"
            isOpen={state.isOpen}
            style={{ overlay: { background: 'transparent' } }}
            className="modal --managestripe container-modal open"
            onRequestClose={_close}
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className="modal__container large">
                <div className="header-modal">
                    <h3 className="header-modal__label">{`${t(`addons:manage`)} ${t(`customers:${title}`)}`}</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                        <IconClose />
                    </div>
                </div>
                <GDStatusBar ref={refAlert} />

                {isLoadingSurcharge ? (
                    <LoadingSurchargeManage />
                ) : (
                    <>
                        <div className={classNames('body-modal has-form scrolls', { 'is-disable': isDisable })}>
                            <form ref={refForm} onSubmit={_handleUpdateSurcharge}>
                                <div className="rows">
                                    <div className="txt">{t('addons:note')}</div>
                                    <input
                                        name="item_note"
                                        type="text"
                                        className="field-input"
                                        placeholder={t('common:message_paying_card')}
                                        autoFocus
                                        defaultValue={surcharge.item_note}
                                    />
                                    <p className="rows__note">{t('addons:square_surcharge_note_description')}</p>
                                </div>

                                <div className="rows mt-3">
                                    <div className="txt">{t('common:item')}</div>
                                    <input
                                        name="item_name"
                                        type="text"
                                        className="field-input"
                                        placeholder={t('common:merchant_surcharge.')}
                                        defaultValue={surcharge.item_name}
                                    />
                                    <p className="rows__note">{t('addons:square_surcharge_name_description')}</p>
                                </div>
                            </form>

                            <div className="rows --multi mt-3">
                                <div className="col">
                                    <div className="txt">{t('common:tax1')}</div>
                                    <SurchargeTaxDropdown
                                        id="item_tax1"
                                        ref={refFirstTax}
                                        defaultSelected={surcharge.item_tax1}
                                    />
                                </div>
                                <div className="col">
                                    <div className="txt">{t('common:tax2')}</div>
                                    <SurchargeTaxDropdown
                                        id="item_tax2"
                                        ref={refSecondTax}
                                        defaultSelected={surcharge.item_tax2}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="footer-modal btn-close">
                            <span className="v2-btn-default --transparent" onClick={_close}>
                                {t('cancel')}
                            </span>
                            <GdButton
                                isLoading={state.isLoading}
                                className="v2-btn-main"
                                onClick={_handleUpdateSurcharge}
                                disabled={isDisable}
                            >
                                {t('save')}
                            </GdButton>
                        </div>
                    </>
                )}
            </div>
        </ReactModal>
    );
};

SurchargeAddonsManager.displayName = 'SurchargeAddonsManager';

export default forwardRef(SurchargeAddonsManager);

/* eslint-disable no-unused-vars */
import { getListReportRevenueByStaffDetail } from 'common/redux/actions/reports/revenueByStaffAction';
import { getGidColumnsDetailTab } from 'app/const/report/RevenueByStaff';
import { ACCESS_TOKEN } from 'app/const/App';
import { getGidColumnsMonthTab } from 'app/const/report/RevenueByStaff';
import { getLocalStorageValue } from 'common/utils/LocalStorageUtils';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import loadable from '@loadable/component';
import { getParamsRevenuePage } from 'common/utils/ReportUtils';

const GdGridRowTotal = loadable(() => import('app/components/grid/GdGridRowTotal'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const ReportRevenuePopupDetail = loadable(() => import('app/modules/report/components/ReportRevenuePopupDetail'));

function MonthTabGridView({ dataReport, paramsReport, handleActionHeader = () => {}, handleOpenInvoice, monthTab }) {
    const refPopup = useRef(null);
    const getColumnsMonthTab = getGidColumnsMonthTab(paramsReport?.orderMonth);

    const handleClickTable = ({ key, row }) => {
        refPopup.current.openPopup({ ...getParamsRevenuePage({ key, paramsReport }), staff: row.user_id });
    };

    return (
        <div className="tab-conts tab-content-active">
            <GdGridView
                isEmptyFlat
                isLoading={dataReport.isLoading}
                classTable="--revenue table-multi-column scrolls-x has-text-ellipsis"
                classTableContent="--hastotal"
                content={dataReport?.data}
                fileTranslation={'report'}
                handleClick={(event) => {
                    handleClickTable(event);
                }}
                handleClickHeader={handleActionHeader}
                {...getColumnsMonthTab}
                rowTotal={(props) => (
                    <GdGridRowTotal
                        columns={dataReport?.rowTotal}
                        contentConfig={getColumnsMonthTab?.contentConfig}
                        showCheckBox={false}
                        isLoading={dataReport.isLoading}
                        {...props}
                    />
                )}
            />

            <ReportRevenuePopupDetail
                ref={refPopup}
                functionCallAPI={getListReportRevenueByStaffDetail}
                girdColumn={getGidColumnsDetailTab}
                keyTotalRow={'total_payments_received'}
                handleOpenInvoice={handleOpenInvoice}
                monthTab={monthTab}
            />
        </div>
    );
}

MonthTabGridView.propTypes = {
    dataReport: PropTypes.object
};

function handleExportMonthTab(isCsv, actionPrint, paramsReport) {
    const token = getLocalStorageValue(ACCESS_TOKEN);
    const typeExport = isCsv ? 'csv' : 'xls';
    const form = document.createElement('form');

    // form.action = actionPrint ? URL_PRINT_SALES_TAX_TAB : URL_EXPORT_SALES_TAX_TAB;

    // form.method = 'POST';
    // form.style.display = 'none';
    // form.className = 'export-sale-forecast-report';

    // form.innerHTML = `
    //     <input name="token" value="${token}" >
    //     <input name="type" value="${typeExport}" >
    //     <input name="keyword" value="${paramsReport.keyword}" >
    //     <input name="start" value="${paramsReport.start}" >
    //     <input name="end" value="${paramsReport.end}">
    //     <input name="revenue" value="${paramsReport.revenue}">
    //     <input name="payment_methods" value="${paramsReport.payment_methods}">
    // `;

    // form.setAttribute('target', '_blank');
    // document.body.append(form);
    // form.submit();

    // const elements = document.getElementsByClassName('export-sale-forecast-report');
    // elements.length > 0 && elements[0].parentNode.removeChild(elements[0]);
    return false;
}

export { MonthTabGridView, handleExportMonthTab };

import classNames from 'classnames';
import React, { Fragment, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import { KEY_DEFAULT, LIST_COLOR_BRANCH } from 'app/const/Branch';
import IconDropUpDown from 'assets/icon/IconDropUpDown';

const DropdownBranchColor = ({ style, value, item, notDisplayDefault = false, preventSetColor = false }) => {
    const { t } = useTranslation();
    const finalValue = value || KEY_DEFAULT;
    const refDropdown = useRef(null);

    useEffect(() => {
        if (preventSetColor)
            document
                .querySelector(`#${item.id}.rows`)
                ?.setAttribute('style', `--background: ${LIST_COLOR_BRANCH[finalValue].bgColor}`);
    }, [value]);

    const _renderButton = () => {
        const { color, name } = LIST_COLOR_BRANCH[finalValue] || {};

        return (
            <Fragment>
                <span className="dots" style={{ '--branch-color': color }} />
                <span className="mr-2">{t(`setting:${name}`)}</span>
                <IconDropUpDown />
            </Fragment>
        );
    };

    const _renderListItem = () => {
        return Object.entries(LIST_COLOR_BRANCH).map(([key, { color, name }]) => {
            const _handleClick = () => {
                refDropdown.current._close();
                item.onChangeColor({ id: item.id, color: key });
            };
            if (notDisplayDefault && key === KEY_DEFAULT) return null;
            return (
                <li key={key} className={classNames('items', { active: key === finalValue })} onClick={_handleClick}>
                    <span className="dots" style={{ '--branch-color': color }} />
                    <span className="txt-ellipsis flex-1">{t(`setting:${name}`)}</span>
                </li>
            );
        });
    };

    return (
        <div className={style}>
            <DropdownPopper
                ref={refDropdown}
                wrapperClassName="v2-dropdown select-status"
                wrapperListClass="v2-dropdown__menu scrolls content-full popper-content --right"
                buttonClassName="dropbtn items"
                customButton={_renderButton()}
                isUseToggle
            >
                <ul>{_renderListItem()}</ul>
            </DropdownPopper>
        </div>
    );
};

export const ColumnCount = ({ style, value }) => {
    return <div className={style}>{value || 0}</div>;
};

export default DropdownBranchColor;

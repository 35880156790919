import classNames from 'classnames';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import IconDoubleStep from 'assets/icon/IconDoubleStep';
import { DEFAULT_CURRENCY_SYMBOL } from 'app/const/App';

const AddonGetStarted = ({
    currencySymbol = DEFAULT_CURRENCY_SYMBOL,
    upgrade = 0,
    isActivePlan = false,
    textGettingStarted = '',
    textActive = '',
    textInActive = '',
    linkLearnMore = '',
    textLearnMore = '',
    balance = 0,
    userQuantity = 0,
    textButton = '',
    isShowManageButton = true,
    onClickButton = () => {}
}) => {
    const { t } = useTranslation('addons');

    return (
        <Fragment>
            <div className={classNames('boxs boxs-has-footer active', { 'is-disable': !!upgrade })}>
                <div className="flex-column gap-8 p-4">
                    <h4 className="fw-500">{t(textGettingStarted)}</h4>
                    <p className="mb-1">{t(isActivePlan ? textActive : textInActive)}</p>
                    <a href={linkLearnMore} target="_blank" rel="noreferrer" className="is-link fw-500">
                        {t(textLearnMore)}
                        <IconDoubleStep isPurple />
                    </a>
                    {isActivePlan ? (
                        <Fragment>
                            <div className="is-divider --horizontal" />
                            <div className="flexcenter blue-default fw-600">
                                <span>{t('credit_balance_money', { balance, symbol: currencySymbol })}</span>
                                {userQuantity ? (
                                    <span className="dots">{t('activated_users', { count: userQuantity })}</span>
                                ) : null}
                            </div>
                        </Fragment>
                    ) : null}
                </div>
                {isShowManageButton ? (
                    <div className="boxs__footer">
                        <div className="v2-btn-default btn-bg-purple --icon-r --sm" onClick={onClickButton}>
                            {t(textButton)}
                            <IconDoubleStep isPurple />
                        </div>
                    </div>
                ) : null}
            </div>
            <div className="is-divider --horizontal my-3" />
        </Fragment>
    );
};

export default AddonGetStarted;

export default function IconMicrophone({ isSmGreen = false, isSmGrey = false, isNormal = false }) {
    if (isSmGreen)
        return (
            <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width={16} height={16} rx={8} fill="#1EAA5C" />
                <rect
                    x="6.25"
                    y="3.3335"
                    width="3.5"
                    height="5.83333"
                    rx="1.75"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.758333"
                />
                <path
                    d="M5.08331 6.8335V7.41683C5.08331 9.02766 6.38915 10.3335 7.99998 10.3335V10.3335C9.61081 10.3335 10.9166 9.02766 10.9166 7.41683V6.8335"
                    stroke="white"
                    strokeWidth="0.758333"
                    strokeLinecap="round"
                />
                <path
                    d="M8 10.3335V12.6668"
                    stroke="white"
                    strokeWidth="0.758333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.25 12.6665H9.75"
                    stroke="white"
                    strokeWidth="0.758333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );

    if (isSmGrey)
        return (
            <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width={16} height={16} rx={8} fill="#999999" />
                <rect
                    x="6.25"
                    y="3.3335"
                    width="3.5"
                    height="5.83333"
                    rx="1.75"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.758333"
                />
                <path
                    d="M5.08331 6.8335V7.41683C5.08331 9.02766 6.38915 10.3335 7.99998 10.3335V10.3335C9.61081 10.3335 10.9166 9.02766 10.9166 7.41683V6.8335"
                    stroke="white"
                    strokeWidth="0.758333"
                    strokeLinecap="round"
                />
                <path
                    d="M8 10.3335V12.6668"
                    stroke="white"
                    strokeWidth="0.758333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.25 12.6665H9.75"
                    stroke="white"
                    strokeWidth="0.758333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.0834 3.3335L3.91669 11.5002"
                    stroke="#999999"
                    strokeWidth="1.75"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.0834 3.3335L3.91669 11.5002"
                    stroke="white"
                    strokeWidth="0.758333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );

    if (isNormal)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x={9} y={4} width={6} height={10} rx={3} stroke="#4C4C4C" strokeWidth="1.3" />
                <path
                    d="M7 10V11C7 13.7614 9.23858 16 12 16V16C14.7614 16 17 13.7614 17 11V10"
                    stroke="#4C4C4C"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                />
                <path d="M12 16V20" stroke="#4C4C4C" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9 20H15" stroke="#4C4C4C" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );

    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x={9} y={4} width={6} height={10} rx={3} stroke="var(--color-icon)" strokeWidth="1.3" />
            <path
                d="M7 10V11C7 13.7614 9.23858 16 12 16V16C14.7614 16 17 13.7614 17 11V10"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
            />
            <path d="M12 16V20" stroke="var(--color-icon)" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 20H15" stroke="var(--color-icon)" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19 4L5 18" stroke="var(--color-icon)" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

export const ESTIMATE_STATUS = {
    DRAFT: 'draft',
    PENDING: 'pending',
    WON: 'won',
    LOST: 'lost'
};

export const ESTIMATE_TYPE = {
    BASIC: 1,
    DYNAMIC: 2,
    PACKAGES: 3
};

export const LIST_ESTIMATE_TYPE = [
    {
        id: ESTIMATE_TYPE.BASIC,
        name: 'Basic',
        isDefault: true,
        key: 'basic'
    },
    {
        id: ESTIMATE_TYPE.DYNAMIC,
        name: 'Dynamic',
        description: 'estimate_dynamic_desc',
        key: 'estimate_dynamic'
    },
    {
        id: ESTIMATE_TYPE.PACKAGES,
        name: 'Packages',
        description: 'estimate_package_desc',
        key: 'estimate_package'
    }
];

export const ESTIMATE_DYNAMIC_TYPE = {
    REQUIRED: 1,
    RECOMMMENTED: 2,
    OPTIONAL: 3
};

export const LIST_ESTIMATE_DYNAMIC_TYPE = [
    {
        id: ESTIMATE_DYNAMIC_TYPE.REQUIRED,
        name: 'required',
        color: '#4d4d4d'
    },
    {
        id: ESTIMATE_DYNAMIC_TYPE.RECOMMMENTED,
        name: 'recommended',
        color: '#ff6e26'
    },
    {
        id: ESTIMATE_DYNAMIC_TYPE.OPTIONAL,
        name: 'optional',
        color: '#fb0'
    }
];

export const DEFAULT_VALUE_PACKAGE = {
    id: '',
    name: '',
    color: '#ff9823',
    discount: {
        type: 1,
        value: '',
        total: {
            value: 0,
            format: 0.0
        }
    },
    deposit: {
        value: '',
        type: 1,
        total: {
            value: 0,
            format: 0.0
        },
        invoices: [],
        is_paid: false
    },
    taxes: []
};

export const PACKAGE_LIST_COLOR = [
    {
        name: 'orange',
        color: '#ff9823',
        prev: '#444444'
    },
    {
        name: 'yellow',
        color: '#f6d21b',
        prev: '#ff9823'
    },
    {
        name: 'green',
        color: '#69c91a',
        prev: '#f6d21b'
    },
    {
        name: 'chlorine',
        color: '#1ab781',
        prev: '#69c91a'
    },
    {
        name: 'blue',
        color: '#4672b2',
        prev: '#1ab781'
    },
    {
        name: 'purple',
        color: '#9e4cde',
        prev: '#4672b2'
    },
    {
        name: 'pink',
        color: '#ea2485',
        prev: '#9e4cde'
    },
    {
        name: 'gray',
        color: '#444444',
        prev: '#ea2485'
    }
];

import React, { useContext, useEffect, useReducer } from 'react';
import Month from 'app/modules/jobdetail/tabs/settingschedule/except/Month';
import Codition from 'app/modules/jobdetail/tabs/settingschedule/except/Codition';
import Frequency from 'app/modules/jobdetail/tabs/settingschedule/except/Frequency';
import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';
import { TABS_EXCEPT, EXCEPTON_OPTIONS, CONDITION_1, CONDITION_2, CONDITION_3 } from 'app/modules/jobdetail/const';
import { useTranslation } from 'react-i18next';
import IconClose from 'assets/icon/IconClose';
import { reducer } from 'app/const/Reducer';

const Except = ({ customerId = '', showFrequency = true }) => {
    const { t } = useTranslation(['jobDetail']);

    const { repeatSettings, onChangeException } = useContext(DataRepeatContext);
    const { options: jobExceptionDetail, id: exceptionid } = repeatSettings.exception;

    const [state, dispatchState] = useReducer(reducer, _getActiveTab(parseInt(jobExceptionDetail?.first) || 0));

    const finalIsTabActive = state.tabActive;
    const isActiveTabOff = finalIsTabActive === TABS_EXCEPT.OFF;
    const isActiveTabMonth = finalIsTabActive === TABS_EXCEPT.MONTH;
    const isActiveTabCondition = finalIsTabActive === TABS_EXCEPT.CONDITION;
    const isActiveTabFrequency = finalIsTabActive === TABS_EXCEPT.RREQUENCE;

    useEffect(() => {
        dispatchState({ ..._getActiveTab(parseInt(jobExceptionDetail?.first) || 0) });
    }, [jobExceptionDetail?.first]);

    function _getActiveTab(flagMode) {
        let mode = TABS_EXCEPT.OFF;

        if (flagMode) {
            switch (flagMode) {
                case 1:
                    mode = TABS_EXCEPT.RREQUENCE;
                    break;
                case 2:
                case 3:
                case 4:
                case 5:
                    mode = TABS_EXCEPT.CONDITION;
                    break;
                default:
                    mode = TABS_EXCEPT.MONTH;
                    break;
            }
        }

        return {
            tabActive: mode
        };
    }

    function _handleChangeTab(e, value) {
        dispatchState({
            tabActive: value
        });

        let newException = {
            summary: '',
            id: exceptionid || '',
            options: EXCEPTON_OPTIONS
        };

        switch (value) {
            case TABS_EXCEPT.CONDITION:
                const activeTabOne = CONDITION_1[0];
                const activeTabTwo = CONDITION_2[0];
                const activeTabThere = CONDITION_3[0];
                newException = {
                    summary: `${t(`jobDetail:except`)} ${t(`jobDetail:txt_${activeTabOne.type}`)} ${t(
                        `jobDetail:txt_${activeTabTwo.type}`
                    )} ${t(`jobDetail:txt_${activeTabThere.type}`)}`,
                    options: {
                        ...EXCEPTON_OPTIONS,
                        first: activeTabOne.value,
                        second: activeTabTwo.value,
                        third: activeTabThere.value
                    },
                    id: exceptionid
                };
                break;
            case TABS_EXCEPT.MONTH:
                newException = {
                    summary: `${t(`jobDetail:except`)} ${t('common:January')}`,
                    options: {
                        ...EXCEPTON_OPTIONS,
                        first: '6'
                    },
                    id: exceptionid
                };
                break;
            case TABS_EXCEPT.RREQUENCE:
                newException = {
                    summary: '',
                    options: {
                        ...EXCEPTON_OPTIONS,
                        first: '1'
                    },
                    id: exceptionid
                };
                break;
            default:
                break;
        }

        onChangeException({ ...repeatSettings, exception: newException });
    }

    if (!repeatSettings.repeat) return false;

    return (
        <>
            <div className="rows tabs tabs-daily nav-tabs row-has-line border-top-border-color-grey">
                <div className="txt">
                    <IconClose isHasColor />
                    {t('jobDetail:except')}
                </div>
                <div className="btn-item m-0">
                    <button
                        className={`tab-items item-off ${isActiveTabOff ? 'active-tab-selector' : ''}`}
                        onClick={(e) => _handleChangeTab(e, TABS_EXCEPT.OFF)}
                        type="button"
                    >
                        {t('common:off')}
                    </button>
                    <button
                        className={`tab-items ${isActiveTabMonth ? 'active-tab-selector' : ''}`}
                        onClick={(e) => _handleChangeTab(e, TABS_EXCEPT.MONTH)}
                        type="button"
                    >
                        {t('common:month')}
                    </button>
                    <button
                        className={`tab-items ${isActiveTabCondition ? 'active-tab-selector' : ''}`}
                        type="button"
                        onClick={(e) => _handleChangeTab(e, TABS_EXCEPT.CONDITION)}
                    >
                        {t('jobDetail:condition')}
                    </button>
                    {showFrequency && (
                        <button
                            className={`tab-items ${isActiveTabFrequency ? 'active-tab-selector' : ''}`}
                            type="button"
                            onClick={(e) => _handleChangeTab(e, TABS_EXCEPT.RREQUENCE)}
                        >
                            {t('customers:frequency')}
                        </button>
                    )}
                </div>
            </div>
            <div className="tab-content row-has-line">
                <div className="tab-panel" />
                {isActiveTabMonth && <Month data={jobExceptionDetail} />}
                {isActiveTabCondition && <Codition />}
                <Frequency isActive={isActiveTabFrequency} jobId={repeatSettings.id} customerId={customerId} />
            </div>
        </>
    );
};

export default Except;

import classNames from 'classnames';
import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { updateToolTipSettings } from 'app/const/api/V2';
import { reducer } from 'app/const/Reducer';
import { BOX_TOOLTIP, LIST_TOOLTIP } from 'app/const/Settings';
import IconClose from 'assets/icon/IconClose';
import { clientQuery } from 'common/utils/ApiUtils';

const BoxTipsSetting = ({ typeId, isHiddenButtonClose = false, onAddNew = () => {} }) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        boxData: null
    });

    const auth = useSelector((state) => state.auth?.user?.settings?.inactive_tooltips || {});

    useEffect(() => {
        if (!auth.find((item) => item === typeId)) dispatchState({ boxData: BOX_TOOLTIP[typeId] });
    }, []);

    const boxData = state?.boxData || {};
    const finalBoxDataCreateNew = boxData.create_new ? t(`setting:${boxData.create_new}`) : '';

    const _handleSave = () => {
        boxData.isShow = false;
        dispatchState({ boxData });
        clientQuery(updateToolTipSettings(typeId), {
            method: 'PUT'
        });
    };

    const handleContactSupport = () => {
        try {
            window.Intercom('boot');
            window.Intercom('show');
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className={classNames('box-tips', { hide: !isHiddenButtonClose && !boxData.isShow })}>
            <div className="box-tips__content">
                <div className="tip">
                    <div className="title">
                        <span className="fw-600 fs-15">{t(`setting:${boxData.title}`)}</span>
                        {!isHiddenButtonClose ? (
                            <div className="cursor-pointer pl-2" onClick={_handleSave}>
                                <IconClose />
                            </div>
                        ) : null}
                    </div>
                    <div className="description fs-14">
                        {t(`setting:${boxData.description}`)}
                        {typeId === LIST_TOOLTIP.AUTOMATED_EMAILS ? (
                            <span
                                className="description__link purple-default cursor-pointer pl-1"
                                onClick={handleContactSupport}
                            >
                                {finalBoxDataCreateNew}
                            </span>
                        ) : (
                            <span className="description__link purple-default cursor-pointer pl-1" onClick={onAddNew}>
                                {finalBoxDataCreateNew}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BoxTipsSetting;

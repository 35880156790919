import { MIXPANEL_EVENT_NAME, MIXPANEL_METHOD, MIXPANEL_PROPERTIES } from 'app/const/Mixpanel';

export const mixpanelWatchVideo = ({ profile = {}, data = {} }) => {
    const peopleSet = {
        [MIXPANEL_PROPERTIES.VIDEO_NAME]: data.video_name,
        [MIXPANEL_PROPERTIES.PRESS_PLAY]: data.press_play ? 'True' : 'False',
        [MIXPANEL_PROPERTIES.PERCENT_VIEW]: data.percent_view,
        [MIXPANEL_PROPERTIES.VIEWER_EMAIL]: profile.email,
        [MIXPANEL_PROPERTIES.PLAN_TYPE]: profile.plan?.type.replace('_', ' '),
        [MIXPANEL_PROPERTIES.TERM_LENGTH]: profile.plan?.term_length,
        [MIXPANEL_PROPERTIES.PLAN_PRICE]: profile.plan?.price
    };

    return {
        type: MIXPANEL_EVENT_NAME.WATCH_VIDEO,
        data: {
            [MIXPANEL_METHOD.IDENTIFY]: profile.id,
            [MIXPANEL_METHOD.REGISTER]: peopleSet,
            [MIXPANEL_METHOD.SET]: peopleSet
        }
    };
};

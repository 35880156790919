import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

function CustomListOptions({ editorState, options = [], onToggle = () => {} }) {
    const { t } = useTranslation(['drip']);
    const selection = editorState.getSelection();
    const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

    if (!options.length) return null;
    return options.map((type, index) => {
        return (
            <div
                key={type.id}
                className={classNames('editor-controls__btn tooltip', {
                    active: type.style === blockType,
                    '--break': index + 1 === options.length
                })}
                onMouseDown={() => onToggle(type.value)}
            >
                {type.component}
                <span className="tooltiptext top">{t(`drip:${type.tooltip}`)}</span>
            </div>
        );
    });
}

export default React.memo(CustomListOptions);

export default ({
    color = 'var(--color-icon)',
    width = 24,
    height = 24,
    isHasColor = false,
    isActive = false,
    isContent = false,
    isOverride = false
}) => {
    if (isActive) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4.84892 10.3432C5.67224 7.26812 8.52386 5 11.9152 5C15.3255 5 18.1901 7.29358 18.9951 10.3949"
                    stroke="#1E7EF7"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.84892 13.6568C5.67224 16.7319 8.52386 19 11.9152 19C15.3255 19 18.1901 16.7064 18.9951 13.6051"
                    stroke="#1E7EF7"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.4676 9.8609L19.4198 11.0469L20.6281 7.16768"
                    stroke="#1E7EF7"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.53239 14.4809L4.58024 13.2949L3.37195 17.1741"
                    stroke="#1E7EF7"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
    if (isHasColor)
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.2631 4.11255C14.4992 4.11255 17.3377 6.03272 18.2206 8.80952C18.388 9.33584 18.097 9.89818 17.5707 10.0655C17.0443 10.2329 16.482 9.94189 16.3147 9.41557C15.7051 7.49862 13.6562 6.11255 11.2631 6.11255C9.0441 6.11255 7.12013 7.30441 6.36631 9.00755L9.01295 8.77652C9.52383 8.73183 9.97854 9.07956 10.0794 9.56997L10.0963 9.68556C10.141 10.1964 9.79326 10.6512 9.30285 10.752L9.18726 10.7689L4.20628 11.2047C3.6954 11.2494 3.24069 10.9017 3.1398 10.4112L3.12293 10.2957L2.68715 5.31468C2.63902 4.7645 3.04601 4.27946 3.59619 4.23133C4.10708 4.18663 4.56179 4.53437 4.66268 5.02478L4.67954 5.14037L4.88707 7.5236C6.12555 5.45674 8.55555 4.11255 11.2631 4.11255Z"
                    fill="#7A83A6"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.6436 12.589L19.6605 12.7046L20.0963 17.6856C20.1444 18.2357 19.7374 18.7208 19.1873 18.7689C18.6764 18.8136 18.2217 18.4659 18.1208 17.9755L18.1039 17.8599L17.8713 15.1966C16.7262 17.5817 14.1565 19.1738 11.2631 19.1738C8.03165 19.1738 5.2029 17.188 4.31278 14.3269C4.14871 13.7996 4.44321 13.2391 4.97056 13.075C5.49791 12.9109 6.05842 13.2054 6.22249 13.7328C6.84688 15.7397 8.88971 17.1738 11.2631 17.1738C13.5259 17.1738 15.4873 15.8703 16.2066 14.0112L13.7705 14.2237C13.2596 14.2684 12.8049 13.9207 12.704 13.4303L12.6872 13.3147C12.6425 12.8038 12.9902 12.3491 13.4806 12.2482L13.5962 12.2313L18.5772 11.7956C19.088 11.7509 19.5428 12.0986 19.6436 12.589Z"
                    fill="#C1C9D4"
                ></path>
            </svg>
        );
    if (isContent)
        return (
            <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width={18} height={18} rx={9} fill="#745BB0" />
                <path d="M9 7L13 4.5L9 2V7Z" fill="white" />
                <path
                    d="M13.5 9C13.5 9.89002 13.2361 10.76 12.7416 11.5001C12.2471 12.2401 11.5443 12.8169 10.7221 13.1575C9.89981 13.4981 8.99501 13.5872 8.12209 13.4135C7.24918 13.2399 6.44736 12.8113 5.81802 12.182C5.18868 11.5526 4.7601 10.7508 4.58647 9.87791C4.41283 9.00499 4.50195 8.10019 4.84254 7.27792C5.18314 6.45566 5.75991 5.75285 6.49993 5.25839C7.23996 4.76392 8.10998 4.5 9 4.5"
                    stroke="white"
                />
            </svg>
        );
    if (isOverride)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.6436 12.5891L19.6605 12.7047L20.0963 17.6856C20.1444 18.2358 19.7374 18.7208 19.1872 18.769C18.6763 18.8137 18.2216 18.4659 18.1207 17.9755L18.1039 17.8599L17.8713 15.1967C16.7261 17.5818 14.1565 19.1739 11.2631 19.1739C8.03162 19.1739 5.20287 17.188 4.31275 14.327C4.14868 13.7996 4.44317 13.2391 4.97053 13.0751C5.49788 12.911 6.05839 13.2055 6.22246 13.7328C6.84685 15.7398 8.88968 17.1739 11.2631 17.1739C13.5258 17.1739 15.4873 15.8704 16.2066 14.0113L13.7705 14.2238C13.2596 14.2685 12.8049 13.9207 12.704 13.4303L12.6871 13.3147C12.6424 12.8039 12.9902 12.3492 13.4806 12.2483L13.5962 12.2314L18.5771 11.7956C19.088 11.7509 19.5427 12.0986 19.6436 12.5891Z"
                    fill="#C1C9D4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.2631 4.11255C14.4992 4.11255 17.3377 6.03272 18.2206 8.80952C18.388 9.33584 18.097 9.89818 17.5707 10.0655C17.0443 10.2329 16.482 9.94189 16.3147 9.41557C15.7051 7.49862 13.6562 6.11255 11.2631 6.11255C9.0441 6.11255 7.12013 7.30441 6.36631 9.00755L9.01295 8.77652C9.52383 8.73183 9.97854 9.07956 10.0794 9.56997L10.0963 9.68556C10.141 10.1964 9.79326 10.6512 9.30285 10.752L9.18726 10.7689L4.20628 11.2047C3.6954 11.2494 3.24069 10.9017 3.1398 10.4112L3.12293 10.2957L2.68715 5.31468C2.63902 4.7645 3.04601 4.27946 3.59619 4.23133C4.10708 4.18663 4.56179 4.53437 4.66268 5.02478L4.67954 5.14037L4.88707 7.5236C6.12555 5.45674 8.55555 4.11255 11.2631 4.11255Z"
                    fill="#7A83A6"
                />
            </svg>
        );

    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.2542 10.0885C18.409 6.87222 15.4816 4.5 12 4.5C8.49901 4.5 5.55827 6.89885 4.73181 10.1426"
                stroke={color}
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.2542 13.5541C18.409 16.7703 15.4816 19.1426 12 19.1426C8.49901 19.1426 5.55827 16.7437 4.73181 13.5"
                stroke={color}
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.35311 9.584L4.29585 10.8244L3.05542 6.76717"
                stroke={color}
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.4728 14.416L19.5301 13.1756L20.7705 17.2328"
                stroke={color}
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default function IconPortal({ isGrey, isActive }) {
    if (isActive)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FFF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 8H24C24.5523 8 25 8.44772 25 9L24 18H8L7 9C7 8.44772 7.44772 8 8 8Z"
                    fill="#C0E5CF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.11728 9H23.8827C24.435 9 24.8827 9.44772 24.8827 10C24.8827 10.0369 24.8807 10.0738 24.8766 10.1104L24 18H8.00001L7.12339 10.1104C7.0624 9.56152 7.45794 9.06711 8.00684 9.00612C8.04351 9.00204 8.08038 9 8.11728 9Z"
                    fill="#1EAA5C"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.15302 10H22.847C23.3993 10 23.847 10.4477 23.847 11C23.847 11.0473 23.8436 11.0946 23.8369 11.1414L23 17H9.00001L8.16307 11.1414C8.08497 10.5947 8.46486 10.0882 9.0116 10.0101C9.05844 10.0034 9.1057 10 9.15302 10Z"
                    fill="#C0E5CF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.87868 19.1213L8 18H24L25.1213 19.1213C25.6839 19.6839 26 20.447 26 21.2426V22C26 22.5523 25.5523 23 25 23H7C6.44772 23 6 22.5523 6 22V21.2426C6 20.447 6.31607 19.6839 6.87868 19.1213Z"
                    fill="#C0E5CF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 21H26C26 22.1046 25.1046 23 24 23H8C6.89543 23 6 22.1046 6 21Z"
                    fill="#1EAA5C"
                />
                <path
                    d="M18 12C18 10.8954 17.1046 10 16 10C14.8954 10 14 10.8954 14 12C14 13.1046 14.8954 14 16 14C17.1046 14 18 13.1046 18 12Z"
                    fill="#1EAA5C"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 14C17.6569 14 19 15.3431 19 17H13C13 15.3431 14.3431 14 16 14Z"
                    fill="#1EAA5C"
                />
            </svg>
        );

    if (isGrey) {
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FAFBFC"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 8H24C24.5523 8 25 8.44772 25 9L24 18H8L7 9C7 8.44772 7.44772 8 8 8Z"
                    fill="#C1C9D4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.11728 9H23.8827C24.435 9 24.8827 9.44772 24.8827 10C24.8827 10.0369 24.8807 10.0738 24.8766 10.1104L24 18H8.00001L7.12339 10.1104C7.0624 9.56152 7.45794 9.06711 8.00684 9.00612C8.04351 9.00204 8.08038 9 8.11728 9Z"
                    fill="var(--color-icon)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.15302 10H22.847C23.3993 10 23.847 10.4477 23.847 11C23.847 11.0473 23.8436 11.0946 23.8369 11.1414L23 17H9.00001L8.16307 11.1414C8.08497 10.5947 8.46486 10.0882 9.0116 10.0101C9.05844 10.0034 9.1057 10 9.15302 10Z"
                    fill="#C1C9D4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.87868 19.1213L8 18H24L25.1213 19.1213C25.6839 19.6839 26 20.447 26 21.2426V22C26 22.5523 25.5523 23 25 23H7C6.44772 23 6 22.5523 6 22V21.2426C6 20.447 6.31607 19.6839 6.87868 19.1213Z"
                    fill="#C1C9D4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 21H26C26 22.1046 25.1046 23 24 23H8C6.89543 23 6 22.1046 6 21Z"
                    fill="var(--color-icon)"
                />
                <path
                    d="M18 12C18 10.8954 17.1046 10 16 10C14.8954 10 14 10.8954 14 12C14 13.1046 14.8954 14 16 14C17.1046 14 18 13.1046 18 12Z"
                    fill="var(--color-icon)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 14C17.6569 14 19 15.3431 19 17H13C13 15.3431 14.3431 14 16 14Z"
                    fill="var(--color-icon)"
                />
            </svg>
        );
    }
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                fill="#FAFBFC"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 8H24C24.5523 8 25 8.44772 25 9L24 18H8L7 9C7 8.44772 7.44772 8 8 8Z"
                fill="#dac2f2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.11728 9H23.8827C24.435 9 24.8827 9.44772 24.8827 10C24.8827 10.0369 24.8807 10.0738 24.8766 10.1104L24 18H8.00001L7.12339 10.1104C7.0624 9.56152 7.45794 9.06711 8.00684 9.00612C8.04351 9.00204 8.08038 9 8.11728 9Z"
                fill="#8c49fb"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.15302 10H22.847C23.3993 10 23.847 10.4477 23.847 11C23.847 11.0473 23.8436 11.0946 23.8369 11.1414L23 17H9.00001L8.16307 11.1414C8.08497 10.5947 8.46486 10.0882 9.0116 10.0101C9.05844 10.0034 9.1057 10 9.15302 10Z"
                fill="#dac2f2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.87868 19.1213L8 18H24L25.1213 19.1213C25.6839 19.6839 26 20.447 26 21.2426V22C26 22.5523 25.5523 23 25 23H7C6.44772 23 6 22.5523 6 22V21.2426C6 20.447 6.31607 19.6839 6.87868 19.1213Z"
                fill="#dac2f2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 21H26C26 22.1046 25.1046 23 24 23H8C6.89543 23 6 22.1046 6 21Z"
                fill="#8c49fb"
            />
            <path
                d="M18 12C18 10.8954 17.1046 10 16 10C14.8954 10 14 10.8954 14 12C14 13.1046 14.8954 14 16 14C17.1046 14 18 13.1046 18 12Z"
                fill="#8c49fb"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 14C17.6569 14 19 15.3431 19 17H13C13 15.3431 14.3431 14 16 14Z"
                fill="#8c49fb"
            />
        </svg>
    );
}

import classNames from 'classnames';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import { CUSTOMER_STATUSES } from 'app/const/Customers';
import IconArrowDown from 'assets/icon/IconArrowDown';

const StatusesCustomer = ({ selected = [], options = null, onChange = () => {} }) => {
    const refDropdown = useRef(null);
    const finalOptions = options || CUSTOMER_STATUSES;

    return (
        <DropdownPopper
            ref={refDropdown}
            id="select-condition"
            isUseToggle
            isCalculateWidth
            isCheckHidden
            wrapperClassName="v2-dropdown field"
            buttonClassName="dropbtn items"
            modifiers={[{ name: 'offset', options: { offset: [0, 5] } }]}
            customButton={<CustomButton selected={selected} options={finalOptions} />}
        >
            <ul>
                {finalOptions.map((item) => {
                    const value = item?.value?.toString();
                    const isSomeSelected =
                        Array.isArray(selected) && selected?.some((selectedItem) => selectedItem?.toString() === value);
                    const isActive = selected?.value?.toString() === value || isSomeSelected;
                    return <StatusesCustomerItem key={item.id} isActive={isActive} item={item} onChange={onChange} />;
                })}
            </ul>
        </DropdownPopper>
    );
};

const CustomButton = ({ selected = [], options = [] }) => {
    const { t } = useTranslation('smartView');
    const finalTitle = () => {
        if (!selected?.length || !Array.isArray(selected)) return t('choose_a_status');
        const arrayNames = [];
        selected.forEach((item) => {
            const name = options?.find((status) => status?.value?.toString() === item?.toString())?.name || '';
            if (name) arrayNames.push(t(`smartView:${name}`));
        });
        return arrayNames.join(', ');
    };
    return (
        <>
            <span className="txt-ellipsis mr-2">{t(`customers:${finalTitle()}`)}</span>
            <div className="arrow">
                <IconArrowDown />
            </div>
        </>
    );
};

const StatusesCustomerItem = ({ item, isActive = false, onChange = () => {} }) => {
    const { t } = useTranslation();
    const refInput = useRef(null);
    const idCheckbox = `${item.value}`;

    const _handleClick = () => {
        onChange(item?.value?.toString());
    };

    return (
        <li className={classNames('items', { active: isActive })} onClick={_handleClick}>
            <div className="check-items">
                <input
                    ref={refInput}
                    id={idCheckbox}
                    type="checkbox"
                    disabled
                    checked={isActive}
                    onChange={(e) => e.preventDefault()}
                />
                <div className="item-checkbox">
                    <label htmlFor={idCheckbox}>
                        <span className="txt-ellipsis">{t(`customers:${item.name}`)}</span>
                    </label>
                </div>
            </div>
        </li>
    );
};

StatusesCustomer.displayName = 'StatusesCustomer';
export default StatusesCustomer;

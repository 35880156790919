import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { NOTES_TEMPLATE_TYPE } from 'app/const/Notes';
import PaymentTermName from 'app/modules/location/components/PaymentTermName';

const TemplateName = ({ ...item }, ref) => {
    const { t } = useTranslation(['jobDetail']);
    const { type = NOTES_TEMPLATE_TYPE.CUSTOMER, isHeader = true, isInsertJobNote = false, name = '' } = item || {};

    switch (type) {
        case NOTES_TEMPLATE_TYPE.PAYMENT_TERMS:
            return <PaymentTermName ref={ref} {...item} />;
        default:
            if (isHeader) return <span className="txt-ellipsis">{t(isInsertJobNote ? 'insert' : 'templates')}</span>;
            return <p className="txt-ellipsis word-break flex-1">{name}</p>;
    }
};

export default forwardRef(TemplateName);

import {
    REPORT_PAYMENTS_COLLECTED_GET_DATA_CHART,
    REPORT_PAYMENTS_COLLECTED_GET_LIST,
    REPORT_PAYMENTS_COLLECTED_GET_LIST_YEARS,
    REPORT_PAYMENTS_COLLECTED_METHOD_FILTER,
    REPORT_PAYMENTS_COLLECTED_STAFFS_FILTER
} from 'app/const/Api';
import { fetchWithToken } from 'common/utils/ApiUtils';
import { call } from 'redux-saga/effects';

export function* getListReportPaymentsCollected({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_PAYMENTS_COLLECTED_GET_LIST, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getDataChartPaymentsCollected({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_PAYMENTS_COLLECTED_GET_DATA_CHART, params);
        if (response && response.success) {
            response.year = params.year;
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListYearsPaymentsCollected({ actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_PAYMENTS_COLLECTED_GET_LIST_YEARS);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListStaffFilterPaymentsCollected({ actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_PAYMENTS_COLLECTED_STAFFS_FILTER);
        if (response && response.success) {
            actionSuccess({ ...response, data: [...response.data, { id: -2, name: 'W/O Staff' }] });
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListMethodFilterPaymentsCollected({ actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_PAYMENTS_COLLECTED_METHOD_FILTER);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

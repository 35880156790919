import classNames from 'classnames';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import IconCogWheel from 'assets/icon/IconCogWheel';
import SettingsAfterHours from './afterHours/SettingsAfterHours';

const AddonsOptionsFooterSMS = ({ isEnabled = false, status = 0 }) => {
    const { t } = useTranslation('addons');
    const refManage = useRef(null);
    const displayManage = isEnabled && !!status;

    const _handleOpenManager = () => {
        refManage.current && refManage.current._open();
    };

    return (
        <div className="boxs__footer --multi">
            <p className="grey-extrlight txt-ellipsis">{t('addons:setup_sms_and_time_frame')}</p>
            <div
                onClick={_handleOpenManager}
                className={classNames('v2-btn-default svg-purple --purple --icon-r btn-modal', {
                    'is-disable': !displayManage
                })}
            >
                {t('manage')}
                <IconCogWheel />
            </div>
            {displayManage ? <SettingsAfterHours ref={refManage} /> : null}
        </div>
    );
};

export default AddonsOptionsFooterSMS;

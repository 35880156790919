import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import CalendarDatePicker from 'app/modules/calendar/header/components/date/DatePicker';
import { AddInvoiceContext } from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import IconCalendar from 'assets/icon/IconCalendar';
import { convertTimeToISO } from 'common/utils/DateUtils';

const DateIssue = ({ isInvoice = true }) => {
    const { t } = useTranslation();
    const { invoiceData, onUpdateInvoiceDataContext } = useContext(AddInvoiceContext);

    function _handleChangeDate(date) {
        const newDate = convertTimeToISO(moment(date)) || null;

        onUpdateInvoiceDataContext({
            date: newDate,
            recurrence: isInvoice
                ? {
                      ...invoiceData.recurrence,
                      offset: {
                          ...invoiceData.recurrence.offset,
                          nextDateInvoice: newDate,
                          dateIssue: newDate
                      }
                  }
                : null
        });
    }

    return (
        <div className="sm-row">
            <div className="txt">
                <IconCalendar />
                <span className="flex-1 txt-ellipsis" title={t('jobDetail:date_issued')}>
                    {t('jobDetail:date_issued')}
                </span>
            </div>
            <div className="detail">
                <CalendarDatePicker
                    onChange={_handleChangeDate}
                    showIconCalendar={false}
                    selectDefault={invoiceData.date}
                />
            </div>
        </div>
    );
};

export default DateIssue;

import ButtonSave from 'app/components/button/ButtonSave';
import { CUSTOMER_LIST_TASKS } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import Date from 'app/modules/customer/task/components/Date';
import Time from 'app/modules/customer/task/components/Time';
import SearchCustomer from 'app/modules/quickadd/components/SearchCustomer';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconClose from 'assets/icon/IconClose';
import { updateTaskStatus } from 'common/redux/actions/calendar/listTaskAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { combineDateTimeToISO } from 'common/utils/DateUtils';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import CalendarDropdown from '../CalendarDropdown';

const AddTaskModal = ({ onAddSuccess = () => {}, onAddFailed = () => {} }, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        defaultDate: null,
        defaultUserId: null
    });
    const calendarReducer = useSelector(({ calendar }) => calendar);

    const { schedules } = calendarReducer || {};

    const refButtonSave = useRef(null);
    const refAlert = useRef(null);
    const refTaskName = useRef(null);
    const refAssign = useRef(null);
    const refTaskDate = useRef(null);
    const refTaskTime = useRef(null);
    const refCustomer = useRef(null);

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = (data) => {
        dispatchState({ isOpen: true, ...data });
    };

    const _close = () => {
        dispatchState({ isOpen: false });
        refCustomer.current = null;
    };

    const _handleSelectCustomer = (value, dataSelect) => {
        refCustomer.current = dataSelect;
    };

    const _handleSubmitAdd = () => {
        const finalDate = combineDateTimeToISO(refTaskDate.current.getValue(), refTaskTime.current.getValue());

        if (!refCustomer.current) {
            refAlert.current.showStatusBar({
                id: 'add-task-error',
                message: 'Customer cannot be blank',
                type: LIST_STATUS.ERROR
            });
            refButtonSave.current.removeLoading();
            return;
        }

        const dataCreate = {
            title: refTaskName.current.value,
            start: finalDate,
            end: finalDate,
            date: finalDate,
            active: 1,
            job: null,
            customer: {
                ...refCustomer.current,
                full_name: `${refCustomer.current.first_name} ${refCustomer.current.last_name}`
            },
            assignee: refAssign.current.getValue(),
            completed_at: null,
            completed_by: null,
            type: 'task'
        };

        clientQuery(
            CUSTOMER_LIST_TASKS,
            {
                data: {
                    title: refTaskName.current.value,
                    date: finalDate,
                    assignee: refAssign.current.getValue().id,
                    type: 1,
                    type_id: refCustomer.current?.id || '',
                    schedules: schedules.map((item) => item.id).join(','),
                    // eslint-disable-next-line no-undef
                    socket_id: global.socket_id_task_service
                },
                toFormData: false,
                method: 'POST'
            },
            ({ data }) => _handleAddSuccess({ ...dataCreate, id: data.id, schedule: data.schedule }),
            _handleAddFailed
        );
    };

    const _handleAddSuccess = (dataCreate) => {
        if (dataCreate.schedule) onAddSuccess(dataCreate);
        dispatch(updateTaskStatus({ ...dataCreate, typeAction: 'create' }));
        _close();
    };

    const _handleAddFailed = ({ message }) => {
        onAddFailed(message);
        refAlert.current.showStatusBar({ id: 'add-task-error', message, type: LIST_STATUS.ERROR });
        refButtonSave.current.removeLoading();
    };

    const _handleAfterOpen = () => {
        refTaskName.current.focus();
    };

    if (!state.isOpen) return null;
    return (
        <ReactModal
            isOpen
            id="add_task"
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal --viewtask-details open"
            onAfterOpen={_handleAfterOpen}
            onRequestClose={_close}
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className="modal__container tasks-list">
                <div className="header-modal">
                    <h2 className="header-modal__label">{t('add_a_task')}</h2>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                        <IconClose />
                    </div>
                </div>

                <div className="body-modal scrolls">
                    <div className="box-task is-edited">
                        <div className="form-editing">
                            <div className="form-editing__content has-form">
                                <AlertCustomer ref={refAlert} />
                                <div className="rows">
                                    <div className="txt">{t('assign_to')}</div>
                                    <AssignTo ref={refAssign} defaultValue={state.defaultUseId} />
                                </div>

                                <div className="rows mt-3">
                                    <div className="txt">{t('customer')}</div>
                                    <SearchCustomer
                                        classWrapper="v2-dropdown --large"
                                        onSelect={_handleSelectCustomer}
                                        icon={<IconArrowDown />}
                                    />
                                </div>

                                <div className="rows row-haft mt-3">
                                    <Date ref={refTaskDate} defaultValue={state.defaultDate} />
                                    <Time ref={refTaskTime} />
                                </div>

                                <div className="rows --edit-field mt-3">
                                    <p className="txt">{t('task_')}</p>
                                    <textarea
                                        ref={refTaskName}
                                        className="field-textarea --no-resize --h-120"
                                        placeholder={t('customers:what_do_you_need_to_do')}
                                        defaultValue={''}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-modal btn-close">
                    <div className="v2-btn-default --transparent" onClick={_close}>
                        {t('cancel')}
                    </div>
                    <ButtonSave ref={refButtonSave} className="v2-btn-main" onSave={_handleSubmitAdd}>
                        {t('save')}
                    </ButtonSave>
                </div>
            </div>
        </ReactModal>
    );
};

const AssignTo = forwardRef(({ defaultValue = null }, ref) => {
    const companyUsers = useSelector((state) => state.companyUsers.users || []);
    const userData = useSelector(({ auth }) => auth.user.profile);

    const [state, dispatchState] = useReducer(reducer, {
        selected:
            companyUsers.find((item) => parseInt(item.id) === parseInt(defaultValue || userData.id)) ||
            companyUsers[0] ||
            {}
    });

    const { selected } = state;
    const refDropdownAssign = useRef(null);

    useImperativeHandle(ref, () => ({ getValue: () => selected }));

    const _handleSelect = (value) => {
        dispatchState({ selected: companyUsers.find((item) => item.id === value) });
    };

    return (
        <CalendarDropdown
            id="add_task_assign"
            ref={refDropdownAssign}
            selected={selected.full_name}
            selectedOption={selected}
            options={companyUsers}
            keyGetKey="id"
            keyGetValue="id"
            keyGetName="full_name"
            onSelect={_handleSelect}
        />
    );
});

export default forwardRef(AddTaskModal);

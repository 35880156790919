import React, { useContext, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import TemplateOptions from 'app/components/templatenotes';
import { NOTES_TEMPLATE_TYPE } from 'app/const/Notes';
import { PERMISSIONS } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import Options from 'app/modules/customer/note/detail/Options';
import { UnitDetailContext } from 'app/modules/jobdetail/contexts/UnitDetailContext';
import IconTopNote from 'assets/icon/IconTopNote';
import { checkPermission } from 'common/utils/PermissionUtils';
import Edit from './Edit';

export default function TopNote() {
    const { t } = useTranslation();
    const [state, setState] = useReducer(reducer, {
        isVisibleEdit: false
    });
    const refContentNote = useRef(null);
    const { unitDetailContextData, updateUnitDetailContextData } = useContext(UnitDetailContext);
    const permissionsList = useSelector((state) => state.auth.user?.permissions?.enabled || []);
    const { top_note: topNoteContent } = unitDetailContextData;
    const finalIvisibleEdit = state.isVisibleEdit;

    function _openFormEdit() {
        setState({
            isVisibleEdit: true
        });
    }

    function _closeFormEdit() {
        setState({
            isVisibleEdit: false
        });
    }

    function _handleDelete() {
        updateUnitDetailContextData({
            top_note: t('common:this_note_will_appear_on_all_unit')
        });
    }

    function _handleSelectTemplate({ content: newContent }) {
        refContentNote.current.setValue(newContent, true);
    }

    return (
        <div className="dashboard-wrapper --main">
            <div className="content-top-notes">
                <div className="boxs --topnote">
                    <div className={`content elm-parent ${finalIvisibleEdit ? 'has-edited' : ''}`}>
                        <div className="header-box">
                            <div className="header-box__icons">
                                <IconTopNote />
                            </div>
                            <div className="name">
                                <h5 className="name__label">{`${unitDetailContextData.buildingName} ${unitDetailContextData.unitName}'s ${t('customers:customer_top_note')}`}</h5>
                            </div>
                            {checkPermission(permissionsList, PERMISSIONS.editOrDeleteTopNote) && (
                                <div className="header-box__action">
                                    <Options
                                        openFormEdit={_openFormEdit}
                                        onDelete={_handleDelete}
                                        addCommment={false}
                                        textDelete={t('jobDetail:remove_top_note')}
                                        textEdit={t('jobDetail:edit_top_note')}
                                        isHaveValue={!!topNoteContent}
                                        content={topNoteContent}
                                    />
                                </div>
                            )}
                            <TemplateOptions
                                isShow={finalIvisibleEdit}
                                typeFilter={NOTES_TEMPLATE_TYPE.TOP}
                                onSelectTemplate={_handleSelectTemplate}
                            />
                        </div>
                        <div className="wrap-content is-complete">
                            <div className="wrap-content-box">
                                <div className="description" dangerouslySetInnerHTML={{ __html: topNoteContent }} />
                            </div>
                        </div>
                        {finalIvisibleEdit && <Edit ref={refContentNote} onClose={_closeFormEdit} />}
                    </div>
                </div>
            </div>
        </div>
    );
}

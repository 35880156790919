import React, { useReducer, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { KEY_REPORT_LOCAL_STORAGE, LIST_STATUS } from 'app/const/App';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { ALL_SUBSCRIPTION_COLUMNS, SUBSCRIPTION_LIST_FILTER } from 'app/const/report/ReportFilter';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { checkAddon } from 'common/utils/AddonUtils';
import { getGridColumns } from 'app/const/report/Subscriptions';
import { URL_EXPORT_SUBSCRIPTION } from 'app/const/api/Export';
import { LIST_EXPORT } from 'app/const/report/Common';
import EditSubscription from 'app/modules/subscription/edit';
import { reducer } from 'app/const/Reducer';
import loadable from '@loadable/component';
import { REPORT_SUBSCRIPTION_GET_LIST } from 'app/const/Api';
import { clientQuery } from 'common/utils/ApiUtils';
import { REPORT_LIMIT } from 'app/const/Reports';
import { PAYMENT_CARD_CUSTOMER, MERCHANT_ID } from 'app/const/Customers';
import { getLocalParamsReport } from 'common/utils/ReportUtils';
import HeaderBottom from '../components/HeaderBottom';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';

const Export = loadable(() => import('app/modules/report/components/Export'));
const GdGridRowTotal = loadable(() => import('app/components/grid/GdGridRowTotal'));
const ReportSearch = loadable(() => import('app/modules/report/components/ReportSearch'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const MainHeaderReport = loadable(() => import('app/modules/report/components/MainHeader'));
const StatusBar = loadable(() => import('app/components/status'));
const ErrorPage = loadable(() => import('app/modules/error'));
const SubscriptionCancelModal = loadable(
    () => import('app/modules/subscription/add/components/SubscriptionCancelModal')
);
const AlertCustomer = loadable(() => import('app/modules/customer/components/AlertCustomer'));

function ReportSubscription({ type = PAYMENT_CARD_CUSTOMER.STRIPE }) {
    const { t } = useTranslation(['report']);
    const refEditSubscription = useRef(null);
    const refCancelSubscription = useRef(null);
    const refAlert = useRef(null);
    const abortController = useRef(null);

    const { auth, companyUsers } = useSelector((state) => state || {});
    const addonsList = auth.user.settings.addons || {};

    const [dataReport, dispatchActionReport] = useReducer(reducer, {
        refresh: false,
        dataProgressBar: false,
        dataStatusBar: [],
        data: [],
        rowTotal: 0,
        isLoading: true,
        isLoadMore: false,
        total: 0,
        totalSubscription: 0,
        refreshScreen: 0,
        reloadFilter: 0
    });
    const {
        refreshScreen,
        data: finalData,
        isLoading: finalIsLoading,
        isLoadMore: finalLoadMore,
        total: finalTotal
    } = dataReport;

    function _closeStatusBar(id) {
        const listDataStatusBar = [...dataReport.dataStatusBar];

        dispatchActionReport({
            dataStatusBar: listDataStatusBar.filter((item) => item.id !== id)
        });
    }

    const isStripe = type === PAYMENT_CARD_CUSTOMER.STRIPE;
    const exportType = REPORT_TYPE.SUBSCRIPTION[type];
    const keyLocalStorage = KEY_REPORT_LOCAL_STORAGE.concat('_', exportType);
    const paramsReport = getLocalParamsReport(keyLocalStorage, exportType);

    useEffect(() => {
        getListReport(true, paramsReport);
    }, []);

    useEffect(() => {
        if (finalLoadMore) {
            setTimeout(() => {
                getListReport(
                    false,
                    {
                        ...getLocalStorage(keyLocalStorage),
                        offset: finalData.length,
                        reloadFilter: dataReport.reloadFilter
                    },
                    true
                );
            }, 200);
        }
    }, [finalLoadMore]);

    const getListReport = (isReset = false, params, notShowLoading = false) => {
        refAlert.current?.closeStatusBar();
        abortController.current = new AbortController();
        params = getNewAPIRequest(params);
        const checkRefresh = dataReport.reloadFilter;
        const checkShouldLoading = !notShowLoading && !finalIsLoading;
        const columns = params?.columns;

        if (checkShouldLoading) {
            dispatchActionReport((prev) => ({
                ...prev,
                data: isReset ? [] : prev.data,
                isLoading: !!checkShouldLoading || prev.isLoading
            }));
        }

        delete params?.columns;
        delete params?.filterTab;
        delete params?.currentPage;
        delete params?.staffs;

        clientQuery(
            REPORT_SUBSCRIPTION_GET_LIST,
            { data: params, method: 'GET', abortController: abortController.current },
            (response) => getListSuccess(response, columns, checkRefresh),
            getListFailed
        );
    };

    const getNewAPIRequest = (params) => {
        if (!params.total) {
            params.total = 1;
        }
        params.plans = (isStripe ? params.plans?.toString() : params.square_plans?.toString()) || '';
        params.techs = params.staffs?.toString() || '';
        params.limit = REPORT_LIMIT;
        params.merchant = MERCHANT_ID[type];
        return params;
    };

    const getListSuccess = ({ data, total, total_subscription, isReset }, columns, tabQuery) => {
        dispatchActionReport((prev) => {
            const checkDiffrentTabs = !!tabQuery && prev.reloadFilter !== tabQuery;
            data = data.map((item) => {
                return {
                    ...item,
                    id: item.subscription_id
                };
            });
            const newData = isReset ? data : checkDiffrentTabs ? prev.data : [...prev.data, ...data];

            return {
                ...prev,
                isLoading: false,
                isLoadMore: false,
                data: newData,
                total: total,
                totalSubscription: total_subscription,
                refreshScreen: prev.refreshScreen + 1
            };
        });

        handleCreateRowTotal(total_subscription, columns, total);
    };

    function getListFailed({ message }) {
        _handleShowStatusBar({ message });
        dispatchActionReport((prev) => ({ ...prev, isLoading: false, isLoadMore: false }));
    }

    const handleCreateRowTotal = (data, columns, total) => {
        let totalColumns = ['customer', ...columns, 'edit'];
        totalColumns = ALL_SUBSCRIPTION_COLUMNS.filter((col) => totalColumns.includes(col));
        if (columns.length) {
            totalColumns = totalColumns.map((column) => {
                if (data[column]) {
                    return { id: column, isShow: true, totalAmount: data[column], isCurrency: true };
                }
                return { id: column, isShow: true };
            });
        } else {
            totalColumns = [
                {
                    id: 'customer',
                    isShow: true
                }
            ];
        }
        totalColumns[0].title = `${t('report:total')} ${total || dataReport.total} ${t('report:subscriptions')}`;

        dispatchActionReport({ rowTotal: totalColumns });
    };

    const handleRecountRowTotal = ({ itemSuccess, totalSubscription, rowTotal, newTotal }) => {
        const newTotalSubscription = { ...totalSubscription };

        newTotalSubscription.total_value -= itemSuccess.total_value;

        const newRowTotal = rowTotal.map((item) => {
            return Object.keys(newTotalSubscription).includes(item.id)
                ? { ...item, totalAmount: newTotalSubscription[item.id] }
                : item;
        });
        newRowTotal[0].title = `${t('report:total')} ${newTotal} ${t('report:subscriptions')}`;

        return { newTotalSubscription, newRowTotal };
    };

    const handleChangeFilter = (params, mode) => {
        if (mode && mode === 'columns') {
            dispatchActionReport({ refresh: !dataReport.refresh });
            handleCreateRowTotal(dataReport.totalSubscription, params.columns);
        }
    };

    const _handleUpdate = () => {
        abortController.current.abort();
        getListReport(true, getLocalStorage(keyLocalStorage));
    };

    const _handleEditSubscription = (subscription) => {
        refEditSubscription.current._open(
            { ...subscription.customer, account_customer_id: subscription.account_customer_id || '' },
            subscription,
            true
        );
    };

    const _handleCancelSubscription = (subscription) => {
        refCancelSubscription.current._open(subscription);
    };

    const _handleEditSuccess = (subscription, message, selectedPlans) => {
        refEditSubscription.current._close();
        const { subscription_id, upcoming, end_date, payment_card } = subscription;
        const index = finalData.findIndex((item) => item.subscription_id === subscription_id);
        if (index > -1) {
            let totalValue = 0;
            const plansName = [];

            selectedPlans.forEach((plan) => {
                const { deleted, amount, qty, name } = plan;
                if (!deleted) {
                    totalValue += amount * qty;
                    plansName.push(name);
                }
            });

            dispatchActionReport((prev) => {
                const { data, rowTotal, totalSubscription } = prev;
                const newData = [...data];
                newData[index] = {
                    ...newData[index],
                    total_value: totalValue,
                    plan: plansName.join(', '),
                    next_invoice: upcoming,
                    end_date,
                    payment_card
                };

                const newRowTotal = rowTotal.map((item) => {
                    const itemId = item.id;
                    return Object.keys(totalSubscription).includes(itemId)
                        ? { ...item, totalAmount: totalSubscription[itemId] + totalValue - data[index].total_value }
                        : item;
                });

                return {
                    ...prev,
                    data: newData,
                    rowTotal: newRowTotal
                };
            });
        }

        _handleShowStatusBar({
            id: LIST_STATUS.SUCCESS,
            message: message || t('report:subscription_updated_successfully'),
            type: LIST_STATUS.SUCCESS
        });
    };

    const _handleCancelSuccess = (subscription, message, immediately) => {
        _handleShowStatusBar({
            id: LIST_STATUS.SUCCESS,
            message: message || t('report:subscription_canceled_successfully'),
            type: LIST_STATUS.SUCCESS
        });

        const { period_end, end_date, subscription_id } = subscription;

        const endDate = new Date(end_date);
        const today = new Date();
        const index = finalData.findIndex((item) => item.subscription_id === subscription_id);

        if (immediately || today.getTime() >= endDate.getTime()) {
            switch (getLocalStorage(keyLocalStorage).status) {
                case -1:
                    if (index > -1) {
                        dispatchActionReport((prev) => {
                            const newData = { ...prev };
                            newData.data[index] = { ...newData.data[index], status: 'canceled' };

                            return newData;
                        });
                    }
                    break;
                case 1:
                case 2:
                    dispatchActionReport((prev) => {
                        const { data, total, totalSubscription, rowTotal } = prev;
                        const newTotal = total - 1;
                        let itemSuccess = null;
                        const newData = data.filter((item) => {
                            if (item.subscription_id !== subscription_id) return true;
                            itemSuccess = item;
                            return false;
                        });
                        const { newTotalSubscription, newRowTotal } = handleRecountRowTotal({
                            itemSuccess,
                            totalSubscription,
                            rowTotal,
                            newTotal
                        });

                        return {
                            ...prev,
                            data: newData,
                            total: newTotal,
                            rowTotal: newRowTotal,
                            totalSubscription: newTotalSubscription
                        };
                    });
                    break;
                default:
                    break;
            }
        } else {
            if (index > -1 && type === PAYMENT_CARD_CUSTOMER.SQUARE) {
                dispatchActionReport((prev) => {
                    const newData = { ...prev };
                    const dataIndex = newData.data[index];
                    const newEndDate = dataIndex.end_date === 'N/A' ? period_end : dataIndex.end_date;
                    newData.data[index] = { ...dataIndex, end_date: newEndDate };

                    return newData;
                });
            }
        }
    };

    const _handleScroll = () => {
        const numberData = finalData.length;
        if (!!numberData && numberData < finalTotal && !finalIsLoading) {
            const refreshId = Date.now();
            dispatchActionReport((prev) => ({ ...prev, isLoadMore: true, reloadFilter: refreshId }));
        }
    };
    const _handleCancelFail = (message) => {
        _handleShowStatusBar({ message: message || t('report:subscription_canceled_fail') });
    };

    function _renderStatusBars() {
        return dataReport.dataStatusBar.map((item) => {
            return (
                <StatusBar
                    key={item.id}
                    message={item.message}
                    type={item.type}
                    id={item.id}
                    closeStatusBar={_closeStatusBar}
                />
            );
        });
    }

    function _renderHeaderRight() {
        return (
            <>
                <Export
                    title={t('report:records', { count: finalTotal || 0 })}
                    activePrint
                    url={URL_EXPORT_SUBSCRIPTION}
                    params={paramsReport}
                    pageExport={LIST_EXPORT.EXPORT_REPORT_SUBSCRIPTION[type]}
                    isDisable={finalIsLoading}
                    refresh={refreshScreen}
                />
                <ReportSearch reportType={exportType} placeholder={t('report:search')} onKeyEnter={_handleUpdate} />
            </>
        );
    }

    if (isStripe) {
        if (!checkAddon(addonsList?.stripe?.subscription)) {
            return <ErrorPage />;
        }
    } else {
        if (!checkAddon(addonsList?.square?.square_subscription)) {
            return <ErrorPage />;
        }
    }

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: REPORT_TYPE.SUBSCRIPTION[type],
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleUpdate
        });
    };

    const _handleShowStatusBar = ({ id = LIST_STATUS.ERROR, message, type = LIST_STATUS.ERROR }) => {
        refAlert.current?.showStatusBar({ id, message, type });
    };

    return (
        <>
            <MainHeaderReport contentRight={_renderHeaderRight} reportType={exportType} onSelectTab={_handleUpdate} />
            <div className="wrapper-columns">
                <div className="container-print contents-pages gap-8">
                    <AlertCustomer ref={refAlert} />

                    <div className="wrap-tables flex-column relative">
                        <HeaderBottom
                            classNameHeader="header --filter"
                            typeReport={exportType}
                            filters={SUBSCRIPTION_LIST_FILTER[type]}
                            handleChangeFilter={handleChangeFilter}
                            handleUpdate={_handleUpdate}
                            companyUsers={companyUsers.users || []}
                            isLoading={finalIsLoading}
                            isNotShowDateRange
                        />
                        {_renderStatusBars()}
                        <GdGridView
                            isEmptyFlat
                            isLoading={finalIsLoading}
                            classTable="table-multi-column scrolls-x has-text-ellipsis"
                            classTableContent="--hastotal"
                            content={finalData.filter((item) => !item.manualHide)}
                            fileTranslation={'report'}
                            showCheckBox={false}
                            isHasDropdown
                            onCancelSubscription={_handleCancelSubscription}
                            onEditSubscription={_handleEditSubscription}
                            {...getGridColumns(paramsReport?.columns, type, paramsReport?.order)}
                            rowTotal={(props) => (
                                <GdGridRowTotal
                                    columns={dataReport.rowTotal}
                                    contentConfig={getGridColumns(paramsReport?.columns, type)?.contentConfig}
                                    showCheckBox={false}
                                    {...props}
                                />
                            )}
                            isScroll
                            showRowTotalOnTop
                            isLoadmore={finalLoadMore}
                            onScrollToEnd={_handleScroll}
                            autoLoad={false}
                            handleClickHeader={_handleActionHeader}
                        />
                    </div>

                    <EditSubscription
                        ref={refEditSubscription}
                        typeSubscription={type}
                        onEditSuccess={_handleEditSuccess}
                    />
                    <SubscriptionCancelModal
                        ref={refCancelSubscription}
                        typeSubscription={type}
                        onCancelSuccess={_handleCancelSuccess}
                        onCancelFail={_handleCancelFail}
                    />
                </div>
            </div>
        </>
    );
}

export default ReportSubscription;

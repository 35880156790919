import { transformToCurrency } from 'common/utils/NumberUtils';
import { PRICE_TRANSCRIPTIONS } from '.';
import store from 'common/redux/store/configureStore';
import { ACCOUNT_ROLE } from 'app/const/App';

export const calculatePriceTranscription = ({ duration, currency }) => {
    return transformToCurrency(Math.ceil(duration / 60) * PRICE_TRANSCRIPTIONS, currency);
};

export const getClassNameRoleCall = (isUser = false) => {
    return isUser ? '--user' : '--customer';
};

export const createElementHighlight = ({ className = 'txt-highlight', content = '' }) => {
    const element = document.createElement('span');
    element.className = className;
    element.textContent = content;
    return element;
};
export const isUserOwnerVoip = ({ value = 0 }) => {
    const { role: userRole, id: userId } = store.getState().auth.user.profile;
    return userRole === ACCOUNT_ROLE.SUPERADMIN && value === String(userId);
};

import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import LoadingSmsList from 'app/components/loading/sms/LoadingSmsList';
import GDModalWarning from 'app/components/modal/ModalWarning';
import { getPortalConversation } from 'app/const/api/V2';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import Header from './Header';
import ItemPortalConversation from './ItemPortalConversation';

const PortalConversation = (props, ref) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, isLoading: true, data: {}, conversation: [] });
    const { isOpen, isLoading, data, conversation } = state;
    const { summary = '', customer } = data || {};
    const { full_name = '' } = customer || {};

    const refConfirm = useRef();

    useImperativeHandle(ref, () => ({ open: _getListPortalSummary, close: _handleClose }));

    const _getListPortalSummary = (data) => {
        const { customer, id } = data || {};
        const { id: customerId } = customer || {};
        dispatchState((prev) => ({ ...prev, isOpen: true, isLoading: true, data }));
        const _handleSuccess = ({ data }) => {
            dispatchState((prev) => ({ ...prev, conversation: data || [], isLoading: false }));
        };
        const _handleFail = ({ message }) => {
            dispatchState((prev) => ({ ...prev, isLoading: false, conversation: [] }));
            refConfirm.current && refConfirm.current._open({ description: message });
        };
        clientQuery(
            getPortalConversation(id || 0),
            { data: { customer_id: customerId || 0 }, method: 'GET' },
            _handleSuccess,
            _handleFail
        );
    };

    const _handleClose = () => {
        dispatchState((prev) => ({ ...prev, isOpen: false, data: {}, conversation: [] }));
    };

    if (!isOpen) return null;

    return (
        <div className="box-report-sms boxs-notification">
            <div className="conversation-sms notification-dropdown active">
                <Header fullName={full_name} summary={summary} handleClose={_handleClose} />
                {isLoading ? (
                    <LoadingSmsList />
                ) : (
                    <div className="message-contents">
                        {!!conversation.length
                            ? conversation.map((item) => <ItemPortalConversation key={item.id} {...item} />)
                            : null}
                    </div>
                )}
                <GDModalWarning
                    ref={refConfirm}
                    isLargeContent={false}
                    title={t('addons:warning')}
                    buttonConfirm
                    onConfirm={_handleClose}
                    onClose={_handleClose}
                />
            </div>
        </div>
    );
};

export default forwardRef(PortalConversation);

import { getListPlan } from 'app/const/Api';
import IconArrow from 'assets/icon/IconArrow';
import IconPlus from 'assets/icon/IconPlus';
import IconSearch from 'assets/icon/IconSearch';
import IconWarning from 'assets/icon/IconWarning';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { SubscriptionContext } from '../../context/SubscriptionContext';
import PlanAddModal from './PlanAddModal';
import PlanList from './PlanList';
import { reducer } from 'app/const/Reducer';

const PlanListModal = forwardRef(({ isSquare, customerId }, ref) => {
    const { t } = useTranslation(['common', 'customers']);
    const { selectedPlans, typePlanSelect, _handleSelectPlan } = useContext(SubscriptionContext);
    const [state, dispatchState] = useReducer(reducer, { data: [], isOpen: false, isLoading: true });
    const refAddModal = useRef(null);
    const refIsFirstTime = useRef(true);
    const refData = useRef([]);

    const plans = state.data;
    const haveSelectedPlan = !!selectedPlans.length;

    useImperativeHandle(ref, () => ({ _open, _close }));

    useEffect(() => {
        if (state.isOpen && refIsFirstTime.current) _getListPlan();
    }, [state.isOpen]);

    const _open = () => {
        if (haveSelectedPlan) {
            dispatchState({
                data: _handleFilterPlan(plans),
                isOpen: true
            });
        } else {
            dispatchState({ isOpen: true });
        }
    };

    const _close = () => {
        dispatchState({ isOpen: false, data: refData.current });
    };

    const _getListPlan = () => {
        if (!customerId) return;

        const _success = ({ data }) => {
            dispatchState({
                data: selectedPlans.length ? _handleFilterPlan(data) : data,
                isLoading: false
            });
            refIsFirstTime.current = false;
            refData.current = data;
        };

        const _failed = () => dispatchState({ data: [], isLoading: false });
        /**
         * get list plan
         * data: { merchant: isSquare ? 2 : 1 }
         * 1: stripe
         * 2: square
         */
        clientQuery(
            getListPlan(customerId),
            { data: { merchant: isSquare ? 2 : 1 }, method: 'GET' },
            _success,
            _failed
        );
    };

    const _handleCreatePlan = () => refAddModal.current._open();

    const _handleCreateSuccess = (newPlan) => {
        if (!haveSelectedPlan || (haveSelectedPlan && _checkMathPlanSelected(newPlan))) {
            dispatchState({ data: [newPlan, ...plans] });
        }
        refData.current = [newPlan, ...plans];
    };

    const _handleSelect = (plan) => {
        _close();
        _handleSelectPlan(plan);
    };

    const _checkMathPlanSelected = (plan) => {
        let result = true;
        for (var key in typePlanSelect) {
            if (plan[key] === undefined || plan[key] !== typePlanSelect[key]) result = false;
        }
        return result;
    };

    const _handleFilterPlan = (data) =>
        data.filter((item) => {
            for (var key in typePlanSelect) {
                if (item[key] === undefined || item[key] !== typePlanSelect[key]) return false;
            }
            return true;
        });

    const _handleSearch = (e) => {
        const valueSearch = e.target.value;

        const _handleGetValueSearch = (key = '') => {
            return key.toLowerCase().includes(valueSearch.toLowerCase());
        };

        const result = refData.current.filter((obj) => {
            if (haveSelectedPlan && !_checkMathPlanSelected(obj)) return false;
            if (valueSearch.length) return _handleGetValueSearch(obj.name) || _handleGetValueSearch(obj.id.toString());
            return true;
        });

        dispatchState({ data: result });
    };

    return (
        <>
            <ReactModal
                id="plan_list_modal"
                isOpen={state.isOpen}
                style={{ overlay: { zIndex: 99, background: 'transparent' } }}
                className="modal container-modal form-stripe-subscription --add-plan open"
                onRequestClose={_close}
            >
                <div className="modal__overlay bg-fixed" onClick={_close} />
                <div className="modal__container">
                    <div className="header-modal">
                        <div className="d-flex align-center">
                            <div className="v2-btn-default --icon-lg --transparent btn-back" onClick={_close}>
                                <IconArrow isPrev />
                            </div>
                            <h3 className="header-modal__label">{t('customers:plan')}</h3>
                        </div>
                        <div className="d-flex align-center">
                            <div className="search-input">
                                <span className="svg-search-absolute">
                                    <IconSearch />
                                </span>
                                <input type="text" placeholder={t('addons:search')} onChange={_handleSearch} />
                            </div>
                            <div className="btn-action ml-11">
                                <div className="v2-btn-main btn-modal has-icon svg-white" onClick={_handleCreatePlan}>
                                    <IconPlus />
                                    {t('common:create_plan')}
                                </div>
                            </div>
                        </div>
                    </div>
                    {state.isLoading ? (
                        <LoadingList />
                    ) : (
                        <div className="body-modal flex-column scrolls pt-2">
                            {!!haveSelectedPlan && <WarningInfo />}
                            <div className="plan-list flex-column__content">
                                <PlanList data={plans} onSelect={_handleSelect} />
                            </div>
                        </div>
                    )}
                </div>
            </ReactModal>
            <PlanAddModal
                ref={refAddModal}
                isSquare={isSquare}
                customerId={customerId}
                onCreateSuccess={_handleCreateSuccess}
            />
        </>
    );
});

const WarningInfo = () => {
    const { t } = useTranslation();
    return (
        <div className="alert --info">
            <p className="alert__description svg-attention-blue">
                <IconWarning />
                <span className="txt">{t('common:warning_add_plan')}</span>
            </p>
        </div>
    );
};

const LoadingList = () => (
    <div className="wrap-loading --stripe-subscription body-modal">
        <div className="alert --info d-flex align-center bg-white-grey">
            <div className="loading --animation --line --threefourth" />
        </div>
        <div className="plan-list scrolls">
            <div className="rows">
                <div className="col --icon d-flex align-center">
                    <div className="v2-btn-default --icon-sm --transparent loading bg-white-cultured" />
                </div>
                <div className="col --info">
                    <div className="loading --animation --line --half" />
                </div>
                <div className="col --price flex-1">
                    <div className="loading --animation --line --twothird" />
                </div>
                <div className="col --action">
                    <div className="v2-btn-default loading --grey btn-x-sm">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
            </div>
            <div className="rows">
                <div className="col --icon d-flex align-center">
                    <div className="v2-btn-default --icon-sm --transparent loading bg-white-cultured" />
                </div>
                <div className="col --info">
                    <div className="loading --animation --line --half" />
                </div>
                <div className="col --price flex-1">
                    <div className="loading --animation --line --twothird" />
                </div>
                <div className="col --action">
                    <div className="v2-btn-default loading --grey btn-x-sm">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
            </div>
            <div className="rows">
                <div className="col --icon d-flex align-center">
                    <div className="v2-btn-default --icon-sm --transparent loading bg-white-cultured" />
                </div>
                <div className="col --info">
                    <div className="loading --animation --line --half" />
                </div>
                <div className="col --price flex-1">
                    <div className="loading --animation --line --twothird" />
                </div>
                <div className="col --action">
                    <div className="v2-btn-default loading --grey btn-x-sm">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default PlanListModal;

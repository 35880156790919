import { KEY_CODE_ENTER } from 'app/const/Keyboard';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';

const GDInputNumber = forwardRef(
    (
        {
            defaultValue,
            integerNumber = false,
            exceptZero = false,
            exceptNegative = false,
            isStringValue = false,
            type = 'text',
            limitValue = {},
            onChange = () => {},
            onEnter = () => {},
            ...props
        },
        ref
    ) => {
        const refInput = useRef(null);
        const [value, setValue] = useState(defaultValue);

        useImperativeHandle(ref, () => ({ value, reset: _handleReset, clear: _handleClear, focus: _handleFocus }));

        const _handleInputChange = (e) => {
            const regex = exceptNegative ? /^[0-9]*\.?[0-9]*$/ : /^-?[0-9]*\.?[0-9]*$/; // regex to match numbers and decimal points
            const { value, name } = e.target;
            // Especially, must uses "integerNumber" and "type='text'"
            // to prevent inputting decimal point
            if (integerNumber && value.includes('.')) return;
            if (exceptZero && value === '0') return;
            if (type === 'number') {
                const { max, min } = limitValue;
                const numericValue = parseFloat(value);
                if ((!isNaN(max) && numericValue > max) || (!isNaN(min) && numericValue < min)) return;
            }
            if (value === '' || regex.test(value)) {
                setValue(value);
                onChange(isStringValue ? value : Number(value), name);
            }
        };

        const _handleReset = () => {
            if (value === defaultValue) return;
            setValue(defaultValue);
            refInput.current.value = defaultValue;
        };

        const _handleKeyDown = (event) => {
            if (event.keyCode === KEY_CODE_ENTER) onEnter();
        };

        const _handleClear = () => {
            setValue('');
            refInput.current.value = '';
        };

        const _handleFocus = () => {
            refInput.current && refInput.current.focus();
        };

        return (
            <input
                ref={refInput}
                type={type}
                value={value}
                {...props}
                onChange={_handleInputChange}
                onKeyDown={_handleKeyDown}
            />
        );
    }
);

export default GDInputNumber;

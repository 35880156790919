import React, { useContext, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import { UnitDetailContext } from 'app/modules/jobdetail/contexts/UnitDetailContext';
import IconUser from 'assets/icon/IconUser';

export default function LastName() {
    const { t } = useTranslation();
    const { unitDetailContextData, updateUnitDetailContextData } = useContext(UnitDetailContext);
    const parentUnitLastName = unitDetailContextData.last_name;

    const [state, setState] = useReducer(reducer, {
        isVisible: false,
        value: parentUnitLastName
    });

    const finalLastName = state.value;

    useEffect(() => {
        parentUnitLastName !== finalLastName && setState({ value: parentUnitLastName });
    }, [parentUnitLastName]);

    function _handleSubmitLastName(e) {
        updateUnitDetailContextData({
            last_name: e.target.value
        });
    }

    function _handleChangeValue(e) {
        setState({
            value: e.target.value
        });
    }

    return (
        <div className="rows">
            <div className="rows__label">
                <IconUser />
                <span className="flex-1 txt-ellipsis">{t('common:last_name')}</span>
            </div>
            <div className="rows__form">
                <input
                    className="field-input"
                    type="text"
                    placeholder={t('common:last_name')}
                    value={finalLastName}
                    onChange={(e) => _handleChangeValue(e)}
                    onBlur={(e) => _handleSubmitLastName(e)}
                />
            </div>
        </div>
    );
}

import React, { forwardRef, useContext, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import GdGridView from 'app/components/grid/GdGridView';
import { actionDeleteInvoiceRecurrence, CUSTOMER_INVOICE_RECURRING } from 'app/const/Api';
import { DEFAULT_ALL, PAGE_SIZE_DEFAULT } from 'app/const/App';
import { GRID_RECURRING_TABLE } from 'app/const/customer/CustomerInvoices';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import { handleAbortController } from 'app/modules/customer/utils';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { CustomerDetailContext } from '../../context/CustomerDetailContext';

const CustomerInvoiceTableRecurring = ({ onShowAlert = () => {} }, ref) => {
    const { t } = useTranslation(['customers']);
    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: true,
        isLoadingMore: false,
        showMore: false,
        cursor: '',
        limit: PAGE_SIZE_DEFAULT
    });
    const { id: customer_id } = useParams();
    const dispatch = useDispatch();

    const { location_ids, unitSelected, reloadCustomerPage } = useContext(CustomerDetailContext);

    const {
        data: dataRecurring,
        isLoading,
        isLoadingMore: finalIsLoadingMore,
        showMore: finalShowMore,
        cursor: finalCursor,
        limit: finalLimit
    } = state;

    const refOldLocation = useRef(null);
    const refOldUnit = useRef(null);
    const abortController = useRef(null);

    useEffect(() => {
        if (reloadCustomerPage || checkReload(location_ids, unitSelected, customer_id)) {
            if (!isLoading) dispatchState({ isLoading: true });
            refOldLocation.current = location_ids;
            _getListInvoiceRecurring({});
        }
    }, [location_ids, unitSelected, reloadCustomerPage, customer_id]);

    useEffect(() => {
        return () => {
            handleAbortController(abortController);
        };
    }, []);

    const checkReload = (location_ids, unitSelected, customer_id) => {
        if (!location_ids || !unitSelected || !customer_id) return false;
        return location_ids !== refOldLocation.current || unitSelected?.id !== refOldUnit.current || !!customer_id;
    };

    const _getListInvoiceRecurring = ({
        data = {},
        shouldLoadingMore = false,
        isResetCursor = true,
        shouldLoading = true
    }) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();
        const params = { ...data };
        delete params?.filterTab;
        dispatchState((prev) => ({
            ...prev,
            isLoading: shouldLoading,
            cursor: isResetCursor ? '' : prev.cursor,
            showMore: isResetCursor ? false : prev.showMore,
            isLoadingMore: shouldLoadingMore
        }));

        const _handleSuccess = ({ data = [], show_more = false, cursor = '' }) => {
            dispatchState((prev) => ({
                ...prev,
                data: shouldLoadingMore ? [...prev.data, ...data] : data,
                isLoading: false,
                showMore: show_more,
                cursor,
                isLoadingMore: false
            }));
        };
        const _handleFail = ({ isAborted = false }) => {
            if (isAborted) return;
            dispatchState({ data: [], isLoading: false, isLoadingMore: false });
        };
        clientQuery(
            CUSTOMER_INVOICE_RECURRING,
            {
                data: {
                    ...params,
                    customer_id,
                    location_ids,
                    unit_ids: unitSelected?.id || DEFAULT_ALL,
                    cursor: isResetCursor ? '' : finalCursor,
                    limit: finalLimit
                },
                method: 'GET',
                abortController: abortController.current
            },
            _handleSuccess,
            _handleFail
        );
    };

    useImperativeHandle(ref, () => ({ _getListInvoiceRecurring }));

    const _handleOpenInvoice = (data) => {
        dispatch(
            actionOpenInvoice({
                id: data.id,
                status: data.status,
                total: '',
                isRecurring: true
            })
        );
    };

    const _deleteRecurrenceInvoice = (item) => {
        const _success = () => {
            onShowAlert(LIST_STATUS.SUCCESS, {
                id: 'success',
                message: t('delete_invoice_success'),
                type: LIST_STATUS.SUCCESS
            });
            dispatchState({ data: dataRecurring.filter((invoice) => invoice.id !== item.id) });
        };

        const _failed = ({ message }) =>
            onShowAlert(LIST_STATUS.ERROR, {
                id: 'error',
                message: message || t('delete_invoice_error'),
                type: LIST_STATUS.ERROR
            });

        clientQuery(
            actionDeleteInvoiceRecurrence(item.recurrence_id),
            { data: {}, method: 'DELETE' },
            _success,
            _failed
        );
    };

    const _handleClick = ({ mode, item }) => {
        switch (mode) {
            case 'view':
            case 'edit':
                _handleOpenInvoice(item);
                break;
            case 'delete':
                _deleteRecurrenceInvoice(item);
                break;
            default:
                break;
        }
    };

    const _handleScrollToEnd = () => {
        if (!finalIsLoadingMore && finalShowMore) {
            _getListInvoiceRecurring({ shouldLoadingMore: true, isResetCursor: false, shouldLoading: false });
        }
    };

    if (!dataRecurring.length) return null;

    return (
        <GdGridView
            content={dataRecurring}
            isLoading={isLoading}
            classTable="tables table-recurring wrap-tables"
            isHasDropdown
            fileTranslation="report"
            {...GRID_RECURRING_TABLE}
            isScroll
            isLoadmore={finalIsLoadingMore}
            handleClick={_handleClick}
            onOpenInvoice={_handleOpenInvoice}
            onScrollToEnd={_handleScrollToEnd}
        />
    );
};

export default forwardRef(CustomerInvoiceTableRecurring);

export default function IconHide() {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            {/* mask-type="alpha" */}
            <mask id="mask0" maskUnits="userSpaceOnUse" x={0} y={4} width={13} height={16}>
                <path d="M0.5 4.5L7.5 4.5L13 19.5H0.5V4.5Z" fill="white" />
            </mask>
            <g mask="url(#mask0)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.5 16.5C13.9486 16.5 16.226 15.5899 18.3321 13.7698L18.3321 13.7698C19.5856 12.6864 19.7236 10.7919 18.6402 9.53835C18.5451 9.42831 18.4421 9.32532 18.3321 9.23021C16.226 7.41007 13.9486 6.5 11.5 6.5C9.05133 6.5 6.77397 7.41007 4.66791 9.23022L4.66792 9.23022C3.41435 10.3136 3.27639 12.2081 4.35977 13.4616C4.45488 13.5717 4.55787 13.6747 4.66792 13.7698C6.77398 15.5899 9.05133 16.5 11.5 16.5Z"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                />
                <path
                    d="M11.5 14C12.8807 14 14 12.8807 14 11.5C14 10.1193 12.8807 9 11.5 9C10.1193 9 9 10.1193 9 11.5C9 12.8807 10.1193 14 11.5 14Z"
                    fill="white"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                />
            </g>
            {/* mask-type="alpha" */}
            <mask id="mask1" maskUnits="userSpaceOnUse" x={10} y={4} width={14} height={16}>
                <path d="M23.0694 4.5H10.5L16 19.5H23.0694V4.5Z" fill="white" />
            </mask>
            <g mask="url(#mask1)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.5 16.5C13.9486 16.5 16.226 15.5899 18.3321 13.7698L18.3321 13.7698C19.5856 12.6864 19.7236 10.7919 18.6402 9.53835C18.5451 9.42831 18.4421 9.32532 18.3321 9.23021C16.226 7.41007 13.9486 6.5 11.5 6.5C9.05133 6.5 6.77397 7.41007 4.66791 9.23022L4.66792 9.23022C3.41435 10.3136 3.27639 12.2081 4.35977 13.4616C4.45488 13.5717 4.55787 13.6747 4.66792 13.7698C6.77398 15.5899 9.05133 16.5 11.5 16.5Z"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                />
                <path
                    d="M11.5 14C12.8807 14 14 12.8807 14 11.5C14 10.1193 12.8807 9 11.5 9C10.1193 9 9 10.1193 9 11.5C9 12.8807 10.1193 14 11.5 14Z"
                    fill="white"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                />
            </g>
            <path
                d="M8.5 3.5L14.5 19.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

import React from 'react';
import { useTranslation } from 'react-i18next';

import IconPhone from 'assets/icon/IconPhone';

export default function ({ item }) {
    const { t } = useTranslation();
    return (
        <div className="call-bar flexcenter">
            <div className="call-bar__btn flex-column align-center fw-500">
                <IconPhone isCallOut />
                <span className="w-100 text-center txt-ellipsis">
                    {t('common:out')}
                </span>
            </div>
            <div className="call-bar__info flex-1">
                <div className="flexcenter mt-2">
                    <div className="valid-phone dots m-0">
                        <p className="valid-phone__number txt-ellipsis black">{item.to}</p>
                    </div>
                </div>
            </div>
            <div className="call-bar__action flexcenter pr-2">
                <span className="status-label">{t('common:accepting_call')}</span>
            </div>
            <span className="call-bar__status w-100 is-calling" />
        </div>
    );
}

import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { geJobLogs, geStatementLogs } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import Loading from 'app/modules/jobdetail/tabs/settingschedule/history/Loading';
import IconCogWheel from 'assets/icon/IconCogWheel';
import IconDropUpDown from 'assets/icon/IconDropUpDown';
import IconLoading from 'assets/icon/IconLoading';
import { clientQuery } from 'common/utils/ApiUtils';
import RealtimeJobLogs from './Services';

const ScheduleHistory = ({ jobId, customerId }) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        events: [],
        showMore: false,
        loadingLoadMore: false,
        isVisible: false
    });

    const { isVisible: finalIsVisible, isLoading } = state;

    function _getListLog(params) {
        if (!jobId) {
            clientQuery(
                geStatementLogs,
                {
                    method: 'GET',
                    data: { ...params, customer_id: customerId }
                },
                _getListLogSuccess
            );
        } else {
            clientQuery(
                geJobLogs(jobId),
                {
                    method: 'GET',
                    data: params
                },
                _getListLogSuccess
            );
        }
    }

    function _getListLogSuccess(response) {
        dispatchState({
            isLoading: false,
            events: [...state.events, ...response.data],
            showMore: response.show_more,
            loadingLoadMore: false
        });
    }

    function _handleShowMore() {
        dispatchState({
            loadingLoadMore: true
        });

        _getListLog({
            limit: 20,
            offset: state.events.length
        });
    }

    function _toggle() {
        dispatchState({
            isVisible: !finalIsVisible
        });

        if (isLoading) {
            _getListLog({
                limit: 20,
                offset: 0
            });
        }
    }

    function _handleAddNewLog(newData) {
        const finalJobId = newData.id || newData.job_id || '';
        if (finalJobId.toString() === jobId.toString()) {
            dispatchState((prev) => {
                return {
                    ...prev,
                    events: [newData, ...prev.events]
                };
            });
        }
    }

    function _renderShowMore() {
        if (state.loadingLoadMore) {
            return (
                <div className="flex-centeritem">
                    <IconLoading />
                </div>
            );
        }

        return (
            <div className="flex-centeritem" onClick={_handleShowMore}>
                <div className="v2-btn-default px-6">{t('common:show_more_logs')}</div>
            </div>
        );
    }

    function _renderListLogs() {
        return state.events.map((item, index) => {
            return (
                <div key={item.id || index} className="timeline">
                    <div className="timeline__details">
                        <div className="line" />
                        <div className="circle">
                            <IconCogWheel />
                        </div>
                        <div className="detail">
                            <div className="info">
                                <span className="datetime">{item.date}</span>
                            </div>
                            <div className="conts" dangerouslySetInnerHTML={{ __html: item.log }} />
                        </div>
                    </div>
                </div>
            );
        });
    }

    function _renderLogsDetail() {
        if (isLoading) {
            return <Loading />;
        }

        return (
            <div className="history__content">
                <div className="timeline-history main-timeline">
                    {_renderListLogs()}
                    {state.showMore && _renderShowMore()}
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="content-history">
                <div className={`history ${finalIsVisible ? 'active' : ''}`} id="box_history_scheduling">
                    <div className="history__btn" onClick={_toggle}>
                        {t('common:history')}
                        <span className="toggle-icon">
                            <IconDropUpDown />
                        </span>
                    </div>
                    {finalIsVisible && _renderLogsDetail()}
                </div>
            </div>
            {!!jobId && <RealtimeJobLogs onAddNewLog={_handleAddNewLog} />}
        </>
    );
};

export default ScheduleHistory;

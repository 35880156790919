import moment from 'moment-timezone';

import i18n from 'assets/i18n';
import { formatDateLocal } from 'common/utils/DateUtils';
import { calculateDaysPassed, roundToNearestFive } from 'common/utils/TimeUtils';
import {
    RESOURCE_EMPTY,
    RESOURCE_TYPES,
    START_TRACKING_EVENT,
    STOP_TRACKING_EVENT,
    TRACKING_ALERT_EVENT,
    TRACKING_EVENTS,
    TRACKING_ID_TRACKING,
    TRACKING_LOCAL_NAME,
    TRACKING_OFFLINE,
    TRACKING_ONLINE
} from '../constant';
import store from 'common/redux/store/configureStore';
import { convertGroups, getGroupsEvents } from './calendar';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';

const DEFAULT_DATA_LOGS = {
    amount: 0,
    start: 0,
    end: 0,
    duration: 0,
    expanded: true,
    isExpanded: true
};

export const trackerAlert = (notification, type) => {
    const event = new CustomEvent(TRACKING_ALERT_EVENT, { detail: { notification, type } });
    dispatchEvent(event);
};

export const trackerStart = (detail = {}) => {
    const customEvent = new CustomEvent(START_TRACKING_EVENT, { detail });
    dispatchEvent(customEvent);
};
export const trackerStop = (end) => {
    const detail = { start: getLocalStorage(TRACKING_LOCAL_NAME), end };
    const idActiveWorkLog = getLocalStorage(TRACKING_ID_TRACKING);
    if (idActiveWorkLog) detail.work_log_id = idActiveWorkLog;
    const customEvent = new CustomEvent(STOP_TRACKING_EVENT, { detail });
    dispatchEvent(customEvent);
};

export const setTracking = (data = {}) => {
    setLocalStorage(TRACKING_LOCAL_NAME, data?.start);
    setLocalStorage(TRACKING_ID_TRACKING, data?.id);
};
export const getTracking = () => {
    return { start: getLocalStorage(TRACKING_LOCAL_NAME), id: getLocalStorage(TRACKING_ID_TRACKING) };
};
export const clearTracking = () => {
    removeLocalStorage(TRACKING_ID_TRACKING);
    removeLocalStorage(TRACKING_LOCAL_NAME);
};
export const isHaveLoggingInAnother = () => {
    let result = false;
    Object.keys(localStorage).forEach((key) => {
        const match = key.match(/\/(.*)_tracking_time_id/);
        // eslint-disable-next-line no-undef
        if (match && match[1] !== global.branchid) return (result = true);
    });
    return result;
};

export const getDefaultTime = (date, timezone) => {
    let currentMoment = date ? moment.tz(date, timezone) : moment.tz(timezone);
    if (!timezone) currentMoment = date ? moment(date) : moment();
    const addedMoment = currentMoment.clone().set('minutes', currentMoment.clone().get('minutes') + 10);

    const currentTime = roundToNearestFive(currentMoment.clone()).unix();
    const timeRounded = roundToNearestFive(addedMoment).unix();
    return { currentTime, timeRounded };
};

export const getDuration = (start, end) => {
    if (!start || !end) return 0;
    return Math.abs(end - start);
};

export const convertTimeWorklog = (date) => {
    const tz = store?.getState()?.auth?.user?.settings?.timezone;
    const momentItem = moment.unix(date).tz(tz);
    return moment()
        .set('hour', momentItem.get('hour'))
        .set('minute', momentItem.get('minute'))
        .set('second', momentItem.get('second'))
        .unix();
};
export const convertTimeEvent = (date, timezone) => {
    const momentItem = timezone ? moment.unix(date).tz(timezone) : moment.unix(date);
    if (!momentItem) return moment().format();
    return moment()
        .set('hour', momentItem.get('hour'))
        .set('minute', momentItem.get('minute'))
        .set('second', momentItem.get('second'))
        .format();
};

export const convertWorklog = (item = {}, type = RESOURCE_TYPES.CHILDREN) => {
    const timezone = store?.getState()?.auth?.user?.settings?.timezone;
    const dayPassed = calculateDaysPassed(item.start, item.end, timezone) || 0;

    let itemId = item.id;
    const resourceId = type === RESOURCE_TYPES.GROUP ? item.group_id || itemId : itemId;

    // Check itemId have `_group` or `_children` then remove it
    if (itemId.includes('_group')) itemId = `${itemId}`.replace('_group', '');
    if (itemId.includes('_children')) itemId = `${itemId}`.replace('_children', '');

    const convertedEvent = {
        id: itemId + '_' + type,
        idMatch: itemId,
        resourceId,
        start: convertTimeEvent(item.start, timezone),
        end: !dayPassed ? convertTimeEvent(item.end, timezone) : moment().endOf('day').format(),
        startUnix: item.start,
        endUnix: item.end,
        ...(item.props || {})
    };

    if (type === RESOURCE_TYPES.CHILDREN) {
        convertedEvent['backgroundColor'] = '#0000002e';
        convertedEvent['borderColor'] = '#0000002e';
    }
    return convertedEvent;
};

const createNewGroup = (data = {}) => ({
    ...DEFAULT_DATA_LOGS,
    id: data.group_id,
    originId: data.id,
    title: data.title,
    isParent: true,
    date: data.date,
    overlapEvents: []
});

export const updateOverlapEvents = (data = [], tz, resource) => {
    const groupId = data?.[0]?.groupId;
    const events = [...data].sort((a, b) => a.start - b.start);

    const currentOverlap = [];
    for (let index = 0; index < events.length; index++) {
        const currentEvent = events[index];

        const nextEvent = events[index + 1] || null;
        const prevEvent = events[index - 1] || null;

        if (prevEvent && currentEvent.start < prevEvent.end) {
            const overlapStart = currentEvent.extendedProps.startUnix;
            const overlapEnd = Math.min(currentEvent?.extendedProps?.endUnix, prevEvent?.extendedProps?.endUnix);

            currentOverlap.push({
                start: overlapStart,
                end: overlapEnd,
                id: currentEvent.extendedProps.idMatch,
                groupId: groupId
            });
        }

        if (nextEvent && currentEvent.end > nextEvent.start) {
            const overlapStart = nextEvent.extendedProps.startUnix;
            const overlapEnd = Math.min(currentEvent?.extendedProps?.endUnix, nextEvent?.extendedProps?.endUnix);

            currentOverlap.push({
                start: overlapStart,
                end: overlapEnd,
                id: currentEvent.extendedProps.idMatch,
                groupId: groupId
            });
        }

        currentEvent.setExtendedProp('overlapEvents', []);
    }

    // Set isHaveOverlap for resource
    const resourcesChildren = resource?.getChildren() || [];
    if (!currentOverlap.length && resource) {
        resource.setExtendedProp('isHaveOverlap', false);
        resource.getChildren().forEach((item) => {
            item.setExtendedProp('isHaveOverlap', false);
        });
    }

    // Set overlapEvents for event
    events.forEach((item) => {
        const overlaps = currentOverlap.filter((overlap) => overlap.id === item?.extendedProps?.idMatch);
        const isHaveOverlap = overlaps.length > 0;

        if (isHaveOverlap || resource?.extendedProps?.isHaveOverlap) {
            resource && resource.setExtendedProp('isHaveOverlap', true);
            resourcesChildren
                .find((resource) => item.extendedProps.idMatch === resource.id)
                ?.setExtendedProp('isHaveOverlap', true);
        }
        if (isHaveOverlap) item.setExtendedProp('overlapEvents', overlaps);
    });

    const newEventsGroup = events.map((item) => ({
        id: item.id,
        group_id: item.extendedProps.parentId,
        start: item.extendedProps.startUnix,
        end: item.extendedProps.endUnix,
        overlap: item.extendedProps.overlapEvents
    }));

    return {
        overlap: currentOverlap || [],
        events: convertGroups(newEventsGroup, events[0]?.extendedProps?.parentId) || []
    };
};

export const convertDataWorkLogs = (data) => {
    let events = [];

    const groupsEvents = {};
    const resources = [];
    const groups = {};

    data.forEach((item) => {
        const groupId = item.group_id;
        // Create new group if not exist
        if (groupId && !groups[groupId]) groups[groupId] = createNewGroup(item);

        // if group is exist then update data group
        if (groupId) {
            const { start, end, amount, duration } = item;

            groups[groupId].amount += amount;
            groups[groupId].duration += duration;
            if (!groups[groupId].start) groups[groupId].start = start;
            if (groups[groupId].start > start) groups[groupId].start = start;
            if (groups[groupId].end < end) groups[groupId].end = end;

            groupsEvents[groupId] = [...(groupsEvents[groupId] || []), item];
        }

        const newItem = { ...item };
        if (groupId) {
            newItem['parentId'] = groupId;
            newItem['isSubItem'] = true;
        }

        resources.push(newItem);
        const eventCalendar = convertWorklog(
            { ...item, props: { overlapEvents: item.overlap || [], parentId: groupId } },
            newItem['isSubItem'] ? RESOURCE_TYPES.CHILDREN : RESOURCE_TYPES.GROUP
        );

        if (eventCalendar) {
            if (item.overlap.length && item.group_id) {
                groups[groupId]['isHaveOverlap'] = true;
                groups[groupId]['overlapEvents'].push(...item.overlap);
            }
            events = [...events, eventCalendar];
        }
    });

    resources.forEach((item) => {
        item['isHaveOverlap'] = groups[item.group_id]?.['isHaveOverlap'];
    });

    const newResources = [...resources, ...Object.values(groups)];
    if (!newResources.length) newResources.push({ ...RESOURCE_EMPTY, title: i18n.t(`common:${RESOURCE_EMPTY.title}`) });
    return { resources: newResources, events: [...events, ...getGroupsEvents(groupsEvents)] };
};

// For calendar
export const labelClassName = ({ resource }) => {
    const extendedProps = resource._resource.extendedProps;
    if (extendedProps.isEditing) return ['edit-clock'];
    if (extendedProps.isHaveOverlap && extendedProps.isSubItem) return ['--overlap-child'];
    if (extendedProps.isHaveOverlap) return ['--overlap'];
    if (extendedProps.expanded) return ['today'];
    if (extendedProps.isSubItem) return ['today-child'];
};

export const setExtendedProps = (resource, data = {}) => {
    for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
            resource.setExtendedProp(key, data[key]);
        }
    }
};

export const updateEventWithOverlap = (event, { payload, props = {} }, tz) => {
    if (!event) throw new Error('Event is not exist');
    event.setStart(convertTimeEvent(payload['start'], tz));
    event.setEnd(convertTimeEvent(payload['end'], tz));
    setExtendedProps(event, props);
};

export const getTotalResource = (resources) => {
    const result = { amount: 0, duration: 0 };
    resources.forEach((resource) => {
        result['amount'] += resource._resource.extendedProps.amount;
        result['duration'] += resource._resource.extendedProps.duration;
    });
    return result;
};

export const isThisWeekOrLastWeek = (unixTimestamp, timezone) => {
    // Convert the Unix timestamp to a Moment.js object in the given timezone
    const date = moment.unix(unixTimestamp).tz(timezone);

    // Start and end of the current week in the specified timezone
    const startOfThisWeek = moment().tz(timezone).startOf('week');
    const endOfThisWeek = moment().tz(timezone).endOf('week');

    // Start and end of the last week in the specified timezone
    const startOfLastWeek = moment().tz(timezone).subtract(1, 'weeks').startOf('week');
    const endOfLastWeek = moment().tz(timezone).subtract(1, 'weeks').endOf('week');

    if (date.isBetween(startOfThisWeek, endOfThisWeek, undefined, '[]')) {
        return 'this_week';
    } else if (date.isBetween(startOfLastWeek, endOfLastWeek, undefined, '[]')) {
        return 'last_week';
    } else {
        return 'other';
    }
};

export const createNewParentLog = (data = {}, resourceChildren = {}) => {
    const { start = 0, end = 0, duration = 0, amount = 0 } = resourceChildren;
    return {
        id: data.group_id,
        title: data.title,
        date: data.date,
        start: data.start < start ? data.start : start,
        end: data.end > end ? data.end : end,
        amount: amount + data.amount,
        duration: duration + data.duration,
        expanded: true,
        isExpanded: true,
        isParent: true
    };
};

export const getTitleWeek = (date, dateFormat, prefix = 'this_week') => {
    return (
        i18n.t(prefix) +
        ' ' +
        formatDateLocal(date.startOf('week').format('YYYY-MM-DD'), dateFormat) +
        ' - ' +
        formatDateLocal(date.endOf('week').format('YYYY-MM-DD'), dateFormat)
    );
};

export const reducerTrackingTime = (state, action) => {
    switch (action?.type) {
        case TRACKING_EVENTS.START:
            return { ...state, isCounting: true, isDisabled: true, isDisabledButton: true };
        case TRACKING_EVENTS.START_WITH_ID:
            return { ...state, isCounting: true, isDisabledButton: false };
        case TRACKING_EVENTS.STOP:
            return { ...state, isCounting: false, isDisabled: false };
        case TRACKING_EVENTS.SETTED_TRACKING_ID:
            return { ...state, isDisabled: false, isDisabledButton: false };
        case TRACKING_EVENTS.SETTING_TRACKING_ID:
            return { ...state, isDisabled: true };
        case TRACKING_EVENTS.START_WITH_ID_DETAIL:
            return { ...state, isCounting: true, isDisabled: false, isDisabledButton: false };
        case TRACKING_ONLINE:
            return { ...state, isDisabled: false, isDisabledButton: false };
        case TRACKING_OFFLINE:
            return { ...state, isDisabled: true, isDisabledButton: true };
        default:
            return state;
    }
};

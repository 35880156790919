import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PAYMENT_METHODS } from 'app/modules/jobdetail/const/Invoice';
import { AddPaymentContext } from 'app/modules/jobdetail/contexts/AddPaymentContext';
import { onKeyDownOnlyNumber } from 'common/utils/NumberUtils';

export default function Value({ placeholder }) {
    const { t } = useTranslation();
    const { addPayment, updatePaymentDataContext } = useContext(AddPaymentContext);
    const { paymentSelected, valuePayment, check_value } = addPayment || {};

    function _handleChangeValue(e) {
        updatePaymentDataContext({
            valuePayment: e.target.value
        });
    }

    function _handleChangeCheckValue(e) {
        updatePaymentDataContext({
            check_value: e.target.value
        });
    }

    const _handleOnKeyDownNumber = (event) => {
        const finalKey = event.key;
        const currentValue = event.target.value;

        if (finalKey === '-' && currentValue !== '') {
            const convertToNegative = Number(`-${currentValue}`);
            event.target.value = isNaN(convertToNegative) ? 0 : convertToNegative;
            event.preventDefault();
            _handleChangeCheckValue(event);
        }

        const isKeyAccept =
            onKeyDownOnlyNumber(event) || (finalKey === '-' && event.target.value === '') || finalKey === '.';
        !isKeyAccept && event.preventDefault();
    };

    return (
        <Fragment>
            <input
                type="text"
                className="field-input field-method"
                value={valuePayment}
                placeholder={placeholder}
                onChange={_handleChangeValue}
            />
            {paymentSelected?.id === PAYMENT_METHODS.CHECK && (
                <input
                    type="number"
                    className="field-input field-method"
                    value={check_value}
                    placeholder={t('jobDetail:check_value')}
                    onKeyDown={_handleOnKeyDownNumber}
                    onChange={_handleChangeCheckValue}
                />
            )}
        </Fragment>
    );
}

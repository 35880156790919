export default function IconLocation({ isHasColor = false, isTimeoff = false, color = '#7A83A6' }) {
    if (isTimeoff)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 4C15.3137 4 18 6.68629 18 10C18 12.6102 16.4739 15.5496 13.4864 18.868C13.4396 18.9199 13.3901 18.9694 13.3382 19.0162C12.5173 19.7552 11.2527 19.6889 10.5136 18.868C7.52614 15.5496 6 12.6102 6 10C6 6.68629 8.68629 4 12 4ZM12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7Z"
                    fill="#C1C9D4"
                />
                <path
                    d="M12 12C13.1046 12 14 11.1046 14 10C14 8.89543 13.1046 8 12 8C10.8954 8 10 8.89543 10 10C10 11.1046 10.8954 12 12 12Z"
                    fill="#7A83A6"
                />
            </svg>
        );
    if (isHasColor)
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path
                    fill={color}
                    fillRule="evenodd"
                    d="M12 4a6 6 0 016 6c0 2.61-1.526 5.55-4.514 8.868a2 2 0 01-2.973 0C7.527 15.55 6 12.61 6 10a6 6 0 016-6zm0 3a3 3 0 100 6 3 3 0 000-6z"
                    clipRule="evenodd"
                ></path>
                <path fill={color} d="M12 12a2 2 0 100-4 2 2 0 000 4z"></path>
            </svg>
        );

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 4.5C15.0376 4.5 17.5 7.01332 17.5 10.1136C17.5 12.5558 16.101 15.3059 13.3625 18.4106C13.3196 18.4592 13.2743 18.5055 13.2267 18.5492C12.4742 19.2407 11.3149 19.1786 10.6375 18.4106C7.89896 15.3059 6.5 12.5558 6.5 10.1136C6.5 7.01332 8.96243 4.5 12 4.5Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M12 11.5C12.8284 11.5 13.5 10.8284 13.5 10C13.5 9.17157 12.8284 8.5 12 8.5C11.1716 8.5 10.5 9.17157 10.5 10C10.5 10.8284 11.1716 11.5 12 11.5Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
}

import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useHistory } from 'react-router-dom';

import GDIframe from 'app/components/iframe';
import { SETTINGS_SERVICE } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import IconPlay from 'assets/icon/IconPlay';
import { ADD_SERVICE_TEMPLATES } from '../../constant';

const ModalFAQ = (props, ref) => {
    const history = useHistory();
    const { t } = useTranslation('onboarding');
    const [state, dispatchState] = useReducer(reducer, { isVisible: false, data: {} });
    const { isVisible, data } = state;
    const { title, link, description, isHideStartOnBoarding, type } = data;
    const finalTitle = t(title);

    useImperativeHandle(ref, () => ({ _open: _handleOpen, _close: _handleClose }));

    const _handleOpen = (data) => {
        dispatchState((prev) => ({ ...prev, isVisible: true, data }));
    };

    const _handleClose = () => {
        dispatchState((prev) => ({ ...prev, isVisible: false }));
    };

    const _handleStartOnBoarding = () => {
        switch (type) {
            case ADD_SERVICE_TEMPLATES:
                history.push(addBranchPath(SETTINGS_SERVICE));
                break;
            default:
                break;
        }
    };

    if (!isVisible) return null;

    return (
        <ReactModal
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            portalClassName="ReactModalPortal_alert_customer"
            className="modal container-modal modal-onboarding-question open"
            onRequestClose={_handleClose}
        >
            <div className="modal__overlay bg-fixed" onClick={_handleClose} />
            <div className="modal__container --preview-r">
                <div className="header-modal">
                    <h3 className="header-modal__label" title={finalTitle}>
                        {finalTitle}
                    </h3>
                    {isHideStartOnBoarding ? null : (
                        <div
                            className="v2-btn-main --icon-r --large btn-onboarding fs-14 mx-1"
                            onClick={_handleStartOnBoarding}
                        >
                            {t('start_onboarding')}
                            <IconPlay isTracking />
                        </div>
                    )}
                    <div className="v2-btn-default --transparent --icon-lg" onClick={_handleClose}>
                        <IconClose />
                    </div>
                </div>
                <div className="body-modal flex-column scrolls border-none pt-0">
                    <GDIframe
                        link={link}
                        className="onboarding-iframe"
                        loadingComponent={() => (
                            <div className="onboarding-iframe loading --animation bg-white-cultured" />
                        )}
                    />
                    <h5 className="fs-15 fw-600 black mt-5 mb-2" title={finalTitle}>
                        {finalTitle}
                    </h5>
                    <p className="fs-14" dangerouslySetInnerHTML={{ __html: t(description) }} />
                </div>
            </div>
        </ReactModal>
    );
};

export default forwardRef(ModalFAQ);

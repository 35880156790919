import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import { LIST_AVATAR_DEFAULT } from 'app/const/setting/SettingUsers';
import { BROWSER_DEVICES } from 'app/const/Voip';
import IconClose from 'assets/icon/IconClose';
import IconInternet from 'assets/icon/IconInternet';
import { detectBrowser, measureConnectionSpeed } from 'common/utils/FunctionUtils';

let timmer = null;

export default function NetWorkInfo() {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        isSlow: false,
        isShowSlow: true,
        isOffline: false,
        isShowOffline: true,
        browser: detectBrowser()
    });

    const { isSlow, isOffline, isShowSlow, isShowOffline, browser } = state;
    const refIsSlow = useRef(false);

    useEffect(() => {
        window.addEventListener('offline', () => {
            dispatchState({
                isOffline: true,
                isSlow: false
            });
            refIsSlow.current = false;
        });

        window.addEventListener('online', () => {
            dispatchState({
                isOffline: false
            });
        });

        const isSafari = browser === BROWSER_DEVICES.SAFARI;
        const timeOut = isSafari ? 30000 : 5000;

        timmer = setInterval(() => {
            if (isSafari) {
                const compareValue = 1;
                measureConnectionSpeed(LIST_AVATAR_DEFAULT[0].url)
                    .then(({ speed }) => {
                        if (speed <= compareValue) {
                            _handleSetSlow(true);
                            return;
                        }
                        _handleSetSlow(false);
                    })
                    .catch(() => {
                        _handleSetSlow(true);
                    });
                return;
            }

            if (navigator.connection?.downlink < 1) {
                if (!refIsSlow.current && navigator.onLine) {
                    _handleSetSlow(true);
                }
            } else {
                if (refIsSlow.current) {
                    _handleSetSlow(false);
                }
            }
        }, timeOut);

        return () => {
            clearInterval(timmer);
        };
    }, []);

    const _handleCloseShowSlow = () => {
        dispatchState({
            isShowSlow: false
        });
    };

    const _handleCloseShowOffline = () => {
        dispatchState({
            isShowOffline: false
        });
    };

    const _handleSetSlow = (value) => {
        dispatchState({
            isSlow: value
        });
        refIsSlow.current = value;
    };

    return (
        <div className="wrap-connection">
            {isSlow && isShowSlow && !isOffline && (
                <div className="connection-bar flexcenter gap-12 is-slow">
                    <IconInternet isSlow />
                    <p className="fs-13 fw-500 flex-1">{t('common:connection_slow')}</p>
                    <span onClick={_handleCloseShowSlow} className="v2-btn-default --icon-sm --transparent btn-bg-grey">
                        <IconClose />
                    </span>
                </div>
            )}
            {isOffline && isShowOffline && (
                <div className="connection-bar flexcenter gap-12 is-off">
                    <IconInternet />
                    <p className="fs-13 fw-500 flex-1">{t('common:no_internet_connection')}</p>
                    <span
                        onClick={_handleCloseShowOffline}
                        className="v2-btn-default --icon-sm --transparent btn-bg-grey"
                    >
                        <IconClose />
                    </span>
                </div>
            )}
        </div>
    );
}

import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import IconItalic from 'assets/icon/IconItalic';
import { handleGetActiveInlineStyle } from '../utils';

const CustomItalicOption = ({ editorState, onInsert = () => {} }) => {
    const { t } = useTranslation();
    return (
        <span
            className={classNames('editor-controls__btn tooltip', {
                active: handleGetActiveInlineStyle(editorState, 'ITALIC')
            })}
            onMouseDown={(e) => onInsert(e, 'ITALIC')}
        >
            <IconItalic />
            <span className="tooltiptext top">{t('common:italic_ctrl_i')}</span>
        </span>
    );
};

export default CustomItalicOption;

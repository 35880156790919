import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import IconOrderList from 'assets/icon/IconOrderList';
import { handleGetActiveInlineStyle } from '../utils';

const CustomOLOption = ({ editorState, onInsert = () => {} }) => {
    const { t } = useTranslation();
    return (
        <span
            className={classNames('editor-controls__btn tooltip', {
                active: handleGetActiveInlineStyle(editorState, 'ordered-list-item')
            })}
            onMouseDown={(e) => onInsert(e, 'ordered-list-item')}
        >
            <IconOrderList />
            <span className="tooltiptext top">{t('common:ordered_list')}</span>
        </span>
    );
};

export default CustomOLOption;

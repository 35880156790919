import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import IconTrash from 'assets/icon/IconTrash';

const DeleteItems = ({ onDelete, isPackage = false }) => {
    const { t } = useTranslation();
    const [state, setState] = useReducer(reducer, {
        isVisible: false
    });

    const refDropdown = useRef(null);
    const finalIsVisible = state.isVisible;

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_option_delete_items');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_option_delete_items');

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    const _handleOpen = (e) => {
        e.stopPropagation();
        setState({ isVisible: !finalIsVisible });
    };

    const _renderOptions = () => {
        return (
            <div className="delete-option">
                <div className="tabs-daily btn-item red-items">
                    <button className="tab-items active-tab-selector item-off" onClick={onDelete} type="button">
                        {isPackage ? t('jobDetail:delete_package') : t('addons:delete_item')}
                    </button>
                    <button className="tab-items" type="button" onClick={_closeSearchResult}>
                        {t('common:cancel')}
                    </button>
                </div>
            </div>
        );
    };

    if (isPackage) {
        return (
            <div
                className={`delete-package tabs ${finalIsVisible ? 'active' : ''}`}
                id={'show_option_delete_items'}
                ref={refDropdown}
            >
                <div onClick={(e) => _handleOpen(e)} className="v2-btn-default --icon-r has-bg-red">
                    {t('jobDetail:delete_package')}
                    <IconTrash />
                </div>
                {_renderOptions()}
            </div>
        );
    }

    return (
        <div
            className={`button delete-items tabs ${finalIsVisible ? 'active' : ''}`}
            id={'show_option_delete_items'}
            ref={refDropdown}
        >
            <div onClick={(e) => _handleOpen(e)} tabIndex="0">
                <IconTrash />
            </div>
            {_renderOptions()}
        </div>
    );
};

export default DeleteItems;

import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import classNames from 'classnames';
import React, { useReducer } from 'react';
import ReactModal from 'react-modal';
import SearchByEmail from './SearchByEmail';
import { SearchByID } from './SearchByID';
import { PAYMENT_MERCHANT_KEYWORD } from 'app/const/customer/CustomerPayment';
import { useTranslation } from 'react-i18next';

const LinkStripeModal = ({
    customer = null,
    contacts = [],
    stripeId = false,
    type = PAYMENT_MERCHANT_KEYWORD.STRIPE,
    onConnectSuccess = () => {},
    classWrapper = 'stripe-additional__link',
    classButton = 'btn-modal v2-btn-default --purple',
    textButton = ''
}) => {
    const hasStripeId = !!stripeId;
    const emailContact = contacts.find((item) => !!item.email) || null;
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { isOpen: false });
    const { isOpen, activeTab } = state;

    const _open = () => {
        dispatchState({ isOpen: true, activeTab: hasStripeId ? 0 : 1 });
    };

    const _close = () => {
        dispatchState({ isOpen: false });
    };

    const _handleChangeTab = (tab) => dispatchState({ activeTab: tab });

    const _handleConnectSuccess = (data) => {
        onConnectSuccess(data);
        _close();
    };
    return (
        <>
            {isOpen && (
                <ReactModal
                    id="existing_stripeID"
                    isOpen={isOpen}
                    onRequestClose={_close}
                    className="modal container-modal modal-link-stripeID open"
                >
                    <div className="modal__overlay bg-fixed" onClick={_close} />
                    <div className="modal__container large">
                        <div className="header-modal">
                            <h3 className="header-modal__label">{t('customers:link_an_existing_type_id', { type })}</h3>
                            <div className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                                <IconClose />
                            </div>
                        </div>

                        <div className="body-modal accessible-tabs-container">
                            <div className="tabs p-2">
                                <div className="tab-selectors btn-item w-100 ml-0">
                                    <button
                                        className={classNames('tab-items', {
                                            'active-tab-selector': !activeTab,
                                            'is-disable': hasStripeId
                                        })}
                                        onClick={() => _handleChangeTab(0)}
                                        type="button"
                                        tabIndex={0}
                                    >
                                        {t(`customers:search_by_type_id`, { type })}
                                    </button>
                                    <button
                                        className={classNames('tab-items', {
                                            'active-tab-selector': activeTab,
                                            'is-disable': hasStripeId
                                        })}
                                        onClick={() => _handleChangeTab(1)}
                                        type="button"
                                        tabIndex={-1}
                                    >
                                        {t('customers:search_by_email')}
                                    </button>
                                </div>
                            </div>

                            <div className="tab-contents">
                                <SearchByID
                                    isActive={activeTab === 0}
                                    stripeId={stripeId}
                                    customer={customer}
                                    type={type}
                                    onConnectSuccess={_handleConnectSuccess}
                                />
                                <SearchByEmail
                                    isActive={activeTab === 1}
                                    customer={
                                        customer?.email
                                            ? { ...(customer || {}), isMainEmail: true }
                                            : { ...(emailContact || {}), isContactEmail: true }
                                    }
                                    type={type}
                                    onConnectSuccess={_handleConnectSuccess}
                                />
                            </div>
                        </div>
                    </div>
                </ReactModal>
            )}

            <div className={classWrapper}>
                <div className={classButton} onClick={_open} tabIndex="0">
                    {textButton || t('common:link_an_existing_stripe_id')}
                </div>
            </div>
        </>
    );
};

export default LinkStripeModal;

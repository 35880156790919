import React, { useContext, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import { UnitDetailContext } from 'app/modules/jobdetail/contexts/UnitDetailContext';
import IconBuilding from 'assets/icon/IconBuilding';
import IconError from 'assets/icon/IconError';

export default function Building() {
    const { t } = useTranslation();
    const { unitDetailContextData, updateUnitDetailContextData } = useContext(UnitDetailContext);
    const parentBuildingName = unitDetailContextData.buildingName;

    const [state, dispatchState] = useReducer(reducer, {
        isWarning: false,
        value: parentBuildingName,
        isEdit: false
    });

    const finalIsWarning = state.isWarning;

    useEffect(() => {
        finalIsWarning !== parentBuildingName && dispatchState({ value: parentBuildingName, isEdit: false });
    }, [parentBuildingName]);

    function _handleSubmitBuilding(e) {
        const finalValue = e.target.value;
        let isExist = false;

        if (!state.isEdit) {
            return false;
        }

        if (finalValue.length === 0) {
            dispatchState({
                isWarning: t('jobDetail:building_name_cannot_be_blank')
            });
            updateUnitDetailContextData({
                isCheckBilding: false
            });
            return false;
        }

        unitDetailContextData.buildingData.forEach((element) => {
            if (element.name === finalValue && unitDetailContextData.buildingSelected?.id !== element.id) {
                isExist = true;
            }
        });

        if (isExist) {
            dispatchState({
                isWarning: t('jobDetail:building_name_is_exist')
            });
            updateUnitDetailContextData({
                isCheckBilding: false
            });
            return false;
        }

        updateUnitDetailContextData({
            isCheckBilding: true,
            buildingName: finalValue
        });
    }

    function _handleChangeValue(e) {
        dispatchState({
            value: e.target.value,
            isWarning: null,
            isEdit: true
        });
    }

    return (
        <div className="rows">
            <div className="rows__label">
                <IconBuilding isHasColor />
                <span className="flex-1 txt-ellipsis">{t('jobDetail:building')}</span>
            </div>
            <div className="rows__form">
                <input
                    onBlur={(e) => _handleSubmitBuilding(e)}
                    onChange={(e) => _handleChangeValue(e)}
                    className="field-input"
                    type="text"
                    placeholder={t('customers:building_name')}
                    value={state.value}
                />
                {finalIsWarning && (
                    <p className="txt-incorrect">
                        <IconError />
                        {finalIsWarning}
                    </p>
                )}
            </div>
        </div>
    );
}

import classNames from 'classnames';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_ALL } from 'app/const/App';
import { NAME } from 'app/const/Customers';
import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconBuilding from 'assets/icon/IconBuilding';
import IconFilter from 'assets/icon/IconFilter';
import IconSearch from 'assets/icon/IconSearch';
import { getDefaultSelectUnit } from '../../constant';
import { displayLocation } from '../../locations/utils';

export const TYPE_DROPDOWN = { LOCATION: 'LOCATION', UNIT: 'UNIT' };

const FilterDropdown = ({
    data: defaultOptions = [],
    searchKey = [NAME],
    type = TYPE_DROPDOWN.LOCATION,
    defaultSelected = DEFAULT_ALL,
    optionDefault = DEFAULT_ALL,
    onSelect = () => {},
    className = '',
    fileTranslation = 'customers'
}) => {
    const { t } = useTranslation(fileTranslation);
    const initialState = ({ defaultOptions = [], defaultSelected, optionDefault }) => {
        const initState = { options: defaultOptions };
        initState['selected'] =
            defaultSelected === DEFAULT_ALL
                ? optionDefault
                : defaultOptions.find((item) => item.id === defaultSelected) || optionDefault;
        return initState;
    };

    const [state, dispatchState] = useReducer(
        reducer,
        { defaultOptions, defaultSelected, optionDefault },
        initialState
    );
    const { options = [], selected } = state;
    const refData = useRef(defaultOptions);
    const refDropdown = useRef(null);
    const refSearchValue = useRef('');

    const filterButtonType = {
        [TYPE_DROPDOWN.LOCATION]: () => (
            <ButtonFilterLocation locationSelected={selected} fileTranslation={fileTranslation} />
        ),
        [TYPE_DROPDOWN.UNIT]: () => <ButtonFilterUnit unitSelected={selected} fileTranslation={fileTranslation} />
    };

    useEffect(() => {
        if (Array.isArray(defaultOptions)) {
            dispatchState(initialState({ defaultOptions, defaultSelected, optionDefault }));
            refData.current = defaultOptions;
        }
    }, [defaultOptions, defaultSelected]);

    const _handleSearch = (event) => {
        let newOptions = refData.current;
        const valueSearch = event.target.value;
        refSearchValue.current = valueSearch;
        if (valueSearch) {
            newOptions = newOptions.filter((item) => {
                return searchKey.some((key) => {
                    const dataSearch = item[key];
                    if (!dataSearch) return false;
                    return dataSearch.toLowerCase().trim().includes(valueSearch.toLowerCase().trim());
                });
            });
        }
        dispatchState((prev) => ({ ...prev, options: newOptions }));
    };

    const _handleSelect = (itemSelect) => {
        refDropdown.current && refDropdown.current._closeDropdown();
        onSelect(itemSelect);
    };
    return (
        <CalendarDropdown
            ref={refDropdown}
            id={type}
            wrapperClassName={className}
            buttonClassName="filter-btn flexcenter gap-4 cursor-pointer h-100"
            wrapperListClass="v2-dropdown__menu content-search"
            customDropButton={filterButtonType[type]}
        >
            <div className="container-column">
                <div className="flex-auto header-search">
                    <div className="search-input">
                        <span className="svg-search-absolute">
                            <IconSearch />
                        </span>
                        <input
                            type="text"
                            className="search-input"
                            placeholder={t('search...')}
                            name={`${type}_search`}
                            defaultValue={refSearchValue.current || ''}
                            onChange={_handleSearch}
                            autoFocus
                        />
                    </div>
                </div>
                <FilterList
                    data={options}
                    type={type}
                    idSelected={selected?.id || DEFAULT_ALL}
                    optionDefault={optionDefault}
                    fileTranslation={fileTranslation}
                    keyword={refSearchValue.current}
                    onSelect={_handleSelect}
                />
            </div>
        </CalendarDropdown>
    );
};

const FilterList = ({
    data = [],
    idSelected = DEFAULT_ALL,
    type = TYPE_DROPDOWN.LOCATION,
    optionDefault,
    fileTranslation = 'customers',
    keyword = '',
    onSelect = () => {}
}) => {
    const { t } = useTranslation(fileTranslation);
    const isLocationType = type === TYPE_DROPDOWN.LOCATION;
    if (!data.length) return <FilterItemEmpty keyword={keyword} type={type} />;
    return (
        <>
            <div className="content-checked__all" onClick={() => onSelect(optionDefault)}>
                <div className={classNames('items', { active: idSelected === DEFAULT_ALL })}>
                    <span className="word-break fw-600">{t(isLocationType ? 'all_location' : 'all_units')}</span>
                </div>
            </div>
            <ul className="box-auto scrolls">
                {data.map((item) => (
                    <FilterItem
                        key={item.id}
                        active={idSelected === item.id}
                        onSelect={() => onSelect(item)}
                        {...item}
                        isLocationType={isLocationType}
                    />
                ))}
            </ul>
        </>
    );
};

const FilterItem = ({ name = '', address = '', active = false, isLocationType = false, onSelect = () => {} }) => {
    return (
        <div className={classNames('items', { active, 'align-top': isLocationType })} onClick={onSelect}>
            {isLocationType ? <span className="word-break fw-600">{name}</span> : null}
            <span className="word-break">{isLocationType ? address : name}</span>
        </div>
    );
};

const FilterItemEmpty = ({ fileTranslation = 'customers', type = TYPE_DROPDOWN.LOCATION, keyword = '' }) => {
    const { t } = useTranslation([`${fileTranslation}`, 'header', 'common']);
    return (
        <ul className="box-auto">
            <div className="item-empty fw-600">
                {keyword
                    ? t('header:search_not_match')
                    : t('common:no_data_to_display', {
                          title: t(`common:${type === TYPE_DROPDOWN.LOCATION ? 'locations' : 'units'}`)
                      })}
            </div>
        </ul>
    );
};

const ButtonFilterLocation = ({ locationSelected, fileTranslation = 'customers' }) => {
    const { t } = useTranslation(fileTranslation);
    return (
        <>
            <IconFilter isHasColor />
            <div className="filter-btn__info flex-column flex-1">
                <span className="title-sx">{t('location')}:</span>
                <span className="fw-600 txt-ellipsis">{locationSelected.name}</span>
                {locationSelected?.id === DEFAULT_ALL ? null : (
                    <span className="fw-500 txt-ellipsis grey-faux-granite">
                        {displayLocation({
                            street1: locationSelected.street1,
                            street2: locationSelected.street2,
                            city: locationSelected.city,
                            state: locationSelected.state,
                            zip: locationSelected.zip,
                            withoutHTML: true
                        })}
                    </span>
                )}
            </div>
            <span className="arrow">
                <IconArrowDown />
            </span>
        </>
    );
};

const ButtonFilterUnit = ({ unitSelected, fileTranslation = 'customers' }) => {
    const { t } = useTranslation(fileTranslation);
    return (
        <>
            <IconBuilding />
            <div className="filter-btn__info flex-column flex-1">
                <span className="title-sx">{t('units')}:</span>
                <span className="fw-600 txt-ellipsis">{unitSelected?.name || getDefaultSelectUnit()?.name}</span>
            </div>
            <span className="arrow">
                <IconArrowDown />
            </span>
        </>
    );
};

export default FilterDropdown;

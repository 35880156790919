import React from 'react';

export default function IconSideMenu() {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.5406 8.5412C14.7943 8.28723 15.2059 8.28702 15.4598 8.54073C15.7138 8.79444 15.714 9.206 15.4603 9.45997L13.5716 11.3506H19.5003C19.8593 11.3506 20.1503 11.6416 20.1503 12.0006C20.1503 12.3596 19.8593 12.6506 19.5003 12.6506H13.5679L15.4598 14.5408C15.7137 14.7945 15.7139 15.206 15.4602 15.46C15.2064 15.714 14.7949 15.7141 14.5409 15.4604L11.5412 12.4633C11.2872 12.2096 11.287 11.7981 11.5407 11.5441L14.5406 8.5412Z"
                fill="var(--color-icon)"
            />
            <rect x={5} y={5} width={4} height={14} stroke="var(--color-icon)" strokeWidth="1.3" strokeLinejoin="round" />
        </svg>
    );
}

import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

import i18n from 'assets/i18n';
import { convertPhpFormatToMoment } from 'common/utils/DateUtils';
import ResourceTimeEdit from './ResourceTimeEdit';

const ResourceGroupLabel = ({ title, isEditing = false, extendedProps = {}, onBlur = () => {} }) => {
    const date_format = useSelector(({ auth }) => auth.user.company.date_format);
    const formattedDate = moment(title).locale(i18n.language).format(convertPhpFormatToMoment(date_format));
    return (
        <>
            <div className="col col-date flexcenter gap-4">
                <p className="col-label fw-500" title={formattedDate}>
                    {formattedDate}
                </p>
            </div>
            <ResourceTimeEdit isEditing={isEditing} {...extendedProps} onBlur={onBlur} />
        </>
    );
};

export default ResourceGroupLabel;

import GridEmpty from 'app/components/grid/components/GridEmpty';
import { reducer } from 'app/const/Reducer';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';
import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

const SMSAddonsPhonesTable = ({ data = [], number, onSelect = () => {} }, ref) => {
    const { t } = useTranslation('addons');

    const [state, dispatchState] = useReducer(reducer, {
        isLoading: false
    });
    const { isLoading } = state;

    useImperativeHandle(ref, () => ({
        setLoading: _handleSetLoading
    }));

    const _handleSetLoading = (value) => {
        dispatchState({ isLoading: value });
    };

    const _handleSelectPhone = (e) => {
        onSelect(e.target.value);
    };

    const _renderRows = () => {
        if (isLoading)
            return (
                <div className="preloader-small py-4 ">
                    <span className="preloader-small__items" />
                </div>
            );

        if (!data.length)
            return (
                <GridEmpty
                    msgEmpty={!!number ? t('number_prefix_not_in_stock', { number }) : t('common:empty_table_text')}
                    isFlat
                />
            );

        return data.map((item) => (
            <div key={item.number} className="rows">
                <div className="col --checkbox flex-none">
                    <div className="check-radio d-flex">
                        <input
                            id={`radio_${item.number}`}
                            type="radio"
                            name="phone"
                            value={item.number}
                            onChange={_handleSelectPhone}
                        />
                        <label htmlFor={`radio_${item.number}`} className="check-radio__label" />
                    </div>
                </div>

                <div className="col --info">
                    <p className="col-label">{formatPhoneNumberVoip(item.number)}</p>
                </div>
                <div className="col --info">
                    <p className="col-label">{item.locality}</p>
                </div>
            </div>
        ));
    };

    return (
        <div className="tables flex-column scrolls-x">
            <div className="rows --fixed --header">
                <div className="col --checkbox flex-none" />
                <div className="col --info">
                    <p className="col-label">{t('report:phone_number')}</p>
                </div>
                <div className="col --info">
                    <p className="col-label">{t('addons:region')}</p>
                </div>
            </div>

            <div className="tables-list">{_renderRows()}</div>
        </div>
    );
};

export default forwardRef(SMSAddonsPhonesTable);

import React, { useContext } from 'react';
import FormInterval from 'app/modules/jobdetail/tabs/settingschedule/repeat/FormInterval';
import { useTranslation } from 'react-i18next';
import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';
import classNames from 'classnames';

const RepeatDaily = ({ isEvent = false }) => {
    const { t } = useTranslation(['jobDetail']);
    const { repeatSettings, onChangeDataContext } = useContext(DataRepeatContext);
    const jobCurrentceDetail = repeatSettings.recurrence.offset || {};

    function _handleChangeValue(interval) {
        const refValue = {
            ...repeatSettings,
            recurrence: {
                ...repeatSettings.recurrence,
                offset: {
                    ...jobCurrentceDetail,
                    interval: interval
                }
            }
        };

        onChangeDataContext(refValue);
    }

    return (
        <div className="tab-panel active">
            <div className={classNames({ rows: !isEvent, flexcenter: isEvent })}>
                {isEvent ? (
                    <div className="tabs">
                        <div className="btn-item m-0">
                            <button className="tab-items active-tab-selector" type="button">
                                {t('jobDetail:every')}
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="txt no-icon"> {t('jobDetail:every')} </div>
                )}

                <div className={classNames('field-every', { 'ml-2': isEvent, details: !isEvent })}>
                    <FormInterval interval={jobCurrentceDetail.interval} onChange={_handleChangeValue} />
                    <span className="text-last">{t(`jobDetail:days`)}</span>
                </div>
            </div>
        </div>
    );
};

export default RepeatDaily;

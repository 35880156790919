export default function IconFaceBook({ isNormal = false, isCenter = false }) {
    if (isNormal) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.5 4.5H15.5C17.7091 4.5 19.5 6.29086 19.5 8.5V15.5C19.5 17.7091 17.7091 19.5 15.5 19.5H8.5C6.29086 19.5 4.5 17.7091 4.5 15.5V8.5C4.5 6.29086 6.29086 4.5 8.5 4.5Z"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.5 19.5V9.5C10.5 8.39543 11.3954 7.5 12.5 7.5H15.5V9.5H13.5C12.9477 9.5 12.5 9.94772 12.5 10.5V11.5H15.5V13.5H12.5V19.5"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }

    if (isCenter)
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                style={{ fill: '#3C5A99' }}
            >
                <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z" />
            </svg>
        );

    return (
        <svg width={26} height={26} viewBox="0 0 267 267" xmlns="http://www.w3.org/2000/svg">
            <g fill="white">
                <path
                    id="Blue_1_"
                    fill="#FFFFFF"
                    d="M248.082,262.307c7.854,0,14.223-6.369,14.223-14.225V18.812
        c0-7.857-6.368-14.224-14.223-14.224H18.812c-7.857,0-14.224,6.367-14.224,14.224v229.27c0,7.855,6.366,14.225,14.224,14.225
        H248.082z"
                />
                <path
                    id="f"
                    fill="#3C5A99"
                    d="M182.409,262.307v-99.803h33.499l5.016-38.895h-38.515V98.777c0-11.261,3.127-18.935,19.275-18.935
        l20.596-0.009V45.045c-3.562-0.474-15.788-1.533-30.012-1.533c-29.695,0-50.025,18.126-50.025,51.413v28.684h-33.585v38.895h33.585
        v99.803H182.409z"
                />
            </g>
        </svg>
    );
}

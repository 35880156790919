import IconClose from 'assets/icon/IconClose';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { OPERATOR_TYPES } from '../../constants/types';
const ItemFilteredSource = ({ filter, field, onUpdate = () => {}, onRemove = () => {} }) => {
    const { t } = useTranslation(['smartView']);
    const sources = useSelector(({ customerReducer }) => customerReducer.sources);
    const { values } = filter?.value?.set || {};
    const { prefixToken } = OPERATOR_TYPES[filter.operatorId];
    let currentSource;
    if (values && values.length === 1 && sources)
        currentSource = sources.find((source) => source?.id?.toString() === values?.[0]?.toString());

    return (
        <>
            <div onClick={onUpdate} className="flex-1">
                <span className="black-darklight fw-500">{t(field.label)}:</span>
                {prefixToken ? <span className="budget --grey fw-600 text-uppercase">{t(prefixToken)}</span> : null}
                {values && values.length > 1 ? <span className="budget --black">{values.length}</span> : null}
                {currentSource && values.length === 1 ? (
                    <span className="fw-600 ml-1">{currentSource.name || values[0]}</span>
                ) : null}
            </div>
            <span className="icon-close" onClick={onRemove}>
                <IconClose isSmall />
            </span>
        </>
    );
};

export default ItemFilteredSource;

import React from 'react';

const Loading = () => {
    return (
        <>
            <div className="wrap-loading --addon">
                <div className="boxs boxs--no-border --hasline --mr-line">
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="v2-btn-default loading --animation --transparent --icon-lg mr-0" />
                            <div className="title">
                                <div className="loading --animation --line --half" />
                            </div>
                        </div>
                        <div className="flexcenter loading --fourth">
                            <div className="loading flex-1 switch ml-0">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="v2-btn-default --grey loading btn-sm ml-3">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                    </div>
                    <div className="boxs__contents mt-4">
                        <div className="description loading-lines">
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --onefifth" />
                            <div className="loading --animation --line --ninety" />
                        </div>
                        <div className="d-flex justify-space-between align-center mt-3">
                            <div className="v2-btn-default --transparent loading btn-sm ml-n2">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="link-portal loading --fourth">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-loading --addon">
                <div className="boxs boxs--no-border --hasline">
                    <div className="link-portal loading --threefourth">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="boxs__contents mt-3">
                        <div className="description loading-lines">
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --fifth" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-loading --addon">
                <div className="boxs boxs--power-up loading">
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="title loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="dots powerups loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                        <div className="switch loading --onefifth">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="boxs__contents">
                        <div className="description loading-lines">
                            <div className="loading --animation --line --ninety" />
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-loading --addon mt-2">
                <div className="boxs boxs--power-up loading">
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="title loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="dots powerups loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                        <div className="switch loading --onefifth">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="boxs__contents">
                        <div className="description loading-lines">
                            <div className="loading --animation --line --ninety" />
                            <div className="loading --animation --line --fifth" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-loading --addon mt-2">
                <div className="boxs boxs-has-footer loading">
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="title loading --onefourth ml-0">
                                <div className="loading --animation --line --full"></div>
                            </div>
                        </div>
                    </div>
                    <div className="boxs__contents mt-4">
                        <div className="description loading-lines">
                            <div className="loading --animation --line --full"></div>
                            <div className="loading --animation --line --full"></div>
                            <div className="loading --animation --line --full"></div>
                            <div className="loading --animation --line --half"></div>
                        </div>
                    </div>
                    <div className="boxs__footer">
                        <div className="v2-btn-default field-h24 loading --grey btn-sm">
                            <div className="loading --animation --line --full"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-loading --addon mt-2">
                <div className="boxs boxs-has-footer loading">
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="title loading --onefourth ml-0">
                                <div className="loading --animation --line --full"></div>
                            </div>
                        </div>
                    </div>
                    <div className="boxs__contents mt-4">
                        <div className="description loading-lines">
                            <div className="loading --animation --line --full"></div>
                            <div className="loading --animation --line --full"></div>
                            <div className="loading --animation --line --full"></div>
                            <div className="loading --animation --line --half"></div>
                        </div>
                    </div>
                    <div className="boxs__footer">
                        <div className="v2-btn-default field-h24 loading --grey btn-sm">
                            <div className="loading --animation --line --full"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Loading;

import React from 'react';

import IconClose from 'assets/icon/IconClose';

const Header = ({ fullName, summary, handleClose = () => {} }) => {
    return (
        <div className="message-header">
            <div className="message-header__title flexcenter gap-8">
                <div className="flex-column flex-1 txt-ellipsis">
                    <div className="fs-14 fw-500 purple-default txt-ellipsis" title={fullName}>
                        {fullName}
                    </div>
                    <div className="black txt-ellipsis" title={summary}>
                        {summary}
                    </div>
                </div>
            </div>
            <div className="v2-btn-default --icon-lg --transparent" onClick={handleClose}>
                <IconClose />
            </div>
        </div>
    );
};

export default Header;

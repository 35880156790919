export default function IconSMS({
    isOpportunity = false,
    isAddons = false,
    isAddonsGrey = false,
    isGreen = false,
    isHasDots = false,
    isBlack = false
}) {
    if (isOpportunity) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.0436 4C13.7645 4 15.0492 4.30916 16.1347 4.8897C17.2202 5.47024 18.0722 6.32215 18.6527 7.40766C19.2332 8.49318 19.5424 9.77796 19.5424 11.4988V12.0437C19.5424 12.3365 19.5334 12.6167 19.5157 12.8854L19.5424 12.8577L19.5026 13.0636C19.4492 13.7144 19.3427 14.297 19.1859 14.8289C19.0459 16.1906 19.2959 17.5694 19.9125 18.8024C20.1168 19.2112 19.9511 19.7082 19.5424 19.9126C19.3094 20.0291 19.0352 20.0291 18.8023 19.9126C17.5653 19.2941 16.1815 19.0452 14.8153 19.1879C14.2968 19.3419 13.729 19.4462 13.0967 19.4999L12.8814 19.5426L12.9094 19.5143C12.6334 19.5331 12.3452 19.5426 12.0436 19.5426H11.4988C9.77789 19.5426 8.49313 19.2334 7.40763 18.6529C6.32212 18.0723 5.47022 17.2204 4.88969 16.1349C4.30916 15.0494 4 13.7646 4 12.0437V11.4988C4 9.77796 4.30916 8.49318 4.88969 7.40766C5.47022 6.32215 6.32212 5.47024 7.40763 4.8897C8.49313 4.30916 9.77789 4 11.4988 4H12.0436ZM8.07062 10.6611C7.45749 10.6611 6.96045 11.1581 6.96045 11.7713C6.96045 12.3844 7.45749 12.8815 8.07062 12.8815C8.68376 12.8815 9.18079 12.3844 9.18079 11.7713C9.18079 11.1581 8.68376 10.6611 8.07062 10.6611ZM11.7712 10.6611C11.158 10.6611 10.661 11.1581 10.661 11.7713C10.661 12.3844 11.158 12.8815 11.7712 12.8815C12.3843 12.8815 12.8814 12.3844 12.8814 11.7713C12.8814 11.1581 12.3843 10.6611 11.7712 10.6611ZM15.4718 10.6611C14.8586 10.6611 14.3616 11.1581 14.3616 11.7713C14.3616 12.3844 14.8586 12.8815 15.4718 12.8815C16.0849 12.8815 16.5819 12.3844 16.5819 11.7713C16.5819 11.1581 16.0849 10.6611 15.4718 10.6611Z"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
    if (isGreen)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                {' '}
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FAFBFC"
                />{' '}
                <path
                    d="M24 4H14C12.3431 4 11 5.34315 11 7V25C11 26.6569 12.3431 28 14 28H24C25.6569 28 27 26.6569 27 25V7C27 5.34315 25.6569 4 24 4Z"
                    fill="#C0E5CF"
                />{' '}
                <path
                    d="M21 6H19C18.4477 6 18 6.44772 18 7C18 7.55228 18.4477 8 19 8H21C21.5523 8 22 7.55228 22 7C22 6.44772 21.5523 6 21 6Z"
                    fill="#1EAA5C"
                />{' '}
                <path
                    d="M17 7C17 6.44772 16.5523 6 16 6C15.4477 6 15 6.44772 15 7C15 7.55228 15.4477 8 16 8C16.5523 8 17 7.55228 17 7Z"
                    fill="#1EAA5C"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21 23H7C5.89543 23 5 22.1046 5 21V9.41421C5 8.86193 5.44772 8.41421 6 8.41421C6.26522 8.41421 6.51957 8.51957 6.70711 8.70711L8.70711 10.7071C8.89464 10.8946 9.149 11 9.41421 11H21C22.1046 11 23 11.8954 23 13V21C23 22.1046 22.1046 23 21 23Z"
                    fill="#1EAA5C"
                />{' '}
                <path
                    d="M19 14H9C8.44772 14 8 14.4477 8 15C8 15.5523 8.44772 16 9 16H19C19.5523 16 20 15.5523 20 15C20 14.4477 19.5523 14 19 14Z"
                    fill="#C0E5CF"
                />{' '}
                <path
                    d="M15 18H9C8.44772 18 8 18.4477 8 19C8 19.5523 8.44772 20 9 20H15C15.5523 20 16 19.5523 16 19C16 18.4477 15.5523 18 15 18Z"
                    fill="#C0E5CF"
                />{' '}
            </svg>
        );

    if (isAddons) {
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FAFBFC"
                />
                <path
                    d="M24 4H14C12.3431 4 11 5.34315 11 7V25C11 26.6569 12.3431 28 14 28H24C25.6569 28 27 26.6569 27 25V7C27 5.34315 25.6569 4 24 4Z"
                    fill="#dbcbff"
                />
                <path
                    d="M21 6H19C18.4477 6 18 6.44772 18 7C18 7.55228 18.4477 8 19 8H21C21.5523 8 22 7.55228 22 7C22 6.44772 21.5523 6 21 6Z"
                    fill="#8d4afc"
                />
                <path
                    d="M17 7C17 6.44772 16.5523 6 16 6C15.4477 6 15 6.44772 15 7C15 7.55228 15.4477 8 16 8C16.5523 8 17 7.55228 17 7Z"
                    fill="#8d4afc"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21 23H7C5.89543 23 5 22.1046 5 21V9.41421C5 8.86193 5.44772 8.41421 6 8.41421C6.26522 8.41421 6.51957 8.51957 6.70711 8.70711L8.70711 10.7071C8.89464 10.8946 9.149 11 9.41421 11H21C22.1046 11 23 11.8954 23 13V21C23 22.1046 22.1046 23 21 23Z"
                    fill="#8d4afc"
                />
                <path
                    d="M19 14H9C8.44772 14 8 14.4477 8 15C8 15.5523 8.44772 16 9 16H19C19.5523 16 20 15.5523 20 15C20 14.4477 19.5523 14 19 14Z"
                    fill="#dbcbff"
                />
                <path
                    d="M15 18H9C8.44772 18 8 18.4477 8 19C8 19.5523 8.44772 20 9 20H15C15.5523 20 16 19.5523 16 19C16 18.4477 15.5523 18 15 18Z"
                    fill="#dbcbff"
                />
            </svg>
        );
    }
    if (isAddonsGrey) {
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FAFBFC"
                />{' '}
                <path
                    d="M24 4H14C12.3431 4 11 5.34315 11 7V25C11 26.6569 12.3431 28 14 28H24C25.6569 28 27 26.6569 27 25V7C27 5.34315 25.6569 4 24 4Z"
                    fill="#C1C9D4"
                />{' '}
                <path
                    d="M21 6H19C18.4477 6 18 6.44772 18 7C18 7.55228 18.4477 8 19 8H21C21.5523 8 22 7.55228 22 7C22 6.44772 21.5523 6 21 6Z"
                    fill="var(--color-icon)"
                />{' '}
                <path
                    d="M17 7C17 6.44772 16.5523 6 16 6C15.4477 6 15 6.44772 15 7C15 7.55228 15.4477 8 16 8C16.5523 8 17 7.55228 17 7Z"
                    fill="var(--color-icon)"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21 23H7C5.89543 23 5 22.1046 5 21V9.41421C5 8.86193 5.44772 8.41421 6 8.41421C6.26522 8.41421 6.51957 8.51957 6.70711 8.70711L8.70711 10.7071C8.89464 10.8946 9.149 11 9.41421 11H21C22.1046 11 23 11.8954 23 13V21C23 22.1046 22.1046 23 21 23Z"
                    fill="var(--color-icon)"
                />{' '}
                <path
                    d="M19 14H9C8.44772 14 8 14.4477 8 15C8 15.5523 8.44772 16 9 16H19C19.5523 16 20 15.5523 20 15C20 14.4477 19.5523 14 19 14Z"
                    fill="#C1C9D4"
                />{' '}
                <path
                    d="M15 18H9C8.44772 18 8 18.4477 8 19C8 19.5523 8.44772 20 9 20H15C15.5523 20 16 19.5523 16 19C16 18.4477 15.5523 18 15 18Z"
                    fill="#C1C9D4"
                />{' '}
            </svg>
        );
    }
    if (isHasDots) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx={12} cy={11} r={1} stroke="var(--color-icon)" strokeWidth="1.1" />
                <circle cx={8} cy={11} r={1} stroke="var(--color-icon)" strokeWidth="1.1" />
                <circle cx={16} cy={11} r={1} stroke="var(--color-icon)" strokeWidth="1.1" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.5 5.5H17.5C18.6046 5.5 19.5 6.39543 19.5 7.5V18.4194C19.5 18.9717 19.0523 19.4194 18.5 19.4194C18.2729 19.4194 18.0526 19.3421 17.8753 19.2002L14.7739 16.7191C14.5966 16.5773 14.3763 16.5 14.1492 16.5H6.5C5.39543 16.5 4.5 15.6046 4.5 14.5V7.5C4.5 6.39543 5.39543 5.5 6.5 5.5Z"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
    if (isBlack) {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.0436 4C13.7645 4 15.0492 4.30916 16.1347 4.8897C17.2202 5.47024 18.0722 6.32215 18.6527 7.40766C19.2332 8.49318 19.5424 9.77796 19.5424 11.4988V12.0437C19.5424 12.3365 19.5334 12.6167 19.5157 12.8854L19.5424 12.8577L19.5026 13.0636C19.4492 13.7144 19.3427 14.297 19.1859 14.8289C19.0459 16.1906 19.2959 17.5694 19.9125 18.8024C20.1168 19.2112 19.9511 19.7082 19.5424 19.9126C19.3094 20.0291 19.0352 20.0291 18.8023 19.9126C17.5653 19.2941 16.1815 19.0452 14.8153 19.1879C14.2968 19.3419 13.729 19.4462 13.0967 19.4999L12.8814 19.5426L12.9094 19.5143C12.6334 19.5331 12.3452 19.5426 12.0436 19.5426H11.4988C9.77789 19.5426 8.49313 19.2334 7.40763 18.6529C6.32212 18.0723 5.47022 17.2204 4.88969 16.1349C4.30916 15.0494 4 13.7646 4 12.0437V11.4988C4 9.77796 4.30916 8.49318 4.88969 7.40766C5.47022 6.32215 6.32212 5.47024 7.40763 4.8897C8.49313 4.30916 9.77789 4 11.4988 4H12.0436ZM8.07062 10.6611C7.45749 10.6611 6.96045 11.1581 6.96045 11.7713C6.96045 12.3844 7.45749 12.8815 8.07062 12.8815C8.68376 12.8815 9.18079 12.3844 9.18079 11.7713C9.18079 11.1581 8.68376 10.6611 8.07062 10.6611ZM11.7712 10.6611C11.158 10.6611 10.661 11.1581 10.661 11.7713C10.661 12.3844 11.158 12.8815 11.7712 12.8815C12.3843 12.8815 12.8814 12.3844 12.8814 11.7713C12.8814 11.1581 12.3843 10.6611 11.7712 10.6611ZM15.4718 10.6611C14.8586 10.6611 14.3616 11.1581 14.3616 11.7713C14.3616 12.3844 14.8586 12.8815 15.4718 12.8815C16.0849 12.8815 16.5819 12.3844 16.5819 11.7713C16.5819 11.1581 16.0849 10.6611 15.4718 10.6611Z"
                    fill="var(--granite-gray)"
                ></path>
            </svg>
        );
    }
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.5 5.5H17.5C18.6046 5.5 19.5 6.39543 19.5 7.5V18.4194C19.5 18.9717 19.0523 19.4194 18.5 19.4194C18.2729 19.4194 18.0526 19.3421 17.8753 19.2002L14.7739 16.7191C14.5966 16.5773 14.3763 16.5 14.1492 16.5H6.5C5.39543 16.5 4.5 15.6046 4.5 14.5V7.5C4.5 6.39543 5.39543 5.5 6.5 5.5Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.5 9.5H15.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.5 12.5H13.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export const ONE = 'one';
export const TWO = 'two';
export const THREE = 'three';
export const CODE = 'code';
export const INDUSTRY = 'industry';
export const TEAMSIZE = 'team_size';
export const COMPANY_NAME = 'company_name';
export const FIRST_NAME = 'first_name';
export const LAST_NAME = 'last_name';
export const COMPANY_PHONE = 'company_phone';
export const SOURCE = 'source';
export const NAME = 'name';
export const PHONE = 'phone';
export const COUNTRY = 'country';
export const COUNTRY_CODE = 'country_code';
export const EMAIL = 'email';
export const PHONE_NUMBER_LENGTH = 10;

export const STEP = {
    [ONE]: {
        id: ONE,
        value: 1,
        title: 'let_get_you_started',
        subTitle: ''
    },
    [TWO]: {
        id: TWO,
        value: 2,
        title: 'thank_tell_us_about_yourself',
        subTitle: ''
    },
    [THREE]: {
        id: THREE,
        value: 3,
        title: 'last_step_confirm_you_are_human',
        subTitle: ''
    }
};

export const DEFAULT_INDUSTRY = 'Default Industry';
export const CLEANING = 'Cleaning';
const LAWN = 'Lawn';
const TRADES = 'Trades';
const MORE = 'More';

export const LIST_INDUSTRY = {
    [DEFAULT_INDUSTRY]: [
        { id: '40', value: 'Pest Control' },
        { id: '92', value: 'Pest & Wildlife' },
        { id: '91', value: 'Wildlife Control' },
        { id: '93', value: 'Mosquito Control' },
        { id: '33', value: 'Lawn Care' },
        { id: '100', value: 'Lawn Care & Maintenance' },
        { id: '32', value: 'Landscaping' },
        { id: '52', value: 'Tree Care' },
        { id: '94', value: 'Bin Cleaning' },
        { id: '6', value: 'Cleaning' },
        { id: '98', value: 'Residential Cleaning' },
        { id: '96', value: 'Commercial Cleaning' }
    ],
    [CLEANING]: [
        { id: '95', value: 'Carpet Cleaning' },
        { id: '97', value: 'Pressure Washing' },
        { id: '99', value: 'Window Washing' }
    ],
    [LAWN]: [{ id: '101', value: 'Landscaping Contractor' }],
    [TRADES]: [
        { id: '8', value: 'Construction' },
        { id: '102', value: 'Electrical Contractor' },
        { id: '103', value: 'HVAC' },
        { id: '34', value: 'Locksmith' },
        { id: '104', value: 'Mechanical Service' },
        { id: '44', value: 'Plumbing' }
    ],
    [MORE]: [
        { id: '1', value: 'Air Filtration' },
        { id: '2', value: 'Appliance Repair' },
        { id: '3', value: 'Appraisers' },
        { id: '4', value: 'Audio & A/V' },
        { id: '5', value: 'Auto Repair' },
        { id: '7', value: 'Computer / IT Service' },
        { id: '9', value: 'Consultant' },
        { id: '10', value: 'Contractor' },
        { id: '11', value: 'Delivery' },
        { id: '12', value: 'Duct Cleaning' },
        { id: '13', value: 'Electrical' },
        { id: '105', value: 'Entertainment' },
        { id: '14', value: 'Event Planning' },
        { id: '15', value: 'Facility Maintenance' },
        { id: '16', value: 'Fertilization' },
        { id: '18', value: 'Fire Protection' },
        { id: '17', value: 'Fire and Water Restoration' },
        { id: '19', value: 'Fitness' },
        { id: '20', value: 'Flooring' },
        { id: '106', value: 'Flooring Service' },
        { id: '21', value: 'Glass Repair' },
        { id: '22', value: 'Grounds Maintenance' },
        { id: '23', value: 'Gutter Installation / Service' },
        { id: '24', value: 'Handyman' },
        { id: '25', value: 'Heating and A/C' },
        { id: '26', value: 'Holiday Lighting' },
        { id: '27', value: 'Home Health Care' },
        { id: '28', value: 'Home Inspector' },
        { id: '30', value: 'Inspection Service' },
        { id: '29', value: 'Irrigation Service' },
        { id: '31', value: 'Janitorial' },
        { id: '107', value: 'Junk Removal' },
        { id: '35', value: 'Maid Service' },
        { id: '36', value: 'Mobile Detailing' },
        { id: '38', value: 'Painting' },
        { id: '39', value: 'Personal Trainer' },
        { id: '41', value: 'Pet Grooming' },
        { id: '42', value: 'Pet Sitting' },
        { id: '43', value: 'Pet Waste Removal' },
        { id: '45', value: 'Pool & Spa' },
        { id: '46', value: 'Power Washing' },
        { id: '47', value: 'Propane Delivery' },
        { id: '48', value: 'Property Management' },
        { id: '108', value: 'Renovations' },
        { id: '49', value: 'Roofing' },
        { id: '50', value: 'Security Systems' },
        { id: '109', value: 'Snow Removal' },
        { id: '51', value: 'Spray Tanning' },
        { id: '110', value: 'Sprinkler Repair' },
        { id: '53', value: 'Tutor' },
        { id: '54', value: 'Water Treatment' },
        { id: '59', value: 'Wildlife' },
        { id: '55', value: 'Window & Door Installers' },
        { id: '56', value: 'Window Cleaning' },
        { id: '37', value: 'Other' }
    ]
};

export const LIST_TEAM_SIZE = [
    { id: 1, value: 'Just me' },
    { id: 2, value: '2-3 people' },
    { id: 3, value: '4-10 people' },
    { id: 4, value: '10+ people' }
];

export const WORD_VALIDATE_EMAIL = '[a-zA-Z0-9-_+.@]';
export const REGEX_VALIDATE_EMAIL = /^[a-zA-Z0-9\-_+.@]+$/;
export const VALIDATE_PHONE_BY_CODE = {
    US: 'US',
    CA: 'CA'
};

export const DOCUMENT_LIBRARY = {
    ALL: -1,
    DOC: 1,
    PDF: 2
};

export const TABS_DOCUMENT_FILTER = [
    {
        value: DOCUMENT_LIBRARY.DOC,
        label: 'docs'
    },
    {
        value: DOCUMENT_LIBRARY.PDF,
        label: 'pdfs'
    }
];

export const TABS_DOCUMENT_LIBRARY = [
    {
        value: DOCUMENT_LIBRARY.ALL,
        label: 'all'
    },
    ...TABS_DOCUMENT_FILTER
];

export const TYPE_PRESIGN_PDF = 'document_pdf';

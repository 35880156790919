import moment from 'moment';
import React, { useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonSave from 'app/components/button/ButtonSave';
import GdConfirm from 'app/components/confirm';
import StatusBar from 'app/components/status/statusbar';
import { ACTIONS_DELETE_INVOICE, getEstimateDetail, REPORT_ESTIMATE, updateInvoiceDetail } from 'app/const/Api';
import { LIST_STATUS } from 'app/const/Status';
import CustomerInvoiceConfirmDelete from 'app/modules/customer/detail/invoices/components/CustomerInvoiceConfirmDelete';
import AddInvoiceContextProvider from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import { InvoiceDetailContext } from 'app/modules/jobdetail/contexts/InvoiceDetailContext';
import ConfirmAddInvoice from 'app/modules/jobdetail/modal/ConfirmAddInvoice';
import InvoiceLocation from 'app/modules/jobdetail/tabs/addinvoice/form/InvoiceLocation';
import InvoiceInfo from 'app/modules/jobdetail/tabs/addinvoice/form/info';
import InvoiceService from 'app/modules/jobdetail/tabs/addinvoice/form/items';
import InvoiceNotes from 'app/modules/jobdetail/tabs/addinvoice/form/notes';
import InvoiceRepeat from 'app/modules/jobdetail/tabs/addinvoice/form/settings/ButtonRepeat';
import InvoiceSummary from 'app/modules/jobdetail/tabs/addinvoice/form/summary';
import InvoiceTerms from 'app/modules/jobdetail/tabs/addinvoice/form/terms';
import IconGoto from 'assets/icon/IconGoto';
import IconTrash from 'assets/icon/IconTrash';
import { clientQuery } from 'common/utils/ApiUtils';
import { convertTimeToISO } from 'common/utils/DateUtils';
import { DEFAULT_VALUE_PACKAGE, ESTIMATE_TYPE } from '../../const/Estimate';
import { TYPES_PHOTO } from '../../const/Images';
import { DEFAULT_VALUE, INVOICE_STATUS } from '../../const/Invoice';
import EditInvoiceImages from '../invoice/EditInvoiceImages';

export const convertDiscount = (currentDiscount) => {
    const { type: discountType } = currentDiscount || {};
    const checkDiscouunt = discountType !== null;

    return {
        type: checkDiscouunt ? parseInt(discountType) : 1,
        value: checkDiscouunt ? currentDiscount.value : '',
        total: {
            value: checkDiscouunt ? currentDiscount?.total?.value || '' : ''
        },
        is_paid: checkDiscouunt ? currentDiscount.is_paid : false,
        repeat: currentDiscount?.repeat || 0
    };
};

const EditInvoice = ({
    onClose,
    onDelete = () => {},
    onUpdateBalance,
    isInvoice,
    isAddPayment = true,
    hideRemove = false,
    isEstimateCustomer = false
}) => {
    const { t } = useTranslation();
    const { invoiceDetail, onReloadInvoiceData, onDeleteInvoice, onUpdateInvoiceData } =
        useContext(InvoiceDetailContext);

    const {
        customer: customerInfo = {},
        location: locationInfo = {},
        id: finalInvoiceId,
        invoice_status_id: invoiceStatusId,
        isOpenWithJob,
        isInvoiceRecurring,
        repeat: finalRepeat
    } = invoiceDetail;
    const jobId = invoiceDetail?.job?.id;

    const isInvoicePaided = invoiceStatusId === INVOICE_STATUS.PAID;

    const refContent = useRef(null);
    const refSummary = useRef();
    const refStatusBar = useRef(null);
    const refConfirm = useRef(null);
    const refConfirmDelete = useRef(null);
    const refConfirmPenaltyDelete = useRef(null);
    const refEditInvoiceImages = useRef(null);
    const refButtonSave = useRef(null);

    const defaultData = useMemo(() => {
        const {
            discount: currentDiscount = {},
            deposit: currentDeposit = DEFAULT_VALUE_PACKAGE.deposit,
            date: invoiceDate,
            recurrence: invoiceRecurrence,
            next_date
        } = invoiceDetail;
        const { value: invoiceNextDate } = next_date || {};

        let moreProps = {};

        if (!isInvoice) {
            moreProps = {
                ...moreProps,
                type: invoiceDetail.type || ESTIMATE_TYPE.BASIC,
                packages: invoiceDetail.packages.map((itemPackage) => {
                    const { discount: packageDiscount, deposit: packageDeposit } = itemPackage || {};
                    return {
                        ...itemPackage,
                        subtotal: itemPackage.subtotal.value,
                        total: itemPackage.total.value,
                        discount: convertDiscount(packageDiscount),
                        deposit: {
                            ...convertDiscount(packageDeposit),
                            isInvoice,
                            invoices: packageDeposit?.invoices || []
                        }
                    };
                }),
                payment_terms: invoiceDetail.payment_terms || {}
            };
        }

        return {
            id: finalInvoiceId,
            discount: convertDiscount(currentDiscount),
            deposit: { ...convertDiscount(currentDeposit), isInvoice, invoices: currentDeposit?.invoices || [] },
            number: invoiceDetail.number,
            po_number: invoiceDetail.po_number,
            date: invoiceDetail.date.value,
            next_date: invoiceNextDate || null,
            subtotal: invoiceDetail.subtotal.value,
            total: invoiceDetail.total.value,
            trigger_action: '1',
            recurrence: invoiceRecurrence
                ? {
                      ...invoiceRecurrence,
                      offset: invoiceRecurrence.offset
                          ? {
                                ...invoiceRecurrence.offset,
                                nextDateInvoice: invoiceNextDate || invoiceDate.value, // Date next invoice
                                dateIssue: invoiceDate.value // Date create invoice
                            }
                          : {}
                  }
                : null,
            items: invoiceDetail.items,
            shouldUpdateTermAndNote: false,
            terms: invoiceDetail.terms,
            note: invoiceDetail.note,
            noteDefault: invoiceDetail.noteDefault || invoiceDetail.default?.note || '',
            termsDefault: invoiceDetail.termsDefault || invoiceDetail.default?.terms || '',
            amount_due: invoiceDetail.amount_due,
            available_credit: invoiceDetail.available_credit,
            available_balance: invoiceDetail.available_balance,
            customer: customerInfo,
            sign_status: invoiceDetail.sign_status,
            invoice_no: invoiceDetail.invoice_no,
            items_surcharge: invoiceDetail.items_surcharge,
            has_service: invoiceDetail.has_service,
            customer_id: customerInfo.id,
            location_id: locationInfo.id,
            locationInfo,
            isInvoiceRecurring: isInvoiceRecurring,
            isJobInvoice: !!jobId,
            customer_job_id: jobId,
            note_repeat: finalRepeat?.note,
            status: invoiceDetail.status,
            invoice_status_id: invoiceDetail.invoice_status_id,
            ...moreProps,
            payment_terms: invoiceDetail.payment_terms || {}
        };
    }, [invoiceDetail.reloadInvoice]);

    function _handleClose(e) {
        onClose(e);
    }

    const _handleError = (msg) => {
        _removeLoading();

        refStatusBar.current.showStatusBar(
            'show_error',
            msg ||
                t(
                    `common:${
                        isInvoice ? 'one_or_more_invoice_field_not_complete' : 'one_or_more_estimate_field_not_complete'
                    }`
                ),
            LIST_STATUS.ERROR
        );
        refContent.current.scrollIntoView();
        return false;
    };

    const _getOldPaymentValue = (invoices = []) => {
        return invoices.reduce((accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue.total.value);
        }, 0);
    };

    const _checkDeposit = (isInvoice, depositValue, total, packageId) => {
        const finalValueDeposit = parseFloat(depositValue.total.value);

        if (parseFloat(total) >= 0 && finalValueDeposit > parseFloat(total)) {
            const elm = document.getElementById(`deposit_cost_${packageId}`);
            elm && elm.classList.add('field-error');
            _handleError(
                t('common:message_error_value_deposit_with_name', {
                    name: t(`common:${isInvoice ? 'invoice' : 'estimate'}`)
                })
            );
            return false;
        }

        const listInvoice = depositValue.invoices || [];
        const totalInvoicePaied = _getOldPaymentValue(depositValue.invoices || []);

        if (!!listInvoice.length && !!totalInvoicePaied && finalValueDeposit < totalInvoicePaied) {
            const elm = document.getElementById(`deposit_cost_${packageId}`);
            elm && elm.classList.add('field-error');
            _handleError(t('jobDetail:message_change_deposit_paid'));
            return false;
        }

        return true;
    };

    function _handleEditInvoice(trigger_action) {
        const refValue = refSummary.current.getValue();
        const { type: estimateType, packages: estimatePackages, items: listItems, total: invoiceTotal } = refValue;

        const itemsSubmit = [];
        let checkPass = true;
        const recurrence = { ...refValue.recurrence };
        const imagesAttach = refEditInvoiceImages.current ? refEditInvoiceImages.current.getImages() : {};
        const finalDeposit = { ...(refValue.deposit || DEFAULT_VALUE.deposit) };

        recurrence.repeat && refConfirm.current._handleCloseForm();

        if (!!imagesAttach?.add?.length && imagesAttach.add?.some((item) => !item.object_key)) {
            _removeLoading();

            refStatusBar.current.showStatusBar('show_error', t('jobDetail:some_images_being_sync'), LIST_STATUS.ERROR);
            refContent.current.scrollIntoView();
            return false;
        }

        if (!isInvoice && estimateType === ESTIMATE_TYPE.PACKAGES) {
            let checkPassDeposit = true;

            estimatePackages.every((itemPackage) => {
                const { id: packageId, name: packageName, deposit, total } = itemPackage;

                checkPassDeposit = _checkDeposit(isInvoice, deposit, total, packageId);

                if (!checkPassDeposit) {
                    return false;
                }

                if (!!!packageName) {
                    checkPass = false;
                    document.getElementById(`package_item_name_${packageId}`).classList.add('field-error');
                }

                if (!listItems.some((lineItem) => lineItem.package_id === packageId)) {
                    checkPass = false;
                    document.getElementById(`package_item_detail_${packageId}`).classList.remove('dp-hide');
                }

                return true;
            });

            if (!checkPassDeposit) {
                return false;
            }
        } else {
            if (!_checkDeposit(isInvoice, finalDeposit, invoiceTotal, '')) {
                return false;
            }
        }

        if (!checkPass) {
            return _handleError();
        }

        listItems.forEach((element) => {
            const tax1Detail = element.tax1 || {};
            const tax2Detail = element.tax2 || {};
            const { item_id: serviceItemId, id: itemId, cost: itemCost } = element;
            const checkItemCost = itemCost.value.length !== 0;

            if (checkItemCost && serviceItemId) {
                itemsSubmit.push({
                    item_id: serviceItemId,
                    name: element.name,
                    quantity: element.quantity,
                    cost: itemCost.value,
                    tax1_id: tax1Detail.id || '',
                    tax2_id: tax2Detail.id || '',
                    tax1_name: tax1Detail.name || '',
                    tax2_name: tax2Detail.name || '',
                    tax1_rate: tax1Detail.rate || '',
                    tax2_rate: tax2Detail.rate || '',
                    description: element.description,
                    one_time: element.one_time || 0,
                    sold_by_ids: element.sold_by_ids || [],
                    type: element.type || '',
                    package_id: element.package_id || '',
                    is_check: element.is_check || false,
                    id: itemId
                });
            } else {
                checkPass = false;
                if (!checkItemCost) {
                    document.getElementById(`service_item_cost_${itemId}`).classList.add('field-error');
                }
                if (!serviceItemId) {
                    document.getElementById(`service_item_detail_${itemId}`).classList.add('field-error');
                }
            }
        });

        if (!checkPass) {
            return _handleError();
        }

        const offset = recurrence.offset || {};
        const finalDiscount = { ...refValue.discount };
        delete offset.dateIssue;
        delete offset.nextDateInvoice;
        delete finalDiscount.total;

        const finalURL = isInvoice ? updateInvoiceDetail(refValue.id) : getEstimateDetail(refValue.id);

        clientQuery(
            finalURL,
            {
                method: 'PUT',
                data: {
                    customer_job_id: jobId || '',
                    customer_id: customerInfo.id,
                    discount: finalDiscount,
                    deposit: isInvoice ? null : finalDeposit,
                    number: refValue.number?.toString(),
                    po_number: refValue.po_number,
                    date: refValue.date,
                    items: itemsSubmit,
                    subtotal: refValue.subtotal,
                    total: refValue.total,
                    trigger_action: trigger_action,
                    recurrence: {
                        action: recurrence.action,
                        offset: offset
                    },
                    location_id: refValue.location_id,
                    terms: refValue.terms,
                    note: refValue.note,
                    type: isInvoice ? (isInvoiceRecurring ? 2 : 1) : estimateType,
                    images: imagesAttach,
                    note_repeat: refValue.note_repeat,
                    packages: estimatePackages,
                    payment_terms_id: refValue.payment_terms.id || ''
                },
                toFormData: false
            },
            _handleEditInvoiceSuccess,
            _handleEditInvoiceFail
        );
    }

    const _handleEditInvoiceSuccess = () => {
        _removeLoading();
        onReloadInvoiceData(true);
        onClose();
    };

    const _removeLoading = () => {
        refButtonSave.current && refButtonSave.current.removeLoading();
    };

    const _handleEditInvoiceFail = (response) => {
        _removeLoading();
        refConfirm.current._handleCloseForm();
        refStatusBar.current.showStatusBar(
            'show_error',
            response?.message?.toString() || t('common:please_try_again'),
            LIST_STATUS.ERROR
        );
    };

    const _handleOpenConfirm = () => {
        const refValue = refSummary.current.getValue();
        const dateNow = convertTimeToISO(moment());
        const currentInvoiceData = refValue.date;
        const isInvoiceDatePast =
            moment(currentInvoiceData).utc().startOf('day').unix() <= moment(dateNow).utc().startOf('day').unix();
        const invoiceRecurrence = refValue?.recurrence || {};

        if (
            invoiceRecurrence.repeat &&
            !isInvoiceRecurring &&
            isInvoiceDatePast &&
            INVOICE_STATUS.DRAFT === invoiceDetail.invoice_status_id
        ) {
            refConfirm.current._handleOpenForm(invoiceRecurrence.action);
        } else {
            _handleEditInvoice('0');
        }
    };

    function _handleAddPaymentSuccess() {
        onReloadInvoiceData(false);
    }

    function _openConfirmDelete() {
        refConfirmDelete.current.open();
    }

    function _deleteInvoiceConfirm(response, ids) {
        if (response.is_payments)
            return refConfirmPenaltyDelete.current._open({ data_payment: response.data_payment, ids });

        onDelete();
        onDeleteInvoice();
    }

    function _handleDeleteInvoice() {
        clientQuery(
            isInvoice ? ACTIONS_DELETE_INVOICE : REPORT_ESTIMATE,
            {
                method: 'DELETE',
                data: { ids: [finalInvoiceId] }
            },
            (response) => _deleteInvoiceConfirm(response, [finalInvoiceId])
        );
    }

    function _handleTriggerButtonSave(value) {
        refButtonSave.current.setDisable(value);
    }

    const _handleCloseConfirm = () => {
        refButtonSave.current.removeLoading();
    };

    return (
        <div className="container-column form-edit-template has-form elm-parent">
            <div ref={refContent} className="form-edit-template__content">
                <StatusBar ref={refStatusBar} />
                <div className="content-elm-edit header-modal flex-auto">
                    <div className="d-flex name-tabs">
                        <IconGoto />
                        <div className="txt-ellipsis ml-2">
                            {t(`jobDetail:${isInvoice ? 'edit_invoice' : 'edit_estimate'}`)}
                        </div>
                    </div>
                    <ConfirmAddInvoice
                        ref={refConfirm}
                        title={t('jobDetail:edit_invoice')}
                        handleConfirm={_handleEditInvoice}
                        onClose={_handleCloseConfirm}
                    />
                </div>
                <AddInvoiceContextProvider
                    reloadEditInvoice={invoiceDetail.reloadInvoice}
                    invoiceData={defaultData}
                    onUpdateButtonSave={_handleTriggerButtonSave}
                    isInvoice={isInvoice}
                >
                    <div className="rows --header-info">
                        {isInvoice && <InvoiceRepeat />}
                        <div className="content-elm-edit customer-info">
                            <InvoiceLocation isInvoice={isInvoice} locationInfo={locationInfo} />
                            <InvoiceInfo isInvoice={isInvoice} isFetchInit={false} />
                        </div>
                    </div>

                    <InvoiceService isEditInvoice isInvoice={isInvoice} showOnTime />

                    <InvoiceSummary
                        isAddPayment={isAddPayment}
                        addPaymentSuccess={_handleAddPaymentSuccess}
                        ref={refSummary}
                        isInvoice={isInvoice}
                        onUpdateBalance={onUpdateBalance}
                    />

                    <div className="content-elm-edit rows note-details">
                        <div className="dashboard-wrapper --main">
                            <div className="content-top-notes">
                                <InvoiceTerms isInvoice={isInvoice} onUpdateDefaultSuccess={onUpdateInvoiceData} />
                                <InvoiceNotes
                                    isInvoice={isInvoice}
                                    jobId={jobId}
                                    onUpdateDefaultSuccess={onUpdateInvoiceData}
                                />
                                {!isInvoiceRecurring && (
                                    <EditInvoiceImages ref={refEditInvoiceImages} parentType={TYPES_PHOTO.INVOICE} />
                                )}
                            </div>
                        </div>
                    </div>
                </AddInvoiceContextProvider>
            </div>

            <GdConfirm
                ref={refConfirmDelete}
                title={t('common:confirm')}
                message={t('jobDetail:are_you_sure_delete_this', {
                    text: t(`common:${isInvoice ? 'invoice' : 'estimate'}`)
                })}
                listButton={{ cancel: true, confirm: true }}
                onConfirm={_handleDeleteInvoice}
            />

            <CustomerInvoiceConfirmDelete ref={refConfirmPenaltyDelete} onDeleteSuccess={_deleteInvoiceConfirm} />

            <div className="footer-modal is-sticky is-footer-inv">
                {!hideRemove && !isEstimateCustomer && !isInvoicePaided && !!isOpenWithJob && (
                    <div className="v2-btn-default has-icon --delete" tabIndex="0" onClick={_openConfirmDelete}>
                        <IconTrash />
                        {t(`jobDetail:${isInvoice ? 'delete_invoice' : 'delete_estimate'}`)}
                    </div>
                )}
                <div className="flexcenter justify-end flex-1">
                    <div className="v2-btn-default --transparent" onClick={_handleClose} tabIndex="0">
                        {t('common:cancel')}
                    </div>
                    <ButtonSave
                        ref={refButtonSave}
                        className="v2-btn-main ml-2"
                        onSave={_handleOpenConfirm}
                        title={t('common:save')}
                    />
                </div>
            </div>
        </div>
    );
};

export default EditInvoice;

export const MATERIAL_TYPES = {
    MATERIAL: 1,
    METHOD: 2,
    LOCATION: 3,
    TARGET_PEST: 4,
    UNIT: 5,
    AREA: 6,
    DILUTION: 7,
    CUSTOM_MATERIAL: 8
};

import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import IconThreeDots from 'assets/icon/IconThreeDots';
import IconPlus from 'assets/icon/IconPlus';
import GdConfirm from 'app/components/confirm';
import { useTranslation } from 'react-i18next';
import { REPORT_INBOUND_LEADS_ASSIGN_CUSTOMER, REPORT_ONLINE_BOOKING_ASSIGN_CUSTOMER } from 'app/const/Api';
import SearchCustomer from 'app/modules/calendar/components/sidebar/components/Search';
import { clientQuery } from 'common/utils/ApiUtils';
import { reducer } from 'app/const/Reducer';
import React, { useEffect, useReducer, useRef } from 'react';
import ButtonSave from 'app/components/button/ButtonSave';
import { Manager, Popper, Reference } from 'react-popper';
import classNames from 'classnames';

export default function GridAssignBooking({
    urlAPI,
    callBackAfterAssign,
    listSuggestion,
    rowId,
    rowIndex,
    style = 'col --menu',
    handleAssignSuccess = () => {},
    handleAssignFailed = () => {},
    isInbound = false
}) {
    const { t } = useTranslation(['common']);
    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        listSuggestion: []
    });
    const refNotificationPopup = useRef(null);
    const refButtonSave = useRef(null);
    const { isOpen } = state;
    const idRow = `assign-booking-popup-${rowId}`;

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('click', _onClickOutside, true);
            document.addEventListener('keydown', _handlePressKeyEsc, true);
        } else {
            document.removeEventListener('click', _onClickOutside, true);
            document.removeEventListener('keydown', _handlePressKeyEsc, true);
        }
        return () => {
            document.removeEventListener('click', _onClickOutside, true);
            document.removeEventListener('keydown', _handlePressKeyEsc, true);
        };
    }, [isOpen]);

    const _onClickOutside = (e) => {
        const elPrevent = document.getElementById(idRow);

        if (elPrevent && !elPrevent.contains(e.target)) {
            _handleClose();
        }
    };

    const _handlePressKeyEsc = (e) => {
        const elPrevent = document.getElementById(idRow);
        if (e.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _handleClose();
        }
    };

    const _handleClose = () => {
        dispatchState({ isOpen: false });
    };

    const _handleToggleOpen = () => {
        refButtonSave.current.removeLoading();
        dispatchState({ isOpen: !isOpen });
    };

    const _handleConfirmAssign = (data) => {
        !!data &&
            refNotificationPopup.current.open(
                { ...data, rowId: rowId },
                t('common:confirm_assign_booking', {
                    firstName: data.firstName || data.label,
                    label: isInbound ? `${t('common:lead')}` : `${t('common:job')}`
                })
            );
    };

    const _handleConfirmAssignToNewCustomer = () => {
        refNotificationPopup.current.open(
            { rowId: rowId, id: '' },
            t('common:are_you_sure_you_want_to_add_this_customer')
        );
    };

    const _handleAssign = (data) => {
        refButtonSave.current.setLoading(true);
        const params = {
            lead_id: data.rowId,
            customer_id: data.id
        };
        if (urlAPI === REPORT_INBOUND_LEADS_ASSIGN_CUSTOMER) params.lead_id = data.rowId;
        if (urlAPI === REPORT_ONLINE_BOOKING_ASSIGN_CUSTOMER) params.job_id = data.rowId;
        clientQuery(
            urlAPI,
            { method: 'POST', data: params },
            _handleAssignSuccess,
            _handleAssignFailure,
            _handleFinally
        );
    };

    const _handleAssignSuccess = (response) => {
        callBackAfterAssign({ rowIndex: rowIndex, data: response.data });
        handleAssignSuccess(response);
    };

    const _handleAssignFailure = (response) => {
        handleAssignFailed(response);
    };

    const _handleFinally = () => {
        refButtonSave.current.removeLoading();
        _handleClose();
    };

    const _renderListSuggestion = (data = []) => {
        return data.map((customer) => (
            <li
                className="items"
                key={customer.id}
                onClick={() => _handleConfirmAssign({ id: customer.id, firstName: customer.first_name })}
            >
                <div className="user-name">
                    <div className="avt fs-11">{customer.avatar}</div>
                    <span className="txt-ellipsis mt-0">{customer.full_name}</span>
                </div>
            </li>
        ));
    };

    const _renderContent = () => {
        if (isOpen) {
            return (
                <Popper
                    placement={'bottom-end'}
                    strategy="fixed"
                    modifiers={[{ name: 'offset', options: { offset: [0, 5] } }]}
                >
                    {({ ref, style, placement }) => (
                        <>
                            <div
                                className="v2-dropdown__menu content-user"
                                ref={ref}
                                style={style}
                                data-placement={placement}
                            >
                                <div className="has-form">
                                    <p className="txt">{t('common:assign_booking')}</p>
                                    <SearchCustomer
                                        classWrapper="v2-dropdown --large"
                                        selectCustomer={(data) => _handleConfirmAssign(data)}
                                    />
                                </div>
                                <ul className="scrolls">
                                    <li
                                        className="items has-icon svg-plus purple"
                                        onClick={_handleConfirmAssignToNewCustomer}
                                    >
                                        <IconPlus />
                                        <p className="txt-ellipsis">{t('common:add_as_new_customer')}</p>
                                    </li>
                                    {_renderListSuggestion(listSuggestion)}
                                </ul>
                            </div>
                        </>
                    )}
                </Popper>
            );
        }

        return null;
    };

    return (
        <Manager>
            <div className={style}>
                <Reference>
                    {({ ref }) => (
                        <>
                            <div
                                className={classNames('v2-dropdown dropdown-assign', { active: isOpen })}
                                id={idRow}
                                ref={ref}
                            >
                                <ButtonSave
                                    wrapClass="dropbtn v2-btn-default --icon-sm --transparent"
                                    ref={refButtonSave}
                                    onSave={_handleToggleOpen}
                                >
                                    <IconThreeDots />
                                </ButtonSave>
                                {_renderContent()}
                            </div>

                            <GdConfirm
                                ref={refNotificationPopup}
                                title={t('common:notification')}
                                listButton={{ confirm: true, cancel: true }}
                                onConfirm={_handleAssign}
                                onCancel={_handleClose}
                                onClose={_handleClose}
                            />
                        </>
                    )}
                </Reference>
            </div>
        </Manager>
    );
}

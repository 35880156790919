export const TYPE_REPORT_MATERIAL_USE = {
    GET_LIST: 'GET_LIST_REPORT_MATERIAL_USE',
    COUNTY: 'GET_LIST_COUNTY_MATERIAL_USE',
    GET_LIST_SERVICE_FILTER_REQUEST: 'GET_LIST_SERVICE_FILTER_REQUEST',
    GET_LIST_JOBS_WO_COUNTY: 'GET_LIST_JOBS_WO_COUNTY',
    GET_LIST_MATERIAL: 'GET_LIST_MATERIAL'
};

export const getListReportMaterialUse = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_MATERIAL_USE.GET_LIST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListCountyMaterialUseFilter = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_MATERIAL_USE.COUNTY,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListCompletedJobWoCounty = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_MATERIAL_USE.GET_LIST_JOBS_WO_COUNTY,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListMaterial = (params = {}, actionSuccess = () => {}, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_MATERIAL_USE.GET_LIST_MATERIAL,
        params,
        actionSuccess,
        actionFailed
    };
};

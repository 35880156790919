import { createPopper } from '@popperjs/core';
import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { KEY_OPTIONS_CHECK_RECURRING_JOB_CALENDAR } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import IconActiveJob from 'assets/icon/IconActiveJob';
import IconActiveJobAndRecurring from 'assets/icon/IconActiveJobAndRecurring';
import IconMinus from 'assets/icon/IconMinus';
import IconPlus from 'assets/icon/IconPlus';
import { getLocalStorageValue, setLocalStorage } from 'common/utils/LocalStorageUtils';
import {
    JOB_RECURRING_EVENT,
    JOB_RECURRING_OPTIONS,
    MAX_HEIGHT_OF_ROW,
    MIN_HEIGHT_OF_ROW,
    STEP_CHANGE_HEIGHT_ROW
} from '../const';

function CalendarControl({
    onUpdateSize = () => {},
    currentHeightProps = null,
    toolTipZoomInId = 'tooltip-zoom-in',
    toolTipZoomOutId = 'tooltip-zoom-out',
    controlZoomInId = 'calendar-control-zoom-in',
    controlZoomOutId = 'calendar-control-zoom-out',
    isShowIcon = false,
    isDisplayControl = true
}) {
    const { t } = useTranslation(['calendar']);
    const currentHeight = currentHeightProps || useSelector(({ calendar }) => calendar.heightofrow);
    const refFirstTime = useRef(true);

    const optionStorage = getLocalStorageValue(KEY_OPTIONS_CHECK_RECURRING_JOB_CALENDAR);
    const [state, dispatchState] = useReducer(reducer, {
        isMoveActiveJobOnly: false,
        isMoveActiveJobAndRecurring: false
    });
    const { isMoveActiveJobOnly, isMoveActiveJobAndRecurring } = state;

    useEffect(() => {
        addEventListener(JOB_RECURRING_EVENT.RESET, _handleReset);

        return () => {
            removeEventListener(JOB_RECURRING_EVENT.RESET, _handleReset);
        };
    }, []);

    useEffect(() => {
        const isStringOption = typeof optionStorage === 'string';
        if (!isStringOption) return;
        dispatchState({
            isMoveActiveJobOnly: optionStorage === JOB_RECURRING_OPTIONS.ACTIVE,
            isMoveActiveJobAndRecurring: optionStorage === JOB_RECURRING_OPTIONS.ACTIVE_AND_ALL_RECURRING
        });
    }, [optionStorage]);

    const _changeSizeHeight = (action) => {
        let newHeight = currentHeight;
        let shouldUpdate = true;

        switch (action) {
            case 'INCREMENT':
                newHeight = currentHeight + STEP_CHANGE_HEIGHT_ROW;
                if (newHeight > MAX_HEIGHT_OF_ROW) {
                    newHeight = MAX_HEIGHT_OF_ROW;
                    shouldUpdate = false;
                }
                break;
            case 'DECREMENT':
                newHeight = currentHeight - STEP_CHANGE_HEIGHT_ROW;
                if (newHeight < MIN_HEIGHT_OF_ROW) {
                    newHeight = MIN_HEIGHT_OF_ROW;
                    shouldUpdate = false;
                }
                break;
            default:
                break;
        }
        if (shouldUpdate) onUpdateSize(newHeight);
    };

    const _handleCreatePopper = (idPopcorn, idTooltip) => {
        const popcorn = document.getElementById(idPopcorn);
        const tooltip = document.getElementById(idTooltip);
        if (popcorn && tooltip) {
            createPopper(popcorn, tooltip, {
                placement: 'left',
                strategy: 'fixed',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 5]
                        }
                    }
                ]
            });
        }
    };

    /**
     * It gets the tooltip element, and if it exists, it gets the text element inside of it and sets its
     * visibility to visible
     * @param type - The type of tooltip to display.
     */
    const _handleMouseOverTooltip = (type) => {
        if (refFirstTime.current) {
            _handleCreatePopper(controlZoomInId, toolTipZoomInId);
            _handleCreatePopper(controlZoomOutId, toolTipZoomOutId);
            refFirstTime.current = false;
        }

        const tooltipDisplay = _handleGetTooltipEl(type);

        if (tooltipDisplay) tooltipDisplay.style.visibility = 'visible';
    };

    /**
     * It gets the tooltip element, and if it exists, it hides the text inside it
     * @param type - the type of the tooltip to be displayed.
     */
    const _handleMouseBlurTooltip = (type) => {
        const tooltipUnDisplay = _handleGetTooltipEl(type);
        if (tooltipUnDisplay) tooltipUnDisplay.style.visibility = 'hidden';
    };

    /**
     * It returns the tooltip element for the given event type
     * @param type - The type of tooltip you want to display.
     * @returns The tooltipDisplay variable is being returned.
     */
    const _handleGetTooltipEl = (type) => {
        let tooltipDisplay = null;

        switch (type) {
            case 'zoom-in':
                tooltipDisplay = document.getElementById(toolTipZoomInId);
                break;
            case 'zoom-out':
                tooltipDisplay = document.getElementById(toolTipZoomOutId);
                break;

            default:
                break;
        }

        return tooltipDisplay;
    };

    const _handleResetCheckRecurring = () => {
        setLocalStorage(KEY_OPTIONS_CHECK_RECURRING_JOB_CALENDAR, null);
        _handleReset();
    };

    const _handleReset = () => {
        dispatchState({ isMoveActiveJobOnly: false, isMoveActiveJobAndRecurring: false });
    };

    return (
        <>
            <div className="vector-control">
                {isShowIcon && isMoveActiveJobOnly && (
                    <div
                        className="action-vector btn-vector btn-recurring tooltip active"
                        onClick={_handleResetCheckRecurring}
                    >
                        <IconActiveJob />
                        <div className="tooltiptext">{t('move_this_job_only_is_active_tooltip')}</div>
                    </div>
                )}
                {isShowIcon && isMoveActiveJobAndRecurring && (
                    <div
                        className="action-vector btn-vector btn-recurring tooltip active"
                        onClick={_handleResetCheckRecurring}
                    >
                        <IconActiveJobAndRecurring />
                        <div className="tooltiptext">{t('move_this_job_and_recurring_is_active_tooltip')}</div>
                    </div>
                )}
                {isDisplayControl && (
                    <div className="action-vector action-zoom">
                        <div
                            id={controlZoomInId}
                            className="btn-vector btn-zoomin tooltip"
                            onClick={() => _changeSizeHeight('INCREMENT')}
                            onMouseOver={() => _handleMouseOverTooltip('zoom-in')}
                            onMouseLeave={() => _handleMouseBlurTooltip('zoom-in')}
                        >
                            <IconPlus />
                        </div>
                        <div
                            id={controlZoomOutId}
                            className="btn-vector btn-zoomout tooltip"
                            onClick={() => _changeSizeHeight('DECREMENT')}
                            onMouseOver={() => _handleMouseOverTooltip('zoom-out')}
                            onMouseLeave={() => _handleMouseBlurTooltip('zoom-out')}
                        >
                            <IconMinus />
                        </div>
                    </div>
                )}
            </div>
            {isDisplayControl && (
                <>
                    <p className="tooltip">
                        <span id={toolTipZoomOutId} style={{ zIndex: 99999, left: 'auto' }} className="tooltiptext">
                            {t('reduce_timeline')}
                        </span>
                    </p>
                    <p className="tooltip">
                        <span id={toolTipZoomInId} style={{ zIndex: 99999, left: 'auto' }} className="tooltiptext">
                            {t('stretch_timeline')}
                        </span>
                    </p>
                </>
            )}
        </>
    );
}

CalendarControl.propTypes = { onUpdateSize: PropTypes.func };

export default CalendarControl;

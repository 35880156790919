import React, { useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_ALL } from 'app/const/App';
import { TYPE_SELECT } from 'app/const/report/ReportFilter';
import IconLoading from 'assets/icon/IconLoading';
import SearchOption from 'app/modules/report/filter/SearchOption';
import {
    ItemDropdownCustomer,
    ItemDropdownMulti,
    ItemDropdownRating,
    ItemDropdownSingle,
    ItemDropdownSort
} from './ReportListDropdownItem';

const ReportListDropdown = ({
    listRef = null,
    fileTranslation = '',
    options = [],
    typeDropdown = '',
    isFetched = false,
    isFirstTime = false,
    keyword = '',
    isLoading = false,
    isPopper = false,
    enableSelectedAll = false,
    additionalOptions = null,
    update = () => {},
    renderSelectAllOption = () => {},
    handleChangeSearch = () => {},
    handleOnScrollContent = () => {},
    ...props
}) => {
    const { t } = useTranslation([fileTranslation]);

    useEffect(() => {
        update();
    }, [options]);

    const _renderLoading = () => {
        return (
            <div className="items justify-center">
                <div className="loading --ajaxbar">
                    <IconLoading />
                </div>
            </div>
        );
    };

    const _renderOptions = () => {
        if (isFirstTime) return _renderLoading();
        return <div className="result-empty">{t('there_is_no_data')}</div>;
    };

    const _renderContent = () => {
        switch (typeDropdown) {
            case TYPE_SELECT.MULTI: {
                if (!options.length) {
                    return _renderOptions();
                }
                return (
                    <Fragment>
                        {enableSelectedAll ? (
                            <div className="content-checked__all">{renderSelectAllOption()}</div>
                        ) : null}
                        <ul className="status-dropdown scrolls">
                            <ItemDropdownMulti fileTranslation={fileTranslation} options={options} {...props} />
                        </ul>
                    </Fragment>
                );
            }
            case TYPE_SELECT.SINGLE: {
                if (!options.length) {
                    return _renderOptions();
                }
                if (options.length === 1 && options[0].id === DEFAULT_ALL && !isFetched) return _renderLoading();
                const finalOptions = [...options];
                if (additionalOptions) {
                    finalOptions.splice(1, 0, ...additionalOptions);
                }

                return (
                    <ul>
                        <ItemDropdownSingle
                            isPopper={isPopper}
                            fileTranslation={fileTranslation}
                            options={finalOptions}
                            {...props}
                        />
                    </ul>
                );
            }
            case TYPE_SELECT.CUSTOMERS:
                return (
                    <div className="container-column">
                        <SearchOption
                            placeholder={t('header:search')}
                            onSearch={handleChangeSearch}
                            defaultValue={''}
                            style="field-search"
                            isSearchIcon
                        />
                        {keyword === '' && <div className="content-checked__all">{renderSelectAllOption()}</div>}
                        <ul ref={listRef} className="box-auto scrolls" onScroll={handleOnScrollContent}>
                            <ItemDropdownCustomer isPopper={isPopper} options={options} {...props} />
                            {isLoading && _renderLoading()}
                        </ul>
                        {options.length === 0 && !isLoading && (
                            <div className="items justify-center">
                                <div>{t('report:not_found')}</div>
                            </div>
                        )}
                    </div>
                );
            case TYPE_SELECT.MULTI_SELECT_RATING:
                return (
                    <ul>
                        <ItemDropdownRating
                            options={options}
                            renderSelectAllOption={renderSelectAllOption}
                            {...props}
                        />
                    </ul>
                );
            case TYPE_SELECT.SORT: {
                if (!options.length) {
                    return _renderOptions();
                }
                if (options.length === 1 && options[0].id === DEFAULT_ALL) return _renderLoading();

                return (
                    <ul>
                        <ItemDropdownSort
                            isPopper={isPopper}
                            options={options}
                            fileTranslation={fileTranslation}
                            {...props}
                        />
                    </ul>
                );
            }
            default:
                break;
        }
    };

    return _renderContent();
};

export default ReportListDropdown;

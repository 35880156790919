import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useReducer, useRef } from 'react';
import IconFileType from 'assets/icon/IconFileType';
import IconUpload from 'assets/icon/IconUpload';
import { reducer } from 'app/const/Reducer';
import ButtonSave from 'app/components/button/ButtonSave';
import { uploadToS3, imageType } from 'common/utils/FileUtils';
import { clientQuery } from 'common/utils/ApiUtils';
import { ATTACHMENT_PRESIGN } from 'app/const/Api';
import GdConfirm from 'app/components/confirm';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import IconCircleClose from 'assets/icon/IconCircleClose';
import Options from './Options';
import GDPreviewAttach from 'app/components/attachments/GDPreviewAttach';
import { v4 as uuidv4 } from 'uuid';
import { TYPES_PHOTO } from 'app/modules/jobdetail/const/Images';
import { actionAbilityPhotos } from 'app/const/api/Photos';
import Loading from './Loading';
import DropZone from './DropZone';
import { limitSizeAttachment } from 'app/modules/customer/utils';

const AbilityPhotos = forwardRef(
    (
        {
            parentId,
            parentType = TYPES_PHOTO.INVOICE,
            haveFetchList = true,
            onTriggerUpdate = () => {},
            isParentEdit = false,
            reload,
            isInsertImage = false
        },
        ref
    ) => {
        const [state, dispatchState] = useReducer(reducer, {
            isVisibleEdit: false,
            data: [],
            itemsDelete: [],
            isLoading: !!haveFetchList,
            updateSuccess: 0,
            triggerReload: 0
        });
        let dragTimer;
        const { t } = useTranslation(['customers']);

        const refDropWarning = useRef(null);
        const refButtonSave = useRef(null);
        const refConfirm = useRef(null);
        const refUpload = useRef(null);
        const imageRef = useRef({});
        const refPreviewAttach = useRef(null);
        const refButtonCancel = useRef(null);

        const {
            isVisibleEdit,
            data: dataImage = [],
            updateSuccess,
            itemsDelete,
            triggerReload,
            isLoading: finalIsLoading
        } = state;

        const numberImages = dataImage?.length || 0;
        const callApiAfterAction = !isVisibleEdit && !isParentEdit;

        useImperativeHandle(ref, () => ({
            setImages: (newImages) => {
                dispatchState({
                    data: newImages,
                    itemsDelete: [],
                    isLoading: false,
                    updateSuccess: 0
                });
            },
            getImages: () => {
                return {
                    add: getPramsSubmit(dataImage.filter((item) => item.isAddNew)),
                    update: getPramsSubmit(dataImage.filter((item) => item.isUpdate)),
                    delete: itemsDelete
                };
            }
        }));

        useEffect(() => {
            if (reload && haveFetchList) {
                dispatchState({
                    data: [],
                    itemsDelete: [],
                    isLoading: true,
                    updateSuccess: 0,
                    triggerReload: Date.now()
                });
            }
        }, [reload]);

        useEffect(() => {
            triggerReload && _getImagesList();
        }, [triggerReload]);

        /**
         * this action trigger update images invoice to WO and Wo to Invoice
         */
        useEffect(() => {
            if (!isParentEdit && updateSuccess && typeof onTriggerUpdate === 'function') {
                onTriggerUpdate(dataImage);
            }
        }, [updateSuccess]);

        useEffect(() => {
            // document.addEventListener('dragstart', _handleSetRef);
            document.addEventListener('dragover', _handleDragOver);
            document.addEventListener('dragleave', _handleDragEnd);
            document.addEventListener('dragend', _handleDragEnd);
            document.addEventListener('drop', _handleDragEnd);
            // document.addEventListener('click', _handleDragEnd);

            return () => {
                document.removeEventListener('dragover', _handleDragOver);
                document.removeEventListener('dragleave', _handleDragEnd);
                document.removeEventListener('dragend', _handleDragEnd);
                document.removeEventListener('drop', _handleDragEnd);
                // document.removeEventListener('dragstart', _handleSetRef);
            };
        }, []);

        const disableEdit = useMemo(() => {
            return isParentEdit ? false : dataImage.some((data) => data.idQueue) || isVisibleEdit;
        }, [dataImage]);

        const _getImagesList = () => {
            const _getSuccess = ({ data }) => {
                dispatchState({
                    data,
                    isLoading: false,
                    updateSuccess: Date.now(),
                    triggerReload: 0
                });
            };
            const _getFailed = () => {
                dispatchState({
                    isLoading: false
                });
            };
            clientQuery(actionAbilityPhotos(parentType, parentId), { method: 'GET' }, _getSuccess, _getFailed);
        };

        /**
         * This function reset data of list image
         * with case open edit and drag file
         */
        const _afterSaveSuccess = ({ data }) => {
            dispatchState((prev) => {
                return {
                    ...prev,
                    data: [...prev.data].map((item) => {
                        const itemSuccess = data.find((itemSC) => itemSC.id === item.id);

                        if (itemSuccess) {
                            return {
                                ...item,
                                ...itemSuccess,
                                isAddNew: false,
                                isUpdate: false,
                                isLoading: false,
                                idQueue: '',
                                is_insert: false
                            };
                        }
                        return item;
                    }),
                    itemsDelete: [],
                    isVisibleEdit: false,
                    updateSuccess: Date.now()
                };
            });

            refButtonCancel.current && refButtonCancel.current.classList.remove('dp-hide');
        };

        const _afterSaveFailed = ({ message }, actionFailed) => {
            refButtonCancel.current && refButtonCancel.current.classList.remove('dp-hide');
            refButtonSave.current && refButtonSave.current.removeLoading(false);
            refConfirm.current.open(null, message?.toString() || t('common:please_try_again'));
            typeof actionFailed === 'function' && actionFailed();
        };

        const _handleActionsImages = (params, actionFailed) => {
            clientQuery(actionAbilityPhotos(parentType, parentId), params, _afterSaveSuccess, (res) =>
                _afterSaveFailed(res, actionFailed)
            );
        };

        const _handleDragOver = (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (refDropWarning.current) refDropWarning.current.handleDisplay();
            window.clearTimeout(dragTimer);
        };

        const _handleDragEnd = (e) => {
            e.preventDefault();
            e.stopPropagation();
            dragTimer = window.setTimeout(() => {
                if (refDropWarning.current) refDropWarning.current.handleHidden();
            }, 25);
        };

        const _setButtonSave = (value) => {
            refButtonSave.current && refButtonSave.current.setDisable(value);
        };

        const getPramsSubmit = (arrayFiles) => {
            return arrayFiles.map((item) => ({
                id: item.id,
                object_key: item.object_key,
                object_tag: item.object_tag || '',
                mime: item.mime,
                size: item.size,
                description: item.description || '',
                name: item.name,
                is_insert: item.is_insert || false
            }));
        };

        const _uploadAttachments = (targetFiles = []) => {
            const newFiles = [];
            let checkOver = false;

            for (const itemFiles of targetFiles) {
                if (itemFiles.type && imageType.includes(itemFiles.type)) {
                    if (!limitSizeAttachment(itemFiles.size)) {
                        newFiles.push(itemFiles);
                    } else {
                        checkOver = true;
                    }
                }
            }

            const numberFiles = newFiles.length;

            if (checkOver) {
                refConfirm.current.open(null, t('setting:maximum_file_size', { size: '20', unit: 'MB' }));
                if (!numberFiles) {
                    return false;
                }
            }

            if (!numberFiles) {
                refConfirm.current.open(null, t('common:please_select_an_image_file_type_to_upload'));
                refUpload.current.value = '';
                return false;
            }

            _setButtonSave(true);

            const idQueue = Date.now();

            newFiles.forEach((file) => {
                _addAttachWithQuery(
                    {
                        id: uuidv4(),
                        file,
                        name: file.name,
                        url: 'https://cdn.gorilladesk.com/assets/images/image-broken.png',
                        mime: file.type
                    },
                    idQueue,
                    numberFiles
                );
            });

            refUpload.current.value = '';
        };

        const _addAttachWithQuery = (attach, idQueue, numberFiles) => {
            const finalSize = attach.file.size;

            const { id: idAttachment, name: nameAttachment } = attach;

            dispatchState((prev) => {
                return {
                    ...prev,
                    data: [
                        ...prev.data,
                        {
                            url: 'https://cdn.gorilladesk.com/assets/images/image-broken.png',
                            name: nameAttachment,
                            id: idAttachment,
                            isLoading: true,
                            isAddNew: true,
                            mime: attach.mime,
                            size: finalSize,
                            description: '',
                            idQueue
                        }
                    ],
                    [idQueue]: numberFiles
                };
            });

            const _addSuccess = (response) => {
                const { presigned_url, object_key, object_tag, public_url, thumbnail_url } = response.data;

                imageRef.current[idAttachment].src = attach.url;

                uploadToS3(
                    presigned_url,
                    { 'x-amz-tagging': object_tag },
                    attach.file,
                    {
                        object_key,
                        object_tag,
                        id: idAttachment,
                        public_url,
                        thumbnail_url,
                        idQueue
                    },
                    _uploadS3Success,
                    (res) => _uploadS3Failure(res, idAttachment)
                );
            };

            clientQuery(
                ATTACHMENT_PRESIGN,
                {
                    data: {
                        name: `web-${nameAttachment}`,
                        item_id: parentId,
                        type: parentType.toLowerCase()
                    },
                    method: 'POST'
                },
                _addSuccess,
                (res) => _uploadS3Failure(res, idAttachment)
            );
        };

        const _uploadS3Failure = (response, idRemove) => {
            let isDisableButtonSave = false;

            dispatchState((prev) => {
                return {
                    ...prev,
                    data: [...prev.data].filter((item) => {
                        if (item?.id !== idRemove) {
                            if (item?.isLoading) {
                                isDisableButtonSave = true;
                            }
                            return item;
                        }
                    })
                };
            });

            setTimeout(() => {
                !isDisableButtonSave && _setButtonSave(false);
            }, 0);
        };

        const _uploadS3Success = ({ object_key, object_tag, id, public_url, thumbnail_url, idQueue }) => {
            let isDisableButtonSave = false;

            let prevData = [];
            let syncSuccess = false;

            dispatchState((prev) => {
                prevData = [...prev.data];
                prevData = prevData.map((item) => {
                    if (item?.id === id) {
                        return {
                            ...item,
                            object_key,
                            object_tag,
                            isLoading: callApiAfterAction, // With case not open form edit will loading to call api upload new images success
                            isAddNew: true,
                            isInsert: false,
                            url: public_url,
                            thumbnail_url
                        };
                    } else {
                        if (item?.isLoading) {
                            //Check list have end loading yet
                            isDisableButtonSave = true;
                        }
                    }
                    return item;
                });

                const syncSuccessLastFile = prev[idQueue] - 1;
                syncSuccess = syncSuccessLastFile === 0;

                return {
                    ...prev,
                    data: prevData,
                    [idQueue]: syncSuccessLastFile
                };
            });

            setTimeout(() => {
                if (syncSuccess && callApiAfterAction) {
                    const _handleActionFailed = () => {
                        dispatchState((prev) => {
                            return {
                                ...prev,
                                data: prev.data.filter((item) => item.idQueue !== idQueue)
                            };
                        });
                    };

                    _handleActionsImages(
                        {
                            data: { add: getPramsSubmit(prevData.filter((item) => item.idQueue === idQueue)) },
                            method: 'PUT'
                        },
                        _handleActionFailed
                    );
                }

                !isDisableButtonSave && _setButtonSave(false);
            }, 0);
        };

        const _handleDrop = (e) => {
            _uploadAttachments(e);
        };

        const _handleGetFiles = (e) => {
            _uploadAttachments(e.target.files);
        };

        const _handleOpenFormEdit = () => {
            dispatchState((prev) => {
                return {
                    ...prev,
                    dataTemp: isParentEdit ? [] : [...prev.data],
                    isVisibleEdit: true
                };
            });
        };

        const _handleUpdateImage = (id, value, type) => {
            dispatchState((prev) => {
                return {
                    ...prev,
                    data: [...prev.data].map((item) => {
                        if (item.id === id) {
                            return {
                                ...item,
                                [type]: value,
                                isUpdate: isVisibleEdit ? !item.isAddNew : true
                            };
                        }
                        return item;
                    })
                };
            });

            setTimeout(() => {
                if (callApiAfterAction) {
                    const _handleActionFailed = () => {
                        dispatchState({
                            updateSuccess: 0,
                            triggerReload: Date.now()
                        });
                    };

                    _handleActionsImages(
                        { data: { update: [{ id, [type]: value }] }, method: 'PUT' },
                        _handleActionFailed
                    );
                }
            }, 0);
        };

        const _actionDelete = (e, imageId) => {
            e.stopPropagation();
            _handleDeleteImage(imageId);
        };

        const _handleDeleteImage = (imageId) => {
            let checkIsAddNew = false;
            const isDeleteAll = imageId === -1;

            if (callApiAfterAction) {
                //Call api delete image
                clientQuery(actionAbilityPhotos(parentType, parentId), {
                    data: { ids: isDeleteAll ? '' : [imageId] },
                    method: 'DELETE'
                });
            }

            if (isDeleteAll) {
                dispatchState((prev) => {
                    return {
                        ...prev,
                        data: [],
                        itemsDelete: !callApiAfterAction ? prev.data.map((item) => item.id) : [],
                        updateSuccess: Date.now()
                    };
                });
            } else {
                dispatchState((prev) => {
                    return {
                        ...prev,
                        data: [...prev.data].filter((item) => {
                            if (item?.id !== imageId) {
                                return item;
                            } else {
                                checkIsAddNew = !!item.isAddNew;
                            }
                        }),
                        itemsDelete:
                            checkIsAddNew || !isVisibleEdit ? prev.itemsDelete : [...prev.itemsDelete, imageId],
                        updateSuccess: isVisibleEdit ? prev.updateSuccess : Date.now()
                    };
                });
            }
        };

        function _handleClickItem(e, selected, openEditDescription) {
            e.stopPropagation();

            if (!selected.isLoading) {
                refPreviewAttach.current.openPreviewWithAttach(
                    dataImage.map((item) => {
                        item.type = item.mime;
                        return item;
                    }),
                    selected.url,
                    openEditDescription
                );
            }
        }

        const _handleToggleUpload = () => {
            refUpload.current.click();
        };

        const _handleCancelEdit = () => {
            if (isParentEdit) {
                dispatchState((prev) => {
                    return {
                        ...prev,
                        isVisibleEdit: false
                    };
                });
            } else {
                dispatchState((prev) => {
                    return {
                        ...prev,
                        data: prev.dataTemp,
                        itemsDelete: [],
                        isVisibleEdit: false,
                        dataTemp: []
                    };
                });
            }
        };

        const _handleEdit = () => {
            refButtonCancel.current.classList.add('dp-hide');

            const _handleActionFailed = () => {
                dispatchState({
                    updateSuccess: 0,
                    triggerReload: Date.now()
                });
            };

            _handleActionsImages(
                {
                    data: {
                        add: getPramsSubmit(dataImage.filter((item) => item.isAddNew)),
                        update: getPramsSubmit(dataImage.filter((item) => item.isUpdate)),
                        delete: itemsDelete
                    },
                    method: 'PUT'
                },
                _handleActionFailed
            );
        };

        const _handleSelectInsert = (dataImageInsert) => {
            let finalNewImages = [];

            let shouldCallApi = false;

            dispatchState((prev) => {
                shouldCallApi = !prev.isVisibleEdit && !isParentEdit;

                finalNewImages = [...dataImageInsert].map((item) => {
                    return {
                        ...item,
                        isLoading: shouldCallApi,
                        isAddNew: true,
                        is_insert: true,
                        id: uuidv4()
                    };
                });

                return {
                    ...prev,
                    data: [...prev.data, ...finalNewImages]
                };
            });

            setTimeout(() => {
                if (shouldCallApi) {
                    const _actionFailed = () => {
                        dispatchState((prev) => {
                            return {
                                ...prev,
                                data: [...prev.data].filter(
                                    (item) => !finalNewImages.some((itemFind) => item.id === itemFind.id)
                                )
                            };
                        });
                    };

                    _handleActionsImages(
                        {
                            data: { add: getPramsSubmit(finalNewImages) },
                            method: 'PUT'
                        },
                        _actionFailed
                    );
                }
            }, 0);
        };

        const _renderImages = () => {
            return dataImage.map((item) => {
                const { isLoading: itemLoading, description, id: itemId } = item;

                return (
                    <div
                        key={itemId}
                        className={classNames('attachment-wrap m-0', {
                            'is-uploading': itemLoading
                        })}
                    >
                        <div className="attachment-image-items" onClick={(e) => _handleClickItem(e, item, false)}>
                            <img
                                src={
                                    item?.thumbnail_url || 'https://cdn.gorilladesk.com/assets/images/image-broken.png'
                                }
                                width={105}
                                height={80}
                                ref={(el) => (imageRef.current[itemId] = el)}
                            />
                            <div
                                className="elm-edit-hide btn-delete svg-white-stroke"
                                onClick={(e) => {
                                    _actionDelete(e, itemId);
                                }}
                            >
                                <IconCircleClose isWhite />
                            </div>
                            {itemLoading && (
                                <div className="has-progress">
                                    <span className="has-progress__bar" />
                                </div>
                            )}
                        </div>
                        {!!description ? (
                            <div className="detail-items">
                                <div className={'detail-items__text'}>{description}</div>
                            </div>
                        ) : (
                            <div onClick={(e) => _handleClickItem(e, item, true)} className="detail-items">
                                <div className={'detail-items__text --btn'}>{t('common:add_caption')}</div>
                            </div>
                        )}
                    </div>
                );
            });
        };

        if (finalIsLoading) {
            return <Loading />;
        }

        return (
            <div className={`boxs boxs-file-upload ${isVisibleEdit ? 'has-edit' : ''}`}>
                <div className="left-icons">
                    <IconFileType type={'imageActive'} />
                </div>
                <div className="content">
                    <div className="wrap-child flexcenter">
                        <div className="h-title">
                            <h4 className="fs-13">{t('jobDetail:images')}</h4>
                            <p className="fs-10 fw-500 grey-generic">{t('jobDetail:visible_to_client')}</p>
                        </div>
                        <div className="drop-zone flex-centeritem">{t('jobDetail:drag_and_drop_image')}</div>
                        <Options
                            disableEdit={disableEdit}
                            openFormEdit={_handleOpenFormEdit}
                            onUpLoad={_handleToggleUpload}
                            onDelete={_actionDelete}
                            onSelectInsert={_handleSelectInsert}
                            isInsertImage={isInsertImage}
                            isHideDeleteAll={!numberImages}
                        />
                        <div
                            className="elm-edit-hide v2-btn-default --icon-lg btn-bg-grey"
                            onClick={_handleToggleUpload}
                        >
                            <IconUpload />
                        </div>
                    </div>

                    <div className="content__body wrap-content p-0">
                        {!!numberImages ? <div className="wrap-child attachment-image">{_renderImages()}</div> : null}
                        {isVisibleEdit && (
                            <div className="elm-edit-hide wrap-child wrap-footer">
                                <div
                                    ref={refButtonCancel}
                                    onClick={_handleCancelEdit}
                                    className="v2-btn-default --transparent mr-2"
                                >
                                    Cancel
                                </div>
                                {!isParentEdit && <ButtonSave onSave={_handleEdit} ref={refButtonSave} />}
                            </div>
                        )}
                    </div>
                </div>

                <DropZone ref={refDropWarning} onDrop={_handleDrop} />

                <input
                    onChange={_handleGetFiles}
                    type="file"
                    ref={refUpload}
                    style={{ display: 'none' }}
                    multiple
                    accept={'image/jpeg,image/gif,image/png,image/bmp'}
                />

                <GdConfirm
                    ref={refConfirm}
                    title={t('customers:confirm')}
                    listButton={{ confirm: true, cancel: false }}
                    titleConfirm={t('customers:confirm')}
                />

                <GDPreviewAttach
                    ref={refPreviewAttach}
                    hasDetail
                    preventSetSource
                    onDeleteAttachment={_handleDeleteImage}
                    noteId={''}
                    commentId={''}
                    isHaveDesciption
                    onUpdateAttachment={_handleUpdateImage}
                    isCallApiDelete={false}
                />
            </div>
        );
    }
);

export default AbilityPhotos;

import React, { useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import IconUpgrade from 'assets/icon/IconUpgrade';
import GdConfirm from 'app/components/confirm';
import { reducer } from 'app/const/Reducer';
import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';

export default function TabCreditSync({ parentData, onUpdateOption, keyword }) {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        ...parentData
    });
    const refConfirm = useRef(null);
    const finalIsActive = !!state.value;

    function _handleChangeValue() {
        const nextActive = !finalIsActive;

        if (nextActive) {
            refConfirm.current.open();
            return false;
        } else {
            dispatchState({
                value: 0
            });
            onUpdateOption(keyword, 0);
        }
    }

    function _handleConfirm() {
        dispatchState({
            value: 1
        });
        onUpdateOption(keyword, 1);
    }

    function _renderContent() {
        return (
            <>
                <p>{t('addons:addons_quick_book_content')}</p>
                <p>{t('addons:addons_quick_book_desc')}</p>
            </>
        );
    }

    return (
        <div className={`boxs boxs--power-up ${finalIsActive ? 'active' : ''}`}>
            <div className="boxs__header">
                <div className="header-title">
                    <div className="title">{t('common:credit_sync')}</div>
                    <div className="dots powerups">
                        <IconUpgrade isFillOrange />
                        {t('addons:power_up')}
                    </div>
                </div>
                <GdSwitchCheckbox
                    id="toggle_credit"
                    inputClassName="toggle toggle-round"
                    textUnChecked={t('common:disabled')}
                    textChecked={t('common:enabled')}
                    defaultChecked={finalIsActive}
                    onChangeImmediately={_handleChangeValue}
                />
            </div>
            <div className="boxs__contents">
                <p className="description">{t('addons:addons_quick_book_sub_desc')}</p>
            </div>
            <GdConfirm
                ref={refConfirm}
                title={t('addons:confirm_credit_sync')}
                renderContent={_renderContent()}
                listButton={{ confirm: true, cancel: true }}
                onConfirm={_handleConfirm}
            />
        </div>
    );
}

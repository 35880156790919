import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import IconArrow from 'assets/icon/IconArrow';
import { SetupEmailContext } from '../../context/SetupEmailContext';
import ConnectWithGG from './ConnectWithGG';
import ConnectWithMS from './ConnectWithMS';

const StepChooseSetup = ({ onSaveWithMS = () => {}, onSaveWithGG = () => {} }) => {
    const { t } = useTranslation();
    const { nextStep } = useContext(SetupEmailContext);
    return (
        <div className="step scrolls show">
            <div className="row --multi --hasline">
                <div className="col">
                    <p>{t('addons:step_choose_setup_email')}</p>

                    <ConnectWithGG onClick={onSaveWithGG} />
                    <ConnectWithMS onClick={onSaveWithMS} />
                </div>
                <div className="col">
                    <p>{t('addons:choose_setup_email_title')}</p>
                    <p className="fs-12 btn-hover-black">
                        {t('addons:we_take_the')}
                        <a href="#" className="purple-default">
                            {t('addons:security_and_privacy')}
                        </a>
                        {t('addons:of_your_data_very_seriously')}
                    </p>
                    <div className="v2-btn-default --icon-r" onClick={nextStep}>
                        {t('addons:custom_settings')}
                        <IconArrow />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepChooseSetup;

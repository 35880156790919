import classNames from 'classnames';
import React, { forwardRef, useEffect, useId, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { NICK_NAME } from 'app/const/Settings';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconLoading from 'assets/icon/IconLoading';

const CalendarDropdown = forwardRef(
    (
        {
            selected,
            selectedOption,
            disableOption = [],
            isLoading = false,
            shouldToggle = true,
            isDropUp = false,
            useMouseDownOpen = false,
            customDropIcon = null,
            customDropButton: CustomDropButton,
            selectedClassName = 'txt-ellipsis',
            buttonClassName = 'dropbtn items',
            wrapperClassName = '',
            listOptionsClass = '',
            wrapperListClass = 'v2-dropdown__menu scrolls',
            wrapperItemClass,
            spanItemClassName = 'txt-ellipsis',
            keyGetValue,
            keyGetKey,
            keyGetName,
            options = null,
            onVisible = () => {},
            onHide = () => {},
            onSelect = () => {},
            children,
            disable = false,
            disableOpen = false,
            isMouseOverHandle = false,
            isAlwayShowOptions = false,
            shouldDisplayClassDropdown = true,
            onKeydown = () => {},
            styleButton = {},
            fileTranslation = ''
        },
        ref
    ) => {
        const { t } = useTranslation();
        const id = useId();
        const [isVisible, setIsVisible] = useState(false);
        const refDropdown = useRef(null);

        useImperativeHandle(ref, () => ({ _closeDropdown, _openDropdown }));

        useEffect(() => {
            if (isVisible) {
                document.addEventListener('click', handleClickOutside, true);
                document.addEventListener('keydown', handleHideDropdown, true);
                onVisible();
            } else {
                document.removeEventListener('click', handleClickOutside, true);
                document.removeEventListener('keydown', handleHideDropdown, true);
                onHide();
            }

            return () => {
                document.removeEventListener('click', handleClickOutside, true);
                document.removeEventListener('keydown', handleHideDropdown, true);
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isVisible]);

        function handleHideDropdown(event) {
            const elPrevent = document.getElementById(id);
            onKeydown(event);
            if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
                _closeDropdown();
            }
        }

        function handleClickOutside(event) {
            const elPrevent = document.getElementById(id);

            if (
                refDropdown.current &&
                elPrevent &&
                !elPrevent.contains(event.target) &&
                !refDropdown.current.contains(event.target)
            ) {
                _closeDropdown();
            }
        }

        function _closeDropdown() {
            isVisible && setIsVisible(false);
        }

        function _openDropdown() {
            if (disableOpen) return;
            if (shouldToggle) {
                setIsVisible(!isVisible);
            } else {
                !isVisible && setIsVisible(true);
            }
        }

        function _selectOption(value) {
            onSelect(value);
            _closeDropdown();
        }

        function _handleMouseOver() {
            if (!isMouseOverHandle) return;
            setIsVisible(true);
        }

        function _handleMouseLeave() {
            if (!isMouseOverHandle) return;
            _closeDropdown();
        }

        /**
         * If the user clicks on the dropdown button, prevent the default action (which is to open the link)
         * and open the dropdown.
         * @param event - The event object that was triggered.
         */
        function _handleMouseDown(event) {
            if (useMouseDownOpen) {
                event.preventDefault();
                _openDropdown();
            }
        }

        return (
            <div
                ref={refDropdown}
                className={classNames(wrapperClassName, {
                    active: isVisible,
                    disable,
                    'v2-dropup': isDropUp && shouldDisplayClassDropdown,
                    'v2-dropdown': !isDropUp && shouldDisplayClassDropdown
                })}
                onMouseOver={_handleMouseOver}
                onMouseLeave={_handleMouseLeave}
            >
                <div
                    onClick={useMouseDownOpen ? () => {} : () => _openDropdown()}
                    onMouseDown={_handleMouseDown}
                    className={buttonClassName}
                    style={styleButton}
                    tabIndex="0"
                >
                    {CustomDropButton ? (
                        <CustomDropButton selected={selectedOption} />
                    ) : (
                        <>
                            <div className={selectedClassName}>
                                {fileTranslation ? t(`${fileTranslation}:${selected}`) : selected}
                            </div>
                            {!disableOpen ? (
                                <div className="arrow">
                                    {customDropIcon || <IconArrowDown width={16} height={16} />}
                                </div>
                            ) : null}
                        </>
                    )}
                </div>
                <div id={id} className={wrapperListClass}>
                    {isLoading && (
                        <div className="items justify-center">
                            <div className="loading -ajaxbar">
                                <IconLoading />
                            </div>
                        </div>
                    )}
                    {!isLoading && !options && (isVisible || isAlwayShowOptions) && children}
                    {!isLoading && !!options?.length && isVisible && (
                        <ListOptions
                            data={options}
                            keyGetValue={keyGetValue}
                            keyGetKey={keyGetKey}
                            keyGetName={keyGetName}
                            listOptionsClass={listOptionsClass}
                            selectedOption={selectedOption}
                            disableOption={disableOption}
                            onSelect={_selectOption}
                            wrapperItemClass={wrapperItemClass}
                            spanItemClassName={spanItemClassName}
                            fileTranslation={fileTranslation}
                        />
                    )}
                </div>
            </div>
        );
    }
);

const ListOptions = ({
    data = [],
    listOptionsClass = '',
    spanItemClassName = 'txt-ellipsis',
    keyGetValue = 'value',
    keyGetKey = 'value',
    keyGetName = 'name',
    selectedOption = null,
    disableOption = [],
    wrapperItemClass = 'items',
    fileTranslation = '',
    onSelect = () => {}
}) => {
    const { t } = useTranslation(['setting']);
    if (!data.length) return <div className="result-empty">{t('addons:there_is_no_data')}</div>;
    return (
        <ul className={listOptionsClass}>
            {data.map((item) => {
                const hasActiveJobs = !!item.active_job_counts;
                return (
                    <li
                        key={item[keyGetKey]}
                        className={classNames(
                            wrapperItemClass,
                            { active: selectedOption?.id === item.id },
                            { 'is-disable': disableOption.includes(item.id) }
                        )}
                        onClick={() => onSelect(item[keyGetValue])}
                        tabIndex="0"
                    >
                        {keyGetName === NICK_NAME ? (
                            <span className={spanItemClassName}>{`${item.nickname || item.name} (${
                                hasActiveJobs ? item.active_job_counts : ''
                            } ${hasActiveJobs ? t('setting:active_jobs') : t('setting:recurring_jobs')})`}</span>
                        ) : (
                            <span className={spanItemClassName}>
                                {fileTranslation ? t(`${fileTranslation}:${item[keyGetName]}`) : item[keyGetName]}
                            </span>
                        )}
                    </li>
                );
            })}
        </ul>
    );
};

CalendarDropdown.displayName = 'CalendarDropdown';

export default CalendarDropdown;

import React, { Fragment } from 'react';

const LoadingServicePlanDetail = () => {
    return (
        <Fragment>
            <div className="container-setting-center wrap-loading">
                <div className="header-modal">
                    <div className="loading --animation --line --onefifth"></div>
                </div>
                <div className="new-service schedule-wrapper form-tabpane-details">
                    <div className="details-job">
                        <div className="rows">
                            <div className="txt">
                                <div className="loading --animation --line --half"></div>
                            </div>
                            <div className="details">
                                <div className="v2-btn-default loading --grey w-100"></div>
                            </div>
                        </div>
                        <div className="rows row-has-line border-top-border-color-grey">
                            <div className="v2-btn-default loading --grey dropdown-select-color"></div>
                        </div>
                        <div className="rows row-has-line border-top-border-color-grey flex-column gap-8">
                            <div className="flex-betweenitems gap-8 w-100">
                                <div className="flexcenter gap-4 flex-1 rows__trigger">
                                    <div className="loading --animation --line flex-1"></div>
                                    <div className="v2-btn-default loading --grey dropdown-select-color"></div>
                                    <div className="v2-btn-default loading --grey --onefifth"></div>
                                    <div className="loading --animation --line flex-1"></div>
                                    <div className="v2-btn-default loading --grey dropdown-select-color"></div>
                                    <div className="loading --animation --line --onetenth"></div>
                                </div>
                                <div className="v2-btn-default loading bg-white-cultured --icon-lg --transparent"></div>
                            </div>
                        </div>
                        <div className="rows row-has-line border-top-border-color-grey flex-column gap-8">
                            <div className="flex-betweenitems gap-8 w-100">
                                <div className="flexcenter gap-4 flex-1 rows__trigger">
                                    <div className="loading --animation --line flex-1"></div>
                                    <div className="v2-btn-default loading --grey dropdown-select-color"></div>
                                    <div className="v2-btn-default loading --grey --onefifth"></div>
                                    <div className="loading --animation --line flex-1"></div>
                                    <div className="v2-btn-default loading --grey dropdown-select-color"></div>
                                    <div className="loading --animation --line --onetenth"></div>
                                </div>
                                <div className="v2-btn-default loading bg-white-cultured --icon-lg --transparent"></div>
                            </div>
                        </div>
                        <div className="rows row-has-line border-top-border-color-grey">
                            <div className="v2-btn-default loading --grey btn-sm">
                                <div className="loading --animation --line --full"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-setting-center mt-3 wrap-loading">
                <div className="header-modal">
                    <div className="loading --animation --line --onefifth"></div>
                </div>
                <div className="details-job">
                    <div className="summary-items">
                        <div className="loading --animation --line --half"></div>
                    </div>
                    <div className="summary-items">
                        <div className="loading --animation --line --half"></div>
                    </div>
                    <div className="summary-items">
                        <div className="loading --animation --line --half"></div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default LoadingServicePlanDetail;

import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ADDONS_DOCUMENTS_LIBRARY } from 'app/config/routes';
import { ADDONS_DOCUMENT, UPDATE_ADDONS_DOCUMENT_OPTIONS } from 'app/const/Api';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import IconArrow from 'assets/icon/IconArrow';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import AddonHeaderDetail from '../components/AddonHeaderDetail';
import AddonsBoxHeader from '../components/AddonsBoxHeader';
import AddonsOptions from '../components/AddonsOptions';
import DocumentLoading from './components/DocumentLoading';

const AddonsDocument = () => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { document: {}, isLoading: true });
    const { document, isLoading } = state;

    useEffect(() => {
        _getDocumentAddons();
    }, []);

    const _getDocumentAddons = () => {
        const _getSuccess = ({ data }) => dispatchState({ document: data, isLoading: false });
        clientQuery(ADDONS_DOCUMENT, { data: {}, method: 'GET' }, _getSuccess, checkAccessFail);
    };

    const _handleUpdateAddon = (status) => {
        dispatchState((prev) => ({ ...prev, document: { ...prev.document, status: status ? 1 : 0 } }));
    };

    return (
        <div className="addons-wrap__container">
            <AddonHeaderDetail isLoading={isLoading} tutorial={document.tutorial} />
            <div className="scrolls">
                <div className="boxs-wrapper --document">
                    {isLoading ? (
                        <DocumentLoading />
                    ) : (
                        <>
                            <div className="boxs boxs--no-border --hasline">
                                <AddonsBoxHeader
                                    keyword={document.keyword}
                                    name={document.name}
                                    status={document.status}
                                    upgrade={document.upgrade}
                                    level={document.level}
                                    urlUpdateStatus={UPDATE_ADDONS_DOCUMENT_OPTIONS}
                                    onUpdateStatusSuccess={_handleUpdateAddon}
                                />
                                <Link
                                    to={addBranchPath(ADDONS_DOCUMENTS_LIBRARY)}
                                    className="v2-btn-main --icon-r svg-white"
                                >
                                    {t('addons:my_document_library')}
                                    <IconArrow />
                                </Link>
                                <div className="boxs__contents mb-0">
                                    <p className="description">{t('addons:desc_first_addon_document')}</p>
                                    <p className="description mt-4">{t('addons:desc_second_addon_document')}</p>
                                </div>
                            </div>
                            <AddonsOptions
                                data={document.options}
                                urlUpdate={UPDATE_ADDONS_DOCUMENT_OPTIONS}
                                status={document.status}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddonsDocument;

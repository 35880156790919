import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { SetupEmailContext } from '../../context/SetupEmailContext';

const EmailChoose = () => {
    const { t } = useTranslation();
    const { email, isAutomatically, changeEmail, setAutomatically } = useContext(SetupEmailContext);
    const refInput = useRef(null);

    const _handleChange = (e) => setAutomatically(e.target.checked);

    return (
        <>
            <div className="row --email-address">
                <span className="txt">{t('addons:work_email_address')}</span>
                <div className="label-email">{email}</div>
                <div className="change-email" onClick={changeEmail}>
                    {t('common:change')}
                </div>
            </div>
            <div className="row --recommended">
                <span className="txt">{t('addons:highly_recommend')}</span>
                <div className="check-items">
                    <input
                        ref={refInput}
                        id="setup_recommend"
                        type="checkbox"
                        onChange={_handleChange}
                        checked={isAutomatically}
                    />
                    <div className="item-checkbox">
                        <label htmlFor="setup_recommend">{t('addons:setup_recommend')}</label>
                    </div>
                </div>
                <p className="fs-12 btn-hover-black">{t('addons:email_choose_desc')}</p>
            </div>
        </>
    );
};

export default EmailChoose;

import moment from 'moment-timezone';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import GdGridRowTotal from 'app/components/grid/GdGridRowTotal';
import GdGridView from 'app/components/grid/GdGridView';
import GDStatusBar from 'app/components/status/statusbar';
import { DEFAULT_ALL, KEY_REPORT_LOCAL_STORAGE, LIST_STATUS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { REPORT_TYPE } from 'app/const/Reports';
import { URL_EXPORT_WORK_LOGS } from 'app/const/api/Export';
import { REPORT_WORK_LOG } from 'app/const/api/V2';
import { LIST_EXPORT } from 'app/const/report/Common';
import { TRACKER_LIST_FILTER } from 'app/const/report/ReportFilter';
import { handleAbortController } from 'app/modules/customer/utils';
import ErrorPage from 'app/modules/error';
import TrackerTimeModal from 'app/modules/tracker/components/TrackerTimeModal';
import { clientQuery } from 'common/utils/ApiUtils';
import { getListStaffs } from 'common/utils/FunctionUtils';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { getPlanUser } from 'common/utils/PermissionUtils';
import { getLocalParamsReport } from 'common/utils/ReportUtils';
import Export from '../components/Export';
import HeaderBottom from '../components/HeaderBottom';
import MainHeaderReport from '../components/MainHeader';
import ModalDetailTracked from './ModalDetailTracked';
import { getGridTableTracker } from './constant';

const ReportTracker = () => {
    const { t } = useTranslation('report');
    const { users, crew } = useSelector(({ companyUsers }) => companyUsers);
    const companyUsers = getListStaffs({ users, crew, isHasCrews: true });
    const keyLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.WORK_LOG);
    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        rowTotal: [],
        isLoading: true,
        isFirstTimeAccess: !getLocalStorage(keyLocal)
    });
    const { data, rowTotal, isLoading, refreshScreen, isFirstTimeAccess } = state;

    const worklogAddon = useSelector(({ auth }) => auth.user.settings?.addons?.work_log);
    const { edit } = useSelector(({ auth }) => auth.user.settings?.time_tracking || {});
    const permissionTracking = edit;
    const profileData = useSelector(({ auth }) => auth.user.profile);

    const { isProPlan, isGrowthPlan, isTrial } = getPlanUser(profileData);
    const hasPermission = (isProPlan || isGrowthPlan || isTrial) && worklogAddon && permissionTracking;

    const paramsReport = getLocalParamsReport(keyLocal, REPORT_TYPE.WORK_LOG);
    const gridColumn = getGridTableTracker();
    const refModalDetail = useRef(null);
    const abortController = useRef(null);
    const refAlert = useRef(null);

    useEffect(() => {
        getListReport();

        return () => {
            handleAbortController(abortController);
        };
    }, []);

    const getListReport = () => {
        refAlert.current?.clearAllStatusBar();
        handleAbortController(abortController);
        abortController.current = new AbortController();
        const keyLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.WORK_LOG);
        const paramsReport = getLocalParamsReport(keyLocal, REPORT_TYPE.WORK_LOG);
        const newParams = { ...paramsReport };
        newParams.user_ids = isFirstTimeAccess
            ? companyUsers.map((item) => item.id).toString()
            : newParams.staffs.toString();
        delete newParams.staffs;

        if (!isLoading)
            dispatchState((prevState) => ({ ...prevState, isLoading: true, refreshScreen: new Date().getTime() }));

        const _getListSuccess = ({ data = [], total }) => {
            const totalColumns = Object.keys(gridColumn.contentConfig).map((column, index) => {
                if (index === 0) return { id: column, isShow: true, title: t('total') };
                if (total[column]) {
                    const newColumn = { id: column, isShow: true };
                    if (column === 'duration') {
                        newColumn['duration'] = total[column];
                        newColumn['isDuration'] = true;
                    }
                    if (column === 'amount') {
                        newColumn['totalAmount'] = total[column];
                        newColumn['isCurrency'] = true;
                    }
                    return newColumn;
                }
                return { id: column, isShow: true };
            });

            dispatchState((prevState) => ({
                ...prevState,
                data,
                total,
                isLoading: false,
                rowTotal: totalColumns,
                refreshScreen: new Date().getTime(),
                isFirstTimeAccess: false
            }));
        };
        const _getListFailed = ({ isAborted = false, message }) => {
            if (isAborted) return;
            refAlert.current?.handeAddStatus({ id: 'time_clocking_error', message, type: LIST_STATUS.ERROR });
            dispatchState((prev) => ({ ...prev, isLoading: false, isFirstTimeAccess: false }));
        };
        clientQuery(
            REPORT_WORK_LOG,
            { data: { ...newParams }, abortController: abortController.current },
            _getListSuccess,
            _getListFailed
        );
    };

    const _handleRowClick = (rowId) => {
        const worklog = data.find((work) => work.id === rowId);
        if (hasPermission) refModalDetail.current?.open(worklog);
    };

    const _handleAddWorklog = (data) => {
        const start = moment(paramsReport.start).startOf('day');
        const end = moment(paramsReport.end).endOf('day');
        // check in range with moment
        const isInsideRange = moment(moment.unix(data.start)).isBetween(start, end, null, '[]');

        if ((paramsReport.staffs === DEFAULT_ALL || paramsReport.staffs.includes(data.user_id)) && isInsideRange)
            dispatchState((prevState) => ({
                ...prevState,
                data: [data, ...prevState.data],
                refreshScreen: new Date().getTime()
            }));
    };

    if (!worklogAddon) return <ErrorPage errorMessage={t('auth:no_permission_access')} />;
    return (
        <>
            <MainHeaderReport
                reportType={REPORT_TYPE.WORK_LOG}
                contentRight={() => (
                    <Export
                        title={t('report:records', { count: data.length || 0 })}
                        activePrint={true}
                        isDisable={isLoading}
                        url={URL_EXPORT_WORK_LOGS}
                        params={paramsReport}
                        refresh={refreshScreen}
                        pageExport={LIST_EXPORT.EXPORT_WORK_LOG}
                    />
                )}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages">
                    <GDStatusBar ref={refAlert} />
                    <div className="wrap-tables flex-column relative">
                        <HeaderBottom
                            classNameHeader="header --filter"
                            typeReport={REPORT_TYPE.WORK_LOG}
                            filters={TRACKER_LIST_FILTER}
                            companyUsers={companyUsers}
                            isShowAvatar
                            isLoading={isLoading}
                            isShowUserDeleted
                            isFirstTimeAccess={isFirstTimeAccess}
                            handleUpdate={getListReport}
                        />
                        {hasPermission ? <TrackerTimeModal onAddWorkLog={_handleAddWorklog} /> : null}
                        <GdGridView
                            isEmptyFlat
                            content={data}
                            isLoading={isLoading}
                            fileTranslation="report"
                            classTable="tables table-multi-column table-time-tracker scrolls-x has-text-ellipsis"
                            classTableContent="--hastotal"
                            isSelectRow
                            isHasCrews
                            onRowClick={_handleRowClick}
                            {...gridColumn}
                            rowTotal={(props) => (
                                <GdGridRowTotal
                                    columns={rowTotal || []}
                                    contentConfig={gridColumn.contentConfig}
                                    showCheckBox={false}
                                    {...props}
                                />
                            )}
                        />
                    </div>
                    <ModalDetailTracked ref={refModalDetail} />
                </div>
            </div>
        </>
    );
};

export default ReportTracker;

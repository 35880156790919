import React, { useContext, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import { UnitDetailContext } from 'app/modules/jobdetail/contexts/UnitDetailContext';
import IconEmail from 'assets/icon/IconEmail';
import IconError from 'assets/icon/IconError';
import { validateEmail } from 'common/utils/EmailUtils';

export default function Email() {
    const { t } = useTranslation();
    const { unitDetailContextData, updateUnitDetailContextData } = useContext(UnitDetailContext);
    const parentUnitEmail = unitDetailContextData.email;

    const [state, dispatchState] = useReducer(reducer, {
        isWarning: false,
        value: parentUnitEmail
    });

    const { isWarning: finalIsWarning, value: finalEmail } = state;

    useEffect(() => {
        parentUnitEmail !== finalEmail && dispatchState({ value: parentUnitEmail, isWarning: false });
    }, [parentUnitEmail]);

    function _handleSubmitEmail(e) {
        const finalValue = e.target.value;
        if (finalValue.length === 0) {
            return false;
        }
        if (!validateEmail(finalValue)) {
            dispatchState({
                isWarning: true
            });
        }

        updateUnitDetailContextData({
            email: finalValue
        });
    }

    function _handleChangeValue(e) {
        dispatchState({
            value: e.target.value,
            isWarning: false
        });
    }

    return (
        <div className="rows">
            <div className="rows__label">
                <IconEmail isEmailAddress />
                <span className="flex-1 txt-ellipsis" title={t('common:email')}>{t('common:email')}</span>
            </div>
            <div className="rows__form">
                <input
                    onBlur={(e) => _handleSubmitEmail(e)}
                    onChange={(e) => _handleChangeValue(e)}
                    className="field-input"
                    type="text"
                    placeholder={t('common:email')}
                    value={finalEmail}
                />
                {finalIsWarning && (
                    <p className="txt-incorrect">
                        <IconError />
                        {t('common:incorrect_email_address')}
                    </p>
                )}
            </div>
        </div>
    );
}

import { COMMON, KEY_REPORT_LOCAL_STORAGE, LIST_STATUS, DELAY_TIME } from 'app/const/App';
import {
    getColumnsParams,
    SALES_TAX_SUMMARY_LIST_FILTER,
    SALES_TAX_SUMMARY_TABS,
    SORT_BY
} from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { getListReportSalesTax, getListReportTaxSummary } from 'common/redux/actions/reports/salesTaxSummaryAction';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { URL_REPORT_SALES_TAX, URL_EXPORT_TAX_RATE } from 'app/const/api/Export';
import { LIST_EXPORT } from 'app/const/report/Common';
import { reducer } from 'app/const/Reducer';
import loadable from '@loadable/component';
import { removeObjParams, getLocalParamsReport } from 'common/utils/ReportUtils';
import HeaderBottom from '../components/HeaderBottom';

const StatusBar = loadable(() => import('app/components/status/statusbar'));
const Export = loadable(() => import('app/modules/report/components/Export'));
const MainHeaderReport = loadable(() => import('app/modules/report/components/MainHeader'));
const ReportSearch = loadable(() => import('app/modules/report/components/ReportSearch'));
const ReportTabs = loadable(() => import('app/modules/report/components/ReportTabs'));
const SalesTaxTabGridView = loadable(() => import('app/modules/report/salestaxsummary/components/SalesTaxTab'));
const TaxSummaryGridView = loadable(() => import('app/modules/report/salestaxsummary/components/TaxSummaryTab'));

const SALES_TAX_TAB = REPORT_TYPE.SALES_TAX_SUMMARY_SALES_TAX_TAB;
const TAX_SUMMARY_TAB = REPORT_TYPE.SALES_TAX_SUMMARY_TAX_SUMMARY_TAB;

const keyTotalRow = {
    [SALES_TAX_TAB]: 'total_sales',
    [TAX_SUMMARY_TAB]: 'total_tax_summary'
};

function ReportSalesTaxSummary() {
    const { t } = useTranslation(['report']);
    const dispatch = useDispatch();
    const refStatusBar = useRef(null);
    const keyCurrentTab = COMMON.CURRENT_TAB.concat('_', REPORT_TYPE.SALES_TAX_SUMMARY);
    const [dataReport, dispatchActionReport] = useReducer(reducer, {
        isFirstTime: true,
        [SALES_TAX_TAB]: {
            data: [],
            total: 0,
            rowTotal: [],
            isLoading: true
        },
        [TAX_SUMMARY_TAB]: {
            data: [],
            total: 0,
            rowTotal: [],
            isLoading: true
        },
        refreshScreen: 0,
        currentTab: getLocalStorage(keyCurrentTab) || SALES_TAX_TAB
    });

    const currentTab = dataReport.currentTab;
    const refreshScreen = dataReport.refreshScreen;
    const totalItemsExport = dataReport[currentTab]?.total || 0;
    let timer;
    const keyLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.SALES_TAX_SUMMARY);
    const paramsReport = getLocalParamsReport(keyLocal, REPORT_TYPE.SALES_TAX_SUMMARY);

    useEffect(() => {
        getListReport(paramsReport);
    }, []);

    useEffect(() => {
        timer && clearTimeout(timer);
        timer = setTimeout(() => {
            if (!dataReport.isFirstTime) {
                getListReport(paramsReport);
                setLocalStorage(keyCurrentTab, currentTab);
            }
        }, DELAY_TIME);
        return () => {
            removeLocalStorage(keyCurrentTab);
            clearTimeout(timer);
        };
    }, [currentTab]);

    const getListReport = (params) => {
        let newParams = { ...params };
        newParams.payment_methods = params.payment_methods.toString();
        delete newParams?.currentPage;

        switch (currentTab) {
            case SALES_TAX_TAB:
                newParams.order = newParams.orderSaleable;
                newParams = removeObjParams(newParams, ['orderSaleable', 'orderTaxable', 'sort_by']);
                dispatch(
                    getListReportSalesTax(newParams, (response) => getListSuccess(response, params), getListFailed)
                );
                break;
            case TAX_SUMMARY_TAB:
                newParams.order = newParams.orderTaxable;
                newParams = removeObjParams(newParams, ['orderSaleable', 'orderTaxable']);

                dispatch(
                    getListReportTaxSummary(newParams, (response) => getListSuccess(response, params), getListFailed)
                );
                break;
            default:
                break;
        }
    };

    const getListSuccess = (response) => {
        const dataReducer = {
            isFirstTime: false,
            [currentTab]: {
                isLoading: false,
                data: response.data,
                total: response.data.length,
                rowTotal: createRowTotal(response[keyTotalRow[currentTab]], response.data.length)
            },
            refreshScreen: refreshScreen + 1
        };
        dispatchActionReport(dataReducer);
    };

    const createRowTotal = (data, total) => {
        const columns = getColumnsParams(currentTab);
        const totalColumns = columns.map((column) => {
            if (typeof data?.[column] !== 'undefined') {
                return {
                    id: column,
                    isShow: true,
                    isCurrency: true,
                    totalAmount: data[column]
                };
            }
            return { id: column, isShow: true };
        });
        totalColumns[0].title = `${t('report:total')} ${total ?? totalItemsExport} ${t('report:sales_tax_summary')}`;
        return totalColumns;
    };

    function getListFailed() {
        refStatusBar.current.showStatusBar(LIST_STATUS.ERROR, t('common:action_failure'), LIST_STATUS.ERROR);
        dispatchActionReport({ isFirstTime: false, [currentTab]: { isLoading: false } });
    }

    const _handleUpdate = () => {
        const params = getLocalStorage(keyLocal);
        dispatchActionReport({
            [currentTab]: {
                data: [],
                total: 0,
                rowTotal: [],
                isLoading: true
            }
        });
        getListReport(params);
    };

    const _handleChangeFilter = (params, type) => {
        switch (type) {
            case SORT_BY:
                _handleUpdate();
                break;
            default:
                break;
        }
    };

    function _renderHeaderLeft() {
        return (
            <ReportTabs
                list={SALES_TAX_SUMMARY_TABS}
                onChange={handleChangeTab}
                tabActive={currentTab}
                classTabList="tab-selectors btn-item ml-0"
            />
        );
    }

    function _renderHeaderRight() {
        return (
            <>
                <Export
                    title={t('report:records', { count: totalItemsExport })}
                    activePrint
                    params={_getParamsExport()}
                    url={_getUrlExport()}
                    pageExport={_getPageExport()}
                    isDisable={dataReport[currentTab]?.isLoading}
                    refresh={refreshScreen}
                />
                <ReportSearch
                    reportType={REPORT_TYPE.SALES_TAX_SUMMARY}
                    placeholder={t('report:search')}
                    onKeyEnter={_handleUpdate}
                />
            </>
        );
    }

    function _getParamsExport() {
        let paramsExport = { ...paramsReport };
        switch (currentTab) {
            case SALES_TAX_TAB:
                paramsExport.order = paramsExport.orderSaleable;
                paramsExport = removeObjParams(paramsExport, ['orderSaleable', 'orderTaxable', 'sort_by']);
                return paramsExport;

            case TAX_SUMMARY_TAB:
                paramsExport.order = paramsExport.orderTaxable;
                paramsExport = removeObjParams(paramsExport, ['orderSaleable', 'orderTaxable']);
                return paramsExport;

            default:
                return null;
        }
    }

    function _getUrlExport() {
        switch (currentTab) {
            case SALES_TAX_TAB:
                return URL_REPORT_SALES_TAX;

            case TAX_SUMMARY_TAB:
                return URL_EXPORT_TAX_RATE;

            default:
                return null;
        }
    }

    function _getPageExport() {
        switch (currentTab) {
            case SALES_TAX_TAB:
                return LIST_EXPORT.EXPORT_REPORT_SALES_TAXES;

            case TAX_SUMMARY_TAB:
                return LIST_EXPORT.EXPORT_REPORT_TAXES_RATE;

            default:
                return null;
        }
    }

    const handleChangeTab = (tab) => {
        dispatchActionReport({ currentTab: tab, [tab]: { isLoading: true } });
    };

    function _renderHeaderBottom() {
        return (
            <HeaderBottom
                typeReport={REPORT_TYPE.SALES_TAX_SUMMARY}
                filters={currentTab === TAX_SUMMARY_TAB ? SALES_TAX_SUMMARY_LIST_FILTER.ROW_2 : []}
                currentTab={currentTab}
                params={paramsReport}
                isLoading={dataReport[currentTab]?.isLoading}
                handleUpdate={_handleUpdate}
                handleChangeFilter={_handleChangeFilter}
            />
        );
    }

    const _handleOpenInvoice = (data) => {
        dispatch(
            actionOpenInvoice({
                id: data?.row?.invoice_id,
                status: '',
                total: '',
                isRecurring: false
            })
        );
    };

    const _renderGirdView = () => {
        switch (currentTab) {
            case SALES_TAX_TAB:
                return (
                    <SalesTaxTabGridView
                        dataReport={dataReport[SALES_TAX_TAB]}
                        paramsReport={paramsReport}
                        onChange={_handleUpdate}
                    />
                );
            case TAX_SUMMARY_TAB:
                return (
                    <TaxSummaryGridView
                        dataReport={dataReport[TAX_SUMMARY_TAB]}
                        paramsReport={paramsReport}
                        onChange={_handleUpdate}
                        handleOpenInvoice={_handleOpenInvoice}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            <MainHeaderReport
                contentLeft={_renderHeaderLeft}
                contentRight={_renderHeaderRight}
                reportType={REPORT_TYPE.SALES_TAX_SUMMARY}
                onSelectTab={_handleUpdate}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages has-tab gap-8">
                    <StatusBar ref={refStatusBar} />
                    {_renderHeaderBottom()}
                    <div className="wrap-tables tab-contents scrolls-x">{_renderGirdView()}</div>
                </div>
            </div>
        </>
    );
}

export default ReportSalesTaxSummary;

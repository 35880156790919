import { useTranslation } from 'react-i18next';

import { CONVERSION_RATE, COUNT, WEIGHTED_VALUE, WON, WON_LOST } from 'app/const/opportunity';
import { LIST_STATUS_PIPELINES } from 'app/const/setting/SettingPipelines';
import { getDarkModeValue } from 'common/utils/FunctionUtils';
import { capitalizeEachWord } from 'common/utils/StringUtils';
import { formatSecondToDay } from 'common/utils/TimeUtils';

export const configSalesFunnel = ({
    data: finalData,
    selectedTab,
    style = {},
    convertValueToCurrency = () => {},
    toggleTooltip = () => {}
}) => {
    const { t } = useTranslation();

    const finalListValue = [];
    const finalListColor = [];
    finalData.forEach((data) => {
        finalListValue.push(data[selectedTab]);
        finalListColor.push(LIST_STATUS_PIPELINES[1].id === data.status ? '#1eaa5c' : '#355DFB');
    });
    const { width: widthFunnelChart, height: heightFunnelChart } = style;
    const themeFillColor = getDarkModeValue() ? '#838383' : '#000';

    const drawTitle = {
        id: 'drawTitle',
        afterDatasetDraw: function (chart) {
            const { ctx, data } = chart;
            ctx.save();
            let isShowWinRate = true;
            const isSelectedCount = selectedTab === COUNT;

            chart.getDatasetMeta(0).data.forEach((element, index) => {
                const { x, y, height } = chart.getDatasetMeta(0).data[index] || {};
                const { x: nextX, y: nextY } = chart.getDatasetMeta(0).data[index + 1] || {};
                const checkIsActiveStatus = finalData[index]['status'] === 1;
                const getFont = (fontSize, fontStyle) => `${fontStyle || '400'} ${fontSize || 10}px Inter`;

                const heightTitle = 20;
                const title = isSelectedCount ? data.labels[index] : convertValueToCurrency(data.labels[index]);
                const widthTitle = ctx.measureText(title).width + 15;
                const xPos = x - widthTitle / 2;
                const yPos = y - heightTitle / 2;
                ctx.fillStyle = '#fff';
                ctx.strokeStyle = '#000';
                ctx.beginPath();
                ctx.lineWidth = 1;
                ctx.roundRect(xPos, yPos, widthTitle, heightTitle, 10);
                ctx.stroke();
                ctx.closePath();
                ctx.fill();

                ctx.beginPath();
                ctx.font = getFont(10, 500);
                ctx.fillStyle = '#000';
                ctx.textAlign = 'center';
                ctx.fillText(title, x, y + 4);
                ctx.fill();
                ctx.closePath();

                const fontSize = 14;
                const paddingX = 20;
                const paddingY = 5;
                const padding = 5;
                const borderRadius = 5;
                const borderRadiusPercent = 10;

                const headerBackgroundColor = checkIsActiveStatus ? '#ffbd2e' : '#1eaa5c';

                const text = finalData[index]['name'];
                const textWidth = ctx.measureText(text).width;
                const textHeight = fontSize;
                const width = 130;

                let truncatedText = text;
                if (truncatedText.length > 14) {
                    truncatedText = truncatedText.slice(0, 14) + '...';
                }

                const backgroundWidth = Math.min(textWidth, width);
                ctx.fillStyle = headerBackgroundColor;
                ctx.beginPath();
                ctx.moveTo(x - backgroundWidth / 2 - paddingX, 27 - paddingY);
                ctx.arcTo(
                    x + backgroundWidth / 2 + paddingX,
                    27 - paddingY,
                    x + backgroundWidth / 2 + paddingX,
                    27 + textHeight + paddingY,
                    borderRadius
                );
                ctx.arcTo(
                    x + backgroundWidth / 2 + paddingX,
                    27 + textHeight + paddingY,
                    x - backgroundWidth / 2 - paddingX,
                    27 + textHeight + paddingY,
                    borderRadius
                );
                ctx.arcTo(
                    x - backgroundWidth / 2 - paddingX,
                    27 + textHeight + paddingY,
                    x - backgroundWidth / 2 - paddingX,
                    27 - paddingY,
                    borderRadius
                );
                ctx.arcTo(
                    x - backgroundWidth / 2 - paddingX,
                    27 - paddingY,
                    x + backgroundWidth / 2 + paddingX,
                    27 - paddingY,
                    borderRadius
                );
                ctx.closePath();
                ctx.fill();

                ctx.font = getFont(13, 500);
                ctx.fillStyle = checkIsActiveStatus ? '#000' : '#fff';
                ctx.fillText(capitalizeEachWord(truncatedText), x, 38);

                const avgTime = formatSecondToDay(finalData[index]['avg_time']);
                const avgTimeWidth = ctx.measureText(avgTime).width;
                if (!!avgTime) {
                    ctx.font = getFont(11);
                    ctx.fillStyle = '#4c4c4c';
                    ctx.fillText(avgTime, x, 67);
                }

                if (checkIsActiveStatus) {
                    const chartHeight = chart.chartArea.bottom - chart.chartArea.top;

                    const circleRadius = 15;

                    ctx.beginPath();
                    ctx.arc(x, chartHeight + 123, circleRadius, 0, 2 * Math.PI);
                    ctx.strokeStyle = '#e2e2e2';
                    ctx.fillStyle = '#e2e2e2';
                    ctx.lineWidth = 1;
                    ctx.fill();
                    ctx.stroke();
                    ctx.closePath();

                    const svgString =
                        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="red" d="M8 15a7 7 0 0 0 7-7 7 7 0 0 0-7-7 7 7 0 0 0-7 7 7 7 0 0 0 7 7ZM4.533 8.27A.438.438 0 0 1 4.937 8h1.75V5.375c0-.483.392-.875.875-.875h.875c.484 0 .876.392.876.875V8h1.75a.437.437 0 0 1 .309.747l-3.063 3.062a.437.437 0 0 1-.618 0L4.628 8.747a.435.435 0 0 1-.095-.476Z"></path></svg>';

                    const img = new Image();
                    img.src = 'data:image/svg+xml;base64,' + btoa(svgString);
                    img.onload = function () {
                        ctx.drawImage(img, x - 8, chartHeight + 115, img.width, img.height);
                    };

                    ctx.font = getFont(13);
                    ctx.fillStyle = themeFillColor;
                    ctx.fillText(
                        `${
                            isSelectedCount
                                ? finalData[index].lost.count
                                : convertValueToCurrency(finalData[index][`${selectedTab}_lost`]?.[selectedTab])
                        } ${t('inbox:lost').toUpperCase()}`,
                        x,
                        chartHeight + 155
                    );

                    const textPercent = `${
                        finalData[index][isSelectedCount ? 'lost' : `${selectedTab}_lost`]?.['percent'] || 0
                    }%`;
                    const percentWidth = ctx.measureText(textPercent).width;
                    const percentHeight = fontSize;
                    ctx.font = getFont(12);
                    ctx.fillStyle = '#000';

                    const backgroundPercentWidth = Math.min(percentWidth, width);

                    ctx.fillStyle = '#e4e4e4';
                    ctx.beginPath();
                    ctx.moveTo(x - backgroundPercentWidth / 2 - padding, chartHeight + 167 - padding);
                    ctx.arcTo(
                        x + backgroundPercentWidth / 2 + padding,
                        chartHeight + 167 - padding,
                        x + backgroundPercentWidth / 2 + padding,
                        chartHeight + 167 + percentHeight + padding,
                        borderRadiusPercent
                    );
                    ctx.arcTo(
                        x + backgroundPercentWidth / 2 + padding,
                        chartHeight + 167 + percentHeight + padding,
                        x - backgroundPercentWidth / 2 - padding,
                        chartHeight + 167 + percentHeight + padding,
                        borderRadiusPercent
                    );
                    ctx.arcTo(
                        x - backgroundPercentWidth / 2 - padding,
                        chartHeight + 167 + percentHeight + padding,
                        x - backgroundPercentWidth / 2 - padding,
                        chartHeight + 167 - padding,
                        borderRadiusPercent
                    );
                    ctx.arcTo(
                        x - backgroundPercentWidth / 2 - padding,
                        chartHeight + 167 - padding,
                        x + backgroundPercentWidth / 2 + padding,
                        chartHeight + 167 - padding,
                        borderRadiusPercent
                    );
                    ctx.closePath();
                    ctx.fill();

                    ctx.fillStyle = 'rgba(28,31,34,.7)';
                    ctx.fillText(textPercent, x + 1, chartHeight + 179);
                } else if (isShowWinRate) {
                    const chartHeight = chart.chartArea.bottom - chart.chartArea.top;

                    const circleRadius = 16;

                    ctx.beginPath();
                    ctx.arc(x, chartHeight + 123, circleRadius, 0, 2 * Math.PI);
                    ctx.strokeStyle = '#8d8';
                    ctx.fillStyle = '#8d8';
                    ctx.lineWidth = 1;
                    ctx.fill();
                    ctx.stroke();
                    ctx.closePath();

                    const svgString =
                        '<svg xmlns="http://www.w3.org/2000/svg" fill="none" width="18" height="18" viewBox="0 0 16 16"><path fill="currentColor" d="M15.643 3.253a.658.658 0 0 0-.64-.503h-2.756c.007-.34.003-.648-.004-.903A.868.868 0 0 0 11.37 1H4.38a.87.87 0 0 0-.875.847c-.03.255-.01.563-.002.903H.745a.657.657 0 0 0-.638.503c-.022.093-.537 2.319.907 4.47 1.023 1.527 2.75 2.6 5.127 3.211.51.131 1.077.608 1.077 1.136v1.617H5.032c-.362 0-.631.294-.631.657 0 .362.27.656.631.656h5.663c.362 0 .632-.294.632-.656a.651.651 0 0 0-.632-.656H8.531v-1.62c.001-.528.567-1.003 1.078-1.135 2.378-.61 4.104-1.683 5.127-3.21 1.443-2.151.93-4.377.907-4.47ZM2.117 7.01c-.764-1.126-.832-2.3-.794-2.947H3.52c.147 1.63.556 3.584 1.577 5.17-1.34-.533-2.338-1.277-2.98-2.223Zm5.758 3.615c-1.523-1.704-3.07-3.311-3.063-8.313h6.1c.034 4.999-1.514 6.607-3.037 8.313Zm5.759-3.615c-.641.946-1.64 1.689-2.98 2.221 1.019-1.584 1.43-3.539 1.574-5.168h2.198c.014.648-.03 1.823-.792 2.947Z"></path></svg>';

                    const img = new Image();
                    img.src = 'data:image/svg+xml;base64,' + btoa(svgString);
                    img.onload = function () {
                        ctx.drawImage(img, x - 9, chartHeight + 114, img.width, img.height);
                    };

                    ctx.font = getFont(14, 600);
                    ctx.fillStyle = themeFillColor;
                    ctx.fillText(
                        `${
                            selectedTab === WEIGHTED_VALUE
                                ? '-'
                                : `${
                                      finalData[index].win_rate?.[
                                          isSelectedCount ? 'percent' : `percent_${selectedTab}`
                                      ] || 0
                                  }%`
                        }`,
                        x,
                        chartHeight + 160
                    );

                    ctx.font = getFont(14);
                    ctx.fillStyle = themeFillColor;
                    ctx.fillText(t('inbox:win_rate'), x, chartHeight + 180);

                    isShowWinRate = false;
                }
                const tagW = 12;
                const tagZero = y + height - tagW;
                const tagX = nextX - (nextX - x) / 2 - tagW * 1.4;
                const tagY = tagZero - Math.abs(nextY - y) / 2;

                if (!!nextY) {
                    ctx.font = getFont(10, 500);
                    ctx.strokeStyle = '#464a55';
                    ctx.fillStyle = '#6b6e77';

                    ctx.beginPath();
                    ctx.moveTo(tagX, tagY);
                    ctx.lineTo(tagX + tagW * 2.5, tagY);
                    ctx.lineTo(tagX + tagW * 2.5 + tagW, tagY + tagW);
                    ctx.lineTo(tagX + tagW * 2.5, tagY + tagW * 2);
                    ctx.lineTo(tagX, tagY + tagW * 2);
                    ctx.lineTo(tagX, tagY + tagW);
                    ctx.lineTo(tagX, tagY);

                    ctx.lineWidth = 2;

                    ctx.stroke();
                    ctx.fill();

                    ctx.lineWidth = 1;

                    ctx.fillStyle = '#fff';
                    ctx.font = getFont(10, 500);
                    ctx.fillText(
                        `${finalData[index][isSelectedCount ? CONVERSION_RATE : `${selectedTab}_${CONVERSION_RATE}`]}%`,
                        tagX + tagW * 1.4 + 2,
                        tagY + tagW * 1.4 - 1
                    );
                    ctx.closePath();
                }

                let tooltipVisible = false;

                const clearTooltip = () => {
                    if (tooltipVisible) {
                        toggleTooltip({ display: 'none' });
                        tooltipVisible = false;
                    }
                };

                chart.canvas.addEventListener('mousemove', (event) => {
                    const offsetCanvas = chart.canvas?.getBoundingClientRect() || {};
                    const { left: offsetCanvasLeft, top: offsetCanvasTop } = offsetCanvas;
                    const mouseX = event.clientX - offsetCanvas?.left;
                    const mouseY = event.clientY - offsetCanvas?.top;

                    if (
                        mouseX >= tagX &&
                        mouseX <= tagX + tagW * 2.5 + tagW &&
                        mouseY >= tagY &&
                        mouseY <= tagY + tagW * 2
                    ) {
                        if (!tooltipVisible) {
                            tooltipVisible = true;
                            const renderConversionRate = isSelectedCount
                                ? `${finalData[index]['next_pipeline'].move} moved`
                                : `${convertValueToCurrency(finalData[index]['next_pipeline'][selectedTab])} converted`;
                            toggleTooltip({
                                display: 'block',
                                left: `${tagX + offsetCanvasLeft + 10}px`,
                                top: `${tagY + offsetCanvasTop + 30}px`,
                                content: `
                                <div style="text-align: center;max-width:250px;min-width:150px;font-size: 12px;">
                                    <p>${t('inbox:conversion_rate')}</p>
                                    <br />
                                    <p>${renderConversionRate}</p>
                                    <i>${t('inbox:directly')}</i>
                                     <div>${t('setting:from_lower')} "${finalData[index]['name'] || ''}" ${t('setting:to_lower')} "${
                                         finalData[index]['next_pipeline'].name || finalData[index + 1]['name'] || ''
                                     }"</div>
                                </div>
                            `
                            });
                        }
                    } else if (
                        mouseX >= x - 35 &&
                        mouseX <= x + 35 &&
                        mouseY >= chart.chartArea.bottom - chart.chartArea.top + 105 &&
                        mouseY <= chart.chartArea.bottom - chart.chartArea.top + 195 &&
                        finalData[index]['status'] === 2 &&
                        finalData[index].win_rate
                    ) {
                        if (!tooltipVisible) {
                            tooltipVisible = true;
                            toggleTooltip({
                                display: 'block',
                                left: `${x + offsetCanvasLeft}px`,
                                top: `${chart.chartArea.bottom - chart.chartArea.top + offsetCanvasTop + 40}px`,
                                content: `
                                <div style="text-align: center;font-size: 12px;">
                                    <div>${
                                        isSelectedCount
                                            ? finalData[index]['win_rate'][WON]
                                            : convertValueToCurrency(
                                                  finalData[index]['win_rate'][`${selectedTab}_${WON}`]
                                              )
                                    } (${t('inbox:won')}) / ${
                                        isSelectedCount
                                            ? finalData[index]['win_rate'][WON_LOST]
                                            : convertValueToCurrency(
                                                  finalData[index]['win_rate'][`${selectedTab}_${WON_LOST}`]
                                              )
                                    } (${t('inbox:won_or_lost')})</div>
                                    <div style="text-align: center;">${t('inbox:win_rate_calculated_dividing')}</div>
                                    <div style="text-align: center;">${t('inbox:the_no_opportunities_won')}</div>
                                </div>
                            `
                            });
                        }
                    } else if (mouseX >= x - textWidth / 2 && mouseX <= x + textWidth / 2 && mouseY <= 47) {
                        if (!tooltipVisible) {
                            tooltipVisible = true;

                            toggleTooltip({
                                display: 'block',
                                left: `${x + offsetCanvasLeft}px`,
                                top: `${50 + offsetCanvasTop}px`,
                                content: `
                                <div style="text-align: center;width: fit-content;text-transform: capitalize;font-size:12px;">
                                    ${finalData[index]['name']}
                                </div>
                            `
                            });
                        }
                    } else if (
                        mouseX >= x - avgTimeWidth &&
                        mouseX <= x + avgTimeWidth &&
                        mouseY >= 60 &&
                        mouseY <= 70
                    ) {
                        if (!tooltipVisible && !!avgTime) {
                            tooltipVisible = true;

                            toggleTooltip({
                                display: 'block',
                                left: `${x + offsetCanvasLeft}px`,
                                top: `${80 + offsetCanvasTop}px`,
                                content: `<div style="width: 200px;border-radius: 5px;font-size: 12px;">
                                    <div style="text-align:center;font-weight:500;">${t('inbox:avg_time_to_dvanced')}</div>
                                    <br />
                                    <div style="text-align:center;">${t('inbox:avg_time_an_opportunit_desc')}</div>
                                </div>`
                            });
                        }
                    } else clearTooltip();
                });

                chart.canvas.addEventListener('mouseout', clearTooltip);
            });

            ctx.restore();
        }
    };
    return {
        data: {
            labels: finalListValue,
            datasets: [
                {
                    data: finalListValue,
                    borderWidth: 1,
                    backgroundColor: finalListColor
                }
            ]
        },
        options: {
            scales: {
                x: {
                    ticks: { display: false },
                    grid: { color: '#838383' },
                    border: { color: '#838383' }
                },
                y: {
                    beginAtZero: true,
                    grid: { display: false },
                    ticks: { display: false }
                }
            },
            layout: {
                padding: {
                    top: 90,
                    bottom: 100
                }
            },
            plugins: {
                legend: { display: false },
                tooltip: { enabled: false }
            },
            events: null,
            aspectRatio: widthFunnelChart / heightFunnelChart
        },
        plugins: [drawTitle]
    };
};

import classNames from 'classnames';
import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import GdTags from 'app/components/tags';
import { TYPE_TAGS } from 'app/const/Customers';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import { updateTagsLocation } from 'app/const/api/V2';
import IconPlus from 'assets/icon/IconPlus';
import { clientQuery } from 'common/utils/ApiUtils';
import TagsDisplay from '../../accounts/components/TagsDisplay';
import { CustomerDetailContext } from '../../context/CustomerDetailContext';
import { _handleConvertTags } from '../../context/utils';

const LocationTags = ({
    id = 'show_customer_tags',
    idLocation = '',
    idDropdown = 'list_tags_sidebar',
    isDisable = false,
    isEditAccount = false,
    classTitle = 'title flexcenter fs-14 fw-500',
    classWrapper = 'v2-dropdown tags list-add-tags',
    classRowTagsDisplay = 'wrap-existing-tag flexcenter flex-wrap',
    classWrapperDropdown = 'box-tags flex-1',
    classLabel = 'tags__label flex-betweenitems'
}) => {
    const { t } = useTranslation(['customers']);
    const { locations, _handleUpdateLocationTags } = useContext(CustomerDetailContext);
    const [state, dispatchState] = useReducer(reducer, { isEdit: false });
    const refTags = useRef(null);
    const isEdit = state.isEdit;
    const finalSelected = locations.find((item) => item.id === idLocation) || {};
    const tags = _handleConvertTags(finalSelected?.tags || []);

    useEffect(() => {
        if (isEdit) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleKeydown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleKeydown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleKeydown, true);
        };
    }, [isEdit]);

    const handleKeydown = (event) => {
        const elPrevent = document.getElementById(id);
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            handleHideDropdown();
        }
    };

    const _handleOpen = () => {
        dispatchState({ isEdit: true });
    };

    const handleHideDropdown = () => {
        isEdit && dispatchState({ isEdit: false });
    };

    const handleClickOutside = (event) => {
        const elPrevent = document.getElementById(id);
        if (elPrevent && !elPrevent.contains(event.target)) {
            handleHideDropdown();
            _handleSaveTags();
        }
    };

    const _handleSaveTags = () => {
        const tags = refTags.current?.getValue().map((item) => item.id);
        _handleUpdateLocationTags({ tags, id: idLocation });
        clientQuery(updateTagsLocation(idLocation), { data: { tags }, toFormData: false, method: 'PUT' });
    };

    const _renderListTags = () => {
        return isEdit ? (
            <div id={id} className={classNames({ 'rows__info flex-1': isEditAccount })}>
                <GdTags
                    ref={refTags}
                    id={`${idDropdown}_${idLocation}`}
                    type={TYPE_TAGS.TAG_CUSTOMER}
                    defaultTags={tags.map((item) => ({ id: item, name: item }))}
                    isTagsSideMenu={!isEditAccount}
                    isTagsAccount={isEditAccount}
                    classWrapper={classWrapperDropdown}
                    autoFocus
                />
            </div>
        ) : (
            <TagsDisplay
                data={tags}
                isTagsSideMenu
                isEditAccount={isEditAccount}
                classRow={classRowTagsDisplay}
                classWrapper="rows__info flex-1"
                btnAdd={isEditAccount ? _renderBtnAdd : () => {}}
            />
        );
    };

    const _renderBtnAdd = () => {
        return (
            <span
                className={classNames('v2-btn-default --icon-sm btn-edit-tag', { 'is-disable': isDisable })}
                onClick={_handleOpen}
            >
                <IconPlus />
            </span>
        );
    };

    return (
        <div className={classWrapper}>
            <div className={classLabel}>
                <div className={classTitle}>
                    {t('smartView:location_tags')}
                    {!isEdit && _renderBtnAdd()}
                </div>
                {isEdit && (
                    <span onClick={handleHideDropdown} className="btn-close fs-13 cursor-pointer">
                        {t('customers:close')}
                    </span>
                )}
            </div>
            {_renderListTags()}
        </div>
    );
};

export default LocationTags;

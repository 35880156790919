import React from 'react';
import { useTranslation } from 'react-i18next';

import IconArrow from 'assets/icon/IconArrow';
import { useFilters } from '../../context/addFilterContext';

const Category = ({ category }) => {
    const { t } = useTranslation('smartView');
    const { handleCategoryId } = useFilters();
    const _handleClick = () => {
        handleCategoryId(category.id);
    };

    return (
        <li className="rows" onClick={_handleClick}>
            <div className="txt-ellipsis">{t(category.title)}</div>
            <IconArrow />
        </li>
    );
};

export default Category;

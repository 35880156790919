import React from 'react';
import { useTranslation } from 'react-i18next';

export default function TabHistorySync({ data }) {
    const { t } = useTranslation();
    const historyStatus = data?.status;

    function _renderListData() {
        return [...(data?.log || [])].map((item, index) => {
            return (
                <p key={index.toString()} className="screen-sync__history">
                    {item}
                </p>
            );
        });
    }

    if (data) {
        return (
            <div className="tab-content-active">
                <div className="rows">
                    <span className="mr-1 txt-ellipsis rows__status">{t('addons:last_sync_status')}</span>
                    {!!historyStatus && (
                        <div className={`status-btn --sm mr-1 ${historyStatus.toLowerCase()}`}>
                            {t(`common:${historyStatus.toLowerCase()}`)}
                        </div>
                    )}
                    {data.write_datetime || ''}
                </div>
                <div className="rows">{t('addons:sync_log')}</div>
                <div className="screen-sync scrolls">{_renderListData()}</div>
            </div>
        );
    }
    return null;
}

/* eslint-disable no-unused-vars */
import { reducer } from 'app/const/Reducer';
import { COLOR_SCHEDULES } from 'app/modules/calendar/const';
import IconClock from 'assets/icon/IconClock';
import moment from 'moment';
import React, { useLayoutEffect, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';

let timmer = null;
let clicks = 0;

const JobItem = ({ dataJob: defaultData, onDBClick }) => {
    const setHandleRef = useRef(null);
    const [state, dispatchState] = useReducer(reducer, { dataJob: defaultData });
    const scheduleList = useSelector(({ schedules }) => schedules.data.schedules);
    const { start, end } = useSelector(({ inlineCalendarReducer }) => inlineCalendarReducer);
    // const { dataJob } = state;
    const dataJob = defaultData || {};
    const { colorEvent: dataJobColor = {}, tile: finalJobTitle = {} } = defaultData || {};

    const dataJobTextColor = dataJobColor.text;
    const defaultTile = defaultData.tile || {};

    useLayoutEffect(() => {
        const startEvent = moment.utc(dataJob.start);
        const startCalendar = moment.utc(start);
        const endCalendar = moment.utc(end);

        let days = endCalendar.diff(startCalendar, 'days');
        days = days > COLOR_SCHEDULES.length ? COLOR_SCHEDULES.length : days;

        const dayNumber = (moment.utc(dataJob.start)._d - moment.utc(start)._d) / (24 * 3600 * 1000);
        let startDateWeekday = dayNumber % days;
        startDateWeekday = Math.floor(startDateWeekday < 0 ? startDateWeekday + days : startDateWeekday);
        const idResourceColor = `${Math.abs(defaultData?.schedule?.id)}_${startDateWeekday}`;
        const divQuery = document.querySelector(`[data-resource-id-color="${idResourceColor}"]`);

        dispatchState((prevState) => ({
            ...prevState,
            colorBorder: divQuery ? divQuery.style.backgroundColor : COLOR_SCHEDULES[startDateWeekday]
        }));

        // dispatchState((prevState) => {
        //     const user = scheduleList.find((item) => item.id === defaultData.schedule?.id) || null;
        //     return {
        //         ...prevState,
        //         dataJob: {
        //             ...prevState.dataJob,
        //             tile: { ...defaultTile, header: defaultTile.header_long || defaultTile.header }
        //         },
        //         start: defaultData.start,
        //         borderColorLeft: user
        //             ? user.colors[+moment.utc(defaultData.start).format('DD') - 1]
        //             : prevState.dataJob.schedule_color
        //     };
        // });
    }, [scheduleList, defaultData.resourceId, defaultData.start, defaultData.schedule, defaultTile]);

    // const defaultTile = defaultData.tile || {};

    // useLayoutEffect(() => {
    //     dispatchState((prevState) => {
    //         const user = scheduleList.find((item) => item.id === defaultData.schedule?.id) || null;
    //         return {
    //             ...prevState,
    //             dataJob: {
    //                 ...prevState.dataJob,
    //                 tile: { ...defaultTile, header: defaultTile.header_long || defaultTile.header }
    //             },
    //             start: defaultData.start,
    //             borderColorLeft: user
    //                 ? user.colors[+moment.utc(defaultData.start).format('DD') - 1]
    //                 : prevState.dataJob.schedule_color
    //         };
    //     });
    // }, [scheduleList, defaultData.start, defaultData.schedule, defaultTile]);

    function initialiseDrag() {
        setHandleRef.current.setAttribute(
            'data',
            JSON.stringify({ ...dataJob, resourceId: `GD_fake_id_${Date.now()}` })
        );
        window.addEventListener('mouseup', _stopDragging, false);
        window.addEventListener('touchend', _stopDragging, false);
    }

    function _stopDragging() {
        setHandleRef.current.classList.remove('has-drag');
        setHandleRef.current.setAttribute('data', false);
        window.removeEventListener('mouseup', _stopDragging, false);
        window.removeEventListener('touchend', _stopDragging, false);
    }

    function _resetClick() {
        clicks = 0;
        clearTimeout(timmer);
    }

    function _eventClick() {
        clicks += 1;
        if (clicks === 1) {
            timmer = setTimeout(() => {
                _resetClick();
            }, 1000);
        } else {
            onDBClick(dataJob);
            _resetClick();
        }
    }

    function _renderTitleContent(content = [], color) {
        return content.map((item, index) => {
            if (item.length === 0) {
                return <div key={index.toString()}></div>;
            } else {
                return (
                    <div key={index.toString()} className="info" style={{ color: color }}>
                        {item}
                    </div>
                );
            }
        });
    }

    return (
        <div
            key={dataJob.id}
            onMouseDown={initialiseDrag}
            onTouchStart={initialiseDrag}
            ref={setHandleRef}
            onClick={_eventClick}
            className={`${!!!dataJob.locked ? 'fc-event' : 'fc-event-block'} jobslist active-joblist`}
            // style={{ backgroundColor: dataJobColor.background }}
            style={{ backgroundColor: dataJobColor.background, borderLeft: `solid 4px ${state.colorBorder}` }}
        >
            <div className="name" style={{ color: dataJobTextColor }}>
                {!!dataJob.locked && (
                    <div className="icon">
                        <IconClock clockActive />
                    </div>
                )}
                <span className="word-break">{defaultData.tile.header_long || defaultData.tile.header || ''}</span>
            </div>
            {_renderTitleContent(finalJobTitle.content || [], dataJobTextColor)}
        </div>
    );
};

export default JobItem;

import React, { Fragment, useContext, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import { METHOD_API } from 'app/const/App';
import { LIST_TYPE_CARD } from 'app/const/Payments';
import { reducer } from 'app/const/Reducer';
import { ACTION_VALUE } from 'app/modules/jobdetail/const/Invoice';
import { InvoiceRepeatContext } from 'app/modules/jobdetail/contexts/InvoiceRepeatContext';
import IconDropDown from 'assets/icon/IconDropDown';
import IconFlag from 'assets/icon/IconFlag';
import { ActionTime } from './ActionTime';

const Actions = ({ listEmail, listCard }) => {
    const { t } = useTranslation();
    const refDropdown = useRef(null);
    const [state, setState] = useReducer(reducer, {
        title: null,
        name: null
    });
    const { invoiceRepeatData, updateInvoiRepeatContext } = useContext(InvoiceRepeatContext);
    const { action: finalRepeatAction, offset: invoiceOffset = {} } = invoiceRepeatData;
    const cardSelected = invoiceOffset.card;

    const { title, name = '' } = state;

    useEffect(() => {
        _handleChangeName(cardSelected);
    }, [invoiceRepeatData, listEmail, listCard]);

    const _getNameKeywordCard = (keyword, accountNumber) => {
        switch (keyword) {
            case LIST_TYPE_CARD.STRIPE_CARD:
            case LIST_TYPE_CARD.PAYMENT_CARD:
                return `${t('jobDetail:charge_to_stripe')} ${METHOD_API} ${accountNumber}`;
            case LIST_TYPE_CARD.ACH_CARD:
                return `${t('jobDetail:charge_to_bank_account_ach')} ${METHOD_API} ${accountNumber}`;
            case LIST_TYPE_CARD.SQUARE_CARD:
                return `${t('jobDetail:charge_to_square')} ${METHOD_API} ${accountNumber}`;
            default:
                return '';
        }
    };

    const _handleChangeName = (cardId) => {
        switch (finalRepeatAction) {
            case ACTION_VALUE.NONE:
                setState({ title: t('customers:none'), name: '' });
                break;
            case ACTION_VALUE.SEND_EMAIL:
                setState({ title: t('jobDetail:send_by_email'), name: listEmail.email });
                break;
            case ACTION_VALUE.SEND_BY_EMAIL_AND_CC:
                setState({ title: t('jobDetail:send_by_email_and_cc'), name: listEmail.email });
                break;
            case ACTION_VALUE.SEND_SMS:
                setState({ title: t('jobDetail:send_by_sms'), name: '' });
                break;
            case ACTION_VALUE.SEND_EMAIL_AND_SMS:
                setState({ title: t('jobDetail:send_by_email_and_sms'), name: listEmail.email });
                break;
            case ACTION_VALUE.CHARGE:
                const card = listCard.find((item) => item.id === cardId?.toString());
                setState({
                    title: card
                        ? `${_getNameKeywordCard(card.keyword, card.account_number)}`
                        : t('jobDetail:charge_to'),
                    name: ''
                });
                break;
            case ACTION_VALUE.CHARGE_AND_SEND_RECEIPT:
                const cardSend = listCard.find((item) => item.id === cardId?.toString());
                setState({
                    title: cardSend
                        ? `${_getNameKeywordCard(cardSend.keyword, cardSend.account_number)} ${t('jobDetail:send_receipt')}`
                        : t('jobDetail:charge_to_and_send_receipt'),
                    name: ''
                });
                break;
            default:
                setState({ name: '' });
                break;
        }
    };

    const _stopPropagation = (e) => {
        e && e.stopPropagation();
    };

    const _handleSelectAction = (e, value, cardId = '') => {
        _stopPropagation(e);

        updateInvoiRepeatContext({
            ...invoiceRepeatData,
            action: value,
            offset: {
                ...invoiceRepeatData.offset,
                card: cardId
            }
        });
        refDropdown.current._close();
    };

    const _renderListCardsEmpty = () => {
        if (!!listCard.length) {
            return null;
        }

        return (
            <Fragment>
                <li
                    onClick={(e) => {
                        _handleSelectAction(e, ACTION_VALUE.CHARGE, '');
                    }}
                    className={`items ${finalRepeatAction === ACTION_VALUE.CHARGE ? 'active' : ''}`}
                >
                    <p className="txt-ellipsis">{t('jobDetail:charge_to')}</p>
                </li>
                <li
                    onClick={(e) => {
                        _handleSelectAction(e, ACTION_VALUE.CHARGE_AND_SEND_RECEIPT, '');
                    }}
                    className={`items ${finalRepeatAction === ACTION_VALUE.CHARGE_AND_SEND_RECEIPT ? 'active' : ''}`}
                >
                    <p className="txt-ellipsis">{t('jobDetail:charge_to_and_send_receipt')}</p>
                </li>
            </Fragment>
        );
    };

    const _renderListCards = () => {
        return listCard.map((card) => {
            const isDefault = !!card.is_default;
            const { id: cardId, keyword: cardKeyword, account_number: cardAccountNumber } = card;
            const isActive = cardSelected === cardId;

            return (
                <Fragment key={cardId}>
                    <li
                        onClick={(e) => {
                            _handleSelectAction(e, ACTION_VALUE.CHARGE, cardId);
                        }}
                        className={`items ${finalRepeatAction === ACTION_VALUE.CHARGE && isActive ? 'active' : ''}`}
                    >
                        <p className="txt-ellipsis">
                            {`${_getNameKeywordCard(cardKeyword, cardAccountNumber)} ${isDefault ? t('jobDetail:default') : ''}`}
                        </p>
                    </li>
                    <li
                        onClick={(e) => {
                            _handleSelectAction(e, ACTION_VALUE.CHARGE_AND_SEND_RECEIPT, cardId);
                        }}
                        className={`items ${
                            finalRepeatAction === ACTION_VALUE.CHARGE_AND_SEND_RECEIPT && isActive ? 'active' : ''
                        }`}
                    >
                        <p className="txt-ellipsis">
                            {`${_getNameKeywordCard(cardKeyword, cardAccountNumber)}${
                                isDefault ? ` ${t('jobDetail:default')}` : ''
                            }${t('jobDetail:send_receipt')}`}
                        </p>
                    </li>
                </Fragment>
            );
        });
    };

    function _renderOptions() {
        return (
            <ul>
                <li
                    onClick={(e) => {
                        _handleSelectAction(e, ACTION_VALUE.NONE);
                    }}
                    className={`items ${finalRepeatAction === ACTION_VALUE.NONE ? 'active' : ''}`}
                >
                    <p className="txt-ellipsis">{t('customers:none')}</p>
                </li>
                <li
                    onClick={(e) => {
                        _handleSelectAction(e, ACTION_VALUE.SEND_EMAIL);
                    }}
                    className={`items ${finalRepeatAction === ACTION_VALUE.SEND_EMAIL ? 'active' : ''}`}
                >
                    <p className="txt-ellipsis">{t('jobDetail:send_by_email')}</p>
                </li>
                <li
                    onClick={(e) => {
                        _handleSelectAction(e, ACTION_VALUE.SEND_BY_EMAIL_AND_CC);
                    }}
                    className={`items ${finalRepeatAction === ACTION_VALUE.SEND_BY_EMAIL_AND_CC ? 'active' : ''}`}
                >
                    <p className="txt-ellipsis">{t('jobDetail:send_by_email_and_cc')}</p>
                </li>
                <li
                    onClick={(e) => {
                        _handleSelectAction(e, ACTION_VALUE.SEND_SMS);
                    }}
                    className={`items ${finalRepeatAction === ACTION_VALUE.SEND_SMS ? 'active' : ''}`}
                >
                    <p className="txt-ellipsis">{t('jobDetail:send_by_sms')}</p>
                </li>
                <li
                    onClick={(e) => {
                        _handleSelectAction(e, ACTION_VALUE.SEND_EMAIL_AND_SMS);
                    }}
                    className={`items ${finalRepeatAction === ACTION_VALUE.SEND_EMAIL_AND_SMS ? 'active' : ''}`}
                >
                    <p className="txt-ellipsis">{t('jobDetail:send_by_email_and_sms')}</p>
                </li>
                {_renderListCardsEmpty()}
                {_renderListCards()}
            </ul>
        );
    }

    return (
        <div className="rows row-has-line border-top-border-color-grey">
            <div className="txt">
                <IconFlag />
                {t('report:action')}
            </div>
            <div className="detail repeat-action">
                <DropdownPopper
                    isUseToggle
                    ref={refDropdown}
                    strategy="absolute"
                    wrapperClassName="v2-dropdown"
                    buttonClassName="dropbtn v2-btn-default selection"
                    dropdownIcon={
                        <>
                            <span className="txt-ellipsis">{title}</span>
                            <span className="svg-selectbox">
                                <IconDropDown />
                            </span>
                        </>
                    }
                >
                    {_renderOptions()}
                </DropdownPopper>
                <div className="detail-email ml-2">
                    <div className="txt-ellipsis">{name}</div>
                </div>
                <ActionTime />
            </div>
        </div>
    );
};

export default Actions;

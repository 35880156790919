export default function IconInbox({ isActive = false }) {
    if (isActive) {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.7639 6C16.5215 6 17.214 6.428 17.5528 7.10557L19 10L19.0003 10.2678C18.7061 10.0975 18.3644 10 18 10H17C15.9456 10 15.0818 10.8159 15.0055 11.8507L15 12H8.99999C8.99999 10.8954 8.10456 10 6.99999 10H5.99999C5.63545 10 5.2937 10.0975 4.99939 10.2679L4.99999 10L6.4472 7.10557C6.78598 6.428 7.47851 6 8.23605 6H15.7639Z"
                    fill="#C1C9D4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 11H7C7.55228 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13H15C15.5523 13 16 12.5523 16 12C16 11.4477 16.4477 11 17 11H18C18.5523 11 19 11.4477 19 12V16C19 17.1046 18.1046 18 17 18H7C5.89543 18 5 17.1046 5 16V12C5 11.4477 5.44772 11 6 11Z"
                    fill="#464B55"
                />
            </svg>
        );
    }
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.60557 7.94721L8.5 6.5H15.5L18.3944 7.94721C19.072 8.286 19.5 8.97852 19.5 9.73607V15.5C19.5 16.6046 18.6046 17.5 17.5 17.5H6.5C5.39543 17.5 4.5 16.6046 4.5 15.5V9.73607C4.5 8.97852 4.928 8.286 5.60557 7.94721Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.5 12.5V11.5C4.5 10.9477 4.94772 10.5 5.5 10.5H7.5L10.5 12.5H13.5L16.5 10.5H18.5C19.0523 10.5 19.5 10.9477 19.5 11.5V12.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

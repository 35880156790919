import classNames from 'classnames';
import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import GDInputNumber from 'app/components/input/InputNumber';
import { reducer } from 'app/const/Reducer';
import { actionUpdateWorklogUser } from 'common/redux/actions/worklogs';

const WorkLogItem = ({ item = {} }) => {
    const { t } = useTranslation('addons');
    const dispatch = useDispatch();
    const currency = useSelector(({ auth }) => auth.user.settings.currency);
    const [state, dispatchState] = useReducer(reducer, {
        isTracking: !!item.tracking,
        isEdit: !!item.edit
    });
    const { isTracking, isEdit } = state;

    const _handleSwitch = (checked, id, e) => {
        const keyword = e.target.name;
        const keyUpdate = keyword === 'tracking' ? 'isTracking' : 'isEdit';
        const value = checked ? 1 : 0;

        dispatch(actionUpdateWorklogUser({ id: item.id, keyword, value, [keyword]: value, locked: item.locked }));
        dispatchState((prevState) => ({ ...prevState, [keyUpdate]: checked }));
    };

    const _handleBlur = (e) => {
        const value = e.target.value;
        dispatch(actionUpdateWorklogUser({ id: item.id, keyword: 'rate', value, rate: value, locked: item.locked }));
    };

    return (
        <div key={item.id} className="rows">
            <div className="col col-lg">
                <div className="col-customer flexcenter gap-4">
                    <span className="avt-img">
                        <img src={item.avatar} width="32" height="32" />
                    </span>
                    <span className="txt-ellipsis fw-500">{item.full_name}</span>
                </div>
            </div>
            <div className="col col-md">
                <GdSwitchCheckbox
                    id={item.id}
                    name="tracking"
                    textChecked={t('enabled')}
                    textUnChecked={t('disabled')}
                    textPosition="right"
                    defaultChecked={isTracking}
                    onChangeImmediately={_handleSwitch}
                />
            </div>
            <div className="col col-md --time">
                <GdSwitchCheckbox
                    id={`${item.id}-edit`}
                    name="edit"
                    textChecked={t('enabled')}
                    textUnChecked={t('disabled')}
                    textPosition="right"
                    defaultChecked={isEdit}
                    onChangeImmediately={_handleSwitch}
                />
            </div>

            <div className={classNames('col col-rate flexcenter gap-8', { 'is-disable': !isTracking })}>
                <span className="black-verylight-green fs-14">{t('rate')}</span>
                <div className="cost-input --left --right" data-currency-unit={currency.symbol}>
                    <GDInputNumber
                        disabled={!isTracking}
                        className="field-input fs-14"
                        type="number"
                        defaultValue={item.rate || 0}
                        placeholder="0"
                        exceptZero
                        onBlur={_handleBlur}
                    />
                </div>
            </div>
        </div>
    );
};

export default WorkLogItem;

import React from 'react';
import { useTranslation } from 'react-i18next';

import EmailChoose from './EmailChoose';

const StepConfirmEmail = () => {
    const { t } = useTranslation();
    return (
        <div className="step scrolls show">
            <div className="row">
                <p>{t('addons:confirm_email_step')}</p>
            </div>
            <EmailChoose />
        </div>
    );
};

export default StepConfirmEmail;

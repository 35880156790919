export default function IconBell({ isHasColor = false, isDoubleActive = false, isPurple = false }) {
    if (isDoubleActive) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24">
                <path
                    fill="#C1C9D4"
                    fillRule="evenodd"
                    d="M11.5 5h1A4.5 4.5 0 0117 9.5V13a1 1 0 01-1 1H8a1 1 0 01-1-1V9.5A4.5 4.5 0 0111.5 5z"
                    clipRule="evenodd"
                ></path>
                <path fill="#7A83A6" d="M12 6a1 1 0 100-2 1 1 0 000 2z"></path>
                <path
                    fill="#7A83A6"
                    fillRule="evenodd"
                    d="M12 20a2 2 0 002-2h-4a2 2 0 002 2z"
                    clipRule="evenodd"
                ></path>
                <path fill="#7A83A6" d="M17 15H7a1 1 0 100 2h10a1 1 0 100-2z"></path>
            </svg>
        );
    }
    if (isHasColor)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.5 5H12.5C14.9853 5 17 7.01472 17 9.5V13C17 13.5523 16.5523 14 16 14H8C7.44772 14 7 13.5523 7 13V9.5C7 7.01472 9.01472 5 11.5 5Z"
                    fill="#C1C9D4"
                ></path>
                <path
                    d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                    fill="#7A83A6"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 20C13.1046 20 14 19.1046 14 18H10C10 19.1046 10.8954 20 12 20Z"
                    fill="#7A83A6"
                ></path>
                <path
                    d="M17 15H7C6.44772 15 6 15.4477 6 16C6 16.5523 6.44772 17 7 17H17C17.5523 17 18 16.5523 18 16C18 15.4477 17.5523 15 17 15Z"
                    fill="#7A83A6"
                ></path>
            </svg>
        );
    if (isPurple)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.5 5H12.5C14.9853 5 17 7.01472 17 9.5V13C17 13.5523 16.5523 14 16 14H8C7.44772 14 7 13.5523 7 13V9.5C7 7.01472 9.01472 5 11.5 5Z"
                    fill="#DAC2F2"
                />
                <path
                    d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                    fill="#8D4AFC"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 20C13.1046 20 14 19.1046 14 18H10C10 19.1046 10.8954 20 12 20Z"
                    fill="#8D4AFC"
                />
                <path
                    d="M17 15H7C6.44772 15 6 15.4477 6 16C6 16.5523 6.44772 17 7 17H17C17.5523 17 18 16.5523 18 16C18 15.4477 17.5523 15 17 15Z"
                    fill="#8D4AFC"
                />
            </svg>
        );
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5 6.5C14.7091 6.5 16.5 8.29086 16.5 10.5V13.5C16.5 14.6046 17.3954 15.5 18.5 15.5H6.5C7.60457 15.5 8.5 14.6046 8.5 13.5V10.5C8.5 8.29086 10.2909 6.5 12.5 6.5Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5 4.5C13.0523 4.5 13.5 4.94772 13.5 5.5V6.5H11.5V5.5C11.5 4.94772 11.9477 4.5 12.5 4.5Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M12.5 19.5C13.0523 19.5 13.5 19.0523 13.5 18.5C13.5 17.9477 13.0523 17.5 12.5 17.5C11.9477 17.5 11.5 17.9477 11.5 18.5C11.5 19.0523 11.9477 19.5 12.5 19.5Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
}

const IconCheckList = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.5 16.5H19.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M10.5 11.5H19.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M10.5 6.5H19.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M4.5 6.5L5.5 7.5L7.5 5.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M4.5 11.5L5.5 12.5L7.5 10.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M4.5 16.5L5.5 17.5L7.5 15.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
};
export default IconCheckList;

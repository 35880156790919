import React from 'react';

export default function RenderAvatar({ userData = {}, width = 24, height = 24, extendClass = '' }) {
    const { avatar, full_name, first_name, last_name } = userData || {};

    function _renderAvatar() {
        if (!!avatar) {
            return <img src={avatar} alt="" width={width} height={height} />;
        } else {
            let finalName = full_name?.substring(0, 2);
            // eslint-disable-next-line no-prototype-builtins
            if (userData.hasOwnProperty('first_name') && userData.hasOwnProperty('last_name'))
                finalName = `${first_name.substring(0, 1)}${last_name.substring(0, 1)}`;

            return <div className="avt fs-11">{finalName}</div>;
        }
    }

    return (
        <div className={`avt-img ${extendClass}`} title={full_name}>
            {!!userData ? _renderAvatar() : ''}
        </div>
    );
}

import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import IconAttachment from 'app/components/attachments/IconAttachment';
import ButtonSave from 'app/components/button/ButtonSave';
import SelectEmail from 'app/components/email/SelectEmail';
import RichTextDropzone from 'app/components/richtext/components/RichTextDropzone';
import { LIMIT_ATTACHMENT } from 'app/const/Customers';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import AddAttachment from 'app/modules/customer/components/AddAttachment';
import OptionsEmailTo from 'app/modules/customer/email/components/OptionsEmailTo';
import { LIST_ACTIONS, TYPE_SEND_EMAIL_SMS } from 'app/modules/jobdetail/const';
import { handleCreatePopperAttach } from 'app/modules/jobdetail/const/Utils';
import IconEmail from 'assets/icon/IconEmail';
import IconPen from 'assets/icon/IconPen';
import IconTrash from 'assets/icon/IconTrash';
import { clientQuery } from 'common/utils/ApiUtils';
import { preventPasteImage } from 'common/utils/FunctionUtils';
import StatusBar from 'app/components/status/statusbar';

const Email = forwardRef(
    (
        {
            isVisible,
            handleCloseForm = () => {},
            customerId,
            onSend,
            urlGetEmail,
            paramsEmail,
            isEmailAndSms = false,
            parentSendSms = () => {},
            onChangeTab = () => {},
            onCallbackMessage = () => {},
            objectEmail
        },
        ref
    ) => {
        const { t } = useTranslation();
        let dragTimer = null;
        const [state, dispatchState] = useReducer(reducer, {
            isCc: false,
            isBcc: false,
            listEmail: [],
            to: [],
            cc: [],
            bcc: [],
            subject: '',
            message: '',
            attachments: [],
            attachmentDefault: ''
        });
        const refDropWarning = useRef(null);
        const refAttachment = useRef(null);
        // const refContent = useRef(null);

        useImperativeHandle(ref, () => ({
            handleSend: _handleSendEmail
        }));

        useEffect(() => {
            document.addEventListener('dragover', _handleDragOver);
            document.addEventListener('dragleave', _handleDragEnd);

            return () => {
                document.removeEventListener('dragover', _handleDragOver);
                document.removeEventListener('dragleave', _handleDragEnd);
            };
        }, []);

        // Handle drag image over window
        const _handleDragOver = (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (refDropWarning.current) refDropWarning.current.handleDisplay();
            window.clearTimeout(dragTimer);
        };

        const _handleDragEnd = (e) => {
            e.preventDefault();
            e.stopPropagation();
            dragTimer = window.setTimeout(() => {
                if (refDropWarning.current) refDropWarning.current.handleHidden();
            }, 25);
        };

        const refForm = useRef(null);
        const refButtonSave = useRef(null);
        const refStatusBar = useRef(null);

        const {
            isCc,
            isBcc,
            to: finalEmailTo,
            listEmail: finalListEmail,
            cc: finalEmailCC,
            bcc: finalEmailBCC,
            subject,
            message,
            attachments: finalListAttachments,
            attachmentDefault
        } = state;

        useEffect(() => {
            if (isVisible) {
                if (!objectEmail) {
                    _getEmailTemplate();
                } else {
                    dispatchState({
                        ...objectEmail
                    });
                }
            }
        }, [isVisible]);

        function _getEmailTemplate() {
            const _getEmailTemplateFail = ({ message }) => {
                _handleCallback(false, message);
            };
            clientQuery(
                urlGetEmail,
                { method: 'GET', data: paramsEmail },
                _getEmailTemplateSuccess,
                _getEmailTemplateFail
            );
        }

        function _getEmailTemplateSuccess(response) {
            const { emails, cc_emails } = response;
            const responseData = emails ? [...emails] : [];
            const emailActive = [];
            const emailCC = cc_emails ? [...cc_emails] : [];

            const finalListEmail = responseData.map((item) => {
                if (item.is_active) {
                    emailActive.push(item.value);
                }
                return {
                    email: item.value || ''
                };
            });

            // refContent.current && refContent.current.setValue(response.content || '');
            dispatchState({
                listEmail: finalListEmail,
                to: emailActive,
                cc: emailCC,
                isCc: !!emailCC.length,
                subject: response.subject,
                message: response.content,
                attachmentDefault: response.attachment
            });
        }

        const _preventDefault = (e) => {
            e && e.preventDefault();
        };

        const _stopPropagation = (e) => {
            e && e.stopPropagation();
        };

        const _handleChangeSubject = (e) => {
            dispatchState({ subject: e.target.value });
        };

        const _handleChangeMessage = (e) => {
            dispatchState({ message: e.target.innerHTML });
        };

        function _handleChangeCC() {
            dispatchState({ isCc: !isCc });
        }

        function _handleChangeBCC() {
            dispatchState({ isBcc: !isBcc });
        }

        const _handleDeleteAction = (e, value) => {
            _stopPropagation(e);
            switch (value) {
                case LIST_ACTIONS.EMAIL_CC:
                    dispatchState({ isCc: false });
                    break;
                case LIST_ACTIONS.EMAIL_BCC:
                    dispatchState({ isBcc: false });
                    break;
                default:
                    break;
            }
        };

        const _handleSendEmail = (fromComponent = false) => {
            if (!subject) {
                _handleCallback(false, t('customers:cannot_be_blank', { name: t('jobDetail:subject') }));
                return false;
            }
            if (!message || message === '<p><br></p>') {
                _handleCallback(false, t('customers:cannot_be_blank', { name: t('addons:message') }));
                return false;
            }

            if (fromComponent && isEmailAndSms) {
                if (!parentSendSms()) {
                    _handleCloseLoading();
                    return false;
                }
            }

            onSend(
                {
                    attachments: finalListAttachments.map((item) => item.object_key),
                    receiver: finalEmailTo,
                    cc: finalEmailCC,
                    bcc: finalEmailBCC,
                    subject: subject,
                    message: message,
                    request_sig: false
                },
                _handleCallback
            );

            return true;
        };

        function _handleCallback(status, message) {
            if (isEmailAndSms) {
                onCallbackMessage(status ? LIST_STATUS.SUCCESS : LIST_STATUS.ERROR, message);
            } else {
                refStatusBar.current?.showStatusBar(
                    'show_error',
                    message,
                    status ? LIST_STATUS.SUCCESS : LIST_STATUS.ERROR
                );
            }

            _handleCloseLoading();

            if (status) {
                setTimeout(() => {
                    !isEmailAndSms && handleCloseForm();
                }, 2000);
            } else {
                isEmailAndSms && onChangeTab(null, TYPE_SEND_EMAIL_SMS.SEND_EMAIL);
            }
            return status;
        }

        function _handleCloseLoading() {
            refButtonSave.current?.removeLoading();
        }

        function _addNewAttachment(newAttachment) {
            dispatchState((prevState) => {
                return {
                    ...prevState,
                    attachments: [newAttachment].concat(prevState.attachments)
                };
            });
        }

        function _updateAttachment(dataAttach) {
            dispatchState((prevState) => {
                const newAttach = [...prevState.attachments];
                const index = newAttach.findIndex((item) => item.id === dataAttach?.idAttach);
                if (index < 0) return state;
                newAttach[index]['isUploading'] = false;
                if (dataAttach?.object_key) newAttach[index]['object_key'] = dataAttach.object_key;

                return {
                    ...prevState,
                    attachments: newAttach
                };
            });
        }

        function _deleteAttachment(idAttach) {
            dispatchState((prevState) => {
                return {
                    ...prevState,
                    attachments: [...prevState.attachments].filter((item) => item.id !== idAttach)
                };
            });
        }

        function _handleSelectEmailTo(selected) {
            dispatchState({
                to: selected
            });
        }

        function _handleSelectEmailCC(selected) {
            dispatchState({
                cc: selected
            });
        }

        function _handleSelectEmailBCC(selected) {
            dispatchState({
                bcc: selected
            });
        }

        const _renderAttachmentItem = (list) => {
            if (!Array.isArray(list) || list.length === 0) return null;

            return list.map((item) => {
                const { id, url, mime, type, name, size, isUploading } = item;

                return (
                    <div key={id} className="file">
                        <div
                            id={id}
                            className="file__items tooltip"
                            onMouseOver={() => handleCreatePopperAttach(id, url)}
                        >
                            <IconAttachment type={mime || type} />
                            <p className="names">{name}</p>
                            <p id={url} className="tooltiptext">{`${name} (${size})`}</p>
                        </div>
                        <span
                            onClick={() => {
                                _deleteAttachment(id);
                            }}
                            className="btn-delete v2-btn-default --icon-sm --delete"
                        >
                            {!isUploading && <IconTrash />}
                        </span>
                    </div>
                );
            });
        };

        if (!isVisible) return null;

        function _renderEmailTo() {
            return (
                <div className="row-multi --email-to">
                    <div className="left-txt">
                        <IconEmail isEmailAddress />
                        {t('addons:to')}:
                    </div>
                    <SelectEmail
                        max={3}
                        emails={finalListEmail}
                        emailSelected={finalEmailTo}
                        onSelect={_handleSelectEmailTo}
                    />
                    <OptionsEmailTo
                        emailCC={isCc}
                        emailBCC={isBcc}
                        onClickCC={_handleChangeCC}
                        onClickBCC={_handleChangeBCC}
                    />
                </div>
            );
        }

        function _renderCC() {
            return (
                <div className="row-multi --email-to --to-cc --email-cc">
                    <div className="left-txt">
                        <IconEmail isEmailAddress />
                        {t('jobDetail:cc')}:
                    </div>
                    <SelectEmail
                        max={1}
                        emails={finalListEmail}
                        emailSelected={finalEmailCC}
                        onSelect={_handleSelectEmailCC}
                    />
                    <div
                        onClick={(e) => {
                            _handleDeleteAction(e, LIST_ACTIONS.EMAIL_CC);
                        }}
                        className="v2-btn-default --icon-lg --delete"
                    >
                        <IconTrash />
                    </div>
                </div>
            );
        }

        function _renderBCC() {
            return (
                <div className="row-multi --email-to --to-cc --email-bcc">
                    <div className="left-txt">
                        <IconEmail isEmailAddress />
                        {t('jobDetail:bcc')}:
                    </div>
                    <SelectEmail
                        max={1}
                        emails={finalListEmail}
                        emailSelected={finalEmailBCC}
                        onSelect={_handleSelectEmailBCC}
                    />
                    <div
                        onClick={(e) => {
                            _handleDeleteAction(e, LIST_ACTIONS.EMAIL_BCC);
                        }}
                        className="v2-btn-default --icon-lg --delete"
                    >
                        <IconTrash />
                    </div>
                </div>
            );
        }

        const _handleDropFile = (files) => {
            if (refAttachment.current) refAttachment.current.uploadAttachments(files);
        };

        return (
            <>
                <StatusBar ref={refStatusBar} />
                <form ref={refForm} onSubmit={_preventDefault} className="wrap-send-email has-cc-email has-bcc-email">
                    {_renderEmailTo()}

                    {isCc && _renderCC()}

                    {isBcc && _renderBCC()}

                    <div className="row-multi">
                        <div className="left-txt">
                            <IconPen isHasColor />
                            <span className="txt-ellipsis">{t('jobDetail:subject')}:</span>
                        </div>
                        <input
                            onBlur={_handleChangeSubject}
                            className="field-input"
                            placeholder={t('jobDetail:subject')}
                            name="subject"
                            type="text"
                            defaultValue={subject}
                            autoFocus
                        />
                    </div>
                    <div className="body-modal scrolls">
                        <div className="wrap-draft-email">
                            <div
                                className="container-content-email scrolls"
                                contentEditable="true"
                                aria-multiline="true"
                                onPaste={preventPasteImage}
                                onBlur={_handleChangeMessage}
                                dangerouslySetInnerHTML={{ __html: message }}
                            />
                            <RichTextDropzone ref={refDropWarning} pointerEventNone={false} onDrop={_handleDropFile} />
                        </div>

                        <div className="attachment-file">
                            {attachmentDefault && (
                                <div className="file">
                                    <div
                                        id={attachmentDefault}
                                        className="file__items tooltip"
                                        onMouseOver={() =>
                                            handleCreatePopperAttach(
                                                attachmentDefault,
                                                `${attachmentDefault}${customerId}`
                                            )
                                        }
                                    >
                                        <IconAttachment type={'application/pdf'} />
                                        <p className="names">{attachmentDefault}</p>
                                        <p id={`${attachmentDefault}${customerId}`} className="tooltiptext">
                                            {attachmentDefault}
                                        </p>
                                    </div>
                                </div>
                            )}

                            {_renderAttachmentItem(finalListAttachments)}
                        </div>
                    </div>
                    <div className="footer-modal btn-close">
                        <AddAttachment
                            ref={refAttachment}
                            itemId={customerId}
                            onAddNew={_addNewAttachment}
                            onDelete={_deleteAttachment}
                            onUpdate={_updateAttachment}
                            type={'note'}
                            totalSize={finalListAttachments.reduce((a, b) => a + b.file.size, 0)}
                            limitTotalSize={LIMIT_ATTACHMENT / 1024}
                        />
                        <div className="d-flex justify-end flex-1">
                            <span onClick={handleCloseForm} className="v2-btn-default --noborder --label">
                                {t('common:cancel')}
                            </span>
                            <ButtonSave
                                disabled={!finalEmailTo.length || finalListAttachments.some((item) => item.isUploading)}
                                onSave={() => _handleSendEmail(true)}
                                ref={refButtonSave}
                                title={t('common:send')}
                            />
                        </div>
                    </div>
                </form>
            </>
        );
    }
);

export default Email;

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import IconArrow from 'assets/icon/IconArrow';
import IconClose from 'assets/icon/IconClose';
import IconSingleStep from 'assets/icon/IconSingleStep';
import { SetupEmailContext } from '../../context/SetupEmailContext';

const Header = ({ onClose = () => {} }) => {
    const { t } = useTranslation();
    const { step, prevStep } = useContext(SetupEmailContext);

    return (
        <div className="header-modal">
            {step !== 1 && (
                <div className="v2-btn-default --icon-lg --transparent btn-back" onClick={prevStep}>
                    <IconArrow isPrev />
                </div>
            )}
            {step === 3 ? (
                <h3 className="header-modal__label flexcenter">
                    <span className="mr-2 txt-ellipsis">{t('addons:setup_your_email')}</span>
                    <IconSingleStep />
                    <span className="ml-2 txt-ellipsis">{t('addons:confirm_account')}</span>
                </h3>
            ) : (
                <h3 className="header-modal__label">{t('addons:setup_your_email')}</h3>
            )}
            <span className="v2-btn-default --icon-lg --transparent" onClick={onClose}>
                <IconClose />
            </span>
        </div>
    );
};

export default Header;

import React from 'react';
import { useTranslation } from 'react-i18next';

const RowHeader = () => {
    const { t } = useTranslation();
    return (
        <div className="rows --fixed --header">
            <div className="col col-day">
                <p className="col-label" title={t('common:day')}>
                    {t('common:day')}
                </p>
            </div>
            <div className="col col-day">
                <p className="col-label" title={t('addons:all_day')}>
                    {t('addons:all_day')}
                </p>
            </div>
            <div className="col col-lg">
                <p className="col-label" title={t('addons:office_hours')}>
                    {t('addons:office_hours')}
                </p>
            </div>
            <div className="col col-lg">
                <p className="col-label" title={t('addons:after_hours')}>
                    {t('addons:after_hours')}
                </p>
            </div>
        </div>
    );
};

export default RowHeader;

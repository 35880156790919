import { all } from 'redux-saga/effects';
import { appWatcher } from 'common/redux/sagas/app';
import { authWatcher } from './auth/auth';
import { inboxWatcher } from './inbox/index';
import { notificationWatcher } from './notification';
import { searchWatcher } from './search';
import { companyUsersEditWatcher, companyUsersWatcher } from './companyUsers';

import {
    smsConversationGetTotalWatcher,
    smsGetListPhonesWatcher,
    smsGetListByCustomerWatcher,
    smsGetListVariableWatcher,
    smsUpdateStatusWatcher
} from './smsv2';

import {
    // Batch Actions
    reportInvoiceSendMailWatcher,
    reportInvoiceGeneratePdfWatcher,
    reportEstimateSendMailWatcher,
    reportInvoiceGetListWatcher,
    reportInvoiceSearchWatcher,
    reportInvoiceDeleteWatcher,
    reportInvoiceArchiveWatcher,
    reportInvoiceGetServiceWatcher,
    reportServiceLookReassignJobsupWatcher,
    getListReportEstimatesWatcher,
    deleteReportEstimatesWatcher,
    activeReportEstimatesWatcher,
    archiveReportEstimatesWatcher,
    unDeleteReportEstimatesWatcher,
    trashReportEstimatesWatcher,
    reportPaymentGetListWatcher,
    reportPaymentSearchWatcher,
    reportPaymentDeleteWatcher,
    reportPaymentSyncQbWatcher,
    reportPaymentGetListMethodWatcher,
    reportDocumentGetListWatcher,
    reportDocumentDeleteWatcher,
    reportDocumentUndeleteWatcher,
    reportDocumentTrashWatcher,
    reportDocumentGetListTypeWatcher,
    reportCreditGetListWatcher,
    reportCreditDeleteWatcher,
    reportRecurringInvoiceGetListWatcher,
    reportSubscriptionGetListWatcher,
    reportSubscriptionGetListFlansFilterWatcher,
    reportServiceLookupGetListWatcher,
    reportlocationsWoActiveJobsGetListWatcher,
    reportCustomersWoActiveJobGetListWatcher,
    reportAccountsAgingGetListWatcher,
    reportAccountsAgingPrintInvoicesWatcher,
    reportAccountsAgingGenerateInvoicesPdfWatcher,
    reportAccountsAgingEmailLatePaymentRemindersWatcher,
    reportAccountsAgingSmsLatePaymentRemindersWatcher,
    reportAccountsAgingEmailStatementsWatcher,
    reportSalesTaxGetListWatcher,
    reportTaxSummaryGetListWatcher,
    reportFilterMethodTaxSummaryWatcher,
    reportSalesForecastGetListWatcher,
    reportRevenueServiceGetListWatcher,
    reportRevenueServicePaymentGetListWatcher,
    reportRevenueServiceGetListMethodFilterWatcher,
    reportRevenueServiceGetListServiceFilterWatcher,
    reportRevenueClientGetListWatcher,
    reportRevenueClientPaymentGetListWatcher,
    reportRevenueGetListYearWatcher,
    reportRevenueItemGetListWatcher,
    reportRevenueItemPaymentGetListWatcher,
    reportRevenueItemGetListMethodFilterWatcher,
    reportRevenueItemGetListServiceFilterWatcher,
    reportRevenueStaffGetListWatcher,
    reportRevenueStaffPaymentGetListWatcher,
    reportRevenueStaffGetListMethodFilterWatcher,
    reportRevenueStaffGetListServiceFilterWatcher,
    reportRevenueSourceGetListWatcher,
    reportRevenueSourcePaymentGetListWatcher,
    reportRevenueSourceGetListMethodFilterWatcher,
    reportRevenueSourceGetListSourceFilterWatcher,
    reportRevenueTagsGetListWatcher,
    reportRevenueTagsPaymentGetListWatcher,
    reportRevenueTagsGetListMethodFilterWatcher,
    reportEmailHistoryGetListWatcher,
    exportEmailHistoryWatcher,
    reportMaterialUseGetListWatcher,
    reportMaterialUseListCountyWatcher,
    reportMaterialUseGetListJobsWoCountyWatcher,
    reportMaterialUseDetailGetListWatcher,
    reportTotalSalesGetListWatcher,
    reportTotalSalesGetListDataChartWatcher,
    reportTotalSalesGetListYearWatcher,
    reportPaymentsCollectedGetListWatcher,
    reportPaymentsCollectedGetListYearsWatcher,
    reportPaymentsCollectedGetListDataChartWatcher,
    reportPaymentsCollectedGetListStaffsFilterWatcher,
    reportPaymentsCollectedGetListMethodFilterWatcher,
    reportNewCustomersGetListWatcher,
    reportNewCustomersGetListDataChartWatcher,
    reportNewCustomersGetListYearWatcher,
    reportNewCustomersGetListSourceFilterWatcher,
    reportCustomerLeadsGetListWatcher,
    reportBookingJobsGetListWatcher,
    reportOnlineBookingsChartWatcher,
    reportInboundLeadsWatcher,
    reportGetYearInboundLeadsWatcher,
    reportGetYearOnlineBookingsChartWatcher,
    reportServiceLookupGetListScheduleWatcher,
    reportServiceLookupGetListServiceTypeWatcher,
    reportMaterialGetListWatcher
} from './report';

import {
    reviewGetLikeylyWatcher,
    reviewGetServiceWatcher,
    reviewGetListUserFilterWatcher,
    reviewGetListUserWatcher
} from './review';

import {
    commissionGetListDataWatcher,
    commissionGetListUserWatcher,
    commissionGetSettingUserWatcher,
    commissionApplySettingFromAnotherUserWatcher,
    commissionUpdateSettingUserWatcher,
    commissionGetListStaffsFilterWatcher,
    commissionGetListMethodFilterWatcher,
    commissionGetListItemsWatcher,
    commissionGetListUsersWatcher
} from './commission';

import {
    getListSearchCustomerWatcher,
    getListCustomerWatcher,
    customerMarkAsWatcher,
    updateStatusCustomerWatcher,
    getStatisticCustomerWatcher,
    getCustomerAccountNumberWatcher,
    getListSettingCompanyPhoneTypeWatcher,
    getListCustomerSourceWatcher,
    getListCustomersearchTagsWatcher,
    updateStatusCustomerDetailCreditWatcher,
    getListContactWatcher,
    createContactWatcher,
    updateContactWatcher,
    deleteContactWatcher,
    getCustomerInformationWatcher,
    getListLocationWatcher,
    createLocationWatcher,
    updateLocationWatcher,
    getStatementSummaryWatcher,
    getStatementHistoriesWatcher,
    statementToPDFWatcher,
    statementSendByEmailWatcher,
    getListCustomerReportWatcher,
    getCustomerJobsWatcher,
    getListCustomerTagHistoryWatcher,
    getListJobWorkPoolWatcher,
    getCustomerListTaskWatcher,
    updateTaskStatusWatcher,
    calendarGetListCustomerSendingWatcher
} from './customer';

import {
    settingsGetListServiceWatcher,
    settingsGetListServicePlanWatcher,
    settingsGetListSimplePaymentMethodsWatcher,
    settingsGetListPaymentMethodsWatcher,
    settingsCreatePaymentMethodsWatcher,
    settingsUpdatePaymentMethodsWatcher,
    settingsUpdateStatusPaymentMethodsWatcher,
    settingsGetListSimpleTagsWatcher,
    settingsGetListSettingWatcher,
    settingsCreateTagsWatcher,
    settingsUpdateTagWatcher,
    settingsDeleteTagsWatcher,
    settingsGetListTilesWatcher,
    settingsGetOptionsTilesWatcher,
    settingsUpdateListTilesWatcher,
    settingsGetListSchedulesWatcher,
    settingsReassignSchedulesWatcher,
    settingsAddAndUpdateSchedulesWatcher,
    settingsDeleteSchedulesWatcher,
    getListUsersSettingsWatcher,
    deleteUserSettingsWatcher,
    settingsCreateUserWatcher,
    settingsUpdateUserWatcher,
    getUserDetailWatcher,
    settingsGetListLineItemsWatcher,
    settingCreateLineItemWatcher,
    settingUpdateLineItemWatcher,
    settingsUpdateStatusLineItemsWatcher,
    settingsGetListTaxesWatcher,
    settingsCreateTaxWatcher,
    settingsUpdateTaxWatcher,
    settingsUpdateStatusTaxesWatcher,
    settingsGetDetailServiceTemplateWatcher,
    settingsUpdateDetailServiceTemplateWatcher,
    settingsUpdateServiceTemplateWatcher,
    settingsPreviewServiceTemplateWatcher,
    settingsGetListSourcesWatcher,
    settingsCreateSourceWatcher,
    settingsUpdateSourceWatcher,
    settingsUpdateStatusSourcesWatcher,
    settingsGetDetailCompanyWatcher,
    settingsGetListIndustry,
    settingsCreateAndUpdateCompanyWatcher,
    getListTemplateReminderWatcher,
    getListTemplateConfirmationWatcher,
    getListTemplateBroadcastWatcher,
    uploadLogoWatcher,
    deleteLogoWatcher,
    settingsGetListCustomsWatcher,
    settingCreateCustomWatcher,
    settingUpdateCustomWatcher,
    settingsUpdateStatusCustomsWatcher,
    settingsDetailCustomWatcher,
    settingsGetListBroadcastWatcher,
    settingCreateBroadcastWatcher,
    settingUpdateBroadcastWatcher,
    settingsUpdateStatusBroadcastWatcher,
    settingsDetailBroadcastWatcher,
    settingsGetListEmailInboxWatcher,
    settingUpdateStatusEmailInboxWatcher,
    getEmailInboxDetailWatcher,
    settingCreateEmailInboxWatcher,
    settingUpdateEmailInboxWatcher,
    settingsGetListSystemsWatcher,
    settingUpdateSystemsWatcher,
    settingsUpdateStatusSystemsWatcher,
    settingsDetailSystemsWatcher,
    settingGetListEmailVariableWatcher,
    settingSendTestEmailWatcher,
    settingGetListTimeZoneWatcher,
    settingsPipelinesWatcher,
    getListTemplateEmailInboxWatcher,
    settingsGetListConversationTagsWatcher
} from './settings';

import {
    dripGetListMessageTemplatesWatcher,
    dripGetDetailTemplatesWatcher,
    dripCreateMessageTemplateWatcher,
    dripUpdateMessageTemplateWatcher,
    dripSendTestEmailWatcher,
    dripDeleteMessageTemplateWatcher,
    dripDuplicateMessageTemplateWatcher,
    dripGetListVariableWatcher,
    dripGetListUnsubscribedWatcher,
    dripGetListSmartListsWatcher,
    dripDeleteItemSmartListsWatcher,
    dripDuplicateItemSmartListsWatcher,
    dripCreateSmartListsWatcher,
    dripUpdateSmartListsWatcher,
    dripGetListSmartListBasicInfoWatcher,
    dripUpdateStatusCustomerWatcher,
    dripGetListCustomerWatcher,
    dripGetDetailFiltersItemSmartListWatcher,
    dripGetListFiltersSmartListWatcher,
    dripGetListCampaignsWatcher,
    dripGetListCategoriesWatcher,
    dripUpdateStatusCampaignsWatcher,
    dripDeleteCampaignsWatcher,
    dripGetDetailCampaignWatcher,
    dripCreateNewCampaignWatcher,
    dripUpdateDetailCampaignWatcher,
    dripDuplicateCampaignWatcher,
    dripGetReportActivity,
    dripGetReportSummary,
    dripGetReportLink,
    dripGetReportDetail,
    dripGetListFilterOptionsByURLWatcher
} from './drip';
import { questlogWatcher } from './questlog';

import { getListLocationCityWatcher, getListLocationZipWatcher } from './location';

import { actionGetJobSummaryWatcher, actionUpdateStautsJobWatcher } from './job';

import {
    getCalendarListScheduleWatcher,
    getCalendarListJobWatcher,
    updateResizeTimeJobWatcher,
    updateMoveJobWatcher,
    updateMoveJobToWorkPoolWatcher,
    calendarGetListJobEventsWatcher,
    calendarBatchActionMoveJobsWatcher,
    calendarSendActionWatcher,
    getTotalBroadcastWatcher,
    calendarSendBroadcastWatcher
} from './calendar';

import {
    getDriveMatrixWatcher,
    updateDriveMatrixWatcher,
    getListEventRouteWatcher,
    optimizedEventsRouteWatcher,
    acceptNewRouteWatcher
} from './routeoptimizer';

import { actionGetListEmailTemplateWatcher } from './email';

import { actionGetListItemsWatcher } from './items';
import { actionGetListTaxesWatcher } from './taxes';
import { getListPhoneTypesWatcher } from './global';
import { actionGetListTimeWindowWatcher } from './timewindow';
import { schedulesListWatcher } from './schedulesList';
import { worklogWatcher } from './worklog';
import { smartViewsWatcher } from './smartviews';
import { customFieldsWatcher } from './customFields';

export default function* rootSaga() {
    yield all([
        appWatcher(),
        authWatcher(),
        inboxWatcher(),
        notificationWatcher(),
        searchWatcher(),
        questlogWatcher(),

        // companyUsers
        companyUsersWatcher(),
        companyUsersEditWatcher(),

        //smsv2
        smsConversationGetTotalWatcher(),
        smsGetListPhonesWatcher(),
        smsGetListByCustomerWatcher(),
        smsGetListVariableWatcher(),
        smsUpdateStatusWatcher(),

        // review
        reviewGetLikeylyWatcher(),
        reviewGetServiceWatcher(),
        reviewGetListUserFilterWatcher(),
        reviewGetListUserWatcher(),

        // report
        reportInvoiceSendMailWatcher(),
        reportInvoiceGeneratePdfWatcher(),
        reportEstimateSendMailWatcher(),

        reportInvoiceGetListWatcher(),
        reportInvoiceSearchWatcher(),
        reportInvoiceDeleteWatcher(),
        reportInvoiceArchiveWatcher(),
        reportInvoiceGetServiceWatcher(),

        getListReportEstimatesWatcher(),
        deleteReportEstimatesWatcher(),
        activeReportEstimatesWatcher(),
        archiveReportEstimatesWatcher(),
        unDeleteReportEstimatesWatcher(),
        trashReportEstimatesWatcher(),

        reportPaymentGetListWatcher(),
        reportPaymentSearchWatcher(),
        reportPaymentDeleteWatcher(),
        reportPaymentSyncQbWatcher(),
        reportPaymentGetListMethodWatcher(),

        reportDocumentGetListWatcher(),
        reportDocumentDeleteWatcher(),
        reportDocumentUndeleteWatcher(),
        reportDocumentTrashWatcher(),
        reportDocumentGetListTypeWatcher(),

        reportCreditGetListWatcher(),
        reportCreditDeleteWatcher(),

        reportRecurringInvoiceGetListWatcher(),

        reportSubscriptionGetListWatcher(),
        reportSubscriptionGetListFlansFilterWatcher(),

        reportServiceLookupGetListWatcher(),
        reportServiceLookupGetListScheduleWatcher(),
        reportServiceLookupGetListServiceTypeWatcher(),
        reportServiceLookReassignJobsupWatcher(),

        reportlocationsWoActiveJobsGetListWatcher(),

        reportCustomersWoActiveJobGetListWatcher(),

        reportAccountsAgingGetListWatcher(),
        reportAccountsAgingPrintInvoicesWatcher(),
        reportAccountsAgingGenerateInvoicesPdfWatcher(),
        reportAccountsAgingEmailLatePaymentRemindersWatcher(),
        reportAccountsAgingSmsLatePaymentRemindersWatcher(),
        reportAccountsAgingEmailStatementsWatcher(),

        reportSalesForecastGetListWatcher(),

        reportSalesTaxGetListWatcher(),
        reportTaxSummaryGetListWatcher(),
        reportFilterMethodTaxSummaryWatcher(),

        reportRevenueServiceGetListWatcher(),
        reportRevenueServicePaymentGetListWatcher(),
        reportRevenueServiceGetListMethodFilterWatcher(),
        reportRevenueServiceGetListServiceFilterWatcher(),

        reportRevenueClientGetListWatcher(),
        reportRevenueClientPaymentGetListWatcher(),
        reportRevenueGetListYearWatcher(),

        reportRevenueItemGetListWatcher(),
        reportRevenueItemPaymentGetListWatcher(),
        reportRevenueItemGetListMethodFilterWatcher(),
        reportRevenueItemGetListServiceFilterWatcher(),

        reportRevenueStaffGetListWatcher(),
        reportRevenueStaffPaymentGetListWatcher(),
        reportRevenueStaffGetListMethodFilterWatcher(),
        reportRevenueStaffGetListServiceFilterWatcher(),

        reportRevenueSourceGetListWatcher(),
        reportRevenueSourcePaymentGetListWatcher(),
        reportRevenueSourceGetListMethodFilterWatcher(),
        reportRevenueSourceGetListSourceFilterWatcher(),

        reportRevenueTagsGetListWatcher(),
        reportRevenueTagsPaymentGetListWatcher(),
        reportRevenueTagsGetListMethodFilterWatcher(),

        reportEmailHistoryGetListWatcher(),
        exportEmailHistoryWatcher(),

        reportMaterialUseGetListWatcher(),
        reportMaterialGetListWatcher(),
        reportMaterialUseListCountyWatcher(),
        reportMaterialUseGetListJobsWoCountyWatcher(),

        reportMaterialUseDetailGetListWatcher(),

        reportTotalSalesGetListWatcher(),
        reportTotalSalesGetListDataChartWatcher(),
        reportTotalSalesGetListYearWatcher(),

        reportPaymentsCollectedGetListWatcher(),
        reportPaymentsCollectedGetListDataChartWatcher(),
        reportPaymentsCollectedGetListYearsWatcher(),
        reportPaymentsCollectedGetListStaffsFilterWatcher(),
        reportPaymentsCollectedGetListMethodFilterWatcher(),

        reportNewCustomersGetListWatcher(),
        reportNewCustomersGetListDataChartWatcher(),
        reportNewCustomersGetListYearWatcher(),
        reportNewCustomersGetListSourceFilterWatcher(),

        // commission
        commissionGetListDataWatcher(),
        commissionGetListUserWatcher(),
        commissionGetSettingUserWatcher(),
        commissionApplySettingFromAnotherUserWatcher(),
        commissionUpdateSettingUserWatcher(),
        commissionGetListStaffsFilterWatcher(),
        commissionGetListMethodFilterWatcher(),
        commissionGetListItemsWatcher(),
        commissionGetListUsersWatcher(),

        //// customer
        getListSearchCustomerWatcher(),
        getListCustomerWatcher(),
        customerMarkAsWatcher(),
        updateStatusCustomerWatcher(),
        getStatisticCustomerWatcher(),
        getCustomerAccountNumberWatcher(),
        getListSettingCompanyPhoneTypeWatcher(),
        getListCustomerSourceWatcher(),
        getListCustomersearchTagsWatcher(),

        //// lead
        reportCustomerLeadsGetListWatcher(),
        reportBookingJobsGetListWatcher(),
        reportOnlineBookingsChartWatcher(),
        reportInboundLeadsWatcher(),
        reportGetYearInboundLeadsWatcher(),
        reportGetYearOnlineBookingsChartWatcher(),

        //// settings
        settingsGetListServiceWatcher(),
        settingsGetListServicePlanWatcher(),
        settingsGetListSimplePaymentMethodsWatcher(),
        settingsGetListPaymentMethodsWatcher(),
        settingsCreatePaymentMethodsWatcher(),
        settingsUpdatePaymentMethodsWatcher(),
        settingsUpdateStatusPaymentMethodsWatcher(),

        settingsGetListSimpleTagsWatcher(),
        settingsGetListConversationTagsWatcher(),
        settingsGetListSettingWatcher(),
        settingsCreateTagsWatcher(),
        settingsUpdateTagWatcher(),
        settingsDeleteTagsWatcher(),

        settingsGetListTilesWatcher(),
        settingsGetOptionsTilesWatcher(),
        settingsUpdateListTilesWatcher(),

        settingsGetListSchedulesWatcher(),
        settingsReassignSchedulesWatcher(),
        settingsAddAndUpdateSchedulesWatcher(),
        settingsDeleteSchedulesWatcher(),

        getListUsersSettingsWatcher(),
        deleteUserSettingsWatcher(),
        settingsCreateUserWatcher(),
        settingsUpdateUserWatcher(),
        getUserDetailWatcher(),

        // Line Items
        settingsGetListLineItemsWatcher(),
        settingCreateLineItemWatcher(),
        settingUpdateLineItemWatcher(),
        settingsUpdateStatusLineItemsWatcher(),

        // Taxes
        settingsGetListTaxesWatcher(),
        settingsCreateTaxWatcher(),
        settingsUpdateTaxWatcher(),
        settingsUpdateStatusTaxesWatcher(),

        // Template
        settingsGetDetailServiceTemplateWatcher(),
        settingsUpdateDetailServiceTemplateWatcher(),
        settingsUpdateServiceTemplateWatcher(),
        settingsPreviewServiceTemplateWatcher(),
        getListTemplateReminderWatcher(),
        getListTemplateConfirmationWatcher(),
        getListTemplateBroadcastWatcher(),
        getListTemplateEmailInboxWatcher(),

        // Source
        settingsGetListSourcesWatcher(),
        settingsCreateSourceWatcher(),
        settingsUpdateSourceWatcher(),
        settingsUpdateStatusSourcesWatcher(),

        //Company
        settingsGetDetailCompanyWatcher(),
        settingsGetListIndustry(),
        settingsCreateAndUpdateCompanyWatcher(),
        uploadLogoWatcher(),
        deleteLogoWatcher(),

        // Customs
        settingsGetListCustomsWatcher(),
        settingCreateCustomWatcher(),
        settingUpdateCustomWatcher(),
        settingsUpdateStatusCustomsWatcher(),
        settingsDetailCustomWatcher(),

        //Broadcast
        settingsGetListBroadcastWatcher(),
        settingCreateBroadcastWatcher(),
        settingUpdateBroadcastWatcher(),
        settingsUpdateStatusBroadcastWatcher(),
        settingsDetailBroadcastWatcher(),

        //Email Inbox
        settingsGetListEmailInboxWatcher(),
        settingUpdateStatusEmailInboxWatcher(),
        getEmailInboxDetailWatcher(),
        settingCreateEmailInboxWatcher(),
        settingUpdateEmailInboxWatcher(),

        //Systems
        settingsGetListSystemsWatcher(),
        settingUpdateSystemsWatcher(),
        settingsUpdateStatusSystemsWatcher(),
        settingsDetailSystemsWatcher(),

        // settings
        //// common
        settingGetListEmailVariableWatcher(),
        settingSendTestEmailWatcher(),
        settingGetListTimeZoneWatcher(),

        // drip
        //// message template
        dripGetListMessageTemplatesWatcher(),
        dripGetDetailTemplatesWatcher(),
        dripCreateMessageTemplateWatcher(),
        dripUpdateMessageTemplateWatcher(),
        dripSendTestEmailWatcher(),
        dripDeleteMessageTemplateWatcher(),
        dripDuplicateMessageTemplateWatcher(),
        dripGetListVariableWatcher(),

        //// Unsubscribed
        dripGetListUnsubscribedWatcher(),

        //// Smart lists
        dripGetListSmartListsWatcher(),
        dripDeleteItemSmartListsWatcher(),
        dripDuplicateItemSmartListsWatcher(),
        dripCreateSmartListsWatcher(),
        dripUpdateSmartListsWatcher(),
        dripGetListSmartListBasicInfoWatcher(),

        dripUpdateStatusCustomerWatcher(),
        dripGetListCustomerWatcher(),
        dripGetDetailFiltersItemSmartListWatcher(),

        dripGetListFiltersSmartListWatcher(),
        dripGetListFilterOptionsByURLWatcher(),

        dripGetListCampaignsWatcher(),
        dripGetListCategoriesWatcher(),
        dripUpdateStatusCampaignsWatcher(),
        dripDeleteCampaignsWatcher(),
        dripGetDetailCampaignWatcher(),
        dripCreateNewCampaignWatcher(),
        dripUpdateDetailCampaignWatcher(),
        dripDuplicateCampaignWatcher(),

        dripGetReportActivity(),
        dripGetReportSummary(),
        dripGetReportLink(),
        dripGetReportDetail(),

        // Customers
        // Credits
        updateStatusCustomerDetailCreditWatcher(),

        // Customers
        // Contacts
        getListContactWatcher(),
        createContactWatcher(),
        updateContactWatcher(),
        deleteContactWatcher(),
        getCustomerInformationWatcher(),

        // Customers

        // Customers
        //// Locations
        getListLocationWatcher(),
        createLocationWatcher(),
        updateLocationWatcher(),

        // Customers
        // Statement
        getStatementSummaryWatcher(),
        getStatementHistoriesWatcher(),
        statementToPDFWatcher(),
        statementSendByEmailWatcher(),

        // Customers

        //Customer report
        getListCustomerReportWatcher(),

        //Customer job
        getCustomerJobsWatcher(),

        //Customer tag
        getListCustomerTagHistoryWatcher(),

        //Customer work pool
        getListJobWorkPoolWatcher(),

        //Customer list tasks
        getCustomerListTaskWatcher(),
        updateTaskStatusWatcher(),

        //Location
        getListLocationCityWatcher(),
        getListLocationZipWatcher(),

        getCalendarListScheduleWatcher(),
        getCalendarListJobWatcher(),
        updateResizeTimeJobWatcher(),
        updateMoveJobWatcher(),
        updateMoveJobToWorkPoolWatcher(),
        calendarGetListJobEventsWatcher(),
        calendarBatchActionMoveJobsWatcher(),
        actionGetJobSummaryWatcher(),
        actionUpdateStautsJobWatcher(),
        calendarGetListCustomerSendingWatcher(),
        calendarSendActionWatcher(),

        getDriveMatrixWatcher(),
        updateDriveMatrixWatcher(),
        getListEventRouteWatcher(),
        optimizedEventsRouteWatcher(),
        acceptNewRouteWatcher(),
        actionGetListItemsWatcher(),
        actionGetListTaxesWatcher(),
        getTotalBroadcastWatcher(),
        calendarSendBroadcastWatcher(),

        //Global Store
        getListPhoneTypesWatcher(),
        actionGetListEmailTemplateWatcher(),
        actionGetListTimeWindowWatcher(),

        //PipeLines
        settingsPipelinesWatcher(),

        // SchedulesList
        schedulesListWatcher(),
        worklogWatcher(),
        // smart views watcher
        smartViewsWatcher(),
        // Custom Fields
        customFieldsWatcher()
    ]);
}

export default function IconPhone({
    isBlack = false,
    isMissing = false,
    isClose = false,
    isCallIn = false,
    isCallOut = false,
    isGreen = false,
    isSmallGreen = false,
    isHangUp = false,
    isHoldCall = false
}) {
    if (isBlack)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.82 14.9402C16.8477 14.1652 15.861 13.6957 14.9006 14.4696L14.3272 14.9373C13.9076 15.2768 13.1276 16.8632 10.1115 13.6296C7.0961 10.4001 8.89053 9.89723 9.31071 9.56064L9.88729 9.09234C10.8426 8.31672 10.4821 7.34032 9.79308 6.33523L9.37729 5.72644C8.68514 4.7237 7.93145 4.06515 6.97362 4.8396L6.45608 5.26107C6.03275 5.54849 4.84944 6.48275 4.56241 8.2576C4.21697 10.3872 5.30669 12.8259 7.80332 15.5016C10.2968 18.1785 12.7382 19.5231 15.0508 19.4997C16.9727 19.4804 18.1334 18.5192 18.4964 18.1697L19.0158 17.7477C19.9712 16.9738 19.3776 16.1859 18.4047 15.4091L17.82 14.9402Z"
                    fill="var(--color-icon)"
                />
            </svg>
        );
    if (isMissing) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 5L15 10" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15 5L20 10" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.82 14.9402C16.8477 14.1652 15.861 13.6957 14.9006 14.4696L14.3272 14.9373C13.9077 15.2768 13.1276 16.8632 10.1115 13.6296C7.0961 10.4001 8.89053 9.89723 9.31071 9.56064L9.88729 9.09234C10.8426 8.31672 10.4821 7.34032 9.79308 6.33523L9.37729 5.72644C8.68514 4.7237 7.93145 4.06515 6.97362 4.8396L6.45608 5.26107C6.03275 5.54849 4.84944 6.48275 4.56241 8.2576C4.21697 10.3872 5.30669 12.8259 7.80332 15.5016C10.2968 18.1785 12.7382 19.5231 15.0508 19.4997C16.9727 19.4804 18.1334 18.5192 18.4964 18.1697L19.0158 17.7477C19.9712 16.9738 19.3776 16.1859 18.4047 15.4091L17.82 14.9402Z"
                    stroke="white"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
    if (isClose) {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.87032 12.2232C7.10104 12.0463 8.11914 11.6494 8.21369 10.4197L8.26606 9.68157C8.30633 9.14335 7.68564 7.48811 12.1074 7.50765C16.5258 7.52485 15.6623 9.17625 15.6195 9.71292L15.5654 10.4537C15.4755 11.681 16.4337 12.0876 17.6378 12.2746L18.3661 12.389C19.5707 12.5721 20.5668 12.4745 20.6592 11.2463L20.707 10.5805C20.7877 10.0753 20.5959 8.50722 19.5 7.08187C18.1859 5.37088 15.9855 4.5593 12.326 4.54378C8.66768 4.52518 6.01539 5.38165 4.4476 7.08187C3.14456 8.49477 2.99429 9.99584 3.00001 10.4997L3.00624 11.1701C2.91513 12.3962 3.89569 12.5039 5.12812 12.3277L5.87032 12.2232Z"
                    stroke="white"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M10 20L14 16"
                    stroke="white"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M14 20L10 16"
                    stroke="white"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </svg>
        );
    }
    if (isCallIn) {
        return (
            <svg width={24} height={24} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.82 15.9402C16.8477 15.1652 15.861 14.6957 14.9006 15.4696L14.3272 15.9373C13.9077 16.2768 13.1276 17.8632 10.1115 14.6296C7.0961 11.4001 8.89053 10.8972 9.31071 10.5606L9.88729 10.0923C10.8426 9.31672 10.4821 8.34032 9.79308 7.33523L9.37729 6.72644C8.68514 5.7237 7.93145 5.06515 6.97362 5.8396L6.45608 6.26107C6.03275 6.54849 4.84944 7.48275 4.56241 9.2576C4.21697 11.3872 5.30669 13.8259 7.80332 16.5016C10.2968 19.1785 12.7382 20.5231 15.0508 20.4997C16.9727 20.4804 18.1334 19.5192 18.4964 19.1697L19.0158 18.7477C19.9712 17.9738 19.3776 17.1859 18.4047 16.4091L17.82 15.9402Z"
                    stroke="#1EAA5C"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M17 11H22V6" stroke="#1EAA5C" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15 4L22 11" stroke="#1EAA5C" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }
    if (isCallOut) {
        return (
            <svg width={24} height={24} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.82 15.9402C16.8477 15.1652 15.861 14.6957 14.9006 15.4696L14.3272 15.9373C13.9077 16.2768 13.1276 17.8632 10.1115 14.6296C7.0961 11.4001 8.89053 10.8972 9.31071 10.5606L9.88729 10.0923C10.8426 9.31672 10.4821 8.34032 9.79308 7.33523L9.37729 6.72644C8.68514 5.7237 7.93145 5.06515 6.97362 5.8396L6.45608 6.26107C6.03275 6.54849 4.84944 7.48275 4.56241 9.2576C4.21697 11.3872 5.30669 13.8259 7.80332 16.5016C10.2968 19.1785 12.7382 20.5231 15.0508 20.4997C16.9727 20.4804 18.1334 19.5192 18.4964 19.1697L19.0158 18.7477C19.9712 17.9738 19.3776 17.1859 18.4047 16.4091L17.82 15.9402Z"
                    stroke="#1EAA5C"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M16 8V3H21" stroke="#1EAA5C" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16 3L23 10" stroke="#1EAA5C" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }

    if (isGreen)
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                <path />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.984 15.5283C17.8172 14.5982 16.6332 14.0349 15.4808 14.9635L14.7927 15.5248C14.2892 15.9322 13.3531 17.8358 9.73382 13.9555C6.11532 10.0801 8.26863 9.47668 8.77286 9.07277L9.46475 8.51081C10.6111 7.58007 10.1785 6.40838 9.3517 5.20228L8.85275 4.47173C8.02217 3.26844 7.11773 2.47818 5.96834 3.40752L5.3473 3.91328C4.8393 4.25819 3.41933 5.3793 3.07489 7.50912C2.66036 10.0646 3.96803 12.991 6.96398 16.2019C9.95617 19.4142 12.8858 21.0277 15.6609 20.9996C17.9672 20.9765 19.3601 19.823 19.7957 19.4037L20.419 18.8972C21.5654 17.9686 20.8531 17.0231 19.6857 16.0909L18.984 15.5283Z"
                    fill="#1EAA5C"
                />
            </svg>
        );

    if (isSmallGreen)
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32" fill="none">
                <rect width="32" height="32" rx="12" fill="#FFF" />
                <path
                    d="M21.82 18.9402C20.8477 18.1652 19.861 17.6957 18.9006 18.4696L18.3272 18.9373C17.9077 19.2768 17.1276 20.8632 14.1115 17.6296C11.0961 14.4001 12.8905 13.8972 13.3107 13.5606L13.8873 13.0923C14.8426 12.3167 14.4821 11.3403 13.7931 10.3352L13.3773 9.72644C12.6851 8.7237 11.9314 8.06515 10.9736 8.8396L10.4561 9.26107C10.0328 9.54849 8.84944 10.4827 8.56241 12.2576C8.21697 14.3872 9.30669 16.8259 11.8033 19.5016C14.2968 22.1785 16.7382 23.5231 19.0508 23.4997C20.9727 23.4804 22.1334 22.5192 22.4964 22.1697L23.0158 21.7477C23.9712 20.9738 23.3776 20.1859 22.4047 19.4091L21.82 18.9402Z"
                    stroke="#2CA01C"
                    fill="#2CA01C"
                    strokeWidth="1.5"
                />
            </svg>
        );

    if (isHangUp)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_449_32616)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.87032 12.2232C7.10104 12.0463 8.11914 11.6494 8.21369 10.4197L8.26606 9.68157C8.30633 9.14335 7.68564 7.48811 12.1074 7.50765C16.5258 7.52485 15.6623 9.17625 15.6195 9.71292L15.5654 10.4537C15.4755 11.681 16.4337 12.0876 17.6378 12.2746L18.3661 12.389C19.5707 12.5721 20.5668 12.4745 20.6592 11.2463L20.707 10.5805C20.7877 10.0753 20.5959 8.50722 19.5 7.08187C18.1859 5.37088 15.9855 4.5593 12.326 4.54378C8.66768 4.52518 6.01539 5.38165 4.4476 7.08187C3.14456 8.49477 2.99429 9.99584 3.00001 10.4997L3.00624 11.1701C2.91513 12.3962 3.89569 12.5039 5.12812 12.3277L5.87032 12.2232Z"
                        stroke="white"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M10 20L14 16"
                        stroke="white"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M14 20L10 16"
                        stroke="white"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_449_32616">
                        <rect width={24} height={24} fill="white" />
                    </clipPath>
                </defs>
            </svg>
        );

    if (isHoldCall)
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.6808 15.0003C16.7085 14.2252 15.7218 13.7558 14.7615 14.5296L14.188 14.9974C13.7685 15.3369 12.9884 16.9232 9.97235 13.6896C6.95692 10.4601 8.75136 9.95729 9.17154 9.6207L9.74812 9.1524C10.7034 8.37678 10.3429 7.40038 9.65391 6.39529L9.23812 5.7865C8.54597 4.78376 7.79227 4.12521 6.83445 4.89966L6.31691 5.32113C5.89358 5.60855 4.88315 6.82289 4.59611 8.59774C4.25067 10.7273 5.16752 12.8859 7.66415 15.5617C10.1576 18.2386 12.599 19.5832 14.9116 19.5598C16.8335 19.5404 18.0297 18.6212 18.3927 18.2717L18.8767 17.8077C19.832 17.0339 19.2384 16.246 18.2655 15.4692L17.6808 15.0003Z"
                    stroke="white"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.4596 3.54048C17.2058 3.28664 16.7942 3.28664 16.5404 3.54048C16.2865 3.79432 16.2865 4.20588 16.5404 4.45972L18.4307 6.3501H14C13.641 6.3501 13.35 6.64111 13.35 7.0001C13.35 7.35908 13.641 7.6501 14 7.6501H18.4307L16.5404 9.54048C16.2865 9.79432 16.2865 10.2059 16.5404 10.4597C16.7942 10.7136 17.2058 10.7136 17.4596 10.4597L20.4591 7.46019L20.4596 7.45972M17.4596 3.54048L20.4591 6.54L17.4596 3.54048ZM20.4596 6.54048C20.461 6.54189 20.4624 6.54331 20.4638 6.54474L20.4596 6.54048ZM20.4638 6.54474C20.5217 6.60373 20.5685 6.67372 20.6007 6.75129C20.5697 6.67637 20.524 6.60606 20.4638 6.54474Z"
                    fill="white"
                />
            </svg>
        );

    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.82 14.9402C16.8477 14.1652 15.861 13.6957 14.9006 14.4696L14.3272 14.9373C13.9077 15.2768 13.1276 16.8632 10.1115 13.6296C7.0961 10.4001 8.89053 9.89723 9.31071 9.56064L9.88729 9.09234C10.8426 8.31672 10.4821 7.34032 9.79308 6.33523L9.37729 5.72644C8.68514 4.7237 7.93145 4.06515 6.97362 4.8396L6.45608 5.26107C6.03275 5.54849 4.84944 6.48275 4.56241 8.2576C4.21697 10.3872 5.30669 12.8259 7.80332 15.5016C10.2968 18.1785 12.7382 19.5231 15.0508 19.4997C16.9727 19.4804 18.1334 18.5192 18.4964 18.1697L19.0158 17.7477C19.9712 16.9738 19.3776 16.1859 18.4047 15.4091L17.82 14.9402Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

import React from 'react';

const LoadingSummary = () => {
    return (
        <div className="rows row-summary pt-4">
            <div className="txt">
                <div className="loading --animation --line --half"></div>
            </div>
            <div className="details flex-column gap-10">
                <div className="loading --animation --line --half"></div>
                <div className="loading --animation --line --threefourth"></div>
                <div className="loading --animation --line --threefourth"></div>
            </div>
        </div>
    );
};

export default LoadingSummary;

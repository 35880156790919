import ButtonSave from 'app/components/button/ButtonSave';
import GDModalWarning from 'app/components/modal/ModalWarning';
import { UPDATE_ADDONS_EMAIL_ACCOUNT, UPDATE_ADDONS_INBOX_EMAIL_SETTINGS } from 'app/const/Api';
import { LIST_STATUS } from 'app/const/Status';
import IconClose from 'assets/icon/IconClose';
import IconEdit from 'assets/icon/IconEdit';
import IconTrash from 'assets/icon/IconTrash';
import classNames from 'classnames';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import AddonsAlert from '../../components/AddonsAlert';
import { reducer } from 'app/const/Reducer';

const EditFormModal = forwardRef(({ isImap = false, onDeleteSuccess = () => {}, onUpdateSuccess = () => {} }, ref) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, email: '', selectedName: '', options: [] });
    const refButtonSave = useRef(null);
    const refForm = useRef(null);
    const refAlert = useRef(null);
    const refWarning = useRef(null);
    const { isOpen, host, username, encryption, isEnable, email, name } = state;
    const typeUpdate = isImap ? 'imap' : 'smtp';

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = (dataUpdate) => {
        dispatchState({ isOpen: true, ...dataUpdate });
    };

    const _close = () => {
        dispatchState({ isOpen: false, email: '', selectedName: '', options: [] });
    };

    const _handleSaveForm = () => {
        const data = {};
        const dataStatus = {};
        const elements = refForm.current.elements;

        for (let index = 0; index < elements.length; index++) {
            const { name, value, type, checked } = elements[index];
            if (type === 'text' || type === 'password') data[name] = value;
            if (type === 'checkbox' && name === 'active') dataStatus[isImap ? 'sync' : 'send'] = checked ? 1 : 0;
            if (type === 'checkbox') data[name] = checked ? 1 : 0;
        }

        const _handleUpdateSuccess = ({ data }) => {
            onUpdateSuccess(data[typeUpdate], typeUpdate);
            _close();
        };
        const _handleUpdateFailed = ({ message }) => {
            refAlert.current.showStatusBar({ id: 'edit_failed', message, type: LIST_STATUS.ERROR });
        };
        const _handleUpdateFinally = () => refButtonSave.current.removeLoading();

        clientQuery(
            UPDATE_ADDONS_EMAIL_ACCOUNT,
            {
                data: isImap ? { email, name, imap: data, ...dataStatus } : { email, name, smtp: data, ...dataStatus },
                toFormData: false,
                method: 'PUT'
            },
            _handleUpdateSuccess,
            _handleUpdateFailed,
            _handleUpdateFinally
        );
    };

    const _handleChangeActive = (e) => {
        if (!username) return;
        const data = {};
        const value = e.target.checked ? 1 : 0;
        if (!isImap) data['send'] = value;
        if (isImap) data['sync'] = value;

        const _handleSuccess = () => onUpdateSuccess({ active: value, status: value }, typeUpdate);

        clientQuery(
            UPDATE_ADDONS_INBOX_EMAIL_SETTINGS,
            {
                data,
                toFormData: false,
                method: 'PUT'
            },
            _handleSuccess
        );
    };

    const _handleDelete = () => {
        refWarning.current._open({ description: t('addons:are_you_sure_you_want_to_delete_this_account') });
    };

    const _handleCloseConfirm = () => refWarning.current._close();

    const _handleConfirmDelete = () => {
        const _handleDeleteSuccess = ({ message }) => {
            _handleCloseConfirm();
            _close();
            onDeleteSuccess(isImap ? 'imap' : 'smtp', message);
        };
        clientQuery(
            UPDATE_ADDONS_EMAIL_ACCOUNT,
            { data: { type: isImap ? 2 : 1 }, method: 'DELETE' },
            _handleDeleteSuccess
        );
    };

    if (!isOpen) return null;
    return (
        <>
            <ReactModal
                id="edit_smtp"
                isOpen={isOpen}
                style={{ overlay: { background: 'transparent' } }}
                className="modal container-modal --edit-smtp open"
                onRequestClose={_close}
            >
                <div className="modal__overlay bg-fixed" onClick={_close} />
                <div className="modal__container --md">
                    <div className="header-modal btn-close">
                        <h3 className="header-modal__label">{t(`setting:${isImap ? 'edit_imap' : 'edit_smtp'}`)}</h3>
                        <span className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                            <IconClose />
                        </span>
                    </div>
                    <AddonsAlert ref={refAlert} />
                    <form ref={refForm} className="body-modal scrolls">
                        <div className="row">
                            <div className="check-items">
                                <input
                                    name="active"
                                    id="check_edit_smtp"
                                    type="checkbox"
                                    onChange={_handleChangeActive}
                                    defaultChecked={isEnable}
                                />
                                <div className="item-checkbox">
                                    <label htmlFor="check_edit_smtp">
                                        {t('addons:enable_email_sending_for_this_account')}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="txt">{t(`addons:${isImap ? 'imap_host' : 'smtp_host'}`)}</div>
                            <input
                                name="host"
                                className="field-input"
                                placeholder={t('addons:host')}
                                type="text"
                                defaultValue={host}
                            />
                        </div>
                        <div className="row">
                            <div className="txt">{t(`addons:${isImap ? 'imap_username' : 'smtp_username'}`)}</div>
                            <input
                                name="user"
                                className="field-input"
                                placeholder={t('common:user_name')}
                                type="text"
                                defaultValue={username}
                            />
                        </div>
                        <div className="row">
                            <div className="txt">{t(`addons:${isImap ? 'imap_password' : 'smtp_password'}`)}</div>
                            <input
                                name="password"
                                className="field-input"
                                placeholder={t('common:password')}
                                type="password"
                            />
                        </div>
                        <div className="row">
                            <div className={classNames('check-items', { 'is-disable': isImap })}>
                                <input
                                    name="secure"
                                    id="check_edit_stp"
                                    type="checkbox"
                                    defaultChecked={!!encryption || isImap}
                                />
                                <div className="item-checkbox">
                                    <label htmlFor="check_edit_stp">
                                        {t(`addons:${isImap ? 'imap_secure' : 'smtp_secure'}`)}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="footer-modal btn-close justify-space-between">
                        <div className="v2-btn-default --delete has-icon ml-n2" onClick={_handleDelete}>
                            <IconTrash />
                            {t('delete')}
                        </div>
                        <div className="flex-centeritem">
                            <span className="v2-btn-default --transparent" onClick={_close}>
                                {t('cancel')}
                            </span>
                            <ButtonSave ref={refButtonSave} wrapClass="v2-btn-main fs-14" onSave={_handleSaveForm} />
                        </div>
                    </div>
                </div>
            </ReactModal>
            <GDModalWarning
                ref={refWarning}
                title={t('addons:delete_account')}
                modalClassName="modal container-modal modal-confirm-delete open"
                headerClassName="header-modal"
                bodyClassName="body-modal"
                descriptionClassName="text"
                isLargeContent={false}
                isDisplayClose={false}
                footer={
                    <div className="footer-modal footer-hasbtn btn-close">
                        <span className="v2-btn-default --transparent" onClick={() => refWarning.current._close()}>
                            {t('cancel')}
                        </span>
                        <ButtonSave
                            ref={refButtonSave}
                            title={t('common:confirm')}
                            className="v2-btn-main"
                            onSave={_handleConfirmDelete}
                        />
                    </div>
                }
            />
        </>
    );
});

const EditSMTP = ({
    isImap = false,
    host = '',
    username = '',
    encryption = '',
    email,
    name,
    isEnable = 0,
    ...props
}) => {
    const { t } = useTranslation();
    const refModal = useRef(null);

    const _handleEdit = () => {
        refModal.current._open({ host, username, encryption, isEnable, email, name });
    };

    return (
        <>
            <div className="v2-btn-default svg-purple --purple --icon-r btn-modal ml-1" onClick={_handleEdit}>
                {t(`setting:${isImap ? 'edit_imap' : 'edit_smtp'}`)} <IconEdit />
            </div>
            <EditFormModal ref={refModal} isImap={isImap} {...props} />
        </>
    );
};

export default EditSMTP;

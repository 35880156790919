import { reducer } from 'app/const/Reducer';
import MessageTabs from 'app/modules/calendar/job/components/messaging/MessageTabs';
import IconClose from 'assets/icon/IconClose';
import classNames from 'classnames';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import { AddCustomerContext } from '../../contexts';
import PreferencesContact from './PreferencesContact';
import { useTranslation } from 'react-i18next';

const Preferences = (props, ref) => {
    const { t } = useTranslation(['customers']);
    const { profile, additional_contacts } = useContext(AddCustomerContext);
    const activateSms = useSelector(({ auth }) => auth.user?.settings?.addons?.activate_sms || false);
    const defaultCheckPreference = activateSms ? 3 : 2;
    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        listSMS: [],
        listEmail: [],
        selectedEmail: [],
        selectedSMS: [],
        appointment_confirmations: defaultCheckPreference,
        appointment_reminders: defaultCheckPreference,
        appointment_followups: defaultCheckPreference,
        broadcasts: defaultCheckPreference,
        late_payment_reminders: defaultCheckPreference
    });

    const refCustomerInfo = useRef(null);
    const refSelected = useRef({ email_to: [], sms_to: [] });
    const { isOpen, listSMS, listEmail, selectedEmail, selectedSMS } = state;

    useImperativeHandle(ref, () => ({ _open, _getValue }));

    useEffect(() => {
        dispatchState({ ..._handleGetPreferences() });
    }, [profile, additional_contacts]);

    const _open = () => {
        dispatchState({ isOpen: true, ..._handleGetPreferences() });
    };

    const _handleGetPreferences = () => {
        const result = { listEmail: [], selectedEmail: [], listSMS: [], selectedSMS: [] };
        const { email_to, sms_to } = refSelected.current;
        const idSMSSelected = !activateSms ? [] : sms_to.map((item) => item.id);

        // Handle profile email
        if (profile.email) {
            const newEmail = { id: `${new Date().getTime()}`, email: profile.email, isFromProfile: true };

            if (email_to.length) {
                const indexUpdate = email_to.findIndex((item) => item.isFromProfile);

                if (indexUpdate >= 0) {
                    const emailUpdated = { ...email_to[indexUpdate], email: profile.email };
                    result['selectedEmail'].push(emailUpdated);
                    result['listEmail'].push(emailUpdated);
                } else {
                    result['listEmail'].push(newEmail);
                }
            } else {
                result['listEmail'] = [newEmail];
                result['selectedEmail'] = [newEmail];
            }
        }

        // Handle profile phones
        if (profile.phones.length && activateSms) {
            if (sms_to.length) {
                for (let index = 0; index < profile.phones.length; index++) {
                    const element = profile.phones[index];
                    const isSelected = Boolean(sms_to.find((item) => item.id === element.id));

                    if (isSelected) result['selectedSMS'].push(element);
                }
            } else {
                result['selectedSMS'].push(profile.phones[0]);
            }

            result['listSMS'] = [...profile.phones];
        }

        // Handle from additional contact
        additional_contacts.forEach((item) => {
            const indexUpdateEmail = !!email_to.find((el) => el.id === item.id);

            if (indexUpdateEmail) result['selectedEmail'].push(item);
            if (item.email) result['listEmail'].push({ id: item.id, email: item.email });

            if (activateSms) {
                const hasSMSSelected = item.phones.find((item) => idSMSSelected.includes(item.id));
                if (hasSMSSelected) result['selectedSMS'] = [...result['selectedSMS'], ...item.phones];
                if (item.phones.length) result['listSMS'] = [...result['listSMS'], ...item.phones];
            }
        });

        refSelected.current = { sms_to: result['selectedSMS'] || [], email_to: result['selectedEmail'] || [] };
        return result;
    };

    const _close = () => {
        dispatchState({ isOpen: false });
    };

    const _handleSave = () => {
        if (refCustomerInfo.current) refSelected.current = refCustomerInfo.current._getValue();
        dispatchState({ ...state, ..._getValueSave(), isOpen: false });
    };

    const _getValue = () => {
        const result = {};
        const { sms_to, email_to } = refSelected.current;

        if (activateSms)
            result['sms_to'] = sms_to.map((item) => ({ id: item.id, phone: item.phone, type: 'customer' }));
        result['email_to'] = email_to.map((item) => ({ id: item.id, email: item.email, type: 'customer' }));
        result['appointment_confirmations'] = state['appointment_confirmations'];
        result['appointment_reminders'] = state['appointment_reminders'];
        result['appointment_followups'] = state['appointment_followups'];
        result['broadcasts'] = state['broadcasts'];
        result['late_payment_reminders'] = state['late_payment_reminders'];

        return result;
    };

    const _getValueSave = () => {
        const valueCustomer = refCustomerInfo.current?._getValue() || {};
        const confirmations = document.getElementById('appointment_confirmations').getAttribute('data-value-selected');
        const reminders = document.getElementById('appointment_reminders').getAttribute('data-value-selected');
        const followups = document.getElementById('appointment_followups').getAttribute('data-value-selected');
        const broadcasts = document.getElementById('broadcasts').getAttribute('data-value-selected');
        const payment = document.getElementById('late_payment_reminders').getAttribute('data-value-selected');

        return {
            appointment_confirmations: parseInt(confirmations),
            appointment_reminders: parseInt(reminders),
            appointment_followups: parseInt(followups),
            broadcasts: parseInt(broadcasts),
            late_payment_reminders: parseInt(payment),
            ...valueCustomer
        };
    };

    return (
        <div
            id="modal_messaging"
            className={classNames('modal container-modal messaging-preferences --wrap --has-detail', { open: isOpen })}
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className="modal__container --center">
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('customers:messaging_preferences')}</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_close} tabIndex="0">
                        <IconClose />
                    </div>
                </div>
                <div className="body-modal scrolls">
                    <PreferencesContact
                        ref={refCustomerInfo}
                        listSMS={listSMS}
                        listEmail={listEmail}
                        selectedEmail={selectedEmail}
                        selectedSMS={selectedSMS}
                    />
                    {isOpen && (
                        <div className="box-preferences tabs">
                            <span className="txt-appointment">{t('customers:appointment')}</span>
                            <div className="lines">
                                <span className="txt">{t('customers:confirmations')}</span>
                                <MessageTabs
                                    value={state.appointment_confirmations}
                                    type="appointment_confirmations"
                                    activateSms={activateSms}
                                />
                            </div>
                            <div className="lines">
                                <span className="txt">{t('customers:reminders')}</span>
                                <MessageTabs
                                    value={state.appointment_reminders}
                                    type="appointment_reminders"
                                    activateSms={activateSms}
                                />
                            </div>
                            <div className="lines">
                                <span className="txt">{t('customers:followups')}</span>
                                <MessageTabs
                                    value={state.appointment_followups}
                                    type="appointment_followups"
                                    activateSms={activateSms}
                                />
                            </div>
                            <div className="lines line-border" />
                            <div className="lines">
                                <span className="txt">{t('customers:broadcasts')}</span>
                                <MessageTabs value={state.broadcasts} type="broadcasts" activateSms={activateSms} />
                            </div>
                            <div className="lines">
                                <span className="txt text-left" title={t('customers:late_payment_reminders')}>{t('customers:late_payment_reminders')}</span>
                                <MessageTabs
                                    value={state.late_payment_reminders}
                                    type="late_payment_reminders"
                                    activateSms={activateSms}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className="footer-modal btn-close justify-end">
                    <span className="v2-btn-default --noborder --label" onClick={_close} tabIndex="0">
                        {t('customers:cancel')}
                    </span>
                    <div className="v2-btn-main" onClick={_handleSave} tabIndex="0">
                        {t('customers:save')}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default forwardRef(Preferences);

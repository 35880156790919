import { useReducer, useEffect, useRef } from 'react';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { reducer } from 'app/const/Reducer';
import IconCircleClose from 'assets/icon/IconCircleClose';
import classNames from 'classnames';
import PinToTop from 'app/modules/jobdetail/tabs/materials/components/PinToTop';
import { handleStickyMaterial } from 'common/utils/FunctionUtils';
import { useTranslation } from 'react-i18next';

const Select = ({
    name,
    classWrapper,
    options,
    selected,
    onSelect,
    isInput = false,
    onlyNumber = false,
    isPinToTop = false,
    onAddSticky = () => {},
    isSelectName = false,
    isAllowOnInput = false
}) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        dropDownVisible: false,
        data: options
    });
    const refDropDown = useRef(null);
    const refInput = useRef(null);
    const { dropDownVisible, data: finalData } = state;

    useEffect(() => {
        if (dropDownVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [dropDownVisible]);

    const handleHideDropdown = (event) => {
        const elPrevent = document.getElementById(`show_list_option_dropdow_list_sms_${name}`);
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeDropdown();
        }
    };

    const handleClickOutside = (event) => {
        const elPrevent = document.getElementById(`show_list_option_dropdow_list_sms_${name}`);
        if (
            refDropDown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropDown.current.contains(event.target)
        ) {
            _closeDropdown();
        }
    };

    const _handleOpenDropdown = (e) => {
        e.stopPropagation();

        dispatchState({ dropDownVisible: !dropDownVisible });
    };

    const _closeDropdown = () => {
        dropDownVisible && dispatchState({ dropDownVisible: false });
    };

    const _handleOnSelect = (name, value) => {
        _closeDropdown();
        onSelect(name, value);
    };

    const _onKeyDownCost = (event) => {
        const finalKeyCode = event.keyCode;
        const finalKey = event.key;

        const valueKeyCode =
            event.ctrlKey ||
            event.altKey ||
            (47 < finalKeyCode && finalKeyCode < 58 && event.shiftKey === false) ||
            (95 < finalKeyCode && finalKeyCode < 106) ||
            finalKeyCode === 8 ||
            finalKeyCode === 9 ||
            (finalKeyCode > 34 && finalKeyCode < 40) ||
            finalKeyCode === 46;

        const finalValue = valueKeyCode || (finalKey === '+' && event.target.value.length === 0);
        !finalValue && onlyNumber && event.preventDefault();
    };

    const _renderTitle = (list) => {
        if (!Array.isArray(list) || list.length === 0) return null;

        const data = list.find((item) => item.id === selected);

        return <span className="txt-ellipsis">{data?.name || ''}</span>;
    };

    const _renderListOptions = (list) => {
        if (!Array.isArray(list) || list.length === 0) {
            return (
                <li className="items pointer-events-none">
                    {t('common:no_data_to_display', { title: t(`common:${name}`) })}
                </li>
            );
        }

        return list.map((item) => {
            const { id: itemId = '', name: itemName = '' } = item;
            const valueCheckActive = isSelectName ? itemName : itemId;
            const isCheck = selected === valueCheckActive && selected;

            return (
                <li
                    key={itemId}
                    className={classNames('items', { active: isCheck })}
                    onClick={() => {
                        _handleOnSelect(name, itemId);
                    }}
                    tabIndex="0"
                >
                    <span className={classNames('word-break', { 'flex-1': isPinToTop })}>{itemName}</span>
                    {isPinToTop && !!itemId && (
                        <PinToTop sticky={item.sticky} onPin={(sticky) => _handleSticky(sticky, itemId)} />
                    )}
                </li>
            );
        });
    };

    const _handleSticky = (sticky, id) => {
        const { ids, list } = handleStickyMaterial(id, sticky, finalData);
        onAddSticky(ids, name);
        dispatchState({
            data: list
        });
    };

    const _handleChangeValue = (e) => {
        if (isAllowOnInput) _closeDropdown();
        onSelect(name, e.target.value, true);
    };

    const _handleClear = (e) => {
        e.stopPropagation();
        onSelect(name, '');
        if (refInput.current) refInput.current.focus();
    };

    const _renderInput = () => {
        return (
            <div className="search-input">
                <input
                    ref={refInput}
                    onKeyDown={_onKeyDownCost}
                    onChange={_handleChangeValue}
                    value={selected}
                    type="text"
                    placeholder={t('jobDetail:enter_phone_number')}
                />
                {selected.length !== 0 && (
                    <div className="close-icon" onClick={_handleClear}>
                        <IconCircleClose />
                    </div>
                )}
            </div>
        );
    };

    return (
        <div ref={refDropDown} className={`${classWrapper} ${state.dropDownVisible ? 'active' : ''}`}>
            <div onClick={(e) => _handleOpenDropdown(e)} className="dropbtn items" tabIndex="0">
                {isInput ? _renderInput() : _renderTitle(options)}
                <span className="arrow">
                    <IconArrowDown />
                </span>
            </div>
            <div
                id={`show_list_option_dropdow_list_sms_${name}`}
                className={classNames('v2-dropdown__menu content-full scrolls', {
                    'disable-anchor': isPinToTop
                })}
            >
                <ul>{_renderListOptions(isPinToTop ? finalData : options)}</ul>
            </div>
        </div>
    );
};

export default Select;

import i18n from 'assets/i18n';
import { convertTimeToISO } from 'common/utils/DateUtils';
import moment from 'moment';
import React from 'react';

export function LabelContent({
    text,
    date,
    level = 1,
    isVerticalMode = false,
    isDayTimeline = false,
    isMonthTimeline = false
}) {
    let textRender = text;
    const i18nLanguageCode = i18n.language;

    if (isVerticalMode) return <span>{textRender}</span>;
    if ((isMonthTimeline || level === 0) && !isDayTimeline) {
        textRender = moment(convertTimeToISO(moment(date).locale(i18nLanguageCode).utc()))
            .utc()
            .locale(i18nLanguageCode)
            .format('ddd, DD');
    }

    return <span>{textRender}</span>;
}

import React from 'react';

export default function IconLoading({ isPurple = false, ...props }) {
    const fill = props.isWhite ? '#eee' : 'var(--color-icon-loading)';

    if (isPurple)
        return (
            <div className="preloader-small">
                <span className="preloader-small__items" />
            </div>
        );

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={10} height={14} viewBox="0 0 12.8 16" {...props}>
            <path fill={fill} d="M0 0h4v20H0z">
                <animate
                    attributeName="opacity"
                    attributeType="XML"
                    values="1; .2; 1"
                    begin="0s"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
            </path>
            <path fill={fill} d="M7 0h4v20H7z">
                <animate
                    attributeName="opacity"
                    attributeType="XML"
                    values="1; .2; 1"
                    begin="0.2s"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
            </path>
            <path fill={fill} d="M14 0h4v20h-4z">
                <animate
                    attributeName="opacity"
                    attributeType="XML"
                    values="1; .2; 1"
                    begin="0.4s"
                    dur="0.6s"
                    repeatCount="indefinite"
                />
            </path>
        </svg>
    );
}

import React, { useReducer, useImperativeHandle, forwardRef } from 'react';
import StatusBar from 'app/components/status';
import { reducer } from 'app/const/Reducer';

const GDStatusBar = forwardRef((props, ref) => {
    const [state, dispatchState] = useReducer(reducer, {
        data: []
    });

    useImperativeHandle(ref, () => ({
        showStatusBar: _handleShowStatus,
        handeAddStatus: _handleAddStatus,
        closeStatusBar: _closeStatusBar,
        clearAllStatusBar: _clearAllStatusBar
    }));

    const { data: finalData } = state;

    function _handleShowStatus(id, message, type) {
        dispatchState((prev) => {
            return {
                ...prev,
                data: [{ id, message, type }, ...prev.data]
            };
        });
    }

    function _handleAddStatus(newStatus) {
        dispatchState((prev) => {
            return {
                ...prev,
                data: [newStatus, ...prev.data]
            };
        });
    }

    function _closeStatusBar(id) {
        dispatchState((prev) => {
            return {
                ...prev,
                data: prev.data.filter((item) => item.id !== id)
            };
        });
    }

    function _clearAllStatusBar() {
        dispatchState((prev) => {
            return { ...prev, data: [] };
        });
    }

    if (!finalData.length) return null;
    return finalData?.map((item, index) => {
        return (
            <StatusBar
                key={index.toString()}
                message={item.message}
                type={item.type}
                id={item.id}
                closeStatusBar={_closeStatusBar}
            />
        );
    });
});

export default GDStatusBar;

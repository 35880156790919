import IconClock from 'assets/icon/IconClock';
import IconDone from 'assets/icon/IconDone';
import IconEmail from 'assets/icon/IconEmail';
import IconNote from 'assets/icon/IconNote';
import IconTelephone from 'assets/icon/IconTelephone';

export const CUSTOMER_STATUS = [
    { name: 'Inactive', type: 0 },
    { name: 'Active', type: 1 },
    { name: 'Lead', type: 2 }
];

export const ID_CALL_FILTER = '7';

export const FILTER_CUSTOMER_TIMELINE = [
    {
        id: '1',
        name: 'notes'
    },
    {
        id: '2',
        name: 'emails'
    },
    {
        id: '3',
        name: 'all_open_tasks'
    },
    {
        id: '4',
        name: 'my_open_tasks'
    },
    {
        id: '5',
        name: 'all_completed_tasks'
    },
    {
        id: '6',
        name: 'activities'
    },
    {
        id: ID_CALL_FILTER,
        name: 'calls'
    }
];

export const CUSTOMER_TYPE_IME_TILE = {
    NOTE: 'note',
    TASK: 'task',
    LOG: 'log',
    EMAIL_INBOX: 'email_inbox',
    CUSTOMER_LOG: 'customer_log',
    CALL: 'call',
    BANK_LOG: 'bank_log',
    RESCHEDULE: 'reschedule'
};

export const CUSTOMER_TYPE_LOG = [
    '::WORK_ORDER::',
    '::INVOICE_LINK::',
    '::INVOICE_NUMBER_LINK::',
    '::ESTIMATE_LINK::',
    '::ESTIMATE_NUMBER_LINK::',
    '::SERVICE::'
];

export const VIEW_CUSTOMER_LIST = 'customer_list';
export const VIEW_CUSTOMER_MAP = 'customer_map';

export const FILTER_CUSTOMER_TIMELINE_ICON = {
    NOTES: 'notes',
    EMAILS: 'emails',
    ALL_OPEN_TASK: 'all_open_tasks',
    MY_OPEN_TASK: 'my_open_tasks',
    ALL_COMPLETED_TASK: 'all_completed_tasks',
    ACTIVITIES: 'activities',
    CALLS: 'calls'
};

export const FILTER_CUSTOMER_TIMELINE_ICONS = {
    [FILTER_CUSTOMER_TIMELINE_ICON.NOTES]: <IconNote />,
    [FILTER_CUSTOMER_TIMELINE_ICON.EMAILS]: <IconEmail isEmailAddress />,
    [FILTER_CUSTOMER_TIMELINE_ICON.ALL_OPEN_TASK]: <IconDone isHasColor />,
    [FILTER_CUSTOMER_TIMELINE_ICON.MY_OPEN_TASK]: <IconDone isHasColor />,
    [FILTER_CUSTOMER_TIMELINE_ICON.ALL_COMPLETED_TASK]: <IconDone isHasColor />,
    [FILTER_CUSTOMER_TIMELINE_ICON.ACTIVITIES]: <IconClock isHasColor />,
    [FILTER_CUSTOMER_TIMELINE_ICON.CALLS]: <IconTelephone isNoline />
};

export const LIMIT_GET_CUSTOMER_MAP = 500;

export const LIST_PAGE_HIDE_LOCATION = ['portal', 'account', 'contacts', 'credits'];

export const KEY_LOCATION_FILTER_SELECTED = 'location_filter_selected';

export const CUSTOMER_INACTIVE = 'Inactive';

// Custom Events Name
export const CUSTOMER_CUSTOM_EVENTS = {
    OPEN_ADD_EMAIL: 'open_add_email',
    OPEN_SEND_SMS: 'open_send_sms',
    OPEN_PHONE_POPPER: 'open_phone_popper',
    CLOSE_PHONE_POPPER: 'close_phone_popper',
    LISTEN_TASK_SIDE_MENU: 'listen_task_side_menu',
    OPEN_LOCATION_POPPER: 'open_location_popper',
    SAVE_CALL_NOTE: 'save_call_note',
    VOICEMAIL_DROPPED: 'voicemail_dropped',
    UPDATE_DARK_MODE: 'update_dark_mode'
};

export const CHARACTER_DEFAULT_ITEM = {
    id: '',
    name: 'A-Z'
};

export const KEY_LOCAL_SHOW_SUB_LOCATION = 'show_sub_locations';

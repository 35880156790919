import React from 'react';
import { useTranslation } from 'react-i18next';

import IconUser from 'assets/icon/IconUser';

const CustomerInfo = ({ customer }) => {
    const { t } = useTranslation();
    return (
        <div className="sm-row">
            <div className="txt svg-name">
                <IconUser isCustomer />
                {t('common:customer')}
            </div>
            <div className="detail">{customer.full_name}</div>
        </div>
    );
};

export default CustomerInfo;

import React, { useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { STRIPE_ADDONS_OPTIONS } from 'app/const/Api';
import { getLinkStripeConnect } from 'app/const/api/V2';
import { setBranchLocalStorage } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import AddonsBoxHeader from '../../components/AddonsBoxHeader';
import StripeAddonsDisconnect from './StripeAddonsDisconnect';

const StripeAddonsContent = ({
    keyword,
    name,
    status,
    level = 1,
    stripe_user_id,
    upgrade = 0,
    onDisconnect = () => {},
    onDisableUpdate = () => {}
}) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { isNotConnect: !!status });
    const stripeClientId = useSelector(({ auth }) => auth.user.settings.stripe.client_id);
    const branchId = useSelector(({ auth }) => auth.user.company.branch.id);
    const refDisconnect = useRef(null);
    const refHeader = useRef(null);

    const _handleDisableStripe = () => {
        refDisconnect.current._open();
    };

    const _handleDisconnectSuccess = () => {
        dispatchState({ isNotConnect: false });
        onDisconnect();
    };

    const _handleConnectStripe = () => {
        setBranchLocalStorage(branchId);
        window.location.href = getLinkStripeConnect(stripeClientId, branchId);
    };

    return (
        <>
            <div className="boxs boxs--no-border --hasline">
                <AddonsBoxHeader
                    ref={refHeader}
                    isMerchant
                    keyword={keyword}
                    name={name}
                    upgrade={upgrade}
                    status={status}
                    level={level}
                    onDisableUpdate={onDisableUpdate}
                    onConfirmChange={_handleDisableStripe}
                    urlUpdateStatus={STRIPE_ADDONS_OPTIONS}
                />
                <div className="boxs__contents mt-3">
                    <p className="description">
                        {t('addons:connect_stripe_title_1')} <br />
                        {t('addons:connect_stripe_title_2')} <br />
                        {t('addons:connect_stripe_title_3')}{' '}
                        <a
                            href="https://stripe.com/us/help/faq"
                            target="_blank"
                            className="purple-default"
                            rel="noreferrer"
                        >
                            {t('addons:general_question_about_stripe')}
                        </a>
                    </p>
                    {!!state.isNotConnect ? (
                        <div className="desc-connect">
                            <div className="v2-btn-default --grey btn-modal" onClick={_handleDisableStripe}>
                                {t('addons:disconnect_from_stripe')}
                            </div>
                            <p className="ml-2 flex-1">
                                {t('report:stripe_id')}:{' '}
                                <a
                                    href="https://dashboard.stripe.com"
                                    target="_blank"
                                    className="purple-default"
                                    rel="noreferrer"
                                >
                                    {stripe_user_id}
                                </a>
                            </p>
                        </div>
                    ) : (
                        <div className="desc-connect">
                            <div className="v2-btn-default --grey btn-modal" onClick={_handleConnectStripe}>
                                {t('addons:connect_with_stripe')}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <StripeAddonsDisconnect
                ref={refDisconnect}
                onDisconnect={() => refHeader.current._handleChangeStatus(0, true)}
                onDisconnectSuccess={_handleDisconnectSuccess}
            />
        </>
    );
};

export default StripeAddonsContent;

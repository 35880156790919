import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { getListTaxes } from 'common/redux/actions/taxesAction';
import LocationPaymentTerm from './LocationPaymentTerm';
import LocationTax from './LocationTax';

const LocationTaxes = ({ defaultValue = {} }, ref) => {
    const { t } = useTranslation();
    const refFirstTax = useRef(null);
    const refSecondTax = useRef(null);
    const refPaymentTerm = useRef(null);
    const dispatch = useDispatch();
    const { taxes = [], payment_terms } = defaultValue;

    useImperativeHandle(ref, () => ({ _getValue }));

    useEffect(() => {
        // eslint-disable-next-line no-undef
        !global.fetchedListTax && _handleGetListTaxes();
    }, []);

    const _getValue = () => ({
        tax1_id: refFirstTax.current._getValue(),
        tax2_id: refSecondTax.current._getValue(),
        payment_terms_id: refPaymentTerm.current._getValue()
    });

    const _handleGetListTaxes = () => {
        dispatch(getListTaxes({ fields: 'rate' }, () => {}));
    };

    return (
        <div className="column-content column-tax">
            <div className="tax-rate">
                <div className="flexcenter gap-20 flex-1">
                    <div className="col flex-1 text-info">
                        <div className="txt txt-ellipsis">{t('setting:payment_terms')}</div>
                        <LocationPaymentTerm id="payment_terms" ref={refPaymentTerm} defaultSelected={payment_terms} />
                    </div>
                    <div className="col flex-1 text-info">
                        {t(
                            'customers:when_adding_invoice_items_for_this_location_automatically_assign_these_tax_rates'
                        )}
                    </div>
                </div>
                <div className="col-tax-rate">
                    <div className="rows --multi">
                        <div className="col">
                            <div className="txt txt-ellipsis">{t('setting:tax_1')}</div>
                            <LocationTax id="item_tax1" ref={refFirstTax} defaultSelected={taxes[0]} />
                        </div>
                        <div className="col">
                            <div className="txt txt-ellipsis">{t('setting:tax_2')}</div>
                            <LocationTax id="item_tax2" ref={refSecondTax} defaultSelected={taxes[1]} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default forwardRef(LocationTaxes);

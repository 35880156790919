import SearchOption from 'app/modules/report/filter/SearchOption';
import IconSwitch from 'assets/icon/IconSwitch';
import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { GET_VOIP_MEMBERS } from 'app/const/api/Voip';
import { clientQuery } from 'common/utils/ApiUtils';
import { reducer } from 'app/const/Reducer';
import IconPhone from 'app/components/phone/IconPhone';
import classNames from 'classnames';
import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { useTranslation } from 'react-i18next';

const Transfer = (
    {
        callData,
        isInvite = false,
        buttonClassName = '--icon-lg --transparent btn-bg-grey tooltip',
        isCloseOnSelect = false,
        isDisable = false,
        onTransfer = () => {}
    },
    ref
) => {
    const { t } = useTranslation('header');

    const [state, dispatchState] = useReducer(reducer, {
        users: [],
        numbers: [],
        isVisible: false,
        isLoading: true,
        isDisable: false
    });

    const { isVisible, users: finalUsers, numbers: finalNumbers, isLoading, isDisable: finalIsDisable } = state;
    const refDropDown = useRef(null);
    const refDropDownId = useRef(null);

    useImperativeHandle(ref, () => ({
        _setDisable: _handleSetDisable
    }));

    useEffect(() => {
        if (isVisible) {
            clientQuery(GET_VOIP_MEMBERS, { method: 'GET' }, _getVoipMembers);
        }
    }, [isVisible]);

    const _renderMembers = () => {
        let isFirstOffline = true;
        const sortByIsOnline = (a, b) => {
            return a.is_online === b.is_online ? 0 : a.is_online ? -1 : 1;
        };

        if (!finalUsers.filter((item) => !item.isHide).length) {
            return (
                <li className="items gap-md">
                    <span className="schedule-user flex-1">
                        <span className="tech-name m-0 px-1">{t('addons:user_is_empty')}</span>
                    </span>
                </li>
            );
        }

        return finalUsers.sort(sortByIsOnline).map((item, index) => {
            const { id: itemId, isHide, is_online } = item;
            if (isHide) {
                return true;
            }

            const flag = !is_online && isFirstOffline;
            if (flag) isFirstOffline = false;

            return (
                <Fragment key={itemId}>
                    {flag && index !== 0 && <li className="line" />}
                    <li onClick={(e) => _handleTransferCall(e, item)} className="items gap-md">
                        <span className="schedule-user flex-1">
                            <span className="tech-name m-0">
                                <img className="avt-img" src={item.avatar} width={24} height={24} />
                                <span className="tech-name__label">{item.full_name}</span>
                            </span>
                        </span>
                        <span className={`status-dot ${is_online ? 'bg-green-default' : 'bg-grey-generic'} `} />
                    </li>
                </Fragment>
            );
        });
    };

    const _getVoipMembers = (reponse) => {
        const responseData = reponse.data;
        dispatchState({
            users: responseData.users,
            numbers: responseData.numbers,
            isLoading: false
        });
    };

    const _handleSearch = (keyword) => {
        dispatchState((prev) => {
            return {
                ...prev,
                users: prev.users.map((item) => {
                    if (item.full_name.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())) {
                        return {
                            ...item,
                            isHide: false
                        };
                    }
                    return {
                        ...item,
                        isHide: true
                    };
                }),
                numbers: prev.numbers.map((item) => {
                    if (item.phone_number.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())) {
                        return {
                            ...item,
                            isHide: false
                        };
                    }
                    return {
                        ...item,
                        isHide: true
                    };
                })
            };
        });
    };

    const _handleTransferCall = (e, item) => {
        e.stopPropagation();
        isCloseOnSelect && refDropDown.current._close();
        onTransfer(callData, item);
    };

    const _renderNumbers = () => {
        if (!finalNumbers.filter((item) => !item.isHide).length) {
            return (
                <li className="items gap-md">
                    <span className="schedule-user flex-1">
                        <span className="tech-name m-0 px-1">{t('addons:number_is_empty')}</span>
                    </span>
                </li>
            );
        }
        return finalNumbers.map((item) => {
            const { id: itemId, isHide } = item;
            if (isHide) {
                return true;
            }
            return (
                <li key={itemId} onClick={(e) => _handleTransferCall(e, item)} className="items gap-md rows-phone">
                    <IconPhone />
                    <div className="items__info flex-column flex-1">
                        <span className="black-eerie">{item.name}</span>
                        <span className="grey-generic fs-13">{item.phone_number}</span>
                    </div>
                </li>
            );
        });
    };

    const _renderContent = () => {
        if (isLoading)
            return (
                <div className="container-column">
                    <div className="wrap-loading box-auto">
                        <div className="items gap-md pointer-events-none">
                            <div className="schedule-user flex-1">
                                <div className="avt-img" />
                                <div className="tech-name__label loading --animation --line --half" />
                            </div>
                            <div className="status-dot bg-white-cultured" />
                        </div>
                        <div className="items gap-md pointer-events-none">
                            <div className="schedule-user flex-1">
                                <div className="avt-img" />
                                <div className="tech-name__label loading --animation --line --fourth" />
                            </div>
                            <div className="status-dot bg-white-cultured" />
                        </div>
                        <div className="items gap-md pointer-events-none">
                            <div className="schedule-user flex-1">
                                <div className="avt-img" />
                                <div className="tech-name__label loading --animation --line --twothird" />
                            </div>
                            <div className="status-dot bg-white-cultured" />
                        </div>
                        <div className="items gap-md pointer-events-none">
                            <div className="schedule-user flex-1">
                                <div className="avt-img" />
                                <div className="tech-name__label loading --animation --line --twothird" />
                            </div>
                            <div className="status-dot bg-white-cultured" />
                        </div>
                    </div>
                </div>
            );

        return (
            <div className="container-column">
                <SearchOption
                    placeholder={t('common:search')}
                    onSearch={_handleSearch}
                    defaultValue={''}
                    parentStyle={'flex-auto header-search'}
                    isSearchIcon
                />
                <ul className="box-auto scrolls">
                    {_renderMembers()}
                    {!isInvite && (
                        <Fragment>
                            <li className="line" />
                            {_renderNumbers()}
                        </Fragment>
                    )}
                </ul>
            </div>
        );
    };

    const _handleVisible = (value) => {
        dispatchState((prev) => ({ ...prev, isVisible: value }));
    };

    const _handleSetDisable = (value = false) => {
        refDropDown.current._close();
        dispatchState((prev) => ({ ...prev, isDisable: value }));
    };

    const _renderButton = () => {
        if (isInvite) {
            return (
                <Fragment>
                    <span className="txt-ellipsis">{t('invite')}</span>
                    <span className="arrow">
                        <IconArrowDown />
                    </span>
                </Fragment>
            );
        }

        return (
            <Fragment>
                <IconSwitch />
                <span className="tooltiptext bottom">{t('common:transfer_call')}</span>
            </Fragment>
        );
    };

    return (
        <DropdownPopper
            id={refDropDownId}
            ref={refDropDown}
            isUseToggle
            placement="bottom"
            wrapperClassName={classNames('v2-dropdown wrap-invite', {
                'is-disable': !callData.transfer_call_id
            })}
            buttonClassName={`dropbtn v2-btn-default ${buttonClassName}`}
            wrapperListClass="v2-dropdown__menu content-search --center"
            customButton={_renderButton()}
            onOpen={() => _handleVisible(true)}
            onClose={() => _handleVisible(false)}
            isDisable={isDisable || finalIsDisable}
        >
            {_renderContent()}
        </DropdownPopper>
    );
};

export default forwardRef(Transfer);

import GdButton from 'app/components/button';
import { customerPortalSend } from 'app/const/Api';
import { LIST_STATUS } from 'app/const/Status';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import IconClose from 'assets/icon/IconClose';
import { reducer } from 'app/const/Reducer';

const CustomerSendByEmail = forwardRef(
    (
        { emailContent = null, resetToken = null, customerId = '', onShowStatus = () => {}, onUpdatePortal = () => {} },
        ref
    ) => {
        const { t } = useTranslation(['common', 'customers']);
        const [state, dispatchState] = useReducer(reducer, {
            isVisible: false,
            isLoading: false
        });
        const refContainer = useRef(null);

        useImperativeHandle(ref, () => ({ _open, _close }));

        const _open = () => dispatchState({ isVisible: true });
        const _close = () => dispatchState({ isVisible: false });

        const _handleSubmit = () => {
            dispatchState({ isLoading: true });
            const content = refContainer.current.innerHTML;

            const _success = ({ message }) => {
                onUpdatePortal({ email_template: content });
                dispatchState({ isLoading: false, isVisible: false });
                onShowStatus({ id: 'update_email_success', message, type: LIST_STATUS.SUCCESS });
            };

            const _failed = ({ message }) => {
                dispatchState({ isLoading: false, isVisible: false });
                onShowStatus({ id: 'update_email_error', message, type: LIST_STATUS.ERROR });
            };

            clientQuery(
                customerPortalSend(customerId),
                { data: { content, token: resetToken }, method: 'POST' },
                _success,
                _failed
            );
        };

        return (
            <ReactModal
                id="send_email_preview"
                className="modal container-modal modal-email-send open"
                isOpen={state.isVisible}
                onRequestClose={_close}
                style={{ overlay: { background: 'transparent' } }}
            >
                <div className="modal__overlay bg-fixed" onClick={_close} />
                <div className="modal__container">
                    <div className="header-modal btn-close">
                        <h3 className="header-modal__label">{t('common:send_by_email')}</h3>
                        <span className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                            <IconClose />
                        </span>
                    </div>
                    <div className="wrap-send-email">
                        <div className="body-modal scrolls">
                            <div
                                ref={refContainer}
                                className="container-content-email"
                                contentEditable
                                suppressContentEditableWarning
                                dangerouslySetInnerHTML={{ __html: emailContent }}
                            ></div>
                        </div>
                        <div className="footer-modal">
                            <span className="v2-btn-default --noborder --label" onClick={_close}>
                                {t('cancel')}
                            </span>
                            <GdButton className="v2-btn-main ml-2" onClick={_handleSubmit} isLoading={state.isLoading}>
                                {t('send')}
                            </GdButton>
                        </div>
                    </div>
                </div>
            </ReactModal>
        );
    }
);

export default CustomerSendByEmail;

export default function IconTask({ ...props }) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.5 4.5H16.5C17.6046 4.5 18.5 5.39543 18.5 6.5V16.5C18.5 17.6046 17.6046 18.5 16.5 18.5H6.5C5.39543 18.5 4.5 17.6046 4.5 16.5V6.5C4.5 5.39543 5.39543 4.5 6.5 4.5Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.50171 11.5L10.5032 14L14.5 9.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

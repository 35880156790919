import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { REPORT_LEADS_BOOKING, REPORT_LEADS_INBOUND } from 'app/config/routes';
import { addBranchPath, addPrefixPath } from 'app/const/Branch';
import { PERMISSIONS } from 'app/const/Permissions';
import { LEADS } from 'app/const/Route';
import { MAIN_FEATURES_MENU_HEADER } from 'app/const/StaticLinks';
import { checkAddon } from 'common/utils/AddonUtils';
import { checkPermission } from 'common/utils/PermissionUtils';
import PinButton from './pinMenu/PinButton';
import { LINE_ONBOARDING, KEY_ONBOARDING } from 'app/modules/onboarding/constant';

export const mustCheckAccessArray = ['customers', 'reports', 'settings', 'addons', 'dashboard'];
export const mustCheckActivePages = [LEADS, REPORT_LEADS_INBOUND, REPORT_LEADS_BOOKING];

export const accessName = {
    customers: 'accessCustomersTab',
    reports: 'accessReportsTab',
    settings: 'accessSettingsTab',
    addons: 'accessAddonsTab',
    dashboard: 'accessDashboard'
};

function MainFeatureHeader({ canEditSetting, typeAccount, onLinkClick = () => {} }) {
    const location = useLocation();

    const { user } = useSelector((state) => state.auth);
    const permissionTracking = useSelector(({ auth }) => !!auth.user.settings?.time_tracking?.tracking);
    const permissionsList = user?.permissions?.enabled || [];
    const addonsList = user?.settings?.addons || {};
    const isHaveAccessAddons = checkPermission(permissionsList, PERMISSIONS.accessAddonsTab);
    const permissionOnboarding = useSelector(({ auth }) => !!auth.user.settings?.questlog?.question_active);

    return (
        <ul className="nav-list">
            {MAIN_FEATURES_MENU_HEADER.map((item) => {
                const { isSetting } = item;

                const isLine = item?.type === 'line' || false;
                if (item.id === LINE_ONBOARDING && !permissionOnboarding) return null;
                /* If is line return line to UI */
                if (isLine && (canEditSetting || isHaveAccessAddons)) return <div key={item.id} className="line" />;

                let isActive = matchPath(location.pathname, { path: addPrefixPath(item.path) });
                const isRequiredPermission = item?.isRequiredPermission || false;
                const canDisplay = (isRequiredPermission && canEditSetting) || !isRequiredPermission;

                if (
                    mustCheckActivePages.some((path) => addBranchPath(path) === location.pathname) &&
                    location.pathname.split('/')[2] === 'reports' &&
                    isActive &&
                    item.id !== 'leads'
                ) {
                    isActive = false;
                }

                /* Check has access to display item */
                const isMustCheckAccess = mustCheckAccessArray.includes(item.id);
                const hasAccess = isSetting
                    ? canEditSetting
                    : checkPermission(permissionsList, PERMISSIONS[accessName[item.id]]);
                const isSuperAdmin = user.profile.role === 'SuperAdmin';
                const isCheckSetting = checkPermission(permissionsList, PERMISSIONS.accessSettingsTab);

                /* If must check access to display and access is not return null */
                if (isMustCheckAccess && !hasAccess && !isSuperAdmin) return null;

                if (item.id === 'commissions')
                    item.isShow =
                        checkPermission(permissionsList, PERMISSIONS.admin) &&
                        checkAddon(addonsList?.commission_tracking);

                if (item.id === 'reviews')
                    item.isShow =
                        checkPermission(permissionsList, PERMISSIONS.accessReportsTab) &&
                        checkAddon(addonsList?.review_engine);

                if (item.id === 'leads')
                    item.isShow =
                        checkPermission(permissionsList, PERMISSIONS.accessReportsTab) &&
                        (checkAddon(addonsList?.customer_portal) ||
                            checkAddon(addonsList?.online_booking) ||
                            checkAddon(addonsList?.zapier));
                if (item.id === 'work_log') item.isShow = checkAddon(addonsList?.work_log) && permissionTracking;
                if (item.id === KEY_ONBOARDING) item.isShow = permissionOnboarding;
                if ((canDisplay || item.id === 'dashboard') && !isLine && item.isShow) {
                    return (
                        <MenuItem
                            key={item.id}
                            item={item}
                            isActive={isActive}
                            isSetting={isSetting}
                            isCheckSetting={isCheckSetting}
                            typeAccount={typeAccount}
                            onLinkClick={onLinkClick}
                        />
                    );
                }
                return null;
            })}
        </ul>
    );
}

const MenuItem = ({ item, isActive, isSetting, isCheckSetting, typeAccount, onLinkClick = () => {} }) => {
    const { t } = useTranslation();
    return (
        <li className="relative" title={t(`header:${item.title}`)}>
            <Link
                to={addBranchPath(isSetting && !isCheckSetting ? item.url_account : item.url)}
                className={classNames('items', { active: isActive })}
                onClick={onLinkClick}
            >
                {isActive ? item.iconActive : item.icon}
                <p className="txt-ellipsis">{t(`header:${item.title}`)}</p>
                {typeAccount?.isBasicPlan && item.isBadge && (
                    <div className="status-btn">{t(`addons:${item.badgeTitle}`)}</div>
                )}
            </Link>
            <PinButton idPin={item.id} />
        </li>
    );
};

export default MainFeatureHeader;

export default function IconFuture() {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.06389 6.5H16.9361C17.8276 6.5 18.1509 6.59283 18.4768 6.76713C18.8028 6.94144 19.0586 7.19723 19.2329 7.52315C19.4072 7.84908 19.5 8.17237 19.5 9.06389V16.9361C19.5 17.8276 19.4072 18.1509 19.2329 18.4768C19.0586 18.8028 18.8028 19.0586 18.4768 19.2329C18.1509 19.4072 17.8276 19.5 16.9361 19.5H7.06389C6.17237 19.5 5.84908 19.4072 5.52315 19.2329C5.19723 19.0586 4.94144 18.8028 4.76713 18.4768C4.59283 18.1509 4.5 17.8276 4.5 16.9361V9.06389C4.5 8.17237 4.59283 7.84908 4.76713 7.52315C4.94144 7.19723 5.19723 6.94144 5.52315 6.76713C5.84908 6.59283 6.17237 6.5 7.06389 6.5Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.5 13.5001H8.5C7.94772 13.5001 7.5 13.9478 7.5 14.5001V15.5001C7.5 16.0524 7.94772 16.5001 8.5 16.5001H9.5C10.0523 16.5001 10.5 16.0524 10.5 15.5001V14.5001C10.5 13.9478 10.0523 13.5001 9.5 13.5001Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.50012 8.37494V4.62494"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.5001 8.37494V4.62494"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.5 10.4999H19.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import { OPTIONS_VALUE_OPPORTUNITY } from 'app/const/opportunity';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { onKeyDownOnlyNumber, roundingNumber } from 'common/utils/NumberUtils';
import { useSelector } from 'react-redux';

const OpportunityValue = forwardRef(({ defaultValue = {}, prevIdEditing = null }, ref) => {
    const { t } = useTranslation('customers');
    const [state, dispatchState] = useReducer(reducer, {
        period: OPTIONS_VALUE_OPPORTUNITY.find((item) => item.id === defaultValue?.period)
    });
    const { period } = state;
    const refValue = useRef(null);
    const refIdEditing = useRef(null);

    useImperativeHandle(ref, () => ({
        getValue: () => ({
            value: refValue.current.getValue(),
            period: period.value
        })
    }));

    useEffect(() => {
        if (defaultValue?.period && prevIdEditing !== refIdEditing.current) {
            if (defaultValue?.value !== refValue.current?.getValue()) refValue.current?.setValue(defaultValue?.value);
            refIdEditing.current = prevIdEditing;
            dispatchState({ period: OPTIONS_VALUE_OPPORTUNITY.find((item) => item.id === defaultValue?.period) });
        }
    }, [defaultValue]);

    const _handleSelect = (idSelected) => {
        dispatchState({ period: OPTIONS_VALUE_OPPORTUNITY.find((item) => item.id === idSelected) });
    };

    return (
        <div className="flex-betweenitems align-end gap-8">
            <div className="flex-column gap-4 flex-1">
                <p>{t('value')}</p>
                <InputValue ref={refValue} initValue={defaultValue?.value || 0} />
            </div>
            <div className="flex-column gap-4 flex-1">
                <p></p>
                <CalendarDropdown
                    id="opportunity-period"
                    selected={t(`customers:${period.name}`)}
                    selectedOption={period}
                    options={OPTIONS_VALUE_OPPORTUNITY}
                    fileTranslation="customers"
                    onSelect={_handleSelect}
                />
            </div>
        </div>
    );
});

const InputValue = forwardRef(({ initValue = 0 }, ref) => {
    const currency = useSelector(({ auth }) => auth.user.settings.currency);
    const refInput = useRef(null);

    useImperativeHandle(ref, () => ({
        getValue: () => refInput.current.value,
        setValue: _setValue
    }));

    const _setValue = (value) => {
        refInput.current.value = roundingNumber(value);
    };

    const _handleBlur = (event) => {
        _setValue(event.target.value);
    };

    const _handleKeyDown = (event) => {
        const finalKey = event.key;
        const currentValue = event.target.value;

        if (finalKey === '-' && currentValue !== '') {
            const convertToNegative = Number(`-${currentValue}`);
            event.target.value = isNaN(convertToNegative) ? 0 : convertToNegative;
            event.preventDefault();
            _handleKeyDown(event);
        }

        const isKeyAccept =
            onKeyDownOnlyNumber(event) || (finalKey === '-' && event.target.value === '') || finalKey === '.';
        !isKeyAccept && event.preventDefault();
    };

    return (
        <div className="cost-input --left" data-currency-unit={currency?.symbol || '$'}>
            <input
                ref={refInput}
                type="text"
                name="value"
                className="field-input"
                maxLength={15}
                defaultValue={initValue}
                onBlur={_handleBlur}
                onKeyDown={_handleKeyDown}
            />
        </div>
    );
});

export default OpportunityValue;

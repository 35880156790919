import { LINE_ITEMS } from 'app/const/setting/SettingPageName';
import { COMMON } from 'app/const/App';
import { getTabParams } from 'app/const/setting/SettingParams';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
    getListSettingLineItems,
    updateStatusSettingLineItems,
    createLineItem,
    updateLineItem
} from 'common/redux/actions/settings/lineItemsAction';
import {
    getLocalStorageSettingPage,
    updateLocalStorageSettingPage,
    removeLocalStorageSettingPage
} from '../utils/localStorage';
import { TAB, LIST_BUTTONS, LIST_BUTTON_MESSAGES, getColumns } from 'app/const/setting/SettingLineItems';
import StatusBar from 'app/components/status/statusbar';
import { LIST_TOOLTIP } from 'app/const/Settings.js';
import { getNewParamsOrderStatus } from '../utils/statusOrder';
import { reducer } from 'app/const/Reducer';
import { resetListItems } from 'common/redux/actions/itemsAction';
import loadable from '@loadable/component';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { mixpanelAddProduct } from 'app/modules/mixpanel/MixpanelAddProduct';
import { checkAccessFail, checkPermission, getPlanUser } from 'common/utils/PermissionUtils';
import { ACCESS_SETTINGS_TAB, PERMISSIONS } from 'app/const/Permissions';
import IconSync from 'assets/icon/IconSync';
import SettingsGlobalOverride from '../globalOverride';

const BoxTipsSetting = loadable(() => import('../components/BoxTipsSetting'));
const GdButton = loadable(() => import('app/components/button'));
const GdConfirm = loadable(() => import('app/components/confirm'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const ListButtonAction = loadable(() => import('app/modules/settings/components/ListButtonAction'));
const SettingTabsFilter = loadable(() => import('app/modules/settings/components/SettingTabsFilter'));
const SettingPagination = loadable(() => import('app/modules/settings/components/SettingPagination'));
const LineItemForm = loadable(() => import('app/modules/settings/lineitems/components/LineItemForm'));
const IconPlus = loadable(() => import('assets/icon/IconPlus'));
const MainHeaderSettings = loadable(() => import('app/modules/settings/components/MainHeaderSettings'));
const CheckBoxHeader = loadable(() => import('app/modules/report/components/CheckBoxHeader'));

function SettingsLineItems({ routes = [] }) {
    const { t } = useTranslation(['setting']);
    const dispatch = useDispatch();
    const userProfile = useSelector(({ auth }) => auth.user.profile);
    const permissions = useSelector(({ auth }) => auth.user.permissions.enabled);
    const { id: profileId } = userProfile;
    const { isBasicPlan } = getPlanUser(userProfile);
    const isHavePermissionOverride = !isBasicPlan && checkPermission(permissions, PERMISSIONS.accessSettingsTab);

    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: false,
        checkedItems: {
            is_check_all: false,
            ids: []
        },
        listTab: TAB.LIST,
        currentTab: getLocalStorageSettingPage({
            namePage: LINE_ITEMS,
            key: COMMON.CURRENT_TAB,
            defaultCurrentTab: TAB.DEFAULT_SELECTED
        }),
        total: 1,
        params: getLocalStorageSettingPage({ namePage: LINE_ITEMS, key: COMMON.PARAMS }),
        isOverride: false
    });

    const { checkedItems, data: finalData, isOverride } = state;
    const { ids: checkedItemsIds } = checkedItems || {};

    const refModal = useRef(null);
    const refConfirm = useRef(null);
    const refCreateLineItem = useRef(true);
    const refStatusBar = useRef(null);
    const totalSelected = checkedItemsIds.length;

    useEffect(() => {
        _getListLineItems(state.params);
        return () => {
            removeLocalStorageSettingPage({ namePage: LINE_ITEMS });
        };
    }, []);

    const _resetReducerLineItems = () => {
        dispatch(resetListItems());
    };

    // Callback after call API
    const _getListLineItemSuccess = (response) => {
        const currentTab = getLocalStorageSettingPage({ namePage: LINE_ITEMS, key: COMMON.CURRENT_TAB });
        const params = getLocalStorageSettingPage({ namePage: LINE_ITEMS, key: COMMON.PARAMS });

        dispatchState((prev) => ({
            ...prev,
            isLoading: false,
            data: response.data,
            currentTab: currentTab,
            checkedItems: {
                is_check_all: false,
                ids: []
            },
            total: response.total,
            params: params
        }));
    };

    const _getListLineItemFailure = (err) => {
        dispatchState({ isLoading: false });
        checkAccessFail(err, ACCESS_SETTINGS_TAB);
    };

    const _createLineItemSuccess = (response) => {
        handleTrackingEvent(mixpanelAddProduct({ id: profileId }));
        refModal.current.hideModal();

        refStatusBar.current.showStatusBar(COMMON.SUCCESS, response.message, COMMON.SUCCESS);

        _getListLineItems(state.params);
        _resetReducerLineItems();
    };

    const _createLineItemFailure = () => {
        refModal.current.hideModal();
        dispatchState({ isLoading: false });
        refStatusBar.current.showStatusBar(COMMON.ERROR, t('setting:create_line_item_failure'), COMMON.ERROR);
    };

    const _updateData = (arrayData, dataUpdated) => {
        const result = [...arrayData];
        for (var i in result) {
            if (result[i].id === dataUpdated.id) {
                result[i] = dataUpdated;
                break; //Stop this loop, we found it!
            }
        }
        return result;
    };

    const _updateLineItemSuccess = (response) => {
        refModal.current.hideModal();
        const lineItemUpdated = response.data;
        const newDataLineItems = _updateData(finalData, lineItemUpdated);
        dispatchState((prev) => ({ ...prev, isLoading: false, data: newDataLineItems }));
        _resetReducerLineItems();
        refStatusBar.current.showStatusBar(COMMON.SUCCESS, response.message, COMMON.SUCCESS);
    };

    const _updateLineItemFailure = () => {
        refModal.current.hideModal();
        dispatchState({ isLoading: false });
        refStatusBar.current.showStatusBar(COMMON.ERROR, t('setting:edit_line_item_failure'), COMMON.ERROR);
    };

    const _checkIsInLastPage = ({ total, offset, limit }) => {
        if (offset + limit >= total) return true;
        return false;
    };

    const _checkIsInFirstPage = ({ total, limit }) => {
        if (limit >= total) return true;
        return false;
    };

    const _updateStatusLineItemsSuccess = (response) => {
        refModal.current.hideModal();
        refStatusBar.current.showStatusBar('success', response.message, COMMON.SUCCESS);
        const params = getLocalStorageSettingPage({ namePage: LINE_ITEMS, key: COMMON.PARAMS });
        const isLastPage = _checkIsInLastPage({ total: state.total, offset: params.offset, limit: params.limit });
        //Process went action in last page,
        if (isLastPage) {
            const isRemoveAllItemInPage = response.listIdChecked.length === finalData.length;
            const isInFirstPage = _checkIsInFirstPage({ total: state.total, limit: params.limit });
            if (isRemoveAllItemInPage && !isInFirstPage) {
                const newParams = params;
                newParams.offset = params.offset - params.limit;
                _getListLineItems(newParams, state.currentTab, false);
            }
        }

        _resetReducerLineItems();

        if (!isLastPage) _getListLineItems(params, state.currentTab, false);
    };

    const _updateStatusLineItemsFailure = (response) => {
        refStatusBar.current.showStatusBar('success', response?.message?.toString(), COMMON.ERROR);
        dispatchState({ isLoading: false });
    };

    // Dispatch call API
    const _getListLineItems = (params, tabId = state.currentTab) => {
        updateLocalStorageSettingPage({
            namePage: LINE_ITEMS,
            value: { params: params, current_tab: tabId }
        });
        dispatchState({ isLoading: true, params: params, currentTab: tabId });
        dispatch(getListSettingLineItems(params, _getListLineItemSuccess, _getListLineItemFailure));
    };

    const _handleSaveOnModal = (params) => {
        if (params.id) {
            dispatch(updateLineItem(params, _updateLineItemSuccess, _updateLineItemFailure));
        } else {
            dispatch(createLineItem(params, _createLineItemSuccess, _createLineItemFailure));
        }
    };

    const _handleChangeTab = (tabId) => {
        const newTabParams = getTabParams(tabId);
        const newParams = { ...state.params, ...newTabParams, offset: 0 };
        _getListLineItems(newParams, tabId);
    };

    const _onConfirmUpdateStatusLineItems = (params) => {
        const lineItemsUpdated = params.ids;
        const newDataLineItems = finalData.filter((item) => {
            if (lineItemsUpdated.includes(item.id)) return false;
            return true;
        });
        dispatchState((prev) => ({
            ...prev,
            data: newDataLineItems,
            checkedItems: {
                is_check_all: false,
                ids: []
            }
        }));
        dispatch(updateStatusSettingLineItems(params, _updateStatusLineItemsSuccess, _updateStatusLineItemsFailure));
    };

    const _handleClickButtonAction = (value) => {
        const listIdChecked = checkedItemsIds;
        if (listIdChecked.length) {
            refConfirm.current.open({ ids: listIdChecked, type: value }, t(`common:${LIST_BUTTON_MESSAGES[value]}`));
        }
    };

    const _handleEditLineItem = (lineItem) => {
        refModal.current.showModal({ ...finalData.find((item) => item.id === lineItem.row.id) });
    };

    const _handleAddItem = () => {
        refModal.current.showModal();
        refCreateLineItem.current = true;
    };

    const _onChangeStateCheckedItems = (stateCheckedItems) => {
        dispatchState({ checkedItems: stateCheckedItems });
    };

    const _handleChangePage = (newParams) => {
        _getListLineItems(newParams);
    };

    const _handleActionHeader = (action) => {
        const newParams = getNewParamsOrderStatus({ params: state.params, actionEvent: action });
        _getListLineItems(newParams);
    };

    const _renderActionHeader = () => {
        return (
            <div className="header --filter">
                <CheckBoxHeader checkedItems={checkedItems} />
                <ListButtonAction
                    list={LIST_BUTTONS[state.currentTab]}
                    fileTranslation="report"
                    onSubmit={_handleClickButtonAction}
                    noSpacing
                    disabled={!totalSelected}
                />
            </div>
        );
    };

    const _renderHeaderLeft = () => {
        if (isOverride) return null;
        return <SettingTabsFilter list={state.listTab} onChange={_handleChangeTab} tabActive={state.currentTab} />;
    };

    const _renderHeaderRight = () => {
        if (isOverride) return null;

        return (
            <Fragment>
                {isHavePermissionOverride ? (
                    <div className="header-items">
                        <span className="v2-btn-default has-icon" onClick={() => _handleToggleOverride(true)}>
                            <IconSync />
                            {t('line_item_global_override')}
                        </span>
                    </div>
                ) : null}

                <div className="modal-setting header-items">
                    <GdButton
                        className="v2-btn-main has-icon svg-white"
                        title={t('setting:add_item')}
                        iconSvg={<IconPlus />}
                        onClick={_handleAddItem}
                    />
                    <LineItemForm ref={refModal} handleSave={_handleSaveOnModal} />
                </div>
            </Fragment>
        );
    };

    const _handleToggleOverride = (value = false) => {
        dispatchState({ isOverride: value });
        if (!value) _getListLineItems(state.params);
    };

    if (isHavePermissionOverride && isOverride)
        return <SettingsGlobalOverride routes={routes} onClickBack={_handleToggleOverride} />;

    return (
        <>
            <MainHeaderSettings contentLeft={_renderHeaderLeft} contentRight={_renderHeaderRight} />
            <div className="wrapper-columns">
                <div className="container-print contents-pages maintables-page has-tab">
                    <StatusBar ref={refStatusBar} />
                    <BoxTipsSetting typeId={LIST_TOOLTIP.ITEMS} onAddNew={_handleAddItem} />
                    {_renderActionHeader()}
                    <div className="tab-contents box-auto has-footer">
                        <div className="tab-conts tab-content-active">
                            <GdGridView
                                isLoading={state.isLoading}
                                classTable="scrolls-x has-checkbox --items"
                                content={finalData}
                                showCheckBox
                                fileTranslation={'setting'}
                                handleClick={_handleEditLineItem}
                                checkedItems={checkedItems}
                                onChangeStateCheckedItems={_onChangeStateCheckedItems}
                                handleClickHeader={(event) => {
                                    _handleActionHeader(event);
                                }}
                                isScroll
                                {...getColumns({
                                    actionStatus: state.params?.order,
                                    currentTab: state.currentTab
                                })}
                            />
                        </div>
                    </div>
                    <GdConfirm
                        ref={refConfirm}
                        title={t('common:confirm')}
                        message={t('setting:are_you_sure_delete_this_item')}
                        listButton={{ cancel: true, confirm: true }}
                        onConfirm={_onConfirmUpdateStatusLineItems}
                    />
                    <SettingPagination totalItem={state.total} namePage={LINE_ITEMS} onSelect={_handleChangePage} />
                </div>
            </div>
        </>
    );
}

export default SettingsLineItems;

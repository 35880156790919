import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import DropdownColumns from './DropdownColumns';
import { DEFAULT_COLUMNS_LIST_VIEW } from '../../const/Columns';

const HeaderListView = ({ additionalCells = [] }) => {
    const { t } = useTranslation();
    const calendarColumns = useSelector(({ calendar }) => calendar.columns);

    return (
        <div className="rows --fixed --header">
            <div className="col col-md">
                <DropdownColumns />
            </div>

            {DEFAULT_COLUMNS_LIST_VIEW.map((item) => {
                if (!!!item.header) return null;
                if (item.key === 'emoji' && !calendarColumns.includes(item.key)) return null;
                if (item.key === 'customer_company_name' && !calendarColumns.includes(item.key)) return null;
                return <item.header key={item.key} className={item.className} value={t(`calendar:${item.key}`)} />;
            })}
            {additionalCells.map((item) => {
                if (!!!item.header) return null;
                return <item.header key={item.key} className={item.className} value={t(`calendar:${item.key}`)} />;
            })}
        </div>
    );
};

export default HeaderListView;

import { ADDONS_API_KEYS, ADDONS_CUSTOM_FIELDS, ADDONS_MAIN_JOB_STATUS, ADDONS_TIME_WINDOW } from 'app/config/routes';
import { DEFAULT_ALL } from 'app/const/App';
import { PERMISSIONS } from 'app/const/Permissions';
import { REPORT_TYPE } from 'app/const/Reports';
import {
    ADDONS_LEVEL_ALL,
    ADDONS_LEVEL_BASIC,
    ADDONS_LEVEL_GROWTH,
    ADDONS_LEVEL_PRO,
    ADDONS_MULTIPLE_BRANCH,
    ADDONS_STATUS_ACTIVE,
    ADDONS_STATUS_ALL,
    ADDONS_STATUS_INACTIVE,
    ADDONS_API_KEYS as API_KEYS,
    ADDONS_CUSTOM_FIELDS_KEY,
    TIME_WINDOW,
    ADDONS_CUSTOM_JOB_STATUS,
    PERMISSIONS_CUSTOM_FIELDS,
    PERMISSIONS_JOB_STATUS,
    KEY_ADDONS_CUSTOM_FIELDS,
    KEY_ADDONS_CUSTOM_JOB_STATUS
} from 'app/const/addons';
import {
    JOB_STATUS_PERMISSION_END_POINT,
    LIST_USER_PERMISSION_CUSTOM_FIELDS,
    updateJobStatusPermission,
    updatePermissionCustomFields
} from 'app/const/api/V2';
import { GRID_COLUMNS as GRID_COLUMNS_CUSTOM_FIELDS } from '../customFields/utils';
import { GRID_COLUMNS as GRID_COLUMNS_JOB_STATUS } from '../jobStatus/utils';

export const OPTIONS_BUFFER = [
    { value: 5, label: '5 mins' },
    { value: 10, label: '10 mins' },
    { value: 15, label: '15 mins' },
    { value: 30, label: '30 mins' },
    { value: 45, label: '45 mins' },
    { value: 60, label: '1 hour' }
];

export const SMS_ADDON_STATUS = {
    ACTIVE: 1,
    INACTIVE: 2
};

export const ADDON_LEVEL = {
    PRO: 20,
    GROWTH: 60
};

export const LIST_ADDONS_CHECK_PERMISSION = [
    {
        id: ADDONS_MULTIPLE_BRANCH,
        permission: PERMISSIONS.manageBranch,
        checkPlan: 'isGrowthPlan'
    }
];
export const LIST_ADDON_LEVELS = [ADDONS_LEVEL_ALL, ADDONS_LEVEL_PRO, ADDONS_LEVEL_GROWTH, ADDONS_LEVEL_BASIC];
export const LIST_ADDON_STATUSES = [ADDONS_STATUS_ALL, ADDONS_STATUS_ACTIVE, ADDONS_STATUS_INACTIVE];
export const ADDONS_DASHBOARD_LOCAL_KEY = 'addons_dashboard_filter';
export const ADDONS_DASHBOARD_DEFAULT_LOCAL_VALUE = {
    isGridView: false,
    level: DEFAULT_ALL,
    status: ADDONS_LEVEL_GROWTH
};

export const ITEMS_SETTING_PORTAL = [
    {
        id: 'payment_options',
        title: 'manage_payment_options'
    },
    {
        id: 'work_order',
        title: 'work_order'
    },
    {
        id: 'invoice',
        title: 'invoices'
    },
    {
        id: 'estimate',
        title: 'estimates'
    },
    {
        id: 'document',
        title: 'documents'
    },
    {
        id: 'add_card_bank',
        title: 'add_card_bank_accounts'
    },
    {
        id: 'delete_card_bank',
        title: 'delete_card_bank_accounts'
    }
];

export const ITEMS_SETTINGS_API_KEY = [
    {
        id: 'notification',
        title: 'inbound_leads_notifications'
    }
];

export const URL_LINK_ADDONS = {
    [TIME_WINDOW]: ADDONS_TIME_WINDOW,
    [API_KEYS]: ADDONS_API_KEYS,
    [ADDONS_CUSTOM_FIELDS_KEY]: ADDONS_CUSTOM_FIELDS,
    [ADDONS_CUSTOM_JOB_STATUS]: ADDONS_MAIN_JOB_STATUS
};

export const MANAGE_PERMISSIONS_ADDONS = {
    [PERMISSIONS_CUSTOM_FIELDS]: {
        reportType: REPORT_TYPE.CUSTOM_FIELDS_PERMISSIONS,
        getListEndPoint: LIST_USER_PERMISSION_CUSTOM_FIELDS,
        updateEndPoint: updatePermissionCustomFields,
        historyPath: ADDONS_CUSTOM_FIELDS,
        transPrevBtn: 'common:custom_fields',
        gridColumn: GRID_COLUMNS_CUSTOM_FIELDS,
        keyAddon: KEY_ADDONS_CUSTOM_FIELDS,
        description: 'addons:desc_custom_fields_manage'
    },
    [PERMISSIONS_JOB_STATUS]: {
        reportType: REPORT_TYPE.CUSTOM_JOB_STATUS_PERMISSIONS,
        getListEndPoint: JOB_STATUS_PERMISSION_END_POINT,
        updateEndPoint: updateJobStatusPermission,
        historyPath: ADDONS_MAIN_JOB_STATUS,
        transPrevBtn: 'addons:custom_job_status_title',
        gridColumn: GRID_COLUMNS_JOB_STATUS,
        keyAddon: KEY_ADDONS_CUSTOM_JOB_STATUS,
        description: 'addons:desc_job_status_manage'
    }
};

export const LIST_ACTION_MESSAGE = {
    CREATED: 'created',
    UPDATED: 'updated',
    DELETED: 'deleted'
};

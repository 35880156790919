import React from 'react';

const IconChevron = ({ type }) => {
    switch (type) {
        case 'right':
            return (
                <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.5 7.5L8 11L11.5 7.5"
                        stroke="var(--color-icon)"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case 'right_white':
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10.1384 18.8272L17.6248 13.6444C18.7736 12.8491 18.7736 11.1509 17.6248 10.3556L10.1384 5.17275C8.81196 4.25443 7 5.20381 7 6.81714V17.1829C7 18.7962 8.81196 19.7456 10.1384 18.8272Z"
                        fill="white"
                    />
                </svg>
            );
        case 'right_green':
            return (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10.1384 18.8272L17.6248 13.6444C18.7736 12.8491 18.7736 11.1509 17.6248 10.3556L10.1384 5.17275C8.81196 4.25443 7 5.20381 7 6.81714V17.1829C7 18.7962 8.81196 19.7456 10.1384 18.8272Z"
                        fill="white"
                    />
                </svg>
            );
        default:
            return null;
    }
};

export default IconChevron;

import classNames from 'classnames';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';

import { reducer } from 'app/const/Reducer';
import LoadingDefault from './LoadingDefault';

const GDIframeObserver = ({
    isAutoPlay = false,
    link = '',
    title = '',
    className = '',
    loadingComponent: LoadingComponent = LoadingDefault
}) => {
    const [state, dispatchState] = useReducer(reducer, { isLoadingIframe: true, isVisible: false });
    const { isLoadingIframe, isVisible } = state;

    const iframeRef = useRef(null);
    const observerRef = useRef(null);

    useEffect(() => {
        if (!link) return;

        const observerCallback = (entries) => {
            const [entry] = entries;
            if (entry.isIntersecting) {
                dispatchState((prev) => ({ ...prev, isVisible: true }));
                observerRef.current?.disconnect();
            }
        };
        observerRef.current = new IntersectionObserver(observerCallback, { root: null, threshold: 0.1 });
        if (iframeRef.current) {
            observerRef.current.observe(iframeRef.current);
        }

        return () => observerRef.current?.disconnect();
    }, [link]);

    const _onIframeLoadComplete = () => {
        dispatchState((prev) => ({ ...prev, isLoadingIframe: false }));
    };

    if (!link) return null;

    return (
        <div ref={iframeRef}>
            {isVisible ? (
                <Fragment>
                    {isLoadingIframe ? <LoadingComponent /> : null}
                    <iframe
                        src={isAutoPlay ? `${link}?autoplay=true` : link}
                        title={title}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                        className={classNames(className, { 'dp-hide': isLoadingIframe })}
                        onLoad={_onIframeLoadComplete}
                    />
                </Fragment>
            ) : (
                <LoadingComponent />
            )}
        </div>
    );
};

export default GDIframeObserver;

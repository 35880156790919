import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';

import { getUrlCustomerContact, getUrlCustomerLocation } from 'app/const/Api';
import { LIST_PAGE_SIZE } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import LocationBilling from './LocationBilling';
import LocationServices from './LocationServices';

const LocationContent = ({ dataUpdate = {}, customer = {}, isUpdate = false, customerId = '' }, ref) => {
    const addressDefault = dataUpdate?.address_contact?.address_to || '';
    const billingDefault = dataUpdate?.address_contact?.bill_to || '';
    const [state, dispatchState] = useReducer(reducer, {
        customers: [customer || {}],
        addressSelected: isUpdate ? { id: addressDefault, full_name: addressDefault } : customer || {},
        billingSelected: isUpdate ? { id: billingDefault, full_name: billingDefault } : customer || {},
        isSame: isUpdate ? !!dataUpdate.same_billing_location : true,
        isFetching: true,
        billingAddressOptions: [],
        offset: 0,
        total: 0,
        isLoadMore: false
    });
    const refLocationServices = useRef(null);
    const refLocationBilling = useRef(null);
    const refFirstTime = useRef(true);
    const refFetchAddress = useRef(true);
    const refIsSame = useRef(true);
    const {
        customers,
        addressSelected,
        billingSelected,
        isSame,
        isFetching,
        offset: finalOffset,
        total: finalTotal,
        isLoadMore: finalIsLoadMore
    } = state;
    const LIMIT_DROPDOWN_CUSTOMER_CONTACT = LIST_PAGE_SIZE[3];

    useImperativeHandle(ref, () => ({
        _getValue,
        _updateTagGlobal: () => refLocationBilling.current._updateTagGlobal()
    }));

    useEffect(() => {
        refIsSame.current = isSame;
    }, [isSame]);

    const _getValue = () => {
        return {
            address_to: addressSelected.full_name,
            same_billing_location: isSame,
            ...refLocationServices.current._getValue(),
            ...refLocationBilling.current._getValue()
        };
    };

    const _handleGetCustomerSimply = (newOffset = 0, isLoadMore = false) => {
        if (!refFirstTime.current && !newOffset && !isLoadMore) return;
        if (!!newOffset && isLoadMore) dispatchState((prev) => ({ ...prev, isLoadMore: true, offset: newOffset }));
        const _handleGetSuccess = ({ data = [], total = 0 }) => {
            const customerData = { ...customer, id: `${customer?.id}_main` };
            const finalCustomers = (isLoadMore ? [...customers, ...data] : data).map((item) => ({
                ...item,
                full_name: `${item.first_name} ${item.last_name}`
            }));
            const addressSelected = isUpdate
                ? finalCustomers.find((item) => item.full_name === dataUpdate.address_contact.address_to) ||
                  customerData
                : customerData || finalCustomers[0];
            const billingSelected = isUpdate
                ? finalCustomers.find((item) => item.full_name === dataUpdate.address_contact.bill_to) || customerData
                : customerData || finalCustomers[0];

            refFirstTime.current = false;
            dispatchState((prev) => ({
                ...prev,
                customers: isLoadMore ? finalCustomers : [customerData, ...finalCustomers],
                addressSelected,
                billingSelected,
                isFetching: false,
                isLoadMore: false,
                total
            }));
        };

        clientQuery(
            getUrlCustomerContact(customer?.id || ''),
            {
                method: 'GET',
                data: { inc: 'company', limit: LIMIT_DROPDOWN_CUSTOMER_CONTACT, offset: newOffset, total: 1 }
            },
            _handleGetSuccess
        );
    };

    const _handleCheckSame = (value) => {
        dispatchState({ isSame: value });
    };

    const _handleSelectAddress = (id) => {
        const newSelected = customers.find((item) => item.id === id);
        const newState = { addressSelected: newSelected };
        if (isSame) newState['billingSelected'] = newSelected;
        dispatchState(newState);
    };

    const _handleSelectBilling = (id) => {
        dispatchState({ billingSelected: customers.find((item) => item.id === id), isSame: false });
    };

    const _handleSetSameAddress = (checked) => {
        const newState = { isSame: checked };
        if (!isSame) newState['billingSelected'] = addressSelected;
        dispatchState((prevState) => ({ ...prevState, ...newState }));
        _handleServiceChange(null, refLocationServices.current._handleGetDataAddress(), true);
    };

    const _handleServiceChange = (name, newValue, isForceSet = false) => {
        delete newValue['country'];
        delete newValue['county'];
        if (refIsSame.current || isForceSet) refLocationBilling.current._setValueForm(name, newValue);
    };

    const _handleGetBillingAddress = () => {
        if (!refFetchAddress.current) return;

        clientQuery(
            getUrlCustomerLocation(customer?.id || customerId),
            { data: {}, method: 'GET' },
            _getBillingAddressSuccess
        );
    };

    const _getBillingAddressSuccess = ({ data }) => {
        refFetchAddress.current = false;
        dispatchState({ billingAddressOptions: data });
    };

    const _handleScroll = () => {
        if (!finalIsLoadMore && finalOffset < finalTotal) {
            _handleGetCustomerSimply(finalOffset + LIMIT_DROPDOWN_CUSTOMER_CONTACT, true);
        }
    };

    return (
        <div className="column-content left-column">
            <div className="w-100 d-flex">
                <LocationServices
                    ref={refLocationServices}
                    options={customers}
                    selected={addressSelected}
                    customerId={customer?.id}
                    dataUpdate={dataUpdate}
                    isUpdate={isUpdate}
                    isLoading={isFetching}
                    isLoadMore={finalIsLoadMore}
                    onSelect={_handleSelectAddress}
                    onVisible={_handleGetCustomerSimply}
                    onChange={_handleServiceChange}
                    onScrollAddressTo={_handleScroll}
                />
                <LocationBilling
                    ref={refLocationBilling}
                    customerId={customer?.id}
                    options={customers}
                    selected={billingSelected}
                    dataUpdate={dataUpdate}
                    isUpdate={isUpdate}
                    isLoading={isFetching}
                    isSame={isSame}
                    billingAddressOptions={state.billingAddressOptions}
                    isLoadingAddress={refFetchAddress.current}
                    isLoadMore={finalIsLoadMore}
                    onHandleCheckSame={_handleCheckSame}
                    onSelect={_handleSelectBilling}
                    onVisible={_handleGetCustomerSimply}
                    onSetSameAddress={_handleSetSameAddress}
                    onHandleGetBillingAddress={_handleGetBillingAddress}
                    onScrollBillingTo={_handleScroll}
                />
            </div>
        </div>
    );
};

export default forwardRef(LocationContent);

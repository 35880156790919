import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { PACKAGE_LIST_COLOR } from 'app/modules/jobdetail/const/Estimate';
import IconDropUpDown from 'assets/icon/IconDropUpDown';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export default function PackageColor({ onChange, parentColor }) {
    const { t } = useTranslation();
    const refDropdown = useRef(null);

    const _handleChangeColor = (e, selected) => {
        onChange(selected);
        refDropdown.current._closeDropdown();
    };

    const _renderOptions = () => {
        return PACKAGE_LIST_COLOR.map((itemColor) => {
            const { color, name } = itemColor;
            const isActive = color === parentColor;
            return (
                <li
                    key={color}
                    onClick={(e) => _handleChangeColor(e, color)}
                    className={`items ${isActive ? 'active' : ''}`}
                >
                    <span className="dots" style={{ '--bg-estimate': color }} />
                    <span className="txt-ellipsis flex-1">{t(`setting:${name}`)}</span>
                </li>
            );
        });
    };

    return (
        <CalendarDropdown
            ref={refDropdown}
            id="method_select_edit"
            wrapperClassName="v2-dropdown dropdown-select-color"
            buttonClassName="dropbtn items"
            wrapperListClass="v2-dropdown__menu scrolls content-full"
            customDropButton={() => (
                <>
                    <span className="dots" style={{ '--bg-estimate': parentColor }} />
                    <span className="arrow">
                        <IconDropUpDown />
                    </span>
                </>
            )}
        >
            <ul>{_renderOptions()}</ul>
        </CalendarDropdown>
    );
}

import loadable from '@loadable/component';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { URL_EXPORT_SENTRICON } from 'app/const/api/Export';
import { REPORT_SENTRICON } from 'app/const/api/V2';
import { KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { LIST_EXPORT } from 'app/const/report/Common';
import { COLUMNS } from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { handleAbortController } from 'app/modules/customer/utils';
import ErrorPage from 'app/modules/error';
import { KEYWORD_SENTRICON } from 'app/modules/jobdetail/tabs/devices/sentricon/constant';
import { clientQuery } from 'common/utils/ApiUtils';
import { showStatusBar } from 'common/utils/FunctionUtils';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { getPlanUser, isActiveFeature } from 'common/utils/PermissionUtils';
import { getLocalParamsReport } from 'common/utils/ReportUtils';
import HeaderBottom from '../components/HeaderBottom';
import { getGridTableSentricon } from './constant';
import { convertDateSentricons, getOrderSentriconReport } from './utils';

const Export = loadable(() => import('app/modules/report/components/Export'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const MainHeaderReport = loadable(() => import('app/modules/report/components/MainHeader'));
const GDStatusBar = loadable(() => import('app/components/status/statusbar'));

const ReportSentricon = () => {
    const { t } = useTranslation();
    const isActiveSentricon = useSelector(({ auth }) => auth.user.settings.addons.device_tracking?.sentricon);
    const profileData = useSelector(({ auth }) => auth.user.profile);
    const { isBasicPlan } = getPlanUser(profileData);
    const havePermission = !isBasicPlan && !!isActiveSentricon && isActiveFeature(KEYWORD_SENTRICON);
    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: false,
        total: 0,
        showMore: false,
        cursor: '',
        isLoadMore: false,
        refreshScreen: 0
    });
    const { data, isLoading, total, showMore, cursor, isLoadMore, refreshScreen } = state;

    const finalTypeReport = REPORT_TYPE.SENTRICON;
    const keyLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', finalTypeReport);
    const paramsReport = getLocalParamsReport(keyLocal, finalTypeReport);

    const abortController = useRef(null);
    const refAlert = useRef(null);

    useEffect(() => {
        if (!havePermission) return;
        getListReport();

        return () => {
            handleAbortController(abortController);
        };
    }, []);

    const getListReport = ({ params = {}, shouldLoadMore = false, shouldLoading = true } = {}) => {
        refAlert.current?.clearAllStatusBar();
        handleAbortController(abortController);
        abortController.current = new AbortController();
        dispatchState((prev) => ({
            ...prev,
            isLoadMore: shouldLoadMore,
            data: !shouldLoadMore ? [] : prev.data,
            isLoading: shouldLoading
        }));

        const _handleSuccess = ({ data = [], total = 0, show_more = false, cursor = '' }) => {
            dispatchState((prev) => ({
                ...prev,
                data: convertDateSentricons(prev.isLoadMore ? [...(prev.data || []), ...data] : data),
                isLoading: false,
                isLoadMore: false,
                total: total ?? prev.total ?? 0,
                showMore: show_more,
                cursor,
                refreshScreen: prev.refreshScreen + 1
            }));
        };
        const _handleFailed = ({ isAborted = false, message = '' }) => {
            if (isAborted) return;
            showStatusBar({ id: 'get_list_sentricon_fail', message, refAlert });
            dispatchState({ data: [], isLoading: false, isLoadMore: false });
        };

        const finalParams = { ...paramsReport, ...params, cursor: shouldLoadMore ? cursor : null };
        delete finalParams?.columns;
        clientQuery(
            REPORT_SENTRICON,
            {
                data: { ...finalParams, order: getOrderSentriconReport(finalParams.order) },
                method: 'GET'
            },
            _handleSuccess,
            _handleFailed
        );
    };

    const _handleLoadMore = () => {
        if (showMore && !isLoading && !isLoadMore) {
            getListReport({ shouldLoadMore: true, shouldLoading: false });
        }
    };

    const _handleChangeFilter = (params, mode) => {
        if (mode && mode === COLUMNS) {
            dispatchState((prev) => ({ ...prev, refreshScreen: prev.refreshScreen + 1 }));
        }
    };

    const _handleUpdate = () => {
        getListReport({ params: getLocalStorage(keyLocal) });
    };

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: finalTypeReport,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleUpdate
        });
    };

    if (!havePermission) return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;

    return (
        <>
            <MainHeaderReport
                reportType={finalTypeReport}
                contentRight={() => (
                    <Export
                        title={t('report:records', { count: total })}
                        activePrint
                        isDisable={isLoading}
                        url={URL_EXPORT_SENTRICON}
                        pageExport={LIST_EXPORT.EXPORT_SENTRICON}
                        refresh={refreshScreen}
                        params={paramsReport}
                    />
                )}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages">
                    <GDStatusBar ref={refAlert} />
                    <div className="wrap-tables flex-column relative">
                        <HeaderBottom
                            classNameHeader="header --filter"
                            typeReport={finalTypeReport}
                            isLoading={isLoading}
                            isShowBtnUpdate={false}
                            handleChangeFilter={_handleChangeFilter}
                            handleUpdate={_handleUpdate}
                        />
                        <GdGridView
                            isEmptyFlat
                            content={data}
                            isLoading={isLoading}
                            fileTranslation="report"
                            classTable="tables has-text-ellipsis table-multi-column scrolls-x"
                            {...getGridTableSentricon(paramsReport.order)}
                            isScroll
                            isLoadmore={isLoadMore}
                            onScrollToEnd={_handleLoadMore}
                            handleClickHeader={_handleActionHeader}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReportSentricon;

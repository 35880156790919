export const PRICE_TRANSCRIPTIONS = 0.15;
export const TYPE_TRANSCRIPTIONS = { SUMMARY: 'summary', TRANSCRIPTION: 'transcription' };
export const LIST_TYPE_TRANSCRIPTIONS = [
    { value: 0, label: TYPE_TRANSCRIPTIONS.SUMMARY },
    { value: 1, label: TYPE_TRANSCRIPTIONS.TRANSCRIPTION }
];

export const TRANSCRIPTION_STATUS = {
    NOT_AVAILABLE: 0,
    IN_PROGRESS: 1,
    COMPLETED: 2
};

export const TRANSCRIPTION_ROLE = {
    USER: 'user',
    CUSTOMER: 'customer'
};

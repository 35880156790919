import classNames from 'classnames';
import React, { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { PORTAL_COLOR, SETTING_PORTAL_WIDGET } from '../constant';

const ItemPortalColor = ({ portalColor = {}, onChangeValue = () => {} }) => {
    const { t } = useTranslation();

    const refDropdownColor = useRef(null);

    const _handleChangeColor = (value) => {
        if (value === portalColor.color) return;
        onChangeValue({ value, key: PORTAL_COLOR });
        refDropdownColor.current && refDropdownColor.current._closeDropdown();
    };

    return (
        <div className="line">
            <span className="txt">{t('addons:portal_color')}</span>
            <div className="field">
                <CalendarDropdown
                    ref={refDropdownColor}
                    id="setting_select_color"
                    wrapperClassName="v2-dropdown dropdown-select-color"
                    buttonClassName="dropbtn items"
                    wrapperListClass="v2-dropdown__menu scrolls content-full"
                    customDropButton={() => (
                        <Fragment>
                            <span className="dots" style={{ '--bg-estimate': portalColor.color }} />
                            <span className="flex-1">{t(`addons:${portalColor.name}`)}</span>
                            <span className="arrow">
                                <IconArrowDown />
                            </span>
                        </Fragment>
                    )}
                >
                    <ul>
                        {SETTING_PORTAL_WIDGET.LIST_COLOR_PORTAL.map(({ color, name }) => (
                            <li
                                key={color}
                                onClick={() => _handleChangeColor(color)}
                                className={classNames('items', {
                                    active: name === portalColor.name
                                })}
                            >
                                <span className="dots" style={{ '--bg-estimate': color }} />
                                <span className="txt-ellipsis flex-1">{t(`addons:${name}`)}</span>
                            </li>
                        ))}
                    </ul>
                </CalendarDropdown>
            </div>
        </div>
    );
};

export default ItemPortalColor;

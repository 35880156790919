import React, { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import GdRichText from 'app/components/richtext';
import {
    CONTENT_SMS_TEMPLATE_VARIABLE,
    getVariableCompany,
    ID_CONTENT_PREVIEW,
    LIMIT_SMS_COUNT
} from 'app/const/Settings';
import ButtonPreview from 'app/modules/settings/templates/components/ButtonPreview';
import IconSentSms from 'assets/icon/IconSentSms';
import IconTooltip from 'assets/icon/IconTooltip';
import { handlePreviewTemplate } from '../../utils';
import { getSMSRichTextSetup } from '../constant';
import { useSettingTemplate } from '../SettingTemplateContext';

const BoxSMSContent = () => {
    const { t } = useTranslation();
    const {
        template,
        idTypeTemplate,
        currentReminder,
        activeIndex,
        typePreviewing,
        onGetCurrentData,
        onUpdateDataReminder
    } = useSettingTemplate();
    const { company, settings } = useSelector(({ auth }) => auth.user);
    const isActiveSMS = settings?.addons?.smsplivo;
    const refRichtext = useRef(null);
    const refWrapperCounter = useRef(null);
    const refCounterEl = useRef(null);

    useLayoutEffect(() => {
        const dataCurrentReminder = onGetCurrentData();
        if (refRichtext.current) {
            refRichtext.current.setValue(dataCurrentReminder.sms_content);
            _handleWordCount(refRichtext.current.getWordCount() || 0);
        }
    }, [activeIndex, typePreviewing]);

    const _handleChangeContent = (value) => {
        if (refCounterEl.current && refRichtext.current) {
            setTimeout(() => {
                if (!refRichtext.current) return;
                const counter = refRichtext.current.getWordCount();
                _handleWordCount(counter);
                onUpdateDataReminder(['sms_content', 'counter_sms'], [value, counter]);
            }, 0);

            if (!!typePreviewing && typePreviewing === 'sms') {
                const content = handlePreviewTemplate(
                    value,
                    getVariableCompany(company),
                    CONTENT_SMS_TEMPLATE_VARIABLE(settings?.shorten_url)
                );

                const divInner = document.getElementById(ID_CONTENT_PREVIEW);
                if (divInner) divInner.innerHTML = content;
            }
        }
    };

    const _handleWordCount = (counter = 0) => {
        if (!refCounterEl.current || !refWrapperCounter.current) return;
        refCounterEl.current.innerHTML = counter;
        refWrapperCounter.current.classList = counter > LIMIT_SMS_COUNT ? 'ml-1 red-default' : 'ml-1 grey-generic';
    };

    return (
        <div className="content-insert-variable">
            <div className="box-use-variable">
                <div className="box-header flex-betweenitems mb-1">
                    <div className="flexcenter flex-1">
                        <div className="title flexcenter svg-sms-yellow">
                            <IconSentSms isFillOrange />
                            {t('setting:sms')}
                        </div>
                        <div className="sms-count dots flexcenter">
                            <div className="tooltip">
                                <IconTooltip />
                                <span className="tooltiptext top ml-6">{t('setting:tooltip_sms_template')}</span>
                            </div>
                            <div ref={refWrapperCounter} className="ml-1 grey-generic">
                                <span ref={refCounterEl}>0</span>
                                <span>/{LIMIT_SMS_COUNT}</span>
                            </div>
                        </div>
                    </div>

                    <ButtonPreview typePreview="sms" />
                </div>

                <div className="wrap-content content-sendsms">
                    <GdRichText
                        ref={refRichtext}
                        valueRestore={currentReminder.default_sms_content}
                        defaultContent={currentReminder.sms_content || ' '}
                        textDrop={t('common:drop_text_here')}
                        textDrag={t('common:drag_text_here')}
                        isChangeWithHTML
                        isDisableSMS={!isActiveSMS}
                        onChange={_handleChangeContent}
                        toolbarConfig={getSMSRichTextSetup(template, idTypeTemplate)}
                    />
                </div>
            </div>
        </div>
    );
};

export default BoxSMSContent;

import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import LogsCard from './LogsCard';

const SquareDetailCard = (props, ref) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, dataCard: {} });
    const { isOpen, dataCard } = state;

    useImperativeHandle(ref, () => ({ _handleOpen, _handleClose }));

    function _handleOpen(dataCard) {
        dispatchState({ isOpen: true, dataCard });
    }

    function _handleClose() {
        dispatchState({ isOpen: false, dataCard: {} });
    }

    if (!isOpen) return null;
    return (
        <ReactModal
            isOpen
            id="modal-detail-square"
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal modal-methods --form-no-border open"
            onRequestClose={_handleClose}
            ariaHideApp={false}
        >
            <div className="modal__overlay bg-fixed" onClick={_handleClose} />
            <div className="modal__container --md">
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('common:credit_card_in_square')}</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_handleClose}>
                        <IconClose />
                    </div>
                </div>

                <div className="body-modal has-form mb-3">
                    <div className="rows">
                        <div className="txt">{t('customers:card_number')}</div>
                        <input
                            type="text"
                            className="field-input number-card is-disable"
                            defaultValue={`**** **** **** ${dataCard.account_number}`}
                        />
                    </div>
                    <div className="row-multi">
                        <div className="col">
                            <div className="txt">{t('customers:expiration')}</div>
                            <input
                                type="text"
                                className="field-input is-disable"
                                defaultValue={`${dataCard.exp_month}/${dataCard.exp_year}`}
                            />
                        </div>
                        <div className="col">
                            <div className="txt">{t('customers:cvv')}</div>
                            <input className="field-input is-disable" type="text" defaultValue={'***'} />
                        </div>
                        <div className="col">
                            <div className="txt">{t('customer:postal')}</div>
                            <input
                                type="text"
                                className="field-input is-disable"
                                placeholder={t('customers:placeholder')}
                                defaultValue="*****"
                            />
                        </div>
                    </div>
                </div>
                <LogsCard id={dataCard.id} />
                <div className="footer-modal btn-close border-top-border-color-grey">
                    <span className="v2-btn-default --transparent" onClick={_handleClose}>
                        {t('common:cancel')}
                    </span>
                </div>
            </div>
        </ReactModal>
    );
};

export default forwardRef(SquareDetailCard);

/* eslint-disable no-unused-vars */
import { GET_LIST_CUSTOMER_SOURCE } from 'app/const/Api';
import { DEFAULT_SOURCE } from 'app/const/Customers';
import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconPlus from 'assets/icon/IconPlus';
import classNames from 'classnames';
import { addCustomerSources, updateCustomerSources } from 'common/redux/actions/customer';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddSourceModal from './AddSourceModal';
import { useTranslation } from 'react-i18next';

const SelectSource = ({ defaultValue = null, isEditAccount = false }, ref) => {
    const { t } = useTranslation(['setting']);
    const dispatch = useDispatch();
    const customerSources = useSelector(({ customerReducer }) => customerReducer.sources);
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: !customerSources,
        sources: customerSources || [DEFAULT_SOURCE],
        selected: defaultValue || DEFAULT_SOURCE
    });
    const { selected, sources, isLoading } = state;

    const refDropdown = useRef(null);
    const refAddNewSource = useRef(null);

    useImperativeHandle(ref, () => ({ getValue: _handleGetValue }));

    const _handleGetValue = () => selected;

    const _handleOpenDropdown = () => {
        /* It's checking if the customerSources is already loaded. If it is, then it will return. */
        if (customerSources) return;
        const _getSourcesSuccess = ({ data }) => {
            dispatch(updateCustomerSources(data));
            dispatchState({
                sources: data,
                isLoading: false,
                selected: defaultValue || DEFAULT_SOURCE
            });
        };
        clientQuery(GET_LIST_CUSTOMER_SOURCE, { data: {}, method: 'GET' }, _getSourcesSuccess);
    };

    const _handleSelect = (selected, shouldClose = true) => {
        dispatchState({ selected });
        shouldClose && refDropdown.current._closeDropdown();
    };

    const _handleAddNewSource = () => {
        refAddNewSource.current._open();
        refDropdown.current._closeDropdown();
    };

    const _handleAddSourceSuccess = (newSource) => {
        dispatchState({ sources: [...sources, newSource] });
        dispatch(addCustomerSources(newSource));
        refDropdown.current._openDropdown();
    };

    return (
        <>
            <CalendarDropdown
                ref={refDropdown}
                id="add-customer-select-source"
                customDropButton={() => (
                    <>
                        <div className={classNames({ 'tag-label px-1 m-0': !isEditAccount })}>
                            <span className={!isEditAccount ? 'tag-label__ellipsis' : 'txt-ellipsis'}>
                                {t(`customers:${selected.name}`)}
                            </span>
                        </div>
                        <span className="arrow">
                            <IconArrowDown />
                        </span>
                    </>
                )}
                wrapperClassName="select-source"
                selected={selected.name}
                isLoading={isLoading}
                fileTranslation="customers"
                onVisible={_handleOpenDropdown}
            >
                <ul>
                    <li
                        onClick={() => _handleSelect(DEFAULT_SOURCE)}
                        className={classNames('items', { active: selected.id === DEFAULT_SOURCE.id })}
                        tabIndex="0"
                    >
                        <p className="txt-ellipsis">{t(`customers:${DEFAULT_SOURCE.name}`)}</p>
                    </li>
                    <ListOptions data={sources} selected={selected} onSelect={_handleSelect} />
                    <li onClick={_handleAddNewSource} className="items has-icon" tabIndex="0">
                        <IconPlus />
                        <p className="txt-ellipsis">{t('setting:add_source')}</p>
                    </li>
                </ul>
            </CalendarDropdown>
            <AddSourceModal ref={refAddNewSource} onAddSuccess={_handleAddSourceSuccess} />
        </>
    );
};

const ListOptions = ({ data = [], selected, onSelect = () => {} }) => {
    const refSearch = useRef({});

    useEffect(() => {
        document.addEventListener('keydown', handleHideDropdown, true);
        return () => {
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, []);

    const handleHideDropdown = (event) => {
        const finalKey = event.key;
        const listItem = data.filter((item) => item.name[0].toLowerCase().includes(finalKey));

        const oldValue = refSearch.current[finalKey] || 0;
        let findItem = listItem[oldValue];
        refSearch.current = { [finalKey]: oldValue + 1 };

        if (!findItem) {
            findItem = listItem[0];
            refSearch.current = { [finalKey]: findItem ? 1 : 0 };
        }

        if (findItem) {
            onSelect(findItem, false);
            setTimeout(() => {
                const element = document.getElementById(`source_item_${findItem.id}`);
                element && element.scrollIntoView();
            }, 0);
        }
    };

    return data.map((item) => {
        const itemId = item.id;
        return (
            <li
                id={`source_item_${itemId}`}
                key={itemId}
                onClick={() => onSelect(item)}
                className={classNames('items', { active: selected.id === itemId })}
                tabIndex="0"
            >
                <p className="txt-ellipsis">{item.name}</p>
            </li>
        );
    });
};

export default forwardRef(SelectSource);

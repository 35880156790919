import IconAmount from 'assets/icon/IconAmount';
import IconEstimate from 'assets/icon/IconEstimate';
import IconFille from 'assets/icon/IconFile';
import IconInvoice from 'assets/icon/IconInvoice';
import IconPlus from 'assets/icon/IconPlus';
import IconProfile from 'assets/icon/IconProfile';
import IconUser from 'assets/icon/IconUser';
import classNames from 'classnames';
import { checkAddon } from 'common/utils/AddonUtils';
import { checkPermission } from 'common/utils/PermissionUtils';
import React, { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const QuickAdd = ({ handleOpenForm, isExpired = false }) => {
    const { t } = useTranslation(['header']);
    const addonsList = useSelector((state) => state.auth.user?.settings?.addons || {});
    const isPermissionDocument = checkAddon(addonsList?.documents);
    const permissionsList = useSelector((state) => state.auth.user?.permissions?.enabled || []);
    const isPermissionAddCustomer = checkPermission(permissionsList, 'appAddCustomer');
    const refDropDown = useRef(null);

    const _stopPropagation = (e) => {
        e && e.stopPropagation();
    };

    const _handleOpenForm = (e, type, params = null) => {
        _stopPropagation(e);
        handleOpenForm(type, params);
    };

    const _renderList = () => {
        return (
            <div id="show_list_quick_add" className="v2-dropdown__menu --center">
                <ul className="relative">
                    <div className="arrow">
                        <i className="up"></i>
                    </div>

                    {isPermissionAddCustomer ? (
                        <Fragment>
                            <li
                                onClick={(e) => _handleOpenForm(e, 'customer', { defaultStatus: 2 })}
                                className="items has-icon"
                                tabIndex="0"
                            >
                                <IconUser isAdd />
                                <p className="txt-ellipsis">{t('header:new_leads')}</p>
                            </li>
                            <li
                                onClick={(e) => _handleOpenForm(e, 'customer', { defaultStatus: 1 })}
                                className="items has-icon"
                                tabIndex="0"
                            >
                                <IconProfile />
                                <p className="txt-ellipsis">{t('header:new_customer')}</p>
                            </li>
                        </Fragment>
                    ) : null}

                    {isPermissionDocument && (
                        <li onClick={(e) => _handleOpenForm(e, 'document')} className="items has-icon" tabIndex="0">
                            <IconFille />
                            <p className="txt-ellipsis">{t('header:new_document')}</p>
                        </li>
                    )}
                    <li onClick={(e) => _handleOpenForm(e, 'invoice')} className="items has-icon" tabIndex="0">
                        <IconInvoice isNormal />
                        <p className="txt-ellipsis">{t('header:new_invoice')}</p>
                    </li>
                    <li onClick={(e) => _handleOpenForm(e, 'estimate')} className="items has-icon" tabIndex="0">
                        <IconEstimate isNormal />
                        <p className="txt-ellipsis">{t('header:new_estimate')}</p>
                    </li>
                    <li onClick={(e) => _handleOpenForm(e, 'paymnet')} className="items has-icon" tabIndex="0">
                        <IconAmount isNormal />
                        <p className="txt-ellipsis">{t('header:new_payment')}</p>
                    </li>
                </ul>
            </div>
        );
    };

    return (
        <div
            ref={refDropDown}
            className={classNames('v2-dropdown add-menu wrap-btn-primary', { 'is-disable': isExpired })}
        >
            <span className="btn-primary --icon svg-white">
                <IconPlus />
            </span>
            {_renderList()}
        </div>
    );
};

export default QuickAdd;

import React, { useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCalendarColors } from 'app/modules/calendar/const/Header';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconDone from 'assets/icon/IconDone';
import IconWorkOrder from 'assets/icon/IconWorkOrder';
import IconEditCard from 'assets/icon/IconEditCard';
import IconTypes from 'assets/icon/IconTypes';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { updateServiceColor } from 'common/redux/actions/calendar';
import { clientQuery } from 'common/utils/ApiUtils';
import { CALENDAR_STORE } from 'app/const/Api';
import { useHotkeys } from 'react-hotkeys-hook';
import { isIOS } from 'app/const/Flatform';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';
import TooltipPopper from 'app/components/tooltip/TooltipPopper';

const CalendarColor = () => {
    const { t } = useTranslation(['calendar']);
    const dispatch = useDispatch();
    const { color } = useSelector((state) => state.calendar);

    const [state, setState] = useReducer(reducer, {
        isVisible: false
    });
    const refDowpdown = useRef(null);

    const finalIsVisible = state.isVisible;

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_list_service_color');

        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeDropdown();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_list_service_color');
        if (
            refDowpdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDowpdown.current.contains(event.target)
        ) {
            _closeDropdown();
        }
    }

    function _closeDropdown() {
        finalIsVisible && setState({ isVisible: false });
    }

    function _handleOpen(e) {
        e.preventDefault();
        e.stopPropagation();

        setState({ isVisible: !finalIsVisible });
    }

    function _getIcon(id) {
        switch (id) {
            case 1:
                return <IconDone />;
            case 2:
                return <IconWorkOrder />;
            default:
                return <IconEditCard />;
        }
    }

    function _selectColor(e, colorSect) {
        e && e.stopPropagation();
        dispatch(updateServiceColor({ color: colorSect }));
        clientQuery(CALENDAR_STORE, { data: { value: colorSect, type: 5 }, method: 'PUT' });
        _closeDropdown();
    }

    useHotkeys('alt+1', () => {
        _selectColor(null, 1);
    });

    useHotkeys('alt+2', () => {
        _selectColor(null, 2);
    });

    useHotkeys('alt+3', () => {
        _selectColor(null, 3);
    });

    useHotkeys('alt+4', () => {
        _selectColor(null, 4);
    });

    function _renderColors() {
        return getCalendarColors().map((item) => {
            const typeColor = item.id;
            const isActive = typeColor === color;

            return (
                <li
                    className={`items ${isActive ? 'active' : ''}`}
                    onClick={(e) => _selectColor(e, typeColor)}
                    key={typeColor.toString()}
                >
                    <div className="icon-text">
                        {_getIcon(typeColor)}
                        {t(`report:${item.name}`)}
                    </div>
                    <span className="budget">{`${isIOS ? 'Option' : 'Alt'}+${typeColor}`}</span>
                </li>
            );
        });
    }

    return (
        <div ref={refDowpdown} className={`v2-dropdown list-calendarcolor ${finalIsVisible ? 'active' : ''} `}>
            <TooltipPopper
                onClick={_handleOpen}
                className={`dropbtn v2-btn-default tooltip`}
                tooltipText={t('calendar:color_codes')}
            >
                <span className="mr-1">
                    <IconTypes />
                </span>
                <span className="arrow">
                    <IconArrowDown />
                </span>
            </TooltipPopper>
            {finalIsVisible && (
                <div id="show_list_service_color" className="v2-dropdown__menu">
                    <ul>{_renderColors()}</ul>
                </div>
            )}
        </div>
    );
};

export default CalendarColor;

import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';
import { COMMON, LIST_TABLE_ITEM_TYPE } from '../App';

export const getGridColumns = (columns = [], actionsStatus = '') => {
    const HEADER_INVOICE = {
        account_number: {
            title: 'account_number_#',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        invoice_number: {
            title: 'invoice_#',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        customer: {
            title: 'customer',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        job_name: {
            title: 'job_name',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        frequency: {
            title: 'frequency',
            style: 'col col-frequency'
        },
        billing_email: {
            title: 'billing_email',
            style: 'col col-email',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        date: {
            title: 'date',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.DESC
        },
        total_format: {
            title: 'total',
            style: 'col col-total',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        invoice_tags: {
            title: 'invoice_tags',
            style: 'col --tags',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        status: {
            title: 'status',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        signature_status: {
            title: 'signature_status',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        invoice_discount: {
            title: 'invoice_discount',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        }
    };

    const REPORT_COLUMNS_INVOICE = [
        {
            id: 'account_number',
            title: 'account',
            status: true
        },
        {
            id: 'invoice_number',
            title: 'invoice_#',
            status: true
        },
        {
            id: 'customer',
            title: 'customer_name',
            status: true
        },
        {
            id: 'job_name',
            title: 'customer_name',
            status: true
        },
        {
            id: 'frequency',
            title: 'frequency',
            status: true
        },
        {
            id: 'billing_email',
            title: 'billing_email',
            status: true
        },
        {
            id: 'date',
            title: 'billing_email',
            status: true
        },
        { id: 'total_format', title: 'billing_email', status: true },
        { id: 'invoice_tags', title: 'invoice_tags', status: true },
        { id: 'status', title: 'status', status: true },
        { id: 'signature_status', title: 'signature_status', status: true },
        { id: 'invoice_discount', title: 'invoice_discount', status: true }
    ];

    const REPORT_CONTENT_INVOICE = {
        account_number: {
            id: 'account_number',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO,
            isAccountNumber: true
        },
        invoice_number: {
            id: 'invoice_number',
            style: 'col col-md',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.LINK
        },
        customer: {
            id: 'customer',
            style: 'col col-xl',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO
        },
        job_name: {
            id: 'job_name',
            style: 'col col-xl'
        },
        frequency: {
            id: 'frequency',
            style: 'col col-frequency',
            type: LIST_TABLE_ITEM_TYPE.INVOICE_FREQUENCY
        },
        billing_email: {
            id: 'billing_email',
            style: 'col col-email'
        },
        date: {
            id: 'date',
            style: 'col col-md'
        },
        total_format: {
            id: 'total_format',
            style: 'col col-total'
        },
        invoice_tags: {
            title: 'invoice_tags',
            style: 'col --tags',
            type: LIST_TABLE_ITEM_TYPE.TAGS_DISPLAY,
            keyGetValue: 'invoice_tags'
        },
        status: {
            id: 'status',
            style: 'col col-md d-flex',
            type: LIST_TABLE_ITEM_TYPE.STATUS
        },
        signature_status: {
            id: 'signature_status',
            style: 'col col-md d-flex',
            type: LIST_TABLE_ITEM_TYPE.STATUS
        },
        invoice_discount: {
            id: 'invoice_discount',
            style: 'col col-md',
            keyGetValue: 'invoice_discount.format',
            type: LIST_TABLE_ITEM_TYPE.TEXT
        }
    };

    const newHeader = mapCurrentValueHeader(actionsStatus, { ...HEADER_INVOICE });
    const newColumns = [];

    REPORT_COLUMNS_INVOICE.forEach((col) => {
        const item = col.id;
        if (columns.includes(item)) newColumns.push(col);
    });

    return {
        header: newHeader,
        columns: newColumns,
        contentConfig: REPORT_CONTENT_INVOICE
    };
};

import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import GdConfirm from 'app/components/confirm';
import GdGridView from 'app/components/grid/GdGridView';
import ModalLoading from 'app/components/loading/ModalLoading';
import GDModalWarning from 'app/components/modal/ModalWarning';
import SearchQBModal from 'app/components/quickbooks/SearchQBModal';
import { ADDONS_QUICKBOOKS_SYNC_LOG } from 'app/config/routes';
import { CUSTOMER_DETAIL_PAYMENTS, QUICK_BOOK_CUSTOMER_PAYMENTS } from 'app/const/Api';
import { KEY_REPORT_LOCAL_STORAGE, LIST_STATUS } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { TYPE_PAYMENT_FOR } from 'app/const/Customers';
import { reducer } from 'app/const/Reducer';
import { REPORT_TYPE } from 'app/const/Reports';
import { getColumnsPayments } from 'app/const/customer/CustomerPaymentTable';
import { QB_SYNC_STATUS, QUICK_BOOK_PAYMENT } from 'app/const/quickbook';
import { getDefaultParams } from 'app/const/report/ReportParams';
import CustomerEditCredit from 'app/modules/customer/detail/credits/components/EditForm';
import ReportPagination from 'app/modules/report/components/ReportPagination';
import { actionOpenEstimate } from 'common/redux/actions/estimateAction';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import AlertCustomer from '../../components/AlertCustomer';
import { customersPayments, handleAbortController, resetPagingLocation } from '../../utils';
import { CustomerDetailContext } from '../context/CustomerDetailContext';
import CustomerAddPayment from './components/CustomerAddPayment';
import CustomerEditPayment from './components/CustomerEditPayment';
import CustomerPaymentActions from './components/CustomerPaymentActions';

const keyLocalStorage = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.CUSTOMER_PAYMENTS);

const CustomerPayments = () => {
    const { t } = useTranslation('customers');
    const dispatch = useDispatch();
    const history = useHistory();
    const { id: customer_id } = useParams();
    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        checkedItems: { is_check_all: false, ids: [] },
        totalPage: 1,
        total: 0,
        isLoading: true
    });
    const { quickbooks } = useSelector(({ auth }) => auth.user.settings.addons);
    const {
        isLoading: isLoadCustomerSuccess,
        location_ids,
        unitSelected,
        reloadCustomerPage
    } = useContext(CustomerDetailContext);
    const currency = useSelector(({ auth }) => auth.user.settings.currency);

    const refQBModal = useRef(null);
    const refLoading = useRef(null);
    const refOldLocation = useRef(null);
    const refOldUnit = useRef(null);
    const refWarning = useRef(null);
    const refAlert = useRef(null);
    const refConfirm = useRef(null);
    const refEditPayment = useRef(null);
    const refAddPayment = useRef(null);
    const refEditCredit = useRef(null);
    const abortController = useRef(null);
    const refDuplicateInvoiceQB = useRef(true);

    const ids = state.checkedItems.ids;
    let paramsPayments = getLocalStorage(keyLocalStorage);

    if (!paramsPayments || paramsPayments.customer_id !== customer_id) {
        paramsPayments = getDefaultParams(REPORT_TYPE.CUSTOMER_PAYMENTS);
        paramsPayments.customer_id = customer_id;
        setLocalStorage(keyLocalStorage, paramsPayments);
    }

    useEffect(() => {
        if (checkReload(location_ids, unitSelected, customer_id) || reloadCustomerPage) {
            if (!state.isLoading) dispatchState({ isLoading: true });
            refOldLocation.current = location_ids;
            refOldUnit.current = unitSelected?.id;
            _getListPayments(
                resetPagingLocation({
                    params: paramsPayments || getLocalStorage(keyLocalStorage),
                    key: keyLocalStorage
                })
            );
        }

        return () => {
            handleAbortController(abortController);
        };
    }, [location_ids, unitSelected, reloadCustomerPage, customer_id]);

    const checkReload = (location_ids, unitSelected, customer_id) => {
        if (!location_ids || !unitSelected || !customer_id) return false;
        return location_ids !== refOldLocation.current || unitSelected?.id !== refOldUnit.current || !!customer_id;
    };

    const _getListPayments = (data, notShowLoading = false) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();

        const params = data || {};
        delete params?.currentPage;
        params.order = params.order.replace('methodName', 'method_name');
        params['customer_id'] = customer_id;
        const optionsQuery = {
            data: { ...params, location_ids, unit_ids: unitSelected.id },
            method: 'GET',
            abortController: abortController.current
        };

        if (ids.length !== 0 || !notShowLoading) {
            const paramReducer = {};

            if (!notShowLoading) {
                paramReducer.checkedItems = { is_check_all: false, ids: [] };
                paramReducer.isLoading = true;
            }
            dispatchState(paramReducer);
        }

        const _success = ({ data, total }) => {
            dispatchState({
                data: customersPayments(data, currency),
                isLoading: false,
                totalPage: Math.ceil(total / params.limit),
                total
            });
        };

        const _failed = ({ isAborted = false, message }) => {
            if (isAborted) return;
            dispatchState({ data: [], isLoading: false });
            refAlert.current.showStatusBar({ id: LIST_STATUS.ERROR, message, type: LIST_STATUS.ERROR });
        };

        clientQuery(CUSTOMER_DETAIL_PAYMENTS, optionsQuery, _success, _failed);
    };

    const _handleSelect = (checkedItems) => {
        dispatchState({ checkedItems });
    };

    const _handleDelete = () => {
        if (!ids.length) return refWarning.current._open();
        refConfirm.current.open();
    };

    const _handleConfirmDelete = () => {
        refLoading.current._open();

        const _handleSuccessDelete = ({ message }) => {
            refAlert.current.showStatusBar({ id: LIST_STATUS.SUCCESS, message, type: LIST_STATUS.SUCCESS });
            _getListPayments(getLocalStorage(keyLocalStorage));
        };
        const _handleSuccessFinally = () => refLoading.current._close();

        clientQuery(
            CUSTOMER_DETAIL_PAYMENTS,
            { data: { ids }, method: 'DELETE' },
            _handleSuccessDelete,
            null,
            _handleSuccessFinally
        );
    };

    const _handleUpdatePagination = () => {
        _getListPayments(getLocalStorage(keyLocalStorage));
    };

    const _handleEditPayment = (data) => {
        const methodName = data.method_name || '';
        const dataCreditEdit = {
            ...data,
            method_name: { name: methodName, id: methodName.trim().toLowerCase() },
            check_number: data.check_number,
            check_value: data.check_value,
            memo: data.memo,
            amount: data.amount
        };

        data.type === TYPE_PAYMENT_FOR.CREDIT
            ? refEditCredit.current._showModal(dataCreditEdit, true)
            : refEditPayment.current._open(dataCreditEdit);
    };

    const _handleAddPayment = () => {
        refAddPayment.current._open();
    };

    /** Handle when add payment success  */
    const _handleSubmitAddPayment = () => {
        _getListPayments(getLocalStorage(keyLocalStorage));
    };

    function _handleClick(row) {
        switch (row.type) {
            case TYPE_PAYMENT_FOR.PAYMENT:
                dispatch(
                    actionOpenInvoice({ id: row.invoice_id, status: row.invoice_status, total: '', isRecurring: false })
                );
                break;
            case TYPE_PAYMENT_FOR.DEPOSIT:
                dispatch(
                    actionOpenEstimate({ id: row.estimate_id, status: row.estimate_status, isEstimateCustomer: true })
                );
                break;
            default:
                break;
        }
    }

    const _editSuccess = (message) => {
        refAlert.current.showStatusBar({ id: LIST_STATUS.SUCCESS, message, type: LIST_STATUS.SUCCESS });
        _getListPayments(getLocalStorage(keyLocalStorage));
    };

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: REPORT_TYPE.CUSTOMER_PAYMENTS,
            columnsTarget,
            currentValue,
            paramsReport: paramsPayments,
            callBack: _handleUpdatePagination
        });
    };

    const _handleQBSyncIds = () => {
        clientQuery(
            QUICK_BOOK_CUSTOMER_PAYMENTS,
            { data: { ids: ids }, method: 'POST' },
            null,
            null,
            quickBooksFinally
        );
    };

    const quickBooksFinally = () => {
        history.push(addBranchPath(ADDONS_QUICKBOOKS_SYNC_LOG));
    };

    const _handleFindQuickBook = (data) => {
        refQBModal.current._open(null, null, data?.invoice_id, data?.number);
    };

    const _handleDeleteInvoiceSuccessQB = (itemChecked, isErrorDuplicate) => {
        refDuplicateInvoiceQB.current = isErrorDuplicate;
        dispatchState((prevState) => ({
            ...prevState,
            data: prevState.data.map((item) => {
                if (item.number === itemChecked.number) item.quickbooks.status = QB_SYNC_STATUS.FAIL;
                return item;
            })
        }));
    };

    return (
        <>
            <div className="wrapper-columns">
                <div className="container-print contents-pages invoice-page">
                    <div className="wrap-tables flex-column relative">
                        <AlertCustomer ref={refAlert} />
                        <CustomerPaymentActions
                            totalItems={state.total}
                            totalChecked={ids.length}
                            onDelete={_handleDelete}
                            onHandleQuickBook={_handleQBSyncIds}
                            onAddPayment={_handleAddPayment}
                            isLoadCustomerSuccess={isLoadCustomerSuccess}
                            checkedItems={state.checkedItems}
                        />
                        <GdGridView
                            msgEmpty={t('no_payments')}
                            showCheckBox
                            content={state.data}
                            isLoading={state.isLoading}
                            classTable="has-footer has-checkbox scrolls-x"
                            fileTranslation="report"
                            checkedItems={state.checkedItems}
                            onChangeStateCheckedItems={_handleSelect}
                            onEdit={_handleEditPayment}
                            handleClick={_handleClick}
                            handleClickHeader={_handleActionHeader}
                            // for quick books
                            typeQuickBook={QUICK_BOOK_PAYMENT}
                            styleQBSync="col --qb flex-none"
                            isDuplicateInvoiceQB={refDuplicateInvoiceQB.current}
                            onSearchQB={_handleFindQuickBook}
                            {...getColumnsPayments(paramsPayments?.order, quickbooks)}
                            isScroll
                            isShowToolTip
                        />
                        <ReportPagination
                            isCustomerPage
                            reportType={REPORT_TYPE.CUSTOMER_PAYMENTS}
                            totalPage={state.totalPage || 1}
                            onSelect={_handleUpdatePagination}
                        />
                    </div>
                    {/* Modals */}
                    <CustomerAddPayment ref={refAddPayment} onSubmit={_handleSubmitAddPayment} isPaymentCustomer />
                    <CustomerEditPayment ref={refEditPayment} editSuccess={_editSuccess} />
                    <CustomerEditCredit ref={refEditCredit} onEditSuccess={_editSuccess} />
                    <GDModalWarning
                        ref={refWarning}
                        title={t('warning_payment_title')}
                        description={t('warning_payment_description')}
                    />
                    <GdConfirm
                        ref={refConfirm}
                        title={t('common:notification')}
                        renderContent={
                            <>
                                <p>{t('selected_permanent_delete_payment', { total: ids.length })}</p>
                                <p>{t('this_action_cant_reversed')}</p>
                            </>
                        }
                        listButton={{ confirm: true, cancel: true }}
                        onConfirm={_handleConfirmDelete}
                    />
                    <ModalLoading ref={refLoading} />
                </div>
            </div>

            {!!quickbooks ? (
                <SearchQBModal ref={refQBModal} onHandleDeleteInvoiceSuccessQB={_handleDeleteInvoiceSuccessQB} />
            ) : null}
        </>
    );
};

export default CustomerPayments;

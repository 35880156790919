export const JOB_UNCONFIRMED = '0';
export const JOB_CONFIRMED = '1';
export const JOB_COMPLETED = '2';
export const JOB_RESCHEDULE = '3';
export const JOB_PENDING_CONFIRMATION = '4';
export const JOB_CANCELED = '5';
export const JOB_RECURRENCE = '6';
export const JOB_PENDING_BOOKING = '7';
export const JOB_CUSTOM = '8';
export const JOB_TERMINATE_SERVICE = '9';
export const NEW_JOB_REPORT = 'NEW_JOB_REPORT';
export const REPORT_TABLE = 'REPORT_TABLE';
export const NOTIFY_TECH = 'notify_tech';
export const NOTIFY_CUSTOMER = 'notify_customer';

export const ACTIONS_RECURRING = {
    ONLY: 0,
    RECURRING: 1
};

export const DEVICE_ACTIVITY_TYPES = {
    DISABLED: 1,
    ACTIVE: 2
};

export const JOB_EXCLUDE = {
    NEW_JOB: [JOB_UNCONFIRMED, JOB_CONFIRMED],
    JOB_DETAIL: [JOB_PENDING_CONFIRMATION, JOB_PENDING_BOOKING, JOB_RECURRENCE],
    JOB_DETAIL_STATUS: [JOB_RECURRENCE],
    ROUTE: [JOB_COMPLETED, JOB_CANCELED, JOB_RECURRENCE, JOB_TERMINATE_SERVICE],
    BATCH_ACTION: [JOB_COMPLETED, JOB_CANCELED, JOB_RECURRENCE, JOB_TERMINATE_SERVICE],
    WORKPOOL: [JOB_COMPLETED, JOB_CANCELED, JOB_RECURRENCE, JOB_PENDING_BOOKING, JOB_TERMINATE_SERVICE],
    DEFAULT: [],
    SEND_ACTION: [JOB_RECURRENCE],
    PRINT_SCHEDULE: [JOB_RECURRENCE],
    [REPORT_TABLE]: [JOB_PENDING_BOOKING],
    MANAGE_CUSTOM_JOB: [JOB_RECURRENCE, JOB_PENDING_BOOKING, JOB_PENDING_CONFIRMATION],
    SYNC_GOOGLE_CALENDAR: [JOB_PENDING_BOOKING],
    MOVE_JOB: [JOB_COMPLETED, JOB_CANCELED, JOB_PENDING_BOOKING, JOB_TERMINATE_SERVICE],
    RESIZE_JOB: [JOB_COMPLETED, JOB_CANCELED, JOB_TERMINATE_SERVICE],
    [NEW_JOB_REPORT]: [JOB_PENDING_BOOKING, JOB_RECURRENCE]
};

export const JOB_STATUS_ITEM_ACTION = {
    ADD: 'ADD',
    EDIT: 'EDIT',
    DELETE: 'DELETE'
};

export const LIST_JOB_STATUS_SEND_CONFIRMATIONS = [JOB_UNCONFIRMED, JOB_PENDING_CONFIRMATION];
export const LIST_JOB_STATUS_ACTIVE_NOTIFY_CUSTOMER = [JOB_UNCONFIRMED, JOB_PENDING_CONFIRMATION, JOB_CONFIRMED];
export const TYPE_CONFIRMATIONS_JOB = { REMINDERS: 'reminder', CONFIRMATIONS: 'confirmation' };

import { URL_EXPORT_ESTIMATE, URL_PRINT_ESTIMATE } from 'app/const/api/Export';
import { ACCESS_TOKEN, DEFAULT_ALL, KEY_REPORT_LOCAL_STORAGE, LIST_STATUS, TYPE_BUTTON_ACTIONS } from 'app/const/App';
import { getGridColumns } from 'app/const/report/Estimates';
import { ESTIMATE_LIST_FILTER } from 'app/const/report/ReportFilter';
import {
    getListButtonEstimate,
    MODE_VIEW_REPORT,
    REPORT_BATCH_ACTION_ITEMS,
    REPORT_LIST_BATCH_ACTIONS,
    REPORT_TYPE
} from 'app/const/report/ReportTypeContent';
import {
    activeReportEstimatesRequest,
    archiveReportEstimatesRequest,
    deleteReportEstimatesRequest,
    trashReportEstimatesRequest,
    unDeleteReportEstimatesRequest
} from 'common/redux/actions/reports/estimateAction';
import { getLocalStorage, getLocalStorageValue } from 'common/utils/LocalStorageUtils';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LIST_EXPORT } from 'app/const/report/Common';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { INVOICE_DETAIL_TYPE } from 'app/const/customer/CustomerInvoices';
import { actionOpenEstimate } from 'common/redux/actions/estimateAction';
import { REPORT_BATCH_LIMIT, REPORT_STATUS_STYLE, SIGNATURE_STATUS } from 'app/const/Reports';
import { clientQuery } from 'common/utils/ApiUtils';
import { REPORT_ESTIMATE_SEND_MAIL, REPORT_ESTIMATE_GET_LIST } from 'app/const/Api';
import { getLocalParamsReport } from 'common/utils/ReportUtils';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';

import { reducer } from 'app/const/Reducer';
import classNames from 'classnames';
import loadable from '@loadable/component';
import { REPORT_LIMIT, REPORT_LIMIT_LOADMORE } from 'app/const/Reports';
import HeaderBottom from '../components/HeaderBottom';
import { getBranchId } from 'app/const/Branch';

const GdConfirm = loadable(() => import('app/components/confirm'));
const GDStatusBar = loadable(() => import('app/components/status/statusbar'));
const ModalLoading = loadable(() => import('app/components/loading/ModalLoading'));
const Export = loadable(() => import('app/modules/report/components/Export'));
const MainHeaderReport = loadable(() => import('app/modules/report/components/MainHeader'));
const ReportSearch = loadable(() => import('app/modules/report/components/ReportSearch'));
const GdButton = loadable(() => import('app/components/button'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const SingleSelect = loadable(() => import('app/components/select/singleSelect'));
const CheckBoxHeader = loadable(() => import('app/modules/report/components/CheckBoxHeader'));
const RealtimeEstimateList = loadable(() => import('./RealtimeEstimateList'));

function ReportEstimate() {
    const { t } = useTranslation(['report']);
    const dispatch = useDispatch();
    const refConfirm = useRef(null);
    const refAlert = useRef(null);
    const refLoading = useRef(null);
    const abortController = useRef(null);
    const refConfirmLimit = useRef(null);

    const keyEstimateLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.ESTIMATE);
    const paramsReport = getLocalParamsReport(keyEstimateLocal, REPORT_TYPE.ESTIMATE);

    const [dataReport, dispatchActionReport] = useReducer(reducer, {
        refresh: false,
        dataProgressBar: false,
        checkedItems: { is_check_all: false, ids: [] },
        dataStatusBar: [],
        data: [],
        isLoading: true,
        isLoadingMore: false,
        total: 0,
        refreshScreen: 0,
        reloadFilter: 0,
        actionSuccess: 0
    });

    const {
        checkedItems: finalCheckedItems,
        refreshScreen,
        isLoadingMore,
        total: finalTotal,
        isLoading: finalIsLoading,
        data: finalData,
        actionSuccess
    } = dataReport;
    const ids = finalCheckedItems.ids;
    const numberCurrentData = finalData.length;

    const _handleRealTimeStatus = (responseStatus = {}) => {
        dispatchActionReport((prev) => {
            return {
                ...prev,
                data: prev.data.map((item) => {
                    if (item.id === responseStatus.id) return { ...item, status: responseStatus.status };
                    return item;
                })
            };
        });
    };

    useEffect(() => {
        getListReport(true, paramsReport);
    }, []);

    useEffect(() => {
        if (
            actionSuccess &&
            numberCurrentData < finalTotal &&
            numberCurrentData < REPORT_LIMIT_LOADMORE &&
            !finalIsLoading &&
            !isLoadingMore
        ) {
            dispatchActionReport((prev) => {
                return {
                    ...prev,
                    isLoadingMore: true
                };
            });
        }
    }, [actionSuccess]);

    useEffect(() => {
        if (isLoadingMore) {
            setTimeout(() => {
                getListReport(
                    false,
                    { ...paramsReport, offset: finalData.length, reloadFilter: dataReport.reloadFilter },
                    true
                );
            }, 200);
        }
    }, [isLoadingMore]);

    const getListReport = (isReset = false, params, notShowLoading = false) => {
        params = getNewAPIRequest(params);

        abortController.current = new AbortController();

        const checkItemChecked = ids.length !== 0;
        const checkShouldLoading = !notShowLoading && !finalIsLoading;
        const checkRefresh = dataReport.reloadFilter;

        if (checkItemChecked || checkShouldLoading) {
            dispatchActionReport((prev) => {
                return {
                    ...prev,
                    total: isReset ? 0 : prev.total,
                    data: isReset ? [] : prev.data,
                    isLoading: !!checkShouldLoading || prev.isLoading,
                    checkedItems: checkShouldLoading ? { is_check_all: false, ids: [] } : prev.checkedItems
                };
            });
        }

        delete params?.columns;
        delete params?.filterTab;
        delete params?.currentPage;

        clientQuery(
            REPORT_ESTIMATE_GET_LIST,
            { data: params, method: 'GET', abortController: abortController.current },
            (response) => getListSuccess(response, checkRefresh, isReset),
            getListFailed
        );
    };

    const getNewAPIRequest = (params) => {
        const newParams = { ...params };

        if (!newParams.total) {
            newParams.total = 1;
        }

        newParams.status = newParams.status.toString();
        const paramSignStatus = newParams.sign_status || DEFAULT_ALL;

        if (Array.isArray(paramSignStatus)) {
            const indexStatus = paramSignStatus.findIndex((status) => status === SIGNATURE_STATUS);

            if (indexStatus !== -1) {
                paramSignStatus[indexStatus] = REPORT_STATUS_STYLE.draft;
            }
        }
        newParams['sign_status'] = paramSignStatus.toString();
        newParams.limit = REPORT_LIMIT;
        return newParams;
    };

    const getListSuccess = ({ data, total }, tabQuery, isReset) => {
        dispatchActionReport((prev) => {
            const { checkedItems, refreshScreen, data: prevData } = prev;
            const checkDiffrentTabs = !!tabQuery && prev.reloadFilter !== tabQuery;
            const newData = isReset ? data : checkDiffrentTabs ? prev.data : [...prevData, ...data];

            return {
                ...prev,
                isLoading: false,
                isLoadingMore: false,
                data: newData,
                total: total,
                refreshScreen: refreshScreen + 1,
                checkedItems: {
                    ...checkedItems,
                    ids: _handleLimitCheckedItems(
                        checkedItems.is_check_all ? newData.map((e) => e.id) : checkedItems.ids
                    )
                }
            };
        });
    };

    function getListFailed() {
        dispatchActionReport({ isLoading: false, isLoadingMore: false });
    }

    const handleChangeFilter = (params, mode) => {
        if (mode && mode === 'columns') dispatchActionReport({ refresh: !dataReport.refresh });
    };

    const _handleUpdate = () => {
        abortController.current.abort();
        getListReport(true, getLocalStorage(keyEstimateLocal));
    };

    const onChangeStateCheckedItems = (stateCheckedItems) => {
        dispatchActionReport((prev) => ({
            ...prev,
            checkedItems: { ...stateCheckedItems, ids: _handleLimitCheckedItems(stateCheckedItems?.ids) }
        }));
    };

    const _handleScroll = () => {
        const numberData = finalData.length;

        if (!!numberData && !isLoadingMore && finalTotal > numberData) {
            const refreshId = Date.now();
            dispatchActionReport((prev) => {
                return { ...prev, isLoadingMore: true, reloadFilter: refreshId };
            });
        }
    };

    const handleBatchActions = (name, value) => {
        switch (value) {
            case REPORT_BATCH_ACTION_ITEMS.ESTIMATES_PRINT:
                handleOnClickExport(false, false, true);
                break;
            default:
                refConfirm.current.open(value, t('report:confirm_send_email_estimate', { total: ids.length }));
                break;
        }
    };

    const _handleSendInvoices = (value) => {
        refLoading.current._open();
        clientQuery(
            REPORT_ESTIMATE_SEND_MAIL,
            {
                data: { value: value, ids: ids },
                method: 'POST'
            },
            _handleSendInvoiceSuccess,
            _handleSendInvoiceFail,
            _handleSendInvoiceFinally
        );
    };

    function _handleSendInvoiceSuccess(response) {
        refAlert.current.handeAddStatus({
            id: `${Date.now()}_success`,
            message: response.message || t('report:send_estimate_reminders_success'),
            type: LIST_STATUS.SUCCESS
        });
    }

    const _handleSendInvoiceFail = (response) => {
        refAlert.current.handeAddStatus({
            id: `${Date.now()}_error`,
            message: response.message || t('report:send_fail'),
            type: LIST_STATUS.ERROR
        });
    };

    const _handleSendInvoiceFinally = () => {
        refLoading.current._close();
        dispatchActionReport({ checkedItems: { is_check_all: false, ids: [] } });
    };

    const handleClickButton = (value) => {
        dispatchActionReport((prev) => {
            return {
                ...prev,
                data: [...prev.data].map((item) => {
                    if (ids.includes(item.id)) {
                        item.manualHide = true;
                    }
                    return item;
                }),
                total: prev?.total - ids.length,
                checkedItems: { is_check_all: false, ids: [] }
            };
        });

        switch (value) {
            case TYPE_BUTTON_ACTIONS.DELETE:
                dispatch(
                    deleteReportEstimatesRequest(
                        { ids: ids },
                        (response) => clickButtonSuccess(response, ids),
                        _handleActionFailed
                    )
                );
                break;
            case TYPE_BUTTON_ACTIONS.ACTIVE:
                dispatch(
                    activeReportEstimatesRequest(
                        { ids: ids, type: value },
                        (response) => clickButtonSuccess(response, ids),
                        _handleActionFailed
                    )
                );
                break;
            case TYPE_BUTTON_ACTIONS.ARCHIVE:
                dispatch(
                    archiveReportEstimatesRequest(
                        { ids: ids, type: value },
                        (response) => clickButtonSuccess(response, ids),
                        _handleActionFailed
                    )
                );
                break;
            case TYPE_BUTTON_ACTIONS.UNDELETE:
                dispatch(
                    unDeleteReportEstimatesRequest(
                        { ids: ids, type: value },
                        (response) => clickButtonSuccess(response, ids),
                        _handleActionFailed
                    )
                );
                break;
            case TYPE_BUTTON_ACTIONS.TRASH:
                dispatch(
                    trashReportEstimatesRequest(
                        { ids: ids },
                        (response) => clickButtonSuccess(response, ids),
                        _handleActionFailed
                    )
                );
                break;
            default:
                break;
        }
    };

    function _handleActionFailed(response) {
        refAlert.current.handeAddStatus({
            id: `${Date.now()}_error`,
            message: Array.isArray(response.message) ? response.message : [],
            type: LIST_STATUS.SUCCESS
        });
    }

    const clickButtonSuccess = (response, idsParam) => {
        const errorResponse = response.error;
        const idsFailed = errorResponse?.items || [];
        const numberIdsFailed = idsFailed.length;

        if (!!errorResponse || !!numberIdsFailed) {
            refAlert.current.handeAddStatus({
                id: `${Date.now()}_error`,
                message: errorResponse.message,
                type: LIST_STATUS.ERROR
            });
        }

        refAlert.current.handeAddStatus({
            id: `${Date.now()}_sussess`,
            message: response.message,
            type: LIST_STATUS.SUCCESS
        });

        dispatchActionReport((prev) => {
            const prevData = [...prev.data];
            const prevCheckedIds = prev.checkedItems.ids;

            return {
                ...prev,
                checkedItems: {
                    is_check_all: numberIdsFailed === idsParam.length,
                    ids: prevCheckedIds.filter((item) => idsFailed.includes(item))
                },
                data: prevData
                    .map((item) => {
                        if (idsFailed.includes(item.id)) {
                            item.manualHide = false;
                        }
                        return item;
                    })
                    .filter((itemRemove) => !itemRemove.manualHide),
                total: prev.total + numberIdsFailed,
                actionSuccess: Date.now()
            };
        });
    };

    function handleOnClickExport(e, isCsv, actionPrint) {
        e && e.preventDefault();

        const token = getLocalStorageValue(ACCESS_TOKEN);
        const typeExport = isCsv ? 'csv' : 'xls';
        const form = document.createElement('form');

        form.action = actionPrint ? URL_PRINT_ESTIMATE : URL_EXPORT_ESTIMATE;
        form.method = 'POST';
        form.style.display = 'none';
        form.className = 'export-ESTIMATE-history';

        form.innerHTML = `
            <input name="token" value="${token}" >
            <input name="type" value="${typeExport}" >
            <input name="start" value="${paramsReport.start}" >
            <input name="end" value="${paramsReport.end}" >
            <input name="ids" value="${ids}" >
            <input name="gd-branch-id" value="${getBranchId()}">
        `;

        form.setAttribute('target', '_blank');
        document.body.append(form);
        form.submit();

        const elements = document.getElementsByClassName('export-ESTIMATE-history');
        elements.length > 0 && elements[0].parentNode.removeChild(elements[0]);

        return false;
    }

    const renderListActionButton = () => {
        if (!paramsReport) {
            return false;
        }

        let finalMode = MODE_VIEW_REPORT.ACTIVE;

        switch (paramsReport.deleted - paramsReport.archived) {
            case 1:
                finalMode = MODE_VIEW_REPORT.DELETE;
                break;
            case 0:
                finalMode = MODE_VIEW_REPORT.ACTIVE;
                break;
            default:
                finalMode = MODE_VIEW_REPORT.ARCHIVE;
                break;
        }

        return getListButtonEstimate(finalMode).map((item) => {
            return (
                <GdButton
                    key={item.id}
                    title={t(`report:${item.label}`)}
                    onClick={() => {
                        handleClickButton(item.value);
                    }}
                    className={item.className}
                    iconClassName={item.iconClassName}
                    iconSvg={item.iconSvg}
                />
            );
        });
    };

    function _renderHeaderRight() {
        return (
            <Fragment>
                <Export
                    title={t('report:records', { count: finalTotal })}
                    activePrint
                    params={paramsReport}
                    url={URL_EXPORT_ESTIMATE}
                    pageExport={LIST_EXPORT.EXPORT_REPORT_ESTIMATE}
                    isDisable={finalIsLoading}
                    refresh={refreshScreen}
                />
                <ReportSearch
                    reportType={REPORT_TYPE.ESTIMATE}
                    placeholder={t('report:search')}
                    onKeyEnter={_handleUpdate}
                />
            </Fragment>
        );
    }

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType: actionType,
            reportType: REPORT_TYPE.ESTIMATE,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleUpdate
        });
    };

    function _renderHeaderBootom() {
        return (
            <Fragment>
                <div className={classNames('header --filter', { 'is-disable': !ids.length })}>
                    <CheckBoxHeader isShowTotal total={finalTotal} checkedItems={finalCheckedItems} />
                    {renderListActionButton()}
                    <SingleSelect
                        options={REPORT_LIST_BATCH_ACTIONS.ESTIMATE}
                        title="batch_actions"
                        fileTranslation="report"
                        name="batch_actions"
                        onSelect={handleBatchActions}
                        classWrapper={'header-items'}
                    />
                </div>
            </Fragment>
        );
    }

    function _handleUpdateEstimate({ data }) {
        dispatchActionReport((prev) => {
            return {
                ...prev,
                data: [...prev.data].map((item) => {
                    if (item.id === data.id) {
                        return {
                            ...item,
                            date: data.date.format,
                            total: data.total.value,
                            status: data.status,
                            signature_status: data.sign_status
                        };
                    }
                    return item;
                })
            };
        });
    }

    const _handleClickTable = ({ row }) => {
        dispatch(
            actionOpenEstimate({
                id: row.id,
                status: row.status,
                total: row.total,
                onTriggerUpdated: _handleUpdateEstimate
            })
        );
    };

    const _handleOpenInvoice = (item) => {
        dispatch(
            actionOpenInvoice({
                id: item.invoice.id,
                status: item.status,
                total: item.total,
                isRecurring: false,
                type: INVOICE_DETAIL_TYPE.NORMAL
            })
        );
    };

    const _handleLimitCheckedItems = (ids = []) => {
        if (ids.length <= REPORT_BATCH_LIMIT) return ids;

        refConfirmLimit.current?.open();
        return ids.slice(0, REPORT_BATCH_LIMIT);
    };

    return (
        <Fragment>
            <MainHeaderReport
                contentRight={_renderHeaderRight}
                reportType={REPORT_TYPE.ESTIMATE}
                onSelectTab={_handleUpdate}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages gap-8">
                    <GDStatusBar ref={refAlert} />
                    <HeaderBottom
                        typeReport={REPORT_TYPE.ESTIMATE}
                        filters={ESTIMATE_LIST_FILTER}
                        isLoading={finalIsLoading}
                        handleChangeFilter={handleChangeFilter}
                        handleUpdate={_handleUpdate}
                    />

                    <div className="wrap-tables flex-column relative">
                        {_renderHeaderBootom()}
                        <GdGridView
                            isEmptyFlat
                            isLoading={finalIsLoading}
                            classTable="has-checkbox scrolls-x table-multi-column has-text-ellipsis"
                            classTableContent=""
                            content={finalData.filter((item) => !item.manualHide)}
                            showCheckBox
                            fileTranslation={'report'}
                            handleClick={(value) => {
                                _handleClickTable(value);
                            }}
                            handleClickHeader={_handleActionHeader}
                            onOpenInvoice={_handleOpenInvoice}
                            checkedItems={finalCheckedItems}
                            onChangeStateCheckedItems={onChangeStateCheckedItems}
                            {...getGridColumns(paramsReport?.columns, paramsReport?.order)}
                            isScroll
                            isLoadmore={isLoadingMore}
                            onScrollToEnd={_handleScroll}
                        />
                    </div>

                    <GdConfirm
                        ref={refConfirm}
                        title={t('report:send_estimate_reminders')}
                        listButton={{ cancel: true, confirm: true }}
                        titleConfirm={t('report:send')}
                        onConfirm={_handleSendInvoices}
                    />
                    <ModalLoading ref={refLoading} />
                    <GdConfirm
                        ref={refConfirmLimit}
                        title={t('reminder')}
                        message={t('confirm_limit')}
                        listButton={{ confirm: true, cancel: true }}
                        titleConfirm={t('common:confirm')}
                    />
                    <RealtimeEstimateList onUpdate={_handleRealTimeStatus} />
                </div>
            </div>
        </Fragment>
    );
}

export default ReportEstimate;

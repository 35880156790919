import GdGridView from 'app/components/grid/GdGridView';
import { KEY_REPORT_LOCAL_STORAGE, LIST_STATUS } from 'app/const/App';
import { getGridColumnsCustomerCredits } from 'app/const/customer/CustomerCredits';
import { clientQuery } from 'common/utils/ApiUtils';
import { getListCreditCustomer, QUICK_BOOK_CREDIT_SYNC, REPORT_CREDIT_DELETE } from 'app/const/Api';
import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
    customerCredits,
    handleAbortController,
    numberItemChecked,
    resetPagingLocation
} from 'app/modules/customer/utils';
import ReportPagination from 'app/modules/report/components/ReportPagination';
import { REPORT_TYPE } from 'app/const/Reports';
import { getDefaultParams } from 'app/const/report/ReportParams';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import ActionHeader from './components/ActionHeader';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import ModalLoading from 'app/components/loading/ModalLoading';
import CreditForm from 'app/modules/customer/detail/credits/components/CreditForm';
import EditForm from 'app/modules/customer/detail/credits/components/EditForm';
import moment from 'moment';
import { convertPhpFormatToMoment } from 'common/utils/DateUtils';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import GDModalWarning from 'app/components/modal/ModalWarning';
import ButtonSave from 'app/components/button/ButtonSave';
import { useSelector } from 'react-redux';
import { QUICK_BOOK_CREDIT } from 'app/const/quickbook';
import { ADDONS_QUICKBOOKS_SYNC_LOG } from 'app/config/routes';
import { CustomerDetailContext } from '../context/CustomerDetailContext';
import { reducer } from 'app/const/Reducer';
import { addBranchPath } from 'app/const/Branch';
import { APP_INVOICE_CARD } from 'app/const/Permissions';
import { checkPermission } from 'common/utils/PermissionUtils';
import ErrorPage from 'app/modules/error';

const CustomerCredits = () => {
    const dateFormatCompany = useSelector(({ auth }) => auth.user.company.date_format);
    const keyLocalStore = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.CREDIT_CUSTOMER_LIST);
    const currency = useSelector(({ auth }) => auth.user.settings.currency);
    const permissionsList = useSelector((state) => state.auth.user?.permissions?.enabled || []);
    const isPermissionInvoiceCard = checkPermission(permissionsList, APP_INVOICE_CARD);

    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        checkedItems: { is_check_all: false, ids: [] },
        totalPage: 1,
        isLoading: true
    });
    const { t } = useTranslation(['report', 'customers']);
    const { id: customerId } = useParams();
    const history = useHistory();
    const { quickbooks } = useSelector(({ auth }) => auth.user.settings?.addons);
    const { isLoading: isLoadCustomerSuccess, reloadCustomerPage } = useContext(CustomerDetailContext);
    const { data: listCredit, checkedItems, isLoading } = state;
    const ids = checkedItems.ids;
    const totalSelected = ids.length || 0;
    const refAlert = useRef(null);
    const refConfirm = useRef(null);
    const refLoading = useRef(null);
    const refCreditForm = useRef(null);
    const refEditForm = useRef(null);
    const refButtonSave = useRef(null);
    const abortController = useRef(null);

    const paramsCustomersCredit = getLocalStorage(keyLocalStore);
    !paramsCustomersCredit && setLocalStorage(keyLocalStore, getDefaultParams(REPORT_TYPE.CREDIT_CUSTOMER_LIST));

    useEffect(() => {
        const initParams =
            paramsCustomersCredit ||
            getLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.CREDIT_CUSTOMER_LIST));

        if (!isLoading) {
            dispatchState({ isLoading: true });
        }

        _getListCredit(resetPagingLocation({ params: initParams, key: keyLocalStore }));

        return () => {
            handleAbortController(abortController);
        };
    }, [customerId, reloadCustomerPage]);

    const _getListCredit = (params, notShowLoading = false) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();

        if (!notShowLoading) {
            const paramReducer = {};
            paramReducer.checkedItems = { is_check_all: false, ids: [] };
            paramReducer.isLoading = true;
            dispatchState(paramReducer);
        }

        const _success = ({ data, total }) => {
            dispatchState({
                data: customerCredits(data, currency),
                isLoading: false,
                totalPage: Math.ceil(total / params.limit),
                total
            });
        };
        const _failed = ({ isAborted = false }) => {
            if (isAborted) return;
            dispatchState({ data: [], isLoading: false });
        };
        clientQuery(
            getListCreditCustomer(customerId),
            { data: params, method: 'GET', abortController: abortController.current },
            _success,
            _failed
        );
    };

    const onChangeStateCheckedItems = (checkedItems) => {
        dispatchState({ checkedItems });
    };

    const _handleUpdate = () => {
        _getListCredit(getLocalStorage(keyLocalStore));
    };

    const _handleDeleteCredit = () => {
        refConfirm.current._open({
            description: numberItemChecked(
                totalSelected,
                t('customers:warning_credits_description', { total: totalSelected }),
                t('customers:warning_credit', { total: totalSelected })
            ),
            subDescription: t('customers:this_action_cant_reversed')
        });
    };

    const _handleCloseDeleteCredit = () => {
        refConfirm.current._close();
    };

    const _handleConfirmDelete = () => {
        refLoading.current._open();

        const _handleSuccessDelete = ({ message }) => {
            refAlert.current.showStatusBar({
                id: LIST_STATUS.SUCCESS,
                message,
                type: LIST_STATUS.SUCCESS
            });

            refButtonSave.current.removeLoading();
            _handleCloseDeleteCredit();
            _getListCredit(getLocalStorage(keyLocalStore));
        };

        const _handleSuccessFinally = () => refLoading.current._close();

        clientQuery(
            REPORT_CREDIT_DELETE,
            { data: { ids }, method: 'DELETE' },
            _handleSuccessDelete,
            null,
            _handleSuccessFinally
        );
    };

    const handleAddCredit = () => {
        refCreditForm.current.showModal();
    };

    const handleCreateCreditSuccess = (data, message) => {
        _getListCredit(getLocalStorage(keyLocalStore));

        refAlert.current.showStatusBar({
            id: LIST_STATUS.SUCCESS,
            message,
            type: LIST_STATUS.SUCCESS
        });

        refCreditForm.current.hideModal();
    };

    const handleCreateCreditFail = (message) => {
        refAlert.current.showStatusBar({
            id: LIST_STATUS.ERROR,
            message,
            type: LIST_STATUS.ERROR
        });

        refCreditForm.current.hideModal();
    };

    const handleEditCredit = (data) => {
        const { method_name } = data;

        // const methodId = method.split(/[#/]/g).map((x) => x.trim().toLowerCase());

        const objMethod = { name: data.method_name, id: method_name.trim().toLowerCase() };
        const formatDate = moment(data.date, convertPhpFormatToMoment(dateFormatCompany));

        const dataCreditEdit = {
            ...data,
            amount: data.amount,
            date: formatDate,
            method_name: objMethod || '',
            check_number: data.check_number || '',
            check_value: data.check_value || '',
            memo: data.check_number || ''
        };

        refEditForm.current._showModal(dataCreditEdit);
    };

    const _handleEditCreditSuccess = (message) => {
        refAlert.current.showStatusBar({
            id: LIST_STATUS.SUCCESS,
            message,
            type: LIST_STATUS.SUCCESS
        });

        refEditForm.current._hideModal();

        _getListCredit(getLocalStorage(keyLocalStore));
    };

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: REPORT_TYPE.CREDIT_CUSTOMER_LIST,
            columnsTarget,
            currentValue,
            paramsReport: paramsCustomersCredit,
            callBack: _handleUpdate
        });
    };

    const _handleQBSyncIds = () => {
        clientQuery(QUICK_BOOK_CREDIT_SYNC, { data: { ids: ids }, method: 'POST' }, null, null, quickBooksFinally);
    };

    const quickBooksFinally = () => {
        history.push(addBranchPath(ADDONS_QUICKBOOKS_SYNC_LOG));
    };

    if (!isPermissionInvoiceCard) return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;

    return (
        <>
            <div className="wrapper-columns">
                <div className="container-print contents-pages">
                    <div className="wrap-tables flex-column relative">
                        <AlertCustomer ref={refAlert} />
                        <ActionHeader
                            totalItems={state.total}
                            onDelete={_handleDeleteCredit}
                            onAddCredit={handleAddCredit}
                            isLoadCustomerSuccess={isLoadCustomerSuccess}
                            checkedItems={checkedItems}
                            handleUpdate={_handleUpdate}
                            onHandleQuickBook={_handleQBSyncIds}
                        />
                        <GdGridView
                            isLoading={isLoading}
                            classTable="has-checkbox has-footer scrolls-x"
                            content={listCredit}
                            showCheckBox
                            msgEmpty={t('customers:no_credits')}
                            fileTranslation={'customers'}
                            checkedItems={state?.checkedItems}
                            typeQuickBook={QUICK_BOOK_CREDIT}
                            onChangeStateCheckedItems={onChangeStateCheckedItems}
                            handleClick={handleEditCredit}
                            handleClickHeader={_handleActionHeader}
                            {...getGridColumnsCustomerCredits(paramsCustomersCredit?.order, quickbooks)}
                            isScroll
                            isShowToolTip
                        />
                        <ReportPagination
                            isCustomerPage
                            reportType={REPORT_TYPE.CREDIT_CUSTOMER_LIST}
                            totalPage={state.totalPage || 1}
                            onSelect={_handleUpdate}
                        />
                    </div>
                    <CreditForm
                        ref={refCreditForm}
                        onCreateSuccess={handleCreateCreditSuccess}
                        onCreateFail={handleCreateCreditFail}
                    />
                    <EditForm ref={refEditForm} onEditSuccess={_handleEditCreditSuccess} />
                    <GDModalWarning
                        ref={refConfirm}
                        title={t('customers:warning_credit_title')}
                        footer={
                            <div className="footer-modal btn-close justify-end">
                                <div className="v2-btn-default --transparent" onClick={_handleCloseDeleteCredit}>
                                    {t('customers:cancel')}
                                </div>
                                <ButtonSave
                                    ref={refButtonSave}
                                    onSave={_handleConfirmDelete}
                                    title={t('customers:confirm')}
                                />
                            </div>
                        }
                    />
                    <ModalLoading ref={refLoading} />
                </div>
            </div>
        </>
    );
};

export default CustomerCredits;

export default function IconEstimate({
    isNormal = false,
    isActive = false,
    isActiveColor = false,
    isPurple = false,
    isAddons = false,
    type = ''
}) {
    if (isNormal) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.5 4.5H9.5C10.0523 4.5 10.5 4.94772 10.5 5.5V18.5C10.5 19.0523 10.0523 19.5 9.5 19.5H6.5C5.94772 19.5 5.5 19.0523 5.5 18.5V5.5C5.5 4.94772 5.94772 4.5 6.5 4.5Z"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.5 4.5C16.6046 4.5 17.5 5.39543 17.5 6.5V15.3377C17.5 15.4452 17.4827 15.552 17.4487 15.6539L16.4487 18.6539C16.274 19.1779 15.7077 19.4611 15.1838 19.2864C14.8852 19.1869 14.6509 18.9526 14.5513 18.6539L13.5513 15.6539C13.5173 15.552 13.5 15.4452 13.5 15.3377V6.5C13.5 5.39543 14.3954 4.5 15.5 4.5Z"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M7.5 10.5H6.5"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M7.5 16.5H6.5"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M7.5 13.5H6.5"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M7.5 7.5H6.5"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M14.5 7.5H16.5"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M14.5 14.5H16.5"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </svg>
        );
    }
    if (isActive) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 12H5V11H3V9H5V8H3V6H6V5H3V4C3 2.89543 3.89543 2 5 2H7C8.10457 2 9 2.89543 9 4V16C9 17.1046 8.10457 18 7 18H5C3.89543 18 3 17.1046 3 16V15H6V14H3V12Z"
                    fill="#C1C9D4"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 6H15V14.8074C15 14.9346 14.9757 15.0607 14.9285 15.1788L13.9285 17.6788C13.7234 18.1916 13.1414 18.441 12.6286 18.2359C12.3745 18.1343 12.1732 17.9329 12.0715 17.6788L11.0715 15.1788C11.0243 15.0607 11 14.9346 11 14.8074V6Z"
                    fill="#7A83A6"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 2C14.1046 2 15 2.89543 15 4V5H11V4C11 2.89543 11.8954 2 13 2Z"
                    fill="#7A83A6"
                />{' '}
                <path
                    d="M8.43414 17.5487C8.43414 15.9551 10.3012 15 12.2306 17C14.1599 19 16.3035 16.3602 16.8247 12.628"
                    stroke="white"
                    strokeWidth={5}
                    strokeLinecap="round"
                />{' '}
                <path
                    d="M8.43414 17.5487C8.43414 15.9551 10.3012 15 12.2306 17C14.1599 19 16.3035 16.3602 16.8247 12.628"
                    stroke="#7A83A6"
                    strokeWidth={2}
                    strokeLinecap="round"
                />{' '}
            </svg>
        );
    }
    if (isActiveColor) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 12H5V11H3V9H5V8H3V6H6V5H3V4C3 2.89543 3.89543 2 5 2H7C8.10457 2 9 2.89543 9 4V16C9 17.1046 8.10457 18 7 18H5C3.89543 18 3 17.1046 3 16V15H6V14H3V12Z"
                    fill="#DAC2F2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 6H15V14.8074C15 14.9346 14.9757 15.0607 14.9285 15.1788L13.9285 17.6788C13.7234 18.1916 13.1414 18.441 12.6286 18.2359C12.3745 18.1343 12.1732 17.9329 12.0715 17.6788L11.0715 15.1788C11.0243 15.0607 11 14.9346 11 14.8074V6Z"
                    fill="#8D4AFC"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 2C14.1046 2 15 2.89543 15 4V5H11V4C11 2.89543 11.8954 2 13 2Z"
                    fill="#8D4AFC"
                />
                <path
                    d="M8.43414 17.5487C8.43414 15.9551 10.3012 15 12.2306 17C14.1599 19 16.3035 16.3602 16.8247 12.628"
                    stroke="white"
                    strokeWidth={5}
                    strokeLinecap="round"
                />
                <path
                    d="M8.43414 17.5487C8.43414 15.9551 10.3012 15 12.2306 17C14.1599 19 16.3035 16.3602 16.8247 12.628"
                    stroke="#8D4AFC"
                    strokeWidth={2}
                    strokeLinecap="round"
                />
            </svg>
        );
    }
    if (isPurple) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 14H8V13H6V11H8V10H6V8H9V7H6V6C6 4.89543 6.89543 4 8 4H10C11.1046 4 12 4.89543 12 6V18C12 19.1046 11.1046 20 10 20H8C6.89543 20 6 19.1046 6 18V17H9V16H6V14Z"
                    fill="#dac2f2"
                />
                <path
                    d="M14 8H18V16.8074C18 16.9346 17.9757 17.0607 17.9285 17.1788L16.9285 19.6788C16.7234 20.1916 16.1414 20.441 15.6286 20.2359C15.3745 20.1343 15.1732 19.9329 15.0715 19.6788L14.0715 17.1788C14.0243 17.0607 14 16.9346 14 16.8074V8Z"
                    fill="#8d4afc"
                />
                <path d="M16 4C17.1046 4 18 4.89543 18 6V7H14V6C14 4.89543 14.8954 4 16 4Z" fill="#8d4afc" />
            </svg>
        );
    }
    if (isAddons) {
        switch (type) {
            case 'inactive':
                return (
                    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
                            fill="black"
                            fillOpacity="0.04"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8 13.9975C8.00137 13.4463 8.44856 13 9 13L12 13C13.1046 13 14 12.1046 14 11L14 8C14 7.44848 14.4465 7.00125 14.9977 7H21C22.1046 7 23 7.89543 23 9L23 23C23 24.1046 22.1046 25 21 25L10 25C8.89543 25 8 24.1046 8 23V13.9975ZM16.5 10C16.2238 10 16 10.4477 16 11C16 11.5523 16.2238 12 16.5 12H20.5C20.7761 12 21 11.5523 21 11C21 10.4477 20.7761 10 20.5 10H16.5ZM14 16V18H11V16H14ZM11 19H14V22H11V19ZM9.99999 15V19V23H14H15H21V19V15H15H14H9.99999ZM20 22V19H15V22H20ZM15 18H20V16H15V18ZM13 8.20711V11C13 11.5523 12.5523 12 12 12L9.20712 12C8.76167 12 8.53858 11.4614 8.85356 11.1464L12.1465 7.85355C12.4614 7.53857 13 7.76165 13 8.20711Z"
                            fill="black"
                            fillOpacity="0.6"
                        />
                    </svg>
                );

            default:
                return (
                    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                            fill="#FFF"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8 13.9975C8.00137 13.4463 8.44856 13 9 13L12 13C13.1046 13 14 12.1046 14 11L14 8C14 7.44848 14.4465 7.00125 14.9977 7H21C22.1046 7 23 7.89543 23 9L23 23C23 24.1046 22.1046 25 21 25L10 25C8.89543 25 8 24.1046 8 23V13.9975ZM16.5 10C16.2238 10 16 10.4477 16 11C16 11.5523 16.2238 12 16.5 12H20.5C20.7761 12 21 11.5523 21 11C21 10.4477 20.7761 10 20.5 10H16.5ZM14 16V18H11V16H14ZM11 19H14V22H11V19ZM9.99999 15V19V23H14H15H21V19V15H15H14H9.99999ZM20 22V19H15V22H20ZM15 18H20V16H15V18ZM13 8.20711V11C13 11.5523 12.5523 12 12 12L9.20712 12C8.76167 12 8.53858 11.4614 8.85356 11.1464L12.1465 7.85355C12.4614 7.53857 13 7.76165 13 8.20711Z"
                            fill="#1EAA5C"
                        />
                    </svg>
                );
        }
    }

    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 14H8V13H6V11H8V10H6V8H9V7H6V6C6 4.89543 6.89543 4 8 4H10C11.1046 4 12 4.89543 12 6V18C12 19.1046 11.1046 20 10 20H8C6.89543 20 6 19.1046 6 18V17H9V16H6V14Z"
                fill="#C1C9D4"
            />
            <path
                d="M14 8H18V16.8074C18 16.9346 17.9757 17.0607 17.9285 17.1788L16.9285 19.6788C16.7234 20.1916 16.1414 20.441 15.6286 20.2359C15.3745 20.1343 15.1732 19.9329 15.0715 19.6788L14.0715 17.1788C14.0243 17.0607 14 16.9346 14 16.8074V8Z"
                fill="#7A83A6"
            />
            <path d="M16 4C17.1046 4 18 4.89543 18 6V7H14V6C14 4.89543 14.8954 4 16 4Z" fill="#7A83A6" />
        </svg>
    );
}

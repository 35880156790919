import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import GDLineChart from 'app/components/chart/line';
import { configNewCustomerAndLead } from './config';
import WidgetDatePicker from '../../components/datepicker';
import { KEY_WIDGET_LOCAL_STORAGE, LIST_WIDGETS, SIZE_CHART, TYPE_PICKER, getDefaultParamsWidget } from '../../const';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { reducer } from 'app/const/Reducer';
import ServiceDashboard from '../../ServiceDashboard';
import LoadingLineChart from '../loading/LoadingChart';

export default function WidgetCustomerAndLead() {
    const { t } = useTranslation('report');

    const refService = useRef(null);

    const keyWidget = LIST_WIDGETS.NEW_CUSTOMER_LEAD;
    const defaultParams = getLocalStorage(KEY_WIDGET_LOCAL_STORAGE)?.[keyWidget] || getDefaultParamsWidget(keyWidget);

    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: true,
        params: defaultParams
    });

    const { isLoading: finalIsLoading, data: finalData } = state;

    useEffect(() => {
        fetchNewLeadCustomerBySource(defaultParams);
    }, []);

    const fetchNewLeadCustomerBySource = (params) => {
        refService.current._handleGetData(params);
    };

    const _handleChangeFilterWidget = (params) => {
        dispatchState({
            isLoading: true
        });
        const newParams = refService.current._handleChangeFilter({ ...params });
        fetchNewLeadCustomerBySource(newParams);
    };

    const _handleGetDataSuccess = ({ data }) => {
        dispatchState((prev) => ({
            ...prev,
            data,
            isLoading: false
        }));
    };

    const _handleGetDataFailed = () => {
        dispatchState((prev) => ({ ...prev, isLoading: false }));
    };

    const _renderData = () => {
        if (finalIsLoading) return <LoadingLineChart />;

        return (
            <div className="boxs__content">
                <div className="chart">
                    <div className="chart__content">
                        <div className="flex-column">
                            <div id={`htmlLegend_${keyWidget}`} />
                            <GDLineChart
                                id={keyWidget}
                                configLineChart={configNewCustomerAndLead({
                                    data: finalData.map((data) => ({
                                        ...data,
                                        amount_due: data.values
                                    }))
                                })}
                                size={SIZE_CHART.LARGE}
                                isUseCustomLegend
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Fragment>
            <div className="boxs --full">
                <div className="boxs__header">
                    <div className="header-label flex-1">{t('new_customers_and_leads_by_source')}</div>
                    <WidgetDatePicker
                        key={keyWidget}
                        typePicker={TYPE_PICKER.YEAR}
                        selectDefault={defaultParams.year}
                        onSelect={(values) => _handleChangeFilterWidget({ ...values })}
                    />
                </div>
                {_renderData()}
            </div>
            <ServiceDashboard
                ref={refService}
                keyWidget={keyWidget}
                onGetSuccess={_handleGetDataSuccess}
                onGetFailed={_handleGetDataFailed}
            />
        </Fragment>
    );
}

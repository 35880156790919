import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { CHATBOT_SETTING_INFO } from '../constants';
import RowHeader from './RowHeader';
import RowSettingReply from './RowSettingReply';

const TableSettingReply = ({ isHidden = false, activeDay = {} }, ref) => {
    const { t } = useTranslation();

    const dayRefs = useRef(CHATBOT_SETTING_INFO.DEFAULT_ACTIVE_DAY);

    useImperativeHandle(ref, () => ({
        getValue: () => {
            const values = {};
            for (const [key, dayRef] of Object.entries(dayRefs.current)) {
                values[key] = dayRef?.getValue() || {};
            }
            return values;
        }
    }));

    return (
        <div className={classNames('container-print', { 'dp-hide': isHidden })}>
            <div className="wrap-tables flex-column relative">
                <div className="tables table-multi-column table-setting scrolls-x">
                    <RowHeader />
                    <div className="tables-list">
                        {Object.entries(CHATBOT_SETTING_INFO.DAY_CODE_LABELS).map(([id, dayKey]) => (
                            <RowSettingReply
                                key={id}
                                ref={(el) => (dayRefs.current[id] = el)}
                                id={id}
                                label={t(`common:${dayKey}`)}
                                {...activeDay[id]}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default forwardRef(TableSettingReply);

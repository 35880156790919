import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import { ACTION_VALUE } from '../const/Invoice';

const ConfirmAddInvoice = forwardRef(({ handleConfirm, onClose = () => {} }, ref) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        isCard: false,
        isChargeAndAction: false,
        text: 'Email'
    });

    const { isVisible, isCard, isChargeAndAction, text: actionText } = state;

    useImperativeHandle(ref, () => ({
        _handleOpenForm,
        _handleCloseForm
    }));

    const _stopPropagation = (e) => {
        e && e.stopPropagation();
    };

    const getText = (type) => {
        switch (type) {
            case ACTION_VALUE.SEND_SMS:
                return 'sms';
            case ACTION_VALUE.SEND_EMAIL_AND_SMS:
                return 'email_and_sms';
            default:
                return 'email';
        }
    };

    const _handleOpenForm = (type) => {
        dispatchState({
            isVisible: true,
            isCard: type === ACTION_VALUE.CHARGE || type === ACTION_VALUE.CHARGE_AND_SEND_RECEIPT,
            isChargeAndAction: type === ACTION_VALUE.CHARGE_AND_SEND_RECEIPT,
            text: getText(type)
        });
    };

    const _handleCloseForm = (trigger) => {
        dispatchState({
            isVisible: false
        });
        trigger && onClose();
    };

    const _handleConfirm = (e, id) => {
        _stopPropagation(e);
        handleConfirm(id);
    };

    if (!isVisible) return null;

    return (
        <div className="modal container-modal --more-button open">
            <div className="modal__overlay bg-fixed" />
            <div className="modal__container">
                <div className="header-modal">
                    <h2 className="header-modal__label">{t('jobDetail:invoice_action')}</h2>
                </div>
                <div className="body-modal">
                    <p>{t('jobDetail:set_invoice_action')}</p>
                    <p>{`${t('jobDetail:would_you_like_gd_to')} ${
                        isCard
                            ? isChargeAndAction
                                ? t('jobDetail:charge_and', { action: t(`common:${actionText}`) })
                                : t('jobDetail:charge')
                            : t(`common:${actionText}`)
                    } ${t('jobDetail:this_invoice_now')}?`}</p>
                    <div
                        onClick={(e) => {
                            _handleConfirm(e, 1);
                        }}
                        className="v2-btn-default"
                    >
                        {isCard
                            ? t('jobDetail:charge_card_now')
                            : `${t('common:yes')}, ${t(`common:${actionText}`)} ${t('jobDetail:this_invoice_now')}`}
                    </div>
                    <div
                        onClick={(e) => {
                            _handleConfirm(e, 0);
                        }}
                        className="v2-btn-default"
                    >
                        {t('jobDetail:complete_later')}
                    </div>
                    <div
                        onClick={() => {
                            _handleCloseForm(true);
                        }}
                        className="v2-btn-default --transparent"
                    >
                        {t('common:cancel')}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ConfirmAddInvoice;

import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import { ADD_TEMPLATE_NOTE } from 'app/const/api/Notes';
import { NAME } from 'app/const/Customers';
import { NOTES_TEMPLATE_TYPE } from 'app/const/Notes';
import { reducer } from 'app/const/Reducer';
import {
    CONTENT,
    DUE_DATE,
    DUE_DATE_TYPE,
    LIST_DUE_DATE,
    LIST_PAYMENT_FEE,
    MAX_LENGTH_DUE_DATE,
    PAYMENT_FEE,
    PAYMENT_FEE_TYPE
} from 'app/modules/settings/templatesManager/components/notes/const';
import DropdownPaymentTerms from 'app/modules/settings/templatesManager/components/notes/detail/DropdownPaymentTerms';
import { validatePaymentFee } from 'app/modules/settings/templatesManager/components/notes/utils';
import IconClose from 'assets/icon/IconClose';
import { clientQuery } from 'common/utils/ApiUtils';
import { showStatusBar } from 'common/utils/FunctionUtils';
import ButtonSave from '../button/ButtonSave';
import GDInputNumber from '../input/InputNumber';
import GDStatusBar from '../status/statusbar';

const ModalAddTerms = ({ onAddData = () => {} }, ref) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        [DUE_DATE_TYPE]: LIST_DUE_DATE[0].type,
        [PAYMENT_FEE_TYPE]: LIST_PAYMENT_FEE[0].type
    });
    const { isVisible, [DUE_DATE_TYPE]: finalDueDateType, [PAYMENT_FEE_TYPE]: finalPaymentFeeType } = state;

    const refTermName = useRef(null);
    const refContent = useRef(null);
    const refInputDueDate = useRef(null);
    const refDropdownDueDate = useRef(null);
    const refInputLatePaymentFee = useRef(null);
    const refDropdownPaymentFee = useRef(null);
    const refButtonSave = useRef(null);
    const refStatusBar = useRef(null);

    useImperativeHandle(ref, () => ({ _open: _handleOpen, _close: _handleClose }));

    const _handleOpen = () => {
        dispatchState((prev) => ({ ...prev, isVisible: true }));
    };

    const _handleClose = () => {
        dispatchState((prev) => ({ ...prev, isVisible: false, data: {} }));
    };

    const _getErrorMessage = (name = '', checkLengthValue = false) => {
        return checkLengthValue ? name : t('common:can_not_be_blank', { name: t(`setting:${name}`) });
    };

    const _handleSave = () => {
        const termName = (refTermName.current?.value || '').trim();
        const content = (refContent.current?.value || '').trim();
        const paymentFeeValue = refInputLatePaymentFee.current.value || 0;
        const msgErrors = [];

        if (!termName) msgErrors.push(_getErrorMessage(NAME));
        if (!content) msgErrors.push(_getErrorMessage(CONTENT));
        if (!validatePaymentFee(paymentFeeValue)) msgErrors.push(t('setting:error_message_payment_fee', true));
        if (!!msgErrors.length) {
            _showAlert({ message: msgErrors });
            _removeLoading();
            return;
        }

        const _handleSuccess = ({ data }) => {
            onAddData(data);
            _handleClose();
            _removeLoading();
        };
        const _handleFail = ({ message }) => {
            showStatusBar({ message });
            _removeLoading();
        };

        clientQuery(
            ADD_TEMPLATE_NOTE,
            {
                data: {
                    name: termName,
                    content,
                    type: NOTES_TEMPLATE_TYPE.PAYMENT_TERMS,
                    [DUE_DATE]: { type: finalDueDateType, value: refInputDueDate.current.value || 0 },
                    [PAYMENT_FEE]: { type: finalPaymentFeeType, value: paymentFeeValue }
                },
                method: 'POST',
                toFormData: false
            },
            _handleSuccess,
            _handleFail
        );
    };

    const _removeLoading = () => {
        refButtonSave.current && refButtonSave.current.removeLoading();
    };

    const _handleChangeItem = ({ key, value }) => {
        dispatchState((prev) => ({ ...prev, [key]: value }));
    };

    const _showAlert = ({ message, success }) => {
        showStatusBar({ id: 'add_terms', message, success, refAlert: refStatusBar });
    };

    if (!isVisible) return null;

    return (
        <ReactModal
            id="modal_add_terms"
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            portalClassName="ReactModalPortal_alert_customer"
            className="modal container-modal modal-add-terms open"
            onRequestClose={_handleClose}
        >
            <div className="modal__overlay bg-fixed" onClick={_handleClose} />
            <div className="modal__container --sm">
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('common:add_new_terms')}</h3>
                    <span className="v2-btn-default --icon-lg --transparent" onClick={_handleClose}>
                        <IconClose />
                    </span>
                </div>
                <div className="body-modal flex-column gap-10 scrolls">
                    <GDStatusBar ref={refStatusBar} />
                    <div className="flex-column gap-4 flex-1">
                        <p className="black fs-14">{t('setting:term_name')}</p>
                        <input
                            ref={refTermName}
                            autoFocus
                            className="field-input"
                            placeholder={t('setting:term_name')}
                        />
                    </div>
                    <div className="flex-column gap-4 flex-1">
                        <p className="black fs-14">{t('setting:due_date')}</p>
                        <div className="has-many-field__items phone-field mb-0">
                            <GDInputNumber
                                ref={refInputDueDate}
                                className="field-input"
                                name={DUE_DATE}
                                placeholder={0}
                                defaultValue={''}
                                exceptNegative
                                isStringValue
                                exceptZero
                                maxLength={MAX_LENGTH_DUE_DATE}
                                integerNumber
                            />
                            <DropdownPaymentTerms
                                ref={refDropdownDueDate}
                                keyGetValue={DUE_DATE_TYPE}
                                data={LIST_DUE_DATE}
                                type={finalDueDateType}
                                onHandleChange={_handleChangeItem}
                            />
                        </div>
                    </div>
                    <div className="flex-column gap-4 flex-1">
                        <p className="black fs-14">{t('setting:fee')}</p>
                        <div className="has-many-field__items phone-field mb-0">
                            <GDInputNumber
                                ref={refInputLatePaymentFee}
                                className="field-input"
                                name={PAYMENT_FEE}
                                type="number"
                                placeholder={0}
                                defaultValue={''}
                                exceptNegative
                                isStringValue
                                exceptZero
                            />
                            <DropdownPaymentTerms
                                ref={refDropdownPaymentFee}
                                keyGetValue={PAYMENT_FEE_TYPE}
                                data={LIST_PAYMENT_FEE}
                                type={finalPaymentFeeType}
                                onHandleChange={_handleChangeItem}
                            />
                        </div>
                    </div>
                    <div className="flex-column gap-4 flex-1">
                        <p className="black fs-14">{t('setting:content')}</p>
                        <textarea ref={refContent} className="field-textarea --no-resize" />
                    </div>
                </div>
                <div className="footer-modal justify-end">
                    <span className="v2-btn-default --transparent" onClick={_handleClose}>
                        {t('common:cancel')}
                    </span>
                    <ButtonSave ref={refButtonSave} className="v2-btn-main ml-2" onSave={_handleSave} />
                </div>
            </div>
        </ReactModal>
    );
};

export default forwardRef(ModalAddTerms);

import moment from 'moment';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { reducer } from 'app/const/Reducer';
import { CONTENT_SMS_TEMPLATE_VARIABLE, getVariableCompany } from 'app/const/Settings';
import { handlePreviewTemplate } from 'app/modules/settings/utils';
import SmsAttachments from 'app/modules/sms/components/SmsAttachments';
import { convertPhpFormatToMoment, formatTime } from 'common/utils/DateUtils';

const PreviewSMSTemplate = ({ defaultMessage = '' }, ref) => {
    const { t, i18n } = useTranslation('setting');
    const refMessage = useRef(null);

    const { profile, company, settings } = useSelector(({ auth }) => auth.user);
    const { avatar, first_name } = profile;
    const formatDisplay = formatTime(
        moment().locale(i18n.language),
        `${convertPhpFormatToMoment(company?.date_format)}, hh:mm A`
    );

    const [state, dispatchState] = useReducer(reducer, {
        attachments: []
    });
    const { attachments: finalAttach } = state;

    useImperativeHandle(ref, () => ({
        changeMessage: _handleChangeMessage,
        changeAttachments: _handleChangeAttachments
    }));

    const _handleChangeMessage = ({ message = '' }) => {
        refMessage.current.innerHTML = handlePreviewTemplate(
            message.trim(),
            getVariableCompany(company),
            CONTENT_SMS_TEMPLATE_VARIABLE(settings?.shorten_url)
        );
    };

    const _handleChangeAttachments = (attachments = []) => {
        dispatchState({ attachments: attachments.map((item) => ({ ...item, type: item.mime })) });
    };

    return (
        <div className="flex-column gap-6">
            <p className="txt-title fw-600">{t('live_preview')}</p>
            <div className="container-content-email is-sms">
                <div className="wrap-content-email">
                    <div className="sms-message flextop">
                        <div className="sms-message__info mr-1">
                            <div ref={refMessage} className="message-desc">
                                {defaultMessage || <br />}
                            </div>
                            <SmsAttachments
                                data={finalAttach}
                                excludeWrapper
                                boxAttachClass={!!finalAttach.length ? ' has-manyimage' : ''}
                            />
                            <div className="text-right fs-11">
                                <span className="mr-1">{first_name}</span>
                                <span className="granite-gray">{formatDisplay}</span>
                            </div>
                        </div>
                        <div className="avt-img">
                            <img src={avatar} alt="avatar" className="m-0" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default forwardRef(PreviewSMSTemplate);

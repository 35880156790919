import moment from 'moment';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ADDONS_VOIP } from 'app/config/routes';
import { RECHARGE_AMOUNT, RECHARGE_THRESHOLD } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { LIST_CLASS_DIVIDE, LIST_RECHARGE_AMOUNT, LIST_RECHARGE_THRESHOLD } from 'app/const/Voip';
import { ACTIVE_ACCOUNT_VOIP } from 'app/const/api/Voip';
import AddonBoxRange from 'app/modules/addons/components/AddonBoxRange';
import AddonProgressBalance from 'app/modules/addons/components/AddonProgressBalance';
import IconArrow from 'assets/icon/IconArrow';
import IconTooltip from 'assets/icon/IconTooltip';
import { updateAddonStatus } from 'common/redux/actions/authAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { formatMoneyProgressBalance } from 'common/utils/FunctionUtils';
import PlanChooseCard from './PlanChooseCard';

export const VOIPAddonsModifyPlan = ({
    data,
    isShowFooter = true,
    onChange = () => {},
    isManage = false,
    onVisibleToolTip = () => {},
    isCanceled
}) => {
    const { t } = useTranslation('addons');
    const history = useHistory();
    const dispatch = useDispatch();
    const refCard = useRef(null);

    const _addMonth = (date, month) => {
        const newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() + month);
        return newDate;
    };

    const [state, dispatchState] = useReducer(reducer, {
        ...data,
        isCanceled,
        isChanged: false
    });

    useEffect(() => {
        dispatchState({ ...data });
    }, [data]);

    const { account, plans, handleBack, oldAccount, isCanceled: finalIsCanceled, isChanged: finalIsChanged } = state;
    const {
        plan_id = plans[0]['id'],
        balance = LIST_RECHARGE_AMOUNT[0],
        usage_this_month = 0,
        recharge_amount = LIST_RECHARGE_AMOUNT[0],
        recharge_threshold = LIST_RECHARGE_THRESHOLD[0],
        user_quantity,
        isActive = true,
        expiration_date = ''
    } = account || {};

    const { plan_id: oldPlanId = plans[0]['id'], user_quantity: oldUser = 0 } = oldAccount || {};
    const isReActivate = isManage && finalIsCanceled && finalIsChanged;

    const {
        credit,
        price,
        name: planName,
        user_price: pricePerUser,
        user_quantity: userFree
    } = plans.find((item) => item.id === plan_id);
    const newBalance = isActive ? balance : recharge_amount;
    const expireDate = expiration_date || moment(_addMonth(new Date(), 1)).format('DD/MM/YYYY');

    const _renderBoxTotal = () => {
        const isRaw = !isManage || isReActivate;
        const isUpgradePlan = oldPlanId < plan_id;
        const isUpgradeUser = oldUser < user_quantity;
        const compareUser = user_quantity > oldUser;

        const oldPlan = plans.find((item) => item.id === oldPlanId);

        const totalUserQuantity = Math.max(user_quantity - userFree, 0);

        let priceForUsers = 0;
        if (isRaw) {
            priceForUsers = pricePerUser * totalUserQuantity;
        } else if (isUpgradePlan || isUpgradeUser) {
            const userPriceDiff = Math.max(pricePerUser - oldPlan.user_price, 0);
            const userQuantityDiff = compareUser ? oldUser : user_quantity;
            priceForUsers =
                userPriceDiff * (userQuantityDiff - userFree) +
                pricePerUser * (compareUser ? user_quantity - oldUser : 0);
        }

        const pricePlan = isRaw ? price : Math.max(price - oldPlan.price, 0);
        const priceTotal = pricePlan + priceForUsers + (!isManage ? recharge_amount : 0);

        return (
            <div className="box-plans --lg flex-column gap-10 black fs-14">
                <div className="flex-betweenitems">
                    <span>{t(`voip_${planName.toLowerCase()}_plan`)}</span>
                    <span className="blue-default fw-600">
                        {formatMoneyProgressBalance({ number: pricePlan, isPerMo: true })}
                    </span>
                </div>
                <div className="flex-betweenitems">
                    <span>{t('voip_activated_users_one_free', { users: user_quantity, free: userFree })}</span>
                    <span className="blue-default fw-600">
                        {formatMoneyProgressBalance({ number: priceForUsers, isPerMo: true })}
                    </span>
                </div>
                <div className="flex-betweenitems">
                    <span>{t('starting_voip_credit_balance')}</span>
                    <span className="blue-default fw-600">
                        {formatMoneyProgressBalance({ number: isManage ? 0 : recharge_amount })}
                    </span>
                </div>
                <div className="is-divider --horizontal" />
                <div className="flex-betweenitems fw-600">
                    <span>{t('total')}</span>
                    <span className="blue-default fs-16">{formatMoneyProgressBalance({ number: priceTotal })}</span>
                </div>
                <div className="note-billing flex-centeritem gap-4 fs-12">
                    {t('future_monthly_voip_plan_billing')}
                    <span className="blue-default">
                        {formatMoneyProgressBalance({
                            number: price + pricePerUser * totalUserQuantity,
                            isPerMo: true
                        })}
                    </span>
                </div>
            </div>
        );
    };

    const _handleChangeRange = (data) => {
        dispatchState((prev) => ({ ...prev, account: { ...prev.account, ...(data || {}) } }));
        onChange(data);
    };

    const _handleClickActive = () => {
        refCard.current._open();
    };

    const _handleActivePlan = ({ isNewCard = false, cardDetail = {} }) => {
        const newData = {
            plan_id,
            user_quantity,
            recharge_amount,
            recharge_threshold
        };

        if (isNewCard) {
            newData.stripe_card_token = cardDetail.token.id;
        }

        const _handleSuccess = () => {
            refCard.current._close();
            dispatch(updateAddonStatus({ keyword: 'voip', data: {} }));
            history.push(addBranchPath(ADDONS_VOIP), { isActiveSuccess: true });
        };

        const _handleFail = ({ message }) => {
            refCard.current._removeLoading(message);
        };

        clientQuery(
            ACTIVE_ACCOUNT_VOIP,
            {
                method: 'POST',
                toFormData: false,
                data: newData
            },
            _handleSuccess,
            _handleFail
        );
    };

    const _renderCreditBalance = () => {
        if (isManage)
            return (
                <h3 className="fs-14 fw-600 has-tooltip">
                    {t('credit_balance')}
                    <span className="ml-1 cursor-pointer tooltip" onClick={onVisibleToolTip}>
                        <IconTooltip />
                        <p className="tooltiptext top">
                            {t('addons:desc_per_minute_call')}
                            <p className="dots">{t('addons:desc_local_numbers')}</p>
                            <p className="dots">{t('addons:desc_toll_free_numbers')}</p>
                            {t('addons:desc_set_up_your_credit')} <br />
                            {t('addons:desc_credit_notice')}
                        </p>
                    </span>
                </h3>
            );

        return (
            <Fragment>
                <h3 className="fs-14 fw-600">
                    {t('voip_credit_balance', {
                        balance: Number(
                            newBalance - (credit >= usage_this_month ? 0 : usage_this_month - credit)
                        ).toFixed(2)
                    })}
                </h3>
                <div className="flex-column gap-3">
                    <p className="dots">{t('desc_local_numbers')}</p>
                    <p className="dots">{t('desc_toll_free_numbers')}</p>
                </div>
                <p>{t('desc_set_up_your_credit')}</p>
                <p className="mt-4">{t('desc_credit_notice')}</p>
            </Fragment>
        );
    };

    return (
        <div className="wrap-plans flex-column gap-12 mt-2">
            <div className="flex-column gap-6">{_renderCreditBalance()}</div>
            {!isManage && <div className="is-divider --horizontal" />}
            <AddonProgressBalance
                rechargeThreshold={recharge_threshold}
                balance={newBalance}
                credit={credit}
                usageThisMonth={usage_this_month}
                expireDate={expireDate}
            />
            <div className="is-divider --horizontal" />
            <AddonBoxRange
                listClassDivide={LIST_CLASS_DIVIDE}
                keyLeftRange={RECHARGE_THRESHOLD}
                valueLeftRange={recharge_threshold}
                listLeftRange={LIST_RECHARGE_THRESHOLD}
                keyRightRange={RECHARGE_AMOUNT}
                valueRightRange={recharge_amount}
                listRightRange={LIST_RECHARGE_AMOUNT}
                onChangeRange={_handleChangeRange}
            />
            {_renderBoxTotal()}
            {isShowFooter && (
                <div className="flex-betweenitems">
                    <div className="v2-btn-default has-icon --grey" onClick={handleBack}>
                        <IconArrow isPrev />
                        {t('back')}
                    </div>
                    <div className="v2-btn-default has-bg-blue white --transparent" onClick={_handleClickActive}>
                        {t('pay_total_and_activate_plan')}
                    </div>
                </div>
            )}
            <PlanChooseCard ref={refCard} onChooseCardSuccess={_handleActivePlan} />
        </div>
    );
};

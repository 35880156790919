import { DEFAULT_ALL } from 'app/const/App';
import i18n from 'assets/i18n';

export const MESSAGE_TABS = {
    OFF: 4,
    BOTH: 3,
    EMAIL: 2,
    SMS: 1
};

export const getLocationDefaultAll = () => {
    return { id: DEFAULT_ALL, address: i18n.t('customers:all_location') };
};

export const TYPE_JOB_META = {
    EMOJI: 1
};

export const JOB_ACTIVE = {
    UNACTIVE: 0,
    ACTIVE: 1
};

export const LOCKED = 'locked';
export const CONFIRMED = 'confirmed';

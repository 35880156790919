import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getEstimateNumber, getInvoiNumber } from 'app/const/Api';
import { AddInvoiceContext } from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import IconHashtag from 'assets/icon/IconHashtag';
import { clientQuery } from 'common/utils/ApiUtils';

const InvoiceNumber = ({ isInvoice = true, display = true, isFetchInit = true }) => {
    const { t } = useTranslation();
    const { invoiceData, onUpdateInvoiceDataContext } = useContext(AddInvoiceContext);
    const isExitData = invoiceData.number;

    useEffect(() => {
        if (isFetchInit) {
            const getApiURL = isInvoice ? getInvoiNumber : getEstimateNumber;
            clientQuery(
                getApiURL,
                { method: 'GET', data: { job_id: invoiceData.customer_job_id } },
                _getInvoiceNumberSuccess
            );
        }
    }, []);

    /**
     * isFromAddJob check case add job have invoice or estimate. We will not fill data detail to note and terms
     *
     */
    function _getInvoiceNumberSuccess(response) {
        const responseData = response.data || {};

        setTimeout(() => {
            onUpdateInvoiceDataContext({
                number: invoiceData.number || responseData.number || 0,
                note:
                    !isExitData && !invoiceData.isFromAddJob
                        ? responseData.job_note || responseData.note || ''
                        : invoiceData.note,
                terms: !isExitData && !invoiceData.isFromAddJob ? responseData.terms || '' : invoiceData.terms,
                noteDefault: responseData.note || '',
                termsDefault: responseData.terms || '',
                recurrence: isInvoice
                    ? {
                          ...invoiceData.recurrence,
                          offset: {
                              ...invoiceData.recurrence.offset,
                              action_time: responseData.recurrence?.offset?.action_time || 0
                          }
                      }
                    : invoiceData.recurrence
            });
        }, 500);
    }

    function _handleChangeValue(e) {
        onUpdateInvoiceDataContext({ number: e.target.value });
    }

    if (!display) {
        return false;
    }

    const finalNumber = `${invoiceData.isInvoiceRecurring ? t('jobDetail:recurring_of') : ''}${invoiceData.number}`;

    return (
        <div className="sm-row">
            <div className="txt">
                <IconHashtag />
                <span className="flex-1 txt-ellipsis" title={t(`jobDetail:${isInvoice ? 'invoice' : 'estimate'}`)}>
                    {t(`jobDetail:${isInvoice ? 'invoice' : 'estimate'}`)}
                </span>
            </div>
            <div className="detail" title={finalNumber}>
                <input
                    className="field-input"
                    type="text"
                    defaultValue={finalNumber}
                    placeholder={t('jobDetail:auto_generated')}
                    disabled={invoiceData.isInvoiceRecurring}
                    onBlur={(e) => _handleChangeValue(e)}
                />
            </div>
        </div>
    );
};

export default InvoiceNumber;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { PAYMENT_CARD_CUSTOMER } from 'app/const/Customers';
import IconClose from 'assets/icon/IconClose';
import IconReply from 'assets/icon/IconReply';
import IconTrash from 'assets/icon/IconTrash';

const SubscriptionDetailFooter = ({
    isCanceled = false,
    typeSubscription,
    onCancel = () => {},
    onReactive = () => {},
    onCancelImmediate = () => {}
}) => {
    const { t } = useTranslation();
    const isSquare = typeSubscription === PAYMENT_CARD_CUSTOMER.SQUARE;

    return (
        <div className="footer-modal justify-center">
            {!isCanceled || isSquare ? (
                <div className="v2-btn-default has-icon --delete btn-modal" onClick={onCancel}>
                    <IconTrash />
                    {t('common:cancel_subscription')}
                </div>
            ) : null}

            {isCanceled && !isSquare ? (
                <>
                    <div className="v2-btn-main has-icon svg-white-stroke --delete btn-modal" onClick={onReactive}>
                        <IconReply />
                        {t('common:reactive_subscription')}
                    </div>

                    <div
                        className="v2-btn-main has-icon svg-white-stroke --delete btn-modal ml-2"
                        onClick={onCancelImmediate}
                    >
                        <IconClose />
                        {t('common:cancel_subscription_immediately')}
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default SubscriptionDetailFooter;

import moment from 'moment';
import i18next from 'i18next';

import {
    CUSTOM_ID,
    FUTURE_ID,
    OPTIONS_DATE_RANGE,
    OPTIONS_DATE_WITHIN,
    PAST_ID
} from 'app/modules/smartview/constants/dateOptions';
import { OPERATOR_IDS } from 'app/modules/smartview/constants/types';
import { offsetTostring } from '../../utils/dateOperator';

export const getValueString = (
    { value, customDate, offset, value2, customDate2, offset2 } = {},
    operatorId,
    format = 'YYYY/MM/DD'
) => {
    const isWithin = [OPERATOR_IDS.WITHIN, OPERATOR_IDS.NOT_WITHIN].includes(operatorId);
    const isBetween = [OPERATOR_IDS.BETWEEN, OPERATOR_IDS.NOT_BETWEEN].includes(operatorId);
    if (!value && !value2) return '';
    if (isWithin) {
        if ([FUTURE_ID, PAST_ID].includes(value)) {
            const offsetString = offsetTostring(offset);
            if (!offsetString) return '';
            return `${offsetString} ${i18next.t(`smartView:${value}`)}`;
        }
        const label = OPTIONS_DATE_WITHIN.find((item) => item.value === value)?.label;
        return label || '';
    }

    let result = '';
    if (![FUTURE_ID, PAST_ID, CUSTOM_ID].includes(value)) {
        const label = OPTIONS_DATE_RANGE.find((item) => item.value === value)?.label;
        result = label || value || '';
    }
    if (value === CUSTOM_ID && null !== customDate && moment(customDate).isValid())
        result = moment(customDate).locale(i18next.language).format(format);
    if ([FUTURE_ID, PAST_ID].includes(value)) {
        const stringOffset = offsetTostring(offset);
        if (stringOffset) result = `${stringOffset} ${i18next.t(`smartView:${value}`)}`;
    }

    if (isBetween) {
        if (!value2) return result;
        const date2 = moment(customDate2).locale(i18next.language);
        if (![FUTURE_ID, PAST_ID, CUSTOM_ID].includes(value2)) {
            const label2 = OPTIONS_DATE_RANGE.find((item) => item.value === value2)?.label;
            if (result) result += ' - ';
            return (result += `${label2 || value2}`);
        }
        if (value2 === CUSTOM_ID && null !== customDate2 && moment(customDate2).isValid()) {
            if (result) result += ' - ';
            return (result += `${date2.format(format)}`);
        }
        if ([FUTURE_ID, PAST_ID].includes(value2)) {
            const stringOffset2 = offsetTostring(offset2);
            if (!stringOffset2) return result;
            if (result) result += ' - ';
            result += `${stringOffset2} ${i18next.t(`smartView:${value2}`)}`;
        }
    }
    return result;
};

export const getStringDuration = (value) => {
    return offsetTostring(value?.durationValue || {});
};

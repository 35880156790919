import classNames from 'classnames';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import { reducer } from 'app/const/Reducer';
import IconPlus from 'assets/icon/IconPlus';
import IconSearch from 'assets/icon/IconSearch';

const TemplateSelectServices = ({ data = [], selectedServices = [], onUpdateSelected = () => {} }) => {
    const { t } = useTranslation(['setting', 'common']);
    const [state, dispatchState] = useReducer(reducer, { data, selected: [] });
    const { data: services } = state;

    const refData = useRef(data || []);
    const refOldSearch = useRef(null);
    const refIsSelectingAll = useRef(false);
    const refDropdown = useRef(null);

    useEffect(() => {
        dispatchState({ data });
        refData.current = data;
    }, [data]);

    useEffect(() => {
        if (selectedServices.length === data.length) refIsSelectingAll.current = true;
    }, [selectedServices, data]);

    const _handleSearch = (e) => {
        const searchStr = e.target.value;
        refOldSearch.current = searchStr;

        if (searchStr.length) {
            const result = [];
            refData.current.forEach((item) => {
                if (item.name.toLocaleLowerCase().search(searchStr.toLocaleLowerCase()) > -1) {
                    result.push(item);
                }
            });

            dispatchState({ data: result });
        } else {
            dispatchState({ data: refData.current });
        }
    };

    const _handleSelect = (dataService, isAdded) => {
        let result = [...selectedServices];
        if (isAdded) {
            result = result.filter((item) => item.id !== dataService.id);
        } else {
            result.push(dataService);
        }
        onUpdateSelected(result);
    };

    const _handleSelectAll = () => {
        const isSelectingAll = refIsSelectingAll.current;

        // In first select all, we're update selected to all services display in box.
        if (!isSelectingAll) _handleMergeSelected(services);
        // In select second times, we're remove all services of box from selected services.
        if (isSelectingAll) {
            const result = [];
            [...selectedServices].forEach((item) => {
                if (!services.some((service) => service.id === item.id)) {
                    result.push(item);
                }
            });
            onUpdateSelected(result);
        }

        refIsSelectingAll.current = !isSelectingAll;
    };

    const _handleMergeSelected = (newData) => {
        const ids = new Set(selectedServices.map((d) => d.id));
        const merged = [...selectedServices, ...newData.filter((d) => !ids.has(d.id))];
        onUpdateSelected(merged);
    };

    const _handleHideDropdown = () => {
        dispatchState({ data: refData.current });
    };

    const _renderListServices = () => {
        if (!services.length) return <div className="result-empty">{t('addons:there_is_no_data')}</div>;
        return services.map((item) => {
            return (
                <ServiceItem
                    key={item.id}
                    isAdded={selectedServices.some((selected) => parseInt(selected.id) === parseInt(item.id))}
                    onSelect={_handleSelect}
                    {...item}
                />
            );
        });
    };

    return (
        <DropdownPopper
            ref={refDropdown}
            id="dropdown_services_template_select"
            wrapperClassName="v2-dropdown"
            buttonClassName="dropbtn v2-btn-default has-icon"
            wrapperListClass="v2-dropdown__menu content-search"
            onHide={_handleHideDropdown}
            isUseToggle
            dropdownIcon={
                <>
                    <IconPlus />
                    {t('add_service')}
                </>
            }
        >
            <div className="container-column">
                <div className="flex-auto header-search">
                    <div className="search-input">
                        <span className="svg-search-absolute">
                            <IconSearch />
                        </span>
                        <input
                            type="text"
                            placeholder={t('common:search')}
                            defaultValue={refOldSearch.current || ''}
                            onChange={_handleSearch}
                            spellCheck
                        />
                    </div>

                    {services.length > 0 ? (
                        <div className="items" onClick={_handleSelectAll}>
                            {t('setting:select_all')}
                        </div>
                    ) : null}
                </div>
                <ul className="box-auto scrolls">{_renderListServices()}</ul>
            </div>
        </DropdownPopper>
    );
};

const ServiceItem = ({ id, name = '', isAdded = false, onSelect = () => {} }) => {
    return (
        <li className={classNames('items', { active: isAdded })} onClick={() => onSelect({ id, name }, isAdded)}>
            <p className="txt-ellipsis">{name}</p>
        </li>
    );
};

export default TemplateSelectServices;

export default function IconSentricon({ isJobDetail = false }) {
    if (isJobDetail)
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.6245 7.3125C10.409 7.3125 10.2023 7.3981 10.05 7.55048L7.54996 10.0505C7.23266 10.3678 7.23266 10.8822 7.54996 11.1995L10.675 14.3245C10.8273 14.4769 11.034 14.5625 11.2495 14.5625H18.7495C18.965 14.5625 19.1716 14.4769 19.324 14.3245L22.449 11.1995C22.7663 10.8822 22.7663 10.3678 22.449 10.0505L19.949 7.55048C19.7966 7.3981 19.59 7.3125 19.3745 7.3125H10.6245ZM9.27354 10.625L10.961 8.9375H19.0379L20.7254 10.625L18.4129 12.9375H11.586L9.27354 10.625ZM6.70911 12.0404C6.45527 11.7865 6.04371 11.7865 5.78987 12.0404L2.03987 15.7904C1.78603 16.0442 1.78603 16.4558 2.03987 16.7096L9.53987 24.2096C9.66177 24.3315 9.8271 24.4 9.99949 24.4H13.7495C14.1085 24.4 14.3995 24.109 14.3995 23.75V20C14.3995 19.8276 14.331 19.6623 14.2091 19.5404L6.70911 12.0404ZM13.0995 20.2692V23.1H10.2687L3.41873 16.25L6.24949 13.4192L13.0995 20.2692ZM23.2899 12.0404C23.5437 11.7865 23.9553 11.7865 24.2091 12.0404L27.9591 15.7904C28.2129 16.0442 28.2129 16.4558 27.9591 16.7096L20.4591 24.2096C20.3372 24.3315 20.1719 24.4 19.9995 24.4H16.2495C15.8905 24.4 15.5995 24.109 15.5995 23.75V20C15.5995 19.8276 15.668 19.6623 15.7899 19.5404L23.2899 12.0404ZM16.8995 20.2692V23.1H19.7302L26.5802 16.25L23.7495 13.4192L16.8995 20.2692Z"
                    fill="var(--color-icon)"
                ></path>
            </svg>
        );

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.6245 7.3125C10.409 7.3125 10.2023 7.3981 10.05 7.55048L7.54996 10.0505C7.23266 10.3678 7.23266 10.8822 7.54996 11.1995L10.675 14.3245C10.8273 14.4769 11.034 14.5625 11.2495 14.5625H18.7495C18.965 14.5625 19.1716 14.4769 19.324 14.3245L22.449 11.1995C22.7663 10.8822 22.7663 10.3678 22.449 10.0505L19.949 7.55048C19.7966 7.3981 19.59 7.3125 19.3745 7.3125H10.6245ZM9.27354 10.625L10.961 8.9375H19.0379L20.7254 10.625L18.4129 12.9375H11.586L9.27354 10.625ZM6.70911 12.0404C6.45527 11.7865 6.04371 11.7865 5.78987 12.0404L2.03987 15.7904C1.78603 16.0442 1.78603 16.4558 2.03987 16.7096L9.53987 24.2096C9.66177 24.3315 9.8271 24.4 9.99949 24.4H13.7495C14.1085 24.4 14.3995 24.109 14.3995 23.75V20C14.3995 19.8276 14.331 19.6623 14.2091 19.5404L6.70911 12.0404ZM13.0995 20.2692V23.1H10.2687L3.41873 16.25L6.24949 13.4192L13.0995 20.2692ZM23.2899 12.0404C23.5437 11.7865 23.9553 11.7865 24.2091 12.0404L27.9591 15.7904C28.2129 16.0442 28.2129 16.4558 27.9591 16.7096L20.4591 24.2096C20.3372 24.3315 20.1719 24.4 19.9995 24.4H16.2495C15.8905 24.4 15.5995 24.109 15.5995 23.75V20C15.5995 19.8276 15.668 19.6623 15.7899 19.5404L23.2899 12.0404ZM16.8995 20.2692V23.1H19.7302L26.5802 16.25L23.7495 13.4192L16.8995 20.2692Z"
                fill="#1eaa5c"
            ></path>
        </svg>
    );
}

import { useEffect } from 'react';

export const useWindowEvent = (eventName, callback) => {
    useEffect(() => {
        // Attach the event listener
        const eventHandler = (event) => callback(event.detail);
        window.addEventListener(eventName, eventHandler);

        // Clean up the listener when the component unmounts
        return () => {
            window.removeEventListener(eventName, eventHandler);
        };
    }, [eventName, callback]); // Dependencies ensure updates if the event or callback changes
};

export default ({
    isCustomer = false,
    isWorker = false,
    isAdd = false,
    isMenu = false,
    isMenuActive = false,
    isCustomerActive = false,
    isOnBoarding = false,
    isBlack = false
}) => {
    if (isOnBoarding) {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    opacity="0.4"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.1919 13C16.4363 13 18.3294 14.4604 18.6078 16.4065L18.9944 19.1072L18.5815 19.378C17.97 19.7792 17.2257 20 16.4455 20H7.54489C6.76372 20 6.01856 19.7787 5.4067 19.3766L4.99438 19.1057L5.38103 16.4065C5.65941 14.4604 7.55258 13 9.79697 13H9.99993C9.99993 14.1046 10.8954 15 11.9999 15C13.1045 15 13.9999 14.1046 13.9999 13H14.1919Z"
                    fill="#8D4AFC"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 4C13.6569 4 15 5.34315 15 7V7.99566L14.4846 10.0596C14.1998 11.1999 13.1753 12 12 12C10.8246 12 9.80001 11.2 9.51493 10.0597L9 8V7C9 5.34315 10.3431 4 12 4Z"
                    fill="#8D4AFC"
                ></path>
            </svg>
        );
    }
    if (isMenu) {
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#F4FAF2"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.0644 15.8768L21.444 16.5554C22.9288 16.9797 24.0368 18.2207 24.2907 19.7439L24.653 21.9177C24.8346 23.0073 24.0986 24.0377 23.009 24.2193C22.9735 24.2252 22.9379 24.2302 22.9021 24.2342L20.3474 24.5193C17.4851 24.8388 14.5962 24.8402 11.7336 24.5236L9.10036 24.2323C8.00249 24.1109 7.21093 23.1224 7.33237 22.0246C7.3364 21.9881 7.34143 21.9518 7.34746 21.9156L7.70941 19.7439C7.96328 18.2207 9.07128 16.9797 10.5561 16.5554L12.9357 15.8768C13.7821 16.8493 14.8454 17.5 16.0001 17.5C17.1548 17.5 18.218 16.8493 19.0644 15.8768Z"
                    fill="#81C479"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 15C18.2091 15 20 12.2091 20 10C20 7.79086 18.2091 6 16 6C13.7909 6 12 7.79086 12 10C12 12.2091 13.7909 15 16 15Z"
                    fill="#81C479"
                />
            </svg>
        );
    }
    if (isMenuActive) {
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#81C479"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.0644 15.8768L21.444 16.5554C22.9288 16.9797 24.0368 18.2207 24.2907 19.7439L24.653 21.9177C24.8346 23.0073 24.0986 24.0377 23.009 24.2193C22.9735 24.2252 22.9379 24.2302 22.9021 24.2342L20.3474 24.5193C17.4851 24.8388 14.5962 24.8402 11.7336 24.5236L9.10036 24.2323C8.00249 24.1109 7.21093 23.1224 7.33237 22.0246C7.3364 21.9881 7.34143 21.9518 7.34746 21.9156L7.70941 19.7439C7.96328 18.2207 9.07128 16.9797 10.5561 16.5554L12.9357 15.8768C13.7821 16.8493 14.8454 17.5 16.0001 17.5C17.1548 17.5 18.218 16.8493 19.0644 15.8768Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 15C18.2091 15 20 12.2091 20 10C20 7.79086 18.2091 6 16 6C13.7909 6 12 7.79086 12 10C12 12.2091 13.7909 15 16 15Z"
                    fill="white"
                />
            </svg>
        );
    }
    if (isAdd) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 12.5C11.3807 12.5 12.5 10.933 12.5 9C12.5 7.067 11.3807 6.5 10 6.5C8.61929 6.5 7.5 7.067 7.5 9C7.5 10.933 8.61929 12.5 10 12.5Z"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.5 18.5L5.02669 17.0955C5.61214 15.5343 7.10462 14.5 8.772 14.5H11.228C12.8954 14.5 14.3879 15.5343 14.9733 17.0955L15.5 18.5"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17 10V14"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19 12L15 12"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
    if (isCustomer)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.1919 12C15.4363 12 17.3295 13.4604 17.6079 15.4065L17.9944 18.1072L17.5816 18.378C16.9701 18.7792 16.2258 19 15.4456 19H6.54495C5.76378 19 5.01862 18.7787 4.40676 18.3766L3.99445 18.1057L4.38109 15.4065C4.65947 13.4604 6.55264 12 8.79703 12H8.99999C8.99999 13.1046 9.89542 14 11 14C12.1046 14 13 13.1046 13 12H13.1919Z"
                    fill="#C1C9D4"
                />{' '}
                <path
                    d="M18 18H12C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20H18C18.5523 20 19 19.5523 19 19C19 18.4477 18.5523 18 18 18Z"
                    fill="#7A83A6"
                />{' '}
                <path
                    d="M14 15H12C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17H14C14.5523 17 15 16.5523 15 16C15 15.4477 14.5523 15 14 15Z"
                    fill="#7A83A6"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 3C12.6569 3 14 4.34315 14 6V6.99566L13.4846 9.05959C13.1998 10.1999 12.1753 11 11 11C9.82459 11 8.80001 10.2 8.51493 9.05971L8 7V6C8 4.34315 9.34315 3 11 3Z"
                    fill="#7A83A6"
                />{' '}
            </svg>
        );

    if (isWorker)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                {/* mask-type="alpha" */}
                <mask id="mask0" maskUnits="userSpaceOnUse" x="4" y="13" width="15" height="7">
                    <path
                        d="M14.1919 13C16.4363 13 18.3295 14.4604 18.6079 16.4065L18.9944 19.1072L18.5816 19.378C17.9701 19.7792 17.2258 20 16.4456 20H7.54495C6.76378 20 6.01862 19.7787 5.40676 19.3766L4.99445 19.1057L5.38109 16.4065C5.65947 14.4604 7.55264 13 9.79703 13H9.99999C9.99999 14.1046 10.8954 15 12 15C13.1046 15 14 14.1046 14 13H14.1919Z"
                        fill="white"
                    ></path>
                </mask>
                <g>
                    <path
                        d="M14.1919 13C16.4363 13 18.3295 14.4604 18.6079 16.4065L18.9944 19.1072L18.5816 19.378C17.9701 19.7792 17.2258 20 16.4456 20H7.54495C6.76378 20 6.01862 19.7787 5.40676 19.3766L4.99445 19.1057L5.38109 16.4065C5.65947 14.4604 7.55264 13 9.79703 13H9.99999C9.99999 14.1046 10.8954 15 12 15C13.1046 15 14 14.1046 14 13H14.1919Z"
                        fill="#C1C9D4"
                    ></path>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5 18L7.89443 16.5528C8.572 16.214 9 15.5215 9 14.7639V13H15V14.7639C15 15.5215 15.428 16.214 16.1056 16.5528L19 18V20H5V18Z"
                        fill="#7A83A6"
                    ></path>
                </g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 4C13.6569 4 15 5.34315 15 7V7.99566L14.4846 10.0596C14.1998 11.1999 13.1753 12 12 12C10.8246 12 9.80001 11.2 9.51493 10.0597L9 8V7C9 5.34315 10.3431 4 12 4Z"
                    fill="#7A83A6"
                ></path>
            </svg>
        );
    if (isCustomerActive)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.1919 12C15.4363 12 17.3295 13.4604 17.6079 15.4065L17.9944 18.1072L17.5816 18.378C16.9701 18.7792 16.2258 19 15.4456 19H6.54495C5.76378 19 5.01862 18.7787 4.40676 18.3766L3.99445 18.1057L4.38109 15.4065C4.65947 13.4604 6.55264 12 8.79703 12H8.99999C8.99999 13.1046 9.89542 14 11 14C12.1046 14 13 13.1046 13 12H13.1919Z"
                    fill="#DAC2F2"
                />
                <path
                    d="M18 18H12C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20H18C18.5523 20 19 19.5523 19 19C19 18.4477 18.5523 18 18 18Z"
                    fill="#8D4AFC"
                />
                <path
                    d="M14 15H12C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17H14C14.5523 17 15 16.5523 15 16C15 15.4477 14.5523 15 14 15Z"
                    fill="#8D4AFC"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 3C12.6569 3 14 4.34315 14 6V6.99566L13.4846 9.05959C13.1998 10.1999 12.1753 11 11 11C9.82459 11 8.80001 10.2 8.51493 9.05971L8 7V6C8 4.34315 9.34315 3 11 3Z"
                    fill="#8D4AFC"
                />
            </svg>
        );
    if (isBlack) {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.1919 13C16.4363 13 18.3294 14.4604 18.6078 16.4065L18.9944 19.1072L18.5815 19.378C17.97 19.7792 17.2257 20 16.4455 20H7.54489C6.76372 20 6.01856 19.7787 5.4067 19.3766L4.99438 19.1057L5.38103 16.4065C5.65941 14.4604 7.55258 13 9.79697 13H9.99993C9.99993 14.1046 10.8954 15 11.9999 15C13.1045 15 13.9999 14.1046 13.9999 13H14.1919Z"
                    fill="var(--granite-gray)"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 4C13.6569 4 15 5.34315 15 7V7.99566L14.4846 10.0596C14.1998 11.1999 13.1753 12 12 12C10.8246 12 9.80001 11.2 9.51493 10.0597L9 8V7C9 5.34315 10.3431 4 12 4Z"
                    fill="var(--granite-gray)"
                ></path>
            </svg>
        );
    }
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.1919 13C16.4363 13 18.3295 14.4604 18.6079 16.4065L18.9944 19.1072L18.5816 19.378C17.9701 19.7792 17.2258 20 16.4456 20H7.54495C6.76378 20 6.01862 19.7787 5.40676 19.3766L4.99445 19.1057L5.38109 16.4065C5.65947 14.4604 7.55264 13 9.79703 13H9.99999C9.99999 14.1046 10.8954 15 12 15C13.1046 15 14 14.1046 14 13H14.1919Z"
                fill="#C1C9D4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 4C13.6569 4 15 5.34315 15 7V7.99566L14.4846 10.0596C14.1998 11.1999 13.1753 12 12 12C10.8246 12 9.80001 11.2 9.51493 10.0597L9 8V7C9 5.34315 10.3431 4 12 4Z"
                fill="#7A83A6"
            />
        </svg>
    );
};

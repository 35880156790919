import { reducer } from 'app/const/Reducer';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconPlus from 'assets/icon/IconPlus';
import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useReducer } from 'react';

const GDCollapse = forwardRef(
    (
        {
            title = '',
            isDisable = false,
            isArrowRight = false,
            isUp = false,
            classWrapTitle = 'note-header p-0 flex-betweenitems',
            classTitle = 'title-sx',
            className = 'wrap-note wrap-collapse',
            classOpen = 'is-open',
            defaultCollapsed = false,
            defaultDisableAdd = false,
            children,
            badge = 0,
            onToggle = () => {},
            onClickAdd = null
        },
        ref
    ) => {
        const [state, dispatchState] = useReducer(reducer, {
            isCollapsed: defaultCollapsed,
            isDisableAdd: defaultDisableAdd
        });
        const { isCollapsed, isDisableAdd } = state;

        useImperativeHandle(ref, () => ({ setDisableAdd: _handleSetDisableAdd }));

        const _handleToggle = () => {
            onToggle(!isCollapsed);
            if (!isDisable) dispatchState({ isCollapsed: !isCollapsed });
        };

        const _handleSetDisableAdd = (isDisableAdd) => {
            dispatchState({ isDisableAdd });
        };

        return (
            <div className={classNames(className, { [classOpen]: isCollapsed, 'open-box': isDisableAdd })}>
                {!!onClickAdd ? (
                    <div className="v2-btn-default --transparent --icon-lg" onClick={onClickAdd}>
                        <IconPlus />
                    </div>
                ) : null}
                <div className={classWrapTitle} onClick={_handleToggle}>
                    {!isArrowRight ? <ArrowItem isUp={isUp} /> : null}
                    <p className={classTitle}>{title}</p>
                    {!!badge ? <div className="budget --grey">{badge}</div> : null}
                    {isArrowRight ? <ArrowItem isUp={isUp} /> : null}
                </div>
                {isCollapsed ? children : null}
            </div>
        );
    }
);

const ArrowItem = ({ isUp = false }) => {
    return (
        <div className="arrow">
            <IconArrowDown isUp={isUp} />
        </div>
    );
};

export default GDCollapse;

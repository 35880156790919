import React, { useEffect, useMemo, useReducer, useRef } from 'react';
import IconThreeDots from 'assets/icon/IconThreeDots';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import IconFolder from 'assets/icon/IconFolder';
import IconFille from 'assets/icon/IconFile';
import Template from '../template';
import { useHistory } from 'react-router-dom';
import { SETTINGS_TEMPLATES_TODO } from 'app/config/routes';
import { checkPermission } from 'common/utils/PermissionUtils';
import { useSelector } from 'react-redux';
import { PERMISSIONS } from 'app/const/Permissions';
import IconTrash from 'assets/icon/IconTrash';
import { useTranslation } from 'react-i18next';
import { addBranchPath } from 'app/const/Branch';

export default function Options({
    onSelectTemplate = () => {},
    onSaveAsTemplate = () => {},
    onDeleteTodo = () => {},
    numberCheckList = 0
}) {
    const { t } = useTranslation(['jobDetail']);
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false
    });
    const refOptions = useRef(null);
    const history = useHistory();
    const currentUser = useSelector(({ auth }) => auth.user);

    const isHavePermissionTodo = useMemo(() => {
        return checkPermission(currentUser.permissions.enabled || [], PERMISSIONS.manageTodoTemplate);
    }, []);

    const finalIsVisible = state.isVisible;

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_job_todo_options');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_job_todo_options');

        if (
            refOptions.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refOptions.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && dispatchState({ isVisible: false });
    }

    function _handleOpen(e) {
        e.stopPropagation();
        dispatchState({ isVisible: !finalIsVisible });
    }

    function _handleSelectTemplate(templateId) {
        onSelectTemplate(templateId);
        _closeSearchResult();
    }

    function _handleOpenFormSaveAs() {
        onSaveAsTemplate();
        _closeSearchResult();
    }

    function _handleDeleteTodo() {
        onDeleteTodo();
        _closeSearchResult();
    }

    function _goToManageTemplate() {
        history.replace(addBranchPath(SETTINGS_TEMPLATES_TODO));
    }

    return (
        <div
            id={'show_job_todo_options'}
            className={`v2-dropdown --more ${finalIsVisible ? 'active' : ''}`}
            ref={refOptions}
        >
            <div className="dropbtn" onClick={(e) => _handleOpen(e)}>
                <IconThreeDots />
            </div>
            <div className="v2-dropdown__menu content-search scrolls">
                <div className="container-column">
                    {isHavePermissionTodo && (
                        <ul>
                            <li>
                                <a onClick={_handleOpenFormSaveAs} className="items has-icon btn-modal">
                                    <IconFolder />
                                    <span className="flex-1 txt-ellipsis nowrap">
                                        {t('jobDetail:save_todo_list_as_template')}
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a onClick={_goToManageTemplate} className="items has-icon">
                                    <IconFille />
                                    <span className="flex-1 txt-ellipsis nowrap">
                                        {t('jobDetail:manage_templates')}
                                    </span>
                                </a>
                            </li>
                            {!!numberCheckList && (
                                <li className="items has-icon red-coral" onClick={_handleDeleteTodo}>
                                    <IconTrash />
                                    <span className="flex-1 txt-ellipsis nowrap">
                                        {t('jobDetail:delete_todo_list')}
                                    </span>
                                </li>
                            )}
                        </ul>
                    )}

                    {finalIsVisible && <Template onSelect={_handleSelectTemplate} />}
                </div>
            </div>
        </div>
    );
}

import classNames from 'classnames';
import moment from 'moment';
import React, { useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { updateTask } from 'app/const/Api';
import { PERMISSIONS } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import TaskItemTags from 'app/modules/calendar/components/task/TaskItemTags';
import IconDone from 'assets/icon/IconDone';
import IconEdit from 'assets/icon/IconEdit';
import { clientQuery } from 'common/utils/ApiUtils';
import { convertPhpFormatToMoment } from 'common/utils/DateUtils';
import { checkPermission } from 'common/utils/PermissionUtils';

const TaskItem = ({
    id,
    title,
    date,
    job,
    customer,
    assignee,
    created_by,
    onUpdate = () => {},
    onChangeStatus = () => {}
}) => {
    const { t, i18n } = useTranslation(['common', 'customers']);
    const currentUser = useSelector(({ auth }) => auth.user);
    const formatCompany = currentUser.company.date_format;
    const formatDisplay = convertPhpFormatToMoment(formatCompany);
    const createdAt = moment(date).utc();
    const timeDisplay = createdAt.isValid() ? createdAt.locale(i18n.language).format(`${formatDisplay} h:mm A`) : '';

    const [state, dispatchState] = useReducer(reducer, { isDone: false });
    const { isDone } = state;

    const refTimer = React.useRef(null);
    const isHavePermissionTask = useMemo(() => {
        return checkPermission(currentUser.permissions.enabled || [], PERMISSIONS.editOrDeleteTaskInbox);
    }, []);

    // eslint-disable-next-line no-unused-vars
    const _deleteTask = (dataDelete) => {
        // onDelete(dataDelete);
        clientQuery(updateTask(id), {
            // eslint-disable-next-line no-undef
            data: { socket_id: global.socket_id_task_service },
            method: 'DELETE'
        });
    };

    const _changeStatus = () => {
        dispatchState({ isDone: !isDone });

        if (refTimer.current) {
            clearTimeout(refTimer.current);
            refTimer.current = null;
        } else {
            refTimer.current = setTimeout(() => {
                if (!isDone) onChangeStatus();
            }, 2000);
        }
    };

    return (
        <div className={classNames('box-task', { done: isDone })}>
            <div className="title">
                <div className="v2-btn-default --icon-sm task-done" onClick={_changeStatus}>
                    <IconDone />
                </div>
                <div className="avt-img tooltip">
                    <img src={assignee?.avatar} width={24} height={24} alt="" />
                    <p className="tooltiptext top">
                        {t('customers:created_by', { name: created_by?.full_name })}
                        <br />
                        {t('customers:assigned_to', { name: assignee?.full_name })}
                    </p>
                </div>
                <div className="title__info">
                    <div className="name">{assignee?.full_name || 'Unknown customer'}</div>
                    <div className="time">{timeDisplay}</div>
                </div>
                {isHavePermissionTask ? (
                    <span
                        id={`button-task-${id}`}
                        className="v2-btn-default --icon-sm --transparent ml-1"
                        onClick={onUpdate}
                    >
                        <IconEdit />
                    </span>
                ) : null}
            </div>
            <div className="description" style={{ whiteSpace: 'pre-wrap' }}>
                {title}
            </div>
            <TaskItemTags customer={customer} job={job} />
        </div>
    );
};

export default TaskItem;

import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import classNames from 'classnames';
import ButtonSave from 'app/components/button/ButtonSave';

const TimeOffConfirm = (
    {
        title = '',
        textRecurring = '',
        textOnly = '',
        description = '',
        showDes = true,
        onCancel = () => {},
        onSubmit = () => {}
    },
    ref
) => {
    const { t } = useTranslation(['calendar', 'common']);
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, isRepeat: false, dataConfirm: null });
    const { isOpen, isRepeat } = state;
    const refButton = useRef(null);

    useImperativeHandle(ref, () => ({ _toggleOpen, _close }));

    const _toggleOpen = (isRepeat = false, dataConfirm = null) => {
        dispatchState({ isOpen: !state.isOpen, isRepeat, dataConfirm });
    };

    const _close = () => {
        dispatchState({ isOpen: false, isRepeat: false, dataConfirm: null });
        onCancel();
    };

    const _handleSubmit = (type) => {
        onSubmit(type, state.dataConfirm);
    };

    if (!isOpen) return null;
    return (
        <ReactModal
            isOpen
            id="remove_time_off"
            style={{ overlay: { zIndex: 999, background: 'transparent' } }}
            onRequestClose={_toggleOpen}
            className={classNames('modal container-modal open', {
                'batch-job --recurring': isRepeat,
                'modal-confirm-delete': !isRepeat
            })}
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className={classNames('modal__container', { '--lg': !isRepeat })}>
                <div className="header-modal">
                    <h3 className="header-modal__label">{title || t('remove_time_off_event')}</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                        <IconClose />
                    </div>
                </div>
                <div className={classNames('body-modal', { scrolls: isRepeat })}>
                    {!isRepeat ? (
                        <div className="text">{description || t('setting:remove_time_off_event_message')}</div>
                    ) : (
                        <>
                            {showDes ? (
                                <div className="rows">
                                    <p className="desc">{t('are_you_sure_you_would_like_to_remove_this_event')}</p>
                                </div>
                            ) : null}
                            <div className="rows">
                                <div className="v2-btn-default" onClick={() => _handleSubmit(0)}>
                                    {textOnly || t('remove_this_event_only')}
                                </div>
                            </div>
                            <div className="rows">
                                <div className="v2-btn-default" onClick={() => _handleSubmit(1)}>
                                    {textRecurring || t('remove_all_event_recurring')}
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className="footer-modal btn-close">
                    <span className="v2-btn-default --transparent" onClick={_close}>
                        {t('cancel')}
                    </span>
                    {!isRepeat ? (
                        <ButtonSave ref={refButton} onSave={() => _handleSubmit(0)} title={t('common:delete')} />
                    ) : null}
                </div>
            </div>
        </ReactModal>
    );
};

export default forwardRef(TimeOffConfirm);

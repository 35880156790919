import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import IconBoolean from 'assets/icon/IconBoolean';
import IconFuture from 'assets/icon/IconFuture';
import IconHash from 'assets/icon/IconHash';
import IconProfile from 'assets/icon/IconProfile';
import IconText from 'assets/icon/IconText';
import IconTime from 'assets/icon/IconTime';
import IconUnOrderList from 'assets/icon/IconUnOrderList';
import { FIELDS_TYPE, OPERATOR_IDS_DROPDOWN, REFERENCE_TYPES } from '../../constants/types';
import { useFilters } from '../../context/addFilterContext';
import ConditionRender from '../itemCondition';
import SelectCondition from '../itemCondition/SelectCondition';

const SubCategory = ({
    label,
    id,
    objectType,
    fieldName,
    type,
    referencedType,
    operatorIds = [],
    isJobStatus = false,
    shouldFetch = false,
    keyJoin = '',
    keySelect = '',
    keyChoose = '',
    keyGetValue = '',
    options = null
}) => {
    const { t } = useTranslation(['smartView']);
    const pipelines = useSelector(({ pipelines }) => pipelines?.data?.[0]?.pipelines || []);
    const { handleUpdateFilters, handleUpdateCondition, handleUpdateConditionValue, isEditing, filters } = useFilters();
    const selectedField = filters?.[objectType]?.[fieldName];

    const items = useMemo(() => {
        return operatorIds.flatMap((operatorId) =>
            !operatorId.length
                ? []
                : [...operatorId.map((insideId) => OPERATOR_IDS_DROPDOWN[insideId]), '__DIVIDER_ITEM__']
        );
    }, [operatorIds]);

    const defaultSelected = useMemo(
        () => (selectedField ? items.find((item) => item.id === selectedField.operatorId) : items[0]),
        [selectedField]
    );

    const _handleChange = (value) => {
        let newValue = value;
        switch (type) {
            case FIELDS_TYPE.STATUS:
            case FIELDS_TYPE.STATUS_OPPORTUNITY:
            case FIELDS_TYPE.REFERENCE_USER:
            case FIELDS_TYPE.CREATED_BY:
            case FIELDS_TYPE.SOURCE_ID:
            case FIELDS_TYPE.REFERENCE_TYPE:
                let oldSelected = selectedField?.value?.set?.values || [];
                if (referencedType === REFERENCE_TYPES.STATUS_OPPORTUNITY) {
                    oldSelected = oldSelected.filter((item) =>
                        pipelines?.some((pipeline) => item.toString() === pipeline?.id?.toString())
                    );
                }
                const index = oldSelected.findIndex((item) => item?.toString() === value?.toString());
                if (index > -1) {
                    oldSelected.splice(index, 1);
                    newValue = { set: { values: oldSelected } };
                } else {
                    newValue = { set: { values: [...oldSelected, value] } };
                }
                break;
            default:
                break;
        }

        handleUpdateConditionValue({
            objectType,
            fieldName,
            fieldType: type,
            operatorId: selectedField.operatorId,
            value: newValue
        });
    };

    const handleToggleCondition = (e) => {
        const { checked } = e.target;
        const dataFilter = { objectType, fieldName, fieldType: type, checked, operatorId: defaultSelected?.id || null };
        handleUpdateFilters(dataFilter);
        if (type === FIELDS_TYPE.BOOLEAN) handleUpdateConditionValue({ ...dataFilter, value: checked });
    };

    const handleSelectCondition = (item) => {
        handleUpdateCondition({
            objectType,
            fieldName,
            fieldType: type,
            operatorId: item.id
        });
    };

    return (
        <div className="result">
            {isEditing ? (
                <div className="flexcenter">
                    <IconType type={type} />
                    <p className="result__label">{t(label)}</p>
                </div>
            ) : (
                <div className="check-items">
                    <input
                        id={`${id}_${objectType}`}
                        type="checkbox"
                        checked={!!selectedField}
                        onChange={handleToggleCondition}
                    />
                    <div className="item-checkbox">
                        <label htmlFor={`${id}_${objectType}`}>
                            <span className="txt-ellipsis">
                                <IconType type={type} />
                                {t(label)}
                            </span>
                        </label>
                    </div>
                </div>
            )}
            {selectedField && type !== FIELDS_TYPE.BOOLEAN ? (
                <SelectCondition items={items} selected={defaultSelected} onSelect={handleSelectCondition} />
            ) : null}
            {selectedField ? (
                <ConditionRender
                    type={type}
                    fieldName={fieldName}
                    objectType={objectType}
                    referencedType={referencedType}
                    shouldFetch={shouldFetch}
                    isJobStatus={isJobStatus}
                    options={options}
                    keyJoin={keyJoin}
                    keySelect={keySelect}
                    keyChoose={keyChoose}
                    keyGetValue={keyGetValue}
                    operatorId={selectedField?.operatorId}
                    value={selectedField.value}
                    onChange={_handleChange}
                />
            ) : null}
        </div>
    );
};

const IconType = ({ type }) => {
    switch (type) {
        case FIELDS_TYPE.STATUS:
            return <IconUnOrderList />;
        case FIELDS_TYPE.REFERENCE_USER:
        case FIELDS_TYPE.CREATED_BY:
            return <IconProfile />;
        case FIELDS_TYPE.CREATED_DATE:
        case FIELDS_TYPE.DATE_WITHOUT_PRESENT:
        case FIELDS_TYPE.LATEST_CALL_CREATED_DATE:
        case FIELDS_TYPE.CUSTOMER_CREATED_DATE:
        case FIELDS_TYPE.DATE:
        case FIELDS_TYPE.DATETIME:
            return <IconFuture />;
        case FIELDS_TYPE.COUNT_TASK:
        case FIELDS_TYPE.COUNT_CALL:
        case FIELDS_TYPE.COUNT_OUTGOING_CALL:
        case FIELDS_TYPE.COUNT_INCOMING_CALL:
        case FIELDS_TYPE.COUNT_MISSED_CALL:
        case FIELDS_TYPE.COUNT_SMS:
        case FIELDS_TYPE.COUNT_JOB:
        case FIELDS_TYPE.INTEGER:
            return <IconHash />;
        case FIELDS_TYPE.DURATION:
            return <IconTime />;
        case FIELDS_TYPE.TEXT:
            return <IconText />;
        case FIELDS_TYPE.BOOLEAN:
            return <IconBoolean />;
        default:
            return <IconUnOrderList />;
    }
};

export default SubCategory;

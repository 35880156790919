import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import { LIST_OPTIONS_SERVICE_ADDRESS, LIST_OPTIONS_TEMPLATES } from 'app/const/Settings';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconTooltip from 'assets/icon/IconTooltip';

const ServiceAddressOptions = ({ optionsDefaultData = {}, defaultServiceAddress = 0 }, ref) => {
    const { t } = useTranslation();
    const refForm = useRef(null);
    const refAddress = useRef(null);
    useImperativeHandle(ref, () => ({ _getValue }));

    const _getValue = () => {
        const result = {};
        const elements = refForm.current.elements;

        for (let index = 0; index < elements.length; index++) {
            const { type, checked, name } = elements[index];
            if (type === 'checkbox') result[name] = checked ? 1 : 0;
        }

        return { ...result, service_address: refAddress.current._getValue() };
    };

    const renderListOptions = () => {
        return LIST_OPTIONS_TEMPLATES.map((option) => (
            <ItemOptions key={option.id} defaultValue={optionsDefaultData[option.id]} {...option} />
        ));
    };

    return (
        <form ref={refForm} className="boxs__content">
            <div className="rows">
                <div className="name">
                    <span className="txt-ellipsis">{t('common:service_address')}</span>
                </div>
                <ServiceAddress ref={refAddress} defaultSelect={defaultServiceAddress} />
            </div>
            {renderListOptions()}
        </form>
    );
};

const ServiceAddress = forwardRef(({ defaultSelect = 0 }, ref) => {
    const refDropdown = useRef(null);
    const [selected, setSelected] = useState(LIST_OPTIONS_SERVICE_ADDRESS.find((item) => item.value === defaultSelect));
    useImperativeHandle(ref, () => ({ _getValue: () => selected.value }));

    const _handleSelect = (value) => setSelected(LIST_OPTIONS_SERVICE_ADDRESS.find((item) => item.value === value));

    return (
        <CalendarDropdown
            id="service_address_dropdown"
            ref={refDropdown}
            selected={selected.name}
            selectedOption={selected}
            onSelect={_handleSelect}
            options={LIST_OPTIONS_SERVICE_ADDRESS}
            fileTranslation="setting"
        />
    );
});

const ItemOptions = ({ id, tooltip, title, defaultValue = true }) => {
    const { t } = useTranslation();
    return (
        <div className="rows">
            <div className="name">
                <span className="txt-ellipsis fw-bold">{t(`setting:${title}`)}</span>
                <div className="tooltip">
                    <IconTooltip />
                    <span className="tooltiptext top">{t(`setting:${tooltip}`)}</span>
                </div>
            </div>
            <GdSwitchCheckbox
                name={id}
                id={id}
                textChecked={t('report:show')}
                textUnChecked={t('report:hide')}
                inputClassName="toggle toggle-round"
                defaultChecked={defaultValue}
            />
        </div>
    );
};
ServiceAddressOptions.displayName = 'ServiceAddressOptions';
export default forwardRef(ServiceAddressOptions);

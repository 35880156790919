import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import PhoneLabel from 'app/components/phoneLabel';
import { DEFAULT_PHONE_MOBILE } from 'app/const/Phone';
import { UnitDetailContext } from 'app/modules/jobdetail/contexts/UnitDetailContext';
import IconTelephone from 'assets/icon/IconTelephone';

const Phone = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const refCustomerPhone = useRef(null);
    const { unitDetailContextData } = useContext(UnitDetailContext);
    const parentPhones = unitDetailContextData?.phone;

    useImperativeHandle(ref, () => ({ getValue: _handleGetValue }));

    useEffect(() => {
        if (unitDetailContextData.unit_id && refCustomerPhone.current) {
            refCustomerPhone.current && refCustomerPhone.current._setValue(_getListPhone() || []);
        }
    }, [unitDetailContextData.unit_id, unitDetailContextData.refreshUinitDetail]);

    function _handleGetValue() {
        let finalPhones = refCustomerPhone.current ? refCustomerPhone.current._getValue() : [];

        finalPhones = finalPhones.map((item) => {
            return {
                type: item.type,
                number: item.phone,
                name: item.name
            };
        });

        return {
            job_id: unitDetailContextData.jobId,
            phones: finalPhones,
            name: unitDetailContextData.unitName,
            first_name: unitDetailContextData.first_name,
            last_name: unitDetailContextData.last_name,
            email: unitDetailContextData.email,
            note: unitDetailContextData.top_note,
            buildingName: unitDetailContextData.buildingName
        };
    }

    function _getListPhone() {
        if (parentPhones.length === 0) {
            return [{ id: `${new Date().getTime()}`, phone: '', type: DEFAULT_PHONE_MOBILE }];
        }
        return parentPhones.map((item, index) => {
            return {
                id: item.number?.concat('_', index),
                phone: item.number,
                type: {
                    id: item.type,
                    name: item.name
                }
            };
        });
    }

    return (
        <div className="rows --has-phone">
            <div className="rows__label">
                <IconTelephone />
                <span className="flex-1 txt-ellipsis">{t('common:phone')}</span>
            </div>
            <div className="rows__field field-has-phone has-many-field has-new-field">
                <PhoneLabel ref={refCustomerPhone} limit={2} defaultSelected={_getListPhone()} />
            </div>
        </div>
    );
});

export default Phone;

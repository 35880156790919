import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { matchPath, NavLink } from 'react-router-dom';

import GDCollapse from 'app/components/collapse';
import { addBranchPath, addPrefixPath } from 'app/const/Branch';
import { REPORT_MENU_OPTIONS } from 'app/const/StaticLinks';
import { getToggleLocal } from 'common/utils/FunctionUtils';
import { getPermissionReport, isActiveFeature, isNotPermissionValue } from 'common/utils/PermissionUtils';

function SideMenu() {
    const { t } = useTranslation(['report']);

    const addonsList = useSelector((state) => state.auth.user?.settings?.addons);
    const permissions = getPermissionReport(addonsList);

    const ItemMenu = ({ item }) => {
        return (
            <li key={item.id} id={`side-report-item-${item.id}`}>
                <NavLink
                    className="sidebar-items flexcenter"
                    to={{
                        pathname: addBranchPath(item.link),
                        state: item.tab ? { reload: Date.now(), newTab: item.tab } : null
                    }}
                    isActive={(_, location) =>
                        matchPath(location.pathname, {
                            path: item.subsLink?.map((pathSub) => addPrefixPath(pathSub)) || addPrefixPath(item.link),
                            exact: true
                        })
                    }
                >
                    <p className="txt-ellipsis" title={t(`report:${item.value}`)}>
                        {t(`report:${item.value}`)}
                    </p>
                </NavLink>
            </li>
        );
    };

    const renderMenu = (list) => {
        let previousWasDivider = false;
        return list.map((item) => {
            const { link, id, value, nestedLinks } = item;
            if (isNotPermissionValue(permissions, value)) return null;
            if (!isActiveFeature(value)) return null;
            if (link) {
                previousWasDivider = false;
                return <ItemMenu key={id} item={item} />;
            }
            if (Array.isArray(nestedLinks) && !!nestedLinks.length) {
                previousWasDivider = false;
                return (
                    <GDCollapse
                        key={id}
                        title={t(value)}
                        defaultCollapsed={false}
                        isArrowRight
                        className="sidebar-report flex-column gap-4 wrap-collapse"
                        classWrapTitle="sidebar-items --title flexcenter gap-4"
                        classTitle="flex-1 txt-ellipsis"
                        badge={nestedLinks.length}
                    >
                        {nestedLinks.map(({ id, value, link }) => (
                            <NavLink key={id} className="sidebar-items flexcenter" to={addBranchPath(link)}>
                                <p className="txt-ellipsis" title={t(value)}>
                                    {t(value)}
                                </p>
                            </NavLink>
                        ))}
                    </GDCollapse>
                );
            }
            if (previousWasDivider) return null;
            previousWasDivider = true;
            return <div key={id} className="is-divider --horizontal my-2 mx-1" />;
        });
    };

    return (
        <div
            className={classNames('sidebar-menu sidebar-left scrolls', {
                'is-hide-sidebar': !getToggleLocal('toggledReport')
            })}
        >
            <ul className="sidebar-menu__nav flex-column">{renderMenu(REPORT_MENU_OPTIONS)}</ul>
        </div>
    );
}

export default SideMenu;

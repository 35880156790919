import React, { useReducer, useContext } from 'react';
import { LIST_ACTIONS } from 'app/modules/jobdetail/const';
import IconPlus from 'assets/icon/IconPlus';
import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';
import EditForm from 'app/modules/jobdetail/tabs/materials/components/EditForm';
import { clientQuery } from 'common/utils/ApiUtils';
import { deleteMaterialService } from 'app/const/Api';
import UpdateMaterial from './UpdateMaterial';
import MaterialItem from 'app/modules/jobdetail/tabs/materials/components/Item';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';

export default function MaterialService({ tabActive }) {
    const { t } = useTranslation('setting');
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        id: 0
    });
    const { repeatSettings, onChangeDataRepeatContext } = useContext(DataRepeatContext);
    const { materials = [], id: serviceId } = repeatSettings || {};
    const { isVisible, id } = state;

    const currentEdit = materials.find((mate) => mate.id === id) || {};

    const _handleOnSelected = (value, serviceId, id) => {
        value === LIST_ACTIONS.EDIT ? _handleOpenForm(id) : _handleDelete(id, serviceId);
    };

    const _handleOpenForm = (id) => {
        dispatchState({
            id: id,
            isVisible: true
        });
    };

    const _handleCloseForm = () => {
        dispatchState({
            isVisible: false
        });
    };

    const _handleDelete = (id, serviceId) => {
        let newMaterials = [...materials];
        let itemDelete = {};

        newMaterials = newMaterials.filter((item) => {
            if (item.id !== id) {
                return item;
            }
            itemDelete = item;
        });

        onChangeDataRepeatContext({
            materials: newMaterials
        });

        if (id && !itemDelete.isTemp) {
            const optionsQuery = { method: 'DELETE' };
            clientQuery(deleteMaterialService(serviceId, id), optionsQuery);
        }
    };

    const _handleSubmit = (id, params, listItems) => {
        const _getDataResult = (id, params, listItems) => {
            const materialSelected = listItems?.materials?.find((item) => item.id === params.material_id) || {};
            const customMaterialSelected =
                listItems?.custom_materials?.find((item) => item.id === params?.custom_material_id) || {};
            const methodSelected = listItems?.methods?.find((item) => item.id === params.method_id) || {};
            const locationIds = params.location_ids || [];
            const targetIds = params.target_ids || [];
            const itemLocationSelected = [];
            const itemTargetSelected = [];

            locationIds.forEach((item) => {
                const tmp = listItems?.locations?.find((i) => i.id === item) || {};
                itemLocationSelected.push(tmp);
            });

            targetIds.forEach((item) => {
                const tmp = listItems?.targets?.find((i) => i.id === item);
                itemTargetSelected.push(tmp);
            });

            const unitSelected = listItems?.units?.find((item) => item.id === params.measurement_unit_id) || {};
            unitSelected['quantity'] = params.quantity;

            const areaSelected = listItems?.areas?.find((item) => item.id === params.area_id) || {};
            areaSelected['quantity'] = params.area_quantity;

            materialSelected.active = methodSelected.active = unitSelected.active = areaSelected.active = 1;

            const finalId = id || Date.now().toString();

            return {
                id: finalId,
                dilution: params.dilution,
                material: materialSelected,
                custom_material: { ...(customMaterialSelected || {}), active: 1 },
                method: methodSelected,
                location: { active: 1, items: itemLocationSelected },
                target: { active: 1, items: itemTargetSelected },
                unit: unitSelected,
                area: areaSelected,
                isTemp: currentEdit.isTemp || !!!id, // Check case have id not yet
                paramAdd: { ...params, id: id }
            };
        };

        let newMaterials = [...materials];

        if (!id) {
            newMaterials.push(_getDataResult(null, params, listItems));
        } else {
            const material = _getDataResult(id, params, listItems);
            newMaterials = newMaterials.map((item) => (item.id === id?.toString() ? material : item));
        }
        onChangeDataRepeatContext({
            materials: newMaterials
        });
        _handleCloseForm();
    };

    const _renderListMaterials = () => {
        if (!Array.isArray(materials) || materials.length === 0) return null;

        return materials.map((item) => {
            return <MaterialItem key={item.id} item={item} itemId={serviceId} onSelected={_handleOnSelected} />;
        });
    };

    if (!tabActive) return null;

    return (
        <div
            className={`tab-conts form-tabpane-details form-material tab-content-active ${
                isVisible ? 'show-form-edit' : ''
            }`}
        >
            <div className="container-setting-center">
                {isVisible ? (
                    <EditForm
                        isVisible={isVisible}
                        id={id}
                        data={materials}
                        handleSubmit={_handleSubmit}
                        closeForm={_handleCloseForm}
                        quickAdd
                    />
                ) : (
                    <div className="wrapper-dashboard container-column form-has-content">
                        <div className="header-modal">
                            <div className="name-tabs">
                                <div className="title-h1">{t('materials')}</div>
                            </div>
                            {serviceId ? <UpdateMaterial serviceId={serviceId} /> : null}
                            <div
                                onClick={() => {
                                    _handleOnSelected(LIST_ACTIONS.EDIT, 0, 0);
                                }}
                                className="v2-btn-main has-icon svg-white action-add-material"
                                tabIndex="0"
                            >
                                <IconPlus />
                                {t('add_material')}
                            </div>
                        </div>
                        <div className="box-auto scrolls">{_renderListMaterials()}</div>
                    </div>
                )}
            </div>
        </div>
    );
}

import { setLocalStorage } from 'common/utils/LocalStorageUtils';
import { KEY_CURRENT_BRANCH } from './App';

/* eslint-disable no-undef */
export const addPrefixPath = (path = '') => {
    return !!global.branchid ? `/:branchid${path}` : path;
};

export const addBranchPath = (path) => {
    return !!global.branchid ? `/${global.branchid}${path}` : path;
};

export const getBranchWithKey = (key) => {
    return `/${global.branchid}_${key}`;
};

export const isBranchId = (testString) => {
    const pattern = /^GD.{10}$/;
    return pattern.test(testString);
};

export const isBranchStartPrefix = (testString) => {
    const pattern = /^GD/;
    return pattern.test(testString);
};

export const getBranchId = () => {
    return global.branchid || '';
};

export const setBranchId = (id) => {
    global.branchid = id;
};

export const convertPrefixPath = (path = '', toString = true) => {
    if (Array.isArray(path)) {
        const newPaths = path.map((item) => addPrefixPath(item));
        return toString ? newPaths.join(',') : newPaths;
    }

    return addPrefixPath(path);
};

export const isFetchedBranch = {
    value: false,
    get isFetched() {
        return this.value;
    },
    set isFetched(newValue) {
        this.value = newValue;
    }
};

export const TYPE_LIST_BRANCH = {
    ASSIGNED_BRANCHES: 0,
    ALL_ACTIVE_BRANCHES: 1
};

export const setBranchLocalStorage = (branchId) => {
    setLocalStorage(KEY_CURRENT_BRANCH, branchId);
};

export const KEY_DEFAULT = 'default';

export const LIST_COLOR_BRANCH = {
    [KEY_DEFAULT]: {
        id: 1,
        name: 'default',
        bgColor: '#FFFFFF',
        color: '#FFFFFF',
        avtColor: '#1E7EF7'
    },
    orange: {
        id: 2,
        name: 'orange',
        bgColor: '#FFE9D0',
        color: '#FF9823'
    },
    yellow: {
        id: 3,
        name: 'yellow',
        bgColor: '#FFF8D4',
        color: '#F6D21B'
    },
    green: {
        id: 4,
        name: 'green',
        bgColor: '#E1F1D4',
        color: '#69C91A'
    },
    chlorine: {
        id: 5,
        name: 'chlorine',
        bgColor: '#CCF2E5',
        color: '#1AB781'
    },
    blue: {
        id: 6,
        name: 'blue',
        bgColor: '#D3E2F8',
        color: '#4672B2'
    },
    purple: {
        id: 7,
        name: 'purple',
        bgColor: '#ECD5FE',
        color: '#9E4CDE'
    },
    pink: {
        id: 8,
        name: 'pink',
        bgColor: '#FFD2E8',
        color: '#EA2485'
    },
    grey: {
        id: 9,
        name: 'grey',
        bgColor: '#D7D7D7',
        color: '#444444'
    }
};

export const getAvtColorBranch = ({ color }) => {
    const { color: branchColor, avtColor } = LIST_COLOR_BRANCH[color || KEY_DEFAULT] || {};
    return avtColor || branchColor;
};

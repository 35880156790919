export const TYPE_WORK_POOL_ACTION = {
    ACTION_MOVE_WP_TO_CALENDAR_SUCCESS: 'ACTION_MOVE_WP_TO_CALENDAR_SUCCESS',
    ACTION_MOVE_JL_TO_CALENDAR_SUCCESS: 'ACTION_MOVE_JL_TO_CALENDAR_SUCCESS',
    ACTION_REMOVE_MOVE_WP_TO_CALENDAR: 'ACTION_REMOVE_MOVE_WP_TO_CALENDAR',
    ACTION_MOVE_CALENDAR_TO_WP_SUCCESS: 'ACTION_MOVE_CALENDAR_TO_WP_SUCCESS',
    ACTION_REMOVE_CALENDAR_TO_WP_SUCCESS: 'ACTION_REMOVE_CALENDAR_TO_WP_SUCCESS',
    ACTION_WORK_POOL_REAL_TIME_STATUS: 'ACTION_WORK_POOL_REAL_TIME_STATUS',
    ACTION_WORK_POOL_REAL_TIME_DELETE_JOB: 'ACTION_WORK_POOL_REAL_TIME_DELETE_JOB',
    ACTION_WORK_POOL_REAL_TIME_MOVE_JOB: 'ACTION_WORK_POOL_REAL_TIME_MOVE_JOB',
    ACTION_WORK_POOL_MOVE_RESIZE_ON_CALENDAR: 'ACTION_WORK_POOL_MOVE_RESIZE_ON_CALENDAR'
};
export const actionMoveWPOnCalendar = (payload) => {
    return { type: TYPE_WORK_POOL_ACTION.ACTION_WORK_POOL_MOVE_RESIZE_ON_CALENDAR, payload };
};
export const actionMoveWPToCalendar = (params) => {
    return {
        type: TYPE_WORK_POOL_ACTION.ACTION_MOVE_WP_TO_CALENDAR_SUCCESS,
        params
    };
};

export const actionMoveJLToCalendar = (params) => {
    return {
        type: TYPE_WORK_POOL_ACTION.ACTION_MOVE_JL_TO_CALENDAR_SUCCESS,
        params
    };
};

export const actionRemoveOldData = () => {
    return {
        type: TYPE_WORK_POOL_ACTION.ACTION_REMOVE_MOVE_WP_TO_CALENDAR
    };
};

export const actionMoveCalendarToWP = (params) => {
    return {
        type: TYPE_WORK_POOL_ACTION.ACTION_MOVE_CALENDAR_TO_WP_SUCCESS,
        params
    };
};

export const actionWPRealtimeSatus = (params) => {
    return {
        type: TYPE_WORK_POOL_ACTION.ACTION_WORK_POOL_REAL_TIME_STATUS,
        params
    };
};

export const actionWPRealtimeDelete = (params) => {
    return {
        type: TYPE_WORK_POOL_ACTION.ACTION_WORK_POOL_REAL_TIME_DELETE_JOB,
        params
    };
};

export const actionWPRealtimeMove = (params) => {
    return {
        type: TYPE_WORK_POOL_ACTION.ACTION_WORK_POOL_REAL_TIME_MOVE_JOB,
        params
    };
};

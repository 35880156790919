import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { STATUS_ACCOUNT } from 'app/const/App';
import { PASSWORD_LIMIT_CHARACTER } from 'app/const/Auth';
import { addBranchPath } from 'app/const/Branch';
import { MIXPANEL_PROPERTIES, START_SIGN_UP_FORM } from 'app/const/Mixpanel';
import { reducer } from 'app/const/Reducer';
import {
    AUTH_CONFIRM_TOKEN,
    AUTH_PASSWORD_FORGOT,
    AUTH_SIGN_UP,
    AUTH_SIGN_UP_SOCIAL,
    SETTINGS_PLANS
} from 'app/const/Route';
import { LINK_CDN_IMG } from 'app/const/URL';
import AuthError from 'app/modules/auth/components/AuthError';
import AuthInputField from 'app/modules/auth/components/AuthInputField';
import ButtonLoading from 'app/modules/auth/components/ButtonLoading';
import LoginSocial from 'app/modules/auth/login/components/FormLoginSocial';
import { restoreOptionJobCalendar } from 'app/modules/calendar/ultil/Calendar';
import { mixpanelLogin } from 'app/modules/mixpanel/MixpanelLogin';
import { mixpanelSignup } from 'app/modules/mixpanel/MixpanelSignup';
import { getPathWithOnboarding } from 'app/modules/onboarding/utils';
import { useSiteTitle } from 'common/hooks/useSiteTitle';
import { userLoginRequest, userSignupSocialRequest } from 'common/redux/actions/authAction';
import { validateEmail } from 'common/utils/EmailUtils';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';

let timer;

function Login() {
    useSiteTitle('common:login');

    const { t } = useTranslation(['auth', 'common']);
    const dispatch = useDispatch();
    const history = useHistory();

    const refForm = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        formDisable: false,
        error: null,
        errorsForm: null
    });

    useEffect(() => {
        return () => {
            clearTimeout(timer);
        };
    }, []);

    const _handleLoginSocial = (params) => {
        dispatchState({ formDisable: true });
        dispatch(userSignupSocialRequest(params, loginSocialStepCompany, loginSocialFailed));
    };

    const loginSocialStepCompany = (response, params) => {
        if (response.token) {
            loginSuccess(response);
        } else {
            const { email, id } = response.user || {};
            const data = {
                id,
                type: params.type,
                email: params.email || email
            };
            handleTrackingEvent(
                mixpanelSignup({
                    profile: {
                        id: data.id,
                        email: data.email,
                        registration_method: MIXPANEL_PROPERTIES.SOCIAL[data.type]
                    },
                    company: { [MIXPANEL_PROPERTIES.INDUSTRY]: '' },
                    event: START_SIGN_UP_FORM
                })
            );
            history.push({ pathname: AUTH_SIGN_UP_SOCIAL, state: { dataUser: data, params } });
        }
    };

    const loginSocialFailed = (response) => {
        history.push({ pathname: AUTH_CONFIRM_TOKEN, state: { dataUser: response } });
    };

    const _handleKeypress = (e) => {
        if (e.code === 'Enter') {
            _handleCheckForm();
        }
    };

    const _handleFocus = () => {
        dispatchState({ error: null, errorsForm: null });
    };

    const _handleDebounceSubmit = () => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            _handleCheckForm();
        }, 300);
    };

    const _handleCheckForm = () => {
        const msgError = {};
        const { username, password } = refForm.current;
        const lengthForm = refForm.current.length;

        for (let i = 0; i < lengthForm; i++) {
            const { name, value } = refForm.current[i];
            if (!value.trim()) {
                msgError[name] = t(`auth:${name}`).concat(' ', t('auth:can_not_be_blank'));
            }
        }

        const usernameValue = username?.value.trim();
        const passwordValue = password?.value;

        if (usernameValue && usernameValue.includes('@') && !validateEmail(usernameValue)) {
            msgError['username'] = t('common:wrong_format_email');
        }

        if (passwordValue?.length && passwordValue.length < PASSWORD_LIMIT_CHARACTER) {
            msgError['password'] = t('common:password_limit').concat(
                ' ',
                PASSWORD_LIMIT_CHARACTER,
                ' ',
                t('common:characters')
            );
        }

        const errorKeys = Object.keys(msgError);

        if (errorKeys.length) {
            refForm.current[errorKeys[0]].focus();
            dispatchState({ errorsForm: msgError });
            return false;
        }

        dispatchState({ formDisable: true });

        _handleLoginForm({ username: usernameValue, password: passwordValue });
    };

    const _handleLoginForm = (data) => {
        dispatch(userLoginRequest(data, loginSuccess, loginFailed));
    };

    const loginSuccess = ({ profile, company, settings }) => {
        handleTrackingEvent(mixpanelLogin({ profile, company }));

        restoreOptionJobCalendar();

        if (profile?.plan?.status === STATUS_ACCOUNT.EXPIRED) {
            history.replace(addBranchPath(SETTINGS_PLANS));
            return;
        }

        history.replace(getPathWithOnboarding({ profile, settings }));
    };

    const loginFailed = (response) => {
        refForm.current['username'].focus();
        dispatchState({ formDisable: false, error: response?.message });
    };

    return (
        <>
            <div id="gd_login" className="gd-form-v2 px-6">
                <AuthError text={state.error} />
                <h1 className="logo-text-gorilla">
                    <img src={`${LINK_CDN_IMG}gorilladesk.png`} width={377} height={90} />
                </h1>

                <LoginSocial onLoginSocial={_handleLoginSocial} t={t} />

                <div className="gd-line flex-betweenitems">
                    <div className="line" />
                    <span> {t('common:or')} </span>
                    <div className="line" />
                </div>

                <form id="login-form" action="#" ref={refForm}>
                    <section className="gd-login-btn-group p-0">
                        <div className="user-box">
                            <AuthInputField
                                label={t('auth:username_or_email')}
                                placeholder={t('auth:username_or_email')}
                                error={state.errorsForm?.username}
                                onKeyPress={_handleKeypress}
                                name="username"
                                type="text"
                                onFocus={_handleFocus}
                                isShowIconError={false}
                                isShowLabel={false}
                            />
                        </div>
                        <div className="pwd-box">
                            <AuthInputField
                                label={t('auth:password')}
                                placeholder={t('auth:password')}
                                error={state.errorsForm?.password}
                                onKeyPress={_handleKeypress}
                                name="password"
                                type="password"
                                onFocus={_handleFocus}
                                isShowIconError={false}
                                isShowLabel={false}
                            />
                        </div>
                        <ButtonLoading
                            className="gd-getstarted v2-btn-main gd-btn-getstarted fw-500"
                            text={t('common:login')}
                            isLoading={state.formDisable}
                            onSubmit={_handleDebounceSubmit}
                            onKeyPress={_handleKeypress}
                        />
                        <div className="line mt-2 mb-4" />
                        <div className="gd-login-confirm black-3 fw-600 py-5">
                            {t('auth:dont_have_an_account')}
                            <Link className="gd-login-label fw-600" to={AUTH_SIGN_UP}>
                                {t('common:sign_up')}
                            </Link>
                        </div>
                        <div className="text-center mb-8 black-3 fw-600">
                            <Link className="fs-12 fw-600 black-3" to={AUTH_PASSWORD_FORGOT}>
                                {t('auth:need_to_reset_your_password')}
                            </Link>
                        </div>
                    </section>
                </form>
            </div>
        </>
    );
}

export default Login;

import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
    useElements,
    useStripe
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getDarkModeCardStyles, getDarkModeValue } from 'common/utils/FunctionUtils';

const StripeContentInfo = forwardRef(({ stripeKey, showNameCard = false, isGDCompany = false }, ref) => {
    const refForm = useRef(null);

    const userSetting = useSelector(({ auth }) => auth.user?.settings);

    const STRIPE_API_KEY = isGDCompany ? userSetting?.stripe?.public_key || '' : userSetting?.stripe?.key;
    const finalStripeKey = stripeKey || STRIPE_API_KEY;

    useImperativeHandle(ref, () => ({ _getTokenCardStripe: () => refForm.current._getTokenCardStripe() }));

    const stripePromise = finalStripeKey && loadStripe(finalStripeKey);

    return (
        <Elements stripe={stripePromise}>
            <FormInformation showNameCard={showNameCard} ref={refForm} />
        </Elements>
    );
});

const FormInformation = forwardRef(({ showNameCard }, ref) => {
    const { t } = useTranslation();
    const elements = useElements();
    const stripe = useStripe();
    const refNameCard = useRef('');
    const darkModeCardStyle = getDarkModeCardStyles(getDarkModeValue());

    useImperativeHandle(ref, () => ({ _getTokenCardStripe }));

    const _getTokenCardStripe = async () => {
        if (!stripe || !elements) {
            return;
        }

        const card = elements.getElement(CardNumberElement);
        const result = await stripe.createToken(card, { name: refNameCard.current ? refNameCard.current.value : '' });

        if (result.error) {
            return result.error;
        } else {
            return result;
        }
    };

    return (
        <div className="content-info">
            <div className="rows --inmodal">
                <div className="txt relative">
                    <span className="flex-1 txt-ellipsis" title={t('customers:card_number')}>
                        {t('customers:card_number')}
                    </span>
                </div>
                <CardNumberElement options={{ style: darkModeCardStyle }} />
            </div>

            <div className="rows --inmodal d-flex mt-3">
                <div className="col col-cvc">
                    <div className="txt">
                        <span className="flex-1 txt-ellipsis" title={t('customers:month')}>
                            {t('customers:month')}
                        </span>
                    </div>
                    <CardExpiryElement options={{ style: darkModeCardStyle }} />
                </div>

                <div className="col col-cvc">
                    <div className="txt">
                        <span className="flex-1 txt-ellipsis" title={t('customers:cvc')}>
                            {t('customers:cvc')}
                        </span>
                    </div>
                    <CardCvcElement options={{ style: darkModeCardStyle }} />
                </div>
            </div>
            {showNameCard && (
                <div className="rows --inmodal mt-3">
                    <div className="col">
                        <span className="txt">
                            <span className="flex-1 txt-ellipsis" title={t('common:name_on_card')}>
                                {t('common:name_on_card')}
                            </span>
                        </span>
                        <input
                            ref={refNameCard}
                            className="field-input"
                            type="text"
                            name
                            placeholder={t('common:name_on_card')}
                            spellCheck
                        />
                    </div>
                </div>
            )}
        </div>
    );
});

export default StripeContentInfo;

export default function IconPen({ isHasColor = false, isActiveColor = false, isUnderline }) {
    if (isActiveColor)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M13 5L16 8L9.99786 14.0021L7 11L13 5Z" fill="#DAC2F2" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.8637 12.136L8.8637 15.136L7.99785 16.0021L5.17924 17.1336C4.66671 17.3394 4.08443 17.0907 3.87868 16.5781C3.78333 16.3406 3.78269 16.0756 3.87688 15.8376L4.99999 13L5.8637 12.136Z"
                    fill="#8D4AFC"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.5 3.5C18.3284 4.32842 18.3284 5.67157 17.5 6.5L16.964 7.035L13.964 4.035L14.5 3.5C15.3284 2.67157 16.6715 2.67157 17.5 3.5Z"
                    fill="#8D4AFC"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 18H17C17.5523 18 18 18.4477 18 19C18 19.5523 17.5523 20 17 20H8C7.44772 20 7 19.5523 7 19C7 18.4477 7.44772 18 8 18Z"
                    fill="#8D4AFC"
                />
            </svg>
        );
    if (isHasColor)
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 5L16 8L9.99786 14.0021L7 11L13 5Z"
                    fill="#C1C9D4"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.8637 12.136L8.8637 15.136L7.99785 16.0021L5.17924 17.1336C4.66671 17.3393 4.08443 17.0907 3.87868 16.5781C3.78333 16.3406 3.78269 16.0756 3.87688 15.8376L4.99999 13L5.8637 12.136Z"
                    fill="#7A83A6"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.5 3.49998C18.3284 4.32841 18.3284 5.67156 17.5 6.49998L16.964 7.03498L13.964 4.03498L14.5 3.49998C15.3284 2.67156 16.6715 2.67156 17.5 3.49998Z"
                    fill="#7A83A6"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 18H17C17.5523 18 18 18.4477 18 19C18 19.5523 17.5523 20 17 20H8C7.44772 20 7 19.5523 7 19C7 18.4477 7.44772 18 8 18Z"
                    fill="#7A83A6"
                ></path>
            </svg>
        );
    if (isUnderline)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.7526 6.16716C15.8893 6.03048 16.1109 6.03048 16.2476 6.16716L17.8333 7.7529C17.97 7.88961 17.9701 8.11125 17.8334 8.24798L17.8334 8.24803L16.9984 9.08303C16.9863 9.06854 16.9734 9.05445 16.9597 9.04081L14.9597 7.04081C14.9461 7.02721 14.9321 7.01433 14.9176 7.00218L15.7526 6.16716ZM16.0405 9.96005C16.0541 9.9737 16.0683 9.98662 16.0828 9.99881L9.99879 16.0834C9.98652 16.0688 9.9735 16.0546 9.95974 16.0408L7.95974 14.0408C7.94613 14.0272 7.93207 14.0143 7.91761 14.0022L14.0019 7.91792C14.014 7.93239 14.0269 7.94645 14.0405 7.96005L16.0405 9.96005ZM7.23753 15.1571L6.16685 17.8338L8.84369 16.7632L7.23753 15.1571ZM17.1668 5.24792C16.5225 4.60356 15.4778 4.60356 14.8334 5.24792L6.5405 13.5408C6.47825 13.6031 6.4293 13.6773 6.39661 13.759L4.39661 18.759C4.30004 19.0004 4.35663 19.2762 4.54048 19.46C4.72433 19.6439 5.00006 19.7005 5.24148 19.604L10.2424 17.604C10.3242 17.5713 10.3985 17.5223 10.4607 17.46L18.7527 9.16717L18.7527 9.16713C19.3969 8.52273 19.3969 7.47812 18.7527 6.83375L18.7526 6.83371L17.1668 5.24792ZM8.50012 21.1504L18.5001 21.1504C18.8591 21.1504 19.1501 20.8594 19.1501 20.5004C19.1501 20.1414 18.8591 19.8504 18.5001 19.8504L8.50012 19.8504C8.14114 19.8504 7.85012 20.1415 7.85012 20.5004C7.85012 20.8594 8.14114 21.1504 8.50012 21.1504Z"
                    fill="var(--color-icon)"
                />
            </svg>
        );
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.7071 5.70711L18.2929 7.29289C18.6834 7.68344 18.6834 8.31658 18.2929 8.70715L10.001 17L5 19L7 14L15.2929 5.70711C15.6834 5.31658 16.3166 5.31658 16.7071 5.70711Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M14.5 7.5L16.5 9.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M7.5 14.5L9.5 16.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
}

import { SMS_LOGS_TYPES } from 'app/const/Locations';
import IconPen from 'assets/icon/IconPen';
import IconPlus from 'assets/icon/IconPlus';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ItemLog = ({ date, time, user, type, sms_number = {}, from = '' }) => {
    const { t } = useTranslation();
    const _renderTypeLog = () => {
        switch (type) {
            case SMS_LOGS_TYPES.CREATE:
                return (
                    <>
                        {t('SMS_number')} <span className="phone-number">{sms_number.new}</span> {t('common:added')}
                    </>
                );
            case SMS_LOGS_TYPES.UPDATE:
                const isLocationPanel = from === 'location_panel';
                return (
                    <>
                        {t('SMS_number')} <span className="phone-number">{sms_number.old}</span>{' '}
                        {isLocationPanel ? t('edited_to') : t('changed_to')}&nbsp;
                        <span className="phone-number">{sms_number.new}</span>{' '}
                        {!isLocationPanel && t('from_contact_field')}
                    </>
                );

            default:
                break;
        }
    };

    const _renderIcon = () => {
        switch (type) {
            case SMS_LOGS_TYPES.CREATE:
                return <IconPlus />;
            case SMS_LOGS_TYPES.UPDATE:
                return <IconPen />;
            default:
                break;
        }
    };

    return (
        <div className="log-content">
            <div className="log-content__left">
                <div className="avt-img">
                    <img src={user?.avatar} alt="" width={24} height={24} />
                </div>
                <div className="v2-btn-default --icon-lg --transparent">{_renderIcon()}</div>
            </div>
            <div className="log-content__right">
                <div className="d-flex align-center">
                    <div className="name txt-ellipsis">{user?.full_name}</div>
                    <div className="date txt-ellipsis">
                        {date} {time}
                    </div>
                </div>
                <p>{_renderTypeLog()}</p>
            </div>
        </div>
    );
};

export default ItemLog;

import React, { useEffect, useReducer } from 'react';
import SearchCustomer from 'app/modules/calendar/components/sidebar/components/Search';
import FilterLocaltions from 'app/modules/calendar/components/sidebar/components/joblist/FilterLocaltions';
import { actionGetCustomerJobs } from 'common/redux/actions/customers/jobAction';
import { useDispatch, useSelector } from 'react-redux';
import JobList from 'app/modules/calendar/components/sidebar/components/joblist/List';
import { TASK_JOB_LIST } from 'app/modules/calendar/const/SideBar';
import { convertEvents } from 'app/modules/calendar/ultil/Calendar';
import { reducer } from 'app/const/Reducer';
import JobListService from './Services';
import { useTranslation } from 'react-i18next';
import { getLocationDefaultAll } from 'app/modules/calendar/const/Job';
import { displayLocation } from 'app/modules/customer/detail/locations/utils';
import { useRef } from 'react';

/**
 *
 * @param {*} selectCustomer is data from reducer when tocuh joblist with global search
 * Component search customer will handle check before call api
 */
const TabJobList = ({ activeTab, selectCustomer }) => {
    const { t } = useTranslation(['calendar']);
    const dispatch = useDispatch();
    const [state, dispatchState] = useReducer(reducer, {
        customer: null,
        location: getLocationDefaultAll(),
        isLoadingActive: false,
        jobActive: [],
        showMoreActive: false,
        isLoadingHistory: false,
        jobHistory: [],
        showMoreHistory: false,
        fetchWithNewColor: null
    });
    const { color } = useSelector((state) => state.calendar);
    const refJobList = useRef(null);

    const {
        customer: finalCustomerId,
        location: finalLocation,
        fetchWithNewColor: flagFetchWithNewColor,
        jobActive: dataJobActive,
        jobHistory: dataJobHistory
    } = state;
    const { id: finalLocationId } = finalLocation;

    useEffect(() => {
        if (finalCustomerId && activeTab && color !== flagFetchWithNewColor) {
            dispatchState({
                isLoadingActive: true,
                isLoadingHistory: true,
                jobActive: [],
                jobHistory: [],
                showMoreHistory: false,
                showMoreActive: false,
                fetchWithNewColor: color
            });
        }
    }, [color, activeTab]);

    useEffect(() => {
        finalCustomerId && _getCustomerJobs(null, true);
    }, [finalCustomerId, finalLocation, color]);

    function _getCustomerJobs(typeJob = null, reset = false) {
        if (!activeTab) {
            dispatchState({
                isLoadingActive: true,
                isLoadingHistory: true,
                jobActive: [],
                jobHistory: [],
                showMoreHistory: false,
                showMoreActive: false,
                fetchWithNewColor: color
            });
        }

        let offset = dataJobActive.length;

        if (typeJob === TASK_JOB_LIST.HISTORY) {
            offset = dataJobHistory.length;
        }

        const params = {
            customer_id: finalCustomerId,
            offset: reset ? 0 : offset,
            location_id: finalLocationId,
            color_id: color,
            type: typeJob
        };

        dispatch(actionGetCustomerJobs(params, (res) => _getCustomerJobsSuccess(res, reset), _getCustomerJobsFailed));
    }

    function _getCustomerJobsSuccess(response, reset) {
        const dataReducer = {};
        const { data_active: dataActive, data_history: dataHistory } = response;

        if (dataActive) {
            dataReducer.isLoadingActive = false;
            dataReducer.showMoreActive = dataActive.show_more;
            dataReducer.jobActive = reset
                ? convertEvents(dataActive.data)
                : [...dataJobActive, ...convertEvents(dataActive.data)];
        }

        if (dataHistory) {
            dataReducer.isLoadingHistory = false;
            dataReducer.showMoreHistory = dataHistory.show_more;
            dataReducer.jobHistory = reset
                ? convertEvents(dataHistory.data)
                : [...dataJobHistory, ...convertEvents(dataHistory.data)];
        }
        dataReducer.fetchWithNewColor = color;
        dispatchState(dataReducer);
    }

    function _getCustomerJobsFailed() {
        dispatchState({ isLoadingActive: false, isLoadingHistory: false });
    }

    function _getCustomer(customer) {
        const { id: nextCustomerId = null, location } = customer || {};
        const locationId = location?.id || -1;

        if (finalCustomerId !== nextCustomerId || locationId !== finalLocationId) {
            refJobList.current.scrollTo(0, 0);
            dispatchState({
                customer: nextCustomerId,
                isLoadingActive: true,
                isLoadingHistory: true,
                jobActive: [],
                jobHistory: [],
                showMoreHistory: false,
                showMoreActive: false,
                location: !location?.id
                    ? getLocationDefaultAll()
                    : {
                          ...location,
                          address: displayLocation({
                              street1: location.street1,
                              street2: location.street2,
                              city: location.city,
                              state: location.state,
                              zip: location.zip,
                              withoutHTML: true
                          })
                      }
            });
        }

        return false;
    }

    function _getLocation(location) {
        dispatchState({
            location: location,
            isLoadingActive: true,
            isLoadingHistory: true,
            jobActive: [],
            jobHistory: [],
            showMoreHistory: false,
            showMoreActive: false
        });
    }

    function _handleLoadMoreJob(type) {
        if (type === TASK_JOB_LIST.ACTIVE) {
            dispatchState({ isLoadingActive: true });
        } else {
            dispatchState({ isLoadingHistory: true });
        }

        _getCustomerJobs(type);
    }

    function _handleUpdateJobs(active, history) {
        dispatchState((prev) => {
            return {
                ...prev,
                jobActive: active,
                jobHistory: history
            };
        });
    }

    function _renderJobList() {
        if (!finalCustomerId || !activeTab) {
            return false;
        }

        return (
            <>
                <div className="title">{t('common:active')}</div>

                <JobList
                    jobList={dataJobActive}
                    textEmpty={t('calendar:no_active_jobs')}
                    type={TASK_JOB_LIST.ACTIVE}
                    isLoading={state.isLoadingActive}
                    showMore={state.showMoreActive}
                    loadMore={_handleLoadMoreJob}
                    activeDragable
                />

                <div className="title">{t('common:history')}</div>

                <JobList
                    jobList={dataJobHistory}
                    textEmpty={t('calendar:no_jobs_history')}
                    type={TASK_JOB_LIST.HISTORY}
                    isLoading={state.isLoadingHistory}
                    showMore={state.showMoreHistory}
                    loadMore={_handleLoadMoreJob}
                />
            </>
        );
    }

    function _renderLocations() {
        if (!finalCustomerId || !activeTab) {
            return false;
        }

        return (
            <FilterLocaltions
                customerId={finalCustomerId}
                title={finalLocation.address}
                selected={finalLocationId}
                onSelect={_getLocation}
            />
        );
    }

    return (
        <div className={`tab-conts detail-joblist ${activeTab ? 'tab-content-active' : ''}`}>
            <div className="scrolls" ref={refJobList}>
                <SearchCustomer selectCustomer={_getCustomer} selectJobListCustomer={selectCustomer} />
                {_renderLocations()}
                <div
                    id={'external-events-job-list'}
                    className="external-events-list-job details-jobslist external-events"
                >
                    {_renderJobList()}
                </div>
            </div>
            <JobListService jobActive={dataJobActive} jobHistory={dataJobHistory} onUpdateJobs={_handleUpdateJobs} />
        </div>
    );
};

export default TabJobList;

import classNames from 'classnames';
import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import GridEmpty from 'app/components/grid/components/GridEmpty';
import { ADDONS, ADDONS_API_KEYS, ADDONS_TIME_WINDOW } from 'app/config/routes';
import { ID_API_KEY, ID_TIME_WINDOW } from 'app/const/addons';
import { COMMON } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { PERMISSIONS } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import IconGrowth from 'assets/icon/IconGrowth';
import IconUpgrade from 'assets/icon/IconUpgrade';
import { checkPermission } from 'common/utils/PermissionUtils';

const AddonsHeaderTabs = ({ addons = [], typeAccount = {}, shouldRemoveSettings = false, onLinkClick = () => {} }) => {
    const { t } = useTranslation();
    const permissionsList = useSelector(({ auth }) => auth.user.permissions.enabled) || [];
    const isHavePermissionAddons = checkPermission(permissionsList, PERMISSIONS.accessAddonsTab);
    const isHavePermissionSettings = checkPermission(permissionsList, PERMISSIONS.accessSettingsTab);

    const [state, dispatchState] = useReducer(reducer, {
        activeTab: isHavePermissionAddons ? COMMON.ACTIVE : COMMON.SETTINGS
    });
    const { activeTab } = state;

    const _handleChangeTab = (tab) => dispatchState({ activeTab: tab });

    if (!isHavePermissionSettings && !isHavePermissionAddons) return null;

    return (
        <div className="nav-boxs --list-addons">
            <div className="nav-boxs__title nav-tabs">
                <TabButton
                    title={t('addons:active_addons')}
                    activeTab={activeTab}
                    count={addons[COMMON.ACTIVE].total}
                    type={COMMON.ACTIVE}
                    budgetClassName="--green"
                    onChange={_handleChangeTab}
                    isHidden={!isHavePermissionAddons}
                />
                <TabButton
                    title={t('addons:inactive')}
                    activeTab={activeTab}
                    count={addons[COMMON.INACTIVE].total}
                    type={COMMON.INACTIVE}
                    budgetClassName="--grey"
                    onChange={_handleChangeTab}
                    isHidden={!isHavePermissionAddons}
                />
                {shouldRemoveSettings ? null : (
                    <TabButton
                        title={t('addons:settings')}
                        activeTab={activeTab}
                        type={COMMON.SETTINGS}
                        budgetClassName="--grey"
                        onChange={_handleChangeTab}
                    />
                )}
            </div>
            <TabPanel
                data={addons[activeTab].data}
                className={activeTab}
                typeAccount={typeAccount}
                onLinkClick={onLinkClick}
            />
        </div>
    );
};

const TabPanel = ({ data = [], typeAccount = {}, className = '', onLinkClick = () => {} }) => {
    const { t } = useTranslation('addons');
    const isSettingTab = className === COMMON.SETTINGS;

    return (
        <ul className={`nav-list ${isSettingTab ? 'active-settings' : `${className}-addons`}  `}>
            {!!!data.length && <GridEmpty className="tables-empty" />}
            {data.map((item, index) => {
                let finalUrl =
                    item.id === ID_TIME_WINDOW
                        ? ADDONS_TIME_WINDOW
                        : item.id === ID_API_KEY
                        ? ADDONS_API_KEYS
                        : `${ADDONS}/${item.id}`;
                if (isSettingTab || item.url) finalUrl = item.url;
                return (
                    <li key={item?.id || index.toString()}>
                        <Link
                            to={addBranchPath(finalUrl)}
                            title={t(`addons:${item.title}`)}
                            className={`${item.class || 'items'} `}
                            onClick={onLinkClick}
                        >
                            {item.icon}
                            <p className="txt-ellipsis">{t(`addons:${item.title}`)}</p>
                            {item.isBeta && <div className="status-btn --beta">{t('addons:beta')}</div>}
                            {item.isEarlyAccess && <div className="status-btn --beta">{t('addons:early_access')}</div>}
                            {(typeAccount?.isBasicPlan || typeAccount?.isTrail) && item.isBadge && (
                                <div className="status-btn --has-icon">
                                    <IconUpgrade isAddonUpgrade />
                                    {t(`addons:${item.badgeTitle}`)}
                                </div>
                            )}
                            {!typeAccount?.isGrowthPlan && item.isGrowthBadge && (
                                <div className="status-btn --has-icon --growth">
                                    <IconGrowth />
                                    {t(`addons:${item.badgeTitle}`)}
                                </div>
                            )}
                        </Link>
                    </li>
                );
            })}
        </ul>
    );
};

const TabButton = ({
    activeTab,
    type,
    title,
    count = 0,
    budgetClassName = '',
    isHidden = false,
    onChange = () => {}
}) => {
    if (isHidden) return null;

    return (
        <div className={classNames('nav-tabs__items', { active: activeTab === type })} onClick={() => onChange(type)}>
            {title}
            {!!count && <span className={classNames('budget', budgetClassName)}>{count}</span>}
        </div>
    );
};

export default AddonsHeaderTabs;

import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useDispatch } from 'react-redux';

import ButtonSave from 'app/components/button/ButtonSave';
import StatusBar from 'app/components/status/statusbar';
import { reducer } from 'app/const/Reducer';
import CompanyLogo from 'app/modules/settings/company/components/CompanyLogo';
import IconClose from 'assets/icon/IconClose';
import { crateAndUpdateCompany, getDetailCompany } from 'common/redux/actions/settings/companyAction';
import { showStatusBar } from 'common/utils/FunctionUtils';

const ModalAddLogo = ({ onAddSuccess = () => {} }, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { isVisible: false, data: {}, isLoading: true });
    const { isVisible, data, isLoading } = state;
    const { logo, default_logo } = data;

    const refButtonSave = useRef(null);
    const refStatus = useRef(null);
    const refLogo = useRef({});

    useImperativeHandle(ref, () => ({ _open: _handleOpen, _close: _handleClose }));

    useEffect(() => {
        if (isVisible) _getCompanySetting();
    }, [isVisible]);

    const _getCompanySetting = () => {
        const _handleSuccess = ({ data }) => {
            dispatchState((prev) => ({ ...prev, data: data || {}, isLoading: false }));
        };
        const _handleFail = ({ message }) => {
            showStatusBar({ id: 'get_company_setting_fail', message, success: false, refAlert: refStatus });
            dispatchState((prev) => ({ ...prev, isLoading: false }));
        };
        dispatch(getDetailCompany({}, _handleSuccess, _handleFail));
    };

    const _handleOpen = () => {
        dispatchState((prev) => ({ ...prev, isVisible: true, isLoading: true }));
    };

    const _handleClose = () => {
        if (refButtonSave.current && refButtonSave.current.getStatusBtn()) return;
        dispatchState((prev) => ({ ...prev, isVisible: false, data: {}, isLoading: false }));
    };

    const _handleSave = () => {
        const params = { ...data };
        const { url: finalLogo, object_key, object_tag } = refLogo.current;
        if (object_key) params.logo = { object_key, object_tag };
        if (finalLogo) {
            if (finalLogo === default_logo) params.logo = null;
            params.newLogoUrl = finalLogo;
        } else {
            params.logo = '';
            params.newLogoUrl = logo;
        }

        const _handleSuccess = () => {
            dispatchState((prev) => ({ ...prev, isVisible: false, data: {}, isLoading: false }));
            onAddSuccess();
            _removeLoading();
        };
        const _handleFail = ({ message }) => {
            showStatusBar({ id: 'add_logo_fail', message, success: false, refAlert: refStatus });
            _removeLoading();
        };
        dispatch(crateAndUpdateCompany(params, _handleSuccess, _handleFail));
    };

    const _removeLoading = () => {
        refButtonSave.current && refButtonSave.current.removeLoading();
    };

    const _handleDeleteLogoSuccess = () => {
        refLogo.current = { url: default_logo, object_key: '', object_tag: '' };
    };

    const _handleDisableSave = (value) => {
        refButtonSave.current && refButtonSave.current.setDisable(value);
    };

    const _handleUploadLogoSuccess = (url, object_key, object_tag) => {
        refLogo.current = { url, object_key, object_tag };
        _handleDisableSave(false);
    };

    if (!isVisible) return;

    return (
        <ReactModal
            id="modal_add_logo"
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            portalClassName="ReactModalPortal_alert_customer"
            className="modal container-modal modal-add-logo open"
            onRequestClose={_handleClose}
        >
            <div className="modal__overlay bg-fixed" onClick={_handleClose} />
            <div className="modal__container large">
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('onboarding:add_logo')}</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_handleClose}>
                        <IconClose />
                    </div>
                </div>
                <StatusBar ref={refStatus} />
                <div className="body-modal">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <CompanyLogo
                            data={{ logo, default_logo }}
                            onDisableSave={_handleDisableSave}
                            onDeleteLogoSuccess={_handleDeleteLogoSuccess}
                            onUploadLogoSuccess={_handleUploadLogoSuccess}
                        />
                    )}
                </div>
                <div className="footer-modal btn-close">
                    <span className="v2-btn-default --transparent" onClick={_handleClose}>
                        {t('common:cancel')}
                    </span>
                    <ButtonSave ref={refButtonSave} disabled={isLoading} onSave={_handleSave} />
                </div>
            </div>
        </ReactModal>
    );
};

export default forwardRef(ModalAddLogo);

const Loading = () => {
    return (
        <div className="rows --edit-logo has-divider flex-betweenitems wrap-loading">
            <div className="logo">
                <div className="logo-img bg-white-cultured"></div>
            </div>
            <div className="v2-btn-default --grey --twothird loading ml-2">
                <div className="loading --animation --line flex-1"></div>
            </div>
            <div className="v2-btn-default --grey btn-sm loading ml-2">
                <div className="loading --animation --line flex-1"></div>
            </div>
        </div>
    );
};

export default function IconRouter({ isNormal = false }) {
    if (isNormal) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.01493 11.1213L8.5 10.5H15.5L17.9851 11.1213C18.8754 11.3439 19.5 12.1438 19.5 13.0616V15.5C19.5 16.6046 18.6046 17.5 17.5 17.5H6.5C5.39543 17.5 4.5 16.6046 4.5 15.5V13.0616C4.5 12.1438 5.12459 11.3439 6.01493 11.1213Z"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.5 4.5C15.0523 4.5 15.5 4.94772 15.5 5.5V10.5H13.5V5.5C13.5 4.94772 13.9477 4.5 14.5 4.5Z"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M6.5 14.5H12.5" stroke="var(--color-icon)" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />{' '}
                <path d="M15.5 14.5H16.5" stroke="var(--color-icon)" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />{' '}
            </svg>
        );
    }
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.66667 10H15.3333C15.7661 10 16.1871 10.1404 16.5333 10.4L19.2 12.4C19.7036 12.7777 20 13.3705 20 14V17C20 18.1046 19.1046 19 18 19H6C4.89543 19 4 18.1046 4 17V14C4 13.3705 4.29639 12.7777 4.8 12.4L7.46667 10.4C7.81286 10.1404 8.23393 10 8.66667 10Z"
                fill="#C1C9D4"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 4C14.5523 4 15 4.44772 15 5V9H13V5C13 4.44772 13.4477 4 14 4Z"
                fill="#7A83A6"
            ></path>
            <path
                d="M13 15H7C6.44772 15 6 15.4477 6 16C6 16.5523 6.44772 17 7 17H13C13.5523 17 14 16.5523 14 16C14 15.4477 13.5523 15 13 15Z"
                fill="#7A83A6"
            ></path>
            <path
                d="M18 16C18 15.4477 17.5523 15 17 15C16.4477 15 16 15.4477 16 16C16 16.5523 16.4477 17 17 17C17.5523 17 18 16.5523 18 16Z"
                fill="#7A83A6"
            ></path>
        </svg>
    );
}

import GdGridView from 'app/components/grid/GdGridView';
import GDModalWarning from 'app/components/modal/ModalWarning';
import { GET_LIST_DOCUMENTS, REPORT_DOCUMENT_TRASH, REPORT_DOCUMENT_UNDELETE } from 'app/const/Api';
import { DEFAULT_PAGE, KEY_REPORT_LOCAL_STORAGE, LIST_STATUS, TYPE_BUTTON_ACTIONS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { REPORT_TYPE } from 'app/const/Reports';
import { getGirdColumnsCustomerDocuments } from 'app/const/customer/CustomerDocuments';
import { TABS_FILTER } from 'app/const/report/ReportFilter';
import { getDefaultParams } from 'app/const/report/ReportParams';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import { customersDocuments, handleAbortController, resetPagingLocation } from 'app/modules/customer/utils';
import NewDocument from 'app/modules/quickadd/document';
import ReportPagination from 'app/modules/report/components/ReportPagination';
import { actionOpenDocumentDetail } from 'common/redux/actions/documentAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { React, useContext, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CustomerDetailContext } from '../context/CustomerDetailContext';
import ActionHeader from './components/ActionHeader';
import { convertParamFields } from 'common/utils/ReportUtils';

const CustomerDocument = () => {
    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        checkedItems: { is_check_all: false, ids: [] },
        totalPage: 1,
        total: 0,
        isLoading: true,
        deleted: 1,
        currentPage: DEFAULT_PAGE
    });

    const keyLocalStore = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.DOCUMENT_CUSTOMER_LIST);

    const { id } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation(['customers']);
    const { data: documents, isLoading, checkedItems, total: finalTotal, deleted: finalDeleted } = state;
    const refWarning = useRef(null);
    const refAlert = useRef(null);
    const refAddDocument = useRef(null);
    const refOldLocation = useRef(null);
    const refOldUnit = useRef(null);
    const { location_ids, unitSelected, reloadCustomerPage } = useContext(CustomerDetailContext);
    const abortController = useRef(null);

    const paramsCustomersDocument = getLocalStorage(keyLocalStore);
    !paramsCustomersDocument && setLocalStorage(keyLocalStore, getDefaultParams(REPORT_TYPE.DOCUMENT_CUSTOMER_LIST));

    useEffect(() => {
        if (checkReload(location_ids, unitSelected) || reloadCustomerPage) {
            if (!isLoading) dispatchState({ isLoading: true });
            refOldLocation.current = location_ids;
            refOldUnit.current = unitSelected.id;
            _getListDocumentCustomer(
                resetPagingLocation({
                    params: paramsCustomersDocument || getDefaultParams(REPORT_TYPE.DOCUMENT_CUSTOMER_LIST),
                    key: keyLocalStore
                })
            );
        }

        return () => {
            handleAbortController(abortController);
        };
    }, [location_ids, unitSelected, reloadCustomerPage]);

    const checkReload = (location_ids, unitSelected) => {
        if (!location_ids || !unitSelected) return false;
        return location_ids !== refOldLocation.current || unitSelected?.id !== refOldUnit.current;
    };

    const _getListDocumentCustomer = (data, notShowLoading = false, isEmptyFields = false) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();

        const params = data ? _getNewOptionsRequest(data) : paramsCustomersDocument;

        const optionsQuery = {
            data: {
                customer_id: id,
                location_ids,
                unit_ids: unitSelected?.id,
                ...convertParamFields({ params, isEmptyFields })
            },
            method: 'GET',
            abortController: abortController.current
        };

        if (!notShowLoading) {
            const paramReducer = {};
            paramReducer.checkedItems = { is_check_all: false, ids: [] };
            paramReducer.isLoading = true;
            paramReducer.deleted = params.deleted;
            dispatchState(paramReducer);
        }

        const _success = ({ data, total }) => {
            dispatchState((prev) => ({
                ...prev,
                data: customersDocuments(data),
                isLoading: false,
                totalPage: !isEmptyFields ? Math.ceil(total / params.limit) : prev.totalPage,
                total: !isEmptyFields ? total : prev.total
            }));
        };

        const _failed = ({ isAborted = false }) => {
            if (isAborted) return;
            dispatchState({ data: [], isLoading: false });
        };
        clientQuery(GET_LIST_DOCUMENTS, optionsQuery, _success, _failed);
    };

    const _handleUpdate = ({ isEmptyFields = false } = {}) => {
        const params = getLocalStorage(keyLocalStore);
        _getListDocumentCustomer(params, false, isEmptyFields);
    };

    const _getNewOptionsRequest = (params) => {
        if (!params.total) params.total = 1;
        params.state = params.filterTab;
        params.deleted = params.state;
        return params;
    };

    const onChangeStateCheckedItems = (checkedItems) => {
        dispatchState({ checkedItems });
    };

    const _handleClickAction = (type) => {
        const ids = checkedItems.ids;

        if (!ids.length)
            return refWarning.current._open({
                title: t('customers:title_warning_document'),
                description: t('customers:description_warning_document')
            });

        switch (type) {
            case TYPE_BUTTON_ACTIONS.DELETE:
                _handleDeleteDocumentsCustomer({ ids });
                break;
            case TYPE_BUTTON_ACTIONS.TRASH:
                _handlePermanentlyDelete({ ids });
                break;
            case TYPE_BUTTON_ACTIONS.UNDELETE:
                _handleUnDelete({ ids });
                break;
            default:
                break;
        }
    };

    const _handleDeleteDocumentsCustomer = (data) => {
        clientQuery(
            GET_LIST_DOCUMENTS,
            { data, method: 'DELETE' },
            (response) => _handleSuccessDelete(response, data.ids),
            null
        );
    };

    const _handleUnDelete = (data) => {
        clientQuery(
            REPORT_DOCUMENT_UNDELETE,
            { data, method: 'PUT' },
            (response) => _handleSuccessDelete(response, data.ids),
            null
        );
    };

    const _handlePermanentlyDelete = (data) => {
        clientQuery(
            REPORT_DOCUMENT_TRASH,
            { data, method: 'DELETE' },
            (response) => _handleSuccessDelete(response, data.ids),
            null
        );
    };

    const _handleAddDocument = () => {
        refAddDocument.current._handleOpenForm();
    };

    const _handleSuccessDelete = ({ message }, ids = []) => {
        const params = getLocalStorage(keyLocalStore);
        const { filterTab, currentPage, offset, limit } = params;
        params.state = filterTab;

        if (finalTotal - ids.length <= offset && currentPage > 1) {
            params.currentPage = Math.max(currentPage - 1, 1);
            params.offset = offset - limit;
            setLocalStorage(keyLocalStore, params);
        }

        if (message.length) {
            refAlert.current.showStatusBar({ id: 'success', message, type: LIST_STATUS.SUCCESS });
        }

        _getListDocumentCustomer(params);
    };

    const _handleAddDocsSuccess = () => {
        if (paramsCustomersDocument.filterTab !== TABS_FILTER.DELETE_CUSTOMER_DOCUMENT.value) {
            _handleUpdate();
        }
    };

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: REPORT_TYPE.DOCUMENT_CUSTOMER_LIST,
            columnsTarget,
            currentValue,
            paramsReport: paramsCustomersDocument,
            callBack: () => _handleUpdate({ isEmptyFields: true })
        });
    };

    const _handleDeleteDocumentsDetail = () => {
        _getListDocumentCustomer(getLocalStorage(keyLocalStore));
    };

    const _handleClick = ({ row }) => {
        if (finalDeleted !== DEFAULT_PAGE) return;
        dispatch(
            actionOpenDocumentDetail({
                id: row.id,
                name: row.name,
                onDeleteProp: _handleDeleteDocumentsDetail,
                onUpdateProp: _handleForceUpdate
            })
        );
    };

    function _handleForceUpdate(documentId, data) {
        dispatchState((prev) => {
            return {
                ...prev,
                data: prev.data.map((item) => {
                    if (item.id.toString() === documentId.toString()) {
                        return {
                            ...item,
                            ...data
                        };
                    }
                    return item;
                })
            };
        });
    }

    const _handleChangePagination = (params, isChangeTab = false) => {
        _handleUpdate({ isEmptyFields: !isChangeTab });
    };

    return (
        <>
            <div className="wrapper-columns">
                <div className="container-print has-tab contents-pages gap-8">
                    <div className="wrap-tables flex-column relative">
                        <AlertCustomer ref={refAlert} />
                        <GDModalWarning ref={refWarning} />
                        <ActionHeader
                            totalItems={state.total}
                            checkedItems={checkedItems}
                            handleUpdate={_handleUpdate}
                            onClickAction={_handleClickAction}
                            onAddDocument={_handleAddDocument}
                            params={paramsCustomersDocument}
                            keyLocalStore={keyLocalStore}
                        />
                        <GdGridView
                            content={documents}
                            isLoading={isLoading}
                            msgEmpty={t('no_documents')}
                            classTable="has-checkbox has-footer scrolls-x"
                            fileTranslation={'report'}
                            checkedItems={checkedItems}
                            handleClick={_handleClick}
                            handleClickHeader={_handleActionHeader}
                            onChangeStateCheckedItems={onChangeStateCheckedItems}
                            showCheckBox
                            {...getGirdColumnsCustomerDocuments(
                                paramsCustomersDocument?.order,
                                paramsCustomersDocument?.filterTab
                            )}
                            isScroll
                        />
                        <ReportPagination
                            isCustomerPage
                            reportType={REPORT_TYPE.DOCUMENT_CUSTOMER_LIST}
                            totalPage={state.totalPage || 1}
                            onSelect={_handleChangePagination}
                        />
                    </div>
                </div>
                <NewDocument
                    ref={refAddDocument}
                    idCustomer={id}
                    onAddDocsSuccess={_handleAddDocsSuccess}
                    isDocCustomer
                />
            </div>
        </>
    );
};

export default CustomerDocument;

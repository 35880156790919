import classNames from 'classnames';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconCheck from 'assets/icon/IconCheck';
import { DEFAULT_SELECT_OPTIONS } from '../../constants';

const SelectCondition = ({
    label = '',
    items = [],
    keyId = 'id',
    selected = DEFAULT_SELECT_OPTIONS,
    onSelect = () => {}
}) => {
    const { t } = useTranslation(['smartView', 'customers']);
    const refDropdown = useRef(null);
    const selectedLabel =
        selected?.id === DEFAULT_SELECT_OPTIONS['id'] ? t(DEFAULT_SELECT_OPTIONS['label']) : t(selected?.label);

    const handleSelect = (item) => {
        refDropdown.current?._close();
        if (item?.id === selected?.id) return;
        onSelect(item);
    };

    return (
        <>
            {label && <p className="txt-ellipsis label-time">{label}</p>}
            <DropdownPopper
                ref={refDropdown}
                id="select-condition"
                isUseToggle
                isCalculateWidth
                isCheckHidden
                wrapperClassName="v2-dropdown field"
                buttonClassName="dropbtn items"
                modifiers={[{ name: 'offset', options: { offset: [0, 5] } }]}
                customButton={
                    <>
                        <span className="txt-ellipsis mr-2">{selectedLabel}</span>
                        <div className="arrow">
                            <IconArrowDown />
                        </div>
                    </>
                }
            >
                <ListSelectCondition items={items} selected={selected} keyId={keyId} onSelect={handleSelect} />
            </DropdownPopper>
        </>
    );
};

const ListSelectCondition = ({ items = [], selected = DEFAULT_SELECT_OPTIONS, keyId, onSelect = () => {} }) => {
    return (
        <ul>
            {items.map((item, index) => (
                <SelectConditionItem
                    key={`${item.id}-${index}`}
                    isActive={selected?.[keyId] === item?.[keyId]}
                    item={item}
                    shouldNotRender={index >= items.length - 1 && typeof item === 'string'}
                    onSelect={onSelect}
                />
            ))}
        </ul>
    );
};

const SelectConditionItem = ({ item, isActive = false, shouldNotRender = false, onSelect = () => {} }) => {
    const { t } = useTranslation(['smartView', 'customers']);
    if (shouldNotRender) return null;
    if (typeof item === 'string') return <div className="line" />;
    return (
        <li
            className={classNames('items --ticked svg-purple', { active: isActive })}
            onClick={() => {
                onSelect(item);
            }}
        >
            <IconCheck />
            <span className="txt-ellipsis">{t(item.label)}</span>
        </li>
    );
};

SelectCondition.displayName = 'SelectCondition';
export default SelectCondition;

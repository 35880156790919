import React from 'react';

export default function IconDone({
    isHasColor = false,
    isSuccess = false,
    isLarge = false,
    isSquare = false,
    isActiveColor = false
}) {
    if (isLarge)
        return (
            <svg width={70} height={70} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width={70} height={70} rx={35} fill="#1EAA5C" />
                <path d="M19 35L31.5 47.5L53 26" stroke="white" strokeWidth={7} />
            </svg>
        );
    if (isSuccess) {
        return (
            <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width={16} height={16} rx={8} fill="#1EAA5C" />
                <path
                    d="M5.5 8.5L7.5 10.5L11.5 6.5"
                    stroke="white"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
    if (isHasColor)
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 4C13.2528 4 15.2881 4.93117 16.742 6.42962L11 12.171L8.41421 9.58579L8.287 9.46895C7.50121 8.8069 6.32573 8.84585 5.58579 9.58579C4.80474 10.3668 4.80474 11.6332 5.58579 12.4142L11 17.8284L18.7644 10.0649C18.9183 10.6845 19 11.3327 19 12C19 16.4183 15.4183 20 11 20C6.58172 20 3 16.4183 3 12C3 7.58172 6.58172 4 11 4Z"
                    fill="#C1C9D4"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.2929 6.29289C18.6834 5.90237 19.3166 5.90237 19.7071 6.29289C20.0676 6.65338 20.0953 7.22061 19.7903 7.6129L19.7071 7.70711L11 16.4142L6.29289 11.7071C5.90237 11.3166 5.90237 10.6834 6.29289 10.2929C6.65338 9.93241 7.22061 9.90468 7.6129 10.2097L7.70711 10.2929L11 13.585L18.2929 6.29289Z"
                    fill="#7A83A6"
                ></path>
            </svg>
        );
    if (isSquare)
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.5 12.5L11 16L17.5 9.5"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </svg>
        );
    if (isActiveColor)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 4C13.2528 4 15.2881 4.93117 16.742 6.42962L11 12.171L8.41421 9.58579L8.287 9.46895C7.50121 8.8069 6.32573 8.84585 5.58579 9.58579C4.80474 10.3668 4.80474 11.6332 5.58579 12.4142L11 17.8284L18.7644 10.0649C18.9183 10.6845 19 11.3327 19 12C19 16.4183 15.4183 20 11 20C6.58172 20 3 16.4183 3 12C3 7.58172 6.58172 4 11 4Z"
                    fill="#DAC2F2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.2929 6.29289C18.6834 5.90237 19.3166 5.90237 19.7071 6.29289C20.0676 6.65338 20.0953 7.22061 19.7903 7.6129L19.7071 7.70711L11 16.4142L6.29289 11.7071C5.90237 11.3166 5.90237 10.6834 6.29289 10.2929C6.65338 9.93241 7.22061 9.90468 7.6129 10.2097L7.70711 10.2929L11 13.585L18.2929 6.29289Z"
                    fill="#8D4AFC"
                />
            </svg>
        );
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.375 12.2917L11.4167 14.3333L15.2083 10.5417"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default function IconEditCard() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5 4.5H6.5C5.39543 4.5 4.5 5.39543 4.5 6.5V15.3787C4.5 16.7369 5.03957 18.0396 6 19C6.96043 19.9604 8.26306 20.5 9.62132 20.5H16.5C17.6046 20.5 18.5 19.6046 18.5 18.5V6.5C18.5 5.39543 17.6046 4.5 16.5 4.5Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M4.5 13.5V14.5C4.5 15.6046 5.39543 16.5 6.5 16.5C7.60457 16.5 8.5 17.3954 8.5 18.5C8.5 19.6046 9.39543 20.5 10.5 20.5H11.5077"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M11.5 8.5V14.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M8.50391 11.5H14.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
}

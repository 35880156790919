import ButtonSave from 'app/components/button/ButtonSave';
import { customerEditCredit } from 'app/const/Api';
import { COMMON, LIST_STATUS, REGEX_NUMBER } from 'app/const/App';
import CalendarDatePicker from 'app/modules/calendar/header/components/date/DatePicker';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import { CustomerDetailContext } from 'app/modules/customer/detail/context/CustomerDetailContext';
import CreditPaymentMethod from 'app/modules/customer/detail/credits/components/CreditPaymentMethod';
import { LIST_PAYMENT_METHODS } from 'app/modules/jobdetail/const/Invoice';
import { clientQuery } from 'common/utils/ApiUtils';
import { convertPhpFormatToMoment, convertTimeToISO } from 'common/utils/DateUtils';
import moment from 'moment';
import React, { forwardRef, useContext, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useParams } from 'react-router-dom';
import IconClose from 'assets/icon/IconClose';
import { reducer } from 'app/const/Reducer';
import { roundingNumber } from 'common/utils/NumberUtils';
import { useSelector } from 'react-redux';

const EditForm = forwardRef(({ onEditSuccess = () => {} }, ref) => {
    const { id } = useParams();
    const { t } = useTranslation(['customers']);
    const dateFormatCompany = useSelector(({ auth }) => auth.user.company.date_format);

    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        disable: false,
        isPayment: false,
        data: {}
    });
    const { customer } = useContext(CustomerDetailContext);

    const refAlert = useRef(null);
    const refButtonSave = useRef(null);

    const { data: dataCredit } = state;
    const dateFormat = convertTimeToISO(moment(dataCredit.date));
    const refAmount = useRef(null);

    useImperativeHandle(ref, () => ({ _showModal, _hideModal }));

    const _showModal = (dataCredit = {}, isPayment = false) => {
        let data = { ...dataCredit };
        refAmount.current = roundingNumber(dataCredit.amount);

        if (isPayment) {
            data = {
                id: dataCredit.id,
                memo: dataCredit.memo || '',
                check_number: dataCredit.check_number || '',
                check_value: dataCredit.check_value || '',
                amount: dataCredit.amount || ''
            };
        }

        const formattedDate = moment(dataCredit.date, convertPhpFormatToMoment(dateFormatCompany));
        dispatchState({
            data: {
                ...data,
                method_name: dataCredit.method_name || {},
                date: formattedDate.isValid() ? formattedDate : dataCredit.date
            },
            isOpen: true,
            disable: true,
            isPayment
        });
    };

    const _hideModal = () => {
        dispatchState({ isOpen: false });
    };

    const _handleSelect = (value) => {
        dispatchState({
            data: {
                ...state.data,
                method_name: value,
                memo: '',
                check_number: ''
            }
        });
    };

    const _handleChange = (e, field) => {
        const value = e.target.value;
        if (field === COMMON.AMOUNT && value !== '' && !REGEX_NUMBER.test(value)) {
            return false;
        }

        if (field === 'memo' || field === 'check_number') {
            dispatchState({ data: { ...state.data, memo: value, check_number: value } });
        } else {
            dispatchState({ data: { ...state.data, [field]: value } });
        }
    };

    const _handleBlur = (e, field) => {
        const value = e.target.value;
        refAmount.current.value = roundingNumber(value);

        dispatchState({ data: { ...state.data, [field]: value } });
    };

    const _handleSelectDate = (date) => {
        dispatchState({ data: { ...state.data, date: date } });
    };

    const _handleEdit = () => {
        const amount = dataCredit.amount || 0;
        if (isNaN(amount)) {
            refButtonSave.current.removeLoading();
            return refAlert.current.showStatusBar({
                id: LIST_STATUS.ERROR,
                message: t('customers:error_submit_form_credit'),
                type: LIST_STATUS.ERROR
            });
        }
        if (Number(amount) < 0) {
            refButtonSave.current.removeLoading();
            return refAlert.current.showStatusBar({
                id: LIST_STATUS.ERROR,
                message: t('customers:amount_is_not_negative_value'),
                type: LIST_STATUS.ERROR
            });
        }

        clientQuery(
            customerEditCredit(id, dataCredit.id),
            {
                data: { ...dataCredit, amount, date: dateFormat, method_name: dataCredit.method_name?.name },
                method: 'PUT'
            },
            _handleEditSuccess,
            _handleEditFailed
        );
    };

    const _handleEditSuccess = ({ message }) => {
        onEditSuccess(message);
        _hideModal();
    };

    const _handleEditFailed = ({ message }) => {
        refButtonSave.current.removeLoading();
        refAlert.current.showStatusBar({
            id: LIST_STATUS.ERROR,
            message: message || t('customers:error_submit_form_credit'),
            type: LIST_STATUS.ERROR
        });
    };

    const _renderFooter = () => {
        return (
            <div className="footer-modal btn-close">
                <span className="v2-btn-default --noborder --label" onClick={_hideModal}>
                    {t('customers:cancel')}
                </span>
                <ButtonSave
                    ref={refButtonSave}
                    title={t('customers:save')}
                    wrapClass="v2-btn-main"
                    onSave={_handleEdit}
                />
            </div>
        );
    };

    if (!state.isOpen) return null;
    return (
        <ReactModal
            isOpen
            id="addCredit"
            className="modal container-modal --credit open"
            style={{ overlay: { zIndex: 99, background: 'transparent' } }}
            onRequestClose={_hideModal}
        >
            <div className="modal__overlay bg-fixed" onClick={_hideModal} />
            <div className="modal__container">
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('customers:update_credit')}</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_hideModal}>
                        <IconClose />
                    </div>
                </div>
                <div className="body-modal">
                    <AlertCustomer ref={refAlert} />
                    <div className="has-form has-sm-datepicker">
                        <div className="rows --customer-name">
                            <div className="txt">{t('customers:customer')}</div>
                            <div className="field-input">{customer.full_name}</div>
                        </div>
                        <div className="rows mt-3">
                            <div className="width-xs">
                                <div className="txt">{t('customers:amount')}</div>
                                <div className="cost-input --right" data-currency-unit="$">
                                    <input
                                        ref={refAmount}
                                        className="field-input"
                                        type="number"
                                        placeholder={t('customers:amount')}
                                        onBlur={(e) => {
                                            _handleBlur(e, COMMON.AMOUNT);
                                        }}
                                        defaultValue={roundingNumber(dataCredit.amount)}
                                    />
                                </div>
                            </div>
                            <div className="date-box width-xs">
                                <div className="txt">{t('customers:date')}</div>
                                <CalendarDatePicker selectDefault={dateFormat} onChange={_handleSelectDate} />
                            </div>
                        </div>
                        <CreditPaymentMethod
                            isOpen={state.isOpen}
                            defaultCheckNumber={dataCredit.check_number || ''}
                            defaultCheckValue={dataCredit.check_value || ''}
                            defaultMemo={dataCredit.memo || ''}
                            onSelect={_handleSelect}
                            onChange={_handleChange}
                            defaultMethod={dataCredit.method_name || LIST_PAYMENT_METHODS[1]}
                            disable={state.disable}
                        />
                    </div>
                </div>
                {_renderFooter()}
            </div>
        </ReactModal>
    );
});

export default EditForm;

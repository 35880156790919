import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { replaceSpaceByToken } from 'common/utils/StringUtils';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconStar from 'assets/icon/IconStar';
import { MAP_QUEST_LOG_LEVELS } from 'app/const/Header';
import IconBachelorsHat from 'assets/icon/IconBachelorsHat';
import classNames from 'classnames';

function ListLevel({ allLevels = [] }) {
    const { t } = useTranslation(['header']);

    const [currentSelectLevel, setCurrenSelectLevel] = useState(MAP_QUEST_LOG_LEVELS);

    const handleChooseLevel = (nameLevel) => {
        if (currentSelectLevel.includes(nameLevel)) {
            setCurrenSelectLevel(currentSelectLevel.filter((item) => item !== nameLevel));
            return;
        }
        setCurrenSelectLevel([...currentSelectLevel, nameLevel]);
    };

    const checkLevelActive = (level) => {
        return currentSelectLevel.includes(level);
    };

    const _renderLevelTitle = (name, is_pro, percent) => {
        return (
            <div className="accordion-item" onClick={() => handleChooseLevel(name)}>
                <div className="flex-betweenitems mb-1">
                    <div className="flexcenter">
                        <span className="fw-600">{t(`header:${replaceSpaceByToken(name.toLowerCase(), '_')}`)}</span>
                        {is_pro && (
                            <div className="status-btn --pro svg-white">
                                <IconStar />
                                {t('header:pro')}
                            </div>
                        )}
                    </div>
                    <div className={classNames('range-percent', { 'is-zero': !percent })}>
                        {percent}%
                        <div className="chevron">
                            <IconArrowDown />
                        </div>
                    </div>
                </div>
                <div className={`progress-bar --green per-${percent}`}>
                    <span />
                </div>
            </div>
        );
    };

    const _renderLevelsDetail = (items, name) => {
        return (
            <div className={classNames('accordion-panel', { 'dp-block': checkLevelActive(name) })}>
                {items.map((item) => _renderItemsDetail(item))}
            </div>
        );
    };

    const _renderItemsDetail = (item) => {
        const { count, total, link, name } = item;
        const checked = count === total;
        const finalName = t(`header:${replaceSpaceByToken(name.toLowerCase(), '_')}`);

        return (
            <a
                href={link}
                className={classNames('items', { completed: checked })}
                key={link}
                rel="noreferrer"
                target="_blank"
            >
                {checked ? (
                    <>
                        <div className="check-items check-items--green">
                            <input type="checkbox" id={`check-items-${link}`} readOnly checked={checked} />
                            <div className="item-checkbox">
                                <label htmlFor={`check-items-${link}`} />
                            </div>
                        </div>
                        <span className="txt" title={finalName}>
                            {finalName}
                        </span>
                    </>
                ) : (
                    <span className="txt" title={finalName}>
                        {`[${count}/${total}]`}&nbsp;
                        {finalName}
                    </span>
                )}
                <div className="tooltip">
                    <IconBachelorsHat isNormal />
                    <p className="tooltiptext top">
                        {t('knowledge_base')}
                    </p>
                </div>
            </a>
        );
    };

    return allLevels.map((level, index) => {
        const { name } = level;
        return (
            <React.Fragment key={index.toString()}>
                <div className="line" />
                <div className={classNames('accordion', { active: checkLevelActive(name) })}>
                    {_renderLevelTitle(name, level.is_pro, level.percent)}
                    {_renderLevelsDetail(level.items, name)}
                </div>
            </React.Fragment>
        );
    });
}

export default ListLevel;

import loadable from '@loadable/component';
import classNames from 'classnames';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ButtonSave from 'app/components/button/ButtonSave';
import GDModalWarning from 'app/components/modal/ModalWarning';
import { INBOX } from 'app/config/routes';
import { BLOCK_PHONE_NUMBER, GET_LIST_INBOXES } from 'app/const/Api';
import { RE_SEND_SMS_BY_CUSTOMER } from 'app/const/api/Sms';
import { KEY_REPORT_LOCAL_STORAGE, LIST_PAGE_SIZE, PAGE_SIZE_DEFAULT } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { SMS_FAILED } from 'app/const/Sms';
import { LIST_STATUS } from 'app/const/Status';
import {
    CONST_INBOX_PAGE,
    FILTER_STATUS_INBOX,
    FILTER_TYPE_INBOX,
    INBOX_ACTION_CONFIGS,
    LIST_SORT_INBOX,
    SMS_SENT_STATUS,
    TYPE_RENDER
} from 'app/modules/inbox/const';
import IconUndo from 'assets/icon/IconUndo';
import { actionAssignLead } from 'common/redux/actions/assignLead';
import { getEmailInboxSignature } from 'common/redux/actions/authAction';
import { createCustomer } from 'common/redux/actions/customer';
import { clientQuery } from 'common/utils/ApiUtils';
import { convertPhpFormatToMoment } from 'common/utils/DateUtils';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';
import { TYPE_CALL_TIME_LINE } from '../customer/const/Call';
import { checkSentSms } from './utils';

const Sms = loadable(() => import('./sms'));
const Call = loadable(() => import('./call'));
const ActionDone = loadable(() => import('app/modules/inbox/header/ActionDone'));
const ActionSnooze = loadable(() => import('app/modules/inbox/header/ActionSnooze'));
const FilterType = loadable(() => import('./header/FilterType'));
const AssignTo = loadable(() => import('./header/AssignTo'));
const Sort = loadable(() => import('./header/Sort'));
const Pagination = loadable(() => import('app/components/pagination'));
const EmailInboxDetail = loadable(() => import('app/modules/inbox/email'));
const TaskDetail = loadable(() => import('./tasks'));
const GridEmpty = loadable(() => import('app/components/grid/components/GridEmpty'));
const ActionMoveInbox = loadable(() => import('app/modules/inbox/header/ActionMoveInbox'));
const ActionSend = loadable(() => import('./header/ActionSend'));
const ActionSendLater = loadable(() => import('./header/ActionSendLater'));
const StatusBar = loadable(() => import('app/components/status/statusbar'));

export default function InboxPage({ statusPage, typePage, onChangeType }) {
    const dispatch = useDispatch();
    const { t } = useTranslation(['report', 'common', 'addons']);
    const history = useHistory();
    const abortController = useRef(null);
    const refWarning = useRef(null);
    const refButtonSave = useRef(null);
    const refValuePhone = useRef(null);
    const refStatusBar = useRef();
    const refResendSms = useRef([]);
    const refInboxActionConfig = useRef({});

    const { profile: userProfile, isFetchSignature, company, settings } = useSelector(({ auth }) => auth.user);
    const isActiveEmailInbox = !!settings.addons.inbox_email;
    const isActiveSms = !!settings.addons.smsplivo;

    const companyDateFormat = company?.date_format || '';
    const formatDisplay = convertPhpFormatToMoment(companyDateFormat);
    const paramsReport = getLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', CONST_INBOX_PAGE.INBOX)) || {};

    const [state, setState] = useReducer(reducer, {
        isLoading: true,
        type: typePage,
        status: statusPage,
        keyword: paramsReport?.keyword || '',
        assignee_ids: paramsReport?.assignee_ids || [userProfile.id],
        limit: paramsReport?.limit || PAGE_SIZE_DEFAULT,
        offset: paramsReport?.offset || 0,
        order: LIST_SORT_INBOX[1].type,
        total: 0,
        currentPage: paramsReport?.currentPage || 1,
        dataInbox: [],
        checkedItems: [],
        totalPage: 1
    });

    const {
        assignee_ids: tempUserIds,
        isLoading: finalIsLoading,
        type: finalType,
        status: finalStatus,
        keyword: finalKeyword = '',
        currentPage: finalCurrentPage,
        order: finalSort,
        limit: finalLimit,
        offset: finalOffset,
        checkedItems: finalCheckedItems,
        dataInbox: finalDataInbox,
        total: finalTotal
    } = state;
    const finalAssign = Array.isArray(tempUserIds) ? tempUserIds.join(',') : tempUserIds;
    const numberChecked = finalCheckedItems.length;
    const isSentSms = checkSentSms({ type: finalType, status: finalStatus });
    const filterSelectableItemIds = (data = []) => {
        return data.reduce((result, item) => {
            const isSmsFailed = isSentSms && item.type === TYPE_RENDER.SMS && item.status === SMS_FAILED;
            const isNotSms = item.type !== TYPE_RENDER.SMS;

            return isSmsFailed || isNotSms ? [...result, item.id] : result;
        }, []);
    };

    const isCheckAll =
        numberChecked !== 0 && finalDataInbox && numberChecked === filterSelectableItemIds(finalDataInbox).length;

    useEffect(() => {
        const unregisterHistoryListener = history.listen(({ pathname: nextPathname }) => {
            if (nextPathname.split('/')[1] !== INBOX.replace('/', '')) {
                removeLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', CONST_INBOX_PAGE.INBOX));
            }
        });

        !isFetchSignature && dispatch(getEmailInboxSignature());

        return () => {
            unregisterHistoryListener();
        };
    }, []);

    useEffect(() => {
        _handleChangeStatus(statusPage);
    }, [statusPage]);

    function _handleReloadPage(isBlockNumber = false, isClearStatusBar = true) {
        if (finalTotal > finalLimit || isBlockNumber) {
            //This case check if in last page and remove all item
            // we go back 1 page
            if (isCheckAll && finalOffset + finalLimit >= finalTotal) {
                _handleChangePage({
                    offset: finalOffset - finalLimit,
                    limit: finalLimit,
                    currentPage: finalCurrentPage - 1 || 0,
                    isClearStatusBar
                });
            } else {
                _getListInboxes(
                    {
                        type: finalType,
                        status: finalStatus,
                        keyword: finalKeyword,
                        assignee_ids: finalAssign,
                        limit: finalLimit,
                        offset: finalOffset,
                        order: finalSort,
                        total: 1,
                        checkedItems: []
                    },
                    isClearStatusBar
                );
            }
        }
    }

    function _handleChangeAssign(userSelected) {
        setState({
            dataInbox: [],
            isLoading: true,
            assignee_ids: userSelected,
            offset: 0,
            currentPage: 1,
            checkedItems: [],
            totalPage: 1
        });

        _getListInboxes({
            type: finalType,
            status: finalStatus,
            keyword: finalKeyword,
            assignee_ids: userSelected.toString(),
            limit: finalLimit,
            offset: 0,
            order: finalSort,
            total: 1
        });
    }

    function _handleChangeStatus(status) {
        const isNotHideSortDate = status === FILTER_STATUS_INBOX.DONE;
        const finalOrder = isNotHideSortDate ? finalSort : LIST_SORT_INBOX[1].type;
        let finalTypeInbox = status === FILTER_STATUS_INBOX.DRAFT ? FILTER_TYPE_INBOX.EMAIL : finalType;
        if (status === FILTER_STATUS_INBOX.SENT) {
            if (isActiveEmailInbox && finalTypeInbox !== FILTER_TYPE_INBOX.SMS) {
                finalTypeInbox = FILTER_TYPE_INBOX.EMAIL;
            } else if (isActiveSms || checkSentSms({ type: finalType, status })) {
                finalTypeInbox = FILTER_TYPE_INBOX.SMS;
            }
        }
        setState((prev) => {
            return {
                ...prev,
                dataInbox: [],
                isLoading: true,
                status,
                offset: 0,
                currentPage: 1,
                checkedItems: [],
                totalPage: 1,
                type: finalTypeInbox,
                order: finalOrder,
                total: 0
            };
        });

        if (status !== FILTER_STATUS_INBOX.REPORT) {
            if (typePage !== finalTypeInbox) {
                onChangeType(finalTypeInbox);
            }

            _getListInboxes({
                type: finalTypeInbox,
                status: status,
                keyword: finalKeyword,
                assignee_ids: finalAssign,
                limit: finalLimit,
                offset: 0,
                order: finalOrder,
                total: 1
            });
        }
    }

    function _handleChangetype(type) {
        const isNotHideSortDate = finalStatus === FILTER_STATUS_INBOX.DONE;
        const finalOrder = isNotHideSortDate ? finalSort : LIST_SORT_INBOX[1].type;

        setState({
            dataInbox: [],
            isLoading: true,
            type,
            offset: 0,
            currentPage: 1,
            checkedItems: [],
            totalPage: 1,
            order: finalOrder,
            total: 0
        });

        _getListInboxes({
            type: type,
            status: finalStatus,
            keyword: finalKeyword,
            assignee_ids: finalAssign,
            limit: finalLimit,
            offset: 0,
            order: finalOrder,
            total: 1
        });

        onChangeType(type);
    }

    function _handleChangeSort(typeSort) {
        setState({
            dataInbox: [],
            isLoading: true,
            order: typeSort,
            offset: 0,
            currentPage: 1,
            checkedItems: []
        });

        _getListInboxes({
            type: finalType,
            status: finalStatus,
            keyword: finalKeyword,
            assignee_ids: finalAssign,
            limit: finalLimit,
            offset: 0,
            order: typeSort,
            total: 1
        });
    }

    function _handleDeleteItem(id) {
        setState((prevState) => {
            const newValue = [...prevState.dataInbox];
            return {
                ...prevState,
                dataInbox: newValue.filter((item) => item.id !== id),
                checkedItems: finalCheckedItems.filter((itemChecked) => itemChecked !== id)
            };
        });
    }

    function _handleChangeSearch(keyword) {
        setState((prev) => {
            return {
                ...prev,
                dataInbox: [],
                isLoading: true,
                keyword,
                offset: 0,
                currentPage: 1,
                checkedItems: [],
                totalPage: 1,
                total: 0
            };
        });

        _getListInboxes({
            type: finalType,
            status: finalStatus,
            keyword,
            assignee_ids: finalAssign,
            limit: finalLimit,
            offset: finalOffset,
            order: finalSort,
            total: 1
        });
    }

    function _handleChangePage({ offset, limit, currentPage, isClearStatusBar = true }) {
        const offsetValue = Math.max(offset, 0);
        setState({
            dataInbox: [],
            isLoading: true,
            offset: offsetValue,
            limit,
            currentPage,
            checkedItems: []
        });

        _getListInboxes(
            {
                type: finalType,
                status: finalStatus,
                keyword: finalKeyword,
                assignee_ids: finalAssign,
                limit,
                offset: offsetValue,
                order: finalSort,
                total: 1,
                checkedItems: []
            },
            isClearStatusBar
        );
    }

    function _getListInboxes(params, isClearStatusBar = true) {
        if (isClearStatusBar) _clearStatusBar();
        if (!!abortController.current) abortController.current?.abort();

        abortController.current = new AbortController();

        const finalParams = { ...params };
        if (checkSentSms({ type: finalParams.type, status: finalParams.status })) {
            finalParams[SMS_SENT_STATUS.KEY] = SMS_SENT_STATUS.VALUE;
        }
        clientQuery(
            GET_LIST_INBOXES,
            { method: 'GET', data: finalParams, abortController: abortController.current },
            _getListInboxesSuccess,
            _getListInboxesFailed
        );
        setLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', CONST_INBOX_PAGE.INBOX), {
            ...params,
            offset: 0,
            currentPage: 1
        });
    }

    function _getListInboxesSuccess(response) {
        setState((prev) => {
            const finalTotal = response?.total;
            const isNewTotal = typeof finalTotal !== 'undefined';

            return {
                ...prev,
                dataInbox: response.data,
                isLoading: false,
                totalPage: isNewTotal ? Math.ceil(finalTotal / prev.limit) : prev.totalPage,
                total: isNewTotal ? finalTotal : prev.total,
                checkedItems: isSentSms ? [] : prev.checkedItems
            };
        });
    }

    function _getListInboxesFailed({ isAborted = false, message }) {
        if (!!isAborted) return;
        _showStatusBar({ message });
        setState((prev) => ({ ...prev, isLoading: false }));
    }

    function _handleUpdateCallback(callBack) {
        setState((prevState) => {
            return callBack(prevState);
        });
    }

    function _handleSelect(itemId) {
        setState((prevState) => {
            let ids = [...prevState.checkedItems];
            let findItem = null;

            ids = ids.filter((item) => {
                if (item === itemId) {
                    findItem = item;
                } else {
                    return item;
                }
            });

            !findItem && ids.push(itemId);

            return {
                ...prevState,
                checkedItems: ids
            };
        });
    }

    function _renderListItem() {
        if (finalIsLoading) {
            return (
                <div className="preloader">
                    <div className="loader-wave">
                        <span className="loader-wave__items" />
                    </div>
                </div>
            );
        }

        if (!finalIsLoading && finalDataInbox.length === 0) {
            return <GridEmpty msgEmpty={t('common:your_inbox_is_empty')} isFlat />;
        }

        return finalDataInbox.map((item) => {
            const itemId = item.id;
            const isSelected = finalCheckedItems.includes(itemId);

            switch (item.type) {
                case TYPE_RENDER.EMAIL_INBOX:
                    return (
                        <EmailInboxDetail
                            key={itemId}
                            isSelected={isSelected}
                            emailInBoxDetail={item}
                            onUpdateCallback={_handleUpdateCallback}
                            onDelete={_handleDeleteItem}
                            onReload={_handleReloadPage}
                            onSelect={_handleSelect}
                            filterStatus={finalStatus}
                            formatDisplay={companyDateFormat}
                        />
                    );
                case TYPE_RENDER.TASK:
                    return (
                        <TaskDetail
                            isSelected={isSelected}
                            onSelect={_handleSelect}
                            onReload={_handleReloadPage}
                            key={itemId}
                            taskDetail={item}
                            onUpdateCallback={_handleUpdateCallback}
                            onDelete={_handleDeleteItem}
                            filterStatus={finalStatus}
                            formatDisplay={formatDisplay}
                        />
                    );
                case TYPE_RENDER.CALL:
                    return (
                        <Call
                            key={itemId}
                            item={item}
                            filterStatus={finalStatus}
                            onReload={_handleReloadPage}
                            onDelete={_handleDeleteItem}
                            onUpdateCallback={_handleUpdateCallback}
                            isSelected={isSelected}
                            onSelect={_handleSelect}
                            onCreateLead={_handleCreateLead}
                            onAssignLead={_handleAssignLead}
                            onOpenBlockWarning={_handleOpenBlockWarning}
                        />
                    );
                case TYPE_RENDER.SMS:
                    return (
                        <Sms
                            key={itemId}
                            item={item}
                            onCreateLead={_handleCreateLead}
                            onAssignLead={_handleAssignLead}
                            filterType={finalType}
                            isSentSms={isSentSms}
                            isSelected={isSelected}
                            onSelect={_handleSelect}
                            onOpenBlockWarning={_handleOpenBlockWarning}
                            onOpenResendConfirm={_handleOpenConfirm}
                        />
                    );
                default:
                    return false;
            }
        });
    }

    function _handleCheckedAll(e, isChecked) {
        e.preventDefault();
        e && e.stopPropagation();
        const listCheckedItems = filterSelectableItemIds(finalDataInbox);
        if (!listCheckedItems.length) {
            const warningSelectAllConfig = INBOX_ACTION_CONFIGS.WARNING_SELECT_ALL;
            refInboxActionConfig.current = warningSelectAllConfig;
            refWarning.current._open({
                title: t(`common:${warningSelectAllConfig.title}`),
                description: t(`common:${warningSelectAllConfig.description}`)
            });
            return;
        }
        setState((prevState) => ({ ...prevState, checkedItems: isChecked ? [] : listCheckedItems }));
    }

    const _handleCreateLead = (id, phone) => {
        const dataCreate = {
            onSubmit: onAddCustomer,
            isGetAllDataSuccess: true,
            isVisibleModal: true,
            // Above for open modal
            defaultStatus: 2,
            defaultPhones: [
                {
                    id: Date.now(),
                    phone: formatPhoneNumberVoip(phone),
                    type: {
                        id: '1',
                        name: 'Mobile',
                        is_default: 1
                    },
                    isDisable: true
                }
            ],
            call_log_id: id
        };
        dispatch(createCustomer(dataCreate));
    };

    const _handleAssignLead = (id, phone) => {
        dispatch(
            actionAssignLead({
                id,
                phone,
                assignSuccess: (conversationId, response) => {
                    const { customer, phone: responsePhone = {} } = response;
                    _handleAssignSuccess(conversationId, {
                        ...response,
                        customer: {
                            ...customer,
                            phone: { ...responsePhone, type: responsePhone.name }
                        }
                    });
                }
            })
        );
    };

    const onAddCustomer = (respomse) => {
        const { customer: finalCustomerData, profile: customerProfile = {} } = respomse || {};
        const customerPhone = customerProfile.phones[0] || {};

        _handleAssignSuccess(respomse.call_log_id, {
            customer: {
                ...finalCustomerData,
                id: respomse.id,
                phone: { ...customerPhone, type: customerPhone.type.name }
            },
            company: customerProfile.company
        });
    };

    const _handleAssignSuccess = (callLogId, respomse) => {
        setState((prev) => {
            const findType = prev.dataInbox.find((item) => item.id === callLogId) || {};
            /**
             * OUTGOING we will get receiver
             * VOICE MAIL or INCOMMING will get caller
             */
            const getPhoneNumber =
                TYPE_CALL_TIME_LINE.OUTGOING === findType.call_type ? findType.receiver : findType.caller;

            return {
                ...prev,
                dataInbox: prev.dataInbox.map((item) => {
                    if (
                        item.type === TYPE_RENDER.CALL &&
                        !item.customer &&
                        (getPhoneNumber === item.caller || getPhoneNumber === item.receiver)
                    ) {
                        return {
                            ...item,
                            ...respomse
                        };
                    }

                    if (item.type === TYPE_RENDER.SMS && item.id === callLogId) {
                        return {
                            ...item,
                            ...respomse,
                            key: 1
                        };
                    }

                    return item;
                })
            };
        });
    };

    function _renderCheckedAll() {
        return (
            <div
                onClick={(e) => _handleCheckedAll(e, isCheckAll)}
                className={`check-items ${isCheckAll ? '' : '--uncheck'} flexcenter`}
            >
                <input checked={numberChecked > 0} id="cb_inbox" type="checkbox" onChange={() => {}} />
                <div className="item-checkbox">
                    <label htmlFor="cb_inbox">
                        <span />
                    </label>
                </div>
                <span className="flexcenter black fw-600">
                    <span className="black-darker2">{numberChecked}</span>
                    <span>/{finalTotal}</span>
                </span>
            </div>
        );
    }

    function _renderActionDone() {
        return (
            <ActionDone
                dataInbox={finalDataInbox}
                onReload={_handleReloadPage}
                onUpdateCallback={_handleUpdateCallback}
                finalCheckedItems={finalCheckedItems}
                filterStatus={finalStatus}
                isMarkDone={finalType === FILTER_TYPE_INBOX.CALLS}
            />
        );
    }

    function _renderActionSnooze() {
        return (
            <ActionSnooze
                dataInbox={finalDataInbox}
                onReload={_handleReloadPage}
                onUpdateCallback={_handleUpdateCallback}
                finalCheckedItems={finalCheckedItems}
                filterStatus={finalStatus}
            />
        );
    }

    function _renderActionMoveInbox() {
        return (
            <ActionMoveInbox
                dataInbox={finalDataInbox}
                onReload={_handleReloadPage}
                onUpdateCallback={_handleUpdateCallback}
                finalCheckedItems={finalCheckedItems}
                filterStatus={finalStatus}
            />
        );
    }

    const _handleOpenConfirm = ({ sms_id = [] }) => {
        const finalNumberChecked = sms_id.length || numberChecked;
        refResendSms.current = sms_id || finalCheckedItems;
        const resendActionConfig = INBOX_ACTION_CONFIGS.RESEND;
        refInboxActionConfig.current = resendActionConfig;
        const prefix = finalNumberChecked > 1 ? 's' : '';
        refWarning.current?._open({
            title: t(`report:${resendActionConfig.title}`, { prefix }),
            description: t(`report:${resendActionConfig.description}`, { prefix, number: finalNumberChecked })
        });
    };

    function _renderButtonActions() {
        switch (finalStatus) {
            case FILTER_STATUS_INBOX.INBOX:
                return (
                    <Fragment>
                        {_renderActionDone()}
                        {_renderActionSnooze()}
                    </Fragment>
                );
            case FILTER_STATUS_INBOX.DONE:
                return _renderActionMoveInbox();
            case FILTER_STATUS_INBOX.FUTURE:
                return (
                    <Fragment>
                        {_renderActionDone()}
                        {_renderActionMoveInbox()}
                        {_renderActionSnooze()}
                    </Fragment>
                );
            case FILTER_STATUS_INBOX.SENT:
                return (
                    <Fragment>
                        {_renderActionMoveInbox()}
                        {_renderActionSnooze()}
                    </Fragment>
                );
            case FILTER_STATUS_INBOX.DRAFT:
                return (
                    <Fragment>
                        <ActionSend
                            filterType={finalType}
                            filterStatus={finalStatus}
                            keyword={finalKeyword}
                            sort={finalSort}
                            onReload={_handleReloadPage}
                            onUpdateCallback={_handleUpdateCallback}
                            finalCheckedItems={finalCheckedItems}
                        />
                        <ActionSendLater
                            dataInbox={finalDataInbox}
                            onReload={_handleReloadPage}
                            onUpdateCallback={_handleUpdateCallback}
                            finalCheckedItems={finalCheckedItems}
                            filterStatus={finalStatus}
                            filterType={finalType}
                            keyword={finalKeyword}
                            sort={finalSort}
                        />
                    </Fragment>
                );
            default:
                return false;
        }
    }

    const _handleCloseWarning = () => {
        refWarning.current._close();
        refInboxActionConfig.current = {};
    };

    const _handleOpenBlockWarning = (e, phone) => {
        e && e.stopPropagation();
        const blockNumberActionConfig = INBOX_ACTION_CONFIGS.BLOCK_NUMBER;
        refInboxActionConfig.current = blockNumberActionConfig;
        refValuePhone.current = { phone };
        const phoneNumber = formatPhoneNumberVoip(phone);

        refWarning.current._open({
            title: t(`report:${blockNumberActionConfig.title}`, { number: phoneNumber }),
            description: (
                <p className="black-eerie-dark">
                    {t('report:description_modal_block_number')} <span className="text-decoration">{phoneNumber}</span>
                </p>
            )
        });
    };

    const _generateMessage = ({
        message = [],
        success = false,
        countSms = 0,
        isBlockingNumberAction = false,
        messageFail = '',
        messageSuccess = ''
    }) => {
        if (!!message.length) return message;
        const smsCountText = countSms > 1 ? 'messages have' : 'message has';
        const negativePrefix = !success && !isBlockingNumberAction ? 'not' : '';
        const translationKey = isBlockingNumberAction ? 'addons' : 'report';

        return t(`${translationKey}:${success ? messageSuccess : messageFail}`, {
            number: countSms,
            text: smsCountText,
            negativePrefix
        });
    };

    const _handleSave = () => {
        _clearStatusBar();
        const { key } = refInboxActionConfig.current;
        const isBlockingNumberAction = key === INBOX_ACTION_CONFIGS.BLOCK_NUMBER.key;
        refWarning.current._clearStatusAlert();
        if (key === INBOX_ACTION_CONFIGS.WARNING_SELECT_ALL.key) {
            _handleCloseWarning();
            return;
        }
        const _handleSuccess = ({ data = [], message }) => {
            _showStatusBar({
                id: LIST_STATUS.SUCCESS,
                message: _generateMessage({
                    message,
                    success: true,
                    countSms: Object.values(data).filter((value) => !!value).length,
                    isBlockingNumberAction,
                    ...refInboxActionConfig.current
                }),
                type: LIST_STATUS.SUCCESS
            });
            _handleCloseWarning();
            _handleReloadPage(true, false);
        };

        const _handleFail = ({ message }) => {
            _showStatusBar({
                id: LIST_STATUS.ERROR,
                isBlockingNumberAction,
                message: _generateMessage({
                    message,
                    success: false,
                    isBlockingNumberAction,
                    ...refInboxActionConfig.current
                }),
                type: LIST_STATUS.ERROR
            });
            _handleCloseWarning();
        };
        const finalData = {};
        if (isBlockingNumberAction) {
            finalData['value'] = refValuePhone.current.phone;
        } else {
            finalData['sms_id'] = !!refResendSms.current?.length ? refResendSms.current : finalCheckedItems;
        }
        clientQuery(
            isBlockingNumberAction ? BLOCK_PHONE_NUMBER : RE_SEND_SMS_BY_CUSTOMER,
            { data: finalData, toFormData: false, method: 'POST' },
            _handleSuccess,
            _handleFail
        );
    };

    const _showStatusBar = ({ id = 'error', message, type = LIST_STATUS.ERROR }) => {
        refStatusBar.current?.showStatusBar(id, message || t('common:something_went_wrong'), type);
    };

    const _clearStatusBar = () => {
        refStatusBar.current?.clearAllStatusBar();
    };

    return (
        <Fragment>
            <FilterType
                filterStatus={finalStatus}
                filterType={finalType}
                onSelect={_handleChangetype}
                onSearch={_handleChangeSearch}
                finalKeyword={finalKeyword}
            />
            <div className="wrap-tables flex-column relative">
                <StatusBar ref={refStatusBar} />
                {finalType !== FILTER_TYPE_INBOX.SMS || isSentSms ? (
                    <>
                        <div className="header --filter flex-betweenitems">
                            <div className="header__content flexcenter gap-4">
                                {_renderCheckedAll()}
                                {isSentSms ? (
                                    <div
                                        className={classNames('v2-btn-default has-icon', {
                                            'is-disable': !numberChecked
                                        })}
                                        onClick={_handleOpenConfirm}
                                    >
                                        <IconUndo />
                                        {t('header:resend')}
                                    </div>
                                ) : (
                                    _renderButtonActions()
                                )}
                            </div>
                            {isSentSms ? null : (
                                <div className="header__content flexcenter">
                                    <AssignTo filterAssign={finalAssign} onSelect={_handleChangeAssign} />
                                    <span className="is-divider" />
                                    <Sort
                                        filterStatus={finalStatus}
                                        sortData={finalSort}
                                        onChange={_handleChangeSort}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                ) : null}
                <div className="c-inbox-email inbox-content scrolls">
                    <div className="tables wrapper-calls scrolls">
                        <div className="tables__content">{_renderListItem()}</div>
                    </div>
                </div>
                <Pagination
                    currentPageSize={finalLimit}
                    pageSize={LIST_PAGE_SIZE}
                    currentPage={finalCurrentPage}
                    totalPage={state.totalPage}
                    onChange={_handleChangePage}
                />
            </div>
            <GDModalWarning
                ref={refWarning}
                footer={
                    <div className="footer-modal footer-hasbtn btn-close">
                        <span className="v2-btn-default --transparent" onClick={_handleCloseWarning}>
                            {t('report:cancel')}
                        </span>
                        <ButtonSave ref={refButtonSave} title={t('common:confirm')} onSave={_handleSave} />
                    </div>
                }
            />
        </Fragment>
    );
}

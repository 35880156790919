//Google Places API
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { displayLocation } from 'app/modules/customer/detail/locations/utils';
import { handlePlacesAddressComponent } from './utils';

import './css/index.scss';

const GdPlaces = ({
    withClassName,
    name,
    defaultValue,
    onSelect,
    onEmpty = () => {},
    onBlur = () => {},
    spellCheck = true
}) => {
    const { t } = useTranslation();
    const refPlaceInput = useRef(null);
    const refAutocomplete = useRef(null);
    const companyCountry = useSelector(({ auth }) => auth.user.company.country);

    useEffect(() => {
        initPlaceAPI();
    }, []);

    useEffect(() => {
        refPlaceInput.current.value = defaultValue;
    }, [defaultValue]);

    // initialize the google place autocomplete
    const initPlaceAPI = () => {
        if (!window.google || refAutocomplete.current) return;
        const autocomplete = new window.google.maps.places.Autocomplete(refPlaceInput.current, {
            types: ['address'],
            componentRestrictions: { country: companyCountry }
        });
        new window.google.maps.event.clearInstanceListeners(autocomplete);
        new window.google.maps.event.addListener(autocomplete, 'place_changed', function () {
            const place = autocomplete.getPlace();
            const dataAddress = handlePlacesAddressComponent(place.address_components);
            const nameData = displayLocation({ ...dataAddress, street1: dataAddress.nameInput, placeInput: true });

            const params = {
                name: nameData,
                lng: place.geometry.location.lng(),
                lat: place.geometry.location.lat()
            };

            refPlaceInput.current.value = nameData;
            onSelect(name, params);
        });
        refAutocomplete.current = autocomplete;
    };

    const _handleBlur = () => {
        initPlaceAPI();
        !refPlaceInput.current.value ? onEmpty(name) : onBlur({ value: refPlaceInput.current.value, name });
    };

    return (
        <input
            className={withClassName}
            name={name}
            type="text"
            ref={refPlaceInput}
            defaultValue={defaultValue}
            onBlur={_handleBlur}
            spellCheck={spellCheck}
            placeholder={t('calendar:enter_a_location')}
        />
    );
};

export default GdPlaces;

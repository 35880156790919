import React from 'react';
import { useTranslation } from 'react-i18next';

import IconClose from 'assets/icon/IconClose';
const ItemFilteredBool = ({ field, filter, onUpdate = () => {}, onRemove = () => {} }) => {
    const { t } = useTranslation(['smartView']);
    return (
        <>
            <div onClick={onUpdate} className="flex-1">
                <span className="black-darklight fw-500">{t(field.label)}:</span>
                <span className="fw-600 ml-1">{filter.value ? t('yes') : t('no')}</span>
            </div>
            <span className="icon-close" onClick={onRemove}>
                <IconClose isSmall />
            </span>
        </>
    );
};
export default ItemFilteredBool;

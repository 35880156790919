import React from 'react';

import Select from 'app/modules/jobdetail/tabs/materials/components/Select';
import IconMaterial from 'assets/icon/IconMaterial';

const MaterialRow = ({
    title,
    keyValue = '',
    selected = '1',
    options = [],
    onAddSticky = () => {},
    onSelect = () => {}
}) => {
    return (
        <div className="rows --sm">
            <div className="txt">
                <IconMaterial />
                <span className="flex-1 txt-ellipsis">{title}</span>
            </div>
            <div className="details">
                <Select
                    isPinToTop
                    name={keyValue}
                    classWrapper="v2-dropdown"
                    options={options}
                    selected={selected}
                    onSelect={onSelect}
                    onAddSticky={onAddSticky}
                />
            </div>
        </div>
    );
};
export default MaterialRow;

export default function IconUIconTriggerpgrade({ isGrey = false, isGreen }) {
    if (isGreen)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                {' '}
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FFF"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.5849 8.5C23.9029 8.5 24.2036 8.60026 24.4513 8.77549C24.699 8.95072 24.8936 9.20091 24.9994 9.50077L28.6469 19.8351C28.9685 20.7465 28.8903 21.702 28.5039 22.5101C28.1174 23.3182 27.4227 23.9788 26.5113 24.3005C26.1371 24.4325 25.7432 24.5 25.3464 24.5H6.65356C5.68707 24.5 4.81207 24.1083 4.17869 23.4749C3.54532 22.8415 3.15356 21.9665 3.15356 21C3.15356 20.6032 3.22104 20.2093 3.3531 19.8351L7.00052 9.50077C7.10635 9.20091 7.30098 8.95072 7.54865 8.77549C7.79631 8.60026 8.09702 8.5 8.415 8.5H23.5849Z"
                    stroke="#C0E5CF"
                />{' '}
                <path
                    d="M21.5 22C22.8807 22 24 20.8807 24 19.5C24 18.1193 22.8807 17 21.5 17C20.1193 17 19 18.1193 19 19.5C19 20.8807 20.1193 22 21.5 22Z"
                    fill="#1EAA5C"
                />{' '}
                <path
                    d="M16.5 22C17.8807 22 19 20.8807 19 19.5C19 18.1193 17.8807 17 16.5 17C15.1193 17 14 18.1193 14 19.5C14 20.8807 15.1193 22 16.5 22Z"
                    fill="#1EAA5C"
                />{' '}
                <path d="M17 9H16V17H17V9Z" fill="#C0E5CF" /> <path d="M22 9H21V17H22V9Z" fill="#C0E5CF" />{' '}
                <path
                    d="M13.5774 9.00321L12.4227 8.33655L8.42267 15.2648L9.57737 15.9314L13.5774 9.00321Z"
                    fill="#C0E5CF"
                />{' '}
                <path
                    d="M7.5 20C8.88071 20 10 18.8807 10 17.5C10 16.1193 8.88071 15 7.5 15C6.11929 15 5 16.1193 5 17.5C5 18.8807 6.11929 20 7.5 20Z"
                    fill="#1EAA5C"
                />{' '}
            </svg>
        );

    if (isGrey)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FAFBFC"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.5849 8.5C23.9029 8.5 24.2036 8.60026 24.4513 8.77549C24.699 8.95072 24.8936 9.20091 24.9994 9.50077L28.6469 19.8351C28.9685 20.7465 28.8903 21.702 28.5039 22.5101C28.1174 23.3182 27.4227 23.9788 26.5113 24.3005C26.1371 24.4325 25.7432 24.5 25.3464 24.5H6.65356C5.68707 24.5 4.81207 24.1083 4.17869 23.4749C3.54532 22.8415 3.15356 21.9665 3.15356 21C3.15356 20.6032 3.22104 20.2093 3.3531 19.8351L7.00052 9.50077C7.10635 9.20091 7.30098 8.95072 7.54865 8.77549C7.79631 8.60026 8.09702 8.5 8.415 8.5H23.5849Z"
                    stroke="#C1C9D4"
                />{' '}
                <path
                    d="M21.5 22C22.8807 22 24 20.8807 24 19.5C24 18.1193 22.8807 17 21.5 17C20.1193 17 19 18.1193 19 19.5C19 20.8807 20.1193 22 21.5 22Z"
                    fill="var(--color-icon)"
                />{' '}
                <path
                    d="M16.5 22C17.8807 22 19 20.8807 19 19.5C19 18.1193 17.8807 17 16.5 17C15.1193 17 14 18.1193 14 19.5C14 20.8807 15.1193 22 16.5 22Z"
                    fill="var(--color-icon)"
                />{' '}
                <path d="M17 9H16V17H17V9Z" fill="#C1C9D4" /> <path d="M22 9H21V17H22V9Z" fill="#C1C9D4" />{' '}
                <path
                    d="M13.5774 9.00321L12.4227 8.33655L8.42267 15.2648L9.57737 15.9314L13.5774 9.00321Z"
                    fill="#C1C9D4"
                />{' '}
                <path
                    d="M7.5 20C8.88071 20 10 18.8807 10 17.5C10 16.1193 8.88071 15 7.5 15C6.11929 15 5 16.1193 5 17.5C5 18.8807 6.11929 20 7.5 20Z"
                    fill="var(--color-icon)"
                />
            </svg>
        );
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                fill="#FAFBFC"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.5849 8.5C23.9029 8.5 24.2036 8.60026 24.4513 8.77549C24.699 8.95072 24.8936 9.20091 24.9994 9.50077L28.6469 19.8351C28.9685 20.7465 28.8903 21.702 28.5039 22.5101C28.1174 23.3182 27.4227 23.9788 26.5113 24.3005C26.1371 24.4325 25.7432 24.5 25.3464 24.5H6.65356C5.68707 24.5 4.81207 24.1083 4.17869 23.4749C3.54532 22.8415 3.15356 21.9665 3.15356 21C3.15356 20.6032 3.22104 20.2093 3.3531 19.8351L7.00052 9.50077C7.10635 9.20091 7.30098 8.95072 7.54865 8.77549C7.79631 8.60026 8.09702 8.5 8.415 8.5H23.5849Z"
                stroke="#dbcbff"
            />
            <path
                d="M21.5 22C22.8807 22 24 20.8807 24 19.5C24 18.1193 22.8807 17 21.5 17C20.1193 17 19 18.1193 19 19.5C19 20.8807 20.1193 22 21.5 22Z"
                fill="#8d4afc"
            />
            <path
                d="M16.5 22C17.8807 22 19 20.8807 19 19.5C19 18.1193 17.8807 17 16.5 17C15.1193 17 14 18.1193 14 19.5C14 20.8807 15.1193 22 16.5 22Z"
                fill="#8d4afc"
            />
            <path d="M17 9H16V17H17V9Z" fill="#dbcbff" /> <path d="M22 9H21V17H22V9Z" fill="#dbcbff" />
            <path
                d="M13.5774 9.00321L12.4227 8.33655L8.42267 15.2648L9.57737 15.9314L13.5774 9.00321Z"
                fill="#dbcbff"
            />
            <path
                d="M7.5 20C8.88071 20 10 18.8807 10 17.5C10 16.1193 8.88071 15 7.5 15C6.11929 15 5 16.1193 5 17.5C5 18.8807 6.11929 20 7.5 20Z"
                fill="#8d4afc"
            />
        </svg>
    );
}

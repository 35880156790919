import React from 'react';
import { useTranslation } from 'react-i18next';

import IconClose from 'assets/icon/IconClose';
import { OPERATOR_TYPES } from '../../constants/types';
import UserWithAvatar from '../UserWithAvatar';

const ItemFilteredUser = ({ filter, field, onUpdate = () => {}, onRemove = () => {} }) => {
    const { t } = useTranslation(['smartView']);
    const { values } = filter?.value?.set || {};
    const { prefixLabel, label } = field || {};
    const valuesLength = values?.length || 0;
    const { prefixToken } = OPERATOR_TYPES[filter.operatorId];

    return (
        <>
            <div onClick={onUpdate} className="flex-1 flexcenter gap-5">
                <span className="black-darklight fw-500">
                    {prefixLabel ? `${t(prefixLabel)} ` : null}
                    {t(label)}:
                </span>
                {prefixToken ? <span className="budget --grey fw-600 text-uppercase">{t(prefixToken)}</span> : null}
                {values && valuesLength > 1 ? <span className="budget --black">{valuesLength}</span> : null}
                {valuesLength === 1 ? <UserWithAvatar userId={values[0]} /> : null}
            </div>
            <span className="icon-close" onClick={onRemove}>
                <IconClose isSmall />
            </span>
        </>
    );
};

export default ItemFilteredUser;

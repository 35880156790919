import React, { useEffect, useReducer, useRef, useContext } from 'react';
import { clientQuery } from 'common/utils/ApiUtils';
import { getJobException } from 'app/const/Api';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';
import { useTranslation } from 'react-i18next';
import IconDropDown from 'assets/icon/IconDropDown';
import { reducer } from 'app/const/Reducer';

const Frequency = ({ isActive, jobId, customerId }) => {
    const { t } = useTranslation(['jobDetail']);

    const { repeatSettings, onChangeException } = useContext(DataRepeatContext);
    const jobExceptionDetail = repeatSettings.exception.options;

    const [state, dispatchState] = useReducer(reducer, {
        isFirstTime: true,
        jobs: [],
        isVisibleList: false,
        selected: jobExceptionDetail?.second
    });

    const refDropdown = useRef(null);
    const finalIsVisible = state.isVisibleList;

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_result_customer_location');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_result_customer_location');

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && dispatchState({ isVisibleList: false });
    }

    useEffect(() => {
        if (isActive) {
            if (state.isFirstTime) {
                clientQuery(
                    getJobException(jobId),
                    { method: 'GET', data: { customer_id: customerId } },
                    _getListJobException
                );
            } else {
                const firstItem = state.jobs[0];
                if (firstItem) {
                    const summary = `${t(`jobDetail:except`)} ${t('jobDetail:the_frequency_of')} ${firstItem.name}`;
                    dispatchState({ selected: firstItem.id });

                    const newException = {
                        summary: summary,
                        options: {
                            ...jobExceptionDetail,
                            first: '1',
                            second: firstItem.id
                        }
                    };

                    onChangeException({ ...repeatSettings, exception: newException });
                }
            }
        }
    }, [isActive]);

    const _handleOpen = (e) => {
        e.stopPropagation();
        dispatchState({ isVisibleList: !finalIsVisible });
    };

    function _getListJobException(response) {
        dispatchState({
            isFirstTime: false,
            jobs: response.data
        });
    }

    function _selectJob(e, jobItem) {
        dispatchState({
            isVisibleList: false,
            selected: jobItem.id
        });

        const summary = `${t(`jobDetail:except`)} ${t('jobDetail:the_frequency_of')} ${jobItem.name}`;

        const newException = {
            summary: summary,
            options: {
                ...jobExceptionDetail,
                first: '1',
                second: jobItem.id
            }
        };
        onChangeException({ ...repeatSettings, exception: newException });
    }

    function _renderListJob() {
        if (state.jobs.length === 0) {
            return (
                <li className="items">
                    <div className="txt-ellipsis">{t('report:not_found')}</div>
                </li>
            );
        }
        return state.jobs.map((item) => {
            return (
                <li className="items" key={item.id} onClick={(e) => _selectJob(e, item)}>
                    <div className="txt-ellipsis">{item.name}</div>
                </li>
            );
        });
    }

    function _renderJobName() {
        return state.jobs.find((item) => item.id === state.selected)?.name || t('customers:none');
    }

    return (
        <div ref={refDropdown} className={`tab-panel ${isActive ? 'active' : ''}`}>
            <div className="rows">
                <div className="txt no-icon"> {t('customers:job')} </div>
                <div className="details">
                    <div className={`v2-dropdown select-jobs  ${finalIsVisible ? 'active' : ''}`}>
                        <div className="dropbtn v2-btn-default selection" onClick={(e) => _handleOpen(e)}>
                            <div className="txt-ellipsis">{_renderJobName()}</div>
                            <span className="svg-selectbox">
                                <IconDropDown />
                            </span>
                        </div>
                        <div className={'v2-dropdown__menu scrolls'} id={'show_result_customer_location'}>
                            <ul>{_renderListJob()}</ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Frequency;

import React, { Fragment, useContext, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonSave from 'app/components/button/ButtonSave';
import StatusBar from 'app/components/status/statusbar';
import { updateTodosWithService } from 'app/const/Api';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';
import IconClose from 'assets/icon/IconClose';
import { clientQuery } from 'common/utils/ApiUtils';

const UpdateTodo = ({ serviceId }) => {
    const { t } = useTranslation();
    const [state, setState] = useReducer(reducer, {
        isVisible: false
    });
    const refButtonSave = useRef();
    const refStatusBar = useRef();
    const { isVisible: finalIsVisible } = state;

    const { repeatSettings, onSaveService, onChangeState } = useContext(DataRepeatContext);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        if (event.keyCode === KEY_CODE_ESCAPE) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    function _handleOpen(e) {
        e.stopPropagation();
        setState({ isVisible: !finalIsVisible });
    }

    function _callbaclSync(type, response) {
        if (type === LIST_STATUS.SUCCESS) {
            _handleUpdateTodoSuccess(response);

            onChangeState((prev) => {
                return {
                    ...prev,
                    isUpdateTodo: 0,
                    update_job_todo_list: false
                };
            });
        } else {
            _handleUpdateTodoFailed(response);
        }
    }

    function _handleUpdateTodo() {
        if (repeatSettings.isUpdateTodo) {
            onChangeState((prev) => {
                return {
                    ...prev,
                    update_job_todo_list: true
                };
            });
            onSaveService(_callbaclSync);
        } else {
            clientQuery(
                updateTodosWithService(serviceId),
                { method: 'PUT' },
                _handleUpdateTodoSuccess,
                _handleUpdateTodoFailed
            );
        }
    }

    function _handleUpdateTodoSuccess(response) {
        refStatusBar.current.showStatusBar(
            'error',
            response?.message?.toString() || t('common:success'),
            LIST_STATUS.SUCCESS
        );
        _removeLoading();

        setTimeout(() => {
            _closeSearchResult();
        }, 1000);
    }

    function _handleUpdateTodoFailed(response) {
        if (response) {
            refStatusBar.current.showStatusBar(
                'error',
                response?.message?.toString() || t('common:please_try_again'),
                LIST_STATUS.ERROR
            );
        } else {
            _closeSearchResult();
        }

        _removeLoading();
    }

    function _removeLoading() {
        refButtonSave.current.removeLoading();
    }

    function _renderModalConfirm() {
        if (!finalIsVisible) {
            return false;
        }
        return (
            <div className="modal container-modal open">
                <div className="modal__overlay bg-fixed" onClick={_closeSearchResult} />
                <div className="modal__container large">
                    <div className="header-modal btn-close">
                        <h3 className="header-modal__label">{t('common:update_todo_list')}</h3>
                        <span onClick={_closeSearchResult} className="v2-btn-default --icon-lg --transparent ml-0">
                            <IconClose />
                        </span>
                    </div>
                    <div className="body-modal scrolls">
                        <StatusBar ref={refStatusBar} />
                        <div className="alert --success dp-hide">
                            <p className="alert__description fs-13">{t('common:update_todo_list_desc')}</p>
                        </div>
                        <p>{t('common:update_todo_list_message')}</p>
                    </div>
                    <div className="footer-modal btn-close justify-end">
                        <span onClick={_closeSearchResult} className="v2-btn-default --transparent mr-2">
                            {t('common:cancel')}
                        </span>
                        <ButtonSave
                            ref={refButtonSave}
                            onSave={_handleUpdateTodo}
                            title={t('common:update_todo_list_title')}
                        />
                    </div>
                </div>
            </div>
        );
    }
    return (
        <Fragment>
            <a onClick={(e) => _handleOpen(e)} className="v2-btn-default btn-modal">
                {t('common:update_todo_list_content')}
            </a>
            {_renderModalConfirm()}
        </Fragment>
    );
};

export default UpdateTodo;

import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';

const WeeklyRepeat = ({
    MO,
    TU,
    WE,
    TH,
    FR,
    SA,
    SU,
    onChange,
    shortDate = true,
    shouldCheckHaveSelect = true,
    isAddService,
    isEvent = false,
    title = { title: '', className: 'txt pl-6' },
    className = 'rows mt-3',
    classWrapper = 'btn-item m-0'
}) => {
    const { t } = useTranslation();
    const isFirstTime = useRef(true);

    const [state, dispatchState] = useReducer(reducer, {});
    const {
        MO: finalMonday = false,
        TU: finalTuesday = false,
        WE: finalWednesday = false,
        TH: finalThursday = false,
        FR: finalFriday = false,
        SA: finalSaturday = false,
        SU: finalSunday = false
    } = state;

    useEffect(() => {
        if (
            MO !== (finalMonday ? '1' : '0') ||
            TU !== (finalTuesday ? '1' : '0') ||
            WE !== (finalWednesday ? '1' : '0') ||
            TH !== (finalThursday ? '1' : '0') ||
            FR !== (finalFriday ? '1' : '0') ||
            SA !== (finalSaturday ? '1' : '0') ||
            SU !== (finalSunday ? '1' : '0')
        ) {
            dispatchState({
                MO: parseInt(MO || 0) === 1,
                TU: parseInt(TU || 0) === 1,
                WE: parseInt(WE || 0) === 1,
                TH: parseInt(TH || 0) === 1,
                FR: parseInt(FR || 0) === 1,
                SA: parseInt(SA || 0) === 1,
                SU: parseInt(SU || 0) === 1
            });
        }
    }, [MO, TU, WE, TH, FR, SA, SU]);

    useEffect(() => {
        if (!isFirstTime.current) {
            const days = {
                MO: finalMonday ? '1' : '0',
                TU: finalTuesday ? '1' : '0',
                WE: finalWednesday ? '1' : '0',
                TH: finalThursday ? '1' : '0',
                FR: finalFriday ? '1' : '0',
                SA: finalSaturday ? '1' : '0',
                SU: finalSunday ? '1' : '0'
            };
            onChange(days);
        }
    }, [state.MO, finalTuesday, finalWednesday, finalThursday, finalFriday, finalSaturday, finalSunday]);

    function _handleChange(e, key) {
        if (shouldCheckHaveSelect) {
            let count = 0;
            const finalValue = !state[key];

            Object.keys(state).map((item) => {
                if (state[item]) {
                    count++;
                }
            });

            if (count === 1 && !finalValue && !isAddService) {
                return false;
            }

            dispatchState({ [key]: finalValue });
            isFirstTime.current = false;
        } else {
            dispatchState({ [key]: !state[key] });
            isFirstTime.current = false;
        }
    }

    return (
        <div className={className}>
            {!isEvent ? (
                <div className={title?.className || 'txt pl-6'}> {title?.title || t('jobDetail:repeat_on')} </div>
            ) : null}
            <div className="detail tabs detail-repeaton">
                <div className={classWrapper}>
                    <button
                        className={`tab-items ${finalSunday ? 'active-tab-selector' : ''}`}
                        type="button"
                        onClick={(e) => _handleChange(e, 'SU')}
                    >
                        {t(`jobDetail:${shortDate ? 'sun_short' : 'sun'}`)}
                    </button>
                    <button
                        className={`tab-items ${finalMonday ? 'active-tab-selector' : ''}`}
                        type="button"
                        onClick={(e) => _handleChange(e, 'MO')}
                    >
                        {t(`jobDetail:${shortDate ? 'mon_short' : 'mon'}`)}
                    </button>
                    <button
                        className={`tab-items ${finalTuesday ? 'active-tab-selector' : ''}`}
                        type="button"
                        onClick={(e) => _handleChange(e, 'TU')}
                    >
                        {t(`jobDetail:${shortDate ? 'tue_short' : 'tue'}`)}
                    </button>
                    <button
                        className={`tab-items ${finalWednesday ? 'active-tab-selector' : ''}`}
                        type="button"
                        onClick={(e) => _handleChange(e, 'WE')}
                    >
                        {t(`jobDetail:${shortDate ? 'wed_short' : 'wed'}`)}
                    </button>
                    <button
                        className={`tab-items ${finalThursday ? 'active-tab-selector' : ''}`}
                        type="button"
                        onClick={(e) => _handleChange(e, 'TH')}
                    >
                        {t(`jobDetail:${shortDate ? 'thu_short' : 'thu'}`)}
                    </button>
                    <button
                        className={`tab-items ${finalFriday ? 'active-tab-selector' : ''}`}
                        type="button"
                        onClick={(e) => _handleChange(e, 'FR')}
                    >
                        {t(`jobDetail:${shortDate ? 'fri_short' : 'fri'}`)}
                    </button>
                    <button
                        className={`tab-items ${finalSaturday ? 'active-tab-selector' : ''}`}
                        type="button"
                        onClick={(e) => _handleChange(e, 'SA')}
                    >
                        {t(`jobDetail:${shortDate ? 'sat_short' : 'sat'}`)}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default WeeklyRepeat;

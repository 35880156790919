import loadable from '@loadable/component';
import classNames from 'classnames';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { REPORT_CREDIT_GET_LIST } from 'app/const/Api';
import { COMMON, DELAY_TIME, KEY_REPORT_LOCAL_STORAGE, LIST_STATUS, TYPE_BUTTON_ACTIONS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { REPORT_LIMIT, REPORT_LIMIT_LOADMORE } from 'app/const/Reports';
import { URL_EXPORT_CREDIT } from 'app/const/api/Export';
import { QUICK_BOOK_CREDIT } from 'app/const/quickbook';
import { LIST_EXPORT } from 'app/const/report/Common';
import { getGridColumns } from 'app/const/report/Credits';
import { CREDIT_LIST_FILTER } from 'app/const/report/ReportFilter';
import { REPORT_LIST_BUTTON, REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { handleAbortController } from 'app/modules/customer/utils';
import { deleteReportCreditRequest } from 'common/redux/actions/reports/creditAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { createRowTotal, getLocalParamsReport, getTitleRowTotal } from 'common/utils/ReportUtils';
import HeaderBottom from '../components/HeaderBottom';

const GdButton = loadable(() => import('app/components/button'));
const GdConfirm = loadable(() => import('app/components/confirm'));
const GdGridRowTotal = loadable(() => import('app/components/grid/GdGridRowTotal'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const GDStatusBar = loadable(() => import('app/components/status/statusbar'));
const Export = loadable(() => import('app/modules/report/components/Export'));
const MainHeaderReport = loadable(() => import('app/modules/report/components/MainHeader'));
const ReportSearch = loadable(() => import('app/modules/report/components/ReportSearch'));
const CheckBoxHeader = loadable(() => import('app/modules/report/components/CheckBoxHeader'));

function ReportCredit() {
    const quickBooks = useSelector(({ auth }) => auth.user.settings.addons.quickbooks);
    const isActiveQuickBook = !!quickBooks && !!quickBooks.sync_credit;
    const { t } = useTranslation(['report']);
    const dispatch = useDispatch();

    const [dataReport, dispatchActionReport] = useReducer(reducer, {
        refesh: false,
        dataProgressBar: false,
        checkedItems: { is_check_all: false, ids: [] },
        data: [],
        isLoading: true,
        isLoadmore: false,
        total: 0,
        refreshScreen: 0,
        totalCredit: {},
        rowTotal: [],
        actionSuccess: 0
    });
    const {
        refreshScreen,
        checkedItems,
        data: finalData,
        isLoading: finalIsLoading,
        isLoadmore: finalIsLoadmore,
        total: finalTotal,
        actionSuccess
    } = dataReport;
    const { ids } = checkedItems;
    const numberCurrentData = finalData.length;
    const finalTypeReport = REPORT_TYPE.CREDIT;
    const keyLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', finalTypeReport);
    const paramsReport = getLocalParamsReport(keyLocal, finalTypeReport);
    const gridColumn = getGridColumns(paramsReport.order, paramsReport.columns);

    const refConfirm = useRef(null);
    const refAlert = useRef(null);
    const abortController = useRef(null);

    useEffect(() => {
        getListReport(paramsReport);

        return () => {
            handleAbortController(abortController);
        };
    }, []);

    useEffect(() => {
        if (
            actionSuccess &&
            numberCurrentData < finalTotal &&
            numberCurrentData < REPORT_LIMIT_LOADMORE &&
            !finalIsLoading &&
            !finalIsLoadmore
        ) {
            dispatchActionReport((prev) => {
                return {
                    ...prev,
                    isLoadmore: true
                };
            });
        }
    }, [actionSuccess]);

    useEffect(() => {
        const loadMoreTimer =
            finalIsLoadmore &&
            setTimeout(() => {
                getListReport({ ...paramsReport, offset: finalData.length }, true, false);
            }, DELAY_TIME);

        return () => {
            if (loadMoreTimer) clearTimeout(loadMoreTimer);
        };
    }, [finalIsLoadmore]);

    const getListReport = (params, notShowLoading = false, isReset = true) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();
        params = getNewAPIRequest(params);

        const checkShouldLoading = !notShowLoading && !finalIsLoading;
        const columns = params?.columns;

        if (ids.length || checkShouldLoading) {
            dispatchActionReport((prev) => {
                return {
                    ...prev,
                    data: isReset ? [] : prev.data,
                    isLoading: !!checkShouldLoading || prev.isLoading,
                    checkedItems: checkShouldLoading ? { is_check_all: false, ids: [] } : prev.checkedItems
                };
            });
        }

        delete params?.columns;
        delete params?.currentPage;

        clientQuery(
            REPORT_CREDIT_GET_LIST,
            { data: params, abortController: abortController.current },
            (response) => getListSuccess(response, columns),
            getListFailed
        );
    };

    const getNewAPIRequest = (params) => {
        if (!params.total) {
            params.total = 1;
        }
        params.limit = REPORT_LIMIT;

        return params;
    };

    const _handleCreateRowTotal = ({ data = {}, columns = [], total = 0 }) => {
        return createRowTotal({
            data,
            columns,
            title: getTitleRowTotal({ title: 'credit', total }),
            typeReport: finalTypeReport,
            isActiveQuickBook
        });
    };

    const getListSuccess = ({ data = [], total, total_credit }, columns) => {
        dispatchActionReport((prev) => {
            const {
                checkedItems,
                rowTotal: prevRowTotal,
                totalCredit: prevTotalCredit,
                refreshScreen: prevRefreshScreen,
                total: prevTotal,
                data: prevData,
                isLoadmore: prevLoadMore
            } = prev;
            const finalData = [...prevData, ...data];
            const newTotal = total ?? prevTotal ?? 0;
            return {
                ...prev,
                isLoading: false,
                isLoadmore: false,
                data: finalData,
                total: newTotal,
                refreshScreen: prevRefreshScreen + 1,
                totalCredit: prevLoadMore ? prevTotalCredit : total_credit,
                rowTotal: prevLoadMore
                    ? prevRowTotal
                    : _handleCreateRowTotal({ data: total_credit, columns, total: newTotal }),
                checkedItems: {
                    ...checkedItems,
                    ids: checkedItems.is_check_all ? finalData.map((e) => e.id) : checkedItems.ids
                }
            };
        });
    };

    function getListFailed({ isAborted = false }) {
        if (isAborted) return;
        dispatchActionReport((prev) => ({ ...prev, isLoading: false, isLoadmore: false }));
    }

    const _handleTotal = ({ itemsDeleted, totalCredit, rowTotal, newTotal }) => {
        const newTotalCredit = { ...totalCredit };
        const totalCreditKeys = Object.keys(totalCredit);
        itemsDeleted.forEach((item) => {
            totalCreditKeys.forEach((key) => {
                newTotalCredit[key] -= item[key];
            });
        });
        const newRowTotal = rowTotal.map((item) => {
            if (totalCreditKeys.includes(item.id))
                return {
                    ...item,
                    totalAmount: newTotalCredit[item.id]
                };

            return item;
        });

        if (!newRowTotal[0].isCurrency)
            newRowTotal[0].title = t('report:total_rows', {
                number: newTotal,
                title: t('report:credits')
            });

        return { newTotalCredit, newRowTotal };
    };

    const handleChangeFilter = (params, mode) => {
        if (mode && mode === 'columns') {
            dispatchActionReport((prev) => ({
                ...prev,
                refesh: !prev.refesh,
                rowTotal: _handleCreateRowTotal({
                    data: prev.totalCredit,
                    columns: params?.columns || [],
                    total: prev.total
                })
            }));
        }
    };

    const _handleUpdate = () => {
        getListReport(getLocalStorage(keyLocal));
    };

    const onChangeStateCheckedItems = (stateCheckedItems) => {
        dispatchActionReport({ checkedItems: stateCheckedItems });
    };

    const handleClickButton = (value) => {
        switch (value) {
            case TYPE_BUTTON_ACTIONS.DELETE:
                refConfirm.current.open(
                    ids,
                    ids.length === 1
                        ? t('report:message_delete_credit', { total: ids.length })
                        : t('report:message_delete_credits', { total: ids.length })
                );
                break;
            default:
                break;
        }
    };

    const deleteCustomerDetailContact = (ids) => {
        dispatchActionReport((prev) => {
            const { data, total } = prev;
            const newTotal = total - ids.length;
            return {
                ...prev,
                data: [...data].map((item) => {
                    if (ids.includes(item.id)) {
                        item.manualHide = true;
                    }
                    return item;
                }),
                total: newTotal
            };
        });

        dispatch(
            deleteReportCreditRequest(
                { ids: ids },
                (response) => _clickButtonSuccess(response, t(`report:delete_success`)),
                _clickButtonFailed
            )
        );
    };

    const _clickButtonSuccess = (response, msgSuccess) => {
        const errorResponse = response.error;
        const idsFailed = errorResponse?.items || [];
        const numberIdsFailed = idsFailed.length;

        if (!!errorResponse || !!numberIdsFailed) {
            refAlert.current.handeAddStatus({
                id: `${Date.now()}_error`,
                message: errorResponse.message,
                type: LIST_STATUS.ERROR
            });
        }

        refAlert.current.handeAddStatus({
            id: `${Date.now()}_sussess`,
            message: response.message || msgSuccess,
            type: LIST_STATUS.SUCCESS
        });

        dispatchActionReport((prev) => {
            const { data: prevData, checkedItems, totalCredit, rowTotal, total } = prev;
            const prevCheckedIds = checkedItems.ids;
            const newTotal = total + numberIdsFailed;
            const itemsDeleted = [];

            const newData = prevData
                .map((item) => {
                    if (idsFailed.includes(item.id)) {
                        item.manualHide = false;
                    }
                    return item;
                })
                .filter((itemRemove) => {
                    if (!itemRemove.manualHide) return true;
                    itemsDeleted.push(itemRemove);
                    return false;
                });

            const { newTotalCredit, newRowTotal } = _handleTotal({
                itemsDeleted,
                totalCredit,
                rowTotal,
                newTotal
            });

            return {
                ...prev,
                checkedItems: {
                    is_check_all: numberIdsFailed === ids.length,
                    ids: prevCheckedIds.filter((item) => idsFailed.includes(item))
                },
                data: newData,
                total: newTotal,
                actionSuccess: Date.now(),
                totalCredit: newTotalCredit,
                rowTotal: newRowTotal
            };
        });
    };

    function _clickButtonFailed(response) {
        refAlert.current.handeAddStatus({
            id: `${Date.now()}_error`,
            message: response.message,
            type: LIST_STATUS.ERROR
        });
    }

    const _handleScroll = () => {
        const numberData = finalData?.length;
        if (!!numberData && numberData < finalTotal && !finalIsLoadmore) {
            dispatchActionReport((prev) => ({ ...prev, isLoadmore: true }));
        }
    };

    const handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        const newParamsReport = { ...paramsReport };
        const { ORDER } = COMMON;
        switch (actionType) {
            case ORDER:
                newParamsReport[ORDER] = columnsTarget.concat(' ', currentValue);
                break;
            default:
                break;
        }
        setLocalStorage(keyLocal, newParamsReport);
        _handleUpdate();
    };

    const _renderListActionButton = () => {
        if (!paramsReport) {
            return false;
        }

        return REPORT_LIST_BUTTON.CREDIT.map((item) => {
            return (
                <GdButton
                    key={item.id}
                    title={t(`report:${item.label}`)}
                    onClick={() => {
                        handleClickButton(item.value);
                    }}
                    className={item.className}
                    iconClassName={item.iconClassName}
                    iconSvg={item.iconSvg}
                />
            );
        });
    };

    const _renderHeaderRight = () => {
        return (
            <>
                <Export
                    title={t('report:records', { count: finalTotal })}
                    activePrint
                    url={URL_EXPORT_CREDIT}
                    params={paramsReport}
                    pageExport={LIST_EXPORT.EXPORT_REPORT_CREDIT}
                    isDisable={finalIsLoading}
                    refresh={refreshScreen}
                />
                <ReportSearch
                    reportType={finalTypeReport}
                    placeholder={t('report:search')}
                    onKeyEnter={_handleUpdate}
                />
            </>
        );
    };

    const _renderHeaderBootom = () => {
        const idsSelected = ids?.length || 0;

        return (
            <div className={classNames('header --filter', { 'is-disable': !idsSelected })}>
                <CheckBoxHeader isShowTotal total={finalTotal} checkedItems={checkedItems} />
                {_renderListActionButton()}
            </div>
        );
    };

    return (
        <>
            <MainHeaderReport
                contentRight={_renderHeaderRight}
                reportType={finalTypeReport}
                onSelectTab={_handleUpdate}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages gap-8">
                    <GDStatusBar ref={refAlert} />
                    <HeaderBottom
                        typeReport={finalTypeReport}
                        filters={CREDIT_LIST_FILTER}
                        isLoading={finalIsLoading}
                        handleChangeFilter={handleChangeFilter}
                        handleUpdate={_handleUpdate}
                    />

                    <div className="wrap-tables flex-column relative">
                        {_renderHeaderBootom()}
                        <GdGridView
                            isEmptyFlat
                            isLoading={dataReport.isLoading}
                            classTable="table-multi-column has-checkbox scrolls-x has-text-ellipsis"
                            classTableContent="--hastotal"
                            content={finalData.filter((item) => !item.manualHide)}
                            showCheckBox
                            fileTranslation={'report'}
                            checkedItems={checkedItems}
                            {...gridColumn}
                            rowTotal={(props) => (
                                <GdGridRowTotal
                                    columns={dataReport.rowTotal}
                                    contentConfig={gridColumn.contentConfig}
                                    showCheckBox={true}
                                    currency
                                    {...props}
                                />
                            )}
                            isScroll
                            isLoadmore={finalIsLoadmore}
                            showRowTotalOnTop
                            quickbookActive={isActiveQuickBook}
                            typeQuickBook={QUICK_BOOK_CREDIT}
                            styleQBSync="col col-xs"
                            showNumberQB={false}
                            isShowToolTip
                            onChangeStateCheckedItems={onChangeStateCheckedItems}
                            handleClickHeader={handleActionHeader}
                            onScrollToEnd={_handleScroll}
                        />
                    </div>

                    <GdConfirm
                        ref={refConfirm}
                        title={t('report:delete_confirmation')}
                        listButton={{ cancel: true, confirm: true }}
                        onConfirm={deleteCustomerDetailContact}
                    />
                </div>
            </div>
        </>
    );
}

export default ReportCredit;

export default function IconSwitch({ isToggle = false, isTransfer = false }) {
    if (isToggle)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16 5H8C4.13401 5 1 8.13401 1 12C1 15.866 4.13401 19 8 19H16C19.866 19 23 15.866 23 12C23 8.13401 19.866 5 16 5Z"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8 9C7.20435 9 6.44129 9.31607 5.87868 9.87868C5.31607 10.4413 5 11.2044 5 12C5 12.7956 5.31607 13.5587 5.87868 14.1213C6.44129 14.6839 7.20435 15 8 15C8.79565 15 9.55871 14.6839 10.1213 14.1213C10.6839 13.5587 11 12.7956 11 12C11 11.2044 10.6839 10.4413 10.1213 9.87868C9.55871 9.31607 8.79565 9 8 9Z"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );

    if (isTransfer)
        return (
            <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 2L13 5L10 8" stroke="var(--icon-banana-expired)" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6 8L3 11L6 14" stroke="var(--icon-banana-expired)" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13 5H4" stroke="var(--icon-banana-expired)" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13 11H4" stroke="var(--icon-banana-expired)" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );

    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 5H13V10" stroke="var(--color-icon)" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11 14V19H16" stroke="var(--color-icon)" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13 5L5 13" stroke="var(--color-icon)" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19 11L11 19" stroke="var(--color-icon)" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}

import { LIST_TABLE_ITEM_TYPE } from 'app/const/App';

export const DEVICE_SESSION_TYPES = {
    DESKTOP: 'desktop',
    TABLET: 'tablet',
    MOBILE: 'mobile'
};

const HEADER_SESSION = {
    user_agent: {
        title: 'device',
        style: 'col --device'
    },
    created: {
        title: 'session_history',
        style: 'col col-md'
    },
    country_name: {
        title: 'country',
        style: 'col col-md'
    },
    ip: {
        title: 'ip_addess',
        style: 'col col-md'
    },
    active: {
        title: '',
        style: 'col --signout'
    }
};

const COLUMNS_SESSION = [
    {
        id: 'user_agent',
        title: 'device',
        status: true
    },
    {
        id: 'created',
        title: 'session_history',
        status: true
    },
    {
        id: 'country_name',
        title: 'country',
        status: true
    },
    {
        id: 'ip',
        title: 'ip_addess',
        status: true
    },
    {
        id: 'active',
        title: '',
        status: true
    }
];

const CONTENT_SESSION = {
    user_agent: {
        id: 'user_agent',
        style: 'col --device',
        type: LIST_TABLE_ITEM_TYPE.DEVICE
    },
    created: {
        id: 'created',
        style: 'col col-md',
        type: LIST_TABLE_ITEM_TYPE.TIME_AGO,
        styleText: 'word-break'
    },
    country_name: {
        id: 'country_name',
        style: 'col col-md',
        type: LIST_TABLE_ITEM_TYPE.WORD_BREAK
    },
    ip: {
        id: 'ip',
        style: 'col col-md',
        type: LIST_TABLE_ITEM_TYPE.WORD_BREAK
    },
    active: {
        id: 'active',
        style: 'col --signout d-flex',
        type: LIST_TABLE_ITEM_TYPE.CUSTOM_BUTTON,
        styleText: 'v2-btn-default has-icon fw-500'
    }
};

export const COLUMN_SESSION = {
    header: HEADER_SESSION,
    columns: COLUMNS_SESSION,
    contentConfig: CONTENT_SESSION
};

export const TYPE_USER_SETTING = {
    USER: { id: 0, title: 'user' },
    CREW: { id: 1, title: 'crew' }
};

export const TYPE_SCHEDULE_ASSIGNEE = [
    { id: 0, title: 'schedules' },
    { id: 1, title: 'crew' }
];

import GdConfirm from 'app/components/confirm';
import { actionLocationMote } from 'app/const/api/V2';
import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconEdit from 'assets/icon/IconEdit';
import IconThreeDots from 'assets/icon/IconThreeDots';
import IconTrash from 'assets/icon/IconTrash';
import classNames from 'classnames';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { Fragment, useLayoutEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonSave from 'app/components/button/ButtonSave';
import { convertLinkInString } from 'common/utils/StringUtils';
import { useWindowEvent } from 'common/hooks/useWindowEvent';

const LocationNote = ({ locationId = '', defaultValue = '', onUpdate = () => {} }) => {
    const { t } = useTranslation(['common']);
    const [state, dispatchState] = useReducer(reducer, { note: defaultValue, isEdit: false });

    const { note, isEdit } = state;

    const refConfirm = useRef();
    const refDropdown = useRef();
    const refTextarea = useRef(null);

    useLayoutEffect(() => {
        dispatchState({ note: defaultValue });
    }, [defaultValue]);

    const _handleToggleEdit = () => {
        dispatchState({ isEdit: !isEdit });
    };

    useWindowEvent('update_location_note', (detail) => {
        handleMessage(detail);
    });

    function handleMessage({ note }) {
        dispatchState({ note: note });
        onUpdate(note);
    }

    const _handleUpdate = () => {
        if (!locationId || !refTextarea.current) return;
        const newNote = refTextarea.current.value;

        dispatchState({ isEdit: false, note: newNote });

        dispatchEvent(new CustomEvent('update_location_note', { detail: { note: newNote } }));

        const _updateSuccess = () => {
            onUpdate(newNote);
        };

        const _updateFail = () => {};

        clientQuery(
            actionLocationMote(locationId),
            { data: { note: newNote }, method: 'PUT', toFormData: false },
            _updateSuccess,
            _updateFail
        );
    };

    const _handleDelete = () => {
        if (!locationId) return;
        dispatchState({ note: '' });
        clientQuery(actionLocationMote(locationId), { data: { note: '' }, method: 'PUT', toFormData: false });
    };

    const _handleConfirm = () => {
        refDropdown.current._closeDropdown();
        refConfirm.current.open();
    };

    return (
        <Fragment>
            <div className={classNames('location-note', { 'has-edit': isEdit })}>
                <div className="wrap-description">
                    <div className="location-note__header flex-betweenitems">
                        <h5 className="fs-13">{t('customers:location_note')}</h5>
                        {!!note ? (
                            <CalendarDropdown
                                id="location_note"
                                ref={refDropdown}
                                wrapperClassName="v2-dropdown--more elm-list active"
                                buttonClassName="v2-btn-default --icon-lg --transparent btn-bg-grey"
                                customDropButton={() => <IconThreeDots />}
                            >
                                <ul>
                                    <li className="items has-icon" onClick={_handleToggleEdit}>
                                        <IconEdit /> {t('jobDetail:edit_location_note')}
                                    </li>

                                    <li className="items has-icon red-default" onClick={_handleConfirm}>
                                        <IconTrash /> {t('jobDetail:delete_location_note')}
                                    </li>
                                </ul>
                            </CalendarDropdown>
                        ) : (
                            <div className="v2-btn-default --icon-lg btn-bg-grey" onClick={_handleToggleEdit}>
                                <IconEdit />
                            </div>
                        )}
                    </div>
                </div>

                {isEdit ? (
                    <div className="wrap-content">
                        <div className="wrapbox-editor">
                            <div className="wrapbox-editor__header fs-13 fw-600">{t('customers:location_note')}</div>

                            <div className="wrapbox-editor__form">
                                <textarea
                                    ref={refTextarea}
                                    className="field-textarea --no-border --px-12 d-flex"
                                    style={{ height: '80px' }}
                                    autoFocus
                                    defaultValue={note}
                                />
                            </div>
                            <div className="wrapbox-editor__controls">
                                <div className="editor-controls --action">
                                    <div className="v2-btn-default --transparent" onClick={_handleToggleEdit}>
                                        {t('common:cancel')}
                                    </div>
                                    <ButtonSave onSave={_handleUpdate} />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        className="description word-break"
                        dangerouslySetInnerHTML={{ __html: convertLinkInString(note) }}
                    />
                )}
            </div>

            <GdConfirm
                ref={refConfirm}
                title={t('common:notification')}
                message={t('jobDetail:are_you_sure_want_to_delete_location_note')}
                titleConfirm={t('common:yes')}
                listButton={{ confirm: true, cancel: true }}
                onConfirm={_handleDelete}
            />
        </Fragment>
    );
};

export default LocationNote;

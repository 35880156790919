import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import GDIframe from 'app/components/iframe';
import { KEY_ONBOARDING_VIDEO_COMPLETED } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { SETTINGS_PLANS } from 'app/const/Route';
import { CUSTOMER_STATUS } from 'app/modules/customer/const';
import { mixpanelWatchVideo } from 'app/modules/mixpanel/MixpanelWatchVideo';
import NewInvoice from 'app/modules/quickadd/invoice';
import IconPlay from 'assets/icon/IconPlay';
import { createCustomer } from 'common/redux/actions/customer';
import { actionCreateJob } from 'common/redux/actions/job/create';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import {
    ADD_CUSTOMER,
    ADD_INVOICE,
    ADD_JOB,
    ADD_LOGO,
    NEXT_STEP_ADD_LOGO,
    NEXT_STEP_DEMO,
    ONBOARDING_POPULAR_VIDEOS,
    PLANS_AND_PRICING
} from '../../constant';
import ModalAddLogo from '../ModalAddLogo';

const OnBoardingVideoPopular = ({ isTrial = false }) => {
    const profile = useSelector(({ auth }) => auth.user.profile);
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation('onboarding');
    const [state, dispatchState] = useReducer(reducer, { videoSelected: {}, videoList: [] });
    const { videoSelected, videoList } = state;
    const {
        id: videoSelectedId,
        type: videoSelectedType,
        title: videoSelectedTitle,
        isNextStep = false,
        isCompleted: videoSelectedCompleted = false
    } = videoSelected || {};
    const finalConstantVideoPopular = ONBOARDING_POPULAR_VIDEOS.map((item) => ({
        ...item,
        title: t(`onboarding:${item.title}`)
    }));

    const refFormNewInvoice = useRef(null);
    const refModalAddLogo = useRef(null);

    useEffect(() => {
        const storedVideo = getLocalStorage(KEY_ONBOARDING_VIDEO_COMPLETED);
        if (!storedVideo) {
            setLocalStorage(
                KEY_ONBOARDING_VIDEO_COMPLETED,
                finalConstantVideoPopular.reduce((acc, { type, isCompleted }) => {
                    acc[type] = { type, isCompleted };
                    return acc;
                }, {})
            );
            dispatchState((prev) => ({
                ...prev,
                videoSelected: finalConstantVideoPopular[isTrial ? 0 : 1],
                videoList: finalConstantVideoPopular
            }));
            return;
        }
        const mergedVideoList = finalConstantVideoPopular.map((item) => ({
            ...item,
            isCompleted: storedVideo[item.type]?.isCompleted
        }));
        dispatchState((prev) => ({
            ...prev,
            videoSelected: mergedVideoList[isTrial ? 0 : 1],
            videoList: mergedVideoList
        }));
    }, []);

    const _handleSelectVideo = (data) => {
        if (videoSelectedId === data.id) return;
        dispatchState((prev) => ({ ...prev, videoSelected: data }));
    };

    const roundMinutesToNearestInterval = (minutes) => {
        const intervals = [0, 15, 30, 45];
        return intervals.find((item) => minutes <= item) || 0;
    };

    const _handleStartOnBoarding = (type) => {
        const finalType = type || videoSelectedType;
        switch (finalType) {
            case ADD_CUSTOMER:
                dispatch(
                    createCustomer({
                        defaultStatus: CUSTOMER_STATUS[1].type,
                        isVisibleModal: true,
                        onSubmit: () => _handleCompleted(ADD_CUSTOMER)
                    })
                );
                break;
            case ADD_JOB:
                const finalMinute = roundMinutesToNearestInterval(parseInt(moment().format('mm')));
                dispatch(
                    actionCreateJob({
                        start: `${moment().format(`yyyy-MM-DDTHH:${finalMinute > 0 ? finalMinute : '0'.concat(finalMinute)}:ss`)}Z`,
                        onCreateSuccess: () => _handleCompleted(ADD_JOB)
                    })
                );
                break;
            case ADD_INVOICE:
                refFormNewInvoice.current._handleOpenForm();
                break;
            case PLANS_AND_PRICING:
                history.push(addBranchPath(SETTINGS_PLANS));
                break;
            case NEXT_STEP_DEMO:
            case NEXT_STEP_ADD_LOGO:
                _handleSelectVideo({
                    ...videoList.find(
                        (item) => item.type === (finalType === NEXT_STEP_DEMO ? NEXT_STEP_ADD_LOGO : ADD_LOGO)
                    ),
                    isAutoPlay: true
                });
                break;
            case ADD_LOGO:
                refModalAddLogo.current && refModalAddLogo.current._open();
                break;
            default:
                break;
        }
    };

    const _handleSelectStartOnBoarding = (e, item) => {
        e.stopPropagation();
        _handleSelectVideo(item);
        _handleStartOnBoarding(item.type);
    };

    const _handleCompleted = (type) => {
        dispatchState((prev) => {
            const finalVideoList = prev.videoList.map((item) =>
                item.type === type ? { ...item, isCompleted: true } : item
            );
            setLocalStorage(
                KEY_ONBOARDING_VIDEO_COMPLETED,
                finalVideoList.reduce((acc, { type, isCompleted }) => {
                    acc[type] = { type, isCompleted };
                    return acc;
                }, {})
            );
            return {
                ...prev,
                videoSelected: { ...prev.videoSelected, isCompleted: true },
                videoList: finalVideoList
            };
        });
    };

    const _handleTracking = (data = {}) => {
        handleTrackingEvent(mixpanelWatchVideo({ profile, data }));
    };

    return (
        <div className="onboarding-items flextop gap-16">
            <div className="onboarding-steps">
                <h5 className="black fw-500 fs-15">{t('common:get_started')}</h5>
                <div className="flex-column gap-8 mt-3">
                    {videoList.map((item) => {
                        const { id, icon, title, isHideStartOnBoarding, isHideNotTrial, isCompleted = false } = item;
                        if (isHideNotTrial && !isTrial) return null;
                        return (
                            <div
                                key={id}
                                className={classNames('step-items flexcenter gap-8', {
                                    active: videoSelectedId === id
                                })}
                                onClick={() => _handleSelectVideo(item)}
                            >
                                <div className="step-items__icon">{icon}</div>
                                <div className="flex-1 black fw-500 txt-ellipsis" title={title}>
                                    {title}
                                </div>
                                {!isHideStartOnBoarding && !isCompleted ? (
                                    <div
                                        className="v2-btn-main --icon-r fs-14"
                                        onClick={(e) => _handleSelectStartOnBoarding(e, item)}
                                    >
                                        {t('start_onboarding')}
                                        <IconPlay isTracking />
                                    </div>
                                ) : null}
                                {isCompleted ? (
                                    <ButtonDone onClickButton={(e) => _handleSelectStartOnBoarding(e, item)} />
                                ) : null}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="onboarding-video flex-column">
                <h5 className="black fw-500 fs-15 mb-3" title={videoSelectedTitle}>
                    {videoSelectedTitle}
                </h5>
                <GDIframe
                    {...videoSelected}
                    className="onboarding-video__iframe"
                    loadingComponent={() => <div className="onboarding-video__iframe loading --animation" />}
                    onTrackingEvent={_handleTracking}
                />
                <div className="text-right mt-3">
                    {!videoSelectedCompleted ? (
                        <div
                            className="v2-btn-main --icon-r --large btn-onboarding fs-14 --large px-3"
                            onClick={() => _handleStartOnBoarding()}
                        >
                            {t(isNextStep ? 'next_step' : 'start_onboarding')}
                            <IconPlay isTracking />
                        </div>
                    ) : (
                        <ButtonDone onClickButton={() => _handleStartOnBoarding()} />
                    )}
                </div>
            </div>
            <NewInvoice ref={refFormNewInvoice} onAddSuccess={() => _handleCompleted(ADD_INVOICE)} />
            <ModalAddLogo ref={refModalAddLogo} onAddSuccess={() => _handleCompleted(ADD_LOGO)} />
        </div>
    );
};

export default OnBoardingVideoPopular;

const ButtonDone = ({ onClickButton }) => {
    const { t } = useTranslation();
    return (
        <div
            className="v2-btn-default fs-14 btn-bg-grey bg-grey-bright --transparent black --large px-3"
            onClick={onClickButton}
        >
            {t('common:done')}
        </div>
    );
};

import { reducer } from 'app/const/Reducer';
import queryStrings from 'query-string';
import { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import LoadingFullPage from 'app/components/loading';
import { LOGIN_ADMIN, LOGIN_CLASSIC, LOGIN_INTEGRATION } from 'app/const/Api';
import { ACCESS_TOKEN, KEY_MODAL_SMS_CHARGE_FAILED, LOGIN_BY_TOKEN_TYPES, STATUS_ACCOUNT } from 'app/const/App';
import { REDIRECT_MAP, SETTINGS_PLANS_REDIRECT_CODE } from 'app/const/Auth';
import { CALENDAR } from 'app/const/Route';
import { restoreOptionJobCalendar } from 'app/modules/calendar/ultil/Calendar';
import ErrorPage from 'app/modules/error';
import { mixpanelLogin } from 'app/modules/mixpanel/MixpanelLogin';
import { getPathWithOnboarding } from 'app/modules/onboarding/utils';
import { userLoginByTokenRequest } from 'common/redux/actions/authAction';
import { getLocalStorageValue, removeLocalStorage } from 'common/utils/LocalStorageUtils';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';

function LoginByToken({ onReload = () => {} }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('auth');
    const { search } = useLocation();
    const { token, type } = useParams();
    const history = useHistory();
    const [state, dispatchState] = useReducer(reducer, { message: '', isLoading: true });
    const { message: finalMessage, isLoading: finalIsLoading } = state;

    const isValidType = [
        LOGIN_BY_TOKEN_TYPES.ACCESS,
        LOGIN_BY_TOKEN_TYPES.ACCESS_CLOSEIO,
        LOGIN_BY_TOKEN_TYPES.CLASSIC
    ].includes(type);

    const _loginSuccess = (response) => {
        handleTrackingEvent(mixpanelLogin({ profile: response.profile, company: response.company, isHaveToken: true }));
        const redirectCode =
            response.profile?.plan?.status === STATUS_ACCOUNT.EXPIRED
                ? SETTINGS_PLANS_REDIRECT_CODE
                : response.profile?.redirect;

        const { isSignupSuccess } = queryStrings.parse(search) || {};
        history.push({
            pathname: getPathWithOnboarding({
                profile: response.profile,
                settings: response.settings,
                defaultPath: REDIRECT_MAP[redirectCode] || CALENDAR
            }),
            state: { isSignupSuccess: parseInt(isSignupSuccess) === 1 }
        });
        restoreOptionJobCalendar();
    };

    const _loginFail = ({ message }) => {
        dispatchState({ message: message || t('link_is_invalid'), isLoading: false });
    };

    const _getUrl = () => {
        switch (type) {
            case LOGIN_BY_TOKEN_TYPES.ACCESS:
                return LOGIN_ADMIN;
            case LOGIN_BY_TOKEN_TYPES.ACCESS_CLOSEIO:
                return LOGIN_INTEGRATION;
            case LOGIN_BY_TOKEN_TYPES.CLASSIC:
                return LOGIN_CLASSIC;
            default:
                return '';
        }
    };

    useEffect(() => {
        removeLocalStorage(KEY_MODAL_SMS_CHARGE_FAILED);
        if (type === LOGIN_BY_TOKEN_TYPES.CLASSIC && getLocalStorageValue(ACCESS_TOKEN)) {
            onReload();
            return;
        }
        isValidType && dispatch(userLoginByTokenRequest({ token, url: _getUrl() }, _loginSuccess, _loginFail));
    }, []);

    if (finalIsLoading) return <LoadingFullPage />;

    return <ErrorPage isBack={isValidType} errorMessage={finalMessage} showContactSupport={false} />;
}

export default LoginByToken;

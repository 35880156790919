export default function IconAddonGps({ isGrey = false, isGreen }) {
    if (isGreen)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                {' '}
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FFF"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.9246 7.07534C25.2914 8.44217 25.2914 10.6582 23.9246 12.0251L20.0355 15.9142C19.2544 16.6952 17.9881 16.6952 17.2071 15.9142L15.0857 13.7929C14.3047 13.0118 14.3047 11.7455 15.0857 10.9644L18.9748 7.07534C20.3417 5.7085 22.5577 5.7085 23.9246 7.07534Z"
                    fill="#C0E5CF"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M28.5208 14.5L22.864 20.1568C21.3019 18.5948 21.3019 16.0621 22.864 14.5C24.4261 12.9379 26.9587 12.9379 28.5208 14.5Z"
                    fill="#1EAA5C"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.7928 21.5711L9.42889 15.2071C10.991 13.645 13.5236 13.645 15.0857 15.2071L15.7929 15.9142C17.3549 17.4763 17.3549 20.009 15.7928 21.5711Z"
                    fill="#1EAA5C"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.4999 2.47919L10.8431 8.13604C12.4052 9.69814 14.9378 9.69814 16.4999 8.13604C18.062 6.57395 18.062 4.04129 16.4999 2.47919Z"
                    fill="#1EAA5C"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.66312 12.5785L5.79085 13.5676C4.52012 15.0188 3.75 16.9195 3.75 19C3.75 23.5563 7.44365 27.25 12 27.25C14.0355 27.25 15.8989 26.5128 17.3376 25.2908L18.308 26.4347C16.6077 27.8788 14.4056 28.75 12 28.75C6.61522 28.75 2.25 24.3847 2.25 19C2.25 16.5405 3.16063 14.2938 4.66312 12.5785ZM6.91973 14.5554L8.04899 15.5427C7.24018 16.4662 6.75 17.6759 6.75 19C6.75 21.8995 9.1005 24.25 12 24.25C13.2954 24.25 14.4813 23.7808 15.3969 23.0031L16.3677 24.1466C15.1904 25.1466 13.6657 25.75 12 25.75C8.27208 25.75 5.25 22.7279 5.25 19C5.25 17.2978 5.88008 15.7427 6.91973 14.5554ZM9.17818 16.5301L10.3071 17.5178C9.96023 17.9137 9.75 18.4323 9.75 19C9.75 20.2426 10.7574 21.25 12 21.25C12.5552 21.25 13.0635 21.0489 13.4559 20.7155L14.4259 21.8597C13.772 22.415 12.9251 22.75 12 22.75C9.92893 22.75 8.25 21.071 8.25 19C8.25 18.054 8.60027 17.1898 9.17818 16.5301Z"
                    fill="#C0E5CF"
                />{' '}
            </svg>
        );

    if (isGrey)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FAFBFC"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.9246 7.07534C25.2914 8.44217 25.2914 10.6582 23.9246 12.0251L20.0355 15.9142C19.2544 16.6952 17.9881 16.6952 17.2071 15.9142L15.0857 13.7929C14.3047 13.0118 14.3047 11.7455 15.0857 10.9644L18.9748 7.07534C20.3417 5.7085 22.5577 5.7085 23.9246 7.07534Z"
                    fill="#C1C9D4"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M28.5208 14.5L22.864 20.1568C21.3019 18.5948 21.3019 16.0621 22.864 14.5C24.4261 12.9379 26.9587 12.9379 28.5208 14.5Z"
                    fill="var(--color-icon)"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.7928 21.5711L9.42889 15.2071C10.991 13.645 13.5236 13.645 15.0857 15.2071L15.7929 15.9142C17.3549 17.4763 17.3549 20.009 15.7928 21.5711Z"
                    fill="var(--color-icon)"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.4999 2.47919L10.8431 8.13604C12.4052 9.69814 14.9378 9.69814 16.4999 8.13604C18.062 6.57395 18.062 4.04129 16.4999 2.47919Z"
                    fill="var(--color-icon)"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.66312 12.5785L5.79085 13.5676C4.52012 15.0188 3.75 16.9195 3.75 19C3.75 23.5563 7.44365 27.25 12 27.25C14.0355 27.25 15.8989 26.5128 17.3376 25.2908L18.308 26.4347C16.6077 27.8788 14.4056 28.75 12 28.75C6.61522 28.75 2.25 24.3847 2.25 19C2.25 16.5405 3.16063 14.2938 4.66312 12.5785ZM6.91973 14.5554L8.04899 15.5427C7.24018 16.4662 6.75 17.6759 6.75 19C6.75 21.8995 9.1005 24.25 12 24.25C13.2954 24.25 14.4813 23.7808 15.3969 23.0031L16.3677 24.1466C15.1904 25.1466 13.6657 25.75 12 25.75C8.27208 25.75 5.25 22.7279 5.25 19C5.25 17.2978 5.88008 15.7427 6.91973 14.5554ZM9.17818 16.5301L10.3071 17.5178C9.96023 17.9137 9.75 18.4323 9.75 19C9.75 20.2426 10.7574 21.25 12 21.25C12.5552 21.25 13.0635 21.0489 13.4559 20.7155L14.4259 21.8597C13.772 22.415 12.9251 22.75 12 22.75C9.92893 22.75 8.25 21.071 8.25 19C8.25 18.054 8.60027 17.1898 9.17818 16.5301Z"
                    fill="#C1C9D4"
                />{' '}
            </svg>
        );
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                fill="#FAFBFC"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.9246 7.07534C25.2914 8.44217 25.2914 10.6582 23.9246 12.0251L20.0355 15.9142C19.2544 16.6952 17.9881 16.6952 17.2071 15.9142L15.0857 13.7929C14.3047 13.0118 14.3047 11.7455 15.0857 10.9644L18.9748 7.07534C20.3417 5.7085 22.5577 5.7085 23.9246 7.07534Z"
                fill="#dbcbff"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.5208 14.5L22.864 20.1568C21.3019 18.5948 21.3019 16.0621 22.864 14.5C24.4261 12.9379 26.9587 12.9379 28.5208 14.5Z"
                fill="#8d4afc"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.7928 21.5711L9.42889 15.2071C10.991 13.645 13.5236 13.645 15.0857 15.2071L15.7929 15.9142C17.3549 17.4763 17.3549 20.009 15.7928 21.5711Z"
                fill="#8d4afc"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.4999 2.47919L10.8431 8.13604C12.4052 9.69814 14.9378 9.69814 16.4999 8.13604C18.062 6.57395 18.062 4.04129 16.4999 2.47919Z"
                fill="#8d4afc"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.66312 12.5785L5.79085 13.5676C4.52012 15.0188 3.75 16.9195 3.75 19C3.75 23.5563 7.44365 27.25 12 27.25C14.0355 27.25 15.8989 26.5128 17.3376 25.2908L18.308 26.4347C16.6077 27.8788 14.4056 28.75 12 28.75C6.61522 28.75 2.25 24.3847 2.25 19C2.25 16.5405 3.16063 14.2938 4.66312 12.5785ZM6.91973 14.5554L8.04899 15.5427C7.24018 16.4662 6.75 17.6759 6.75 19C6.75 21.8995 9.1005 24.25 12 24.25C13.2954 24.25 14.4813 23.7808 15.3969 23.0031L16.3677 24.1466C15.1904 25.1466 13.6657 25.75 12 25.75C8.27208 25.75 5.25 22.7279 5.25 19C5.25 17.2978 5.88008 15.7427 6.91973 14.5554ZM9.17818 16.5301L10.3071 17.5178C9.96023 17.9137 9.75 18.4323 9.75 19C9.75 20.2426 10.7574 21.25 12 21.25C12.5552 21.25 13.0635 21.0489 13.4559 20.7155L14.4259 21.8597C13.772 22.415 12.9251 22.75 12 22.75C9.92893 22.75 8.25 21.071 8.25 19C8.25 18.054 8.60027 17.1898 9.17818 16.5301Z"
                fill="#dbcbff"
            />
        </svg>
    );
}

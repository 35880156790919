import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';

const TerminateConfirm = ({ onConfirm = () => {} }, ref) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        checked: false,
        status: {}
    });

    const refConfirm = useRef(null);
    const refInput = useRef(null);

    useImperativeHandle(ref, () => ({
        showReason: _showReason
    }));

    const _showReason = (selected) => {
        dispatchState({
            isVisible: true,
            status: selected
        });
    };

    const _closeReason = () => {
        dispatchState({
            isVisible: false
        });
    };

    const _handleChangeReason = ({ target }) => {
        const value = target.value;
        if (!value.trim().length) {
            refConfirm.current.classList.add('is-disable');
        } else {
            refConfirm.current.classList.remove('is-disable');
        }
    };

    const _handleConfirm = () => {
        onConfirm({ ...state.status, reason: refInput.current.value });
        _closeReason();
    };

    if (!state.isVisible) {
        return null;
    }

    return (
        <div id="modal_confirm_terminate" className="modal container-modal open">
            <div className="modal__overlay bg-fixed" onClick={_closeReason} />
            <div className="modal__container">
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('jobDetail:confirm_termination')}</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_closeReason}>
                        <IconClose />
                    </div>
                </div>
                <div className="body-modal">
                    <p className="black-2 fw-500">{t('jobDetail:confirm_termination_desc')}</p>
                    <div className="flex-column gap-4 mt-2">
                        <p className="black-2 fw-500">{t('jobDetail:enter_the_reason_for_terminate')}</p>
                        <input
                            maxLength={250}
                            ref={refInput}
                            type="text"
                            className="field-input"
                            onChange={_handleChangeReason}
                        />
                    </div>
                </div>
                <div className="footer-modal">
                    <span className="v2-btn-default --transparent " onClick={_closeReason}>
                        {t('common:cancel')}
                    </span>
                    <div ref={refConfirm} onClick={_handleConfirm} className="v2-btn-main ml-2 is-disable">
                        {t('common:confirm')}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default forwardRef(TerminateConfirm);

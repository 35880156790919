import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { STATUS_ACCOUNT } from 'app/const/App';
import { REDIRECT_MAP, SETTINGS_PLANS_REDIRECT_CODE } from 'app/const/Auth';
import { AUTH_LOGIN, CALENDAR } from 'app/const/Route';
import { restoreOptionJobCalendar } from 'app/modules/calendar/ultil/Calendar';
import { mixpanelLogin } from 'app/modules/mixpanel/MixpanelLogin';
import { getPathWithOnboarding } from 'app/modules/onboarding/utils';
import { userLoginSocialRequest } from 'common/redux/actions/authAction';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';

function LoginSocial() {
    const { t } = useTranslation(['auth']);
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const dataUser = location?.state?.dataUser;

    const _handleBackLogin = () => {
        history.push(AUTH_LOGIN);
    };

    const _handleLoginWithSocial = () => {
        dispatch(
            userLoginSocialRequest(
                { token: dataUser.token, type: dataUser.type, access_token: dataUser.id },
                _handleSuccess
            )
        );
    };

    const _handleSuccess = ({ profile, company, settings }) => {
        handleTrackingEvent(mixpanelLogin({ profile, company }));

        const redirectCode =
            profile?.plan?.status === STATUS_ACCOUNT.EXPIRED ? SETTINGS_PLANS_REDIRECT_CODE : profile?.redirect;
        restoreOptionJobCalendar();
        history.push(getPathWithOnboarding({ profile, settings, defaultPath: REDIRECT_MAP[redirectCode] || CALENDAR }));
    };

    return (
        <div className="gd-form-v2 social-form">
            <p className="social-desc">{t('auth:email_already_login')}</p>
            <p className="social-desc mt-2">{t('auth:continue_with_login_social')}</p>
            <div onClick={_handleLoginWithSocial} className="v2-btn-main">
                {t('auth:login_with_google')}
            </div>
            <div onClick={_handleBackLogin} className="v2-btn-main">
                {t('auth:return_to_main_login')}
            </div>
        </div>
    );
}

export default LoginSocial;

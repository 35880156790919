import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import GDStatusBar from 'app/components/status/statusbar';
import { REPORT_TYPE } from 'app/const/Reports';
import { ADDONS_FOOTER_OPTIONS } from 'app/const/addons';
import { GET_CHATBOT_REPORT, updateConversationTags } from 'app/const/api/V2';
import { CHATBOT_REPORT_FILTER } from 'app/const/report/ReportFilter';
import { clientQuery } from 'common/utils/ApiUtils';
import { showStatusBar } from 'common/utils/FunctionUtils';
import TableChatbot from '../components/TableChatbot';
import { TYPE_DATA_CHATBOT } from '../constants';
import { getColumnReportAI } from '../constants/getColumnReportAI';
import { checkTypeAgentChatbot } from '../utils';
import PortalConversation from './PortalConversation';
import SummaryConversation from './SummaryConversation';

const ChatbotReport = () => {
    const { type: typeAgent } = useParams();
    const { t } = useTranslation('addons');
    const typeReport = REPORT_TYPE.CHATBOT_REPORT;
    const isAgentPortal = checkTypeAgentChatbot({ typeAgent, conditionCheck: ADDONS_FOOTER_OPTIONS.CHATBOT_AI_PORTAL });

    const refAlert = useRef(null);
    const refTable = useRef(null);
    const refRowSelected = useRef({});
    const refSummary = useRef(null);
    const refPortalConversation = useRef(null);

    const _handleGetData = () => {
        refTable.current?.getData();
    };

    const _handleClickRow = ({ key, row = {} } = {}) => {
        if (key !== 'summary') return;
        refRowSelected.current = row;
        if (isAgentPortal) {
            refPortalConversation.current.open(row);
        } else {
            refSummary.current.open(row);
        }
    };

    const _handleShowAlert = (alert = {}) => {
        showStatusBar({ id: 'chat_bot_report', ...alert, refAlert });
    };

    const _handleSaveTags = (tags = [], id) => {
        const finalTags = tags.map((tag) => tag.name);
        const _handleFail = ({ message }) => {
            _handleShowAlert({ id: 'update_tags_fail', message });
        };
        refTable.current?.updateDataByType({
            type: TYPE_DATA_CHATBOT.UPDATE,
            data: { id, tags: finalTags },
            isForceRerender: true,
            isTriggerReload: false
        });
        clientQuery(
            updateConversationTags(id),
            { data: { tags: finalTags }, method: 'PUT', toFormData: false },
            null,
            _handleFail
        );
    };

    return (
        <div className="wrapper-chatbot flex-column js-contents-content">
            <div className="flex-betweenitems">
                <h3 className="wrapper-chatbot__title fs-600 fs-18 black">{t('report')}</h3>
            </div>
            <GDStatusBar ref={refAlert} />
            <div className="container-print container-wrap">
                <TableChatbot
                    ref={refTable}
                    reportType={typeReport}
                    endpoint={GET_CHATBOT_REPORT}
                    gridColumnReport={getColumnReportAI}
                    isShowDateRange
                    isShowCheckbox={false}
                    filters={CHATBOT_REPORT_FILTER}
                    isMapDropdownTagByName
                    typeAgent={typeAgent}
                    onClickRow={_handleClickRow}
                    onGetData={_handleGetData}
                    onShowAlert={_handleShowAlert}
                    onHandleSaveTags={_handleSaveTags}
                />
            </div>
            <SummaryConversation ref={refSummary} />
            <PortalConversation ref={refPortalConversation} />
        </div>
    );
};
export default ChatbotReport;

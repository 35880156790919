import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import ButtonSave from 'app/components/button/ButtonSave';
import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';

const EditCompanyAddress = ({ onEditAddress = () => {} }, ref) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { isOpen: false });
    const { isOpen } = state;
    const refArea = useRef(null);
    const refButtonSave = useRef(null);
    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = (companyAddress) => {
        dispatchState({ isOpen: true, companyAddress });
        setTimeout(() => {
            refArea.current.defaultValue = companyAddress.replaceAll('<br />', '\n');
        }, 0);
    };
    const _close = () => dispatchState({ isOpen: false });

    const _handleSave = () => {
        onEditAddress(refArea.current.value);
        _close();
    };

    if (!isOpen) return null;
    return (
        <ReactModal
            id="edit_company_address"
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal --form-no-border open"
            onRequestClose={_close}
        >
            <div className="modal__overlay bg-fixed" />
            <div className="modal__container --md">
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('common:edit_company_address')}</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                        <IconClose />
                    </div>
                </div>
                <div className="body-modal scrolls">
                    <div className="rows">
                        <p className="txt mb-1">{t('report:company_address')}</p>
                        <textarea
                            ref={refArea}
                            className="field-textarea --no-resize"
                            rows={5}
                            cols={30}
                            autoFocus
                            spellCheck
                        />
                    </div>
                </div>
                <div className="footer-modal footer-hasbtn btn-close fs-14">
                    <span className="v2-btn-default --transparent" onClick={_close}>
                        {t('common:cancel')}
                    </span>
                    <ButtonSave ref={refButtonSave} className="v2-btn-main" onSave={_handleSave} />
                </div>
            </div>
        </ReactModal>
    );
};

export default forwardRef(EditCompanyAddress);

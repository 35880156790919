import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';

import { reducer } from 'app/const/Reducer';
import BodyConversation from '../components/BodyConversation';
import FooterConversation from '../components/FooterConversation';
import HeaderConversation from '../components/HeaderConversation';

const AIConversationDetail = ({ onClose = () => {} }, ref) => {
    const [state, dispatchState] = useReducer(reducer, { data: [], isOpen: false });
    const { data: finalData, isOpen } = state;

    const refFooterConversation = useRef(null);

    useImperativeHandle(ref, () => ({ _open: _handleOpen, _close: _handleClose }));

    const _handleOpen = () => {
        dispatchState((prev) => ({ ...prev, isOpen: true, data: [] }));
    };

    const _handleClose = () => {
        dispatchState((prev) => ({ ...prev, isOpen: false, data: [] }));
        onClose();
        refFooterConversation.current && refFooterConversation.current._resetConversation();
    };

    const _handleAddData = ({ data = {} }) => {
        dispatchState((prev) => ({ ...prev, data: [...prev.data, data] }));
    };

    return (
        <div className={classNames('conversation-sms notification-dropdown', { active: isOpen })}>
            <HeaderConversation onClose={_handleClose} />
            <BodyConversation data={finalData} />
            <FooterConversation ref={refFooterConversation} onAddData={_handleAddData} />
        </div>
    );
};

export default forwardRef(AIConversationDetail);

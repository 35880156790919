import moment from 'moment-timezone';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import InputTime from 'app/components/input/InputTime';
import { reducer } from 'app/const/Reducer';
import {
    calculateDaysPassed,
    correctNearFormats,
    formatTimeUnix,
    formatUnix,
    getTimeObject,
    timeObjectFromString,
    validateTimeDuration
} from 'common/utils/TimeUtils';
import { getDefaultTime, getDuration, trackerAlert } from '../utils';

const TimerTotal = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const {
        implementWithModal = false,
        defaultDayOver = 0,
        defaultInTime = 0,
        defaultOutTime = 0,
        defaultDuration = 0,
        onBlur = () => {},
        onError = () => {}
    } = props;
    const defaultProps = { defaultDayOver, defaultInTime, defaultOutTime, defaultDuration };

    const tz = useSelector(({ auth }) => auth.user?.settings?.timezone);
    const date = moment().tz(tz);

    const initializer = ({ defaultDayOver, defaultInTime, defaultOutTime, defaultDuration }) => {
        const dateObject = {
            date: date.clone().get('date'),
            month: date.clone().get('month'),
            year: date.clone().get('year')
        };
        const { currentTime, timeRounded } = getDefaultTime(date.clone(), tz);

        const inTime = defaultInTime ? moment.tz(moment.unix(defaultInTime), tz).set(dateObject).unix() : currentTime;
        const outTime = defaultOutTime
            ? moment.tz(moment.unix(defaultOutTime), tz).set(dateObject).unix()
            : timeRounded;

        const dayOver =
            defaultDayOver ||
            calculateDaysPassed(defaultInTime, defaultOutTime, tz) ||
            calculateDaysPassed(inTime, outTime, tz);
        return { inTime, outTime, dayOver, duration: defaultDuration || getDuration(inTime, outTime) };
    };

    const [state, dispatchState] = useReducer(reducer, defaultProps, initializer);
    const { inTime, outTime, dayOver, duration } = state;

    const refIn = useRef(null);
    const refOut = useRef(null);
    const refDuration = useRef(null);

    useImperativeHandle(ref, () => ({ getValue: _handleGetValue }));

    const _handleGetValue = () => {
        return { inTime, outTime, dayOver };
    };

    const _handleBlur = (timeString, prefix = 'inTime') => {
        const newState = { ...state };
        const objectTime = timeObjectFromString(timeString, moment.unix(newState[prefix]).get('seconds'));
        newState[prefix] = date.clone().set(objectTime).unix();

        if (prefix === 'inTime') {
            // Case out time > in time and day over > 0
            if (newState['dayOver'] > 0) {
                newState['duration'] = getDuration(
                    newState['inTime'],
                    moment.unix(newState['outTime']).add('day', newState['dayOver']).unix()
                );
            } else {
                if (newState['inTime'] > newState['outTime']) {
                    newState['duration'] = getDuration(
                        newState['inTime'],
                        moment.unix(newState['outTime']).add('day', 1).set({ seconds: 0 }).unix()
                    );
                } else {
                    // Case default day over = 0
                    newState['duration'] = getDuration(newState['inTime'], newState['outTime']);
                }
            }
        } else {
            // Case out time < in time and day over < 0
            if (newState[prefix] < newState['inTime']) {
                newState[prefix] = outTime;
                newState['duration'] = duration;
                refOut.current.setValue(formatUnix(outTime, tz));

                // Alert when out time < in time
                const message = t('edit_duration_alert_warn');
                implementWithModal ? onError({ message }) : trackerAlert(message);
            } else {
                const currentOutUnix = date.clone().add('day', dayOver).set(objectTime).unix();
                newState['duration'] = getDuration(newState['inTime'], currentOutUnix);
            }
        }

        refDuration.current.value = formatTimeUnix(newState['duration']);
        onBlur(newState);
        dispatchState((prevState) => ({ ...prevState, ...newState }));
    };

    const _handleDuration = (event) => {
        const valueInput = correctNearFormats(event.target.value);
        const isValid = validateTimeDuration(valueInput);

        // case input is invalid or value input is same with current duration
        if (!isValid || valueInput === formatTimeUnix(duration)) {
            refDuration.current.value = formatTimeUnix(duration);
            return;
        }
        event.target.value = valueInput;

        const newState = { ...state };
        const timeDuration = getTimeObject(valueInput);
        // calculate out time from in time and new duration object
        newState['outTime'] = moment.unix(newState['inTime']).add(timeDuration).unix();
        newState['duration'] = getDuration(newState['inTime'], newState['outTime']);

        // case for duration set is over current day, reset duration
        if (date.clone().add(1, 'day').startOf('day').unix() < newState['outTime']) {
            refDuration.current.value = formatTimeUnix(duration);

            // Alert when out time > 1 day
            const message = t('edit_duration_alert_warn');
            implementWithModal ? onError({ message }) : trackerAlert(message);
            return;
        }

        // calculate day over
        newState['dayOver'] = calculateDaysPassed(newState['inTime'], newState['outTime'], tz) || 0;
        // reset date for out time
        newState['outTime'] = moment
            .unix(newState['outTime'])
            .set({
                day: date.get('day'),
                hours: moment.unix(newState['outTime']).get('hours'),
                minutes: moment.unix(newState['outTime']).get('minutes'),
                second: moment.unix(newState['outTime']).get('seconds')
            })
            .unix();

        // set value for input `out time` with formatted with timezone
        refOut.current.setValue(formatUnix(newState['outTime'], tz));
        onBlur(newState);
        dispatchState((prevState) => ({ ...prevState, ...newState }));
    };

    return (
        <>
            <div className="wrap-time flexcenter gap-4">
                <div className="flex-column gap-5">
                    {implementWithModal ? <span className="txt-title">{t('common:start_time')}</span> : null}
                    <InputTime
                        ref={refIn}
                        prefix="inTime"
                        defaultTime={formatUnix(inTime, tz)}
                        timesDisable={formatUnix(outTime, tz)}
                        onBlur={_handleBlur}
                    />
                </div>
                {implementWithModal ? null : <span className="txt">-</span>}
                <div className="flex-column gap-5">
                    {implementWithModal ? <span className="txt-title">{t('common:end_time')}</span> : null}
                    <InputTime
                        ref={refOut}
                        prefix="outTime"
                        defaultTime={formatUnix(outTime, tz)}
                        onBlur={_handleBlur}
                    />
                </div>
            </div>
            <div className="col col-duration">
                {implementWithModal ? <span className="txt">{t('common:total_time')}</span> : null}
                <input
                    ref={refDuration}
                    className="time field-input flex-1"
                    defaultValue={formatTimeUnix(duration)}
                    onBlur={_handleDuration}
                />
            </div>
        </>
    );
});

export default TimerTotal;

import React, { useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SQUARE_ADDONS_OPTIONS } from 'app/const/Api';
import { getLinkSquareConnect } from 'app/const/api/V2';
import { setBranchLocalStorage } from 'app/const/Branch';
import { INTERCOM_SQUARE_URL } from 'app/const/Keys';
import { reducer } from 'app/const/Reducer';
import IconBachelorsHat from 'assets/icon/IconBachelorsHat';
import AddonsBoxHeader from '../../components/AddonsBoxHeader';
import SquareAddonsDisconnect from './SquareAddonsDisconnect';
import SquareCodeDropdown from './SquareCodeDropdown';

const SquareContent = ({
    keyword,
    name,
    status,
    level = 1,
    upgrade = 0,
    onDisconnect = () => {},
    onDisableUpdate = () => {}
}) => {
    const { t } = useTranslation();
    const { application_id } = useSelector(({ auth }) => auth.user.settings.square);
    const branchId = useSelector(({ auth }) => auth.user.company.branch.id);
    const [state, dispatchState] = useReducer(reducer, { isNotConnect: !!status });
    const refDisconnect = useRef(null);
    const refHeader = useRef(null);
    const { isNotConnect } = state;

    /** This function to call modal confirm disconnect square open */
    const _handleDisconnectSquare = () => {
        refDisconnect.current._open();
    };

    /**
     * When the user clicks the disconnect button, the user is disconnected from the server and the state
     * is updated.
     */
    const _handleDisconnectSuccess = () => {
        dispatchState({ isNotConnect: false });
        onDisconnect();
    };

    const _handleConnectSquare = (e) => {
        e && e.preventDefault();
        setBranchLocalStorage(branchId);
        window.location.href = getLinkSquareConnect(application_id, branchId);
    };

    return (
        <>
            <div className="boxs boxs--no-border">
                <AddonsBoxHeader
                    ref={refHeader}
                    isMerchant
                    keyword={keyword}
                    name={name}
                    upgrade={upgrade}
                    status={status}
                    level={level}
                    onDisableUpdate={onDisableUpdate}
                    onConfirmChange={_handleDisconnectSquare}
                    urlUpdateStatus={SQUARE_ADDONS_OPTIONS}
                />

                <div className="boxs__contents mt-3">
                    <p className="description">{t('addons:connect_square_desc')}</p>
                    <div className="boxs__contents --hasline">
                        {!!isNotConnect ? (
                            <div className="desc-connect mb-0">
                                <div className="v2-btn-default --grey btn-modal" onClick={_handleDisconnectSquare}>
                                    {t('addons:disconnect_from_square')}
                                </div>
                            </div>
                        ) : (
                            <div className="desc-connect mb-0">
                                <a onClick={_handleConnectSquare} className="v2-btn-default --grey btn-modal">
                                    {t('addons:connect_with_square')}
                                </a>
                            </div>
                        )}
                    </div>

                    <div className="boxs__contents --hasline">
                        <p className="description">
                            {t('addons:square_price_content')}
                            <a
                                href="https://squareup.com/t/cmtp_performance/pr_developers/d_partnerships/p_gorilladesk/?route=sales/contact&page=/us/en/payments/pricing"
                                className="purple-default"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {t('addons:square_price_desc')}
                            </a>
                        </p>
                    </div>
                </div>
            </div>

            <div className="boxs boxs--no-border mb-6">
                <div className="boxs__contents mt-0">
                    <p className="description mb-2">{t('addons:connect_your_square')}</p>
                    <p className="description mb-2">{t('addons:connect_your_square_desc')}</p>
                    <a
                        href={INTERCOM_SQUARE_URL}
                        target="_blank"
                        className="mb-2 svg-purple purple-default link-portal customize-ml"
                        rel="noreferrer"
                    >
                        <IconBachelorsHat />
                        <span className="txt-ellipsis ml-1">{t('addons:how_to_find_API')}</span>
                    </a>

                    <SquareCodeDropdown isNotConnect={isNotConnect} />
                </div>
            </div>
            <SquareAddonsDisconnect
                ref={refDisconnect}
                onDisconnect={() => refHeader.current._handleChangeStatus(0, true)}
                onDisconnectSuccess={_handleDisconnectSuccess}
            />
        </>
    );
};

export default SquareContent;

import IconFuture from 'assets/icon/IconFuture';
import { HeaderCustom } from 'app/components/daterange/components/HeaderCustom';
import { convertPhpFormatToMoment, formartDateWithString } from 'common/utils/DateUtils';
import moment from 'moment';
import React, { forwardRef, useEffect, useId, useImperativeHandle, useMemo, useRef } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import { useSelector } from 'react-redux';
import i18n from 'assets/i18n';

const CustomInput = ({ onClick, date, onChangeDate, isAutofocus = false, isShowIcon = true }, ref) => {
    const newDate = moment(date).locale(i18n.language);
    const isChanged = useRef(false);
    const refIsFocus = useRef(false);
    const dateFormat = useSelector(({ auth }) => auth?.user?.company?.date_format);

    const formatDisplay = convertPhpFormatToMoment(dateFormat);

    // Handle autofocus
    useEffect(() => {
        if (isAutofocus && !refIsFocus.current) {
            refIsFocus.current = true;
            onClick();
        }
    }, []);

    const _handleBlur = (e) => {
        if (isChanged.current) {
            if (moment(e.target.value, formatDisplay).isValid()) {
                onChangeDate(moment(e.target.value, formatDisplay).toISOString());
            } else {
                onChangeDate(moment().toString());
            }
        }

        isChanged.current = false;
    };

    return (
        <div onClick={onClick} className="relative">
            <input
                ref={ref}
                className="field-input text-grey-light"
                type="text"
                placeholder={'MM/DD/YYYY'}
                defaultValue={newDate.isValid() ? newDate.format(formatDisplay) : date}
                onChange={() => {
                    isChanged.current = true;
                }}
                autoFocus={isAutofocus}
                onBlur={_handleBlur}
                spellCheck
            />
            {isShowIcon ? (
                <span className="svg-calendar-absolute">
                    <IconFuture />
                </span>
            ) : null}
        </div>
    );
};

function DatePickerInput(
    {
        selectDefault,
        modifiers = null,
        disableConvert = false,
        isShouldDefaultValue = true,
        isAutofocus = false,
        isShowIcon = true,
        onChange,
        minDate = false,
        ...props
    },
    ref
) {
    const refDropDown = useRef(null);
    const refPicker = useRef(null);

    const CustomInputRef = forwardRef(CustomInput);
    const dropdownId = useId(null);

    useImperativeHandle(ref, () => ({ _reset: _handleReset }));

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const handleClickOutside = (event) => {
        const elPrevent = document.getElementById(dropdownId);
        if (
            refDropDown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropDown.current.contains(event.target)
        ) {
            refPicker.current?.setOpen(false);
        }
    };

    const _handleChangeDate = (date) => {
        onChange(date);
    };

    const renderContainer = ({ children }) => {
        return (
            <div ref={refDropDown} id="show_date_picker" className="format-container-date-picker">
                <div className="wrapper-date-picker-container">
                    <CalendarContainer className="react-datepicker-custom">
                        <div style={{ position: 'relative' }}>{children}</div>
                    </CalendarContainer>
                </div>
            </div>
        );
    };

    const finalSelected = useMemo(() => {
        if (disableConvert) return selectDefault;
        return selectDefault
            ? formartDateWithString(moment(selectDefault).utc().format('YYYY-MM-DD HH:mm:ss'))
            : isShouldDefaultValue
              ? new Date()
              : '';
    }, [selectDefault]);

    const _handleReset = () => {
        _handleChangeDate(new Date());
    };

    return (
        <div id={dropdownId}>
            <DatePicker
                ref={refPicker}
                selected={finalSelected}
                minDate={minDate ? new Date() : false}
                shouldCloseOnSelect
                popperModifiers={modifiers || []}
                calendarContainer={renderContainer}
                onChange={_handleChangeDate}
                customInput={
                    <CustomInputRef
                        date={finalSelected || ''}
                        isAutofocus={isAutofocus}
                        isShowIcon={isShowIcon}
                        onChangeDate={_handleChangeDate}
                    />
                }
                renderCustomHeader={HeaderCustom}
                {...props}
            />
        </div>
    );
}

export default forwardRef(DatePickerInput);

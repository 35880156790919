export default function IconBook() {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 5.5H7C6.17157 5.5 5.5 6.17157 5.5 7V16C5.5 16.8284 6.17157 17.5 7 17.5H14C14.8284 17.5 15.5 16.8284 15.5 16V7C15.5 6.17157 14.8284 5.5 14 5.5Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />{' '}
            <path
                d="M6.5 17.5L8.5 19.5H15.5C16.6046 19.5 17.5 18.6046 17.5 17.5V8.5L15.5 6.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />{' '}
            <path d="M7.5 8.5H12.5" stroke="var(--color-icon)" strokeWidth="1.3" strokeLinecap="round" />{' '}
            <path d="M7.5 10.5H10.5" stroke="var(--color-icon)" strokeWidth="1.3" strokeLinecap="round" />{' '}
        </svg>
    );
}

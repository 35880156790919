import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import ReactModal from 'react-modal';
import { useTranslation } from 'react-i18next';

import ButtonSave from 'app/components/button/ButtonSave';
import { ADDONS_SMS_COUNTRIES, ADDONS_SMS_RENT_NUMBERS } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import IconClose from 'assets/icon/IconClose';
import { clientQuery } from 'common/utils/ApiUtils';
import AddonsAlert from '../../components/AddonsAlert';
import { LoadingRentMobileModal } from './SMSAddonsLoading';
import SMSAddonsPhonesTable from './SMSAddonsPhonesTable';
import SMSAddonsSearchPhones from './SMSAddonsSearchPhones';

const SMSAddonsRentPhones = forwardRef(({ onRentSuccess = () => {} }, ref) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        isDisplayTable: false,
        isDisabled: true,
        phones: [],
        phoneCountries: [],
        isLoading: true,
        packageId: null
    });
    const refPhoneSelect = useRef(null);
    const refAlert = useRef(null);
    const refButtonSave = useRef(null);
    const refFirsTime = useRef(false);
    const { isDisplayTable, isDisabled, phones, isLoading, packageId, phoneCountries } = state;

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = (packageId) => {
        dispatchState({ isOpen: true, packageId });
        if (!refFirsTime.current) {
            const _handleGetPhoneCountriesSuccess = ({ data }) => {
                dispatchState({ phoneCountries: data, isLoading: false, isDisabled: true });
            };
            clientQuery(ADDONS_SMS_COUNTRIES, { method: 'GET' }, _handleGetPhoneCountriesSuccess);
        }
    };

    /** It sets the state to the initial state and clears the phone select ref. */
    const _close = () => {
        dispatchState({
            isOpen: false,
            isDisplayTable: false,
            isDisabled: true,
            phones: [],
            phoneCountries: [],
            isLoading: true,
            packageId: null
        });
        refPhoneSelect.current = null;
    };

    /** It sets the state of the application to display the table of phones. */
    const _handleSearchSuccess = (phones, prefix) => {
        dispatchState({ isDisplayTable: true, phones, isDisabled: true, prefix });
    };

    const _handleSubmitRent = () => {
        _handleCloseAlert();
        const _handleSuccess = ({ data }) => {
            dispatchState({ isOpen: false });
            onRentSuccess(data);
        };

        const _handleFailed = ({ message }) => {
            _handleShowAlert({ id: 'ren_failed', message, type: LIST_STATUS.ERROR });
            refButtonSave.current.removeLoading();
        };

        clientQuery(
            ADDONS_SMS_RENT_NUMBERS,
            { data: { number: refPhoneSelect.current, package_id: packageId }, method: 'POST' },
            _handleSuccess,
            _handleFailed
        );
    };

    const _handleSelectPhone = (phone) => {
        refPhoneSelect.current = phone;
        dispatchState({ isDisabled: false });
    };

    const _handleShowAlert = (alert) => {
        refAlert.current.showStatusBar(alert);
    };

    const _handleCloseAlert = () => {
        refAlert.current.closeStatusBar();
    };

    if (!state.isOpen) return null;
    return (
        <ReactModal
            isOpen
            id="modal_choose_sms"
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal container-print choose-system-sms open"
            onRequestClose={_close}
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />

            <div className="modal__container large">
                {isLoading ? (
                    <LoadingRentMobileModal />
                ) : (
                    <>
                        <div className="header-modal btn-close">
                            <h3 className="header-modal__label">{t('common:choose_your_system_SMS_phone_number')}</h3>
                            <span className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                                <IconClose />
                            </span>
                        </div>

                        <div className="body-modal">
                            <div className="mx-3">
                                <AddonsAlert ref={refAlert} />
                            </div>
                            <SMSAddonsSearchPhones
                                options={phoneCountries}
                                onSearchSuccess={_handleSearchSuccess}
                                onShowAlert={_handleShowAlert}
                                onCloseAlert={_handleCloseAlert}
                            />
                            {isDisplayTable && (
                                <SMSAddonsPhonesTable
                                    data={phones}
                                    number={state.prefix}
                                    onSelect={_handleSelectPhone}
                                />
                            )}
                        </div>

                        <div className="footer-modal">
                            <ButtonSave
                                ref={refButtonSave}
                                className="v2-btn-main"
                                onSave={_handleSubmitRent}
                                disabled={isDisabled}
                                title={t('customers:activate')}
                            />
                        </div>
                    </>
                )}
            </div>
        </ReactModal>
    );
});

SMSAddonsRentPhones.displayName = 'SMSAddonsRentPhones';

export default SMSAddonsRentPhones;

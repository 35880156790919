import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ButtonSave from 'app/components/button/ButtonSave';
import DropdownRange from 'app/components/dropdown/DropdownRange';
import GDStatusBar from 'app/components/status/statusbar';
import { DAY_RANGE_OPTIONS, DISTANCE_RANGE_OPTIONS } from 'app/const/App';
import { LIST_STATUS } from 'app/const/Status';
import { getDefaultValue } from 'app/modules/calendar/components/magnet/utils';
import { AUTH_TYPE } from 'common/redux/actions/authAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { messageActionsAlert } from 'common/utils/StringUtils';
import { LIST_ACTION_MESSAGE } from '../../constants';
import { CONFIG_ADDON_ROUTING, ROUTING_LIMITS, ROUTING_UNITS, TYPE_ADVANCE_ROUTING } from '../constants';
import AvailableTimeSlot from './AvailableTimeSlot';

const BoxDefaultSetup = ({ title = '', description = '', defaultData = {}, type = TYPE_ADVANCE_ROUTING.MAGNET }) => {
    const { t } = useTranslation(['addons', 'common']);
    const dispatch = useDispatch();
    const distance_type = useSelector(({ auth }) => auth.user.company.distance_type);
    const { endPointSetup, keySetting, messageSaveSuccess, showTimeSlot } = CONFIG_ADDON_ROUTING[type];

    const refAlert = useRef(null);
    const refButtonSave = useRef(null);
    const defaultDistance = defaultData?.distance || { value: 0, unit: ROUTING_UNITS[distance_type]['value'] };
    const defaultDays = defaultData?.day_range || 0;
    const refData = useRef({ distance: defaultDistance, day_range: defaultDays });
    const refTimeSlot = useRef(null);

    useEffect(() => {
        _handleDisableBtnSave(true);
    }, []);

    const handleShowStatus = ({ message, type = LIST_STATUS.ERROR }) => {
        if (!refAlert.current) return;
        refAlert.current.clearAllStatusBar();
        refAlert.current.showStatusBar('permission', message, type);
    };

    const handleSelect = (unit, value) => {
        _handleDisableBtnSave(false);
        refData.current = {
            ...(refData.current || {}),
            [unit]: unit === 'distance' ? { value, unit: ROUTING_UNITS[distance_type]['value'] } : value
        };
    };

    const handleSave = () => {
        refAlert.current.clearAllStatusBar();

        let finalData = refData.current;
        const { day_range: dayRange = 0, distance = {} } = finalData;
        const { value: distanceValue } = distance;
        const { MAX_DISTANCE, MAX_DAYS } = ROUTING_LIMITS;
        const msgError = [];

        if (isNaN(distanceValue) || distanceValue === '') msgError.push(t('common:distance_range_can_not_blank'));
        if (isNaN(dayRange) || dayRange === '') msgError.push(t('common:day_range_can_not_blank'));

        if (distanceValue > MAX_DISTANCE)
            msgError.push(
                t('addons:error_message_limit', {
                    name: t('common:unit_limit', { unit: t(`calendar:${ROUTING_UNITS[distance_type]['label']}`) }),
                    value: MAX_DISTANCE
                })
            );
        if (dayRange > MAX_DAYS)
            msgError.push(t('addons:error_message_limit', { name: t('day_range'), value: MAX_DAYS }));

        if (!!msgError.length) {
            _removeLoading();
            handleShowStatus({ message: msgError });
            return;
        }

        const handleSaveSuccess = ({ data, message = [] }) => {
            dispatch({ type: AUTH_TYPE.UPDATE_ADVANCE_ROUTING, payload: { keyword: keySetting, data } });
            handleShowStatus({
                message: messageActionsAlert({
                    message,
                    type: t(`addons:${messageSaveSuccess}`),
                    action: LIST_ACTION_MESSAGE.UPDATED
                }),
                type: LIST_STATUS.SUCCESS
            });
        };
        const handleSaveFail = ({ message }) => {
            handleShowStatus({ message });
        };
        const handleSaveFinally = () => {
            _removeLoading();
            _handleDisableBtnSave(true);
        };

        if (showTimeSlot) finalData = { ...finalData, ...refTimeSlot.current.getValue() };

        clientQuery(
            endPointSetup,
            { data: finalData, toFormData: false, method: 'PUT' },
            handleSaveSuccess,
            handleSaveFail,
            handleSaveFinally
        );
    };

    const _removeLoading = () => {
        refButtonSave.current?.removeLoading();
    };

    const _handleDisableBtnSave = (value) => {
        const buttonSave = refButtonSave.current;
        if (!buttonSave) return;
        buttonSave.getStatusBtn() !== !!value && buttonSave.setDisable(!!value);
    };

    return (
        <>
            <div className="boxs boxs-has-footer --routing">
                <div className="boxs__header align-top flex-column gap-4">
                    <p className="fs-15 fw-700 black">{title}</p>
                    <p className="black-darker3">{description}</p>
                </div>
                <div className="boxs__contents flex-column gap-16">
                    <GDStatusBar ref={refAlert} />
                    <div className="flexcenter gap-8">
                        <div className="flex-column gap-2 flex-1">
                            <div className="fw-500 black-darker3">
                                {t('common:unit_limit', {
                                    unit: t(`calendar:${ROUTING_UNITS[distance_type]['label']}`)
                                })}
                            </div>
                            <DropdownRange
                                unit={t(`calendar:${ROUTING_UNITS[distance_type]['label']}`)}
                                keyValue="distance"
                                options={DISTANCE_RANGE_OPTIONS}
                                defaultSelect={getDefaultValue(defaultDistance.value, DISTANCE_RANGE_OPTIONS)}
                                propsCustom={{
                                    title: t('common:custom_distance_range'),
                                    label: t('common:distance_range')
                                }}
                                onSelect={handleSelect}
                            />
                        </div>
                        <div className="flex-column gap-2 flex-1">
                            <div className="fw-500 black-darker3">{t('common:day_range')}</div>
                            <DropdownRange
                                unit={t('customers:days')}
                                keyValue="day_range"
                                options={DAY_RANGE_OPTIONS}
                                defaultSelect={getDefaultValue(defaultDays, DAY_RANGE_OPTIONS)}
                                propsCustom={{ title: t('common:custom_distance_range'), label: t('common:day_range') }}
                                onSelect={handleSelect}
                            />
                        </div>
                    </div>
                    {showTimeSlot ? (
                        <AvailableTimeSlot
                            ref={refTimeSlot}
                            defaultData={defaultData}
                            onDisableBtnSave={_handleDisableBtnSave}
                        />
                    ) : null}
                </div>
                <div className="boxs__contents d-flex justify-end">
                    <ButtonSave ref={refButtonSave} onSave={handleSave} />
                </div>
            </div>
        </>
    );
};

export default BoxDefaultSetup;

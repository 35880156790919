import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ADDONS_BOOKING } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import IconCogWheel from 'assets/icon/IconCogWheel';
import { getPlanUser } from 'common/utils/PermissionUtils';

const AddonFooterPortalAdditional = () => {
    const { t } = useTranslation(['addons']);
    const { profile } = useSelector(({ auth }) => auth.user);
    const { isBasicPlan } = getPlanUser(profile);

    return (
        <div className="boxs__footer">
            <Link
                to={addBranchPath(ADDONS_BOOKING)}
                className={classNames('v2-btn-default svg-purple btn-bg-purple --icon-r --sm', {
                    'is-disable': isBasicPlan
                })}
            >
                {t('manage')} <IconCogWheel />
            </Link>
        </div>
    );
};

export default AddonFooterPortalAdditional;

import queryString from 'query-string';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import GdLazyImage from 'app/components/lazyimage';
import { ENTER_KEY_CODE } from 'app/const/App';
import { PASSWORD_LIMIT_CHARACTER } from 'app/const/Auth';
import { reducer } from 'app/const/Reducer';
import { AUTH_LOGIN, HOME } from 'app/const/Route';
import { LINK_CDN_IMG } from 'app/const/URL';
import 'app/modules/auth/changePassword/css/index.scss';
import AuthInputField from 'app/modules/auth/components/AuthInputField';
import ButtonLoading from 'app/modules/auth/components/ButtonLoading';
import { useSiteTitle } from 'common/hooks/useSiteTitle';
import { userChangePasswordRequest } from 'common/redux/actions/authAction';
import { detectKeyPress } from 'common/utils/FunctionUtils';
import { checkTokenExpired } from 'common/utils/SecurityUtils';
import { AuthBanner } from '../components/AuthBanner';
import NotiChangePassword from './components/NotiChangePassword';

let timer;

function ChangePassword() {
    useSiteTitle('auth:reset_password');
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation(['auth']);
    const params = queryString.parse(location.search);

    const refForm = useRef(null);
    const refTimeout = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        errorsForm: null,
        error: null,
        disableForm: false,
        message: '',
        success: false
    });

    const token = params && params['token'] ? params['token'] : '';
    const tokenExpired = token.split('_');
    const dateToken = tokenExpired[1];

    useEffect(() => {
        refForm.current?.password && refForm.current.password.focus();
        if (!dateToken || !checkTokenExpired(dateToken)) {
            dispatchState({ message: [t('auth:token_expired')] });
        }
        return () => {
            clearTimeout(timer);
            clearTimeout(refTimeout.current);
        };
    }, []);

    useEffect(() => {
        if (!token) {
            history.push(AUTH_LOGIN);
        }
    }, [token]);

    const _handleKeypress = (e) => {
        if (detectKeyPress(e, ENTER_KEY_CODE)) {
            _handleCheckForm(e);
        }
    };

    const _handleCheckForm = (e) => {
        e && e.preventDefault();
        const msgError = {};
        const error = null;
        const { password, repeat_password } = refForm.current;
        const lengthForm = refForm.current.length;

        for (let i = 0; i < lengthForm; i++) {
            const { name, value } = refForm.current[i];
            if (!value.trim()) {
                msgError[name] = t(`auth:${name}`).concat(' ', t('auth:must_not_be_empty'), '!');
            }
        }

        const passwordValue = password?.value;
        const repeatPasswordValue = repeat_password?.value;

        if (passwordValue?.length && passwordValue.length < PASSWORD_LIMIT_CHARACTER) {
            msgError['password'] = t('common:password_limit').concat(
                ' ',
                PASSWORD_LIMIT_CHARACTER,
                ' ',
                t('common:characters')
            );
        }

        if (repeatPasswordValue?.length && repeatPasswordValue.length < PASSWORD_LIMIT_CHARACTER) {
            msgError['repeat_password'] = t('common:repeat_password_limit').concat(
                ' ',
                PASSWORD_LIMIT_CHARACTER,
                ' ',
                t('common:characters')
            );
        }

        if (!msgError['repeat_password'] && passwordValue !== repeatPasswordValue) {
            msgError['repeat_password'] = t('auth:password_not_match');
        }

        if (Object.keys(msgError).length || error) {
            dispatchState({
                errorsForm: msgError,
                error
            });
            return false;
        }

        dispatchState({
            disableForm: true
        });

        dispatch(
            userChangePasswordRequest(
                { password: passwordValue, repeat_password: repeatPasswordValue, token: params.token },
                handleChangePasswordSuccess,
                handleChangePasswordFailed
            )
        );
    };

    const handleChangePasswordSuccess = () => {
        dispatchState({
            message: [t('auth:change_password_success')],
            success: true
        });
        refTimeout.current = setTimeout(() => {
            window.location.href = HOME;
        }, 5000);
    };

    const handleChangePasswordFailed = (response) => {
        dispatchState({
            message: response?.message,
            disableForm: false
        });
    };

    const _handleDebounceSubmit = () => {
        if (timer) clearTimeout(timer);

        timer = setTimeout(() => {
            _handleCheckForm();
        }, 300);
    };

    const _handleFocus = () => {
        dispatchState({ errorsForm: null, error: null });
    };

    if (state.message) {
        return <NotiChangePassword message={state.message} status={state.success} />;
    }

    return (
        <div className="gorilladesk auth-page">
            <div className="wrapper auth-container">
                <AuthBanner />
                <div className="main-login">
                    <div className="flex-1 flex-centeritem">
                        <div className="gd-form-v2">
                            <h1 className="logo-text-gorilla">
                                <GdLazyImage
                                    src={`${LINK_CDN_IMG}gorilladesk.png`}
                                    alt={t('common:gorilladesk')}
                                    width={335}
                                    height={80}
                                />
                            </h1>
                            <form id="change-password-form" ref={refForm} action="#">
                                <section className="gd-login-btn-group">
                                    <div className="user-box">
                                        <AuthInputField
                                            label={t('auth:new_password')}
                                            type="password"
                                            name="password"
                                            error={state.errorsForm?.password}
                                            placeholder={t('auth:new_password')}
                                            onKeyPress={_handleKeypress}
                                            onFocus={_handleFocus}
                                        />
                                    </div>

                                    <div className="user-box">
                                        <AuthInputField
                                            label={t('auth:repeat_password')}
                                            type="password"
                                            name="repeat_password"
                                            error={state.errorsForm?.repeat_password}
                                            placeholder={t('auth:repeat_password')}
                                            onKeyPress={_handleKeypress}
                                            onFocus={_handleFocus}
                                        />
                                    </div>

                                    <ButtonLoading
                                        onSubmit={_handleDebounceSubmit}
                                        text={t('auth:save')}
                                        classWrapper="v2-btn-main gd-btn-getstarted mb-8 mt-4"
                                        isLoading={state.disableForm}
                                        onKeyPress={_handleKeypress}
                                    />
                                </section>
                            </form>
                        </div>
                    </div>
                    <p className="mascot">
                        <GdLazyImage
                            src={`${LINK_CDN_IMG}half-gorilla.png`}
                            width={165}
                            height={150}
                            alt={t('common:gorilladesk')}
                        />
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ChangePassword;

export const mapboxStyleScheme = 'mapbox://styles/mapbox/';

const mapSettings = {
    handleStyles: {
        top: { width: '100%', height: '2px', top: '-1px' },
        right: { width: '2px', height: '100%', right: '0' }
    },
    className: 'wrapper-map wrap-map-control',
    id: 'wrapper-map',
    handleClasses: { top: 'resizable', right: 'resizable' }
};

const MAP_KEY_LIGHT = 'light-v10';
export const MAP_KEY_DARK = 'dark-v10';
export const MAP_KEY_STREET = 'streets-v11';
export const MAP_STYLES = [
    { key: MAP_KEY_LIGHT, class: 'map-bright', name: 'map_bright' },
    { key: MAP_KEY_STREET, class: 'map-streets', name: 'map_street' },
    { key: 'satellite-v9', class: 'map-satellite-hybrid', name: 'map_satellite_hybrid' },
    { key: MAP_KEY_DARK, class: 'map-dark', name: 'map_dark' }
];

export const MAP_STYLES_LIST = {
    street: MAP_KEY_STREET,
    outdoor: 'outdoors-v11',
    light: MAP_KEY_LIGHT,
    satellite: 'satellite-v9'
};

export const DEFAULT_MAP_STYLE = mapboxStyleScheme + MAP_STYLES_LIST.street + '?optimize=true';

export const DEFAULT_SETTING_ENABLE = {
    top: false,
    right: true,
    bottom: false,
    left: false,
    topRight: false,
    bottomRight: false,
    bottomLeft: false,
    topLeft: false
};

// Default to USA if haven't start_point of schedule user.
export const DEFAULT_CENTER_MAP = [-122.08370208740234, 37.42158889770508];

export default mapSettings;

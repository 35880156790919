import classNames from 'classnames';
import React, { Fragment, useEffect, useReducer } from 'react';

import { reducer } from 'app/const/Reducer';
import GDWistiaPlayer from './GDWistiaPlayer';
import LoadingDefault from './LoadingDefault';
import { regexLinkWistiaEmbed } from './utils';

const GDIframe = ({
    isAutoPlay = false,
    link = '',
    title = '',
    className = '',
    loadingComponent: LoadingComponent = LoadingDefault,
    onTrackingEvent
}) => {
    const [state, dispatchState] = useReducer(reducer, { isLoadingIframe: true, link });
    const { isLoadingIframe, link: finalLink } = state;
    const matchEmbedLink = regexLinkWistiaEmbed(finalLink);
    const haveTrackingEvent = typeof onTrackingEvent === 'function';

    useEffect(() => {
        if (!!link) dispatchState((prev) => ({ ...prev, link, isLoadingIframe: true }));
    }, [link]);

    const _onIframeLoadComplete = () => {
        dispatchState((prev) => ({
            ...prev,
            isLoadingIframe: false,
            link: isAutoPlay ? `${link}?autoplay=true` : prev.link
        }));
    };

    if (!link) return null;

    const _handleTrackingEvent = (data = {}) => {
        onTrackingEvent({ video_name: title, ...(data || {}) });
    };

    return (
        <Fragment>
            {isLoadingIframe && !haveTrackingEvent ? <LoadingComponent /> : null}
            {haveTrackingEvent ? (
                <GDWistiaPlayer
                    className={className}
                    videoId={matchEmbedLink ? matchEmbedLink[1] : ''}
                    isAutoPlay={isAutoPlay}
                    isLoading={isLoadingIframe}
                    loadingComponent={LoadingComponent}
                    onLoadComplete={_onIframeLoadComplete}
                    onTracking={_handleTrackingEvent}
                />
            ) : (
                <iframe
                    key={title}
                    src={finalLink}
                    title={title}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    className={classNames(className, { 'dp-hide': isLoadingIframe })}
                    onLoad={_onIframeLoadComplete}
                />
            )}
        </Fragment>
    );
};

export default GDIframe;

import classNames from 'classnames';
import React, { Fragment, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
    DEFAULT_ALL,
    KEYS_SINGLE_SELECT_CALL_API,
    LIST_SINGLE_SELECT_CALL_API,
    MATERIAL_NY_PRL,
    MATERIAL_USE_SELECT_OPTIONS
} from 'app/const/App';
import { COUNTY, TYPE_SELECT, YEAR } from 'app/const/report/ReportFilter';
import ReportListDropdown from 'app/modules/report/components/ReportListDropdown';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconDropDown from 'assets/icon/IconDropDown';
import useClickOutside from 'common/hooks/useClickOutside';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import DropdownPopper from '../dropdown/DropdownPopper';
import ItemJobStatus from '../job/ItemJobStatus';

const SingleSelect = ({
    name,
    options = [{ id: DEFAULT_ALL, name: 'Nonamed', value: DEFAULT_ALL }],
    selected: defaultSelected = [],
    title = '',
    onSelect = () => {},
    fileTranslation,
    onGetAsyncOptions = () => {},
    classWrapper = 'v2-dropdown',
    disableTranslation = false,
    parseHtml,
    timeText,
    reportType,
    buttonClassName = 'dropbtn v2-btn-default',
    isFetched = false,
    isPopper = false,
    isUseToggle = false,
    forceRerender = false,
    isJobStatus = false,
    isDisable = false,
    additionalOptions = null
}) => {
    const { t } = useTranslation([`${fileTranslation}`]);
    const isFirstTime = useRef(true);
    const [refSelect, isOpen, setIsOpen] = useClickOutside(false);
    const selected = isJobStatus && defaultSelected !== DEFAULT_ALL ? defaultSelected.toString() : defaultSelected;

    useEffect(() => {
        return () => {
            isFirstTime.current = true;
        };
    }, [forceRerender]);

    const handleOpen = () => {
        if (!isOpen && isFirstTime.current) {
            onGetAsyncOptions
                ? onGetAsyncOptions({
                      item: name,
                      callBack: () => {
                          isFirstTime.current = false;
                      }
                  })
                : (isFirstTime.current = false);
        }
        !isPopper && setIsOpen(!isOpen);
    };

    const handleSelectOption = (item) => {
        const finalValue = item?.value || item?.id;

        //Save last selected if type is service_type_single
        if (LIST_SINGLE_SELECT_CALL_API.includes(name)) {
            let objectLastSelected =
                getLocalStorage(KEYS_SINGLE_SELECT_CALL_API.concat('_', `${reportType}_${name}`)) || {};

            objectLastSelected = {
                id: item?.id,
                name: item?.name,
                value: finalValue
            };

            setLocalStorage(KEYS_SINGLE_SELECT_CALL_API.concat('_', `${reportType}_${name}`), {
                ...objectLastSelected
            });
        }

        //Fix bug replace selected = null when click selected option. DELETE after review pls :))
        if (finalValue === selected) return false;
        onSelect(name, finalValue, item.name);
    };

    const _renderText = () => {
        let itemSelected = options.find((item) => item?.value === selected || item?.id === selected);

        if (!itemSelected) {
            switch (name) {
                case YEAR:
                    itemSelected = { name: selected, id: selected, value: selected };
                    break;
                case COUNTY:
                    itemSelected = { id: selected, value: selected };
                    switch (selected) {
                        case MATERIAL_USE_SELECT_OPTIONS.ALL_COUNTY:
                            itemSelected.name = t('report:all_by_county_usa');
                            break;
                        case MATERIAL_USE_SELECT_OPTIONS.ALL_DETAILED:
                            itemSelected.name = t('report:all_detailed');
                            break;
                        case MATERIAL_USE_SELECT_OPTIONS.NY_PRL:
                            itemSelected.name = MATERIAL_NY_PRL;
                            break;
                        default:
                            itemSelected.name = selected;
                            break;
                    }
                    break;
                default:
                    break;
            }
        }

        if (LIST_SINGLE_SELECT_CALL_API.includes(name)) {
            const objectLastSelected = getLocalStorage(
                KEYS_SINGLE_SELECT_CALL_API.concat('_', `${reportType}_${name}`)
            );

            //check itemSelected match with lastSelected saved in local storage
            if (objectLastSelected?.value == selected && !itemSelected) {
                itemSelected = objectLastSelected;
            }
        }
        const text = itemSelected?.name || title;

        return disableTranslation ? text : t(`${fileTranslation}:${text}`);
    };

    const _renderTitle = () => {
        if (isJobStatus) {
            return (
                <div className="dropbtn v2-btn-default selection">
                    {selected === DEFAULT_ALL ? (
                        <span className="txt-ellipsis">{t('all')}</span>
                    ) : (
                        <ItemJobStatus idStatus={selected} classWrapper="wrap-tags" />
                    )}
                    <span className="arrow">
                        <IconArrowDown />
                    </span>
                </div>
            );
        }

        return (
            <div
                className={classNames('dropbtn', {
                    'v2-btn-default': !!timeText,
                    items: !timeText
                })}
            >
                {_renderButton(!!timeText, _renderText())}
            </div>
        );
    };

    const _renderButton = (isTimeText = false, text = '') => {
        if (isTimeText) {
            return (
                <Fragment>
                    <span className="flex-1">{text}</span>
                    <span className="time-text">{timeText}</span>
                    <span className="ml-1">
                        <IconDropDown />
                    </span>
                </Fragment>
            );
        }

        return (
            <Fragment>
                {parseHtml ? (
                    <span dangerouslySetInnerHTML={{ __html: text }}></span>
                ) : (
                    <span className="txt-ellipsis">{text}</span>
                )}
                <span className="arrow">
                    <IconArrowDown />
                </span>
            </Fragment>
        );
    };

    const _renderReportListDropdown = () => {
        return (
            <ReportListDropdown
                refSelect={refSelect}
                options={options}
                fileTranslation={fileTranslation}
                selected={selected}
                title={title}
                isFetched={isFetched}
                parseHtml={parseHtml}
                isFirstTime={!!isFirstTime.current}
                disableTranslation={disableTranslation}
                isPopper={isPopper}
                timeText={!!timeText}
                typeDropdown={TYPE_SELECT.SINGLE}
                handleSelectOption={handleSelectOption}
                isJobStatus={isJobStatus}
                additionalOptions={additionalOptions}
            />
        );
    };

    if (isPopper) {
        return (
            <DropdownPopper
                ref={refSelect}
                isCalculateWidth
                isDisable={isDisable}
                customStyle={{ maxWidth: 'auto' }}
                buttonClassName={buttonClassName}
                placement="bottom-start"
                wrapperListClass="v2-dropdown__menu content-full scrolls"
                wrapperClassName={classNames(classWrapper, 'v2-dropdown')}
                isUseToggle={isUseToggle}
                customButton={_renderButton(!!timeText, _renderText())}
                onOpen={handleOpen}
            >
                {_renderReportListDropdown()}
            </DropdownPopper>
        );
    }

    return (
        <div
            ref={refSelect}
            className={classNames(classWrapper, ' v2-dropdown', { active: isOpen })}
            onClick={handleOpen}
        >
            {_renderTitle()}
            <div className="v2-dropdown__menu content-full scrolls">{_renderReportListDropdown()}</div>
        </div>
    );
};

const compare = (prevProps, nextProps) => {
    return !(prevProps.name && nextProps.name) && prevProps.name === nextProps.name;
};

export default React.memo(SingleSelect, compare);

export default function IconTelephone({ isNormal = false, isSideMenu = false, isNoline = false }) {
    if (isSideMenu)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.96416 7.29907C4.99079 7.27378 5.01732 7.24841 5.04375 7.22298L8.16897 10.3496L8.08799 10.4294C8.00758 10.5098 7.98583 10.5783 8.00801 10.6667L8.03265 10.7372C8.29943 11.3574 8.67698 11.9101 9.10005 12.4272C9.95282 13.468 10.9125 14.3953 12.0536 15.1213C12.2983 15.2752 12.5688 15.3901 12.8232 15.531C12.9521 15.6048 13.0404 15.5829 13.1442 15.4758C13.1691 15.4503 13.194 15.4248 13.2189 15.3993L16.3523 18.5323C15.8704 19.0715 15.2632 19.2404 14.562 19.1926C13.5405 19.1244 13.0684 19.2458 12.1571 18.795C10.1332 17.7925 8.39683 16.416 6.93511 14.6851C5.85299 13.4052 4.95657 12.0127 4.36002 10.4498C4.06863 9.69477 4.32986 9.39178 4.38232 8.57523C4.41563 8.07351 4.59751 7.64578 4.96416 7.29907Z"
                    fill="#C1C9D4"
                />

                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.05296 6.64024L6.44362 6.24987C6.9573 5.74403 7.66338 5.8055 8.27695 6.40591C8.65618 6.77857 9.02883 7.1578 9.40478 7.53374L10.4977 8.62993C11.1402 9.27576 11.2004 9.96743 10.6647 10.5071C10.4802 10.6935 10.2965 10.8799 10.112 11.0651L5.87118 6.82415C6.06103 6.63231 6.25005 6.43961 6.44362 6.24987L6.05296 6.64024Z"
                    fill="#7A83A6"
                />

                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.9531 13.0736L13.3438 12.6832C13.8575 12.1773 14.5635 12.2388 15.1771 12.8392C15.5563 13.2119 15.929 13.5911 16.3049 13.9671L17.3978 15.0632C18.0404 15.7091 18.1006 16.4007 17.5648 16.9404C17.3803 17.1268 17.1967 17.3132 17.0122 17.4984L12.7713 13.2575C12.9612 13.0656 13.1502 12.8729 13.3438 12.6832L12.9531 13.0736Z"
                    fill="#7A83A6"
                />

                <path
                    d="M20 5H14C13.4477 5 13 5.44772 13 6C13 6.55228 13.4477 7 14 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5Z"
                    fill="#7A83A6"
                />

                <path
                    d="M16 8H14C13.4477 8 13 8.44772 13 9C13 9.55228 13.4477 10 14 10H16C16.5523 10 17 9.55228 17 9C17 8.44772 16.5523 8 16 8Z"
                    fill="#7A83A6"
                />
            </svg>
        );
    if (isNormal) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.82 14.9402C16.8477 14.1652 15.861 13.6957 14.9006 14.4696L14.3272 14.9373C13.9077 15.2768 13.1276 16.8632 10.1115 13.6296C7.0961 10.4001 8.89053 9.89723 9.31071 9.56064L9.88729 9.09234C10.8426 8.31672 10.4821 7.34032 9.79308 6.33523L9.37729 5.72644C8.68514 4.7237 7.93145 4.06515 6.97362 4.8396L6.45608 5.26107C6.03275 5.54849 4.84944 6.48275 4.56241 8.2576C4.21697 10.3872 5.30669 12.8259 7.80332 15.5016C10.2968 18.1785 12.7382 19.5231 15.0508 19.4997C16.9727 19.4804 18.1334 18.5192 18.4964 18.1697L19.0158 17.7477C19.9712 16.9738 19.3776 16.1859 18.4047 15.4091L17.82 14.9402Z"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
    if (isNoline) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.96416 6.29905C5.99079 6.27376 6.01732 6.24839 6.04375 6.22296L9.16897 9.34959L9.08799 9.42936C9.00758 9.50978 8.98583 9.5783 9.00801 9.66668L9.03265 9.73719C9.29943 10.3574 9.67698 10.9101 10.1 11.4272C10.9528 12.468 11.9125 13.3953 13.0536 14.1213C13.2983 14.2752 13.5688 14.3901 13.8232 14.531C13.9521 14.6048 14.0404 14.5828 14.1442 14.4758C14.1691 14.4503 14.194 14.4247 14.2189 14.3992L17.3523 17.5322C16.8704 18.0714 16.2632 18.2404 15.562 18.1926C14.5405 18.1244 14.0684 18.2458 13.1571 17.795C11.1332 16.7924 9.39683 15.416 7.93511 13.6851C6.85299 12.4052 5.95657 11.0127 5.36002 9.44983C5.06863 8.69475 5.32986 8.39177 5.38232 7.57521C5.41563 7.07349 5.59751 6.64577 5.96416 6.29905Z"
                    fill="#C1C9D4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.05295 5.64022L7.44361 5.24986C7.95729 4.74401 8.66337 4.80548 9.27693 5.40589C9.65617 5.77855 10.0288 6.15778 10.4048 6.53372L11.4977 7.62991C12.1402 8.27574 12.2004 8.96741 11.6647 9.50709C11.4802 9.69345 11.2965 9.87988 11.112 10.0651L6.87116 5.82413C7.06101 5.63229 7.25004 5.43959 7.44361 5.24986L7.05295 5.64022Z"
                    fill="#7A83A6"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.9531 12.0735L14.3438 11.6832C14.8575 11.1773 15.5635 11.2388 16.1771 11.8392C16.5563 12.2119 16.929 12.5911 17.3049 12.967L18.3978 14.0632C19.0404 14.7091 19.1006 15.4007 18.5648 15.9404C18.3804 16.1268 18.1967 16.3132 18.0122 16.4984L13.7713 12.2575C13.9612 12.0656 14.1502 11.8729 14.3438 11.6832L13.9531 12.0735Z"
                    fill="#7A83A6"
                />
            </svg>
        );
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path
                fill="#C1C9D4"
                fillRule="evenodd"
                d="M4.964 7.3l.08-.077 3.125 3.127-.081.08c-.08.08-.102.148-.08.237l.025.07c.266.62.644 1.173 1.067 1.69.853 1.041 1.813 1.968 2.954 2.694.244.154.515.27.77.41.128.074.216.052.32-.055l.075-.077 3.133 3.133c-.482.54-1.089.708-1.79.66-1.021-.068-1.494.054-2.405-.397a16.171 16.171 0 01-5.222-4.11c-1.082-1.28-1.978-2.672-2.575-4.235-.291-.755-.03-1.058.022-1.875.034-.501.216-.93.582-1.276z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#7A83A6"
                fillRule="evenodd"
                d="M6.053 6.64l.39-.39c.514-.506 1.22-.444 1.834.156.38.373.752.752 1.128 1.128l1.093 1.096c.642.646.702 1.337.167 1.877-.185.187-.368.373-.553.558l-4.24-4.24c.189-.193.378-.385.572-.575l-.391.39zM12.953 13.074l.39-.39c.514-.507 1.22-.445 1.834.155.38.373.752.752 1.128 1.128l1.093 1.096c.642.646.703 1.338.167 1.877-.185.187-.368.373-.553.558l-4.24-4.24c.19-.192.378-.385.572-.575l-.39.39z"
                clipRule="evenodd"
            ></path>
            <path fill="#7A83A6" d="M20 5h-6a1 1 0 100 2h6a1 1 0 100-2zM16 8h-2a1 1 0 100 2h2a1 1 0 100-2z"></path>
        </svg>
    );
}

import { ONE_HUNDRED_PERCENT } from 'app/const/addons';

export const calculateViewPercentage = ({ time = 0, duration = 0 }) => {
    if (!duration || isNaN(duration) || time < 0) return 0;
    if (time >= duration) return ONE_HUNDRED_PERCENT;
    return Math.floor((time / duration) * ONE_HUNDRED_PERCENT);
};

export const regexLinkWistiaEmbed = (link = '') => {
    return link.match(/embed\/iframe\/([a-zA-Z0-9]+)/);
};

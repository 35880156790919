export default function IconDoubleStep({ isPrev = false, isPurple = false }) {
    if (isPrev)
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.5 5.5L5 12L11.5 18.5"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M17.5 5.5L11 12L17.5 18.5"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </svg>
        );

    if (isPurple)
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} viewBox="0 0 25 24" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.54194 7.95923C6.2881 7.70539 6.2881 7.29383 6.54194 7.03999C6.79578 6.78615 7.20734 6.78615 7.46118 7.03999L11.9612 11.54C12.215 11.7938 12.215 12.2054 11.9612 12.4592L7.46118 16.9592C7.20734 17.2131 6.79578 17.2131 6.54194 16.9592C6.2881 16.7054 6.2881 16.2938 6.54194 16.04L10.5823 11.9996L6.54194 7.95923ZM12.5419 7.95923C12.2881 7.70539 12.2881 7.29383 12.5419 7.03999C12.7958 6.78615 13.2073 6.78615 13.4612 7.03999L17.9612 11.54C18.215 11.7938 18.215 12.2054 17.9612 12.4592L13.4612 16.9592C13.2073 17.2131 12.7958 17.2131 12.5419 16.9592C12.2881 16.7054 12.2881 16.2938 12.5419 16.04L16.5823 11.9996L12.5419 7.95923Z"
                    fill="#8D4AFC"
                />
            </svg>
        );

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5 5.5L19 12L12.5 18.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M6.5 5.5L13 12L6.5 18.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
}

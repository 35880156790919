import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import { ACTION_QUICKBOOKS_MAPPING, actionQuickBooksMapping } from 'app/const/api/V2';
import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import IconTrash from 'assets/icon/IconTrash';
import { clientQuery } from 'common/utils/ApiUtils';
import FormAdd from './FormAdd';
import Loading from './Loading';

const MappingTax = forwardRef(({ taxCode }, ref) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        dataMapping: [],
        isFirstTime: true,
        isLoading: true
    });

    useImperativeHandle(ref, () => ({ _open, _close }));
    const finalIsLoading = state.isLoading;
    const finalDataMapping = state.dataMapping || [];

    const _open = () => {
        if (state.isFirstTime) {
            _getListMappingTax();
        }
        dispatchState({ isVisible: true });
    };

    const _close = () => dispatchState({ isVisible: false });

    function _getListMappingTax() {
        clientQuery(ACTION_QUICKBOOKS_MAPPING, { method: 'GET' }, _getListMappingTaxSuccess);
    }

    function _getListMappingTaxSuccess(response) {
        dispatchState({
            isLoading: false,
            isFirstTime: false,
            dataMapping: response.data
        });
    }

    function _handleAddNew(newData) {
        dispatchState((prev) => {
            return {
                ...prev,
                dataMapping: [...prev.dataMapping, newData]
            };
        });
    }

    function _handleDelete(e, id) {
        e.stopPropagation();
        _handleDeleteItem(id);
        clientQuery(actionQuickBooksMapping(id), { method: 'PUT' });
    }

    function _handleDeleteItem(id) {
        dispatchState((prev) => {
            return {
                ...prev,
                dataMapping: [...prev.dataMapping].filter((item) => item.id !== id)
            };
        });
    }

    function _handleUpdateQuickAdd(oldId, newId) {
        dispatchState((prev) => {
            let tempList = [...prev.dataMapping];
            tempList = tempList.map((item) => {
                if (item.id === oldId) {
                    return {
                        ...item,
                        id: newId,
                        quickAdd: false
                    };
                }
                return item;
            });
            return {
                ...prev,
                dataMapping: tempList
            };
        });
    }

    function _renderListMappingTax() {
        return finalDataMapping.map((item) => {
            return (
                <div key={item.id} className="list-mapping__content">
                    <div className="col --tax-name">
                        <p className="word-break">{item.tax_name_qb}</p>
                    </div>
                    <div className="col --tag flex-1">
                        <p className="word-break">{item.name_group}</p>
                    </div>
                    <div className="col --icon flex-auto">
                        {!item.quickAdd && (
                            <div
                                onClick={(e) => _handleDelete(e, item.id)}
                                className="v2-btn-default --icon-lg --delete"
                            >
                                <IconTrash />
                            </div>
                        )}
                    </div>
                </div>
            );
        });
    }

    function _renderMapping() {
        if (finalIsLoading) {
            return <Loading />;
        }

        if (!finalIsLoading && !finalDataMapping.length) {
            return (
                <div className="list-mapping">
                    <h5 className="list-mapping__title">{t('addons:mapping_groups')}</h5>
                    <div className="tables-empty m-0">{t('addons:there_is_no_data')}</div>
                </div>
            );
        }

        return (
            <div className="list-mapping">
                <h5 className="list-mapping__title">{t('addons:mapping_groups')}</h5>
                {_renderListMappingTax()}
            </div>
        );
    }

    if (!state.isVisible) {
        return false;
    }

    return (
        <ReactModal
            isOpen
            onRequestClose={_close}
            style={{ overlay: { zIndex: 99999, background: 'transparent' } }}
            className="modal container-modal modal-mapping-tax open"
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className="modal__container large">
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('addons:mapping_tax')}</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                        <IconClose />
                    </div>
                </div>
                <div className="body-modal has-form scrolls">
                    <FormAdd
                        taxCode={taxCode}
                        dataMapping={finalDataMapping}
                        onDelete={_handleDeleteItem}
                        onUpdateQuickAdd={_handleUpdateQuickAdd}
                        onAdd={_handleAddNew}
                    />
                    {_renderMapping()}
                </div>
                <div className="footer-modal btn-close justify-end">
                    <span onClick={_close} className="v2-btn-default --transparent">
                        {t('common:cancel')}
                    </span>
                </div>
            </div>
        </ReactModal>
    );
});

export default MappingTax;

import StatusBar from 'app/components/status/statusbar';
import { GET_LIST_TIME_WINDOW_TEMPLATE } from 'app/const/api/V2';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import IconPen from 'assets/icon/IconPen';
import classNames from 'classnames';
import { clientQuery } from 'common/utils/ApiUtils';
import { convertTimeNumber } from 'common/utils/TimeUtils';
import { React, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import TimeWindowAddonsForm from './TimeWindowAddonsForm';
import { TimeWindowTableLoading } from './TimeWindowAddonsLoading';

const TimeWindowAddonsTemplates = ({ isDisable = false }) => {
    const { t } = useTranslation(['addons']);
    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: false
    });
    const refEditForm = useRef(null);
    const refStatusBar = useRef(null);
    const { data = [], isLoading } = state;

    useEffect(() => {
        if (!isDisable) {
            _handleGetListTemplate();
        }
    }, [isDisable]);

    const _handleOpenForm = () => {
        refEditForm.current.open(data);
    };

    const _handleGetListTemplate = () => {
        dispatchState({ isLoading: true });
        clientQuery(GET_LIST_TIME_WINDOW_TEMPLATE, { method: 'GET' }, _handleTemplateSuccess);
    };

    const _handleTemplateSuccess = ({ data }) => {
        dispatchState({ data, isLoading: false });
    };

    const _handleUpdate = (data = []) => {
        dispatchState({ data });
    };

    const _handleSuccess = () => {
        refStatusBar.current.showStatusBar(LIST_STATUS.SUCCESS, t('addons:update_successfully'), LIST_STATUS.SUCCESS);
    };

    const returnTime = (timeNumber) => {
        if (!timeNumber) {
            return '00:00';
        }
        const { hour, minute, midDay } = convertTimeNumber(timeNumber);
        return `${hour}:${minute} ${midDay}`;
    };

    const _renderContent = () => {
        if (!data?.length) {
            return <div className="tables-empty">{t('addons:no_template_please_add_new_window')}</div>;
        }

        return data.map((item, index) => {
            return (
                <div key={index.toString()} className="timewindown-template">
                    <div className="timewindown-template__name">{item.name}</div>
                    <div className="timewindown-template__number">
                        {`${returnTime(item.start)} - ${returnTime(item.end)}`}
                    </div>
                </div>
            );
        });
    };

    if (isLoading) {
        return <TimeWindowTableLoading />;
    }

    return (
        <div
            className={classNames('boxs boxs--power-up box-timewindow active', {
                'dp-hide': isDisable
            })}
        >
            <StatusBar ref={refStatusBar} />
            <div className="boxs__header">
                <div className="header-title">
                    <div className="title">{t('addons:custom_templates')}</div>
                </div>
                <div
                    className={classNames('v2-btn-default has-icon --grey js-edit-template', {
                        'is-disable': isDisable
                    })}
                    onClick={_handleOpenForm}
                >
                    <IconPen />
                    {t('addons:edit')}
                </div>
            </div>

            <div className="boxs__contents mt-0">
                <p className="red-default mb-4">{t('addons:custom_templates_description')}</p>
                {_renderContent()}
            </div>
            <TimeWindowAddonsForm ref={refEditForm} onHandleUpdate={_handleUpdate} onHandleSuccess={_handleSuccess} />
        </div>
    );
};

export default TimeWindowAddonsTemplates;

import { FIELDS_TYPE, OPPORTUNITIES_STATUSES } from '../../constants/types';
import BooleanCondition from './BooleanCondition';
import CountCondition from './CountCondition';
import DateCondition from './DateCondition';
import DurationCondition from './duration';
import ReferenceCondition from './references';
import SourceCondition from './SourceCondition';
import StatusesCustomer from './StatusesCustomer';
import TextCondition from './TextCondition';

const ConditionRender = ({ type, operatorId, value, onChange = () => {}, ...props }) => {
    const valuesSet = [...(value?.set?.values || [])];
    switch (type) {
        case FIELDS_TYPE.REFERENCE_USER:
        case FIELDS_TYPE.CREATED_BY:
        case FIELDS_TYPE.REFERENCE_TYPE:
            return <ReferenceCondition selected={valuesSet} operatorId={operatorId} {...props} onChange={onChange} />;
        case FIELDS_TYPE.STATUS:
            return <StatusesCustomer selected={valuesSet} onChange={onChange} />;
        case FIELDS_TYPE.STATUS_OPPORTUNITY:
            return <StatusesCustomer selected={valuesSet} options={OPPORTUNITIES_STATUSES} onChange={onChange} />;
        case FIELDS_TYPE.SOURCE_ID:
            return <SourceCondition selected={valuesSet} onChange={onChange} />;
        case FIELDS_TYPE.COUNT_TASK:
        case FIELDS_TYPE.COUNT_CALL:
        case FIELDS_TYPE.COUNT_OUTGOING_CALL:
        case FIELDS_TYPE.COUNT_SMS:
        case FIELDS_TYPE.COUNT_JOB:
        case FIELDS_TYPE.INTEGER:
            return <CountCondition operatorId={operatorId} selected={value} onChange={onChange} />;
        case FIELDS_TYPE.CREATED_DATE:
        case FIELDS_TYPE.DATE_WITHOUT_PRESENT:
        case FIELDS_TYPE.CUSTOMER_CREATED_DATE:
        case FIELDS_TYPE.LATEST_CALL_CREATED_DATE:
            return <DateCondition value={value} operatorId={operatorId} onChange={onChange} />;
        case FIELDS_TYPE.TEXT:
            return <TextCondition value={value} operatorId={operatorId} onChange={onChange} />;
        case FIELDS_TYPE.DURATION:
            return <DurationCondition value={value?.durationValue || {}} operatorId={operatorId} onChange={onChange} />;
        case FIELDS_TYPE.BOOLEAN:
            return (
                <BooleanCondition value={value} name={props.fieldName} operatorId={operatorId} onChange={onChange} />
            );
        default:
            return null;
    }
};

export default ConditionRender;

import IconEdit from 'assets/icon/IconEdit';
import IconTrash from 'assets/icon/IconTrash';
import IconArea from 'assets/icon/IconArea';
import IconRouter from 'assets/icon/IconRouter';
import IconDone from 'assets/icon/IconDone';
import IconActivity from 'assets/icon/IconActivity';
import IconPen from 'assets/icon/IconPen';
import IconFile from 'assets/icon/IconFile';
import IconFolder from 'assets/icon/IconFolder';
import IconNote from 'assets/icon/IconNote';
import IconEmail from 'assets/icon/IconEmail';
import IconSentSms from 'assets/icon/IconSentSms';
import IconSent from 'assets/icon/IconSent';

export const TABS = {
    DETAIL: 'detail',
    WORK_ORDER: 'work_order',
    INVOICE: 'invoice',
    ESTIMATE: 'estimate',
    MATERIALS: 'materials',
    DEVICES: 'devices',
    DOCUMENTS: 'documents',
    ADD_INVOICE: 'add_invoice',
    ADD_ESTIMATE: 'add_estimate',
    UNIT: 'unit',
    CHECK_LIST: 'checklist',
    SCHEDULING: 'scheduling'
};

export const TABS_REPEAT = {
    OFF: 'OFF',
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
    YEARLY: 'YEARLY'
};

export const TABS_EXCEPT = {
    OFF: 'OFF',
    MONTH: 'MONTH',
    CONDITION: 'CONDITION',
    RREQUENCE: 'RREQUENCE'
};

export const MONTHS = [
    { name: 'January', value: 6, short: 'Jan' },
    { name: 'February', value: 7, short: 'Feb' },
    { name: 'March', value: 8, short: 'Mar' },
    { name: 'April', value: 9, short: 'Apr' },
    { name: 'May', value: 10, short: 'May' },
    { name: 'June', value: 11, short: 'Jun' },
    { name: 'July', value: 12, short: 'July' },
    { name: 'August', value: 13, short: 'Aug' },
    { name: 'September', value: 14, short: 'Sep' },
    { name: 'October', value: 15, short: 'Oct' },
    { name: 'November', value: 16, short: 'Nov' },
    { name: 'December', value: 17, short: 'Dec' }
];

export const EXCEPTON_OPTIONS = {
    first: '',
    second: '',
    third: '',
    fourth: '',
    fifth: '',
    sixth: '',
    seventh: '',
    eighth: '',
    ninth: '',
    tenth: '',
    eleventh: ''
};

export const CONDITION_1 = [
    { type: 'the_1st', value: 2 },
    { type: 'the_2nd', value: 3 },
    { type: 'the_3rd', value: 4 },
    { type: 'the_last', value: 5 }
];

export const CONDITION_2 = [
    { type: 'Sun_of', value: 19 },
    { type: 'Mon_of', value: 20 },
    { type: 'Tue_of', value: 21 },
    { type: 'Wed_of', value: 22 },
    { type: 'Thu_of', value: 23 },
    { type: 'Fri_of', value: 24 },
    { type: 'Sat_of', value: 25 }
];

export const CONDITION_3 = [
    { type: 'everyweek', value: 29 },
    { type: 'everymonth', value: 30 }
];

export const MODES_MONTH = {
    DAY_WEEK: 'dayofweek',
    DAY_MONTH: 'dayofmonth'
};

export const TABS_PERIOD = {
    MODE_1_3: '1st and 3rd',
    MODE_2_4: '2nd and 4th',
    MODE_EVERY: 'EVERY'
};

export const TABS_ENDS = {
    MODE_NEVER: 'never',
    MODE_AFTER: 'afterOccurances',
    MODE_ON_DATE: 'onDate'
};

export const LIST_ACTIONS = {
    EDIT: 1,
    DELETE: 2,
    MANAGE_AREA_LIST: 3,
    MANAGE_DEVICE_TYPE: 4,
    MANAGE_DEVICE_STATUS: 5,
    MANAGE_ACTIVITY_LEVEL: 6,
    SET_DEFAULT: 7,
    EMAIL_CC: 8,
    EMAIL_BCC: 9,
    SAVE_NOTE_AS_TEMPLATE: 10,
    MANAGE_TEMPLATE: 11,
    INSERT_JOB_NOTE: 12,
    INSERT_INVOICE_NOTE: 13,
    INSERT_WORK_ORDER_NOTE: 14
};

export const LIST_MATERIAL_ACTION_OPTIONS = [
    {
        id: LIST_ACTIONS.EDIT,
        name: 'edit_material',
        value: LIST_ACTIONS.EDIT,
        class: 'items has-icon action-edit',
        icon: <IconEdit />
    },
    {
        id: LIST_ACTIONS.DELETE,
        name: 'delete_material',
        value: LIST_ACTIONS.DELETE,
        class: 'items has-icon red-default',
        icon: <IconTrash />
    }
];

export const LIST_DOCUMENT_ACTION_OPTIONS = [
    {
        id: LIST_ACTIONS.EDIT,
        name: 'edit_document',
        value: LIST_ACTIONS.EDIT,
        class: 'items has-icon action-edit',
        icon: <IconEdit />
    },
    {
        id: LIST_ACTIONS.DELETE,
        name: 'delete_document',
        value: LIST_ACTIONS.DELETE,
        class: 'items has-icon red-default',
        icon: <IconTrash />
    }
];

export const LIST_DEVICE_ACTION_OPTIONS = [
    {
        id: LIST_ACTIONS.EDIT,
        name: 'edit_device',
        value: LIST_ACTIONS.EDIT,
        class: 'items has-icon action-edit',
        icon: <IconEdit />
    },
    {
        id: LIST_ACTIONS.DELETE,
        name: 'delete_device',
        value: LIST_ACTIONS.DELETE,
        class: 'items has-icon red-default',
        icon: <IconTrash />
    }
];
export const LIST_SENTRICON_ACTION_OPTIONS = [
    {
        id: LIST_ACTIONS.EDIT,
        name: 'edit_sentricon',
        value: LIST_ACTIONS.EDIT,
        class: 'items has-icon action-edit',
        icon: <IconEdit />
    },
    {
        id: LIST_ACTIONS.DELETE,
        name: 'delete_sentricon',
        value: LIST_ACTIONS.DELETE,
        class: 'items has-icon red-default',
        icon: <IconTrash />
    }
];

export const LIST_MANAGE_ACTION_OPTIONS = [
    {
        id: LIST_ACTIONS.MANAGE_AREA_LIST,
        name: 'manage_area_list',
        value: LIST_ACTIONS.MANAGE_AREA_LIST,
        class: 'items has-icon manage-area',
        icon: <IconArea isNormal />
    },
    {
        id: LIST_ACTIONS.MANAGE_DEVICE_TYPE,
        name: 'manage_device_type',
        value: LIST_ACTIONS.MANAGE_DEVICE_TYPE,
        class: 'items has-icon manage-types',
        icon: <IconRouter isNormal />
    },
    {
        id: LIST_ACTIONS.MANAGE_DEVICE_STATUS,
        name: 'manage_device_status',
        value: LIST_ACTIONS.MANAGE_DEVICE_STATUS,
        class: 'items has-icon manage-status',
        icon: <IconDone />
    },
    {
        id: LIST_ACTIONS.MANAGE_ACTIVITY_LEVEL,
        name: 'manage_activity_level',
        value: LIST_ACTIONS.MANAGE_ACTIVITY_LEVEL,
        class: 'items has-icon manage-level',
        icon: <IconActivity isActive />
    }
];

export const LIST_TERM_NOTE_ACTION_OPTIONS = [
    {
        id: LIST_ACTIONS.EDIT,
        name: 'edit',
        value: LIST_ACTIONS.EDIT,
        class: 'items has-icon',
        icon: <IconPen />
    },
    {
        id: LIST_ACTIONS.SET_DEFAULT,
        name: 'set_default_for_all',
        value: LIST_ACTIONS.SET_DEFAULT,
        class: 'items has-icon',
        icon: <IconDone />
    }
];

export const LIST_NOTE_ACTION_OPTIONS = [
    {
        id: LIST_ACTIONS.EDIT,
        name: 'edit',
        value: LIST_ACTIONS.EDIT,
        class: 'items has-icon',
        icon: <IconPen />
    },
    {
        id: LIST_ACTIONS.SET_DEFAULT,
        name: 'set_default_for_all',
        value: LIST_ACTIONS.SET_DEFAULT,
        class: 'items has-icon',
        icon: <IconDone />
    },
    {
        id: 'line',
        name: '',
        value: '',
        class: 'lines'
    },
    {
        id: LIST_ACTIONS.SAVE_NOTE_AS_TEMPLATE,
        name: 'save_note_as_template',
        value: LIST_ACTIONS.SAVE_NOTE_AS_TEMPLATE,
        class: 'items has-icon',
        icon: <IconFolder />
    },
    {
        id: LIST_ACTIONS.MANAGE_TEMPLATE,
        name: 'manage_template',
        value: LIST_ACTIONS.MANAGE_TEMPLATE,
        class: 'items has-icon',
        icon: <IconFile />
    },
    {
        id: LIST_ACTIONS.INSERT_JOB_NOTE,
        name: 'insert_job_note',
        value: LIST_ACTIONS.INSERT_JOB_NOTE,
        class: 'items has-icon',
        icon: <IconNote isAddNote />
    },
    {
        id: LIST_ACTIONS.INSERT_INVOICE_NOTE,
        name: 'insert_from_invoice_note',
        value: LIST_ACTIONS.INSERT_INVOICE_NOTE,
        class: 'items has-icon',
        icon: <IconNote isAddNote />
    },
    {
        id: LIST_ACTIONS.INSERT_WORK_ORDER_NOTE,
        name: 'insert_from_work_order_note',
        value: LIST_ACTIONS.INSERT_WORK_ORDER_NOTE,
        class: 'items has-icon',
        icon: <IconNote isAddNote />
    }
];

export const LIMIT_TAG = 5;

export const LIST_BUTTON_ADD_OPTIONS = [
    {
        id: LIST_ACTIONS.EMAIL_CC,
        name: 'EMAIL_CC_email',
        value: LIST_ACTIONS.EMAIL_CC,
        class: 'items js-toggle-cc-email'
    },
    {
        id: LIST_ACTIONS.EMAIL_BCC,
        name: 'EMAIL_BCC_email',
        value: LIST_ACTIONS.EMAIL_BCC,
        class: 'items js-add-bcc-email'
    }
];

export const TYPE_SEND_EMAIL_SMS = {
    SEND_EMAIL: 1,
    SEND_SMS: 2
};

export const LIST_ACTION_HEADER_IN_DETAIL_INVOICE = {
    SEND_BY_EMAIL: 1,
    SEND_BY_SMS: 2,
    SEND_BY_EMAIL_AND_SMS: 3,
    TAKE_SIGNATURE: 4,
    ADD_PAYMENT: 5,
    DELETE: 6,
    EMAIL_E_SIG: 7,
    SMS_E_SIG: 8,
    GENERATE_PDF: 9,
    GENERATE_PRINT: 10
};

export const LIST_ACTION_SEND_ACH_INVITATION = {
    SEND_BY_EMAIL: {
        id: 1,
        title: 'send_by_email',
        icon: <IconEmail />
    },
    SEND_BY_SMS: {
        id: 2,
        title: 'send_by_sms',
        icon: <IconSentSms />,
        checkPermission: true
    },
    SEND_BY_EMAIL_AND_SMS: {
        id: 3,
        title: 'send_by_email_and_sms',
        icon: <IconSent />,
        checkPermission: true
    }
};

export const MAX_LENGTH_CREW = 15;

export const TYPE_WARNING = { DELETE: 'delete', NOTIFY_TECHNICIAN: 'notify_technician' };

export const DATA_WARNING_BY_TYPE = {
    [TYPE_WARNING.DELETE]: {
        title: 'delete_job',
        description: 'confirm_delete_job_desc',
        titleButtonConfirm: 'yes'
    },
    [TYPE_WARNING.NOTIFY_TECHNICIAN]: {
        title: 'notify_technician_title',
        description: 'notify_assigned_technician',
        titleButtonConfirm: 'send',
        modalClassName: 'modal container-modal --form-no-border open',
        descriptionClassName: 'fs-14 black py-1'
    }
};

export const SERVICE_TYPE = {
    TEMPLATE: '1',
    PLAN: '2'
};

export const JOB_STATE = {
    ACTIVE: 'active',
    IN_ACTIVE: 'inactive',
    CREATING: 'creating',
    GET_DATA_FORM: 'getDataForm'
};

export const JOB_DELAY = {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
    YEAR: 'year'
};

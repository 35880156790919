import React, { forwardRef, useContext, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import StatusBar from 'app/components/status/statusbar';
import { ESTIMATE_TYPE } from 'app/modules/jobdetail/const/Estimate';
import { DEFAULT_VALUE } from 'app/modules/jobdetail/const/Invoice';
import AddInvoiceContextProvider from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';
import EstimateType from 'app/modules/jobdetail/tabs/addestimate/form/Type';
import InvoiceNumber from 'app/modules/jobdetail/tabs/addinvoice/form/info/InvoiceNumber';
import InvoiceService from 'app/modules/jobdetail/tabs/addinvoice/form/items';
import InvoiceNotes from 'app/modules/jobdetail/tabs/addinvoice/form/notes';
import InvoiceSummary from 'app/modules/jobdetail/tabs/addinvoice/form/summary';
import InvoiceTerms from 'app/modules/jobdetail/tabs/addinvoice/form/terms';
import { convertDiscount } from 'app/modules/jobdetail/tabs/editinvoice';
import IconTrash from 'assets/icon/IconTrash';

const EstimateService = forwardRef(({ tabActive, onDelete, onTriggerBtnSave = () => {} }, ref) => {
    const { t } = useTranslation();
    const { repeatSettings, onChangeDataRepeatContext } = useContext(DataRepeatContext);

    const refSummary = useRef();
    const refStatusBar = useRef(null);
    const { estimate: refEstimate, id: serviceId } = repeatSettings;

    useImperativeHandle(ref, () => ({
        getValue: _getValue
    }));

    function _getValue() {
        return refSummary.current?.getValue() || null;
    }

    const defaultData = () => {
        const dateRef = repeatSettings.start;

        if (!refEstimate) {
            return null;
        } else {
            return {
                ...DEFAULT_VALUE,
                customer_id: null,
                location_id: null,
                locationInfo: {},
                date: dateRef,
                ...refEstimate,
                id: refEstimate.id || '1',
                recurrence: {
                    ...DEFAULT_VALUE.recurrence,
                    offset: {
                        ...DEFAULT_VALUE.recurrence.offset,
                        nextDateInvoice: dateRef, // Date next invoice
                        dateIssue: dateRef // Date create invoice
                    }
                },
                deposit: { ...refEstimate.deposit, invoices: [] },
                number: serviceId ? 'invoice_number_service' : '',
                type: refEstimate.type || ESTIMATE_TYPE.BASIC,
                packages:
                    refEstimate.packages?.map((itemPackage) => {
                        const { discount: packageDiscount, deposit: packageDeposit } = itemPackage || {};
                        return {
                            ...itemPackage,
                            subtotal: itemPackage.subtotal.value,
                            total: itemPackage.total.value,
                            discount: convertDiscount(packageDiscount),
                            deposit: { ...convertDiscount(packageDeposit), invoices: [] }
                        };
                    }) || [],
                payment_terms: refEstimate.payment_terms || {}
            };
        }
    };

    function _handleRemoveEstimate(e) {
        e.stopPropagation();
        onChangeDataRepeatContext({ estimate: null });
        onDelete();
    }

    const estimateContext = defaultData();

    if (!estimateContext) {
        return false;
    }

    return (
        <div
            className={`tab-conts form-tabpane-details show-form-edit form-add-estimate ${
                tabActive ? 'tab-content-active' : ''
            }`}
        >
            <div className="container-setting-center">
                <div className="container-column form-edit-template has-form">
                    <StatusBar ref={refStatusBar} />
                    <div className="content-elm-edit header-modal">
                        <h3 className="name-tabs">{t('setting:estimate')}</h3>
                        <div
                            className="v2-btn-default has-icon --delete"
                            onClick={(e) => _handleRemoveEstimate(e)}
                            tabIndex="0"
                        >
                            <IconTrash />
                            {t('common:remove_estimate')}
                        </div>
                    </div>
                    <AddInvoiceContextProvider invoiceData={estimateContext} onUpdateButtonSave={onTriggerBtnSave}>
                        <EstimateType />
                        <InvoiceService isInvoice={false} />
                        <InvoiceSummary isInvoice={false} ref={refSummary} />
                        <InvoiceNumber isInvoice={false} display={false} />
                        {tabActive && (
                            <div className="content-elm-edit rows note-details">
                                <div className="dashboard-wrapper --main">
                                    <div className="content-top-notes">
                                        <InvoiceTerms isInvoice={false} />
                                        <InvoiceNotes isInvoice={false} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </AddInvoiceContextProvider>
                </div>
            </div>
        </div>
    );
});

export default EstimateService;

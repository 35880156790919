import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, NavLink, matchPath } from 'react-router-dom';

import { ADDONS_AI, ADDONS_CHAT_BOT_CONTENT, ADDONS_CHAT_BOT_CUSTOM_ANSWER } from 'app/config/routes';
import { ADDONS_FOOTER_OPTIONS } from 'app/const/addons';
import { addBranchPath, addPrefixPath } from 'app/const/Branch';
import IconArrow from 'assets/icon/IconArrow';
import { getPlanUser } from 'common/utils/PermissionUtils';
import ButtonAIChatBot from '../components/ButtonAIChatBot';
import ButtonPortalAIChatBot from '../components/ButtonPortalAIChatBot';
import { ADDONS_CHAT_BOT_SIDE_MENU } from '../constants';

const SideMenu = ({ aiAgent = {}, typeAgent = ADDONS_FOOTER_OPTIONS.CHATBOT_AI_SMS, onOpen = () => {} }, ref) => {
    const userProfile = useSelector(({ auth }) => auth.user.profile);
    const { isGrowthPlan, isTrial } = getPlanUser(userProfile);
    const { t } = useTranslation(['addons']);

    const isActiveAISmsChatbot = !!aiAgent[ADDONS_FOOTER_OPTIONS.CHATBOT_AI_SMS];
    const isActivePortalAIChatbot = !!aiAgent[ADDONS_FOOTER_OPTIONS.CHATBOT_AI_PORTAL] && (isGrowthPlan || isTrial);
    const isAnyAIChatbotActive = isActiveAISmsChatbot || isActivePortalAIChatbot;

    const refButtonAIChatBot = useRef(null);
    const refButtonPortalAIChatBot = useRef(null);

    useImperativeHandle(ref, () => ({ setDisableButton: _handleDisableButton }));

    const _handleDisableButton = (value) => {
        if (refButtonAIChatBot.current) {
            refButtonAIChatBot.current.setDisable(value);
        }
        if (refButtonPortalAIChatBot.current) {
            refButtonPortalAIChatBot.current.setDisable(value);
        }
    };

    return (
        <div className="sidebar-chatbot flex-column gap-16">
            <Link to={addBranchPath(ADDONS_AI)} className="v2-btn-default has-icon --grey">
                <IconArrow isPrev />
                {t('back')}
            </Link>
            {isAnyAIChatbotActive && (
                <div className="flex-column gap-4 w-100">
                    {isActiveAISmsChatbot && <ButtonAIChatBot ref={refButtonAIChatBot} onOpen={onOpen} />}
                    {isActivePortalAIChatbot && (
                        <ButtonPortalAIChatBot ref={refButtonPortalAIChatBot} onOpen={onOpen} />
                    )}
                </div>
            )}
            {isAnyAIChatbotActive && <div className="is-divider --horizontal w-100" />}
            <ul className="sidebar-left sidebar-menu">
                <span className="sidebar-menu__title black-verylight-green fw-500 fs-12">{t('ai_training_dojo')}</span>
                <li>
                    <NavLink
                        className="sidebar-items flexcenter"
                        to={addBranchPath(ADDONS_CHAT_BOT_CONTENT.replace(':type', typeAgent))}
                    >
                        <p className="txt-ellipsis">{t('content')}</p>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className="sidebar-items flexcenter"
                        to={addBranchPath(ADDONS_CHAT_BOT_CUSTOM_ANSWER.replace(':type', typeAgent))}
                    >
                        <p className="txt-ellipsis">{t('custom_answers')}</p>
                    </NavLink>
                </li>
            </ul>
            <div className="is-divider --horizontal w-100" />
            <ul className="sidebar-left sidebar-menu">
                <span className="sidebar-menu__title black-verylight-green fw-500 fs-12">{t('settings')}</span>
                {ADDONS_CHAT_BOT_SIDE_MENU.SETTINGS.map(({ id, title, link, type }) => {
                    const finalLink = link.replace(':type', type);
                    return (
                        <li key={id}>
                            <NavLink
                                className={classNames('sidebar-items flexcenter', {
                                    'is-disable':
                                        !aiAgent[type] ||
                                        (type === ADDONS_FOOTER_OPTIONS.CHATBOT_AI_PORTAL && !isGrowthPlan)
                                })}
                                to={addBranchPath(finalLink)}
                                isActive={(_, location) =>
                                    matchPath(location.pathname, { path: addPrefixPath(finalLink), exact: true })
                                }
                            >
                                <p className="txt-ellipsis">{t('ai_agent_title', { title: t(title) })}</p>
                            </NavLink>
                        </li>
                    );
                })}
            </ul>
            <div className="is-divider --horizontal w-100" />
            <ul className="sidebar-left sidebar-menu">
                <span className="sidebar-menu__title black-verylight-green fw-500 fs-12">{t('reports')}</span>
                {ADDONS_CHAT_BOT_SIDE_MENU.REPORTS.map(({ id, title, link, type }) => {
                    const finalLink = link.replace(':type', type);
                    return (
                        <li key={id}>
                            <NavLink
                                className={classNames('sidebar-items flexcenter', {
                                    'is-disable': type === ADDONS_FOOTER_OPTIONS.CHATBOT_AI_PORTAL && !isGrowthPlan
                                })}
                                to={addBranchPath(finalLink)}
                                isActive={(_, location) =>
                                    matchPath(location.pathname, { path: addPrefixPath(finalLink), exact: true })
                                }
                            >
                                <p className="txt-ellipsis">{t('ai_agent_title', { title: t(title) })}</p>
                            </NavLink>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default forwardRef(SideMenu);

import React, { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { clientQuery } from 'common/utils/ApiUtils';
import { GET_DETAIL_ACCOUNT_INVOICE } from 'app/const/api/V2';
import { APP_URL } from 'app/const/URL';
import { Link } from 'react-router-dom';
import { SETTINGS_INVOICES, SETTINGS_USERS } from 'app/config/routes';
import IconArrow from 'assets/icon/IconArrow';
import IconViewPdf from 'assets/icon/IconViewPdf';
import IconPrint from 'assets/icon/IconPrint';
import { useSelector } from 'react-redux';
import Loading from './components/Loading';
import { reducer } from 'app/const/Reducer';
import { METHOD_CONVERT } from 'app/const/App';
import { useTranslation } from 'react-i18next';
import MainHeaderSettings from 'app/modules/settings/components/MainHeaderSettings';
import { addBranchPath } from 'app/const/Branch';

export default function SettingInvoiceDetail({ children }) {
    const companyInfo = useSelector(({ auth }) => auth.user.company);
    const [state, dispatchState] = useReducer(reducer, { isLoading: true, invoiceData: {} });
    const { id: stripeInvoiceId } = useParams();
    const { t } = useTranslation();
    const finalInvoiceData = state.invoiceData || {};
    const idInvoice = finalInvoiceData.id;

    const SETTINGS_USERS_URL = `${APP_URL}${addBranchPath(SETTINGS_USERS)}`;

    useEffect(() => {
        if (stripeInvoiceId) {
            clientQuery(
                GET_DETAIL_ACCOUNT_INVOICE,
                { method: 'GET', data: { id: stripeInvoiceId } },
                _getInvoiceAccountSuccess
            );
        }
    }, []);

    function _getInvoiceAccountSuccess(response) {
        dispatchState({
            invoiceData: response.data,
            isLoading: false
        });
    }

    function _handleViewPDF(e) {
        e.stopPropagation();
        window.open(finalInvoiceData.pdf, '_blank');
    }

    function _handlePrint(e) {
        e.stopPropagation();
        window.open(finalInvoiceData.print, '_blank');
    }

    function _renderListItems() {
        return finalInvoiceData.items.map((item, index) => {
            const { name, description } = item;
            return (
                <div key={index.toString()} className="rows">
                    <div className="col --description">
                        {name && (
                            <p className="col-label" title={name}>
                                {name}
                            </p>
                        )}
                        {description && (
                            <p className="col-label" title={description}>
                                {description}
                            </p>
                        )}
                    </div>
                    <div className="col --qty col-sm">
                        <p className="col-label">{item.qty}</p>
                    </div>
                    <div className="col --amount --label-r">
                        <p className="col-label">{item.amount}</p>
                    </div>
                </div>
            );
        });
    }

    function _renderCompanyInfo() {
        const companyService = companyInfo.service || {};

        return (
            <div className="details">
                <p>{t('setting:this_charge_will_appear_credit')}</p>
                <p className="mt-4">{companyInfo.name}</p>
                <p className="mt-4">{`${companyService.address} ${companyService.city} ${companyService.state} ${companyService.zip}`}</p>
            </div>
        );
    }

    function _renderBillTo() {
        const userDetail = finalInvoiceData.user || {};

        return (
            <div className="rows --multi">
                <div className="txt">{t('customers:bill_to')}</div>
                <div className="details">
                    <p>{userDetail.full_name}</p>
                    <p className="mt-1">{companyInfo.name}</p>
                </div>
            </div>
        );
    }

    return (
        <>
            <MainHeaderSettings />
            <div className="wrapper-columns">
                {children}
                {state.isLoading ? (
                    <Loading />
                ) : (
                    <div className="contents-pages container-column container-print invoices-details">
                        <div className="title-header">
                            <Link
                                to={addBranchPath(SETTINGS_INVOICES)}
                                className="v2-btn-default --transparent --icon-lg mr-1"
                            >
                                <IconArrow isPrev />
                            </Link>
                            <h3 className="title-header__label">{t('setting:invoices')}</h3>
                        </div>
                        <div className="scrolls box-auto">
                            <div className="boxs-wrapper">
                                <div className="boxs">
                                    <div className="boxs__header">
                                        <h3 className="title pr-1">{idInvoice}</h3>
                                        <div className="btn-action">
                                            {finalInvoiceData.pdf && (
                                                <div
                                                    onClick={(e) => _handleViewPDF(e)}
                                                    className="v2-btn-default --icon-lg"
                                                >
                                                    <IconViewPdf />
                                                </div>
                                            )}
                                            {finalInvoiceData.print && (
                                                <div
                                                    onClick={(e) => _handlePrint(e)}
                                                    className="v2-btn-default --icon-lg"
                                                >
                                                    <IconPrint />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="boxs__content">
                                        <div className="rows">
                                            <h3 className="title">{t('setting:thank_you_for_being_a_customer')}</h3>
                                            <p className="fs-14 mt-1">
                                                {t('setting:this_is_an_invoice_for_your_gorilladesk_account')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="boxs__content pt-2 pb-2">
                                        <div className="rows --multi">
                                            <div className="txt">{t('setting:summary')}</div>
                                            <div className="details">
                                                <p>
                                                    {t('setting:summary_card_info', {
                                                        accountNumber: finalInvoiceData.account_number,
                                                        amount: finalInvoiceData.amount
                                                    })}
                                                </p>
                                                <p className="mt-4">
                                                    <a href={SETTINGS_USERS_URL}>
                                                        {finalInvoiceData?.user?.full_name}{' '}
                                                        {t('setting:is_the_account_owner', {
                                                            setting_users: SETTINGS_USERS_URL
                                                        })}
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="rows --multi">
                                            <div className="txt">{t('setting:invoice')}</div>
                                            <div className="details">{finalInvoiceData.date_create}</div>
                                        </div>
                                        <div className="rows --multi">
                                            <div className="txt">{t('report:invoice_#')}</div>
                                            <div className="details">{idInvoice}</div>
                                        </div>
                                        <div className="rows --multi">
                                            <div className="txt">{t('setting:important_note')}</div>
                                            {_renderCompanyInfo()}
                                        </div>
                                        {_renderBillTo()}
                                    </div>
                                    <div className="boxs__footer">
                                        <div className="tables">
                                            <div className="rows --fixed --header">
                                                <div className="col --description">
                                                    <p className="col-label">{t('setting:description')}</p>
                                                </div>
                                                <div className="col --qty col-sm">
                                                    <p className="col-label">{t('jobDetail:quantity')}</p>
                                                </div>
                                                <div className="col --amount --label-r">
                                                    <p className="col-label">{t('common:price')}</p>
                                                </div>
                                            </div>
                                            <div className="tables-list">{_renderListItems()}</div>
                                        </div>
                                        <div className="wrap-rows --amount">
                                            <div className="rows">
                                                <div className="txt">{t('setting:amount_paid')}</div>
                                                <div className="details">
                                                    <p className="fw-600">{finalInvoiceData.amount_paid}</p>
                                                </div>
                                            </div>
                                            <div className="rows">
                                                <div className="txt">{t('report:total')}:</div>
                                                <div className="details">
                                                    <div className="info-card">
                                                        <div className="card-number">
                                                            <p className="card-number__label">{t('setting:stripe')}</p>
                                                            <div className="number">
                                                                <p className="number__dots">{METHOD_CONVERT}</p>
                                                                <p className="number__digit txt-ellipsis">
                                                                    {finalInvoiceData.account_number}
                                                                </p>
                                                            </div>
                                                            <span className="date">
                                                                {finalInvoiceData.expire_month}/
                                                                {finalInvoiceData.expire_year}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

import React from 'react';
import { useTranslation } from 'react-i18next';

import ResourceGroupLabel from './ResourceGroupLabel';
import ResourceTimeEdit from './ResourceTimeEdit';

const ResourceLabel = ({ resource, onChangeTime = () => {} }) => {
    const { t } = useTranslation();
    const resourceData = resource?._resource || {};
    const isEditing = resourceData.extendedProps?.isEditing;
    const extendedProps = resourceData.extendedProps || {};

    if (extendedProps.isResourceEmpty) return <div style={{ marginLeft: '-14px' }}>{resourceData.title}</div>;
    if (!resourceData.parentId)
        return (
            <ResourceGroupLabel
                title={resourceData.title}
                isEditing={isEditing}
                extendedProps={extendedProps}
                onBlur={onChangeTime}
            />
        );
    return (
        <>
            <div className="col col-date flexcenter gap-4">
                <span className="dots" />
                <p className="col-label fw-500">{t('common:clock_in_out')}</p>
            </div>
            <ResourceTimeEdit isEditing={isEditing} {...extendedProps} onBlur={onChangeTime} />
        </>
    );
};

export default ResourceLabel;

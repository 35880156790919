import GdConfirm from 'app/components/confirm';
import GdGridView from 'app/components/grid/GdGridView';
import { COMMON, KEY_CUSTOMER_DETAIL_CONTACT_LOCAL_STORAGE, LIST_PAGE_SIZE } from 'app/const/App';
import { getGirdColumnsCustomerContacts } from 'app/const/customer/CustomerContacts';
import { LIST_BUTTON_ACTION_CUSTOMERS_DETAIL_CONTACT } from 'app/const/Customers';
import CustomerPagination from 'app/modules/customer/components/CustomerPagination';
import ListButtonAction from 'app/modules/customer/components/ListButtonAction';
import ContactForm from 'app/modules/customer/detail/contacts/components/ContactForm';
import {
    createContact,
    deleteContacts,
    getListContact,
    updateContact
} from 'common/redux/actions/customers/contactAction';
import { removeLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    getLocalStorageCustomerPage,
    handleAbortController,
    transformToCustomerType
} from 'app/modules/customer/utils';
import StatusBar from 'app/components/status/statusbar';
import { reducer } from 'app/const/Reducer';
import { CustomerDetailContext } from '../context/CustomerDetailContext';
import classNames from 'classnames';
import CheckBoxHeader from 'app/modules/report/components/CheckBoxHeader';
import { _getDefaultListPhones } from '../../utils';
import { clientQuery } from 'common/utils/ApiUtils';
import { getUrlCustomerContact } from 'app/const/Api';

const getInitState = () => {
    const dataLocal = getLocalStorageCustomerPage({
        namePage: KEY_CUSTOMER_DETAIL_CONTACT_LOCAL_STORAGE,
        key: COMMON.ALL
    });

    return {
        listContacts: [],
        isLoading: true,
        checkedItems: {
            is_check_all: false,
            ids: []
        },
        total: 0,
        currentPage: dataLocal[COMMON.CURRENT_PAGE],
        params: dataLocal[COMMON.PARAMS],
        isFirstTime: true
    };
};

export default function CustomerContacts() {
    const { t } = useTranslation(['customers']);
    const dispatch = useDispatch();
    const refContactForm = useRef(null);
    const refConfirm = useRef(null);
    const refNotification = useRef(null);
    const refStatusBar = useRef(null);
    const { id: customerId } = useParams();
    const [state, dispatchState] = useReducer(reducer, getInitState());
    const { params: currentParams, currentPage, listContacts, checkedItems, total: totalItem, isLoading } = state;
    const { offset: currentOffset, limit: currentLimit } = currentParams;
    const { ids: idsChecked, is_check_all: isFullCheck } = checkedItems;
    const isInLastPage = totalItem - currentOffset <= currentLimit;
    const isInFirstPage = totalItem <= currentLimit;
    const { reloadCustomerPage, _handleUpdateContact } = useContext(CustomerDetailContext);
    const abortController = useRef(null);

    useEffect(() => {
        if (!isLoading) dispatchState({ isLoading: true });
        _getListContact(currentParams);
        return () => {
            handleAbortController(abortController);
            removeLocalStorage(KEY_CUSTOMER_DETAIL_CONTACT_LOCAL_STORAGE);
        };
    }, [customerId, reloadCustomerPage]);

    const _handleChangePageOrPageSize = (pagerData) => {
        _getListContact({
            ...currentParams,
            offset: pagerData.offset,
            limit: pagerData.limit,
            currentPage: pagerData.currentPage
        });
    };

    const _getListContactsSuccess = (response) => {
        const listContacts = response.data;

        const newListContacts = listContacts.map((contact) => {
            return {
                id: contact.id,
                customer: transformToCustomerType(contact),
                email: contact.email,
                phones: contact.phones,
                title: contact.title || ''
            };
        });
        dispatchState({
            isFirstTime: false,
            isLoading: false,
            total: response.total,
            listContacts: newListContacts
        });
    };

    const _getListContactsFailure = ({ isAborted = false }) => {
        if (isAborted) return;
        dispatchState({ ...state, isLoading: false });
    };

    const _getListContact = (params) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();

        const newCurrentPage = params.currentPage;
        const newParams = params;
        delete newParams.currentPage;
        setLocalStorage(KEY_CUSTOMER_DETAIL_CONTACT_LOCAL_STORAGE, {
            [COMMON.PARAMS]: newParams,
            [COMMON.CURRENT_PAGE]: newCurrentPage
        });
        !state.isFirstTime &&
            dispatchState({
                isLoading: true,
                checkedItems: { is_check_all: false, ids: [] },
                params: newParams,
                currentPage: newCurrentPage
            });

        clientQuery(
            getUrlCustomerContact(customerId),
            { data: params, method: 'GET', abortController: abortController.current },
            _getListContactsSuccess,
            _getListContactsFailure
        );
    };

    const _handleSaveContact = () => {
        const paramsContact = refContactForm.current.getDataFrom();
        if (!paramsContact) return false;
        paramsContact.customer_id = customerId;

        if (paramsContact.contact_id) {
            dispatch(updateContact(paramsContact, _updateContactSuccess, actionContactsFailure));
        } else {
            dispatch(createContact(paramsContact, _createContactSuccess, actionContactsFailure));
        }
    };

    const _updateContactSuccess = (response) => {
        const contactId = response.data.id;
        const listPhones = response.data.phones;
        let newDataContact = null;

        const listPhone = listPhones.map((phone) => {
            return {
                contact_id: contactId,
                ...phone
            };
        });

        refStatusBar.current.showStatusBar('edit_success', t('customers:edit_contact_success'), COMMON.SUCCESS);
        const newListContact = listContacts.map((contact) => {
            if (contact.id === contactId) {
                const contactUpdate = {
                    id: contactId,
                    customer: transformToCustomerType(response.data),
                    email: response?.data?.email || '',
                    title: response?.data?.title || '',
                    phones: listPhone
                };
                newDataContact = contactUpdate;
                return contactUpdate;
            }
            return contact;
        });

        dispatchState({ listContacts: newListContact });
        if (newDataContact) _handleUpdateContact(newDataContact, 'update');
        refContactForm.current.closeModal();
    };

    const _createContactSuccess = (response) => {
        const data = response.data;
        const newContact = {
            id: data.id,
            customer: transformToCustomerType(data),
            email: data.email,
            phones: data.phones,
            title: data.title || ''
        };
        const newListContact = [...listContacts];
        newListContact.unshift(newContact);
        if (newListContact.length > currentLimit) newListContact.pop();
        refStatusBar.current.showStatusBar('create_success', t('customers:add_new_contact_success'), COMMON.SUCCESS);
        dispatchState({
            checkedItems: {
                is_check_all: false,
                ids: idsChecked
            },
            listContacts: newListContact,
            total: totalItem + 1
        });
        _handleUpdateContact(newContact, 'create');
        refContactForm.current.closeModal();
    };

    const handleAddContact = () => {
        refContactForm.current.showModal();
    };

    const _handleEditContact = ({ row }) => {
        const contactSelected = listContacts.find((item) => item.customer.id === row.customer.id);
        if (contactSelected) {
            const newObjectContact = {
                ...contactSelected,
                phones: _getDefaultListPhones(contactSelected.phones)
            };
            refContactForm.current.showModal(newObjectContact);
        }
    };

    const handleClickButton = (value) => {
        if (!idsChecked.length) return refNotification.current.open(value);
        refConfirm.current.open(value);
    };

    // Click button confirm delete
    const onConfirm = (value) => {
        switch (value) {
            case COMMON.DELETED:
                deleteCustomerDetailContact(idsChecked);
                break;
            default:
                break;
        }
    };

    const deleteCustomerDetailContact = (ids) => {
        dispatchState({
            listContacts: listContacts.filter((item) => !idsChecked.includes(item.id)),
            checkedItems: { is_check_all: false, ids: [] },
            total: totalItem - ids.length
        });
        _handleUpdateContact(ids, 'delete');
        dispatch(deleteContacts({ customer_id: customerId, ids }, deleteContactsSuccess, actionContactsFailure));
    };

    const deleteContactsSuccess = () => {
        refStatusBar.current.showStatusBar('delete_success', t('customers:delete_contacts_success'), COMMON.SUCCESS);
        if (!isInLastPage) {
            dispatch(
                getListContact(
                    { customer_id: customerId, ...currentParams },
                    _getListContactsSuccess,
                    _getListContactsFailure
                )
            );
        }

        if (isInLastPage && !isInFirstPage && isFullCheck) {
            _getListContact({
                ...currentParams,
                customer_id: customerId,
                offset: currentOffset - currentLimit,
                currentPage: currentPage - 1
            });
        }
    };

    const _callBackOnDeleteSuccess = (idDeleted) => {
        refStatusBar.current.showStatusBar('delete_success', t('customers:delete_contacts_success'), COMMON.SUCCESS);
        const newState = {
            listContacts: listContacts.filter((item) => item.id !== idDeleted),
            total: totalItem - 1,
            checkedItems: {
                is_check_all: false,
                ids: idsChecked.filter((id) => id !== idDeleted)
            }
        };

        const isEmptyData = newState.listContacts.length === 0;
        if (!isEmptyData && isInLastPage) {
            newState.checkedItems.is_check_all = newState.listContacts.length === newState.checkedItems.ids.length;
        }

        dispatchState({ ...newState });
        _handleUpdateContact([idDeleted], 'delete');
        const paramsGetList = { customer_id: customerId, ...currentParams };
        if (!isInLastPage) dispatch(getListContact(paramsGetList, _getListContactsSuccess, _getListContactsFailure));
        if (isInLastPage && !isInFirstPage && isEmptyData) {
            _getListContact({
                ...paramsGetList,
                offset: currentOffset - currentLimit,
                currentPage: currentPage - 1
            });
        }
    };

    const actionContactsFailure = (error) => {
        dispatchState({ ...state });
        refStatusBar.current.showStatusBar(
            'action_failure',
            error?.message || t('customers:edit_contact_fail'),
            COMMON.ERROR
        );
        refContactForm.current.closeModal();
        refConfirm.current.close();
    };

    const _renderActionHeader = () => {
        return (
            <div className="header --filter">
                <div className={classNames('header__left flex-1', { 'is-disable': !idsChecked.length })}>
                    <CheckBoxHeader checkedItems={checkedItems} isShowTotal total={totalItem} />
                    <ListButtonAction
                        list={LIST_BUTTON_ACTION_CUSTOMERS_DETAIL_CONTACT}
                        fileTranslation="customers"
                        onSubmit={handleClickButton}
                    />
                </div>
                <div className="v2-btn-main btn-modal --bg-green" onClick={handleAddContact}>
                    {t('customers:new_contact')}
                </div>
            </div>
        );
    };

    const onChangeStateCheckedItems = (stateCheckedItems) => {
        dispatchState({ checkedItems: stateCheckedItems });
    };

    return (
        <>
            <div className="wrapper-columns">
                <div className="container-print contents-pages contact-page">
                    <div className="wrap-tables flex-column relative">
                        <StatusBar ref={refStatusBar} />
                        {_renderActionHeader()}
                        <GdGridView
                            isLoading={isLoading}
                            msgEmpty={t('no_contacts')}
                            classTable="has-checkbox has-footer scrolls-x"
                            content={listContacts}
                            showCheckBox
                            fileTranslation={'customers'}
                            handleClick={_handleEditContact}
                            checkedItems={state.checkedItems}
                            onChangeStateCheckedItems={onChangeStateCheckedItems}
                            {...getGirdColumnsCustomerContacts({ customerId })}
                            isScroll
                        />
                        <CustomerPagination
                            currentPageSize={state.params?.limit}
                            pageSize={LIST_PAGE_SIZE}
                            currentPage={currentPage}
                            total={totalItem}
                            handleChangePageOrPageSize={_handleChangePageOrPageSize}
                        />
                        <ContactForm
                            ref={refContactForm}
                            onSaveContact={_handleSaveContact}
                            onDeleteSuccess={_callBackOnDeleteSuccess}
                            customerId={customerId}
                        />
                        <GdConfirm
                            ref={refConfirm}
                            title={t('common:confirm')}
                            message={t('setting:are_you_sure_delete_this_item')}
                            listButton={{ cancel: true, confirm: true }}
                            onConfirm={onConfirm}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import CustomerSendByEmail from '../../accounts/components/CustomerSendByEmail';

const EmailPreviewBox = ({
    customerId = '',
    emailContent = null,
    resetPasswordToken = null,
    onShowStatus = () => {},
    onUpdatePortal = () => {}
}) => {
    const { t } = useTranslation();
    const refEmailPreview = useRef(null);

    const _handlePreview = () => {
        refEmailPreview.current._open();
    };

    return (
        <>
            <div className="wrap-portal__box flex-betweenitems">
                {t('customers:send_a_reset_password')}
                <div className="btn-modal v2-btn-default --grey ml-6 flex-auto" onClick={_handlePreview}>
                    {t('customers:email_preview')}
                </div>
            </div>
            <CustomerSendByEmail
                ref={refEmailPreview}
                customerId={customerId}
                emailContent={emailContent}
                resetToken={resetPasswordToken}
                onShowStatus={onShowStatus}
                onUpdatePortal={onUpdatePortal}
            />
        </>
    );
};

export default EmailPreviewBox;

import React from 'react';

const LoadingHeader = () => {
    return (
        <div className="wrap-loading header --addon">
            <div className="header__back v2-btn-default loading --grey btn-x-sm">
                <div className="loading --animation --line --full"></div>
            </div>
        </div>
    );
};

export default LoadingHeader;

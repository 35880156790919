import GdButton from 'app/components/button';
import GdConfirm from 'app/components/confirm';
import GdImage from 'app/components/img';
import { COMMON, MAX_UPLOAD_FILE_SIZE } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { DEFAULT_COMPANY_LOGO } from 'app/const/Settings';
import { uploadLogo } from 'common/redux/actions/settings/companyAction';
import { imageType } from 'common/utils/FileUtils';
import React, { useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export default function CompanyLogo({
    data,
    onDisableSave = () => {},
    onUploadLogoSuccess = () => {},
    onDeleteLogoSuccess = () => {}
}) {
    const { t } = useTranslation(['setting']);
    const [state, dispatchState] = useReducer(reducer, {
        url: data.logo,
        object_key: '',
        object_tag: '',
        isLoading: false,
        error: ''
    });
    const dispatch = useDispatch();
    const refConfirmDeleteLogo = useRef(null);
    const refUpload = useRef(null);

    const defaultLogo = data.default_logo;

    const handleUploadClick = () => {
        refUpload.current.click();
    };

    const handleUpload = (e) => {
        if (e.target.files.length < 1) return false;
        dispatchState({ ...state, isLoading: true, error: '' });
        const imgSelected = e.target.files[0];
        const fileName = imgSelected.name;
        if (imageType.includes(imgSelected.type)) {
            const reader = new FileReader();
            reader.readAsDataURL(imgSelected);
            if (imgSelected.size <= MAX_UPLOAD_FILE_SIZE) {
                if (imgSelected) {
                    onDisableSave(true);
                    reader.onloadend = function () {
                        const params = {
                            name: fileName,
                            imgSelected: imgSelected,
                            url: reader.result,
                            item_id: imgSelected.lastModified,
                            type: COMMON.LOGO
                        };
                        dispatch(uploadLogo(params, uploadLogoSuccess, uploadLogoFailure));
                    };
                }
            } else {
                _showAlertFileError(t('setting:just_support_img_2mb', { fileName }));
            }
        } else {
            _showAlertFileError(t('setting:invalid_upload_image', { fileName }));
        }
    };

    const _showAlertFileError = (message) => {
        dispatchState({ isLoading: false });
        alert(message);
    };

    const uploadLogoFailure = () => {
        dispatchState({ ...state, isLoading: false });
    };

    const onDeleteLogo = (e) => {
        e && e.preventDefault();
        refConfirmDeleteLogo.current.open();
    };

    const onConfirmDeleteLogo = () => {
        refUpload.current.value = '';
        dispatchState({
            url: defaultLogo,
            object_key: '',
            object_tag: ''
        });
        onDeleteLogoSuccess();
    };

    const uploadLogoSuccess = ({ url, object_key, object_tag, public_url }) => {
        onUploadLogoSuccess(public_url, object_key, object_tag);
        dispatchState({
            url: url,
            object_key: object_key,
            object_tag: object_tag,
            isLoading: false
        });
    };

    return (
        <div className="rows --edit-logo has-divider flex-betweenitems">
            <div className="logo flex-1">
                {state.url === DEFAULT_COMPANY_LOGO ? (
                    <DefaultLogo />
                ) : (
                    <GdImage
                        className="logo-img"
                        src={state.url}
                        alt={t('setting:logo_company')}
                        width={125}
                        height={70}
                        isLoading={state.isLoading}
                    />
                )}
            </div>
            <div className="upload-img ml-2">
                <label className="v2-btn-default" onClick={handleUploadClick}>
                    {t('common:upload_new_logo')}
                </label>
                <input
                    onChange={handleUpload}
                    type="file"
                    ref={refUpload}
                    className="dp-hide"
                    accept={COMMON.IMAGE_TYPES}
                />
                <input type="hidden" name={COMMON.OBJECT_KEY} value={state.object_key} />
                <input type="hidden" name={COMMON.OBJECT_TAB} value={state.object_tag} />
            </div>

            {state.url !== defaultLogo && (
                <GdButton
                    className="v2-btn-default has-bg-red ml-2"
                    onClick={onDeleteLogo}
                    title={t('setting:delete_logo')}
                />
            )}

            <GdConfirm
                ref={refConfirmDeleteLogo}
                title={t('common:confirm')}
                message={t('setting:are_you_sure_delete_this_logo')}
                listButton={{ confirm: true, cancel: true }}
                onConfirm={onConfirmDeleteLogo}
                onCancel={() => {}}
            />
        </div>
    );
}

export const DefaultLogo = () => {
    const { t } = useTranslation();
    return (
        <div className="flex-column gap-5 default-image text-center">
            <h3 className="fs-16">{t('setting:default_logo')}</h3>
            <div className="fs-11 fw-500 grey-sort">{t('setting:replace_in_your_settings')}</div>
        </div>
    );
};

import { LIST_TABLE_ITEM_TYPE } from 'app/const/App';

export const getGridColumnsMultipleBranchManage = () => {
    const header = {
        avatar: {
            title: 'avatar',
            style: 'col col-company'
        },
        name: {
            title: 'branch_name',
            style: 'col col-lg'
        },
        company_name: {
            title: 'company_name',
            style: 'col col-lg'
        },
        email: {
            title: 'email',
            style: 'col col-email'
        },
        phone: {
            title: 'phone',
            style: 'col col-phone'
        },
        plan: {
            title: 'plan',
            style: 'col col-lg'
        },
        assigned_users: {
            title: 'assigned_users',
            style: 'col col-xl'
        },
        color: {
            title: 'branch_color',
            style: 'col col-md'
        },
        edit: {
            title: '',
            style: 'col --menu'
        }
    };
    const columns = [
        {
            id: 'avatar',
            title: 'avatar',
            status: true
        },
        {
            id: 'name',
            title: 'branch_name',
            status: true
        },
        {
            id: 'company_name',
            title: 'company_name',
            status: true
        },
        {
            id: 'email',
            title: 'email',
            status: true
        },
        {
            id: 'phone',
            title: 'phone',
            status: true
        },
        {
            id: 'plan',
            title: 'plan',
            status: true
        },
        {
            id: 'assigned_users',
            title: 'assigned users',
            status: true
        },
        {
            id: 'color',
            title: 'branch_color',
            status: true
        },
        {
            id: 'edit',
            title: '',
            status: true
        }
    ];
    const contentConfig = {
        avatar: {
            title: 'avatar',
            style: 'col col-company',
            type: LIST_TABLE_ITEM_TYPE.AVATAR_MULTIPLE_BRANCH
        },
        name: {
            title: 'branch_name',
            style: 'col col-lg'
        },
        company_name: {
            title: 'company_name',
            style: 'col col-lg'
        },
        email: {
            title: 'email',
            style: 'col col-email',
            type: LIST_TABLE_ITEM_TYPE.EMAIL_MULTIPLE_BRANCH
        },
        phone: {
            title: 'phone',
            style: 'col col-phone txt-ellipsis',
            type: LIST_TABLE_ITEM_TYPE.PHONE_MULTILE_BRANCH
        },
        plan: {
            title: 'plan',
            style: 'col col-lg txt-ellipsis',
            type: LIST_TABLE_ITEM_TYPE.PLAN_MULTILE_BRANCH
        },
        assigned_users: {
            title: 'assigned users',
            style: 'col col-xl flexcenter gap-6',
            type: LIST_TABLE_ITEM_TYPE.ASSINGED_USERS_MULTILE_BRANCH
        },
        color: {
            title: 'branch_color',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.BRANCH_COLOR
        },
        edit: {
            title: '',
            style: 'col --menu',
            type: LIST_TABLE_ITEM_TYPE.EDIT_MULTILE_BRANCH
        }
    };

    return {
        header,
        columns,
        contentConfig
    };
};

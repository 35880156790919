import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import { LIMIT_IMG_CAPTION } from 'app/const/Settings';

export default function AttachmentDesciption({ attachmentDetail, onUpdate }) {
    const { t } = useTranslation();
    const { description: parentDescription, id: parentId, openEditDescription = false } = attachmentDetail;

    const [state, dispatchState] = useReducer(reducer, {
        description: parentDescription || '',
        id: parentId || '',
        isVisible: !!openEditDescription
    });

    const { description: finalDescription = '', id: finalId, isVisible: finalIsVisible } = state;
    const refDescription = useRef(null);

    useEffect(() => {
        if (parentDescription !== finalDescription || parentId !== finalId) {
            dispatchState({
                description: parentDescription || '',
                id: parentId,
                isVisible: false
            });
        }
    }, [parentId, parentDescription]);

    useEffect(() => {
        if (finalIsVisible && !!openEditDescription) {
            setTimeout(() => {
                refDescription.current && refDescription.current.focus();
            }, 500);
        }
    }, [finalIsVisible]);

    const _handleOpenEdit = () => {
        dispatchState({
            isVisible: true
        });
    };

    const _handleCloseEdit = () => {
        dispatchState({
            isVisible: false,
            description: parentDescription
        });
    };

    const _handleSave = (e) => {
        e.stopPropagation();

        dispatchState({
            description: finalDescription,
            isVisible: false
        });

        onUpdate(parentId, finalDescription, 'description');
    };

    const _handleChangeValue = (e) => {
        dispatchState({
            description: e.target.value
        });
    };

    return (
        <div className={`wrap-caption ${finalIsVisible ? 'has-editing' : 'has-completed'}`}>
            <div className="caption-desc fs-13 word-break">{finalDescription}</div>
            {finalIsVisible && (
                <div className="caption-form">
                    <p className="caption-form__title">{t('common:image_caption')}</p>
                    <div className="caption-form__edit">
                        <textarea
                            ref={refDescription}
                            maxLength={LIMIT_IMG_CAPTION}
                            autoFocus
                            className="field-textarea --no-border --no-resize"
                            onChange={_handleChangeValue}
                            value={finalDescription}
                        />
                        <div className="footer-form d-flex justify-end">
                            <div className="v2-btn-default --transparent" onClick={_handleCloseEdit}>
                                {t('common:cancel')}
                            </div>
                            <div onClick={_handleSave} className="v2-btn-main ml-2">
                                {t('common:save')}
                            </div>
                        </div>
                    </div>
                    <div className="caption-form__character">
                        {t('common:character_s')}
                        <span className="orange-red ml-1">{finalDescription.length}</span>
                        <span>/{LIMIT_IMG_CAPTION}</span>
                    </div>
                </div>
            )}

            <div onClick={_handleOpenEdit} className="wrap-caption__btn">
                {t(`common:${!!finalDescription ? 'edit' : 'add'}_caption`)}
            </div>
        </div>
    );
}

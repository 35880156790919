import classNames from 'classnames';
import React, { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { ICON_PORTAL_AI_SETTING } from '../constants';

const ItemIcon = ({ portalIcon = {}, onChangeValue = () => {} }) => {
    const { t } = useTranslation(['addons']);

    const refDropdownIcon = useRef(null);

    const _handleChangeIcon = (value) => {
        if (value === portalIcon.name) return;
        onChangeValue({ value, key: ICON_PORTAL_AI_SETTING.ICON });
        refDropdownIcon.current && refDropdownIcon.current._closeDropdown();
    };

    return (
        <div className="line">
            <span className="txt">{t('icon')}</span>
            <div className="field">
                <CalendarDropdown
                    ref={refDropdownIcon}
                    id="setting_select_color"
                    wrapperClassName="v2-dropdown dropdown-select-color"
                    buttonClassName="dropbtn items"
                    wrapperListClass="v2-dropdown__menu scrolls content-full"
                    customDropButton={() => (
                        <Fragment>
                            <div className="dropbtn__label gap-6">
                                {portalIcon.icon}
                                <span className="txt-ellipsis">{t(portalIcon.name)}</span>
                            </div>
                            <span className="arrow">
                                <IconArrowDown />
                            </span>
                        </Fragment>
                    )}
                >
                    <ul>
                        {ICON_PORTAL_AI_SETTING.LIST_ICON.map(({ id, icon, name }) => (
                            <li
                                key={id}
                                className={classNames('items has-icon', { active: portalIcon.id === id })}
                                onClick={() => _handleChangeIcon(name)}
                            >
                                {icon}
                                <span className="txt-ellipsis flex-1">{t(name)}</span>
                            </li>
                        ))}
                    </ul>
                </CalendarDropdown>
            </div>
        </div>
    );
};

export default ItemIcon;

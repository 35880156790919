import classNames from 'classnames';
import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import GDInputRange from 'app/components/input/InputRange';
import TabSwitcher from 'app/components/tabs/TabSwitcher';
import { DEFAULT_VALUE_SLIDER, ONE_HUNDRED_PERCENT } from 'app/const/addons';
import { USER_PROFILE } from 'app/const/api/V2';
import { DARK_MODE_INFO } from 'app/const/Header';
import { reducer } from 'app/const/Reducer';
import { PERCENT_VALUE } from 'app/const/setting/SettingGlobalOverride';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { CUSTOMER_CUSTOM_EVENTS } from 'app/modules/customer/const';
import IconLuna from 'assets/icon/IconLuna';
import IconMinus from 'assets/icon/IconMinus';
import IconPlus from 'assets/icon/IconPlus';
import IconTooltip from 'assets/icon/IconTooltip';
import { updateSettings } from 'common/redux/actions/authAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { clampValue, getDarkModeSettingsFromCSS, toggleDarkMode, applyMapTheme } from 'common/utils/FunctionUtils';

const ButtonDarkMode = ({ isExpired = false, onTurnOffDarkMode = () => {}, onError = () => {} }, ref) => {
    const dispatch = useDispatch();
    const darkModeSetting = useSelector(({ auth }) => auth.user.settings.dark_mode);
    const { t } = useTranslation(['header']);
    const [state, dispatchState] = useReducer(reducer, {
        setting: darkModeSetting || DARK_MODE_INFO.DEFAULT_SETTING,
        isVisible: false
    });
    const { setting, isVisible } = state;
    const { value, sepia, brightness, contrast, grayscale } = setting || {};

    const refDropdown = useRef(null);
    const refDebounce = useRef(null);
    const refSepia = useRef(null);
    const refBrightNess = useRef(null);
    const refContrast = useRef(null);
    const refGrayScale = useRef(null);

    useEffect(() => {
        if (!!darkModeSetting) {
            toggleDarkMode({ setting: darkModeSetting });
            dispatchState({ setting: darkModeSetting });
        }
    }, [darkModeSetting]);

    useImperativeHandle(ref, () => ({ setActive: _handleActive }));

    const _handleActive = (value) => {
        dispatchState({ setting: { [DARK_MODE_INFO.VALUE]: value ? 1 : 0, ...getDarkModeSettingsFromCSS() } });
    };

    const _handleChangeTab = (data) => {
        if (value === data) return;
        if (!data) {
            _handleUpdateProfileDarkMode({ value: 0 });
            toggleDarkMode({ setting: {} });
            dispatchState((prev) => ({ setting: { ...prev.setting, [DARK_MODE_INFO.VALUE]: 0 } }));
            onTurnOffDarkMode();
            dispatchEvent(new CustomEvent(CUSTOMER_CUSTOM_EVENTS.UPDATE_DARK_MODE, { detail: { value: false } }));
            applyMapTheme(false);
            refDropdown.current && refDropdown.current._closeDropdown();
        }
    };

    const _handleClose = () => {
        if (isVisible) {
            _handleUpdateProfileDarkMode();
            dispatchState({ isVisible: false });
        }
    };

    const _handleVisible = () => {
        dispatchState({ setting: { ...getDarkModeSettingsFromCSS(), value: 1 }, isVisible: true });
    };

    const _handleUpdateProfileDarkMode = ({ value = 1 } = {}) => {
        if (refDebounce.current) clearTimeout(refDebounce.current);
        refDebounce.current = setTimeout(() => {
            const finalSetting = {
                [DARK_MODE_INFO.ID_DARK_MODE]: {
                    [DARK_MODE_INFO.VALUE]: value ? 1 : 0,
                    ...getDarkModeSettingsFromCSS()
                }
            };
            dispatch(updateSettings(finalSetting));
            clientQuery(
                USER_PROFILE,
                {
                    data: { ...finalSetting, type: DARK_MODE_INFO.ID_DARK_MODE },
                    method: 'PUT',
                    toFormData: false
                },
                null,
                onError
            );
        }, 100);
    };

    const _handleReset = () => {
        if (!refSepia.current || !refBrightNess.current || !refContrast.current || !refGrayScale.current) return;
        refSepia.current._setValue(0);
        refBrightNess.current._setValue(ONE_HUNDRED_PERCENT);
        refContrast.current._setValue(DEFAULT_VALUE_SLIDER);
        refGrayScale.current._setValue(0);
        _handleUpdateProfileDarkMode();
    };

    if (!value) return null;
    return (
        <CalendarDropdown
            ref={refDropdown}
            wrapperClassName="v2-dropdown dropdown-dark-mode ml-1"
            wrapperListClass="v2-dropdown__menu --right"
            buttonClassName="dropbtn v2-btn-default --sm tooltip"
            customDropButton={() => (
                <Fragment>
                    <IconLuna />
                    <p className="tooltiptext bottom">{t('dark_mode')}</p>
                </Fragment>
            )}
            disable={isExpired}
            onVisible={_handleVisible}
            onHide={_handleClose}
        >
            <div className="box-dark-mode wrap-plans p-3">
                <div className="flexcenter gap-8">
                    <p className="flex-1 black fw-500">{t('dark_mode')}</p>
                    <TabSwitcher
                        activeTab={value}
                        listTabs={DARK_MODE_INFO.LIST_TAB_DARK_MODE}
                        fileTranslation="common"
                        onChange={_handleChangeTab}
                    />
                </div>
                <div className="is-line w-100 my-3" />
                <ItemSlider
                    ref={refSepia}
                    title={DARK_MODE_INFO.SEPIA}
                    value={sepia}
                    onChange={_handleUpdateProfileDarkMode}
                />
                <div className="is-line w-100 my-3" />
                <ItemSlider
                    ref={refBrightNess}
                    title={DARK_MODE_INFO.BRIGHTNESS}
                    value={brightness}
                    onChange={_handleUpdateProfileDarkMode}
                />
                <div className="is-line w-100 my-3" />
                <ItemSlider
                    ref={refContrast}
                    title={DARK_MODE_INFO.CONTRAST}
                    value={contrast}
                    onChange={_handleUpdateProfileDarkMode}
                />
                <div className="is-line w-100 my-3" />
                <ItemSlider
                    ref={refGrayScale}
                    title={DARK_MODE_INFO.GRAYSCALE}
                    value={grayscale}
                    onChange={_handleUpdateProfileDarkMode}
                />
                <div className="v2-btn-default w-100 mt-3" onClick={_handleReset}>
                    {t('reset_settings')}
                </div>
            </div>
        </CalendarDropdown>
    );
};

const ItemSlider = forwardRef(({ title, value = 0, onChange = () => {} }, ref) => {
    const { t } = useTranslation(['header']);
    const [state, dispatchState] = useReducer(reducer, { data: value });
    const { data } = state;

    useEffect(() => {
        dispatchState({ data: value });
    }, [value]);

    useImperativeHandle(ref, () => ({ _setValue: _handleChange }));

    const _handleChange = (data) => {
        const finalData = clampValue({ value: data });
        dispatchState({ data: finalData });
        toggleDarkMode({ setting: { [title]: finalData, [DARK_MODE_INFO.VALUE]: 1 } });
        onChange();
    };

    return (
        <div className="flex-column gap-8">
            <div className="flexcenter gap-4">
                <div className="flexcenter gap-4 flex-1">
                    <p className="black fw-500">
                        <span className="fw-500">{t(title)}</span>
                        <span className="grey-generic"> ({PERCENT_VALUE})</span>
                    </p>
                    <div className="d-flex tooltip">
                        <IconTooltip />
                        <p className="tooltiptext top">{t(`${title}_tooltip`)}</p>
                    </div>
                </div>
                <p className="black fw-500">{data}</p>
            </div>
            <GDInputRange
                displayOverlay
                debounceTime={0}
                max={ONE_HUNDRED_PERCENT}
                min={0}
                step={1}
                classWrapper="flexcenter gap-8"
                classContainer="progress-bar relative flex-1"
                classSlider="flex-1 slider-main"
                defaultValue={data}
                leftComponent={({ onClick }) => (
                    <Icon isDisable={!data} onClick={onClick}>
                        <IconMinus />
                    </Icon>
                )}
                rightComponent={({ onClick }) => (
                    <Icon isDisable={data === ONE_HUNDRED_PERCENT} onClick={onClick}>
                        <IconPlus />
                    </Icon>
                )}
                onChange={_handleChange}
            />
        </div>
    );
});

export const Icon = ({ isDisable = false, children, onClick = () => {} }) => (
    <span className={classNames('v2-btn-default --icon-sm', { 'is-disable': isDisable })} onClick={onClick}>
        {children}
    </span>
);

export default forwardRef(ButtonDarkMode);

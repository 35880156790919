export const LIST_TYPE_CARD = {
    STRIPE_CARD: 1,
    SQUARE_CARD: 3,
    PAYMENT_CARD: 4,
    ACH_CARD: 2
};

export const TYPE_SEARCH_ACCOUNT_ID = 1;
export const TYPE_SEARCH_ACCOUNT_EMAIL = 2;

export const LIST_ACTION_PAY = {
    STRIPE: 11,
    SQUARE: 12
};

export const LIST_REDIRECT_PAYMENT = {
    PORTAL: 2,
    PAID_SUCCESS: 9,
    PAID_ERROR: 8
};

export const LIST_STATUS_CONFIRMATION = {
    PORTAL: 2,
    UNCONFIRM: 3,
    CONFIRMED: 5,
    EXPIRED: 4
};

export const SECURED_BY_IMAGE_URL = 'https://d2e627ktfmb6xb.cloudfront.net/assets/images/secured_by.png';

import classNames from 'classnames';
import React from 'react';

import GdAvatar from 'app/components/avatar';
import { MESSAGE_ACTION_ROLE } from '../constants';
import { getDateString } from '../utils';

const ItemPortalConversation = ({ id, role, timestamp, sender: { avatar, name } = {}, content, action }) => {
    const isSendTo = role === MESSAGE_ACTION_ROLE.ASSISTANT;

    const _renderAvatar = () => {
        return (
            <div className="images">
                <div className="avt-img">
                    <GdAvatar src={avatar} alt="" width={24} height={24} />
                </div>
            </div>
        );
    };

    return (
        <div
            key={id}
            className={classNames('wrapper-message', {
                'box-message-to': isSendTo,
                'box-message-send': !isSendTo
            })}
        >
            {isSendTo ? _renderAvatar() : null}
            <div className="wrap-items">
                <div
                    className={classNames('description', {
                        'bg-green-default': action && isSendTo
                    })}
                >
                    {action?.type ? <span className="text-capitalize">{action.type.replace('_', ' ')}</span> : content}
                </div>
                <div className="name-content">
                    <div className="name">{name}</div>
                    <div className="time">{getDateString(timestamp)}</div>
                </div>
            </div>
            {isSendTo ? null : _renderAvatar()}
        </div>
    );
};

export default ItemPortalConversation;

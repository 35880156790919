import classNames from 'classnames';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import GlobalSearch from 'app/components/search/GlobalSearch';
import { reducer } from 'app/const/Reducer';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconLoading from 'assets/icon/IconLoading';
import IconProfile from 'assets/icon/IconProfile';
import { customerSearchRequest } from 'common/redux/actions/searchAction';

export default function ListLead({ onChangeLead }) {
    const refDropdown = useRef(null);
    const dispatch = useDispatch();

    const [state, dispatchState] = useReducer(reducer, {
        keyWord: '',
        dataSearch: [],
        isLoading: false,
        customerSelected: null,
        showMore: false
    });

    const {
        keyWord: finalKeyword,
        dataSearch: finaldataSearch,
        customerSelected,
        isLoading: finalIsLoading,
        showMore
    } = state;

    useEffect(() => {
        _handleSearchKeyword('', true);
    }, []);

    useEffect(() => {
        onChangeLead(customerSelected);
    }, [customerSelected]);

    function _handleSearchKeyword(keyword, resetResult) {
        dispatch(
            customerSearchRequest(
                {
                    term: keyword || '',
                    limit: 20,
                    offset: resetResult ? 0 : finaldataSearch.length,
                    type: 'customer'
                },
                (response) => _handleSearchSuccess(response, resetResult),
                _handleSearchFailed
            )
        );
    }

    function _handleSearchSuccess(response, resetResult) {
        const finalCustomers = response?.customers || [];
        const finalResult = resetResult ? finalCustomers : [...finaldataSearch, ...finalCustomers];

        dispatchState({
            isLoading: false,
            dataSearch: finalResult,
            showDataSearch: true,
            showMore: response.show_more
        });
    }

    function _handleSearchFailed() {
        dispatchState({
            isLoading: false,
            dataSearch: [],
            showDataSearch: false,
            showMore: false,
            keyWord: ''
        });
    }

    function _handleChangeSearch(text, isReset = true) {
        dispatchState({ keyWord: text, isLoading: true });

        _handleSearchKeyword(text, isReset);
    }

    function _handleClearKeyword() {
        dispatchState({
            keyWord: '',
            dataSearch: [],
            isLoading: true,
            showDataSearch: false,
            showMore: false
        });
        _handleSearchKeyword('', true);
    }

    const _selectCustomer = (selected) => {
        dispatchState({
            customerSelected: selected
        });
        refDropdown.current._close();
    };

    return (
        <DropdownPopper
            id="customer_phones"
            ref={refDropdown}
            isUseToggle
            strategy="absolute"
            wrapperClassName="v2-dropdown choose-lead"
            buttonClassName="dropbtn v2-btn-default has-icon w-100 fw-normal"
            wrapperListClass="v2-dropdown__menu content-search"
            isLoading={false}
            dropdownIcon={
                <Fragment>
                    <IconProfile />
                    {!!customerSelected ? (
                        <div className="lead-info flexcenter flex-1">
                            <span className="lead-info__label txt-ellipsis black-eerie-dark">
                                {customerSelected.label}
                            </span>
                            <span className="lead-info__number txt-ellipsis granite-gray">{customerSelected.desc}</span>
                        </div>
                    ) : (
                        <div className="lead-info flexcenter flex-1" />
                    )}

                    <span className="arrow">
                        <IconArrowDown />
                    </span>
                </Fragment>
            }
        >
            <ChildrenDropdown
                showMore={showMore}
                onSelect={_selectCustomer}
                clearKeyword={_handleClearKeyword}
                onSearch={_handleChangeSearch}
                finalKeyword={finalKeyword}
                finaldataSearch={finaldataSearch}
                customerSelected={customerSelected}
                finalIsLoading={finalIsLoading}
            />
        </DropdownPopper>
    );
}

const ChildrenDropdown = ({
    finalIsLoading,
    update = () => {},
    finalKeyword,
    clearKeyword,
    onSearch,
    onSelect,
    finaldataSearch,
    customerSelected,
    showMore
}) => {
    const { t } = useTranslation();
    return (
        <div className="container-column">
            <div className="flex-auto header-search">
                <GlobalSearch
                    placeholder={t('common:search')}
                    style={'search-input'}
                    isLoading={finalIsLoading}
                    clearKeyword={clearKeyword}
                    autoFocus
                    spellCheck
                    onSearch={onSearch}
                    defaultValue={finalKeyword}
                />
            </div>
            <ListData
                data={finaldataSearch || []}
                selected={customerSelected || {}}
                onSelect={onSelect}
                defaultValue={finalKeyword}
                isLoading={finalIsLoading}
                showMore={showMore}
                onShowMore={onSearch}
                update={update}
            />
        </div>
    );
};

const ListData = ({
    data = [],
    selected,
    onSelect = () => {},
    defaultValue,
    isLoading,
    showMore,
    onShowMore,
    update = () => {}
}) => {
    const { t } = useTranslation();
    useEffect(() => {
        update();
    }, [data]);
    if (!data.length) {
        if (!defaultValue || isLoading) {
            return false;
        }
        return (
            <ul className="box-auto scrolls">
                <li className={'items'}>
                    <div className="lead-info flexcenter flex-1">
                        <span className="lead-info__label txt-ellipsis black-eerie-dark">{t('report:not_found')}</span>
                    </div>
                </li>
            </ul>
        );
    }

    const _renderShowMore = () => {
        if (!showMore) {
            return false;
        }

        if (isLoading) {
            return (
                <li className="items --loadmore justify-center">
                    <div className="v2-btn-default --noborder --label --loadmore bg-transparent">
                        <IconLoading />
                    </div>
                </li>
            );
        }

        if (!isLoading && data.length !== 0) {
            return (
                <li className="items --loadmore justify-center" onClick={() => onShowMore(defaultValue, false)}>
                    <div className="v2-btn-default --noborder --label --loadmore bg-transparent">
                        {t('common:show_more')}
                    </div>
                </li>
            );
        }
    };

    return (
        <ul className="box-auto scrolls">
            {data.map((item) => {
                const itemId = item.id;
                return (
                    <li
                        key={itemId}
                        className={classNames('items', { active: selected?.id === itemId })}
                        onClick={() => onSelect(item)}
                    >
                        <div className="lead-info flexcenter flex-1">
                            <span className="lead-info__label txt-ellipsis black-eerie-dark">{item.label}</span>
                            <span className="lead-info__number txt-ellipsis granite-gray">{item.desc}</span>
                        </div>
                    </li>
                );
            })}
            {_renderShowMore()}
        </ul>
    );
};

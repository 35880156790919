import React, { useReducer, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import IconThreeDots from 'assets/icon/IconThreeDots';
import { reducer } from 'app/const/Reducer';

const Actions = ({ classWrapper, itemId, id, options, onSelected }) => {
    const { t } = useTranslation(['jobDetail']);

    const [state, setState] = useReducer(reducer, {
        isVisible: false
    });
    const isVisible = state.isVisible;

    const refDowpdown = useRef(null);

    useEffect(() => {
        if (isVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [isVisible]);

    const handleHideDropdown = (event) => {
        const elPrevent = document.getElementById('show_list_dropdown_action');

        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeDropdown();
        }
    };

    const handleClickOutside = (event) => {
        const elPrevent = document.getElementById('show_list_dropdown_action');
        if (
            refDowpdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDowpdown.current.contains(event.target)
        ) {
            _closeDropdown();
        }
    };

    const _closeDropdown = () => {
        isVisible && setState({ isVisible: false });
    };

    const _handleOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setState({ isVisible: !isVisible });
    };

    const _handleOnClick = (e, value, itemId, id) => {
        e && e.stopPropagation();
        _closeDropdown();
        onSelected(value, itemId, id);
    };

    const _renderList = (list) => {
        if (!Array.isArray(list) || !isVisible) return null;

        return list.map((item) => {
            const finalName = t(`jobDetail:${item.name}`);
            return (
                <li
                    onClick={(e) => {
                        _handleOnClick(e, item.value, itemId, id);
                    }}
                    key={item.id}
                    className={item.class}
                    tabIndex="0"
                >
                    {item.icon ? item.icon : ''}
                    <span className="flex-1 txt-ellipsis nowrap" title={finalName}>
                        {finalName}
                    </span>
                </li>
            );
        });
    };

    return (
        <div ref={refDowpdown} className={`${classWrapper} ${isVisible ? 'active' : ''}`}>
            <div onClick={_handleOpen} className="dropbtn" tabIndex="0">
                <IconThreeDots />
            </div>
            <div id="show_list_dropdown_action" className="v2-dropdown__menu scrolls">
                <ul>{_renderList(options)}</ul>
            </div>
        </div>
    );
};

Actions.defaultProps = {
    classWrapper: '',
    itemId: 0,
    id: 0,
    options: [],
    onSelected: () => {}
};

export default Actions;

import { LOGIN_TYPE } from './Auth';

export const MIXPANEL_METHOD = {
    IDENTIFY: 'identify',
    REGISTER: 'register',
    SET: 'set',
    SET_ONCE: 'set_once',
    INCREMENT: 'increment',
    REGISTER_ONCE: 'register_once'
};

export const MIXPANEL_PROPERTIES = {
    FREE: 'Free',
    TRIAL: 'Trial',
    SOCIAL: {
        [LOGIN_TYPE.facebook]: 'Facebook',
        [LOGIN_TYPE.google]: 'Google'
    },
    EMAIL: 'Email',
    SUPERADMIN: 'SuperAdmin',
    COMPANY: 'Company',
    INCREMENT_LOGINS: '# of Logins',
    FIRST_LOGIN: 'First Login',
    LAST_LOGIN: 'Last Login',
    INDUSTRY: 'Industry',
    ROLE: 'Role',
    PLAN_TYPE: 'Plan Type',
    TERM_LENGTH: 'Term Length',
    PLAN_PRICE: 'Plan Price',
    $EMAIL: '$email',
    $NAME: '$name',
    $PHONE: '$phone',
    METHOD: 'Method',
    INCREMENT_CALENDAR_VIEWS: '# of Calendar Views',
    FIRST_CALENDAR_VIEW: 'First Calendar View',
    VIEW_TYPE: 'View Type',
    LAST_CALENDAR_VIEW: 'Last Calendar View',
    INCREMENT_USER_ADDED: '# Of Users Added',
    FIRST_USER_ADDED: 'First User Added',
    LAST_USER_ADDED: 'Last User Added',
    NEW_USER_ADDED: 'New User Added',
    LAST_ADD_TAX_ADDED: 'Last Add Tax Added',
    FIRST_ADD_TAX_ADDED: 'First Add Tax Added',
    INCREMENT_ADD_TAX: '# Of Add Taxs Added',
    LAST_PAYMENT_ADDED: 'Last Payment Added',
    FIRST_PAYMENT_ADDED: 'First Payment Added',
    INCREMENT_PAYMENT_ADDED: '# Of Payments Added',
    ADD_PAYMENT: 'Add Payment',
    FIRST_LEAD_ADDED: 'First Lead Added',
    LAST_LEAD_ADDED: 'Last Lead Added',
    ADD_LEAD: 'Add Lead',
    INCREMENT_LEAD_ADDED: '# Of Leads Added',
    FIRST_CUSTOMER_ADDED: 'First Customer Added',
    LAST_CUSTOMER_ADDED: 'Last Customer Added',
    ADD_CUSTOMER: 'Add Customer',
    INCREMENT_CUSTOMER_ADDED: '# Of Customers Added',
    FIRST_ESTIMATE_ADDED: 'First Estimate Added',
    LAST_ESTIMATE_ADDED: 'Last Estimate Added',
    ADD_ESTIMATE: 'Add Estimate',
    INCREMENT_ESTIMATE_ADDED: '# Of Estimates Added',
    FIRST_INVOICE_ADDED: 'First Invoice Added',
    LAST_INVOICE_ADDED: 'Last Invoice Added',
    ADD_INVOICE: 'Add Invoice',
    INCREMENT_INVOICE_ADDED: '# Of Invoices Added',
    FIRST_ADD_PAYMENT_METHOD_ADDED: 'First Add Payment Method Added',
    LAST_ADD_PAYMENT_METHOD_ADDED: 'Last Add Payment Method Added',
    INCREMENT_PAYMENT_METHOD_ADDED: '# Of Add Payment Methods Added',
    FIRST_ADD_A_PRODUCT_ADDED: 'First Add A Product Added',
    LAST_ADD_A_PRODUCT_ADDED: 'Last Add A Product Added',
    ADD_A_PRODUCT: 'Add Add A Product',
    INCREMENT_ADD_A_PRODUCT_ADDED: '# Of Add A Products Added',
    FIRST_ADD_A_GROUP_SCHEDULE_ADDED: 'First Add A Group Schedule Added',
    LAST_ADD_A_GROUP_SCHEDULE_ADDED: 'Last Add A Group Schedule Added',
    ADD_A_GROUP_SCHEDULE: 'Add a Group Schedule',
    INCREMENT_ADD_A_GROUP_SCHEDULE_ADDED: '# Of Add A Group Schedules Added',
    FIRST_ADD_SCHEDULE_ADDED: 'First Add Schedule Added',
    LAST_ADD_SCHEDULE_ADDED: 'Last Add Schedule Added',
    ADD_SCHEDULE: 'Add Schedule',
    INCREMENT_ADD_SCHEDULE_ADDED: '# Of Add Schedules Added',
    FIRST_NEW_JOB_ADDED: 'First New Job Added',
    LAST_NEW_JOB_ADDED: 'Last New Job Added',
    ADD_NEW_JOB: 'Add New Job',
    INCREMENT_NEW_JOB_ADDED: '# Of New Jobs Added',
    FIRST_TIMEOFF_ADDED: 'First Time Off Added',
    LAST_TIMEOFF_ADDED: 'Last Time Off Added',
    ADD_TIMEOFF: 'Add Time Off',
    INCREMENT_TIMEOFF_ADDED: '# Of Time Offs Added',
    FIRST_MANAGE_ADDONS_ADDED: 'First Manage Add-Ons Added',
    LAST_MANAGE_ADDONS_ADDED: 'Last Manage Add-Ons Added',
    ADD_MANAGE_ADDONS: 'Add Manage Add-Ons',
    INCREMENT_MANAGE_ADDONS_ADDED: '# Of Manage Add-Onss Added',
    TEAM_SIZE: 'Team Size',
    ESTIMATE: 'Estimate',
    SOURCE: 'Source',
    REVENUE: 'Revenue',
    ATTRIBUTION: 'Attribution',
    COUNTRY: 'Country',
    VIDEO_NAME: 'Video Name',
    PRESS_PLAY: 'Press Play',
    PERCENT_VIEW: 'Percent View',
    VIEWER_EMAIL: 'Viewer Email'
};

export const MIXPANEL_EVENT_NAME = {
    SIGN_UP: 'Sign Up',
    LOGIN: 'Log In',
    CALENDAR_VIEW: 'Calendar View',
    ADD_USER: 'Add User',
    ADD_TAX: 'Add Tax',
    PURCHASE: 'Purchase',
    UPGRADE: 'Upgrade',
    DOWNGRADE: 'Downgrade',
    EDIT_COMPANY: 'Edit Company',
    ADD_PAYMENT: 'Add Payment',
    ADD_LEAD: 'Add Lead',
    ADD_CUSTOMER: 'Add Customer',
    ADD_INVOICE: 'Add Invoice',
    ADD_ESTIMATE: 'Add Estimate',
    ADD_PAYMENT_METHOD: 'Add Payment Method',
    ADD_PRODUCT: 'Add Product',
    MANAGE_ADD_ONS: 'Manage Add-ons',
    ADD_JOB: 'Add New Job',
    ADD_TIMEOFF: 'Add Time Off',
    ADD_SCHEDULE: 'Add Schedule',
    ADD_A_GROUP_SCHEDULE: 'Add a Group Schedule',
    LOGIN_BY_TOKEN: 'Login By Token',
    TRACKING: 'Tracking',
    WATCH_VIDEO: 'Watch Video'
};

export const CAMPAIGN_KEYWORDS = {
    UTM_SOURCE: 'utm_source',
    UTM_MEDIUM: 'utm_medium',
    UTM_CAMPAIGN: 'utm_campaign',
    UTM_CONTENT: 'utm_content',
    UTM_TERM: 'utm_term'
};

export const MIXPANEL_UTM = 'utm';
export const MIXPANEL_FORMAT_DATE = 'YYYY-MM-DDTHH:mm:ssZ';
export const MIXPANEL_KEY_LOCAL_STORAGE_UTM_PARAMS = 'utm_params';
export const MIXPANEL_EXCLUDE_TRACK = [MIXPANEL_EVENT_NAME.EDIT_COMPANY, MIXPANEL_EVENT_NAME.LOGIN_BY_TOKEN];
export const SIGN_UP_TRACKING_INFO = '/tracking/tracking';

export const START_SIGN_UP_FORM = 'START_SIGN_UP_FORM';
export const ADD_PERSONAL_DETAILS = 'ADD_PERSONAL_DETAILS';
export const ADD_COMPANY_DETAILS = 'ADD_COMPANY_DETAILS';
export const SUBMIT_SIGN_UP_FORM = 'SUBMIT_SIGN_UP_FORM';

export const MIXPANEL_SIGNUP_EVENT = {
    [START_SIGN_UP_FORM]: 'Start Sign Up Form',
    [ADD_PERSONAL_DETAILS]: 'Add Personal Details',
    [ADD_COMPANY_DETAILS]: 'Add Company Details',
    [SUBMIT_SIGN_UP_FORM]: 'Submit Sign Up Form'
};

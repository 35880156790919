/* eslint-disable no-undef */
import { CUSTOMER_UPDATE_TASK_STATUS, updateTask } from 'app/const/Api';
import { PERMISSIONS } from 'app/const/Permissions';
import TaskItemTags from 'app/modules/calendar/components/task/TaskItemTags';
import { STATUS } from 'app/modules/calendar/const/SideBar';
import Assign from 'app/modules/customer/task/detail/Assign';
import Options from 'app/modules/customer/task/detail/Options';
import EditTask from 'app/modules/customer/task/edit';
import IconDone from 'assets/icon/IconDone';
import classNames from 'classnames';
import { clientQuery } from 'common/utils/ApiUtils';
import { convertPhpFormatToMoment, convertTimeToISO } from 'common/utils/DateUtils';
import { checkPermission } from 'common/utils/PermissionUtils';
import moment from 'moment-timezone';
import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const TaskDetail = ({ taskDetail, onUpdate, onDelete }) => {
    const { t, i18n } = useTranslation();
    const refEditTask = useRef(null);
    const userData = useSelector(({ auth }) => auth?.user);
    const { active: finalStatus, id: taskId } = taskDetail;

    const isHavePermissionTask = useMemo(() => {
        return checkPermission(userData.permissions.enabled || [], PERMISSIONS.editOrDeleteTaskInbox);
    }, []);

    const date_format = userData?.company?.date_format;

    function _handleOpenEdit() {
        const elm = document.getElementById(`form_time_line_task_detail_${taskId}`);
        if (elm) {
            elm.classList.toggle('--editer-task');
        }
        refEditTask.current.toggleShowHide();
    }

    function _handleUpdateStatus() {
        const active = finalStatus === STATUS.DONE ? STATUS.ACTIVE : STATUS.DONE;
        let newTaskDetail = { ...taskDetail, active };

        const userProfile = { ...userData.profile };
        newTaskDetail = {
            ...newTaskDetail,
            completed_at: convertTimeToISO(moment().utc()),
            completed_by: {
                ...userProfile,
                full_name: userProfile.first_name.concat(' ', userProfile.last_name)
            }
        };

        onUpdate(newTaskDetail);

        const optionsQuery = {
            method: 'PUT',
            data: { active, ids: [taskId], socket_id: global.customer_time_line_task_service }
        };

        clientQuery(CUSTOMER_UPDATE_TASK_STATUS, optionsQuery);
    }

    function _handleDeleteTask(taskId) {
        if (!window.confirm(t('are_you_sure_you_want_to_delete_this_task'))) return;
        onDelete(taskId);
        clientQuery(updateTask(taskId), {
            method: 'DELETE',
            data: { socket_id: global.customer_time_line_task_service }
        });
    }

    function _handleUpdateTask(newData) {
        onUpdate(newData);
    }

    function _renderUpdateBy() {
        if (finalStatus === STATUS.ACTIVE) {
            return false;
        }

        const formatDisplay = convertPhpFormatToMoment(date_format);
        const timezone = userData?.settings?.timezone;

        return (
            <div className="box-task__logs">
                {t('common:done_by')}
                <div className="tag-label flex-1">
                    {taskDetail?.completed_by?.full_name || taskDetail?.completed_by?.name || ''}
                </div>
                {t('setting:on')}
                <div className="tag-label">
                    {moment(taskDetail.completed_at)
                        .tz(timezone)
                        .locale(i18n.language)
                        .format(`${formatDisplay} h:mm A`)}
                </div>
            </div>
        );
    }

    return (
        <div
            className={classNames('boxs boxs-view-task tasks-list', { 'is-disable': taskDetail.quick_add })}
            id={`form_time_line_task_detail_${taskId}`}
        >
            <div className="left-icons">
                <IconDone isHasColor />
            </div>
            <div className="content is-complete">
                <div className={classNames('box-task', { done: finalStatus === STATUS.DONE })}>
                    <div className="title">
                        <div className="v2-btn-default task-done" onClick={_handleUpdateStatus}>
                            <IconDone />
                            {t('common:done')}
                        </div>
                        <Assign taskDetail={taskDetail} />
                        {isHavePermissionTask && (
                            <Options
                                openFormEdit={_handleOpenEdit}
                                finalStatus={finalStatus}
                                onDelete={_handleDeleteTask}
                                taskId={taskId}
                            />
                        )}
                    </div>
                    <div className="description">{taskDetail.title}</div>
                    <TaskItemTags customer={taskDetail.customer} job={taskDetail.job} />
                    {_renderUpdateBy()}
                </div>
            </div>
            <EditTask
                ref={refEditTask}
                taskDetail={taskDetail}
                socketId={global.customer_time_line_task_service}
                onUpdate={_handleUpdateTask}
            />
        </div>
    );
};

export default TaskDetail;

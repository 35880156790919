export default function IconTraining() {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.5 9C8.5 8.17157 7.82843 7.5 7 7.5C6.17157 7.5 5.5 8.17157 5.5 9V16C5.5 16.8284 6.17157 17.5 7 17.5C7.82843 17.5 8.5 16.8284 8.5 16V9Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.5 11.5H8.5V13.5H15.5V11.5Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.5 9C18.5 8.17157 17.8284 7.5 17 7.5C16.1716 7.5 15.5 8.17157 15.5 9V16C15.5 16.8284 16.1716 17.5 17 17.5C17.8284 17.5 18.5 16.8284 18.5 16V9Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.5 10.5C5.5 9.94772 5.05228 9.5 4.5 9.5C3.94772 9.5 3.5 9.94772 3.5 10.5V14.5C3.5 15.0523 3.94772 15.5 4.5 15.5C5.05228 15.5 5.5 15.0523 5.5 14.5V10.5Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.5 10.5C20.5 9.94772 20.0523 9.5 19.5 9.5C18.9477 9.5 18.5 9.94772 18.5 10.5V14.5C18.5 15.0523 18.9477 15.5 19.5 15.5C20.0523 15.5 20.5 15.0523 20.5 14.5V10.5Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

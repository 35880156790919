import classNames from 'classnames';
import React, { Fragment, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import { PENDING_SERVICE_KEY, SERVICE_PLAN_DELAY_TYPE_OPTIONS } from 'app/const/Settings';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconTrash from 'assets/icon/IconTrash';
import ListService from './ListService';

const ItemService = ({ item = {}, onChangeData = () => {}, onDelete = () => {} }) => {
    const { t } = useTranslation('setting');
    const {
        delay: finalDelay = {},
        id: finalId,
        name: finalName,
        triggerPlanItemOptions,
        serviceTrigger = {},
        plan_item_id: finalPlanItemId
    } = item;
    const { id: serviceTriggerId, name: serviceTriggerName } = serviceTrigger;
    const { value: finalValue = 0, type: finalDelayType } = finalDelay;
    const [state, dispatchState] = useReducer(reducer, { isOpenDelete: false });
    const { isOpenDelete: finalIsOpenDelete } = state;

    const refValue = useRef(null);
    const refDropdown = useRef(null);

    const _handleChangeType = (id) => {
        onChangeData(finalPlanItemId, id, PENDING_SERVICE_KEY.TYPE);
        refDropdown.current?._closeDropdown();
    };

    const _handleOpenDelete = () => {
        dispatchState((prev) => ({ ...prev, isOpenDelete: true }));
    };

    const _handleCloseDelete = () => {
        dispatchState((prev) => ({ ...prev, isOpenDelete: false }));
    };

    const _handleChangeValue = () => {
        const finalValue = parseInt(refValue.current.value) || 1;
        refValue.current.value = finalValue;
        onChangeData(finalPlanItemId, finalValue, PENDING_SERVICE_KEY.VALUE);
    };

    const _handleChangeService = (data, keyUpdate) => {
        onChangeData(finalPlanItemId, data, keyUpdate);
    };

    const _getNameDelayType = (value) => {
        return t(`common:${value}${finalValue > 1 ? 's' : ''}`);
    };

    return (
        <div className="rows row-has-line border-top-border-color-grey flex-column gap-8">
            <div className="flex-betweenitems w-100">
                <div className="rows__trigger flex-1 flexcenter gap-4">
                    <p>{t('trigger')}</p>
                    <ListService
                        id={`pending_service_${finalPlanItemId}`}
                        serviceSelected={{ id: finalId, name: finalName }}
                        placeholder={t('setting:select_a_service')}
                        onChangeService={(data) => _handleChangeService(data, PENDING_SERVICE_KEY.SERVICE_ID)}
                    />
                    <div className="has-many-field__items phone-field mb-0">
                        <input
                            ref={refValue}
                            type="number"
                            className="field-input"
                            defaultValue={finalValue}
                            placeholder=""
                            min="1"
                            onChange={_handleChangeValue}
                        />
                        <CalendarDropdown
                            ref={refDropdown}
                            buttonClassName="dropbtn items bg-white"
                            customDropButton={() => (
                                <Fragment>
                                    <span className="text-capitalize">
                                        {_getNameDelayType(
                                            SERVICE_PLAN_DELAY_TYPE_OPTIONS.find((item) => item.id === finalDelayType)
                                                .value
                                        )}
                                    </span>
                                    <div className="arrow">
                                        <IconArrowDown />
                                    </div>
                                </Fragment>
                            )}
                        >
                            <ul>
                                {SERVICE_PLAN_DELAY_TYPE_OPTIONS.map(({ id, value }) => (
                                    <li
                                        key={id}
                                        className={classNames('items text-capitalize', {
                                            active: finalDelayType === id
                                        })}
                                        onClick={() => _handleChangeType(id)}
                                    >
                                        {_getNameDelayType(value)}
                                    </li>
                                ))}
                            </ul>
                        </CalendarDropdown>
                    </div>
                    <p>{t('after')}</p>
                    <ListService
                        id={`trigger_service_${finalPlanItemId}`}
                        isCheckNeverEnd
                        triggerPlanItemOptions={triggerPlanItemOptions}
                        serviceSelected={{ id: serviceTriggerId, name: serviceTriggerName }}
                        placeholder={t('setting:select_a_service')}
                        onChangeService={(data) => _handleChangeService(data, PENDING_SERVICE_KEY.TRIGGER_PLAN_ITEM_ID)}
                    />
                    <p>{t('is_completed')}</p>
                </div>
                <div className={classNames('delete-items tabs relative', { active: finalIsOpenDelete })}>
                    <div className={classNames('v2-btn-default --icon-lg has-bg-red', {})} onClick={_handleOpenDelete}>
                        <IconTrash />
                    </div>
                    <div className="delete-option">
                        <div className="tabs-daily btn-item red-items">
                            <button
                                className="tab-items active-tab-selector item-off"
                                onClick={() => onDelete(finalPlanItemId)}
                                type="button"
                            >
                                {t('delete_service')}
                            </button>
                            <button className="tab-items" onClick={_handleCloseDelete} type="button">
                                {t('cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ItemService;

import React, { useEffect } from 'react';

import BookMark from 'app/modules/calendar/components/bookmark';
import SlideBar from 'app/modules/calendar/components/sidebar';
import { useSiteTitle } from 'common/hooks';
import CalendarContainer from './CalendarContainer';

import './css/calendar.scss';
import ServicesPath from './ServicesPath';

const Calendar = ({ title }) => {
    useSiteTitle(title);

    useEffect(() => {
        const mainPageDiv = document.getElementById('main-page');
        mainPageDiv.classList.add('calendar-page', 'container-main');
        return () => {
            mainPageDiv.classList.remove('calendar-page', 'container-main');
        };
    }, []);

    return (
        <>
            <BookMark />
            <div className="container-wrap calendar" id={'main_page_full_calendar'}>
                <div className="container">
                    <CalendarContainer />
                    <SlideBar />
                    <ServicesPath />
                </div>
            </div>
        </>
    );
};

export default Calendar;

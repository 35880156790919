import React from 'react';
import { useSelector } from 'react-redux';
import { convertPhpFormatToMoment } from 'common/utils/DateUtils';
import moment from 'moment-timezone';
import i18n from 'assets/i18n';

const Assign = ({ taskDetail }) => {
    const date_format = useSelector(({ auth }) => auth?.user?.company?.date_format);
    const userDetail = taskDetail?.assignee || {};
    const formatDisplay = convertPhpFormatToMoment(date_format);

    return (
        <>
            <div className="avt-img">
                <img src={userDetail.avatar} alt="" width={32} height={32} />
            </div>
            <div className="title__info">
                <div className="name">{userDetail.full_name || ''}</div>
                <div className="time">
                    {moment(taskDetail.date).utc().locale(i18n.language).format(`${formatDisplay} h:mm A`)}
                </div>
            </div>
        </>
    );
};

export default Assign;

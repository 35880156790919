import React, { forwardRef, useContext, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { CUSTOMERS } from 'app/const/Route';
import { formatBillingAddress } from 'app/modules/customer/utils';
import { AddInvoiceContext } from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import EstimateTemplate from 'app/modules/jobdetail/tabs/addestimate/form/template';
import SearchCustomer from 'app/modules/quickadd/components/SearchCustomer';
import LocationInfo from 'app/modules/quickadd/invoice/LocationInfo';
import IconBillingAddress from 'assets/icon/IconBillingAddress';
import IconUser from 'assets/icon/IconUser';
import { getListLocation } from 'common/redux/actions/customers/locationAction';

const CustomerInfo = forwardRef(({ isEmpty = false, isInvoice = true }, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [state, setState] = useReducer(reducer, {
        name: null,
        location: null,
        options: [],
        isLoading: true
    });
    const { invoiceData, onChangeLocation } = useContext(AddInvoiceContext);
    const {
        customer_id: customerId,
        location_id: locationId,
        defaultCustomer: isDefaultCustomerDetail,
        isAddInternal,
        deposit
    } = invoiceData;

    const isPayDeposit = !!deposit.invoices?.length;

    const { name, location, options, isLoading: finalIsLoading } = state;
    const refSearchCustomer = useRef(null);

    const billAddress = formatBillingAddress(location || {});

    // eslint-disable-next-line no-undef
    const customerDetail = global.customerDetail;
    const isQuickAddCustomer = !!customerDetail;

    useEffect(() => {
        customerId && _getListCustomerLocation({ limit: 10, offset: 0, customer_id: customerId }, true);
    }, [customerId]);

    useImperativeHandle(ref, () => ({
        getValue: () => {
            return invoiceData;
        }
    }));

    const _getListCustomerLocation = (params) => {
        dispatch(getListLocation(params, _getListCustomerLocationSuccess));
    };

    const _getListCustomerLocationSuccess = (response) => {
        const data = [...response.data];
        const locationSelected = data.length === 1 ? data[0] : null;

        locationSelected && onChangeLocation(locationSelected || {});

        setState({
            location: locationSelected,
            options: data,
            isLoading: false
        });
    };

    const _handleSelectCustomer = (id, data) => {
        onChangeLocation({}, id);
        setState({ name: data?.first_name || '', options: [] });
    };

    const _handleSelectLocation = (name, data) => {
        onChangeLocation(data);
        setState({ location: data });
    };

    if (isEmpty) {
        return null;
    }

    return (
        <div className="customer-info-number has-customer-name">
            {!!isDefaultCustomerDetail && isAddInternal ? (
                <div className="name txt-ellipsis">
                    <Link to={addBranchPath(`${CUSTOMERS}/${isDefaultCustomerDetail.id}`)}>
                        {isDefaultCustomerDetail.full_name}
                    </Link>
                </div>
            ) : (
                <div className="sm-row">
                    <div className="txt --large">
                        <IconUser />
                        {t('common:customer')}
                    </div>
                    <SearchCustomer
                        ref={refSearchCustomer}
                        selected={customerId}
                        onSelect={_handleSelectCustomer}
                        autoFocus={!isQuickAddCustomer}
                        customerName={customerDetail?.full_name || ''}
                        isDisable={isQuickAddCustomer}
                    />
                </div>
            )}

            {!!customerId ? (
                <div className="sm-row --bill-address">
                    <div className="txt --large">
                        <IconBillingAddress />
                        <span className="flex-1 txt-ellipsis">{t('common:billing_address')}</span>
                    </div>
                    <div className="detail">
                        <LocationInfo
                            name="location_id"
                            classWrapper="v2-dropdown select-info"
                            options={options}
                            selected={locationId}
                            onSelect={_handleSelectLocation}
                            title={t('common:select_location_title')}
                            isLoading={finalIsLoading}
                        />
                        {!!locationId && (
                            <div className="detail__info-address">
                                <p>{location?.bill_to || name || ''}</p>
                                <p>{billAddress.address || ''}</p>
                                <p>{billAddress.fullAddress || ''}</p>
                            </div>
                        )}
                    </div>
                </div>
            ) : null}
            {!isInvoice && <EstimateTemplate isDisable={isPayDeposit} />}
        </div>
    );
});

export default CustomerInfo;

import React, { Fragment, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import IconEmail from 'assets/icon/IconEmail';
import IconSMS from 'assets/icon/IconSMS';
import IconTelephone from 'assets/icon/IconTelephone';
import IconThreeDots from 'assets/icon/IconThreeDots';
import { CUSTOMERS } from 'app/const/Route';
import { formatDateCompany } from 'common/utils/DateUtils';
import { reducer } from 'app/const/Reducer';
import IconEdit from 'assets/icon/IconEdit';
import IconTrash from 'assets/icon/IconTrash';
import {
    CONTACT_ID_DEFAULT,
    OPTIONS_VALUE_OPPORTUNITY,
    TYPE_CONTACT_PIPELINE,
    TYPE_OPTIONS_PIPELINE
} from 'app/const/opportunity';
import { clientQuery } from 'common/utils/ApiUtils';
import { getUrlCustomerContact } from 'app/const/Api';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';
import { checkIsEnableVoip, checkPermission } from 'common/utils/PermissionUtils';
import { detectMobile, getPeriodName } from 'common/utils/FunctionUtils';
import { LIST_STATUS_PIPELINES } from 'app/const/setting/SettingPipelines';
import { addBranchPath } from 'app/const/Branch';
import { ACCESS_CUSTOMER_OPP } from 'app/const/Permissions';

const ItemCard = ({ data = {}, isDisable = false, onEditItem = () => {}, onContact = () => {} }) => {
    const { t } = useTranslation('report');

    const dateFormat = useSelector(({ auth }) => auth.user.company.date_format);
    const permissionsList = useSelector(({ auth }) => auth.user?.permissions?.enabled || []);
    const refDropdown = useRef(null);
    const refFirstTime = useRef(true);

    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        contacts: null
    });

    const { isOpen, contacts } = state;
    const {
        id,
        contact_id,
        customer_id,
        close_date,
        created_date,
        note,
        user,
        period,
        value,
        customer_name,
        created_by,
        confidence,
        pipeline
    } = data;
    const { avatar, full_name } = user || {};

    const isHaveCustomerOpp = checkPermission(permissionsList, ACCESS_CUSTOMER_OPP);
    const isPipelineActive = pipeline.status?.toLowerCase() === LIST_STATUS_PIPELINES[0]['status'];

    const _handleEditItem = (type) => {
        if (!!contacts || type === TYPE_OPTIONS_PIPELINE.DELETE) {
            onEditItem({ type, data, contacts });
        } else {
            _fetchListContact(true, type);
        }
        refDropdown.current._close();
    };

    const _renderDropdown = () => {
        if (!isHaveCustomerOpp || isDisable) return null;

        return (
            <DropdownPopper
                ref={refDropdown}
                id={id}
                wrapperClassName="v2-dropdown d-flex"
                wrapperListClass="v2-dropdown__menu --right content-full scrolls"
                buttonClassName="dropbtn v2-btn-default --transparent --icon-sm"
                dropdownIcon={<IconThreeDots />}
                isUseToggle
                isUseMouseDownOutSide
            >
                <ul>
                    <li>
                        <div
                            className="items has-icon btn-modal"
                            onClick={() => _handleEditItem(TYPE_OPTIONS_PIPELINE.EDIT)}
                        >
                            <IconEdit /> <span>{t('edit')}</span>
                        </div>
                    </li>
                    <li>
                        <div
                            className="items has-icon red-default btn-modal"
                            onClick={() => _handleEditItem(TYPE_OPTIONS_PIPELINE.DELETE)}
                        >
                            <IconTrash /> <div className="red-delete">{t('delete')}</div>
                        </div>
                    </li>
                </ul>
            </DropdownPopper>
        );
    };

    const _handleToggleNote = (value = null) => {
        (!!contact_id || contact_id === '') && _fetchListContact();
        dispatchState((prev) => ({ ...prev, isOpen: typeof value === 'boolean' ? value : !prev.isOpen }));
    };

    const _fetchListContact = (isOpenEdit = false, type) => {
        if (refFirstTime.current) {
            refFirstTime.current = false;

            const _handleSuccess = ({ data: finalData }) => {
                isOpenEdit && onEditItem({ type, data, contacts: finalData });
                dispatchState((prev) => ({ ...prev, contacts: finalData }));
            };

            clientQuery(
                getUrlCustomerContact(customer_id),
                { method: 'GET', data: { inc: 'customer' } },
                _handleSuccess
            );
        }
    };

    return (
        <Fragment>
            <div className="item-title flex-betweenitems">
                <Link to={addBranchPath(`${CUSTOMERS}/${customer_id}`)} className="txt-ellipsis fw-600 is-link">
                    {customer_name}
                </Link>
                {_renderDropdown()}
            </div>
            <div className="item-info">
                <div className="title" onClick={() => _handleToggleNote()}>
                    <div className="avt tooltip">
                        <img src={avatar} width="32" height="32" alt={full_name} className="avt-img" />
                        <p className="tooltiptext top">
                            {t('assigned_to')}: {full_name}
                            <br />
                            {t('created_by')}: {created_by?.full_name}
                        </p>
                    </div>
                    <div className="title__info">
                        {!!value.value ? (
                            <div className="name txt-ellipsis fw-600">
                                {value.format}{' '}
                                {period?.value !== OPTIONS_VALUE_OPPORTUNITY[0]['value']
                                    ? ` ${t(`customers:${getPeriodName(period?.name)}`)}`
                                    : ''}
                            </div>
                        ) : null}
                        <div className="time fs-11 fw-500 grey-outer-space tooltip">
                            {isPipelineActive && Number.isInteger(confidence) ? `${confidence}% on ` : ''}
                            {formatDateCompany(close_date, dateFormat)}
                            <div className="tooltiptext top">
                                {t(isPipelineActive ? 'estimated_close_date' : 'close_date', {
                                    date: formatDateCompany(close_date, dateFormat)
                                })}
                                <br />
                                {t('created_at_opp', { date: formatDateCompany(created_date, dateFormat) })}
                            </div>
                        </div>
                    </div>
                </div>
                {isOpen && (
                    <Fragment>
                        <ContactItem
                            contact_id={contact_id}
                            customer_id={customer_id}
                            contacts={contacts}
                            onContact={onContact}
                            onToggleNote={_handleToggleNote}
                        />
                        {!!note ? (
                            <div className="description" onClick={() => _handleToggleNote(false)}>
                                {note}
                            </div>
                        ) : null}
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
};

const ContactItem = ({ contact_id, customer_id, contacts, onContact = () => {}, onToggleNote = () => {} }) => {
    const { t } = useTranslation('report');
    const settings = useSelector(({ auth }) => auth.user.settings);

    const isMobile = !!detectMobile();
    const isSmsActive = !!settings.addons.smsplivo || isMobile;
    const isVoipActive = checkIsEnableVoip(settings, false) || isMobile;

    const finalContactId = contact_id === '' ? CONTACT_ID_DEFAULT : contact_id;
    if (!finalContactId) return null;
    if (!contacts)
        return (
            <div className="contact flex-betweenitems">
                <div className="loading --animation --line --twothird" />
                <div className="d-flex gap-4">
                    <div className="v2-btn-default loading bg-white-cultured --icon-sm" />
                    <div className="v2-btn-default loading bg-white-cultured --icon-sm" />
                    <div className="v2-btn-default loading bg-white-cultured --icon-sm" />
                </div>
            </div>
        );

    const finalContact = contacts.find((item) => item.id === finalContactId);
    if (!finalContact) return null;
    const { first_name, last_name, email, phones } = finalContact;
    const finalPhone = formatPhoneNumberVoip(phones[0]?.phone);

    const _handleContact = (e, type) => {
        e.preventDefault();
        e.stopPropagation();
        onContact({ type, contact: { ...finalContact, customer_id } });
    };

    return (
        <div className="contact flex-betweenitems gap-8" onClick={() => onToggleNote(false)}>
            <span className="txt-ellipsis flex-1 fw-600 purple-default">{`${first_name} ${last_name}`}</span>
            <div className="flexcenter" onClick={(e) => e.stopPropagation()}>
                <div className="tooltip ml-1">
                    <div
                        className={classNames(`v2-btn-default --icon-sm --transparent`, { 'is-disable': !email })}
                        onClick={(e) => _handleContact(e, TYPE_CONTACT_PIPELINE.EMAIL)}
                    >
                        <IconEmail />
                    </div>
                    <p className="tooltiptext top">{!email ? t('email_empty') : `${t('email')} ${email}`}</p>
                </div>
                <div className="tooltip ml-1">
                    <div
                        className={classNames(`v2-btn-default --icon-sm --transparent`, {
                            'is-disable': !finalPhone || !isSmsActive
                        })}
                        onClick={(e) => _handleContact(e, TYPE_CONTACT_PIPELINE.SMS)}
                    >
                        <IconSMS isOpportunity />
                    </div>
                    <p className="tooltiptext top">
                        {!finalPhone
                            ? t('sms_empty_number')
                            : !isSmsActive
                              ? t('sms_inactivate')
                              : t('send_sms_to_number', { number: finalPhone })}
                    </p>
                </div>
                <div className="tooltip ml-1">
                    <div
                        className={classNames(`v2-btn-default --icon-sm --transparent`, {
                            'is-disable': !finalPhone || !isVoipActive
                        })}
                        onClick={(e) => _handleContact(e, TYPE_CONTACT_PIPELINE.CALL)}
                    >
                        <IconTelephone isNormal />
                    </div>
                    <p className="tooltiptext top">
                        {!finalPhone
                            ? t('call_empty_number')
                            : !isVoipActive
                              ? t('voip_inactivate')
                              : t('call_number', { number: finalPhone })}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ItemCard;

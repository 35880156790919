import { REVIEW_LIKELY_GET_LIST, REVIEW_SERVICE_GET_LIST } from 'app/const/Api';
import { URL_EXPORT_REVIEW_RATINGS } from 'app/const/api/Export';
import { KEY_REPORT_LOCAL_STORAGE, LIST_STATUS } from 'app/const/App';
import { LIST_EXPORT } from 'app/const/report/Common';
import { REVIEW_LIKELY_LIST_FILTER, REVIEW_SERVICE_LIST_FILTER } from 'app/const/report/ReportFilter';
import { PARAMS_LOCAL_REVIEW } from 'app/const/report/ReportParams';
import { REPORT_TYPE, REVIEW_LIKELY_TAB, REVIEW_SERVICE_TAB } from 'app/const/report/ReportTypeContent';
import { REVIEWS_LIST_TAB_BUTTON, REVIEW_PAGE_CURRENT_TAB } from 'app/const/Reviews';
import classNames from 'classnames';
import { checkAddon } from 'common/utils/AddonUtils';
import { clientQuery } from 'common/utils/ApiUtils';
import { getLocalStorage, setLocalStorage, removeLocalStorage } from 'common/utils/LocalStorageUtils';
import React, { useEffect, useReducer, Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { reducer } from 'app/const/Reducer';
import ErrorPage from '../error';
import Export, { generateTitleExport } from '../report/components/Export';
import loadable from '@loadable/component';
import MainHeaderReport from 'app/modules/report/components/MainHeader';
import { REVIEWS_LIKELY, REVIEWS_SERVICE } from 'app/config/routes';
import { REPORT_LIMIT } from 'app/const/Reports';
import { getLocalParamsReport } from 'common/utils/ReportUtils';
import HeaderBottom from '../report/components/HeaderBottom';
import { addBranchPath } from 'app/const/Branch';
import { handleAbortController } from '../customer/utils';
import GDStatusBar from 'app/components/status/statusbar';

const TabContentReview = loadable(() => import('./components/TabContentReview'));

const Review = () => {
    const { t } = useTranslation(['common', 'review', 'report']);
    const addonsList = useSelector((state) => state.auth.user.settings.addons || {});
    const location = useLocation();
    const history = useHistory();
    const abortController = useRef(null);
    const refAlert = useRef(null);

    const URL_FETCH_LIST = {
        service: REVIEW_SERVICE_GET_LIST,
        likely: REVIEW_LIKELY_GET_LIST
    };

    const [reviewState, dispatchAction] = useReducer(reducer, {
        isLoading: true,
        listReview: [],
        currentTab: getLocalStorage(REVIEW_PAGE_CURRENT_TAB) || location.pathname.split('/')[3] || REVIEW_SERVICE_TAB,
        limit: REPORT_LIMIT,
        currentPage: 1,
        total: 0,
        refresh: false,
        isLoadingMore: false,
        reloadFilter: 0
    });
    const { isLoadingMore, total: finalTotal, listReview: finalData, isLoading: finalIsLoading } = reviewState;

    const reloadScreen = location?.state?.reload;
    const refresh = reviewState.refresh;

    const keyLocalStorageService = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.REVIEW_PAGE_PARAMS_SERVICE);
    const keyLocalStorageLikeLy = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.REVIEW_PAGE_PARAMS_LIKELY);
    const { currentTab } = reviewState;

    const paramsReviewService = getLocalParamsReport(keyLocalStorageService, REPORT_TYPE.REVIEW_PAGE_PARAMS_SERVICE);

    const paramsReviewLikely = getLocalParamsReport(keyLocalStorageLikeLy, REPORT_TYPE.REVIEW_PAGE_PARAMS_LIKELY);

    const currentFilter = currentTab === REVIEW_SERVICE_TAB ? REVIEW_SERVICE_LIST_FILTER : REVIEW_LIKELY_LIST_FILTER;
    const typeReview =
        currentTab === REVIEW_SERVICE_TAB
            ? REPORT_TYPE.REVIEW_PAGE_PARAMS_SERVICE
            : REPORT_TYPE.REVIEW_PAGE_PARAMS_LIKELY;

    const keyTabLocalStorage = KEY_REPORT_LOCAL_STORAGE.concat('_', PARAMS_LOCAL_REVIEW[currentTab]);
    const paramsReport = getLocalParamsReport(keyTabLocalStorage, PARAMS_LOCAL_REVIEW[currentTab]);

    useEffect(() => {
        if (reloadScreen) {
            dispatchAction({
                currentTab: location?.state?.newTab
            });
        }
    }, [reloadScreen]);

    useEffect(() => {
        const mainPageDiv = document.getElementById('main-page');
        mainPageDiv.classList.add('review-page');
        return () => {
            mainPageDiv.classList.remove('review-page');
            removeLocalStorage(REVIEW_PAGE_CURRENT_TAB);
            removeLocalStorage(keyLocalStorageService);
            removeLocalStorage(keyLocalStorageLikeLy);
        };
    }, []);

    useEffect(() => {
        getListReview(true, paramsReport, currentTab);
        setLocalStorage(REVIEW_PAGE_CURRENT_TAB, currentTab);
        return () => {
            handleAbortController(abortController);
        };
    }, [currentTab]);

    useEffect(() => {
        let timer = null;
        if (isLoadingMore) {
            timer = setTimeout(() => {
                getListReview(
                    false,
                    {
                        ...getLocalStorage(keyTabLocalStorage),
                        offset: finalData.length,
                        reloadFilter: reviewState.reloadFilter
                    },
                    currentTab,
                    true
                );
            }, 200);
        }

        return () => {
            timer && clearTimeout(timer);
        };
    }, [isLoadingMore]);

    const _filter = (filter = {}) => {
        let params = getLocalStorage(keyTabLocalStorage);
        params = { ...params, ...filter };
        setLocalStorage(keyTabLocalStorage, params);
    };

    const _handleUpdate = () => {
        abortController.current.abort();
        getListReview(true, getLocalStorage(keyTabLocalStorage), currentTab);
    };

    const _getNewParams = (params) => {
        const newParams = getLocalStorage(keyTabLocalStorage);
        newParams.items = params?.items?.toString();
        newParams.users = params?.users?.toString();
        delete newParams?.items_likely;
        if (PARAMS_LOCAL_REVIEW[currentTab] === REPORT_TYPE.REVIEW_PAGE_PARAMS_LIKELY)
            newParams.items = params?.items_likely?.toString();
        newParams.limit = REPORT_LIMIT;
        newParams.offset = params?.offset;
        return newParams;
    };

    const getListReview = (isReset = false, params = {}, currentTab, notShowLoading = false) => {
        refAlert.current?.clearAllStatusBar();
        handleAbortController(abortController);
        abortController.current = new AbortController();
        const newParams = _getNewParams(params, currentTab);
        const checkShouldLoading = !notShowLoading && !finalIsLoading;
        const checkRefresh = reviewState.reloadFilter;

        checkShouldLoading &&
            dispatchAction((prev) => ({
                ...prev,
                total: isReset ? 0 : prev.total,
                listReview: isReset ? [] : prev.listReview,
                isLoading: !!checkShouldLoading || prev.isLoading,
                currentTab: currentTab
            }));

        // Handle when success
        const _getListSuccess = ({ data, total }, tabQuery) => {
            dispatchAction((prev) => {
                const { listReview: prevData, reloadFilter, refresh } = prev;
                const checkDiffrentTabs = !!tabQuery && reloadFilter !== tabQuery;
                const newData = isReset ? data : checkDiffrentTabs ? prevData : [...prevData, ...data];

                return {
                    ...prev,
                    listReview: newData,
                    total: total,
                    isLoading: false,
                    isLoadingMore: false,
                    refresh: refresh + 1
                };
            });
        };

        const _getListFailure = ({ isAborted = false, message }) => {
            if (isAborted) return;
            dispatchAction((prev) => ({ ...prev, isLoading: false, isLoadingMore: false }));
            refAlert.current?.handeAddStatus({ id: 'review_error', message, type: LIST_STATUS.ERROR });
        };

        clientQuery(
            URL_FETCH_LIST[currentTab],
            { data: { ...reviewState?.params, ...newParams }, method: 'GET', abortController: abortController.current },
            (response) => _getListSuccess(response, checkRefresh),
            _getListFailure
        );
    };

    const _handleScroll = () => {
        const numberData = finalData?.length || 0;
        if (!!numberData && !isLoadingMore && finalTotal > numberData) {
            dispatchAction((prev) => ({ ...prev, isLoadingMore: true, reloadFilter: Date.now() }));
        }
    };

    const _changeTab = (tabName) => () => {
        abortController.current.abort();
        history.push({
            pathname: addBranchPath(tabName === REVIEW_SERVICE_TAB ? REVIEWS_SERVICE : REVIEWS_LIKELY),
            state: { reload: Date.now(), newTab: tabName }
        });
    };

    const _renderTabsButton = () => {
        return REVIEWS_LIST_TAB_BUTTON.map((tab) => {
            const tabId = tab.id;

            return (
                <button
                    key={tabId}
                    type="button"
                    className={classNames('tab-items', { 'active-tab-selector': currentTab === tabId })}
                    onClick={_changeTab(tabId)}
                >
                    {t(`review:${tab.title}`)}
                </button>
            );
        });
    };

    function _getParamsExport() {
        const paramsExport = { ...getLocalStorage(keyTabLocalStorage) };
        switch (currentTab) {
            case REVIEW_SERVICE_TAB:
                return paramsExport;
            case REVIEW_LIKELY_TAB:
                paramsExport.items = paramsExport.items_likely;
                delete paramsExport.items_likely;
                delete paramsExport.users;
                return paramsExport;
            default:
                return null;
        }
    }

    function _getPageExport() {
        switch (currentTab) {
            case REVIEW_SERVICE_TAB:
                return LIST_EXPORT.REVIEW_SERVICE;
            case REVIEW_LIKELY_TAB:
                return LIST_EXPORT.REVIEW_LIKELY;
            default:
                return null;
        }
    }

    if (!checkAddon(addonsList?.review_engine)) {
        return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;
    }

    function _renderHeaderLeft() {
        return (
            <>
                <div className="header-items tabs">
                    <div className="btn-item ml-0">{_renderTabsButton()}</div>
                </div>
            </>
        );
    }

    function _renderHeaderRight() {
        return (
            <Export
                title={generateTitleExport(finalTotal, 'report', 'item')}
                url={`${URL_EXPORT_REVIEW_RATINGS}/${currentTab}`}
                params={_getParamsExport()}
                pageExport={_getPageExport()}
                refresh={refresh}
                isDisable={reviewState.isLoading}
                activePrint
            />
        );
    }

    return (
        <Fragment>
            <MainHeaderReport
                contentLeft={_renderHeaderLeft}
                contentRight={_renderHeaderRight}
                reportType={REPORT_TYPE.REVIEW}
                onSelectTab={_handleUpdate}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages has-tab gap-8">
                    <GDStatusBar ref={refAlert} />
                    <HeaderBottom
                        typeReport={typeReview}
                        filters={currentFilter}
                        handleChangeFilter={_filter}
                        handleUpdate={_handleUpdate}
                        currentTab={currentTab}
                        isLoading={reviewState.isLoading}
                        forceRerenderFilter={reviewState?.forceRerender}
                    />

                    <div className="wrap-tables tab-contents scrolls-x">
                        <div className="tab-conts tab-content-active">
                            <TabContentReview
                                paramsService={paramsReviewService}
                                paramsLikely={paramsReviewLikely}
                                data={finalData || []}
                                activeTab={currentTab}
                                isLoading={reviewState.isLoading}
                                handleUpdate={_handleUpdate}
                                typeReview={typeReview}
                                isLoadmore={isLoadingMore}
                                onScrollToEnd={_handleScroll}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Review;

import moment from 'moment';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import GdConfirm from 'app/components/confirm';
import { ATTACHMENT_PRESIGN } from 'app/const/Api';
import { ACCEPTED_CUSTOMER_ATTACHMENTS } from 'app/const/App';
import { LIMIT_TOTAL_SIZE_FILES } from 'app/const/Settings';
import { LINK_CDN_IMG_BROKEN } from 'app/const/URL';
import IconAttachment from 'assets/icon/IconAttachment';
import { clientQuery } from 'common/utils/ApiUtils';
import { bytesToMegaBytes, bytesToSize, uploadToS3 } from 'common/utils/FileUtils';
import { limitSizeAttachment } from '../utils';

const AddAttachment = (props, ref) => {
    const {
        itemId,
        totalSize = 0,
        type,
        limitTotalSize = LIMIT_TOTAL_SIZE_FILES,
        onUpdate = () => {},
        onAddNew = () => {},
        onDelete = () => {}
    } = props;
    const { t } = useTranslation(['common', 'settings']);
    const refUpload = useRef(null);
    const refConfirm = useRef(null);

    useImperativeHandle(ref, () => ({ uploadAttachments: _handleUploadAttachments }));

    const _uploadClick = () => refUpload.current.click();

    const _uploadAttachments = (event) => {
        _handleUploadAttachments(event.target.files);
        refUpload.current.value = '';
    };

    const _handleUploadAttachments = (files = []) => {
        try {
            // If no file selected, reset the input.
            if (!files?.length) return;

            let tempSize = totalSize;
            for (let index = 0; index < files.length; index++) {
                const file = files[index];
                tempSize += file.size;

                // Just support total files size 15Mbs | LIMIT_TOTAL_SIZE_FILES.
                if (bytesToMegaBytes(tempSize) > limitTotalSize) {
                    alert(t('total_size_limit', { size: limitTotalSize }));
                    return;
                }

                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                    _addNewAttachments({
                        id: file.lastModified,
                        file,
                        name: file.name,
                        // TODO: Should replace with image loading not broken
                        // For now, we use file-broken.png
                        url: LINK_CDN_IMG_BROKEN,
                        mime: file.type
                    });
                };
            }
        } catch (error) {
            console.error(error.message);
        }
    };

    const _addNewAttachments = (attachment) => {
        if (Array.isArray(attachment)) {
            attachment.forEach((attach) => {
                _addAttachWithQuery(attach);
            });
        } else {
            _addAttachWithQuery(attachment);
        }
    };

    const _addAttachWithQuery = (attach) => {
        if (limitSizeAttachment(attach.file.size)) {
            refConfirm.current.open(null, t('setting:maximum_file_size', { size: '20', unit: 'MB' }));
            return;
        }

        const idAttach = `${attach.name}${moment().format('x')}`;
        const dataPreAdd = { ...attach };
        const optionQuery = {
            data: { name: `web-${attach.name}`, item_id: itemId, type },
            method: 'POST'
        };

        delete dataPreAdd.file;
        onAddNew({
            ...attach,
            size: bytesToSize(attach.file.size),
            type: attach.mime,
            id: idAttach,
            isUploading: true
        });

        const _addSuccess = (response) => {
            const { presigned_url, object_key, object_tag, thumbnail_url, public_url } = response.data;
            const data = { idAttach, object_key, object_tag, thumbnail_url, public_url };
            const optionsHeaders = { 'x-amz-tagging': object_tag };
            uploadToS3(presigned_url, optionsHeaders, attach.file, data, _uploadS3Success, _uploadS3Failure);
        };

        const _addFailure = () => {
            _removeAttach(idAttach);
        };

        clientQuery(ATTACHMENT_PRESIGN, optionQuery, _addSuccess, _addFailure);
    };

    const _uploadS3Success = ({ idAttach, object_key, object_tag, thumbnail_url, public_url }) => {
        onUpdate({ idAttach, object_key, object_tag, thumbnail_url, public_url });
    };

    const _uploadS3Failure = ({ idAttach }) => {
        _removeAttach(idAttach);
    };

    const _removeAttach = (idAttach) => {
        onDelete(idAttach);
    };

    return (
        <>
            <div onClick={_uploadClick} className="v2-btn-default --icon-lg --transparent tooltip">
                <IconAttachment />
                <p className="tooltiptext top">{t('common:attachment_files')}</p>
                <input
                    onChange={_uploadAttachments}
                    type="file"
                    ref={refUpload}
                    multiple
                    className="dp-hide"
                    accept={ACCEPTED_CUSTOMER_ATTACHMENTS.join(',')}
                />
            </div>
            <GdConfirm
                ref={refConfirm}
                title={t('common:confirm')}
                listButton={{ confirm: true, cancel: false }}
                titleConfirm={t('common:confirm')}
            />
        </>
    );
};

export default forwardRef(AddAttachment);

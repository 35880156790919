import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { checkLimtFileSize } from 'common/utils/FileUtils';
import { useSelector } from 'react-redux';

import MessageTextarea from './MessageTextarea';
import MessageTitleBox from './MessageTitleBox';
import MessageActions from './MessageActions';
import GdConfirm from 'app/components/confirm';
import GDModalWarning from 'app/components/modal/ModalWarning';
import MessageFrom from './MessageFrom';
import { createUUID } from 'common/utils/StringUtils';

const MessageBox = forwardRef(
    (
        {
            customerName = '',
            customerId = null,
            receivers = [],
            onSubmit = () => {},
            toggleActive = () => {},
            isUnknow,
            defaultSender,
            autoOpenBoxMessage = false
        },
        ref
    ) => {
        const { t } = useTranslation(['messages']);
        const bookMarkList = useSelector((state) => state.bookMarkReducer.list);
        const refTextarea = useRef(null);
        const refTitleBox = useRef(null);
        const refMessageBox = useRef(null);
        const refConfirm = useRef(null);
        const refWarning = useRef(null);
        const refMessageFrom = useRef(null);
        const refMessageAction = useRef(null);

        useImperativeHandle(ref, () => ({
            focusTextarea: () => refTextarea.current.focus(),
            closeFocus: () => {
                refMessageBox.current.classList = 'box-message-chat';
            },
            addAttachments: _addAttachments,
            getTextareaValue: () => refTextarea.current.getValueMessage(),
            setDraftSMSValue: _handleSyncDraftValue,
            getAttachments: () => refTextarea.current.getAttachments()
        }));

        useEffect(() => {
            if (autoOpenBoxMessage) {
                _handleActiveMessageBox(null, true);
                refTextarea.current?.focus();
            }
            _handleSetDraft();
        }, []);

        const _addTags = ({ value, attachments }, isConcat = false) => {
            const textarea = refTextarea.current.getCurrent();
            const { selectionStart, value: valueText } = textarea;
            const newSelectionStart = selectionStart + value.length;
            const newValue = isConcat
                ? valueText.concat(value)
                : [valueText.slice(0, selectionStart), value, valueText.slice(selectionStart)].join('');

            _handleActiveMessageBox(null, true);

            textarea.value = newValue;

            textarea.setSelectionRange(newSelectionStart, newSelectionStart);
            if (!!attachments?.length) {
                refTextarea.current.setAttachments(
                    [...refTextarea.current.getAttachments(), ...attachments].map((item) => ({
                        ...item,
                        tempObjectKey: item.tempObjectKey || item.object_key,
                        type: item.type || item.mime,
                        object_key: createUUID()
                    }))
                );
            }
        };

        const _addEmoji = (emoji) => {
            _addTags({ value: emoji.native }, false);
        };

        const _addAttachments = (attachments) => {
            refTextarea.current.addAttachment(attachments);
            _handleActiveMessageBox(null, true);
        };

        const _submitSendMessage = () => {
            const attachments = refTextarea.current.getAttachments();
            const message = refTextarea.current.getValueMessage();
            const phone = refTitleBox.current.getPhoneSend();
            const sender = refMessageFrom.current.getData();

            if (checkLimtFileSize(attachments)) {
                refWarning.current._open({
                    subDescription: t('header:error_to_big_file_size')
                });
                return;
            }

            const newAttachments = attachments.map((itemAttachment) => {
                return {
                    name: itemAttachment.name,
                    object_key: itemAttachment.object_key,
                    type: itemAttachment.type,
                    isUploading: itemAttachment.isUploading
                };
            });

            const dataSubmit = {
                customer_id: customerId,
                phone,
                attachments: attachments.map(({ name, type, object_key, tempObjectKey }) => {
                    return { name, object_key: tempObjectKey || object_key, type };
                }),
                message,
                sender: sender
            };

            if (!Array.isArray(phone) || !phone?.length) {
                return refConfirm.current.open(null, t('pls_update_customer_phone'));
            }
            if (!message.trim()) return refTextarea.current.focus();
            if (newAttachments.some((attach) => attach.isUploading)) {
                return alert(t('some_attachments_uploading'));
            }
            onSubmit(dataSubmit);
            _clearDataForm();

            _handleActiveMessageBox();
        };

        const _clearDataForm = () => {
            const textarea = refTextarea.current.getCurrent();
            refTextarea.current.clearAttachments();
            textarea.value = '';
            refMessageAction.current?.setInputValue('');
        };

        function _handleActiveMessageBox(e, active = false) {
            e && e.stopPropagation();

            if (active) {
                refMessageBox.current.classList = 'box-message-chat active';
                refTextarea.current.focus();
            } else {
                refMessageBox.current.classList = 'box-message-chat';
            }

            toggleActive(e, active);
        }

        const _handleCloseConfirm = () => {
            refWarning.current._close();
        };

        const _handleSetDraft = () => {
            const item = bookMarkList.find(({ data }) => data && data.id === customerId);
            if (!item) return;

            const { text: draftValue = '', attachments: draftAttachments = [] } = item.data?.draft || {};

            if (draftValue) {
                refTextarea.current?.setValue(draftValue);
                refMessageAction.current?.setInputValue(draftValue);
            }

            if (!!draftAttachments.length) refTextarea.current?.setAttachments(draftAttachments);
        };

        const _handleSyncDraftValue = () => {
            const textarea = refTextarea.current.getCurrent();
            const textareaValue = textarea.value;
            if (refMessageAction.current && refMessageAction.current.getValue() !== textareaValue) {
                refMessageAction.current.setInputValue(textareaValue);
            }
        };

        return (
            <div ref={refMessageBox} className={'box-message-chat'}>
                <div onClick={(e) => _handleActiveMessageBox(e, true)} className="content-message">
                    <MessageFrom ref={refMessageFrom} defaultSender={defaultSender} />
                    <MessageTitleBox ref={refTitleBox} receivers={receivers} customerName={customerName} />
                    <MessageTextarea ref={refTextarea} onSubmit={_submitSendMessage} customerId={customerId} />
                </div>
                <MessageActions
                    ref={refMessageAction}
                    onAddTags={_addTags}
                    onAddEmoji={_addEmoji}
                    onAddAttachment={_addAttachments}
                    onClick={_handleActiveMessageBox}
                    onSubmit={_submitSendMessage}
                    isUnknow={isUnknow}
                />
                <GdConfirm
                    ref={refConfirm}
                    title={t('common:confirm')}
                    listButton={{ confirm: true, cancel: false }}
                    titleConfirm={t('common:confirm')}
                />
                <GDModalWarning
                    ref={refWarning}
                    descriptionClassName="text"
                    id="sms_warning_type"
                    title={t('header:gorilladesk_says')}
                    isDisplayClose={false}
                    footer={
                        <div className="footer-modal btn-close justify-end">
                            <div className="v2-btn-main" onClick={_handleCloseConfirm}>
                                {t('common:confirm')}
                            </div>
                        </div>
                    }
                />
            </div>
        );
    }
);

export default React.memo(MessageBox);

export default function IconOpen() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.0092 13.5H7.9908C8.8167 13.5 9.15092 13.5928 9.47685 13.7671C9.80277 13.9414 10.0586 14.1972 10.2329 14.5232C10.4072 14.8491 10.5 15.1833 10.5 16.0092V16.9908C10.5 17.8167 10.4072 18.1509 10.2329 18.4768C10.0586 18.8028 9.80277 19.0586 9.47685 19.2329C9.15092 19.4072 8.8167 19.5 7.9908 19.5H7.0092C6.1833 19.5 5.84908 19.4072 5.52315 19.2329C5.19723 19.0586 4.94144 18.8028 4.76713 18.4768C4.59283 18.1509 4.5 17.8167 4.5 16.9908V16.0092C4.5 15.1833 4.59283 14.8491 4.76713 14.5232C4.94144 14.1972 5.19723 13.9414 5.52315 13.7671C5.84908 13.5928 6.1833 13.5 7.0092 13.5Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M4.5 9.5V8.34583C4.5 7.00855 4.63924 6.52362 4.9007 6.03473C5.16216 5.54584 5.54584 5.16216 6.03473 4.9007C6.52362 4.63924 7.00855 4.5 8.34583 4.5H15.6542C16.9915 4.5 17.4764 4.63924 17.9653 4.9007C18.4542 5.16216 18.8378 5.54584 19.0993 6.03473C19.3608 6.52362 19.5 7.00855 19.5 8.34583V15.9294C19.5 16.9364 19.3608 17.4764 19.0993 17.9653C18.8378 18.4542 18.4542 18.8378 17.9653 19.0993C17.4764 19.3608 16.9364 19.5 15.9294 19.5H15.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M10.5 8.5H15.5V13.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M15.5 8.5L10.5 13.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
}

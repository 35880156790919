import React, { useRef, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import SelectTaxes from './SelectTax';
import SingleSelect from 'app/components/select/singleSelect';
import ButtonSave from 'app/components/button/ButtonSave';
import StatusBar from 'app/components/status/statusbar';
import { LIST_STATUS } from 'app/const/Status';
import { clientQuery } from 'common/utils/ApiUtils';
import { ACTION_QUICKBOOKS_MAPPING } from 'app/const/api/V2';
import { reducer } from 'app/const/Reducer';

export default function FormAdd({ taxCode, dataMapping, onDelete, onUpdateQuickAdd, onAdd }) {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        taxCodeSelected: {}
    });

    const refButtonSave = useRef(null);
    const refSelectTaxes = useRef(null);
    const finalTaxCodeSelected = state.taxCodeSelected || {};

    const refStatusBar = useRef(null);

    function _handleSelectTax(name, value) {
        dispatchState({
            taxCodeSelected: taxCode.find((item) => item.id === value)
        });
    }

    function _handleAddMapping() {
        const listTagsSelected = refSelectTaxes.current.getValue();
        const listIdsSelected = listTagsSelected.map((item) => item.id);
        const idCodeSelected = finalTaxCodeSelected.id;
        const idTemp = new Date().getTime();

        if (!idCodeSelected) {
            return _showAlertError(t('addons:please_select_quick_book_tax'));
        }

        if (!listIdsSelected.length) {
            return _showAlertError(t('addons:please_select_tax_for_mapping'));
        }

        const isExit = dataMapping.some((element) => {
            const { Taxes_GD: listTags = [] } = { ...JSON.parse(element.meta) };
            const elemenCheck = listTags
                .map((item) => item && listIdsSelected.includes(item.id.toString()))
                .some((value) => !value);
            if (!elemenCheck && listTags.length === listIdsSelected.length) {
                return true;
            }
        });

        if (isExit) {
            return _showAlertError(t('addons:groups_taxes_already_exists'));
        }

        const params = {
            tax_code_item: listIdsSelected,
            tax_name_qb: finalTaxCodeSelected.name,
            tax_code_qb: idCodeSelected
        };

        onAdd({
            id: idTemp,
            meta: JSON.stringify({
                Taxes_QB: finalTaxCodeSelected,
                Taxes_GD: listTagsSelected
            }),
            name_group: listTagsSelected
                .slice()
                .map((item) => item.name)
                .join(', '),
            tax_name_qb: finalTaxCodeSelected.name,
            quickAdd: true
        });

        clientQuery(
            ACTION_QUICKBOOKS_MAPPING,
            { method: 'POST', data: params },
            (res) => _addMappingSuccess(res, idTemp),
            (res) => _addMappingFailed(res, idTemp)
        );
    }

    function _addMappingSuccess(res, oldId) {
        onUpdateQuickAdd(oldId, res.data.id);
        refStatusBar.current.showStatusBar(LIST_STATUS.SUCCESS, t('common:add_successfully'), LIST_STATUS.SUCCESS);
        _removeLoading();
    }

    function _addMappingFailed(res, oldId) {
        onDelete(oldId);
        _showAlertError(res?.message?.toString() || t('common:please_try_again'));
    }

    function _showAlertError(message) {
        refStatusBar.current.showStatusBar(LIST_STATUS.ERROR, message, LIST_STATUS.ERROR);
        _removeLoading();
        return false;
    }

    function _removeLoading() {
        refButtonSave.current.removeLoading();
    }

    return (
        <>
            <StatusBar ref={refStatusBar} />
            <div className="rows">
                <span className="txt">{t('addons:quick_books_tax')}</span>
                <SingleSelect
                    options={taxCode}
                    title={'Quickbooks Tax'}
                    fileTranslation="calendar"
                    name="all"
                    selected={finalTaxCodeSelected.id || ''}
                    onSelect={_handleSelectTax}
                    classWrapper={'v2-dropdown'}
                />
            </div>
            <div className="rows my-2">
                <span className="txt">{t('addons:select_tax')}</span>
                <SelectTaxes ref={refSelectTaxes} />
            </div>
            <ButtonSave ref={refButtonSave} onSave={_handleAddMapping} title={t('common:save')} />
        </>
    );
}

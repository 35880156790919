import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { ADDONS_FOOTER_OPTIONS } from 'app/const/addons';
import { reducer } from 'app/const/Reducer';
import IconPlay from 'assets/icon/IconPlay';

const ButtonAIChatBot = ({ onOpen = () => {} }, ref) => {
    const { t } = useTranslation('addons');
    const [state, dispatchState] = useReducer(reducer, { isDisable: false });
    const { isDisable } = state;

    useImperativeHandle(ref, () => ({ setDisable: _handleSetDisable }));

    const _handleSetDisable = (value) => {
        dispatchState((prev) => ({ ...prev, isDisable: value }));
    };

    const _handleClick = () => {
        _handleSetDisable(true);
        onOpen(ADDONS_FOOTER_OPTIONS.CHATBOT_AI_SMS);
    };

    return (
        <div className="box-test-sms boxs-notification w-100">
            <div
                className={classNames('v2-btn-main has-icon --bg-green w-100 justify-center', {
                    'is-disable active-chatbot': isDisable
                })}
                onClick={_handleClick}
            >
                <IconPlay isWhite />
                <span className="txt-chatbot txt-ellipsis" title={t('test_chatbot')}>
                    {t('test_chatbot')}
                </span>
            </div>
        </div>
    );
};

export default forwardRef(ButtonAIChatBot);

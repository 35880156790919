import { reducer } from 'app/const/Reducer';
import classNames from 'classnames';
import React, { useContext, useLayoutEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, NavLink, useLocation, useParams, matchPath } from 'react-router-dom';

import { CUSTOMERS, CUSTOMERS_INVOICES } from 'app/config/routes';
import { CUSTOMER_MAIN_NAVIGATION, CUSTOMER_SUB_NAVIGATION } from 'app/const/Customers';
import IconArrow from 'assets/icon/IconArrow';
import { getPermissionCustomerDetail, isNotPermissionValue } from 'common/utils/PermissionUtils';
import { CustomerDetailContext } from '../../context/CustomerDetailContext';
import ButtonSetupLayout from './ButtonSetupLayout';
import { addBranchPath, addPrefixPath } from 'app/const/Branch';

const HeaderNavigation = () => {
    return (
        <div className="wrap-menu flexcenter relative">
            <ButtonSetupLayout />
            <Link to={addBranchPath(CUSTOMERS)} className="v2-btn-default --icon-lg flex-auto mr-3">
                <IconArrow isPrev />
            </Link>
            <div className="tabs">
                <ListNavigator data={CUSTOMER_MAIN_NAVIGATION} />
                <div className="is-divider" />
                <ListNavigator data={CUSTOMER_SUB_NAVIGATION} />
            </div>
        </div>
    );
};

const ListNavigator = ({ data = [] }) => {
    const { t } = useTranslation('customers');
    const { id } = useParams();
    const { pathname } = useLocation() || {};
    const { _handleReloadCustomer } = useContext(CustomerDetailContext);

    const [state, dispatchState] = useReducer(reducer, { widthItem: 0, offset: 0 });
    const { widthItem, offset } = state;

    const addons = useSelector(({ auth }) => auth.user.settings.addons);
    const permissionsList = useSelector((state) => state.auth.user?.permissions?.enabled || []);

    const isShow = data.some((item) => addBranchPath(item?.to?.replace(':id', id)) === pathname);
    const permissions = getPermissionCustomerDetail(addons, permissionsList);
    const oldPathName = useRef(pathname);
    const parentRef = useRef(null);
    const activeTabRef = useRef(null);

    useLayoutEffect(() => {
        oldPathName.current = pathname;

        const currentActiveItem = activeTabRef.current;
        if (!currentActiveItem) return;

        const width = currentActiveItem.offsetWidth;
        const offset = currentActiveItem.offsetLeft;

        if (width === 0) {
            console.warn('Active tab width is 0');
            return;
        }
        dispatchState((prev) => ({ ...prev, widthItem: width, offset }));
        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry.target === currentActiveItem) {
                    const width = currentActiveItem.offsetWidth;
                    const offset = currentActiveItem.offsetLeft;

                    if (width === 0) {
                        console.warn('Active tab width is 0');
                        return;
                    }
                    dispatchState((prev) => ({ ...prev, widthItem: width, offset }));
                }
            }
        });

        resizeObserver.observe(currentActiveItem);

        return () => {
            resizeObserver.unobserve(currentActiveItem);
        };
    }, [pathname]);

    useLayoutEffect(() => {
        if (!isShow) dispatchState((prev) => ({ ...prev, widthItem: 0, offset: 0 }));
    }, [isShow]);

    const _handleChangeLocation = (pathTo) => {
        if (!matchPath(pathTo, { path: addPrefixPath(CUSTOMERS_INVOICES), exact: true }))
            _handleReloadCustomer(oldPathName.current === pathTo ? Date.now() : 0);
        parentRef?.current?.classList.remove('no-effect');
    };

    return (
        <div ref={parentRef} className={classNames('btn-item bg-white ml-0 relative', { 'no-effect': isShow })}>
            {isShow ? (
                <div className="slide-tab" style={{ width: widthItem, transform: `translateX(${offset}px)` }} />
            ) : null}
            {data.map((nav) => {
                const navId = nav.id;
                const linkTo = addBranchPath(nav.to.replace(':id', id));
                if (isNotPermissionValue(permissions, navId)) return null;
                return (
                    <NavLink
                        exact
                        ref={pathname === linkTo ? activeTabRef : null}
                        key={nav.id}
                        to={linkTo}
                        className="tab-items"
                        activeClassName="active-tab-selector"
                        onClick={() => _handleChangeLocation(linkTo)}
                    >
                        {t(nav.title)}
                    </NavLink>
                );
            })}
        </div>
    );
};

export default HeaderNavigation;

import { TYPE_WORK_POOL_ACTION } from 'common/redux/actions/calendar/workPoolAction';

const initWPMovedCalendar = { data: [], isMoveWPToNormal: false, isMoveToWP: false };
const WorkPoolAction = (
    state = {
        idsRemove: {},
        wpMovedCalendar: initWPMovedCalendar,
        eventUpdateJL: [],
        jobMoveToWP: [],
        realtimeStatus: null,
        realtimeDelete: null
    },
    action
) => {
    switch (action.type) {
        case TYPE_WORK_POOL_ACTION.ACTION_MOVE_WP_TO_CALENDAR_SUCCESS:
            return { ...state, idsRemove: action.params };
        case TYPE_WORK_POOL_ACTION.ACTION_MOVE_JL_TO_CALENDAR_SUCCESS:
            return { ...state, eventUpdateJL: action.params };
        case TYPE_WORK_POOL_ACTION.ACTION_REMOVE_MOVE_WP_TO_CALENDAR:
            return {
                ...state,
                idsRemove: {},
                wpMovedCalendar: initWPMovedCalendar,
                eventUpdateJL: [],
                jobMoveToWP: [],
                realtimeStatus: null,
                realtimeDelete: null
            };
        case TYPE_WORK_POOL_ACTION.ACTION_MOVE_CALENDAR_TO_WP_SUCCESS:
            return { ...state, jobMoveToWP: action.params };
        case TYPE_WORK_POOL_ACTION.ACTION_WORK_POOL_REAL_TIME_STATUS:
            return { ...state, realtimeStatus: action.params };
        case TYPE_WORK_POOL_ACTION.ACTION_WORK_POOL_REAL_TIME_DELETE_JOB:
            return { ...state, realtimeDelete: action.params };
        case TYPE_WORK_POOL_ACTION.ACTION_WORK_POOL_REAL_TIME_MOVE_JOB:
            return { ...state, realtimeMove: action.params };
        case TYPE_WORK_POOL_ACTION.ACTION_WORK_POOL_MOVE_RESIZE_ON_CALENDAR:
            return { ...state, wpMovedCalendar: action.payload || initWPMovedCalendar };
        default:
            return state;
    }
};

export default WorkPoolAction;

import React from 'react';
const IconBoolean = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
            <rect x="3.65" y="6.65" width="16.7" height="10.7" rx="5.35" stroke="var(--color-icon)" strokeWidth="1.3" />
            <path
                d="M18 12C18 13.6569 16.6569 15 15 15C13.3431 15 12 13.6569 12 12C12 10.3431 13.3431 9 15 9C16.6569 9 18 10.3431 18 12Z"
                fill="var(--color-icon)"
            />
        </svg>
    );
};
export default IconBoolean;

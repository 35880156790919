import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';
import { LIST_TABLE_ITEM_TYPE, COMMON } from '../App';

export const getGidColumns = (actionsStatus) => {
    const HEADER_PAYMENTS_COLLECTED = {
        invoice_number: {
            title: 'payment_for',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.ASC
        },
        customer: {
            title: 'customer',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        method: {
            title: 'method',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_state: {
            title: 'service_state',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        staff_name: {
            title: 'staff',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        date: {
            title: 'date',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        amount: {
            title: 'amount',
            style: 'col col-total',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        w_o_tax: {
            title: 'w_o_tax',
            style: 'col col-total',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        }
    };

    const COLUMNS_PAYMENTS_COLLECTED = [
        {
            id: 'invoice_number',
            title: 'invoice_number',
            status: true
        },
        {
            id: 'customer',
            title: 'customer',
            status: true
        },
        {
            id: 'method',
            title: 'method',
            status: true
        },
        {
            id: 'service_state',
            title: 'service_state',
            status: true
        },
        {
            id: 'staff_name',
            title: 'staff',
            status: true
        },
        {
            id: 'date',
            title: 'date',
            status: true
        },
        {
            id: 'amount',
            title: 'amount',
            status: true
        },
        {
            id: 'w_o_tax',
            title: 'w_o_tax',
            status: true
        }
    ];

    const CONTENT_PAYMENTS_COLLECTED = {
        invoice_number: {
            id: 'invoice_number',
            style: 'col col-md',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.PAYMENT_FOR
        },
        customer: {
            id: 'customer',
            style: 'col col-xl',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO
        },
        method: {
            id: 'method',
            style: 'col col-xl'
        },
        service_state: {
            id: 'service_state',
            style: 'col col-md'
        },
        staff_name: {
            id: 'staff_name',
            style: 'col col-sm'
        },
        date: {
            id: 'date',
            style: 'col col-md'
        },
        amount: {
            id: 'amount',
            style: 'col col-total',
            type: LIST_TABLE_ITEM_TYPE.CURRENCY_SYMBOL
        },
        w_o_tax: {
            id: 'w_o_tax',
            style: 'col col-total',
            type: LIST_TABLE_ITEM_TYPE.CURRENCY_SYMBOL
        }
    };

    const newHeader = mapCurrentValueHeader(actionsStatus, { ...HEADER_PAYMENTS_COLLECTED });

    return {
        header: newHeader,
        columns: COLUMNS_PAYMENTS_COLLECTED,
        contentConfig: CONTENT_PAYMENTS_COLLECTED
    };
};

import i18n from 'assets/i18n';
import moment from 'moment';

export const getExampleMap = () => {
    const i18nLanguageCode = i18n.language;
    return {
        '': '',
        invoice_total: '$45',
        customer_name: 'Christopher Moreschi',
        location_name: 'Home',
        location_tags: i18n.t('customers:location_tags'),
        service_type: 'Ant Service',
        service_zip: '98034',
        service_city: 'Kirkland',
        calendar_time: '9:15-9:45',
        time_window: '9:15 to 9:45',
        service_address_1: '134th Court Northeast',
        service_address_county: 'King County',
        balance: '$0.00',
        customer_tags: i18n.t('customers:customer_tags'),
        company_name: 'TruGanix',
        location_notes: '1015 Highway 59 Loop N',
        last_service_date: moment('Mon Jan 16').locale(i18nLanguageCode).format('LL'),
        next_service_date: moment('Mon Jan 23').locale(i18nLanguageCode).format('LL'),
        job_count: '1/3',
        account_number: '16042',
        work_order: '571507',
        po_number: 'PO 5000'
    };
};

const NUMBER_FIELDS = 10;

export { NUMBER_FIELDS };

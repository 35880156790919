import React from 'react';
import { useTranslation } from 'react-i18next';

const Summary = ({ summary = [] }) => {
    const { t } = useTranslation();

    return (
        <div className="container-setting-center mt-3">
            <div className="header-modal">
                <h3 className="name-tabs">{t('setting:summary')}</h3>
            </div>
            <div className="details-job">
                {summary.map((item, index) => (
                    <div key={index.toString()} className="summary-items">
                        {item}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Summary;

import moment from 'moment';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import ButtonSave from 'app/components/button/ButtonSave';
import StatusBar from 'app/components/status/statusbar';
import { createInvoice } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import { DEFAULT_VALUE, TABS_INOIVE_REPEAT } from 'app/modules/jobdetail/const/Invoice';
import AddInvoiceContextProvider from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import ConfirmAddInvoice from 'app/modules/jobdetail/modal/ConfirmAddInvoice';
import InvoiceInfo from 'app/modules/jobdetail/tabs/addinvoice/form/info';
import InvoiceService from 'app/modules/jobdetail/tabs/addinvoice/form/items';
import InvoiceNotes from 'app/modules/jobdetail/tabs/addinvoice/form/notes';
import InvoiceRepeat from 'app/modules/jobdetail/tabs/addinvoice/form/settings/ButtonRepeat';
import InvoiceSummary from 'app/modules/jobdetail/tabs/addinvoice/form/summary';
import InvoiceTerms from 'app/modules/jobdetail/tabs/addinvoice/form/terms';
import { mixpanelAddInvoice } from 'app/modules/mixpanel/MixpanelAddInvoice';
import CustomerInfo from 'app/modules/quickadd/invoice/CustomerInfo';
import IconClose from 'assets/icon/IconClose';
import { useEnterKeydownClick } from 'common/hooks';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { convertTimeToISO } from 'common/utils/DateUtils';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';

const NewInvoice = forwardRef(({ onAddSuccess = () => {}, defaultCustomer = null }, ref) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false
    });
    const refSummary = useRef();
    const refConfirm = useRef(null);
    const refStatusBar = useRef(null);
    const refButtonSave = useRef(null);
    const dispatch = useDispatch();
    const profileId = useSelector(({ auth }) => auth.user.profile.id);
    const isVisible = state.isVisible;
    // eslint-disable-next-line no-undef
    const defaultCustomerFinal = global.customerDetail || defaultCustomer;

    useEnterKeydownClick(isVisible);

    useImperativeHandle(ref, () => ({
        _handleOpenForm
    }));

    const _handleOpenForm = () => {
        dispatchState({
            isVisible: true
        });
    };

    const _handleCloseForm = () => {
        dispatchState({
            isVisible: false
        });
    };

    const dateRef = moment().toISOString();

    const defaultData = {
        ...DEFAULT_VALUE,
        customer_job_id: null,
        customer_id: defaultCustomerFinal?.id || '',
        defaultCustomer: defaultCustomerFinal,
        location_id: null,
        locationInfo: {},
        date: dateRef,
        recurrence: {
            ...DEFAULT_VALUE.recurrence,
            offset: {
                ...DEFAULT_VALUE.recurrence.offset,
                nextDateInvoice: dateRef,
                dateIssue: dateRef
            }
        },
        isInvoiceRecurring: false,
        isJobInvoice: false,
        isAddInternal: !!defaultCustomer,
        note_repeat: 0,
        payment_terms: {}
    };

    function _handleRemoveLoading() {
        refButtonSave.current && refButtonSave.current.removeLoading();
    }

    const _handleSaveInvoice = (trigger_action) => {
        refConfirm.current._handleCloseForm();

        const refValue = refSummary.current.getValue();
        const listItems = [...refValue.items];
        const locationId = refValue.location_id;

        const itemsSubmit = [];
        let checkPass = true;

        if (!locationId) {
            refStatusBar.current.showStatusBar('show_error', t('common:please_select_location'), LIST_STATUS.ERROR);

            const elm = document.getElementById('location_id');
            elm && elm.classList.add('field-error');
            const elmClick = document.getElementById('location_id_trigger_click');
            elmClick && elmClick.click();
            _handleRemoveLoading();
            return false;
        }

        if (!refValue.customer_id) {
            checkPass = false;
            const elm = document.getElementById('com_select_customer_info');
            elm && elm.classList.add('field-error');
        }

        listItems.forEach((element) => {
            const tax1Detail = element.tax1 || {};
            const tax2Detail = element.tax2 || {};
            const checkItemCost = element.cost.value.length !== 0;
            const serviceItemId = element.item_id;
            const itemId = element.id;

            if (checkItemCost && serviceItemId) {
                itemsSubmit.push({
                    item_id: serviceItemId,
                    name: element.name,
                    quantity: element.quantity,
                    cost: element.cost.value,
                    tax1_id: tax1Detail?.id || '',
                    tax2_id: tax2Detail?.id || '',
                    tax1_name: tax1Detail?.name || '',
                    tax2_name: tax2Detail?.name || '',
                    tax1_rate: tax1Detail.rate || '',
                    tax2_rate: tax2Detail.rate || '',
                    description: element.description,
                    one_time: element.one_time || 0,
                    sold_by_ids: element.sold_by_ids
                });
            } else {
                checkPass = false;
                if (!checkItemCost) {
                    document.getElementById(`service_item_cost_${itemId}`).classList.add('field-error');
                }
                if (!serviceItemId) {
                    document.getElementById(`service_item_detail_${itemId}`).classList.add('field-error');
                }
            }
        });

        if (!checkPass) {
            refStatusBar.current.showStatusBar(
                'show_error',
                t('common:one_or_more_invoice_field_not_complete'),
                LIST_STATUS.ERROR
            );
            _handleRemoveLoading();
            return false;
        }

        const recurrence = { ...refValue.recurrence };
        const offset = recurrence.offset;
        const finalDiscount = { ...refValue.discount };
        delete offset.dateIssue;
        delete offset.nextDateInvoice;
        delete finalDiscount.total;

        clientQuery(
            createInvoice,
            {
                method: 'POST',
                data: {
                    customer_job_id: null,
                    customer_id: refValue.customer_id,
                    discount: finalDiscount,
                    number: refValue.number,
                    po_number: refValue.po_number,
                    date: refValue.date,
                    items: itemsSubmit,
                    subtotal: refValue.subtotal,
                    total: refValue.total,
                    trigger_action: trigger_action,
                    recurrence: {
                        action: recurrence.action,
                        offset: offset
                    },
                    location_id: locationId,
                    terms: refValue.terms,
                    note: refValue.note,
                    note_repeat: offset.frequency === TABS_INOIVE_REPEAT.OFF ? 0 : refValue.note_repeat,
                    payment_terms_id: refValue.payment_terms.id || ''
                }
            },
            _handleSaveInvoiceSuccess,
            _handleSaveInvoiceFail
        );
    };

    const _handleSaveInvoiceSuccess = ({ data }) => {
        handleTrackingEvent(mixpanelAddInvoice({ id: profileId }));
        _handleRemoveLoading();
        onAddSuccess();
        _handleCloseForm();
        dispatch(
            actionOpenInvoice({
                id: data.id,
                status: data.status,
                total: data.total,
                isRecurring: false
            })
        );
    };

    const _handleSaveInvoiceFail = (response) => {
        _handleRemoveLoading();
        refStatusBar.current.showStatusBar(
            'show_error',
            response?.message?.toString() || t('common:please_try_again'),
            LIST_STATUS.ERROR
        );
    };

    const _handleOpenConfirm = () => {
        const refValue = refSummary.current.getValue();
        const dateNow = convertTimeToISO(moment());
        const currentInvoiceData = refValue.date;
        const isInvoiceDatePast =
            moment(currentInvoiceData).utc().startOf('day').unix() <= moment(dateNow).utc().startOf('day').unix();
        const invoiceRecurrence = refValue?.recurrence || {};

        if (invoiceRecurrence.repeat && isInvoiceDatePast) {
            refConfirm.current._handleOpenForm(invoiceRecurrence.action);
        } else {
            _handleSaveInvoice('0');
        }
    };

    const _handleChangeLocation = (value) => {
        refButtonSave.current?.setDisable(value);
    };

    function _handleTriggerButtonSave(value) {
        refButtonSave.current.setDisable(value);
    }

    if (!isVisible) return null;

    return (
        <ReactModal
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            className="modal --new-job container-modal open"
            onRequestClose={_handleCloseForm}
            ariaHideApp={false}
        >
            <div className="modal__overlay bg-fixed" />
            <div className="modal__container">
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('customers:new_invoice')}</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_handleCloseForm} tabIndex="0">
                        <IconClose />
                    </div>
                </div>
                <div
                    className="body-modal form-tabpane-details scrolls c-job-details tab-conts"
                    id="tab_inoive_detail_setting"
                >
                    <div className="container-column form-edit-template has-form elm-parent">
                        <div className="content-elm-edit rows --header-info">
                            <StatusBar ref={refStatusBar} />

                            <div className="content-elm-edit grey-extrlight fs-14 pb-3">
                                <p>{t('common:new_invoice_desc')}</p>
                                <p>{t('common:new_invoice_sub_desc')}</p>
                            </div>
                        </div>

                        <AddInvoiceContextProvider
                            onUpdateButtonSave={_handleTriggerButtonSave}
                            invoiceData={defaultData}
                            onChangeLocation={_handleChangeLocation}
                        >
                            <div className="rows --header-info">
                                <InvoiceRepeat />
                                <div className="content-elm-edit customer-info">
                                    <CustomerInfo isInvoice />
                                    <InvoiceInfo />
                                </div>
                            </div>

                            <InvoiceService showOnTime />
                            <InvoiceSummary ref={refSummary} />

                            <div className="content-elm-edit rows note-details">
                                <div className="dashboard-wrapper --main">
                                    <div className="content-top-notes">
                                        <InvoiceTerms isInvoice />
                                        <InvoiceNotes isInvoice />
                                    </div>
                                </div>
                            </div>
                        </AddInvoiceContextProvider>
                    </div>
                </div>
                <div className="footer-modal btn-close">
                    <span onClick={_handleCloseForm} className="v2-btn-default --noborder --label" tabIndex="0">
                        {t('common:cancel')}
                    </span>
                    <ButtonSave onSave={_handleOpenConfirm} ref={refButtonSave} title={t('common:add')} />
                </div>
            </div>
            <ConfirmAddInvoice
                ref={refConfirm}
                title={t('setting:add_invoices')}
                onClose={_handleRemoveLoading}
                handleConfirm={_handleSaveInvoice}
            />
        </ReactModal>
    );
});

export default NewInvoice;

import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { updateCheckinJob } from 'app/const/Api';
import { KEY_LOCAL_STORAGE_BOOK_MARK_HEADER, TYPES_BOOKMARK } from 'app/modules/calendar/const/BookMark';
import CheckIn from 'app/modules/jobdetail/components/checktime';
import Options from 'app/modules/jobdetail/header/Options';
import { LoadingHeader } from 'app/modules/jobdetail/Loading';
import IconClose from 'assets/icon/IconClose';
import IconUnderscore from 'assets/icon/IconUnderscore';
import { addNewBookMarkAction } from 'common/redux/actions/calendar/bookMarkAction';
import { actionToggleInsertJobNotes } from 'common/redux/actions/notesAction';
import { actionToggleInsertJobImages } from 'common/redux/actions/photosAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import IconArrow from 'assets/icon/IconArrow';

const HeaderJobDetail = ({
    jobData,
    isLoading,
    editCheckTime,
    onClose = () => {},
    onChangeJobData = () => {},
    onGoBackJob = () => {},
    isServicePlan = false,
    servicePlanName
}) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const openTime = useSelector(({ notesReducer }) => notesReducer.toggleInsert?.openTime);
    const openTimeInsertImages = useSelector(({ photosReducer }) => photosReducer.toggleInsert?.openTime);

    const jobDataDetail = { ...jobData.job };
    const jobId = jobDataDetail?.id || 0;
    const checkinLength = jobDataDetail?.check_in?.length || 0;

    function _handleCloseJob(e) {
        e.stopPropagation();
        const bookmark = getLocalStorage(KEY_LOCAL_STORAGE_BOOK_MARK_HEADER) || [];
        if (bookmark.find((item) => item.type === TYPES_BOOKMARK.JOB_DETAIL && item.data.id === jobId))
            _handleBookMarkJob();
        else onClose();

        //This action close  modal insert job note to note visible ( invoice, estimate, workorder )
        if (openTime) {
            dispatch(actionToggleInsertJobNotes(null));
        }

        if (openTimeInsertImages) {
            dispatch(actionToggleInsertJobImages(null));
        }
    }

    function _renderLoading() {
        return <LoadingHeader />;
    }

    if (isLoading) return _renderLoading();

    function _handleCheckin(time) {
        clientQuery(
            updateCheckinJob(jobId),
            {
                method: 'PUT',
                data: {
                    type: 1,
                    date: time
                }
            },
            (reponse) => _checkInSuccess(reponse, time)
        );
    }

    function _checkInSuccess(reponse, time) {
        onChangeJobData({ ...jobData.job, check_in: time });
    }

    function _handleCheckout(time) {
        clientQuery(
            updateCheckinJob(jobId),
            {
                method: 'PUT',
                data: {
                    type: 2,
                    date: time
                }
            },
            (response) => _checkOutSuccess(response, time)
        );
    }

    function _checkOutSuccess(response, time) {
        onChangeJobData({ ...jobData.job, check_out: time });
    }

    function _handleBookMarkJob() {
        dispatch(
            addNewBookMarkAction({
                type: TYPES_BOOKMARK.JOB_DETAIL,
                id: moment().format('x'),
                data: {
                    id: jobId,
                    name: jobDataDetail.name,
                    customer: jobData.customer,
                    status: jobDataDetail.status
                }
            })
        );
        onClose();
    }

    if (isServicePlan) {
        return (
            <div className="header-modal">
                <div className="v2-btn-default has-icon mr-2 " onClick={onGoBackJob}>
                    <IconArrow isPrev />
                    {t('customers:job')}
                </div>
                <h3 className="header-modal__label">{servicePlanName}</h3>
                <div className="action ml-2">
                    <div className="wrap-checktime"></div>
                    <div className="line" />
                    <div className="line" />
                    <div onClick={onClose} className="btn-action btn-close">
                        <IconClose />
                    </div>
                </div>
            </div>
        );
    }

    const isCheckedIn = checkinLength !== 0;

    return (
        <div className="header-modal">
            <h3 className="header-modal__label">{jobDataDetail.name}</h3>
            <div className="action ml-2">
                <div className="wrap-checktime">
                    <CheckIn
                        classBtn={'checkin'}
                        finalText={t('common:check_in')}
                        disableBtn={!editCheckTime && isCheckedIn}
                        timeCheck={jobDataDetail.check_in}
                        onSelect={_handleCheckin}
                        title={t(`jobDetail:${isCheckedIn ? 'reset_check_in_time' : 'record_job_arrival_time'}`)}
                    />
                    <CheckIn
                        timeCheck={jobDataDetail.check_out}
                        classBtn={'checkout'}
                        finalText={t('common:check_out')}
                        onSelect={_handleCheckout}
                        isCheckOut
                        disableBtn={!editCheckTime && jobDataDetail.check_out.length !== 0}
                        activeCheckOut={checkinLength !== 0}
                        title={t(
                            `jobDetail:${
                                jobDataDetail.check_out.length !== 0
                                    ? 'reset_check_out_time'
                                    : 'record_job_departure_time'
                            }`
                        )}
                    />
                </div>
                <div className="line" />

                <Options onClose={onClose} jobData={jobData} />

                <div className="line" />
                <div className="btn-action btn-hide" onClick={_handleBookMarkJob}>
                    <IconUnderscore />
                </div>
                <div onClick={(e) => _handleCloseJob(e)} className="btn-action btn-close">
                    <IconClose />
                </div>
            </div>
        </div>
    );
};

export default HeaderJobDetail;

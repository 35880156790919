import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { LIST_PAYMENT_METHODS, PAYMENT_METHODS } from 'app/modules/jobdetail/const/Invoice';
import { AddPaymentContext } from 'app/modules/jobdetail/contexts/AddPaymentContext';
import IconAmount from 'assets/icon/IconAmount';
import { roundingNumber } from 'common/utils/NumberUtils';

const Amount = ({ autoFocus = false }) => {
    const { t } = useTranslation();
    const { addPayment, updateNumberPaymentContext } = useContext(AddPaymentContext);
    const {
        activeCredit,
        activeDeposit,
        amountValue,
        customerCredit: finalCustomerCredit = {},
        depositData,
        isDeposit,
        invoiceSelected
    } = addPayment;

    const refAmountInvoice = useRef(null);

    const _onKeyDown = (event) => {
        /^[eE]{1}$/.test(event.key) && event.preventDefault();
    };

    useEffect(() => {
        refAmountInvoice.current.value = roundingNumber(amountValue);
    }, [addPayment]);

    function _handleChangeValueCredit(e) {
        e && e.stopPropagation();

        updateNumberPaymentContext({
            activeCredit: !activeCredit,
            isEditAmount: !activeCredit,
            activeDeposit: false,
            amountValue: finalCustomerCredit?.value,
            paymentSelected: LIST_PAYMENT_METHODS[activeCredit ? 1 : 2]
        });
    }

    const _handleToggleApplyDeposit = (e) => {
        e && e.stopPropagation();
        updateNumberPaymentContext({
            activeDeposit: !activeDeposit,
            activeCredit: false,
            isEditAmount: !activeDeposit,
            amountValue: depositData?.value,
            paymentSelected: LIST_PAYMENT_METHODS[activeDeposit ? 1 : 7]
        });
    };

    function _onChangeValue(e) {
        const finalValue = e.target.value;

        updateNumberPaymentContext({
            isEditAmount: finalValue && parseFloat(finalValue) > 0,
            amountValue: finalValue,
            invoiceSelected: finalValue ? addPayment.invoiceSelected : [],
            checkAll: finalValue ? addPayment.checkAll : false
        });
    }

    function _renderCustomerCredit() {
        const listPaymentMethod = addPayment?.listPaymentMethod || [];
        const checkShow = listPaymentMethod.some((item) => item.id === PAYMENT_METHODS.CREDIT);

        if (
            isDeposit ||
            !checkShow ||
            !addPayment.customerId ||
            !finalCustomerCredit?.value ||
            parseFloat(finalCustomerCredit?.value) <= 0
        ) {
            return false;
        }

        return (
            <div className="check-items">
                <input onChange={_handleChangeValueCredit} id="check_amount" type="checkbox" checked={activeCredit} />
                <div className="item-checkbox">
                    <label htmlFor="check_amount">
                        <span className="txt-ellipsis">
                            {t('jobDetail:apply_credit_message', { format: finalCustomerCredit?.format })}
                        </span>
                    </label>
                </div>
            </div>
        );
    }

    function _renderCustomerDeposit() {
        if (isDeposit || !invoiceSelected.length || !depositData || !depositData?.value) {
            return false;
        }

        return (
            <div className="check-items">
                <input
                    onChange={_handleToggleApplyDeposit}
                    id="check_deposit"
                    type="checkbox"
                    checked={activeDeposit}
                />
                <div className="item-checkbox">
                    <label htmlFor="check_deposit">
                        <span className="txt-ellipsis">
                            {t('jobDetail:apply_deposit_message', { format: depositData?.format })}
                        </span>
                    </label>
                </div>
            </div>
        );
    }

    return (
        <div className="sm-row">
            <div className="txt">
                <IconAmount />
                <span className="flex-1 txt-ellipsis">{t('common:amount')}</span>
            </div>
            <div className="detail">
                <input
                    ref={refAmountInvoice}
                    className="field-input"
                    type="number"
                    onKeyDown={_onKeyDown}
                    onBlur={_onChangeValue}
                    autoFocus={autoFocus}
                    defaultValue={roundingNumber(addPayment.amountValue)}
                    disabled={activeCredit || activeDeposit}
                />
                <div className="detail-checkbox flex-column gap-6">
                    {_renderCustomerCredit()}
                    {_renderCustomerDeposit()}
                </div>
            </div>
        </div>
    );
};

export default Amount;

import classNames from 'classnames';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import SwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconCheck from 'assets/icon/IconCheck';
import IconDropUpDown from 'assets/icon/IconDropUpDown';

export const VOIPAddonsSelectPlan = ({
    data,
    isShowFooter = true,
    isEffectChange = false,
    wrapClassName = 'wrap-plans flex-column gap-12 mt-2',
    onContinue = () => {},
    isCanceled = false
}) => {
    const { t } = useTranslation('addons');
    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);
    const refFirstTime = useRef(false);

    const [state, dispatchState] = useReducer(reducer, {
        ...data,
        isCanceled
    });

    const { plans, account, isCanceled: finalIsCanceled } = state;
    const { plan_id, user_quantity } = account || {};

    useEffect(() => {
        isEffectChange && _handleContinue();
    }, [state]);

    const _handleClickLabel = (e) => {
        e.preventDefault();
    };

    const _handleSelectBox = (index) => {
        const { id, credit } = plans[index];
        dispatchState((prev) => ({ ...prev, account: { ...prev.account, plan_id: id, credit }, isCanceled: false }));
    };

    const _handleChangeRange = (value) => {
        dispatchState((prev) => ({ ...prev, account: { ...prev.account, user_quantity: value } }));
    };

    const _renderBoxBasic = () => {
        return (
            <div className="content-plans grey-dark word-break flex-column gap-4 flex-1">
                <RowItem title={t('gorilladesk_integration')} />
                <RowItem title={t('two_way_calling')} />
                <RowItem title={t('call_forwarding')} />
                <RowItem title={t('call_inbox_and_voicemail')} />
                <RowItem title={t('after_hours_voicemail')} />
            </div>
        );
    };

    const _renderBoxPro = (credit) => {
        return (
            <div className="content-plans grey-dark word-break flex-column gap-4 flex-1">
                <RowItem isGreen title={t('free_technician_users')} />
                <RowItem isGreen title={t('price_per_mo_voip_credit', { price: credit })} />
                <RowItem title={t('group_number')} />
                <RowItem title={t('auto_attendant')} />
                <RowItem title={t('call_activity_report')} />
                <RowItem title={t('generate_transcription')} />
                <RowItem title={t('call_summary')} />
                <RowItem title={t('voicemail_drop')} />
                <RowItem title={t('toll_free_numbers')} />
                <RowItem isPurple title={t('30_day_call_recording')} />
            </div>
        );
    };

    const _renderBoxGrowth = (credit) => {
        return (
            <div className="content-plans grey-dark word-break flex-column gap-4 flex-1">
                <RowItem isGreen title={t('free_technician_users')} />
                <RowItem isGreen title={t('price_per_mo_voip_credit', { price: credit })} />
                <RowItem title={t('smart_view_auto_dialer')} isPurple />
                <RowItem title={t('listen_in_on_calls')} />
                <RowItem title={t('whisper_in_on_calls')} />
                <RowItem title={t('barge_in_on_calls')} />
                <RowItem isPurple title={t('unlimited_call_recording')} />
            </div>
        );
    };

    const _renderBoxPlan = () => {
        return plans?.map((item, index) => {
            const { id, name, price, user_price, credit, user_quantity: userFree } = item;
            const isActive = id === plan_id;
            const isOldActivated = isActive && finalIsCanceled;
            const isChecked = isActive && !finalIsCanceled;
            const listContent = [_renderBoxBasic(), _renderBoxPro(credit), _renderBoxGrowth(credit)];
            const finalName = name.toLowerCase();

            return (
                <div
                    key={id}
                    className={classNames('box-plans flex-column justify-space-between flex-1 fs-12 cursor-pointer', {
                        active: isChecked,
                        'old-activated': isOldActivated
                    })}
                    onClick={() => _handleSelectBox(index)}
                >
                    <div className="header-plans flex-column text-center">
                        <span className="flex-centeritem gap-4 fs-14">
                            <span className="fw-600 grey-extradark">{t(`addons:${finalName}`)}</span>
                            <span className="fw-600 green-default">{t('price_per_mo', { price })}</span>
                        </span>
                        <span>{t('includes_user', { users: userFree })}</span>
                        <span>{t('price_per_user_per_mo', { price: user_price })}</span>
                        {id !== '1' && <div className="status-btn">{t(`everything_at_${finalName}`)}</div>}
                    </div>
                    {listContent[index]}
                    <SwitchCheckbox
                        id={id}
                        textChecked={t('common:active')}
                        textUnChecked={t('common:inactive')}
                        textPosition="right"
                        wrapClassName="switch large mt-4 fs-14"
                        checked={isChecked}
                        defaultChecked={isChecked}
                        isEffectDefaultChecked
                        onClickLabel={_handleClickLabel}
                        timeOut={0}
                    />
                </div>
            );
        });
    };

    const _handleContinue = () => {
        onContinue({ plan_id, user_quantity, isChanged: refFirstTime.current });
        refFirstTime.current = true;
    };

    const _renderDropdownUsers = () => {
        const txtUser = (user) => {
            return `${user} ${user <= 1 ? t('one_user') : t('more_user')}`;
        };

        const _options = companyUsers.map((item, index) => {
            const value = index + 1;
            return {
                id: value,
                label: txtUser(value)
            };
        });

        if (user_quantity > companyUsers.length)
            _options.push({
                id: user_quantity,
                label: txtUser(user_quantity)
            });

        return (
            <CalendarDropdown
                id="dropdown_select_user"
                buttonClassName="dropbtn v2-btn-default --has-blue has-icon selection"
                customDropButton={() => (
                    <Fragment>
                        <span className="txt-ellipsis">{txtUser(user_quantity)}</span>
                        <span className="svg-selectbox">
                            <IconDropUpDown />
                        </span>
                    </Fragment>
                )}
                wrapperClassName="fw-600"
                wrapperListClass="v2-dropdown__menu content-full scrolls"
                options={_options}
                keyGetName="label"
                keyGetValue="id"
                keyGetKey="id"
                onSelect={_handleChangeRange}
                selectedOption={{ id: user_quantity }}
            />
        );
    };

    return (
        <div className={wrapClassName}>
            <h3 className="fs-14 fw-600">{t('start_by_selecting_your_plan')}</h3>
            <div className="wrap-plans__option d-flex gap-4">{_renderBoxPlan()}</div>
            <div className="is-divider --horizontal" />
            <div className="wrap-plans__user">
                <h3 className="fs-14">{t('voip_activated_users')}</h3>
                <div className="flex-betweentop gap-8 mt-1 mb-2 user-content">
                    <div className="flex-column gap-4">
                        <p>{t('desc_activated_users')}</p>
                        <p className="blue-default">{t('desc_do_not_include_technician_user')}</p>
                    </div>
                    {_renderDropdownUsers()}
                </div>
            </div>

            {isShowFooter && (
                <div className="d-flex justify-end">
                    <div className="v2-btn-default has-bg-blue white --transparent" onClick={_handleContinue}>
                        {t('continue')}
                    </div>
                </div>
            )}
        </div>
    );
};

const RowItem = ({ isDisable = false, isPurple = false, isGreen = false, title = '' }) => {
    return (
        <div className="flexcenter gap-3">
            <span className={classNames('svg-sm flex-centeritem', { 'is-grey': isDisable })}>
                <IconCheck isSuccess />
            </span>
            <span className={classNames({ 'purple-default': isPurple }, { 'green-default fw-600': isGreen })}>
                {title}
            </span>
        </div>
    );
};

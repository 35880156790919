import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import { ACTIVITY_COMPARISON } from 'app/config/routes';
import { addBranchPath, addPrefixPath } from 'app/const/Branch';
import { PERMISSIONS } from 'app/const/Permissions';
import { checkIsEnableVoip, checkPermission } from 'common/utils/PermissionUtils';
import IconActivity from 'assets/icon/IconActivity';

const ComparisonSidebar = ({ isLoadingSetting = true, features = {} }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { pathname } = useLocation();

    const { permissions: userPermissions, settings: settingsUser } = useSelector(({ auth }) => auth.user);

    const permissionsList = userPermissions?.enabled || [];
    const isHavePermissionReport = checkPermission(permissionsList, PERMISSIONS.accessReportsTab);
    const isEnableVoip = checkIsEnableVoip(settingsUser, false);
    const isAccessCallReport = isHavePermissionReport && isEnableVoip;
    const isActive = matchPath(pathname, { path: addPrefixPath(ACTIVITY_COMPARISON), exact: true });
    const featureValue = features.call_activity_report;
    const isHideFeature = typeof featureValue === 'undefined' ? false : !featureValue;

    const _handleClick = () => {
        history.push(addBranchPath(ACTIVITY_COMPARISON));
    };

    if (!isAccessCallReport || isHideFeature) return null;

    if (isLoadingSetting)
        return (
            <div className="wrap-loading w-100">
                <div className="sidebar-items flexcenter pointer-events-none field-h32 loading">
                    <div className="ml-1 loading --animation --line --half" />
                </div>
            </div>
        );

    return (
        <a
            className={classNames('sidebar-items flexcenter gap-4', {
                active: isActive,
                'fw-600': isActive
            })}
            onClick={_handleClick}
        >
            <IconActivity />
            <span className="txt-ellipsis text-capitalize" title={t(`common:activity_comparison`)}>
                {t(`common:activity_comparison`)}
            </span>
        </a>
    );
};

export default ComparisonSidebar;

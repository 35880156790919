/* eslint-disable no-unused-vars */
import { roundingNumber } from 'common/utils/NumberUtils';
import { DEFAULT_VALUE_PACKAGE, ESTIMATE_DYNAMIC_TYPE, ESTIMATE_TYPE } from '../../const/Estimate';
import { DEFAULT_VALUE, DISCOUNT_TYPE, INVOICE_ITEM_SIMPLE } from '../../const/Invoice';
import { convertDiscount } from '../editinvoice';

export const _handleCalcuDiscount = (subTotal, finalValue, finalType, isDiscount) => {
    if (finalType === DISCOUNT_TYPE.PERCENT.type) {
        finalValue = (finalValue * subTotal) / 100;
    }

    let finalTotal = 0;
    if (subTotal === 0) {
        finalTotal = 0;
    } else if (subTotal > 0) {
        finalTotal = finalValue <= 0 ? finalValue : isDiscount ? -parseFloat(finalValue) : parseFloat(finalValue);
    } else {
        finalTotal = finalValue > 0 ? finalValue : isDiscount ? -parseFloat(finalValue) : parseFloat(finalValue);
    }
    const valueFormat = roundingNumber(finalTotal);
    return isDiscount ? valueFormat : valueFormat > 0 ? valueFormat : 0;
};

export const _calculationTotal = (items, discountDetail, depositDetail = {}) => {
    let taxes = [];
    let subTotal = 0;
    const discountTotal = Number(discountDetail.value || 0);
    const discountType = discountDetail.type || 2;

    depositDetail = depositDetail || {};

    const depositTotal = Number(depositDetail.value || 0);
    const depositType = depositDetail.type || 2;
    const depositInvoice = depositDetail.isInvoice;

    items.forEach((element) => {
        const tax1Detail = { ...element.tax1 };
        const tax2Detail = { ...element.tax2 };

        const itemCost = Number(element.cost.value || 0) * Number(element.quantity || 0) || 0;

        if (tax1Detail.id) {
            const finalValueTax1 = itemCost === 0 ? 0 : (Number(tax1Detail.rate) * itemCost) / 100;

            taxes.push({
                ...tax1Detail,
                totalItem: itemCost,
                total: {
                    value: finalValueTax1,
                    format: finalValueTax1
                }
            });
        }

        if (tax2Detail.id) {
            const finalValueTax2 = itemCost === 0 ? 0 : (Number(tax2Detail.rate) * itemCost) / 100;
            taxes.push({
                ...tax2Detail,
                totalItem: itemCost,
                total: {
                    value: finalValueTax2,
                    format: finalValueTax2
                }
            });
        }

        subTotal = subTotal + itemCost;
    });

    const discount = _handleCalcuDiscount(subTotal, discountTotal, discountType, true);
    const finalDiscount = parseFloat(discount);
    let finalTotal = subTotal + finalDiscount;

    const taxDetailsById = new Map();

    taxes.forEach((taxDetail) => {
        const { id, totalItem } = taxDetail || {};
        if (!taxDetailsById.has(id)) {
            taxDetailsById.set(id, { ...taxDetail, totalItemSum: 0 });
        }
        const currentDetail = taxDetailsById.get(id);
        currentDetail.totalItemSum += totalItem;
    });

    taxes = Array.from(taxDetailsById.values()).map(({ totalItemSum, ...taxDetail }) => {
        const itemTotalAndDiscount =
            discountType === DISCOUNT_TYPE.NUMBER.type
                ? totalItemSum + (finalDiscount * totalItemSum) / subTotal
                : totalItemSum - totalItemSum * (discountTotal * 0.01);

        const finalTax = isNaN(itemTotalAndDiscount)
            ? '0.00'
            : roundingNumber(itemTotalAndDiscount * (parseFloat(taxDetail.rate) * 0.01));

        finalTotal = parseFloat(finalTotal) + parseFloat(finalTax);

        return {
            ...taxDetail,
            total: {
                value: finalTax
            }
        };
    });

    const deposit =
        (depositInvoice
            ? depositDetail.total.value
            : _handleCalcuDiscount(roundingNumber(finalTotal), depositTotal, depositType, false)) || 0;

    return {
        subtotal: roundingNumber(subTotal),
        taxes,
        discount: {
            ...discountDetail,
            total: {
                ...discountDetail.total,
                value: discount
            }
        },
        deposit: {
            ...depositDetail,
            total: {
                ...depositDetail.total,
                value: roundingNumber(deposit)
            }
        },
        total: roundingNumber(finalTotal)
    };
};

export const prepareDataTemolate = ({ isEdit, estimateType, estimateDetail }) => {
    if (!isEdit) {
        switch (estimateType) {
            case ESTIMATE_TYPE.BASIC:
                return {
                    ...DEFAULT_VALUE,
                    items: [
                        {
                            ...INVOICE_ITEM_SIMPLE,
                            id: new Date().getTime().toString()
                        }
                    ],
                    type: ESTIMATE_TYPE.BASIC,
                    packages: [],
                    payment_terms: {}
                };
            case ESTIMATE_TYPE.DYNAMIC:
                return {
                    ...DEFAULT_VALUE,
                    items: [
                        {
                            ...INVOICE_ITEM_SIMPLE,
                            id: new Date().getTime().toString(),
                            type: ESTIMATE_DYNAMIC_TYPE.REQUIRED,
                            package_id: ''
                        }
                    ],
                    type: ESTIMATE_TYPE.DYNAMIC,
                    packages: [],
                    payment_terms: {}
                };
            case ESTIMATE_TYPE.PACKAGES:
                const packageId = Date.now();

                return {
                    ...DEFAULT_VALUE,
                    items: [
                        {
                            ...INVOICE_ITEM_SIMPLE,
                            id: new Date().getTime().toString(),
                            package_id: packageId
                        }
                    ],
                    type: ESTIMATE_TYPE.PACKAGES,
                    packages: [
                        {
                            ...DEFAULT_VALUE_PACKAGE,
                            id: packageId
                        },
                        {
                            ...DEFAULT_VALUE_PACKAGE,
                            id: packageId + 1,
                            color: '#f6d21b'
                        }
                    ],
                    taxes: [],
                    discount: {},
                    payment_terms: {}
                };

            default:
                break;
        }
    }
    const { discount: currentDiscount = {}, deposit: currentDeposit } = estimateDetail;

    return {
        id: estimateDetail.id,
        discount: currentDiscount ? convertDiscount(currentDiscount) : {},
        deposit: { ...convertDiscount(currentDeposit), invoices: [] },
        subtotal: estimateDetail.subtotal.value,
        total: estimateDetail.total.value,
        items: estimateDetail.items,
        shouldUpdateTermAndNote: false,
        terms: estimateDetail.terms,
        note: estimateDetail.note,
        noteDefault: estimateDetail.note || '',
        termsDefault: estimateDetail.terms || '',
        amount_due: estimateDetail.amount_due,
        available_credit: estimateDetail.available_credit,
        has_service: estimateDetail.has_service,
        type: estimateDetail.type || ESTIMATE_TYPE.BASIC,
        packages: estimateDetail.packages.map((itemPackage) => {
            const { discount: packageDiscount, deposit: packageDeposit } = itemPackage || {};
            return {
                ...itemPackage,
                subtotal: itemPackage.subtotal.value,
                total: itemPackage.total.value,
                discount: convertDiscount(packageDiscount),
                deposit: { ...convertDiscount(packageDeposit), invoices: [] }
            };
        }),
        payment_terms: estimateDetail.payment_terms || {}
    };
};

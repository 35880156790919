import React, { useContext, useEffect, useId, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import { ESTIMATE_TYPE } from 'app/modules/jobdetail/const/Estimate';
import { DISCOUNT_TYPE } from 'app/modules/jobdetail/const/Invoice';
import { AddInvoiceContext } from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import IconDropDown from 'assets/icon/IconDropDown';
import { onKeyDownOnlyNumber, roundingNumber } from 'common/utils/NumberUtils';
import IconSync from 'assets/icon/IconSync';

const Discount = ({ packageId, isInvoice = true, showDiscountRepeat = true }) => {
    const { t } = useTranslation();
    const { invoiceData, updateInvoiceDataContext, onChangeEstimatePackage } = useContext(AddInvoiceContext);

    const [state, setState] = useReducer(reducer, {
        isVisible: false
    });

    const refDropdown = useRef(null);
    const refInputVallue = useRef(null);
    const dropdownId = useId(null);

    const { isVisible: finalIsVisible } = state;
    const { discount: discountData, type: estimateType, packages: estimatePackages } = invoiceData;
    const isEstimatePackage = estimateType === ESTIMATE_TYPE.PACKAGES;
    const isRepeat = !!discountData?.repeat;

    const currentDiscount = () => {
        if (!packageId || !isEstimatePackage) {
            return discountData;
        }
        return estimatePackages.find((item) => item.id === packageId)?.discount || {};
    };

    const { type: finalDiscountType, value: finalDiscountValue } = currentDiscount();

    useEffect(() => {
        if (finalDiscountValue !== refInputVallue.current.value) {
            refInputVallue.current.value = finalDiscountValue;
        }
    }, [finalDiscountValue]);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById(dropdownId);
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById(dropdownId);
        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    const _handleOpen = (e) => {
        e.stopPropagation();
        setState({ isVisible: !finalIsVisible });
    };

    function _handleChangeDiscountType(e, type) {
        e.stopPropagation();

        if (finalDiscountType === type) {
            return false;
        }

        if (!!packageId && isEstimatePackage) {
            onChangeEstimatePackage((prev) => {
                return {
                    ...prev,
                    packages: prev.packages.map((item) => {
                        if (item.id === packageId) {
                            return {
                                ...item,
                                discount: {
                                    ...item.discount,
                                    type: type
                                }
                            };
                        }
                        return item;
                    })
                };
            });
        } else {
            updateInvoiceDataContext({
                ...invoiceData,
                discount: {
                    ...discountData,
                    type: type
                }
            });
        }

        _closeSearchResult();
    }

    function _handleChangeValue(e) {
        const finalDiscount = roundingNumber(e.target.value);
        refInputVallue.current.value = finalDiscount;

        if (!!packageId && isEstimatePackage) {
            onChangeEstimatePackage((prev) => {
                return {
                    ...prev,
                    packages: prev.packages.map((item) => {
                        if (item.id === packageId) {
                            return {
                                ...item,
                                discount: {
                                    ...item.discount,
                                    value: finalDiscount
                                }
                            };
                        }
                        return item;
                    })
                };
            });
        } else {
            updateInvoiceDataContext({
                ...invoiceData,
                discount: {
                    ...discountData,
                    value: finalDiscount
                }
            });
        }
    }

    const _toggleRepeat = () => {
        updateInvoiceDataContext({
            ...invoiceData,
            discount: {
                ...discountData,
                repeat: !!isRepeat ? 0 : 1
            }
        });
    };

    const _handleKeyDown = (e) => {
        const finalKey = e.key;
        const finalValue = onKeyDownOnlyNumber(e) || finalKey === '.';
        !finalValue && e.preventDefault();
    };

    return (
        <div className="txt relative">
            <div className="first-field">
                <div className={`v2-dropdown ${finalIsVisible ? 'active' : ''}`} ref={refDropdown}>
                    <div onClick={(e) => _handleOpen(e)} className="dropbtn items" tabIndex="0">
                        {t('report:invoice_discount')}
                        <span className="text">{finalDiscountType === DISCOUNT_TYPE.NUMBER.type ? '$' : '%'}</span>
                        <span className="svg-selectbox">
                            <IconDropDown />
                        </span>
                    </div>
                    <div className="v2-dropdown__menu scrolls" id={dropdownId}>
                        <ul>
                            <li
                                className={`items ${DISCOUNT_TYPE.PERCENT.type === finalDiscountType ? 'active' : ''}`}
                                onClick={(e) => _handleChangeDiscountType(e, DISCOUNT_TYPE.PERCENT.type)}
                                tabIndex="0"
                            >
                                {DISCOUNT_TYPE.PERCENT.name}
                            </li>
                            <li
                                className={`items ${DISCOUNT_TYPE.NUMBER.type === finalDiscountType ? 'active' : ''}`}
                                onClick={(e) => _handleChangeDiscountType(e, DISCOUNT_TYPE.NUMBER.type)}
                                tabIndex="0"
                            >
                                {DISCOUNT_TYPE.NUMBER.name}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="second-field">
                <input
                    className="field-input"
                    type="text"
                    defaultValue={finalDiscountValue}
                    ref={refInputVallue}
                    onBlur={(e) => {
                        _handleChangeValue(e);
                    }}
                    onKeyDown={_handleKeyDown}
                />
            </div>
            {isInvoice && showDiscountRepeat && (
                <div className="v2-btn-default --icon-lg tooltip ml-1 btn-repeat" onClick={_toggleRepeat}>
                    <IconSync isActive={isRepeat} />
                    <p className="tooltiptext top">This discount will repeat as this invoice recurs</p>
                </div>
            )}
        </div>
    );
};

export default Discount;

import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonSave from 'app/components/button/ButtonSave';
import StatusBar from 'app/components/status/statusbar';
import { ACTIONN_UPDATE_QUICKBOOKS_ADDON, GET_LIST_QUICKBOOKS_TAX } from 'app/const/api/V2';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import IconTooltip from 'assets/icon/IconTooltip';
import IconUpgrade from 'assets/icon/IconUpgrade';
import { clientQuery } from 'common/utils/ApiUtils';
import MappingTax from '../mapping';
import ListTax from './ListTax';
import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';

let timmer;

export default function TabTaxSync({ parentData, taxData, onUpdateTaxData, onUpdateOption, keyword }) {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        ...parentData,
        isGroup: !!taxData.auto_group_tax,
        valueTax: taxData.tax_code || 0,
        isFetched: false,
        tax_codes: []
    });

    const refButtonSave = useRef(null);
    const refStatusBar = useRef(null);
    const refMappingTax = useRef(null);

    const finalIsActive = !!state.value;

    const {
        country,
        using_sales_tax: usingSalesTax,
        isGroup: finalIsGroup,
        valueTax,
        partner_tax_enabled: parterTaxEnabled
    } = state;
    const finalTaxSelected = valueTax.toString();

    const isHideToggle =
        (country === 'CA' && !usingSalesTax) || (usingSalesTax && country !== 'US' && country !== 'CA');

    useEffect(() => {
        _getListTax();
    }, []);

    function _getListTax() {
        clientQuery(GET_LIST_QUICKBOOKS_TAX, { method: 'GET' }, _getListTaxSuccess, _getListTaxFailed);
    }

    function _getListTaxSuccess(response) {
        dispatchState({
            ...response.data,
            isFetched: true
        });
    }

    function _getListTaxFailed() {
        dispatchState({
            isFetched: true
        });
    }

    function _handleChangeValue() {
        dispatchState({
            value: !finalIsActive
        });

        timmer && clearTimeout(timmer);
        timmer = setTimeout(() => {
            onUpdateOption(keyword, !finalIsActive ? 1 : 0);
        }, 500);
    }

    function _handleChangeOption() {
        dispatchState({
            isGroup: !finalIsGroup
        });
    }

    function _handleSelectTax(value) {
        dispatchState({
            valueTax: value
        });
    }

    function _handleSave() {
        let params = {
            is_auto_tax: 1,
            auto_group_tax: 1
        };

        if (!finalIsGroup) {
            if (!finalTaxSelected) {
                _removeButtonLoading();
                refStatusBar.current.showStatusBar('show_error', t('addons:please_select_tax_code'), LIST_STATUS.ERROR);
                return false;
            }
            params = {
                is_tax: 1,
                tax: finalTaxSelected
            };
        }

        clientQuery(
            ACTIONN_UPDATE_QUICKBOOKS_ADDON,
            {
                method: 'PUT',
                data: params
            },
            _handleSaveSuccess,
            _handleSaveFailed
        );
    }

    function _handleSaveSuccess() {
        onUpdateTaxData({
            ...taxData,
            auto_group_tax: finalIsGroup ? 1 : 0,
            tax_code: finalTaxSelected
        });
        _removeButtonLoading();
        refStatusBar.current.showStatusBar('show_error', t('common:update_successfully'), LIST_STATUS.SUCCESS);
    }

    function _handleSaveFailed(response) {
        _removeButtonLoading();
        refStatusBar.current.showStatusBar(
            'show_error',
            response?.message?.toString() || t('common:please_try_again'),
            LIST_STATUS.ERROR
        );
    }

    function _removeButtonLoading() {
        refButtonSave.current.removeLoading();
    }

    function _checkDisable() {
        if (
            finalIsGroup !== !!taxData.auto_group_tax ||
            (!finalIsGroup && finalTaxSelected !== taxData.tax_code.toString())
        ) {
            return false;
        }
        return true;
    }

    function _showGroupTax() {
        refMappingTax.current._open();
    }

    const _renderFinalContent = () => {
        if (country === 'US' && !usingSalesTax) {
            return <p className={'description'}>{t('addons:message_enable_sale_tax_qb')}</p>;
        }

        if (country !== 'US' && country !== 'CA') {
            return <p className={'description'}>{t('addons:message_tax_code')}</p>;
        }

        if (usingSalesTax && country === 'US') {
            if (parterTaxEnabled) {
                return <p className={'description'}>{t('addons:automatic_taxes_turn_on_qb_online')}</p>;
            }

            return (
                <div className={'tax-desc'}>
                    <div className="check-radio">
                        <input
                            onChange={_handleChangeOption}
                            id="taxe_sync"
                            type="radio"
                            name="TaxSync"
                            checked={!finalIsGroup}
                        />
                        <label htmlFor="taxe_sync" className="check-radio__label">
                            {t('addons:tax_sync_title')}
                        </label>
                    </div>
                    <ListTax
                        tax_codes={state.tax_codes}
                        isFetched={state.isFetched}
                        isGroup={finalIsGroup}
                        valueTax={finalTaxSelected}
                        onSelect={_handleSelectTax}
                    />
                    <div className="check-radio mt-3">
                        <input
                            onChange={_handleChangeOption}
                            id="taxe_groups"
                            type="radio"
                            checked={finalIsGroup}
                            name="TaxSync"
                        />
                        <label htmlFor="taxe_groups" className="check-radio__label">
                            {t('addons:tax_group_title')}
                        </label>
                    </div>
                    <div className="wrap-tooltip">
                        <p onClick={_showGroupTax} className="description violet-name btn-modal">
                            {t('addons:max_tax_group')}
                        </p>
                        <div className="tooltip">
                            <IconTooltip />
                            <div className="tooltiptext top">{t('addons:max_tax_group_title')}</div>
                        </div>
                    </div>
                </div>
            );
        }

        if (!parterTaxEnabled && country === 'CA' && usingSalesTax) {
            return (
                <div className={'tax-desc'}>
                    <p className={'description'}>{t('addons:multi_tax_group')}</p>
                    <div className="wrap-tooltip">
                        <p onClick={_showGroupTax} className="description violet-name btn-modal">
                            {t('addons:multi_tax_group_title')}
                        </p>
                        <div className="tooltip">
                            <IconTooltip />
                            <div className="tooltiptext top">{t('addons:multi_tax_group_desc')}</div>
                        </div>
                    </div>
                </div>
            );
        }

        return false;
    };

    return (
        <div className={`boxs boxs-has-footer ${finalIsActive ? 'active' : ''}`}>
            <div className="boxs__header">
                <div className="header-title">
                    <div className="title">{parentData.name}</div>
                    <div className="dots powerups">
                        <IconUpgrade isFillOrange />
                        {t('addons:power_up')}
                    </div>
                </div>
                {!isHideToggle && (
                    <GdSwitchCheckbox
                        id="toggle_tax"
                        inputClassName="toggle toggle-round"
                        textUnChecked={t('common:disabled')}
                        textChecked={t('common:enabled')}
                        defaultChecked={finalIsActive}
                        onChangeImmediately={_handleChangeValue}
                    />
                )}
            </div>
            <div className={'boxs__contents'}>
                <StatusBar ref={refStatusBar} />
                <p className={'description --hasline'}>{parentData.description}</p>
                {finalIsActive && _renderFinalContent()}
            </div>
            {usingSalesTax && country === 'US' && !parterTaxEnabled && (
                <div className="boxs__footer">
                    <ButtonSave disabled={_checkDisable()} ref={refButtonSave} onSave={_handleSave} />
                </div>
            )}
            <MappingTax taxCode={state.tax_codes || []} ref={refMappingTax} />
        </div>
    );
}

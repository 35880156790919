import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { INVOICE_DETAIL_TYPE } from 'app/const/customer/CustomerInvoices';
import { getRegexVariable } from 'app/const/drip';
import { CUSTOMER_TYPE_LOG } from 'app/modules/customer/const';
import IconEmail from 'assets/icon/IconEmail';
import IconPen from 'assets/icon/IconPen';
import IconPrice from 'assets/icon/IconPrice';
import { actionCloseEstimate, actionOpenEstimate } from 'common/redux/actions/estimateAction';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { actionCloseJobDetail, actionOpenJobDetail } from 'common/redux/actions/job/detail';

const Log = ({ logDetail = {}, isLogTimeLine = false }) => {
    const { t } = useTranslation();
    const CUSTOMER_LOG_PARAMS = [
        {
            id: 'time_line_job_detail_work_order_id',
            key: 'wo_number',
            handleClick: _handleJobDetail
        },
        {
            id: 'invoice_detail_id',
            key: 'invoice_number',
            keyGetValue: 'invoice_id',
            handleClick: _handleOpenInvoice
        },
        {
            id: 'time_line_job_detail_id',
            key: 'job_id',
            handleClick: _handleJobDetail
        },
        {
            id: 'time_line_estimate_id',
            key: 'estimate_number',
            handleClick: _handleOpenEstimateDetail
        }
    ];

    const [newContent, setNewContent] = useState('');
    const dispatch = useDispatch();
    const dataLogDetail = logDetail?.log || {};
    const dataLogDetailParams = dataLogDetail.params || {};
    const { invoice_number, invoice_id, job_id, estimate_number, wo_number, estimate_id, service_name } =
        dataLogDetailParams;
    const { id: finalLogDetailId, status, delivery_message } = logDetail;

    const _handleAddOrEditEventListener = ({
        isAdd = false,
        id = '',
        key = '',
        keyGetValue = '',
        handleClick = () => {}
    }) => {
        if (!dataLogDetailParams[key]) return;
        const element = document.getElementById(`${id}${dataLogDetailParams[keyGetValue || key]}${finalLogDetailId}`);
        if (element) {
            element[isAdd ? 'addEventListener' : 'removeEventListener']('click', handleClick, true);
        }
    };

    useEffect(() => {
        let newValue = dataLogDetail.message || '';

        if (dataLogDetailParams) {
            CUSTOMER_TYPE_LOG.forEach((item) => {
                const regEx = getRegexVariable(item);
                newValue = newValue.replace(regEx, _getLink(item));
            });
        }

        setNewContent(newValue);

        return () => {
            CUSTOMER_LOG_PARAMS.forEach((item) => _handleAddOrEditEventListener({ isAdd: false, ...item }));
        };
    }, []);

    useEffect(() => {
        if (newContent) {
            CUSTOMER_LOG_PARAMS.forEach((item) => _handleAddOrEditEventListener({ isAdd: true, ...item }));
        }
    }, [newContent]);

    function _handleOpenInvoice() {
        dispatch(actionCloseEstimate());
        dispatch(actionCloseJobDetail());
        dispatch(
            actionOpenInvoice({
                id: invoice_id,
                status: '',
                total: '',
                isRecurring: false,
                type: INVOICE_DETAIL_TYPE.NORMAL
            })
        );
    }

    function _handleJobDetail() {
        dispatch(actionOpenJobDetail({ id: job_id }));
    }

    function _handleOpenEstimateDetail() {
        dispatch(actionOpenEstimate({ id: estimate_id }));
    }

    function _getLink(type) {
        switch (type) {
            case CUSTOMER_TYPE_LOG[0]:
                return `<a id=${CUSTOMER_LOG_PARAMS[0].id.concat(
                    wo_number,
                    finalLogDetailId
                )}  class="log-title__link">Work Order #${wo_number} </a>`;
            case CUSTOMER_TYPE_LOG[1]:
                return `<a id=${CUSTOMER_LOG_PARAMS[1].id.concat(
                    invoice_id,
                    finalLogDetailId
                )}  class="log-title__link">Invoice #${invoice_number}</a>`;
            case CUSTOMER_TYPE_LOG[2]:
                return `<a  id=${CUSTOMER_LOG_PARAMS[1].id.concat(
                    invoice_id,
                    finalLogDetailId
                )}  class="log-title__link">${invoice_number}</a>`;
            case CUSTOMER_TYPE_LOG[3]:
                return `<a id=${CUSTOMER_LOG_PARAMS[3].id.concat(
                    estimate_number,
                    finalLogDetailId
                )} class="log-title__link">Estimate #${estimate_number} </a>`;
            case CUSTOMER_TYPE_LOG[4]:
                return `<a id=${CUSTOMER_LOG_PARAMS[3].id.concat(
                    estimate_number,
                    finalLogDetailId
                )} class="log-title__link">${estimate_number} </a>`;
            case CUSTOMER_TYPE_LOG[5]:
                return `<a id=${CUSTOMER_LOG_PARAMS[2].id.concat(
                    job_id,
                    finalLogDetailId
                )} class="log-title__link">${service_name}</a>`;
            default:
                return '';
        }
    }

    const _renderIconLog = () => {
        switch (isLogTimeLine ? logDetail.log.type : logDetail.type) {
            case 'email':
                return <IconEmail isEmailAddress />;
            case 'payment':
                return <IconPrice isLogTimeLine />;
            default:
                return <IconPen isHasColor />;
        }
    };

    return (
        <div className="boxs">
            <div className="left-icons">{_renderIconLog()}</div>
            <div className="content content-log">
                <div className="log-title" dangerouslySetInnerHTML={{ __html: newContent }} />
                <p className="log-time">{logDetail.date}</p>
                <div
                    className={classNames(`log-stt status-btn ${status}`, {
                        tooltip: !!delivery_message
                    })}
                >
                    {t(`report:${status}`)}
                    {!!delivery_message && <p className="tooltiptext top">{delivery_message}</p>}
                </div>
            </div>
        </div>
    );
};

export default Log;

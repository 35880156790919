import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import { reducer } from 'app/const/Reducer';

const DeleteJobRepeat = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        invoices: []
    });
    const functionCallback = useRef(null);

    useImperativeHandle(ref, () => ({
        showModal: _handleShow,
        closeModal: _handleClose
    }));

    function _handleShow(callback) {
        functionCallback.current = {
            callback
        };
        dispatchState({ isVisible: true });
    }

    function _handleClose() {
        state.isVisible && dispatchState({ isVisible: false });
    }

    function _handleDelete() {
        functionCallback.current.callback(1);
    }

    if (!state.isVisible) {
        return false;
    }

    return (
        <ReactModal
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal modal-delete-job open"
            onRequestClose={_handleClose}
        >
            <div className="modal__overlay bg-fixed" onClick={_handleClose} />
            <div className="modal__container">
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('jobDetail:delete_invoice_recurrence')}</h3>
                </div>
                <div className="body-modal scrolls">
                    <p>{t('jobDetail:delete_invoice_recurrence_message')}</p>
                </div>
                <div className="footer-modal btn-close justify-end">
                    <span className="v2-btn-default --transparent" onClick={_handleClose}>
                        {t('common:no')}
                    </span>
                    <div className="v2-btn-main" onClick={_handleDelete}>
                        {t('common:yes')}
                    </div>
                </div>
            </div>
        </ReactModal>
    );
});

export default DeleteJobRepeat;

import moment from 'moment-timezone';
import React, { createContext, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getEstimateDetail, getEstimateNumber, getInvoiceDetail } from 'app/const/Api';
import { COMMISSION_TYPE_VALUE } from 'app/const/Commissions';
import { reducer } from 'app/const/Reducer';
import { actionToggleInsertJobNotes } from 'common/redux/actions/notesAction';
import { actionToggleInsertJobImages } from 'common/redux/actions/photosAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { convertPhpFormatToMoment } from 'common/utils/DateUtils';
import { transformToCurrency } from 'common/utils/NumberUtils';

export const InvoiceDetailContext = createContext();

const InvoiceDetailContextProvider = ({
    isInvoice = true,
    children,
    invoiceData,
    reloadJobDetail,
    reloadSoldBy,
    reloadSchedule,
    commission,
    schedule,
    isActive,
    isInvoiceRecurring = false,
    reloadInvoice = 0,
    isOpenWithJob = true,
    jobData = {},
    contacts = [],
    onUpdate = () => {},
    onDelete = () => {},
    onClose,
    onEditSuccess = () => {},
    onTriggerUpdated = () => {},
    onUpdateNote = () => {}
}) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        isOpenWithJob: isOpenWithJob,
        invoiceImages: []
    });

    const { settings, company } = useSelector(({ auth }) => auth?.user);
    const commissionReducer = useSelector(({ commissionReducer }) => commissionReducer);
    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);
    const isFirstTime = useRef(true);
    const summaryInvoice = useRef('');
    const dispatch = useDispatch();

    const { note: finalNote, isLoading: finalIsLoading } = state;
    const shouldUpdateNote = useRef(false);

    useEffect(() => {
        if (!finalIsLoading && isOpenWithJob && shouldUpdateNote.current) {
            onUpdateNote(finalNote);
        }

        /**
         * Check case update WO note. Only update when edit invoice note
         */
        if (!finalIsLoading) {
            shouldUpdateNote.current = true;
        }

        return () => {
            dispatch(actionToggleInsertJobNotes(null));
            dispatch(actionToggleInsertJobImages(null));
        };
    }, [finalNote]);

    useEffect(() => {
        if (!isFirstTime.current) {
            if (isInvoice) {
                const {
                    invoice_status_id: currentInvoiceStatus,
                    total: currentTotal,
                    recurrence: currenRrecurrence = {}
                } = state;

                const currentSummary = currenRrecurrence?.summary || '';

                if (
                    currentInvoiceStatus &&
                    (currentInvoiceStatus !== invoiceData?.status ||
                        invoiceData?.total !== currentTotal?.format ||
                        summaryInvoice.current !== currentSummary)
                ) {
                    onUpdate(
                        currentInvoiceStatus,
                        currentTotal,
                        {
                            summary: currentSummary,
                            frequency: currenRrecurrence?.offset?.frequency
                        },
                        state?.invoice_no
                    );

                    summaryInvoice.current = currentSummary;
                }
            } else {
                const currentEstimateStatus = state?.status;
                if (currentEstimateStatus && currentEstimateStatus !== invoiceData?.status) {
                    onUpdate(currentEstimateStatus);
                }
            }
        }
    }, [state]);

    /**
     * Reload this invoice, estimate with cases:
     * 1. change service with flag reloadJobDetail
     * 2. reload only invoice with flag reloadInvoice with job change status to complete and addon completionist active
     */

    useEffect(() => {
        if (!isFirstTime.current && (reloadJobDetail || (reloadInvoice && isInvoice))) {
            isFirstTime.current = true;
            _resetDataInvoice();
            isActive && _handleGetInvoiceDeail();
        }
    }, [reloadJobDetail, reloadInvoice]);

    useEffect(() => {
        if (reloadSoldBy && isInvoice) _handleUpdateCommission(commission?.sales || []);
    }, [reloadSoldBy]);

    useEffect(() => {
        if (reloadSchedule && isInvoice)
            _handleUpdateCommission(
                commissionReducer.items.filter((item) => schedule.some((el) => +item.user_id === +el.user_id)),
                true
            );
    }, [reloadSchedule]);

    /**
     * Else check caase date job scheduling change. So need check and update date issue invoice
     */
    useEffect(() => {
        if (isActive) {
            if (isFirstTime.current) {
                _handleGetInvoiceDeail();
            } else {
                // eslint-disable-next-line no-undef
                const timeJobSchedule = global.timeJobSchedule;
                const invoiceRecurence = state.recurrence;

                if (state.invoice_status_id === 'draft' && !invoiceRecurence.repeat && timeJobSchedule) {
                    dispatchState({
                        date: {
                            value: timeJobSchedule,
                            format: moment(timeJobSchedule).utc().format(convertPhpFormatToMoment(company.date_format))
                        }
                    });
                }
                // eslint-disable-next-line no-undef
                global.timeJobSchedule = null;
            }
        }
    }, [isActive]);

    function _resetDataInvoice() {
        dispatchState(() => {
            return {
                isLoading: true,
                isOpenWithJob: isOpenWithJob
            };
        });
    }

    function _handleGetInvoiceDeail(triggerUpdate) {
        const getApiURL = isInvoice ? getInvoiceDetail(invoiceData.id) : getEstimateDetail(invoiceData.id);
        clientQuery(
            getApiURL,
            { method: 'GET', data: { type: isInvoiceRecurring ? 2 : 1 } },
            (res) => _handleGetInvoiceDeailSuccess(res, triggerUpdate),
            _handleGetInvoiceDeailFailed
        );
        isFirstTime.current && !isInvoice && _getTermAndNoteDefault();
    }

    function _handleGetInvoiceDeailSuccess(response, triggerUpdate) {
        // Listen case update scuccess get data
        triggerUpdate && onTriggerUpdated(response);

        const responseData = response.data;

        if (isInvoice && isFirstTime.current) {
            summaryInvoice.current = responseData.recurrence.summary;
        }
        const dataDefault = responseData.default || {};

        const objectDefault = isInvoice
            ? {
                  noteDefault: dataDefault.note || '',
                  termsDefault: dataDefault.terms || ''
              }
            : {};

        dispatchState((prev) => {
            return {
                ...prev,
                isLoading: false,
                ...responseData,
                reloadInvoice: isFirstTime.current ? 0 : new Date().getTime(),
                isInvoiceRecurring,
                items: responseData.items.map((item) => {
                    const sales = item.commission?.sales || [];
                    return { ...item, sold_by_ids: sales.map((sale) => sale.user.id) };
                }),
                isJobInvoice: !!responseData.job?.id,
                job: isInvoice ? responseData.job : jobData?.id ? { ...jobData } : responseData.job, //This line add job data to estimate
                ...objectDefault
            };
        });

        isFirstTime.current = false;
    }

    function _getTermAndNoteDefault() {
        const _getInvoiceNumberSuccess = (response) => {
            const responseData = response.data || {};

            _handleUpdateData({
                noteDefault: responseData.note || '',
                termsDefault: responseData.terms || ''
            });
        };

        clientQuery(
            getEstimateNumber,
            { method: 'GET', data: { job_id: invoiceData.customer_job_id } },
            _getInvoiceNumberSuccess
        );
    }

    function _handleGetInvoiceDeailFailed(response) {
        if (onClose) {
            alert(response?.message?.toString() || t('common:please_try_again'));
            onClose();
        }
    }

    function _handleUpdateData(newData) {
        dispatchState((prev) => {
            return {
                ...prev,
                ...newData
            };
        });
    }

    function _reloadInvoiceData(alert = true) {
        _handleGetInvoiceDeail(alert);
        alert && onEditSuccess();
    }

    function _handleDeleteInvoice() {
        onDelete();
        isFirstTime.current = true;

        _resetDataInvoice();
    }

    const _handleUpdateCommission = (dataUpdate, isSchedule = false) => {
        const type = isSchedule ? 'productions' : 'sales';
        dispatchState((prevState) => {
            const newItems = [...(prevState?.items || [])].map((item) => {
                item['commission'] = {
                    ...(item['commission'] || {}),
                    [type]: _handleGetCommissionData(type, dataUpdate, item)
                };
                return item;
            });

            return { ...prevState, items: newItems };
        });
    };

    const _handleGetCommissionData = (type, data, itemData) => {
        const result = [];
        const keyUpdate = type === 'sales' ? 'sale' : 'production';
        data.forEach((item) => {
            if (+item.item_id === +itemData.item_id) {
                const value = item[keyUpdate].value;
                const repeat = item[keyUpdate].repeat;
                const format =
                    item[keyUpdate].type === COMMISSION_TYPE_VALUE.NUMBER
                        ? transformToCurrency(value, settings.currency)
                        : transformToCurrency(itemData.subtotal.value * (+value / 100), settings.currency);
                if (!item['user']) item['user'] = companyUsers.find((user) => +user.id === +item.user_id);
                item['active'] = repeat === 1 || repeat === -1 ? 1 : 0;
                result.push({ ...item, format, value });
            }
        });

        return result;
    };

    const InvoiceDetailContextData = {
        invoiceDetail: state,
        contacts: contacts || [],
        onUpdateInvoiceData: _handleUpdateData,
        onReloadInvoiceData: _reloadInvoiceData,
        onDeleteInvoice: _handleDeleteInvoice,
        onForceUpdate: dispatchState
    };

    return <InvoiceDetailContext.Provider value={InvoiceDetailContextData}>{children}</InvoiceDetailContext.Provider>;
};

export default InvoiceDetailContextProvider;

import React from 'react';

export default function IconWallet({ isHasColor }) {
    if (isHasColor) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                {' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 4H15C16.1046 4 17 4.89543 17 6V14C17 15.1046 16.1046 16 15 16H6C4.89543 16 4 15.1046 4 14V6C4 4.89543 4.89543 4 6 4Z"
                    fill="#C1C9D4"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.41005 6.10847C6.50015 6.03848 5.6453 5.6453 5 5C4.89014 4.89015 4.74115 4.82843 4.58579 4.82843C4.26227 4.82843 4 5.0907 4 5.41422V15.1727C4 15.7024 4.21021 16.2106 4.58449 16.5856L4.99817 17C5.64222 17.6452 6.49611 18.0384 7.40509 18.1083L16.8466 18.8344C16.8977 18.8383 16.9488 18.8403 17 18.8403C18.1046 18.8403 19 17.9449 19 16.8403V8.85207C19 7.80699 18.1954 6.93811 17.1534 6.85796L7.41005 6.10847ZM16 13C16 13.5523 15.5523 14 15 14C14.4477 14 14 13.5523 14 13C14 12.4477 14.4477 12 15 12C15.5523 12 16 12.4477 16 13Z"
                    fill="#7A83A6"
                />{' '}
            </svg>
        );
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path
                stroke="var(--color-icon)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.3"
                d="M4.5 9.127V7.5a2 2 0 012-2h9a2 2 0 012 2v1"
            ></path>
            <path
                fillRule="evenodd"
                stroke="var(--color-icon)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.3"
                d="M4.5 7.5v0a1 1 0 001 1h12a2 2 0 012 2v6a2 2 0 01-2 2h-11a2 2 0 01-2-2v-9z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="var(--color-icon)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.3"
                d="M15.5 14.5a1 1 0 100-2 1 1 0 000 2z"
            ></path>
        </svg>
    );
}

import { reducer } from 'app/const/Reducer';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import StatusBar from 'app/components/status/statusbar';
import { TIMEOFF_API, EVENT_API, getTimeoffDetail, getEventDetail } from 'app/const/api/V2';
import { LIST_STATUS } from 'app/const/Status';
import { closeEvent, createEventSuccess } from 'common/redux/actions/event';
import { clientQuery } from 'common/utils/ApiUtils';
import FooterModal from '../components/modal/components/FooterModal';
import HeaderModal from '../components/modal/components/HeaderModal';
import SelectLocation from '../components/modal/components/SelectLocation';
import SelectRepeat from '../components/modal/components/SelectRepeat';
import SelectSchedule from '../components/modal/components/SelectSchedule';
import SelectText from '../components/modal/components/SelectText';
import TimeLength from '../components/modal/components/TimeLength';

const TimeOffConfirm = React.lazy(() => import('../components/sidebar/components/timeoff/TimeOffConfirm'));

import 'assets/css/common/_sidebar-calendar.scss';
import IconPen from 'assets/icon/IconPen';
import InputColor from 'app/components/input/InputColor';
import { PERMISSIONS } from 'app/const/Permissions';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { mixpanelAddTimeoff } from 'app/modules/mixpanel/MixpanelAddTimeoff';

const TimeOff = () => {
    const { t } = useTranslation('calendar');
    const dispatch = useDispatch();
    const { data: eventData, isEdit } = useSelector(({ eventReducer }) => eventReducer);
    const permissionsDisabled = useSelector(({ auth }) => auth.user.permissions.disabled || []);
    const profileId = useSelector(({ auth }) => auth.user.profile.id);
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, data: {}, isRepeat: false });
    const { isOpen, data, isRepeat, isTimeoff } = state;

    const refAlert = useRef(null);
    const refConfirm = useRef(null);
    const refFooter = useRef(null);
    const refDescription = useRef(null);
    const refLocation = useRef(null);
    const refSummary = useRef(null);
    const refTimeLength = useRef(null);
    const refSchedule = useRef(null);
    const refName = useRef(null);
    const refColor = useRef(null);
    const refDataUpdate = useRef({});

    useEffect(() => {
        if (eventData) {
            dispatchState({
                isOpen: true,
                isRepeat: !!eventData.repeat,
                isTimeoff: eventData.type === 'timeoff',
                data: isEdit
                    ? eventData
                    : {
                          start: eventData.start,
                          schedules: eventData?.schedule ? [eventData.schedule] : [],
                          recurrence: null
                      }
            });
        }
    }, [eventData]);

    const _handleClose = () => {
        dispatchState({ isOpen: false });
        dispatch(closeEvent());
    };

    const _handleSubmit = () => {
        const errors = [];
        const description = refDescription.current.getValue();
        const location = refLocation.current.getValue();
        const { offset } = refSummary.current.getValue();
        const { isAllDay, hours, minutes, start } = refTimeLength.current.getValue();
        const schedules = refSchedule.current.getValue();
        const schedule_ids = schedules.map((item) => item.id);
        const optionsQuery = {
            data: {
                description,
                schedule_ids,
                hours,
                minutes,
                address: location,
                start,
                all_day: isAllDay ? 1 : 0,
                type: isTimeoff ? 'timeoff' : 'event',
                recurrence: offset.offset
            },
            toFormData: false,
            method: isEdit ? 'PUT' : 'POST'
        };

        if (!isTimeoff) {
            optionsQuery.data['name'] = refName.current.getValue();
            optionsQuery.data['color'] = refColor.current.getValue();
        }

        // Validate condition submit
        if (isTimeoff && !description.trim().length)
            errors.push(t('customers:cannot_be_blank', { name: t('common:description') }));
        if (!schedule_ids.length) errors.push(t('customers:cannot_be_blank', { name: t('jobDetail:schedule') }));
        if (!isAllDay && !hours && !minutes) errors.push(t('common:length_of_time_off_is_not_valid'));

        // If has errors break function
        if (errors.length) {
            _submitFail({ message: errors });
        } else {
            const dataUpdateSuccess = {
                schedules,
                hours,
                minutes,
                start,
                all_day: isAllDay ? 1 : 0,
                description,
                recurrence: offset,
                repeat: offset?.summary === t('calendar:once') ? 0 : 1,
                type: isTimeoff ? 'timeoff' : 'event'
            };

            if (!isTimeoff) {
                dataUpdateSuccess['name'] = refName.current.getValue();
                dataUpdateSuccess['color'] = refColor.current.getValue();
            }

            if (isEdit && isRepeat) {
                refDataUpdate.current = dataUpdateSuccess;
                refConfirm.current._toggleOpen(true, optionsQuery.data);
                return;
            }

            clientQuery(
                getURL(),
                optionsQuery,
                ({ data = {} }) => _submitSuccess({ ...data, ...dataUpdateSuccess }),
                _submitFail
            );
        }
    };

    const _submitSuccess = (data) => {
        if (isEdit) {
            eventData.onUpdate(data);
        } else {
            dispatch(createEventSuccess(data));
            handleTrackingEvent(mixpanelAddTimeoff({ id: profileId }));
        }
        _handleClose();
    };

    const _submitFail = ({ message }) => {
        refAlert.current.showStatusBar('alert', message, LIST_STATUS.ERROR);
        refFooter.current.removeLoading();
    };

    const _handleConfirm = (all, dataConfirm = {}) => {
        clientQuery(
            getURL(),
            { method: 'PUT', data: { ...dataConfirm, all }, toFormData: false },
            ({ data }) => _submitSuccess({ ...data, ...refDataUpdate.current }),
            _submitFail
        );
    };

    const _handleCancel = () => {
        refFooter.current.removeLoading();
    };

    const _handleChangeDate = (start) => {
        refSummary.current.changeStart(start);
    };

    const getURL = () => {
        let url = isEdit ? getTimeoffDetail(data.id) : TIMEOFF_API;
        if (!isTimeoff) url = isEdit ? getEventDetail(data.id) : EVENT_API;
        return url;
    };

    const getTitle = (type = 'timeoff') => {
        let title = '';
        switch (type) {
            case 'timeoff':
                title = isEdit ? t('edit_time_off') : t('add_time_off');
                break;
            case 'event':
                title = isEdit ? t('edit_custom_event') : t('add_custom_event');
                break;
            default:
                break;
        }

        return title;
    };

    if (!isOpen) return null;

    if (
        [
            PERMISSIONS.addEvent,
            PERMISSIONS.editOrDeleteEvent,
            PERMISSIONS.addTimeOff,
            PERMISSIONS.editOrDeleteTimeOff
        ].every((p) => permissionsDisabled.includes(p))
    )
        return;

    return (
        <>
            <ReactModal
                isOpen
                id="add_time_off"
                className="modal container-modal wrapper-new-events open"
                style={{ overlay: { zIndex: 999, backgroundColor: 'transparent' } }}
                onRequestClose={_handleClose}
            >
                <div className="modal__overlay bg-fixed" onClick={_handleClose} />
                <div className="modal__container sidebar-left-content">
                    <div className="wrapper-events flex-column">
                        <HeaderModal title={getTitle(isTimeoff ? 'timeoff' : 'event')} onClose={_handleClose} />
                        <div className="sidebar-content flex-1">
                            <StatusBar ref={refAlert} />

                            {!isTimeoff ? (
                                <>
                                    <SelectText
                                        ref={refName}
                                        icon={<IconPen isHasColor />}
                                        placeholder={t('event_name')}
                                        autoFocus
                                        defaultValue={data.name || ''}
                                    />
                                    <InputColor ref={refColor} defaultColor={data.color} />
                                </>
                            ) : null}

                            <TimeLength
                                ref={refTimeLength}
                                start={data.start}
                                onChangeDate={_handleChangeDate}
                                defaultValue={
                                    !isEdit ? null : { all_day: data.all_day, minutes: data.minutes, hours: data.hours }
                                }
                            />
                            <SelectRepeat
                                ref={refSummary}
                                defaultValue={data.recurrence || null}
                                start={data.start}
                                isRepeat={isRepeat}
                            />
                            <SelectSchedule ref={refSchedule} defaultValue={data.schedules} />
                            <SelectLocation ref={refLocation} defaultValue={data?.location?.address || ''} />
                            <SelectText
                                ref={refDescription}
                                autoFocus={isTimeoff}
                                isTextarea
                                defaultValue={data.description || ''}
                                placeholder={t('description')}
                            />
                        </div>
                        <FooterModal ref={refFooter} onCancel={_handleClose} onSave={_handleSubmit} />
                    </div>
                </div>
            </ReactModal>
            <TimeOffConfirm
                ref={refConfirm}
                showDes={false}
                title={t(`calendar:${isTimeoff ? 'update_recurring_time_off' : 'update_recurring_custom_event'}`)}
                textOnly={t(`calendar:${isTimeoff ? 'update_this_time_off_only' : 'update_this_custom_event_only'}`)}
                textRecurring={t(
                    `calendar:${isTimeoff ? 'update_this_time_off_and_all_recurring' : 'update_this_custom_event_and_all_recurring'}`
                )}
                onCancel={_handleCancel}
                onSubmit={_handleConfirm}
            />
        </>
    );
};

export default TimeOff;

import classNames from 'classnames';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from 'app/components/checkbox';
import ItemJobStatus from 'app/components/job/ItemJobStatus';
import { DEFAULT_ALL } from 'app/const/App';
import IconUpgrade from 'assets/icon/IconUpgrade';

export const ItemDropdownSingle = ({
    refSelect = null,
    options = [],
    fileTranslation = '',
    selected = null,
    timeText = false,
    parseHtml = false,
    isPopper = false,
    disableTranslation = false,
    isJobStatus = false,
    handleSelectOption = () => {}
}) => {
    const { t } = useTranslation([fileTranslation]);

    return options.map((item, index) => {
        const finalValue = item?.value || item?.id;
        const finalName = disableTranslation ? item.name : t(`${fileTranslation}:${item.name}`);
        const className = classNames('items', { active: finalValue === selected, 'is-disable': item?.disable });

        const _handleClick = () => {
            handleSelectOption(item);
            isPopper && refSelect.current?._close();
        };

        // case options has group title
        if (item.type === 'group') {
            return (
                <div
                    key={index.toString()}
                    className="title-section"
                    onClick={(e) => {
                        e.stopPropagation();
                        isPopper && refSelect.current?._close();
                    }}
                >
                    {t(`${fileTranslation}:${item.name}`)}
                </div>
            );
        }

        const _renderItem = () => {
            if (timeText) return item.name;
            if (isJobStatus) return item.id === DEFAULT_ALL ? t('all') : <ItemJobStatus idStatus={item.id} />;
            if (parseHtml) return <div dangerouslySetInnerHTML={{ __html: item.name }} />;
            if (item?.showIcon)
                return (
                    <div className="d-flex align-center">
                        {finalName}
                        <IconUpgrade isFillOrange />
                    </div>
                );

            return <div className="txt-ellipsis">{finalName}</div>;
        };

        return (
            <li key={index.toString()} className={className} title={finalName} onClick={_handleClick}>
                {_renderItem()}
            </li>
        );
    });
};

export const ItemDropdownMulti = ({
    fileTranslation = '',
    title = '',
    options = [],
    selected = null,
    isShowAvatar = false,
    isJobStatus = false,
    handleSelectOption = () => {}
}) => {
    const { t } = useTranslation([fileTranslation]);

    return options.map(({ id: idSelect, name, avatar, value, first_name, last_name, label, color }, index) => {
        const finalValue = idSelect || value;
        const finalId = `${finalValue}-${index}`;
        const isCheck = selected === DEFAULT_ALL || selected.includes(finalValue);

        const _renderAvatar = () => {
            return avatar ? (
                <img src={avatar} width={24} height={24} alt="" />
            ) : (
                <div className="avt">{name.substring(0, 2)}</div>
            );
        };

        const _handleSelectOption = (e) => {
            e.preventDefault();
            handleSelectOption(e, finalValue);
        };

        return (
            <li key={finalId} className={classNames('items', { active: isCheck })} onClick={_handleSelectOption}>
                <Checkbox
                    label={
                        isJobStatus
                            ? null
                            : t(`${name || `${first_name} ${last_name}`}`, {
                                  nsSeparator: false
                              })
                    }
                    checked={isCheck}
                    id={`check-box-${finalId}-${title}-${name}`}
                    onChangeValue={handleSelectOption}
                    value={finalValue}
                    classLabelChild={classNames({ 'user-name': isShowAvatar })}
                >
                    {isShowAvatar && <span className="avt-img">{_renderAvatar()}</span>}
                    {isJobStatus ? <ItemJobStatus defaultStatus={{ name, label, color }} excludeWrapper /> : null}
                </Checkbox>
            </li>
        );
    });
};

export const ItemDropdownCustomer = ({
    refSelect = null,
    options = [],
    selected = null,
    isPopper = false,
    handleSelectOption = () => {}
}) => {
    return options.map((item) => {
        const isCheck = item?.id === selected || selected === -1;

        return (
            <li
                key={item.id}
                className={classNames('items', { active: isCheck })}
                onClick={(e) => {
                    handleSelectOption(e, item);
                    isPopper && refSelect.current?._close();
                }}
            >
                <div className="user-name">
                    <div className="avt fs-11 fw-500">
                        {item.first_name.charAt(0).concat(item.last_name.charAt(0)).toUpperCase()}
                    </div>
                    <span className="txt-ellipsis">{`${item.first_name} ${item.last_name}`}</span>
                </div>
            </li>
        );
    });
};

export const ItemDropdownRating = ({ options = [], renderSelectAllOption = () => {}, renderList = () => {} }) => {
    return (
        <Fragment>
            {renderSelectAllOption()}
            {renderList(options)}
        </Fragment>
    );
};

export const ItemDropdownSort = ({
    refSelect = null,
    isPopper = false,
    options = [],
    selected = null,
    fileTranslation = '',
    handleSelectOption = () => {}
}) => {
    const { t } = useTranslation([fileTranslation]);

    return options.map((item) => {
        return (
            <li
                key={item.id}
                className={classNames('items', { active: item.value === selected })}
                onClick={() => {
                    handleSelectOption(item.value, item);
                    isPopper && refSelect.current?._close();
                }}
            >
                <div className="txt-ellipsis">{t(`${fileTranslation}:${item.name}`)}</div>
            </li>
        );
    });
};

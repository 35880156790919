import React, { useEffect, useReducer, useRef } from 'react';
import moment from 'moment-timezone';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import IconDoubleStep from 'assets/icon/IconDoubleStep';
import { convertTimeToISO } from 'common/utils/DateUtils';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';
import TooltipPopper from 'app/components/tooltip/TooltipPopper';

const JumpPicker = ({ currentDate, type = 'prev', onSelect = () => {} }) => {
    const { t } = useTranslation(['calendar, jobDetail']);
    const isPrev = type === 'prev';
    const [state, setState] = useReducer(reducer, { isVisible: false });
    const finalIsVisible = state.isVisible;
    const refDropDown = useRef(null);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById(`show_list_jum_${type}_datepicker`);
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById(`show_list_jum_${type}_datepicker`);

        if (
            refDropDown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropDown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    const _handleOpen = (e) => {
        e.stopPropagation();

        setState({ isVisible: !finalIsVisible });
    };

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    function _handleSelectJumb(e, typeSelect, value) {
        e.stopPropagation();
        if (type === 'prev') {
            onSelect(convertTimeToISO(moment(currentDate, 'YYYY-MM-DD HH:mm:ss').subtract(value, typeSelect)));
        } else {
            onSelect(convertTimeToISO(moment(currentDate, 'YYYY-MM-DD HH:mm:ss').add(value, typeSelect)));
        }
        _closeSearchResult();
    }

    function _renderWeek() {
        return Array.from(new Array(8), (val, index) => {
            const finalValue = index + 1;

            return (
                <li
                    className="items"
                    key={finalValue.toString()}
                    onClick={(e) => _handleSelectJumb(e, 'weeks', finalValue)}
                >
                    <p className="txt-ellipsis">
                        {finalValue} {t(`jobDetail:${`week${finalValue === 1 ? '' : 's'}`}`)}
                    </p>
                </li>
            );
        });
    }

    function _renderMonth() {
        // 3 and 6 is months
        return Array.from([3, 6], (val) => {
            return (
                <li className="items" key={val.toString()} onClick={(e) => _handleSelectJumb(e, 'months', val)}>
                    <p className="txt-ellipsis">
                        {val} {t('jobDetail:months')}
                    </p>
                </li>
            );
        });
    }

    function _renderYear() {
        return (
            <li onClick={(e) => _handleSelectJumb(e, 'years', 1)} className="items">
                <p className="txt-ellipsis">{t('calendar:1year')}</p>
            </li>
        );
    }

    return (
        <div ref={refDropDown} className={`v2-dropdown dropdown-jump ${finalIsVisible ? 'active' : ''}`}>
            <TooltipPopper
                onClick={_handleOpen}
                className={`btn-action tooltip`}
                tooltipText={t(isPrev ? 'calendar:jump_back' : 'calendar:jump_next')}
            >
                <IconDoubleStep isPrev={isPrev} />
            </TooltipPopper>

            {finalIsVisible && (
                <div id={`show_list_jum_${type}_datepicker`} className="v2-dropdown__menu --right scrolls">
                    <ul>
                        {_renderWeek()}
                        {_renderMonth()}
                        {_renderYear()}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default JumpPicker;

import React from 'react';
import { useTranslation } from 'react-i18next';

import IconClose from 'assets/icon/IconClose';

export default function Header({ onClose }) {
    const { t } = useTranslation();
    return (
        <div className="header-modal">
            <h3 className="header-modal__label">{t('customers:add_payment')}</h3>
            <div className="v2-btn-default --icon-lg --transparent" onClick={onClose} tabIndex="0">
                <IconClose />
            </div>
        </div>
    );
}

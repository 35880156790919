export default function IconTag({ isNormal }) {
    if (isNormal)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                {' '}
                <path
                    d="M11.6213 6.37868C12.1839 5.81607 12.947 5.5 13.7426 5.5L17.5 5.5C18.6046 5.5 19.5 6.39544 19.5 7.50001L19.5 11.2574C19.5 12.053 19.1839 12.8161 18.6213 13.3787L12.2071 19.7929C11.8166 20.1834 11.1834 20.1834 10.7929 19.7929L5.20711 14.2071C4.81658 13.8166 4.81658 13.1834 5.20711 12.7929L11.6213 6.37868Z"
                    stroke="var(--color-icon)"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />{' '}
                <circle cx={15} cy={10} r="1.5" stroke="var(--color-icon)" strokeWidth="1.3" />{' '}
            </svg>
        );
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.00007 4.00391L10.1653 4.00507C10.6955 4.00521 11.204 4.21592 11.5789 4.59085L18.5725 11.5844C19.3124 12.3243 19.3514 13.4998 18.6893 14.2856L18.5725 14.4128L14.4076 18.5777C13.6677 19.3177 12.4922 19.3566 11.7064 18.6946L11.5792 18.5777L4.58576 11.5843C4.21074 11.2093 4.00003 10.7007 3.99998 10.1703L3.99951 6.00279C3.99982 4.89822 4.8955 4.00304 6.00007 4.00391ZM12.4143 8.41325C11.6199 7.64574 10.3538 7.66751 9.58628 8.46187L8.46665 9.62065C7.71256 10.4011 7.7185 11.6406 8.48004 12.4138L11.5823 15.5636L11.5923 15.5736C12.3729 16.3551 13.6392 16.3558 14.4207 15.5752L15.5851 14.4121C16.3677 13.631 16.3677 12.3647 15.5859 11.5844L12.4266 8.42525L12.4143 8.41325ZM7.00007 5.99862C6.44778 5.99862 6.00007 6.44633 6.00007 6.99862C6.00007 7.5509 6.44778 7.99862 7.00007 7.99862C7.55235 7.99862 8.00007 7.5509 8.00007 6.99862C8.00007 6.44633 7.55235 5.99862 7.00007 5.99862Z"
                fill="#C1C9D4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99949 12C9.44744 11.4477 9.44757 10.5524 9.99977 10.0002C10.5522 9.44785 11.4478 9.44793 12 10.0004L14.0001 12.0012C14.5529 12.5543 14.5521 13.451 13.9982 14.003C13.4454 14.5539 12.551 14.553 11.9993 14.001L9.99949 12Z"
                fill="#7A83A6"
            />
        </svg>
    );
}

import {
    INBOX_DONE,
    INBOX_DRAFT,
    INBOX_FUTURE,
    INBOX_SENT,
    OPPORTUNITIES_INBOX,
    SALES_FUNNEL,
    STATUS_CHANGES
} from 'app/config/routes';
import { ACCESS_PIPELINE_REPORT, ACCESS_SALES_FUNNEL, ACCESS_STATUS_CHANGES } from 'app/const/Permissions';
import { INBOX } from 'app/const/Route';

export const FILTER_STATUS_INBOX = {
    INBOX: '0',
    DONE: '2',
    FUTURE: '1',
    SENT: '4',
    DRAFT: '3',
    REPORT: '5'
};

export const FILTER_TYPE_INBOX = {
    ALL: '0',
    EMAIL: '2',
    TASKS: '1',
    CALLS: '3',
    SMS: '4'
};

export const CONST_INBOX_PAGE = {
    INBOX: 'inbox'
};

export const TYPE_FEATURE_SETTING = {
    COMPARISON: 'call_activity_report',
    SMART_DIALER: 'smart_dialer',
    SMART_VIEWS: 'smart_views'
};

export const KEY_SUB_INBOX = {
    INBOX: 'inbox',
    DONE: 'done',
    FUTURE: 'future',
    SENT: 'sent',
    DRAFT: 'draft'
};

export const LIST_FILTER_STATUS_INBOX = [
    {
        name: KEY_SUB_INBOX.INBOX,
        type: FILTER_STATUS_INBOX.INBOX,
        allow: [
            FILTER_TYPE_INBOX.ALL,
            FILTER_TYPE_INBOX.EMAIL,
            FILTER_TYPE_INBOX.TASKS,
            FILTER_TYPE_INBOX.CALLS,
            FILTER_TYPE_INBOX.SMS
        ],
        parent: true,
        path: INBOX
    },
    {
        name: KEY_SUB_INBOX.DONE,
        type: FILTER_STATUS_INBOX.DONE,
        allow: [FILTER_TYPE_INBOX.ALL, FILTER_TYPE_INBOX.EMAIL, FILTER_TYPE_INBOX.TASKS, FILTER_TYPE_INBOX.CALLS],
        path: INBOX_DONE
    },
    {
        name: KEY_SUB_INBOX.FUTURE,
        type: FILTER_STATUS_INBOX.FUTURE,
        allow: [FILTER_TYPE_INBOX.ALL, FILTER_TYPE_INBOX.EMAIL, FILTER_TYPE_INBOX.TASKS, FILTER_TYPE_INBOX.CALLS],
        path: INBOX_FUTURE
    },
    {
        name: KEY_SUB_INBOX.SENT,
        type: FILTER_STATUS_INBOX.SENT,
        allow: [FILTER_TYPE_INBOX.ALL, FILTER_TYPE_INBOX.EMAIL, FILTER_TYPE_INBOX.SMS],
        path: INBOX_SENT,
        isCheckActivateInboxAndSMS: true
    },
    {
        name: KEY_SUB_INBOX.DRAFT,
        type: FILTER_STATUS_INBOX.DRAFT,
        allow: [FILTER_TYPE_INBOX.ALL, FILTER_TYPE_INBOX.EMAIL],
        path: INBOX_DRAFT,
        isCheckActivateInbox: true
    }
];

export const LIST_FILTER_TYPE_INBOX = [
    {
        name: 'all',
        type: FILTER_TYPE_INBOX.ALL,
        allow: [FILTER_STATUS_INBOX.INBOX, FILTER_STATUS_INBOX.DONE, FILTER_STATUS_INBOX.FUTURE]
    },
    {
        name: 'email',
        type: FILTER_TYPE_INBOX.EMAIL,
        allow: [
            FILTER_STATUS_INBOX.INBOX,
            FILTER_STATUS_INBOX.DONE,
            FILTER_STATUS_INBOX.FUTURE,
            FILTER_STATUS_INBOX.SENT,
            FILTER_STATUS_INBOX.DRAFT
        ]
    },
    {
        name: 'tasks',
        type: FILTER_TYPE_INBOX.TASKS,
        allow: [FILTER_STATUS_INBOX.INBOX, FILTER_STATUS_INBOX.DONE, FILTER_STATUS_INBOX.FUTURE]
    },
    {
        name: 'calls',
        type: FILTER_TYPE_INBOX.CALLS,
        allow: [FILTER_STATUS_INBOX.INBOX, FILTER_STATUS_INBOX.DONE, FILTER_STATUS_INBOX.FUTURE]
    },
    {
        name: 'sms',
        type: FILTER_TYPE_INBOX.SMS,
        allow: [FILTER_STATUS_INBOX.INBOX, FILTER_STATUS_INBOX.SENT]
    }
];

export const LIST_SORT_INBOX = [
    {
        name: 'oldest_first',
        type: 'date asc'
    },
    {
        name: 'newest_first',
        type: 'date desc'
    },
    {
        name: 'date_completed',
        type: 'date_completed desc'
    }
];

export const TYPE_RENDER = {
    TASK: 'task',
    EMAIL_INBOX: 'email_inbox',
    CALL: 'call',
    SMS: 'sms'
};

export const TYPE_SHOW_SMS = {
    INBOX: 'inbox',
    JOB: 'job',
    HEADER: 'header'
};

export const TYPE_OPPORTUNITY_INBOX = {
    PIPELINE: 'pipeline',
    SALES_FUNNEL: 'sales_funnel',
    STATUS_CHANGES: 'status_changes'
};

export const ROUTE_OPPORTUNITY_REPORT = {
    [ACCESS_PIPELINE_REPORT]: OPPORTUNITIES_INBOX,
    [ACCESS_SALES_FUNNEL]: SALES_FUNNEL,
    [ACCESS_STATUS_CHANGES]: STATUS_CHANGES
};

export const LIST_MARK_DONE_CALLS = [
    {
        label: 'selected_items',
        value: 1,
        tooltip: 'tooltip_selected_items'
    },
    {
        label: 'for_all_users',
        value: 2,
        tooltip: 'tooltip_for_all_users'
    }
];

export const KEY_GET_NUMBER_SELECTED_INBOX = 'selected_inbox_customer';

export const TYPE_ACTION_CALL_ACTIVITY = {
    INCOMING_CALL: 1,
    OUTBOUND_CALL: 2
};
export const INBOX_ACTION_CONFIGS = {
    BLOCK_NUMBER: {
        key: 'BLOCK_NUMBER',
        title: 'block_number',
        messageSuccess: 'number_block_successfully',
        messageFail: 'number_block_failed'
    },
    RESEND: {
        key: 'RESEND',
        title: 'resend_message',
        description: 'description_resend_message_fail',
        messageSuccess: 'resend_sms_message',
        messageFail: 'resend_sms_message'
    },
    WARNING_SELECT_ALL: {
        key: 'WARNING_SELECT_ALL',
        title: 'confirm',
        description: 'no_items_to_resend_warning'
    }
};
export const SMS_SENT_STATUS = { KEY: 'status', VALUE: 6 };

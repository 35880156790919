import classNames from 'classnames';
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import { ONBOARDING } from 'app/config/routes';
import { TYPE_ACCOUNT } from 'app/const/Account';
import { TIMEOUT_DEFAULT } from 'app/const/addons';
import { UPDATE_QUEST_LOG_OPTION, USER_PROFILE } from 'app/const/api/V2';
import { KEY_MAP_CALENDAR_STYLE, KEY_MAP_CUSTOMER_LIST_STYLE } from 'app/const/App';
import { addBranchPath, addPrefixPath } from 'app/const/Branch';
import { DARK_MODE_INFO, ID_QUEST_LOG } from 'app/const/Header';
import { CALENDAR, GORILLA_DESK_TRAINING_CALL_LINK } from 'app/const/Route';
import { helpOptions } from 'app/const/StaticLinks';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { CUSTOMER_CUSTOM_EVENTS } from 'app/modules/customer/const';
import { handleAbortController } from 'app/modules/customer/utils';
import { KEY_ONBOARDING } from 'app/modules/onboarding/constant';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { updateSettings } from 'common/redux/actions/authAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { applyMapTheme, getDarkModeSettingsFromCSS, toggleDarkMode } from 'common/utils/FunctionUtils';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';

function Help(
    { isExpired = false, onHandleChangeQuestLog = () => {}, onToggleDarkMode = () => {}, onError = () => {} },
    ref
) {
    const { pathname } = useLocation();
    const { t } = useTranslation(['header', 'common']);
    const dispatch = useDispatch();
    const history = useHistory();
    const questLog = useSelector(({ auth }) => auth?.user?.settings?.questlog?.question_active);
    const userStatus = useSelector((state) => state?.auth?.user?.profile?.plan?.status || '');
    const darkModeSetting = useSelector(({ auth }) => auth.user.settings.dark_mode);
    const dataPins = useSelector(({ auth }) => auth.user.settings.header_menu || []);

    const isDarkMode = !!darkModeSetting?.value;
    const refDropdown = useRef(null);
    const refValue = useRef(!!questLog);
    const refDarkMode = useRef(isDarkMode);
    const refQuestLogCheckbox = useRef(null);
    const refDarkModeCheckbox = useRef(null);
    const abortController = useRef(null);

    useImperativeHandle(ref, () => ({ _setHideDarkMode: _handleHideDarkMode }));

    useEffect(() => {
        return () => {
            handleAbortController(abortController);
        };
    }, []);

    useEffect(() => {
        if (!getLocalStorage(KEY_MAP_CALENDAR_STYLE) && !getLocalStorage(KEY_MAP_CUSTOMER_LIST_STYLE)) {
            applyMapTheme(isDarkMode);
        }
    }, []);

    const _handleHideDarkMode = (value) => {
        if (refDarkMode.current) {
            refDarkMode.current = false;
        }
        refDarkModeCheckbox.current && refDarkModeCheckbox.current._setActive(value);
    };

    const _handleOpenLink = ({ link, isNewTab = true }) => {
        if (!isNewTab) {
            history.push(addBranchPath(link));
            refDropdown.current?._closeDropdown();
            return;
        }

        const newWindow = window.open(link, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const _handleQuestLog = (checked) => {
        const value = checked ? 1 : 0;
        clientQuery(UPDATE_QUEST_LOG_OPTION, { data: { value }, method: 'PUT' });
        dispatch(updateSettings({ questlog: { question_active: value } }));
        onHandleChangeQuestLog(checked && dataPins.includes(KEY_ONBOARDING));
        refValue.current = checked;
        if (!value && matchPath(pathname, { path: addPrefixPath(ONBOARDING), exact: true })) {
            history.push(addBranchPath(CALENDAR));
        }
    };

    const _handleToggleDarkMode = (checked) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();

        const finalOptions = !darkModeSetting ? DARK_MODE_INFO.DEFAULT_SETTING : getDarkModeSettingsFromCSS();
        toggleDarkMode({ setting: checked ? { [DARK_MODE_INFO.VALUE]: 1, ...finalOptions } : {} });
        onToggleDarkMode(checked);
        refDarkMode.current = checked;
        const finalSetting = {
            [DARK_MODE_INFO.ID_DARK_MODE]: { [DARK_MODE_INFO.VALUE]: checked ? 1 : 0, ...finalOptions }
        };
        dispatch(updateSettings(finalSetting));
        dispatchEvent(new CustomEvent(CUSTOMER_CUSTOM_EVENTS.UPDATE_DARK_MODE, { detail: { value: !!checked } }));
        applyMapTheme(checked);
        clientQuery(
            USER_PROFILE,
            {
                data: { ...finalSetting, type: DARK_MODE_INFO.ID_DARK_MODE },
                method: 'PUT',
                toFormData: false,
                abortController: abortController.current
            },
            null,
            onError
        );
    };

    const renderList = () => {
        return helpOptions.map((item, index) => {
            const isUserTrial = userStatus === TYPE_ACCOUNT.FREE_TRIAL;
            const isQuestLog = item.id === ID_QUEST_LOG;

            if (isUserTrial && item.isHideTrial) return null;

            if (item.type === 'line') {
                if (helpOptions[index + 1]?.id === ID_QUEST_LOG && isExpired) return null;
                return <li key={item.id} className={item.type} />;
            }
            const _handleClick = () => {
                if (item.id !== 'chat_with_support') return _handleOpenLink(item);

                window.Intercom('boot');
                window.Intercom('show');
            };

            if (!isUserTrial) {
                if (item.id === 'book-s-sales-call') {
                    item.id = 'book_a_training_call';
                    item.link = GORILLA_DESK_TRAINING_CALL_LINK;
                }
            }

            if (isQuestLog || item.id === DARK_MODE_INFO.ID_DARK_MODE) {
                if (isQuestLog && isExpired) return null;
                return (
                    <ItemCheckbox
                        ref={isQuestLog ? refQuestLogCheckbox : refDarkModeCheckbox}
                        key={item.id}
                        item={item}
                        defaultChecked={() => (isQuestLog ? refValue.current : refDarkMode.current)}
                        timeOut={isQuestLog ? TIMEOUT_DEFAULT : 0}
                        isDisable={isExpired}
                        handleChange={isQuestLog ? _handleQuestLog : _handleToggleDarkMode}
                    />
                );
            }

            return (
                <li key={item.id} onClick={_handleClick} className="items has-icon">
                    {item.icon}
                    <p className="txt-ellipsis">{t(`header:${item?.id}`)}</p>
                </li>
            );
        });
    };

    return (
        <CalendarDropdown
            ref={refDropdown}
            id="help_options_dropdown"
            wrapperClassName="nav-help"
            wrapperListClass="v2-dropdown__menu --right"
            buttonClassName="dropbtn v2-btn-default --sm --icon-r fs-13 tooltip"
            customDropButton={() => (
                <>
                    <span className="txt-ellipsis">{t('help')}</span>
                    <p className="tooltiptext bottom">{t('help')}</p>
                    <span className="arrow">
                        <IconArrowDown />
                    </span>
                </>
            )}
        >
            <ul>{renderList()}</ul>
        </CalendarDropdown>
    );
}

const ItemCheckbox = forwardRef(
    ({ item = {}, defaultChecked = false, timeOut = 0, isDisable = false, handleChange = () => {} }, ref) => {
        const { t } = useTranslation();
        const refCheckbox = useRef(null);

        useImperativeHandle(ref, () => ({ _setActive: _handleActive }));

        const _handleActive = (value) => {
            refCheckbox.current && refCheckbox.current.setIsActive(value);
        };

        return (
            <li key={item.id}>
                <div className="items has-icon cursor-default">
                    {item.icon}
                    <span className="flex-1 mr-2">{t(`header:${item.id}`)}</span>
                    <GdSwitchCheckbox
                        ref={refCheckbox}
                        id={item.id}
                        textChecked={t('on')}
                        textUnChecked={t('off')}
                        inputClassName="toggle toggle-round"
                        defaultChecked={defaultChecked}
                        timeOut={timeOut}
                        wrapClassName={classNames('switch large', { 'is-disable': isDisable })}
                        onChange={handleChange}
                    />
                </div>
            </li>
        );
    }
);

export default forwardRef(Help);

import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import Email from 'app/modules/jobdetail/tabs/invoice/actions/components/email';
import IconClose from 'assets/icon/IconClose';

const SendByEmail = forwardRef(({ customerId, onSend, urlGetEmail, paramsEmail, objectEmail }, ref) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        type: 1,
        isEsign: false
    });

    const isVisible = state.isVisible;

    useImperativeHandle(ref, () => ({
        _handleOpenForm
    }));

    const _handleOpenForm = (type = 1, isEsign = false) => {
        dispatchState({
            isVisible: true,
            type,
            isEsign
        });
    };

    const _handleCloseForm = () => {
        dispatchState({
            isVisible: false
        });
    };

    const _handleSendEmail = (params, callback) => {
        onSend(params, callback, state.isEsign);
    };

    if (!isVisible) return null;

    return (
        <div className="modal container-modal modal-email-send --mail-dialog open">
            <div className="modal__overlay bg-fixed" onClick={_handleCloseForm} />
            <div className="modal__container">
                <div className="header-modal">
                    <h3 className="header-modal__label">
                        {state.isEsign ? t('jobDetail:send_for_e_sign') : t('common:send_by_email')}
                    </h3>
                    <span onClick={_handleCloseForm} className="v2-btn-default --icon-lg --transparent">
                        <IconClose />
                    </span>
                </div>
                <Email
                    customerId={customerId}
                    isVisible
                    handleCloseForm={_handleCloseForm}
                    onSend={_handleSendEmail}
                    urlGetEmail={urlGetEmail}
                    paramsEmail={{
                        ...paramsEmail,
                        type: state.type
                    }}
                    objectEmail={objectEmail}
                />
            </div>
        </div>
    );
});

export default SendByEmail;

import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconArrowDown from 'assets/icon/IconArrowDown';
import classNames from 'classnames';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';

const EMPTY_TAX = { id: '', name: '', rate: '' };

const LocationTax = forwardRef(({ id, defaultSelected = {} }, ref) => {
    const [selected, setSelected] = useState(EMPTY_TAX);
    const refDropdown = useRef(null);
    const { list_taxes } = useSelector((store) => store.taxesReducer);

    useImperativeHandle(ref, () => ({ _getValue: () => selected.id }));

    useEffect(() => {
        setSelected(!!Number(defaultSelected.id) ? defaultSelected : EMPTY_TAX);
    }, []);

    const _handleSelect = (id) => {
        setSelected(list_taxes.find((item) => item.id === id) || EMPTY_TAX);
        refDropdown.current._closeDropdown();
    };

    const _renderListTaxes = () => {
        return [EMPTY_TAX, ...list_taxes].map((item) => {
            return (
                <li
                    key={item.id}
                    className={classNames('items', { active: item.id === selected.id })}
                    onClick={() => _handleSelect(item.id)}
                >
                    <div className="txt-ellipsis">
                        {item.name} {item.rate && `${item.rate}%`}
                    </div>
                </li>
            );
        });
    };

    return (
        <CalendarDropdown
            id={id}
            ref={refDropdown}
            isDropUp
            onSelect={_handleSelect}
            customDropButton={() => (
                <>
                    <span className="txt-ellipsis">
                        {selected.name} {selected.rate && `${selected.rate}%`}
                    </span>
                    <div className="arrow">
                        <IconArrowDown />
                    </div>
                </>
            )}
        >
            <ul>{_renderListTaxes()}</ul>
        </CalendarDropdown>
    );
});

export default LocationTax;

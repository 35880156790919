import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { actionGetListSchedule } from 'common/redux/actions/calendar/scheduleAction';

const SelectScheduleList = forwardRef(({ defaultSelect = null }, ref) => {
    const { t } = useTranslation();
    const schedulesList = useSelector(({ schedules }) => schedules.data?.schedules || []);
    const [selected, setSelected] = useState(null);
    const dispatch = useDispatch();

    const selectedSchedule = selected || schedulesList[0] || {};
    const refScheduleList = useRef();

    useImperativeHandle(ref, () => ({ _getValue: () => selectedSchedule }));

    useEffect(() => {
        const _getListSuccess = ({ data }) => {
            setSelected(data.schedules.find((item) => item.id === defaultSelect));
        };
        dispatch(actionGetListSchedule({}, _getListSuccess));
    }, []);

    const _handleSelect = (idSelect) => {
        setSelected(schedulesList.find((item) => item.id === idSelect) || {});
    };

    return (
        <div className="boxs-selection">
            <p className="boxs-selection__label">{t('calendar:select_schedule')}:</p>
            <CalendarDropdown
                id="calendar_schedule_dropdown_setup"
                ref={refScheduleList}
                options={schedulesList}
                selected={selectedSchedule.name}
                selectedOption={selectedSchedule}
                keyGetKey="id"
                keyGetValue="id"
                keyGetName="name"
                onSelect={_handleSelect}
            />
        </div>
    );
});

export default SelectScheduleList;

import classNames from 'classnames';
import React, { useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ButtonSave from 'app/components/button/ButtonSave';
import { MERCHANT_ID, PAYMENT_CARD_CUSTOMER } from 'app/const/Customers';
import { reducer } from 'app/const/Reducer';
import { getInvoicesSubscription } from 'app/const/api/V2';
import { clientQuery } from 'common/utils/ApiUtils';

const InvoicesTable = ({
    invoices: defaultData = [],
    typeSubscription = PAYMENT_CARD_CUSTOMER.SQUARE,
    subscriptionId,
    isShowMore = false
}) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [state, dispatchState] = useReducer(reducer, { invoices: defaultData, showMore: isShowMore });
    const { invoices, showMore } = state;
    const refButtonSave = useRef(null);

    const _handleShowMore = () => {
        const _handleSuccess = ({ data, showMore }) => {
            dispatchState((prevState) => {
                return { ...prevState, invoices: [...prevState.invoices, ...data], showMore };
            });
        };

        const _handleFail = () => {
            dispatchState((prevState) => {
                return { ...prevState, showMore: false };
            });
        };

        const _handleFinally = () => {
            refButtonSave.current.removeLoading();
        };

        clientQuery(
            getInvoicesSubscription(id),
            {
                data: {
                    subscription_id: subscriptionId,
                    offset: invoices.length,
                    merchant: MERCHANT_ID[typeSubscription]
                },
                method: 'GET'
            },
            _handleSuccess,
            _handleFail,
            _handleFinally
        );
    };

    return (
        <>
            <div className="plans-content mt-9">
                <div className="header">
                    <div className="title-x-sm">{t('addons:invoices')}</div>
                </div>
                <div className="content">
                    <ListInvoice data={invoices} />
                    {showMore ? (
                        <div className="flex-betweenitems justify-center my-2 w-100">
                            <ButtonSave
                                ref={refButtonSave}
                                title={t('common:show_more_title')}
                                wrapClass="v2-btn-default"
                                isWhiteLoading
                                onSave={_handleShowMore}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

const ListInvoice = ({ data = [] }) => {
    if (!data.length) return null;
    return data.map((item) => {
        const status = item.status;
        return (
            <div key={item.number} className="rows --wrap">
                <div className="col --sm fw-600">{item.name}</div>
                <div className="col --stt">
                    <div
                        className={classNames('status-btn', {
                            [status.replace('_', '-').toLowerCase()]: status,
                            na: status === 'AUTO'
                        })}
                    >
                        {status}
                    </div>
                </div>
                <div className="col --stt">
                    <div className="status-btn na">{item.billing}</div>
                </div>
                <div className="flex-betweenitems mt-2 w-100">
                    <div className="col --md">{item.subscription_id}</div>
                    <div className="col --md">{item.number}</div>
                    <div className="col --time flex-1">{item.date}</div>
                </div>
            </div>
        );
    });
};

export default InvoicesTable;

import React from 'react';
import PropTypes from 'prop-types';

const Switch = ({ checked, handleToggle, value }) => {
    return (
        <div className="switch large">
            <input
                checked={checked}
                onChange={handleToggle}
                id={`toggle-switch-${value}`}
                className="toggle toggle-round"
                type="checkbox"
            />
            <label htmlFor={`toggle-switch-${value}`} />
        </div>
    );
};

Switch.propTypes = {
    checked: PropTypes.bool,
    handleToggle: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

Switch.defaultProps = {
    checked: false,
    handleToggle: () => {},
    value: 0
};

export default Switch;

// Calendar routes
export const CALENDAR = '/calendar';
export const DASHBOARD = '/dashboard';

// Report routes
export const REPORT_MAIN_PATH = '/reports';
export const REPORT_INVOICE = '/reports/invoices';
export const REPORT_ESTIMATES = '/reports/estimates';
export const REPORT_PAYMENT = '/reports/payments';
export const REPORT_DOCUMENTS = '/reports/documents';
export const REPORT_CREDIT = '/reports/credits';
export const REPORT_RECURRING_INVOICES = '/reports/recurringinvoices';
export const REPORT_SUBSCRIPTIONS = '/reports/subscriptions';
export const REPORT_SQUARE_SUBSCRIPTIONS = '/reports/squareSubscriptions';
export const REPORT_SERVICES_LOOKUP = '/reports/servicelookup';
export const REPORT_NEW_CUSTOMERS = '/reports/newcustomers';
export const REPORT_SERVICES = '/reports/recurringinvoices';
export const REPORT_LOCATION_WO_ACTIVE_JOBS = '/reports/locationswoactivejobs';
export const REPORT_CUSTOMERS_WO_ACTIVE_JOBS = '/reports/customerswoactivejobs';
export const REPORT_ACCOUNTS_AGING = '/reports/accountsaging';
export const REPORT_PAYMENTS_COLLECTED = '/reports/paymentscollected';
export const REPORT_TOTAL_SALES = '/reports/totalsales';
export const REPORT_SALES_FORECAST = '/reports/salesforecast';
export const REPORT_SALES_TAX_SUMMARY = '/reports/salestaxsummary';
export const REPORT_REVENUE_SERVICE = '/reports/revenue/service';
export const REPORT_REVENUE_CLIENT = '/reports/revenue/client';
export const REPORT_REVENUE_ITEM = '/reports/revenue/item';
export const REPORT_REVENUE_STAFF = '/reports/revenue/staff';
export const REPORT_REVENUE_SOURCE = '/reports/revenue/source';
export const REPORT_REVENUE_TAGS = '/reports/revenue/tags';
export const REPORT_MATERIAL_USE = '/reports/materialuse';
export const REPORT_EMAIL_LOG = '/reports/emailhistory';
export const REPORT_LEADS = '/reports/leads';
export const REPORT_LEADS_INBOUND = '/reports/leads/inbound';
export const REPORT_LEADS_BOOKING = '/reports/leads/booking';
export const REPORT_NEW_JOB = '/reports/newjobs';
export const REPORT_DEVICE_TRACKING = '/reports/device/tracking';
export const REPORT_SENTRICON = '/reports/device/sentricon';

// email inbox
export const INBOX = '/inbox';

// Addons
export const ADDONS = '/addons';

// Commissions
export const COMMISSIONS = '/commissions';

// Addons detail
export const ADDONS_DOCUMENTS = '/addons/documents';
export const ADDONS_DOCUMENTS_LIBRARY = `${ADDONS_DOCUMENTS}/docs_library`;
export const ADDONS_DOCUMENTS_AVAILABLE = `${ADDONS_DOCUMENTS}/docs_available`;
export const ADDONS_DOCUMENTS_MANAGE = `${ADDONS_DOCUMENTS}/manage_docs_library`;
export const ADDONS_STRIPE = '/addons/stripe';
export const ADDONS_STRIPE_SUBSCRIPTION = '/addons/stripe/subscription';
export const ADDONS_SQUARE = '/addons/square';
export const ADDONS_SQUARE_SUBSCRIPTION = '/addons/square/subscription';
export const ADDONS_SMS = '/addons/sms';
export const ADDONS_REVIEW_ENGINE = '/addons/review_engine';
export const ADDONS_ZAPIER = '/addons/zapier';
export const ADDONS_TRIGGER = '/addons/triggers';
export const ADDONS_INBOX_EMAIL = '/addons/inbox_email';
export const ADDONS_GPS_TRACKING = '/addons/gps_tracking';
export const ADDONS_DEVICE_TRACKING = '/addons/device_tracking';
export const ADDONS_MATERIAL_USAGE = '/addons/material';
export const ADDONS_MULTI_UNIT = '/addons/mdu_tracking';
export const ADDONS_MULTI_UNIT_MANAGER = '/addons/mdu_tracking_manager';
export const ADDONS_QUICKBOOKS_SYNC = '/addons/quickbooks';
export const ADDONS_QUICKBOOKS_SYNC_LOG = '/addons/quickbooks_sync_log';
export const ADDONS_ROUTING = '/addons/routing';
export const ADDONS_BOOKING = '/addons/online_booking';
export const ADDONS_BOOKING_SETTINGS = '/addons/online_booking_settings';
export const ADDONS_PORTAL = '/addons/customer_portal';
export const ADDONS_PORTAL_WIDGET = '/addons/customer_portal/widget';
export const ADDONS_COMMISSION_TRACKING = '/addons/commission_tracking';
export const ADDONS_TIME_WINDOW = '/addons/timewindow';
export const ADDONS_API_KEYS = '/addons/api';
export const ADDONS_VOIP = '/addons/voip';
export const ADDONS_VOIP_MANAGE_PERSONAL = '/addons/voip/personal';
export const ADDONS_VOIP_MANAGE_GROUP = '/addons/voip/group';
export const ADDONS_VOIP_SETTINGS = '/addons/voip/settings';
export const ADDONS_VOIP_PLAN = '/addons/voip/plan';
export const ADDONS_VOIP_ACTIVE_USERS = '/addons/voip/users';
export const ADDONS_BLOCKED_NUMBERS = '/addons/blocked_numbers';
export const ADDONS_VOIP_HOLD = '/addons/voip/music';
export const ADDONS_VOIP_VOICEMAIL_DROP = '/addons/voip/voicemail_drop';
export const ADDONS_MULTIPLE_BRANCH = '/addons/multiple_branch';
export const ADDONS_MULTIPLE_BRANCH_PLAN = '/addons/multiple_branch/plan';
export const ADDONS_MULTIPLE_BRANCH_MANAGE = '/addons/multiple_branch/manage';
export const ADDONS_OPPORTUNITY = ADDONS + '/opportunity';
export const ADDONS_OPPORTUNITY_MANAGE = ADDONS + '/opportunity_manage';
export const ADDONS_SMARTVIEW = '/addons/smart_views';
export const ADDONS_SMARTVIEW_PERMISSION = ADDONS_SMARTVIEW + '/permissions';
export const ADDONS_MAIN_JOB_STATUS = ADDONS + '/custom_job_status';
export const ADDONS_OPPORTUNITY_PERMISSIONS = ADDONS + '/opportunity_permissions';
export const ADDONS_WORK_LOG = ADDONS + '/work_log';
export const ADDONS_WORK_LOG_MANAGE = ADDONS_WORK_LOG + '/manage';
export const ADDONS_SERVICE_PLAN = ADDONS + '/service_plan';
// Custom fields addons
export const ADDONS_CUSTOM_FIELDS = ADDONS + '/custom_fields';
export const ADDONS_CUSTOM_FIELDS_MANAGE = ADDONS_CUSTOM_FIELDS + '/manage';
export const ADDONS_CUSTOM_FIELDS_PERMISSIONS = ADDONS_CUSTOM_FIELDS + '/permissions';
export const ADDONS_JOB_STATUS_MANAGE = ADDONS_MAIN_JOB_STATUS + '/manage';
export const ADDONS_JOB_STATUS_PERMISSION = ADDONS_MAIN_JOB_STATUS + '/permissions';
// Estimate
export const ADDONS_ESTIMATE = ADDONS + '/estimates';
export const ADDONS_AI = ADDONS + '/ai_agents';
export const ADDONS_CHAT_BOT_CONTENT = ADDONS_AI + '/content/:type';
export const ADDONS_CHAT_BOT_CUSTOM_ANSWER = ADDONS_AI + '/custom_answers/:type';
export const ADDONS_CHAT_BOT_SETTINGS = ADDONS_AI + '/settings/:type';
export const ADDONS_CHAT_BOT_REPORT = ADDONS_AI + '/report/:type';
export const ADDONS_CHAT_BOT_TRAIN_AI = ADDONS_AI + '/train_ai/:type';
export const ADDONS_CHAT_BOT_CONTENT_PORTAL_AI = ADDONS_AI + '/content/ai_agent_portal';

// Advance Routing
export const ADVANCE_ROUTING = ADDONS + '/advance_routing';
export const BEST_AVAILABLE_TIME_MANAGE = ADDONS_ROUTING + '/available_manage';
export const JOB_MAGNET_MANAGE = ADDONS_ROUTING + '/magnet_manage';
export const ADDONS_SENTRICON_MANAGE = ADDONS_DEVICE_TRACKING + '/sentricon';

// Reviews
export const REVIEWS = '/reviews';
export const REVIEWS_SERVICE = '/reviews/service';
export const REVIEWS_LIKELY = '/reviews/likely';

// Customer import
export const CUSTOMERS_IMPORT = '/customers/customerImport';
export const CUSTOMERS_IMPORTED = '/customers/customerImported';

export const ACCOUNT_MAIN_PATH = '/account/plans';
export const ACCOUNT_PLANS_THANKS = `${ACCOUNT_MAIN_PATH}/thanks`;
export const ACCOUNT_PLANS_UPGRADE = `${ACCOUNT_MAIN_PATH}/upgrade-successful`;
export const ACCOUNT_PLANS_DOWNGRADE = `${ACCOUNT_MAIN_PATH}/downgrade-successful`;

// Settings
export const SETTINGS_MAIN_PATH = '/settings';
export const SETTINGS_LINE_ITEMS = '/settings/items';
export const SETTINGS_PLANS = '/settings/plans';
export const SETTINGS_INVOICES = '/settings/invoices';
export const INVOICE_DETAIL = '/settings/invoice/:id';
export const SETTINGS_PAYMENT_METHODS = '/settings/paymentmethods';
export const SETTINGS_TILES = '/settings/tiles';
export const SETTINGS_TAXES = '/settings/taxes';
export const SETTINGS_TAGS = '/settings/tags';
export const SETTINGS_USERS = '/settings/users';
export const SETTINGS_SCHEDULES = '/settings/schedules';
export const SETTINGS_ESTIMATE = '/settings/estimate';
export const SETTINGS_SOURCES = '/settings/sources';
export const SETTINGS_INVOICE = '/settings/invoice';
export const SETTINGS_WORK_ORDER = '/settings/workorder';
export const SETTINGS_COMPANY = '/settings/company';
export const SETTINGS_SERVICE = '/settings/service';
export const SETTINGS_SERVICE_ADD = '/settings/service/add';
export const SETTINGS_SERVICE_EDIT = '/settings/service/edit/:id';
export const SETTINGS_CUSTOMS = '/settings/customs';
export const SETTINGS_CUSTOMS_DETAIL = '/settings/customsdetails/:id?';
export const SETTINGS_BROADCAST = '/settings/broadcast';
export const SETTINGS_EMAIL_INBOX = '/settings/emailinbox';
export const SETTINGS_EMAIL_INBOX_DETAIL = '/settings/emailinbox/details/:id?';
export const SETTINGS_SYSTEMS = '/settings/emails';
export const SETTINGS_ACCOUNT = '/settings/account';
export const SETTINGS_PAPERWORK = '/settings/paperwork';
export const SETTINGS_TEMPLATES_MANAGER = '/settings/templates';
export const SETTINGS_TEMPLATES_TODO = '/settings/templates/todo';
export const SETTINGS_TEMPLATES_NOTES = '/settings/templates/notes/:id';
export const SETTINGS_TEMPLATES_SMS = '/settings/templates/sms';
export const SETTINGS_TEMPLATES_ESTIMATE = `${SETTINGS_TEMPLATES_MANAGER}/estimates/:id`;
export const SETTINGS_SERVICE_PLAN = `${SETTINGS_MAIN_PATH}/service_plans`;
export const SETTINGS_SERVICE_PLAN_ADD = `${SETTINGS_SERVICE_PLAN}/add`;
export const SETTINGS_SERVICE_PLAN_EDIT = `${SETTINGS_SERVICE_PLAN}/edit/:id`;

// Customers
export const CUSTOMERS = '/customers';
export const CUSTOMERS_DETAIL = '/customers/:id';
export const CUSTOMERS_CREDITS = '/customers/credits/:id';
export const CUSTOMERS_CONTACTS = '/customers/contacts/:id';
export const CUSTOMERS_ESTIMATES = '/customers/estimates/:id';
export const CUSTOMERS_LOCATIONS = '/customers/locations/:id';
export const CUSTOMERS_STATEMENTS = '/customers/statements/:id';
export const CUSTOMERS_ACCOUNT = '/customers/account/:id';
export const CUSTOMERS_INVOICES = '/customers/invoices/:id';
export const CUSTOMERS_DOCUMENTS = '/customers/documents/:id';
export const CUSTOMERS_PAYMENTS = '/customers/payments/:id';
export const CUSTOMERS_JOBS = '/customers/jobs/:id';
export const CUSTOMERS_PORTAL = '/customers/portal/:id';

// Paths routes layout
export const CUSTOMERS_IMPORT_PATHS = [CUSTOMERS_IMPORT, CUSTOMERS_IMPORTED];

export const CUSTOMERS_DETAILS_PATHS = [
    CUSTOMERS_DETAIL,
    CUSTOMERS_CREDITS,
    CUSTOMERS_CONTACTS,
    CUSTOMERS_ESTIMATES,
    CUSTOMERS_LOCATIONS,
    CUSTOMERS_STATEMENTS,
    CUSTOMERS_ACCOUNT,
    CUSTOMERS_INVOICES,
    CUSTOMERS_DOCUMENTS,
    CUSTOMERS_PAYMENTS,
    CUSTOMERS_JOBS,
    CUSTOMERS_PORTAL
];

export const ACTIVITY_COMPARISON = '/inbox/activity-comparison';
export const SMART_VIEW = '/inbox/smart-view/:id?';
export const INBOX_DONE = '/inbox/done';
export const INBOX_FUTURE = '/inbox/future';
export const INBOX_SENT = '/inbox/sent';
export const INBOX_DRAFT = '/inbox/draft';
export const INBOX_HOME = '/inbox/:sub?';
export const SMART_DIALER = '/inbox/smart-dialer';
export const OPPORTUNITIES_INBOX = '/inbox/opportunity/pipeline';
export const SALES_FUNNEL = '/inbox/opportunity/sales-funnel';
export const STATUS_CHANGES = '/inbox/opportunity/status-change';

// Tracker clock-in clock-out
export const TRACKER = '/tracker';
export const REPORT_TRACKER = REPORT_MAIN_PATH + '/time-tracking';

// Referral
export const REFERRAL = '/referral';
export const ONBOARDING = '/onboarding';

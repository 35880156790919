import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import ButtonSave from 'app/components/button/ButtonSave';
import CustomerPhoneLabel from 'app/components/phoneLabel';
import StatusBar from 'app/components/status/statusbar';
import { ACTION_ASSIGN_LEAD } from 'app/const/api/Voip';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import IconClose from 'assets/icon/IconClose';
import { actionAssignLead } from 'common/redux/actions/assignLead';
import { clientQuery } from 'common/utils/ApiUtils';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';
import ListLead from '../ListLead';

const AssignLead = () => {
    const { t } = useTranslation();
    const refCustomerPhone = useRef(null);
    const refFirstName = useRef(null);
    const refLastName = useRef(null);
    const refLead = useRef(null);
    const refButtonSave = useRef(null);
    const refStatusBar = useRef(null);
    const dispatch = useDispatch();

    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        callLogId: '',
        phone: ''
    });

    const { isVisible: finalIsVisible, callLogId, phone, assignSuccess } = state;

    const _open = ({ id, phone, assignSuccess = () => {} }) => {
        dispatchState({ isVisible: true, callLogId: id, phone, assignSuccess });
    };

    const _close = (e) => {
        e && e.stopPropagation();
        dispatchState({ isVisible: false, callLogId: '', phone: '' });
        dispatch(actionAssignLead(null));
    };

    const _handleAssign = () => {
        const customerPhone = refCustomerPhone.current._getValue()?.[0] || {};
        const firstName = refFirstName.current.value || '';
        const lastName = refLastName.current.value || '';

        clientQuery(
            ACTION_ASSIGN_LEAD,
            {
                method: 'PUT',
                data: {
                    first_name: firstName,
                    last_name: lastName,
                    phone_type_id: customerPhone.type,
                    customer_id: refLead.current.id,
                    phone_number: phone
                }
            },
            (res) =>
                _handleAssignSuccess(res, {
                    ...customerPhone,
                    first_name: firstName,
                    last_name: lastName,
                    isCustomerContact: true
                }),
            _handleAssignFailed
        );
    };

    function _showAlert(message, status) {
        refStatusBar.current.showStatusBar(Date.now(), message || t('common:please_try_again'), status);
        refButtonSave.current.removeLoading();
        return false;
    }

    const _handleAssignSuccess = (response, customerPhone) => {
        assignSuccess(callLogId, { ...response.data, phone: customerPhone });
        _showAlert(response.message || t('common:success'), LIST_STATUS.SUCCESS);
        setTimeout(() => {
            _close();
        }, 500);
    };

    const _handleAssignFailed = (response) => {
        _showAlert(response.message || t('common:success'), LIST_STATUS.ERROR);
    };

    const _onChangeFirstName = (e) => {
        const isCheck = !!e.target.value && !!refLead.current;
        refButtonSave.current.setDisable(!isCheck);
    };

    const _onChangeLasttName = () => {
        const isCheck = !!refFirstName.current.value && !!refLead.current;
        refButtonSave.current.setDisable(!isCheck);
    };

    const _onChangeLead = (value) => {
        const isCheck = !!value && !!refFirstName.current.value;
        refLead.current = value;
        refButtonSave.current.setDisable(!isCheck);
    };

    if (!finalIsVisible) {
        return <ServiceAssignLead onTrigger={_open} />;
    }

    return (
        <ReactModal
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            className={'modal container-modal container-assign-lead open'}
            portalClassName="ReactModalPortal_customer"
            onRequestClose={_close}
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className="modal__container" id={'modal_assign_lead'}>
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('common:assign_lead')}</h3>
                    <div onClick={_close} className="v2-btn-default --icon-lg --transparent js-assign-lead">
                        <IconClose />
                    </div>
                </div>
                <div className="body-modal has-form fs-14">
                    <StatusBar ref={refStatusBar} />
                    <div className="rows">
                        <div className="txt">{t('common:phone')}</div>
                        <CustomerPhoneLabel
                            ref={refCustomerPhone}
                            childrenPhone={
                                <span className="phone-field__number txt-ellipsis black-2 fs-13">
                                    {formatPhoneNumberVoip(phone)}
                                </span>
                            }
                            classWrapper={'phone-field flex-betweenitems'}
                            limit={1}
                            defaultSelected={[
                                {
                                    id: Date.now(),
                                    phone: phone,
                                    type: {
                                        id: '1',
                                        name: 'Mobile',
                                        is_default: 1
                                    }
                                }
                            ]}
                        />
                    </div>
                    <div className="rows row-haft mt-3">
                        <div className="col">
                            <div className="txt d-flex gap-4">
                                {t('common:first_name')}
                                <span className="red-default">*</span>
                            </div>
                            <input
                                onChange={_onChangeFirstName}
                                ref={refFirstName}
                                type="text"
                                className="field-input"
                                autoFocus
                            />
                        </div>
                        <div className="col">
                            <div className="txt">{t('common:last_name')}</div>
                            <input
                                onChange={_onChangeLasttName}
                                ref={refLastName}
                                type="text"
                                className="field-input"
                            />
                        </div>
                    </div>
                    <div className="is-divider --horizontal my-4" />
                    <div className="rows">
                        <div className="txt d-flex gap-4">
                            {t('common:lead_title')}
                            <span className="red-default">*</span>
                        </div>
                        <ListLead onChangeLead={_onChangeLead} />
                    </div>
                </div>
                <div className="footer-modal">
                    <div className="v2-btn-default --transparent js-assign-lead" onClick={_close}>
                        {t('common:cancel')}
                    </div>
                    <ButtonSave
                        ref={refButtonSave}
                        className="v2-btn-main"
                        title={t('common:assign')}
                        onSave={_handleAssign}
                    />
                </div>
            </div>
        </ReactModal>
    );
};

const ServiceAssignLead = ({ onTrigger }) => {
    const assignLeadData = useSelector(({ assignLeadReducer }) => assignLeadReducer.assignLeadData);

    useEffect(() => {
        !!assignLeadData && onTrigger(assignLeadData);
    }, [assignLeadData]);
    return null;
};

export default AssignLead;

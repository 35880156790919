export default function IconCopy() {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.5 16.5V17.5C7.5 18.6046 8.39543 19.5 9.5 19.5H16.5C17.6046 19.5 18.5 18.6046 18.5 17.5V8.5C18.5 7.39543 17.6046 6.5 16.5 6.5H15.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.5 3.5H13.5C14.6046 3.5 15.5 4.39543 15.5 5.5V14.5C15.5 15.6046 14.6046 16.5 13.5 16.5H7.5C6.39543 16.5 5.5 15.6046 5.5 14.5V5.5C5.5 4.39543 6.39543 3.5 7.5 3.5Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

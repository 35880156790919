import IconFilter from 'assets/icon/IconFilter';
import React, { useEffect, useId, useReducer, useRef } from 'react';
import { reducer } from 'app/const/Reducer';
import { NOTES_TEMPLATE_OPTIONS } from 'app/const/Notes';
import Checkbox from 'app/components/checkbox';
import { useTranslation } from 'react-i18next';
import { KEY_CODE_ESCAPE } from 'app/const/App';

export default function Filter({ filterParent = -1, onApplyFilter, listDataFilter = NOTES_TEMPLATE_OPTIONS }) {
    const { t } = useTranslation(['setting']);

    const [state, dispatchState] = useReducer(reducer, {
        filter: filterParent,
        isVisible: false
    });

    useEffect(() => {
        dispatchState({
            filter: typeof filterParent === 'string' && filterParent !== -1 ? filterParent.split(',') : filterParent
        });
    }, [filterParent]);

    const { filter: finalDataFilter, isVisible: finalIsVisible } = state;
    const isCheckAll = finalDataFilter === -1;
    const idTemplateForm = useId();
    const refOptions = useRef(null);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById(idTemplateForm);
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById(idTemplateForm);
        if (
            refOptions.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refOptions.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && dispatchState({ isVisible: false });
    }

    function _handleOpen() {
        dispatchState({
            isVisible: !finalIsVisible,
            filter: filterParent
        });
    }

    function _handleSelectOption(e, selected) {
        let newSelected = finalDataFilter === -1 ? listDataFilter.map((i) => i.id) : [...finalDataFilter];

        if (newSelected.includes(selected)) {
            newSelected = newSelected.filter((item) => item !== selected);
        } else {
            newSelected.push(selected);
        }

        if (newSelected.length === listDataFilter.length) {
            newSelected = -1;
        }

        dispatchState((prev) => {
            return {
                ...prev,
                filter: newSelected
            };
        });
    }

    function _handleSelectAll() {
        dispatchState((prev) => {
            return {
                ...prev,
                filter: isCheckAll ? [] : -1
            };
        });
    }

    function _handleApply() {
        onApplyFilter(finalDataFilter);
        _closeSearchResult();
    }

    function _renderType() {
        return listDataFilter.map((item) => {
            const { id: optionId, name: optionName } = item;
            const isCheck = finalDataFilter === -1 || finalDataFilter.includes(optionId);
            return (
                <li key={optionId} className="items">
                    <Checkbox
                        label={t(`${optionName}`)}
                        checked={isCheck}
                        id={`check-box-${optionName}-all`}
                        onChangeValue={_handleSelectOption}
                        value={optionId}
                        typeIcon={optionName}
                    />
                </li>
            );
        });
    }

    return (
        <div
            ref={refOptions}
            id={idTemplateForm}
            className={`v2-dropdown list-filter ${finalIsVisible ? 'active' : ''}`}
        >
            <div onClick={_handleOpen} className="dropbtn v2-btn-default --icon-lg">
                <IconFilter />
            </div>
            {finalIsVisible && (
                <div className="v2-dropdown__menu content-checked">
                    <div className="content-checked__all">
                        <div className="items">
                            <Checkbox
                                label={t('setting:all_templates')}
                                checked={isCheckAll}
                                id={`check-box-${t('setting:all_templates')}-all`}
                                onChangeValue={_handleSelectAll}
                                value={finalDataFilter}
                                typeIcon={'All Templates'}
                            />
                        </div>
                    </div>
                    <ul className="scrolls">{_renderType()}</ul>
                    <div onClick={_handleApply} className="content-checked__btn">
                        <div className="v2-btn-main">{t('common:apply')}</div>
                    </div>
                </div>
            )}
        </div>
    );
}

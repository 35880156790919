import { COMMON } from 'app/const/App';

export const getOrderSentriconReport = (order = '') => {
    return order.replace(COMMON.INSTALL_DATE, COMMON.CHECK_IN);
};

export const convertDateSentricons = (data = []) => {
    return data.map((item) => {
        const { status, status_date } = item;
        const isActiveStatus = status === COMMON.ACTIVE;
        return {
            ...item,
            reactive_date: isActiveStatus ? status_date : '',
            cancellation_date: isActiveStatus ? '' : status_date
        };
    });
};

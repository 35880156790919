import React from 'react';
import { useTranslation } from 'react-i18next';

import IconCalendar from 'assets/icon/IconCalendar';
import Options from './Options';

export default function ReSchedule({ logDetail, customerId, jobId, messaging, contacts, customerPhones = [] }) {
    const { t } = useTranslation();
    return (
        <div className="boxs is-note --reschedule">
            <div className="left-icons">
                <IconCalendar isReSchedule />
            </div>
            <div className="content elm-parent">
                <div className="header-box">
                    <div className="name">
                        <h5 className="name__label">{t('common:reschedule_request')}</h5>
                        <p className="name__des">
                            <span className="info">{logDetail.date}</span>
                        </p>
                        <p className="name__des">
                            <span className="info">{logDetail.location_name}</span>
                        </p>
                    </div>
                    <div className="header-box__action">
                        <Options
                            customerId={customerId}
                            jobId={jobId}
                            messaging={messaging}
                            contacts={contacts}
                            customerPhones={customerPhones}
                        />
                    </div>
                </div>
                {!!logDetail.content && (
                    <div className="wrap-content is-complete">
                        <div className="wrap-content-box">
                            <div className="description">{logDetail.content}</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

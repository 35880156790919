export default function IconOrderList() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.61769 18.5H4.00037C4.6319 17.1667 5.13176 16.5 5.49995 16.5C6.05223 16.5 6.49995 16.0523 6.49995 15.5C6.49995 14.9477 6.05223 14.5 5.49995 14.5C4.94766 14.5 4.49995 14.9477 4.49995 15.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M9.5 8.5H19.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M5.49995 10.5V6.5L4.00037 7.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M9.5 16.5H19.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
}

import React, { forwardRef, useContext, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import { reducer } from 'app/const/Reducer';
import DataRepeatContextProvider, { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';
import Ends from 'app/modules/jobdetail/tabs/settingschedule/ends';
import Repeat from 'app/modules/jobdetail/tabs/settingschedule/repeat';
import IconArrowDown from 'assets/icon/IconArrowDown';
import FooterModal from './FooterModal';
import HeaderModal from './HeaderModal';

const SelectRepeat = ({ isRepeat = false, start = null, defaultValue = null }, ref) => {
    const { t } = useTranslation();
    const refContent = useRef(null);
    const refButton = useRef(null);
    const defaultData =
        defaultValue && isRepeat
            ? { repeat: 1, recurrence: defaultValue }
            : { repeat: 0, recurrence: { offset: {}, summary: t('calendar:once') } };

    useImperativeHandle(ref, () => ({
        getValue: () => refButton.current.getValue(),
        changeStart: (newStart) => {
            refContent.current.changeStart(newStart);
        }
    }));

    const _handleClick = () => {
        refContent.current._open();
    };

    const _handleSave = (summary) => {
        refButton.current.setValue(summary);
    };

    return (
        <div className="wrap-rows flextop border-bottom-line pt-0">
            <DataRepeatContextProvider defaultData={{ ...defaultData, start }}>
                <div className="wrap-rows__icons flex-auto"></div>
                <Button ref={refButton} onClick={_handleClick} />
                <ContentRepeat ref={refContent} start={start} onSave={_handleSave} />
            </DataRepeatContextProvider>
        </div>
    );
};

const Button = forwardRef(({ onClick = () => {} }, ref) => {
    const { repeatSettings } = useContext(DataRepeatContext);
    const [state, dispatchState] = useReducer(reducer, { summary: repeatSettings.recurrence.summary });

    useImperativeHandle(ref, () => ({
        setValue: (summary) => {
            dispatchState({ summary });
        },
        getValue: () => ({ offset: repeatSettings.recurrence })
    }));

    return (
        <div className="summary v2-btn-default btn-bg-grey --icon-r" onClick={onClick}>
            <span className="flex-1">{state.summary}</span>
            <IconArrowDown />
        </div>
    );
});

const ContentRepeat = forwardRef(({ onSave = () => {} }, ref) => {
    const { t } = useTranslation();
    const { repeatSettings, onChangeDataContext } = useContext(DataRepeatContext);
    const [state, dispatchState] = useReducer(reducer, { isOpen: false });
    const refData = useRef(repeatSettings);

    useImperativeHandle(ref, () => ({
        _open,
        _close,
        changeStart: (newStart) => {
            onChangeDataContext({ ...repeatSettings, start: newStart });
        }
    }));

    const _open = () => {
        dispatchState({ isOpen: true });
    };

    const _close = () => {
        dispatchState({ isOpen: false });
        onChangeDataContext(refData.current);
    };

    const _handleSave = () => {
        onSave(repeatSettings.recurrence.summary);
        refData.current = repeatSettings;
        _close();
    };

    if (!state.isOpen) return null;
    return (
        <ReactModal
            isOpen
            className="modal container-modal wrapper-repeat-timeoff open"
            style={{ overlay: { zIndex: 999, backgroundColor: 'transparent' } }}
            onRequestClose={_close}
        >
            <div className="modal__overlay bg-transparent" onClick={_close} />
            <div className="modal__container">
                <HeaderModal
                    title={t('setting:repeat')}
                    className="header-modal"
                    titleClass="header-modal__label"
                    onClose={_close}
                />
                <div className="body-modal fs-14">
                    <Repeat isEvent />
                    <Ends isEvent typeEnd={t('calendar:events')} />
                </div>
                <FooterModal className="footer-modal btn-close" onSave={_handleSave} isNotLoading onCancel={_close} />
            </div>
        </ReactModal>
    );
});

export default forwardRef(SelectRepeat);

import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CLASS_NAME_OPEN_LOCATION, LINK_OPEN_GOOGLE_MAP } from 'app/components/dropdown/constant';
import { DATE_FORMAT_ENGLISH, DATE_FORMAT_SPANISH } from 'app/const/App';
import { EDIT_OR_DELETE_EVENT, EDIT_OR_DELETE_TIMEOFF, PERMISSIONS } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { EN } from 'app/const/Settings';
import { getEventDetail, getTimeoffDetail } from 'app/const/api/V2';
import { CALENDAR_CUSTOM_EVENTS, EVENT_TYPES } from 'app/modules/calendar/const';
import { eventLocationDropdown } from 'app/modules/customer/detail/locations/utils';
import IconCalendar from 'assets/icon/IconCalendar';
import IconClose from 'assets/icon/IconClose';
import IconEdit from 'assets/icon/IconEdit';
import IconLocation from 'assets/icon/IconLocation';
import IconNote from 'assets/icon/IconNote';
import IconTrash from 'assets/icon/IconTrash';
import IconUser from 'assets/icon/IconUser';
import { editEvent } from 'common/redux/actions/event';
import { actionDeleteJob, actionOpenJobPreview } from 'common/redux/actions/job';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkPermission } from 'common/utils/PermissionUtils';
import { combineString } from 'common/utils/ReportUtils';
import { subscribeEvent, unsubscribeEvent } from 'common/utils/eventUtils';
import ScheduleList from '../../../ScheduleList';
import TimeOffConfirm from './TimeOffConfirm';

function PreviewTimeoff() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [state, dispatchState] = useReducer(reducer, { isVisible: false, data: {} });
    const { isVisible: currentVisible, data } = state;
    const refConfirm = useRef(null);
    const refActions = useRef({});

    const userInfo = useSelector((state) => state.auth?.user);
    const isTimeoff = data.type === 'timeoff';
    const permissionList = userInfo.permissions.enabled || [];
    const hasPermission = checkPermission(
        permissionList,
        PERMISSIONS[isTimeoff ? EDIT_OR_DELETE_TIMEOFF : EDIT_OR_DELETE_EVENT]
    );
    const i18nLanguageCode = i18n.language;

    const handlePreview = ({ detail }) => {
        if (!detail) return _closeTimeOff();
        _openTimeOff(detail);
        if (detail.onEdit) refActions.current['edit'] = detail.onEdit;
        // TODO: Remove this line
        dispatch(actionOpenJobPreview(null));
    };

    useEffect(() => {
        subscribeEvent(CALENDAR_CUSTOM_EVENTS.OPEN_PREVIEW, handlePreview);
        return () => unsubscribeEvent(CALENDAR_CUSTOM_EVENTS.OPEN_PREVIEW, handlePreview);
    }, []);

    const _handleButton = (type) => {
        switch (type) {
            case 'EDIT':
                dispatch(editEvent({ ...data, onUpdate: _updateSuccess }));
                break;
            case 'DELETE':
                _deleteTimeOff();
                break;
            default:
                break;
        }
    };

    const _openTimeOff = (eventData) => {
        if (!eventData) return;
        const isTimeoff = eventData.type === EVENT_TYPES.TIMEOFF;
        const isNewEvent = data.type !== eventData.type || data.id !== eventData.id;

        if (isNewEvent) {
            dispatchState((prevState) => ({ isVisible: true, data: { ...(prevState.data || {}), ...eventData } }));
            clientQuery(
                isTimeoff ? getTimeoffDetail(eventData.id) : getEventDetail(eventData.id),
                {},
                _getDetailSuccess,
                _getDetailFailure
            );
        } else {
            dispatchState((prevState) => {
                return {
                    isVisible: true,
                    data: { ...(prevState.data || {}), start: eventData.start, end: eventData.end }
                };
            });
        }
    };

    const _getDetailSuccess = ({ data }) => {
        dispatchState((prev) => ({ ...prev, data: { ...prev.data, ...data }, isLoading: false }));
    };

    const _getDetailFailure = () => {};
    const _closeTimeOff = () => dispatchState({ isVisible: false, data: {} });
    const _deleteTimeOff = () => {
        refConfirm.current._toggleOpen(data.repeat);
    };

    /**
     * Call api to delete time-off.
     * @param {integer} deleteRecurring - Delete all recurring or not with value 1||0
     * @return {void}
     */
    const _submitDelete = (deleteRecurring = 0) => {
        const optionsQuery = { data: { all: deleteRecurring }, method: 'DELETE', toFormData: false };
        clientQuery(
            isTimeoff ? getTimeoffDetail(state.data.id) : getEventDetail(state.data.id),
            optionsQuery,
            () => {
                _deleteSuccess(!deleteRecurring ? 1 : 0);
            },
            _deleteFailure
        );
    };

    const _deleteSuccess = (previously_completed = 0) => {
        dispatch(
            actionDeleteJob({
                ...data,
                parent_job_id: data.parent_job_id.length === 0 ? data.id : data.parent_job_id,
                previously_completed
            })
        );
        refConfirm.current._close();
        _closeTimeOff();
    };
    const _deleteFailure = () => {
        refConfirm.current._close();
    };

    const _updateSuccess = (data) => {
        dispatchState((prev) => ({
            ...prev,
            data: { ...prev.data, ...data, type: isTimeoff ? EVENT_TYPES.TIMEOFF : EVENT_TYPES.EVENT }
        }));
        refActions.current['edit'] && refActions.current.edit();
    };

    const _handleOpenLocationTimeOff = (target) => {
        const { address = '', lat = '', lng = '' } = data?.location || {};
        eventLocationDropdown({
            target,
            address: `${LINK_OPEN_GOOGLE_MAP}${address}`,
            dataLatLng: { lat, lng }
        });
    };

    if (!currentVisible || !data || !Object.keys(data).length) return null;
    return (
        <>
            <div className="sidebar-left-content">
                <div className="wrapper-events flex-column sidebar-preview">
                    {/* Header */}
                    <div className="wrapper-events__header flexcenter border-bottom-line">
                        <div className="flex-1 fw-600 fs-16">
                            {t(`calendar:${isTimeoff ? 'time_off' : 'custom_event'}`)}
                        </div>
                        {hasPermission ? (
                            <>
                                <div
                                    className="v2-btn-default has-bg-grey --icon-lg"
                                    onClick={() => _handleButton('EDIT')}
                                >
                                    <IconEdit />
                                </div>
                                <div
                                    className="v2-btn-default has-bg-grey --icon-lg svg-delete-grey"
                                    onClick={() => _handleButton('DELETE')}
                                >
                                    <IconTrash />
                                </div>
                            </>
                        ) : null}
                        <div className="v2-btn-default has-bg-grey --icon-lg" onClick={_closeTimeOff}>
                            <IconClose />
                        </div>
                    </div>

                    <div className="sidebar-content flex-1">
                        {/* Colors */}
                        {!isTimeoff ? (
                            <div className="wrap-rows flextop">
                                <div className="wrap-rows__icons flex-auto mt-0">
                                    <div className="is-dots" style={{ backgroundColor: data.color }} />
                                </div>
                                <div className="wrap-rows__details flex-1">
                                    <div className="preview-label fw-600 fs-13">{data.name}</div>
                                </div>
                            </div>
                        ) : null}

                        {/* Time-length */}
                        <div className="wrap-rows flextop">
                            <div className="wrap-rows__icons flex-auto mt-0">
                                <IconCalendar />
                            </div>
                            <div className="wrap-rows__details flex-1">
                                <div className="preview-label">
                                    {moment(data.start)
                                        .utc()
                                        .locale(i18nLanguageCode)
                                        .format(i18nLanguageCode === EN ? DATE_FORMAT_ENGLISH : DATE_FORMAT_SPANISH)}
                                    , {moment(data.start).utc().format('h:mma')} -{' '}
                                    {moment(data.end).utc().format('h:mma')}
                                </div>
                                <div className="preview-label">{data.recurrence?.summary}</div>
                            </div>
                        </div>

                        {/* Schedule */}
                        {data.schedules.length ? (
                            <div className="wrap-rows flextop pt-1 pb-4 pr-1">
                                <div className="wrap-rows__icons flex-auto">
                                    <IconUser />
                                </div>
                                <div className="wrap-rows__details flex-1">
                                    <div className="schedule-user w-100">
                                        <ScheduleList selected={data.schedules} />
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {/* Location */}
                        {data?.location?.street1 || data?.location?.address ? (
                            <div className="wrap-rows flextop pt-0 pb-3">
                                <div className="wrap-rows__icons flex-auto mt-0">
                                    <IconLocation isTimeoff />
                                </div>
                                <div className="wrap-rows__details flex-1">
                                    <div
                                        className={classNames(
                                            'preview-label purple-default cursor-pointer',
                                            CLASS_NAME_OPEN_LOCATION
                                        )}
                                        onClick={_handleOpenLocationTimeOff}
                                    >
                                        <p>{data.location.street1 || data?.location?.address || ''}</p>
                                        <p>
                                            {combineString([
                                                data.location?.city || '',
                                                data.location?.state || '',
                                                data.location?.zip || ''
                                            ])}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {data.description ? (
                            <div className="wrap-rows flextop pt-0">
                                <div className="wrap-rows__icons flex-auto mt-0">
                                    <IconNote />
                                </div>
                                <div className="wrap-rows__details flex-1">
                                    <div className="preview-label" style={{ whiteSpace: 'pre-wrap' }}>
                                        {data.description}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <TimeOffConfirm
                ref={refConfirm}
                title={t(`calendar:${isTimeoff ? 'remove_time_off_event' : 'delete_custom_event'}`)}
                description={t(
                    `calendar:${isTimeoff ? 'remove_time_off_event_message' : 'delete_custom_event_message'}`
                )}
                onSubmit={_submitDelete}
            />
        </>
    );
}
export default PreviewTimeoff;

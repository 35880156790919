import { responseToDataTableSticky } from 'app/components/table/utils';
import { DEFAULT_ALL } from 'app/const/App';
import { getGidColumnsTableCompletedJob, getGidColumnsTableMaterial } from 'app/const/report/MaterialUse';
import i18n from 'assets/i18n';

export const getGroupsDefault = (dataGetDefault) => {
    return {
        ...responseToDataTableSticky({
            key: 'material',
            data: dataGetDefault.material.data || [],
            isLoading: dataGetDefault.material.isLoading,
            config: {
                ...getGidColumnsTableMaterial(...dataGetDefault.material.paramsGetGrid),
                fileTranslation: 'report'
            },
            actionsHeader: { handleClickHeader: dataGetDefault.material.handleActionHeader }
        }),
        ...responseToDataTableSticky({
            key: 'completed_job',
            title: { name: i18n.t('report:completed_job_w_o_a_county_match'), className: 'title-material' },
            data: dataGetDefault.completed_job.data || [],
            isLoading: dataGetDefault.completed_job.isLoading,
            handleClick: dataGetDefault.completed_job.handleClick,
            config: { ...getGidColumnsTableCompletedJob(), fileTranslation: 'report' }
        })
    };
};

export const getDefaultDataMaterial = ({
    data = [],
    country,
    orderByParams,
    countyParams,
    isLoading = true,
    handleActionHeader = () => {}
}) => {
    return responseToDataTableSticky({
        data,
        isLoading,
        key: 'material',
        config: {
            ...getGidColumnsTableMaterial(orderByParams, countyParams !== DEFAULT_ALL, country),
            fileTranslation: 'report'
        },
        actionsHeader: { handleClickHeader: handleActionHeader }
    });
};

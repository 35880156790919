import { forwardRef, useImperativeHandle, useReducer, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { clientQuery } from 'common/utils/ApiUtils';
import { getListDocumentType } from 'app/const/Api';
import GdSearch from 'app/components/search/GdSearch';
import { LoadingDocumentType } from 'app/modules/jobdetail/tabs/documents/components/Loading';
import IconPlus from 'assets/icon/IconPlus';
import IconClose from 'assets/icon/IconClose';
import LoadMore from './LoadMore';
import ButtonSave from 'app/components/button/ButtonSave';
import { reducer } from 'app/const/Reducer';
import { isScrollToEndBottom } from 'common/utils/FunctionUtils';
import TabSwitcher from 'app/components/tabs/TabSwitcher';
import { DOCUMENT_LIBRARY, TABS_DOCUMENT_FILTER } from 'app/modules/addons/documents/constants';
import GridEmpty from 'app/components/grid/components/GridEmpty';
import { handleAbortController } from 'app/modules/customer/utils';
import { ADDONS_MANAGE_DOCUMENTS_LIBRARY } from 'app/const/addons';
import NameTagPdf from 'app/modules/addons/documents/components/manage/NameTagPdf';

const AddForm = forwardRef(({ handleSubmit }, ref) => {
    const { t } = useTranslation(['jobDetail', 'header']);
    const refSelected = useRef({});

    const documentsAddon = useSelector(({ auth }) => auth.user.settings.addons.documents);
    const isHaveAccessPdf = !!documentsAddon?.[ADDONS_MANAGE_DOCUMENTS_LIBRARY];

    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        isLoading: true,
        selected: [],
        activeTab: DOCUMENT_LIBRARY.DOC,
        data: [],
        showMore: false,
        keyword: ''
    });

    const { isVisible, isLoading, selected = [], activeTab, showMore, data: documents, keyword } = state;

    const listRef = useRef(null);
    const refButtonSave = useRef(null);
    const refLoadMore = useRef(null);
    const abortController = useRef(null);

    useEffect(() => {
        if (!isVisible) return;
        _getListDocumentType({ keyword: '', offset: 0, limit: 20, reset: true, type: activeTab });
    }, [isVisible]);

    useImperativeHandle(ref, () => ({
        _handleOpenForm,
        _handleCloseForm,
        removeLoading: _removeLoading,
        _setDefault: _handleSetDefault
    }));

    const _preventDefault = (e) => {
        e && e.preventDefault();
    };

    const _stopPropagation = (e) => {
        e && e.stopPropagation();
    };

    function _removeLoading() {
        refButtonSave.current.removeLoading();
    }

    function _removeLoadingPage(value = false) {
        (isLoading || value) &&
            dispatchState({
                isLoading: false
            });
    }

    const _handleOpenForm = (data) => {
        const { selected = [] } = data || {};
        dispatchState((prev) => ({
            ...prev,
            isVisible: true,
            selected,
            isLoading: true
        }));
    };

    const _handleCloseForm = () => {
        refSelected.current = {};
        dispatchState((prev) => ({
            ...prev,
            isVisible: false,
            selected: [],
            keyword: '',
            activeTab: DOCUMENT_LIBRARY.DOC
        }));
    };

    const _handleOnScrollContent = (e) => {
        const finalElmLoading = refLoadMore.current;

        if (!finalElmLoading?.getStatus() && !isLoading && showMore && isScrollToEndBottom(e.target)) {
            finalElmLoading.showLoading();
            _getListDocumentType({
                keyword: keyword,
                limit: 20,
                reset: false,
                offset: documents.length,
                type: activeTab
            });
        }
    };

    const _getListDocumentType = (params) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();

        const _getListDocumentTypeSuccess = (response = {}, params) => {
            const { data = [], show_more: showMore } = response;

            dispatchState((prev) => {
                const finalData = data.map((item) => ({ ...item, id: item.parent_id }));

                return {
                    ...prev,
                    isLoading: false,
                    data: params?.reset ? finalData : [...prev.data, ...finalData],
                    showMore
                };
            });

            _removeLoadingPage(params.reset);
            refLoadMore.current?.removeLoading();
        };

        const _getListDocumentTypeFailed = ({ isAborted = false }) => {
            if (isAborted) return;
            _removeLoadingPage();
            refLoadMore.current?.removeLoading();
        };

        const newParams = { ...params };
        delete newParams.reset;

        clientQuery(
            getListDocumentType,
            { data: newParams, method: 'GET', abortController: abortController.current },
            (response) => _getListDocumentTypeSuccess(response, params),
            _getListDocumentTypeFailed
        );
    };

    const _handleSubmit = (e) => {
        _preventDefault(e);
        _stopPropagation(e);

        handleSubmit(selected, Object.values(refSelected.current));
    };

    const _handleAdd = (e, item) => {
        e && e.stopPropagation();
        dispatchState((prev) => ({
            ...prev,
            selected: prev.selected.concat(item.id)
        }));
        refSelected.current[item.id] = item;
    };

    const _handleRemove = (e, value) => {
        e && e.stopPropagation();
        dispatchState((prev) => ({
            ...prev,
            selected: prev.selected.filter((item) => item !== value)
        }));
        delete refSelected.current[value];
    };

    const _handleSearch = (value) => {
        listRef.current && (listRef.current.scrollTop = 0);
        dispatchState((prev) => ({ ...prev, keyword: value, isLoading: true }));
        _getListDocumentType({ keyword: value, offset: 0, limit: 20, reset: true, type: activeTab });
    };

    const _renderList = (list) => {
        if (!Array.isArray(list) || list.length === 0)
            return (
                <GridEmpty
                    msgEmpty={
                        keyword
                            ? t('header:search_not_match')
                            : t('common:no_data_to_display', { title: t('common:documents') })
                    }
                    isFlat
                />
            );

        return list.map((item) => {
            const documentId = item.id;
            const isSelected = selected.includes(documentId) || false;
            return (
                <div key={documentId} className={`document-rows ${isSelected && 'active'}`}>
                    <div className="action-document">
                        <div
                            onClick={(e) => {
                                _handleAdd(e, item);
                            }}
                            className="add-docs"
                        >
                            <div className="v2-btn-default has-icon" tabIndex="0">
                                <IconPlus />
                                {t('jobDetail:add')}
                            </div>
                        </div>
                        <div
                            onClick={(e) => {
                                _handleRemove(e, documentId);
                            }}
                            className="remove-docs"
                        >
                            <div className="v2-btn-default has-icon" tabIndex="0">
                                <IconClose />
                                {t('common:remove')}
                            </div>
                        </div>
                    </div>
                    <NameTagPdf wrapClassName="docs-name" {...item} />
                </div>
            );
        });
    };

    const _handleChangeTab = (id) => {
        dispatchState((prev) => ({ ...prev, activeTab: id, isLoading: true }));
        _getListDocumentType({ keyword: keyword, offset: 0, limit: 20, reset: true, type: id });
    };

    const _handleSetDefault = ({ key, value }) => {
        refSelected.current[key] = value;
    };

    if (!isVisible) return null;

    return (
        <div className="modal container-modal --contains open">
            <div className="modal__overlay bg-fixed" />
            <div className="modal__container modal-document modal-document--jobs">
                <div className="body-modal flex-column">
                    <div className="flex-column__head body-modal__head">
                        {isHaveAccessPdf ? (
                            <TabSwitcher
                                activeTab={activeTab}
                                listTabs={TABS_DOCUMENT_FILTER}
                                wrapperClassName="tabs mr-2"
                                fileTranslation="addons"
                                onChange={_handleChangeTab}
                            />
                        ) : null}
                        <div className="search-form">
                            <GdSearch
                                placeholder={`${t('jobDetail:search')}`}
                                onSearch={_handleSearch}
                                style="w-100"
                                defaultValue={keyword}
                                isSearchOnClear
                            />
                        </div>
                        <div onClick={_handleCloseForm} className="v2-btn-default --noborder --label ml-2" tabIndex="0">
                            {t('jobDetail:cancel')}
                        </div>
                        <ButtonSave
                            disabled={selected.length === 0}
                            ref={refButtonSave}
                            onSave={_handleSubmit}
                            wrapClass="v2-btn-main ml-2"
                        />
                    </div>
                    {isLoading ? (
                        <LoadingDocumentType />
                    ) : (
                        <div
                            ref={listRef}
                            onScroll={_handleOnScrollContent}
                            className={`flex-column__content document-list scrolls ${isLoading && 'wrap-loading'}`}
                        >
                            {_renderList(documents)}
                            <LoadMore ref={refLoadMore} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});

export default AddForm;

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ButtonSave from 'app/components/button/ButtonSave';
import MessageTabs from 'app/modules/calendar/job/components/messaging/MessageTabs';
import Info from 'app/modules/jobdetail/tabs/settingmessaging/Info';

const MessagePreferences = ({ onClose, settings, onSave, customerId }) => {
    const { t } = useTranslation();
    const activateSms = useSelector((state) => state.auth.user?.settings?.addons?.activate_sms || false);
    const refInfo = useRef(null);
    const refButtonSave = useRef(null);

    function _handleMove() {
        const confirmations = document.getElementById('appointment_confirmations').getAttribute('data-value-selected');
        const reminders = document.getElementById('appointment_reminders').getAttribute('data-value-selected');
        const followups = document.getElementById('appointment_followups').getAttribute('data-value-selected');
        const broadcasts = document.getElementById('broadcasts').getAttribute('data-value-selected');
        const payment = document.getElementById('late_payment_reminders').getAttribute('data-value-selected');

        const finalInfo = refInfo.current.getValue();

        onSave(
            parseInt(confirmations),
            parseInt(reminders),
            parseInt(followups),
            parseInt(broadcasts),
            parseInt(payment),
            finalInfo.sms,
            finalInfo.email,
            () => {
                refButtonSave.current && refButtonSave.current.removeLoading();
            }
        );
    }

    return (
        <div className="container-column messaging-preferences --wrap scrolls column-preferences">
            <div className="header-modal">
                <span>{t('jobDetail:messaging_preferences')}</span>
                <div className="d-inlineflex btn-close-mss">
                    <span className="v2-btn-default --noborder --label btn-cancel" onClick={onClose}>
                        {t('common:cancel')}
                    </span>
                    <ButtonSave ref={refButtonSave} onSave={_handleMove} />
                </div>
            </div>
            <Info ref={refInfo} settings={settings} customerId={customerId} />
            <div className="border-top">
                <div className="box-appointment box-preferences tabs">
                    <span className="txt-appointment">{t('jobDetail:appointment')}</span>
                    <div className="lines">
                        <span className="txt">{t('jobDetail:confirmations')}</span>
                        <MessageTabs
                            value={settings.appointment_confirmations}
                            type={'appointment_confirmations'}
                            activateSms={activateSms}
                        />
                    </div>
                    <div className="lines">
                        <span className="txt">{t('jobDetail:reminders')}</span>
                        <MessageTabs
                            value={settings.appointment_reminders}
                            type={'appointment_reminders'}
                            activateSms={activateSms}
                        />
                    </div>
                    <div className="lines">
                        <span className="txt">{t('jobDetail:followups')}</span>
                        <MessageTabs
                            value={settings.appointment_followups}
                            type={'appointment_followups'}
                            activateSms={activateSms}
                        />
                    </div>
                    <div className="lines line-border" />
                    <div className="lines">
                        <span className="txt">{t('jobDetail:broadcasts')}</span>
                        <MessageTabs value={settings.broadcasts} type={'broadcasts'} activateSms={activateSms} />
                    </div>
                    <div className="lines">
                        <span className="txt text-left" title={t('jobDetail:late_payment_reminders')}>{t('jobDetail:late_payment_reminders')}</span>
                        <MessageTabs
                            value={settings.late_payment_reminders}
                            type={'late_payment_reminders'}
                            activateSms={activateSms}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MessagePreferences;

import React, { useEffect, useReducer, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ADDONS_DOCUMENTS, ADDONS_DOCUMENTS_AVAILABLE } from 'app/config/routes';
import { REPORT_DOCUMENT_GET_LIST_TYPE } from 'app/const/Api';
import Alert from 'app/modules/customer/components/AlertCustomer';
import IconArrow from 'assets/icon/IconArrow';
import IconPlus from 'assets/icon/IconPlus';
import { clientQuery } from 'common/utils/ApiUtils';
import ListDocument from './components/ListDocument';
import ListDocumentLoading from './components/ListDocumentLoading';
import { reducer } from 'app/const/Reducer';
import { addBranchPath } from 'app/const/Branch';
import TabSwitcher from 'app/components/tabs/TabSwitcher';
import { DOCUMENT_LIBRARY, TABS_DOCUMENT_LIBRARY } from './constants';
import { handleAbortController } from 'app/modules/customer/utils';
import { LIST_STATUS } from 'app/const/App';
import { ADDONS_MANAGE_DOCUMENTS_LIBRARY } from 'app/const/addons';

const LibraryDocuments = () => {
    const { t } = useTranslation('addons');
    const refAlert = useRef(null);
    const abortController = useRef(null);

    const documents = useSelector(({ auth }) => auth.user.settings.addons.documents);
    const isHaveAccessPdf = !!documents?.[ADDONS_MANAGE_DOCUMENTS_LIBRARY];

    const [state, dispatchState] = useReducer(reducer, {
        document: {},
        isLoading: true,
        tabActive: isHaveAccessPdf ? DOCUMENT_LIBRARY.ALL : DOCUMENT_LIBRARY.DOC
    });
    const { data, isLoading, tabActive } = state;

    useEffect(() => {
        _getDocumentAddons({ id: tabActive });

        return () => {
            handleAbortController(abortController);
        };
    }, []);

    const _getDocumentAddons = ({ id }) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();

        const _getSuccess = ({ data }) => dispatchState((prev) => ({ ...prev, data, isLoading: false }));

        const _getFail = ({ isAborted = false, message }) => {
            if (isAborted) return;
            _handleShowAlert({ id: 'list_fail', message, type: LIST_STATUS.ERROR });
            dispatchState((prev) => ({ ...prev, data: [], isLoading: false }));
        };

        clientQuery(
            REPORT_DOCUMENT_GET_LIST_TYPE,
            { method: 'GET', data: { type: id }, abortController: abortController.current },
            _getSuccess,
            _getFail
        );
    };

    const _handleShowAlert = (dataAlert) => {
        refAlert.current.showStatusBar(dataAlert);
    };

    const _handleChangeTab = (id) => {
        dispatchState((prev) => ({ ...prev, tabActive: id, isLoading: true }));
        _getDocumentAddons({ id });
    };

    return (
        <div className="addons-wrap__container addons-wrap__documents">
            <div className="header">
                <Link to={addBranchPath(ADDONS_DOCUMENTS)} className="v2-btn-default has-icon">
                    <IconArrow isPrev />
                    {t('documents')}
                </Link>
                {isHaveAccessPdf ? (
                    <TabSwitcher
                        wrapperClassName="tabs flex-1 ml-1"
                        activeTab={tabActive}
                        listTabs={TABS_DOCUMENT_LIBRARY}
                        fileTranslation="addons"
                        onChange={_handleChangeTab}
                    />
                ) : null}
                <Link to={addBranchPath(ADDONS_DOCUMENTS_AVAILABLE)} className="v2-btn-main has-icon svg-white">
                    <IconPlus />
                    {t('all_document')}
                </Link>
            </div>
            <div className="list scrolls">
                <Alert ref={refAlert} />
                {isLoading ? <ListDocumentLoading /> : <ListDocument data={data} onShowAlert={_handleShowAlert} />}
            </div>
        </div>
    );
};

export default LibraryDocuments;

export default function IconEmail({ isEmailType = false, isEmailAddress = false, isHasDot = false, isBlack = false }) {
    if (isEmailAddress) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.5 6H17.5C18.3284 6 19 6.67157 19 7.5V8.3406C19 8.74063 18.7616 9.10217 18.3939 9.25975L12 12L5.60608 9.25975C5.2384 9.10217 5 8.74063 5 8.3406V7.5C5 6.67157 5.67157 6 6.5 6Z"
                    fill="#7A83A6"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.49614 10.8549L12 14L17.5039 10.8549C17.9834 10.5809 18.5942 10.7475 18.8682 11.227C18.9546 11.3781 19 11.5492 19 11.7232V15C19 16.1046 18.1046 17 17 17H7C5.89543 17 5 16.1046 5 15V11.7232C5 11.1709 5.44772 10.7232 6 10.7232C6.17403 10.7232 6.34504 10.7686 6.49614 10.8549Z"
                    fill="#C1C9D4"
                />
            </svg>
        );
    }
    if (isEmailType) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.5 7H17.5C18.3284 7 19 7.67157 19 8.5V9.3406C19 9.74063 18.7616 10.1022 18.3939 10.2597L12 13L5.60608 10.2597C5.2384 10.1022 5 9.74063 5 9.3406V8.5C5 7.67157 5.67157 7 6.5 7Z"
                    fill="var(--color-icon)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.49614 11.855L12 14.5L17.5039 11.855C17.9834 11.5809 18.5942 11.7475 18.8682 12.2271C18.9546 12.3782 19 12.5492 19 12.7232V16C19 17.1046 18.1046 18 17 18H7C5.89543 18 5 17.1046 5 16V12.7232C5 12.1709 5.44772 11.7232 6 11.7232C6.17403 11.7232 6.34504 11.7686 6.49614 11.855Z"
                    fill="var(--color-icon)"
                />
            </svg>
        );
    }
    if (isHasDot) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.5 8C18.3284 8 19 8.67157 19 9.5V10.2792C19 10.7097 18.7246 11.0918 18.3162 11.2279L13 13L7.68377 11.2279C7.53663 11.1789 7.40674 11.0979 7.30078 10.9942C7.36748 10.9981 7.43352 11 7.5 11C9.26303 11 10.7216 9.69645 10.9645 8.00059L17.5 8Z"
                    fill="#7A83A6"
                />{' '}
                <path
                    d="M7.5 10C8.88071 10 10 8.88071 10 7.5C10 6.11929 8.88071 5 7.5 5C6.11929 5 5 6.11929 5 7.5C5 8.88071 6.11929 10 7.5 10Z"
                    fill="#7A83A6"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.44721 12.7236L13 15L17.5528 12.7236C18.0468 12.4766 18.6474 12.6768 18.8944 13.1708C18.9639 13.3097 19 13.4628 19 13.618V16C19 17.1046 18.1046 18 17 18H9C7.89543 18 7 17.1046 7 16V13.618C7 13.0657 7.44772 12.618 8 12.618C8.15525 12.618 8.30836 12.6542 8.44721 12.7236Z"
                    fill="#C1C9D4"
                />{' '}
            </svg>
        );
    }
    if (isBlack) {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.71429 5H18.2857C19.2325 5 20 5.7835 20 6.75V7.7307C20 8.1974 19.7275 8.6192 19.3073 8.80304L12 12L4.69266 8.80304C4.27245 8.6192 4 8.1974 4 7.7307V6.75C4 5.7835 4.76751 5 5.71429 5Z"
                    fill="var(--granite-gray)"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.70987 11.1471L12 14.6544L18.2901 11.1471C18.8381 10.8416 19.5363 11.0273 19.8494 11.5621C19.9481 11.7306 20 11.9213 20 12.1154V15.7696C20 17.0014 18.9767 18 17.7143 18H6.28571C5.02335 18 4 17.0014 4 15.7696V12.1154C4 11.4995 4.51167 11.0002 5.14286 11.0002C5.34175 11.0002 5.53719 11.0508 5.70987 11.1471Z"
                    fill="var(--granite-gray)"
                ></path>
            </svg>
        );
    }
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.5 6.5H17.5C18.6046 6.5 19.5 7.39543 19.5 8.5V15.5C19.5 16.6046 18.6046 17.5 17.5 17.5H6.5C5.39543 17.5 4.5 16.6046 4.5 15.5V8.5C4.5 7.39543 5.39543 6.5 6.5 6.5Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.50171 7.5L10.7089 11.9066C11.4546 12.5377 12.5471 12.5377 13.2928 11.9066L18.5 7.5"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

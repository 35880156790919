import React from 'react';
import { useTranslation } from 'react-i18next';

import { actionNotesMarkAddRead } from 'app/const/Api';
import IconDoubleCheck from 'assets/icon/IconDoubleCheck';
import { clientQuery } from 'common/utils/ApiUtils';

const MarkAsRead = ({ noteDetail, onUpdateMarkAsRead }) => {
    const { t } = useTranslation();
    if (noteDetail.total_notifications <= 0) {
        return false;
    }

    function _handleMarkAsRead() {
        clientQuery(actionNotesMarkAddRead(noteDetail.id), {
            method: 'PUT',
            data: {
                active: 0
            }
        });
        onUpdateMarkAsRead();
    }

    return (
        <div onClick={_handleMarkAsRead} className="v2-btn-default has-icon btn-mark-read">
            <IconDoubleCheck />
            {t('header:mark_as_read')}
        </div>
    );
};

export default MarkAsRead;

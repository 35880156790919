import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';
import { COMMON, LIST_TABLE_ITEM_TYPE } from '../App';

export const getGridColumns = (columns = [], actionsStatus = '') => {
    const HEADER_EMAIL_HISTORY = {
        customer_name: {
            title: 'customer',
            style: 'col col-xl'
        },
        email: {
            title: 'email',
            style: 'col col-email'
        },
        email_type: {
            title: 'email_type',
            style: 'col col-xl'
        },
        status: {
            title: 'status',
            style: 'col col-sm'
        },
        time_stamp: {
            title: 'time_stamp',
            style: 'col col-date',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        }
    };

    const REPORT_COLUMNS_EMAIL_HISTORY = [
        {
            id: 'customer_name',
            title: 'customer',
            status: true
        },
        {
            id: 'email',
            title: 'email',
            status: true
        },
        {
            id: 'email_type',
            title: 'email_type',
            status: true
        },
        {
            id: 'status',
            title: 'status',
            status: true
        },
        {
            id: 'time_stamp',
            title: 'time_stamp',
            status: true
        }
    ];

    const REPORT_CONTENT_EMAIL_HISTORY = {
        customer_name: {
            id: 'customer_name',
            style: 'col col-xl',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO
        },
        email: {
            id: 'email',
            style: 'col col-email'
        },
        email_type: {
            id: 'email_type',
            style: 'col col-xl'
        },
        status: {
            id: 'status',
            style: 'col col-sm d-flex',
            type: LIST_TABLE_ITEM_TYPE.STATUS
        },
        time_stamp: { id: 'time_stamp', style: 'col col-date' }
    };

    const newHeader = mapCurrentValueHeader(actionsStatus, HEADER_EMAIL_HISTORY);
    const newContent = {};
    const newColumns = [];

    REPORT_COLUMNS_EMAIL_HISTORY.forEach((col) => {
        const item = col.id;

        if (columns.includes(item)) {
            newHeader[item] = HEADER_EMAIL_HISTORY[item];
            newContent[item] = REPORT_CONTENT_EMAIL_HISTORY[item];
            newColumns.push(col);
        }
    });
    return {
        header: newHeader,
        columns: newColumns,
        contentConfig: newContent
    };
};

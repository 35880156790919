import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';

import { addCardMerchant } from 'app/const/Api';
import { METHOD_CONVERT } from 'app/const/App';
import { PAYMENT_MERCHANT } from 'app/const/customer/CustomerPayment';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import CustomerAccountStatus from 'app/modules/customer/detail/accounts/components/CustomerAccountStatus';
import IconClose from 'assets/icon/IconClose';
import { clientQuery } from 'common/utils/ApiUtils';
import { getDarkModeValue } from 'common/utils/FunctionUtils';
import ButtonSave from '../button/ButtonSave';
import LoadingPaymentSquare from './components/LoadingPaymentSquare';

const AddPaymentSquare = ({ customerId, onSubmit = () => {}, onSubmitOneTime = () => {} }, ref) => {
    const { t } = useTranslation(['common', 'customers']);
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        isLoadScriptSuccess: false,
        isOneTimeCharge: false
    });
    const { isVisible, isLoadScriptSuccess, isOneTimeCharge } = state;

    const { application_id, location_id } = useSelector(({ auth }) => auth.user.settings.square);

    const refCard = useRef(null);
    const refButtonSave = useRef(null);
    const refStatusBar = useRef(null);

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = (isOneTime = false) => {
        dispatchState({ isVisible: true, isOneTimeCharge: isOneTime });
    };

    const _close = () => {
        dispatchState({ isVisible: false, isOneTimeCharge: false });
    };

    /**
     * It creates a card object, attaches it to the DOM, and returns the card object
     * @param payments - The payments object returned from the initializePayments() function.
     * @returns The card object.
     */
    const initializeCard = async (payments) => {
        const card = await payments.card({
            style: getDarkModeValue()
                ? {
                      '.input-container': { borderColor: '#2D2D2D', borderRadius: '6px' },
                      '.input-container.is-focus': { borderColor: '#006AFF' },
                      '.input-container.is-error': { borderColor: '#ff1600' },
                      '.message-text': { color: '#999999' },
                      '.message-icon': { color: '#999999' },
                      '.message-text.is-error': { color: '#ff1600' },
                      '.message-icon.is-error': { color: '#ff1600' },
                      input: { backgroundColor: '#2D2D2D', color: '#000000' },
                      'input::placeholder': { color: '#999999' },
                      'input.is-error': { color: '#ff1600' }
                  }
                : {}
        });
        await card.attach('#card-container');
        return card;
    };

    const _handleInitSquare = async () => {
        if (!window.Square || !application_id) {
            throw new Error('Square.js failed to load properly');
        }

        const payments = window.Square.payments(application_id, location_id);
        refCard.current = await initializeCard(payments);
        dispatchState({ isLoadScriptSuccess: true });
    };

    const tokenize = async (paymentMethod) => {
        const tokenResult = await paymentMethod.tokenize();
        if (tokenResult.status === 'OK') {
            return tokenResult;
        } else {
            refStatusBar.current.showStatusBar(
                'error',
                tokenResult?.errors?.map((item) => item.message),
                LIST_STATUS.ERROR
            );
        }
    };

    const handlePaymentMethodSubmission = async () => {
        try {
            const dataCard = await tokenize(refCard.current);
            if (!isOneTimeCharge) {
                _handleAddCardMerchant(dataCard.token, { ...dataCard.details.card, ...dataCard.details.billing });
            } else {
                onSubmitOneTime(dataCard.details.card, dataCard.token);
                dispatchState({ isVisible: false });
            }
        } catch (e) {
            refButtonSave.current && refButtonSave.current.removeLoading();
        }
    };

    const _handleAddCardMerchant = async (nonce, cardData) => {
        const _success = ({ data }) => {
            onSubmit(data);
            _close();
        };

        const _failed = ({ message }) => {
            refStatusBar.current.showStatusBar('error', message, LIST_STATUS.ERROR);
            refButtonSave.current.removeLoading();
        };

        clientQuery(
            addCardMerchant(customerId),
            {
                data: {
                    token: nonce,
                    merchant: PAYMENT_MERCHANT.SQUARE_GATE_WAY,
                    exp_year: `${cardData.expYear}`,
                    exp_month: `${cardData.expMonth}`,
                    account_number: `${METHOD_CONVERT} ${cardData.last4}`,
                    account_type: `${cardData.brand}`,
                    meta: { postal_code: cardData.postalCode }
                },
                method: 'POST'
            },
            _success,
            _failed
        );
    };

    if (!isVisible) return null;
    return (
        <ReactModal
            isOpen
            id="add_credit"
            onRequestClose={_close}
            onAfterOpen={_handleInitSquare}
            portalClassName="ReactModalPortal_customer"
            ariaHideApp={false}
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal --form-no-border open form-square-sdk"
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className="modal__container">
                <div className="header-modal btn-close">
                    <h3 className="header-modal__label pr-1">{t('customers:add_credit_card_in_square')}</h3>
                    <span className="v2-btn-default --icon-lg --transparent" onClick={_close} tabIndex="0">
                        <IconClose />
                    </span>
                </div>

                <div className="body-modal has-form">
                    <CustomerAccountStatus ref={refStatusBar} />
                    <div className={classNames({ 'dp-hide': !isLoadScriptSuccess })}>
                        <div id="card-container" />
                    </div>

                    {!isLoadScriptSuccess && <LoadingPaymentSquare />}
                </div>
                <div className="footer-modal btn-close">
                    <span className="v2-btn-default --transparent" onClick={_close} tabIndex="0">
                        {t('common:cancel')}
                    </span>
                    <ButtonSave ref={refButtonSave} onSave={handlePaymentMethodSubmission} className="v2-btn-main">
                        {t('common:save')}
                    </ButtonSave>
                </div>
            </div>
        </ReactModal>
    );
};

export default forwardRef(AddPaymentSquare);

import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SETTINGS_TEMPLATES_NOTES } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { NOTES_TEMPLATE_TYPE } from 'app/const/Notes';
import { reducer } from 'app/const/Reducer';
import IconAddComment from 'assets/icon/IconAddComment';
import IconEdit from 'assets/icon/IconEdit';
import IconFille from 'assets/icon/IconFile';
import IconFolder from 'assets/icon/IconFolder';
import IconThreeDots from 'assets/icon/IconThreeDots';
import IconTrash from 'assets/icon/IconTrash';
import { checkPermissionManageNotesTemplate } from 'common/utils/PermissionUtils';
import SaveAsTemplate from './SaveAsTemplate';

const Options = ({
    openFormEdit,
    openFormAddComment,
    onDelete,
    addCommment = true,
    textDelete = '',
    textEdit = '',
    className = 'v2-dropdown --more',
    isHavePermission = true,
    isHaveValue = true,
    typeTemplate = NOTES_TEMPLATE_TYPE.TOP,
    content = ''
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const permissions = useSelector(({ auth }) => auth.user.permissions.enabled || []);
    const isPermissionNotes = checkPermissionManageNotesTemplate(permissions);

    const [state, setState] = useReducer(reducer, {
        isVisible: false
    });

    const { isVisible: finalIsVisible } = state;

    const refDropdown = useRef(null);
    const refSaveAsTemplate = useRef(null);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_list_options_note_detail');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_list_options_note_detail');

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    function _handleOpen(e) {
        e.stopPropagation();
        setState({ isVisible: !finalIsVisible });
    }

    function _handleEditNote(e) {
        e.stopPropagation();
        openFormEdit();
        setState({ isVisible: false });
    }

    function _handleAddComment(e) {
        e.stopPropagation();
        openFormAddComment();
        setState({ isVisible: false });
    }

    function _handleRemoveComment(e) {
        e.stopPropagation();
        setState({ isVisible: false });
        onDelete();
    }

    function _redirectToManage() {
        history.push(addBranchPath(SETTINGS_TEMPLATES_NOTES.replace(':id', typeTemplate)));
    }

    function _openSaveTemplate() {
        refSaveAsTemplate.current.open(content, typeTemplate);
    }

    function _renderOptions() {
        if (!finalIsVisible) {
            return false;
        }

        return (
            <div className="v2-dropdown__menu" id={'show_list_options_note_detail'}>
                <ul>
                    {isHavePermission && (
                        <li className="items has-icon js-toggle-edit" onClick={_handleEditNote}>
                            <IconEdit />
                            <p className="txt-ellipsis">{textEdit || t('customers:edit_note')}</p>
                        </li>
                    )}
                    {isHaveValue && isPermissionNotes && (
                        <li className="items has-icon js-toggle-edit" onClick={_openSaveTemplate}>
                            <IconFolder />
                            <p className="txt-ellipsis">{t('jobDetail:save_note_as_template')}</p>
                        </li>
                    )}
                    {isPermissionNotes && (
                        <li className="items has-icon js-toggle-edit" onClick={_redirectToManage}>
                            <IconFille />
                            <p className="txt-ellipsis">{t('jobDetail:manage_templates')}</p>
                        </li>
                    )}
                    {addCommment && (
                        <li className="items has-icon js-toggle-comment" onClick={_handleAddComment}>
                            <IconAddComment />
                            <p className="txt-ellipsis">{t('customers:add_comment')}</p>
                        </li>
                    )}
                    {isHavePermission && (
                        <li className="items has-icon red-coral" onClick={_handleRemoveComment}>
                            <IconTrash />
                            <p className="txt-ellipsis">{textDelete || t('customers:remove_note')}</p>
                        </li>
                    )}
                </ul>
            </div>
        );
    }

    return (
        <Fragment>
            <div className={`${className} ${finalIsVisible ? 'active' : ''}`} ref={refDropdown}>
                <div className="dropbtn v2-btn-default --icon-lg --transparent" onClick={_handleOpen}>
                    <IconThreeDots />
                </div>
                {_renderOptions()}
            </div>
            <SaveAsTemplate ref={refSaveAsTemplate} />
        </Fragment>
    );
};

export default Options;

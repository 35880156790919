const IconPortfolio = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 13.3259C6.09292 13.3259 6.18538 13.3389 6.27472 13.3644L10.0493 14.4437C10.2511 15.3348 11.0478 16.0002 12 16.0002C12.9522 16.0002 13.7489 15.3348 13.9507 14.4437L17.7253 13.3644C18.2563 13.2127 18.8098 13.5202 18.9615 14.0512C18.987 14.1406 19 14.233 19 14.3259V17.0002C19 18.1048 18.1046 19.0002 17 19.0002H7C5.89543 19.0002 5 18.1048 5 17.0002V14.3259C5 13.7737 5.44772 13.3259 6 13.3259Z"
                fill="var(--granite-gray)"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.9949 6.82373C16.9037 5.24892 15.5977 4 14 4H10C8.34315 4 7 5.34315 7 7H9C9 6.44772 9.44772 6 10 6H14C14.5128 6 14.9355 6.38604 14.9933 6.88338L15 7H17L16.9949 6.82373ZM17 8C18.1046 8 19 8.89543 19 10V11.2457C19 11.6922 18.704 12.0846 18.2747 12.2072L14 13.428V13L13.9945 12.8507C13.9182 11.8159 13.0544 11 12 11C10.8954 11 10 11.8954 10 13V13.429L5.72528 12.2072C5.29598 12.0846 5 11.6922 5 11.2457V10C5 8.89543 5.89543 8 7 8H17ZM13 13C13 12.4477 12.5523 12 12 12C11.4477 12 11 12.4477 11 13V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V13Z"
                fill="var(--granite-gray)"
            ></path>
        </svg>
    );
};

export default IconPortfolio;

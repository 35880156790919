import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import IconArrowDown from 'assets/icon/IconArrowDown';

const Time = forwardRef(({ defaultValue }, ref) => {
    const { t } = useTranslation();
    const { company: companySettings, settings } = useSelector(({ auth }) => auth.user);

    const companyTimes = companySettings?.operating_time;
    const companyTimeZone = settings?.timezone;

    const options = companyTimes && typeof companyTimes === 'object' ? Object.keys(companyTimes) : [];

    function getCurrentTime() {
        const currentTime = moment().tz(companyTimeZone).format('h:00 a');
        return options.find((item) => item === currentTime) || currentTime;
    }

    const [state, setState] = useReducer(reducer, {
        isVisible: false,
        options: options,
        selected: defaultValue || getCurrentTime() || ''
    });

    const { isVisible: finalIsVisible, selected: timeSelected } = state;
    const refDropdown = useRef(null);

    useImperativeHandle(ref, () => ({
        getValue: () => {
            return state.selected;
        },
        resetValue: () => {
            setState({
                selected: defaultValue || getCurrentTime()
            });
        }
    }));

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_list_company_times');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_list_company_times');

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    const _handleOpen = (e) => {
        e.stopPropagation();

        setState({
            isVisible: !finalIsVisible
        });
    };

    function _handleSelectTime(e, time) {
        e.stopPropagation();
        setState({
            isVisible: false,
            selected: time
        });
    }

    function _renderListTimes() {
        if (!finalIsVisible) {
            return false;
        }

        return state.options.map((item) => {
            const isActive = timeSelected === item;
            return (
                <li
                    key={item}
                    className={`items ${isActive ? 'active' : ''}`}
                    onClick={(e) => _handleSelectTime(e, item)}
                >
                    <div className="txt-ellipsis">{item}</div>
                </li>
            );
        });
    }

    return (
        <div className="col">
            <p className="txt">{t('common:time')}</p>
            <div className={`v2-dropdown ${finalIsVisible ? 'active' : ''}`} ref={refDropdown}>
                <div className="dropbtn items" onClick={_handleOpen}>
                    <div className="txt-ellipsis">{timeSelected || t('customers:select_time')}</div>
                    <div className="arrow">
                        <IconArrowDown />
                    </div>
                </div>
                <div className="v2-dropdown__menu scrolls" id={'show_list_company_times'}>
                    <ul>{_renderListTimes()}</ul>
                </div>
            </div>
        </div>
    );
});

export default Time;

import loadable from '@loadable/component';
import { React, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { GET_LIST_SYSTEM_SETTING } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { getGridColumnsSettingSystem } from 'app/const/setting/SettingSystemTable';
import { LIST_TOOLTIP, TYPE_TEMPLATE, TYPE_TEMPLATE_ID } from 'app/const/Settings';
import { customersSystem } from 'app/modules/settings/utils';
import { clientQuery } from 'common/utils/ApiUtils';

const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const BoxTipsSetting = loadable(() => import('app/modules/settings/components/BoxTipsSetting'));
const DropDownFromEmail = loadable(() => import('app/modules/settings/components/DropDownFromEmail'));
const SettingsTemplate = loadable(() => import('../templates'));
const MainHeaderSettings = loadable(() => import('app/modules/settings/components/MainHeaderSettings'));

const SettingsSystem = () => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { data: [], isLoading: true });
    const refContainer = useRef(null);
    const refTemplate = useRef(null);
    const isLoading = state.isLoading;

    useEffect(() => {
        _getListSystemsSetting();
    }, []);

    const _getListSystemsSetting = () => {
        !isLoading && dispatchState({ isLoading: true });

        const _success = ({ data }) => {
            dispatchState({
                data,
                isLoading: false
            });
        };

        const _failed = () => dispatchState({ data: [], isLoading: false });

        clientQuery(GET_LIST_SYSTEM_SETTING, { method: 'GET' }, _success, _failed);
    };

    const _handleOpenTemplate = (item) => {
        refContainer.current.classList = 'wrapper-columns setting-custom has-details';
        refTemplate.current.displayTemplate(item.id, null, false, state.data, true);
    };

    const _handleCloseTemplate = (newTemplate, shouldClose = true) => {
        if (shouldClose) refContainer.current.classList = 'wrapper-columns setting-custom';

        if (newTemplate) {
            delete newTemplate['isCreate'];
            const newData = state.data.map((item) => {
                if (item.id === newTemplate.id) return { ...item, ...newTemplate };
                return item;
            });

            dispatchState({ data: newData });
        }
    };

    return (
        <>
            <MainHeaderSettings />
            <div ref={refContainer} className="wrapper-columns setting-custom">
                <div className="container-print contents-pages">
                    <BoxTipsSetting typeId={LIST_TOOLTIP.AUTOMATED_EMAILS} />
                    <DropDownFromEmail className="from-addr flex-auto" />
                    <GdGridView
                        isLoading={state.isLoading}
                        content={customersSystem(state.data)}
                        classTable="scrolls-x --email"
                        fileTranslation={'setting'}
                        onNameClick={_handleOpenTemplate}
                        msgEmpty={t('setting:create_your_first_template')}
                        {...getGridColumnsSettingSystem()}
                    />
                </div>
                <SettingsTemplate
                    ref={refTemplate}
                    type={TYPE_TEMPLATE.SYSTEM}
                    templateType={TYPE_TEMPLATE_ID.SYSTEM}
                    onClose={_handleCloseTemplate}
                />
            </div>
        </>
    );
};

export default SettingsSystem;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { ROUTING_UNITS } from 'app/modules/addons/advanceRouting/constants';
import { convertSecondsToETA } from 'common/utils/TimeUtils';

const BoxDistance = ({ distance = {}, time }) => {
    const { t } = useTranslation(['calendar']);
    return (
        <div className="box-distance">
            <span>
                {distance.value}
                {t(`${ROUTING_UNITS[distance.unit]?.label}`)?.toLowerCase()}
            </span>
            <span className="dots" />
            {convertSecondsToETA(time || 0)}
        </div>
    );
};

export default BoxDistance;

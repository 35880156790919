import IconOnBoarding from 'assets/icon/IconOnBoarding';
import React from 'react';

export const MenuSidebarTopCalendarIcon = () => {
    return (
        <>
            <div className="default">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                        fill="#EEF4FE"
                    ></path>
                    <path
                        opacity="0.6"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8 15H24V21C24 22.6569 22.6569 24 21 24H11C9.34315 24 8 22.6569 8 21V15Z"
                        fill="#2477F1"
                    ></path>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13 7C13.5523 7 14 7.44772 14 8V9H18V8C18 7.44772 18.4477 7 19 7H20C20.5523 7 21 7.44772 21 8V9C22.6569 9 24 10.3431 24 12V13H8V12C8 10.3431 9.34315 9 11 9V8C11 7.44772 11.4477 7 12 7H13Z"
                        fill="#2477F1"
                    ></path>
                </svg>
            </div>
            <div className="active">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                        fill="#2477F1"
                    ></path>
                    <path
                        opacity="0.6"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8 15H24V21C24 22.6569 22.6569 24 21 24H11C9.34315 24 8 22.6569 8 21V15Z"
                        fill="white"
                    ></path>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13 7C13.5523 7 14 7.44772 14 8V9H18V8C18 7.44772 18.4477 7 19 7H20C20.5523 7 21 7.44772 21 8V9C22.6569 9 24 10.3431 24 12V13H8V12C8 10.3431 9.34315 9 11 9V8C11 7.44772 11.4477 7 12 7H13Z"
                        fill="white"
                    ></path>
                </svg>
            </div>
        </>
    );
};

export const MenuSidebarTopInboxIcon = () => {
    return (
        <>
            <div className="default">
                <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                        fill="#EDF9FB"
                    />
                    <path
                        opacity="0.6"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.8289 9H20.171C20.7018 9 21.2108 9.21099 21.5859 9.58649L24.648 12.6517C24.8734 12.8773 25 13.1831 25 13.502C25 13.777 24.777 14 24.502 14H21.0704C20.4017 14 19.7772 14.3342 19.4063 14.8906L18.5937 16.1094C18.2228 16.6658 17.5983 17 16.9296 17H15.0722C14.4039 17 13.7798 16.6662 13.4087 16.1104L12.5938 14.8896C12.2228 14.3338 11.5987 14 10.9304 14H7.49827C7.22308 14 7 13.7769 7 13.5017C7 13.1827 7.12667 12.8767 7.35218 12.651L10.4141 9.5864C10.7892 9.21095 11.2982 9 11.8289 9Z"
                        fill="#1AB6D2"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8 15H9.92963C10.5983 15 11.2228 15.3342 11.5937 15.8906L12.4063 17.1094C12.7772 17.6658 13.4017 18 14.0704 18H17.9296C18.5983 18 19.2228 17.6658 19.5937 17.1094L20.4063 15.8906C20.7772 15.3342 21.4017 15 22.0704 15H24C24.5523 15 25 15.4477 25 16V20C25 21.6569 23.6569 23 22 23H10C8.34315 23 7 21.6569 7 20V16C7 15.4477 7.44772 15 8 15Z"
                        fill="#1AB6D2"
                    />
                </svg>
            </div>
            <div className="active">
                <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                        fill="#1AB6D2"
                    />
                    <path
                        opacity="0.6"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.8289 9H20.171C20.7018 9 21.2108 9.21099 21.5859 9.58649L24.648 12.6517C24.8734 12.8773 25 13.1831 25 13.502C25 13.777 24.777 14 24.502 14H21.0704C20.4017 14 19.7772 14.3342 19.4063 14.8906L18.5937 16.1094C18.2228 16.6658 17.5983 17 16.9296 17H15.0722C14.4039 17 13.7798 16.6662 13.4087 16.1104L12.5938 14.8896C12.2228 14.3338 11.5987 14 10.9304 14H7.49827C7.22308 14 7 13.7769 7 13.5017C7 13.1827 7.12667 12.8767 7.35218 12.651L10.4141 9.5864C10.7892 9.21095 11.2982 9 11.8289 9Z"
                        fill="white"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8 15H9.92963C10.5983 15 11.2228 15.3342 11.5937 15.8906L12.4063 17.1094C12.7772 17.6658 13.4017 18 14.0704 18H17.9296C18.5983 18 19.2228 17.6658 19.5937 17.1094L20.4063 15.8906C20.7772 15.3342 21.4017 15 22.0704 15H24C24.5523 15 25 15.4477 25 16V20C25 21.6569 23.6569 23 22 23H10C8.34315 23 7 21.6569 7 20V16C7 15.4477 7.44772 15 8 15Z"
                        fill="white"
                    />
                </svg>
            </div>
        </>
    );
};

export const MenuSidebarTopCustomerIcon = () => {
    return (
        <>
            <div className="default">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                        fill="#F4FAF2"
                    ></path>
                    <path
                        opacity="0.6"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.0644 15.8768L21.444 16.5554C22.9288 16.9797 24.0368 18.2207 24.2907 19.7439L24.653 21.9177C24.8346 23.0073 24.0986 24.0377 23.009 24.2193C22.9735 24.2252 22.9379 24.2302 22.9021 24.2342L20.3474 24.5193C17.4851 24.8388 14.5962 24.8402 11.7336 24.5236L9.10036 24.2323C8.00249 24.1109 7.21093 23.1224 7.33237 22.0246C7.3364 21.9881 7.34143 21.9518 7.34746 21.9156L7.70941 19.7439C7.96328 18.2207 9.07128 16.9797 10.5561 16.5554L12.9357 15.8768C13.7821 16.8493 14.8454 17.5 16.0001 17.5C17.1548 17.5 18.218 16.8493 19.0644 15.8768Z"
                        fill="#81C479"
                    ></path>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16 15C18.2091 15 20 12.2091 20 10C20 7.79086 18.2091 6 16 6C13.7909 6 12 7.79086 12 10C12 12.2091 13.7909 15 16 15Z"
                        fill="#81C479"
                    ></path>
                </svg>
            </div>
            <div className="active">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                        fill="#81C479"
                    ></path>
                    <path
                        opacity="0.6"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.0644 15.8768L21.444 16.5554C22.9288 16.9797 24.0368 18.2207 24.2907 19.7439L24.653 21.9177C24.8346 23.0073 24.0986 24.0377 23.009 24.2193C22.9735 24.2252 22.9379 24.2302 22.9021 24.2342L20.3474 24.5193C17.4851 24.8388 14.5962 24.8402 11.7336 24.5236L9.10036 24.2323C8.00249 24.1109 7.21093 23.1224 7.33237 22.0246C7.3364 21.9881 7.34143 21.9518 7.34746 21.9156L7.70941 19.7439C7.96328 18.2207 9.07128 16.9797 10.5561 16.5554L12.9357 15.8768C13.7821 16.8493 14.8454 17.5 16.0001 17.5C17.1548 17.5 18.218 16.8493 19.0644 15.8768Z"
                        fill="white"
                    ></path>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16 15C18.2091 15 20 12.2091 20 10C20 7.79086 18.2091 6 16 6C13.7909 6 12 7.79086 12 10C12 12.2091 13.7909 15 16 15Z"
                        fill="white"
                    ></path>
                </svg>
            </div>
        </>
    );
};

export const MenuSidebarTopLeadIcon = () => {
    return (
        <>
            <div className="default">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                        fill="#FEF4EF"
                    ></path>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.9995 13L19.0001 16V21.5858C19.0001 21.851 18.8947 22.1054 18.7072 22.2929L16.2929 24.7071C16.1054 24.8946 15.8511 25 15.5858 25H14.0001C13.4478 25 13.0001 24.5523 13.0001 24V16L9.99951 13H21.9995Z"
                        fill="#F3743E"
                    ></path>
                    <path
                        opacity="0.6"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23 8C23.5523 8 24 8.44772 24 9V10.1716C24 10.702 23.7893 11.2107 23.4143 11.5858L22.9985 12H8.99951L8.6063 11.6062C8.21917 11.2191 8.00766 10.6902 8.02111 10.1429L8.04981 8.97543C8.06314 8.43288 8.50679 8 9.0495 8H23Z"
                        fill="#F3743E"
                    ></path>
                </svg>
            </div>
            <div className="active">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                        fill="#F3743E"
                    ></path>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.9995 13L19.0001 16V21.5858C19.0001 21.851 18.8947 22.1054 18.7072 22.2929L16.2929 24.7071C16.1054 24.8946 15.8511 25 15.5858 25H14.0001C13.4478 25 13.0001 24.5523 13.0001 24V16L9.99951 13H21.9995Z"
                        fill="white"
                    ></path>
                    <path
                        opacity="0.6"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23 8C23.5523 8 24 8.44772 24 9V10.1716C24 10.702 23.7893 11.2107 23.4143 11.5858L22.9985 12H8.99951L8.6063 11.6062C8.21917 11.2191 8.00766 10.6902 8.02111 10.1429L8.04981 8.97543C8.06314 8.43288 8.50679 8 9.0495 8H23Z"
                        fill="white"
                    ></path>
                </svg>
            </div>
        </>
    );
};

export const MenuSidebarTopReviewIcon = () => {
    return (
        <>
            <div className="default">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                        fill="#FEF8EF"
                    ></path>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.5347 18.7447L12.5034 20.3383C12.0146 20.5953 11.41 20.4073 11.153 19.9185C11.0506 19.7238 11.0153 19.5009 11.0525 19.2841L11.6314 15.9088C11.687 15.5844 11.5795 15.2534 11.3438 15.0236L8.89147 12.6332C8.49598 12.2477 8.48789 11.6146 8.87339 11.2191C9.0269 11.0616 9.22804 10.9591 9.44568 10.9275L12.8347 10.435C13.1604 10.3877 13.442 10.1831 13.5877 9.88799L15.1033 6.817C15.3477 6.32174 15.9473 6.1184 16.4426 6.36283C16.6398 6.46016 16.7994 6.61978 16.8968 6.817L18.4124 9.88799C18.5581 10.1831 18.8396 10.3877 19.1653 10.435L22.5544 10.9275C23.1009 11.0069 23.4796 11.5143 23.4002 12.0609C23.3686 12.2785 23.2661 12.4797 23.1086 12.6332L20.6562 15.0236C20.4206 15.2534 20.313 15.5844 20.3687 15.9088L20.9476 19.2841C21.0409 19.8284 20.6753 20.3454 20.131 20.4388C19.9142 20.4759 19.6913 20.4406 19.4966 20.3383L16.4654 18.7447C16.174 18.5915 15.826 18.5915 15.5347 18.7447Z"
                        fill="#F3A52E"
                    ></path>
                    <path
                        opacity="0.6"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17 22C17.5523 22 18 22.4477 18 23V26H14V23C14 22.4477 14.4477 22 15 22H17ZM23 23C23.5523 23 24 23.4477 24 24V26H20V24C20 23.4477 20.4477 23 21 23H23ZM11 24C11.5523 24 12 24.4477 12 25V26H8V25C8 24.4477 8.44772 24 9 24H11Z"
                        fill="#F3A52E"
                    ></path>
                </svg>
            </div>
            <div className="active">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                        fill="#F3A52E"
                    ></path>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.5347 18.7447L12.5034 20.3383C12.0146 20.5953 11.41 20.4073 11.153 19.9185C11.0506 19.7238 11.0153 19.5009 11.0525 19.2841L11.6314 15.9088C11.687 15.5844 11.5795 15.2534 11.3438 15.0236L8.89147 12.6332C8.49598 12.2477 8.48789 11.6146 8.87339 11.2191C9.0269 11.0616 9.22804 10.9591 9.44568 10.9275L12.8347 10.435C13.1604 10.3877 13.442 10.1831 13.5877 9.88799L15.1033 6.817C15.3477 6.32174 15.9473 6.1184 16.4426 6.36283C16.6398 6.46016 16.7994 6.61978 16.8968 6.817L18.4124 9.88799C18.5581 10.1831 18.8396 10.3877 19.1653 10.435L22.5544 10.9275C23.1009 11.0069 23.4796 11.5143 23.4002 12.0609C23.3686 12.2785 23.2661 12.4797 23.1086 12.6332L20.6562 15.0236C20.4206 15.2534 20.313 15.5844 20.3687 15.9088L20.9476 19.2841C21.0409 19.8284 20.6753 20.3454 20.131 20.4388C19.9142 20.4759 19.6913 20.4406 19.4966 20.3383L16.4654 18.7447C16.174 18.5915 15.826 18.5915 15.5347 18.7447Z"
                        fill="white"
                    ></path>
                    <path
                        opacity="0.6"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17 22C17.5523 22 18 22.4477 18 23V26H14V23C14 22.4477 14.4477 22 15 22H17ZM23 23C23.5523 23 24 23.4477 24 24V26H20V24C20 23.4477 20.4477 23 21 23H23ZM11 24C11.5523 24 12 24.4477 12 25V26H8V25C8 24.4477 8.44772 24 9 24H11Z"
                        fill="white"
                    ></path>
                </svg>
            </div>
        </>
    );
};

export const MenuSidebarTopExportIcon = () => {
    return (
        <>
            <div className="default">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                        fill="#EFFAF8"
                    ></path>
                    <path
                        opacity="0.6"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.32869 11C12.066 11 12.3347 13.9178 13.4169 15.8436C13.151 16.3355 13 16.9001 13 17.5C13 18.8962 13.8176 20.1014 15 20.6632L15 23H6.00005C5.49538 15 6.60493 11 9.32869 11ZM22.2689 15.8762C24.5665 15.8762 25.8102 18.2508 26 23H18L18.0011 20.6627C19.183 20.1007 20 18.8958 20 17.5C20 17.1134 19.9374 16.7414 19.8216 16.3937L19.8722 16.3642C20.3738 16.0909 21.0156 15.8762 22.2689 15.8762Z"
                        fill="#3AC8B0"
                    ></path>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16 8H17V15.05C18.1411 15.2816 19 16.2905 19 17.5C19 18.7095 18.1411 19.7184 17 19.95V23H16V19.95C14.8589 19.7184 14 18.7095 14 17.5C14 16.2905 14.8589 15.2816 16 15.05V8Z"
                        fill="#3AC8B0"
                    ></path>
                </svg>
            </div>
            <div className="active">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                        fill="#3AC8B0"
                    ></path>
                    <path
                        opacity="0.6"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.32869 11C12.066 11 12.3347 13.9178 13.4169 15.8436C13.151 16.3355 13 16.9001 13 17.5C13 18.8962 13.8176 20.1014 15 20.6632L15 23H6.00005C5.49538 15 6.60493 11 9.32869 11ZM22.2689 15.8762C24.5665 15.8762 25.8102 18.2508 26 23H18L18.0011 20.6627C19.183 20.1007 20 18.8958 20 17.5C20 17.1134 19.9374 16.7414 19.8216 16.3937L19.8722 16.3642C20.3738 16.0909 21.0156 15.8762 22.2689 15.8762Z"
                        fill="white"
                    ></path>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16 8H17V15.05C18.1411 15.2816 19 16.2905 19 17.5C19 18.7095 18.1411 19.7184 17 19.95V23H16V19.95C14.8589 19.7184 14 18.7095 14 17.5C14 16.2905 14.8589 15.2816 16 15.05V8Z"
                        fill="white"
                    ></path>
                </svg>
            </div>
        </>
    );
};

export const MenuSidebarTopCommissionIcon = () => {
    return (
        <>
            <div className="default">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                        fill="#F4FAF2"
                    />
                    <path
                        opacity="0.5"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16 5C22.0751 5 27 9.92487 27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5ZM12.3333 8.66667C10.3083 8.66667 8.66667 10.3083 8.66667 12.3333C8.66667 14.3584 10.3083 16 12.3333 16C12.5322 16 12.7274 15.9842 12.9177 15.9537L9.2497 19.6206C8.53374 20.3366 8.53374 21.4974 9.2497 22.2133L9.89788 22.8615C10.6138 23.5775 11.7746 23.5775 12.4906 22.8615L16.0146 19.3367C16.005 19.4454 16 19.5555 16 19.6667C16 21.6917 17.6416 23.3333 19.6667 23.3333C21.6917 23.3333 23.3333 21.6917 23.3333 19.6667C23.3333 17.6416 21.6917 16 19.6667 16C19.5555 16 19.4454 16.005 19.3367 16.0146L22.2133 13.1388C22.9293 12.4228 22.9293 11.262 22.2133 10.5461L21.5651 9.89788C20.8492 9.18192 19.6884 9.18192 18.9724 9.89788L15.9537 12.9177C15.9842 12.7274 16 12.5322 16 12.3333C16 10.3083 14.3584 8.66667 12.3333 8.66667Z"
                        fill="#2BA062"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.4286 16.8571C20.8487 16.8571 22 18.0084 22 19.4286C22 20.8487 20.8487 22 19.4286 22C18.0084 22 16.8571 20.8487 16.8571 19.4286C16.8571 18.0084 18.0084 16.8571 19.4286 16.8571ZM20.5977 11.4023C20.9324 11.7371 20.9324 12.2798 20.5977 12.6145L12.1124 21.0998C11.7777 21.4345 11.235 21.4345 10.9002 21.0998C10.5655 20.765 10.5655 20.2223 10.9002 19.8876L19.3855 11.4023C19.7202 11.0676 20.2629 11.0676 20.5977 11.4023ZM19.4286 18.1429C18.7185 18.1429 18.1429 18.7185 18.1429 19.4286C18.1429 20.1387 18.7185 20.7143 19.4286 20.7143C20.1387 20.7143 20.7143 20.1387 20.7143 19.4286C20.7143 18.7185 20.1387 18.1429 19.4286 18.1429ZM12.5714 10C13.9916 10 15.1429 11.1513 15.1429 12.5714C15.1429 13.9916 13.9916 15.1429 12.5714 15.1429C11.1513 15.1429 10 13.9916 10 12.5714C10 11.1513 11.1513 10 12.5714 10ZM12.5714 11.2857C11.8613 11.2857 11.2857 11.8613 11.2857 12.5714C11.2857 13.2815 11.8613 13.8571 12.5714 13.8571C13.2815 13.8571 13.8571 13.2815 13.8571 12.5714C13.8571 11.8613 13.2815 11.2857 12.5714 11.2857Z"
                        fill="#2BA062"
                    />
                </svg>
            </div>
            <div className="active">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 16C0 12.2801 0 10.4202 0.40889 8.89417C1.5185 4.75307 4.75307 1.5185 8.89417 0.40889C10.4202 0 12.2801 0 16 0V0C19.7199 0 21.5798 0 23.1058 0.40889C27.2469 1.5185 30.4815 4.75307 31.5911 8.89417C32 10.4202 32 12.2801 32 16V16C32 19.7199 32 21.5798 31.5911 23.1058C30.4815 27.2469 27.2469 30.4815 23.1058 31.5911C21.5798 32 19.7199 32 16 32V32C12.2801 32 10.4202 32 8.89417 31.5911C4.75307 30.4815 1.5185 27.2469 0.40889 23.1058C0 21.5798 0 19.7199 0 16V16Z"
                        fill="#2BA062"
                    />
                    <path
                        opacity="0.5"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16 5C22.0751 5 27 9.92487 27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5ZM12.3333 8.66667C10.3083 8.66667 8.66667 10.3083 8.66667 12.3333C8.66667 14.3584 10.3083 16 12.3333 16C12.5322 16 12.7274 15.9842 12.9177 15.9537L9.2497 19.6206C8.53374 20.3366 8.53374 21.4974 9.2497 22.2133L9.89788 22.8615C10.6138 23.5775 11.7746 23.5775 12.4906 22.8615L16.0146 19.3367C16.005 19.4454 16 19.5555 16 19.6667C16 21.6917 17.6416 23.3333 19.6667 23.3333C21.6917 23.3333 23.3333 21.6917 23.3333 19.6667C23.3333 17.6416 21.6917 16 19.6667 16C19.5555 16 19.4454 16.005 19.3367 16.0146L22.2133 13.1388C22.9293 12.4228 22.9293 11.262 22.2133 10.5461L21.5651 9.89788C20.8492 9.18192 19.6884 9.18192 18.9724 9.89788L15.9537 12.9177C15.9842 12.7274 16 12.5322 16 12.3333C16 10.3083 14.3584 8.66667 12.3333 8.66667Z"
                        fill="white"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.4286 16.8571C20.8487 16.8571 22 18.0084 22 19.4286C22 20.8487 20.8487 22 19.4286 22C18.0084 22 16.8571 20.8487 16.8571 19.4286C16.8571 18.0084 18.0084 16.8571 19.4286 16.8571ZM20.5977 11.4023C20.9324 11.7371 20.9324 12.2798 20.5977 12.6145L12.1124 21.0998C11.7777 21.4345 11.235 21.4345 10.9002 21.0998C10.5655 20.765 10.5655 20.2223 10.9002 19.8876L19.3855 11.4023C19.7202 11.0676 20.2629 11.0676 20.5977 11.4023ZM19.4286 18.1429C18.7185 18.1429 18.1429 18.7185 18.1429 19.4286C18.1429 20.1387 18.7185 20.7143 19.4286 20.7143C20.1387 20.7143 20.7143 20.1387 20.7143 19.4286C20.7143 18.7185 20.1387 18.1429 19.4286 18.1429ZM12.5714 10C13.9916 10 15.1429 11.1513 15.1429 12.5714C15.1429 13.9916 13.9916 15.1429 12.5714 15.1429C11.1513 15.1429 10 13.9916 10 12.5714C10 11.1513 11.1513 10 12.5714 10ZM12.5714 11.2857C11.8613 11.2857 11.2857 11.8613 11.2857 12.5714C11.2857 13.2815 11.8613 13.8571 12.5714 13.8571C13.2815 13.8571 13.8571 13.2815 13.8571 12.5714C13.8571 11.8613 13.2815 11.2857 12.5714 11.2857Z"
                        fill="white"
                    />
                </svg>
            </div>
        </>
    );
};

export const MenuSidebarTopDashboardIcon = () => {
    return (
        <div>
            <div className="default">
                <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width={32} height={32} rx={12} fill="#f4f6fa" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.35271 18.6338C5.88424 16.9179 5.88192 15.1081 6.346 13.391C6.81008 11.6739 7.72375 10.1116 8.99282 8.8653C10.0234 7.85324 11.258 7.07925 12.6095 6.59223C13.1291 6.405 13.6746 6.73503 13.809 7.27071L14.7826 11.1504C14.9171 11.6861 14.5822 12.219 14.0964 12.4818C13.7684 12.6593 13.4654 12.8825 13.1969 13.1461C12.6893 13.6447 12.3238 14.2696 12.1382 14.9564C11.9526 15.6433 11.9535 16.3672 12.1409 17.0536C12.3283 17.7399 12.6953 18.3639 13.2042 18.8611C13.7131 19.3583 14.3455 19.7108 15.036 19.8822C15.7265 20.0536 16.4503 20.0378 17.1326 19.8362C17.815 19.6347 18.4312 19.2548 18.9178 18.7358C19.1752 18.4613 19.3913 18.1531 19.5611 17.8211C19.8125 17.3293 20.3376 16.9822 20.8762 17.1041L24.7775 17.9874C25.3161 18.1094 25.6587 18.6471 25.4836 19.1709C25.0281 20.5334 24.283 21.7856 23.2951 22.8393C22.0786 24.137 20.538 25.0867 18.8321 25.5905C17.1262 26.0943 15.3168 26.134 13.5905 25.7055C11.8642 25.277 10.2834 24.3958 9.01113 23.1527C7.73887 21.9097 6.82119 20.3497 6.35271 18.6338Z"
                        fill="#B8ACFE"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16 7C16 6.44772 16.4488 5.99482 16.9983 6.04996C17.9691 6.14736 18.9222 6.38648 19.8268 6.7612C21.0401 7.26375 22.1425 8.00035 23.0711 8.92893C23.9997 9.85752 24.7362 10.9599 25.2388 12.1732C25.6135 13.0778 25.8526 14.0309 25.95 15.0017C26.0052 15.5512 25.5523 16 25 16H21C20.4477 16 20.0123 15.5455 19.8756 15.0104C19.8287 14.8264 19.7685 14.6455 19.6955 14.4693C19.4945 13.984 19.1999 13.543 18.8284 13.1716C18.457 12.8001 18.016 12.5055 17.5307 12.3045C17.3545 12.2315 17.1736 12.1713 16.9896 12.1244C16.4545 11.9877 16 11.5523 16 11L16 7Z"
                        fill="#755FFF"
                    />
                </svg>
            </div>
            <div className="active">
                <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width={32} height={32} rx={12} fill="#755FFF" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.35271 18.6338C5.88424 16.9179 5.88192 15.1081 6.346 13.391C6.81008 11.6739 7.72375 10.1116 8.99282 8.8653C10.0234 7.85324 11.258 7.07925 12.6095 6.59223C13.1291 6.405 13.6746 6.73503 13.809 7.27071L14.7826 11.1504C14.9171 11.6861 14.5822 12.219 14.0964 12.4818C13.7684 12.6593 13.4654 12.8825 13.1969 13.1461C12.6893 13.6447 12.3238 14.2696 12.1382 14.9564C11.9526 15.6433 11.9535 16.3672 12.1409 17.0536C12.3283 17.7399 12.6953 18.3639 13.2042 18.8611C13.7131 19.3583 14.3455 19.7108 15.036 19.8822C15.7265 20.0536 16.4503 20.0378 17.1326 19.8362C17.815 19.6347 18.4312 19.2548 18.9178 18.7358C19.1752 18.4613 19.3913 18.1531 19.5611 17.8211C19.8125 17.3293 20.3376 16.9822 20.8762 17.1041L24.7775 17.9874C25.3161 18.1094 25.6587 18.6471 25.4836 19.1709C25.0281 20.5334 24.283 21.7856 23.2951 22.8393C22.0786 24.137 20.538 25.0867 18.8321 25.5905C17.1262 26.0943 15.3168 26.134 13.5905 25.7055C11.8642 25.277 10.2834 24.3958 9.01113 23.1527C7.73887 21.9097 6.82119 20.3497 6.35271 18.6338Z"
                        fill="#B8ACFE"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16 7C16 6.44772 16.4488 5.99482 16.9983 6.04996C17.9691 6.14736 18.9222 6.38648 19.8268 6.7612C21.0401 7.26375 22.1425 8.00035 23.0711 8.92893C23.9997 9.85752 24.7362 10.9599 25.2388 12.1732C25.6135 13.0778 25.8526 14.0309 25.95 15.0017C26.0052 15.5512 25.5523 16 25 16H21C20.4477 16 20.0123 15.5455 19.8756 15.0104C19.8287 14.8264 19.7685 14.6455 19.6955 14.4693C19.4945 13.984 19.1999 13.543 18.8284 13.1716C18.457 12.8001 18.016 12.5055 17.5307 12.3045C17.3545 12.2315 17.1736 12.1713 16.9896 12.1244C16.4545 11.9877 16 11.5523 16 11L16 7Z"
                        fill="white"
                    />
                </svg>
            </div>
        </div>
    );
};

export const MenuSidebarBottomSettingIcon = () => {
    return (
        <>
            <div className="default">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                        fill="#F9F3FC"
                    ></path>
                    <path
                        opacity="0.6"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.7151 6.02057L22.2849 6.92694C22.6019 7.10991 22.7935 7.45138 22.7846 7.81721L22.7316 10.0261C23.5317 10.9271 24.1537 11.9898 24.5424 13.159L26.4789 14.2158C26.8001 14.391 27 14.7277 27 15.0937V16.9064C27 17.2723 26.8001 17.6091 26.4789 17.7843L24.5424 18.8411C24.1537 20.0104 23.5316 21.0731 22.7314 21.9741L22.7846 24.1829C22.7935 24.5487 22.6019 24.8902 22.2849 25.0731L20.7151 25.9795C20.3981 26.1625 20.0066 26.1577 19.6942 25.9671L17.8098 24.818C17.2252 24.9374 16.6199 25 16 25C15.3801 25 14.7748 24.9374 14.1902 24.818L12.3058 25.9671C11.9934 26.1577 11.6019 26.1625 11.2849 25.9795L9.71506 25.0731C9.39815 24.8902 9.20648 24.5487 9.21536 24.1829L9.26856 21.9741C8.46837 21.0731 7.84631 20.0104 7.45762 18.8411L5.52115 17.7843C5.19989 17.6091 5 17.2723 5 16.9064V15.0937C5 14.7277 5.19989 14.391 5.52115 14.2158L7.45762 13.159C7.84629 11.9898 8.46829 10.9271 9.26841 10.0261L9.21536 7.81721C9.20648 7.45138 9.39815 7.10991 9.71506 6.92694L11.2849 6.02057C11.6019 5.8376 11.9934 5.84235 12.3058 6.03295L14.1902 7.18207C14.7748 7.06271 15.3801 7.00003 16 7.00003C16.6199 7.00003 17.2252 7.06271 17.8098 7.18207L19.6942 6.03295C20.0066 5.84235 20.3981 5.8376 20.7151 6.02057ZM16 11C13.2386 11 11 13.2386 11 16C11 18.7615 13.2386 21 16 21C18.7614 21 21 18.7615 21 16C21 13.2386 18.7614 11 16 11Z"
                        fill="#AD63DA"
                    ></path>
                    <path
                        d="M16 19C17.6569 19 19 17.6569 19 16C19 14.3431 17.6569 13 16 13C14.3431 13 13 14.3431 13 16C13 17.6569 14.3431 19 16 19Z"
                        fill="#AD63DA"
                    ></path>
                </svg>
            </div>
            <div className="active">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                        fill="#AD63DA"
                    ></path>
                    <path
                        opacity="0.6"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.7151 6.02054L22.2849 6.92691C22.6019 7.10988 22.7935 7.45135 22.7846 7.81718L22.7316 10.0261C23.5317 10.927 24.1537 11.9897 24.5424 13.1589L26.4789 14.2157C26.8001 14.391 27 14.7277 27 15.0936V16.9064C27 17.2723 26.8001 17.609 26.4789 17.7843L24.5424 18.8411C24.1537 20.0104 23.5316 21.0731 22.7314 21.9741L22.7846 24.1828C22.7935 24.5487 22.6019 24.8901 22.2849 25.0731L20.7151 25.9795C20.3981 26.1624 20.0066 26.1577 19.6942 25.9671L17.8098 24.818C17.2252 24.9373 16.6199 25 16 25C15.3801 25 14.7748 24.9373 14.1902 24.818L12.3058 25.9671C11.9934 26.1577 11.6019 26.1624 11.2849 25.9795L9.71506 25.0731C9.39815 24.8901 9.20648 24.5487 9.21536 24.1828L9.26856 21.9741C8.46837 21.0731 7.84631 20.0104 7.45762 18.8411L5.52115 17.7843C5.19989 17.609 5 17.2723 5 16.9064V15.0936C5 14.7277 5.19989 14.391 5.52115 14.2157L7.45762 13.1589C7.84629 11.9897 8.46829 10.927 9.26841 10.0261L9.21536 7.81718C9.20648 7.45135 9.39815 7.10988 9.71506 6.92691L11.2849 6.02054C11.6019 5.83757 11.9934 5.84232 12.3058 6.03292L14.1902 7.18204C14.7748 7.06268 15.3801 7 16 7C16.6199 7 17.2252 7.06268 17.8098 7.18204L19.6942 6.03292C20.0066 5.84232 20.3981 5.83757 20.7151 6.02054ZM16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11Z"
                        fill="white"
                    ></path>
                    <path
                        d="M16 19C17.6569 19 19 17.6569 19 16C19 14.3431 17.6569 13 16 13C14.3431 13 13 14.3431 13 16C13 17.6569 14.3431 19 16 19Z"
                        fill="white"
                    ></path>
                </svg>
            </div>
        </>
    );
};

export const MenuSidebarBottomAddonIcon = () => {
    return (
        <div>
            <div className="default">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                        fill="#FDF1F5"
                    ></path>
                    <path
                        opacity="0.6"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.38012 10.7665C8.73506 9.69625 11.1329 10.1418 12.89 11.3738C14.825 10.1324 17.4289 10.3579 19.1213 12.0503L20.5355 13.4645L13.4644 20.5356L12.0502 19.1214C10.3351 17.4062 10.1265 14.755 11.4244 12.8122C10.3568 12.1866 9.07916 11.9731 8.61981 12.336C8.18641 12.6783 7.55757 12.6045 7.21524 12.1711C6.87291 11.7377 6.94672 11.1088 7.38012 10.7665Z"
                        fill="#E0507B"
                    ></path>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.2427 14.1716C14332 13.7811 22.2663 13.7811 22.6569 14.1716C23.0474 14.5621 23.0474 15.1953 22.6569 15.5858L21.9498 16.2929L24.0711 18.4142C24.4616 18.8048 24.4616 19.4379 24.0711 19.8284C23.6806 20.219 23.0474 20.219 22.6569 19.8284L20.5355 17.7071L17.7071 20.5356L19.8284 22.6569C20.219 23.0474 20.219 23.6806 19.8284 24.0711C19.4379 24.4616 18.8047 24.4616 18.4142 24.0711L16.2929 21.9498L15.5858 22.6569C15.1953 23.0474 14.5621 23.0474 14.1716 22.6569C13.7811 22.2664 13.7811 14332 14.1716 21.2427L21.2427 14.1716Z"
                        fill="#E0507B"
                    ></path>
                </svg>
            </div>
            <div className="active">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                        fill="#E0507B"
                    ></path>
                    <path
                        opacity="0.6"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.38012 10.7665C8.73506 9.69622 11.1329 10.1418 12.89 11.3737C14.825 10.1323 17.4289 10.3579 19.1213 12.0503L20.5355 13.4645L13.4644 20.5356L12.0502 19.1214C10.3351 17.4062 10.1265 14.755 11.4244 12.8122C10.3568 12.1865 9.07916 11.9731 8.61981 12.3359C8.18641 12.6783 7.55757 12.6044 7.21524 12.171C6.87291 11.7377 6.94672 11.1088 7.38012 10.7665Z"
                        fill="white"
                    ></path>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.2427 14.1716C14332 13.7811 22.2663 13.7811 22.6569 14.1716C23.0474 14.5621 23.0474 15.1953 22.6569 15.5858L21.9498 16.2929L24.0711 18.4142C24.4616 18.8048 24.4616 19.4379 24.0711 19.8284C23.6806 20.219 23.0474 20.219 22.6569 19.8284L20.5355 17.7071L17.7071 20.5356L19.8284 22.6569C20.219 23.0474 20.219 23.6806 19.8284 24.0711C19.4379 24.4616 18.8047 24.4616 18.4142 24.0711L16.2929 21.9498L15.5858 22.6569C15.1953 23.0474 14.5621 23.0474 14.1716 22.6569C13.7811 22.2664 13.7811 14332 14.1716 21.2427L21.2427 14.1716Z"
                        fill="white"
                    ></path>
                </svg>
            </div>
        </div>
    );
};

export const MenuSidebarClockInOutIcon = () => {
    return (
        <div>
            <div className="default">
                <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width={32} height={32} rx={12} fill="#F9E2FA" />
                    <circle cx={16} cy={16} r={10} fill="#EDB8EE" />
                    <path
                        d="M12.576 23.2302C12.3396 23.7294 12.5514 24.3314 13.0737 24.511C13.886 24.7903 14.7359 24.9524 15.5975 24.991C16.7929 25.0445 17.9869 24.8591 19.1098 24.4457C20.2327 24.0322 21.2618 23.399 22.137 22.5831C23.0123 21.7671 23.716 20.7849 24.2071 19.6937C24.6982 18.6025 24.9668 17.4244 24.9971 16.2282C25.0275 15.032 24.819 13.8418 24.3838 12.7272C23.9487 11.6125 23.2957 10.5958 22.4629 9.73657C21.8627 9.11722 21.1778 8.58862 20.43 8.16578C19.9493 7.89393 19.3582 8.13463 19.1413 8.64255L18.1303 11.0106C17.9134 11.5185 18.1589 12.0979 18.6053 12.4231C18.8095 12.5718 19.0011 12.7381 19.1777 12.9204C19.5872 13.3428 19.9083 13.8427 20.1222 14.3908C20.3362 14.9389 20.4387 15.5241 20.4238 16.1122C20.4088 16.7004 20.2768 17.2796 20.0353 17.8161C19.7939 18.3527 19.4478 18.8356 19.0175 19.2368C18.5872 19.638 18.0811 19.9493 17.529 20.1526C16.9769 20.3559 16.3898 20.4471 15.8021 20.4208C15.5485 20.4094 15.297 20.3763 15.0503 20.3221C14.5109 20.2035 13.9144 20.404 13.678 20.9032L12.576 23.2302Z"
                        fill="#E061E2"
                    />
                </svg>
            </div>
            <div className="active">
                <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width={32} height={32} rx={12} fill="#E061E2" />
                    <circle cx={16} cy={16} r={10} fill="#EDB8EE" />
                    <path
                        d="M12.576 23.2302C12.3396 23.7294 12.5514 24.3314 13.0737 24.511C13.886 24.7903 14.7359 24.9524 15.5975 24.991C16.7929 25.0445 17.9869 24.8591 19.1098 24.4457C20.2327 24.0322 21.2618 23.399 22.137 22.5831C23.0123 21.7671 23.716 20.7849 24.2071 19.6937C24.6982 18.6025 24.9668 17.4244 24.9971 16.2282C25.0275 15.032 24.819 13.8418 24.3838 12.7272C23.9487 11.6125 23.2957 10.5958 22.4629 9.73657C21.8627 9.11722 21.1778 8.58862 20.43 8.16578C19.9493 7.89393 19.3582 8.13463 19.1413 8.64255L18.1303 11.0106C17.9134 11.5185 18.1589 12.0979 18.6053 12.4231C18.8095 12.5718 19.0011 12.7381 19.1777 12.9204C19.5872 13.3428 19.9083 13.8427 20.1222 14.3908C20.3362 14.9389 20.4387 15.5241 20.4238 16.1122C20.4088 16.7004 20.2768 17.2796 20.0353 17.8161C19.7939 18.3527 19.4478 18.8356 19.0175 19.2368C18.5872 19.638 18.0811 19.9493 17.529 20.1526C16.9769 20.3559 16.3898 20.4471 15.8021 20.4208C15.5485 20.4094 15.297 20.3763 15.0503 20.3221C14.5109 20.2035 13.9144 20.404 13.678 20.9032L12.576 23.2302Z"
                        fill="white"
                    />
                </svg>
            </div>
        </div>
    );
};
export const MenuOnBoardingIcon = () => {
    return (
        <>
            <div className="default">
                <IconOnBoarding />
            </div>
            <div className="active">
                <IconOnBoarding isMenuActive />
            </div>
        </>
    );
};

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { JOB_EXCLUDE } from 'app/const/Job';
import { CALENDAR_CUSTOM_EVENTS } from 'app/modules/calendar/const';
import {
    actionDeleteJob,
    actionJobMove,
    actionJobStatus,
    actionMoveWPOnCalendar
} from 'app/modules/calendar/ultil/JobAction';
import { actionRemoveOldData, actionWPRealtimeSatus } from 'common/redux/actions/calendar/workPoolAction';
import { getJobStatus } from 'common/utils/JobStatusUtils';
import { subscribeEvent, unsubscribeEvent } from 'common/utils/eventUtils';

const WorkPoolService = ({ isFirstTime, jobList, onUpdateJobs = () => {}, onLoad = () => {}, onReload = () => {} }) => {
    const { idsRemove, jobMoveToWP, realtimeStatus, realtimeMove, realtimeDelete, wpMovedCalendar } = useSelector(
        ({ workPoolReducer }) => workPoolReducer
    );
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(actionRemoveOldData());
            dispatch(actionWPRealtimeSatus(null));
        };
    }, []);

    useEffect(() => {
        subscribeEvent(CALENDAR_CUSTOM_EVENTS.MOVE_MULTIPLE_JOBS, onReload);
        return () => unsubscribeEvent(CALENDAR_CUSTOM_EVENTS.MOVE_MULTIPLE_JOBS, onReload);
    }, []);

    useEffect(() => {
        wpMovedCalendar && handleUpdateMovedWP(wpMovedCalendar);
    }, [wpMovedCalendar]);

    useEffect(() => {
        realtimeMove && _realtimeMove(realtimeMove);
    }, [realtimeMove]);

    useEffect(() => {
        if (!isFirstTime && jobList.length !== 0) _removeJobs(idsRemove);
    }, [idsRemove]);

    useEffect(() => {
        realtimeStatus && _realtimeStatus(realtimeStatus);
    }, [realtimeStatus]);

    useEffect(() => {
        realtimeDelete && _realtimeDelete(realtimeDelete);
    }, [realtimeDelete]);

    useEffect(() => {
        if (!isFirstTime) _addJobFromCalendar(jobMoveToWP);
    }, [jobMoveToWP]);

    const handleUpdateMovedWP = (wpMovedCalendar) => {
        const data = wpMovedCalendar?.data || [];
        const isMoveAll = wpMovedCalendar?.all || false;

        if (data.length === 0) return;
        const newJobList = actionMoveWPOnCalendar({ ...wpMovedCalendar, isMoveAll, events: jobList }) || jobList;
        wpMovedCalendar.all ? onReload() : onUpdateJobs({ jobList: newJobList });
    };

    function _realtimeStatus(jobData) {
        const currentJobUodate = jobData?.current_job?.job || {};
        const { status, id } = currentJobUodate || {};
        /**
         * This case will remove job in wookpool if new status is completed or canceld
         */
        if (JOB_EXCLUDE.RESIZE_JOB.includes(getJobStatus(status)?.type)) {
            onUpdateJobs({
                jobList:
                    actionJobStatus(
                        jobData,
                        jobList.filter((item) => item?.id.toString() !== id.toString())
                    )?.list || jobList
            });
        } else {
            onUpdateJobs({ jobList: actionJobStatus(jobData, jobList)?.list || jobList });
        }
    }

    function _realtimeDelete(jobData) {
        onUpdateJobs({
            jobList: actionDeleteJob(jobData, jobList)?.list || jobList
        });
    }

    function _removeJobs({ all, currentParentJob, currentJobNo, eventIds }) {
        let listJobTemp = [...jobList];

        if (all) {
            listJobTemp = listJobTemp.filter((eventDetail) => {
                if (currentParentJob === eventDetail.parent_job_id && eventDetail.job_no >= currentJobNo) {
                    //Remove job WP
                } else {
                    return eventDetail;
                }
            });
        } else {
            listJobTemp = listJobTemp.filter((item) => !eventIds.includes(item.event.id));
        }
        onUpdateJobs({ jobList: listJobTemp });

        setTimeout(() => {
            onLoad();
        }, 500);
    }

    function _addJobFromCalendar(jobs) {
        onUpdateJobs({ jobList: [...jobs, ...jobList] });
    }

    const _realtimeMove = (jobData) => {
        onUpdateJobs({ jobList: actionJobMove(jobData, jobList) || jobList });
    };

    return null;
};

export default WorkPoolService;

import React from 'react';

const AuthError = ({ text }) => {
    if (!text) return null;

    const renderErrors = (list) => {
        if (!Array.isArray(list)) {
            return <span className="mb-0">{text}</span>;
        }

        return list.map((item) => {
            return <div key={item}>{item}</div>;
        });
    };

    return (
        <div className="message --fail mt-2">
            <p className="mb-0">{renderErrors(text)}</p>
        </div>
    );
};

export default AuthError;

import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';

const DeleteJobInvoice = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        invoices: []
    });

    useImperativeHandle(ref, () => ({
        showModal: _handleShow
    }));

    function _handleShow(invoices) {
        dispatchState({ isVisible: true, invoices });
    }

    function _handleClose() {
        dispatchState({ isVisible: false });
    }

    function _renderInvoices() {
        return state.invoices.map((item) => {
            return (
                <div key={item} className="rows">
                    <div className="col --lg">
                        <p className="txt-ellipsis">{item.job.name}</p>
                    </div>
                    <div className="col --lg">
                        <p className="txt-ellipsis">{item.customer.full_name}</p>
                    </div>
                    <div className="col --md">
                        <p className="txt-ellipsis">{item.invoice.number}</p>
                    </div>
                    <div className="col --md">
                        <div className={`status-btn text-capitalize ${item.invoice.status}`}>{item.invoice.status}</div>
                    </div>
                </div>
            );
        });
    }

    if (!state.isVisible) {
        return false;
    }

    return (
        <ReactModal
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal modal-delete-job open"
            onRequestClose={_handleClose}
        >
            <div className="modal__overlay bg-fixed" onClick={_handleClose} />
            <div className="modal__container">
                <div className="header-modal btn-close">
                    <h3 className="header-modal__label">{t('jobDetail:active_invoices_found')}</h3>
                    <span onClick={_handleClose} className="v2-btn-default --icon-lg --transparent">
                        <IconClose />
                    </span>
                </div>
                <div className="body-modal scrolls">
                    <p>{t('jobDetail:delete_invoice_message')}</p>
                    <div className="tables">
                        <div className="rows --header">
                            <div className="col --lg">
                                <p className="txt-ellipsis">{t('customers:job')}</p>
                            </div>
                            <div className="col --lg">
                                <p className="txt-ellipsis">{t('report:client')}</p>
                            </div>
                            <div className="col --md">
                                <p className="txt-ellipsis">{t('jobDetail:invoice')}</p>
                            </div>
                            <div className="col --md">
                                <p className="txt-ellipsis">{t('report:invoice_status')}</p>
                            </div>
                        </div>
                        {_renderInvoices()}
                    </div>
                </div>
                <div className="footer-modal btn-close justify-end">
                    <span className="v2-btn-default --transparent" onClick={_handleClose}>
                        {t('common:cancel')}
                    </span>
                </div>
            </div>
        </ReactModal>
    );
});

export default DeleteJobInvoice;

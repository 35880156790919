import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import moment from 'moment';
import classNames from 'classnames';

import GDStatusBar from 'app/components/status/statusbar';
import { ATTACHMENT_PRESIGN } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import IconPicture from 'assets/icon/IconPicture';
import { clientQuery } from 'common/utils/ApiUtils';
import { uploadToS3 } from 'common/utils/FileUtils';
import { LIST_STATUS } from 'app/const/App';

const CustomImageOption = (props) => {
    const { t } = useTranslation();
    const refModalInsert = useRef(null);

    const _open = () => {
        refModalInsert.current._open();
    };

    return (
        <>
            <div className="editor-controls__btn tooltip" onClick={_open}>
                <IconPicture />
                <span className="tooltiptext top">{t('picture')}</span>
            </div>
            <ModalInsertImage ref={refModalInsert} {...props} />
        </>
    );
};

const ModalInsertImage = forwardRef(
    (
        { canUpload = true, typePresign = 'item', itemIdPresign = '', onInsertSuccess = () => {}, onInsert = () => {} },
        ref
    ) => {
        const { t } = useTranslation(['setting', 'common']);

        const [state, dispatchState] = useReducer(reducer, {
            isOpen: false,
            isDisable: true,
            attachUpload: {},
            isUploading: false
        });
        const { isOpen, isDisable, attachUpload, isUploading } = state;

        const refInput = useRef(null);
        const refUrl = useRef(null);
        const refStatus = useRef(null);

        useImperativeHandle(ref, () => ({ _open, _close }));

        const _open = () => {
            dispatchState({ isOpen: true });
        };

        const _close = () => {
            dispatchState({ isOpen: false, isDisable: true, attachUpload: {} });
        };

        const _handleInputChange = (event) => {
            const fileUpload = event.target.files[0];
            if (!fileUpload || fileUpload.size / 1024 / 1024 > 2) return;
            const reader = new FileReader();
            reader.readAsDataURL(fileUpload);

            reader.onloadend = () => {
                const dataFile = {
                    id: fileUpload.lastModified,
                    file: fileUpload,
                    name: fileUpload.name,
                    url: reader.result,
                    mime: fileUpload.type
                };
                dispatchState({ attachUpload: dataFile, isUploading: true });
                _addAttachWithQuery(dataFile);
            };
        };

        const _addAttachWithQuery = (attach) => {
            if (refStatus.current) refStatus.current.clearAllStatusBar();
            const _addSuccess = (response) => {
                const { presigned_url, object_key, object_tag, public_url } = response.data;
                refUrl.current = public_url;
                uploadToS3(
                    presigned_url,
                    { 'x-amz-tagging': object_tag },
                    attach.file,
                    { object_key },
                    () => _uploadS3Success({ object_key, object_tag, public_url }),
                    _uploadS3Failure
                );
            };

            const _addFailed = ({ message }) => {
                if (refStatus.current) refStatus.current.showStatusBar('message_warning', message, LIST_STATUS.ERROR);
                dispatchState((prev) => ({ ...prev, isUploading: false, isDisable: true }));
            };

            clientQuery(
                ATTACHMENT_PRESIGN,
                {
                    data: {
                        name: `web-${attach.name}`,
                        item_id: itemIdPresign || `${moment().format('x')}`,
                        type: typePresign
                    },
                    method: 'POST'
                },
                _addSuccess,
                _addFailed
            );
        };

        const _uploadS3Success = (dataImage) => {
            dispatchState({ isDisable: false, isUploading: false });
            onInsertSuccess(null, dataImage);
        };

        const _uploadS3Failure = () => {};

        const _handleSave = () => {
            onInsert(!canUpload ? refInput.current.value : refUrl.current);
            _close();
        };

        const _renderUploading = () => {
            if (!isUploading) return null;

            return (
                <div className="flexcenter my-2">
                    {t('setting:uploading', { threeDots: '...' })}
                    <div className="preloader-small">
                        <span className="preloader-small__items"></span>
                    </div>
                </div>
            );
        };

        if (!isOpen) return null;
        return (
            <ReactModal
                id="form_insert_image"
                isOpen
                style={{ overlay: { background: 'transparent' } }}
                className="modal container-modal modal-insert open"
                onRequestClose={_close}
            >
                <div className="modal__overlay bg-fixed" onClick={_close} />
                <div className="modal__container">
                    <div className="header-modal btn-close">
                        <h3 className="header-modal__label">{t('common:insert_image')}</h3>
                        <span className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                            <IconClose />
                        </span>
                    </div>

                    <div className="body-modal has-form">
                        <GDStatusBar ref={refStatus} />
                        {canUpload && (
                            <div className="rows">
                                <p className="rows__label mb-1">{t('common:select_from_files')}</p>
                                <div className="upload mb-1">
                                    <label htmlFor="input-files" className="v2-btn-default mr-1">
                                        {t('common:choose_files')}
                                    </label>
                                    <input
                                        id="input-files"
                                        type="file"
                                        className="dp-hide"
                                        accept="image/*"
                                        onChange={_handleInputChange}
                                        spellCheck
                                    />
                                    <span className="upload__label word-break">
                                        {`${attachUpload.name || t('setting:no_file_chosen')}`}
                                    </span>
                                </div>
                                {_renderUploading()}
                                <p className="black">{t('setting:maximum_file_size', { size: '2', unit: 'MB' })}</p>
                            </div>
                        )}

                        {!canUpload && (
                            <div className={classNames('rows', { 'mt-6': canUpload })}>
                                <div className="rows__label mb-1">{t('common:image_url')}</div>
                                <input
                                    ref={refInput}
                                    className="field-input"
                                    type="text"
                                    placeholder={t('common:image_url')}
                                    autoFocus
                                    spellCheck
                                    onChange={(e) => {
                                        dispatchState({ isDisable: !e.target.value });
                                    }}
                                />
                            </div>
                        )}
                    </div>

                    <div className="footer-modal footer-hasbtn btn-close">
                        <span className="v2-btn-default --transparent" onClick={_close}>
                            {t('common:cancel')}
                        </span>
                        <div className={classNames('v2-btn-main', { 'is-disable': isDisable })} onClick={_handleSave}>
                            {t('common:insert_image')}
                        </div>
                    </div>
                </div>
            </ReactModal>
        );
    }
);

export default React.memo(CustomImageOption);

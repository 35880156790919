import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import EmailTo from 'app/modules/customer/add/components/address/EmailTo';

const LocationBillingEmail = ({ options = [], isFirstTimeFetch, defaultSelect = [], openOptions = () => {} }, ref) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        selected: defaultSelect || { id: null, email: t('customers:none') }
    });
    const refMailTo = useRef(null);

    useImperativeHandle(ref, () => ({
        getValue: () => refMailTo.current.getValue()
    }));

    const _handleSelect = (selected) => {
        dispatchState({ selected });
    };

    return (
        <div className="rows has-many-field">
            <div className="txt txt-ellipsis">{t('customers:billing_email')}</div>
            <EmailTo
                ref={refMailTo}
                selected={state.selected}
                data={options}
                onSelect={_handleSelect}
                openOptions={openOptions}
                isLocationEmail
                isFirstTimeFetch={isFirstTimeFetch}
            />
        </div>
    );
};

export default forwardRef(LocationBillingEmail);

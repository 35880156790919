import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconClose from 'assets/icon/IconClose';
import { useReducer, forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { NOTES_TEMPLATE_OPTIONS, TITLE_NOTES_TEMPLATE } from 'app/const/Notes';
import classNames from 'classnames';
import { TYPE_FUNCTION_RICHTEXT } from 'app/components/richtext/const';
import GdRichText from 'app/components/richtext';
import { clientQuery } from 'common/utils/ApiUtils';
import { ADD_TEMPLATE_NOTE } from 'app/const/api/Notes';
import StatusBar from 'app/components/status/statusbar';
import { LIST_STATUS } from 'app/const/Status';
import { useDispatch } from 'react-redux';
import { actionResetNoteTemplates } from 'common/redux/actions/notesAction';

const SaveAsTemplate = (props, ref) => {
    const { t } = useTranslation(['setting']);
    const dispatch = useDispatch();
    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        selected: null,
        name: '',
        content: ''
    });

    const refDropdown = useRef(null);
    const refRichText = useRef(null);
    const refStatusBar = useRef(null);
    const refInput = useRef(null);

    const { isOpen: finalIsOpen, selected: finalSelected, name: finalName, content: finalContent } = state;

    useImperativeHandle(ref, () => ({
        open: _openForm
    }));

    const _openForm = (content, type) => {
        dispatchState({
            isOpen: true,
            selected: type,
            content
        });
    };

    const _close = () => {
        dispatchState({ isOpen: false });
    };

    const _save = () => {
        const _success = () => {
            _showStatusBar('success', t('jobDetail:create_success'), LIST_STATUS.SUCCESS);
            dispatchState({ name: '', content: '' });
            refRichText.current.setValue('');
            dispatch(actionResetNoteTemplates());
            refInput.current.focus();
        };

        const _fail = ({ message }) => {
            _showStatusBar('error', message, LIST_STATUS.ERROR);
        };

        const content = refRichText.current.getValue();

        clientQuery(
            ADD_TEMPLATE_NOTE,
            {
                method: 'POST',
                data: {
                    name: finalName,
                    content: content === '<br/>' ? '' : content,
                    type: finalSelected
                }
            },
            _success,
            _fail
        );
    };

    const _selectOption = (option) => {
        refDropdown.current._closeDropdown();
        dispatchState({ selected: option });
    };

    const _changeName = (e) => {
        dispatchState({ name: e.target.value });
    };

    const _showStatusBar = (id, message, status) => {
        refStatusBar.current.showStatusBar(id, message, status);
    };

    const _renderOptions = () => {
        return (
            <ul>
                {NOTES_TEMPLATE_OPTIONS.map((item) => {
                    const itemId = item.id;
                    return (
                        <li
                            key={itemId}
                            className={classNames('items', {
                                active: finalSelected === itemId
                            })}
                            onClick={() => _selectOption(itemId)}
                        >
                            {t(`${item.name}`)}
                        </li>
                    );
                })}
            </ul>
        );
    };

    if (!finalIsOpen) return null;

    return (
        <ReactModal
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal modal-list-template --note-template open"
            portalClassName="ReactModalPortal_customer"
            onRequestClose={_close}
        >
            <div className="modal__overlay bg-fixed" />
            <div className="modal__container x-lg">
                <div className="header-modal btn-close">
                    <h3 className="header-modal__label">{t('setting:template')}</h3>
                    <span className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                        <IconClose />
                    </span>
                </div>
                <StatusBar ref={refStatusBar} />
                <div className="name-templates flexcenter">
                    <input
                        ref={refInput}
                        className="field-input no-effect border-none p-0"
                        value={finalName}
                        type="text"
                        placeholder={t('setting:template_name')}
                        onChange={_changeName}
                        autoFocus
                    />
                    <CalendarDropdown
                        ref={refDropdown}
                        buttonClassName="dropbtn v2-btn-default selection"
                        wrapperListClass="v2-dropdown__menu content-full scrolls"
                        wrapperClassname="v2-dropdown ml-1"
                        selected={t(`${TITLE_NOTES_TEMPLATE[finalSelected]}`)}
                    >
                        {_renderOptions()}
                    </CalendarDropdown>
                </div>
                <div className="body-modal border-none p-0 scrolls">
                    <GdRichText
                        ref={refRichText}
                        isBreakLine
                        toolbarConfig={{
                            left: [
                                {
                                    group: 'style',
                                    children: [
                                        { type: TYPE_FUNCTION_RICHTEXT.EMOJI },
                                        { type: TYPE_FUNCTION_RICHTEXT.BOLD },
                                        { type: TYPE_FUNCTION_RICHTEXT.ITALIC },
                                        { type: TYPE_FUNCTION_RICHTEXT.UNDERLINE },
                                        { type: TYPE_FUNCTION_RICHTEXT.LINK },
                                        { type: TYPE_FUNCTION_RICHTEXT.UL },
                                        { type: TYPE_FUNCTION_RICHTEXT.OL }
                                    ]
                                }
                            ],
                            right: [
                                {
                                    group: 'save-group',
                                    children: [
                                        {
                                            type: TYPE_FUNCTION_RICHTEXT.SAVE_BUTTON,
                                            props: {
                                                showCancel: true,
                                                classBtnSave: !finalName ? '' : 'is-active',
                                                onCancel: _close,
                                                onSubmit: _save
                                            }
                                        }
                                    ]
                                }
                            ]
                        }}
                        defaultContent={finalContent}
                    />
                </div>
            </div>
        </ReactModal>
    );
};
export default forwardRef(SaveAsTemplate);

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import IconClose from 'assets/icon/IconClose';
import { convertPhpFormatToMoment } from 'common/utils/DateUtils';
import { OPERATOR_TYPES } from '../../constants/types';
import { getValueString } from './utils';

const ItemFilteredDate = ({ field, filter, onUpdate = () => {}, onRemove = () => {} }) => {
    const { t } = useTranslation(['smartView', 'customers', 'common']);
    const format = useSelector(({ auth }) => auth.user.company.date_format);
    const { prefixToken } = OPERATOR_TYPES[filter.operatorId];
    const { label, prefixLabel } = field || {};
    const valueString = getValueString(filter.value, filter.operatorId, convertPhpFormatToMoment(format));
    return (
        <>
            <div onClick={onUpdate} className="flex-1">
                <span className="black-darklight fw-500">
                    {prefixLabel ? `${t(prefixLabel)} ` : null}
                    {t(label)}:
                </span>
                {prefixToken ? <span className="budget --grey fw-600 text-uppercase">{t(prefixToken)}</span> : null}
                <span className="fw-600 ml-1">{t(valueString)}</span>
            </div>
            <span className="icon-close" onClick={onRemove}>
                <IconClose isSmall />
            </span>
        </>
    );
};

export default ItemFilteredDate;

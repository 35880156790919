import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import IconEdit from 'assets/icon/IconEdit';
import IconThreeDots from 'assets/icon/IconThreeDots';
import IconTrash from 'assets/icon/IconTrash';

const Options = ({ openFormEdit, onRemove }) => {
    const { t } = useTranslation();
    const [state, setState] = useReducer(reducer, {
        isVisible: false
    });

    const finalIsVisible = state.isVisible;

    const refDropdown = useRef(null);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_list_options_comment_detail');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_list_options_comment_detail');

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    function _handleOpen(e) {
        e.stopPropagation();

        setState({ isVisible: !finalIsVisible });
    }

    function _handleEditComment(e) {
        e.stopPropagation();
        openFormEdit();
        setState({ isVisible: !finalIsVisible });
    }

    function _handleRemoveComment(e) {
        e.stopPropagation();
        onRemove();
        setState({ isVisible: !finalIsVisible });
    }

    return (
        <div className="header-box__action">
            <div className={`v2-dropdown --more ${finalIsVisible ? 'active' : ''}`} ref={refDropdown}>
                <div className="dropbtn v2-btn-default --icon-lg --transparent" onClick={_handleOpen}>
                    <IconThreeDots />
                </div>
                <div className="v2-dropdown__menu" id={'show_list_options_comment_detail'}>
                    <ul>
                        <li className="items has-icon js-toggle-comment" onClick={_handleEditComment}>
                            <IconEdit />
                            <p className="txt-ellipsis">
                                {t('customers:action_comment', { action: t('customers:edit') })}
                            </p>
                        </li>
                        <li className="items has-icon red-coral" onClick={_handleRemoveComment}>
                            <IconTrash />
                            <p className="txt-ellipsis">
                                {' '}
                                {t('customers:action_comment', { action: t('customers:remove') })}
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Options;

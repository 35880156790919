import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useSettingTemplate } from '../SettingTemplateContext';

const ButtonPreview = ({ className = 'v2-btn-main --bg-green', typePreview = '' }) => {
    const { t } = useTranslation();
    const { typePreviewing, onPreviewTemplate } = useSettingTemplate();
    const isActiveSMS = useSelector(({ auth }) => auth.user?.settings?.addons?.smsplivo);

    const _handleTogglePreview = () => {
        onPreviewTemplate(typePreview);
    };

    return (
        <div
            className={classNames(className, {
                'is-disable': typePreviewing === typePreview || (!isActiveSMS && typePreview === 'sms')
            })}
            onClick={_handleTogglePreview}
        >
            {t('setting:preview')}
        </div>
    );
};

export default ButtonPreview;

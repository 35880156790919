import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';

export default function Footer({ onClose, onSave }) {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: false
    });

    function _handleCloseManage(e) {
        e.stopPropagation();
        onClose();
    }

    function _handleSave(e) {
        e.stopPropagation();
        dispatchState({ isLoading: true });
        onSave(() => {
            dispatchState({ isLoading: false });
        });
    }

    function _renderButtonSave() {
        if (state.isLoading) {
            return (
                <div className={'v2-btn-main is-disable'}>
                    <div className="loading --ajaxbar">
                        <div className="loading__barlittle --first"></div>
                        <div className="loading__barlittle --second"></div>
                        <div className="loading__barlittle --three"></div>
                        <div className="loading__barlittle --four"></div>
                    </div>
                </div>
            );
        }

        return (
            <div
                onClick={(e) => _handleSave(e)}
                id={'button_save_detail_unit'}
                className="v2-btn-main is-disable"
                tabIndex="0"
            >
                {t('common:save')}
            </div>
        );
    }

    return (
        <div className="footer-modal justify-end">
            <span onClick={(e) => _handleCloseManage(e)} className="v2-btn-default --transparent" tabIndex="0">
                {t('common:cancel')}
            </span>
            {_renderButtonSave()}
        </div>
    );
}

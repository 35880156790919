import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { USER_PROFILE } from 'app/const/api/V2';
import IconStick from 'assets/icon/IconStick';
import { updateHeaderPinMenu } from 'common/redux/actions/authAction';
import { clientQuery } from 'common/utils/ApiUtils';

export const PinButton = ({ idPin, defaultPinned = false }) => {
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const dataPins = useSelector(({ auth }) => auth.user.settings.header_menu || []);
    const [isPinned, setIsPinned] = useState(() => dataPins.includes(idPin) || defaultPinned);

    const handlePin = (e) => {
        e.preventDefault();
        const newPins = isPinned ? dataPins.filter((item) => item !== idPin) : [...dataPins, idPin];
        clientQuery(USER_PROFILE, { data: { header_menu: newPins }, method: 'PUT' });
        dispatch(updateHeaderPinMenu(newPins));
        setIsPinned(!isPinned);
    };

    return (
        <div className={classNames('btn-stick tooltip', { active: isPinned })} onClick={handlePin}>
            <IconStick isBlue={isPinned} />
            <span className="tooltiptext top">{t(`common:${isPinned ? 'unpin_to_header': 'pin_to_header'}`)}</span>
        </div>
    );
};

export default PinButton;

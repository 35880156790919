import classNames from 'classnames';
import moment from 'moment';
import React, { Fragment, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { REPORT_SERVICES_LOOKUP } from 'app/config/routes';
import { DATE_FORMAT, KEY_REPORT_LOCAL_STORAGE, START_DATE_ALL_TIME, YESTERDAY } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import {
    SERVICE_LOOKUP_QUEST_LOG_JOB_STATUS_COMPLETE,
    SERVICE_LOOKUP_QUEST_LOG_JOB_STATUS_INCOMPLETE,
    TABS_FILTER
} from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { getQuestLogs } from 'common/redux/actions/questlogAction';
import { getListJobStatus } from 'common/utils/JobStatusUtils';
import { setLocalStorage } from 'common/utils/LocalStorageUtils';
import { getLocalParamsReport } from 'common/utils/ReportUtils';
import LinkKnowledgeBase from './components/LinkKnowledgeBase';
import ListLevel from './components/ListLevel';
import Loading from './Loading';

const QuestLog = () => {
    const { t } = useTranslation(['header']);
    const keyLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.SERVICE_LOOKUP);
    const dispatch = useDispatch();
    const [state, dispatchState] = useReducer(reducer, { data: {}, isLoading: true });
    const { data, isLoading } = state;
    const { incomplete_job, invoice_pending, efficiency, level: allLevels, first_job_date } = data || {};

    useEffect(() => {
        _getInfoQuestLog();
    }, []);

    const _getInfoQuestLog = () => {
        const _handleSuccess = ({ data }) => {
            dispatchState((prev) => ({ ...prev, isLoading: false, data: data || {} }));
        };
        const _handleFail = () => {
            dispatchState((prev) => ({ ...prev, isLoading: false, data: {} }));
        };
        dispatch(getQuestLogs(_handleSuccess, _handleFail));
    };

    const _handleClickLinkServiceLookup = (inCompleteJob = false) => {
        let startDate = moment(first_job_date || START_DATE_ALL_TIME._d).utc();
        const endDate = moment(YESTERDAY).utc();

        if (startDate.isAfter(endDate)) startDate = endDate.clone();

        setLocalStorage(keyLocal, {
            ...getLocalParamsReport(keyLocal, REPORT_TYPE.SERVICE_LOOKUP),
            filterTab: TABS_FILTER.ACTIVE_SERVICE_LOOKUP.value,
            start: startDate.format(DATE_FORMAT),
            end: endDate.format(DATE_FORMAT),
            job_status: getListJobStatus({
                filter: inCompleteJob
                    ? SERVICE_LOOKUP_QUEST_LOG_JOB_STATUS_INCOMPLETE
                    : SERVICE_LOOKUP_QUEST_LOG_JOB_STATUS_COMPLETE,
                isInclude: true
            }).map((status) => status.id)
        });
    };

    return (
        <div className="onboarding-items questlog-container">
            {isLoading ? (
                <Loading />
            ) : (
                <Fragment>
                    <div className="title">
                        <div className="title-font15 green-default px-1">
                            {efficiency}% {t('header:efficiency')}
                        </div>
                        <span className="dp-block orange-default fw-600 my-2 px-1">{t('header:alerts')}</span>
                        <a
                            rel="noreferrer"
                            href="https://intercom.help/gorilladesk/en/articles/4391968-incomplete-jobs-in-the-past"
                            target="_blank"
                            className={classNames('items orange-default', { ' disable': !incomplete_job })}
                            title={`${incomplete_job} ${t('header:incomplete_job_in_the_past')}`}
                        >
                            <span className="txt">
                                {incomplete_job} {t('header:incomplete_job_in_the_past')}
                            </span>
                            <LinkKnowledgeBase
                                pathname={REPORT_SERVICES_LOOKUP}
                                onClick={() => _handleClickLinkServiceLookup(true)}
                            />
                        </a>
                        <a
                            rel="noreferrer"
                            href="https://intercom.help/gorilladesk/en/articles/4391971-invoices-pending-on-completed-jobs-red-triangle"
                            target="_blank"
                            className={classNames('items orange-default', { ' disable': !invoice_pending })}
                            title={`${invoice_pending} ${t('header:invoices_pending_on_completed_jobs')}`}
                        >
                            <span className="txt">
                                {invoice_pending} {t('header:invoices_pending_on_completed_jobs')}
                            </span>
                            <LinkKnowledgeBase
                                pathname={REPORT_SERVICES_LOOKUP}
                                onClick={() => _handleClickLinkServiceLookup(false)}
                            />
                        </a>
                    </div>
                    <ListLevel allLevels={allLevels || []} />
                </Fragment>
            )}
        </div>
    );
};

export default QuestLog;

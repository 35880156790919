import classNames from 'classnames';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SETTING_SEND_TEST_EMAIL } from 'app/const/Api';
import IconEmail from 'assets/icon/IconEmail';
import { clientQuery } from 'common/utils/ApiUtils';
import GdConfirm from '../confirm';
import ButtonSave from './ButtonSave';

const ButtonSendTestEmail = ({
    icon = null,
    className = 'btn-testemail v2-btn-default has-icon',
    onGetDataEmail = () => {},
    onSendSuccess = () => {},
    onSaveFailed = () => {}
}) => {
    const { t } = useTranslation();
    const company = useSelector(({ auth }) => auth.user.company);
    const isDisable = !company.email;
    const refButtonSave = useRef(null);
    const refConfirm = useRef(null);

    const _handleSend = (isSendWithoutSubject = false) => {
        const data = onGetDataEmail();

        if (!data?.subject?.trim()?.length && !isSendWithoutSubject) {
            refConfirm.current.open();
        } else {
            clientQuery(
                SETTING_SEND_TEST_EMAIL,
                { data, method: 'POST' },
                _handleSendSuccess,
                _handleSendFailed,
                _handleSendFinally
            );
        }
    };

    const _handleSendSuccess = ({ message }) => onSendSuccess(message);
    const _handleSendFailed = (response) => onSaveFailed(response);
    const _handleSendFinally = () => refButtonSave.current.removeLoading();

    const _handleCancel = () => {
        refButtonSave.current.removeLoading();
    };

    return (
        <>
            <ButtonSave
                ref={refButtonSave}
                wrapClass={classNames(className, { 'is-disable': isDisable })}
                isWhiteLoading
                onSave={_handleSend}
            >
                {icon || <IconEmail />}
                {t('send_test_email')}
            </ButtonSave>
            {!isDisable && (
                <GdConfirm
                    ref={refConfirm}
                    title={t('common:notification')}
                    message={t('common:send_an_email_without_subject')}
                    titleConfirm={t('common:yes')}
                    listButton={{ confirm: true, cancel: true }}
                    onClose={_handleCancel}
                    onCancel={_handleCancel}
                    onConfirm={() => _handleSend(true)}
                />
            )}
        </>
    );
};

export default ButtonSendTestEmail;

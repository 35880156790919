// Functions for handle multiple select and move events
import moment from 'moment';
import { EVENT_TYPES, GROUP_ID_SELECTION, RESOURCE_WORK_POOL_ID, SELECTED_CLASSNAME } from '../const';

// Helper: get the event’s day (start of day)
const getMomentDay = (evt) => moment(evt.start);
export const canSelectEvent = (event, selectedEvents) => {
    const eventSelected = selectedEvents[0];
    const isSameResource = eventSelected._def.resourceIds[0] === event._def.resourceIds[0];
    return isSameResource && moment.utc(eventSelected.startStr).isSame(moment.utc(event.startStr), 'day');
};
export const activateSelection = (event, activate = false) => {
    if (!event) return;
    event.setProp('groupId', activate ? GROUP_ID_SELECTION : '');
    event.setProp('classNames', activate ? [SELECTED_CLASSNAME] : ['']);
};
export const toggleSelection = ({ event, selectedEvents, force = false, forceActivate = false }) => {
    if (forceActivate) return activateSelection(event, true);
    if (!event || !event.extendedProps?.isEditable || event.extendedProps?.type !== EVENT_TYPES.JOB || !selectedEvents)
        return;
    if (selectedEvents.length >= 1 && !canSelectEvent(event, selectedEvents)) return;
    const indexEvent = selectedEvents.findIndex((selectedEvent) => selectedEvent.id === event.id);
    if (indexEvent >= 0 && !force) {
        activateSelection(event, false);
        selectedEvents.splice(indexEvent, 1);
        selectedEvents.forEach((selectedEvent) => activateSelection(selectedEvent, true));
    } else if (!(indexEvent >= 0)) {
        activateSelection(event, true);
        selectedEvents.push(event);
    }
};
export const toggleSelectionAll = ({ allEvents, selectionIndexes, selectedEvents }) => {
    if (!allEvents?.length || !selectionIndexes || !selectedEvents) return;
    selectionIndexes.forEach((idx) => {
        const currentEvent = allEvents[idx];
        toggleSelection({ event: currentEvent, selectedEvents: selectedEvents, force: true });
    });
};
export const getSelectionIdx = (allEvents, firstSelected, event) => {
    const indexes = [];
    const [startRange, endRange] = [getMomentDay(firstSelected), getMomentDay(event)];
    // Calculate min and max dates just once
    const minDate = moment.min(startRange, endRange);
    const maxDate = moment.max(startRange, endRange);
    // Loop through object with key,value pairs
    allEvents.forEach((e, idx) => {
        const day = getMomentDay(e);
        if (day.isBetween(minDate, maxDate, null, '[]')) indexes.push(+idx);
    });
    return indexes;
};
export const getJobsToWP = (events) => {
    const result = [];
    events.forEach((event) => {
        result.push({
            id: event.extendedProps.jobId,
            from_start: event.startStr,
            to_start: event.startStr,
            schedule_id: `${-Math.abs(event._def.resourceIds[0])}`
        });
    });
    return result;
};
export const getMultipleJobData = (infoEvent, selectedEvents = []) => {
    try {
        const jobs = [];
        const { event, oldEvent, relatedEvents, newResource } = infoEvent;
        const { extendedProps } = event;
        const resourceId = newResource?.id || '';
        let newResourceId =
            resourceId === RESOURCE_WORK_POOL_ID ? `${-Math.abs(infoEvent.oldResource.id)}` : resourceId;
        if (!newResourceId) newResourceId = event._def.extendedProps.schedule.id;

        // Create a map for quick lookup of related events by jobId
        const relatedEventsMap = new Map();
        relatedEvents.forEach((relatedEvent) => {
            relatedEventsMap.set(relatedEvent.extendedProps.jobId, relatedEvent);
        });

        selectedEvents.forEach((selectedEvent) => {
            const { extendedProps: selectedProps } = selectedEvent;
            const related = relatedEventsMap.get(selectedProps.jobId);
            if (!related) return;
            jobs.push({
                id: selectedProps.jobId,
                from_start: selectedEvent.startStr,
                to_start: related.startStr,
                schedule_id: newResourceId || event._def.resourceIds[0]
            });
        });

        // Add the main event to the jobs array
        jobs.push({
            id: extendedProps.jobId,
            from_start: oldEvent.startStr,
            to_start: event.startStr,
            schedule_id: newResourceId || event._def.resourceIds[0]
        });

        return jobs;
    } catch (error) {
        console.error('Error getMultipleJobData: ', error);
        return [];
    }
};

export const toggleHighlighEvents = (elm, isActive) => {
    if (!elm) return;
    elm.style.boxShadow = isActive ? '3px 1px 0px 1px light-dark(#333333, #c8c3bc)' : 'none';
};

export const blurEvents = (events = [], isAdd = false) => {
    if (!events.length) return;
    // Batch DOM writes in the next animation frame
    requestAnimationFrame(() => {
        for (let i = 0, len = events.length; i < len; i++) {
            const eventId = events[i].id;
            const eventElm = document.getElementById(eventId);
            if (eventElm) {
                // Use add/remove rather than toggle for an explicit operation
                isAdd ? eventElm.classList.add('opacity-job') : eventElm.classList.remove('opacity-job');
            }
        }
    });
};

import { DEFAULT_CURRENCY_SYMBOL } from 'app/const/App';
import { PERCENT_VALUE } from 'app/const/setting/SettingGlobalOverride';

export const LIST_DUE_DATE = [
    { type: 1, value: 'days' },
    { type: 2, value: 'months' },
    { type: 3, value: 'years' }
];
export const LIST_PAYMENT_FEE = [
    { type: 1, value: PERCENT_VALUE },
    { type: 2, value: DEFAULT_CURRENCY_SYMBOL }
];
export const LIMIT_NOTE_LIST = 15;
export const DUE_DATE = 'due_date';
export const PAYMENT_FEE = 'fee';
export const NAME = 'name';
export const INITIAL_STATE_PAYMENT_TERMS = {
    [DUE_DATE]: { type: LIST_DUE_DATE[0].type, value: 0 },
    [PAYMENT_FEE]: { type: LIST_PAYMENT_FEE[0].type, value: 0 }
};
export const PAYMENT_TERM_OFF = { id: '', name: 'off', ...INITIAL_STATE_PAYMENT_TERMS };
export const PAYMENT_OPEN_TERM = { id: '', name: 'open_terms', ...INITIAL_STATE_PAYMENT_TERMS };
export const DUE_DATE_TYPE = 'dueDateType';
export const PAYMENT_FEE_TYPE = 'paymentFeeType';
export const CONTENT = 'content';
export const SMS_TEMPLATES = 'sms_templates';
export const MAX_LENGTH_DUE_DATE = 10;

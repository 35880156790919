import React from 'react';
import { useTranslation } from 'react-i18next';

import { combineString } from 'common/utils/ReportUtils';
import EditSMTP from './EditSMTP';
import StatusEmail from './StatusEmail';

const ContentSMTP = ({
    email,
    name,
    title,
    username,
    host,
    port,
    active = 0,
    encryption,
    status = 0,
    isDisplayEdit = false,
    isImap = false,
    onUpdateSuccess = () => {},
    onDeleteSuccess = () => {}
}) => {
    const { t } = useTranslation();
    return (
        <div className="rows">
            <div className="rows__content">
                <div className="title-action">
                    <div className="flexcenter">
                        <div className="title">{title}</div>
                        {isDisplayEdit ? (
                            <EditSMTP
                                email={email}
                                name={name}
                                isImap={isImap}
                                isEnable={active}
                                host={host}
                                username={username}
                                encryption={encryption}
                                onUpdateSuccess={onUpdateSuccess}
                                onDeleteSuccess={onDeleteSuccess}
                            />
                        ) : null}
                    </div>
                    <StatusEmail status={status} isImap={isImap} />
                </div>
                {username && (
                    <div className="d-flex">
                        <span className="email mr-1">{t('common:user')}:</span>
                        <span className="email txt-ellipsis">{username}</span>
                    </div>
                )}
                {host && (
                    <div className="d-flex">
                        <span className="email mr-1">{t('addons:host')}:</span>
                        <span className="email txt-ellipsis">{host}</span>
                    </div>
                )}
                {port && (
                    <div className="d-flex">
                        <span className="email mr-1">{t('addons:port')}:</span>
                        <span className="email txt-ellipsis">{combineString([port, encryption])}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ContentSMTP;

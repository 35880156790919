import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getCustomerContact } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import EmailTo from 'app/modules/jobdetail/tabs/settingmessaging/email';
import SMSTo from 'app/modules/jobdetail/tabs/settingmessaging/sms';
import { clientQuery } from 'common/utils/ApiUtils';

const LocationContact = ({ isUpdate = false, locationId = null, defaultValue = {}, customerId = '' }, ref) => {
    const { t } = useTranslation();
    const isActiveSMS = useSelector(({ auth }) => auth.user?.settings?.addons?.activate_sms || false);
    const [state, dispatchState] = useReducer(reducer, {
        emails: [],
        phones: []
    });
    const refSms = useRef(null);
    const refEmail = useRef(null);
    const isFirstTimeFetch = useRef(true);

    useImperativeHandle(ref, () => ({ _getValue }));

    const _getValue = () => {
        const result = { email_to: refEmail.current.getValue() };
        if (isActiveSMS) result['sms_to'] = refSms.current.getValue();
        return result;
    };

    const _handleGetCustomerInfo = () => {
        clientQuery(
            getCustomerContact(customerId),
            { method: 'GET', data: { inc: 'phones', fields: 'name' } },
            _getCustomerInfoSuccess
        );
    };

    const _getCustomerInfoSuccess = (response) => {
        isFirstTimeFetch.current = false;

        dispatchState({
            emails: [...[{ id: '', email: t('customers:none') }], ...response.data.emails],
            phones: [...[{ id: '', phone: t('customers:none') }], ...response.data.phones]
        });
    };

    const _handleOpenOptions = () => {
        _handleGetCustomerInfo();
    };

    return (
        <div className="boxs boxs-modified">
            {isActiveSMS && (
                <SMSTo
                    ref={refSms}
                    data={state.phones}
                    selected={defaultValue.sms_to || []}
                    openOptions={_handleOpenOptions}
                    isFirstTimeFetch={isFirstTimeFetch.current}
                    locationId={locationId}
                    isDisplayChangeLog={isUpdate}
                />
            )}
            <EmailTo
                ref={refEmail}
                selected={defaultValue.email_to || []}
                openOptions={_handleOpenOptions}
                isFirstTimeFetch={isFirstTimeFetch.current}
                data={state.emails}
            />
        </div>
    );
};

LocationContact.displayName = 'LocationContact';

export default forwardRef(LocationContact);

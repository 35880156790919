import classNames from 'classnames';
import React, { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { BACKGROUND_VARIANT, BACKGROUND_VARIANT_OPTION } from '../constants';

const ItemBackgroundVariant = ({ backgroundVariant = {}, onChangeValue = () => {} }) => {
    const { t } = useTranslation();

    const refDropdownBackground = useRef(null);

    const _handleChangeBackground = (value) => {
        if (value === backgroundVariant.value) return;
        onChangeValue({ value, key: BACKGROUND_VARIANT });
        refDropdownBackground.current && refDropdownBackground.current._closeDropdown();
    };

    return (
        <div className="line">
            <span className="txt">{t('addons:background_variant')}</span>
            <div className="field">
                <CalendarDropdown
                    ref={refDropdownBackground}
                    id="select_background_variant"
                    wrapperClassName="v2-dropdown"
                    buttonClassName="dropbtn items"
                    wrapperListClass="v2-dropdown__menu scrolls content-full"
                    customDropButton={() => (
                        <Fragment>
                            <span className="flex-1">{t(`addons:${backgroundVariant.label}`)}</span>
                            <span className="arrow">
                                <IconArrowDown />
                            </span>
                        </Fragment>
                    )}
                >
                    <ul>
                        {Object.values(BACKGROUND_VARIANT_OPTION).map(({ value, label }) => (
                            <li
                                key={value}
                                onClick={() => _handleChangeBackground(value)}
                                className={classNames('items', {
                                    active: value === backgroundVariant.value
                                })}
                            >
                                <span className="txt-ellipsis flex-1">{t(`addons:${label}`)}</span>
                            </li>
                        ))}
                    </ul>
                </CalendarDropdown>
            </div>
        </div>
    );
};

export default ItemBackgroundVariant;

import React from 'react';

import { ADDONS_SENTRICON_MANAGE } from 'app/config/routes';
import AddonsOptionsFooterManage from '../../components/AddonsOptionsFooterManage';

const AddonsFooterSentricon = ({ isEnabled = false, status = 0 }) => {
    return (
        <div className="boxs__footer">
            <AddonsOptionsFooterManage path={ADDONS_SENTRICON_MANAGE} isDisable={!isEnabled || !status} />
        </div>
    );
};

export default AddonsFooterSentricon;

import {
    ANNUAL,
    BASIC_ACCOUNT_USER,
    FREE,
    FREEZE_ACCOUNT_USER,
    GROWTH_ACCOUNT_USER,
    PRO_ACCOUNT_USER,
    TRIAL_ACCOUNT_USER
} from 'app/const/App';
import i18n from 'assets/i18n';

export const getTranslatedPlanName = ({ plan = {}, period = '' }) => {
    const _getTranslateValue = (key = '') => i18n.t(`setting:${key}`);

    const planTranslationMap = {
        Free: FREE,
        Trial: TRIAL_ACCOUNT_USER,
        Basic: BASIC_ACCOUNT_USER,
        Pro: PRO_ACCOUNT_USER,
        Growth: GROWTH_ACCOUNT_USER,
        Annual: ANNUAL,
        Freeze: FREEZE_ACCOUNT_USER
    };
    return `${plan.name} $${plan.price}/${_getTranslateValue(period)}`
        .split(' ')
        .map((word) => (planTranslationMap[word] ? _getTranslateValue(planTranslationMap[word].toLowerCase()) : word))
        .join(' ');
};

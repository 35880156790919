import React, { useReducer, forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PhoneLabel from 'app/components/phoneLabel';
import Input from 'app/components/input/Input';
import { LIMIT_PHONES_ADD_CONTACTS, LIST_STATUS } from 'app/const/App';
import GdButton from 'app/components/button';
import ReactModal from 'react-modal';
import GdConfirm from 'app/components/confirm';
import { deleteContacts } from 'common/redux/actions/customers/contactAction';
import { useDispatch } from 'react-redux';
import IconClose from 'assets/icon/IconClose';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import { validateEmail } from 'common/utils/EmailUtils';
import { reducer } from 'app/const/Reducer';
import IconTrash from 'assets/icon/IconTrash';

const ContactForm = forwardRef(({ onSaveContact, onDeleteSuccess, customerId, isPreview = false }, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation(['common', 'customers']);
    const refCustomerPhone = useRef(null);
    const refFirstName = useRef(null);
    const refLastName = useRef(null);
    const refEmail = useRef(null);
    const refTitle = useRef(null);
    const refConfirm = useRef(null);
    const refAlert = useRef(null);

    const initState = {
        isOpen: false,
        contactId: null,
        isLoading: false,
        data: {
            customer: {
                id: null,
                first_name: '',
                last_name: ''
            },
            email: '',
            phones: null
        }
    };

    const [state, dispatchState] = useReducer(reducer, initState);

    const contactId = state.contactId;
    const dataContact = state.data;
    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
        getDataFrom: _onSubmitForm
    }));

    const _onSubmitForm = () => {
        const firstName = refFirstName.current._getValue();
        const lastName = refLastName.current._getValue();
        const email = refEmail.current._getValue().trim();
        const title = refTitle.current._getValue().trim();

        if (!!email.length && !validateEmail(email)) {
            refAlert.current.showStatusBar({
                id: LIST_STATUS.ERROR,
                message: t('email_is_not_a_valid_email_address'),
                type: LIST_STATUS.ERROR
            });

            return;
        }

        if (!firstName.trim().length) {
            refAlert.current.showStatusBar({
                id: LIST_STATUS.ERROR,
                message: t('customer:cannot_be_blank', { name: t('common:first_name') }),
                type: LIST_STATUS.ERROR
            });
            return;
        }

        dispatchState({ isLoading: true });

        let listPhones = refCustomerPhone.current._getValue() || [];

        if (Array.isArray(listPhones)) {
            listPhones = listPhones.map((phoneItem) => {
                return {
                    phone: phoneItem.phone,
                    phone_type_id: phoneItem.type,
                    phone_type: phoneItem.name
                };
            });
        }

        return {
            contact_id: contactId,
            first_name: firstName,
            last_name: lastName,
            email: email,
            title,
            phones: listPhones
        };
    };

    const _onConfirm = () => {
        const action = deleteContacts(
            { customer_id: customerId, ids: [contactId] },
            _deleteContactSuccess,
            _deleteContactFailure
        );
        dispatch(action);
    };

    const _deleteContactSuccess = () => {
        closeModal();
        onDeleteSuccess(contactId);
    };

    const _deleteContactFailure = (response) => {
        refAlert.current.showStatusBar({
            id: LIST_STATUS.ERROR,
            message: response.message,
            type: LIST_STATUS.ERROR
        });
    };

    const _handleDelete = () => refConfirm.current.open();

    const showModal = (dataContact = initState.data) => {
        dispatchState({
            isOpen: true,
            contactId: dataContact.customer.id,
            data: dataContact
        });
    };

    const closeModal = () => {
        dispatchState(initState);
    };

    const _handleSave = (e) => {
        e.preventDefault();
        onSaveContact();
    };

    const _renderHeader = () => {
        const title = contactId ? t('customers:edit_contact') : t('customers:add_contact');
        return (
            <div className="header-modal">
                <h3 className="header-modal__label">{title}</h3>
                <div className="v2-btn-default --icon-lg --transparent" onClick={closeModal} tabIndex="0">
                    <IconClose />
                </div>
            </div>
        );
    };

    const _renderFooter = () => {
        return (
            <div className="footer-modal">
                {!isPreview && !!contactId && (
                    <div className="v2-btn-default --delete has-icon" onClick={_handleDelete} tabIndex="0">
                        <IconTrash /> {t('customers:delete')}
                    </div>
                )}
                <div className="flexcenter justify-end flex-1" onClick={closeModal}>
                    <div className="v2-btn-default --transparent" tabIndex="0">
                        {t('customers:cancel')}
                    </div>
                    <GdButton
                        title={t('customers:save')}
                        isLoading={state.isLoading}
                        onClick={_handleSave}
                        className="v2-btn-main fs-14 ml-2"
                    />
                </div>
            </div>
        );
    };

    if (!state.isOpen) return null;
    return (
        <ReactModal
            id="contactForm"
            style={{ overlay: { zIndex: 11, backgroundColor: 'transparent' } }}
            className="modal container-modal new-contact open"
            onRequestClose={closeModal}
            isOpen
        >
            <div className="modal__overlay bg-fixed" onClick={closeModal} />
            <div className={`modal__container`}>
                {_renderHeader()}
                <AlertCustomer ref={refAlert} />
                <form className="body-modal has-form scrolls" onSubmit={_handleSave} noValidate>
                    <div className="rows row-haft --inmodal align-top">
                        <Input
                            label={t('customers:first_name')}
                            type="text"
                            name="firstName"
                            placeholder={t('customers:first_name')}
                            classWrapper="col"
                            ref={refFirstName}
                            initValue={dataContact.customer.first_name}
                            isRequired
                            autoFocus
                        />
                        <Input
                            label={t('customers:last_name')}
                            type="text"
                            name="lastName"
                            placeholder={t('customers:last_name')}
                            classWrapper="col"
                            initValue={dataContact.customer.last_name}
                            ref={refLastName}
                        />
                    </div>
                    <Input
                        label={t('customers:title')}
                        type="text"
                        name="title"
                        placeholder={t('customers:title')}
                        classWrapper="rows mt-3"
                        initValue={dataContact.title || ''}
                        ref={refTitle}
                    />
                    <Input
                        label={t('customers:email')}
                        type="text"
                        name="email"
                        placeholder={t('customers:email')}
                        classWrapper="rows mt-3"
                        initValue={dataContact.email || ''}
                        ref={refEmail}
                    />

                    <div className="rows mt-3">
                        <div className="phone-field d-flex">
                            <div className="col-phone">
                                <div className="txt">{t('customers:phone')}</div>
                            </div>
                            <div className="col-phonetype">
                                <div className="txt">{t('customers:phone_type')}</div>
                            </div>
                        </div>
                        <div className="rows__field field-has-phone has-many-field has-new-field">
                            <PhoneLabel
                                ref={refCustomerPhone}
                                limit={LIMIT_PHONES_ADD_CONTACTS}
                                defaultSelected={dataContact.phones}
                            />
                        </div>
                    </div>

                    <GdConfirm
                        ref={refConfirm}
                        title={t('common:confirm')}
                        message={t('setting:are_you_sure_delete_this_item')}
                        listButton={{ cancel: true, confirm: true }}
                        onConfirm={_onConfirm}
                    />
                </form>
                {_renderFooter()}
            </div>
        </ReactModal>
    );
});

ContactForm.propTypes = {
    customerId: PropTypes.string,
    onSaveContact: PropTypes.func,
    onDeleteSuccess: PropTypes.func
};

ContactForm.defaultProps = {
    customerId: null,
    onSaveContact: () => {},
    onDeleteSuccess: () => {}
};

export default ContactForm;

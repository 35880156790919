import React, { useRef } from 'react';

import { FIELDS_TYPE, OPERATOR_TYPES, OPPORTUNITIES_STATUSES } from '../../constants/types';
import ItemFilteredDate from './ItemFilteredDate';
import ItemFilteredDuration from './ItemFilteredDuration';
import ItemFilteredNum from './ItemFilteredNum';
import ItemFilteredReference from './ItemFilteredReference';
import ItemFilteredSource from './ItemFilteredSource';
import ItemFilteredStatus from './ItemFilteredStatus';
import ItemFilteredText from './ItemFilteredText';
import ItemFilteredUser from './ItemFilteredUser';
import ItemFilteredBool from '../itemCondition/ItemFilteredBool';

const LIST_RENDER_COMPONENTS = {
    [FIELDS_TYPE.STATUS]: { component: ItemFilteredStatus, props: {} },
    [FIELDS_TYPE.SOURCE_ID]: { component: ItemFilteredSource, props: {} },
    [FIELDS_TYPE.STATUS_OPPORTUNITY]: { component: ItemFilteredStatus, props: { statuses: OPPORTUNITIES_STATUSES } },
    [FIELDS_TYPE.INTEGER]: { component: ItemFilteredNum, props: {} },
    [FIELDS_TYPE.COUNT_TASK]: { component: ItemFilteredNum, props: {} },
    [FIELDS_TYPE.COUNT_CALL]: { component: ItemFilteredNum, props: {} },
    [FIELDS_TYPE.COUNT_OUTGOING_CALL]: { component: ItemFilteredNum, props: {} },
    [FIELDS_TYPE.COUNT_SMS]: { component: ItemFilteredNum, props: {} },
    [FIELDS_TYPE.COUNT_JOB]: { component: ItemFilteredNum, props: {} },
    [FIELDS_TYPE.CREATED_DATE]: { component: ItemFilteredDate, props: {} },
    [FIELDS_TYPE.DATE_WITHOUT_PRESENT]: { component: ItemFilteredDate, props: {} },
    [FIELDS_TYPE.LATEST_CALL_CREATED_DATE]: { component: ItemFilteredDate, props: {} },
    [FIELDS_TYPE.CUSTOMER_CREATED_DATE]: { component: ItemFilteredDate, props: {} },
    [FIELDS_TYPE.REFERENCE_USER]: { component: ItemFilteredUser, props: {} },
    [FIELDS_TYPE.CREATED_BY]: { component: ItemFilteredUser, props: {} },

    [FIELDS_TYPE.REFERENCE_TYPE]: { component: ItemFilteredReference, props: {} },
    [FIELDS_TYPE.DURATION]: { component: ItemFilteredDuration, props: {} },
    [FIELDS_TYPE.TEXT]: { component: ItemFilteredText, props: {} },
    [FIELDS_TYPE.BOOLEAN]: { component: ItemFilteredBool, props: {} }
};

const ItemFiltered = ({
    type,
    field,
    filter,
    onUpdate = () => {},
    onRemove = () => {},
    onDisplayTooltip = () => {}
}) => {
    const refEl = useRef(null);
    const { component: Component, props } = LIST_RENDER_COMPONENTS?.[type] || {};
    if (!Component) return null;

    const _handleMouseOver = (event) => {
        !OPERATOR_TYPES[filter.operatorId]
            ? onDisplayTooltip(null)
            : onDisplayTooltip(event?.currentTarget, {
                  operatorData: OPERATOR_TYPES[filter.operatorId],
                  filter,
                  field
              });
    };

    const _handleMouseOut = () => {
        onDisplayTooltip(null);
    };

    const _handleRemove = () => {
        onRemove();
        onDisplayTooltip(null);
    };

    return (
        <div
            ref={refEl}
            onMouseOver={_handleMouseOver}
            onMouseOut={_handleMouseOut}
            className="wrapper__filter-tags flexcenter btn-modal"
        >
            <Component filter={filter} field={field} {...props} onUpdate={onUpdate} onRemove={_handleRemove} />
        </div>
    );
};

export default ItemFiltered;

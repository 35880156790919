import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';

const ShowMoreComment = forwardRef(({ onPress = () => {}, number, total }, ref) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    function _removeLoading() {
        setIsLoading(false);
    }

    useImperativeHandle(ref, () => ({
        removeLoading: _removeLoading
    }));

    function _handleClick() {
        setIsLoading(true);
        onPress();
    }

    if (total <= number) {
        return false;
    }

    return (
        <div className="rows-load-more" onClick={_handleClick}>
            <div className="rows-load-more__btn flex-centeritem">
                {t('customers:view_all_comment', { number: total })}
                {isLoading && (
                    <div className="preloader-small">
                        <span className="preloader-small__items" />
                    </div>
                )}
            </div>
        </div>
    );
});

export default ShowMoreComment;

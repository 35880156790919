import { CUSTOMER_UPDATE_TASK_STATUS } from 'app/const/Api';
import TaskItemTags from 'app/modules/calendar/components/task/TaskItemTags';
import { STATUS } from 'app/modules/calendar/const/SideBar';
import IconDone from 'assets/icon/IconDone';
import classNames from 'classnames';
import { updateTaskStatus } from 'common/redux/actions/calendar/listTaskAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { convertPhpFormatToMoment, formatDate } from 'common/utils/DateUtils';
import moment from 'moment-timezone';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const TaskItem = ({ taskData }) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const taskId = taskData?.id || '';
    const finalStatus = taskData.active;
    const { company = {}, settings = {} } = useSelector(({ auth }) => auth?.user);
    const dateFormat = company?.date_format;

    function _handleUpdateStatus(e) {
        _updateStatus(e);
    }

    function _updateStatus() {
        const statusUpdate = finalStatus === STATUS.ACTIVE ? STATUS.DONE : STATUS.ACTIVE;

        clientQuery(CUSTOMER_UPDATE_TASK_STATUS, {
            method: 'PUT',
            // eslint-disable-next-line no-undef
            data: { active: statusUpdate, ids: [taskId], socket_id: global.socket_id_task_service }
        });
        dispatch(updateTaskStatus({ ...taskData, active: statusUpdate, typeAction: 'status' }));
    }

    function _renderAssign() {
        const assignee = taskData.assignee;

        return (
            <>
                <div className="avt-img">
                    <img src={assignee.avatar} alt="" width={24} height={24} />
                </div>
                <div className="title__info">
                    <div className="tooltip">
                        <div className="name">{assignee.full_name}</div>
                        <p className="tooltiptext top">{assignee.full_name}</p>
                    </div>
                    <div className="time">{formatDate(taskData.date, dateFormat)}</div>
                </div>
            </>
        );
    }

    function _renderUpdateBy() {
        if (finalStatus === STATUS.ACTIVE || !taskData.completed_at) {
            return false;
        }

        const formatDisplay = convertPhpFormatToMoment(dateFormat);
        const timezone = settings?.timezone;

        return (
            <div className="box-task__logs">
                <span className="log-txt">{t('done_by')}</span>
                <div className="tag-label">
                    {taskData?.completed_by?.full_name || taskData?.completed_by?.name || ''}
                </div>
                <span className="log-txt">{t('on')}</span>
                <div className="tag-label">
                    {moment(taskData.completed_at).tz(timezone).locale(i18n.language).format(`${formatDisplay} h:mm A`)}
                </div>
            </div>
        );
    }

    return (
        <div className={classNames('box-task', { done: finalStatus === STATUS.DONE })}>
            <div className="title">
                <div className="v2-btn-default task-done" onClick={(e) => _handleUpdateStatus(e)}>
                    <IconDone />
                    {t('done')}
                </div>
                {_renderAssign()}
            </div>
            <div className="description">{taskData.title}</div>
            <TaskItemTags customer={taskData.customer} job={taskData.job} />
            {_renderUpdateBy()}
        </div>
    );
};

export default TaskItem;

import RenderAvatar from 'app/components/avatar/RenderAvatar';
import IconAssign from 'assets/icon/IconAssign';
import { getUserInfo } from 'common/utils/CompanyUserUtils';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function AssignPreview({ onToggle, schedules }) {
    const { t } = useTranslation();
    const _renderListSchedules = () => {
        const number = schedules.length;

        if (!number) {
            return (
                <div onClick={onToggle} className="flexcenter gap-4">
                    <IconAssign isCircle />
                    <span className="black-darklight nowrap">{t('jobDetail:no_tech')}</span>
                </div>
            );
        }

        const remaining = number - 5;
        const renderShedules = schedules.slice(0, 5);

        return (
            <div className="wrap-member-group flexcenter flex-1">
                {renderShedules.map((item) => {
                    const { id, is_primary, user_id, name } = item;
                    const userInfo = getUserInfo(user_id);

                    if (!!userInfo) {
                        return (
                            <RenderAvatar key={id} userData={userInfo} extendClass={!!is_primary ? '--primary' : ''} />
                        );
                    }
                    return (
                        <div key={id} className="avt fs-11">
                            {name?.substring(0, 2)}
                        </div>
                    );
                })}
                {remaining > 0 && <span className="avt-img avt-more fs-10 fw-500">+{remaining}</span>}
            </div>
        );
    };

    return <div className="wrap-member-group flexcenter flex-1">{_renderListSchedules()}</div>;
}

import React, { useContext, useRef } from 'react';
import { useDispatch } from 'react-redux';
import IconPlus from 'assets/icon/IconPlus';
import { CustomerDetailContext } from '../context/CustomerDetailContext';
import { useTranslation } from 'react-i18next';
import { openLocation } from 'common/redux/actions/location';
import { useParams } from 'react-router-dom';
import LocationLoading from './components/LocationLoading';
import AlertCustomer from '../../components/AlertCustomer';
import { LIST_STATUS } from 'app/const/App';
import { responseToLocationSelected } from '../locations/utils';

const CustomerLocation = () => {
    const { locations, customer, isLoading, _handleUpdateLocationList, _handleUpdateUnitList } =
        useContext(CustomerDetailContext);
    const dispatch = useDispatch();
    const { t } = useTranslation(['customers']);
    const refAlert = useRef(null);

    const { id: customerId } = useParams();

    const _handleAddOrEditLocationSuccess = (res, messageShow, isAdd = false) => {
        refAlert.current.showStatusBar({
            id: 'success',
            message: messageShow.message || t('customers:save_customer_success', { name: res.location_name }),
            type: LIST_STATUS.SUCCESS
        });
        if (isAdd) _handleUpdateUnitList(res?.mdu?.units || [], 'create_from_location');
        _handleUpdateLocationList(responseToLocationSelected(res, customerId), isAdd ? 'create' : 'update_single');
    };

    const handleAddLocation = () => {
        dispatch(
            openLocation({
                customer,
                customerId,
                onAddLocationSuccess: (res, message) => _handleAddOrEditLocationSuccess(res, message, true)
            })
        );
    };

    const handleEditLocation = (location) => {
        dispatch(
            openLocation({
                id: location.id,
                customer,
                customerId,
                onUpdateLocationSuccess: _handleAddOrEditLocationSuccess,
                onUpdateUnitSuccess: _handleUpdateUnitSuccess
            })
        );
    };

    const _handleUpdateUnitSuccess = (data, type = 'update') => {
        _handleUpdateUnitList(data, type);
    };

    const _renderLocationsList = () => {
        return locations.map((location) => {
            const { deleted, id, address, name } = location;
            if (!deleted) {
                return (
                    <div className="rows mt-0" key={id}>
                        <div className="contact-name w-100 dp-block" onClick={() => handleEditLocation(location)}>
                            <span className="contact-name__label fw-600 purple-default mr-1">{name}</span>
                            <span className="contact-name__label granite-gray">{address}</span>
                        </div>
                    </div>
                );
            }
            return false;
        });
    };
    if (isLoading) return <LocationLoading />;

    return (
        <div className="page-wrapper flex-auto form-location form-inner">
            <div className="head-main flex-betweenitems border-bottom-black-extradark">
                <h3 className="title-font15">{t('customers:locations')}</h3>
                <div className="v2-btn-main --bg-green --icon-lg svg-white btn-modal" onClick={handleAddLocation}>
                    <IconPlus />
                </div>
            </div>
            <AlertCustomer ref={refAlert} />
            {_renderLocationsList()}
        </div>
    );
};

export default CustomerLocation;

import moment from 'moment';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CalendarDatePicker from 'app/modules/calendar/header/components/date/DatePicker';
import { convertTimeToISO } from 'common/utils/DateUtils';

const Date = forwardRef(({ defaultValue }, ref) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState(defaultValue || convertTimeToISO(moment()));

    useImperativeHandle(ref, () => ({
        getValue: () => {
            return selected;
        },
        resetValue: () => {
            setSelected(convertTimeToISO(moment()));
        }
    }));

    function _handleChangeDate(dateSelect) {
        setSelected(convertTimeToISO(moment(dateSelect).startOf('day')) || null);
    }

    return (
        <div className="col">
            <p className="txt">{t('customers:date')}</p>
            <CalendarDatePicker onChange={_handleChangeDate} selectDefault={selected} />
        </div>
    );
});

export default Date;

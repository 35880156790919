import emitter from 'common/utils/emitter';
import { useEffect } from 'react';
const useEmitter = (eventName, handler) => {
    useEffect(() => {
        // Subscribe to the event
        emitter.on(eventName, handler);
        // Cleanup: Unsubscribe when the component unmounts or dependencies change
        return () => emitter.off(eventName, handler);
    }, [eventName, handler]); // Re-run effect if eventName or handler changes
};
export default useEmitter;

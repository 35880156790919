import React from 'react';
import { useTranslation } from 'react-i18next';

import IconArrow from 'assets/icon/IconArrow';

const PaymentHeader = ({ invoiceNumber, onClose }) => {
    const { t } = useTranslation();
    return (
        <div className="header-modal flex-auto">
            <div className="name-tabs">
                <div onClick={onClose} className="v2-btn-default --icon-lg --transparent" tabIndex="0">
                    <IconArrow isPrev />
                </div>
                <div className="txt-ellipsis ml-2">
                    {t('jobDetail:add_payment_invoice')} {invoiceNumber}
                </div>
            </div>
            <div onClick={onClose} className="v2-btn-default --transparent" tabIndex="0">
                {t('common:cancel')}
            </div>
        </div>
    );
};

export default PaymentHeader;

import classNames from 'classnames';
import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ADDONS_VOIP_ACTIVE_USERS, ADDONS_VOIP_MANAGE_PERSONAL } from 'app/config/routes';
import { SOURCE_PHONE_NUMBER_VOIP, TYPE_PHONE_VOIP } from 'app/const/addons';
import { ACTION_USER_SETTING_CALL } from 'app/const/api/Voip';
import { ACCOUNT_ROLE } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconNote from 'assets/icon/IconNote';
import { clientQuery } from 'common/utils/ApiUtils';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';

const ManagePhones = (
    {
        isDisable,
        isCallSetting = true,
        finalNumbers = [],
        classWrapper = 'line-bottom-grey gap-module',
        classWrapperDropdown = 'select-caller flexcenter',
        isLoading = false,
        onClose = () => {},
        onSetPrimary = () => {}
    },
    ref
) => {
    const { t } = useTranslation();
    const history = useHistory();
    const refTooltip = useRef(null);
    const refDropdown = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        selected: null
    });

    const userRole = useSelector(({ auth }) => auth?.user?.profile?.role);
    const isSuperAdminLogin = userRole === ACCOUNT_ROLE.SUPERADMIN;

    const { selected: finalSelected } = state;

    useImperativeHandle(ref, () => ({
        _getNumberSelected: () => finalSelected?.phone_number
    }));

    useEffect(() => {
        const phone = { primary: null, group: null, external: null, default: null };

        finalNumbers.forEach((item) => {
            const { is_primary, type, source } = item;

            if (!!is_primary) phone.primary = item;
            else if (type === TYPE_PHONE_VOIP.GROUP && source !== SOURCE_PHONE_NUMBER_VOIP.EXTERNAL) phone.group = item;
            else if (source === SOURCE_PHONE_NUMBER_VOIP.EXTERNAL && !phone.external) phone.external = item;
            else if (!phone.default) phone.default = item;
        });

        dispatchState((prev) => ({
            ...prev,
            selected: phone.primary || phone.group || phone.default || phone.external
        }));
    }, [finalNumbers]);

    useEffect(() => {
        if (isDisable) refDropdown.current._closeDropdown();
    }, [isDisable]);

    const handleCopyClipboard = () => {
        navigator.clipboard.writeText(finalSelected?.phone_number);
        refTooltip.current.innerText = t('common:copied!');
    };

    const handleOnLeave = () => {
        refTooltip.current.innerText = t('common:copy_to_clipboard');
    };

    const _handleSelectItem = (item, isActive) => {
        if (!isActive) {
            dispatchState({
                selected: item
            });

            const _handleSuccess = () => {
                onSetPrimary(item);
            };

            clientQuery(
                ACTION_USER_SETTING_CALL,
                {
                    method: 'PUT',
                    toFormData: false,
                    data: { primary_number_id: item.id }
                },
                _handleSuccess
            );
        }
        refDropdown.current._closeDropdown();
    };

    const _renderListPhone = () => {
        if (!finalNumbers.length)
            return <li className="items grey-generic p-2 fs-13">{t('common:phone_number_empty')}</li>;

        return finalNumbers.map((item) => {
            const { id: itemId, phone_number, name } = item;
            const isActive = itemId === finalSelected?.id;

            return (
                <li
                    onClick={() => _handleSelectItem(item, isActive)}
                    key={itemId}
                    className={classNames(`items`, { active: isActive, 'has-name': !!name })}
                >
                    <div className="flexcenter gap-4 w-100">
                        <p className="txt-phone">{formatPhoneNumberVoip(phone_number)}</p>
                        {!!name ? (
                            <div className="txt-name flex-1 flexcenter">
                                (<span className="txt-ellipsis nowrap">{name}</span>)
                            </div>
                        ) : null}
                    </div>
                </li>
            );
        });
    };

    const _handleClickManage = () => {
        history.push(addBranchPath(isSuperAdminLogin ? ADDONS_VOIP_ACTIVE_USERS : ADDONS_VOIP_MANAGE_PERSONAL));
        onClose();
    };

    return (
        <div className={classWrapper}>
            {isCallSetting && (
                <div className="flex-betweenitems mb-2">
                    <span className="granite-gray">{t('common:caller_id')}</span>
                    <div
                        className={classNames('purple-default cursor-pointer', { 'is-disable': isDisable })}
                        onClick={_handleClickManage}
                    >
                        {t('common:manage_numbers')}
                    </div>
                </div>
            )}
            <div className={classWrapperDropdown}>
                <CalendarDropdown
                    ref={refDropdown}
                    id="manage_phone"
                    isLoading={isLoading}
                    wrapperClassName={classNames('v2-dropdown flex-1 elm-select-caller', {
                        'is-disable': isDisable
                    })}
                    buttonClassName="elm-select-caller__btn dropbtn shadow-none border-none"
                    wrapperListClass="v2-dropdown__menu scrolls"
                    customDropButton={() => (
                        <Fragment>
                            <span className="caller-phone flex-column">
                                {!!finalSelected && (
                                    <Fragment>
                                        <span className="caller-phone__elm txt-ellipsis w-100 black">
                                            {formatPhoneNumberVoip(finalSelected?.phone_number)}
                                        </span>
                                        <span className="caller-phone__elm txt-ellipsis w-100 fs-11 grey-generic">
                                            {finalSelected?.name}
                                        </span>
                                    </Fragment>
                                )}
                            </span>
                            <span className="arrow">
                                <IconArrowDown />
                            </span>
                        </Fragment>
                    )}
                >
                    <ul>
                        {_renderListPhone()}
                        <li>
                            <div className="items word-break text-left" onClick={_handleClickManage}>
                                {t('common:manage_phone_numbers')}
                            </div>
                        </li>
                    </ul>
                </CalendarDropdown>

                <div
                    className="select-caller__copy flex-centeritem tooltip js-copy-phone"
                    onMouseLeave={handleOnLeave}
                    onClick={handleCopyClipboard}
                >
                    <IconNote isAddNote />
                    <span ref={refTooltip} className="tooltiptext top" id="elm_tooltip_text">
                        {t('common:copy_to_clipboard')}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default forwardRef(ManagePhones);

import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';

const DropZone = ({ text = '', showIcon = false, description = '', onDrop = () => {} }, ref) => {
    const { t } = useTranslation();
    let dragTimer;
    const [state, dispatchState] = useReducer(reducer, { isVisible: false, isDragEnter: false });
    const { isVisible, isDragEnter } = state;
    const refDropzone = useRef(null);

    useImperativeHandle(ref, () => ({
        handleDisplay: _handleDisplay,
        handleHidden: _handleHidden
    }));

    const _handleDisplay = () => {
        !isVisible && dispatchState({ isVisible: true });
    };

    const _handleHidden = () => {
        isVisible && !isDragEnter && dispatchState({ isVisible: false });
    };

    const _handleDragEnter = (e) => {
        if (!isDragEnter) {
            dispatchState({ isDragEnter: true });
            window.clearTimeout(dragTimer);
        }
        e.preventDefault();
        e.stopPropagation();
    };

    const _handleDragLeave = (e) => {
        dragTimer = window.setTimeout(() => {
            dispatchState({ isDragEnter: false });
        }, 25);
        e.preventDefault();
        e.stopPropagation();
    };

    const _handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.dataTransfer.files.length) {
            onDrop(e.dataTransfer.files);
        } else {
            const str = e.dataTransfer.getData('text/html'),
                rex = /<img.*?src="([^">]*\/([^">]*?))".*?>/g,
                url = rex.exec(str);
            if (url) onDrop(url[1]);
        }
        dispatchState({ isVisible: false, isDragEnter: false });
    };

    if (!isVisible) return null;

    return (
        <div
            ref={refDropzone}
            className="box-drop-file dp-block"
            onDragOver={_handleDragEnter}
            onDragLeave={_handleDragLeave}
            onDrop={_handleDrop}
        >
            <div className="box-drop-file__conts" style={{ pointerEvents: 'none' }}>
                {showIcon && <div className="arrow-lg" />}
                <p className="title blue-default">
                    {' '}
                    {isDragEnter ? t('jobDetail:drop_files_here') : text || t('jobDetail:drag_image_or_text_here')}
                </p>
                {description && <p className="description">{description}</p>}
            </div>
        </div>
    );
};

export default forwardRef(DropZone);

import React from 'react';
import { useTranslation } from 'react-i18next';

import IconPen from 'assets/icon/IconPen';

const Summary = ({ summary = [] }) => {
    const { t } = useTranslation();
    return (
        <div className="rows row-summary pt-4">
            <div className="txt">
                <IconPen isHasColor />
                {t('setting:summary')}
            </div>
            <div className="details flex-column gap-10">
                {summary.map((item, index) => (
                    <p key={index.toString()} className="txt-summary">
                        {item}
                    </p>
                ))}
            </div>
        </div>
    );
};

export default Summary;

import React, { useContext, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import { UnitDetailContext } from 'app/modules/jobdetail/contexts/UnitDetailContext';
import IconError from 'assets/icon/IconError';
import IconUnit from 'assets/icon/IconUnit';

export default function Unit() {
    const { t } = useTranslation();
    const { unitDetailContextData, updateUnitDetailContextData } = useContext(UnitDetailContext);
    const parentUnitName = unitDetailContextData.unitName;

    const [state, dispatchState] = useReducer(reducer, {
        isWarning: false,
        value: parentUnitName,
        isEdit: false
    });

    const finalIsWarning = state.isWarning;

    useEffect(() => {
        finalIsWarning !== parentUnitName && dispatchState({ value: parentUnitName, isEdit: false });
    }, [parentUnitName]);

    function _handleSubmitUnit(e) {
        const finalValue = e.target.value;
        let isExist = false;

        if (!state.isEdit) {
            return false;
        }

        if (finalValue.length === 0) {
            dispatchState({
                isWarning: t('jobDetail:unit_name_cannot_be_blank')
            });
            updateUnitDetailContextData({
                isCheckunit: false
            });
            return false;
        }

        unitDetailContextData.buildingData.forEach((element) => {
            element.units.forEach((elmUnit) => {
                if (elmUnit.name === finalValue && element.id === unitDetailContextData.buildingSelected) {
                    isExist = true;
                }
            });
        });

        if (isExist) {
            dispatchState({
                isWarning: t('jobDetail:unit_name_is_exist')
            });
            updateUnitDetailContextData({
                isCheckunit: false
            });
            return false;
        }

        updateUnitDetailContextData({
            isCheckunit: true,
            unitName: finalValue
        });
    }

    function _handleChangeValue(e) {
        dispatchState({
            value: e.target.value,
            isWarning: null,
            isEdit: true
        });
    }

    return (
        <div className="rows">
            <div className="rows__label">
                <IconUnit />
                <span className="flex-1 txt-ellipsis">{t('jobDetail:unit')}</span>
            </div>
            <div className="rows__form">
                <input
                    onBlur={(e) => _handleSubmitUnit(e)}
                    onChange={(e) => _handleChangeValue(e)}
                    className="field-input"
                    type="text"
                    value={state.value}
                />
                {finalIsWarning && (
                    <p className="txt-incorrect">
                        <IconError />
                        {finalIsWarning}
                    </p>
                )}
            </div>
        </div>
    );
}

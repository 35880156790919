export default ({ isHasColor = false, stroke = 'var(--color-icon)' }) => {
    if (isHasColor)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17 8C18.1046 8 19 8.89543 19 10V11.2457C19 11.6922 18.704 12.0846 18.2747 12.2072L14 13.428V13L13.9945 12.8507C13.9182 11.8159 13.0544 11 12 11C10.8954 11 10 11.8954 10 13V13.429L5.72528 12.2072C5.29598 12.0846 5 11.6922 5 11.2457V10C5 8.89543 5.89543 8 7 8H17Z"
                    fill="#7A83A6"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 13.3257C6.09292 13.3257 6.18538 13.3387 6.27472 13.3642L10.0493 14.4435C10.2511 15.3346 11.0478 16 12 16C12.9522 16 13.7489 15.3346 13.9507 14.4435L17.7253 13.3642C18.2563 13.2125 18.8098 13.52 18.9615 14.051C18.987 14.1403 19 14.2328 19 14.3257V17C19 18.1046 18.1046 19 17 19H7C5.89543 19 5 18.1046 5 17V14.3257C5 13.7734 5.44772 13.3257 6 13.3257Z"
                    fill="#C1C9D4"
                />
                <path
                    d="M13 13C13 12.4477 12.5523 12 12 12C11.4477 12 11 12.4477 11 13V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V13Z"
                    fill="#7A83A6"
                />
                <path
                    d="M14 4C15.5977 4 16.9037 5.24892 16.9949 6.82373L17 7H15L14.9933 6.88338C14.9355 6.38604 14.5128 6 14 6H10C9.44772 6 9 6.44772 9 7H7C7 5.34315 8.34315 4 10 4H14Z"
                    fill="#7A83A6"
                />
            </svg>
        );
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.5 7.5H17.5C18.6046 7.5 19.5 8.39543 19.5 9.5V16.5C19.5 17.6046 18.6046 18.5 17.5 18.5H6.5C5.39543 18.5 4.5 17.6046 4.5 16.5V9.5C4.5 8.39543 5.39543 7.5 6.5 7.5Z"
                stroke={stroke}
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5 4.5H13.5C14.6046 4.5 15.5 5.39543 15.5 6.5V7.5H8.5V6.5C8.5 5.39543 9.39543 4.5 10.5 4.5Z"
                stroke={stroke}
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M5.5 11.5L10.5 13.0385"
                stroke={stroke}
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M13.5376 13.0269L18.5 11.5"
                stroke={stroke}
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5 11.5H12.5C13.0523 11.5 13.5 11.9477 13.5 12.5V13.5C13.5 14.0523 13.0523 14.5 12.5 14.5H11.5C10.9477 14.5 10.5 14.0523 10.5 13.5V12.5C10.5 11.9477 10.9477 11.5 11.5 11.5Z"
                stroke={stroke}
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
};

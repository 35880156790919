import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import GdListPhone from 'app/components/phone/ListPhone';
import { addBranchPath } from 'app/const/Branch';
import { CUSTOMERS } from 'app/const/Route';
import { TYPE_OPEN_SMS } from 'app/const/Sms';
import IconUser from 'assets/icon/IconUser';

const CustomerInfo = ({ isLoading, customerDetail, moreClassess = '', isJobDetail = false, isCustomer = true }) => {
    const { t } = useTranslation();
    const { email: finalEmail = '', full_name: customerFullName = '' } = customerDetail || {};

    const isValidFullName = customerFullName.length > 1;
    const getListPhones = (phones) => {
        return phones.map((item) => ({ ...item, type: item.name }));
    };
    const finalPhones = getListPhones(customerDetail?.phones || []);

    function _renderLoading() {
        return (
            <div className="wrap-loading details-job mt-3">
                <div className="rows justify-start">
                    <div className="txt flex-auto">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="details mt-2">
                        <div className="loading --animation --line --twothird" />
                        <div className="loading --animation --line --half" />
                        <div className="loading --animation --line --twothird" />
                    </div>
                </div>
            </div>
        );
    }

    if (isLoading) return _renderLoading();

    if (isJobDetail) {
        return (
            <div className={'details-job__elm rows has-line-bottom'}>
                <div className="txt">{t('common:customer')}</div>
                <div className="details detail-customer">
                    <div className="customer-name mb-0">
                        <div className="customer-name__info">
                            <div className="valid-name">
                                <Link
                                    to={addBranchPath(`${CUSTOMERS}/${customerDetail.id}`)}
                                    className="valid-name__title"
                                >
                                    {customerDetail.full_name}
                                </Link>
                                {customerDetail?.title ? (
                                    <div className="label-content">{customerDetail.title}</div>
                                ) : null}
                            </div>
                            <p className="cus-account txt-ellipsis">{customerDetail.account_no}</p>
                        </div>
                    </div>
                    <GdListPhone
                        data={[...(customerDetail.phones || [])]}
                        typeOpen={TYPE_OPEN_SMS.DETAIL}
                        customer={customerDetail}
                    />
                    {finalEmail && (
                        <a className="info" href={`mailto:${finalEmail}`}>
                            {finalEmail}
                        </a>
                    )}
                </div>
            </div>
        );
    }

    if (!isValidFullName && !customerDetail.phones.length && !finalEmail) {
        return false;
    }

    const _renderCustomerDetail = () => {
        return (
            <div className="customer-name">
                <div className="avt">{customerDetail.avatar}</div>
                <div className="txt-ellipsis cursor-pointer">{customerDetail.full_name}</div>
            </div>
        );
    };

    return (
        <div className={`${moreClassess} details-job__elm rows mt-3`}>
            <div className="txt">
                <IconUser />
                {t('common:customer')}
            </div>
            <div className="details detail-customer">
                {isValidFullName && (
                    <>
                        {isCustomer ? (
                            <Link to={addBranchPath(`${CUSTOMERS}/${customerDetail.id}`)}>
                                {_renderCustomerDetail()}
                            </Link>
                        ) : (
                            _renderCustomerDetail()
                        )}
                    </>
                )}
                <GdListPhone data={finalPhones} typeOpen={TYPE_OPEN_SMS.DETAIL} />
                {finalEmail && (
                    <a className="info cursor-pointer" href={`mailto:${finalEmail}`}>
                        {finalEmail}
                    </a>
                )}
            </div>
        </div>
    );
};

export default CustomerInfo;

import React from 'react';

const LoadingDefault = () => {
    return (
        <div className="wrap-loading mb-3">
            <div className="field-iframe loading --animation"></div>
        </div>
    );
};

export default LoadingDefault;

import GdButton from 'app/components/button';
import { getListPlan } from 'app/const/Api';
import { LIST_STATUS } from 'app/const/Status';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import IconArrow from 'assets/icon/IconArrow';
import IconHashtag from 'assets/icon/IconHashtag';
import IconPen from 'assets/icon/IconPen';
import IconPrice from 'assets/icon/IconPrice';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import PlanInputInterval from './PlanInputInterval';
import PlanInputPrice from './PlanInputPrice';
import { reducer } from 'app/const/Reducer';

const PlanAddModal = forwardRef(({ isSquare, customerId, onCreateSuccess = () => {} }, ref) => {
    const { t } = useTranslation(['common', 'customers']);
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, isLoadingButton: false });
    const refAlert = useRef(null);
    const refInputId = useRef(null);
    const refInputName = useRef(null);
    const refInputPrice = useRef(null);
    const refInputInterval = useRef(null);

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = () => {
        dispatchState({ isOpen: true });
    };

    const _close = () => {
        dispatchState({ isOpen: false });
    };

    const _handleCreatePlan = () => {
        const errors = [];
        const id = refInputId.current.value;
        const name = refInputName.current.value;

        if (!id.trim().length) errors.push(t('customers:cannot_be_blank', { name: t('common:id') }));
        if (!name.trim().length) errors.push(t('customers:cannot_be_blank', { name: t('common:name') }));
        if (errors.length)
            return refAlert.current.showStatusBar({ id: 'error', message: errors, type: LIST_STATUS.ERROR });

        dispatchState({ isLoadingButton: true });

        clientQuery(
            getListPlan(customerId),
            {
                data: {
                    plan: {
                        id,
                        name,
                        ...refInputPrice.current._getValue(),
                        ...refInputInterval.current._getValue()
                    },
                    merchant: isSquare ? 2 : 1
                },
                method: 'POST'
            },
            _handleCreateSuccess,
            _handleCreateFailed,
            _handleCreateFinally
        );
    };

    const _handleCreateSuccess = ({ data }) => {
        _close();
        onCreateSuccess(data);
    };
    const _handleCreateFailed = ({ message }) => {
        if (message.length) refAlert.current.showStatusBar({ id: 'error', message, type: LIST_STATUS.ERROR });
    };
    const _handleCreateFinally = () => dispatchState({ isLoadingButton: false });

    return (
        <ReactModal
            id="create_plan_modal"
            isOpen={state.isOpen}
            style={{ overlay: { zIndex: 99, background: 'transparent' } }}
            className="modal container-modal form-stripe-subscription --create-plan open"
            onRequestClose={_close}
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className="modal__container">
                <div className="header-modal">
                    <div className="d-flex align-center">
                        <div className="v2-btn-default --icon-lg --transparent btn-back" onClick={_close}>
                            <IconArrow isPrev />
                        </div>
                        <h3 className="header-modal__label">{t('common:create_plan')}</h3>
                    </div>
                    <div className="btn-action btn-close">
                        <span className="v2-btn-default --transparent" onClick={_close}>
                            {t('cancel')}
                        </span>
                        <GdButton className="v2-btn-main" onClick={_handleCreatePlan} isLoading={state.isLoadingButton}>
                            {t('save')}
                        </GdButton>
                    </div>
                </div>
                <div className="body-modal scrolls pt-2">
                    <AlertCustomer ref={refAlert} />
                    <div className="create-plan">
                        <div className="rows --modify --has-field">
                            <div className="rows__label">
                                <IconHashtag />
                                <span className="txt-ellipsis">{t('id')}</span>
                            </div>
                            <input ref={refInputId} className="field-input" type="text" />
                        </div>
                        <div className="rows --modify --has-field">
                            <div className="rows__label">
                                <IconPen isHasColor />
                                <span className="txt-ellipsis">{t('name')}</span>
                            </div>
                            <input ref={refInputName} className="field-input" type="text" />
                        </div>

                        <div className="rows --modify --has-field --price">
                            <div className="rows__label">
                                <IconPrice />
                                <span className="txt-ellipsis">{t('price')}</span>
                            </div>
                            <PlanInputPrice ref={refInputPrice} />
                        </div>
                        <PlanInputInterval isSquare={isSquare} ref={refInputInterval} />
                    </div>
                </div>
            </div>
        </ReactModal>
    );
});

export default PlanAddModal;

import { COMMON, KEY_REPORT_LOCAL_STORAGE, DELAY_TIME } from 'app/const/App';
import {
    getColumnsParams,
    REVENUE_BY_CLIENT_LIST_FILER,
    REVENUE_BY_CLIENT_TABS,
    SORT_BY
} from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import {
    getListReportRevenueByClientDetail,
    getListReportRevenueByClientMonth
} from 'common/redux/actions/reports/revenueByClientAction';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { URL_REPORT_REVENUE_BY_CLIENT, URL_EXPORT_PAYMENT_CLIENT } from 'app/const/api/Export';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { LIST_EXPORT } from 'app/const/report/Common';
import { reducer } from 'app/const/Reducer';
import loadable from '@loadable/component';
import { MonthTabGridView } from './components/MouthTab';
import { DetailTabGridView } from './components/DetailsTab';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { removeObjParams, getLocalParamsReport } from 'common/utils/ReportUtils';
import HeaderBottom from '../components/HeaderBottom';

const Export = loadable(() => import('app/modules/report/components/Export'));
const ReportTabs = loadable(() => import('app/modules/report/components/ReportTabs'));
const MainHeaderReport = loadable(() => import('app/modules/report/components/MainHeader'));

const MONTH_TAB = REPORT_TYPE.REVENUE_BY_CLIENT_MONTH_TAB;
const DETAIL_TAB = REPORT_TYPE.REVENUE_BY_CLIENT_DETAIL_TAB;

const keyTotalRow = {
    [MONTH_TAB]: 'total_customer',
    [DETAIL_TAB]: 'total_payments_received'
};

function ReportRevenueByClient() {
    const initState = {
        [MONTH_TAB]: {
            data: [],
            total: 0,
            rowTotal: [],
            isLoading: true
        },
        [DETAIL_TAB]: {
            data: [],
            total: 0,
            rowTotal: [],
            isLoading: true
        },
        refreshScreen: 0
    };

    const { t } = useTranslation(['report']);
    const dispatch = useDispatch();
    const keyCurrentTab = COMMON.CURRENT_TAB.concat('_', REPORT_TYPE.REVENUE_BY_CLIENT);
    const keyLocalStore = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.REVENUE_BY_CLIENT);
    const [dataReport, dispatchActionReport] = useReducer(reducer, {
        ...initState,
        currentTab: getLocalStorage(keyCurrentTab) || MONTH_TAB
    });
    let timer;

    const currentTab = dataReport.currentTab;
    const refreshScreen = dataReport.refreshScreen;
    const totalItemsExport = dataReport[currentTab]?.total || 0;

    const paramsReport = getLocalParamsReport(keyLocalStore, REPORT_TYPE.REVENUE_BY_CLIENT);

    useEffect(() => {
        timer && clearTimeout(timer);
        timer = setTimeout(() => {
            getListReport(paramsReport);
            setLocalStorage(keyCurrentTab, currentTab);
        }, DELAY_TIME);
        return () => {
            removeLocalStorage(keyCurrentTab);
            clearTimeout(timer);
        };
    }, [currentTab]);

    const getListReport = (params) => {
        params.payment_methods = params?.payment_methods?.toString() || '';
        delete params?.currentPage;
        let objectParams = { ...params };

        switch (currentTab) {
            case MONTH_TAB:
                objectParams.order = objectParams['orderMonth'];
                objectParams = removeObjParams(objectParams, [
                    'orderMonth',
                    'orderDetail',
                    'start',
                    'end',
                    'payment_for',
                    'customer_id'
                ]);

                return dispatch(
                    getListReportRevenueByClientMonth(
                        objectParams,
                        (response) => getListSuccess(response, params),
                        getListFailed
                    )
                );
            case DETAIL_TAB:
                objectParams.order = objectParams['orderDetail'];
                objectParams = removeObjParams(objectParams, ['orderMonth', 'orderDetail', 'year', 'customer_id']);

                return dispatch(
                    getListReportRevenueByClientDetail(
                        objectParams,
                        (response) => getListSuccess(response, params),
                        getListFailed
                    )
                );
            default:
                break;
        }
    };

    const getListSuccess = (response) => {
        dispatchActionReport({
            [currentTab]: {
                isLoading: false,
                data: response.data,
                total: response.data.length,
                rowTotal: createRowTotal(response[keyTotalRow[currentTab]], response.data.length)
            },
            refreshScreen: refreshScreen + 1
        });
    };

    const createRowTotal = (data, total) => {
        const totalItem = total ?? dataReport[currentTab].total;
        const columns = getColumnsParams(currentTab);
        const totalColumns = columns.map((column) => {
            if (typeof data?.[column] !== 'undefined') {
                return {
                    id: column,
                    isShow: true,
                    totalAmount: data[column],
                    isCurrency: true
                };
            }
            return { id: column, isShow: true };
        });
        totalColumns[0].title =
            currentTab === MONTH_TAB
                ? t('report:total_rows', {
                      number: totalItem,
                      title: t('report:clients')
                  })
                : t('report:total_payments', { number: totalItem });
        return totalColumns;
    };

    function getListFailed() {
        dispatchActionReport({ [currentTab]: { isLoading: false } });
    }

    const _handleChangeFilter = (params, type) => {
        switch (type) {
            case SORT_BY:
                _handleUpdate();
                break;
            default:
                break;
        }
    };

    const _handleUpdate = () => {
        const params = getLocalStorage(keyLocalStore);
        dispatchActionReport({ [currentTab]: { ...initState[currentTab] } });
        getListReport(params);
    };

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: REPORT_TYPE.REVENUE_BY_CLIENT,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleUpdate,
            keyOrder: currentTab === DETAIL_TAB ? 'orderDetail' : 'orderMonth'
        });
    };

    function _renderHeaderLeft() {
        return <ReportTabs list={REVENUE_BY_CLIENT_TABS} onChange={handleChangeTab} tabActive={currentTab} />;
    }

    function _renderHeaderBottom() {
        return (
            <HeaderBottom
                classNameHeader="header --filter"
                typeReport={REPORT_TYPE.REVENUE_BY_CLIENT}
                filters={REVENUE_BY_CLIENT_LIST_FILER.ROW_2}
                keyLocalStore={keyLocalStore}
                params={paramsReport}
                currentTab={currentTab}
                isNotShowDateRange={currentTab !== DETAIL_TAB}
                isLoading={dataReport[currentTab].isLoading}
                handleChangeFilter={_handleChangeFilter}
                handleUpdate={_handleUpdate}
            />
        );
    }

    function _getParamsExport() {
        let paramsExport = { ...paramsReport };
        switch (currentTab) {
            case MONTH_TAB:
                paramsExport.order = paramsExport['orderMonth'];
                paramsExport = removeObjParams(paramsExport, [
                    'orderMonth',
                    'orderDetail',
                    'start',
                    'end',
                    'payment_for'
                ]);
                return paramsExport;
            case DETAIL_TAB:
                paramsExport.order = paramsExport['orderDetail'];
                paramsExport = removeObjParams(paramsExport, ['orderMonth', 'orderDetail', 'year']);
                return paramsExport;
            default:
                return null;
        }
    }

    function _getUrlExport() {
        switch (currentTab) {
            case MONTH_TAB:
                return URL_REPORT_REVENUE_BY_CLIENT;
            case DETAIL_TAB:
                return URL_EXPORT_PAYMENT_CLIENT;
            default:
                return null;
        }
    }

    function _getPageExport() {
        switch (currentTab) {
            case MONTH_TAB:
                return LIST_EXPORT.EXPORT_REPORT_REVENUE_CLIENT;
            case DETAIL_TAB:
                return LIST_EXPORT.EXPORT_REPORT_REVENUE_CLIENT_DETAIL;
            default:
                return null;
        }
    }

    const handleChangeTab = (tab) => {
        dispatchActionReport({ currentTab: tab, [tab]: { isLoading: true } });
    };

    const _handleOpenInvoice = (data) => {
        dispatch(
            actionOpenInvoice({
                id: data?.row?.invoice_id,
                status: '',
                total: '',
                isRecurring: false
            })
        );
    };

    function _renderHeaderRight() {
        return (
            <Export
                title={t('report:records', { count: totalItemsExport })}
                activePrint
                params={_getParamsExport()}
                url={_getUrlExport()}
                pageExport={_getPageExport()}
                isDisable={dataReport[currentTab].isLoading}
                refresh={refreshScreen}
            />
        );
    }

    const _renderGirdView = () => {
        switch (currentTab) {
            case MONTH_TAB:
                return (
                    <MonthTabGridView
                        dataReport={dataReport[MONTH_TAB]}
                        paramsReport={paramsReport}
                        handleActionHeader={_handleActionHeader}
                        handleOpenInvoice={_handleOpenInvoice}
                        monthTab={dataReport.currentTab}
                    />
                );
            case DETAIL_TAB:
                return (
                    <DetailTabGridView
                        dataReport={dataReport[DETAIL_TAB]}
                        paramsReport={paramsReport}
                        handleActionHeader={_handleActionHeader}
                        handleOpenInvoice={_handleOpenInvoice}
                    />
                );
            default:
                break;
        }
    };

    return (
        <>
            <MainHeaderReport
                contentLeft={_renderHeaderLeft}
                contentRight={_renderHeaderRight}
                reportType={REPORT_TYPE.REVENUE_BY_CLIENT}
                onSelectTab={_handleUpdate}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages has-tab gap-8">
                    <div className="wrap-tables flex-column relative">
                        {_renderHeaderBottom()}
                        <div className="tab-contents">{_renderGirdView()}</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportRevenueByClient;

import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TIMEOFF_HOURS, TIMEOFF_MINUTES } from 'app/const/App';
import IconCalendar from 'assets/icon/IconCalendar';
import DropdownTime from 'app/components/dropdown/DropdownTime';
import InputDate from './InputDate';
import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';

const DEFAULT_SELECT = { name: '00', value: 0 };
const DEFAULT_SELECT_MINUTE = { name: '15', value: 15 };
const UNIT_HOURS = 'h';
const UNIT_MINUTES = 'm';

const TimeLength = ({ start, defaultValue, onChangeDate = () => {} }, ref) => {
    const { t } = useTranslation();
    const [isAllDay, setIsAllDay] = useState(defaultValue ? !!defaultValue.all_day : false);
    const refStartDate = useRef(null);
    const refHoursSelected = useRef(
        defaultValue
            ? //  Don't allow to select over 24 hour
              TIMEOFF_HOURS.find((hour) => hour.value === (defaultValue.hours >= 24 ? 23 : defaultValue.hours))
            : DEFAULT_SELECT
    );
    const refMinutesSelected = useRef(
        defaultValue ? TIMEOFF_MINUTES.find((hour) => hour.value === defaultValue.minutes) : DEFAULT_SELECT_MINUTE
    );

    useImperativeHandle(ref, () => ({
        getValue: () => {
            return {
                isAllDay,
                start: refStartDate.current.getValue(),
                hours: refHoursSelected.current.value,
                minutes: refMinutesSelected.current.value
            };
        }
    }));

    const _handleChange = () => {
        setIsAllDay(!isAllDay);
    };

    const _handleSelect = (unit, value) => {
        if (unit === UNIT_HOURS) refHoursSelected.current = value;
        if (unit === UNIT_MINUTES) refMinutesSelected.current = value;
    };

    return (
        <div className="wrap-rows flextop">
            <div className="wrap-rows__icons flex-auto">
                <IconCalendar />
            </div>
            <div className="wrap-rows__details flex-1">
                <InputDate ref={refStartDate} start={start} onChangeDate={onChangeDate} />
                <GdSwitchCheckbox
                    id="toggle_time"
                    inputClassName="toggle toggle-round"
                    textUnChecked={t('calendar:all_day')}
                    textChecked={t('calendar:all_day')}
                    defaultChecked={isAllDay}
                    onChangeImmediately={_handleChange}
                />
                {!isAllDay && (
                    <div className="timerange-select flexcenter">
                        <span className="mr-1"> {t('length')}</span>
                        <div className="field-input wrapper-choosetime">
                            <DropdownTime
                                id="hours-length-timeoff"
                                unit="h"
                                subText="hrs"
                                options={TIMEOFF_HOURS}
                                defaultValue={refHoursSelected.current}
                                onSelect={_handleSelect}
                            />
                            <DropdownTime
                                id="minutes-length-timeoff"
                                unit="m"
                                subText="mins"
                                options={TIMEOFF_MINUTES}
                                defaultValue={refMinutesSelected.current}
                                onSelect={_handleSelect}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default forwardRef(TimeLength);

import React from 'react';
import { useTranslation } from 'react-i18next';

import IconAlert from 'assets/icon/IconAlert';
import IconDone from 'assets/icon/IconDone';

const IconPhone = ({ isValid = null, ...props }) => {
    const { t } = useTranslation();
    switch (isValid) {
        case 0:
            return (
                <span className="phone-icon" title={t('common:title_phone_invalid')}>
                    <IconAlert {...props} />
                </span>
            );
        case 1:
            return (
                <span className="phone-icon" title={t('common:title_phone_valid')}>
                    <IconDone isSuccess {...props} />
                </span>
            );
        default:
            return null;
    }
};

export default IconPhone;

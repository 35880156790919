import classNames from 'classnames';
import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import { reducer } from 'app/const/Reducer';
import IconArrowDown from 'assets/icon/IconArrowDown';

const Time = forwardRef(({ defaultValue, modifiers = {} }, ref) => {
    const { t } = useTranslation();
    const { company: companySettings, settings } = useSelector(({ auth }) => auth.user);
    const companyTimes = companySettings?.operating_time;
    const companyTimeZone = settings?.timezone;
    const options = companyTimes && typeof companyTimes === 'object' ? Object.keys(companyTimes) : [];

    const getCurrentTime = () => {
        const currentTime = moment().tz(companyTimeZone).format('h:00 a');
        return options.find((item) => item === currentTime) || currentTime;
    };

    const [state, dispatchState] = useReducer(reducer, {
        options: options,
        selected: defaultValue || getCurrentTime() || ''
    });

    const { selected: timeSelected } = state;
    const refDropdown = useRef(null);

    useImperativeHandle(ref, () => ({
        getValue: () => state.selected,
        resetValue: _resetValue
    }));

    useEffect(() => {
        dispatchState((prevState) => ({ ...prevState, selected: defaultValue || getCurrentTime() }));
    }, [defaultValue]);

    const _resetValue = () => {
        dispatchState((prevState) => ({ ...prevState, selected: defaultValue || getCurrentTime() }));
    };

    const _handleSelect = (selected) => {
        refDropdown.current?._close();
        dispatchState((prevState) => ({ ...prevState, selected }));
    };

    return (
        <div className="flex-column gap-4 flex-1">
            <p>{t('common:time')}</p>
            <DropdownPopper
                ref={refDropdown}
                wrapperClassName="v2-dropdown"
                buttonClassName="dropbtn items"
                wrapperListClass="v2-dropdown__menu scrolls"
                isUseToggle
                isCalculateWidth
                modifiers={modifiers}
                dropdownIcon={
                    <>
                        <div className="txt-ellipsis">{timeSelected || t('customers:select_time')}</div>
                        <div className="arrow">
                            <IconArrowDown width={16} height={16} />
                        </div>
                    </>
                }
            >
                <ul>
                    {options.map((item) => (
                        <li
                            key={item}
                            className={classNames('items', { active: timeSelected === item })}
                            onClick={() => _handleSelect(item)}
                        >
                            {item}
                        </li>
                    ))}
                </ul>
            </DropdownPopper>
        </div>
    );
});

export default Time;

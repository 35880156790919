import { useImperativeHandle, forwardRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const Header = ({ defaultValue = '', titleTemplate = null, onChangeName = () => {} }, ref) => {
    const { t } = useTranslation(['setting']);
    const refInput = useRef(null);

    useImperativeHandle(ref, () => ({
        _setInput,
        _getValueInput,
        _autoFocus
    }));

    const _setInput = (value) => (refInput.current.value = value);

    const _getValueInput = () => refInput.current.value;

    const _autoFocus = () => refInput.current.focus();

    return (
        <div className="template-header">
            {titleTemplate}
            <div className="template-header__name mt-2">
                <p className="title">{t('template_name')}</p>
                <input
                    ref={refInput}
                    type="text"
                    className="field-input"
                    placeholder={t('template_name')}
                    defaultValue={defaultValue}
                    onChange={onChangeName}
                />
            </div>
        </div>
    );
};
export default forwardRef(Header);

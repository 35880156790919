import classNames from 'classnames';
import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { MATERIAL_TYPES } from 'app/const/addons/Material';
import { ADD_NEW_MATERIAL_ITEM } from 'app/const/api/Material';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import IconPlus from 'assets/icon/IconPlus';
import { clientQuery } from 'common/utils/ApiUtils';

export default function AddItem({ onAdd, onUpdate, onShowMessage, onDelete, parentType, sortOrder, placeholder = '' }) {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        name: '',
        epa: ''
    });
    const country = useSelector(({ auth }) => auth.user.company.country);

    const { name: finalName, isVisible: finalIsVisible, epa: finalEpa } = state;
    const isMaterialtype = parentType === MATERIAL_TYPES.MATERIAL;

    function _handleOpenForm(e) {
        e && e.stopPropagation();
        dispatchState({
            isVisible: true
        });
    }

    function _handleCloseForm(e) {
        e && e.stopPropagation();
        dispatchState({
            isVisible: false,
            name: '',
            epa: ''
        });
    }

    function _handleSave(e) {
        e && e.stopPropagation();

        const tempId = new Date().getTime();

        onAdd({
            id: tempId,
            name: finalName,
            epa: finalEpa,
            sort_order: sortOrder,
            quick_add: true
        });

        dispatchState({
            isVisible: false,
            name: '',
            epa: ''
        });

        clientQuery(
            ADD_NEW_MATERIAL_ITEM,
            {
                method: 'POST',
                data: {
                    name: finalName,
                    epa: finalEpa,
                    sort_order: sortOrder,
                    type: parentType
                }
            },
            (response) => _handleAddSuccess(response, tempId),
            (response) => _handleAddFailed(response, tempId)
        );
    }

    function _handleAddSuccess(response, tempId) {
        onUpdate(tempId, {
            id: response.data.id,
            quick_add: false
        });
        onShowMessage(tempId, t('addons:add_success'), LIST_STATUS.SUCCESS);
    }

    function _handleAddFailed(response, tempId) {
        onDelete(tempId);
        onShowMessage(tempId, response?.message?.toString() || t('addons:add_failed'), LIST_STATUS.ERROR);
    }

    function _handleChangeName(e) {
        dispatchState({ name: e.target.value });
    }

    const _handleKeyPress = (e) => {
        if ((e.key === 'Enter' || e.keyCode === 13) && finalName.trim().length) _handleSave(e);
    };

    function _handleChangeEPA(e) {
        dispatchState({
            epa: e.target.value
        });
    }

    return (
        <>
            {finalIsVisible && (
                <div className={'boxs-material open'}>
                    <div className="boxs-material__body">
                        <input
                            value={finalName}
                            className="field-input"
                            type="text"
                            autoFocus
                            placeholder={placeholder}
                            onChange={_handleChangeName}
                            onKeyPress={_handleKeyPress}
                        />
                        {isMaterialtype && (
                            <input
                                onChange={(e) => _handleChangeEPA(e)}
                                value={finalEpa}
                                className="field-input"
                                type="text"
                                placeholder={country === 'ca' ? 'PCP#' : 'EPA#'}
                            />
                        )}
                    </div>
                    <div className="boxs-material__footer">
                        <div className="v2-btn-default --transparent cancel" onClick={(e) => _handleCloseForm(e)}>
                            {t('common:cancel')}
                        </div>
                        <div
                            onClick={(e) => _handleSave(e)}
                            className={classNames('v2-btn-main', { 'is-disable': !finalName.trim().length })}
                        >
                            {t('common:save')}
                        </div>
                    </div>
                </div>
            )}
            <div className="rows btn-add-material">
                <div onClick={(e) => _handleOpenForm(e)} className="v2-btn-default has-icon --grey">
                    <IconPlus />
                    {t('addons:add_new')}
                </div>
            </div>
        </>
    );
}

import React, { Fragment, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ButtonCopy from 'app/components/button/ButtonCopy';
import IconBananaReferral from 'assets/icon/IconBananaReferral';
import IconEmail from 'assets/icon/IconEmail';
import IconTooltip from 'assets/icon/IconTooltip';
import { formatNumberWithCommas } from 'common/utils/NumberUtils';
import { LABEL_STATUS_REFERRAL } from '../constant';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { GET_REFERRAL_DETAIL } from 'app/const/api/V2';

const BannerReferral = () => {
    const { t } = useTranslation('referral');
    const company = useSelector(({ auth }) => auth.user.company.name);

    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        data: {
            url: '',
            code: '',
            stats: {
                applied: 0,
                active: 0,
                trial: 0,
                expired: 0
            },
            free_months: 0
        }
    });

    const { data: finalData, isLoading: finalIsLoading } = state;
    const { stats: finalStats, url: finalUrl } = finalData || {};

    useEffect(() => {
        getDetailReferral();
    }, []);

    const getDetailReferral = () => {
        const _handleSuccess = ({ data }) => {
            dispatchState((prev) => ({ ...prev, data, isLoading: false }));
        };

        const _handleFail = () => {
            dispatchState((prev) => ({ ...prev, isLoading: false }));
        };

        clientQuery(GET_REFERRAL_DETAIL, { method: 'GET' }, _handleSuccess, _handleFail);
    };

    return (
        <div className="wrap-referral">
            <div className="referral-content">
                <h2 className="fs-28 white-default">{t('header_title')}</h2>
                <p className="fs-13 white-light-default mt-1">{t('header_desc')}</p>
                <div className="flex-column gap-8 mt-4">
                    <div className="flexcenter gap-6">
                        <span className="white-default">{t('copy_or_share_referral_link')}</span>
                        <span className="tooltip">
                            <IconTooltip />
                            <p className="tooltiptext top">{t('copy_or_share_referral_link_tooltip')}</p>
                        </span>
                    </div>
                    <input className="field-input" value={finalUrl} readOnly />
                </div>
                <div className="d-flex gap-16 mt-3">
                    <ButtonCopy
                        className="v2-btn-main has-icon svg-white-stroke tooltip"
                        title={t('copy_link')}
                        content={finalUrl}
                        isShowOnlyCopied
                    />
                    <a
                        href={`mailto:?body=${encodeURIComponent(
                            t('body_email_referral', { link: finalUrl, company })
                        )}`}
                        className="v2-btn-default has-icon black-dark-charcoal"
                    >
                        <IconEmail />
                        {t('share_via_email')}
                    </a>
                </div>
            </div>
            <div className="referral-content referral-box">
                <h1 className="blue-default fs-40">{t('one_free_month')}</h1>
                <h4 className="blue-default fs-14">{t('for_each_referral')}</h4>
                <p className="granite-gray fs-13 my-3">{t('desc_one_free_month')}</p>
                <div className="line" />
                <ListItemReferral stats={finalStats} isLoading={finalIsLoading} />
            </div>
        </div>
    );
};

const ListItemReferral = ({ stats = {}, isLoading = false }) => {
    const { t } = useTranslation('referral');

    return (
        <div className="box-icon">
            {Object.entries(LABEL_STATUS_REFERRAL).map(([key, value], index) => {
                if (!value.title) return null;
                return (
                    <Fragment key={key}>
                        {index > 0 ? <div className="is-divider" /> : null}
                        <div className="box-items">
                            <IconBananaReferral type={key} />
                            <div className="flex-column gap-4">
                                <p className="fs-13 granite-gray">{t(value.title)}</p>
                                <div className="txt-number">
                                    {isLoading ? (
                                        <div className="wrap-loading flex-centeritem h-100">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                    ) : (
                                        <p className="fs-15 fw-500 black">
                                            {formatNumberWithCommas(stats[key])}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Fragment>
                );
            })}
        </div>
    );
};

export default BannerReferral;

import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TABS } from 'app/modules/jobdetail/const';
import { CUSTOMERS } from 'app/const/Route';
import { useHistory } from 'react-router-dom';
import IconPlus from 'assets/icon/IconPlus';
import IconEstimate from 'assets/icon/IconEstimate';
import IconInvoice from 'assets/icon/IconInvoice';
import IconNote from 'assets/icon/IconNote';
import IconWorkOrder from 'assets/icon/IconWorkOrder';
import IconMaterial from 'assets/icon/IconMaterial';
import IconRouter from 'assets/icon/IconRouter';
import IconDocument from 'assets/icon/IconDocument';
import IconBuilding from 'assets/icon/IconBuilding';
import Status from 'app/modules/job/status';
import { JOB_COMPLETED } from 'app/const/Job';
import { useDispatch, useSelector } from 'react-redux';
import { actionToggleInsertJobNotes } from 'common/redux/actions/notesAction';
import IconCalendar from 'assets/icon/IconCalendar';
import { actionToggleInsertJobImages } from 'common/redux/actions/photosAction';
import { addBranchPath } from 'app/const/Branch';
import NotifyCustomer from '../addjob/NotifyCustomer';
import { isShowNotifyCustomer } from 'common/utils/JobStatusUtils';
import { actionOpenServicePlan } from 'common/redux/actions/servicePlanAction';
import { checkAddon } from 'common/utils/AddonUtils';

const SideBar = (
    { onChangeTab, jobData, isLoading, activeAddons, onUpdateJobDetail = () => {}, onGetData = () => {} },
    ref
) => {
    const { t } = useTranslation(['jobDetail']);
    const history = useHistory();
    const { completionist } = useSelector(({ auth }) => auth.user.settings.addons.triggers) || {};
    const openTime = useSelector(({ notesReducer }) => notesReducer.toggleInsert?.openTime);
    const openTimeInsertImages = useSelector(({ photosReducer }) => photosReducer.toggleInsert?.openTime);
    const addonServicePlan = useSelector((state) => state.auth.user.settings.addons?.service_plan);

    const isActiveServicePlan = checkAddon(addonServicePlan);

    const jobDataDetail = { ...jobData.job };

    const { status: jobStatus, service_plan: servicePlan } = jobDataDetail;

    const dispatch = useDispatch();

    const refNotifyCustomer = useRef(null);

    useImperativeHandle(ref, () => ({
        getValue: () => refNotifyCustomer.current?.getValueNotifyCustomer()
    }));

    function _handleChangeTab(e, tab, moreParams = {}) {
        e && e.stopPropagation();
        onChangeTab(tab, moreParams);

        //This action close  modal insert job note to note visible ( invoice, estimate, workorder )
        if (openTime) {
            dispatch(actionToggleInsertJobNotes(null));
        }

        if (openTimeInsertImages) {
            dispatch(actionToggleInsertJobImages(null));
        }
    }

    const {
        tab: tabActive,
        invoice: jobInvoice,
        estimate: jobEstimate,
        customer: customerDetail,
        with_units_job: jobUnit,
        with_devices: jobDevices,
        with_documents: jobDocuments
    } = jobData;

    const {
        units: activeUnit,
        materials: activeMaterials,
        documents: activeDocuments,
        devices: activeDevices
    } = activeAddons;

    function _goCustomerDetail(e) {
        e.stopPropagation();

        history.push(addBranchPath(`${CUSTOMERS}/${customerDetail.id}`));
    }

    function _handleChangeStatus(response) {
        const { current_job: { job: currentJob } = {}, invoice = {} } = response.data;
        const { status: newStatus, previously_completed } = currentJob || {};
        const completeWithCompletionist = newStatus === JOB_COMPLETED && !!completionist;

        const newDataJob = {
            ...jobData.job,
            previously_completed: previously_completed,
            status: newStatus,
            invoice: {}
        };

        if (jobData.invoice) {
            onUpdateJobDetail({
                job: newDataJob,
                invoice: {
                    ...jobData.invoice,
                    status: invoice.invoice_status_id
                },
                reloadInvoiceDetail: completeWithCompletionist ? new Date().getTime() : 0
            });
        } else {
            onUpdateJobDetail({
                job: newDataJob
            });
        }
    }

    function _openPlanDetail() {
        dispatch(
            actionOpenServicePlan({
                jobId: jobDataDetail.id,
                ...onGetData()
            })
        );
    }

    if (isLoading) {
        return (
            <div className="wrap-loading contents__menu">
                <div className="customer-name">
                    <div className="loading --animation --line --half" />
                </div>
                <div className="line" />
                <div className="list-tabs">
                    <div className="list-tabs__items">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="list-tabs__items">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="list-tabs__items">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="list-tabs__items">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="list-tabs__items">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="list-tabs__items">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="list-tabs__items">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="list-tabs__items">
                        <div className="loading --animation --line --fourth" />
                    </div>
                    <div className="line mx-2" />
                    <div className="list-tabs__items">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="list-tabs__items">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="list-tabs__items">
                        <div className="loading --animation --line --fourth" />
                    </div>
                </div>
            </div>
        );
    }

    function _renderEstimate() {
        if (!jobEstimate) {
            return false;
        }
        const estimateStatus = jobEstimate.status;
        const estimateStatusConverted = t(`customers:${estimateStatus}`);
        return (
            <li
                className={`tab-items itm-has-contents ${tabActive === TABS.ESTIMATE ? 'active-tab-selector' : ''}`}
                onClick={(e) => _handleChangeTab(e, TABS.ESTIMATE, { activeTabEstimateDetail: true })}
            >
                <IconEstimate />
                {t('jobDetail:estimate')}
                <span className={`unique status-btn ${estimateStatus}`} title={estimateStatusConverted}>
                    {estimateStatusConverted}
                </span>
            </li>
        );
    }

    function _renderTabAddEstimate() {
        if (jobEstimate) {
            return false;
        }

        return (
            <li
                className={`tab-items svg-plus svg-noeffect ${
                    tabActive === TABS.ADD_ESTIMATE ? 'active-tab-selector' : ''
                }`}
                onClick={(e) => _handleChangeTab(e, TABS.ADD_ESTIMATE, { activeTabEstimate: true })}
            >
                <IconPlus />
                {t('setting:add_estimates')}
            </li>
        );
    }

    function _renderInvoice() {
        if (!jobInvoice) {
            return false;
        }
        const invoiceStatus = jobInvoice.status;
        const invoiceStatusConverted = t(`customers:${invoiceStatus}`);
        const finalName = `${invoiceStatusConverted} • ${jobInvoice.total}`;
        return (
            <li
                className={`tab-items itm-has-contents ${tabActive === TABS.INVOICE ? 'active-tab-selector' : ''}`}
                onClick={(e) => _handleChangeTab(e, TABS.INVOICE, { activeTabInvoiceDetail: true })}
            >
                <IconInvoice />
                {t('jobDetail:invoice')}
                <span className={`unique status-btn ${invoiceStatus}`} title={finalName}>
                    {finalName}
                </span>
            </li>
        );
    }

    function _renderTabAddInvoice() {
        if (jobInvoice) {
            return false;
        }

        return (
            <li
                className={`tab-items svg-plus svg-noeffect ${
                    tabActive === TABS.ADD_INVOICE ? 'active-tab-selector' : ''
                }`}
                onClick={(e) => _handleChangeTab(e, TABS.ADD_INVOICE, { activeTabInvoice: true })}
            >
                <IconPlus />
                {t('setting:add_invoices')}
            </li>
        );
    }

    function _renderUnit() {
        if (!jobUnit) {
            return null;
        }
        return (
            <li
                className={`tab-items itm-has-contents ${tabActive === TABS.UNIT ? 'active-tab-selector' : ''}`}
                onClick={(e) => _handleChangeTab(e, TABS.UNIT)}
            >
                <IconBuilding isHasColor />
                {t('jobDetail:units')}
            </li>
        );
    }

    function _renderAddUnit() {
        if (jobUnit) {
            return null;
        }
        return (
            <li
                className={`tab-items svg-plus svg-noeffect ${tabActive === TABS.UNIT ? 'active-tab-selector' : ''}`}
                onClick={(e) => _handleChangeTab(e, TABS.UNIT)}
            >
                <IconPlus />
                {t('jobDetail:add_unit')}
            </li>
        );
    }

    function _renderDevices() {
        if (!jobDevices) {
            return null;
        }
        return (
            <li
                className={`tab-items svg-device ${tabActive === TABS.DEVICES ? 'active-tab-selector' : ''}`}
                onClick={(e) => _handleChangeTab(e, TABS.DEVICES)}
            >
                <IconRouter />
                {t('jobDetail:devices')}
            </li>
        );
    }

    function _renderAddDevices() {
        if (jobDevices) {
            return null;
        }
        return (
            <li
                className={`tab-items svg-plus svg-noeffect ${tabActive === TABS.DEVICES ? 'active-tab-selector' : ''}`}
                onClick={(e) => _handleChangeTab(e, TABS.DEVICES)}
                tabIndex="0"
            >
                <IconPlus />
                {t('common:add')} {t('jobDetail:devices')}
            </li>
        );
    }

    function _renderDocuments() {
        if (!jobDocuments) {
            return null;
        }

        return (
            <li
                className={`tab-items ${tabActive === TABS.DOCUMENTS ? 'active-tab-selector' : ''}`}
                onClick={(e) => _handleChangeTab(e, TABS.DOCUMENTS)}
                tabIndex="0"
            >
                <IconDocument />
                {t('jobDetail:documents')}
            </li>
        );
    }

    function _renderAddDocuments() {
        if (jobDocuments) {
            return null;
        }

        return (
            <li
                className={`tab-items svg-plus svg-noeffect ${
                    tabActive === TABS.DOCUMENTS ? 'active-tab-selector' : ''
                }`}
                onClick={(e) => _handleChangeTab(e, TABS.DOCUMENTS)}
            >
                <IconPlus />
                {t('common:add')} {t('jobDetail:documents')}
            </li>
        );
    }

    return (
        <div className="contents__menu scrolls">
            <div className="customer-name" onClick={(e) => _goCustomerDetail(e)}>
                <div className="txt-ellipsis cursor-pointer">{customerDetail?.full_name}</div>
            </div>
            {!!servicePlan && isActiveServicePlan && (
                <div className="customer-name flex-column gap-2 align-top">
                    <div className="fs-13 black-darker2">{t('setting:service_plan')}</div>
                    <div onClick={_openPlanDetail} className="txt-ellipsis cursor-pointer w-100">
                        {servicePlan.name}
                    </div>
                </div>
            )}

            <Status
                jobData={{
                    ...jobDataDetail,
                    invoice: jobData.invoice
                }}
                onChange={_handleChangeStatus}
            />
            <div className="line" />
            <ul className="tab-selectors">
                <li
                    onClick={(e) => _handleChangeTab(e, TABS.DETAIL)}
                    className={`tab-items itm-has-contents ${tabActive === TABS.DETAIL ? 'active-tab-selector' : ''}`}
                >
                    <IconNote />
                    {t('jobDetail:job_details')}
                </li>
                <li
                    onClick={(e) => _handleChangeTab(e, TABS.SCHEDULING, { activeTabScheduling: true })}
                    className={`tab-items itm-has-contents svg-calendar svg-noeffect ${
                        tabActive === TABS.SCHEDULING ? 'active-tab-selector' : ''
                    }`}
                >
                    <IconCalendar />
                    {t('jobDetail:scheduling')}
                </li>
                <li
                    className={`tab-items itm-has-contents svg-jobs ${
                        tabActive === TABS.WORK_ORDER ? 'active-tab-selector' : ''
                    }`}
                    onClick={(e) => _handleChangeTab(e, TABS.WORK_ORDER, { activeTabWorkOrder: true })}
                >
                    <IconWorkOrder isHasColor />
                    {t('jobDetail:work_order')}
                </li>
                {activeUnit && _renderUnit()}
                {_renderInvoice()}
                {_renderEstimate()}
                {activeMaterials && (
                    <li
                        className={`tab-items itm-has-contents ${
                            tabActive === TABS.MATERIALS ? 'active-tab-selector' : ''
                        }`}
                        onClick={(e) => _handleChangeTab(e, TABS.MATERIALS)}
                    >
                        <IconMaterial />
                        {t('jobDetail:materials')}
                    </li>
                )}

                {activeDocuments && _renderDocuments()}
                {activeDevices && _renderDevices()}
                <div className="line mx-2"></div>
                {activeUnit && _renderAddUnit()}
                {_renderTabAddInvoice()}
                {_renderTabAddEstimate()}
                {activeDocuments && _renderAddDocuments()}
                {activeDevices && _renderAddDevices()}
            </ul>
            <div className="wrap-notify flex-column flex-1 gap-4">
                {isShowNotifyCustomer(jobStatus) ? <NotifyCustomer ref={refNotifyCustomer} status={jobStatus} /> : null}
            </div>
        </div>
    );
};

export default forwardRef(SideBar);

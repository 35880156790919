import { COMMON } from 'app/const/App';
import { NUMBER_FIELDS } from 'app/const/setting/SettingTiles';
import { SkeletonFooter, SkeletonListOptions, SkeletonReviewBox } from './components/SkeletonLoading';
import {
    getListSettingsTiles,
    updateListSettingsTiles,
    getListOptionsTiles
} from 'common/redux/actions/settings/tilesAction';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LIST_TOOLTIP } from 'app/const/Settings';
import { reducer } from 'app/const/Reducer';
import loadable from '@loadable/component';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import { ACCESS_SETTINGS_TAB } from 'app/const/Permissions';
import GDStatusBar from 'app/components/status/statusbar';

const GdButton = loadable(() => import('app/components/button'));
const BoxTipsSetting = loadable(() => import('../components/BoxTipsSetting'));
const ListOptions = loadable(() => import('./components/ListOptions'));
const PreviewBox = loadable(() => import('./components/PreviewBox'));
const MainHeaderSettings = loadable(() => import('app/modules/settings/components/MainHeaderSettings'));

export default function SettingsTiles() {
    const { t } = useTranslation(['setting']);
    const dispatch = useDispatch();
    const refAlert = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        isUpdated: false,
        data: [],
        options: [],
        isInitCallApi: true
    });

    const { isInitCallApi, isUpdated, data, options } = state;

    useEffect(() => {
        _getList(true);
    }, []);

    const _getListOptions = (initDataList) => {
        dispatch(getListOptionsTiles(_getListOptionsSuccess, _getListOptionsFailure, initDataList));
    };

    const _getListOptionsSuccess = (response) => {
        const emptyOption = { id: '', name: '' };
        dispatchState({
            options: [emptyOption, ...response.data],
            data: response.initDataList,
            isInitCallApi: false,
            isLoading: false
        });
    };

    const _getListOptionsFailure = (response) => {
        const emptyOption = { id: '', name: '' };
        dispatchState({
            options: [emptyOption],
            data: response.initDataList,
            isInitCallApi: false,
            isLoading: false
        });
    };

    const _getList = (isFirstTime) => {
        !isFirstTime && dispatchState({ isLoading: true });
        dispatch(getListSettingsTiles(_getListSuccess, _getListFailure));
    };

    const _getListSuccess = (response) => {
        const newData = _transformResponseToData(response.data);
        if (state.isInitCallApi) {
            _getListOptions(newData);
        } else {
            dispatchState({ isLoading: false, data: newData, isInitCallApi: false });
        }
    };

    const _transformResponseToData = (responseData) => {
        const result = [];
        for (let index = 0; index < NUMBER_FIELDS; index += 2) {
            const dataField1 = {
                value: responseData[index]?.value || '',
                id: responseData[index]?.id || index + 1
            };
            const dataField2 = {
                value: responseData[index + 1]?.value || '',
                id: responseData[index + 1]?.id || index + 2
            };
            const row = [dataField1, dataField2];
            result.push(row);
        }
        return result;
    };

    const _transformDataToParams = (data) => {
        const result = [];
        for (let index = 0; index < NUMBER_FIELDS / 2; index++) {
            result.push(data[index][0], data[index][1]);
        }
        return result;
    };

    const _getListFailure = (err) => {
        dispatchState({ isLoading: false, data: [], isInitCallApi: false });
        checkAccessFail(err, ACCESS_SETTINGS_TAB);
    };

    const _updateListSuccess = (response) => {
        const newData = _transformResponseToData(response.data);
        _handleShowStatus({ message: t('setting:update_tiles_success'), type: COMMON.SUCCESS });
        dispatchState({ isLoading: false, data: newData, isUpdated: true });
    };

    const _updateListFailure = ({ message }) => {
        _handleShowStatus({ message: !!message?.length ? message : t('setting:update_tiles_failure') });
        dispatchState({ isLoading: false });
    };

    const _onChange = (newData) => {
        dispatchState({ data: newData, isUpdated: false });
    };

    const _handleSave = () => {
        const params = {
            options: _transformDataToParams(state.data)
        };
        dispatchState({ isLoading: true });
        dispatch(updateListSettingsTiles(params, _updateListSuccess, _updateListFailure));
    };

    const _renderFooter = () => {
        if (state.isInitCallApi) return <SkeletonFooter />;
        return (
            <div className="form-footer">
                <div className="form-footer__action">
                    <GdButton
                        className="v2-btn-main"
                        title={t('setting:save_changes')}
                        onClick={_handleSave}
                        isLoading={state.isLoading}
                    />
                </div>
            </div>
        );
    };

    const _handleShowStatus = ({ message, type = COMMON.ERROR }) => {
        refAlert.current?.showStatusBar('status_tiles', message, type);
    };

    return (
        <>
            <MainHeaderSettings />
            <div className="wrapper-columns">
                <div className="contents-pages tiles">
                    <div className="wrapper-box-edit">
                        <GDStatusBar ref={refAlert} />
                        <BoxTipsSetting typeId={LIST_TOOLTIP.CALENDAR_TILES} />
                        <div className="scrolls box-auto p-8">
                            {isInitCallApi ? <SkeletonReviewBox /> : <PreviewBox dataExample={data} />}
                            {isInitCallApi ? (
                                <SkeletonListOptions />
                            ) : (
                                <ListOptions
                                    onChange={_onChange}
                                    dataExample={data}
                                    dataOptions={options}
                                    isUpdated={isUpdated}
                                />
                            )}
                        </div>
                        {_renderFooter()}
                    </div>
                </div>
            </div>
        </>
    );
}

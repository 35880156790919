import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';

import ButtonSave from 'app/components/button/ButtonSave';
import GDModalWarning from 'app/components/modal/ModalWarning';
import { ADDONS_CALENDAR } from 'app/const/Api';
import { GET_REFETCH_TOKEN_GOOGLE_CALENDAR_SYNC, GET_TOKEN_GOOGLE_CALENDAR_SYNC } from 'app/const/api/V2';
import { DISCOVERY_DOCS_GOOGLE_CALENDAR } from 'app/const/Keys';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import IconClose from 'assets/icon/IconClose';
import { clientQuery } from 'common/utils/ApiUtils';
import AddonsAlert from '../addons/components/AddonsAlert';
import CalendarSetup from './components/CalendarSetup';
import CalendarSyncConnect from './components/CalendarSyncConnect';
import CalendarSyncLoading from './components/CalendarSyncLoading';

const SyncGoogleCalendar = (props, ref) => {
    const { t } = useTranslation();
    const gapi = window.gapi;
    const refFirstTime = useRef(true);

    const googleData = useSelector(({ auth }) => auth.user.settings.google);
    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        isLoading: true,
        isConnected: false,
        isFirstConnect: true,
        dataAuth: {},
        calendarList: [],
        email: ''
    });
    const refCalendarSetup = useRef(null);
    const refButtonSync = useRef(null);
    const refAlert = useRef(null);
    const refWarning = useRef(null);
    const refButtonSave = useRef(null);
    const { isOpen, isLoading, isConnected, isFirstConnect, calendarList, dataAuth, email, dataSync } = state;

    useImperativeHandle(ref, () => ({ _open, _close }));

    useEffect(() => {
        if (!gapi || !isOpen) return;

        if (refFirstTime.current) {
            gapi.load('client:auth', () => {
                gapi.client.load('calendar', 'v3', () => {
                    refFirstTime.current = false;
                    getDetailCalendar();
                });
            });
        } else {
            getDetailCalendar();
        }
    }, [isOpen]);

    const _open = () => {
        dispatchState({ isOpen: true, isLoading: true });
    };

    const getDetailCalendar = () => {
        clientQuery(ADDONS_CALENDAR, { method: 'GET' }, _handleGetDetailSuccess);
    };

    const _handleGetDetailSuccess = ({ data }) => {
        dispatchState({ isLoading: false, dataSync: data, isConnected: !!data, isFirstConnect: !data });
        if (!!data) _handleRefreshToken();
    };

    const _handleRefreshToken = () => {
        const _handleRefreshSuccess = ({ data }) =>
            dispatchState({ dataAuth: { access_token: data.token }, access_token: data.token });
        clientQuery(GET_REFETCH_TOKEN_GOOGLE_CALENDAR_SYNC, { data: {}, method: 'GET' }, _handleRefreshSuccess);
    };

    const _close = () => {
        dispatchState({ isOpen: false, access_token: '' });
    };

    const _handleAuth = () => {
        if (isConnected) {
            refWarning.current._open();
            refButtonSync.current._setRemoveLoading();
            return;
        }

        gapi.auth.authorize(
            {
                client_id: googleData.client_id,
                discoveryDocs: DISCOVERY_DOCS_GOOGLE_CALENDAR.split(','),
                scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email openid',
                immediate: false,
                cookie_policy: 'single_host_origin',
                access_type: 'offline',
                approval_prompt: 'force',
                response_type: 'code'
            },
            _handleAuthSuccess
        );
    };

    const _handleAuthSuccess = ({ code, error }) => {
        /* Checking if the code is empty or if there is an error. If either of those are true, it will break code */
        if (!code || error) return refButtonSync?.current?._setRemoveLoading(false);

        const _handleGetSuccess = ({ data }) => {
            dispatchState({ access_token: data.access_token, dataAuth: data });
            _handleGetDataInformation(data.access_token);
        };
        clientQuery(GET_TOKEN_GOOGLE_CALENDAR_SYNC, { data: { code }, method: 'POST' }, _handleGetSuccess);
    };

    const _handleGetDataInformation = (access_token) => {
        fetch(`https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${access_token}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => response.json())
            .then((data) => {
                refButtonSync.current._setRemoveLoading(false);
                dispatchState({ isConnected: true, email: data.email });
                setTimeout(() => {
                    refCalendarSetup.current.setIsChangeInfo(true);
                }, 0);
            })
            .catch(() => refButtonSync.current._setRemoveLoading(false));
    };

    const _handleConfirmDisconnect = () => {
        const _handleDisconnectSuccess = ({ message }) => {
            dispatchState({
                dataAuth: {},
                calendarList: [],
                email: '',
                isLoading: false,
                isOpen: true,
                isFirstConnect: true,
                isConnected: false
            });
            refCalendarSetup.current.setIsChangeInfo(false);
            refButtonSync.current._setRemoveLoading(false);
            _handleShowAlert({ id: 'disconnect_success', message, status: LIST_STATUS.SUCCESS });
        };

        const _handleDisconnectError = ({ message }) => {
            refButtonSync.current._setRemoveLoading(false);
            _handleShowAlert({ id: 'disconnect_failed', message, status: LIST_STATUS.ERROR });
        };

        clientQuery(
            ADDONS_CALENDAR,
            { data: {}, method: 'DELETE' },
            _handleDisconnectSuccess,
            _handleDisconnectError,
            _handleCloseConfirm
        );
    };

    const _handleCloseConfirm = () => {
        refWarning.current._close();
    };

    const _handleShowAlert = (alert) => {
        refAlert.current.showStatusBar(alert);
    };

    const _handleSaveSuccess = (message) => {
        _handleShowAlert({ id: 'setup_sync_success', message, status: LIST_STATUS.SUCCESS });
        isFirstConnect && dispatchState({ isFirstConnect: false });
    };

    if (!isOpen) return null;
    return (
        <>
            <ReactModal
                id="sync_google"
                isOpen
                style={{ overlay: { background: 'transparent' } }}
                className="modal container-modal container-sync-gg open"
                onRequestClose={_close}
            >
                <div className="modal__overlay bg-fixed" onClick={_close} />
                <div className="modal__container --preview-r">
                    {isLoading ? (
                        <CalendarSyncLoading />
                    ) : (
                        <>
                            <div className="header-modal">
                                <h3 className="header-modal__label">{t('calendar:google_calendar')}</h3>
                                <div className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                                    <IconClose />
                                </div>
                            </div>
                            <div className="body-modal scrolls">
                                <AddonsAlert ref={refAlert} />
                                <p className="desc">{t('calendar:google_calendar_desc')}</p>
                                <p className="desc mb-5">{t('calendar:google_calendar_message')}</p>
                                <CalendarSyncConnect
                                    ref={refButtonSync}
                                    isConnected={isConnected}
                                    isFirstConnect={isFirstConnect}
                                    onClose={_close}
                                    onAuth={_handleAuth}
                                />
                                <CalendarSetup
                                    ref={refCalendarSetup}
                                    dataSync={dataSync}
                                    isConnected={isConnected}
                                    isFirstConnect={isFirstConnect}
                                    calendarList={calendarList}
                                    dataAuth={dataAuth}
                                    email={email}
                                    access_token={state.access_token}
                                    onSaveSuccess={_handleSaveSuccess}
                                />
                            </div>
                        </>
                    )}
                </div>
            </ReactModal>

            <GDModalWarning
                ref={refWarning}
                isLargeContent={false}
                title={t('common:disconnect')}
                description={t('calendar:are_you_sure_you_want_to_disconnect')}
                footer={
                    <div className="footer-modal footer-hasbtn btn-close">
                        <span className="v2-btn-default --transparent" onClick={_handleCloseConfirm}>
                            {t('common:cancel')}
                        </span>
                        <ButtonSave
                            ref={refButtonSave}
                            wrapClass="v2-btn-main"
                            title={t('common:yes')}
                            onSave={_handleConfirmDisconnect}
                        />
                    </div>
                }
            />
        </>
    );
};

SyncGoogleCalendar.displayName = 'SyncGoogleCalendar';

export default forwardRef(SyncGoogleCalendar);

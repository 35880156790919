export default function IconProfile() {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 13.5C13.3807 13.5 14.5 11.933 14.5 10C14.5 8.067 13.3807 7.5 12 7.5C10.6193 7.5 9.5 8.067 9.5 10C9.5 11.933 10.6193 13.5 12 13.5Z"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.68848 17.2796C7.06396 16.8268 7.52281 16.4535 8.05702 16.1678C8.87184 15.7321 9.7847 15.5 11.3856 15.5H12.6126C14.2136 15.5 15.1264 15.7321 15.9413 16.1678C16.4446 16.4371 16.8811 16.784 17.244 17.2021"
                stroke="var(--color-icon)"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

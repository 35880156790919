import React from 'react';
import { useTranslation } from 'react-i18next';

import { LIST_VIDEO_LIBRARY } from '../../constant';
import GDIframeObserver from 'app/components/iframe/IframeObserver';

const OnBoardingVideoLibrary = () => {
    const { t } = useTranslation('onboarding');

    return (
        <div className="onboarding-items onboarding-library">
            <h5 className="black fw-500 fs-15 mb-3">{t('video_library')}</h5>
            <div className="library-list flextop gap-16">
                {LIST_VIDEO_LIBRARY.map((item) => {
                    const finalTitle = t(`onboarding:${item.title}`);
                    return (
                        <div key={item.id} className="library-items">
                            <GDIframeObserver
                                className="library-iframe"
                                link={item.link}
                                title={finalTitle}
                                loadingComponent={() => <div className="library-iframe mb-2 loading --animation" />}
                            />
                            <p className="black fs-14 fw-500 mt-1" title={finalTitle}>
                                {finalTitle}
                            </p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default OnBoardingVideoLibrary;

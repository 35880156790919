import { LIST_TABLE_ITEM_TYPE } from 'app/const/App';

export const getGridColumnsActiveVoipUser = ({ groupNumber, isVoipTechnicianSeat = false }) => {
    const header = {
        user: {
            title: isVoipTechnicianSeat ? 'technician_user' : 'user',
            style: `col col-name${isVoipTechnicianSeat ? ' flexcenter gap-4' : ''}`
        },
        personal_number: {
            title: 'personal_local_number',
            style: 'col col-lg'
        },
        group_number: {
            title: 'group_number',
            style: 'col col-group-call'
        },
        call_recording: {
            title: 'call_recording',
            style: 'col col-xl'
        },
        transcription: {
            title: 'generate_transcript',
            style: 'col col-xl'
        },
        edit: {
            title: '',
            style: 'col --menu'
        }
    };
    const columns = [
        {
            id: 'user',
            title: 'user',
            status: true,
            tooltipText: isVoipTechnicianSeat ? 'technician_user_tooltip' : ''
        },
        {
            id: 'personal_number',
            title: 'personal_number',
            status: true
        },
        {
            id: 'group_number',
            title: 'group_number',
            status: !!groupNumber.length
        },
        {
            id: 'call_recording',
            title: 'call_recording',
            status: true
        },
        {
            id: 'transcription',
            title: 'generate_transcript',
            status: true
        },
        {
            id: 'edit',
            title: '',
            status: true
        }
    ];
    const contentConfig = {
        user: {
            title: 'user',
            style: 'col col-name wrapper-call py-1',
            type: LIST_TABLE_ITEM_TYPE.USER_VOIP
        },
        personal_number: {
            title: 'personal_local_number',
            style: 'col col-lg',
            type: LIST_TABLE_ITEM_TYPE.PERSONAL_NUMBER_VOIP
        },
        group_number: {
            title: 'group_number',
            style: 'col col-group-call flexcenter gap-10',
            type: LIST_TABLE_ITEM_TYPE.GROUP_NUMBER_VOIP
        },
        call_recording: {
            title: 'call_recording',
            style: 'col col-xl',
            type: LIST_TABLE_ITEM_TYPE.CALL_RECORDING_VOIP
        },
        transcription: {
            title: 'generate_transcript',
            style: 'col col-xl',
            type: LIST_TABLE_ITEM_TYPE.CALL_TRANSCRIPTION
        },
        edit: {
            title: '',
            style: 'col --menu',
            type: LIST_TABLE_ITEM_TYPE.EDIT_ACTIVE_USER_VOIP
        }
    };

    return {
        header,
        columns,
        contentConfig
    };
};

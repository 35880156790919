import React from 'react';
import { useTranslation } from 'react-i18next';

import { CREDIT_STATUS_COLORS, ONE_HUNDRED_PERCENT } from 'app/const/addons';
import { formatMoneyProgressBalance } from 'common/utils/FunctionUtils';

const AddonProgressBalance = ({
    rechargeThreshold = 0,
    balance = 0,
    credit = 0,
    usageThisMonth = 0,
    expireDate = ''
}) => {
    const { t } = useTranslation(['addons']);

    const perCredit = credit > 0 ? Math.round((usageThisMonth / credit) * ONE_HUNDRED_PERCENT) : 0;
    const colorCredit = perCredit >= ONE_HUNDRED_PERCENT ? CREDIT_STATUS_COLORS.GREEN : CREDIT_STATUS_COLORS.ORANGE;
    const calcBalance = usageThisMonth - credit;
    const balanceUsed = calcBalance >= 0 ? calcBalance : 0;
    const perBalance = Math.round((balanceUsed / balance) * ONE_HUNDRED_PERCENT) || 0;
    const perBelow = Math.round((rechargeThreshold / balance) * ONE_HUNDRED_PERCENT) || 0;

    return (
        <div className="wrap-plans__balance">
            <p>
                {t('your_team_has_used')}{' '}
                <b className="fw-600">{formatMoneyProgressBalance({ number: usageThisMonth })}</b>
                {!!expireDate ? t('which_resets_on', { date: expireDate }) : null}
            </p>
            <div className="flex-betweentop col-gap-4">
                {!!credit && (
                    <div className="balance-credit">
                        <div className={`progress-bar --${colorCredit} --lg per-${perCredit}`}>
                            <span />
                        </div>
                        <span className={`status-btn fs-11 bg-${colorCredit}-default`}>
                            {`\$${credit} ${t('credit')}`}
                        </span>
                    </div>
                )}
                <div className="balance-remaining flex-1 text-right">
                    <div
                        className={`progress-bar --blue --lg per-${perBalance} balance-remaining-${perBelow} relative`}
                    >
                        <span className="relative">
                            <span className="remaining-value absolute blue-default fs-10 fw-600">
                                {formatMoneyProgressBalance({ number: balanceUsed })}
                            </span>
                        </span>
                        <div className="divide-bar is-balance"></div>
                    </div>
                    <span className="fw-600">{formatMoneyProgressBalance({ number: balance })}</span>
                </div>
            </div>
        </div>
    );
};

export default AddonProgressBalance;

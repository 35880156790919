import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import IconTooltip from 'assets/icon/IconTooltip';
import EditCompanyAddress from './EditCompanyAddress';

const BoxHeader = ({ companyAddress = '', onEditAddress = () => {} }) => {
    const { t } = useTranslation();
    const refEditAddress = useRef(null);

    const _handleEditAddress = () => refEditAddress.current._open(companyAddress);

    return (
        <>
            <div className="boxs__header has-form">
                <h5 className="has-form__label fs-13 mb-1">
                    {t('report:company_address')}
                    <div className="tooltip">
                        <IconTooltip />
                        <span className="tooltiptext top">{t('common:changes_the_address')}</span>
                    </div>
                </h5>
                <p className="desc" dangerouslySetInnerHTML={{ __html: companyAddress }} />
                <div className="v2-btn-default btn-modal" onClick={_handleEditAddress}>
                    {t('common:edit')}
                </div>
            </div>
            <EditCompanyAddress ref={refEditAddress} onEditAddress={onEditAddress} />
        </>
    );
};

export default BoxHeader;

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import GDModalWarning from 'app/components/modal/ModalWarning';
import { ADVANCE_ROUTING_OPTIONS_KEYWORDS } from 'app/const/addons';
import { ERROR_CODE } from 'app/const/App';
import { LIST_STATUS } from 'app/const/Status';
import IconCalendarPin from 'assets/icon/IconCalendarPin';
import IconsEvent from 'assets/icon/IconsEvent';
import { AUTH_TYPE } from 'common/redux/actions/authAction';
import JobMagnetModal from './components/magnet';
import Header from './header';
import MainCalendar from './MainCalendar';
import Map from './map';
import Timeoff from './timeoff';
import { convertEventToDataMarker } from './ultil/Map';

const CalendarContainer = () => {
    const { t } = useTranslation(['header']);
    const dispatch = useDispatch();
    const refMainCalendar = useRef(null);
    const refHeader = useRef(null);
    const refWrapperDiv = useRef(null);
    const refMapView = useRef(null);
    const refJobMagnetModal = useRef(null);
    const refModalWarning = useRef(null);

    const _goTodate = (date) => {
        refMainCalendar.current.onGoToDate(date);
    };

    const _getTitle = () => refMainCalendar?.current?.onGetTitle() || '';
    const _nextAgenda = () => refMainCalendar.current.onNext();
    const _prevAgenda = () => refMainCalendar.current.onPrev();
    const _onToday = (shouldFetch = true) => refMainCalendar.current.onToday(shouldFetch);
    const _toggleMap = (isClose = false) => {
        isClose ? refMapView.current._openMap() : refMapView.current._closeMap();
        setTimeout(() => {
            refMainCalendar.current && refMainCalendar.current.onResizeCalendar();
        }, 100);
    };
    const _getCurrentRange = () => refMainCalendar.current.onGetRangeDate();

    const _changeView = () => refMainCalendar.current.onChangeView();

    const _reloadEventsOptimized = () => refMainCalendar.current.reloadEventsOptimized();

    const _handleUpdateEvents = (...rest) => {
        refMapView.current._handleUpdateEvents(...rest);
    };

    const _handleRefetchCalendar = () => {
        refMainCalendar.current.onRefetch();
    };

    const _handleSelectMarker = (...rest) => {
        refMainCalendar.current.onSelectMarker(...rest);
    };

    const _handleResizeCalendar = () => {
        refMainCalendar.current && refMainCalendar.current.onResizeCalendar();
    };

    const _handleServiceClick = (...rest) => {
        refMainCalendar.current.onServiceClick(...rest);
    };

    const _handleDisplayPopup = ({ eventJob, customer, event, location }) => {
        if (refMapView.current) {
            refMapView.current._displayPopup(
                [+location.lng, +location.lat],
                convertEventToDataMarker({ eventJob, customer, event, location }),
                true
            );
        }
    };

    const handleActivateMagnet = ({ cbFinish = () => {} }) => {
        const handleActions = ({ type, message = '', statusCode } = {}) => {
            switch (type) {
                case LIST_STATUS.ERROR:
                    cbFinish();
                    handleWarning({ message, statusCode });
                    break;
                case LIST_STATUS.SUCCESS:
                    cbFinish();
                    break;
                default:
                    cbFinish();
                    break;
            }
        };
        refMainCalendar.current && refMainCalendar.current.onActivateMagnet(null, handleActions);
    };

    const handleAdjustMagnet = (valueAdjust, cbFinish = () => {}) => {
        refMainCalendar.current && refMainCalendar.current.onAdjustMagnet(valueAdjust, cbFinish);
    };

    const handleConfirm = ({ statusCode }) => {
        refModalWarning.current?._close();
        if (statusCode === ERROR_CODE.PERMISSION_DENIED) {
            dispatch({
                type: AUTH_TYPE.UPDATE_ADVANCE_ROUTING,
                payload: { keyword: ADVANCE_ROUTING_OPTIONS_KEYWORDS.JOB_MAGNET, data: 0 }
            });
        }
    };

    const handleWarning = ({ message, statusCode }) => {
        if (refModalWarning.current) {
            refModalWarning.current._open({
                description: message,
                onConfirm: () => handleConfirm({ message, statusCode })
            });
        }
    };

    const handleSetIconMap = (value) => {
        refHeader.current?.setIconMap(value);
    };

    return (
        <>
            <div ref={refWrapperDiv} id="displayView" className="calendar-container">
                <Header
                    ref={refHeader}
                    onGoToDate={_goTodate}
                    onNext={_nextAgenda}
                    onPrev={_prevAgenda}
                    onToday={_onToday}
                    onGetTitle={_getTitle}
                    onToggleMap={_toggleMap}
                    onChangeView={_changeView}
                    onReloadEventsOptimized={_reloadEventsOptimized}
                    onActivateMagnet={handleActivateMagnet}
                    onAdjustMagnet={handleAdjustMagnet}
                />
                <div id="wrapper_main_calendar" className="wrapper-main-page">
                    <Map
                        id="map"
                        ref={refMapView}
                        containerClassName="container-map"
                        onRefetchCalendar={_handleRefetchCalendar}
                        onServiceClick={_handleServiceClick}
                        onGetRangeDate={_getCurrentRange}
                        onSelectedMarker={_handleSelectMarker}
                        onResizeCalendar={_handleResizeCalendar}
                        onSetIconMap={handleSetIconMap}
                    />

                    <MainCalendar
                        ref={refMainCalendar}
                        onUpdateEvents={_handleUpdateEvents}
                        onDisplayPopup={_handleDisplayPopup}
                        onWarning={handleWarning}
                    />
                </div>
            </div>
            <Timeoff />
            <JobMagnetModal ref={refJobMagnetModal} onRefetchCalendar={_handleRefetchCalendar} />
            {/* This SVG sprite for job magnet, maybe update all for calendar later */}
            <IconCalendarPin />
            <GDModalWarning
                id="calendar_warning"
                ref={refModalWarning}
                descriptionClassName="text"
                title={t('gorilladesk_says')}
                isDisplayClose={false}
                footer={<FooterConfirm />}
            />
            {/* All icons in EventContent */}
            <IconsEvent />
        </>
    );
};

const FooterConfirm = ({ onConfirm }) => {
    const { t } = useTranslation();
    return (
        <div className="footer-modal btn-close">
            <div className="v2-btn-main" onClick={onConfirm}>
                {t('confirm')}
            </div>
        </div>
    );
};

export default CalendarContainer;

import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getListBuildingAndUnit } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import ManageBuildingUnit from 'app/modules/jobdetail/tabs/unit/manage';
import IconBuilding from 'assets/icon/IconBuilding';
import { clientQuery } from 'common/utils/ApiUtils';

const ManageUnit = ({ locationId = '', units = 0, customerId = '' }, ref) => {
    const { t } = useTranslation();
    const isDisplayMDU = useSelector(({ auth }) => !!auth.user.settings.addons.mdu_tracking);
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: !!locationId,
        totalUnits: units,
        mdu: [],
        unitContextData: {
            buildingData: [],
            unitData: [],
            jobId: '',
            locationId,
            withoutFetch: !!!locationId,
            customerId: customerId
        }
    });

    const { unitContextData, totalUnits, isLoading } = state;
    const refManageUnit = useRef(null);

    useImperativeHandle(ref, () => ({ open: _handleOpen, getValue: _handleGetValue }));

    useEffect(() => {
        const _handleGetSuccess = ({ data }) =>
            dispatchState({
                isLoading: false,
                unitContextData: {
                    ...unitContextData,
                    buildingData: data.building || [],
                    unitData: data.unit_details || []
                }
            });
        locationId && clientQuery(getListBuildingAndUnit(locationId), { method: 'GET' }, _handleGetSuccess);
    }, [locationId]);

    const _handleGetValue = () => {
        if (!isDisplayMDU) return {};
        const { buildingData, unitData } = unitContextData;

        const result = buildingData
            .map((item) => {
                const building = { building: item.name, units: [], deleted: item.deleted };
                const unitOfBuilding = [...item.units];

                if (unitOfBuilding.length) {
                    building['units'] = unitOfBuilding
                        .map((itemUnit) => {
                            const unitInfo = unitData.find((unit) => unit.unit_id === itemUnit.id) || {};

                            return {
                                name: itemUnit.name || '',
                                first_name: unitInfo.first_name || '',
                                last_name: unitInfo.last_name || '',
                                email: unitInfo.email || '',
                                phone: unitInfo.phone || [],
                                top_note: unitInfo.top_note || '',
                                deleted: itemUnit.deleted
                            };
                        })
                        .filter((itemFilter) => itemFilter.deleted !== -1);
                }
                return building;
            })
            .filter((itemBuilding) => itemBuilding.deleted !== -1);

        return result;
    };

    const _handleOpen = () => {
        refManageUnit.current && refManageUnit.current.open();
    };

    const _handleUpdateUnit = (buildingData, unitData) => {
        dispatchState((prev) => {
            return {
                ...prev,
                totalUnits: buildingData
                    .filter((itemBuilding) => itemBuilding.deleted !== -1)
                    .reduce(
                        (total, building) =>
                            total + building.units.filter((itemUnit) => itemUnit.deleted !== -1).length,
                        0
                    ),
                mdu: buildingData,
                unitContextData: {
                    ...prev.unitContextData,
                    buildingData,
                    unitData
                }
            };
        });
    };

    if (!isDisplayMDU) return null;
    return (
        <>
            <div className="rows rows--units">
                <div className="txt txt-ellipsis">
                    {' '}
                    {totalUnits} {t('customers:units')}{' '}
                </div>
                <div className="v2-btn-default has-icon btn-modal" onClick={_handleOpen}>
                    <IconBuilding />
                    {t('customers:manage_units')}
                </div>
            </div>

            {!isLoading && (
                <ManageBuildingUnit
                    unitContextData={unitContextData}
                    onUpdate={_handleUpdateUnit}
                    ref={refManageUnit}
                />
            )}
        </>
    );
};

export default forwardRef(ManageUnit);

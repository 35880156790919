import { useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';
import AddForm from 'app/modules/jobdetail/tabs/documents/components/AddForm';
import Confirm from 'app/modules/jobdetail/tabs/documents/components/Confirm';
import IconPlus from 'assets/icon/IconPlus';
import IconDocument from 'assets/icon/IconDocument';
import IconClose from 'assets/icon/IconClose';
import NameTagPdf from 'app/modules/addons/documents/components/manage/NameTagPdf';

export default function DocumentsService({ tabActive }) {
    const { t } = useTranslation(['jobDetail']);
    const { repeatSettings, onChangeDataRepeatContext } = useContext(DataRepeatContext);

    const refAddForm = useRef(null);
    const refConfirm = useRef(null);
    const listDocuments = repeatSettings.documents || [];

    const _handleOpenAddForm = () => {
        listDocuments.forEach((item) => {
            refAddForm.current?._setDefault({ key: item.parent_id, value: item });
        });
        refAddForm.current?._handleOpenForm({ selected: listDocuments.map((item) => item.parent_id) });
    };

    function _handleSubmit(selected, listSelected) {
        listSelected = listSelected.map(({ id, name, parent_id, type }) => {
            return { parent_id: id || parent_id, type, name };
        });

        refAddForm.current?._handleCloseForm();
        onChangeDataRepeatContext({
            ...repeatSettings,
            documents: listSelected
        });
    }

    function _handleDelete(id) {
        onChangeDataRepeatContext({
            ...repeatSettings,
            documents: listDocuments.filter((item) => item.parent_id !== id)
        });
        refConfirm.current._handleCloseForm();
    }

    function _openConfirmDelete(e, document) {
        refConfirm.current._handleOpenForm(document.parent_id, document.name);
    }

    function _renderListDocuments() {
        return listDocuments.map((item) => {
            return (
                <div className="content-elm rows rows-menu" key={item.parent_id}>
                    <NameTagPdf wrapClassName="rows__name" icon={<IconDocument />} {...item} />
                    <div
                        className="v2-btn-default --icon-r --delete btn-modal"
                        onClick={(e) => _openConfirmDelete(e, item)}
                        tabIndex="0"
                    >
                        {t('common:remove')}
                        <IconClose />
                    </div>
                </div>
            );
        });
    }

    if (!tabActive) {
        return false;
    }

    return (
        <div className={'tab-conts form-tabpane-details form-documents tab-content-active'}>
            <div className="container-setting-center">
                <div className={'wrapper-dashboard container-column form-has-content'}>
                    <div className="header-modal">
                        <div className="name-tabs">{t('jobDetail:documents')}</div>
                        <div
                            onClick={(e) => {
                                _handleOpenAddForm(e);
                            }}
                            className="btn-modal v2-btn-main has-icon svg-plus white"
                            tabIndex="0"
                        >
                            <IconPlus />
                            {t('jobDetail:add_document')}
                        </div>
                    </div>
                    <div className="box-auto scrolls">{_renderListDocuments()}</div>
                </div>
            </div>
            <AddForm ref={refAddForm} handleSubmit={_handleSubmit} />
            <Confirm ref={refConfirm} title={t('addons:delete_document')} handleConfirm={_handleDelete} />
        </div>
    );
}

import { reducer } from 'app/const/Reducer';
import IconPlus from 'assets/icon/IconPlus';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer } from 'react';
import EmailItem from './ItemEmailTo';
import { useTranslation } from 'react-i18next';

const EmailTo = forwardRef(
    ({ selected = [], openOptions, isFirstTimeFetch, isLocationEmail = false, data, onSelect = () => {} }, ref) => {
        const { t } = useTranslation(['customers']);
        const EMPTY_OPTION = { id: '', email: t('customers:none'), type: 'empty' };
        const [state, dispatchState] = useReducer(reducer, {
            options: selected.length > 0 ? selected : [EMPTY_OPTION]
        });

        useEffect(() => {
            dispatchState({ options: selected.length > 0 ? selected : [EMPTY_OPTION] });
        }, [selected]);

        const { options: finalOptions } = state;
        const canAddMore = finalOptions.length < 3;

        function _addNewItem() {
            if (finalOptions.length >= 3) return;
            const newOptions = finalOptions.concat(EMPTY_OPTION);
            dispatchState({ options: newOptions });
            onSelect(newOptions);
        }

        function _getValue() {
            return finalOptions.filter((item) => item.id.length !== 0);
        }

        useImperativeHandle(ref, () => ({
            getValue: _getValue
        }));

        function _removeItem(position) {
            const listTemp = [...finalOptions];
            const newOptions = listTemp.filter((item, index) => position !== index);

            dispatchState({ options: newOptions });
        }

        function _handleSelectOption(newItem, position) {
            let listTemp = [...finalOptions];
            listTemp = listTemp.map((item, index) => {
                if (index === position) {
                    return newItem;
                }
                return item;
            });
            dispatchState({ options: listTemp });
            onSelect(listTemp);
        }

        function _renderListEmails() {
            const optionSelected = finalOptions.map((item) => item.id);
            return finalOptions.map((item, index) => {
                return (
                    <EmailItem
                        key={index.toString()}
                        total={optionSelected.length}
                        ids={optionSelected}
                        removeItem={_removeItem}
                        addItem={_addNewItem}
                        selectOption={_handleSelectOption}
                        openOptions={openOptions}
                        dataEmail={item}
                        index={index}
                        isFirstTimeFetch={isFirstTimeFetch}
                        data={data}
                        canAddMore={canAddMore}
                        isLocationEmail={isLocationEmail}
                    />
                );
            });
        }

        return (
            <>
                {_renderListEmails()}
                {!isLocationEmail && canAddMore && (
                    <div className="v2-btn-main --bg-green has-icon svg-white" onClick={_addNewItem} tabIndex="0">
                        <IconPlus />
                        {t('customers:add_billing_email')}
                    </div>
                )}
            </>
        );
    }
);

EmailTo.displayName = 'Email_To';

export default EmailTo;

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_METHOD_PAYMENT } from 'app/modules/jobdetail/const/Invoice';
import { AddPaymentContext } from 'app/modules/jobdetail/contexts/AddPaymentContext';
import SearchCustomer from 'app/modules/quickadd/components/SearchCustomer';
import IconUser from 'assets/icon/IconUser';

const SelectCustomer = () => {
    const { t } = useTranslation();
    const { addPayment, updatePaymentDataContext } = useContext(AddPaymentContext);
    // eslint-disable-next-line no-undef
    const customerDetail = global.customerDetail;

    function _handleSelectCustomer(customerId, customerSelected) {
        updatePaymentDataContext({
            customerId: customerId,
            cardSelected: null,
            listCard: [],
            paymentSelected: DEFAULT_METHOD_PAYMENT,
            activeSurcharge: false,
            amountValue: 0,
            surchargeValue: 0,
            valuePayment: '',
            activeCredit: false,
            newCredit: 0,
            customerInfo: customerSelected
        });
    }

    return (
        <div className="sm-row mb-4">
            <div className="txt svg-name">
                <IconUser isCustomer />
                <span className="flex-1 ellipsis">{t('common:customer')}</span>
            </div>
            <div className="detail">
                <SearchCustomer
                    classWrapper="v2-dropdown select-info"
                    selected={addPayment.customerId}
                    triggerFocus={addPayment.triggerFocus}
                    onSelect={_handleSelectCustomer}
                    typeSearch="payment"
                    autoFocus={!customerDetail}
                    customerName={customerDetail ? customerDetail.full_name : ''}
                    isDisable={!!customerDetail}
                />
            </div>
        </div>
    );
};

export default SelectCustomer;

export default function IconDropUpDown() {
    return (
        <svg width={8} height={8} viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            {' '}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.39378 0H8.60622C9.3014 0 9.86495 0.612378 9.86495 1.36778C9.86495 1.68776 9.76171 1.99761 9.5732 2.24342L5.96698 6.94579C5.52194 7.52611 4.72823 7.60452 4.19417 7.12092C4.13569 7.06796 4.08175 7.00934 4.03301 6.94579L0.426789 2.24342C-0.0182539 1.6631 0.0539014 0.800623 0.587953 0.317024C0.814167 0.112181 1.09931 0 1.39378 0Z"
                fill="var(--color-icon)"
            />{' '}
        </svg>
    );
}

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonCopy from 'app/components/button/ButtonCopy';
import ButtonSave from 'app/components/button/ButtonSave';
import { MERCHANT_ID, TYPE_CARD_US_BANK } from 'app/const/Customers';
import { connectStripeExisting } from 'app/const/api/V2';
import { PAYMENT_MERCHANT_KEYWORD } from 'app/const/customer/CustomerPayment';
import { clientQuery } from 'common/utils/ApiUtils';
import CardDate from './CardDate';

const ResultStripeExisted = ({
    id,
    customerId = '',
    email,
    description,
    disableConnect = false,
    cards = [],
    type = PAYMENT_MERCHANT_KEYWORD.STRIPE,
    onRemoveAlert = () => {},
    onConnectSuccess = () => {},
    onConnectFailed = () => {}
}) => {
    const { t } = useTranslation();
    const refButtonSave = useRef(null);

    const _handleConnect = () => {
        onRemoveAlert();

        const _handleConnectFailed = ({ message }) => {
            onConnectFailed(message);
            refButtonSave.current.removeLoading();
        };

        clientQuery(
            connectStripeExisting(customerId),
            { data: { account_customer_id: id, merchant: MERCHANT_ID[type] }, method: 'POST' },
            onConnectSuccess,
            _handleConnectFailed
        );
    };

    return (
        <div className="form-results__items">
            <div className="item-header flex-betweenitems">
                <div className="label-id flex-1">
                    <div className="label-id__btn">{id}</div>
                    <ButtonCopy content={id} />
                </div>
                {!!cards.length && !disableConnect && (
                    <ButtonSave
                        ref={refButtonSave}
                        classWrap="v2-btn-main flex-auto ml-1"
                        title={t('common:connect_this_id')}
                        onSave={_handleConnect}
                    />
                )}
            </div>
            <div className="item-information mt-5">
                <p className="item-information__line">{t('common:account_details')}</p>
                <p className="item-information__line">{email}</p>
                <p className="item-information__line">{description}</p>
            </div>
            {!!cards.length && (
                <div className="item-list-card mt-5">
                    <h4 className="mb-2">{t('setting:payment_methods')}</h4>
                    <ListCard cards={cards} />
                </div>
            )}
        </div>
    );
};

const ListCard = ({ cards = [] }) => {
    const { t } = useTranslation();
    return cards.map((item) => {
        const finalName = item.type === TYPE_CARD_US_BANK ? item.name : item.brand;

        return (
            <div key={item.id} className="item-list-card__line">
                <div className="info-card">
                    <div className="card-number">
                        <div className="number">
                            <p className="branch txt-ellipsis" title={finalName}>
                                {finalName}
                            </p>
                            <p className="number__dots">••••</p>
                            <p className="number__digit txt-ellipsis">{item.last4}</p>
                        </div>
                        <CardDate {...item} />
                    </div>
                </div>
                {item.is_default && <div className="status-btn">{t('common:default')}</div>}
            </div>
        );
    });
};

export default ResultStripeExisted;

export default function IconForward({ above, isBottom }) {
    if (isBottom) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.6501 3.99961C11.6501 3.64062 11.3591 3.34961 11.0001 3.34961C10.6411 3.34961 10.3501 3.64062 10.3501 3.99961V9.99961C10.3501 12.0154 11.9843 13.6496 14.0001 13.6496H21.4309L18.5405 16.54C18.2866 16.7939 18.2866 17.2054 18.5405 17.4593C18.7943 17.7131 19.2059 17.7131 19.4597 17.4593L23.4597 13.4593C23.7135 13.2054 23.7135 12.7939 23.4597 12.54L19.4597 8.54004C19.2059 8.2862 18.7943 8.2862 18.5405 8.54004C18.2866 8.79388 18.2866 9.20544 18.5405 9.45928L21.4308 12.3496H14.0001C12.7022 12.3496 11.6501 11.2975 11.6501 9.99961V3.99961Z"
                    fill="var(--color-icon)"
                    fillOpacity="0.3"
                />
            </svg>
        );
    }
    if (above) {
        return (
            <svg width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.5 4L4.5 1L1.5 4"
                    stroke="#1EAA5C"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.5 9H9.83333C6.88781 9 4.5 6.31371 4.5 3V1"
                    stroke="#1EAA5C"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
    return (
        <svg width={12} height={20} viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            {' '}
            <path
                d="M7.5 12.5L10.5 9.5L7.5 6.5"
                stroke="#1E7EF7"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />{' '}
            <path
                d="M10.5 9.5H7.5C4.18629 9.5 1.5 7.48528 1.5 5V3.5"
                stroke="#1E7EF7"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />{' '}
        </svg>
    );
}

import { useReducer, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionGetListSchedule } from 'common/redux/actions/calendar/scheduleAction';
import IconLoading from 'assets/icon/IconLoading';
import { reducer } from 'app/const/Reducer';
import IconPrimary from 'assets/icon/IconPrimary';
import { ACCOUNT_ROLE } from 'app/const/App';
import { useTranslation } from 'react-i18next';

const ListSchedules = ({ selected, isVisible, onSelect }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: false
    });
    const schedulesReducer = useSelector((state) => state.schedules);
    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);

    const isLoading = state.isLoading;

    useEffect(() => {
        if (isVisible && schedulesReducer?.isFirstTime) {
            dispatchState({ isLoading: true });
            dispatch(actionGetListSchedule({}, actionGetListScheduleSuccess));
        }
    }, [isVisible]);

    function _handleOpenSchedule(e, schedule) {
        if (schedule.id === selected) {
            return false;
        }

        onSelect(schedule);
        e.stopPropagation();
    }

    function actionGetListScheduleSuccess() {
        dispatchState({ isLoading: false });
    }

    function _renderLoadingList() {
        if (!isLoading) return null;

        return (
            <div className="items justify-center">
                <div className="loading -ajaxbar">
                    <IconLoading />
                </div>
            </div>
        );
    }

    function _renderAvatar(scheduleDetail) {
        const userDetail = companyUsers.find((user) => user.id === scheduleDetail.user_id);

        if (userDetail?.avatar) {
            return <img src={userDetail.avatar} alt="" width="24" height="24" />;
        } else {
            return <div className="avt fs-11">{scheduleDetail.name.substring(0, 2)}</div>;
        }
    }

    const _renderListSchedule = () => {
        if (isLoading) return null;
        const finalListschedules = schedulesReducer?.data?.schedules;
        if (!Array.isArray(finalListschedules)) return null;
        return finalListschedules.map((item) => {
            const isActive = Math.abs(selected).toString() === item.id;
            return (
                <li
                    className={`btn-modal items ${isActive ? 'primary-active active' : ''} `}
                    key={item.id}
                    onClick={(e) => _handleOpenSchedule(e, item)}
                >
                    <div className="name">
                        <span className="avt-img relative">
                            {_renderAvatar(item)}
                            {item.role === ACCOUNT_ROLE.SUPERADMIN && (
                                <span className="primary-user-icon">
                                    <IconPrimary />
                                </span>
                            )}
                        </span>
                        <span className="text-name">{item.name}</span>
                    </div>
                    <div className="btn-group">
                        <span className="primary">{t('primary')}</span>
                    </div>
                </li>
            );
        });
    };

    return (
        <div id="show_list_schedule_job_preview" className="v2-dropdown__menu scrolls">
            <ul>
                {_renderLoadingList()}
                {_renderListSchedule()}
            </ul>
        </div>
    );
};

export default ListSchedules;

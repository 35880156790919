import classNames from 'classnames';
import React, { useReducer, useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import GdConfirm from 'app/components/confirm';
import GridEmpty from 'app/components/grid/components/GridEmpty';
import { getDeleteServiceBooking } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import { ADDONS_BOOKING_SETTINGS_ORDER } from 'app/const/api/V2';
import { getListStyle, reorder } from 'app/modules/smartview/components/utils';
import IconPlus from 'assets/icon/IconPlus';
import { clientQuery } from 'common/utils/ApiUtils';
import ServiceAdd from './ServiceAdd';
import ServiceAssign from './ServiceAssign';
import ServiceEditLearnMore from './ServiceEditLearnMore';
import ServiceItem from './ServiceItem';

const ServiceList = ({ data = [], isDisable = false, onShowAlert = () => {} }) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { services: data, isLoading: true });
    const { services } = state;

    const refAddService = useRef(null);
    const refAssign = useRef(null);
    const refConfirm = useRef(null);
    const refEditLearnMore = useRef(null);
    const refPlaceHolder = useRef(null);

    const _handleAddService = () => refAddService.current._open(services.map((item) => item.id));
    const _handleAddSuccess = (services) => {
        onShowAlert({
            id: 'update_service_success',
            message: t('addons:update_service_successfully'),
            type: LIST_STATUS.SUCCESS
        });
        dispatchState({ services });
    };

    const _handleEdit = (id, learnMore) => refEditLearnMore.current._open(id, learnMore);
    const _handleEditSuccess = (data, message) => {
        onShowAlert({
            id: 'update_learn_more_success',
            message,
            type: LIST_STATUS.SUCCESS
        });

        dispatchState({
            services: services.map((item) => {
                if (item.id === data.id) return { ...item, learn_more: data.learn_more };
                return item;
            })
        });
    };

    const _handleAssign = (idService, idSchedule) => refAssign.current._open(idService, idSchedule);
    const _handleAssignSuccess = (data, message, idUpdate) => {
        dispatchState({
            services: services.map((item) => {
                if (item.id === idUpdate) return { ...item, schedule: data };
                return item;
            })
        });
        onShowAlert({
            id: 'update_assign_success',
            message,
            type: LIST_STATUS.SUCCESS
        });
    };

    const _handleDelete = (id) => refConfirm.current.open(id);
    const _handleDeleteSuccess = (idDelete) => {
        clientQuery(getDeleteServiceBooking(idDelete), { data: {}, method: 'DELETE' });
        dispatchState({ services: services.filter((item) => item.id !== idDelete) });
        onShowAlert({
            id: 'update_assign_success',
            message: t('delete_successfully'),
            type: LIST_STATUS.SUCCESS
        });
    };

    const _handleDragEnd = (result = {}) => {
        if (refPlaceHolder.current) {
            refPlaceHolder.current.style.display = 'none';
            refPlaceHolder.current.style.top = 0;
            refPlaceHolder.current.style.left = 0;
        }
        if (!result.destination) return;
        const {
            source: { index: sourceIndex },
            destination: { index: destinationIndex }
        } = result;
        if (sourceIndex === destinationIndex) return;
        const finalServices = reorder(services, sourceIndex, destinationIndex);
        const findPreItem = finalServices[destinationIndex - 1];
        const currentItem = finalServices[destinationIndex];
        dispatchState({ services: finalServices });

        const _handleFailed = ({ message }) => {
            onShowAlert({ id: 'order_setting_failed', message, type: LIST_STATUS.ERROR });
        };
        clientQuery(
            ADDONS_BOOKING_SETTINGS_ORDER,
            { data: { id: currentItem?.id || '', after_id: findPreItem?.id || '' }, method: 'PUT' },
            null,
            _handleFailed
        );
    };

    const _handleDragUpdate = (update) => {
        if (!update.destination) return;
        const draggableId = update.draggableId;
        const initialIndex = update.source.index;
        const destinationIndex = update.destination.index;
        const queryAttr = 'data-rbd-drag-handle-draggable-id';
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        if (!draggedDOM) return;
        const parentDom = draggedDOM.closest('.boxs__contents');
        const arr = [...parentDom.children];
        if (initialIndex < destinationIndex) arr.splice(initialIndex, 1);
        const clientY = arr.slice(0, destinationIndex).reduce((total, curr) => {
            const style = curr.currentStyle || window.getComputedStyle(curr);
            const marginBottom = parseFloat(style.marginBottom);
            return total + curr.clientHeight + marginBottom;
        }, 0);
        if (refPlaceHolder.current) {
            refPlaceHolder.current.style.display = 'flex';
            refPlaceHolder.current.style.top = `${clientY}px`;
            refPlaceHolder.current.style.left = `${parseFloat(window.getComputedStyle(parentDom).paddingLeft)}px`;
        }
    };

    return (
        <>
            <div className={classNames('boxs boxs--booking', { 'is-disable': isDisable })}>
                <div className="boxs__header border-bottom-border-color-grey">
                    <div className="title cursor-pointer">{t('addons:add_your_service')}</div>
                    <div className="v2-btn-default has-icon --grey btn-modal" onClick={_handleAddService}>
                        <IconPlus />
                        {t('addons:add_a_service')}
                    </div>
                </div>
                {!services.length ? (
                    <GridEmpty className="tables-empty mx-3" />
                ) : (
                    <DragDropContext onDragEnd={_handleDragEnd} onDragUpdate={_handleDragUpdate}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    className="boxs__contents --service mb-0"
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {services.map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                            {(provided) => (
                                                <ServiceItem
                                                    key={item.id}
                                                    provided={provided}
                                                    onAssign={_handleAssign}
                                                    onDelete={_handleDelete}
                                                    onEditLearnMore={_handleEdit}
                                                    onDragEnd={_handleDragEnd}
                                                    {...item}
                                                />
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    <div
                                        ref={refPlaceHolder}
                                        className="has-drag absolute w-100"
                                        style={{ display: 'none' }}
                                    />
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                )}
            </div>

            {/* Modals */}
            <ServiceAdd ref={refAddService} onAddSuccess={_handleAddSuccess} />
            <ServiceAssign ref={refAssign} onAssignSuccess={_handleAssignSuccess} />
            <ServiceEditLearnMore ref={refEditLearnMore} onEditSuccess={_handleEditSuccess} />
            <GdConfirm
                ref={refConfirm}
                title={t('delete')}
                message={t('are_you_sure_delete_this_item')}
                listButton={{ confirm: true, cancel: true }}
                onConfirm={_handleDeleteSuccess}
            />
        </>
    );
};

export default ServiceList;

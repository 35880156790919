import classNames from 'classnames';
import React, { forwardRef, useId, useImperativeHandle, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import SearchOption from 'app/modules/report/filter/SearchOption';
import IconArrow from 'assets/icon/IconArrow';
import IconCheck from 'assets/icon/IconCheck';
import ItemJobStatus from '../job/ItemJobStatus';

const GDDropdownWithSearch = forwardRef(
    (
        {
            id = '',
            customDropButton,
            placeholder = '',
            isCheckbox = false,
            buttonClassName,
            options = [],
            tabsHeader: TabsHeader = null,
            refTabs = null,
            onApply = null,
            onHide = () => {},
            onSearch = () => {},
            onSelect = () => {}
        },
        ref
    ) => {
        const { t } = useTranslation('common');
        const refDropdown = useRef(ref);

        useImperativeHandle(ref, () => ({ close: () => refDropdown.current?._closeDropdown() }));

        const _handleCloseDropdown = () => {
            refDropdown.current?._closeDropdown();
        };

        return (
            <CalendarDropdown
                id={id}
                ref={refDropdown}
                buttonClassName={buttonClassName}
                customDropButton={customDropButton}
                onHide={onHide}
                wrapperClassName="header-items list-job-status smartview-column"
                wrapperListClass="v2-dropdown__menu --right"
            >
                <SearchOption
                    parentStyle="drop-header --search"
                    isSearchIcon
                    autoFocus
                    timeDdebound={0}
                    placeholder={placeholder}
                    onSearch={onSearch}
                />
                {TabsHeader ? (
                    <div className="drop-header">
                        <TabsHeader ref={refTabs} />
                    </div>
                ) : null}
                <GroupDropdownList data={options} isCheckbox={isCheckbox} onSelect={onSelect} />
                {onApply ? (
                    <div className="drop-footer">
                        <div className="v2-btn-default --transparent" onClick={_handleCloseDropdown}>
                            {t('cancel')}
                        </div>
                        <div className="v2-btn-main ml-2" onClick={onApply}>
                            {t('apply')}
                        </div>
                    </div>
                ) : null}
            </CalendarDropdown>
        );
    }
);

const GroupDropdownList = ({ data, isCheckbox = false, onSelect = () => {} }) => {
    const { t } = useTranslation('smartView');
    return (
        <div className="drop-container">
            {data.length ? (
                data.map((item, index) => (
                    <GroupDropdown
                        key={item.objectType + index}
                        {...item}
                        isCheckbox={isCheckbox}
                        onSelect={onSelect}
                    />
                ))
            ) : (
                <div className="drop-items">
                    <div className="drop-items__title cursor-default">
                        <p className="my-1 py-1"> {t('no_matches')} </p>
                    </div>
                </div>
            )}
        </div>
    );
};

const GroupDropdown = ({ objectType, title, options, isCheckbox = false, onSelect = () => {} }) => {
    const { t } = useTranslation(['smartView']);
    const [state, dispatchState] = useReducer(reducer, { expanded: true });
    const { expanded } = state;

    const _handleToggle = () => {
        dispatchState({ expanded: !expanded });
    };

    return (
        <div className={classNames('drop-items', { 'is-hide': !expanded })}>
            <div className="flex-betweenitems drop-items__title" onClick={_handleToggle}>
                <span className="fw-600">{t(title)}</span>
                <IconArrow isLeft />
            </div>
            {expanded ? (
                <ul className="drop-items__content">
                    {options.map((item) => (
                        <ItemSelect
                            key={`${objectType}-${item.value}`}
                            {...item}
                            objectType={objectType}
                            isCheckbox={isCheckbox}
                            onSelect={onSelect}
                        />
                    ))}
                </ul>
            ) : null}
        </div>
    );
};

const ItemSelect = ({
    color = '',
    name = '',
    objectType,
    value,
    label,
    isCheckbox,
    disabled = false,
    checked = false,
    onSelect = () => {}
}) => {
    const { t } = useTranslation(['smartView']);
    const refInput = useRef(null);
    const [active, setActive] = useState(checked);
    const _handleClick = () => {
        setActive(!refInput.current.checked);
        onSelect({ objectType, fieldId: value });
    };

    if (isCheckbox) {
        const idCheckbox = useId();
        return (
            <li className={classNames('items', { 'is-disable': disabled, active })} onClick={_handleClick}>
                <div className="check-items">
                    <input
                        ref={refInput}
                        id={idCheckbox}
                        type="checkbox"
                        disabled
                        checked={active}
                        onChange={(e) => e.preventDefault()}
                    />
                    <div className="item-checkbox">
                        <label htmlFor={idCheckbox}>
                            {color ? (
                                <ItemJobStatus
                                    classTextItem="status-btn --job-status"
                                    defaultStatus={{ name, label: t(label), color }}
                                    excludeWrapper
                                />
                            ) : (
                                <span className="txt-ellipsis">{t(label)}</span>
                            )}
                        </label>
                    </div>
                </div>
            </li>
        );
    }

    return (
        <li className="items --ticked" onClick={() => onSelect({ objectType, fieldId: value })}>
            <IconCheck />
            <span className="txt-ellipsis">{t(label)}</span>
        </li>
    );
};

export default GDDropdownWithSearch;

import React from 'react';
import { useTranslation } from 'react-i18next';

import Filter from 'app/components/templatenotes/Filter';
import { ESTIMATES_TEMPLATE_OPTIONS } from 'app/const/Notes';
import SearchOption from 'app/modules/report/filter/SearchOption';

export default function Search({ onChange, onChangeFilter, dataFilter, keyword }) {
    const { t } = useTranslation();
    return (
        <div className="flex-auto header-search flexcenter">
            <SearchOption
                timeDdebound={500}
                isSearchIcon
                parentStyle="search-input has-closeicon"
                defaultValue={keyword || ''}
                placeholder={t('common:search_template')}
                onSearch={onChange}
            />

            <Filter
                filterParent={dataFilter}
                onApplyFilter={onChangeFilter}
                listDataFilter={ESTIMATES_TEMPLATE_OPTIONS}
            />
        </div>
    );
}
